import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Nav, NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";

const MainFooter = ({ contained, menuItems, copyright }) => (
  <footer className="main-footer d-flex p-2 px-3 bg-white border-top">
    <Container fluid className="my-auto">
      <Row className="mt-1 ">
        <span className=" mr-auto">
          <a className="px-3" href="/faq.html" target="_blank">
            FAQ
          </a>
          <a
            className="px-3"
            href="https://www.gloryindia.in/privacy"
            target="_blank"
          >
            Privacy policy
          </a>
          <a
            className="px-3"
            href="https://www.gloryindia.in/terms"
            target="_blank"
          >
            Terms and condition
          </a>
        </span>

        <span
          className="copyright ml-auto my-auto mr-2 "
          style={{ fontSize: "small" }}
        >
          {copyright}
        </span>
      </Row>
    </Container>
  </footer>
);

MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string,
};

MainFooter.defaultProps = {
  contained: false,
  copyright: "© Copyright 2020 Glory ECommerce PVT LTD",
  menuItems: [
    {
      title: "Home",
      to: "#",
    },
    {
      title: "Services",
      to: "#",
    },
    {
      title: "About",
      to: "#",
    },
    {
      title: "Products",
      to: "#",
    },
    {
      title: "Blog",
      to: "#",
    },
  ],
};

export default MainFooter;
