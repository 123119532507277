import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Col } from "shards-react";

const Logo = ({ img, alternate, className, ...attrs }) => {
  const classes = classNames(
    className,

    "mb-sm-0",
    "col-12",
    "col-md-6",
    "col-lg-4",
    "col-xl-3"
  );

  return (
    <Col className={classes} {...attrs}>
      <span className="d-flex justify-content-center">
        <img src={img} alt={alternate} />
      </span>
      {/* <h3 className="page-title">{title}</h3> */}
    </Col>
  );
};

Logo.propTypes = {
  /**
   * Image to be rendered
   */
  img: PropTypes.node,
  /**
   * The page subtitle.
   */
  alternate: PropTypes.string
};

export default Logo;
