import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Collapse,
  Button,
  Tooltip,
  Card,
  CardImg,
  CardBody,
  Form,
  FormCheckbox,
  FormSelect,
  FormGroup,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CardHeader,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";
import * as config from "../constants/constants.json";
import PageTitle from "./../components/common/PageTitle";
import InputDataForm from "./../components/common/InputDataForm";
import TextArea from "./../components/common/TextArea";
import DragAndDrop from "./../components/common/DragAndDropProfile";
import AddVehicleData from "./../views/AddVehicleData";
import Datepicker from "react-date-picker";
import ReactDatepicker from "react-datepicker";
import NavTab from "./../components/common/NavTab";
import EditDrivatab from "./../components/common/EditDrivatab";
import DivRender from "./../components/common/DivRender";

class EditDriver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchphonenumber: "",
      index: 0,
      readOnly: true,
      readOnlyVehicle: true,
      readonlyCheckbox: true,
      readOnlyFamilyDetails: true,
      readOnlyBankDetails: true,
      open: true,
      login_status: "-",
      bankDetailsCog: true,
      driverDetails: true,
      displayProfCog: true,
      vehicleDetailsCog: true,
      ridePrefCog: true,
      familyDetailsCog: true,
      visible: false,
      notExist: false,
      consumerWarn: false,
      divName: "id_proofs",
      first_name: "",
      last_name: "",
      dob: "",
      email: "",
      current_address: "",
      permanent_address: "",
      outstation: false,
      rentals: false,
      airport: false,
      urban: false,
      recurride: false,
      day_shift: false,
      night_shift: false,
      operating_region: "",
      preferred_location: "",
      starting_point: "",
      vehicle_name: "",
      vehicle_number: "",
      vehicle_color: "",
      vehicle_make: "",
      vehicle_model: "",
      phonenumber: "",
      alt_phonenumber: "",
      driver_profile:
        "https://master.gloryindia.in/static/images/avatars/man.png",
      family_name_1: "",
      family_name_2: "",
      family_phn_1: "",
      family_phn_2: "",
      family_rel_1: "",
      family_rel_2: "",
      family_address_1: "",
      family_address_2: "",
      acc_holder_name: "",
      acc_number: "",
      bank_name: "",
      ifsc: "",
      acc_phonenumber: "",
      acc_email: "",
      acc_address: "",
      avg_ratings: "",
      vehicle_type: "",
      results: [],
      vehicle_front_image:
        "https://master.gloryindia.in/static/images/document.png",
      vehicle_back_image:
        "https://master.gloryindia.in/static/images/document.png",
      vehicle_left_image:
        "https://master.gloryindia.in/static/images/document.png",
      vehicle_right_image:
        "https://master.gloryindia.in/static/images/document.png",
      vehicle_with_driver:
        "https://master.gloryindia.in/static/images/document.png",
      aadhar_card_image_front:
        "https://master.gloryindia.in/static/images/document.png",
      aadhar_card_image_back:
        "https://master.gloryindia.in/static/images/document.png",
      pan_card_image: "https://master.gloryindia.in/static/images/document.png",
      driving_license_image:
        "https://master.gloryindia.in/static/images/document.png",
      rc_image: "https://master.gloryindia.in/static/images/document.png",
      vehicle_insurance_image:
        "https://master.gloryindia.in/static/images/document.png",
      driver_badgee_image:
        "https://master.gloryindia.in/static/images/document.png",
      emission_cert_image:
        "https://master.gloryindia.in/static/images/document.png",
      road_tax_image: "https://master.gloryindia.in/static/images/document.png",
      permit_image: "https://master.gloryindia.in/static/images/document.png",
      fitness_image: "https://master.gloryindia.in/static/images/document.png",
      sales_agreement:
        "https://master.gloryindia.in/static/images/document.png",
      police_verification:
        "https://master.gloryindia.in/static/images/document.png",
      badge_number: "",
      permit_number: "",
      aadhar_number: "",
      pan_number: "",
      dl_number: "",
      dunique_id: "",
      driverformImageFront:
        "https://master.gloryindia.in/static/images/document.png",
      driverformImageBack:
        "https://master.gloryindia.in/static/images/document.png",
      registeredBy: "",
      registeredDate: "",
      lastEditedBy: "",
      lastEditedDate: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.handleNavClick = this.handleNavClick.bind(this);
    this.handleKeyChange = this.handleKeyChange.bind(this);
    this.toggleExists = this.toggleExists.bind(this);
    this.toggleWarn = this.toggleWarn.bind(this);
    this.searchDriver = this.searchDriver.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleEditDetails = this.handleEditDetails.bind(this);
    this.handleImagedata = this.handleImagedata.bind(this);
    this.handleProfileDetails = this.handleProfileDetails.bind(this);
    this.handleVehicleDetails = this.handleVehicleDetails.bind(this);
    this.handleEditVehicle = this.handleEditVehicle.bind(this);
    this.handleEditRidepref = this.handleEditRidepref.bind(this);
    this.handleRideprefDetails = this.handleRideprefDetails.bind(this);
    this.handleFamilyDetails = this.handleFamilyDetails.bind(this);
    this.handleEditFamily = this.handleEditFamily.bind(this);
    this.handleBankDetails = this.handleBankDetails.bind(this);
    this.handleEditBank = this.handleEditBank.bind(this);
    this.imagehandler = this.imagehandler.bind(this);
    this.callImagehandler = this.callImagehandler.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.onboarddriver = this.onboarddriver.bind(this);
  }

  onboarddriver() {
    this.props.history.push({
      pathname: "/driveraccounts/adddriver",
      search: `?phonnumber=${this.state.searchphonenumber}`,
      state: { phonenumber: this.state.searchphonenumber },
    });
  }
  validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }
  handleSelectChange(event) {
    this.setState({
      operating_region: event.target.value,
    });
  }
  goBack() {
    this.props.history.goBack();
  }
  // vehicle_front_image: '',
  //         vehicle_back_image: '',
  //         vehicle_left_image: '',
  //         vehicle_right_image: '',
  //         vehicle_with_driver: '',
  //         aadhar_card_image_front: '',
  //         aadhar_card_image_back: '',
  //         pan_card_image: '',
  //         driving_license_image: '',
  //         rc_image: '',
  //         vehicle_insurance_image: '',
  //         driver_badgee_image: '',
  //         emission_cert_image: '',
  //         road_tax_image: '',
  //         permit_image: '',
  //         fitness_image: '',
  //         sales_agreement: '',
  //         police_verification: '',
  callImagehandler(name, data) {
    // console.log("********************")
    console.log(name);
    console.log("here");
    // console.log(data);
    let imageType, imageData;
    switch (name) {
      case "vehicle_front_image":
        imageType = "vehiclefront";
        // imageData = this.state.vehicle_front_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "vehicle_back_image":
        imageType = "vehiclerear";
        // imageData = this.state.vehicle_back_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "vehicle_right_image":
        imageType = "vehicleright";
        // imageData = this.state.vehicle_right_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "vehicle_left_image":
        imageType = "vehicleleft";
        // imageData = this.state.vehicle_left_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "vehicle_with_driver":
        imageType = "driverwithvehicle";
        // imageData = this.state.vehicle_with_driver.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "aadhar_card_image_front":
        imageType = "aadhar";
        // console.log(this.state.aadhar_card_image_front);
        // imageData = this.state.aadhar_card_image_front.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        console.log(imageData);
        break;
      case "pan_card_image":
        imageType = "pan";
        // imageData = this.state.pan_card_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "driving_license_image":
        imageType = "drivinglicense";
        // imageData = this.state.driving_license_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "rc_image":
        imageType = "rc";
        // imageData = this.state.rc_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "vehicle_insurance_image":
        imageType = "vehicleinsurance";
        // imageData = this.state.vehicle_insurance_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "driver_badgee_image":
        imageType = "driverbadge";
        // imageData = this.state.driver_badgee_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "emission_cert_image":
        imageType = "emissioncrt";
        // imageData = this.state.emission_cert_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "road_tax_image":
        imageType = "roadTaxExp";
        // imageData = this.state.road_tax_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "permit_image":
        imageType = "permit";
        // imageData = this.state.permit_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "fitness_image":
        imageType = "fc";
        // imageData = this.state.fitness_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "sales_agreement":
        imageType = "salesagreement";
        // imageData = this.state.sales_agreement.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "police_verification":
        imageType = "policeverification";
        // imageData = this.state.police_verification.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "driverformImageFront":
        imageType = "driverformImageFront";
        // imageData = this.state.driverformImageFront.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "driverformImageBack":
        imageType = "driverformImageBack";
        // imageData = this.state.driverformImageBack.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      default:
        console.log("error");
        break;
    }
    // if (this.validURL(imageData)) {
    //   console.log("true");
    //   console.log(imageData);
    // } else {

    // }
    let dataToSend = {
      dunique_id: this.state.dunique_id,
      imageType: imageType,
      imageData: imageData,
      executive_id: window.sessionStorage.getItem("user_id"),
    };
    // console.log(data);
    console.log("here");
    fetch(config.url + "onboard/updateDriverImages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          console.log(resultsss);
          if (resultsss.message === "successfull") {
            // let dataImage = resultsss.imageResponse + "?random_number=" + new Date().getTime();
            // this.imagehandler(resultsss.imageResponse, name)
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleRideprefDetails() {
    let duty_preference;
    if (this.state.day_shift === true && this.state.night_shift === true) {
      duty_preference = "day / night";
    } else if (this.state.day === true) {
      duty_preference = "day";
    } else if (this.state.night_shift === true) {
      duty_preference = "night";
    }
    fetch(config.url + "onboard/updateDriverPersonalDataEdited", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        dunique_id: this.state.dunique_id,
        prefer_recurride: this.state.recurride,
        prefer_airport: this.state.airport,
        prefer_urban: this.state.urban,
        prefer_hiring: this.state.rentals,
        prefer_outstation: this.state.outstation,
        prefer_location: this.state.preferred_location,
        operating_region: this.state.operating_region,
        duty_preference: duty_preference,
        dataType: "ridepreferences",
        editedBy: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          console.log(resultsss);
          if (resultsss.message === "successfull") {
            this.setState({
              ridePrefCog: !this.state.ridePrefCog,
              readonlyCheckbox: !this.state.readonlyCheckbox,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleVehicleDetails() {
    fetch(config.url + "onboard/updateDriverOtherDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        dunique_id: this.state.dunique_id,
        dataType: "vehicleTypeDetails",
        vehiclenumber: this.state.vehicle_number,
        vehiclemake: this.state.vehicle_make,
        vehiclemodel: "1582732202379",
        vehiclecolor: this.state.vehicle_color,
        vehiclename: this.state.vehicle_name,
        editedBy: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          console.log(resultsss);
          if (resultsss.message === "successfull") {
            this.setState({
              vehicleDetailsCog: !this.state.vehicleDetailsCog,
              readOnlyVehicle: !this.state.readOnlyVehicle,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  handleBankDetails() {
    fetch(config.url + "onboard/updateDriverPersonalDataEdited", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        dunique_id: this.state.dunique_id,
        dataType: "bankdetails",
        accholder_name: this.state.acc_holder_name,
        ifsc_code: this.state.ifsc,
        account_number: this.state.acc_number,
        accphonenumber: this.state.acc_phonenumber,
        address: this.state.acc_address,
        email: this.state.acc_email,
        editedBy: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          console.log(resultsss);
          if (resultsss.message === "successfull") {
            this.setState({
              bankDetailsCog: !this.state.bankDetailsCog,
              readOnlyBankDetails: !this.state.readOnlyBankDetails,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleFamilyDetails() {
    let data = {
      dunique_id: this.state.dunique_id,
      dataType: "familydetails",
      fmone_name: this.state.family_name_1,
      fmone_phonenumber: this.state.family_phn_1,
      fmone_relationship: this.state.family_rel_1,
      fmone_address: this.state.family_address_1,
      fmtwo_name: this.state.family_name_2,
      fmtwo_phonenumber: this.state.family_phn_2,
      fmtwo_relationship: this.state.family_rel_2,
      fmtwo_address: this.state.family_address_2,
      editedBy: window.sessionStorage.getItem("user_id"),
    };
    console.log(data);
    fetch(config.url + "onboard/updateDriverPersonalDataEdited", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        dunique_id: this.state.dunique_id,
        dataType: "familydetails",
        fmone_name: this.state.family_name_1,
        fmone_phonenumber: this.state.family_phn_1,
        fmone_relationship: this.state.family_rel_1,
        fmone_address: this.state.family_address_1,
        fmtwo_name: this.state.family_name_2,
        fmtwo_phonenumber: this.state.family_phn_2,
        fmtwo_relationship: this.state.family_rel_2,
        fmtwo_address: this.state.family_address_2,
        editedBy: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          console.log(resultsss);
          if (resultsss.message === "successfull") {
            this.setState({
              familyDetailsCog: !this.state.familyDetailsCog,
              readOnlyFamilyDetails: !this.state.readOnlyFamilyDetails,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleImagedata() {
    let profileData = this.state.driver_profile.replace(
      /^data:image\/[a-z]+;base64,/,
      ""
    );
    fetch(config.url + "onboard/updateDriverImages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        dunique_id: this.state.dunique_id,
        imageData: profileData,
        imageType: "driverprofileimage",
        executive_id: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          console.log(resultsss);
          if (resultsss.message === "successfull") {
            this.setState({
              displayProfCog: !this.state.displayProfCog,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  handleProfileDetails() {
    fetch(config.url + "onboard/updateDriverPersonalDataEdited", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        dunique_id: this.state.dunique_id,
        dataType: "personaldetails",
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        phonenumber: this.state.phonenumber,
        current_address: this.state.current_address,
        permanent_address: this.state.permanent_address,
        email: this.state.email,
        editedBy: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          console.log(resultsss);
          if (resultsss.message === "successfull") {
            this.setState({
              driverDetails: !this.state.driverDetails,
              readOnly: !this.state.readOnly,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleImage(image, name) {
    console.log(image);
    const newState = {};
    newState[name] = image;
    this.setState({ ...this.state, ...newState });
  }
  handleNavClick(name, index) {
    // console.log(name)
    this.setState({
      divName: name,
      index: index,
    });
  }
  searchDriver(event) {
    if (event) {
      event.preventDefault();
    }

    // serch opertion here
    fetch(config.url + "onboard/search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        phonenumber: this.state.searchphonenumber,
        // phonenumber: "8123173193"
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          if (resultsss.message) {
            if (resultsss.message === "user does not exist") {
              this.setState({
                notExist: !this.state.notExist,
              });
            } else if (resultsss.message === "consumer") {
              this.setState({
                consumerWarn: !this.state.consumerWarn,
              });
            } else if (resultsss.message === "successfull") {
              console.log(resultsss);
              let resData = resultsss.results;
              // let duty_preference;
              let night, day;
              if (
                resData.driverPreference.duty_preference === "" ||
                resData.driverPreference.duty_preference === undefined ||
                resData.driverPreference.duty_preference === null
              ) {
                night = false;
                day = false;
              } else if (
                resData.driverPreference.duty_preference === "day / night"
              ) {
                night = true;
                day = true;
              } else if (resData.driverPreference.duty_preference === "day") {
                night = false;
                day = true;
              } else if (resData.driverPreference.duty_preference === true) {
                night = true;
                day = false;
              }
              // console.log(resultsss.driverPreference)
              // visible: !this.state.visible,
              this.setState({
                visible: true,
                night_shift: night,
                day_shift: day,
                first_name: resData.first_name,
                last_name: resData.last_name,
                email: resData.email,
                current_address: resData.current_address,
                permanent_address: resData.permanent_address,
                phonenumber: resData.phonenumber,
                alt_phonenumber: resData.alternate_number,
                vehicle_name: resData.vehicle_details.vehicleName,
                vehicle_number: resData.vehicle_details.vehiclenumber,
                vehicle_color: resData.vehicle_details.vehicleColor,
                vehicle_make: resData.vehicle_details.vehicle_make,
                vehicle_type: resData.vehicle_details.vehicle_type,
                vehicle_model: resData.vehicle_details.vehicle_model,
                acc_holder_name: resData.accholder_name,
                acc_number: resData.account_number,
                acc_phonenumber: resData.accphonenumber,
                acc_email: resData.accemail,
                acc_address: resData.accaddress,
                bank_name: "",
                ifsc: resData.ifsc_code,
                family_name_1: resData.familyDetails.fmone_name,
                family_name_2: resData.familyDetails.fmtwo_name,
                family_phn_1: resData.familyDetails.fmone_phonenumber,
                family_phn_2: resData.familyDetails.fmtwo_phonenumber,
                family_rel_1: resData.familyDetails.fmone_relationship,
                family_rel_2: resData.familyDetails.fmtwo_relationship,
                family_address_1: resData.familyDetails.fmone_address,
                family_address_2: resData.familyDetails.fmtwo_address,
                outstation: resData.driverPreference.outstation,
                rentals: resData.driverPreference.rental,
                airport: resData.driverPreference.airport,
                urban: resData.driverPreference.urban,
                recurride: resData.driverPreference.recur_ride,
                operating_region: resData.driverPreference.operating_region,
                preferred_location: resData.driverPreference.preferred_location,
                starting_point: resData.driverPreference.starting_point,
                avg_ratings: resData.avg_ratings,
                vehicle_front_image: resData.imagedata.vehicle_pic[0],
                vehicle_back_image: resData.imagedata.vehicle_pic[1],
                vehicle_left_image: resData.imagedata.vehicle_pic[2],
                vehicle_right_image: resData.imagedata.vehicle_pic[3],
                vehicle_with_driver: resData.imagedata.driv_vehicle_image[1],
                aadhar_card_image_front: resData.imagedata.driver_adharcard[0],
                aadhar_card_image_back: resData.imagedata.driver_adharcard[1],
                pan_card_image: resData.imagedata.pan[0],
                driving_license_image: resData.imagedata.dl_image[0],
                rc_image: resData.imagedata.vehicle_rc[0],
                vehicle_insurance_image: resData.imagedata.insurance_image[0],
                driver_badgee_image: resData.imagedata.display_card[0],
                emission_cert_image: resData.imagedata.emission_image[0],
                road_tax_image: resData.imagedata.roadtax_image[0],
                permit_image: resData.imagedata.permit_image[0],
                fitness_image: resData.imagedata.fc_image[0],
                sales_agreement: resData.imagedata.sales_agreement[0],
                police_verification: resData.imagedata.police_verification[0],
                driver_profile: resData.imagedata.driver_image[0],
                driverformImageFront: resData.driverformImageFront[0],
                driverformImageBack: resData.driverformImageBack[0],
                dunique_id: resData.dunique_id,
                registeredDate: resData.registeredDate,
                registeredBy: resData.registeredBy,
                lastEditedDate: resData.lastEditedDate,
                lastEditedBy: resData.lastEditedBy,
                login_status: resData.login_status,
              });
            } else {
            }
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleEdit() {
    // alert("hello")
    this.setState({
      displayProfCog: !this.state.displayProfCog,
    });
  }
  handleEditDetails() {
    // alert("hello")
    this.setState({
      driverDetails: !this.state.driverDetails,
      readOnly: !this.state.readOnly,
    });
  }
  handleEditVehicle() {
    this.setState({
      vehicleDetailsCog: !this.state.vehicleDetailsCog,
      readOnlyVehicle: !this.state.readOnlyVehicle,
    });
  }
  handleEditRidepref() {
    this.setState({
      ridePrefCog: !this.state.ridePrefCog,
      readonlyCheckbox: !this.state.readonlyCheckbox,
    });
  }
  handleEditFamily() {
    this.setState({
      familyDetailsCog: !this.state.familyDetailsCog,
      readOnlyFamilyDetails: !this.state.readOnlyFamilyDetails,
    });
  }
  handleEditBank() {
    this.setState({
      bankDetailsCog: !this.state.bankDetailsCog,
      readOnlyBankDetails: !this.state.readOnlyBankDetails,
    });
  }
  handleChange(e, checkName) {
    console.log(checkName);
    const newState = {};
    newState[checkName] = !this.state[checkName];
    this.setState({ ...this.state, ...newState });
  }

  // Handle text change
  handleKeyChange(e, name) {
    console.log(name);
    let value;
    if (name === "vehicle_model") {
      value = e;
      const newState = {};
      newState[name] = value;
      this.setState({ ...this.state, ...newState });
    } else if (name === "phonenumber") {
      //   console.log("here");
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        value = e.target.value;
        const newState = {};
        newState[name] = value;
        this.setState({ ...this.state, ...newState });
      }
    } else {
      value = e.target.value;
      const newState = {};
      newState[name] = value;
      this.setState({ ...this.state, ...newState });
    }
    // console.log(value);
  }
  toggleExists() {
    this.setState({
      notExist: !this.state.notExist,
    });
  }
  imagehandler(data, name) {
    // console.log("imageeeeeeeeeeeeeee")
    console.log(data);
    if (data === undefined || data === null || data === "") {
      // Do nothing
    } else {
      const newState = {};
      newState[name] = data;
      this.setState({ ...this.state, ...newState }, () => {
        this.callImagehandler(name, data);
      });
    }
  }

  toggleWarn() {
    this.setState({
      consumerWarn: !this.state.consumerWarn,
    });
  }
  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    } else {
      if (this.props.history.location.state) {
        console.log(this.props.history.location.state.phonenumber);
        this.setState(
          {
            searchphonenumber: this.props.history.location.state.phonenumber,
          },
          () => {
            this.searchDriver();
          }
        );
      } else {
      }
    }
  }
  render() {
    return (
      <Container fluid className="main-content-container px-md-4 ">
        <Row noGutters className="page-header py-4 px-3">
          <Col className="col-12">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Driver accounts
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active />
            </Breadcrumb>
          </Col>
          <Col className="col-12">
            <PageTitle
              title="Edit driver"
              className="text-sm-left mb-3 p-0 custom-margin"
            />
          </Col>
          {/* <PageTitle
            title="Edit driver"
            subtitle="Driver Accounts"
            className="text-sm-left mb-3"
          /> */}
        </Row>
        <Row className="mb-4 px-3">
          <Col className="col-12 col-md-12 col-lg-12">
            <Form onSubmit={this.searchDriver}>
              <Row>
                <FormGroup className="col-md-3">
                  <FormInput
                    onChange={(e) =>
                      this.handleKeyChange(e, "searchphonenumber")
                    }
                    value={this.state.searchphonenumber}
                    placeholder="Enter driver's phonenumber here"
                    maxLength="10"
                    minLength="10"
                    required
                  />
                </FormGroup>
                <FormGroup className="col-md-3 ">
                  <Button>SEARCH</Button>
                </FormGroup>
              </Row>
            </Form>
          </Col>
        </Row>
        <Modal open={this.state.notExist}>
          <ModalHeader>On-boarding confirmation</ModalHeader>
          <ModalBody>
            Driver does not exist. Do you want to on-board the driver?
          </ModalBody>
          <ModalFooter>
            <Button theme="danger" onClick={this.toggleExists}>
              CANCEL
            </Button>
            <Button onClick={this.onboarddriver}>ONBOARD DRIVER</Button>
          </ModalFooter>
        </Modal>
        <Modal open={this.state.consumerWarn}>
          <ModalHeader>Error</ModalHeader>
          <ModalBody> This phone number is registered as a rider</ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleWarn}>Close</Button>
          </ModalFooter>
        </Modal>
        {this.state.visible === true && (
          <React.Fragment>
            <Row className="px-3 mb-3">
              <Col className="col-12 col-md-12 col-lg-12">
                <div className="float-right mt-2">
                  <Button className="" id="goback-button" onClick={this.goBack}>
                    <i className="fas fa-arrow-left mx-1" />
                    <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                      BACK
                    </span>
                  </Button>
                </div>
              </Col>
            </Row>
            <Row className="px-3">
              <Col className="col-12 col-md-4 col-lg-4 mb-4">
                <Card small>
                  <CardHeader className="ml-auto">
                    <Row>
                      <Col className="col-md-12 col-lg-12">
                        {this.state.displayProfCog === true && (
                          <div className="ml-auto">
                            <i
                              className="fas fa-cog pointer"
                              style={{ color: "blue" }}
                              onClick={this.handleEdit}
                            />
                          </div>
                        )}

                        {this.state.displayProfCog === false && (
                          <div>
                            <i
                              className="far fa-check-circle pointer px-1"
                              style={{ color: "#59E67F" }}
                              onClick={this.handleImagedata}
                            />
                            <i
                              className="fas fa-ban pointer px-1"
                              style={{ color: "#FF0000" }}
                              onClick={this.handleEdit}
                            />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      {this.state.displayProfCog === true && (
                        <Col className="mb-3 col-4 col-md-4 col-lg-4 mx-auto d-flex justify-content-center flex-wrap px-0">
                          <img
                            className="rounded-circle mx-auto d-block perfect-round"
                            src={this.state.driver_profile}
                            alt="profile"
                          />
                        </Col>
                      )}
                      {this.state.displayProfCog === false && (
                        <Col className="mb-3 col-12 col-md-12 col-lg-12 d-flex justify-content-center flex-wrap px-0">
                          <DragAndDrop
                            id="driver-profile-image"
                            variation="1"
                            label="Profile photo"
                            name="driver_profile"
                            onDropHandler={this.handleImage}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col className="col-12 col-md-12 col-lg-12">
                        <h5 className="center-text">
                          {this.state.first_name + " " + this.state.last_name}
                        </h5>
                      </Col>
                      <Col className="col-12 col-md-12 col-lg-12">
                        <h6 className="center-text">
                          {this.state.vehicle_type}
                        </h6>
                      </Col>
                      <Col className="col-12 col-md-12 col-lg-12">
                        <span className="text-muted mx-auto d-flex justify-content-center flex-wrap mb-2">
                          <i class="fas fa-star"> {this.state.avg_ratings}</i>
                        </span>
                      </Col>
                      <Col className="col-12 col-md-12 col-lg-12">
                        <span className="text-muted mx-auto d-flex justify-content-center flex-wrap mb-2">
                          <h6>Account status : {this.state.login_status}</h6>
                        </span>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardHeader />
                </Card>
              </Col>
              <Col className="col-lg-8">
                <Card>
                  <CardHeader>
                    <Row>
                      <label style={{ fontWeight: "700", fontSize: "1.1rem" }}>
                        <u>DRIVER DETAILS</u>
                      </label>
                      {this.state.driverDetails === true && (
                        <div className="ml-auto px-3">
                          <i
                            className="fas fa-cog pointer"
                            style={{ color: "blue" }}
                            onClick={this.handleEditDetails}
                          />
                        </div>
                      )}
                      {this.state.driverDetails === false && (
                        <div className="ml-auto px-2">
                          <i
                            className="far fa-check-circle pointer px-1"
                            style={{ color: "#59E67F" }}
                            onClick={this.handleProfileDetails}
                          />
                          <i
                            className="fas fa-ban pointer px-1"
                            style={{ color: "#FF0000" }}
                            onClick={this.handleEditDetails}
                          />
                        </div>
                      )}
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      {this.props.inputValues.map((stats, idx) => (
                        <Col
                          className="col-12 col-md-6 col-lg-6 mb-4"
                          key={idx}
                          {...stats.attrs}
                        >
                          {/* {console.log(this.state.hits)} */}
                          <InputDataForm
                            id={`edit-driver-details-${idx}`}
                            variation="1"
                            label={stats.label}
                            value={this.state[stats.name]}
                            stats={stats.stats}
                            name={stats.name}
                            onKeyChange={this.handleKeyChange}
                            readOnly={this.state.readOnly}
                          />
                        </Col>
                      ))}
                      {this.props.addressField.map((stats, idx) => (
                        <Col
                          className="col-12 col-md-6 col-lg-6 "
                          key={idx}
                          {...stats.attrs}
                        >
                          {/* {console.log(this.state.hits)} */}
                          <InputDataForm
                            id={`edit-address-details-${idx}`}
                            variation="1"
                            label={stats.label}
                            value={this.state[stats.name]}
                            stats={stats.stats}
                            name={stats.name}
                            onKeyChange={this.handleKeyChange}
                            readOnly={this.state.readOnly}
                          />
                        </Col>
                      ))}
                    </Row>
                  </CardBody>
                  <CardFooter />
                </Card>
              </Col>
            </Row>
            <Row className="px-3">
              <Col className="col-12 col-md-12 col-lg-12">
                <Card className="mt-4">
                  <CardHeader>
                    <Row className="px-3">
                      <label style={{ fontWeight: "700", fontSize: "1.1rem" }}>
                        <u>VEHICLE DETAILS</u>
                      </label>
                      {this.state.vehicleDetailsCog === true && (
                        <div className="ml-auto px-4">
                          <i
                            className="fas fa-cog pointer"
                            style={{ color: "blue" }}
                            onClick={this.handleEditVehicle}
                          />
                        </div>
                      )}

                      {this.state.vehicleDetailsCog === false && (
                        <div className="ml-auto px-4">
                          <i
                            className="far fa-check-circle pointer px-1"
                            style={{ color: "#59E67F" }}
                            onClick={this.handleVehicleDetails}
                          />
                          <i
                            className="fas fa-ban pointer px-1"
                            style={{ color: "#FF0000" }}
                            onClick={this.handleEditVehicle}
                          />
                        </div>
                      )}
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <Row>
                      {this.props.vehicleinputValues.map((stats, idx) => (
                        <Col
                          className="col-12 col-md-4 col-lg-4 mb-4"
                          key={idx}
                          {...stats.attrs}
                        >
                          {/* {console.log(this.state.hits)} */}
                          <InputDataForm
                            id={`edit-vehicle-details-${idx}`}
                            variation="1"
                            label={stats.label}
                            value={this.state[stats.name]}
                            stats={stats.stats}
                            name={stats.name}
                            onKeyChange={this.handleKeyChange}
                            readOnly={this.state.readOnlyVehicle}
                          />
                        </Col>
                      ))}
                    </Row>
                    <Row className="mt-4">
                      {this.props.vehicleimagesProp.map((stats, idx) => (
                        <Col
                          className="col-12 col-md-3 col-lg-3 mb-4"
                          key={idx}
                          {...stats.attrs}
                        >
                          <DivRender
                            id={`vehicle-images-${idx}`}
                            variation="1"
                            label={stats.label}
                            name={stats.name}
                            value={this.state[stats.name]}
                            onChangeHandler={this.imagehandler}
                            onClickPush={this.callImagehandler}
                          />
                        </Col>
                      ))}
                    </Row>
                  </CardBody>
                  <CardHeader />
                </Card>
              </Col>
            </Row>
            <Row className="mt-4 mb-4 px-3">
              <Col className="col-12 col-md-12 col-lg-12">
                <Card>
                  <CardHeader>
                    <Nav tabs>
                      {this.props.navItems.map((stats, idx) => (
                        <NavItem className="d-flex flex-wrap" key={idx}>
                          <EditDrivatab
                            id={idx}
                            variation="1"
                            label={stats.label}
                            state={this.state}
                            onClickHandler={this.handleNavClick}
                            name={stats.name}
                            attr={
                              this.state.index === idx
                                ? "active nav-item-style Navlink-style"
                                : "nav-item-style Navlink-style"
                            }
                          />
                        </NavItem>
                      ))}
                    </Nav>
                  </CardHeader>
                  <CardBody>
                    {this.state.divName === "id_proofs" && (
                      <div>
                        <Row>
                          {this.props.idProofs.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-3 col-lg-3"
                              key={idx}
                              {...stats.attrs}
                            >
                              <DivRender
                                id={`idproofs-images-${idx}`}
                                variation="1"
                                label={stats.label}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onChangeHandler={this.imagehandler}
                                onClickPush={this.callImagehandler}
                              />
                            </Col>
                          ))}
                        </Row>
                        <hr />
                        <Row>
                          {this.props.liscenseProps.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-3 col-lg-3 "
                              key={idx}
                              {...stats.attrs}
                            >
                              <DivRender
                                id={`license-images-${idx}`}
                                variation="1"
                                label={stats.label}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onChangeHandler={this.imagehandler}
                                onClickPush={this.callImagehandler}
                              />
                            </Col>
                          ))}
                        </Row>
                        <hr />
                        <Row>
                          {this.props.roadProps.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-3 col-lg-3"
                              key={idx}
                              {...stats.attrs}
                            >
                              <DivRender
                                id={`road-images-${idx}`}
                                variation="1"
                                label={stats.label}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onChangeHandler={this.imagehandler}
                                onClickPush={this.callImagehandler}
                              />
                            </Col>
                          ))}
                        </Row>
                        <hr />
                        <Row>
                          {this.props.agreePC.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-3 col-lg-3 "
                              key={idx}
                              {...stats.attrs}
                            >
                              <DivRender
                                id={`agreement-images-${idx}`}
                                variation="1"
                                label={stats.label}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onChangeHandler={this.imagehandler}
                                onClickPush={this.callImagehandler}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                    {this.state.divName === "ride_preferences" && (
                      <div>
                        {this.state.ridePrefCog === true && (
                          <div className="ml-auto px-4 float-right">
                            <i
                              className="fas fa-cog pointer"
                              style={{ color: "blue" }}
                              onClick={this.handleEditRidepref}
                            />
                          </div>
                        )}

                        {this.state.ridePrefCog === false && (
                          <div className="ml-auto px-4 float-right">
                            <i
                              className="far fa-check-circle pointer px-1"
                              style={{ color: "#59E67F" }}
                              onClick={this.handleRideprefDetails}
                            />
                            <i
                              className="fas fa-ban pointer px-1"
                              style={{ color: "#FF0000" }}
                              onClick={this.handleEditRidepref}
                            />
                          </div>
                        )}
                        <Row>
                          <Col className="col-12 col-md-6 col-lg-6 ">
                            <h6 className="label-style">
                              Preferred ride types
                            </h6>
                            <Row>
                              <Col className="col-12 col-md-4">
                                <FormCheckbox
                                  disabled={this.state.readonlyCheckbox}
                                  checked={this.state.outstation}
                                  onChange={(e) =>
                                    this.handleChange(e, "outstation")
                                  }
                                  className=" mt-2 "
                                >
                                  Outstation
                                </FormCheckbox>
                              </Col>
                              <Col className="col-12 col-md-4">
                                <FormCheckbox
                                  disabled={this.state.readonlyCheckbox}
                                  checked={this.state.rentals}
                                  onChange={(e) =>
                                    this.handleChange(e, "rentals")
                                  }
                                  className=" mt-2"
                                >
                                  Rentals
                                </FormCheckbox>
                              </Col>
                              <Col className="col-12 col-md-4">
                                <FormCheckbox
                                  disabled={this.state.readonlyCheckbox}
                                  checked={this.state.airport}
                                  onChange={(e) =>
                                    this.handleChange(e, "airport")
                                  }
                                  className=" mt-2"
                                >
                                  Airport
                                </FormCheckbox>
                              </Col>
                              <Col className="col-12 col-md-4">
                                <FormCheckbox
                                  disabled={this.state.readonlyCheckbox}
                                  checked={this.state.urban}
                                  onChange={(e) =>
                                    this.handleChange(e, "urban")
                                  }
                                  className=" mt-2"
                                >
                                  Urban
                                </FormCheckbox>
                              </Col>
                              <Col className="col-12 col-md-4">
                                <FormCheckbox
                                  disabled={this.state.readonlyCheckbox}
                                  checked={this.state.recurride}
                                  onChange={(e) =>
                                    this.handleChange(e, "recurride")
                                  }
                                  className=" mt-2"
                                >
                                  Recurride
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                          <Col className="col-12 col-md-6 col-lg-6 ">
                            <h6 className="label-style">Preferred shift</h6>
                            <Row>
                              <Col className="col-12 col-md-4">
                                <FormCheckbox
                                  disabled={this.state.readonlyCheckbox}
                                  checked={this.state.day_shift}
                                  onChange={(e) =>
                                    this.handleChange(e, "day_shift")
                                  }
                                  className=" mt-2"
                                >
                                  Day shift(8am-8pm)
                                </FormCheckbox>
                              </Col>
                              <Col className="col-12 col-md-4">
                                <FormCheckbox
                                  disabled={this.state.readonlyCheckbox}
                                  checked={this.state.night_shift}
                                  onChange={(e) =>
                                    this.handleChange(e, "night_shift")
                                  }
                                  className=" mt-2"
                                >
                                  Night shift(8am-8pm)
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col className="col-12 col-md-4 col-lg-4 ">
                            <label>Operating region</label>
                            <FormSelect
                              disabled={this.state.readonlyCheckbox}
                              style={{ height: "calc(3.16rem + 2px)" }}
                              value={this.state.operating_region}
                              onChange={this.handleSelectChange}
                            >
                              <option value="Bangalore">Bangalore</option>
                              <option value="Hyderabad">Hyderabad</option>
                            </FormSelect>
                          </Col>
                          {this.props.driverPreference.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-4 col-lg-4"
                              key={idx}
                              {...stats.attrs}
                            >
                              {/* {console.log(this.state.hits)} */}
                              <InputDataForm
                                id={`driver-preference-${idx}`}
                                variation="1"
                                label={stats.label}
                                stats={stats.stats}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onKeyChange={this.handleKeyChange}
                                readOnly={this.state.readonlyCheckbox}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                    {this.state.divName === "family_details" && (
                      <div>
                        <Row className="px-3">
                          <label
                            style={{
                              fontWeight: "700",
                              fontSize: "1.1rem",
                            }}
                          >
                            <u>FAMILY DETAILS</u>
                          </label>
                          {this.state.familyDetailsCog === true && (
                            <div className="ml-auto px-4 float-right">
                              <i
                                className="fas fa-cog pointer"
                                style={{ color: "blue" }}
                                onClick={this.handleEditFamily}
                              />
                            </div>
                          )}

                          {this.state.familyDetailsCog === false && (
                            <div className="ml-auto px-4 float-right">
                              <i
                                className="far fa-check-circle pointer"
                                style={{ color: "#59E67F" }}
                                onClick={this.handleFamilyDetails}
                              />
                              <i
                                className="fas fa-ban pointer"
                                style={{ color: "#FF0000" }}
                                onClick={this.handleEditFamily}
                              />
                            </div>
                          )}
                        </Row>

                        <Row className="mb-4">
                          <Col className="col-12">
                            <h6>Family member - 1</h6>
                          </Col>

                          {this.props.familyInputData_1.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-3 col-lg-3 mb-4"
                              key={idx}
                              {...stats.attrs}
                            >
                              {/* {console.log(this.state.hits)} */}
                              <InputDataForm
                                id={`family-details-1-${idx}`}
                                variation="1"
                                label={stats.label}
                                stats={stats.stats}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onKeyChange={this.handleKeyChange}
                                readOnly={this.state.readOnlyFamilyDetails}
                              />
                            </Col>
                          ))}
                          <Col className="col-3" />
                          {this.props.familyAddressField_1.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-6 col-lg-6 mb-4"
                              key={idx}
                              {...stats.attrs}
                            >
                              {/* {console.log(this.state.hits)} */}
                              <TextArea
                                id={`smaller-stats-${idx}`}
                                variation="1"
                                label={stats.label}
                                stats={stats.stats}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onKeyChange={this.handleKeyChange}
                                readOnly={this.state.readOnlyFamilyDetails}
                              />
                            </Col>
                          ))}
                        </Row>
                        <Row>
                          <Col className="col-12">
                            <h6>Family member - 2</h6>
                          </Col>

                          {this.props.familyInputData_2.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-3 col-lg-3 mb-4"
                              key={idx}
                              {...stats.attrs}
                            >
                              {/* {console.log(this.state.hits)} */}
                              <InputDataForm
                                id={`smaller-stats-${idx}`}
                                variation="1"
                                label={stats.label}
                                stats={stats.stats}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onKeyChange={this.handleKeyChange}
                                readOnly={this.state.readOnlyFamilyDetails}
                              />
                            </Col>
                          ))}
                          <Col className="col-3" />
                          {this.props.familyAddressField_2.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-6 col-lg-6 mb-4"
                              key={idx}
                              {...stats.attrs}
                            >
                              {/* {console.log(this.state.hits)} */}
                              <TextArea
                                id={`smaller-stats-${idx}`}
                                variation="1"
                                label={stats.label}
                                stats={stats.stats}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onKeyChange={this.handleKeyChange}
                                readOnly={this.state.readOnlyFamilyDetails}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                    {this.state.divName === "bank_account_details" && (
                      <div>
                        <Row className="px-3">
                          <label
                            style={{
                              fontWeight: "700",
                              fontSize: "1.1rem",
                            }}
                          >
                            <u>BANK DETAILS</u>
                          </label>
                          {/* <Col className="col-12 col-md-12 col-lg-12 mb-4"> */}
                          {this.state.bankDetailsCog === true && (
                            <div className="ml-auto px-4 float-right">
                              <i
                                className="fas fa-cog pointer"
                                style={{ color: "blue" }}
                                onClick={this.handleEditBank}
                              />
                            </div>
                          )}

                          {this.state.bankDetailsCog === false && (
                            <div className="ml-auto px-4 float-right">
                              <i
                                className="far fa-check-circle pointer px-1"
                                style={{ color: "#59E67F" }}
                                onClick={this.handleBankDetails}
                              />
                              <i
                                className="fas fa-ban pointer px-1"
                                style={{ color: "#FF0000" }}
                                onClick={this.handleEditBank}
                              />
                            </div>
                          )}
                          {/* </Col> */}
                        </Row>
                        <Row>
                          <Col className="col-12 col-md-12 ">
                            {/* <Card>
                              <CardBody> */}

                            <Row>
                              {this.props.AccinputValues.map((stats, idx) => (
                                <Col
                                  className="col-12 col-md-4 mb-4"
                                  key={idx}
                                  {...stats.attrs}
                                >
                                  {/* {console.log(this.state.hits)} */}
                                  <InputDataForm
                                    id={`smaller-stats-${idx}`}
                                    variation="1"
                                    label={stats.label}
                                    stats={stats.stats}
                                    value={this.state[stats.name]}
                                    name={stats.name}
                                    onKeyChange={this.handleKeyChange}
                                    readOnly={this.state.readOnlyBankDetails}
                                  />
                                </Col>
                              ))}
                            </Row>
                            {/* </CardBody>
                            </Card> */}
                          </Col>
                        </Row>
                      </div>
                    )}
                    {this.state.divName === "driver_form" && (
                      <div>
                        <Row>
                          {this.props.driverForm.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-3 col-lg-3 "
                              key={idx}
                              {...stats.attrs}
                            >
                              <DivRender
                                id={`driverForm-images-${idx}`}
                                variation="1"
                                label={stats.label}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onChangeHandler={this.imagehandler}
                                onClickPush={this.callImagehandler}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                  </CardBody>
                  <CardFooter />
                </Card>
              </Col>
            </Row>
            <Row className="mb-4 px-3">
              <Col className="col-12 col-md-6 col-lg-6">
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-12 col-md-6 col-lg-6">
                        <label
                          style={{
                            fontWeight: "700",
                            fontSize: "1.1rem",
                          }}
                        >
                          <u>REGISTERED BY</u>
                        </label>
                        <div>
                          {this.state.registeredBy +
                            " " +
                            this.state.registeredDate}
                        </div>
                      </Col>
                      <Col className="col-12 col-md-6 col-lg-6">
                        <label
                          style={{
                            fontWeight: "700",
                            fontSize: "1.1rem",
                          }}
                        >
                          <u>LAST UPDATED BY</u>
                        </label>
                        <div>
                          {this.state.lastEditedBy +
                            " " +
                            this.state.lastEditedDate}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Container>
    );
  }
}
EditDriver.propTypes = {
  inputValues: PropTypes.array,
  vehicleimagesProp: PropTypes.array,
};

EditDriver.defaultProps = {
  vehicleinputValues: [
    {
      label: "Vehicle name",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_name",
    },
    {
      label: "Vehicle number",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_number",
    },
    {
      label: "Vehicle make",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_make",
    },
    {
      label: "Vehicle model",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_model",
    },
    {
      label: "Vehicle Color",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_color",
    },
  ],
  inputValues: [
    {
      label: "First name",
      value: "",
      attrs: {},
      stats: false,
      name: "first_name",
    },
    {
      label: "Last name",
      value: "",
      attrs: {},
      stats: false,
      name: "last_name",
    },
    {
      label: "Email",
      value: "",
      attrs: {},
      name: "email",
    },
    {
      label: "Phone number",
      value: "",
      attrs: {},
      name: "phonenumber",
    },
    {
      label: "Alternate phone number",
      value: "",
      attrs: {},
      name: "alt_phonenumber",
    },
  ],
  addressField: [
    {
      label: "Current address",
      value: "",
      attrs: {},
      stats: false,
      name: "current_address",
    },
    {
      label: "Permanent address",
      value: "",
      attrs: {},
      stats: false,
      name: "permanent_address",
    },
  ],
  familyInputData_1: [
    {
      label: "Name",
      value: "",
      attrs: {},
      stats: false,
      name: "family_name_1",
    },
    {
      label: "Phone number",
      value: "",
      attrs: {},
      stats: false,
      name: "family_phn_1",
    },
    {
      label: "Relationship",
      value: "",
      attrs: {},
      stats: false,
      name: "family_rel_1",
    },
  ],
  familyInputData_2: [
    {
      label: "Name",
      value: "",
      attrs: {},
      stats: false,
      name: "family_name_2",
    },
    {
      label: "Phone number",
      value: "",
      attrs: {},
      stats: false,
      name: "family_phn_2",
    },
    {
      label: "Relationship",
      value: "",
      attrs: {},
      stats: false,
      name: "family_rel_2",
    },
  ],
  familyAddressField_1: [
    {
      label: "Address",
      value: "",
      attrs: {},
      stats: false,
      name: "family_address_1",
    },
  ],
  familyAddressField_2: [
    {
      label: "Address",
      value: "",
      attrs: {},
      stats: false,
      name: "family_address_2",
    },
  ],
  driverPreference: [
    {
      label: "Preferred location",
      value: "",
      attrs: {},
      stats: false,
      name: "preferred_location",
    },
    {
      label: "Starting point",
      value: "",
      attrs: {},
      stats: false,
      name: "starting_point",
    },
  ],
  navItems: [
    {
      label: "ID proof",
      name: "id_proofs",
    },
    {
      label: "Ride preferences",
      name: "ride_preferences",
    },
    {
      label: "Family details",
      name: "family_details",
    },
    {
      label: "Bank account details",
      name: "bank_account_details",
    },
    {
      label: "Driver form",
      name: "driver_form",
    },
  ],

  AccinputValues: [
    {
      label: "Account holder name",
      value: "",
      attrs: {},
      stats: false,
      name: "acc_holder_name",
    },
    {
      label: "Phone number",
      value: "",
      attrs: {},
      stats: false,
      name: "acc_phonenumber",
    },
    {
      label: "IFSC",
      value: "",
      attrs: {},
      stats: false,
      name: "ifsc",
    },
    {
      label: "Account number",
      value: "",
      attrs: {},
      stats: false,
      name: "acc_number",
    },
    {
      label: "Email",
      value: "",
      attrs: {},
      stats: false,
      name: "acc_email",
    },
    {
      label: "Address",
      value: "",
      attrs: {},
      stats: false,
      name: "acc_address",
    },
  ],
  vehicleimagesProp: [
    {
      label: "Vehicle front",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_front_image",
    },
    {
      label: "Vehicle rear",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_back_image",
    },
    {
      label: "Vehicel left",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_left_image",
    },
    {
      label: "Vehicle right",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_right_image",
    },
    {
      label: "With driver",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_with_driver",
    },
  ],
  idProofs: [
    {
      label: "Aadhar card",
      value: "",
      attrs: {},
      stats: false,
      name: "aadhar_card_image_front",
    },
    {
      label: "PAN card",
      value: "",
      attrs: {},
      stats: false,
      name: "pan_card_image",
    },
  ],
  agreePC: [
    {
      label: "Sales agreement",
      value: "",
      attrs: {},
      stats: false,
      name: "sales_agreement",
    },
    {
      label: "Police verification",
      value: "",
      attrs: {},
      stats: false,
      name: "police_verification",
    },
  ],
  liscenseProps: [
    {
      label: "Driving license",
      value: "",
      attrs: {},
      stats: false,
      name: "driving_license_image",
    },
    {
      label: "Reg. Certificate",
      value: "",
      attrs: {},
      stats: false,
      name: "rc_image",
    },
    {
      label: "Vehicle insurance",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_insurance_image",
    },
    {
      label: "Driver badge(Opt)",
      value: "",
      attrs: {},
      stats: false,
      name: "driver_badgee_image",
    },
  ],
  roadProps: [
    {
      label: "Emission certificate",
      value: "",
      attrs: {},
      stats: false,
      name: "emission_cert_image",
    },
    {
      label: "Road tax",
      value: "",
      attrs: {},
      stats: false,
      name: "road_tax_image",
    },
    {
      label: "Permit",
      value: "",
      attrs: {},
      stats: false,
      name: "permit_image",
    },
    {
      label: "Fitness certificate",
      value: "",
      attrs: {},
      stats: false,
      name: "fitness_image",
    },
  ],
  driverForm: [
    {
      label: "Front page",
      value: "",
      attrs: {},
      stats: false,
      name: "driverformImageFront",
    },
    {
      label: "Back page",
      value: "",
      attrs: {},
      stats: false,
      name: "driverformImageBack",
    },
  ],
};
export default EditDriver;
