import React, { useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import axios from 'axios';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { InputAdornment } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { FormGroup } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import { Row, Col, Container, Card, Modal } from 'react-bootstrap';
import _ from 'lodash';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import usePrevious from '../utils/usePrevious';

import * as config from '../constants/constants.json';
import * as stringsData from '../constants/stringsConstant.json';

const timeSlots = [
  '12 AM to 06 AM',
  '06 AM to 11 AM',
  '11 AM to 04 PM',
  '04 PM to 09 PM',
  '09 PM to 12:00 AM'
];
const distanceSlabsToAirport = [
  '0 km to 20 km',
  '20 km to 25 km',
  '25 km to 30 km',
  '30 km to 35 km',
  '35 km to 40 km',
  '40 km to 45 km',
  '45 km to 50 km'
];
const distanceSlabsFromAirport = [
  '0 km to 10 km',
  '10 km to 20 km',
  '20 km to 30 km',
  '30 km to 35 km',
  '35 km to 40 km',
  '40 km to 45 km'
];

const suvDistance = ['0 km to 45 km'];

function getMinHour(index, data) {
  let maindata = data[index];
  let splitData = maindata.split('to');
  let minimim_hour = moment(splitData[0], 'hh A').format('HH');
  return minimim_hour * 1;
}

function getMaxHour(index, data) {
  let maindata = data[index];
  let splitData = maindata.split('to');
  let maximum_hour = moment(splitData[1], 'hh A').format('HH');
  return maximum_hour * 1;
}
function clean(str) {
  return str.replace(/[^0-9 ]/g, '').replace(/ +/, '');
}

function getMinKm(index, data) {
  let maindata = data[index];
  // console.log(data);
  //  (maindata);
  let splitData = maindata.split('to');
  let finalMinKm = clean(splitData[0]);

  //   let minimim_hour = moment(splitData[0], "hh A").format("HH");
  //   return minimim_hour;
  return finalMinKm * 1;
}

function getMaxKm(index, data) {
  let maindata = data[index];
  // console.log(data);
  // console.log(maindata);
  let splitData = maindata.split('to');
  let finalMaxKm = clean(splitData[1]);

  //   let minimim_hour = moment(splitData[0], "hh A").format("HH");
  //   return minimim_hour;
  return finalMaxKm * 1;
}

export default function AnalyticsCard(props) {
  const clickPreviousTime = e => {
    // console.log(e);
    let count = timeSlotCount;
    if (count === 0) {
    } else {
      count = count - 1;
    }
    setTimeSlotCount(count);
  };

  const clickNextTime = e => {
    // console.log(e);
    let count = timeSlotCount;
    let data = timeSlots;
    // alert("hello");
    // console.log(count);
    // console.log(data.length);
    if (count === data.length - 1) {
    } else {
      count = count + 1;
    }
    setTimeSlotCount(count);
  };

  const clickPreviousSlab = e => {
    if (props.valuePath === 'to_airport') {
      let count = distanceSlotCount;
      if (count === 0) {
      } else {
        count = count - 1;
      }
      setDistanceSlotCount(count);
    } else {
      let count = distanceSlotFromCount;
      if (count === 0) {
      } else {
        count = count - 1;
      }
      setDistanceSlotFromCount(count);
    }
  };

  const clickNextSlab = e => {
    if (props.valuePath === 'to_airport') {
      let count = distanceSlotCount;
      let data = distanceSlabsToAirport;
      // alert("hello");
      if (count === data.length - 1) {
      } else {
        count = count + 1;
      }
      setDistanceSlotCount(count);
    } else {
      let count = distanceSlotFromCount;
      let data = distanceSlabsFromAirport;
      // alert("hello");
      if (count === data.length - 1) {
      } else {
        count = count + 1;
      }
      setDistanceSlotFromCount(count);
    }
  };

  const getRIdeCountData = () => {
    let dataToSend;
    // console.log(dateRange);
    if (props.type === 'past') {
      if (props.vehicleType === 'suv') {
        dataToSend = {
          log_type: props.type,
          vehicle_type: props.vehicleType,
          travel_type: props.valuePath,
          ride_type: props.rideType,
          fare_type: props.fareType,
          minimum_km: 0,
          maximum_km: 45,
          minimum_hour: getMinHour(timeSlotCount, timeSlots),
          maximum_hour: getMaxHour(timeSlotCount, timeSlots),
          vendor_type: 'mmt',
          start_date: dateRange.length > 0 ? dateRange[0].getTime() : '',
          end_date: dateRange.length > 0 ? dateRange[1].getTime() : ''
        };
      } else {
        dataToSend = {
          log_type: props.type,
          vehicle_type: props.vehicleType,
          travel_type: props.valuePath,
          ride_type: props.rideType,
          fare_type: props.fareType,
          minimum_km:
            props.valuePath === 'to_airport'
              ? getMinKm(distanceSlotCount, distanceSlabsToAirport)
              : getMinKm(distanceSlotFromCount, distanceSlabsFromAirport),
          maximum_km:
            props.valuePath === 'to_airport'
              ? getMaxKm(distanceSlotCount, distanceSlabsToAirport)
              : getMaxKm(distanceSlotFromCount, distanceSlabsFromAirport),
          minimum_hour: getMinHour(timeSlotCount, timeSlots),
          maximum_hour: getMaxHour(timeSlotCount, timeSlots),
          vendor_type: 'mmt',
          start_date: dateRange.length > 0 ? dateRange[0].getTime() : '',
          end_date: dateRange.length > 0 ? dateRange[1].getTime() : ''
        };
      }
    } else {
      if (props.vehicleType === 'suv') {
        dataToSend = {
          log_type: props.type,
          vehicle_type: props.vehicleType,
          travel_type: props.valuePath,
          ride_type: props.rideType,
          fare_type: props.fareType,
          minimum_km: 0,
          maximum_km: 45,
          minimum_hour: getMinHour(timeSlotCount, timeSlots),
          maximum_hour: getMaxHour(timeSlotCount, timeSlots),
          vendor_type: 'mmt',
          start_date: props.start_date,
          end_date: props.end_date
        };
      } else {
        dataToSend = {
          log_type: props.type,
          vehicle_type: props.vehicleType,
          travel_type: props.valuePath,
          ride_type: props.rideType,
          fare_type: props.fareType,
          minimum_km:
            props.valuePath === 'to_airport'
              ? getMinKm(distanceSlotCount, distanceSlabsToAirport)
              : getMinKm(distanceSlotFromCount, distanceSlabsFromAirport),
          maximum_km:
            props.valuePath === 'to_airport'
              ? getMaxKm(distanceSlotCount, distanceSlabsToAirport)
              : getMaxKm(distanceSlotFromCount, distanceSlabsFromAirport),
          minimum_hour: getMinHour(timeSlotCount, timeSlots),
          maximum_hour: getMaxHour(timeSlotCount, timeSlots),
          vendor_type: 'mmt',
          start_date: props.start_date,
          end_date: props.end_date
        };
      }
    }

    console.log(dataToSend);

    axios
      .post(config.url + 'htmlcrm/getTripCountLog', dataToSend)
      .then(response => {
        console.log(response);
        if (response.data.message === 'successfull') {
          //   setGraphData(response.data.data);
          let myArray = _.sortBy(response.data.data, function(dateObj) {
            return new Date(dateObj.date);
          });
          let labelData = myArray.map(function(e) {
            return moment(e.date).format('MMM [-] DD');
          });
          let values = myArray.map(function(e) {
            return e.count;
          });
          let modifiedOn = response.data.modified_time;
          let updatedBy = response.data.updated_by;
          //   console.log(labelData);
          //   console.log(values);
          setModifiedBy(modifiedOn);
          setUpdatedBy(updatedBy);
          setGraphLabel(labelData);
          setGraphData(myArray);
          setGraph(values);
        } else {
          setGraphLabel([]);
          setGraphData([]);
          setGraph([]);
          setModifiedBy('-');
          setUpdatedBy('-');
        }
      })
      .catch();
  };

  const [timeSlotCount, setTimeSlotCount] = React.useState(0);
  const [distanceSlotCount, setDistanceSlotCount] = React.useState(0);
  const [distanceSlotFromCount, setDistanceSlotFromCount] = React.useState(0);
  const [graphData, setGraphData] = React.useState([]);
  const [graphLabel, setGraphLabel] = React.useState([]);
  const [graph, setGraph] = React.useState([]);
  const [dateRange, setDateRange] = React.useState([]);
  const [start_date, setStartDate] = React.useState();
  const [end_date, setEndDate] = React.useState();
  const [vehicleType, setVehicleType] = React.useState('sedan');
  const [rideType, setRideType] = React.useState('airport');
  const [valuePath, setValuePath] = React.useState('to_airport');
  const [modifiedBy, setModifiedBy] = React.useState('-');
  const [updatedBy, setUpdatedBy] = React.useState('-');
  const prevDateRangeProps = usePrevious(dateRange);
  const prevStartDateProps = usePrevious(start_date);
  const prevVehicleProps = usePrevious(vehicleType);
  const prevValuePathProps = usePrevious(valuePath);
  const prevRideProps = usePrevious(rideType);
  const prevTimeSlotProps = usePrevious(timeSlotCount);
  const prevDistanceSlotProps = usePrevious(distanceSlotCount);
  const prevDistanceSlotFromProps = usePrevious(distanceSlotFromCount);

  const data = canvas => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 510);
    gradient.addColorStop(0, 'rgba(108, 198, 255, 0.56)');
    gradient.addColorStop(1, 'rgba(108, 198, 255, 0)');

    return {
      labels: graphLabel,
      datasets: [
        {
          label: 'Trip count',
          data: graph,
          fill: 'start',
          backgroundColor: gradient,
          borderColor: 'rgba(108, 198, 255, 1)'
        }
      ]
    };
  };

  const options = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };

  const changeDate = date => {
    props.changeDate(date);
    setDateRange(date);
  };

  const changeVal = () => {
    props.resetValue();
  };

  useEffect(() => {
    setDateRange([new Date(props.start_date), new Date(props.end_date)]);
    setRideType(props.rideType);
    setValuePath(props.valuePath);
    setVehicleType(props.vehicleType);
    setStartDate(props.start_date);

    getRIdeCountData();
    // console.log(props.start_date);
    // console.log(props.end_date);
  }, []);

  useEffect(() => {
    // if (props.type === "past") {
    //   changeVal();
    // }
    console.log(vehicleType);
    console.log(prevVehicleProps);
    if (prevVehicleProps !== props.vehicleType && prevVehicleProps !== undefined) {
      console.log('**************************************');
      setGraphLabel([]);
      setGraphData([]);
      setGraph([]);
      getRIdeCountData();
    }
  }, [props.vehicleType]);

  // console.log(usePrevious(props.vehicleType));

  useEffect(() => {
    // if (props.type === "past") {
    //   changeVal();
    // }

    if (prevRideProps !== props.rideType && prevRideProps !== undefined) {
      setGraphLabel([]);
      setGraphData([]);
      setGraph([]);
      getRIdeCountData();
    }
  }, [props.rideType]);

  useEffect(() => {
    // if (props.type === "past") {
    //   changeVal();
    // }
    if (prevValuePathProps !== props.valuePath && prevValuePathProps !== undefined) {
      setGraphLabel([]);
      setGraphData([]);
      setGraph([]);
      getRIdeCountData();
    }
  }, [props.valuePath]);

  useEffect(() => {
    if (prevTimeSlotProps !== timeSlotCount && prevTimeSlotProps !== undefined) {
      getRIdeCountData();
    }
  }, [timeSlotCount]);

  useEffect(() => {
    if (prevDistanceSlotProps !== distanceSlotCount && prevDistanceSlotProps !== undefined) {
      getRIdeCountData();
    }
  }, [distanceSlotCount]);

  useEffect(() => {
    if (
      prevDistanceSlotFromProps !== distanceSlotFromCount &&
      prevDistanceSlotFromProps !== undefined
    ) {
      getRIdeCountData();
    }
  }, [distanceSlotFromCount]);

  useEffect(() => {
    if (prevStartDateProps !== props.start_date && prevStartDateProps !== undefined) {
      getRIdeCountData();
      setDateRange([new Date(props.start_date), new Date(props.end_date)]);
    }
  }, [props.start_date]);

  useEffect(() => {
    if (prevDateRangeProps !== dateRange && prevDateRangeProps !== undefined) {
      getRIdeCountData();
    }
  }, [dateRange]);

  return (
    <React.Fragment>
      {props.type === 'future' && (
        <Row className="mt-5 d-flex justify-content-between">
          <Col className="col-12 col-md-6">
            <FormGroup>
              <label className="px-3">Last modified on</label>
              <h5 className="px-3" style={{ fontWeight: 700 }}>
                {modifiedBy}
              </h5>
            </FormGroup>
          </Col>
          <Col className="col-12 col-md-4">
            <FormGroup>
              <label className="px-3">Last modified by</label>
              <h5 className="px-3" style={{ fontWeight: 700 }}>
                {updatedBy}
              </h5>
            </FormGroup>
          </Col>
        </Row>
      )}

      <Row className="mt-4 px-3">
        <Col className="col-12 col-md-12 my-3">
          <Card className="mb-3">
            <Card.Body>
              <Row className="d-flex justify-content-around ">
                <Col className="col-12 col-md-3 mb-3 px-0 mt-md-4 ml-md-4">
                  <FormGroup>
                    <label className="">Time slot</label>
                    <OutlinedInput
                      value={timeSlots[timeSlotCount]}
                      inputProps={{ style: { textAlign: 'center' } }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton onClick={e => clickPreviousTime(e)}>
                            <NavigateBeforeIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={e => clickNextTime(e)}>
                            <NavigateNextIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormGroup>
                </Col>

                <Col className="col-12 col-md-3 mb-3 px-0 mt-md-4 ml-md-4">
                  <FormGroup>
                    <label className="">Distance slab</label>
                    {(props.vehicleType === 'sedan' || props.vehicleType === 'hatchback') && (
                      <OutlinedInput
                        value={
                          props.valuePath === 'to_airport'
                            ? distanceSlabsToAirport[distanceSlotCount]
                            : distanceSlabsFromAirport[distanceSlotFromCount]
                        }
                        inputProps={{ style: { textAlign: 'center' } }}
                        readOnly
                        startAdornment={
                          <InputAdornment position="start">
                            <IconButton onClick={e => clickPreviousSlab(e)}>
                              <NavigateBeforeIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={e => clickNextSlab(e)}>
                              <NavigateNextIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    )}
                    {props.vehicleType === 'suv' && (
                      <OutlinedInput
                        value={suvDistance}
                        inputProps={{ style: { textAlign: 'center' } }}
                        readOnly
                        startAdornment={
                          <InputAdornment position="start">
                            <IconButton>
                              <NavigateBeforeIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton>
                              <NavigateNextIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    )}
                  </FormGroup>
                </Col>
                {props.showRangePicker === 'false' && <Col className="col-12 col-md-3 mb-1"></Col>}
                {props.showRangePicker === 'true' && (
                  <Col className="col-12 col-md-3 mb-3 px-0 mt-md-4 ml-md-4">
                    <FormGroup>
                      <label className="">Time range</label>
                      <DateRangePicker
                        clearIcon={null}
                        className="form-control"
                        format="dd/MM/yyyy"
                        value={dateRange}
                        onChange={changeDate}
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col className="col-12 col-md-12 mb-3 mt-4">
                  <Line data={data} options={options} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
