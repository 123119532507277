import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from "shards-react";

// import AccountBox from "../components/common/AccountBox"
import PageTitle from "./../components/common/PageTitle";
import TableView from "../components/dataviews/TableView";

import * as config from "../constants/constants.json";

// const DriverStatus = ({ smallStats, bottomStats }) => (
//   <Container fluid className="main-content-container px-4">
//     <Row noGutters className="page-header py-4 px-3">
//       <PageTitle
//         title="Driver's List"
//         subtitle="Business accounts"
//         className="text-sm-left mb-3"
//       />
//     </Row>
//     <Row>
//       <Col className="col-12 col-md-12 col-lg-12">
//         <Card>
//           <CardBody>
//             <TableView />
//           </CardBody>
//         </Card>
//       </Col>
//     </Row>
//   </Container>
// );

class DriverStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      application_vercode: "",
    };
    this.goBack = this.goBack.bind(this);
    this.getAppversion = this.getAppversion.bind(this);
  }
  goBack() {
    this.props.history.goBack();
  }
  getAppversion() {
    fetch(config.url + "api/getAppVersionCode", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          console.log(result);
          this.setState({
            application_vercode: result.driver,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    } else {
      this.getAppversion();
    }
  }
  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4 px-md-3 mb-2">
          <Col className="col-12">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Business accounts
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active />
            </Breadcrumb>
          </Col>
          <Col className="col-12 mt-1">
            <PageTitle
              title="Drivers list"
              className="text-sm-left mb-3 p-0 custom-margin"
            />
          </Col>
        </Row>
        <Row className="px-md-3 mb-md-5">
          <Col className="col-12 col-md-12 col-lg-12 mb-3">
            Latest driver app version :{" "}
            <span style={{ color: "#20A4F3" }}>
              {this.state.application_vercode}
            </span>
          </Col>
        </Row>
        <Row className="px-md-3 mb-5">
          <Col className="col-12 col-md-12 col-lg-12 mb-3">
            <div className="float-right mt-2">
              <Button
                className="mb-3"
                id="goback-button"
                theme="dark"
                onClick={this.goBack}
              >
                <i className="fas fa-arrow-left mx-1" />
                <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                  BACK
                </span>
              </Button>
            </div>
          </Col>
          <Col className="col-12 col-md-12 col-lg-12 px-0">
            <Card>
              <CardBody>
                <TableView />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
DriverStatus.propTypes = {
  smallStats: PropTypes.array,
  bottomStats: PropTypes.array,
};

DriverStatus.defaultProps = {
  smallStats: [
    {
      label: "0",
      value: "MMT",
      attrs: { md: "3", sm: "6" },
      stats: false,
      to: "",
    },
    {
      label: "0",
      value: "Clear Trip",
      attrs: { md: "3", sm: "6" },
      stats: false,
    },
    {
      label: "0",
      value: "Zyppys",
      attrs: { md: "3", sm: "6" },
      stats: false,
    },
    {
      label: "0",
      value: "OYO",
      attrs: { md: "3", sm: "6" },
      stats: false,
    },
    {
      label: "0",
      value: "Yatra",
      attrs: { md: "3", sm: "6" },
      stats: false,
    },
    {
      label: "0",
      value: "Via",
      attrs: { md: "3", sm: "6" },
      stats: false,
    },
  ],
  bottomStats: [
    {
      label: "0",
      value: "Ride detachments",
      attrs: { md: "3", sm: "6" },
      stats: true,
    },
    {
      label: "0",
      value: "Driver status",
      attrs: { md: "3", sm: "4" },
      stats: true,
    },
  ],
};

export default DriverStatus;
