import React, { useState, useEffect } from "react";
import ReactPico from "../utils/pico/ReactPico";
import FaceIndicator from "./FaceIndicator";
import socketIOClient from "socket.io-client";

import axios from "axios";

import stringsConstant from "../constants/stringsConstant.json";
import config from "../constants/constants.json";
import urls from "../constants/urlList.json";

// const socket = socketIOClient(config.SOCKET_FACE, {
//   transports: ["websocket"],
// });

// import { FaceFinder } from "react-picojs";
/**
 * Import files and images
 */

// import "react-picojs/dist/index.css";

function checkDispatchRole(data) {
  switch (data) {
    case "traineeexecutive" ||
      "teamlead" ||
      "seniorexecutive" ||
      "juniorexecutive":
      setTimeout(() => {
        window.location.assign("/businessaccounts/bookings");
      }, 1500);
      break;
    default:
      break;
  }
}

function checkSupplyRole(data) {
  switch (data) {
    case "traineeexecutive" ||
      "teamlead" ||
      "seniorexecutive" ||
      "juniorexecutive":
      setTimeout(() => {
        window.location.assign("/driveraccounts");
      }, 1500);
      break;
    default:
      break;
  }
}

function FaceDetect(props) {
  const [face, setFace] = useState(null);
  // console.log("loaded");

  // useEffect(() => {
  //   // console.log(face);
  //   if (face !== null) {
  //     var d = new Date();
  //     var milliseconds = d.getTime();
  //     let data = {
  //       content: face.imageData,
  //       name: props.name,
  //       user_id: props.user_id,
  //       milliseconds: milliseconds,
  //       // name: "ravikiran",
  //       // user_id: "5d07363d8a332a0af0df7b59",
  //       type: "verify",
  //     };
  //     console.log(data);
  //     socket.emit("message", data);
  //   }
  // }, [face]);

  // useEffect(() => {
  //   socket.on("message", function(response) {
  //     // console.log(JSON.parse(response));
  //     let result = JSON.parse(response);
  //     if (result.status === "verified") {
  //       // console.log("here boy 1");
  //       loginUser();
  //     } else {
  //       // console.log("here boy 2");
  //     }
  //   });
  // }, []);

  /**
   * Functionality to login user to the panel directly without QR
   */

  const loginUser = () => {
    // console.log("here boy");
    let dataTovalidate = {
      email: props.email,
      password: props.password,
    };

    axios.post(config.url + urls.loginUser, dataTovalidate).then((response) => {
      // console.log(response);
      if (response.data.message === stringsConstant.onsuccess) {
        let result = response.data;
        sessionStorage.setItem("user_id", result.user_id);
        sessionStorage.setItem("username", result.username);
        sessionStorage.setItem("phonenumber", result.phonenumber);
        sessionStorage.setItem("usertype", result.usertype);
        sessionStorage.setItem("first_name", result.first_name);
        sessionStorage.setItem("last_name", result.last_name);
        sessionStorage.setItem("roletype", result.roletype);
        sessionStorage.setItem("shift", result.shift);
        sessionStorage.setItem("email", result.email);
        openWindow();
      }
    });
  };

  /**
   * Route users based on the role
   */

  // const openWindow = () => {
  //   setTimeout(function() {
  //     if (
  //       (window.sessionStorage.getItem("usertype") === "dispatchexecutive" &&
  //         window.sessionStorage.getItem("roletype") === "traineeexecutive") ||
  //       (window.sessionStorage.getItem("usertype") === "dispatchexecutive" &&
  //         window.sessionStorage.getItem("roletype") === "teamlead") ||
  //       (window.sessionStorage.getItem("usertype") === "dispatchexecutive" &&
  //         window.sessionStorage.getItem("roletype") === "seniorexecutive") ||
  //       (window.sessionStorage.getItem("usertype") === "dispatchexecutive" &&
  //         window.sessionStorage.getItem("roletype") === "juniorexecutive")
  //     ) {
  //       window.location.assign("/businessaccounts/bookings");
  //     } else if (
  //       (window.sessionStorage.getItem("usertype") === "supplyexecutive" &&
  //         window.sessionStorage.getItem("roletype") === "supplyexecutive") ||
  //       (window.sessionStorage.getItem("usertype") === "supplyexecutive" &&
  //         window.sessionStorage.getItem("roletype") === "traineeexecutive") ||
  //       (window.sessionStorage.getItem("usertype") === "supplyexecutive" &&
  //         window.sessionStorage.getItem("roletype") === "seniorexecutive") ||
  //       (window.sessionStorage.getItem("usertype") === "supplyexecutive" &&
  //         window.sessionStorage.getItem("roletype") === "seniorexecutive")
  //     ) {
  //       window.location.assign("/driveraccounts");
  //     } else {
  //       window.location.assign("/businessaccounts/bookings");
  //     }
  //   }, 1500);
  // };

  const openWindow = () => {
    switch (window.sessionStorage.getItem("usertype")) {
      case "dispatchexecutive":
        checkDispatchRole(window.sessionStorage.getItem("roletype"));
        break;
      case "supplyexecutive":
        checkSupplyRole(window.sessionStorage.getItem("roletype"));
        break;
      default:
        setTimeout(() => {
          window.location.assign("/businessaccounts/bookings");
        }, 1500);
        break;
    }
  };

  return (
    <React.Fragment>
      <div
        className="d-flex justify-content-center"
        style={{ height: "100vh" }}
      >
        <ReactPico
          height={500}
          width={500}
          onFaceFound={(face) => {
            setFace(face);
          }}
          style={{ position: "absolute", zIndex: "-1", display: "hidden" }}
        />
      </div>

      {/* {face && <FaceIndicator x={face.totalX} y={face.totalY} />} */}
    </React.Fragment>
  );
}

export default FaceDetect;
