import React from "react";
import {
  Container,
  Row,
  Col,
  Tooltip,
  Card,
  CardBody,
  CardHeader,
} from "shards-react";
import moment from "moment";
import ReactTable from "react-table-v6";

class LogsView extends React.Component {
  constructor(props) {
    super(props);
    this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
  }
  capitalizeFirstLetter(string) {
    console.log(string);
    return string[0].toUpperCase() + string.slice(1);
  }
  render() {
    let { data, trip_id, key } = this.props;
    console.log(trip_id);
    if (data.messagetype === "booking") {
      return (
        <div id={`book-req-${key}`}>
          <hr className="w-100" />
          <p>
            <b style={{ textDecoration: "underline" }}>BOOKING REQUEST</b>
          </p>
          <p>
            <b className="">Trip ID : </b> {data.data.trip_id}
          </p>
          <p>
            <b className="">Vendor name : </b>{" "}
            {this.capitalizeFirstLetter(data.data.requested_through)}
          </p>
          <p>
            <b className="">Pick-up time: </b>
            {moment(new Date(data.data.pickup_time)).format(
              "DD MM YYYY [at] hh:mm:ss A"
            )}
          </p>
          <p>
            <b className="">Pick-up : </b>
            {data.data.sname}
          </p>
          <p>
            <b className="">Drop-off : </b>
            {data.data.dname}
          </p>
          <p>
            <b className="">Passenger details :</b>
            {data.data.vendor_data.passenger_name +
              " " +
              data.data.vendor_data.passenger_phone_number +
              " " +
              data.data.vendor_data.passenger_email}
          </p>
          <hr className="w-100" />
        </div>
      );
    } else if (data.messagetype == "individualautoassign") {
      let tableID = new Date().getTime();
      const Columns = [
        {
          Header: "Name",
          accessor: "drivername",
        },
        {
          Header: "Vehicle number",
          accessor: "vehiclenumber",
          style: { whiteSpace: "unset" },
        },
        {
          Header: "Business accounts",
          accessor: "phonenumber",

          style: { whiteSpace: "unset" },
        },
        {
          Header: "Distance",
          accessor: "distance",

          style: { whiteSpace: "unset" },
        },
        {
          Header: "ETA",
          accessor: "eta",
        },
      ];
      return (
        <div>
          <p>
            <b style={{ textDecoration: "underline" }}>
              ASSIGNING RIDE ( {" " + data.timeStamp + " "} )
            </b>
          </p>
          <p>
            <b>Trip ID : </b>
            {" " + data.trip_id}
          </p>
          {data.data.length > 0 && (
            <Row className="d-flex justify-content-center">
              <Col className="col-12 col-md-6">
                <ReactTable
                  id={`tableid-${tableID}`}
                  className="text-center -striped -highlight"
                  data={data.data}
                  columns={Columns}
                  minRows={data.data.length > 0 ? 0 : 4}
                  noDataText="No data to display"
                  showPagination={false}
                />
              </Col>
            </Row>
          )}
          {data.data.length === 0 && (
            <div>
              <p>
                <b class="row-title"> No drivers found</b>
              </p>
            </div>
          )}
          <hr className="w-100" />
        </div>
      );
    } else if (data.messagetype == "autoassign") {
      let tableID = new Date().getTime();
      const autoColumns = [
        {
          Header: "Name",
          accessor: "drivername",
        },
        {
          Header: "Vehicle number",
          accessor: "vehiclenumber",
          style: { whiteSpace: "unset" },
        },
        {
          Header: "Business accounts",
          accessor: "phonenumber",

          style: { whiteSpace: "unset" },
        },
        {
          Header: "Distance",
          accessor: "distance",

          style: { whiteSpace: "unset" },
        },
        {
          Header: "ETA",
          accessor: "eta",
        },
        {
          Header: "Sent status",
          id: "sentStatus",
          Cell: (row) => {
            return !row.original.sentStatus ? "-" : row.original.sentStatus;
          },
        },
      ];
      let bucketDataRef = data.bucketDataRef;

      let miniBucket = bucketDataRef.miniBucket;

      let slabConfig = bucketDataRef.slabConfig;
      //Time slabs configuration parameters
      let slabname = slabConfig.slabname;
      slabname = "slab " + slabname.charAt(4);
      let leftMinutes = slabConfig.minutes;
      let minTime = slabConfig.min;
      if (minTime >= 60) {
        minTime = parseInt(minTime / 60);
      }
      let maxTime = slabConfig.max;
      if (maxTime >= 60) {
        maxTime = parseInt(maxTime / 60);
      }

      let timingsString = "";

      if (slabConfig.max < 60) {
        timingsString = maxTime + " - " + minTime + " minutes";
      } else {
        timingsString = maxTime + " - " + minTime + " hours";
      }

      let bucketconfig = bucketDataRef.bucketconfig;

      //Bucket configuration parameters
      let bucketName = bucketconfig.name;
      let bucketRadius = bucketconfig.radius;

      return (
        <div>
          <p>
            <b> ASSIGNING RIDE({" " + data.timeStamp + " "} )</b>
          </p>
          <p>
            <b>Trip ID : </b>
            {" " + trip_id + " "}
          </p>
          <p>
            <b style={{ textDecoration: "underline" }}>Info : </b>
          </p>
          <p>
            - Time {" " + slabname + " "} ({" " + timingsString + " "})
          </p>
          <p>- Radius {" " + bucketRadius + " "} meters)</p>
          <p>- Mini bucket {miniBucket}</p>
          {data.data.length > 0 && (
            <Row>
              <Col className="col-12 col-md-6">
                <ReactTable
                  id={`tableid-auto-${tableID}`}
                  className="text-center -striped -highlight"
                  data={data.data}
                  columns={autoColumns}
                  minRows={4}
                  noDataText="No data to display"
                />
              </Col>
            </Row>
          )}
          {data.data.length === 0 && (
            <div>
              <p>
                <b class="row-title"> No drivers found</b>
              </p>
            </div>
          )}
          <hr className="w-100" />
        </div>
      );
    } else if (data.messagetype == "reassign") {
      return (
        <div>
          <p>
            <b style={{ textDecoration: "underline" }}>DRIVER RE-ASSIGN</b>
          </p>
          <p>
            <b>Trip ID : </b> {" " + data.trip_id}{" "}
          </p>
          <p>
            <b>Driver details : </b>{" "}
            {" " +
              data.drivername +
              " " +
              data.phonenumber +
              " " +
              data.vehiclenumber}
          </p>
          <hr className="w-100" />
        </div>
      );
    } else if (data.messagetype == "assign") {
      return (
        <div>
          <p>
            <b style={{ textDecoration: "underline" }}>DRIVER Assigned</b>
          </p>
          <p>
            <b>Trip ID : </b> {" " + data.trip_id}{" "}
          </p>
          <p>
            <b>Driver details : </b>{" "}
            {" " +
              data.drivername +
              " " +
              data.phonenumber +
              " " +
              data.vehiclenumber}
          </p>
          <hr className="w-100" />
        </div>
      );
    } else if (data.messagetype == "leaveforpickup") {
      return (
        <div>
          <p>
            <b style={{ textDecoration: "underline" }}>
              DRIVER IS NAVIGATING TO PICK-UP
            </b>
          </p>

          <hr className="w-100" />
        </div>
      );
    } else if (data.messagetype == "notify") {
      return (
        <div>
          <p>
            <b style={{ textDecoration: "underline" }}>
              TRIP NOTIFIED - Driver has arrived at pick-up
            </b>
          </p>

          <hr className="w-100" />
        </div>
      );
    } else if (data.messagetype == "started") {
      return (
        <div>
          <p>
            <b style={{ textDecoration: "underline" }}>TRIP HAS STARTED</b>
          </p>

          <hr className="w-100" />
        </div>
      );
    } else if (data.messagetype == "tripend") {
      return (
        <div>
          <p>
            <b style={{ textDecoration: "underline" }}>TRIP HAS ENDED</b>
          </p>

          <hr className="w-100" />
        </div>
      );
    } else if (data.messagetype == "notboarded") {
      return (
        <div>
          <p>
            <b style={{ textDecoration: "underline" }}>
              Consumer did not show up at the point of pick-up!
            </b>
          </p>

          <hr className="w-100" />
        </div>
      );
    } else if (data.messagetype == "detach") {
      return (
        <div>
          <p>
            <b style={{ textDecoration: "underline" }}>
              This ride has been detached!
            </b>
          </p>

          <hr className="w-100" />
        </div>
      );
    } else if (data.messagetype === "responseassign") {
      return <div>Response</div>;
    } else {
      return <div />;
    }
  }
}

export default LogsView;
