import React from "react";
import {
  Container,
  Row,
  Col,
  Tooltip,
  Card,
  CardBody,
  CardHeader,
} from "shards-react";
import moment from "moment";
import * as config from "../constants/constants.json";
import socketIOClient from "socket.io-client";
import LogsView from "./LogsView";
const socket = socketIOClient(config.SOCKET, {
  transports: ["websocket"],
});
class AutoAssignLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      trip_id: "",
    };
  }

  callToGetLogs() {
    let reqObj = {
      trip_id: this.props.match.params.id,
    };
    console.log(reqObj);
    fetch(config.url + "htmlcrm/getRidesConsoleLog", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(reqObj),
    })
      .then((res) => res.json())
      .then(
        (results) => {
          // console.log(results);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  componentDidMount() {
    let trip_id = this.props.match.params.id;
    socket.on("connect", (data) => {
      this.callToGetLogs();
    });
    socket.on(trip_id, (data) => {
      // console.log(data);
      // this.callToGetLogs();
      this.setState({
        trip_id: trip_id,
        data: [...this.state.data, data],
      });
    });
  }
  render() {
    let { data } = this.state;
    // let tripData = data.data;
    console.log(data);
    return (
      <div>
        <p style={{ color: "#7A28CB", fontWeight: "700" }}>
          AUTO ASSIGN CONSOLE
        </p>
        <p>
          <b className="row-title"> Finding logs for : </b>
          {" " + this.state.trip_id + " " + "..."}
        </p>
        {data.map((stats, idx) => (
          <LogsView data={stats} key={idx} trip_id={this.state.trip_id} />
        ))}
      </div>
    );
  }
}

export default AutoAssignLogs;
