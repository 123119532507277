import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// import shortid from "shortid";
import { Card, CardBody } from "shards-react";
import { blue } from "@material-ui/core/colors";
// import { Link, Route } from "react-router-dom";
// import Rides from "../../views/Rides";

class DashboardStatusBox extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props.id);
    this.state = {
      default: "",
      clicked: false,
      changeColor: false,
    };
    this.callApiBack = this.callApiBack.bind(this);
  }

  callApiBack(data, index) {
    // console.log(data);
    // console.log(index);
    // console.log(this.props);
    // this.setState({
    //   default: this.props.id,
    //   clicked: true
    // });
    this.props.onClickHandle(data, index);
  }
  // componentDidMount() {
  //   if (this.props.index === 0) {
  //     console.log("index");
  //     this.setState({
  //       changeColor: true
  //     });
  //   }
  // }
  render() {
    const {
      variation,
      label,
      value,
      stats,
      name,
      id,
      defaults,
      indx,
      activeIndex,
    } = this.props;
    // console.log(this.props);
    // console.log(this.props.activeIndex);
    // console.log(indx);
    if (activeIndex === indx) {
      // console.log(stats)
      const cardClasses = classNames(
        "change-color",
        variation && `stats-small--${variation}`
      );

      const cardBodyClasses = classNames(
        variation === "1" ? "p-0 d-flex" : "px-0 pb-0"
      );

      const innerWrapperClasses = classNames(
        "d-flex",
        variation === "1" ? "flex-column m-auto" : "px-3"
      );

      const dataFieldClasses = classNames(
        "stats-small__data",
        variation === "1" && "text-center"
      );

      const labelClasses = classNames(
        "stats-small__label change-font",
        variation !== "1" && "mb-1",
        label === "0" ? "d-none" : "",
        stats === false ? "text-uppercase" : ""
      );

      const valueClasses = classNames(
        stats === false ? "stats-small__value" : "",
        "count change-font",
        variation === "1" ? "my-3" : "m-0"
      );

      const innerDataFieldClasses = classNames(
        "stats-small__data",
        variation !== "1" && "text-right align-items-center"
      );

      return (
        <Card
          small
          id={this.props.id}
          className={cardClasses}
          style={{ height: "120px" }}
        >
          <CardBody
            className={cardBodyClasses}
            onClick={() => {
              this.callApiBack(name, indx);
            }}
          >
            <div className={innerWrapperClasses}>
              <div className={dataFieldClasses}>
                <span className={labelClasses} style={{ fontSize: "0.65rem" }}>
                  {label}
                </span>
                <span
                  className={valueClasses}
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: "900",
                  }}
                >
                  {value}
                </span>
              </div>
              <div className={innerDataFieldClasses}>
                {/* <span className={percentageClasses}>{percentage}</span> */}
              </div>
            </div>
            {/* <canvas
                          height={canvasHeight}
                          ref={this.canvasRef}
                          className={`stats-small-${shortid()}`}
                      /> */}
          </CardBody>
        </Card>
      );
    } else {
      const styles = {
        "background-color": "blue",
      };
      // console.log(stats)
      const cardClasses = classNames(
        "",
        variation && `stats-small--${variation}`
      );

      const cardBodyClasses = classNames(
        variation === "1" ? "p-0 d-flex" : "px-0 pb-0"
      );

      const innerWrapperClasses = classNames(
        "d-flex",
        variation === "1" ? "flex-column m-auto" : "px-3"
      );

      const dataFieldClasses = classNames(
        "stats-small__data",
        variation === "1" && "text-center"
      );

      const labelClasses = classNames(
        "stats-small__label",
        variation !== "1" && "mb-1",
        label === "0" ? "d-none" : "",
        stats === false ? "text-uppercase" : ""
      );

      const valueClasses = classNames(
        stats === false ? "stats-small__value" : "",
        "count",
        variation === "1" ? "my-3" : "m-0"
      );

      const innerDataFieldClasses = classNames(
        "stats-small__data",
        variation !== "1" && "text-right align-items-center"
      );

      return (
        <Card
          small
          id={this.props.id}
          className={cardClasses}
          style={{ height: "120px" }}
        >
          <CardBody
            className={cardBodyClasses}
            style={{ styles }}
            onClick={() => {
              this.callApiBack(name, indx);
            }}
          >
            <div className={innerWrapperClasses}>
              <div className={dataFieldClasses}>
                <span className={labelClasses} style={{ fontSize: "0.65rem" }}>
                  {label}
                </span>
                <span
                  className={valueClasses}
                  style={{
                    color: "#000000",
                    fontSize: "1.4rem",
                    fontWeight: "900",
                  }}
                >
                  {value}
                </span>
              </div>
              <div className={innerDataFieldClasses}>
                {/* <span className={percentageClasses}>{percentage}</span> */}
              </div>
            </div>
            {/* <canvas
                          height={canvasHeight}
                          ref={this.canvasRef}
                          className={`stats-small-${shortid()}`}
                      /> */}
          </CardBody>
        </Card>
      );
    }
  }
  // console.log(this.state.default === this.props.id);
}

DashboardStatusBox.propTypes = {
  /**
   * The Small Stats variation.
   */
  variation: PropTypes.string,
  /**
   * The label.
   */
  label: PropTypes.string,
  /**
   * The value.
   */
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  /**
   * Flag to differentiate stat box
   */
  stats: PropTypes.bool,
};

DashboardStatusBox.defaultProps = {
  value: "",
  label: "Label",
  stats: "",
};

export default DashboardStatusBox;
