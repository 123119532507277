import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
} from "shards-react";
import * as config from "../../constants/constants.json";
import axios from "axios";

// const TopDrivers = ({ title, referralData }) => (

// );

class TopDrivers extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props.data);
    let region = this.props.region;
    this.state = {
      topDrivers: [],
      region: region,
    };
    this.callApiToGetTopDrivers = this.callApiToGetTopDrivers.bind(this);
  }

  callApiToGetTopDrivers_old() {
    fetch(config.url + "htmlcrm/getTopDrivers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: "",
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        let driverResults = [];
        if (result.results.length > 0) {
          driverResults = _.orderBy(result.results, (o) => o.rides, ["desc"]);
          // console.log(driverResults);
        }
        // console.log(driverResults);
        this.setState({
          topDrivers: driverResults,
        });
      });
  }
  callApiToGetTopDrivers = async () => {
    let datas = {
      region: this.state.region,
    };
    try {
      const response = await axios({
        method: "POST",
        url: config.url + "htmlcrm/getTopDrivers",
        data: datas,
      });
      // console.log(response);
      let data = response.data;
      let driverResults = [];
      if (data.results.length > 0) {
        driverResults = _.orderBy(data.results, (o) => o.rides, ["desc"]);
        // console.log(driverResults);
      }
      // console.log(driverResults);
      this.setState({
        topDrivers: driverResults,
      });
    } catch (error) {}
  };
  componentDidMount() {
    this.callApiToGetTopDrivers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.region !== this.props.region) {
      this.setState(
        {
          region: this.props.region,
        },
        () => {
          this.callApiToGetTopDrivers();
        }
      );
    }
  }

  render() {
    return (
      <Card small>
        <CardHeader className="border-bottom">
          <h6 className="m-0">{this.props.title}</h6>
          <div className="block-handle" />
        </CardHeader>

        <CardBody className="p-0">
          <ListGroup small flush className="list-group-small">
            {this.state.topDrivers.map((item, idx) => (
              <ListGroupItem key={idx} className="d-flex px-3">
                <span className="text-semibold font-for-all">
                  {item.driver_name}
                </span>
                <span className="ml-auto text-right text-semibold text-reagent-gray">
                  {item.rides}
                </span>
              </ListGroupItem>
            ))}
          </ListGroup>
        </CardBody>
      </Card>
    );
  }
}

TopDrivers.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The referral data.
   */
  referralData: PropTypes.array,
};

TopDrivers.defaultProps = {
  title: "Top drivers",
  referralData: [
    {
      title: "-",
      value: "-",
    },
    {
      title: "-",
      value: "0",
    },
    {
      title: "-",
      value: "0",
    },
  ],
};

export default TopDrivers;
