import React from "react";
import PropTypes from "prop-types";
// import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  FormSelect,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";
// import ReactDatePicker from "react-datepicker";
// import Loader from "react-loader-spinner";
import PageTitle from "./../components/common/PageTitle";
import InputDataForm from "./../components/common/InputDataForm";
// import TextArea from "./../components/common/TextArea";
// import DragAndDrop from "./../components/common/DragAndDrop";
// import AddVehicleData from "./../views/AddVehicleData";
// import Datepicker from "react-date-picker";
// import ReactDatepicker from "react-datepicker";
// import moment from "moment";
import * as config from "../constants/constants.json";
import ReactSnackBar from "react-js-snackbar";
// import DL from "../assets/dl.svg";
// import RC from "../assets/rc.svg";
// import PAN from "../assets/pan.svg";
// import CheckV from "../assets/checktick.svg";
// import ErrorV from "../assets/errorv.svg";
// import socketIOClient from "socket.io-client";
// const socket = socketIOClient(config.SOCKET_DOC, {
//   transports: ["websocket"],
// });

class QuickRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      vehicle_name: "",
      vehicle_number: "",
      vehicle_color: "",
      vehicle_make: "",
      vehicle_model: "",
      phonenumber: "",
      alt_phonenumber: "",
      vehicle_type: "Choose",
      operating_region: "Choose",
      driver_type: "Choose",
      quickResults: [],
      driver_profile: "",
      toggleModal: false,
      Show: false,
      Showing: false,
      success: false,
      message: "",
      dldob: "",
      pandob: "",
      dlno: "",
      vehicle_num1: "",
      vehicle_num2: "",
      pan_name: "",
      pan_number: "",
      dunique_id: "",
      showVerify: false,
      panVerified: undefined,
      dlVerified: undefined,
      rcVerified: undefined,
      disableDl: false,
      disableRC: false,
      disablePAN: false,
      dlverifiedData: [],
      rcverifiedData: [],
      panverifiedData: [],
    };
    this.handleKeyChange = this.handleKeyChange.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.callApiTouploadData = this.callApiTouploadData.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleOperatingChange = this.handleOperatingChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleActivation = this.handleActivation.bind(this);
    this.callApiToActivateDriver = this.callApiToActivateDriver.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goBackTodriv = this.goBackTodriv.bind(this);
    this.handleDriverChange = this.handleDriverChange.bind(this);
    this.handleDLChange = this.handleDLChange.bind(this);
    this.handlePanDateChange = this.handlePanDateChange.bind(this);
    this.handleDldataChange = this.handleDldataChange.bind(this);
    this.handleVehiclenumChange = this.handleVehiclenumChange.bind(this);
    this.handlePanName = this.handlePanName.bind(this);
    this.handlePanNumber = this.handlePanNumber.bind(this);
  }

  // verifyDL = (e) => {
  //   this.setState({
  //     dlVerified: "waiting",
  //     disableDl: true,
  //   });
  //   let dataToSend = {
  //     phonenumber: this.state.phonenumber,
  //     dob: moment(this.state.dldob).format("DD-MM-yyyy"),
  //     dlno: this.state.dlno,
  //     dunique_id: this.state.dunique_id,
  //   };
  //   // console.log(dataToSend);
  //   socket.on("verifydl", (data) => {
  //     // console.log(data);
  //     if (data.dunique_id === this.state.dunique_id) {
  //       if (data.message === "successfull") {
  //         this.setState(
  //           {
  //             dlVerified: "verified",
  //             dlverifiedData: data.data,
  //           },
  //           () => {
  //             this.callModal();
  //           }
  //         );
  //       } else {
  //         this.setState({
  //           dlVerified: "failed",
  //           disableDl: false,
  //         });
  //       }
  //     }
  //   });

  //   socket.emit("verifydl", dataToSend);
  //   setTimeout(() => {
  //     if (this.state.dlVerified === "waiting") {
  //       this.setState({
  //         dlVerified: undefined,
  //         disableDl: false,
  //       });
  //     }
  //   }, 15000);
  // };

  // verifyRC = (e) => {
  //   this.setState({
  //     rcVerified: "waiting",
  //     disableRC: true,
  //   });
  //   let dataToSend = {
  //     phonenumber: this.state.phonenumber,
  //     vehicle_number: this.state.vehicle_num1 + "-" + this.state.vehicle_num2,
  //     dunique_id: this.state.dunique_id,
  //   };
  //   console.log(dataToSend);
  //   socket.on("verifyrc", (data) => {
  //     console.log(data);
  //     if (data.dunique_id === this.state.dunique_id) {
  //       if (data.message === "successfull") {
  //         this.setState(
  //           {
  //             rcVerified: "verified",
  //             rcverifiedData: data.data,
  //           },
  //           () => {
  //             this.callModal();
  //           }
  //         );
  //       } else {
  //         this.setState({
  //           rcVerified: "failed",
  //           disableRC: false,
  //         });
  //       }
  //     }
  //   });

  //   socket.emit("verifyrc", dataToSend);
  //   setTimeout(() => {
  //     if (this.state.rcVerified === "waiting") {
  //       this.setState({
  //         rcVerified: undefined,
  //         disableRC: false,
  //       });
  //     }
  //   }, 15000);
  // };

  // verifyPAN = (e) => {
  //   this.setState({
  //     panVerified: "waiting",
  //     disablePAN: true,
  //   });
  //   let dataToSend = {
  //     pan_no: this.state.pan_number,
  //     name: this.state.pan_name,
  //     dob: moment(this.state.pandob).format("DD/MM/yyyy"),
  //     phonenumber: this.state.phonenumber,
  //     dunique_id: this.state.dunique_id,
  //   };
  // //   let dataToSend = {
  // //     "pan_no": "GUZPS9603G",
  // //     "phonenumber": "8951973199",
  // //     "name": "SIDHARTH M A",
  // //     "dob": "31/03/1993",
  // //     "dunique_id": "5960d4e5607ce547af585e74",
  // // }

  //   // console.log(dataToSend);
  //   socket.on("verifypan", (data) => {
  //     console.log(data);
  //     if (data.dunique_id === this.state.dunique_id) {
  //       if (data.message === "successfull") {
  //         this.setState(
  //           {
  //             panVerified: "verified",
  //             panverifiedData: data.data,
  //           },
  //           () => {
  //             this.callModal();
  //           }
  //         );
  //       } else {
  //         this.setState({
  //           panVerified: "failed",
  //           disablePAN: false,
  //         });
  //       }
  //     }
  //   });

  //   socket.emit("verifypan", dataToSend);
  //   setTimeout(() => {
  //     if (this.state.panVerified === "waiting") {
  //       this.setState({
  //         panVerified: undefined,
  //         disablePAN: false,
  //       });
  //     }
  //   }, 15000);
  // };
  // verifyRC = (e) => {
  //   if (e) {
  //     e.preventDefault();
  //   }
  //   this.setWaitingRc();
  //   let dataToSend = {
  //     phonenumber: this.state.phonenumber,
  //     vehicle_number: this.state.vehicle_num1 + "-" + this.state.vehicle_num2,
  //     dunique_id: this.state.dunique_id,
  //   };
  //   this.rcSocket(dataToSend.dunique_id);
  //   axios
  //     .post("http://192.168.1.228:8001/verifyRC/", dataToSend)
  //     .then((response) => {
  //       if (response.data.message === "successfull") {
  //         // this.rcSocket(dataToSend.dunique_id);
  //       } else {
  //         this.setFailRc();
  //       }
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         rcVerified: undefined,
  //         disableRC: false,
  //       });
  //     });
  // };
  // verifyDL = (e) => {
  //   if (e) {
  //     e.preventDefault();
  //   }
  //   this.setWaitingDl();
  //   let dataToSend = {
  //     phonenumber: this.state.phonenumber,
  //     dob: moment(this.state.dldob).format("DD-MM-yyyy"),
  //     dlno: this.state.dlno,
  //     dunique_id: this.state.dunique_id,
  //   };
  //   this.dlSocket(dataToSend.dunique_id);
  //   axios
  //     .post("http://192.168.1.228:8001/verifyDL/", dataToSend)
  //     .then((response) => {
  //       if (response.data.message === "successfull") {
  //         // this.dlSocket(dataToSend.dunique_id);
  //       } else {
  //         this.setFailDl();
  //       }
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         dlVerified: undefined,
  //         disableDl: false,
  //       });
  //     });
  // };

  // verifyPAN = (e) => {
  //   if (e) {
  //     e.preventDefault();
  //   }
  //   this.setWaitingPan();
  //   let dataToSend = {
  //     pan_no: this.state.pan_number,
  //     name: this.state.pan_name,
  //     dob: moment(this.state.pandob).format("DD/MM/yyyy"),
  //     phonenumber: this.state.phonenumber,
  //     dunique_id: this.state.dunique_id,
  //   };
  //   this.panSocket(dataToSend.dunique_id);
  //   // console.log(dataToSend);
  //   // let dataToSend = {
  //   //   pan_no: "GUZPS9603G",
  //   //   phonenumber: "8951973199",
  //   //   name: "SIDHARTH M A",
  //   //   dob: "31/03/1993",
  //   //   dunique_id: "5960d4e5607ce547af585e74",
  //   // };
  //   axios
  //     .post("http://192.168.1.228:8001/verifyPAN/", dataToSend)
  //     .then((response) => {
  //       if (response.data.message === "successfull") {
  //         // socket.connect("pan_" + dataToSend.dunique_id, () => {
  //         //   console.log(socket.connected);
  //         // });
  //       } else {
  //         this.setFailPan();
  //       }
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         panVerified: undefined,
  //         disablePAN: false,
  //       });
  //     });
  // };

  // setFailPan = () => {
  //   this.setState({
  //     panVerified: "failed",
  //     disablePAN: false,
  //   });
  // };

  // setWaitingPan = () => {
  //   this.setState({
  //     panVerified: "waiting",
  //     disablePAN: true,
  //   });
  // };

  // setFailDl = () => {
  //   this.setState({
  //     dlVerified: "failed",
  //     disableDl: false,
  //   });
  // };

  // setWaitingDl = () => {
  //   this.setState({
  //     dlVerified: "waiting",
  //     disableDl: true,
  //   });
  // };

  // setFailRc = () => {
  //   this.setState({
  //     rcVerified: "failed",
  //     disableRC: false,
  //   });
  // };

  // setWaitingRc = () => {
  //   this.setState({
  //     rcVerified: "waiting",
  //     disableRC: true,
  //   });
  // };
  // panSocket = (dunique_id) => {
  //   // console.log(dunique_id);

  //   socket.on("pan_" + dunique_id, (data) => {
  //     // console.log(data);
  //     if (data.message === "successfull" && data.data.status === "valid") {
  //       this.setState(
  //         {
  //           panVerified: "verified",
  //           panverifiedData: data.data,
  //         },
  //         () => {
  //           this.callModal();
  //         }
  //       );
  //     } else {
  //       // console.log(data);
  //       this.setFailPan();
  //     }
  //   });
  // };

  // dlSocket = (dunique_id) => {
  //   socket.on("dl_" + dunique_id, (data) => {
  //     // console.log(data);
  //     if (data.message === "successfull") {
  //       this.setState(
  //         {
  //           dlVerified: "verified",
  //           dlverifiedData: data.data,
  //         },
  //         () => {
  //           this.callModal();
  //         }
  //       );
  //     } else {
  //       // console.log(data);
  //       this.setFailDl();
  //     }
  //   });
  // };

  // rcSocket = (dunique_id) => {
  //   socket.on("rc_" + dunique_id, (data) => {
  //     // console.log(data);
  //     if (data.message === "successfull") {
  //       this.setState(
  //         {
  //           rcVerified: "verified",
  //           rcverifiedData: data.data,
  //         },
  //         () => {
  //           this.callModal();
  //         }
  //       );
  //     } else {
  //       // console.log(data);
  //       this.setFailRc();
  //     }
  //   });
  // };

  callModal = () => {
    // console.log(this.state.dlVerified);
    // console.log(this.state.rcVerified);
    // console.log(this.state.panVerified);
    if (
      this.state.dlVerified === "verified" &&
      this.state.rcVerified === "verified" &&
      this.state.panVerified === "verified"
    ) {
      this.toggle();
    }
  };

  handlePanNumber(event) {
    this.setState({
      pan_number: event.target.value,
    });
  }
  handlePanName(event) {
    this.setState({
      pan_name: event.target.value,
    });
  }
  handleVehiclenumChange(event, type) {
    if (type === "f1") {
      this.setState({
        vehicle_num1: event.target.value,
      });
    } else {
      this.setState({
        vehicle_num2: event.target.value,
      });
    }
  }

  handleDldataChange(event) {
    this.setState({
      dlno: event.target.value,
    });
  }

  handlePanDateChange(date) {
    this.setState({
      pandob: date,
    });
  }

  handleDLChange(date) {
    // console.log(date);
    this.setState({
      dldob: date,
    });
  }

  handleDriverChange(event) {
    this.setState({
      driver_type: event.target.value,
    });
  }

  goBackTodriv() {
    this.props.history.push("/driveraccounts");
  }
  handleOperatingChange(event) {
    this.setState({ operating_region: event.target.value });
  }
  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    }
  }

  goBack() {
    this.props.history.goBack();
  }
  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };

  // Handle activation call

  handleActivation() {
    // Call activation API
    this.callApiToActivateDriver();
  }

  // Function to toggle modal
  toggle() {
    // console.log("toggled state ---->" + this.state.toggleModal);
    this.setState({
      toggleModal: true,
    });
  }

  reToggle = () => {
    this.setState({
      toggleModal: false,
      success: false,
      first_name: "",
      last_name: "",
      vehicle_name: "",
      vehicle_number: "",
      vehicle_color: "",
      vehicle_make: "",
      vehicle_model: "",
      phonenumber: "",
      alt_phonenumber: "",
      vehicle_type: "Choose",
      operating_region: "Choose",
      driver_type: "Choose",
      dldob: "",
      pandob: "",
      dlno: "",
      vehicle_num1: "",
      vehicle_num2: "",
      pan_name: "",
      pan_number: "",
      dunique_id: "",
      showVerify: false,
    });
  };
  // Api to activate driver

  callApiToActivateDriver() {
    fetch(config.url + "htmlcrm/pushmessagesuspendaccount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        crmuserid: window.sessionStorage.getItem("user_id"),
        phonenumber: this.state.phonenumber,
        status: "Inactive",
      }),
    })
      .then((res) => res.json())
      .then(
        (results) => {
          // console.log(results);
          if (results.message === "successfull") {
            // alert("successfull");
            this.setState(
              {
                toggleModal: false,
                success: true,
                first_name: "",
                last_name: "",
                vehicle_name: "",
                vehicle_number: "",
                vehicle_color: "",
                vehicle_make: "",
                vehicle_model: "",
                phonenumber: "",
                alt_phonenumber: "",
                vehicle_type: "Choose",
                operating_region: "Choose",
                driver_type: "Choose",
                dldob: "",
                pandob: "",
                dlno: "",
                vehicle_num1: "",
                vehicle_num2: "",
                pan_name: "",
                pan_number: "",
                dunique_id: "",
                showVerify: false,
                message: "Driver has been activated successfuly",
              },
              () => {
                this.show();
              }
            );
          } else {
            // alert("Unsuccessfull");
            this.setState(
              {
                success: false,
                message: "Unknown error. Failed to activate driver",
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  // Api to upload data
  callApiTouploadData() {
    if (this.state.vehicle_type === "Choose") {
      this.setState(
        {
          success: false,
          message: "Please select a vehicle type",
        },
        () => {
          this.show();
        }
      );
    } else if (this.state.driver_type === "Choose") {
      this.setState(
        {
          success: false,
          message: "Please select driver type",
        },
        () => {
          this.show();
        }
      );
    } else if (this.state.operating_region === "Choose") {
      this.setState(
        {
          success: false,
          message: "Please select operating region",
        },
        () => {
          this.show();
        }
      );
    } else {
      // console.log({
      //   driver_type: this.state.driver_type,
      //   phonenumber: this.state.phonenumber,
      //   vehiclenumber: this.state.vehicle_number,
      //   first_name: this.state.first_name,
      //   last_name: this.state.last_name,
      //   vehiclemake: this.state.vehicle_make,
      //   vehiclename: this.state.vehicle_type,
      //   vehicletype: this.state.vehicle_name,
      //   userid: window.sessionStorage.getItem("user_id"),
      //   vehiclemodel: new Date(this.state.vehicle_model).getTime(),
      //   vehicle_color: this.state.vehicle_color,
      //   latitude: 0.0,
      //   longitude: 0.0,
      //   operating_region: this.state.operating_region,
      // });
      // Fetch call
      fetch(config.url + "onboard/driverQuickRegistration", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          driver_type: this.state.driver_type,
          phonenumber: this.state.phonenumber,
          vehiclenumber: this.state.vehicle_number,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          vehiclemake: this.state.vehicle_make,
          vehiclename: this.state.vehicle_type,
          vehicletype: this.state.vehicle_name,
          userid: window.sessionStorage.getItem("user_id"),
          vehiclemodel: new Date(this.state.vehicle_model).getTime(),
          vehicle_color: this.state.vehicle_color,
          latitude: 0.0,
          longitude: 0.0,
          operating_region: this.state.operating_region,
        }),
      })
        .then((res) => res.json())
        .then(
          (results) => {
            // console.log(results);
            if (results.message === "successfull") {
              this.setState(
                {
                  success: true,
                  dunique_id: results.dunique_id,

                  message: "Driver registered",
                },
                () => {
                  this.show();
                  this.toggle();
                }
              );
              // this.toggle();
            } else if (results.message === "vehiclenumber") {
              // Show error
              this.setState(
                {
                  success: false,
                  message: "Failed to register, Invalid vehicle number",
                },
                () => {
                  this.show();
                }
              );
            } else if (
              results.message === "Account already registered as consumer"
            ) {
              this.setState(
                {
                  success: false,
                  message: "Account is already registered as rider",
                },
                () => {
                  this.show();
                }
              );
            } else if (results.message === "already exists") {
              this.setState(
                {
                  success: false,
                  message: "Provided driver account already exists",
                },
                () => {
                  this.show();
                }
              );
            } else if (
              results.message === "Phone number already registered as driver"
            ) {
              this.setState(
                {
                  success: false,
                  message: "Provided driver account already exists",
                },
                () => {
                  this.show();
                }
              );
            } else if (
              results.message === "Vehicle number already registered as driver"
            ) {
              this.setState(
                {
                  success: false,
                  message: "Vehicle number already registered",
                },
                () => {
                  this.show();
                }
              );
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    }
  }
  handleClick(e) {
    e.preventDefault();
    this.callApiTouploadData();
  }
  // Handle select operation
  handleSelectChange(event) {
    this.setState({ vehicle_type: event.target.value });
  }
  // Handle image on drag and drop of the image
  handleImage(image, name) {
    // console.log(image)
    const newState = {};
    newState[name] = image;
    this.setState({ ...this.state, ...newState });
  }
  // Handle checkbox change
  handleKeyChange(e, name) {
    const newState = {};
    let value;
    if (name === "vehicle_model") {
      newState[name] = e;
      this.setState({ ...this.state, ...newState });
    } else if (name === "phonenumber" || name === "alt_phonenumber") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        value = e.target.value;
        newState[name] = value;
        this.setState({ ...this.state, ...newState });
      }
    } else {
      newState[name] = e.target.value;
      this.setState({ ...this.state, ...newState });
    }
  }
  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4 px-3">
          <Col className="col-12">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBackTodriv}>
                  Driver accounts
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active />
            </Breadcrumb>
          </Col>
          <Col className="col-12 mt-1">
            <PageTitle
              title="Quick registration"
              className="text-sm-left mb-3 p-0 custom-margin"
            />
          </Col>
          {/* <PageTitle
            title="Quick Registration"
            subtitle="Driver Accounts"
            className="text-sm-left mb-3"
          /> */}
        </Row>
        <Row className="px-md-3 mb-4">
          <Col className="col-12 col-md-12 col-lg-12">
            <div className="float-right mt-2">
              <Button
                className=""
                id="goback-button"
                theme="dark"
                onClick={this.goBack}
              >
                <i className="fas fa-arrow-left mx-1" />
                <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                  BACK
                </span>
              </Button>
            </div>
          </Col>
        </Row>
        {this.state.showVerify === false && (
          <Form onSubmit={this.handleClick}>
            <Row className="px-md-3">
              <Col className="col-12 col-md-12 col-lg-12">
                <Card>
                  <CardBody className="mb-4">
                    <React.Fragment>
                      <Row>
                        <Col className="col-12 d-flex justify-content-center my-4">
                          <label
                            style={{
                              fontWeight: 800,
                              fontSize: "calc(1rem + 1vmin)",
                            }}
                          >
                            Registration details
                          </label>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        {this.props.inputValues.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-4 col-lg-4 p-md-2 p-0 mb-4"
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.hits)} */}
                            <InputDataForm
                              id={`quick-driver-details-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state[stats.name]}
                              stats={stats.stats}
                              name={stats.name}
                              onKeyChange={this.handleKeyChange}
                            />
                          </Col>
                        ))}
                        <Col className="col-12 col-md-4 col-lg-4   p-2">
                          <div>
                            <label className="label-style">
                              Vehicle type{" "}
                              <span style={{ color: "#FF0000" }}>*</span>
                            </label>
                            <FormSelect
                              style={{ height: "calc(3.16rem + 2px)" }}
                              value={this.state.vehicle_type}
                              onChange={this.handleSelectChange}
                            >
                              <option value="Choose">
                                Choose vehicle type
                              </option>
                              <option value="Hatchback">Hatchback</option>
                              <option value="Sedan">Sedan</option>
                              <option value="SUV">SUV</option>
                            </FormSelect>
                          </div>
                        </Col>
                        <Col className="col-12 col-md-4 col-lg-4   p-2">
                          <div>
                            <label className="label-style">
                              Driver type{" "}
                              <span style={{ color: "#FF0000" }}>*</span>
                            </label>
                            <FormSelect
                              style={{ height: "calc(3.16rem + 2px)" }}
                              value={this.state.driver_type}
                              onChange={this.handleDriverChange}
                            >
                              <option value="Choose">Select driver type</option>
                              <option value="Temporary">Temporary</option>
                              <option value="Owner">Owner</option>
                              <option value="Driver">Driver</option>
                            </FormSelect>
                          </div>
                        </Col>
                        <Col className="col-12 col-md-4 col-lg-4   p-2">
                          <div>
                            <label className="label-style">
                              Region <span style={{ color: "#FF0000" }}>*</span>
                            </label>
                            <FormSelect
                              style={{ height: "calc(3.16rem + 2px)" }}
                              value={this.state.operating_region}
                              onChange={this.handleOperatingChange}
                            >
                              <option value="Choose">Choose region</option>
                              <option value="Bangalore">Bangalore</option>
                              <option value="Hyderabad">Hyderabad</option>
                            </FormSelect>
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-5 mb-5">
              <Col className="col-4" />
              <Col className="col-4 d-flex justify-content-center label-style">
                {/* <Button
                className="label-style py-3 px-5"
                onClick={this.handleClick}
              > */}
                {this.state.showVerify === false && (
                  <Button
                    className=""
                    theme="dark"
                    size="lg"
                    style={{
                      fontSize: "calc(0.5rem + 1vmin)",
                      fontWeight: "700",
                      minWidth: "200px",
                      borderRadius: "3px",
                    }}
                  >
                    SUBMIT
                  </Button>
                )}
              </Col>
              <Col className="col-4 " />
            </Row>
          </Form>
        )}

        <Modal open={this.state.toggleModal} centered>
          <ModalHeader>Driver activation</ModalHeader>
          <ModalBody>
            <Row>
              <h6>Do you want to activate the driver?</h6>
            </Row>
            <Row>
              <Col className="col-6 col-md-6">
                {this.state.first_name + " " + this.state.last_name}
              </Col>
              <Col className="col-6 col-md-6">{this.state.phonenumber}</Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button className="mx-2" theme="danger" onClick={this.reToggle}>
              CANCEL
            </Button>
            <Button className="mx-2" onClick={this.handleActivation}>
              ACTIVATE DRIVER
            </Button>
          </ModalFooter>
        </Modal>
        {this.state.success === false && (
          <ReactSnackBar
            Icon={
              <i
                className="far fa-times-circle fa-lg"
                style={{ color: "#FF0000" }}
              />
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
        {this.state.success === true && (
          <ReactSnackBar
            Icon={
              <span>
                <i
                  className="far fa-check-circle fa-lg"
                  style={{ color: "#26FF58" }}
                />
              </span>
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
      </Container>
    );
  }
}

QuickRegister.propTypes = {
  inputValues: PropTypes.array,
};
QuickRegister.defaultProps = {
  inputValues: [
    {
      label: "First name",
      value: "",
      attrs: {},
      stats: false,
      name: "first_name",
    },
    {
      label: "Last name",
      value: "",
      attrs: {},
      stats: false,
      name: "last_name",
    },
    {
      label: "Phone number",
      value: "",
      attrs: {},
      name: "phonenumber",
    },
    {
      label: "Alternate phone number",
      value: "",
      attrs: {},
      name: "alt_phonenumber",
    },
    {
      label: "Vehicle name",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_name",
    },
    {
      label: "Vehicle number",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_number",
    },
    {
      label: "Vehicle make",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_make",
    },
    {
      label: "Vehicle model",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_model",
    },
    {
      label: "Vehicle color",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_color",
    },
  ],
};

export default QuickRegister;
