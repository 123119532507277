import React, { useState, useEffect } from "react";
import WebCam from "react-webcam";
import { Row, Col } from "react-bootstrap";
import socketIOClient from "socket.io-client";
import Loader from "react-loader-spinner";
import axios from "axios";

/**
 * Import files and modules
 */

import stringsConstant from "../constants/stringsConstant.json";
import config from "../constants/constants.json";
import urls from "../constants/urlList.json";
import ReactPico from "../utils/pico/ReactPico";
import success from "../assets/success.svg";
import error from "../assets/error.svg";

// const socket = socketIOClient(config.SOCKET_FACE, {
//   transports: ["websocket"],
// });

function FaceScanner(props) {
  const [face, setFace] = useState(null);
  const [openSnackbar, setSnackBar] = useState(false);
  const [verifiedState, setVerifiedState] = useState("waiting");
  const [tryAgain, setTryAgain] = useState(true);

  /**
   * Function to check and redirect dispatch roles
   */
  const checkDispatchRole = (data) => {
    console.log(data);
    console.log("here");
    if (
      data === "traineeexecutive" ||
      data === "teamlead" ||
      data === "seniorexecutive" ||
      data === "juniorexecutive"
    ) {
      setTimeout(() => {
        window.location.assign("/businessaccounts/bookings");
      }, 1500);
    }
  };

  /**
   * Function to check and redirect supply roles
   */
  const checkSupplyRole = (data) => {
    if (
      data === "traineeexecutive" ||
      data === "teamlead" ||
      data === "seniorexecutive" ||
      data === "juniorexecutive"
    ) {
      setTimeout(() => {
        window.location.assign("/driveraccounts");
      }, 1500);
    }
  };

  /**
   * Functionality to retry face recog
   */

  const toggleTryAgain = () => {
    // console.log("Heloooooooo");
    setTryAgain(!tryAgain);
    setVerifiedState("waiting");
  };

  /**
   * Effect to verify user face
   */
  useEffect(() => {
    // console.log(face);
    if (face !== null) {
      var d = new Date();
      var milliseconds = d.getTime();
      let data = {
        content: face.imageData,
        name: props.name,
        user_id: props.user_id,
        milliseconds: milliseconds,
        // name: "ravikiran",
        // user_id: "5d07363d8a332a0af0df7b59",
        type: "verify",
      };
      console.log(data.content);
      // socket.emit("message", data);
    }
  }, [face]);

  /**
   * Effect to listen to socket
   */

  // useEffect(() => {
  //   socket.on("message", function(response) {
  //     // console.log(JSON.parse(response));
  //     let result = JSON.parse(response);
  //     console.log(result);
  //     if (result.status === "verified") {
  //       // console.log("here boy 1");
  //       setVerifiedState("verified");
  //       // loginUser();
  //       setTimeout(() => {
  //         loginUser();
  //       }, 2000);
  //     } else {
  //       console.log(response);
  //       setVerifiedState("invalid");
  //       // console.log("here boy 2");
  //     }
  //   });
  // }, []);

  /**
   * Effect to call login API when user's face is verified
   */
  // useEffect(() => {
  //   console.log(verifiedState);
  //   let test = setTimeout(() => {
  //     // loginUser();
  //   }, 2000);

  //   return () => {
  //     clearTimeout(test);
  //   };
  // }, [verifiedState === "verified"]);

  /**
   * Functionality to login user to the panel directly without QR
   */

  const loginUser = () => {
    // console.log("here boy");
    let dataTovalidate = {
      email: props.email,
      password: props.password,
    };
    console.log(dataTovalidate);
    axios.post(config.url + urls.loginUser, dataTovalidate).then((response) => {
      console.log(response);
      if (response.data.message === stringsConstant.onsuccess) {
        let result = response.data;
        sessionStorage.setItem("user_id", result.user_id);
        sessionStorage.setItem("username", result.username);
        sessionStorage.setItem("phonenumber", result.phonenumber);
        sessionStorage.setItem("usertype", result.usertype);
        sessionStorage.setItem("first_name", result.first_name);
        sessionStorage.setItem("last_name", result.last_name);
        sessionStorage.setItem("roletype", result.roletype);
        sessionStorage.setItem("shift", result.shift);
        sessionStorage.setItem("email", result.email);
        openWindow();
      }
    });
  };

  /**
   * Route users based on the role
   */
  const openWindow = () => {
    switch (window.sessionStorage.getItem("usertype")) {
      case "dispatchexecutive":
        checkDispatchRole(window.sessionStorage.getItem("roletype"));
        break;
      case "supplyexecutive":
        checkSupplyRole(window.sessionStorage.getItem("roletype"));
        break;
      default:
        setTimeout(() => {
          window.location.assign("/businessaccounts/bookings");
        }, 1500);
        break;
    }
  };

  return (
    <React.Fragment>
      <Row className="heading-face margin-custom-head w-100">
        <Col className="col-12 col-md-12 col-lg-12 d-flex justify-content-center">
          <p className="mb-0">Face verification</p>
        </Col>
      </Row>
      <Row>
        <Col
          className="col-12 d-flex justify-content-center"
          style={{ height: "100vh" }}
        >
          <div className="hole mt-0"></div>
          <ReactPico
            onFaceFound={(face) => {
              setFace(face);
            }}
            onTryAgain={tryAgain}
          ></ReactPico>
        </Col>
      </Row>
      <Row className="heading-bottom margin-bottom-custom w-100 px-0 d-flex mt-2 justify-content-center">
        <Col className="col-12 col-md-12 col-lg-12 d-flex justify-content-center">
          <p className="mb-0">Place your face on the camera</p>
        </Col>
        <Col className="col-12 col-md-12 col-lg-12 d-flex justify-content-center validation-bottom">
          <div className="d-inline-flex py-0">
            {verifiedState === "waiting" && (
              <React.Fragment>
                <Loader type="Oval" color="white" height="auto" width={10} />
                <p className="mb-0 mx-2">Verifying your data</p>
              </React.Fragment>
            )}
            {verifiedState === "verified" && (
              <React.Fragment>
                {/* <Loader type="Oval" color="white" height="auto" width={10} /> */}
                <img src={success} height="auto" width={15} alt="sucess"></img>
                <p className="mb-0 mx-2">Verification successful</p>
              </React.Fragment>
            )}
            {verifiedState === "invalid" && (
              <React.Fragment>
                {/* <Loader type="Oval" color="white" height="auto" width={10} /> */}
                <img src={error} height="auto" width={15} alt="sucess"></img>
                <p className="mb-0 mx-2">
                  Verification failed!{" "}
                  <span>
                    <u style={{ cursor: "pointer" }} onClick={toggleTryAgain}>
                      Click here
                    </u>
                  </span>{" "}
                  to retry
                </p>
              </React.Fragment>
            )}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default FaceScanner;
