import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper, Polyline } from "google-maps-react";
import PropTypes from "prop-types";

export class LiveMap extends Component {
  constructor(props) {
    super(props);
    // this.onMarkerClick = this.onMarkerClick.bind(this);
    this.state = {
      locationData: [],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      center: {
        lat: 12.9716,
        lng: 77.5946,
      },
      zoom: 9,
      disableDefaultUI: true,
    };
    console.log(this.props);
  }
  onMarkerClick(props, marker, e) {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  }
  // fetchLocationCall() {
  //     fetch("https://dispatcher.gloryindia.inhtmlcrm/getDriverLocation", {
  //         method: 'POST',
  //         headers: {
  //             'Content-Type': 'application/json'
  //             // 'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //         body: JSON.stringify({
  //             trip_id: this.props.dataProps.trip_id
  //             // trip_id: 'zNgXI0v9IeRWgUQf'
  //         })
  //     })
  //         .then(res => res.json())
  //         .then(
  //             (result) => {
  //                 // console.log("herererer");
  //                 console.log(result);
  //                 this.setState({
  //                     locationState: result.driver_location
  //                 })
  //             },
  //             // Note: it's important to handle errors here
  //             // instead of a catch() block so that we don't swallow
  //             // exceptions from actual bugs in components.
  //             (error) => {

  //             }
  //         )
  // }
  // componentDidMount() {
  //     // this.timerID = setInterval(
  //     //     () => this.fetchLocationCall(),
  //     //     50000
  //     // );
  //     this.fetchLocationCall()
  // }
  // componentWillUnmount() {
  //     // Store.removeChangeListener(this.onChange);
  //     clearInterval(this.timerID);
  // }
  render() {
    if (!this.props.google) {
      return <div>Loading...</div>;
    }
    let coordinates = this.props.path;
    let flightPlanCoordinates = [];
    let google = this.props.google;
    // for (let i = 0; i < coordinates.length; i++) {
    //   var point = new google.maps.LatLng(coordinates[i][0], coordinates[i][1]);
    //   flightPlanCoordinates.push(point);
    // }
    // console.log(thitripIds.state.locationState);
    return (
      <div style={{ height: "60vh", width: "100%" }}>
        <Map
          style={{}}
          google={this.props.google}
          zoom={this.state.zoom}
          initialCenter={this.state.center}
        >
          <Marker
            title={"The marker`s title will appear as a tooltip."}
            name={"Source"}
            position={{
              lat: this.props.dataProps.slatlon[1],
              lng: this.props.dataProps.slatlon[0],
            }}
            icon={{
              url: "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/source_new.svg",

              scaledSize: new this.props.google.maps.Size(25, 25),
            }}
          />

          {/* <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                    >
                        <div>
                            <h1>{this.state.selectedPlace.name}</h1>
                        </div>
                    </InfoWindow> */}
        </Map>
      </div>
    );
  }
}
LiveMap.default = {
  center: {
    lat: 12.9716,
    lng: 77.5946,
  },
  zoom: 9,
};
LiveMap.defaultProps = {
  center: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  zoom: PropTypes.number,
};
export default GoogleApiWrapper({
  apiKey: "AIzaSyA6Ir9-jvJV-SFNvy-_Hz4bGXk8sNIWVOo",
})(LiveMap);
