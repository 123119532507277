// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormRadio,
  FormInput,
  FormCheckbox,
  CardFooter,
  Slider,
  Modal,
  ModalBody,
  ModalHeader,
  FormSelect,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";
import moment from "moment";
import ReactTable from "react-table-v6";
import ReactDatePicker from "react-datepicker";
import * as config from "./../constants/constants.json";
import PageTitle from "./../components/common/PageTitle";
import TripDump from "../components/common/TripDump";
import DatePicker from "@wojtekmaj/react-daterange-picker";
import { CSVLink, CSVDownload } from "react-csv";
import _ from "lodash";

const headers = [
  { label: "Trip ID", key: "trip_id" },
  { label: "Trip accept at", key: "accept_time" },
  { label: "Left for pickup at Name", key: "leaveforpickup_time" },
  { label: "Notified at", key: "notify_time" },
  { label: "Ride started at", key: "start_time" },
  { label: "Passed through toll at", key: "toll_timestamp" },
  { label: "Ride completed at", key: "end_time" },
  { label: "Cancelled at", key: "cancel_time" },
  { label: "Cancelled by", key: "cancelled_by" },
  { label: "Order reference number", key: "order_reference_number" },
];

class TripDebugInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      selectionType: "Month",
      daySelect: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      date: [new Date(), new Date()],
      dataStore: [],
      filteredData: [],
      searchInput: "",
      changeView: false,
      indiData: "",
      csvArray: [],
    };
    this.goBack = this.goBack.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.changeEndDate = this.changeEndDate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.generateLogs = this.generateLogs.bind(this);
    this.getDetails = this.getDetails.bind(this);
    this.switchDiv = this.switchDiv.bind(this);
    this.changeDiv = this.changeDiv.bind(this);
    this.disableClick = this.disableClick.bind(this); // this.enableTripDump = this.enableTripDump.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
  }

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    }
  }

  downloadReport() {
    let datas = [];
    let count = 0;
    this.state.dataStore.forEach((data) => {
      console.log(data);
      let accept_time = "-",
        cancel_time = "-",
        cancelled_by = "-",
        leaveforpickup_time = "-",
        notify_time = "-",
        start_time = "-",
        toll_timestamp = "-",
        end_time = "-";

      if (data.accept_time) {
        accept_time = moment(new Date(data.accept_time * 1)).format(
          "DD-MM-YY [at] hh:mm:ss A"
        );
      }
      if (data.end_time) {
        accept_time = moment(new Date(data.end_time * 1)).format(
          "DD-MM-YY [at] hh:mm:ss A"
        );
      }
      if (data.cancel_data.cancel_time) {
        cancel_time = moment(new Date(data.cancel_data.cancel_time * 1)).format(
          "DD-MM-YY [at] hh:mm:ss A"
        );
      }
      if (data.cancel_data.cancelled_by) {
        cancelled_by = data.cancel_data.cancelled_by;
      }
      if (data.leaveforpickup_time) {
        leaveforpickup_time = moment(
          new Date(data.leaveforpickup_time * 1)
        ).format("DD-MM-YY [at] hh:mm:ss A");
      }
      if (data.notify_time) {
        notify_time = moment(new Date(data.notify_time * 1)).format(
          "DD-MM-YY [at] hh:mm:ss A"
        );
      }
      if (data.start_time) {
        start_time = moment(new Date(data.start_time * 1)).format(
          "DD-MM-YY [at] hh:mm:ss A"
        );
      }
      if (data.toll_info) {
        if (data.toll_info.toll_timestamp) {
          toll_timestamp = moment(
            new Date(data.toll_info.toll_timestamp * 1)
          ).format("DD-MM-YY [at] hh:mm:ss A");
        }
      }

      datas.push({
        accept_time,
        cancel_time,
        cancelled_by,
        leaveforpickup_time,
        end_time,
        notify_time,
        start_time,
        toll_timestamp,
        trip_id: data.trip_id,
        order_reference_number: data.order_reference_number,
      });
      count++;

      if (count === this.state.dataStore.length) {
        this.setState(
          {
            csvArray: datas,
          },
          () => {
            setTimeout(() => {
              this.csvLink.link.click();
            }, 2000);
          }
        );
      }
    });
  }
  disableClick() {
    this.setState({
      dataStore: [],
    });
  }
  changeDiv() {
    this.setState({
      changeView: false,
    });
  }
  getDetails(data) {
    this.setState(
      {
        indiData: data,
      },
      () => {
        this.switchDiv();
      }
    );
  }

  switchDiv() {
    this.setState({
      changeView: true,
    });
  }
  generateLogs() {
    let data;
    if (this.state.selectionType === "Month") {
      console.log(this.state.selectionType);
      data = {
        start_millis: new Date(
          this.state.daySelect.getFullYear(),
          this.state.daySelect.getMonth(),
          1
        ).getTime(),
        end_millis: new Date(
          this.state.daySelect.getFullYear(),
          this.state.daySelect.getMonth() + 1,
          0
        ).getTime(),
        filter_type: "airport",
        requested_through: window.sessionStorage.getItem("vendor"),
      };
    } else {
      console.log(this.state.selectionType);
      data = {
        start_millis: new Date(this.state.date[0]).getTime(),
        end_millis: new Date(this.state.date[1]).getTime(),
        filter_type: "airport",
        requested_through: window.sessionStorage.getItem("vendor")
          ? window.sessionStorage.getItem("vendor")
          : "mmt",
      };
    }

    console.log(data);
    fetch(config.url + "htmlcrm/getMultiTripDebugInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (results) => {
          console.log(results);
          if (results.message === "successfull") {
            this.setState({
              dataStore: results.data,
            });
          } else {
            this.setState({
              dataStore: [],
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleSelectChange(event) {
    console.log(event.target.value);
    this.setState({ selectionType: event.target.value });
  }
  goBack() {
    this.props.history.goBack();
  }
  changeEndDate(date) {
    this.setState({
      endDate: date,
    });
  }
  changeDate(date) {
    this.setState({
      daySelect: date,
    });
  }
  onChange(date) {
    // console.log(this);
    console.log(date);
    this.setState({ date });
  }
  render() {
    console.log(this.state);
    const execDataToDisplay = this.state.searchInput
      ? this.state.filteredData
      : this.state.dataStore;
    console.log(execDataToDisplay);
    const columns = [
      {
        Header: "Sl no",
        id: "serialno",
        style: {},
        Cell: (row) => {
          // console.log(row);
          return <div>{row.viewIndex + 1}</div>;
        },
      },
      {
        Header: "Trip accept at",
        accessor: "accept_time",
        Cell: (row) => {
          if (
            row.original.accept_time === undefined ||
            row.original.accept_time === ""
          ) {
            return <div>N/A</div>;
          } else {
            return moment(new Date(row.original.accept_time)).format(
              "DD-MM-YY [at] hh:mm:ss A"
            );
          }
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Left for pickup at",
        accessor: "leaveforpickup_time",
        Cell: (row) => {
          if (
            row.original.leaveforpickup_time === undefined ||
            row.original.leaveforpickup_time === ""
          ) {
            return <div>N/A</div>;
          } else {
            return moment(new Date(row.original.leaveforpickup_time)).format(
              "DD-MM-YY [at] hh:mm:ss A"
            );
          }
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Notified at",
        accessor: "notify_time",
        Cell: (row) => {
          if (
            row.original.notify_time === undefined ||
            row.original.notify_time === ""
          ) {
            return <div>N/A</div>;
          } else {
            return moment(new Date(row.original.notify_time)).format(
              "DD-MM-YY [at] hh:mm:ss A"
            );
          }
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Ride started at",
        accessor: "start_time",
        Cell: (row) => {
          if (
            row.original.start_time === undefined ||
            row.original.start_time === ""
          ) {
            return <div>N/A</div>;
          } else {
            return moment(new Date(row.original.start_time)).format(
              "DD-MM-YY [at] hh:mm:ss A"
            );
          }
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Ride completed at",
        accessor: "end_time",
        Cell: (row) => {
          if (
            row.original.end_time === undefined ||
            row.original.end_time === ""
          ) {
            return <div>N/A</div>;
          } else {
            return moment(new Date(row.original.end_time)).format(
              "DD-MM-YY [at] hh:mm:ss A"
            );
          }
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Passed through toll at",
        accessor: "toll_info",
        Cell: (row) => {
          console.log(row.original);
          if (row.original.toll_info) {
            if (
              row.original.toll_info.toll_timestamp === undefined ||
              row.original.toll_info.toll_timestamp === ""
            ) {
              return <div>N/A</div>;
            } else {
              return moment(
                new Date(row.original.toll_info.toll_timestamp)
              ).format("DD-MM-YY [at] hh:mm:ss A");
            }
          } else {
            return <div>N/A</div>;
          }
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Cancelled at",
        id: "cancelledat",
        accessor: "cancel_data",
        Cell: (row) => {
          if (
            row.original.cancel_data.cancel_time === undefined ||
            row.original.cancel_data.cancel_time === ""
          ) {
            return <div>N/A</div>;
          } else {
            return moment(
              new Date(row.original.cancel_data.cancel_time)
            ).format("DD-MM-YY [at] hh:mm:ss A");
          }
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Cancelled by",
        id: "cancelledby",
        accessor: "cancel_data",
        Cell: (row) => {
          if (
            row.original.cancel_data.cancelled_by === undefined ||
            row.original.cancel_data.cancelled_by === ""
          ) {
            return <div>N/A</div>;
          } else {
            return <div>{row.original.cancel_data.cancelled_by}</div>;
          }
        },
        style: { whiteSpace: "unset" },
      },
      {
        id: "edit",
        Header: "Actions",
        Cell: (row) => {
          //  console.log(this);
          // console.log(row.original.request_status)
          return (
            <div className="">
              <Button
                className="px-4 my-2"
                theme="dark"
                onClick={() => {
                  this.getDetails(row.original.trip_id);
                }}
              >
                VIEW
              </Button>
            </div>
          );
        },
      },
    ];
    return (
      <Container fluid className="main-content-container px-md-4">
        <Row noGutters className="page-header py-4 px-md-3">
          <Col className="col-12 col-md-6 col-lg-6">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Business accounts
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active />
            </Breadcrumb>
          </Col>
          <Col className="col-12 mt-1">
            <PageTitle
              title="Trip debug info"
              className="text-sm-left mb-3  p-0 custom-margin"
            />
          </Col>
        </Row>
        <Row className="px-3 mb-5">
          <Col className="col-12 col-md-12 col-lg-12">
            <div className="float-right mt-2">
              <Button
                className=""
                id="goback-button"
                theme="dark"
                onClick={this.goBack}
              >
                <i className="fas fa-arrow-left mx-1" />
                <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                  BACK
                </span>
              </Button>
            </div>
          </Col>
        </Row>
        {this.state.changeView === false && (
          <React.Fragment>
            {this.state.dataStore.length === 0 && (
              <Row className="px-md-3 mb-3">
                <Col className="col-12 col-md-12 col-lg-12">
                  <Card className="mb-4">
                    <CardHeader className="my-4 text-center font-weight-bold">
                      <h6
                        style={{
                          fontWeight: "800",
                          fontSize: "1.2rem",
                          color: "black",
                        }}
                      >
                        Trip debug info
                        {/* <button
                        type="button"
                        className="close float-right ml-auto mr-3"
                        aria-label="Close"
                        onClick={this.props.enableTripDump}
                      >
                        <span aria-hidden="true">
                          <i class="fas fa-times" />
                        </span>
                      </button> */}
                      </h6>
                    </CardHeader>

                    <CardBody className="px-2 px-md-4">
                      <Row className="mb-3">
                        <Col className="col-12 col-md-12">
                          <Row className="px-3">
                            <Col className="col-12 col-md-4 col-lg-3 mb-3">
                              <div>
                                <label className="label-style">
                                  Generate by
                                </label>
                                <FormSelect
                                  style={{ height: "calc(3.16rem + 2px)" }}
                                  value={this.state.vehicle_type}
                                  onChange={this.handleSelectChange}
                                >
                                  <option value="Month">Month</option>
                                  <option value="Range">
                                    Custom date range
                                  </option>
                                </FormSelect>
                              </div>
                            </Col>
                            <Col className="col-12 col-md-4 col-lg-3 mb-3">
                              {this.state.selectionType === "Month" && (
                                <div>
                                  <label className="label-style">
                                    Select month
                                  </label>
                                  <ReactDatePicker
                                    className="form-control py-3"
                                    selected={this.state.daySelect}
                                    onChange={(date) => this.changeDate(date)}
                                    dateFormat="MMMM"
                                    showMonthYearPicker
                                  />
                                </div>
                              )}
                              {this.state.selectionType === "Range" && (
                                <div>
                                  <label className="label-style">
                                    Select month
                                  </label>
                                  <DatePicker
                                    className="form-control text-center py-3 px-0"
                                    format="dd/MM/yyyy"
                                    onChange={this.onChange}
                                    value={this.state.date}
                                    clearIcon={null}
                                    calendarIcon={
                                      <i className="far fa-calendar-alt" />
                                    }
                                    style={{ borderStyle: "none" }}
                                  />
                                </div>
                              )}
                            </Col>
                            <Col className="col-12 col-md-4 mt-5 mt-md-auto mb-3 d-flex justify-content-center">
                              <Button
                                theme="dark"
                                onClick={this.generateLogs}
                                size="lg"
                                style={{
                                  fontSize: "0.8rem",
                                  fontWeight: "700",
                                  minWidth: "200px",
                                  borderRadius: "3px",
                                }}
                              >
                                GENERATE
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {this.state.dataStore.length > 0 && (
              <Row className=" px-md-3 mb-5">
                <Col className="col-12 col-md-12 col-lg-12">
                  <Card>
                    <CardHeader className="my-4 text-center font-weight-bold">
                      <h6
                        style={{
                          fontWeight: "800",
                          fontSize: "1.2rem",
                          color: "black",
                        }}
                      >
                        Trip debug info
                        <button
                          type="button"
                          className="close float-right ml-auto mr-3"
                          aria-label="Close"
                          onClick={this.disableClick}
                        >
                          <span aria-hidden="true">
                            <i class="fas fa-times" />
                          </span>
                        </button>
                      </h6>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="col-12 col-md-12 px-0 px-md-2">
                          <ReactTable
                            className="text-center -striped -highlight"
                            data={execDataToDisplay}
                            columns={columns}
                            minRows={execDataToDisplay.length > 0 ? 0 : 4}
                          />
                        </Col>
                        <Col className="col-12 col-md-12 d-flex justify-content-center mt-5">
                          <Button onClick={this.downloadReport}>
                            <label className="px-4 py-2 my-auto">
                              DOWNLOAD
                            </label>
                          </Button>
                          <CSVLink
                            data={this.state.csvArray}
                            headers={headers}
                            className="hidden"
                            ref={(r) => (this.csvLink = r)}
                            filename={"data" + new Date().getTime() + ".xlsx"}
                            target="_blank"
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </React.Fragment>
        )}
        {this.state.changeView === true && (
          <TripDump
            tripId={this.state.indiData}
            enableTripDump={this.changeDiv}
          />
        )}
      </Container>
    );
  }
}

export default TripDebugInfo;
