// eslint-disable-next-line
import React from "react";
// import axios from "axios";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import CommonBox from "../components/common/commonDisplayBox";
import stringsData from "../constants/stringsConstant.json";
// import ReactCSSTransitionGroup from "react-transition-group";
// import { Link, Route } from "react-router-dom";
// import AccountBox from "../components/common/AccountBox";
import PageTitle from "./../components/common/PageTitle";
// import * as config from "../constants/constants.json";

class Configuration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorsList: [],
    };
  }

  componentDidMount() {
    // console.log(window.sessionStorage.getItem("usertype"));
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null ||
      !stringsData.checkID.includes(window.sessionStorage.getItem("user_id"))
    ) {
      window.location.assign("/");
    } else {
      //   if (
      //     window.sessionStorage.getItem("usertype") === "dispatchexecutive" ||
      //     window.sessionStorage.getItem("usertype") === "dispatchmanager"
      //   ) {
      //     // console.log("here");
      //     this.getuserDetailsFromServer();
      //   }
    }
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4 px-3">
          <PageTitle
            title="Manage Configurations"
            subtitle="Configration"
            className="text-sm-left mb-3"
          />
        </Row>
        <Row className="px-3">
          {this.props.smallStats.map(
            (stats, idx) =>
              stats.doNotDisplay.roletype !==
                window.sessionStorage.getItem("roletype") &&
              stats.doNotDisplay.roletype !==
                window.sessionStorage.getItem("roletype") && (
                <Col className="col-md mb-4" key={idx} {...stats.attrs}>
                  <CommonBox
                    id={`small-stats-${idx}`}
                    variation="1"
                    label={stats.label}
                    value={stats.value}
                    stats={stats.stats}
                    display={stats.display}
                    path="/configuration/"
                  />
                </Col>
              )
          )}
        </Row>
      </Container>
    );
  }
}

Configuration.propTypes = {
  smallStats: PropTypes.array,
  bottomStats: PropTypes.array,
};

Configuration.defaultProps = {
  smallStats: [
    {
      label: "fareconfiguration",
      value: "Fare configuration",
      id: "fareconfiguration",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
      doNotDisplay: {},
    },
    {
      label: "countanalytics",
      value: "Ride flow analytics",
      id: "fareconfiguration",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
      doNotDisplay: {},
    },
  ],
};

export default Configuration;

/**
 * {
      label: "faresettings",
      value: "Supply configuration",
      id: "faresettings",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
      doNotDisplay: {},
    },
 */
