import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import classNames from "classnames";
// import Switch from '@material-ui/core/Switch';
import { grey } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { FormGroup, FormControlLabel, Switch } from "@material-ui/core";

// import { Animated } from "react-animated-css";
// import shortid from "shortid";
import { Card, CardBody, Row, Col } from "shards-react";
import { Modal } from "react-bootstrap";
import { Link, Route } from "react-router-dom";
import Rides from "../../views/Rides";
import * as config from "../../constants/constants.json";

import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  chipInputRoot: {},
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: "100%",
  },
  tabs: {
    "& .Mui-selected": {
      fontSize: "1.2em",
      fontWeight: 700,
    },
  },
  tabsRoot: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(17),
    textAlign: "left",
    "&:focus": {
      opacity: 1,
    },
    expandedPanel: {
      backgroundColor: theme.palette.primary.main,
    },
  },

  tableRoot: {
    marginTop: theme.spacing(3),
    overflowX: "auto",
    "&-webkit-scrollbar": {
      display: "none",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  headingTwo: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "66.66%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {
    overflowX: "auto",
    border: 0,
    // backgroundColor: "#FAFAFA",
  },
  tabHeader: {
    borderBottom: "none",
    fontWeight: 600,
  },
  selectTableCell: {},
  tableCell: {
    minWidth: 135,

    borderBottom: "none",
  },
  slCell: {
    minWidth: 80,

    borderBottom: "none",
  },
  input: {
    maxWidth: 150,
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  formLabel: {
    fontWeight: 600,

    color: "#000",
    "&.Mui-focused": {
      color: "#000",
    },
  },
  checkbox: {
    root: {
      "&$checked": {
        color: "black",
      },
    },
  },
  typo: {
    fontSize: "0.8rem",
  },
  selectRoot: {
    "&:before": {
      borderColor: "black",
    },
    "&:focus": {
      borderColor: "black",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderColor: "black",
    },
  },
}));

/**Custom component for Switches */
const BlackSwitch = withStyles({
  switchBase: {
    color: grey[200],
    "&$checked": {
      color: grey[900],
    },
    "&$checked + $track": {
      backgroundColor: grey[900],
    },
  },
  checked: {},
  track: {},
})(Switch);
function PayoutBox(props) {
  const classes = useStyles();
  const [toggleCommission, setToggleCommission] = useState(false);
  const [comissionOnModal, setComissionOnModal] = useState(false);
  const [comissionOffModal, setComissionOffModal] = useState(false);
  const [payoutOnModal, setPayoutOnModal] = useState(false);
  const [payoutOffModal, setPayoutOffModal] = useState(false);
  const [togglePayout, setTogglePayout] = useState(false);
  const { variation, label, value, stats, display } = props;
  const cardClasses = classNames(
    "zoom-wrappers",
    variation && `stats-small--${variation}`
  );

  const cardBodyClasses = classNames(
    variation === "1" ? "p-0 d-flex" : "px-0 pb-0 "
  );

  const innerWrapperClasses = classNames(
    "d-flex",
    variation === "1" ? "flex-column my-auto mx-5" : "px-3"
  );

  const dataFieldClasses = classNames(
    "stats-small__data",
    variation === "1" && ""
  );

  const labelClasses = classNames(
    "stats-small__label",
    "title-props",
    variation !== "1" && "mb-1",
    display === false ? "d-none" : "",
    stats === false ? "text-uppercase" : ""
  );

  const valueClasses = classNames(
    stats === false ? "" : "",
    "count",
    "font-props",
    variation === "1" ? "my-3" : "m-0"
  );

  const innerDataFieldClasses = classNames(
    "stats-small__data",
    variation !== "1" && "text-right align-items-center"
  );

  const SaveButton = withStyles((theme) => ({
    root: {
      color: "#fff",
      backgroundColor: grey[900],
      "&:hover": {
        backgroundColor: grey[800],
      },
    },
  }))(Button);

  const handlePayout = (event) => {
    // let payout;
    // console.log('hrerer');
    // if (event.target.checked === true) {
    //   // payout = true;
    //   console.log("hrerer");
    //   setPayoutOnModal(true);
    //   console.log(payoutOnModal);
    //   // updatePayout(payout);
    //   // updateFareConfig(stringsData.faretype.perkm, fare_stat);
    // } else {
    //   // setSwitchFare(false);
    //   // payout = false;
    //   setPayoutOffModal(true);
    //   // updatePayout(payout);
    //   // updateFareConfig(stringsData.faretype.slab, fare_stat);
    // }
  };

  const handleComission = (event) => {
    // let comission;
    if (event.target.checked === true) {
      // comission = true;
      setComissionOnModal(true);
      // updateComission(comission);
      // updateFareConfig(stringsData.faretype.perkm, fare_stat);
    } else {
      // comission = false;
      setComissionOffModal(true);
      // setSwitchFare(false);
      // updateComission(comission);

      // updateFareConfig(stringsData.faretype.slab, fare_stat);
    }
  };
  const updateComission = (comission) => {
    let dataToSend = {
      status: comission,
      type: "DAILY_COMMISSION",
      region: "bangalore",
      userId: sessionStorage.getItem("user_id"),
    };
    console.log(dataToSend);
    axios
      .post(config.url + "payout/configDailyPayoutCommission", dataToSend)
      .then((res) => {
        console.log(res.data);
        if (res.data.message === "successfull") {
          setToggleCommission(comission);
          comission === false
            ? setComissionOffModal(false)
            : setComissionOnModal(false);
        } else {
        }
      })
      .catch((err) => {});
  };
  const updatePayout = (payout) => {
    // let dataToSend = {
    //   status: payout,
    //   region: "bangalore",
    //   type: "DAILY_DRIVER_PAYOUT",
    //   userId: sessionStorage.getItem("user_id"),
    // };
    // axios
    //   .post(config.url + "payout/configDailyPayoutCommission", dataToSend)
    //   .then((res) => {
    //     if (res.data.message === "successfull") {
    //       payout === false ? setPayoutOffModal(false) : setPayoutOnModal(false);
    //       setTogglePayout(payout);
    //     } else {
    //     }
    //   })
    //   .catch((err) => {});
  };

  useEffect(() => {
    getDataOnLoad();
  }, []);

  const getDataOnLoad = () => {
    console.log("hello");
    // getPayout();
    // getComission();
  };

  const getPayout = () => {
    let dataToSend = {
      type: "DAILY_DRIVER_PAYOUT",
      region: "bangalore",
    };
    axios
      .post(config.url + "payout/getDailyAutoPayoutonfig", dataToSend)
      .then((res) => {
        if (res.data.message === "successfull") {
          let datas = res.data.data;
          setTogglePayout(datas[0].status);
        } else {
          setTogglePayout(false);
        }
        // console.log(res.data);
      })
      .catch((err) => {});
  };

  const getComission = () => {
    let dataToSend = {
      type: "DAILY_COMMISSION",
      region: "bangalore",
    };
    axios
      .post(config.url + "payout/getDailyAutoPayoutonfig", dataToSend)
      .then((res) => {
        if (res.data.message === "successfull") {
          let datas = res.data.data;
          setToggleCommission(datas[0].status);
        } else {
          setToggleCommission(false);
        }
        // console.log(res.data);
      })
      .catch((err) => {});
  };

  const handleComOn = () => {
    setComissionOnModal(false);
  };

  const handleComOff = () => {
    setComissionOffModal(false);
  };

  const handlePayOn = () => {
    setPayoutOnModal(false);
  };

  const handlePayOff = () => {
    setPayoutOffModal(false);
  };

  if (props.type === "comission") {
    return (
      <React.Fragment>
        <Modal
          show={comissionOffModal}
          centered
          backdrop="static"
          onHide={handleComOff}
          size="lg"
        >
          <Modal.Header style={{ borderBottom: "none" }} closeButton>
            Toggle comission
          </Modal.Header>
          <Modal.Body>
            {" "}
            <Typography
              variant="h5"
              style={{ textAlign: "center", fontWeight: 700 }}
            >
              Are you sure you want to turn off comission ?
            </Typography>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <SaveButton
              className="py-3"
              size="large"
              style={{ minWidth: "240px" }}
              onClick={(e) => updateComission(false)}
            >
              APPLY
            </SaveButton>
          </Modal.Footer>
        </Modal>
        <Modal
          show={comissionOnModal}
          centered
          backdrop="static"
          onHide={handleComOn}
          size="lg"
        >
          <Modal.Header
            style={{ borderBottom: "none" }}
            closeButton
          ></Modal.Header>
          <Modal.Body>
            {" "}
            <Typography
              variant="h5"
              style={{ textAlign: "center", fontWeight: 700 }}
            >
              Are you sure you want to turn on comission ?
            </Typography>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <SaveButton
              className="py-3"
              size="large"
              style={{ minWidth: "240px" }}
              onClick={(e) => updateComission(true)}
            >
              APPLY
            </SaveButton>
          </Modal.Footer>
        </Modal>

        <Card small className={cardClasses} style={{ height: "160px" }}>
          <CardBody className={cardBodyClasses}>
            <div className={innerWrapperClasses}>
              <div className={dataFieldClasses}>
                <span
                  className={labelClasses}
                  style={{
                    color: "#000000",
                    fontSize: "1.1em",
                  }}
                >
                  {label}
                </span>
                <span
                  className={valueClasses}
                  style={{
                    color: "#000000",
                    fontSize: "1.8em",
                    fontWeight: "900",
                  }}
                >
                  <FormControlLabel
                    className="mt-md-2 w-25"
                    control={
                      <BlackSwitch
                        name="switchfare"
                        checked={toggleCommission}
                        onChange={handleComission}
                      />
                    }
                  />
                </span>
                {/* <Route exact path={`/businessaccounts/${value}`} Component={Rides} /> */}
              </div>
              <div className={innerDataFieldClasses}>
                {/* <span className={percentageClasses}>{percentage}</span> */}
              </div>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  } else if (props.type === "payout") {
    return (
      <React.Fragment>
        <Modal
          show={payoutOffModal}
          centered
          backdrop="static"
          onHide={handlePayOff}
          size="lg"
        >
          <Modal.Header style={{ borderBottom: "none" }} closeButton>
            Toggle auto payout
          </Modal.Header>
          <Modal.Body>
            {" "}
            <Typography
              variant="h5"
              style={{ textAlign: "center", fontWeight: 700 }}
            >
              Are you sure you want to turn off the auto-payouts ?
            </Typography>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <SaveButton
              className="py-3"
              size="large"
              style={{ minWidth: "240px" }}
              onClick={(e) => updatePayout(false)}
            >
              APPLY
            </SaveButton>
          </Modal.Footer>
        </Modal>
        <Modal
          show={payoutOnModal}
          centered
          backdrop="static"
          onHide={handlePayOn}
          size="lg"
        >
          <Modal.Header
            style={{ borderBottom: "none" }}
            closeButton
          ></Modal.Header>
          <Modal.Body>
            {" "}
            <Typography
              variant="h5"
              style={{ textAlign: "center", fontWeight: 700 }}
            >
              Are you sure you want to turn on auto-payouts ?
            </Typography>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <SaveButton
              className="py-3"
              size="large"
              style={{ minWidth: "240px" }}
              onClick={(e) => updatePayout(true)}
            >
              APPLY
            </SaveButton>
          </Modal.Footer>
        </Modal>
        <Card small className={cardClasses} style={{ height: "160px" }}>
          <CardBody className={cardBodyClasses}>
            <div className={innerWrapperClasses}>
              <div className={dataFieldClasses}>
                <span
                  className={labelClasses}
                  style={{
                    color: "#000000",
                    fontSize: "1.1em",
                  }}
                >
                  {label}
                </span>
                <span
                  className={valueClasses}
                  style={{
                    color: "#000000",
                    fontSize: "1.8em",
                    fontWeight: "900",
                  }}
                >
                  <FormControlLabel
                    className="mt-md-2 w-25"
                    control={
                      <BlackSwitch
                        name="switchpay"
                        checked={togglePayout}
                        onChange={handlePayout}
                      />
                    }
                  />
                </span>
                {/* <Route exact path={`/businessaccounts/${value}`} Component={Rides} /> */}
              </div>
              <div className={innerDataFieldClasses}>
                {/* <span className={percentageClasses}>{percentage}</span> */}
              </div>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  } else {
    return (
      <Card small className={cardClasses} style={{ height: "160px" }}>
        <CardBody className={cardBodyClasses}>
          <div className={innerWrapperClasses}>
            <div className={dataFieldClasses}>
              <span
                className={labelClasses}
                style={{
                  color: "#000000",
                  fontSize: "1.1em",
                }}
              >
                {label}
              </span>
              <span
                className={valueClasses}
                style={{
                  color: "#000000",
                  fontSize: "1.8em",
                  fontWeight: "900",
                }}
              >
                ₹ {value}
              </span>
              {/* <Route exact path={`/businessaccounts/${value}`} Component={Rides} /> */}
            </div>
            <div className={innerDataFieldClasses}>
              {/* <span className={percentageClasses}>{percentage}</span> */}
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

PayoutBox.propTypes = {
  /**
   * The Small Stats variation.
   */
  variation: PropTypes.string,
  /**
   * The label.
   */
  label: PropTypes.string,
  /**
   * The value.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Flag to differentiate stat box
   */
  stats: PropTypes.bool,
  /**
   * Toggle label
   */
  display: PropTypes.bool,
};

PayoutBox.defaultProps = {
  value: 0,
  label: "Label",
  stats: "",
};

export default PayoutBox;
