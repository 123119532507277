import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import {
  Button,
  Collapse,
  Row,
  Col,
  NavbarBrand,
  CardBody,
  Card,
} from "shards-react";

class ShiftNotesSection extends React.Component {
  constructor(props) {
    super(props);

    // console.log(props);
    // this.toggle = this.toggle.bind(this);
    this.handleViewClick = this.handleViewClick.bind(this);
  }

  handleViewClick(notification_id, type, trip_id, requestType, requestStatus) {
    if (type === "quickregistration_notification") {
      this.props.onView(notification_id, type);
    } else {
      this.props.onView(
        notification_id,
        type,
        trip_id,
        requestType,
        requestStatus
      );
    }
    // this.props.onView(notification_id, type, this.props.value);
  }
  // componentDidUpdate(prevProps) {
  //   if (prevProps.value.length !== this.props.value.length) {
  //     this.setState({
  //       count: false
  //     })
  //   }
  // }
  render() {
    let { label, value, name } = this.props;

    // console.log(value);

    return (
      <Row className="mb-5">
        <Col className="col-12 col-md-12 col-lg-12">
          <Card>
            <CardBody>
              {value.type !== "dunique_id" && (
                <div>
                  <Row>
                    <Col className="col-12 col-md-6 col-lg-6">
                      <label style={{ fontWeight: "900" }}>Pickup</label>
                      <p style={{ fontWeight: "200" }}>{value.data.sname}</p>
                    </Col>
                    <Col className="col-12 col-md-3 col-lg-3">
                      <label style={{ fontWeight: "900" }}>Trip ID</label>
                      <p style={{ fontWeight: "200" }}>{value.data.trip_id}</p>
                    </Col>
                    <Col className="col-12 col-md-3 col-lg-3">
                      <label style={{ fontWeight: "900" }}>Trip Date</label>
                      <p style={{ fontWeight: "200" }}>
                        {value.data.pickup_time}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12 col-md-12 col-lg-12">
                      <label style={{ fontWeight: "900" }}>Drop off</label>
                      <p style={{ fontWeight: "200" }}>{value.data.dname}</p>
                    </Col>
                  </Row>
                </div>
              )}
              {value.type === "dunique_id" && (
                <div>
                  <Row>
                    <Col className="Col-12 col-md-6 col-lg-6">
                      <lable>Driver name</lable>
                      <p>
                        {value.data.first_name + " " + value.data.last_name}
                      </p>
                    </Col>
                    <Col className="Col-12 col-md-6 col-lg-6">
                      <lable>Phone number</lable>
                      <p>{value.data.phonenumber}</p>
                    </Col>
                  </Row>
                </div>
              )}
              <Row>
                <label style={{ fontWeight: "900" }} className="px-3">
                  <u>Comments</u>
                </label>
                {value.comments.map((stats, idx) => (
                  <Col className="col-12 col-md-12 col-lg-12" key={idx}>
                    {/* {console.log(smallStats)} */}
                    {/* {console.log(idx)}
  {console.log(stats)} */}
                    {/* <ShiftNotesSection
                    id={`shift-reports-${idx}`}
                    variation="1"
                    value={stats}
                  /> */}
                    <Row>
                      <Col className="col-12 col-md-8 col-lg-8">
                        - {stats.comment}
                      </Col>
                      <Col className="col-12 col-md-4 col-lg-4">
                        <label style={{ fontSize: "0.79rem", color: "blue" }}>
                          {" "}
                          - Posted by{" "}
                          {stats.first_name +
                            " " +
                            stats.last_name +
                            " @ " +
                            moment(new Date(stats.milliseconds)).format(
                              "DD-MM-YY, hh:mm:ss A"
                            )}
                        </label>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default ShiftNotesSection;
