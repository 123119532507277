/**
 *
 * Split extra_hour to extra_hour_fare and extra_minute_fare
 * flag is_per_hour_enabled
 *
 */

import { FormGroup } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { grey } from "@material-ui/core/colors";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddButton from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Remove";
import Save from "@material-ui/icons/Save";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import ReactSnackBar from "react-js-snackbar";
import { Breadcrumb, BreadcrumbItem } from "shards-react";
import { ReactAutosuggestExample } from "../components/common/AutoSuggest";
/**External components */
import config from "../constants/constants";
import stringsData from "../constants/stringsConstant";
import PageTitle from "./../components/common/PageTitle";
import { padStart } from "lodash";

/** Custom styling function for Material UI components */
const useStyles = makeStyles((theme) => ({
  chipInputRoot: {},
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: "100%",
  },
  tabs: {
    "& .Mui-selected": {
      fontSize: "1.2em",
      fontWeight: 700,
    },
  },
  tabsRoot: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(17),
    textAlign: "left",
    "&:focus": {
      opacity: 1,
    },
    expandedPanel: {
      backgroundColor: theme.palette.primary.main,
    },
  },

  tableRoot: {
    marginTop: theme.spacing(3),
    overflowX: "auto",
    "&-webkit-scrollbar": {
      display: "none",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  headingTwo: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "66.66%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {
    overflowX: "auto",
    border: 0,
    // backgroundColor: "#FAFAFA",
  },
  tabHeader: {
    borderBottom: "none",
    fontWeight: 600,
  },
  selectTableCell: {},
  tableCell: {
    minWidth: 135,

    borderBottom: "none",
  },
  slCell: {
    minWidth: 80,

    borderBottom: "none",
  },
  input: {
    maxWidth: 150,
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  formLabel: {
    fontWeight: 600,

    color: "#000",
    "&.Mui-focused": {
      color: "#000",
    },
  },
  checkbox: {
    root: {
      "&$checked": {
        color: "black",
      },
    },
  },
  typo: {
    fontSize: "0.8rem",
  },
  selectRoot: {
    "&:before": {
      borderColor: "black",
    },
    "&:focus": {
      borderColor: "black",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderColor: "black",
    },
  },
}));

/**Get the day of the week based on the tab index */
const getDayOfTheWeek = (day) => {
  if (day === 0) {
    return "monday";
  } else if (day === 1) {
    return "tuesday";
  } else if (day === 2) {
    return "wednesday";
  } else if (day === 3) {
    return "thursday";
  } else if (day === 4) {
    return "friday";
  } else if (day === 5) {
    return "saturday";
  } else if (day === 6) {
    return "sunday";
  }
};

const getPackageInfo = (data) => {
  if (data === "PKG_40_4") {
    return {
      package_km: 40,
      package_hour: 4,
    };
  } else if (data === "PKG_20_2") {
    return {
      package_km: 20,
      package_hour: 2,
    };
  } else if (data === "PKG_80_8") {
    return {
      package_km: 80,
      package_hour: 8,
    };
  } else if (data === "PKG_90_9") {
    return {
      package_km: 90,
      package_hour: 9,
    };
  } else if (data === "PKG_100_10") {
    return {
      package_km: 100,
      package_hour: 10,
    };
  } else if (data === "PKG_110_11") {
    return {
      package_km: 110,
      package_hour: 11,
    };
  } else if (data === "PKG_120_12") {
    return {
      package_km: 120,
      package_hour: 12,
    };
  }
};

const FareConfig = (props) => {
  /**Function to change data in the row */
  const onChange = (e, row, data, names) => {
    // console.log(e + "---" + row + "-----" + data + "-----" + names);
    // console.log(row);
    if (data === "time") {
      if (!previous[row.id]) {
        setPrevious((state) => ({ ...state, [row.id]: row }));
      }
      const value = e;
      const name = names;
      const { id } = row;
      const newRows = rows.map((row) => {
        if (row.id === id) {
          return { ...row, [name]: value };
        }
        return row;
      });
      setRows(newRows);
    } else {
      // console.log(row);
      // console.log(e.target);
      if (!previous[row.id]) {
        setPrevious((state) => ({ ...state, [row.id]: row }));
      }
      const value = e.target.value;
      const name = e.target.name;
      const { id } = row;
      const newRows = rows.map((row) => {
        if (row.id === id) {
          return { ...row, [name]: value };
        }
        return row;
      });
      setRows(newRows);
    }
  };

  const onRentalChange = (e, row, data, names) => {
    if (data === "time") {
      if (!previous[row.id]) {
        setPrevious((state) => ({ ...state, [row.id]: row }));
      }
      const value = e;
      const name = names;
      const { id } = row;
      const newRows = rows.map((row) => {
        if (row.id === id) {
          return { ...row, [name]: value };
        }
        return row;
      });
      setRentalRows(newRows);
    } else {
      // console.log(row);
      // console.log(e.target);
      if (!previous[row.id]) {
        setPrevious((state) => ({ ...state, [row.id]: row }));
      }
      const value = e.target.value;
      const name = e.target.name;
      const { id } = row;
      const newRows = rentalRows.map((row) => {
        if (row.id === id) {
          return { ...row, [name]: value };
        }
        return row;
      });
      setRentalRows(newRows);
    }
  };

  /**Function for the go back button to previous screen */
  const goBackAccounts = () => {
    props.history.push("/" + stringsData.config);
  };

  const clickDelete = (data) => {
    let timeData = data.name; // Get the range string from the clicked table
    // console.log(timeData);
    let splitTimeData = timeData.split("to"); // Split the time interval into two parts
    let max_hour = moment(splitTimeData[1], "hh A").format("HH"); // get the max hour
    let date;
    let id;
    let dataToUpdate = [...rows];
    if (max_hour === "06" || max_hour === "03") {
      date = "12 AM to 06 AM";
      id = "12_AM_to_03_AM";
      // date_pick = "00:00,06:00";
    } else if (max_hour === "11" || max_hour === "08") {
      date = "06 AM to 11 AM";
      id = "06_AM_to_08_AM";
      // date_pick = "06:00,11:00";
    } else if (max_hour === "16" || max_hour === "13") {
      date = "11 AM to 04 PM";
      id = "11_AM_to_01_PM";
      // date_pick = "11:00,16:00";
    } else if (max_hour === "21" || max_hour === "18") {
      date = "04 PM to 09 PM";
      id = "04_PM_to_06_PM";
      // date_pick = "16:00,21:00";
    } else if (max_hour === "00" || max_hour === "0" || max_hour === "22") {
      date = "09 PM to 12 AM";
      id = "09_PM_to_10_PM";
      // date_pick = "21:00,00:00";
    }
    let indexOfRow = dataToUpdate.findIndex((x) => {
      // console.log(x.id);
      // console.log(id);
      return x.id === id;
    });
    // console.log(indexOfRow);
    dataToUpdate[indexOfRow] = {
      ...dataToUpdate[indexOfRow],
      name: date,
      parent: true,
      isClicked: "false",
    };
    let indexOfRows = dataToUpdate.findIndex((x) => x.id === data.id);
    dataToUpdate.splice(indexOfRows, 1);
    // console.log(dataToUpdate);
    setRows(dataToUpdate);
    onClickedData(dataToUpdate[indexOfRow], dataToUpdate);
  };

  const clickNext = (data) => {
    // onClickedData(data, rows);
    let timeData = data.name; // Get the range string from the clicked table
    // timeData.replace(/_/g, " "); // Replace the _ with spaces
    let result = getTimeRange(timeData, 2); // Split the time based on the split count. getTimeRange(time range , split count) where interval is the number of splits
    if (result.length > 0) {
      // Change state only is value is greater than 0

      let old_time_range = result[0];
      // console.log(old_time_range); // Split time range 1
      let new_time_range = result[1];
      // console.log(new_time_range); // Split time range 2
      let dataToUpdate = [...rows]; // Store the state into a new array
      let indexOfRow = dataToUpdate.findIndex((x) => x.id === data.id); // Get the index of the old array
      // Update the clicked state to disable adding new time intervals. Update the old interval with new value
      dataToUpdate[indexOfRow] = {
        ...dataToUpdate[indexOfRow],
        name: old_time_range,
        id: old_time_range.replace(/ /g, "_"),
        parent_id: old_time_range.replace(/ /g, "_"),
        isClicked: true,
      };

      let parent_id = old_time_range.replace(/ /g, "_");
      // console.log(parent_id);
      dataToUpdate.splice(
        indexOfRow + 1,
        0,
        createDataNew(
          6,
          new_time_range,
          dataToUpdate[indexOfRow].range_1,
          dataToUpdate[indexOfRow].range_2,
          dataToUpdate[indexOfRow].range_3,
          dataToUpdate[indexOfRow].range_4,
          dataToUpdate[indexOfRow].range_5,
          dataToUpdate[indexOfRow].range_6,
          dataToUpdate[indexOfRow].range_7,
          dataToUpdate[indexOfRow].per_km,
          dataToUpdate[indexOfRow].toll,
          dataToUpdate[indexOfRow].seller_discount,
          true,
          parent_id
        )
      );
      console.log(dataToUpdate);
      setRows(dataToUpdate);
    } else {
    }
  };

  const clickRentalNext = (data) => {
    // onClickedData(data, rows);
    let timeData = data.name; // Get the range string from the clicked table
    // timeData.replace(/_/g, " "); // Replace the _ with spaces
    let result = getTimeRange(timeData, 2); // Split the time based on the split count. getTimeRange(time range , split count) where interval is the number of splits
    if (result.length > 0) {
      // Change state only is value is greater than 0

      let old_time_range = result[0];
      console.log(old_time_range); // Split time range 1
      let new_time_range = result[1];
      console.log(new_time_range); // Split time range 2
      let dataToUpdate = [...rentalRows]; // Store the state into a new array
      let indexOfRow = dataToUpdate.findIndex((x) => x.id === data.id); // Get the index of the old array
      // Update the clicked state to disable adding new time intervals. Update the old interval with new value
      dataToUpdate[indexOfRow] = {
        ...dataToUpdate[indexOfRow],
        name: old_time_range,
        id: old_time_range.replace(/ /g, "_"),
        parent_id: old_time_range.replace(/ /g, "_"),
        isClicked: true,
      };

      let parent_id = old_time_range.replace(/ /g, "_");
      // console.log(parent_id);
      dataToUpdate.splice(
        indexOfRow + 1,
        0,
        createRentalDataNew(
          6,
          new_time_range,
          dataToUpdate[indexOfRow].range_1,
          dataToUpdate[indexOfRow].extra_hour_fare,
          dataToUpdate[indexOfRow].extra_minute_fare,
          dataToUpdate[indexOfRow].extra_km,
          true,
          parent_id
        )
      );
      // console.log(dataToUpdate);
      setRentalRows(dataToUpdate);
    } else {
    }
  };
  const clickRentalDelete = (data) => {
    let timeData = data.name; // Get the range string from the clicked table
    // console.log(timeData);
    let splitTimeData = timeData.split("to"); // Split the time interval into two parts
    let max_hour = moment(splitTimeData[1], "hh A").format("HH"); // get the max hour
    let date;
    let id;
    let dataToUpdate = [...rentalRows];
    if (max_hour === "06" || max_hour === "03") {
      date = "12 AM to 06 AM";
      id = "12_AM_to_03_AM";
      // date_pick = "00:00,06:00";
    } else if (max_hour === "11" || max_hour === "08") {
      date = "06 AM to 11 AM";
      id = "06_AM_to_08_AM";
      // date_pick = "06:00,11:00";
    } else if (max_hour === "16" || max_hour === "13") {
      date = "11 AM to 04 PM";
      id = "11_AM_to_01_PM";
      // date_pick = "11:00,16:00";
    } else if (max_hour === "21" || max_hour === "18") {
      date = "04 PM to 09 PM";
      id = "04_PM_to_06_PM";
      // date_pick = "16:00,21:00";
    } else if (max_hour === "00" || max_hour === "0" || max_hour === "22") {
      date = "09 PM to 12 AM";
      id = "09_PM_to_10_PM";
      // date_pick = "21:00,00:00";
    }
    let indexOfRow = dataToUpdate.findIndex((x) => {
      return x.id === id;
    });
    // console.log(indexOfRow);
    dataToUpdate[indexOfRow] = {
      ...dataToUpdate[indexOfRow],
      name: date,
      parent: true,
      isClicked: "false",
    };
    let indexOfRows = dataToUpdate.findIndex((x) => x.id === data.id);
    dataToUpdate.splice(indexOfRows, 1);
    // console.log(dataToUpdate);
    setRentalRows(dataToUpdate);
    onClickedRentalData(dataToUpdate[indexOfRow], dataToUpdate);
  };

  /**Function to initialize the Material theme */
  const theme = createMuiTheme({
    props: {
      // Name of the component
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
  });

  /**Custom component for radio button */
  const RadioButton = withStyles({
    root: {
      color: "black",
      "&$checked": {
        color: "black",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  /**Custom component for Switches */
  const BlackSwitch = withStyles({
    switchBase: {
      color: grey[200],
      "&$checked": {
        color: grey[900],
      },
      "&$checked + $track": {
        backgroundColor: grey[900],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  /**Custom component for Tab buttons */
  const AntTab = withStyles((theme) => ({
    root: {
      textTransform: "none",

      "&$selected": {
        color: "black",
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);

  /**Function to switch fare type */
  const handlefareswitch = (event) => {
    let fare_stat;
    if (event.target.checked === true) {
      fare_stat = true;
      updateFareConfig(stringsData.faretype.perkm, fare_stat);
    } else {
      // setSwitchFare(false);
      fare_stat = false;
      updateFareConfig(stringsData.faretype.slab, fare_stat);
    }
  };

  /**Function to toggle booking flow */
  const handlebookingflow = (event) => {
    if (event.target.checked === true) {
      setOpenDutyModal(true);
      // setBookingFlow(true);
    } else {
      setDeactiveDuty(true);
      // setBookingFlow(false);
    }
  };

  const handleminuteflow = (event) => {
    if (event.target.checked === true) {
      // setPerMinuteEnabled(true);
      setOpenMinute(true);
      // setBookingFlow(true);
    } else {
      // setPerMinuteEnabled(false);
      setDeactiveMinute(true);
      // setBookingFlow(false);
    }
  };

  const handleCloseDutyModal = () => {
    setOpenDutyModal(false);
    setYourChips([]);
    setValueUpdate("current_day");
  };

  const handleCloseMinuteModal = () => {
    setOpenMinute(false);
    setYourChips([]);
    setValueUpdate("current_day");
  };

  const handleCloseDeactiveMinute = () => {
    setDeactiveMinute(false);
    setYourChips([]);
    setValueUpdate("current_day");
  };

  const handleCloseDeactiveModal = () => {
    setDeactiveDuty(false);
    setYourChips([]);
    setValueUpdate("current_day");
  };

  /**Function to update fare config type */
  const updateFareConfig = (fare_type, fare_stat) => {
    let daysToCheck = stringsData.dayarray;
    if (daysToCheck && daysToCheck.length > 0) {
      let dataSend;
      if (daysToCheck.length > 6) {
        dataSend = {
          ride_type: "airport",
          user_id: sessionStorage.getItem("user_id"),
          vehicle_type: vehicleType,
          vendor_type: "mmt",
          travel_type: airportWay,
          fare_status: "Active",
          // fare_status: data === true ? "Active" : "Inactive",
          request_type: "all",
          fare_type: fare_type,
        };
        // console.log(dataSend);
        axios
          .post(config.url + "htmlcrm/toggleFareStatus", dataSend)
          .then((response) => {
            // console.log(response.data);
            if (response.data.message === "successfull") {
              setSwitchFare(fare_stat);
            } else {
            }
          })
          .catch();
      } else {
        let success = 0;
        for (let i = 0; i < daysToCheck.length; i++) {
          success++;
          dataSend = {
            ride_type: "airport",
            user_id: sessionStorage.getItem("user_id"),
            vehicle_type: vehicleType,
            vendor_type: "mmt",
            travel_type: airportWay,
            fare_status: "Active",
            request_type: "individual",
            day_of_week: daysToCheck[i].toLowerCase(),
            fare_type: fare_type,
          };
          // console.log(dataSend);
          axios
            .post(config.url + "htmlcrm/toggleFareStatus", dataSend)
            .then((response) => {
              // console.log(response.data);
              if (response.data.message === "successfull") {
                // retrieveFareStatus();
                // success = success + 1;
                // console.log(success);
              } else {
              }
            })
            .catch();
        }
        // console.log(success);
        if (success === daysToCheck.length) {
        } else {
        }
      }
    } else {
    }
  };

  /**Function to switch between tabs */
  const handledayswitch = (event, dayTab) => {
    setDayOfWeek(dayTab);
  };

  /**Create custom save button */
  const SaveButton = withStyles((theme) => ({
    root: {
      color: "#fff",
      backgroundColor: grey[900],
      "&:hover": {
        backgroundColor: grey[800],
      },
    },
  }))(Button);

  /**Create table */
  const createData = (
    action,
    name,
    range_1,
    range_2,
    range_3,
    range_4,
    range_5,
    range_6,
    range_7,
    per_km,
    toll,
    seller_discount,
    isEditMode,
    parent_id,
    isClicked
  ) => ({
    id: name.replace(/ /g, "_"),
    action,
    name,
    range_1,
    range_2,
    range_3,
    range_4,
    range_5,
    range_6,
    range_7,
    per_km,
    toll,
    seller_discount,
    isEditMode: isEditMode,
    parent_id: parent_id,
    isClicked: isClicked,
    parent: true,
  });

  /**Create another custom table */
  const createDataNew = (
    action,
    name,
    range_1,
    range_2,
    range_3,
    range_4,
    range_5,
    range_6,
    range_7,
    per_km,
    toll,
    seller_discount,
    isEditMode,
    parent_id
  ) => ({
    id: name.replace(/ /g, "_"),
    action,
    name,
    range_1,
    range_2,
    range_3,
    range_4,
    range_5,
    range_6,
    range_7,
    per_km,
    toll,
    seller_discount,
    isEditMode: isEditMode,
    parent_id: parent_id,
    isClicked: false,
    parent: false,
  });

  const createRentalDataNew = (
    action,
    name,
    range_1,
    extra_hour_fare,
    extra_minute_fare,
    extra_km,
    isEditMode,
    parent_id
  ) => ({
    id: name.replace(/ /g, "_"),
    action,
    name,
    range_1,
    extra_hour_fare,
    extra_minute_fare,
    extra_km,
    isEditMode: isEditMode,
    parent_id: parent_id,
    isClicked: false,
    parent: false,
  });

  /**Create custom rental table */
  const createRentalData = (
    action,
    name,
    range_1,
    extra_hour_fare,
    extra_minute_fare,
    extra_km,
    isEditMode,
    parent_id,
    isClicked
  ) => ({
    id: name.replace(/ /g, "_"),
    action,
    name,
    range_1,
    extra_hour_fare,
    extra_minute_fare,
    extra_km,
    isEditMode: isEditMode,
    parent_id: parent_id,
    isClicked: isClicked,
    parent: true,
  });

  /**Function to modify table edit data */

  const onToggleEditMode = () => {
    if (rideType === stringsData.ridetype.air) {
      setRows((state) => {
        return rows.map((row) => {
          return { ...row, isEditMode: !row.isEditMode };
        });
      });
    } else {
      setRentalRows((state) => {
        return rentalRows.map((row) => {
          return { ...row, isEditMode: !row.isEditMode };
        });
      });
    }
  };

  /**Function to allow edits in table */
  const toggleSave = () => {
    setEditChange(!editChange);
    onToggleEditMode();
  };

  /**Functionality to disallow edits in table */
  const toggleSaveCancel = () => {
    setEditChange(!editChange);
    onToggleEditMode();
    callFareRetrieveAPI(rideType);
  };

  /**State initialization */
  const classes = useStyles();
  const [checkData, setCheckData] = React.useState([]);
  const [fullData, setFullData] = React.useState([]);
  const [dayOfWeek, setDayOfWeek] = React.useState(0);
  const [switchFare, setSwitchFare] = React.useState(false);
  const [airportWay, setAirportWay] = React.useState(
    stringsData.airporttype.to
  );
  const [vehicleType, setVehicleType] = React.useState(
    stringsData.vehicletype.bolt
  );
  const [rideType, setRideType] = React.useState(stringsData.ridetype.air);
  const [bookingFlow, setBookingFlow] = React.useState(stringsData.nostate);
  const [perMinuteEnabled, setPerMinuteEnabled] = React.useState(
    stringsData.nostate
  );
  const [editChange, setEditChange] = React.useState(stringsData.nostate);
  const [rows, setRows] = React.useState([
    createData(1, "12 AM to 6 AM", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
    createData(2, "6 AM to 11 AM", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
    createData(3, "11 AM to 4 PM", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
    createData(4, "4 PM to 9 PM", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
    createData(5, "9 PM to 12 AM", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
  ]);
  const [rentalRows, setRentalRows] = React.useState([
    createRentalData(1, "12 AM to 6 AM", 0, 0, 0, 0),
    createRentalData(2, "6 AM to 11 AM", 0, 0, 0, 0),
    createRentalData(3, "11 AM to 4 PM", 0, 0, 0, 0),
    createRentalData(4, "4 PM to 9 PM", 0, 0, 0, 0),
    createRentalData(5, "9 PM to 12 AM", 0, 0, 0, 0),
  ]);

  const [packageType, setPackageType] = React.useState("PKG_40_4");
  const [packageData, setPackageData] = React.useState([]);
  const [previous, setPrevious] = React.useState({});
  const [openSaveModal, setOpenSaveModal] = React.useState(false);
  const [valueUpdate, setValueUpdate] = React.useState("current_day");
  const [yourChips, setYourChips] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [Show, setShow] = React.useState(false);
  const [Showing, setShowing] = React.useState(false);
  const [openDutyModal, setOpenDutyModal] = React.useState(false);
  const [openDeactiveDuty, setDeactiveDuty] = React.useState(false);
  const [openMinute, setOpenMinute] = React.useState(false);
  const [openDeactiveMinute, setDeactiveMinute] = React.useState(false);
  // const [isPerMinute, setIsPerMinute] = React.useState(false);

  React.useEffect(() => {
    if (
      sessionStorage.getItem("user_id") === null ||
      sessionStorage.getItem("user_id") === undefined ||
      sessionStorage.getItem("user_id") === "" ||
      !stringsData.fareID.includes(window.sessionStorage.getItem("user_id"))
    ) {
      window.location.assign("/");
    }
  }, []);

  React.useEffect(() => {
    if (rideType === stringsData.ridetype.rent) {
      /**Function to get rental package list */
      getPackageList();
      setRows([]);
      // setPrevious({});
      setEditChange(false);
      retrieveRentalFareStatus();
      callFareRetrieveAPI(stringsData.ridetype.rent);
    } else {
      // console.log('called');
      // setPrevious({});
      callFareRetrieveAPI(stringsData.ridetype.air);
      retrieveFareStatus();
      setRows([]);
      setEditChange(false);
    }
  }, [rideType, vehicleType, airportWay, dayOfWeek, switchFare, packageType]);

  /**Function to get fare from server */
  const callFareRetrieveAPI = (ride_type) => {
    switch (ride_type) {
      case stringsData.ridetype.rent:
        getRentalFares();
        break;
      case stringsData.ridetype.air:
        getAirportFares();
        break;
      default:
        break;
    }
  };

  /**Function to get rental fares */
  const getRentalFares = () => {
    let dataToSend = {
      ride_type: rideType,
      vehicle_type: vehicleType,
      vendor_type: "mmt",
      travel_type: stringsData.ridetype.rent,
      day_of_week: getDayOfTheWeek(dayOfWeek),
      package_id: packageType,
      fare_type: "package",
    };
    // console.log(dataToSend);
    axios
      .post(config.url + "htmlcrm/v2/getvendorconfiguredfare", dataToSend)
      .then((response) => {
        console.log(response.data);
        if (response.data.message === stringsData.onunsuccess) {
          let defaultData = [
            createRentalData(
              1,
              "12 AM to 6 AM",
              0,
              0,
              0,
              0,
              false,
              "12 AM to 6 AM"
            ),
            createRentalData(
              2,
              "6 AM to 11 AM",
              0,
              0,
              0,
              0,
              false,
              "6 AM to 11 AM"
            ),
            createRentalData(
              3,
              "11 AM to 4 PM",
              0,
              0,
              0,
              0,
              false,
              "11 AM to 4 PM"
            ),
            createRentalData(
              4,
              "4 PM to 9 PM",
              0,
              0,
              0,
              0,
              false,
              "4 PM to 9 PM"
            ),
            createRentalData(
              5,
              "9 PM to 12 AM",
              0,
              0,
              0,
              0,
              false,
              "9 PM to 12 AM"
            ),
          ];
          setRentalRows(defaultData);
        } else {
          let data = response.data.result.config;
          let resdata = response.data.result;

          setCheckData(response.data.result);
          setFullData(response.data.result);
          let count = 0;
          let arrayData = [];
          let sl_no = 1;
          data.forEach((indData) => {
            if (
              dataToSend.vehicle_type === "sedan" ||
              dataToSend.vehicle_type === "hatchback" ||
              dataToSend.vehicle_type === "suv"
            ) {
              let range1 =
                indData.base_fare !== undefined ? indData.base_fare : 0;
              let extra_km = indData.extra_km;
              let extra_hour_fare =
                indData.extra_hour_fare !== undefined
                  ? indData.extra_hour_fare
                  : 0;
              let extra_minute_fare =
                indData.extra_minute_fare !== undefined
                  ? indData.extra_minute_fare
                  : 0;
              // let extra_hour =
              //   indData.extra_hour === undefined ? 0 : indData.extra_hour;
              let minimum_time = moment(indData.minimum_hour, "hh").format(
                "hh A"
              );
              let maximum_time = moment(indData.maximum_hour, "hh").format(
                "hh A"
              );
              let parent = indData.parent;
              let isClicked =
                indData.isClicked !== undefined ? indData.isClicked : false;
              let parent_id;
              if (parent === true || parent === undefined) {
                parent_id = minimum_time + " to " + maximum_time;
                arrayData.push(
                  createRentalData(
                    sl_no,
                    minimum_time + " to " + maximum_time,
                    range1,
                    extra_hour_fare,
                    extra_minute_fare,
                    extra_km,
                    false,
                    parent_id,
                    isClicked
                  )
                );
              } else {
                parent_id = indData.parent_id;
                arrayData.push(
                  createRentalDataNew(
                    sl_no,
                    minimum_time + " to " + maximum_time,
                    range1,
                    extra_hour_fare,
                    extra_minute_fare,
                    extra_km,
                    false,
                    parent_id
                  )
                );
              }
            } else {
              let range1 = indData.kms[0].base_fare;
              let extra_km = indData.extra_km;
              let extra_hour_fare = indData.extra_hour_fare;
              let extra_minute_fare = indData.extra_minute_fare;
              let minimum_time = moment(indData.minimum_hour, "hh").format(
                "hh A"
              );
              let maximum_time = moment(indData.maximum_hour, "hh").format(
                "hh A"
              );
              arrayData.push(
                createRentalData(
                  sl_no,
                  minimum_time + " to " + maximum_time,
                  range1,
                  extra_hour_fare,
                  extra_minute_fare,
                  extra_km
                )
              );
            }
            sl_no++;
            count++;
            if (count === data.length) {
              // console.log(arrayData);
              setRentalRows(arrayData);
              setPerMinuteEnabled(resdata.is_per_hour_enabled);
            }
          });
        }
      });
  };

  /**Function to get airport fares */
  const getAirportFares = () => {
    switchFare === true
      ? getPerKmFares(stringsData.faretype.perkm)
      : getSlabFares(stringsData.faretype.slab);
  };

  /**Function to get per km fares */
  const getPerKmFares = () => {
    let dataToSend = {
      fare_type: stringsData.faretype.perkm,
      ride_type: rideType,
      vehicle_type: vehicleType,
      vendor_type: "mmt",
      travel_type: airportWay,
      day_of_week: getDayOfTheWeek(dayOfWeek),
    };
    // console.log(dataToSend);
    axios
      .post(config.url + "htmlcrm/v2/getvendorconfiguredfare", dataToSend)
      .then((response) => {
        // console.log(response.data);
        if (response.data.message === stringsData.onsuccess) {
          let data = response.data.result.config;
          setCheckData(response.data.result);
          setFullData(response.data.result);
          let count = 0;
          let arrayData = [];
          let sl_no = 1;
          data.forEach((indData) => {
            let range1 =
              indData.kms[0] !== undefined ? indData.kms[0].per_km_rate : 0;
            let range2 = indData.km_upto !== undefined ? indData.km_upto : 0;
            let range3 =
              indData.airport_toll_charges !== undefined
                ? indData.airport_toll_charges
                : 0;
            let range4 =
              indData.prorated_km_rate !== undefined
                ? indData.prorated_km_rate
                : 0;
            let range5 =
              indData.seller_discount !== undefined
                ? indData.seller_discount
                : 0;

            // let extra_km = indData.prorated_km_rate;
            let range6 = range1 * range2 + range3;
            let range7 = 0;
            let extra_km = 0;
            let toll = 0;
            let seller_discount = 0;
            let minimum_time = moment(indData.minimum_hour, "hh").format(
              "hh A"
            );
            let maximum_time = moment(indData.maximum_hour, "hh").format(
              "hh A"
            );
            // console.log(minimum_time);
            // console.log(maximum_time);
            let parent = indData.parent;
            let parent_id;
            let isClicked =
              indData.isClicked !== undefined ? indData.isClicked : false;
            if (parent === true || parent === undefined) {
              parent_id = minimum_time + " to " + maximum_time;
              arrayData.push(
                createData(
                  sl_no,
                  minimum_time + " to " + maximum_time,
                  range1,
                  range2,
                  range3,
                  range4,
                  range5,
                  range6,
                  range7,
                  extra_km,
                  toll,
                  seller_discount,
                  false,
                  parent_id,
                  isClicked
                )
              );
              // console.log(arrayData);
            } else {
              let parent_id = indData.parent_id;
              arrayData.push(
                createDataNew(
                  sl_no,
                  minimum_time + " to " + maximum_time,
                  range1,
                  range2,
                  range3,
                  range4,
                  range5,
                  range6,
                  range7,
                  extra_km,
                  toll,
                  seller_discount,
                  false,
                  parent_id
                )
              );
              // console.log(arrayData);
            }
            sl_no++;
            count++;
            if (count === data.length) {
              // console.log(arrayData);
              setRows(arrayData);
            }
          });
        } else {
          if (vehicleType === "suvundefined") {
            setFullData([]);
            setCheckData([]);
            let sl_no = 1;
            setRows([
              createData(
                sl_no,
                "12 AM to 6 AM",
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                false,
                "12 AM to 6 AM"
              ),
            ]);
          } else {
            setFullData([]);
            setCheckData([]);
            let defaultData = [
              createData(
                1,
                "12 AM to 6 AM",
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                false,
                "12 AM to 6 AM",
                false
              ),
              createData(
                2,
                "6 AM to 11 AM",
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                false,
                "6 AM to 11 AM",
                false
              ),
              createData(
                3,
                "11 AM to 4 PM",
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                false,
                "11 AM to 4 PM",
                false
              ),
              createData(
                4,
                "4 PM to 9 PM",
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                false,
                "4 PM to 9 PM",
                false
              ),
              createData(
                5,
                "9 PM to 12 AM",
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                false,
                "9 PM to 12 AM",
                false
              ),
            ];
            setRows(defaultData);
          }
        }
      })
      .catch();
  };

  /**Function to get slab fares */
  const getSlabFares = () => {
    let dataToSend = {
      fare_type: stringsData.faretype.slab,
      ride_type: rideType,
      vehicle_type: vehicleType,
      vendor_type: "mmt",
      travel_type: airportWay,
      day_of_week: getDayOfTheWeek(dayOfWeek),
    };

    axios
      .post(config.url + "htmlcrm/v2/getvendorconfiguredfare", dataToSend)
      .then((response) => {
        // console.log(response.data);
        if (response.data.message === stringsData.onsuccess) {
          let data = response.data.result.config;
          setCheckData(response.data.result);
          setFullData(response.data.result);
          let count = 0;
          let arrayData = [];
          let sl_no = 1;
          data.forEach((indData) => {
            if (
              (vehicleType === "sedan" ||
                vehicleType === "hatchback" ||
                vehicleType === "suv") &&
              airportWay === "to_airport"
            ) {
              let range1 =
                indData.kms[0] !== undefined ? indData.kms[0].base_fare : 0;
              let range2 =
                indData.kms[1] !== undefined ? indData.kms[1].base_fare : 0;
              let range3 =
                indData.kms[2] !== undefined ? indData.kms[2].base_fare : 0;
              let range4 =
                indData.kms[3] !== undefined ? indData.kms[3].base_fare : 0;
              let range5 =
                indData.kms[4] !== undefined ? indData.kms[4].base_fare : 0;
              let range6 =
                indData.kms[5] !== undefined ? indData.kms[5].base_fare : 0;
              let range7 =
                indData.kms[6] !== undefined ? indData.kms[6].base_fare : 0;
              let extra_km = indData.extra_km;
              let toll =
                indData.airport_toll_charges !== undefined
                  ? indData.airport_toll_charges
                  : 0;
              let seller_discount =
                indData.seller_discount !== undefined
                  ? indData.seller_discount
                  : 0;
              let minimum_time = moment(indData.minimum_hour, "hh").format(
                "hh A"
              );
              let maximum_time = moment(indData.maximum_hour, "hh").format(
                "hh A"
              );
              let parent = indData.parent;
              let parent_id;
              let isClicked =
                indData.isClicked !== undefined ? indData.isClicked : false;
              if (parent === true || parent === undefined) {
                parent_id = minimum_time + " to " + maximum_time;
                arrayData.push(
                  createData(
                    sl_no,
                    minimum_time + " to " + maximum_time,
                    range1,
                    range2,
                    range3,
                    range4,
                    range5,
                    range6,
                    range7,
                    extra_km,
                    toll,
                    seller_discount,
                    false,
                    parent_id,
                    isClicked
                  )
                );
              } else {
                parent_id = indData.parent_id;
                arrayData.push(
                  createDataNew(
                    sl_no,
                    minimum_time + " to " + maximum_time,
                    range1,
                    range2,
                    range3,
                    range4,
                    range5,
                    range6,
                    range7,
                    extra_km,
                    toll,
                    seller_discount,
                    false,
                    parent_id
                  )
                );
              }
              // console.log(minimum_time);
              // console.log(maximum_time);
            } else if (
              (vehicleType === "sedan" ||
                vehicleType === "hatchback" ||
                vehicleType === "suv") &&
              airportWay === "from_airport"
            ) {
              let range1 =
                indData.kms[0] !== undefined ? indData.kms[0].base_fare : 0;
              let range2 =
                indData.kms[1] !== undefined ? indData.kms[1].base_fare : 0;
              let range3 =
                indData.kms[2] !== undefined ? indData.kms[2].base_fare : 0;
              let range4 =
                indData.kms[3] !== undefined ? indData.kms[3].base_fare : 0;
              let range5 =
                indData.kms[4] !== undefined ? indData.kms[4].base_fare : 0;
              let range6 =
                indData.kms[5] !== undefined ? indData.kms[5].base_fare : 0;
              let range7 =
                indData.kms[6] !== undefined ? indData.kms[6].base_fare : 0;
              let extra_km = indData.extra_km;
              let minimum_time = moment(indData.minimum_hour, "hh").format(
                "hh A"
              );
              let maximum_time = moment(indData.maximum_hour, "hh").format(
                "hh A"
              );
              let toll =
                indData.airport_toll_charges !== undefined
                  ? indData.airport_toll_charges
                  : 0;
              let seller_discount =
                indData.seller_discount !== undefined
                  ? indData.seller_discount
                  : 0;
              // console.log(minimum_time);
              // console.log(maximum_time);
              let parent = indData.parent;
              let parent_id;
              let isClicked =
                indData.isClicked !== undefined ? indData.isClicked : false;
              if (parent === true || parent === undefined) {
                parent_id = minimum_time + " to " + maximum_time;
                arrayData.push(
                  createData(
                    sl_no,
                    minimum_time + " to " + maximum_time,
                    range1,
                    range2,
                    range3,
                    range4,
                    range5,
                    range6,
                    range7,
                    extra_km,
                    toll,
                    seller_discount,
                    false,
                    parent_id,
                    isClicked
                  )
                );
              } else {
                let parent_id = indData.parent_id;
                arrayData.push(
                  createDataNew(
                    sl_no,
                    minimum_time + " to " + maximum_time,
                    range1,
                    range2,
                    range3,
                    range4,
                    range5,
                    range6,
                    range7,
                    extra_km,
                    toll,
                    seller_discount,
                    false,
                    parent_id
                  )
                );
              }
            } else {
              let range1 = 0;
              let range2 = 0;
              let range3 = 0;
              let range4 = 0;
              let range5 = 0;
              let range6 = indData.kms[0].base_fare;
              let range7 = 0;
              let extra_km = indData.extra_km;
              let toll =
                indData.airport_toll_charges !== undefined
                  ? indData.airport_toll_charges
                  : 0;
              let seller_discount =
                indData.seller_discount !== undefined
                  ? indData.seller_discount
                  : 0;
              let minimum_time = moment(indData.minimum_hour, "hh").format(
                "hh A"
              );
              let maximum_time = moment(indData.maximum_hour, "hh").format(
                "hh A"
              );
              arrayData.push(
                createData(
                  sl_no,
                  minimum_time + " to " + maximum_time,
                  range1,
                  range2,
                  range3,
                  range4,
                  range5,
                  range6,
                  range7,
                  extra_km,
                  toll,
                  seller_discount,
                  false
                )
              );
            }
            sl_no++;
            count++;
            if (count === data.length) {
              // console.log(arrayData);
              setRows(arrayData);
            }
          });
        } else {
          if (vehicleType === "suvundefined") {
            setFullData([]);
            setCheckData([]);
            let sl_no = 1;
            setRows([
              createData(
                sl_no,
                "12 AM to 6 AM",
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                false,
                "12 AM to 6 AM",
                false
              ),
            ]);
          } else {
            setFullData([]);
            setCheckData([]);
            let defaultData = [
              createData(
                1,
                "12 AM to 6 AM",
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                false,
                "12 AM to 6 AM",
                false
              ),
              createData(
                2,
                "6 AM to 11 AM",
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                false,
                "6 AM to 11 AM",
                false
              ),
              createData(
                3,
                "11 AM to 4 PM",
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                false,
                "11 AM to 4 PM",
                false
              ),
              createData(
                4,
                "4 PM to 9 PM",
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                false,
                "4 PM to 9 PM",
                false
              ),
              createData(
                5,
                "9 PM to 12 AM",
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                false,
                "9 PM to 12 AM",
                false
              ),
            ];
            setRows(defaultData);
          }
        }
      })
      .catch();
  };

  /**Function to get package list */
  const getPackageList = () => {
    axios
      .get(config.url + "booking/getRentalPackages")
      .then((response) => {
        // console.log(response.data);
        if (response) {
          setPackageData(response.data.packages);
        }
      })
      .catch();
  };

  /**Function to save fareconfig data */

  const saveData = (e) => {
    if (e) {
      e.preventDefault();
    }
    setOpenSaveModal(true);
  };

  /**Function to close save modal */
  const handleCloseSaveModal = () => {
    setOpenSaveModal(false);
    setYourChips([]);
    setValueUpdate("current_day");
  };

  /**Function to handle chip data */
  const handleAddChip = (data) => {
    setYourChips(data);
  };

  /**Function to check and trigger single update or multiple updates */
  const updateFare = (data) => {
    if (valueUpdate === "current_day") {
      // console.log("here");
      updateIndiviualFare(data);
    } else {
      // console.log("many");
      updateMoreFares(data);
    }
  };

  /**Function to update individual fares */
  const updateIndiviualFare = () => {
    if (
      sessionStorage.getItem("user_id") === null ||
      sessionStorage.getItem("user_id") === undefined ||
      sessionStorage.getItem("user_id") === "" ||
      !stringsData.fareID.includes(window.sessionStorage.getItem("user_id"))
    ) {
      window.location.assign("/");
    }
    switch (rideType) {
      case stringsData.ridetype.air:
        updateIndividualAirportFare();
        break;
      case stringsData.ridetype.rent:
        updateIndividualRentalFare();
        break;
      default:
        break;
    }
  };

  /**Function to update multiple fares */
  const updateMoreFares = () => {
    if (
      sessionStorage.getItem("user_id") === null ||
      sessionStorage.getItem("user_id") === undefined ||
      sessionStorage.getItem("user_id") === "" ||
      !stringsData.fareID.includes(window.sessionStorage.getItem("user_id"))
    ) {
      window.location.assign("/");
    }
    switch (rideType) {
      case stringsData.ridetype.air:
        updateMultiAirportFare();
        break;
      case stringsData.ridetype.rent:
        updateMultiRentalFare();
        break;
      default:
        break;
    }
  };

  /**Function to update individual airport fares */
  const updateIndividualAirportFare = () => {
    // console.log(checkData);
    if (switchFare === true) {
      let dataToCheck = rows;
      let configs = [];
      let count = 0;
      let data;
      let minimim_hour;
      let maximum_hour;
      dataToCheck.forEach((dataToFormat) => {
        let timeData = dataToFormat.name;
        if (typeof timeData === "string") {
          let splitTimeData = timeData.split("to");
          minimim_hour = moment(splitTimeData[0], "hh A").format("HH");
          maximum_hour = moment(splitTimeData[1], "hh A").format("HH");
        } else {
          minimim_hour = moment(timeData[0], "hh:mm").format("HH");
          maximum_hour = moment(timeData[1], "hh:mm").format("HH");
        }

        if (
          vehicleType === "sedan" ||
          vehicleType === "hatchback" ||
          vehicleType === "suv"
        ) {
          data = {
            parent: dataToFormat.parent,
            isClicked: dataToFormat.isClicked,
            parent_id: dataToFormat.parent_id
              ? dataToFormat.parent_id
              : dataToFormat.id,
            minimum_hour: minimim_hour * 1,
            maximum_hour: maximum_hour * 1,
            per_km_rate: dataToFormat.range_1 * 1,
            km_upto: dataToFormat.range_2 * 1,
            airport_toll_charges: dataToFormat.range_3 * 1,
            prorated_km_rate: dataToFormat.range_4 * 1,
            seller_discount: dataToFormat.range_5 * 1,
            kms: [
              {
                min_km: 0,
                max_km: dataToFormat.range_2 * 1,
                per_km_rate: dataToFormat.range_1 * 1,
                base_fare: 0,
              },
            ],
          };
        } else {
          data = {
            parent: true,
            parent_id: "none",
            minimum_hour: 0 * 1,
            maximum_hour: 0 * 1,
            per_km_rate: dataToFormat.range_1 * 1,
            km_upto: dataToFormat.range_2 * 1,
            airport_toll_charges: dataToFormat.range_3 * 1,
            prorated_km_rate: dataToFormat.range_4 * 1,
            seller_discount: dataToFormat.range_5 * 1,
            kms: [
              {
                min_km: 0,
                max_km: dataToFormat.range_2 * 1,
                per_km_rate: dataToFormat.range_1 * 1,
                base_fare: 0,
              },
            ],
          };
        }
        configs.push(data);
        count++;
        if (count === rows.length) {
          let max_distance = dataToFormat.range_2 * 1;

          let days;

          let dataSend = {
            ride_type: "airport",
            user_id: sessionStorage.getItem("user_id"),
            vehicle_type: vehicleType,
            vendor_type: "mmt",
            travel_type: airportWay,
            day_of_week: getDayOfTheWeek(dayOfWeek),
            fare_type: "per_km",
            // fare_status: checkedA === true ? "Active" : "Inactive",
            config_old: checkData.config,
            max_distance: max_distance * 1,
            config: configs,
            // config_fare: {
            //   max_distance: max_distance * 1,
            //   type: value,
            //   days: days,
            //   config: configs,
            // },
          };
          // console.log(dataSend);
          axios
            .post(
              config.url + "htmlcrm/v2/updatevendorconfiguredfare",
              dataSend
            )
            .then((response) => {
              // console.log(response);
              if (response.data.message === "successfull") {
                callFareRetrieveAPI(rideType);
                setEditChange(false);
                // setpenSaveModal(false);
                handleCloseSaveModal();
                //  handleDayEdit("", dataSend.day_of_week);
                // retrieveFareData();
                setMessage("Fare changes updated successfully");
                setSuccess(true);
                show();
                // setSuccess(true);
              } else if (
                response.data.message === "unsuccessfull" &&
                response.data.segregatedData === null
              ) {
                callFareRetrieveAPI(rideType);
                setEditChange(false);
                // setpenSaveModal(false);
                handleCloseSaveModal();
                //  handleDayEdit("", dataSend.day_of_week);
                // retrieveFareData();
                setMessage("Fare changes updated successfully");
                setSuccess(true);
                show();
              } else {
                callFareRetrieveAPI(rideType);
                // setEditChange(false)
                //  retrieveFareData();
                setSuccess(false);
                setMessage("Failed to update fares");
                show();
                // setSuccess(false);
              }
            });

          // console.log(dataToSend);
        }
      });
    } else {
      let dataToCheck = rows;
      let configs = [];
      let count = 0;
      let data;
      let minimim_hour;
      let maximum_hour;
      dataToCheck.forEach((dataToFormat) => {
        let timeData = dataToFormat.name;
        if (typeof timeData === "string") {
          let splitTimeData = timeData.split("to");
          minimim_hour = moment(splitTimeData[0], "hh A").format("HH");
          maximum_hour = moment(splitTimeData[1], "hh A").format("HH");
        } else {
          minimim_hour = moment(timeData[0], "hh:mm").format("HH");
          maximum_hour = moment(timeData[1], "hh:mm").format("HH");
        }

        if (
          (vehicleType === "hatchback" || vehicleType === "suv") &&
          airportWay === "to_airport"
        ) {
          // vehicleType === "sedan" ||
          data = {
            parent: dataToFormat.parent,
            isClicked: dataToFormat.isClicked,
            parent_id: dataToFormat.parent_id
              ? dataToFormat.parent_id
              : dataToFormat.id,
            minimum_hour: minimim_hour * 1,
            maximum_hour: maximum_hour * 1,
            extra_km: dataToFormat.per_km * 1,
            airport_toll_charges: dataToFormat.toll * 1,
            seller_discount: dataToFormat.seller_discount * 1,
            kms: [
              {
                min_km: 0,
                max_km: 20,
                base_fare: dataToFormat.range_1 * 1,
              },
              {
                min_km: 20,
                max_km: 25,
                base_fare: dataToFormat.range_2 * 1,
              },
              {
                min_km: 25,
                max_km: 30,
                base_fare: dataToFormat.range_3 * 1,
              },
              {
                min_km: 30,
                max_km: 35,
                base_fare: dataToFormat.range_4 * 1,
              },
              {
                min_km: 35,
                max_km: 40,
                base_fare: dataToFormat.range_5 * 1,
              },
              {
                min_km: 40,
                max_km: 45,
                base_fare: dataToFormat.range_6 * 1,
              },
              {
                min_km: 45,
                max_km: 50,
                base_fare: dataToFormat.range_7 * 1,
              },
            ],
          };
        } else if (
          (vehicleType === "sedan" ||
            vehicleType === "hatchback" ||
            vehicleType === "suv") &&
          airportWay === "from_airport"
        ) {
          data = {
            parent: dataToFormat.parent,
            isClicked: dataToFormat.isClicked,
            parent_id: dataToFormat.parent_id
              ? dataToFormat.parent_id
              : dataToFormat.id,
            minimum_hour: minimim_hour * 1,
            maximum_hour: maximum_hour * 1,
            extra_km: dataToFormat.per_km * 1,
            airport_toll_charges: dataToFormat.toll * 1,
            seller_discount: dataToFormat.seller_discount * 1,
            kms: [
              {
                min_km: 0,
                max_km: 10,
                base_fare: dataToFormat.range_1 * 1,
              },
              {
                min_km: 10,
                max_km: 20,
                base_fare: dataToFormat.range_2 * 1,
              },
              {
                min_km: 20,
                max_km: 30,
                base_fare: dataToFormat.range_3 * 1,
              },
              {
                min_km: 30,
                max_km: 35,
                base_fare: dataToFormat.range_4 * 1,
              },
              {
                min_km: 35,
                max_km: 40,
                base_fare: dataToFormat.range_5 * 1,
              },
              {
                min_km: 40,
                max_km: 45,
                base_fare: dataToFormat.range_6 * 1,
              },
            ],
          };
        } else {
          data = {
            parent: dataToFormat.parent,
            isClicked: dataToFormat.isClicked,
            parent_id: dataToFormat.parent_id
              ? dataToFormat.parent_id
              : dataToFormat.id,
            minimum_hour: minimim_hour * 1,
            maximum_hour: maximum_hour * 1,
            extra_km: dataToFormat.per_km * 1,
            airport_toll_charges: dataToFormat.toll * 1,
            seller_discount: dataToFormat.seller_discount * 1,
            kms: [
              {
                min_km: 0,
                max_km: 20,
                base_fare: dataToFormat.range_1 * 1,
              },
              {
                min_km: 20,
                max_km: 25,
                base_fare: dataToFormat.range_2 * 1,
              },
              {
                min_km: 25,
                max_km: 30,
                base_fare: dataToFormat.range_3 * 1,
              },
              {
                min_km: 30,
                max_km: 35,
                base_fare: dataToFormat.range_4 * 1,
              },
              {
                min_km: 35,
                max_km: 40,
                base_fare: dataToFormat.range_5 * 1,
              },
              {
                min_km: 40,
                max_km: 45,
                base_fare: dataToFormat.range_6 * 1,
              },
            ],
          };
        }
        configs.push(data);
        count++;
        if (count === rows.length) {
          let max_distance;

          if (
            (vehicleType === "hatchback" || vehicleType === "suv") &&
            airportWay === "to_airport"
          ) {
            // vehicleType === "sedan" ||
            max_distance = 50;
          } else {
            max_distance = 45;
            // max_distance =
            //   fullData.max_distance === undefined ? 45 : fullData.max_distance;
          }

          let days;

          let dataSend = {
            ride_type: "airport",
            user_id: sessionStorage.getItem("user_id"),
            vehicle_type: vehicleType,
            vendor_type: "mmt",
            travel_type: airportWay,
            day_of_week: getDayOfTheWeek(dayOfWeek),
            fare_type: "slab",
            // fare_status: checkedA === true ? "Active" : "Inactive",
            config_old: checkData.config,
            max_distance: max_distance * 1,
            config: configs,
          };
          // console.log(dataSend);
          axios
            .post(
              config.url + "htmlcrm/v2/updatevendorconfiguredfare",
              dataSend
            )
            .then((response) => {
              // console.log(response);
              if (response.data.message === "successfull") {
                callFareRetrieveAPI(rideType);
                setEditChange(false);
                // setpenSaveModal(false);
                handleCloseSaveModal();
                //  handleDayEdit("", dataSend.day_of_week);
                // retrieveFareData();
                setMessage("Fare changes updated successfully");
                setSuccess(true);
                show();
                // setSuccess(true);
              } else if (
                response.data.message === "unsuccessfull" &&
                response.data.segregatedData === null
              ) {
                callFareRetrieveAPI(rideType);
                setEditChange(false);
                // setpenSaveModal(false);
                handleCloseSaveModal();
                //  handleDayEdit("", dataSend.day_of_week);
                // retrieveFareData();
                setMessage("Fare changes updated successfully");
                setSuccess(true);
                show();
              } else {
                callFareRetrieveAPI(rideType);
                // setEditChange(false)
                //  retrieveFareData();
                setSuccess(false);
                setMessage("Failed to update fares");
                show();
                // setSuccess(false);
              }
            });

          // console.log(dataToSend);
        }
      });
    }
  };

  /**Function to update individual rental fares */
  const updateIndividualRentalFare = () => {
    let dataToCheck = rentalRows;
    let configs = [];
    let count = 0;
    let data;
    let minimim_hour;
    let maximum_hour;

    dataToCheck.forEach((dataToFormat) => {
      let timeData = dataToFormat.name;
      if (typeof timeData === "string") {
        let splitTimeData = timeData.split("to");
        minimim_hour = moment(splitTimeData[0], "hh A").format("HH");
        maximum_hour = moment(splitTimeData[1], "hh A").format("HH");
      } else {
        minimim_hour = moment(timeData[0], "hh:mm").format("HH");
        maximum_hour = moment(timeData[1], "hh:mm").format("HH");
      }
      if (
        vehicleType === "sedan" ||
        vehicleType === "hatchback" ||
        vehicleType === "suv"
      ) {
        data = {
          parent: dataToFormat.parent,
          isClicked: dataToFormat.isClicked,
          parent_id: dataToFormat.parent_id
            ? dataToFormat.parent_id
            : dataToFormat.id,
          base_fare: dataToFormat.range_1 * 1,
          minimum_hour: minimim_hour * 1,
          maximum_hour: maximum_hour * 1,
          extra_km: dataToFormat.extra_km * 1,
          // extra_hour: dataToFormat.extra_hour * 1,
          extra_hour_fare:
            dataToFormat.extra_hour_fare !== undefined
              ? dataToFormat.extra_hour_fare * 1
              : 0,
          extra_minute_fare:
            dataToFormat.extra_minute_fare !== undefined
              ? dataToFormat.extra_minute_fare * 1
              : 0,
        };
      }
      console.log(data);
      configs.push(data);
      count++;
      if (count === dataToCheck.length) {
        let max_distance = 0;
        let dataSend = {
          ride_type: rideType,
          user_id: sessionStorage.getItem("user_id"),
          vehicle_type: vehicleType,
          vendor_type: "mmt",
          travel_type: rideType,
          day_of_week: getDayOfTheWeek(dayOfWeek),
          // fare_status: checkedA === true ? "Active" : "Inactive",
          config_old: checkData.config,
          fare_type: "package",
          max_distance: max_distance * 1,
          config: configs,
          package_info: getPackageInfo(packageType),
          package_id: packageType,
          is_per_hour_enabled: perMinuteEnabled,
        };
        console.log(dataSend);
        axios
          .post(config.url + "htmlcrm/v2/updatevendorconfiguredfare", dataSend)
          .then((response) => {
            // console.log(response);
            if (response.data.message === "successfull") {
              callFareRetrieveAPI(rideType);
              setEditChange(false);
              // setpenSaveModal(false);
              handleCloseSaveModal();
              //  handleDayEdit("", dataSend.day_of_week);
              // retrieveFareData();
              setMessage("Fare changes updated successfully");
              setSuccess(true);
              show();
              // setSuccess(true);
            } else if (
              response.data.message === "unsuccessfull" &&
              response.data.segregatedData === null
            ) {
              callFareRetrieveAPI(rideType);
              setEditChange(false);
              // setpenSaveModal(false);
              handleCloseSaveModal();
              //  handleDayEdit("", dataSend.day_of_week);
              // retrieveFareData();
              setMessage("Fare changes updated successfully");
              setSuccess(true);
              show();
            } else {
              callFareRetrieveAPI(rideType);
              // setEditChange(false)
              //  retrieveFareData();
              setSuccess(false);
              setMessage("Failed to update fares");
              show();
              // setSuccess(false);
            }
          });
      }
    });
  };
  /**Function to update multiple airport fares */
  const updateMultiAirportFare = () => {
    let max_distance;
    if (switchFare === true) {
      let daysToCheck = yourChips;
      if (daysToCheck && daysToCheck.length > 0) {
        let j = 0;
        for (let i = 0; i < daysToCheck.length; i++) {
          let dataToCheck = rows;
          let configs = [];
          let count = 0;
          let data;
          let minimim_hour;
          let maximum_hour;
          dataToCheck.forEach((dataToFormat) => {
            let timeData = dataToFormat.name;
            if (typeof timeData === "string") {
              let splitTimeData = timeData.split("to");
              minimim_hour = moment(splitTimeData[0], "hh A").format("HH");
              maximum_hour = moment(splitTimeData[1], "hh A").format("HH");
            } else {
              minimim_hour = moment(timeData[0], "hh:mm").format("HH");
              maximum_hour = moment(timeData[1], "hh:mm").format("HH");
            }

            if (
              vehicleType === "sedan" ||
              vehicleType === "hatchback" ||
              vehicleType === "suv"
            ) {
              data = {
                parent: dataToFormat.parent,
                isClicked: dataToFormat.isClicked,
                parent_id: dataToFormat.parent_id
                  ? dataToFormat.parent_id
                  : dataToFormat.id,
                minimum_hour: minimim_hour * 1,
                maximum_hour: maximum_hour * 1,
                per_km_rate: dataToFormat.range_1 * 1,
                km_upto: dataToFormat.range_2 * 1,
                airport_toll_charges: dataToFormat.range_3 * 1,
                prorated_km_rate: dataToFormat.range_4 * 1,
                seller_discount: dataToFormat.range_5 * 1,
                kms: [
                  {
                    min_km: 0,
                    max_km: dataToFormat.range_2 * 1,
                    per_km_rate: dataToFormat.range_1 * 1,
                    base_fare: 0,
                  },
                ],
              };
            } else {
              data = {
                parent: dataToFormat.parent,
                parent_id: dataToFormat.parent_id
                  ? dataToFormat.parent_id
                  : dataToFormat.id,
                minimum_hour: 0 * 1,
                maximum_hour: 0 * 1,
                per_km_rate: dataToFormat.range_1 * 1,
                km_upto: dataToFormat.range_2 * 1,
                airport_toll_charges: dataToFormat.range_3 * 1,
                prorated_km_rate: dataToFormat.range_4 * 1,
                seller_discount: dataToFormat.range_5 * 1,
                kms: [
                  {
                    min_km: 0,
                    max_km: dataToFormat.range_2 * 1,
                    per_km_rate: dataToFormat.range_1 * 1,
                    base_fare: 0,
                  },
                ],
              };
            }
            configs.push(data);
            count++;
            if (count === rows.length) {
              let days;
              // if (fullData.days === undefined || fullData.days.length === 0) {
              //   days =
              //     value === "weekdays"
              //       ? ["monday", "tuesday", "wednesday", "thursday"]
              //       : ["friday", "saturday", "sunday"];
              // } else {
              //   days = fullData.days;
              // }
              // console.log(checkedA);
              let dataSend = {
                ride_type: "airport",
                user_id: sessionStorage.getItem("user_id"),
                vehicle_type: vehicleType,
                vendor_type: "mmt",
                travel_type: airportWay,
                day_of_week: daysToCheck[i].toLowerCase(),
                // fare_status: checkedA === true ? "Active" : "Inactive",
                config_old: checkData.config,
                max_distance: max_distance * 1,
                fare_type: "per_km",
                config: configs,
                // config_fare: {
                //   max_distance: max_distance * 1,
                //   type: value,
                //   days: days,
                //   config: configs,
                // },
              };
              // console.log(dataSend);
              axios
                .post(
                  config.url + "htmlcrm/v2/updatevendorconfiguredfare",
                  dataSend
                )
                .then((response) => {
                  // console.log(response);
                  if (response.data.message === "successfull") {
                    //  handleDayEdit("", dataSend.day_of_week);
                    //  retrieveFareData();
                    // setMessage("Fare changes updated successfully");
                    // show();
                    // setSuccess(true);
                  } else {
                    //  retrieveFareData();
                    // setMessage("Failed to update fares");
                    // show();
                    // setSuccess(false);
                  }
                });

              // console.log(dataToSend);
            }
          });
          j++;
        }
        if (j === daysToCheck.length) {
          callFareRetrieveAPI(rideType);
          setEditChange(false);
          handleCloseSaveModal();
          setMessage("Fare changes updated successfully");
          setSuccess(true);
          show();
        }
      } else {
        setMessage("Days cannot be empty");
        setSuccess(false);
        show();
      }
    } else {
      let daysToCheck = yourChips;
      if (daysToCheck && daysToCheck.length > 0) {
        let j = 0;
        for (let i = 0; i < daysToCheck.length; i++) {
          let dataToCheck = rows;
          let configs = [];
          let count = 0;
          let data;
          let minimim_hour;
          let maximum_hour;
          dataToCheck.forEach((dataToFormat) => {
            let timeData = dataToFormat.name;
            if (typeof timeData === "string") {
              let splitTimeData = timeData.split("to");
              minimim_hour = moment(splitTimeData[0], "hh A").format("HH");
              maximum_hour = moment(splitTimeData[1], "hh A").format("HH");
            } else {
              minimim_hour = moment(timeData[0], "hh:mm").format("HH");
              maximum_hour = moment(timeData[1], "hh:mm").format("HH");
            }

            if (
              (vehicleType === "hatchback" || vehicleType === "suv") &&
              airportWay === "to_airport"
            ) {
              // vehicleType === "sedan" ||
              data = {
                parent: dataToFormat.parent,
                isClicked: dataToFormat.isClicked,
                parent_id: dataToFormat.parent_id
                  ? dataToFormat.parent_id
                  : dataToFormat.id,
                minimum_hour: minimim_hour * 1,
                maximum_hour: maximum_hour * 1,
                extra_km: dataToFormat.per_km * 1,
                airport_toll_charges: dataToFormat.toll * 1,
                seller_discount: dataToFormat.seller_discount * 1,
                kms: [
                  {
                    min_km: 0,
                    max_km: 20,
                    base_fare: dataToFormat.range_1 * 1,
                  },
                  {
                    min_km: 20,
                    max_km: 25,
                    base_fare: dataToFormat.range_2 * 1,
                  },
                  {
                    min_km: 25,
                    max_km: 30,
                    base_fare: dataToFormat.range_3 * 1,
                  },
                  {
                    min_km: 30,
                    max_km: 35,
                    base_fare: dataToFormat.range_4 * 1,
                  },
                  {
                    min_km: 35,
                    max_km: 40,
                    base_fare: dataToFormat.range_5 * 1,
                  },
                  {
                    min_km: 40,
                    max_km: 45,
                    base_fare: dataToFormat.range_6 * 1,
                  },
                  {
                    min_km: 45,
                    max_km: 50,
                    base_fare: dataToFormat.range_7 * 1,
                  },
                ],
              };
            } else if (
              (vehicleType === "sedan" ||
                vehicleType === "hatchback" ||
                vehicleType === "suv") &&
              airportWay === "from_airport"
            ) {
              data = {
                parent: dataToFormat.parent,
                isClicked: dataToFormat.isClicked,
                parent_id: dataToFormat.parent_id
                  ? dataToFormat.parent_id
                  : dataToFormat.id,
                minimum_hour: minimim_hour * 1,
                maximum_hour: maximum_hour * 1,
                extra_km: dataToFormat.per_km * 1,
                airport_toll_charges: dataToFormat.toll * 1,
                seller_discount: dataToFormat.seller_discount * 1,
                kms: [
                  {
                    min_km: 0,
                    max_km: 10,
                    base_fare: dataToFormat.range_1 * 1,
                  },
                  {
                    min_km: 10,
                    max_km: 20,
                    base_fare: dataToFormat.range_2 * 1,
                  },
                  {
                    min_km: 20,
                    max_km: 30,
                    base_fare: dataToFormat.range_3 * 1,
                  },
                  {
                    min_km: 30,
                    max_km: 35,
                    base_fare: dataToFormat.range_4 * 1,
                  },
                  {
                    min_km: 35,
                    max_km: 40,
                    base_fare: dataToFormat.range_5 * 1,
                  },
                  {
                    min_km: 40,
                    max_km: 45,
                    base_fare: dataToFormat.range_6 * 1,
                  },
                ],
              };
            } else {
              data = {
                parent: dataToFormat.parent,
                isClicked: dataToFormat.isClicked,
                parent_id: dataToFormat.parent_id
                  ? dataToFormat.parent_id
                  : dataToFormat.id,
                minimum_hour: minimim_hour * 1,
                maximum_hour: maximum_hour * 1,
                extra_km: dataToFormat.per_km * 1,
                airport_toll_charges: dataToFormat.toll * 1,
                seller_discount: dataToFormat.seller_discount * 1,
                kms: [
                  {
                    min_km: 0,
                    max_km: 20,
                    base_fare: dataToFormat.range_1 * 1,
                  },
                  {
                    min_km: 20,
                    max_km: 25,
                    base_fare: dataToFormat.range_2 * 1,
                  },
                  {
                    min_km: 25,
                    max_km: 30,
                    base_fare: dataToFormat.range_3 * 1,
                  },
                  {
                    min_km: 30,
                    max_km: 35,
                    base_fare: dataToFormat.range_4 * 1,
                  },
                  {
                    min_km: 35,
                    max_km: 40,
                    base_fare: dataToFormat.range_5 * 1,
                  },
                  {
                    min_km: 40,
                    max_km: 45,
                    base_fare: dataToFormat.range_6 * 1,
                  },
                ],
              };
            }
            configs.push(data);
            count++;
            if (count === rows.length) {
              let max_distance;

              if (
                (vehicleType === "hatchback" || vehicleType === "suv") &&
                airportWay === "to_airport"
              ) {
                // vehicleType === "sedan" ||
                max_distance = 50;
              } else {
                max_distance = 45;
                // max_distance =
                //   fullData.max_distance === undefined
                //     ? 45
                //     : fullData.max_distance;
              }

              let days;

              let dataSend = {
                ride_type: "airport",
                user_id: sessionStorage.getItem("user_id"),
                vehicle_type: vehicleType,
                vendor_type: "mmt",
                travel_type: airportWay,
                day_of_week: daysToCheck[i].toLowerCase(),
                // fare_status: checkedA === true ? "Active" : "Inactive",
                config_old: checkData.config,
                max_distance: max_distance * 1,
                config: configs,
                fare_type: "slab",
              };
              // console.log(dataSend);
              axios
                .post(
                  config.url + "htmlcrm/v2/updatevendorconfiguredfare",
                  dataSend
                )
                .then((response) => {
                  // console.log(response);
                  if (response.data.message === "successfull") {
                    //  handleDayEdit("", dataSend.day_of_week);
                    //  retrieveFareData();
                    // setMessage("Fare changes updated successfully");
                    // show();
                    // setSuccess(true);
                  } else {
                    //  retrieveFareData();
                    // setMessage("Failed to update fares");
                    // show();
                    // setSuccess(false);
                  }
                });

              // console.log(dataToSend);
            }
          });
          j++;
        }
        if (j === daysToCheck.length) {
          callFareRetrieveAPI(rideType);
          setEditChange(false);
          handleCloseSaveModal();
          setMessage("Fare changes updated successfully");
          setSuccess(true);
          show();
        }
      } else {
        setMessage("Days cannot be empty");
        setSuccess(false);
        show();
      }
    }
  };

  /**FUnction to get time range */
  const getTimeRange = (parentRange, setClicks) => {
    let clicks = setClicks;
    let finalArray = [];
    let splitTimeData = parentRange.split("to");
    // console.log(splitTimeData);
    let minimum_hour = moment(splitTimeData[0], "hh A");
    let maximum_hour;
    let pushMaxHour;
    if (
      splitTimeData[1].trim() === "12 AM" &&
      splitTimeData[0].trim() === "09 PM"
    ) {
      //   console.log("***************here***********************");
      let dates = moment(splitTimeData[1], "hh A");
      dates.add(24, "hours");
      //   dates.add(59, "minutes");
      maximum_hour = dates;
      let pushDates = moment(splitTimeData[1], "hh A");

      pushDates.add(24, "hours");
      //   pushDates.add(59, "minutes");
      pushMaxHour = pushDates.format("HH:mm");
      //   console.log(maximum_hour);
    } else {
      maximum_hour = moment(splitTimeData[1].trim(), "hh A");
      pushMaxHour = moment(splitTimeData[1], "hh A").format("HH:mm");
    }

    // console.log(maximum_hour);
    let pushMinHour = moment(splitTimeData[0], "hh A").format("HH:mm");

    // console.log(pushMinHour);
    // console.log(pushMaxHour);

    let interval =
      moment.duration(maximum_hour.diff(minimum_hour)).asMinutes() / clicks;
    // console.log(interval);
    if (interval >= 60) {
      let splitMinHour = pushMinHour.split(":");
      let splitMaxHour = pushMaxHour.split(":");

      let startTime = new moment({
        hour: splitMinHour[0],
        minute: splitMinHour[1],
      });
      let endTime;
      if (
        splitTimeData[1].trim() === "12 AM" &&
        splitTimeData[0].trim() === "09 PM"
      ) {
        endTime = new moment({
          hour: splitMaxHour[0],
          minute: splitMaxHour[1],
        });
        endTime.add(24, "hours");
        // console.log(endTime);
      } else {
        endTime = new moment({
          hour: splitMaxHour[0],
          minute: splitMaxHour[1],
        });
      }

      const timeSlices = splitTime(startTime, endTime, interval);
      for (let i = 0; i < timeSlices.length - 1; i++) {
        // console.log(timeSlices[i] + " - " + timeSlices[i + 1]);
        let finalDataMin = moment(new Date(timeSlices[i])).format("hh A");
        let finalDataMax = moment(new Date(timeSlices[i + 1])).format("hh A");
        let finalData = finalDataMin + " to " + finalDataMax;
        finalArray.push(finalData);
      }
      return finalArray;
    } else {
      // Do nothing
      // console.log(finalArray);
      return finalArray;
    }
  };
  const onClickedData = (data, dataToUpdate) => {
    let datas = dataToUpdate;
    // console.log(data);
    // console.log(datas);
    let indexOfRow = datas.findIndex((x) => x.id === data.id);
    // console.log(datas[indexOfRow]);
    datas[indexOfRow] = {
      ...datas[indexOfRow],
      isClicked: !data.isClicked,
    };
    // console.log(datas);
    setRows(datas);
  };

  const onClickedRentalData = (data, dataToUpdate) => {
    let datas = dataToUpdate;
    // console.log(data);
    // console.log(datas);
    let indexOfRow = datas.findIndex((x) => x.id === data.id);
    // console.log(datas[indexOfRow]);
    datas[indexOfRow] = {
      ...datas[indexOfRow],
      isClicked: !data.isClicked,
    };
    // console.log(datas);
    setRentalRows(datas);
  };

  /**Function to split time range */
  const splitTime = (startTime, endTime, interval) => {
    const result = [startTime.toString()];
    let time = startTime.add(interval, "m");
    while (time.isBetween(startTime, endTime, undefined, [])) {
      result.push(time.toString());
      time = time.add(interval, "m");
    }
    return result;
  };

  /**Function to update multiple rental fares */
  const updateMultiRentalFare = () => {
    let daysToCheck = yourChips;
    // console.log(daysToCheck);
    if (daysToCheck && daysToCheck.length > 0) {
      let j = 0;
      for (let i = 0; i < daysToCheck.length; i++) {
        j++;
        let dataToCheck = rentalRows;
        let configs = [];
        let count = 0;
        let data;
        let minimim_hour;
        let maximum_hour;
        dataToCheck.forEach((dataToFormat) => {
          // console.log(dataToFormat);
          let timeData = dataToFormat.name;
          if (typeof timeData === "string") {
            let splitTimeData = timeData.split("to");
            minimim_hour = moment(splitTimeData[0], "hh A").format("HH");
            maximum_hour = moment(splitTimeData[1], "hh A").format("HH");
          } else {
            minimim_hour = moment(timeData[0], "hh:mm").format("HH");
            maximum_hour = moment(timeData[1], "hh:mm").format("HH");
          }

          data = {
            parent: dataToFormat.parent,
            isClicked: dataToFormat.isClicked,
            parent_id: dataToFormat.parent_id
              ? dataToFormat.parent_id
              : dataToFormat.id,
            base_fare: dataToFormat.range_1 * 1,
            minimum_hour: minimim_hour * 1,
            maximum_hour: maximum_hour * 1,
            extra_km: dataToFormat.extra_km * 1,
            extra_hour_fare:
              dataToFormat.extra_hour_fare !== undefined
                ? dataToFormat.extra_hour_fare * 1
                : 0,
            extra_minute_fare:
              dataToFormat.extra_minute_fare !== undefined
                ? dataToFormat.extra_minute_fare * 1
                : 0,
          };
          // console.log(configs);
          configs.push(data);
          count++;
          if (count === dataToCheck.length) {
            let max_distance = 0;

            let days;

            let dataSend = {
              ride_type: rideType,
              user_id: sessionStorage.getItem("user_id"),
              vehicle_type: vehicleType,
              vendor_type: "mmt",
              travel_type: rideType,
              day_of_week: daysToCheck[i].toLowerCase(),
              // fare_status: checkedA === true ? "Active" : "Inactive",
              config_old: checkData.config,
              max_distance: max_distance * 1,
              config: configs,
              fare_type: "package",
              package_info: getPackageInfo(packageType),
              package_id: packageType,
              is_per_hour_enabled: perMinuteEnabled,
              // config_fare: {
              //   max_distance: max_distance * 1,
              //   type: value,
              //   days: days,
              //   config: configs,
              // },
            };
            // console.log(dataSend);
            axios
              .post(
                config.url + "htmlcrm/v2/updatevendorconfiguredfare",
                dataSend
              )
              .then((response) => {
                // console.log(response);
                if (response.data.message === "successfull") {
                  //  handleDayEdit("", dataSend.day_of_week);
                  //  retrieveFareData();
                  // setMessage("Fare changes updated successfully");
                  // show();
                  // setSuccess(true);
                } else {
                  //  retrieveFareData();
                  // setMessage("Failed to update fares");
                  // show();
                  // setSuccess(false);
                }
              });

            // console.log(dataToSend);
          }
        });
      }
      if (j === daysToCheck.length) {
        callFareRetrieveAPI(rideType);
        setEditChange(false);
        handleCloseSaveModal();
        setMessage("Fare changes updated successfully");
        setSuccess(true);
        show();
      }
    } else {
      setMessage("Days cannot be empty");
      setSuccess(false);
      show();
    }
  };

  /**Function to get duty status */
  const retrieveFareStatus = () => {
    let dataToSend = {
      ride_type: "airport",
      vehicle_type: vehicleType,
      vendor_type: "mmt",
      travel_type: airportWay,
      fare_type:
        switchFare === true
          ? stringsData.faretype.perkm
          : stringsData.faretype.slab,
    };
    // console.log(dataToSend);
    axios
      .post(config.url + "htmlcrm/v2/getoverallstatus", dataToSend)
      .then((response) => {
        console.log(response);
        if (response.data.message === stringsData.onsuccess) {
          let dataToCheck = response.data.days;
          // let dataToCompare = {
          //   day_of_week: getDayOfTheWeek(dayOfWeek),
          // };

          let filterData = _.filter(dataToCheck, (o) => {
            return o.day_of_week === getDayOfTheWeek(dayOfWeek);
          });
          // console.log(finalData);
          if (filterData) {
            // setCheckData(dataToCheck.)
            // let boolData =

            // console.log();
            filterData[0].fare_status === "Active"
              ? setBookingFlow(true)
              : setBookingFlow(false);
          }
          if (response.data.slab_status === "Active") {
            setSwitchFare(false);
          } else if (response.data.perkm_status === "Active") {
            setSwitchFare(true);
          } else {
            setSwitchFare(false);
          }
        } else {
          setBookingFlow(false);
        }
      })
      .catch();
  };

  /**Function to get rental duty status */

  const retrieveRentalFareStatus = () => {
    let dataToSend = {
      ride_type: rideType,
      vehicle_type: vehicleType,
      vendor_type: "mmt",
      travel_type: "rental",
      fare_type: "package",
      package_id: packageType,
    };
    // console.log(dataToSend);
    axios
      .post(config.url + "htmlcrm/v2/getoverallstatus", dataToSend)
      .then((response) => {
        // console.log(response);
        if (response.data.message === stringsData.onsuccess) {
          let dataToCheck = response.data.days;
          // let dataToCompare = {
          //   day_of_week: getDayOfTheWeek(dayOfWeek),
          // };

          let filterData = _.filter(dataToCheck, (o) => {
            return o.day_of_week === getDayOfTheWeek(dayOfWeek);
          });
          // console.log(finalData);
          if (filterData) {
            // setCheckData(dataToCheck.)
            // let boolData =

            // console.log();
            filterData[0] &&
            filterData[0].fare_status &&
            filterData[0].fare_status === "Active"
              ? setBookingFlow(true)
              : setBookingFlow(false);

            // filterData[0].is_per_hour_enabled === true
            //   ? setPerMinuteEnabled(true)
            //   : setPerMinuteEnabled(false);
          }
        } else {
          setBookingFlow(false);
          // setPerMinuteEnabled(false);
        }
      })
      .catch();
  };

  /**FUcntion to show and hide snack bar */
  const show = () => {
    if (Showing) return;
    setShow(true);
    setShowing(true);
    // this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      // this.setState({ Show: false, Showing: false });
      setShow(false);
      setShowing(false);
    }, 2500);
  };

  const deactivateFare = (data) => {
    // console.log("not here");
    setBookingFlow(false);
    setTimeout(() => {
      updateDuty(data);
    }, 100);
  };

  const updateDuty = (data) => {
    if (valueUpdate === "current_day") {
      updateIndividualDuty(data);
    } else {
      updateMoreDuties(data);
    }
  };

  const updateIndividualDuty = (data) => {
    let dataSend;
    if (rideType === "airport") {
      dataSend = {
        ride_type: rideType,
        user_id: sessionStorage.getItem("user_id"),
        vehicle_type: vehicleType,
        vendor_type: "mmt",
        travel_type: airportWay,
        fare_status: data === true ? "Active" : "Inactive",
        request_type: "individual",
        day_of_week: getDayOfTheWeek(dayOfWeek),
        fare_type: switchFare === true ? "per_km" : "slab",
      };
    } else {
      dataSend = {
        ride_type: rideType,
        user_id: sessionStorage.getItem("user_id"),
        vehicle_type: vehicleType,
        vendor_type: "mmt",
        travel_type: rideType,
        fare_status: data === true ? "Active" : "Inactive",
        request_type: "individual",
        day_of_week: getDayOfTheWeek(dayOfWeek),
        fare_type: "package",
        package_id: packageType,
      };
    }

    // console.log(dataSend);
    axios
      .post(config.url + "htmlcrm/toggleFareStatus", dataSend)
      .then((response) => {
        // console.log(response.data);
        if (response.data.message === "successfull") {
          rideType === "rental"
            ? retrieveRentalFareStatus()
            : retrieveFareStatus();
          setMessage(
            dataSend.fare_status === "Active"
              ? "Booking flow activated"
              : "Booking flow deactivated"
          );
          setSuccess(true);
          show();
          dataSend.fare_status === "Active"
            ? handleCloseDutyModal()
            : handleCloseDeactiveModal();
        } else {
          setMessage("Duty status failed to update");
          setSuccess(false);
          show();
        }
      })
      .catch();
  };

  const updateMoreDuties = (data) => {
    let daysToCheck = yourChips;
    // console.log(daysToCheck.length);
    if (daysToCheck && daysToCheck.length > 0) {
      let dataSend;
      if (daysToCheck.length > 6) {
        if (rideType === "airport") {
          dataSend = {
            ride_type: rideType,
            user_id: sessionStorage.getItem("user_id"),
            vehicle_type: vehicleType,
            vendor_type: "mmt",
            travel_type: airportWay,
            fare_status: data === true ? "Active" : "Inactive",
            request_type: "all",
            day_of_week: getDayOfTheWeek(dayOfWeek),
            fare_type: switchFare === true ? "per_km" : "slab",
          };
        } else {
          dataSend = {
            ride_type: rideType,
            user_id: sessionStorage.getItem("user_id"),
            vehicle_type: vehicleType,
            vendor_type: "mmt",
            travel_type: rideType,
            fare_status: data === true ? "Active" : "Inactive",
            request_type: "all",
            day_of_week: getDayOfTheWeek(dayOfWeek),
            fare_type: "package",
            package_id: packageType,
          };
        }
        // dataSend = {
        //   ride_type: rideType,
        //   user_id: sessionStorage.getItem("user_id"),
        //   vehicle_type: vehicleType,
        //   vendor_type: "mmt",
        //   travel_type: rideType === "airport" ? airportWay : "rental",
        //   fare_status: data === true ? "Active" : "Inactive",
        //   request_type: "all",
        //   package_id: packageType,
        //   fare_type: switchFare === true ? "per_km" : "slab",
        // };

        // console.log(dataSend);
        axios
          .post(config.url + "htmlcrm/toggleFareStatus", dataSend)
          .then((response) => {
            if (response.data.message === "successfull") {
              rideType === "rental"
                ? retrieveRentalFareStatus()
                : retrieveFareStatus();
              setMessage(
                dataSend.fare_status === "Active"
                  ? "Booking flow activated for all days"
                  : "Booking flow deactivated for all days"
              );
              setSuccess(true);
              show();
              dataSend.fare_status === "Active"
                ? handleCloseDutyModal()
                : handleCloseDeactiveModal();
            } else {
              setMessage("Duty status failed to update");
              setSuccess(false);
              show();
            }
          })
          .catch();
      } else {
        let success = 0;
        for (let i = 0; i < daysToCheck.length; i++) {
          if (rideType === "airport") {
            dataSend = {
              ride_type: rideType,
              user_id: sessionStorage.getItem("user_id"),
              vehicle_type: vehicleType,
              vendor_type: "mmt",
              travel_type: airportWay,
              fare_status: data === true ? "Active" : "Inactive",
              request_type: "individual",
              day_of_week: getDayOfTheWeek(dayOfWeek),
              fare_type: switchFare === true ? "per_km" : "slab",
            };
          } else {
            dataSend = {
              ride_type: rideType,
              user_id: sessionStorage.getItem("user_id"),
              vehicle_type: vehicleType,
              vendor_type: "mmt",
              travel_type: rideType,
              fare_status: data === true ? "Active" : "Inactive",
              request_type: "individual",
              day_of_week: getDayOfTheWeek(dayOfWeek),
              fare_type: "package",
              package_id: packageType,
            };
          }
          // dataSend = {
          //   ride_type: rideType,
          //   user_id: sessionStorage.getItem("user_id"),
          //   vehicle_type: vehicleType,
          //   vendor_type: "mmt",
          //   travel_type: rideType === "airport" ? airportWay : "rental",
          //   fare_status: data === true ? "Active" : "Inactive",
          //   request_type: "individual",
          //   day_of_week: daysToCheck[i].toLowerCase(),
          //   package_id: packageType,
          //   fare_type: switchFare === true ? "per_km" : "slab",
          // };
          // console.log(dataSend);
          axios
            .post(config.url + "htmlcrm/toggleFareStatus", dataSend)
            .then((response) => {
              // console.log(response.data);
              if (response.data.message === "successfull") {
                // retrieveFareStatus();
                // success = success + 1;
                // console.log(success);
              } else {
              }
            })
            .catch();
          success++;
        }
        // console.log(success);
        if (success === daysToCheck.length) {
          // console.log(daysToCheck);
          setMessage("Booking flow updated");
          setSuccess(true);
          show();
          dataSend.fare_status === "Active"
            ? handleCloseDutyModal()
            : handleCloseDeactiveModal();
          rideType === "rental"
            ? retrieveRentalFareStatus()
            : retrieveFareStatus();
        } else {
          setMessage("Duty status failed to update");
          setSuccess(false);
          show();
        }
      }
    } else {
      setMessage("Days cannot be empty");
      setSuccess(false);
      show();
    }
    // console.log(daysToCheck.length);
  };

  const activateFare = (data) => {
    setBookingFlow(true);

    setTimeout(() => {
      updateDuty(data);
    }, 100);
  };

  const activateHour = (data) => {
    setPerMinuteEnabled(true);
    setTimeout(() => {
      updateTime(data);
    }, 100);
  };

  const deactivateHour = (data) => {
    // console.log("not here");
    setPerMinuteEnabled(false);
    setTimeout(() => {
      updateTime(data);
    }, 100);
  };

  const updateTime = (data) => {
    // updateOverallTime(data);
    let dataSend = {
      ride_type: rideType,
      user_id: sessionStorage.getItem("user_id"),
      vehicle_type: vehicleType,
      vendor_type: "mmt",
      travel_type: rideType,

      is_per_hour_enabled: data,
      fare_type: "package",
      package_id: packageType,
    };
    console.log(dataSend);
    axios
      .post(config.url + "htmlcrm/toggleRentalTimeFare", dataSend)
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "successfull") {
          getRentalFares();

          setMessage(
            dataSend.is_per_hour_enabled === true
              ? "Switched to per hour fare"
              : "Reset to per minute fare"
          );
          setSuccess(true);
          show();
          dataSend.is_per_hour_enabled === true
            ? handleCloseMinuteModal()
            : handleCloseDeactiveMinute();
        } else {
          setMessage("Failed to toggle");
          setSuccess(false);
          show();
        }
      })
      .catch();
  };

  const updateOverallTime = (data) => {
    let dataSend = {
      ride_type: rideType,
      user_id: sessionStorage.getItem("user_id"),
      vehicle_type: vehicleType,
      vendor_type: "mmt",
      travel_type: airportWay,
      fare_status: data === true ? "Active" : "Inactive",
      request_type: "all",
      day_of_week: getDayOfTheWeek(dayOfWeek),
      fare_type: switchFare === true ? "per_km" : "slab",
    };
  };

  return (
    <Container fluid className="main-content-container">
      <div className="px-1 px-md-4 py-md-3">
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="#" onClick={goBackAccounts}>
              Configuration /
            </a>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle
          title="Fare configuration"
          className="text-sm-left mb-3 pl-0 custom-margin pt-1"
        />
      </div>
      <Modal
        show={openSaveModal}
        centered
        size="lg"
        backdrop="static"
        onHide={handleCloseSaveModal}
      >
        <Modal.Header
          style={{ borderBottom: "none" }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <Typography>
            <h5 style={{ textAlign: "center", fontWeight: 700 }}>
              Are you sure you want to update the new fare changes ?
            </h5>
          </Typography>
          <div className="d-flex justify-content-center">
            <FormControl component="fieldset" className="mt-md-3">
              <RadioGroup
                row
                aria-label="days"
                name="days"
                value={valueUpdate}
                onChange={(e) => {
                  setValueUpdate(e.target.value);
                  setYourChips([]);
                }}
              >
                <FormControlLabel
                  className="px-4"
                  control={<RadioButton />}
                  label="For the current day"
                  value="current_day"
                />
                <FormControlLabel
                  className="px-4"
                  value="select_days"
                  control={<RadioButton />}
                  label="Select days"
                />
              </RadioGroup>
            </FormControl>
          </div>
          {valueUpdate === "select_days" && (
            <div className="d-flex justify-content-center mt-3">
              {/* <ChipInput
                fullWidth
                Autosuggest
                value={yourChips}
                onAdd={(chip) => handleAddChip(chip)}
                onDelete={(chip, index) => handleDeleteChip(chip, index)}
                placeholder="Select day of the week. Enter 'all' to select all days"
                InputLabelProps={{ shrink: true }}
                alwaysShowPlaceholder
              /> */}

              <ReactAutosuggestExample
                placeholder="Select day of the week. Enter 'all' to select all days"
                fullWidth
                handleAddChip={handleAddChip}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <div>
            {/* <SaveButton className="mx-2" onClick={handleDeactivateCloseSwitch}>
              CANCEL
            </SaveButton> */}
            <SaveButton
              className="py-3"
              size="large"
              style={{ minWidth: "240px" }}
              onClick={updateFare}
            >
              APPLY CHANGES
            </SaveButton>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openDutyModal}
        centered
        size="lg"
        backdrop="static"
        onHide={handleCloseDutyModal}
      >
        <Modal.Header
          style={{ borderBottom: "none" }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <Typography>
            <h5 style={{ textAlign: "center", fontWeight: 700 }}>
              Are you sure you want to activate the booking flow ?
            </h5>
          </Typography>
          <div className="d-flex justify-content-center">
            <FormControl component="fieldset" className="mt-md-3">
              <RadioGroup
                row
                aria-label="days"
                name="days"
                value={valueUpdate}
                onChange={(e) => {
                  setValueUpdate(e.target.value);
                  setYourChips([]);
                }}
              >
                <FormControlLabel
                  className="px-4"
                  control={<RadioButton />}
                  label="For the current day"
                  value="current_day"
                />
                <FormControlLabel
                  className="px-4"
                  value="select_days"
                  control={<RadioButton />}
                  label="Select days"
                />
              </RadioGroup>
            </FormControl>
          </div>
          {valueUpdate === "select_days" && (
            <div className="d-flex justify-content-center mt-3">
              {/* <ChipInput
                fullWidth
                value={yourChips}
                onAdd={(chip) => handleAddChip(chip)}
                onDelete={(chip, index) => handleDeleteChip(chip, index)}
                placeholder="Select day of the week. Enter 'all' to select all days"
                InputLabelProps={{ shrink: true }}
                alwaysShowPlaceholder
              /> */}
              <ReactAutosuggestExample
                placeholder="Select day of the week. Enter 'all' to select all days"
                fullWidth
                handleAddChip={handleAddChip}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <div>
            {/* <SaveButton className="mx-2" onClick={handleDeactivateCloseSwitch}>
              CANCEL
            </SaveButton> */}
            <SaveButton
              className="py-3"
              size="large"
              style={{ minWidth: "240px" }}
              onClick={(e) => activateFare(true)}
            >
              APPLY CHANGES
            </SaveButton>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openDeactiveDuty}
        centered
        size="lg"
        backdrop="static"
        onHide={handleCloseDeactiveModal}
      >
        <Modal.Header
          style={{ borderBottom: "none" }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <Typography>
            <h5 style={{ textAlign: "center", fontWeight: 700 }}>
              Are you sure you want to deactivate the booking flow ?
            </h5>
          </Typography>
          <div className="d-flex justify-content-center">
            <FormControl component="fieldset" className="mt-md-3">
              <RadioGroup
                row
                aria-label="days"
                name="days"
                value={valueUpdate}
                onChange={(e) => {
                  setValueUpdate(e.target.value);
                  setYourChips([]);
                }}
              >
                <FormControlLabel
                  className="px-4"
                  control={<RadioButton />}
                  label="For the current day"
                  value="current_day"
                />
                <FormControlLabel
                  className="px-4"
                  value="select_days"
                  control={<RadioButton />}
                  label="Select days"
                />
              </RadioGroup>
            </FormControl>
          </div>
          {valueUpdate === "select_days" && (
            <div className="d-flex justify-content-center mt-3">
              {/* <ChipInput
                fullWidth
                value={yourChips}
                onAdd={(chip) => handleAddChip(chip)}
                onDelete={(chip, index) => handleDeleteChip(chip, index)}
                placeholder="Select day of the week. Enter 'all' to select all days"
                InputLabelProps={{ shrink: true }}
                alwaysShowPlaceholder
              /> */}

              <ReactAutosuggestExample
                placeholder="Select day of the week. Enter 'all' to select all days"
                fullWidth
                handleAddChip={handleAddChip}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <div>
            {/* <SaveButton className="mx-2" onClick={handleDeactivateCloseSwitch}>
              CANCEL
            </SaveButton> */}
            <SaveButton
              className="py-3"
              size="large"
              style={{ minWidth: "240px" }}
              onClick={(e) => deactivateFare(false)}
            >
              APPLY CHANGES
            </SaveButton>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openMinute}
        centered
        size="lg"
        backdrop="static"
        onHide={handleCloseMinuteModal}
      >
        <Modal.Header
          style={{ borderBottom: "none" }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <Typography>
            <h5 style={{ textAlign: "center", fontWeight: 700 }}>
              Are you sure you want to enable per hour calculation for extra
              time?
            </h5>
          </Typography>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <div>
            {/* <SaveButton className="mx-2" onClick={handleDeactivateCloseSwitch}>
              CANCEL
            </SaveButton> */}
            <SaveButton
              className="py-3"
              size="large"
              style={{ minWidth: "240px" }}
              onClick={(e) => activateHour(true)}
            >
              APPLY CHANGES
            </SaveButton>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openDeactiveMinute}
        centered
        size="lg"
        backdrop="static"
        onHide={handleCloseDeactiveMinute}
      >
        <Modal.Header
          style={{ borderBottom: "none" }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <Typography>
            <h5 style={{ textAlign: "center", fontWeight: 700 }}>
              Are you sure you want to disable per hour calculation for extra
              time?
            </h5>
          </Typography>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <div>
            {/* <SaveButton className="mx-2" onClick={handleDeactivateCloseSwitch}>
              CANCEL
            </SaveButton> */}
            <SaveButton
              className="py-3"
              size="large"
              style={{ minWidth: "240px" }}
              onClick={(e) => deactivateHour(false)}
            >
              APPLY CHANGES
            </SaveButton>
          </div>
        </Modal.Footer>
      </Modal>

      <form className="px-1 px-md-4 py-md-3 mt-3" onSubmit={saveData}>
        <Row>
          <Col className="col-12 col-md-3">
            <FormGroup>
              <label className="px-3">Filter by vehicle type</label>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select vehicle type
                </InputLabel>
                <Select
                  labelId="vehicle-type-select"
                  id="vehicle-type-select"
                  label="Select vehicle type"
                  className={classes.selectRoot}
                  style={{
                    fontWeight: 700,
                  }}
                  value={vehicleType}
                  onChange={(e) => setVehicleType(e.target.value)}
                >
                  <MenuItem value={stringsData.vehicletype.flash}>
                    Hatchback
                  </MenuItem>
                  <MenuItem value={stringsData.vehicletype.bolt}>
                    Sedan
                  </MenuItem>
                  <MenuItem value={stringsData.vehicletype.cruise}>
                    SUV
                  </MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
          </Col>
          <Col className="col-12 col-md-3">
            <FormGroup>
              <label className="px-3">Filter by ride type</label>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Ride type
                </InputLabel>
                <Select
                  labelId="vehicle-type-select"
                  id="vehicle-type-select"
                  label="Ride type"
                  className={classes.selectRoot}
                  style={{
                    fontWeight: 700,
                  }}
                  value={rideType}
                  onChange={(e) => setRideType(e.target.value)}
                >
                  <MenuItem value={stringsData.ridetype.air}>Airport</MenuItem>
                  <MenuItem value={stringsData.ridetype.rent}>Rental</MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
          </Col>
          {rideType === stringsData.ridetype.rent && (
            <Col className="col-12 col-md-3 ">
              <FormGroup>
                <label className="">Select package</label>
                <FormControl variant="outlined" className={classes.formControl}>
                  {/* <InputLabel id="demo-simple-select-outlined-label">
                              Select vehicle type
                            </InputLabel> */}
                  <Select
                    labelId="vehicle-type-select"
                    id="vehicle-type-select"
                    // label="Select vehicle type"
                    className={classes.selectRoot}
                    style={{
                      fontWeight: 700,
                    }}
                    value={packageType}
                    onChange={(e) => setPackageType(e.target.value)}
                  >
                    {/* <MenuItem value="PKG_40_4">
                              4 hours 40 kilometers
                            </MenuItem> */}
                    {packageData.map((data, index) => (
                      <MenuItem key={index} value={data.package_id}>
                        {data.package_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </Col>
          )}
          {/* Render the below component if the ride type is airport */}
          {rideType === stringsData.ridetype.air && (
            <Col className="col-12 col-md-4">
              <FormGroup>
                <label className="px-3">Filter by type of flow</label>
                <FormControl component="fieldset" className="mt-md-3">
                  <RadioGroup
                    row
                    aria-label="days"
                    name="days"
                    className="px-3"
                    value={airportWay}
                    onChange={(e) => setAirportWay(e.target.value)}
                  >
                    <FormControlLabel
                      className=""
                      control={<RadioButton />}
                      label="City to airport"
                      value={stringsData.airporttype.to}
                    />
                    <FormControlLabel
                      className=""
                      value={stringsData.airporttype.from}
                      control={<RadioButton />}
                      label="Airport to city"
                    />
                  </RadioGroup>
                </FormControl>
              </FormGroup>
            </Col>
          )}
          {rideType === stringsData.ridetype.air && (
            <Col className="col-12 col-md-2">
              <FormGroup>
                <label className="">
                  Activate per-km:{" "}
                  {switchFare === true ? (
                    <strong>Enabled</strong>
                  ) : (
                    <strong>Disabled</strong>
                  )}
                </label>
                <FormControlLabel
                  className="mt-md-2 w-25"
                  control={
                    <BlackSwitch
                      name="switchfare"
                      checked={switchFare}
                      onChange={handlefareswitch}
                    />
                  }
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row className="py-3 mt-2 mt-md-4">
          <Col className="col-12">
            <Card>
              <Card.Body style={{ minHeight: "75vh" }}>
                <Row>
                  <Col className="col-12 col-md-3 py-3 px-3 px-md-3">
                    <Typography
                      // style={{handleDayEdit fontWeight: 600 }}
                      className="d-flex"
                    >
                      Booking flow:
                      {bookingFlow === true ? (
                        <Typography
                          style={{
                            fontWeight: 700,
                          }}
                          className="mx-2"
                        >
                          Enabled
                        </Typography>
                      ) : (
                        <Typography
                          style={{
                            fontWeight: 700,
                          }}
                          className="mx-2"
                        >
                          Disabled
                        </Typography>
                      )}
                    </Typography>
                    <FormControlLabel
                      control={
                        <BlackSwitch
                          name="bookingflow"
                          checked={bookingFlow}
                          onChange={handlebookingflow}
                        />
                      }
                    />
                  </Col>
                  {rideType === stringsData.ridetype.rent && (
                    <Col className="col-12 col-md-6 py-3 px-3 px-md-3">
                      <Typography
                        // style={{handleDayEdit fontWeight: 600 }}
                        className="d-flex"
                      >
                        Extra time charges :
                        {perMinuteEnabled === true ? (
                          <Typography
                            style={{
                              fontWeight: 700,
                            }}
                            className="mx-2"
                          >
                            Per hour
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              fontWeight: 700,
                            }}
                            className="mx-2"
                          >
                            Per minute
                          </Typography>
                        )}
                      </Typography>
                      <FormControlLabel
                        control={
                          <BlackSwitch
                            name="perminute"
                            checked={perMinuteEnabled}
                            onChange={handleminuteflow}
                          />
                        }
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col className="col-12 col-md-10 mt-3 ">
                    <ThemeProvider theme={theme}>
                      <Tabs
                        className={classes.tabs}
                        value={dayOfWeek}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handledayswitch}
                        scrollButtons="auto"
                        variant="scrollable"
                        aria-label="inner tabs"
                        TabIndicatorProps={{
                          style: {
                            display: "none",
                          },
                        }}
                      >
                        <AntTab
                          label="Monday"
                          className={classes.tabsRoot}
                          style={{ minWidth: "10%" }}
                        />
                        <AntTab
                          label="Tuesday"
                          className={classes.tabsRoot}
                          style={{ minWidth: "10%" }}
                        />
                        <AntTab
                          label="Wednesday"
                          className={classes.tabsRoot}
                          style={{ minWidth: "10%" }}
                        />
                        <AntTab
                          label="Thursday"
                          className={classes.tabsRoot}
                          style={{ minWidth: "10%" }}
                        />
                        <AntTab
                          label="Friday"
                          className={classes.tabsRoot}
                          style={{ minWidth: "10%" }}
                        />
                        <AntTab
                          label="Saturday"
                          className={classes.tabsRoot}
                          style={{ minWidth: "10%" }}
                        />
                        <AntTab
                          label="Sunday"
                          className={classes.tabsRoot}
                          style={{ minWidth: "10%" }}
                        />
                      </Tabs>
                    </ThemeProvider>
                  </Col>
                  <Col className="col-12 col-md-2 mt-3  d-flex justify-content-end">
                    {editChange === false ? (
                      <Button
                        startIcon={<EditIcon />}
                        style={{ fontSize: "1.1em", fontWeight: "500" }}
                        onClick={toggleSave}
                      >
                        EDIT FARE
                      </Button>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <Button
                          className="mx-2 my-2"
                          type="submit"
                          startIcon={<Save />}
                          style={{ fontSize: "1.1em", fontWeight: "500" }}
                        >
                          SAVE
                        </Button>
                        <Button
                          className="mx-2 my-2"
                          startIcon={<Close />}
                          style={{ fontSize: "1.1em", fontWeight: "500" }}
                          onClick={toggleSaveCancel}
                        >
                          CANCEL
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  {rideType === stringsData.ridetype.air ? (
                    <AirportConfig
                      fare_type={switchFare === true ? "per_km" : "slab"}
                      vehicleType={vehicleType}
                      airportWay={airportWay}
                      rows={rows}
                      editChange={editChange}
                      onChange={onChange}
                      clickNext={clickNext}
                      clickDelete={clickDelete}
                    />
                  ) : (
                    <RentalConfig
                      vehicleType={vehicleType}
                      perHourEnabled={perMinuteEnabled}
                      rows={rentalRows}
                      editChange={editChange}
                      onChange={onRentalChange}
                      clickRentalNext={clickRentalNext}
                      clickRentalDelete={clickRentalDelete}
                    />
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
      {success === false && (
        <ReactSnackBar
          Icon={
            <i
              className="far fa-times-circle fa-lg"
              style={{ color: "#FF0000" }}
            />
          }
          Show={Show}
        >
          {message}
        </ReactSnackBar>
      )}
      {success === true && (
        <ReactSnackBar
          Icon={
            <span>
              <i
                className="far fa-check-circle fa-lg"
                style={{ color: "#26FF58" }}
              />
            </span>
          }
          Show={Show}
        >
          {message}
        </ReactSnackBar>
      )}
    </Container>
  );
};

/**Custom table cells */
const CustomTableCell = ({
  row,
  name,
  onChange,
  removeRow,
  clickNext,
  clickDelete,
  vehicleType,
}) => {
  const classes = useStyles();
  const { isEditMode } = row;
  const { isClicked } = row;
  // console.log(row);
  return (
    <TableCell
      align="left"
      className={name === "sl_no" ? classes.slCell : classes.tableCell}
    >
      {isEditMode && name !== "sl_no" ? (
        <React.Fragment>
          {name !== "name" ? (
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={row[name]}
              name={name}
              onChange={(e) => onChange(e, row, "text", name)}
              className={classes.input}
              required
              type="number"
              InputProps={{ inputProps: { min: 0, step: "any" } }}
            />
          ) : (
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={row[name]}
              disabled
              name={name}
              className={classes.input}
              required
              type="text"
              style={{ minWidth: "150px" }}
            />
          )}
        </React.Fragment>
      ) : name === "sl_no" ? (
        <React.Fragment>
          {/* isClicked !== true && row.parent_id !== row.id && */}
          {isClicked !== true && vehicleType !== "suvundefined" && (
            <React.Fragment>
              {row.parent ? (
                <IconButton
                  aria-label="add"
                  // disabled
                  className={classes.margin}
                  onClick={(e) => clickNext(row)}
                  disabled={!isEditMode}
                >
                  <AddButton />
                </IconButton>
              ) : (
                <IconButton
                  // disabled
                  aria-label="delete"
                  className={classes.margin}
                  onClick={(e) => clickDelete(row)}
                  disabled={!isEditMode}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={row[name]}
          disabled
          name={name}
          className={classes.input}
          required
          type="text"
          style={{ minWidth: "150px" }}
        />
      )}
    </TableCell>
    /* row[name] */
  );
};

const RentalTableCell = ({
  row,
  name,
  onChange,
  removeRow,
  clickRentalNext,
  clickRentalDelete,
}) => {
  const classes = useStyles();
  const { isEditMode } = row;
  const { isClicked } = row;
  // console.log(row);
  // console.log(row[name]);
  return (
    <TableCell
      align="left"
      className={name === "sl_no" ? classes.slCell : classes.tableCell}
    >
      {isEditMode && name !== "sl_no" ? (
        <React.Fragment>
          {name !== "name" ? (
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={row[name]}
              name={name}
              onChange={(e) => onChange(e, row, "text", name)}
              className={classes.input}
              required
              type="number"
              InputProps={{ inputProps: { min: 0, step: "any" } }}
            />
          ) : (
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={row[name]}
              disabled
              name={name}
              className={classes.input}
              required
              type="text"
              style={{ minWidth: "150px" }}
            />
          )}
        </React.Fragment>
      ) : name === "sl_no" ? (
        <React.Fragment>
          {isClicked !== true && (
            <React.Fragment>
              {row.parent ? (
                <IconButton
                  aria-label="add"
                  // disabled
                  className={classes.margin}
                  onClick={(e) => clickRentalNext(row)}
                  disabled={!isEditMode}
                >
                  <AddButton />
                </IconButton>
              ) : (
                <IconButton
                  // disabled
                  aria-label="delete"
                  className={classes.margin}
                  onClick={(e) => clickRentalDelete(row)}
                  disabled={!isEditMode}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={row[name]}
          disabled
          name={name}
          className={classes.input}
          required
          type="text"
          style={{ minWidth: "150px" }}
        />
      )}
    </TableCell>
    /* row[name] */
  );
};

/**Get days  */
const getValue = (data) => {
  if (typeof data === "string") {
    // console.log(data);
    let timeData = data;
    let splitTimeData = timeData.split("to");
    let minimim_hour = moment(splitTimeData[0], "hh A").format("HH:mm");
    let maximum_hour = moment(splitTimeData[1], "hh A").format("HH:mm");
    return [minimim_hour, maximum_hour];
  } else {
    return data;
  }

  // return ["10:00", "12:00"];
};

/**Component for airport fare configuration */

const AirportConfig = (props) => {
  const classes = useStyles();
  let { onChange, clickNext, clickDelete, vehicleType } = props;

  const [rows, setRows] = React.useState(props.rows);
  const [previous, setPrevious] = React.useState({});

  /**Effect to check for changes in the row state */
  React.useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  return (
    <Col className="col-12">
      <Paper className={classes.tableRoot} elevation="0">
        <Table className={classes.table} aria-label="caption table">
          <TableHead>
            {props.fare_type === stringsData.faretype.perkm ? (
              <TableRow>
                <TableCell
                  align="left"
                  className={classes.tabHeader}
                ></TableCell>
                {(props.vehicleType === "sedan" ||
                  props.vehicleType === "hatchback" ||
                  props.vehicleType === "suv") && (
                  <TableCell align="left" className={classes.tabHeader}>
                    Time slot
                  </TableCell>
                )}
                <TableCell align="left" className={classes.tabHeader}>
                  Per km rate
                </TableCell>
                <TableCell align="left" className={classes.tabHeader}>
                  Km upto
                </TableCell>
                <TableCell align="left" className={classes.tabHeader}>
                  Airport toll charges
                </TableCell>
                <TableCell align="left" className={classes.tabHeader}>
                  Pro-rated km rate
                </TableCell>
                <TableCell align="left" className={classes.tabHeader}>
                  Seller discount
                </TableCell>

                <TableCell align="left" className={classes.tabHeader}>
                  Base fare (+ toll)
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell
                  align="left"
                  className={classes.tabHeader}
                  width="10%"
                ></TableCell>
                {(props.vehicleType === "sedan" ||
                  props.vehicleType === "hatchback" ||
                  props.vehicleType === "suv") &&
                  props.airportWay === "to_airport" && (
                    <React.Fragment>
                      <TableCell align="left" className={classes.tabHeader}>
                        Time slot
                      </TableCell>
                      <TableCell align="left" className={classes.tabHeader}>
                        0 km to 20 km
                      </TableCell>
                      <TableCell align="left" className={classes.tabHeader}>
                        20 km to 25 km
                      </TableCell>
                      <TableCell align="left" className={classes.tabHeader}>
                        25 km to 30 km
                      </TableCell>
                      <TableCell align="left" className={classes.tabHeader}>
                        30 km to 35 km
                      </TableCell>
                      <TableCell align="left" className={classes.tabHeader}>
                        35 km to 40 km
                      </TableCell>
                      <TableCell align="left" className={classes.tabHeader}>
                        40 km to 45 km
                      </TableCell>
                      {props.vehicleType !== "sedan" && (
                        <TableCell align="left" className={classes.tabHeader}>
                          45 km to 50 km
                        </TableCell>
                      )}
                    </React.Fragment>
                  )}
                {(props.vehicleType === "sedan" ||
                  props.vehicleType === "hatchback" ||
                  props.vehicleType === "suv") &&
                  props.airportWay === "from_airport" && (
                    <React.Fragment>
                      <TableCell align="left" className={classes.tabHeader}>
                        Time slot
                      </TableCell>
                      <TableCell align="left" className={classes.tabHeader}>
                        0 km to 10 km
                      </TableCell>
                      <TableCell align="left" className={classes.tabHeader}>
                        10 km to 20 km
                      </TableCell>
                      <TableCell align="left" className={classes.tabHeader}>
                        20 km to 30 km
                      </TableCell>
                      <TableCell align="left" className={classes.tabHeader}>
                        30 km to 35 km
                      </TableCell>
                      <TableCell align="left" className={classes.tabHeader}>
                        35 km to 40 km
                      </TableCell>
                      <TableCell align="left" className={classes.tabHeader}>
                        40 km to 45 km
                      </TableCell>
                    </React.Fragment>
                  )}
                {props.vehicleType === "suvundefined" && (
                  <TableCell
                    align="left"
                    className={classes.tabHeader}
                    width="10%"
                  >
                    0 km to 45 km
                  </TableCell>
                )}

                <TableCell align="left" className={classes.tabHeader}>
                  Per km fare
                </TableCell>
                <TableCell align="left" className={classes.tabHeader}>
                  Toll charges
                </TableCell>
                <TableCell align="left" className={classes.tabHeader}>
                  Seller discount
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {props.fare_type === stringsData.faretype.perkm ? (
              <React.Fragment>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <CustomTableCell
                      {...{
                        row,
                        name: "sl_no",
                        onChange,
                        clickNext,
                        clickDelete,
                        vehicleType,
                      }}
                    />
                    {(props.vehicleType === "sedan" ||
                      props.vehicleType === "hatchback" ||
                      props.vehicleType === "suv") && (
                      <CustomTableCell
                        {...{
                          row,
                          name: "name",
                          onChange,
                        }}
                      />
                    )}

                    <CustomTableCell
                      {...{
                        row,
                        name: "range_1",
                        onChange,
                      }}
                    />
                    <CustomTableCell
                      {...{
                        row,
                        name: "range_2",
                        onChange,
                      }}
                    />
                    <CustomTableCell
                      {...{
                        row,
                        name: "range_3",
                        onChange,
                      }}
                    />
                    <CustomTableCell
                      {...{
                        row,
                        name: "range_4",
                        onChange,
                      }}
                    />
                    <CustomTableCell
                      {...{
                        row,
                        name: "range_5",
                        onChange,
                      }}
                    />

                    <CustomTableCell
                      {...{
                        row,
                        name: "range_6",
                        onChange,
                      }}
                    />
                  </TableRow>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <CustomTableCell
                      {...{
                        row,
                        name: "sl_no",
                        onChange,
                        clickNext,
                        clickDelete,
                        vehicleType,
                      }}
                    />

                    {(props.vehicleType === "sedan" ||
                      props.vehicleType === "hatchback" ||
                      props.vehicleType === "suv") &&
                      props.airportWay === "from_airport" && (
                        <React.Fragment>
                          <CustomTableCell
                            {...{
                              row,
                              name: "name",
                              onChange,
                            }}
                          />
                          <CustomTableCell
                            {...{
                              row,
                              name: "range_1",
                              onChange,
                            }}
                          />
                          <CustomTableCell
                            {...{
                              row,
                              name: "range_2",
                              onChange,
                            }}
                          />
                          <CustomTableCell
                            {...{
                              row,
                              name: "range_3",
                              onChange,
                            }}
                          />
                          <CustomTableCell
                            {...{
                              row,
                              name: "range_4",
                              onChange,
                            }}
                          />
                          <CustomTableCell
                            {...{
                              row,
                              name: "range_5",
                              onChange,
                            }}
                          />
                          <CustomTableCell
                            {...{
                              row,
                              name: "range_6",
                              onChange,
                            }}
                          />
                        </React.Fragment>
                      )}

                    {(props.vehicleType === "sedan" ||
                      props.vehicleType === "hatchback" ||
                      props.vehicleType === "suv") &&
                      props.airportWay === "to_airport" && (
                        <React.Fragment>
                          <CustomTableCell
                            {...{
                              row,
                              name: "name",
                              onChange,
                            }}
                          />
                          <CustomTableCell
                            {...{
                              row,
                              name: "range_1",
                              onChange,
                            }}
                          />
                          <CustomTableCell
                            {...{
                              row,
                              name: "range_2",
                              onChange,
                            }}
                          />
                          <CustomTableCell
                            {...{
                              row,
                              name: "range_3",
                              onChange,
                            }}
                          />
                          <CustomTableCell
                            {...{
                              row,
                              name: "range_4",
                              onChange,
                            }}
                          />
                          <CustomTableCell
                            {...{
                              row,
                              name: "range_5",
                              onChange,
                            }}
                          />

                          <CustomTableCell
                            {...{
                              row,
                              name: "range_6",
                              onChange,
                            }}
                          />
                          {props.vehicleType !== "sedan" && (
                            <CustomTableCell
                              {...{
                                row,
                                name: "range_7",
                                onChange,
                              }}
                            />
                          )}
                        </React.Fragment>
                      )}

                    {props.vehicleType === "suvundefined" && (
                      <CustomTableCell
                        {...{
                          row,
                          name: "range_6",
                          onChange,
                          fare_type: "slab",
                        }}
                      />
                    )}

                    <CustomTableCell
                      {...{
                        row,
                        name: "per_km",
                        onChange,
                        fare_type: "slab",
                      }}
                    />
                    <CustomTableCell
                      {...{
                        row,
                        name: "toll",
                        onChange,
                        fare_type: "slab",
                      }}
                    />
                    <CustomTableCell
                      {...{
                        row,
                        name: "seller_discount",
                        onChange,
                        fare_type: "slab",
                      }}
                    />
                  </TableRow>
                ))}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </Paper>
    </Col>
  );
};

/**Component for rental fare configuration */
const RentalConfig = (props) => {
  const classes = useStyles();
  /**Function to change data in the row */
  const {
    onChange,
    clickRentalNext,
    clickRentalDelete,
    perHourEnabled,
  } = props;

  const [rows, setRows] = React.useState(props.rows);
  const [previous, setPrevious] = React.useState({});

  /**Effect to check for changes in the row state */
  React.useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  return (
    <Col className="col-12 col-md-12">
      <Paper
        className={classes.tableRoot}
        elevation="0"
        style={{ maxWidth: 820 }}
      >
        <Table className={classes.table} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                className={classes.tabHeader}
                width="10%"
              ></TableCell>
              <React.Fragment>
                <TableCell
                  align="left"
                  className={classes.tabHeader}
                  width="20%"
                >
                  Time slot
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.tabHeader}
                  width="20%"
                >
                  Base fare
                </TableCell>
                {perHourEnabled === true && (
                  <TableCell
                    align="left"
                    className={classes.tabHeader}
                    width="20%"
                  >
                    Extra time fare (per hour)
                  </TableCell>
                )}

                {perHourEnabled === false && (
                  <TableCell
                    align="left"
                    className={classes.tabHeader}
                    width="20%"
                  >
                    Extra time fare (per min)
                  </TableCell>
                )}

                <TableCell
                  align="left"
                  className={classes.tabHeader}
                  width="20%"
                >
                  Additional kilometers
                </TableCell>
              </React.Fragment>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <RentalTableCell
                  {...{
                    row,
                    name: "sl_no",
                    onChange,
                    clickRentalNext,
                    clickRentalDelete,
                  }}
                />
                <RentalTableCell
                  {...{
                    row,
                    name: "name",
                    onChange,
                    clickRentalNext,
                    clickRentalDelete,
                  }}
                />
                <RentalTableCell
                  {...{
                    row,
                    name: "range_1",
                    onChange,
                    clickRentalNext,
                    clickRentalDelete,
                  }}
                />
                {perHourEnabled === true && (
                  <RentalTableCell
                    {...{
                      row,
                      name: "extra_hour_fare",
                      onChange,
                      clickRentalNext,
                      clickRentalDelete,
                    }}
                  />
                )}

                {perHourEnabled === false && (
                  <RentalTableCell
                    {...{
                      row,
                      name: "extra_minute_fare",
                      onChange,
                      clickRentalNext,
                      clickRentalDelete,
                    }}
                  />
                )}

                <RentalTableCell
                  {...{
                    row,
                    name: "extra_km",
                    onChange,
                    clickRentalNext,
                    clickRentalDelete,
                  }}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Col>
  );
};

export default FareConfig;
