import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container, Navbar } from "shards-react";

import NavbarSearch from "./NavbarSearch";
import NavbarNav from "./NavbarNav/NavbarNav";
import NavbarToggle from "./NavbarToggle";
import NavBookride from "./NavbarNav/NavBookride";
import { Store } from "../../../flux";
// const MainNavbar = ({ layout, stickyTop }) => {
//   const classes = classNames(
//     "main-navbar",
//     "bg-white",
//     stickyTop && "sticky-top"
//   );
// };

class MainNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuVisible: false,
      sidebarNavItems: Store.getSidebarItems(),
    };
    this.onChange = this.onChange.bind(this);
  }
  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }
  onChange() {
    this.setState({
      ...this.state,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: Store.getSidebarItems(),
    });
  }
  render() {
    let { layout, stickyTop } = this.props;
    const classes = classNames(
      "main-navbar",
      "bg-white",
      stickyTop && "sticky-top",
      this.state.menuVisible && "open"
    );
    return (
      <div className={classes}>
        <Container fluid className="p-0 ">
          <Navbar
            type="light"
            className="align-items-stretch flex-md-nowrap p-0 navbar navbar-light"
          >
            <NavbarSearch />
            <NavBookride />
            <NavbarNav />
            <NavbarToggle />
          </Navbar>
        </Container>
      </div>
    );
  }
}
MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,
};

MainNavbar.defaultProps = {
  stickyTop: true,
};

export default MainNavbar;
