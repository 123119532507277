// eslint-disable-next-line
import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
// import ReactCSSTransitionGroup from "react-transition-group";
// import { Link, Route } from "react-router-dom";
import AccountBox from "../components/common/AccountBox";
import PageTitle from "./../components/common/PageTitle";
import * as config from "../constants/constants.json";
// import TopDrivers from "../components/common/TopDrivers"
// import RideAnalysis from "../components/dataviews/RideAnalysis"
// const BizAccounts = ({ smallStats, bottomStats }) => (
//  );

class BizAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorsList: [],
    };
    this.getuserDetailsFromServer = this.getuserDetailsFromServer.bind(this);
    // this.handleChecks = this.handleCheck.bind(this);
  }
  handleCheck(data) {
    // console.log(this.state.vendorsList);
    // return this.state.vendorsList.some((item) => data === item.id);
    return true;
  }

  handleChecks(data) {
    console.log(data);
    return data.doNotDisplay.some(
      (item) =>
        item.usertype === window.sessionStorage.getItem("usertype") &&
        item.roletype === window.sessionStorage.getItem("roletype")
    );
  }
  getuserDetailsFromServer_old() {
    fetch(config.url + "htmlcrm/getUserDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        userid: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          let vendorList = result.data.vendors;
          if (vendorList && vendorList.length > 0) {
            // console.log(vendorList);
            this.setState({
              vendorsList: vendorList,
            });
          } else {
            this.setState({
              vendorsList: [],
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  getuserDetailsFromServer = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: config.url + "htmlcrm/getUserDetails",
        data: JSON.stringify({
          userid: window.sessionStorage.getItem("user_id"),
        }),
        headers: {
          "content-type": "application/json",
        },
      });
      let data = response.data.data;
      // console.log(data);
      let vendorList = data.vendors;
      if (vendorList && vendorList.length > 0) {
        this.setState({
          vendorsList: vendorList,
        });
      } else {
        this.setState({
          vendorsList: [],
        });
      }
    } catch (error) {}
  };

  componentDidMount() {
    // console.log(window.sessionStorage.getItem("usertype"));
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    } else {
      if (
        window.sessionStorage.getItem("usertype") === "dispatchexecutive" ||
        window.sessionStorage.getItem("usertype") === "dispatchmanager"
      ) {
        // console.log("here");
        this.getuserDetailsFromServer();
      }
    }
  }

  render() {
    // console.log(this.state.vendorsList.length);
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4 px-3">
          <PageTitle
            title="Select a business account"
            subtitle="Business accounts"
            className="text-sm-left mb-3"
          />
        </Row>
        {/* {this.state.vendorsList.length > 0 && (
         
        )} */}
        <Row className="px-3">
          {this.props.bookingDisplay.map(
            (stats, idx) =>
              this.handleCheck(stats.id) === true && (
                <Col className="col-md mb-4" key={idx} {...stats.attrs}>
                  <AccountBox
                    id={`small-stats-${idx}`}
                    variation="1"
                    label={stats.label}
                    value={stats.value}
                    stats={stats.stats}
                    display={stats.display}
                  />
                </Col>
              )
          )}
        </Row>
        {/* {this.state.vendorsList.length === 0 &&
          window.sessionStorage.getItem("roletype") !== "manager" && (
            <Row className="px-3">
              <Col
                className="col-12 col-md-12 col-lg-12 mt-4"
                style={{ textAlign: "center" }}
              >
                <img
                  src="https://master.gloryindia.in/static/images/cancel_no_access.png"
                  alt="Access denied"
                  style={{ height: "150px" }}
                  className="mx-auto"
                />
              </Col>
              <Col
                className="col-12 col-md-12 col-lg-12 mt-4"
                style={{ textAlign: "center" }}
              >
                <h2>Access denied!</h2>
              </Col>
              <Col
                className="col-12 col-md-12 col-lg-12"
                style={{ textAlign: "center" }}
              >
                <h2>No vendors assigned!</h2>
              </Col>
            </Row>
          )} */}

        <Row>
          <hr className="w-100 align-items-end" />
        </Row>
        <Row className="px-3 ">
          {this.props.bottomStats.map((stats, idx) =>
            stats.doNotDisplay.length > 0
              ? this.handleChecks(stats) === false && (
                  <Col className="col-md my-4" key={idx} {...stats.attrs}>
                    {/* {console.log(smallStats)} */}
                    {/* {console.log(idx)}
                   {console.log(stats)} */}
                    <AccountBox
                      id={`small-stats-${idx}`}
                      variation="1"
                      label={stats.label}
                      value={stats.value}
                      stats={stats.stats}
                      display={stats.display}
                    />
                  </Col>
                )
              : stats.doNotDisplay.usertype !==
                  window.sessionStorage.getItem("usertype") &&
                stats.doNotDisplay.roletype !==
                  window.sessionStorage.getItem("roletype") && (
                  <Col className="col-md my-4" key={idx} {...stats.attrs}>
                    {/* {console.log(smallStats)} */}
                    {/* {console.log(idx)}
                   {console.log(stats)} */}
                    <AccountBox
                      id={`small-stats-${idx}`}
                      variation="1"
                      label={stats.label}
                      value={stats.value}
                      stats={stats.stats}
                      display={stats.display}
                    />
                  </Col>
                )
          )}
        </Row>
      </Container>
    );
  }
}

BizAccounts.propTypes = {
  smallStats: PropTypes.array,
  bottomStats: PropTypes.array,
};

BizAccounts.defaultProps = {
  testStats: [
    {
      name: "Glory",
      value: "glory",
      id: "HOKSELF00",
    },
    {
      name: "mmt",
      value: "mmt",
      id: "HOKCTVL02",
    },
    {
      name: "Zippys",
      value: "zippys",
      id: "HOKZIPVL01",
    },
    {
      name: "OYO",
      value: "oyo",
      id: "HOKOYVL03",
    },
    {
      name: "Yatra",
      value: "yatra",
      id: "HOKYTVL04",
    },
    {
      name: "Via.com",
      value: "viacom",
      id: "HOKVIAVL05",
    },
    {
      name: "MMT",
      value: "mmt",
      id: "HOKGOMMTVL06",
    },
  ],
  smallStats: [
    {
      label: "mmt",
      value: "MMT",
      id: "HOKGOMMTVL06",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
      animDelay: "1000",
    },
    {
      label: "glory",
      value: "Glory",
      id: "HOKSELF00",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
      animDelay: "2.5",
    },
    {
      label: "cleartrip",
      value: "Cleartrip",
      id: "HOKCTVL02",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "zippys",
      value: "Zyppys",
      id: "HOKZIPVL01",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "oyo",
      value: "OYO",
      id: "HOKOYVL03",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "moyo",
      value: "MOYO",
      id: "HOKOYVL037",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "yatra",
      value: "Yatra",
      id: "HOKYTVL04",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "viacom",
      value: "Via.com",
      id: "HOKVIAVL05",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "quickride",
      value: "Quick ride",
      id: "HOKQRVL07",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
    },
  ],
  bookingDisplay: [
    {
      label: "bookings",
      value: "Bookings",
      id: "HOKGOMMTVL06",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
      animDelay: "1000",
    },
  ],
  bottomStats: [
    {
      label: "ridedetachments",
      value: "Ride detachments",
      attrs: { md: "3", sm: "6" },
      stats: true,
      display: false,
      doNotDisplay: [
        {
          usertype: "dispatchexecutive",
          roletype: "traineeexecutive",
        },
      ],
    },
    {
      label: "tripdebuginfo",
      value: "Trip debug",
      attrs: { md: "3", sm: "6" },
      stats: true,
      display: false,
      doNotDisplay: [
        {
          usertype: "dispatchexecutive",
          roletype: "traineeexecutive",
        },
      ],
    },
    {
      label: "driverstatus",
      value: "Driver status",
      attrs: { md: "3", sm: "6" },
      stats: true,
      display: false,
      doNotDisplay: [{}],
    },
    {
      label: "rejectedrideslist",
      value: "Rejected rides list",
      attrs: { md: "3", sm: "6" },
      stats: true,
      display: false,
      doNotDisplay: [
        {
          usertype: "dispatchexecutive",
          roletype: "traineeexecutive",
        },
      ],
    },
    {
      label: "tagRideList",
      value: "Tagged rides",
      attrs: { md: "3", sm: "6" },
      stats: true,
      display: false,
      doNotDisplay: [
        {
          usertype: "dispatchexecutive",
          roletype: "traineeexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "supplyexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "traineeexecutive",
        },
      ],
    },
    {
      label: "driverotps",
      value: "Driver OTPs",
      attrs: { md: "3", sm: "6" },
      stats: true,
      display: false,
      doNotDisplay: [{}],
    },
  ],
};

export default BizAccounts;

/**
 *  {
      label: "tagRideList",
      value: "Tagged rides",
      attrs: { md: "3", sm: "6" },
      stats: true,
      display: false,
      doNotDisplay: {
        usertype: "dispatchexecutive",
        roletype: "traineeexecutive",
      },
    },
 */
