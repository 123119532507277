// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormRadio,
  FormInput,
  FormCheckbox,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";
import ReactTable from "react-table-v6";
import PageTitle from "./../common/PageTitle";
import Search from "./../common/EmpAccountSearch";
import ReactSnackBar from "react-js-snackbar";
import * as config from "../../constants/constants.json";
// import TopDrivers from "../components/common/TopDrivers"
// import RideAnalysis from "../components/dataviews/RideAnalysis"
const testStats = [
  {
    name: "Glory",
    value: "glory",
    id: "HOKSELF00",
  },
  {
    name: "Cleartrip",
    value: "cleartrip",
    id: "HOKCTVL02",
  },
  {
    name: "Zippys",
    value: "zippys",
    id: "HOKZIPVL01",
  },
  {
    name: "OYO",
    value: "oyo",
    id: "HOKOYVL03",
  },
  {
    name: "Yatra",
    value: "yatra",
    id: "HOKYTVL04",
  },
  {
    name: "Via.com",
    value: "viacom",
    id: "HOKVIAVL05",
  },
  {
    name: "MMT",
    value: "mmt",
    id: "HOKGOMMTVL06",
  },
];
class SupplyAccountViews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      execData: [],
      vendorData: [],
      managerData: [],
      blockedData: [],
      userData: [],
      filteredExecData: [],
      searchExecInput: "",
      filteredMngData: [],
      searchMngInput: "",
      filteredUserData: [],
      searchUserInput: "",
      divShow: false,
      selectedItem: null,
      display: true,
      first_name: "",
      last_name: "",
      email: "",
      phonenumber: "",
      readOnly: true,
      hornok: false,
      cleartrip: false,
      zippys: false,
      oyo: false,
      yatra: false,
      viacom: false,
      mmt: false,
      traineeexecutive: false,
      juniorexecutive: false,
      seniorexecutive: false,
      teamlead: false,
      assistantmanager: false,
      manager: false,
      dayshift: false,
      nightshift: false,
      midshift: false,
      selectedJobRole: null,
      selectedShift: null,
      readOnlyBiz: true,
      readOnlyJob: true,
      readOnlyShift: true,
      success: false,
      Show: false,
      Showing: false,
      message: "",
      region: window.sessionStorage.getItem("operating_region"),
    };
    // console.log(testStats);
    this.enableAccStatus = this.enableAccStatus.bind(this);
    this.goBack = this.goBack.bind(this);
    this.getSupplyExecDetails = this.getSupplyExecDetails.bind(this);
    this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
    this.formatRoledata = this.formatRoledata.bind(this);
    this.getSupplyManagerDetails = this.getSupplyManagerDetails.bind(this);
    this.getPanelUsers = this.getPanelUsers.bind(this);
    this.changeAccountState = this.changeAccountState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeJobRole = this.changeJobRole.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.handleEditBiz = this.handleEditBiz.bind(this);
    this.handleEditShift = this.handleEditShift.bind(this);
    this.handleEditJob = this.handleEditJob.bind(this);
    this.updateRoleType = this.updateRoleType.bind(this);
    this.updateShift = this.updateShift.bind(this);
    this.updateBlockOrActive = this.updateBlockOrActive.bind(this);
    this.updateVendorsAssigned = this.updateVendorsAssigned.bind(this);
    this.updateBlockOrActiveFromDiv =
      this.updateBlockOrActiveFromDiv.bind(this);
  }

  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };
  handleExecFilteredData = (filteredExecData) => {
    this.setState({ filteredExecData });
  };

  handleExecSearchInput = (searchExecInput) => {
    this.setState({ searchExecInput });
  };
  handleMngFilteredData = (filteredMngData) => {
    this.setState({ filteredMngData });
  };

  handleMngSearchInput = (searchMngInput) => {
    this.setState({ searchMngInput });
  };
  handleUserFilteredData = (filteredUserData) => {
    this.setState({ filteredUserData });
  };

  handleuserSearchInput = (searchUserInput) => {
    // console.log(searchUserInput);
    this.setState({ searchUserInput });
  };
  updateVendorsAssigned() {
    let vendorArr = [];
    if (this.state.hornok === true) vendorArr.push(testStats[0].value);
    if (this.state.cleartrip === true) vendorArr.push(testStats[1].value);
    if (this.state.zippys === true) vendorArr.push(testStats[2].value);
    if (this.state.oyo === true) vendorArr.push(testStats[3].value);
    if (this.state.yatra === true) vendorArr.push(testStats[4].value);
    if (this.state.viacom === true) vendorArr.push(testStats[5].value);
    if (this.state.mmt === true) vendorArr.push(testStats[6].value);

    // console.log(vendorArr);
    let data = {
      executiveid: this.state.userData.executiveid,
      vendors: JSON.stringify(vendorArr),
      modified_by: window.sessionStorage.getItem("user_id"),
    };
    // console.log(data);
    fetch(config.url + "htmlcrm/assignVendorsToExecutives", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if (result.message === "successfull") {
            // this.setState({
            //     managerData: result.executives
            // })
            // this.handleEditJob()
            this.handleEditBiz();
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  updateBlockOrActiveFromDiv() {
    // console.log(this.state.userData);
    let data = {
      blockid: this.state.userData.executiveid,
      userid: sessionStorage.getItem("user_id"),
      status: this.state.selectedItem === "activated" ? "Inactive" : "blocked",
    };
    fetch(config.url + "crm/suspendorblock", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if (result.message === "successfull") {
            // this.setState({
            //     managerData: result.executives
            // })
            this.setState(
              {
                success: true,
                message:
                  this.state.selectedItem === "activated"
                    ? "User has been activated"
                    : "User has been blocked",
              },
              () => {
                this.enableAccStatus();
                this.show();
              }
            );
          } else {
            this.setState(
              {
                success: false,
                message: "Failed to update to server",
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  updateBlockOrActive(userid) {
    let data = {
      blockid:
        userid === undefined || userid === null
          ? this.state.userData.executiveid
          : userid,
      userid: sessionStorage.getItem("user_id"),
      status: "Inactive",
    };
    fetch(config.url + "crm/suspendorblock", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if (result.message === "successfull") {
            // this.setState({
            //     managerData: result.executives
            // })
            this.setState(
              {
                success: true,
                message: "User has been activated",
              },
              () => {
                this.show();
                this.getSupplyExecDetails();
                this.getSupplyManagerDetails();
                this.getPanelUsers();
              }
            );
          } else {
            this.setState(
              {
                success: false,
                message: "Failed to activate",
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  updateShift() {
    let shiftSent;
    if (this.state.selectedShift === "dayshift") {
      shiftSent = "day";
    } else if (this.state.selectedShift === "nightshift") {
      shiftSent = "night";
    } else if (this.state.selectedShift === "midshift") {
      shiftSent = "mid";
    }
    let data = {
      shift: shiftSent,
      user_id: this.state.userData.executiveid,
      updated_by: window.sessionStorage.getItem("user_id"),
    };
    fetch(config.url + "htmlcrm/updateUserShift", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if (result.message === "successfull") {
            // this.setState({
            //     managerData: result.executives
            // })
            this.handleEditShift();
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  updateRoleType() {
    let roleSent;
    // traineeexecutive: false,
    //     juniorexecutive: false,
    //     seniorexecutive: false,
    //     teamlead: false,
    //     assistantmanager: false,
    //     manager: false,

    if (this.state.selectedJobRole === "traineeexecutive") {
      roleSent = "traineeexecutive";
    } else if (this.state.selectedJobRole === "juniorexecutive") {
      roleSent = "juniorexecutive";
    } else if (this.state.selectedJobRole === "seniorexecutive") {
      roleSent = "seniorexecutive";
    } else if (this.state.selectedJobRole === "teamlead") {
      roleSent = "teamlead";
    } else if (this.state.selectedJobRole === "assistantmanager") {
      roleSent = "assistantmanager";
    } else if (this.state.selectedJobRole === "manager") {
      roleSent = "manager";
    }
    let data = {
      roletype: roleSent,
      userid: this.state.userData.executiveid,
    };
    fetch(config.url + "crm/updateRoleType", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if (result.message === "successfull") {
            // this.setState({
            //     managerData: result.executives
            // })
            this.handleEditJob();
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  handleEditBiz() {
    this.setState({
      readOnlyBiz: !this.state.readOnlyBiz,
    });
  }

  handleEditJob() {
    this.setState({
      readOnlyJob: !this.state.readOnlyJob,
    });
  }
  handleEditShift() {
    this.setState({
      readOnlyShift: !this.state.readOnlyShift,
    });
  }
  changeJobRole(data) {
    this.setState({
      selectedJobRole: data,
    });
  }
  changeShift(data) {
    this.setState({
      selectedShift: data,
    });
  }

  getUserDetails(data) {
    // console.log(data);
    // getUserDetails
    fetch(config.url + "htmlcrm/getUserDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        userid: data,
        region: this.state.region,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if (result.data) {
            let status =
              result.data.loginstatus === "Active" ||
              result.data.loginstatus === "Inactive"
                ? "activated"
                : "block";
            let shift;
            if (result.data.shift === "mid") {
              // console.log("here");
              shift = "midshift";
              this.changeShift(shift);
            } else if (result.data.shift === "day") {
              shift = "dayshift";
              this.changeShift(shift);
            } else {
              shift = "nightshift";
              this.changeShift(shift);
            }
            if (result.data.vendors.length > 0) {
              // console.log(result.data.vendors[0].value);
              for (let i = 0; i <= result.data.vendors.length - 1; i++) {
                this.handleChange("e", result.data.vendors[i].value);
              }
            }
            this.changeJobRole(result.data.roletype);
            this.setState({
              userData: result.data,
              divShow: !this.state.divShow,
              selectedItem: status,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  onTitleChange() {
    this.setState(
      {
        divShow: !this.state.divShow,
        display: true,
        readOnlyJob: true,
        readOnlyShift: true,
      },
      () => {
        this.getSupplyExecDetails();
        this.getSupplyManagerDetails();
        this.getPanelUsers();
      }
    );
  }
  handleChange(e, data) {
    // console.log("herererere");
    // console.log(data);
    const newState = {};
    newState[data] = !this.state[data];
    // this.setState(prevState => ({
    //     phonenumbers: [...prevState.phonenumbers, phonenumber],

    // }))
    this.setState({ ...this.state, ...newState });
  }
  enableAccStatus() {
    this.setState({
      display: !this.state.display,
    });
  }
  changeAccountState(data) {
    this.setState({
      selectedItem: data,
    });
  }
  formatRoledata(data, type) {
    if (type === "dispatchexecutive" || type === "dispatchmanager") {
      switch (data) {
        case "juniorexecutive":
          return "Junior Dispatch Executive";
        case "teamlead":
          return "Dispatch Team Lead";
        case "traineeexecutive":
          return "Trainee Dispatch Executive";
        case "seniorexecutive":
          return "Senior Dispatch Executive";
        case "manager":
          return "Dispatch Manager";
        case "assistantmanager":
          return "Assistant Dispatch Manager";
        default:
          return "-";
      }
    } else if (type === "supplyexecutive" || type === "supplymanager") {
      switch (data) {
        case "juniorexecutive":
          return "Junior Supply Executive";
        case "teamlead":
          return "Supply Team Lead";
        case "traineeexecutive":
          return "Trainee Supply Executive";
        case "seniorexecutive":
          return "Senior Supply Executive";
        case "manager":
          return "Supply Manager";
        case "assistantmanager":
          return "Assistant Supply Manager";
        default:
          return "-";
      }
    } else {
      switch (data) {
        case "juniorexecutive":
          return "Junior Account Executive";
        case "teamlead":
          return "Accounts Team Lead";
        case "traineeexecutive":
          return "Trainee Account Executive";
        case "seniorexecutive":
          return "Senior Account Executive";
        case "manager":
          return "Accounts Manager";
        case "assistantmanager":
          return "Assistant Accounts Manager";
        default:
          return "-";
      }
    }
  }
  capitalizeFirstLetter(string) {
    // console.log(string);
    // return string[0].toUpperCase() + string.slice(1);
    if (string[0] === undefined) {
      return "-";
    } else {
      return string[0].toUpperCase() + string.slice(1);
    }
  }
  goBack() {
    this.props.history.goBack();
  }
  getSupplyManagerDetails() {
    let manager_type = `supplymanager`;
    fetch(config.url + "htmlcrm/getManagersDetail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        user_id: window.sessionStorage.getItem("user_id"),
        manager_type: manager_type,
        region: this.state.region,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if (result.executives) {
            this.setState({
              managerData: result.executives,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  getSupplyExecDetails() {
    fetch(config.url + "htmlcrm/getExecutivesDetail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        user_id: window.sessionStorage.getItem("user_id"),
        executive_type: "supplyexecutive",
        region: this.state.region,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if (result.executives) {
            this.setState({
              execData: result.executives,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  getPanelUsers() {
    fetch(config.url + "htmlcrm/getPanelUsers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        loginstatus: "blocked",
        region: this.state.region,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if (result.message === "successfull") {
            this.setState({
              blockedData: result.userslist,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    } else {
      this.getSupplyExecDetails();
      this.getSupplyManagerDetails();
      this.getPanelUsers();
      this.setState({
        region: sessionStorage.getItem("operating_region"),
      });
    }
  }

  render() {
    const execDataToDisplay = this.state.searchExecInput.length
      ? this.state.filteredExecData
      : this.state.execData;
    const mngDataToDisplay = this.state.searchMngInput.length
      ? this.state.filteredMngData
      : this.state.managerData;
    const userDataToDisplay = this.state.searchUserInput.length
      ? this.state.filteredUserData
      : this.state.blockedData;

    // console.log(this.state.userData);
    const execColumns = [
      {
        Header: "Employee ID",
        accessor: "executiveid",
      },
      {
        Header: "Name",
        accessor: "first_name",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Business accounts",
        id: "vendors",
        Cell: (row) => {
          if (
            row.original.vendors === undefined ||
            row.original.vendors === null ||
            row.original.vendors.length === 0
          ) {
            return <div>No vendors assigned</div>;
          } else {
            return <div>{row.original.vendors + " "}</div>;
          }
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Duty status",
        id: "shift",
        Cell: (row) => {
          if (
            row.original.shift === undefined ||
            row.original.shift === null ||
            row.original.shift === ""
          ) {
            return <div>-</div>;
          } else {
            return <div>{this.capitalizeFirstLetter(row.original.shift)}</div>;
          }
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Job role",
        accessor: "roletype",
        Cell: (row) => {
          if (
            row.original.roletype === undefined ||
            row.original.roletype === null ||
            row.original.roletype.length === 0
          ) {
            return <div>-</div>;
          } else {
            return (
              <div>
                {this.formatRoledata(
                  row.original.roletype,
                  row.original.usertype
                )}
              </div>
            );
          }
        },
      },
      {
        id: "edit",
        Header: "Actions",
        Cell: (row) => {
          //  console.log(this);
          // console.log(row.original.request_status)
          return (
            <div className="">
              <Button
                className="px-4 my-2"
                theme="dark"
                onClick={() => {
                  this.getUserDetails(row.original.executiveid);
                }}
              >
                VIEW
              </Button>
            </div>
          );
        },
      },
    ];
    const managerColumns = [
      {
        Header: "Employee ID",
        accessor: "executiveid",
      },
      {
        Header: "Name",
        accessor: "first_name",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Duty status",
        id: "shift",
        Cell: (row) => {
          if (
            row.original.shift === undefined ||
            row.original.shift === null ||
            row.original.shift === ""
          ) {
            return <div>-</div>;
          } else {
            return <div>{this.capitalizeFirstLetter(row.original.shift)}</div>;
          }
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Job role",
        accessor: "roletype",
        Cell: (row) => {
          if (
            row.original.roletype === undefined ||
            row.original.roletype === null ||
            row.original.roletype.length === 0
          ) {
            return <div>-</div>;
          } else {
            return (
              <div>
                {this.formatRoledata(
                  row.original.roletype,
                  row.original.usertype
                )}
              </div>
            );
          }
        },
      },
      {
        id: "edit",
        Header: "Actions",
        Cell: (row) => {
          //  console.log(this);
          // console.log(row.original.request_status)
          return (
            <div className="">
              <Button
                className="px-4 my-2"
                theme="dark"
                onClick={() => {
                  this.getUserDetails(row.original.executiveid);
                }}
              >
                VIEW
              </Button>
            </div>
          );
        },
      },
    ];

    const blockedColumns = [
      {
        Header: "Employee ID",
        accessor: "user_id",
      },
      {
        Header: "Name",
        accessor: "first_name",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Phonenumber",
        accessor: "phonenumber",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Job role",
        accessor: "roletype",
        Cell: (row) => {
          if (
            row.original.roletype === undefined ||
            row.original.roletype === null ||
            row.original.roletype.length === 0
          ) {
            return <div>-</div>;
          } else {
            return (
              <div>
                {this.formatRoledata(
                  row.original.roletype,
                  row.original.usertype
                )}
              </div>
            );
          }
        },
      },
      {
        id: "edit",
        Header: "Actions",
        Cell: (row) => {
          //  console.log(this);
          // console.log(row.original.request_status)
          return (
            <div className="">
              <Button
                className="px-4 my-2"
                theme="dark"
                onClick={() => {
                  this.updateBlockOrActive(row.original.user_id);
                }}
              >
                ACTIVATE
              </Button>
            </div>
          );
        },
      },
    ];
    return (
      <Container fluid className="main-content-container px-md-4 px-3">
        <Row noGutters className="page-header py-4 px-3">
          <Col className="col-12">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Employee management
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active>Supply accounts</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="col-12">
            <PageTitle
              title="Manage accounts"
              className="text-sm-left mb-3 p-0 custom-margin"
            />
          </Col>
        </Row>
        <Row className="px-md-3 mb-5">
          <Col className="col-12 col-md-12 col-lg-12">
            <div className="float-right mt-2">
              <Button
                className=""
                theme="dark"
                id="goback-button"
                onClick={this.goBack}
              >
                <i className="fas fa-arrow-left mx-1" />
                <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                  BACK
                </span>
              </Button>
            </div>
          </Col>
        </Row>
        {this.state.divShow === false && (
          <div className="mt-2 mb-5">
            <Row className="mb-5 px-md-3 px-0 ">
              <Col className="col-12 col-md-12 col-lg-12">
                <h5
                  className="page-header py-3 "
                  style={{ fontSize: "1.14rem" }}
                >
                  <u>Supply executives</u>
                </h5>
              </Col>

              <Col className="col-12 col-md-12 col-lg-12">
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col className="col-12 col-md-12 col-lg-3 my-3 order-2 order-md-1">
                        <Search
                          data={this.state.execData}
                          columns={execColumns}
                          handleSetFilteredData={this.handleExecFilteredData}
                          handleSetSearchInput={this.handleExecSearchInput}
                        />
                      </Col>
                      <Col className="col-12 col-md-9 py-3 d-flex justify-content-end pb-2 order-1 order-md-2">
                        <Button
                          onClick={this.getSupplyExecDetails}
                          theme="dark"
                        >
                          <i className="fas fa-sync-alt" />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12 col-md-12 col-lg-12 px-0 px-md-2">
                        <ReactTable
                          className="text-center -striped -highlight"
                          noDataText="No data to display"
                          data={execDataToDisplay}
                          columns={execColumns}
                          minRows={execDataToDisplay.length > 0 ? 0 : 4}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mb-5 px-md-3 px-0">
              <Col className="col-12 col-md-12 col-lg-12 mb-1">
                <h5
                  className="page-header py-3 "
                  style={{ fontSize: "1.14rem" }}
                >
                  <u>Supply managers</u>
                </h5>
              </Col>

              <Col className="col-12 col-md-12 col-lg-12">
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col className="col-12 col-md-3 col-lg-3 my-3 order-2 order-md-1">
                        <Search
                          data={this.state.managerData}
                          columns={managerColumns}
                          handleSetFilteredData={this.handleMngFilteredData}
                          handleSetSearchInput={this.handleMngSearchInput}
                        />
                      </Col>
                      <Col className="col-12 col-md-9 py-3 d-flex justify-content-end pb-2 order-1 order-md-2">
                        <Button
                          onClick={this.getSupplyManagerDetails}
                          theme="dark"
                        >
                          <i className="fas fa-sync-alt" />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12 col-md-12 col-lg-12 px-0 px-md-2">
                        <ReactTable
                          className="text-center -striped -highlight"
                          noDataText="No data to display"
                          data={mngDataToDisplay}
                          columns={managerColumns}
                          minRows={mngDataToDisplay.length > 0 ? 0 : 4}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mb-5 px-md-3 px-0">
              <Col className="col-12 col-md-12 col-lg-12 mb-1">
                <h5
                  className="page-header py-3"
                  style={{ fontSize: "1.14rem" }}
                >
                  <u>Blocked users</u>
                </h5>
              </Col>

              <Col className="col-12 col-md-12">
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col className="col-12 col-md-3 col-lg-3 my-3 order-2 order-md-1">
                        <Search
                          data={this.state.blockedData}
                          columns={blockedColumns}
                          handleSetFilteredData={this.handleUserFilteredData}
                          handleSetSearchInput={this.handleuserSearchInput}
                        />
                      </Col>
                      <Col className="col-12 col-md-9 py-3 d-flex justify-content-end pb-2 order-1 order-md-2">
                        <Button onClick={this.getPanelUsers} theme="dark">
                          <i className="fas fa-sync-alt" />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12 col-md-12 col-lg-12 px-0 px-md-2">
                        <ReactTable
                          className="text-center -striped -highlight"
                          data={userDataToDisplay}
                          columns={blockedColumns}
                          noDataText="No data to display"
                          minRows={userDataToDisplay.length > 0 ? 0 : 4}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
        {this.state.divShow === true && (
          <div className="mb-5 px-md-3">
            <Row>
              <Col className="col-12 col-md-12 col-lg-12">
                <Card className="mb-4">
                  <CardHeader>
                    <button
                      type="button"
                      className="close float-right ml-auto mr-3 mt-4"
                      aria-label="Close"
                      onClick={this.onTitleChange}
                    >
                      <span aria-hidden="true">
                        <i class="fas fa-times" />
                      </span>
                    </button>
                  </CardHeader>
                  <CardBody className="">
                    <Row />
                    <Row>
                      <Col
                        className="col-12 col-md-12 col-lg-12"
                        style={{ "text-align": "center" }}
                      >
                        <img
                          className="rounded-image"
                          src="https://panel.hornok.in/static/images/avatars/man.png"
                          width="110px"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        className="col-12 col-md-12 col-lg-12"
                        style={{ "text-align": "center" }}
                      >
                        <h4>
                          {this.state.userData.first_name +
                            " " +
                            this.state.userData.last_name}
                        </h4>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col
                        className="col-12 col-md-12 col-lg-12"
                        style={{ "text-align": "center" }}
                      >
                        <span>
                          {this.formatRoledata(
                            this.state.userData.roletype,
                            this.state.userData.usertype
                          )}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col className="col-12 col-md-4 col-lg-4" />
                      <Col
                        className="col-12 col-md-4 col-lg-4"
                        style={{ "text-align": "center" }}
                      >
                        {/* <Button onClick={this.enableAccStatus}>Edit</Button> */}
                        {this.state.display === true && (
                          <Button
                            onClick={this.enableAccStatus}
                            className="mb-2"
                            theme="dark"
                            id="goback-button"
                          >
                            EDIT
                          </Button>
                        )}
                        {this.state.display === false && (
                          <Button
                            onClick={this.updateBlockOrActiveFromDiv}
                            theme="dark"
                            id="goback-button"
                          >
                            SAVE
                          </Button>
                        )}
                      </Col>
                      <Col className="col-12 col-md-4 col-lg-4" />
                    </Row>
                    <Row className="mb-4">
                      <Col className="col-12 col-md-4 col-lg-4" />
                      <Col className="col-6 col-md-2 col-lg-2 d-flex justify-content-center">
                        {this.state.display === true && (
                          <FormRadio
                            name="accountstate"
                            disabled={this.state.display}
                            checked={this.state.selectedItem === "activated"}
                          >
                            <label style={{ color: "#59E67F" }}>
                              {this.state.selectedItem === "activated"
                                ? "Activated"
                                : "Activate"}
                            </label>
                          </FormRadio>
                        )}
                        {this.state.display === false && (
                          <FormRadio
                            name="accountstate"
                            disabled={this.state.display}
                            checked={this.state.selectedItem === "activated"}
                            onChange={() => {
                              this.changeAccountState("activated");
                            }}
                          >
                            <label style={{ color: "#59E67F" }}>
                              {this.state.selectedItem === "activated"
                                ? "Activated"
                                : "Activate"}
                            </label>
                          </FormRadio>
                        )}
                      </Col>
                      <Col className="col-6 col-md-2 col-lg-2 d-flex justify-content-center">
                        {this.state.display === true && (
                          <FormRadio
                            name="accountstate"
                            disabled={this.state.display}
                            checked={this.state.selectedItem === "block"}
                          >
                            <label style={{ color: "#FF0000" }}>
                              {this.state.selectedItem === "block"
                                ? "Blocked"
                                : "Block"}
                            </label>
                          </FormRadio>
                        )}
                        {this.state.display === false && (
                          <FormRadio
                            name="accountstate"
                            disabled={this.state.display}
                            checked={this.state.selectedItem === "block"}
                            onChange={() => {
                              this.changeAccountState("block");
                            }}
                          >
                            <label style={{ color: "#FF0000" }}>
                              {this.state.selectedItem === "block"
                                ? "Blocked"
                                : "Block"}
                            </label>
                          </FormRadio>
                        )}
                      </Col>
                      <Col className="col-12 col-md-4 col-lg-4" />
                    </Row>
                    <Row>
                      <Col className="col-6 col-md-4 mb-4 ">
                        <label>First name</label>
                        <FormInput
                          id="offeredAmount"
                          readOnly={this.state.readOnly}
                          type="text"
                          placeholder={this.state.userData.first_name}
                          onChange={this.handleOnChange}
                        />
                      </Col>
                      <Col className="col-6 col-md-4 mb-4 ">
                        <label>Last name</label>
                        <FormInput
                          id="extra_traveled_kms"
                          readOnly={this.state.readOnly}
                          type="text"
                          placeholder={this.state.userData.last_name}
                          onChange={this.handleOnChange}
                        />
                      </Col>
                      <Col className="col-6 col-md-4 mb-4 ">
                        <label>Email</label>
                        <FormInput
                          id="empty_kms"
                          readOnly={this.state.readOnly}
                          type="text"
                          placeholder={this.state.userData.email}
                          onChange={this.handleOnChange}
                        />
                      </Col>
                      <Col className="col-6 col-md-4 mb-4 ">
                        <label>Mobile number</label>
                        <FormInput
                          id="fineAmount"
                          readOnly={this.state.readOnly}
                          type="text"
                          placeholder={this.state.userData.phonenumber}
                          onChange={this.handleOnChange}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="col-10 col-md-10 col-lg-10">
                        <h5>Job role</h5>
                      </Col>
                      <Col className="col-2 col-md-2 col-lg-2 mb-3">
                        {this.state.readOnlyJob === true && (
                          <Button
                            className="float-right"
                            onClick={this.handleEditJob}
                            theme="dark"
                            id="goback-button"
                          >
                            EDIT
                          </Button>
                        )}
                        {this.state.readOnlyJob === false && (
                          <Button
                            className="float-right"
                            theme="dark"
                            id="goback-button"
                            onClick={this.updateRoleType}
                          >
                            SAVE
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col className="col-12 col-md-12 col-lg-12">
                        {this.state.readOnlyJob === true && (
                          <Row>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                inline
                                disabled={this.state.readOnlyJob}
                                name="jobManager"
                                checked={
                                  this.state.selectedJobRole ===
                                  "traineeexecutive"
                                }
                              >
                                Trainee executive
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                inline
                                disabled={this.state.readOnlyJob}
                                name="jobManager"
                                checked={
                                  this.state.selectedJobRole ===
                                  "juniorexecutive"
                                }
                              >
                                Junior executive
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                inline
                                disabled={this.state.readOnlyJob}
                                name="jobManager"
                                checked={
                                  this.state.selectedJobRole ===
                                  "seniorexecutive"
                                }
                              >
                                Senior executive
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                inline
                                disabled={this.state.readOnlyJob}
                                name="jobManager"
                                checked={
                                  this.state.selectedJobRole === "teamlead"
                                }
                              >
                                Team lead
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                inline
                                disabled={this.state.readOnlyJob}
                                name="jobManager"
                                checked={
                                  this.state.selectedJobRole ===
                                  "assistantmanager"
                                }
                              >
                                Assistant Manager
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                inline
                                disabled={this.state.readOnlyJob}
                                name="jobManager"
                                checked={
                                  this.state.selectedJobRole === "manager"
                                }
                              >
                                Manager
                              </FormRadio>
                            </Col>
                          </Row>
                        )}
                        {this.state.readOnlyJob === false && (
                          <Row>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                inline
                                disabled={this.state.readOnlyJob}
                                name="jobManager"
                                checked={
                                  this.state.selectedJobRole ===
                                  "traineeexecutive"
                                }
                                onChange={() => {
                                  this.changeJobRole("traineeexecutive");
                                }}
                              >
                                Trainee executive
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                inline
                                disabled={this.state.readOnlyJob}
                                name="jobManager"
                                checked={
                                  this.state.selectedJobRole ===
                                  "juniorexecutive"
                                }
                                onChange={() => {
                                  this.changeJobRole("juniorexecutive");
                                }}
                              >
                                Junior executive
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                inline
                                disabled={this.state.readOnlyJob}
                                name="jobManager"
                                checked={
                                  this.state.selectedJobRole ===
                                  "seniorexecutive"
                                }
                                onChange={() => {
                                  this.changeJobRole("seniorexecutive");
                                }}
                              >
                                Senior executive
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                inline
                                disabled={this.state.readOnlyJob}
                                name="jobManager"
                                checked={
                                  this.state.selectedJobRole === "teamlead"
                                }
                                onChange={() => {
                                  this.changeJobRole("teamlead");
                                }}
                              >
                                Team lead
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                inline
                                disabled={this.state.readOnlyJob}
                                name="jobManager"
                                checked={
                                  this.state.selectedJobRole ===
                                  "assistantmanager"
                                }
                                onChange={() => {
                                  this.changeJobRole("assistantmanager");
                                }}
                              >
                                Assistant Manager
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                inline
                                disabled={this.state.readOnlyJob}
                                name="jobManager"
                                checked={
                                  this.state.selectedJobRole === "manager"
                                }
                                onChange={() => {
                                  this.changeJobRole("manager");
                                }}
                              >
                                Manager
                              </FormRadio>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col className="col-10 col-md-10 col-lg-10">
                        <h5>Shift details</h5>
                      </Col>
                      <Col className="col-2 col-md-2 col-lg-2 mb-3">
                        {this.state.readOnlyShift === true && (
                          <Button
                            className="float-right mb-3"
                            onClick={this.handleEditShift}
                            theme="dark"
                            id="goback-button"
                          >
                            EDIT
                          </Button>
                        )}
                        {this.state.readOnlyShift === false && (
                          <Button
                            className="float-right mb-3"
                            theme="dark"
                            id="goback-button"
                            onClick={this.updateShift}
                          >
                            SAVE
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col className="col-12 col-md-12 col-lg-12 ">
                        {this.state.readOnlyShift === true && (
                          <Row>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                inline
                                disabled={this.state.readOnlyShift}
                                name="shiftstatus"
                                checked={
                                  this.state.selectedShift === "dayshift"
                                }
                              >
                                Day shift
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                disabled={this.state.readOnlyShift}
                                inline
                                name="shiftstatus"
                                checked={
                                  this.state.selectedShift === "midshift"
                                }
                              >
                                Mid shift
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                disabled={this.state.readOnlyShift}
                                inline
                                name="shiftstatus"
                                checked={
                                  this.state.selectedShift === "nightshift"
                                }
                              >
                                Night shift
                              </FormRadio>
                            </Col>
                          </Row>
                        )}
                        {this.state.readOnlyShift === false && (
                          <Row>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                inline
                                disabled={this.state.readOnlyShift}
                                name="shiftstatus"
                                checked={
                                  this.state.selectedShift === "dayshift"
                                }
                                onChange={() => {
                                  this.changeShift("dayshift");
                                }}
                              >
                                Day shift
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                disabled={this.state.readOnlyShift}
                                inline
                                name="shiftstatus"
                                checked={
                                  this.state.selectedShift === "midshift"
                                }
                                onChange={() => {
                                  this.changeShift("midshift");
                                }}
                              >
                                Mid shift
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                disabled={this.state.readOnlyShift}
                                inline
                                name="shiftstatus"
                                checked={
                                  this.state.selectedShift === "nightshift"
                                }
                                onChange={() => {
                                  this.changeShift("nightshift");
                                }}
                              >
                                Night shift
                              </FormRadio>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
        {this.state.success === false && (
          <ReactSnackBar
            Icon={
              <i
                className="far fa-times-circle fa-lg"
                style={{ color: "#FF0000" }}
              />
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
        {this.state.success === true && (
          <ReactSnackBar
            Icon={
              <span>
                <i
                  className="far fa-check-circle fa-lg"
                  style={{ color: "#26FF58" }}
                />
              </span>
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
      </Container>
    );
  }
}

SupplyAccountViews.propTypes = {
  onBoardingTop: PropTypes.array,
};

SupplyAccountViews.defaultProps = {};

export default SupplyAccountViews;
