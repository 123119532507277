// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Collapse,
  Button,
  Tooltip,
  Card,
  CardBody,
  FormCheckbox,
  FormSelect,
  CardHeader,
  Form,
  Breadcrumb,
  BreadcrumbItem,
  FormInput,
  FormGroup,
} from "shards-react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import * as config from "../constants/constants.json";
import ReactSnackBar from "react-js-snackbar";
import ProgressBar from "./../components/common/ProgressStepBar";
import PageTitle from "./../components/common/PageTitle";
import InputDataForm from "./../components/common/InputDataForm";
// import AddDriver from "./AddDriverLogic";
import AddDriver from "../viewHooks/AddDriver";
// import AddDriver from "../components/dataviews/AddDriverReWork";
import EditDriver from "./EditDriverLogic";
const phonetest = /[6-9]{1}\d{9}/;
class AddEditDriver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchphonenumber: "",
      sentPhonenumber: "",
      newNumber: "",
      notExist: false,
      driverExists: false,
      Show: false,
      Showing: false,
      success: false,
      open: false,
      comoingFromEdit: false,
      display: undefined,
      clicked: false,
      operatingRegionArray: [],
    };
    this.goBack = this.goBack.bind(this);
    this.goBackTodriv = this.goBackTodriv.bind(this);
    this.toggleExists = this.toggleExists.bind(this);
    this.handleKeyChange = this.handleKeyChange.bind(this);
    this.searchDriver = this.searchDriver.bind(this);
    this.toggleDriverExists = this.toggleDriverExists.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
  }

  goBackTodriv() {
    this.props.history.push("/driveraccounts");
  }
  handleStateChange(data) {
    console.log(data);
    if (data === "edit") {
      this.setState(
        {
          display: data,
        },
        () => {
          this.toggleDriverExists();
        }
      );
    } else {
      this.setState(
        {
          display: data,
        },
        () => {
          this.toggleExists();
        }
      );
    }
  }
  toggleDriverExists() {
    this.setState({
      driverExists: !this.state.driverExists,
      sentPhonenumber: this.state.searchphonenumber,
      clicked: true,
    });
  }
  toggleExists() {
    this.setState({
      notExist: !this.state.notExist,
    });
  }
  handleKeyChange(e, name) {
    let value;
    const newState = {};
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      value = e.target.value;
      newState[name] = value;
      this.setState({ ...this.state, ...newState });
    }
  }
  goBack() {
    this.props.history.goBack();
  }
  searchDriver(event) {
    console.log("herrrrrrrrrrrrrrrrrrrrrrrrr");
    if (event) {
      event.preventDefault();
    }
    if (this.state.searchphonenumber.length !== 10) {
      this.setState(
        {
          success: false,
          message: "Phonenumber should be 10 digits",
        },
        () => {
          this.show();
        }
      );
    } else if (!phonetest.test(this.state.searchphonenumber)) {
      this.setState(
        {
          success: false,
          message: "Phonenumber is not valid",
        },
        () => {
          this.show();
        }
      );
    } else {
      // serch opertion here
      fetch(config.url + "onboard/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          phonenumber: this.state.searchphonenumber,
          region: sessionStorage.getItem("operating_region"),
          // phonenumber: "8123173193"
        }),
      })
        .then((res) => res.json())
        .then(
          (resultsss) => {
            console.log(resultsss);
            if (resultsss.message) {
              if (resultsss.message === "user does not exist") {
                if (this.state.comoingFromEdit === true) {
                  this.setState({
                    newNumber: true,
                  });
                } else {
                  this.setState({
                    notExist: !this.state.notExist,
                    operatingRegionArray: resultsss.regions,
                  });
                }

                // this.setState({
                //   newNumber: true
                // });
              } else if (resultsss.message === "consumer") {
                this.setState(
                  {
                    newNumber: false,
                    success: false,
                    message: "The user is registered as a rider",
                  },
                  () => {
                    this.show();
                  }
                );
              } else if (resultsss.message === "successfull") {
                this.setState({
                  newNumber: false,
                  driverExists: !this.state.driverExists,
                  operatingRegionArray: resultsss.regions,
                });
              } else {
                this.setState(
                  {
                    newNumber: false,
                    success: false,
                    message: "The user is already registered",
                    operatingRegionArray: resultsss.regions,
                  },
                  () => {
                    this.show();
                  }
                );
              }
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    }
  }

  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };

  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     prevState.searchphonenumber !==
  //     this.state.searchphonenumber
  //   ) {
  //     this.setState({
  //       searchphonenumber: this.props.history.location.state.searchphonenumber,
  //     });
  //   }
  // }

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    } else {
      if (this.props.history.location.state) {
        console.log(this.props.history.location.state.phonenumber);
        this.setState(
          {
            searchphonenumber: this.props.history.location.state.phonenumber,
          },
          () => {
            this.searchDriver();
          }
        );
      } else {
      }
    }
  }
  render() {
    // console.log(
    //   this.state.display === "onboard" ||
    //     this.state.display === undefined ||
    //     this.state.display === " "
    // );
    return (
      <Container fluid className="main-content-container px-md-4">
        <Row noGutters className="page-header py-4 px-md-3">
          <Col className="col-12">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBackTodriv}>
                  Driver accounts
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active />
            </Breadcrumb>
          </Col>
          <Col className="col-12 mt-1">
            <PageTitle
              title={
                this.state.display === "onboard" ||
                this.state.display === undefined
                  ? "Add a driver"
                  : "Edit driver"
              }
              className="text-sm-left mb-3 p-0 custom-margin"
            />
          </Col>
          {/* <PageTitle
            title="Add a driver"
            subtitle="Driver Accounts"
            className="text-sm-left mb-3"
          /> */}
        </Row>
        <Row className="mb-4 px-md-3">
          <Col className="col-12 col-md-12 col-lg-12">
            <Form onSubmit={this.searchDriver}>
              <Row>
                <FormGroup className="col-md-4 col-lg-4 mb-md-0">
                  <FormInput
                    className="py-3"
                    readOnly={this.state.display === "onboard" ? true : false}
                    onChange={(e) =>
                      this.handleKeyChange(e, "searchphonenumber")
                    }
                    value={this.state.searchphonenumber}
                    placeholder="Enter driver's phonenumber here"
                    maxLength="10"
                    required
                  />
                </FormGroup>
                <FormGroup className="col-md-3 d-none d-sm-none d-md-block my-auto">
                  <Button
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "700",
                      minWidth: "200px",
                      borderRadius: "3px",
                    }}
                    theme="dark"
                    size="lg"
                    disabled={this.state.display === "onboard" ? true : false}
                  >
                    SEARCH
                  </Button>
                </FormGroup>
                <FormGroup className="col-md-3 d-block d-sm-block d-md-none d-flex justify-content-center">
                  <Button
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "700",
                      minWidth: "200px",
                      borderRadius: "3px",
                    }}
                    theme="dark"
                    size="lg"
                    disabled={this.state.display === "onboard" ? true : false}
                  >
                    SEARCH
                  </Button>
                </FormGroup>
              </Row>
            </Form>
          </Col>
        </Row>

        <Modal
          show={this.state.driverExists}
          centered
          onHide={this.toggleDriverExists}
        >
          <ModalHeader closeButton>Edit confirmation</ModalHeader>
          <ModalBody>
            Driver already exists. Do you want to edit the driver's information?
          </ModalBody>
          <ModalFooter>
            <Button
              theme="dark"
              size="lg"
              onClick={this.toggleDriverExists}
              style={{
                fontSize: "0.8rem",
                fontWeight: "700",
                minWidth: "120px",
                borderRadius: "3px",
              }}
            >
              CANCEL
            </Button>
            <Button
              theme="dark"
              size="lg"
              style={{
                fontSize: "0.8rem",
                fontWeight: "700",
                minWidth: "120px",
                borderRadius: "3px",
              }}
              onClick={() => {
                this.handleStateChange("edit");
              }}
            >
              EDIT
            </Button>
          </ModalFooter>
        </Modal>
        <Modal show={this.state.notExist} centered onHide={this.toggleExists}>
          <ModalHeader>On-boarding confirmation</ModalHeader>
          <ModalBody>
            Driver does not exist. Do you want to on-board the driver?
          </ModalBody>
          <ModalFooter>
            <Button
              theme="dark"
              size="lg"
              onClick={this.toggleExists}
              style={{
                fontSize: "0.8rem",
                fontWeight: "700",
                minWidth: "120px",
                borderRadius: "3px",
              }}
            >
              CANCEL
            </Button>
            <Button
              theme="dark"
              size="lg"
              onClick={() => {
                this.handleStateChange("onboard");
              }}
              style={{
                fontSize: "0.8rem",
                fontWeight: "700",
                minWidth: "120px",
                borderRadius: "3px",
              }}
            >
              ONBOARD
            </Button>
          </ModalFooter>
        </Modal>
        {this.state.display === "onboard" && (
          <AddDriver
            phonenumber={this.state.searchphonenumber}
            dataForPush={this.props}
            operatingRegionArray={this.state.operatingRegionArray}
          />
        )}
        {this.state.display === "edit" && (
          <EditDriver
            phonenumber={this.state.sentPhonenumber}
            dataForPush={this.props}
            clicked={this.state.clicked}
            clicker={this.updateClick}
            operatingRegionArray={this.state.operatingRegionArray}
          />
        )}
        {this.state.success === false && (
          <ReactSnackBar
            Icon={
              <i
                className="far fa-times-circle fa-lg"
                style={{ color: "#FF0000" }}
              />
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
      </Container>
    );
  }
}

export default AddEditDriver;
