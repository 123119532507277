import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { Map, Source, Layer, LayerProps } from "react-map-gl";
import config from "../../constants/constants";
import styling from "../../constants/googleMapStyles";

const MAPBOX_TOKEN = config.MAPBOX_ACCESS_TOKEN;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

function LiveMaps(props) {
  const [pointData, setPointData] = useState([]);
  const [viewState, setViewState] = React.useState({
    longitude: -100,
    latitude: 40,
    zoom: 3.5,
  });

  const fetchLocationCall = () => {
    let dataToSend = {
      trip_id: props.dataProps.trip_id,
    };
  };

  return (
    <Map
      {...viewState}
      mapStyle="mapbox://styles/mapbox/streets-v10"
      mapboxAccessToken={MAPBOX_TOKEN}
    />
  );
}

export default LiveMaps;
