// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";
// import DatePicker from "react-date-picker";

// import TripDataBox from "../components/common/TripDataBox";
// import CollapseDiv from "../components/common-actions/CollapseDiv"
import PageTitle from "./../components/common/PageTitle";
import * as config from "../constants/constants.json";
// import DatePickerCustom from "../components/utilities/DatePicker"
// import CardLayout from "../components/common/CardLayout"
// import TripView from "./../components/dataviews/TripView"
//import TripDetalis from "./../components/common/TripDetails"
import TripDetachView from "./../components/dataviews/Detachmentviews";
class RideDetachment extends React.Component {
  constructor(props) {
    // console.log(props)
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: false,
      hits: {},
      date: new Date(),
      showDetails: false,
    };
    // console.log(this.state.hits);
    this.onChange = this.onChange.bind(this);
    this.goBack = this.goBack.bind(this);
  }
  // onChange = date => this.setState({ date })
  goBack() {
    this.props.history.goBack();
  }
  onChange(date) {
    // console.log(this);
    // console.log(date)
    this.setState({ date }, () => {
      this.countFetch();
    });
  }
  toggle() {
    this.setState({
      collapse: !this.state.collapse,
      showDetails: !this.state.showDetails,
    });
  }

  countFetch() {
    let time = this.state.date;
    let milliseconds = time.getTime();
    // console.log(new Date(this.state.date));
    fetch(config.url + "htmlcrm/getAllRidesCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        milliseconds: milliseconds,
        region_name: window.sessionStorage.getItem("operating_region"),
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result)
          let resArray = {
            totalRidesCount: result.totalRidesCount,
            assignedRides: result.scheduledRidesCount.assignedRides,
            unassignedRides: result.scheduledRidesCount.unassignedRides,
            cancelledRides: result.scheduledRidesCount.cancelledRides,
            completedRides: result.scheduledRidesCount.completedRides,
            ongoing: result.ongoing,
            detachedRides: result.scheduledRidesCount.detachedRides,
            consumerCanceledCount: result.consumerCanceledCount,
            driverCanceledCount: result.driverCanceledCount,
          };
          this.setState({ hits: resArray });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  componentDidMount() {
    this.countFetch();
  }

  render() {
    let data = this.state.hits;
    // console.log("****************");
    // console.log(data);
    return (
      <Container fluid className="main-content-container px-md-4">
        <Row noGutters className="page-header py-4 px-md-3">
          <Col className="col-12">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Business accounts
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active />
            </Breadcrumb>
          </Col>
          <Col class="col-12 mt-1">
            <PageTitle
              title="Ride detachment list"
              className="text-sm-left mb-3 pl-0 custom-margin"
            />
          </Col>
        </Row>
        <Row className="px-md-3 mb-5 mt-md-5">
          <Col className="col-12 col-md-12 col-lg-12 mb-5">
            <div className="float-right mt-2">
              <Button
                className=""
                id="goback-button"
                theme="dark"
                onClick={this.goBack}
              >
                <i className="fas fa-arrow-left mx-1" />
                <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                  BACK
                </span>
              </Button>
            </div>
          </Col>
          <TripDetachView dataProps={this.props} />
        </Row>
      </Container>
    );
  }
}
RideDetachment.propTypes = {
  smallStats: PropTypes.array,
  RideStats: PropTypes.array,
  CountStats: PropTypes.array,
  hits: PropTypes.string,
  date: PropTypes.oneOf(["object", "string"]),
  tripDate: PropTypes.oneOf(["object", "string"]),
};

RideDetachment.defaultProps = {
  smallStats: [
    {
      label: "Total",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "totalRidesCount",
    },
  ],
  RideStats: [
    {
      label: "UNASSIGNED",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "unassignedRides",
    },
    {
      label: "ASSIGNED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "assignedRides",
    },
    {
      label: "ON-GOING RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "ongoing",
    },
    {
      label: "COMPLETED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "completedRides",
    },
    {
      label: "CANCELLED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "cancelledRides",
    },
    {
      label: "DETACHED",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "detachedRides",
    },
  ],
  CountStats: [
    {
      label: "Total",
      value: "0",
      attrs: { md: "2", sm: "6" },
      stats: false,
    },
  ],
};

export default RideDetachment;
