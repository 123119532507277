// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import CommonBox from "../components/common/commonDisplayBox";
import PageTitle from "./../components/common/PageTitle";
// import TopDrivers from "../components/common/TopDrivers"
// import RideAnalysis from "../components/dataviews/RideAnalysis"

class EmployeeManagementView extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    }
  }
  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4 px-3">
          <PageTitle
            title="Manage employee accounts"
            subtitle="Employee accounts"
            className="text-sm-left mb-3"
          />
        </Row>
        <Row className="px-3">
          {this.props.employeeAccountsTop.map(
            (stats, idx) =>
              stats.doNotDisplay.roletype !==
                window.sessionStorage.getItem("roletype") &&
              stats.doNotDisplay.roletype !==
                window.sessionStorage.getItem("roletype") && (
                <Col className="col-md mb-4" key={idx} {...stats.attrs}>
                  <CommonBox
                    id={`small-stats-${idx}`}
                    variation="1"
                    label={stats.label}
                    value={stats.value}
                    stats={stats.stats}
                    display={stats.display}
                    path="/employeemanagement/"
                  />
                </Col>
              )
          )}
        </Row>
      </Container>
    );
  }
}

// const EmployeeManagementView = ({ employeeAccountsTop }) => (
// );

EmployeeManagementView.propTypes = {
  employeeAccountsTop: PropTypes.array,
};

EmployeeManagementView.defaultProps = {
  employeeAccountsTop: [
    {
      label: "dispatch",
      value: "Dispatch",
      id: "DISPATCH",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
      doNotDisplay: {},
    },
    {
      label: "supply",
      value: "Supply",
      id: "SUPPLY",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
      doNotDisplay: {
        usertype: "dispatchmanager",
        roletype: "assistantmanager",
      },
    },
    {
      label: "accounts",
      value: "Accounts",
      id: "ACCOUNTS",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
      doNotDisplay: {
        usertype: "dispatchmanager",
        roletype: "assistantmanager",
      },
    },
  ],
};

export default EmployeeManagementView;
