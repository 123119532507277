import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
// import Button from "@material-ui/core/Button";
// import Paper from "@material-ui/core/Paper";
// import * as config from "../../constants/constants.json";
// import Check from "@material-ui/icons/Check";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  completed: {
    color: "#27FC69",
  },
  step: {
    "&$active": {
      color: "#FF8307",
    },
    "&$completed": {
      color: "#27FC69",
    },

    "&$disabled": {
      color: "#FF0000",
    },
    "&$error": {
      color: "#FF0000",
    },
  },
  active: {
    backgroundColor: "#27FC69",
  },
}));

function getSteps() {
  return [
    "Leave for pick up",
    "Notified",
    "Ride started",
    "Toll passed",
    "Ride completed",
  ];
}

// function getStepContent(step) {
//   switch (step) {
//     case 0:
//       return `more.`;
//     case 1:
//       return "keywords.";
//     case 2:
//       return `issues.`;
//     default:
//       return "Unknown step";
//   }
// }
const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});
// function QontoStepIcon(props) {
//   const classes = useQontoStepIconStyles();
//   const { active, completed } = props;

//   return (
//     <div
//       className={clsx(classes.root, {
//         [classes.active]: active,
//       })}
//     >
//       {completed ? (
//         <div className={classes.circle} />
//       ) : (
//         <div className={classes.circle} />
//       )}
//     </div>
//   );
// }

export default function VerticalLinearStepper(props) {
  // console.log(props);
  // console.log(props.dlocProps.toll_info.toll_plaza_passed);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  // const [data, setData] = React.useState([]);
  // const steps = getSteps();

  //   if (props.dlocProps !== undefined) {
  //     if (props.dlocProps.trip_status === "notified") {
  //       setActiveStep(2);
  //     } else if (props.dlocProps.trip_status === "started") {
  //       setActiveStep(3);
  //     } else if (props.dlocProps.toll_info.toll_plaza_passed === true) {
  //       setActiveStep(4);
  //     }
  //   }
  useEffect(() => {
    // console.log(props.dlocProps.toll_info.toll_plaza_passed);
    if (props.dlocProps !== undefined) {
      if (props.dlocProps.trip_status === "accepted") {
        setActiveStep(1);
      } else if (props.dlocProps.trip_status === "notified") {
        setActiveStep(2);
      } else if (props.dlocProps.trip_status === "started") {
        setActiveStep(3);
      } else if (props.dlocProps.toll_info.toll_plaza_passed === true) {
        setActiveStep(4);
      }
    }
  }, []);
  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  //   useEffect(() => {
  //     fetch(config.url + "htmlcrm/getDriverLocation", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         // 'Content-Type': 'application/x-www-form-urlencoded',
  //       },
  //       body: JSON.stringify({
  //         //  trip_id: this.props.tripId,
  //         trip_id: "BjEa30g0RrrJ7i8d",
  //       }),
  //     })
  //       .then((res) => res.json())
  //       .then(
  //         (result) => {
  //           // console.log("herererer");
  //           console.log(result);
  //           setData(result);
  //           if (result.trip_status === "notified") {
  //             setActiveStep(2);
  //           } else if (result.trip_status === "started") {
  //             setActiveStep(3);
  //           } else if (result.toll_info.toll_plaza_passed === true) {
  //             setActiveStep(4);
  //           }
  //         },
  //         // Note: it's important to handle errors here
  //         // instead of a catch() block so that we don't swallow
  //         // exceptions from actual bugs in components.
  //         (error) => {}
  //       );
  //     // fetch(config.url + "booking/getTripStatusLocations", {
  //     //   method: "POST",
  //     //   headers: {
  //     //     "Content-Type": "application/json",
  //     //     // 'Content-Type': 'application/x-www-form-urlencoded',
  //     //   },
  //     //   body: JSON.stringify({
  //     //     trip_id: props.dlocProps,
  //     //   }),
  //     // })
  //     //   .then((res) => res.json())
  //     //   .then(
  //     //     (result) => {
  //     //       console.log(result);
  //     //       // console.log("herererer");
  //     //       // this.setState({
  //     //       //   convoDetails: result.data,
  //     //       // });

  //     //       setData(result);
  //     //       setActiveStep(5);
  //     //     },
  //     //     // Note: it's important to handle errors here
  //     //     // instead of a catch() block so that we don't swallow
  //     //     // exceptions from actual bugs in components.
  //     //     (error) => {}
  //     //   );
  //   }, []);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step expanded>
          <StepLabel
            StepIconProps={{
              classes: {
                root: classes.step,
                completed: classes.completed,
              },
            }}
          >
            Left for pickup
          </StepLabel>
          <StepContent>
            {(props.dlocProps !== undefined || props.dlocProps.length > 0) && (
              <Typography style={{ fontSize: "0.8rem" }}>
                {props.dlocProps.time_stamps !== "" &&
                  props.dlocProps.time_stamps.leaveforpickup_time !== "" &&
                  moment(
                    new Date(props.dlocProps.time_stamps.leaveforpickup_time)
                  ).format("DD/MM/YY [at] hh:mm:ss A")}
              </Typography>
            )}
          </StepContent>
        </Step>
        <Step expanded>
          <StepLabel
            StepIconProps={{
              classes: {
                root: classes.step,
                completed: classes.completed,
              },
            }}
          >
            Notified
          </StepLabel>
          <StepContent>
            {props.dlocProps !== undefined && (
              <Typography style={{ fontSize: "0.8rem" }}>
                {props.dlocProps.time_stamps.notify_time !== "" &&
                  moment(
                    new Date(props.dlocProps.time_stamps.notify_time)
                  ).format("DD/MM/YY [at] hh:mm:ss A")}
              </Typography>
            )}
          </StepContent>
        </Step>
        <Step expanded>
          <StepLabel
            StepIconProps={{
              classes: {
                root: classes.step,
                completed: classes.completed,
              },
            }}
          >
            Ride started
          </StepLabel>
          <StepContent>
            {props.dlocProps !== undefined && (
              <Typography style={{ fontSize: "0.8rem" }}>
                {props.dlocProps.time_stamps.start_time !== "" &&
                  moment(
                    new Date(props.dlocProps.time_stamps.start_time)
                  ).format("DD/MM/YY [at] hh:mm:ss A")}
              </Typography>
            )}
          </StepContent>
        </Step>
        {(props.rideType === "rental" || props.rideType === "Rental") && (
          <Step expanded>
            {(props.dlocProps.toll_info.toll_plaza_passed === true ||
              props.dlocProps.toll_info.toll_plaza_passed === "") && (
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classes.step,
                    completed: classes.completed,
                  },
                }}
              >
                Toll passed
              </StepLabel>
            )}
            {props.dlocProps.toll_info.toll_plaza_passed === false && (
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classes.step,
                    completed: classes.completed,
                  },
                }}
                error={true}
              >
                Toll passed
              </StepLabel>
            )}
            <StepContent>
              {props.dlocProps.toll_info.toll_plaza_passed === true && (
                <Typography style={{ fontSize: "0.8rem" }}>
                  {props.dlocProps.toll_info.toll_timestamp !== "" &&
                    moment(
                      new Date(props.dlocProps.toll_info.toll_timestamp)
                    ).format("DD/MM/YY [at] hh:mm:ss A")}
                </Typography>
              )}
            </StepContent>
          </Step>
        )}
        <Step expanded>
          <StepLabel
            StepIconProps={{
              classes: {
                root: classes.step,
                completed: classes.completed,
              },
            }}
          >
            Ride completed
          </StepLabel>
          <StepContent>
            {props.dlocProps !== undefined && (
              <Typography style={{ fontSize: "0.8rem" }}>
                {/* {moment(new Date(data.end_time)).format(
                "DD/MM/YY [at] hh:mm:ss A"
              )} */}
              </Typography>
            )}
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
}
