// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import DriveraccountBox from "../components/common/DriveraccountBox";
import PageTitle from "./../components/common/PageTitle";
// import TopDrivers from "../components/common/TopDrivers"
// import RideAnalysis from "../components/dataviews/RideAnalysis"
class DriverAccounts extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    }
  }
  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4 px-3">
          <PageTitle
            title="Manage driver accounts"
            subtitle="Driver Accounts"
            className="text-sm-left mb-3"
          />
        </Row>
        {(window.sessionStorage.getItem("usertype") === "dispatchexecutive" ||
          window.sessionStorage.getItem("usertype") === "dispatchmanager") && (
          <div>
            <Row className="px-3">
              {this.props.driverAccountsTop.map(
                (stats, idx) =>
                  stats.doNotDisplay.usertype !==
                    window.sessionStorage.getItem("usertype") &&
                  stats.doNotDisplay.roletype !==
                    window.sessionStorage.getItem("roletype") && (
                    <Col className="col-md mb-4" key={idx} {...stats.attrs}>
                      <DriveraccountBox
                        id={`small-stats-${idx}`}
                        variation="1"
                        label={stats.label}
                        value={stats.value}
                        stats={stats.stats}
                        display={stats.display}
                      />
                    </Col>
                  )
              )}
            </Row>
            <Row className="px-3">
              {this.props.driverAccountsBottom.map(
                (stats, idx) =>
                  stats.doNotDisplay.usertype !==
                    window.sessionStorage.getItem("usertype") &&
                  stats.doNotDisplay.roletype !==
                    window.sessionStorage.getItem("roletype") && (
                    <Col className="col-md mb-4" key={idx} {...stats.attrs}>
                      <DriveraccountBox
                        id={`small-stats-${idx}`}
                        variation="1"
                        label={stats.label}
                        value={stats.value}
                        stats={stats.stats}
                        display={stats.display}
                      />
                    </Col>
                  )
              )}
            </Row>
          </div>
        )}
        {(window.sessionStorage.getItem("usertype") === "supplyexecutive" ||
          window.sessionStorage.getItem("usertype") === "supplymanager") && (
          <React.Fragment>
            <Row className="px-3">
              {this.props.driverAccountsTop.map((stats, idx) => (
                <Col className="col-md mb-4" key={idx} {...stats.attrs}>
                  <DriveraccountBox
                    id={`small-stats-${idx}`}
                    variation="1"
                    label={stats.label}
                    value={stats.value}
                    stats={stats.stats}
                    display={stats.display}
                  />
                </Col>
              ))}
            </Row>
          </React.Fragment>
        )}
      </Container>
    );
  }
}
// const DriverAccounts = ({ driverAccountsTop, driverAccountsBottom }) => (
//   );

DriverAccounts.propTypes = {
  driverAccountsTop: PropTypes.array,
  driverAccountsBottom: PropTypes.array,
};

// {
//   label: "adddriver",
//   value: "+ Add a driver",
//   id: "ADDADRIVER",
//   attrs: { md: "4", sm: "6" },
//   stats: false,
//   display: false,
//   doNotDisplay: {}
// },
// {
//   label: "editdriver",
//   value: "Edit a driver",
//   id: "EDITADRIVER",
//   attrs: { md: "4", sm: "6" },
//   stats: false,
//   display: false,
//   doNotDisplay: {}
// },
DriverAccounts.defaultProps = {
  driverAccountsTop: [
    {
      label: "addeditdriver",
      value: "Add/Edit a driver",
      id: "ADDEDITADRIVER",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
      doNotDisplay: {},
    },

    {
      label: "driveractivation",
      value: "Driver activation",
      id: "driveractivation",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
      doNotDisplay: {
        usertype: "dispatchexecutive",
        roletype: "traineeexecutive",
      },
    },
    {
      label: "quicregister",
      value: "Quick register",
      attrs: { md: "4", sm: "6" },
      stats: true,
      display: false,
      doNotDisplay: {},
    },
    {
      label: "onboardingtracker",
      value: "Onboarding tracker",
      attrs: { md: "4", sm: "6" },
      stats: true,
      display: false,
      doNotDisplay: {
        usertype: "dispatchexecutive",
        roletype: "traineeexecutive",
      },
    },
    {
      label: "driverdashboard",
      value: "Driver dashboard",
      attrs: { md: "4", sm: "6" },
      stats: true,
      display: false,
      doNotDisplay: {
        usertype: "dispatchexecutive",
        roletype: "traineeexecutive",
      },
    },
    {
      label: "qclist",
      value: "QC checklist",
      attrs: { md: "4", sm: "6" },
      stats: true,
      display: false,
      doNotDisplay: {
        usertype: "dispatchexecutive",
        roletype: "traineeexecutive",
      },
    },
  ],
  driverAccountsBottom: [],
};

export default DriverAccounts;
