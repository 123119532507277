import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// import shortid from "shortid";
import { Card, CardBody } from "shards-react";
import { Link, Route } from "react-router-dom";
import Rides from "../../views/Rides";

import styled from "styled-components";

class CommonBox extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleClicks = this.handleClicks.bind(this);
  }
  handleClick(label) {
    this.props.onClickHandle(label);
  }
  handleClicks(label) {
    // console.log("*****************************************");
    let data;
    if (window.sessionStorage.getItem("shift") === "day") {
      data = "firstshift";
    } else if (window.sessionStorage.getItem("shift") === "mid") {
      data = "secondshift";
    } else {
      data = "thirdshift";
    }
    // this.props.onClickHandle(data);
  }
  render() {
    const { variation, label, value, stats, display, path } = this.props;
    // console.log(stats)
    const cardClasses = classNames(
      "zoom-wrapper",
      variation && `stats-small--${variation}`
    );

    const cardBodyClasses = classNames(
      variation === "1" ? "p-0 d-flex" : "px-0 pb-0"
    );

    const innerWrapperClasses = classNames(
      "d-flex",
      variation === "1" ? "flex-column m-auto" : "px-3"
    );

    const dataFieldClasses = classNames(
      "stats-small__data",
      variation === "1" && "text-center"
    );

    const labelClasses = classNames(
      "stats-small__label",
      variation !== "1" && "mb-1",
      display === false ? "d-none" : "",
      stats === false ? "text-uppercase" : ""
    );

    const valueClasses = classNames(
      stats === false ? "" : "",
      "count",
      variation === "1" ? "my-3" : "m-0"
    );

    const innerDataFieldClasses = classNames(
      "stats-small__data",
      variation !== "1" && "text-right align-items-center"
    );
    if (
      label === "firstshift" ||
      label === "secondshift" ||
      label === "thirdshift"
    ) {
      return (
        <Link
          to="#"
          style={{
            textDecorationColor: "black",
          }}
        >
          <Card
            small
            className={cardClasses}
            style={{ height: "120px" }}
            onClick={() => {
              this.handleClick(label);
            }}
          >
            <CardBody className={cardBodyClasses}>
              <div className={innerWrapperClasses}>
                <div className={dataFieldClasses}>
                  <span className={labelClasses}>{label}</span>
                  <span
                    className={valueClasses}
                    style={{
                      color: "#000000",
                      fontSize: "1.4rem",
                      fontWeight: "900",
                    }}
                  >
                    {value}
                  </span>
                  {/* <Route exact path={`/businessaccounts/${value}`} Component={Rides} /> */}
                </div>
                <div className={innerDataFieldClasses}>
                  {/* <span className={percentageClasses}>{percentage}</span> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Link>
      );
    } else {
      return (
        <Link
          to={`${path}${label}`}
          style={{
            textDecorationColor: "black",
          }}
        >
          <Card small className={cardClasses} style={{ height: "120px" }}>
            <CardBody className={cardBodyClasses}>
              <div className={innerWrapperClasses}>
                <div className={dataFieldClasses}>
                  <span className={labelClasses}>{label}</span>
                  <span
                    className={valueClasses}
                    style={{
                      color: "#000000",
                      fontSize: "1.4rem",
                      fontWeight: "900",
                    }}
                  >
                    {value}
                  </span>
                  {/* <Route exact path={`/businessaccounts/${value}`} Component={Rides} /> */}
                </div>
                <div className={innerDataFieldClasses}>
                  {/* <span className={percentageClasses}>{percentage}</span> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Link>
      );
    }
  }
}

CommonBox.propTypes = {
  /**
   * The Small Stats variation.
   */
  variation: PropTypes.string,
  /**
   * The label.
   */
  label: PropTypes.string,
  /**
   * The value.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Flag to differentiate stat box
   */
  stats: PropTypes.bool,
  /**
   * Toggle label
   */
  display: PropTypes.bool,
};

CommonBox.defaultProps = {
  value: 0,
  label: "Label",
  stats: "",
};

export default CommonBox;
