import React from "react";
import { Bar, Pie } from "react-chartjs-2";

class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.chartReference = React.createRef();
  }

  componentDidMount() {
    console.log(this.chartReference); // returns a Chart.js instance reference
  }

  render() {
    var options = {
      responsive: true,
      tooltips: {
        enabled: true,
        mode: "single",
      },
    };
    return (
      <Pie
        ref={this.chartReference}
        data={this.props.data}
        options={options}
        width="300px"
        height="120px"
        legend={{ display: true, position: "right" }}
      />
    );
  }
}

export default Chart;
