import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import * as config from "../../constants/constants.json";
// import Check from "@material-ui/icons/Check";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  step: {
    "&$completed": {
      color: "#27FC69",
    },
    "&$disabled": {
      color: "#FF0000",
    },
    "&$error": {
      color: "#FF0000",
    },
  },
  error: {
    color: "#FF0000",
  },
  completed: {
    color: "#27FC69",
  },
  active: {
    backgroundColor: "#27FC69",
  },
}));

function getSteps() {
  return [
    "Leave for pick up",
    "Notified",
    "Ride started",
    "Toll passed",
    "Ride completed",
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return `more.`;
    case 1:
      return "keywords.";
    case 2:
      return `issues.`;
    default:
      return "Unknown step";
  }
}
const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});
function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <div className={classes.circle} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

export default function VerticalLinearStepper(props) {
  console.log(props.rideType);
  console.log(props.rideType == "rental");
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(5);
  const [cancelled, setCancelled] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [tollData, setTollData] = React.useState([]);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    // fetch("https://192.168.1.121:4000/htmlcrm/getDriverLocation", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     // 'Content-Type': 'application/x-www-form-urlencoded',
    //   },
    //   body: JSON.stringify({
    //     //  trip_id: this.props.tripId,
    //     trip_id: "h2MXzYIN0gpBU2PT",
    //   }),
    // })
    //   .then((res) => res.json())
    //   .then(
    //     (result) => {
    //       // console.log("herererer");
    //       console.log(result);
    //       setData(result);
    //     },
    //     // Note: it's important to handle errors here
    //     // instead of a catch() block so that we don't swallow
    //     // exceptions from actual bugs in components.
    //     (error) => {}
    //   );
    fetch(config.url + "booking/getTripStatusLocations", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        trip_id: props.dlocProps,
        // trip_id: "p721BsJ3NK4hlMfB",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          // console.log("herererer");
          // this.setState({
          //   convoDetails: result.data,
          // });
          // console.log(result.toll_info.toll_plaza_passed);
          setData(result);
          setTollData(result.toll_info);
          // setActiveStep(5);
          if (result.cancel_time !== undefined) {
            setCancelled(true);
            setActiveStep(6);
            // console.log(result.leaveforpickup_time);
            // if (result.leaveforpickup_time === "") {
            // }
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }, []);
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step
          expanded
          disabled={data.leaveforpickup_time === "" ? true : false}
        >
          <StepLabel
            StepIconProps={{
              classes: {
                root: classes.step,
                completed: classes.completed,
              },
            }}
          >
            Left for pickup
          </StepLabel>
          <StepContent>
            {data.leaveforpickup_time !== "" && (
              <Typography style={{ fontSize: "0.8rem" }}>
                {moment(new Date(data.leaveforpickup_time)).format(
                  "DD/MM/YY [at] hh:mm:ss A"
                )}
              </Typography>
            )}
          </StepContent>
        </Step>
        <Step expanded>
          <StepLabel
            StepIconProps={{
              classes: {
                root: classes.step,
                completed: classes.completed,
              },
            }}
          >
            Notified
          </StepLabel>
          <StepContent>
            {data.notify_time !== "" && (
              <Typography style={{ fontSize: "0.8rem" }}>
                {moment(new Date(data.notify_time)).format(
                  "DD/MM/YY [at] hh:mm:ss A"
                )}
              </Typography>
            )}
          </StepContent>
        </Step>
        <Step expanded>
          <StepLabel
            StepIconProps={{
              classes: {
                root: classes.step,
                completed: classes.completed,
              },
            }}
          >
            Ride started
          </StepLabel>
          <StepContent>
            {data.start_time !== "" && (
              <Typography style={{ fontSize: "0.8rem" }}>
                {moment(new Date(data.start_time)).format(
                  "DD/MM/YY [at] hh:mm:ss A"
                )}
              </Typography>
            )}
          </StepContent>
        </Step>
        {props.rideType == "rental" ? (
          <React.Fragment></React.Fragment>
        ) : (
          <Step expanded>
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.step,
                  completed: classes.completed,
                  error: classes.error,
                },
              }}
              error={tollData.toll_plaza_passed === true ? false : true}
            >
              Toll passed
            </StepLabel>
            <StepContent>
              {data.toll_timestamp !== "" && (
                <React.Fragment>
                  {tollData.toll_plaza_passed === true && (
                    <Typography style={{ fontSize: "0.8rem" }}>
                      {moment(new Date(tollData.toll_timestamp)).format(
                        "DD/MM/YY [at] hh:mm:ss A"
                      )}
                    </Typography>
                  )}
                </React.Fragment>
              )}
            </StepContent>
          </Step>
        )}

        <Step expanded>
          <StepLabel
            StepIconProps={{
              classes: {
                root: classes.step,
                completed: classes.completed,
              },
            }}
          >
            Ride completed
          </StepLabel>
          <StepContent>
            {data.end_time !== "" && (
              <Typography style={{ fontSize: "0.8rem" }}>
                {moment(new Date(data.end_time)).format(
                  "DD/MM/YY [at] hh:mm:ss A"
                )}
              </Typography>
            )}
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
}
