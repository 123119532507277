import React, { useState } from "react";
import { Card, FormGroup, Row, Col } from "react-bootstrap";
import { Button } from "shards-react";
import { FormInput } from "shards-react";
import querystring from "query-string";
import { motion } from "framer-motion";
import axios from "axios";
import { Toast } from "react-bootstrap";
import { Animated } from "react-animated-css";

/**
 * Import files and modules
 */
import Loader from "react-loader-spinner";
import * as animationData from "../assets/spinner.json";
import logo from "../assets/logo.svg";
import LoginImg from "../assets/loginArt.svg";
import stringsConstant from "../constants/stringsConstant.json";
import config from "../constants/constants.json";
import urls from "../constants/urlList.json";
import Cancel from "../assets/cancel.svg";
import Check from "../assets/check.svg";

function ChangePassword(props) {
  const [newPassword, setNewPassword] = useState("");
  const [reenterPassword, setrePassword] = useState("");
  const [clicked, setClicked] = useState(false);
  const [newPassError, setNewPassError] = useState(false);
  const [rePassError, setRepassError] = useState(false);
  const [resetTime, setResetTime] = useState(false);
  const [success, setSuccess] = useState("");

  /**
   * Handle password input
   */

  const handlePassInput = (e, type) => {
    switch (type) {
      case stringsConstant.newPass:
        setNewPassword(e.target.value);
        break;
      case stringsConstant.rePass:
        setrePassword(e.target.value);
        break;
      default:
        break;
    }
  };

  /**
   * API to reset the password
   */
  const sendResetPass = () => {
    if (newPassword !== reenterPassword) {
      setNewPassError(true);
    } else if (reenterPassword !== newPassword) {
      setRepassError(true);
    } else {
      let dataTosend = {
        email: querystring.parse(props.location.search).avatar,
        password: newPassword,
      };
      console.log(dataTosend);
      axios.post(config.url + urls.resetPass, dataTosend).then((response) => {
        console.log(response);
        setClicked(!clicked);
        if (response.data.message === stringsConstant.onsuccess) {
          handleSuccess();
          // setTimeout(() => {
          //   window.location.assign("/");
          // }, 2000);
        } else {
          handleFailure();
        }
      });
    }
  };

  /**
   * Handle success animation
   */
  const handleSuccess = () => {
    setSuccess(true);
    setResetTime(true);
    setTimeout(() => {
      setResetTime(false);
      loadLoginPage();
    }, 2000);
  };

  /**
   * Load home page
   */

  const loadLoginPage = () => {
    setTimeout(() => {
      window.location.assign("/");
    }, 2500);
  };

  /**
   * Handle failure animation
   */

  const handleFailure = () => {
    setSuccess(false);
    setResetTime(true);
    setTimeout(() => {
      setResetTime(false);
      setClicked(false);
    }, 2000);
  };

  return (
    <Row className="login-custom">
      <Col
        className="col-12 col-md-5 ipad-spec-main"
        style={{ backgroundColor: "#F1F5FB" }}
      >
        <Row className="d-flex justify-content-center custom-row-height">
          <Col className="col-10 col-md-6 col-lg-8 my-auto custom-padding">
            <Card className="login-card">
              <Card.Body>
                <Row className="px-2 my-auto">
                  <Col className="col-12 d-flex justify-content-center mb-4 mt-2">
                    {/* Test as img is not availabel */}
                    {/* <label className="login-image">Glory</label> */}
                    <img src={logo} height="auto" width="125"></img>
                  </Col>
                  <Col className="col-12 d-flex justify-content-center  mb-2">
                    <label className="login-text-reset">
                      Create new password
                    </label>
                  </Col>
                  <Col className="col-12 mb-4">
                    <label className="login-reset-desctext">
                      Your new password must be different from previous used
                      passwords.
                    </label>
                  </Col>

                  <Col className="col-12 mb-3">
                    <FormGroup>
                      <label className="login-text-label px-2">
                        Enter a new password
                      </label>
                      <FormInput
                        className="py-3"
                        placeholder="Password"
                        type="password"
                        disabled={clicked}
                        value={newPassword}
                        onChange={(e) =>
                          handlePassInput(e, stringsConstant.newPass)
                        }
                        invalid={newPassError}
                      />
                      {newPassError && (
                        <label className="login-invalid-msg px-2">
                          Password does not match
                        </label>
                      )}
                    </FormGroup>
                  </Col>
                  <Col className="col-12 mb-4">
                    <FormGroup>
                      <label className="login-text-label px-2">
                        Re-enter new password
                      </label>
                      <FormInput
                        className="py-3"
                        placeholder="Password"
                        type="password"
                        disabled={clicked}
                        value={reenterPassword}
                        invalid={rePassError}
                        onChange={(e) =>
                          handlePassInput(e, stringsConstant.rePass)
                        }
                      />
                      {rePassError && (
                        <label className="login-invalid-msg px-2">
                          Password does not match
                        </label>
                      )}
                    </FormGroup>
                  </Col>
                  {/* <Col className="col-12 mb-4">
                    <Row>
                      <Col className="col-6 d-flex justify-content-start">
                        <label className="login-link-text">
                          First time user?
                        </label>
                      </Col>
                      <Col className="col-6 d-flex justify-content-end">
                        <label className="login-link-text login-link-align">
                          Forgot password?
                        </label>
                      </Col>
                    </Row>
                  </Col> */}
                  <Col className="col-12 d-flex justify-content-center mb-2">
                    <Button
                      className="login-button-new py-3"
                      theme="dark"
                      style={{
                        fontSize: "calc(0.5rem + 1vmin)",
                        fontWeight: 900,
                      }}
                      onClick={sendResetPass}
                    >
                      CONTINUE
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col
        className="col-12 col-md-7 d-none d-sm-none d-md-block login-ipad-spec"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <div className="custom-row-height login-logo-position">
          <img src={LoginImg} height="auto" width="70%"></img>
        </div>
        {success === false && (
          <Animated
            className="animwidth mx-auto animPos col-4 offset-4"
            animationIn="fadeInUp"
            animationOut="fadeOutDown"
            animationInDuration={1500}
            animationOutDuration={1500}
            isVisible={resetTime}
            style={{
              float: "right",
            }}
          >
            <Toast className="custom-toast">
              <Toast.Body className="d-flex justify-content-center">
                <img
                  src={Cancel}
                  height="auto"
                  width={15}
                  className="mx-1"
                ></img>
                Failed to reset password
              </Toast.Body>
            </Toast>
          </Animated>
        )}

        {success === true && (
          <Animated
            className="animwidth mx-auto animPos col-5 offset-5"
            animationIn="fadeInUp"
            animationOut="fadeOutDown"
            animationInDuration={1500}
            animationOutDuration={1500}
            isVisible={resetTime}
            style={{
              float: "right",
            }}
          >
            <Toast className="custom-toast">
              <Toast.Body className="d-flex justify-content-center">
                <img
                  src={Check}
                  height="auto"
                  width={15}
                  className="mx-1"
                ></img>
                Password reset successfully
              </Toast.Body>
            </Toast>
          </Animated>
        )}

        <footer className="footerClass px-4 py-4 footer-class-font">
          <Row>
            <Col className="col-1">
              <span>
                <a className="login-link-text" href="/faq.html" target="_blank">
                  FAQ
                </a>
              </span>
            </Col>
            <Col className="col-2">
              <span>
                <a
                  className="login-link-text"
                  href="https://www.gloryindia.in/privacy"
                  target="_blank"
                >
                  Privacy policy
                </a>
              </span>
            </Col>
            <Col className="col-3">
              <span>
                <a
                  className="login-link-text"
                  href="https://www.gloryindia.in/terms"
                  target="_blank"
                >
                  Terms and conditions
                </a>
              </span>
            </Col>
            <Col className="col-6 d-flex justify-content-end">
              <span style={{ fontWeight: 500, color: "#151220" }}>
                Copyright © 2020 Glory
              </span>
            </Col>
          </Row>
        </footer>
      </Col>
    </Row>
  );
}

export default ChangePassword;
