import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Collapse,
  Button,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  FormSelect,
} from "shards-react";
import DatePicker from "react-date-picker";

/**
 * Import files and components
 */
import TripDataBox from "../components/common/TripDataBox";
import Chart from "../components/utilities/RidesPart";
import PageTitle from "./../components/common/PageTitle";
import * as config from "../constants/constants.json";
import * as stringConstants from "../constants/stringsConstant.json";

/**
 * Global variable
 */

const oneDay = 24 * 60 * 60 * 1000;

function Vendors(props) {
  const [collapse, setCollapse] = useState(false);
  const [hits, setHits] = useState({});
  const [date, setDate] = useState(new Date());
  const [offeredAmount, setOfferedAmount] = useState("");
  const [openTip, setOpenTip] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [daySelected, setDayselected] = useState("Today");
  const [assignedCount, setAssignedCount] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [region, setRegion] = useState("");

  const handleSelectChange = (event) => {
    setRegion(event.target.value);
  };

  const toggle = () => {
    setCollapse(!collapse);
    setShowDetails(!showDetails);
  };

  const handleOnChange = (event) => {
    setOfferedAmount(event.target.value);
  };

  const onChange = (date) => {
    setDate(date);
  };

  const toggleTip = () => {
    setOpenTip(!openTip);
  };

  const goBack = () => {
    props.data.history.goBack();
  };

  const goBackAccounts = () => {
    props.data.history.push("/businessaccounts");
  };
  const ridesCountSNextDate = () => {
    // console.log(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    nextDate.setHours(0, 0, 0, 0);
    // console.log(nextDate);
    if (nextDate) {
      setDate(nextDate);
      //  this.setState({
      //    date: nextDate,
      //  });
    }
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    // console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));
    // console.log(diffDays);
    if (nextDate.getTime() >= currentDate.getTime()) {
      if (nextDate.getTime() == currentDate.getTime()) {
        setDayselected("Today");
      } else {
        if (diffDays == 1) {
          setDayselected("Tomorrow");
          //   this.setState(
          //     {
          //       daySelected: "Tomorrow",
          //     },
          //     () => {
          //       this.countFetch();
          //       this.getMyAssignedCount();
          //       this.callLog();
          //     }
          //   );
          // $("#ridesCountSDateName").text("Tomorrow");
          // getAllRidesCount();
        } else {
          diffDays = diffDays - 1;
          // console.log(diffDays);
          if (diffDays == 1) {
            setDayselected("Day after tomorrow");
            // this.setState(
            //   {
            //     daySelected: "Day after tomorrow",
            //   },
            //   () => {
            //     this.countFetch();
            //     this.getMyAssignedCount();
            //     this.callLog();
            //   }
            // );
            // $("#ridesCountSDateName").text("Day after tomorrow");
          } else {
            setDayselected(`${diffDays} days after tomorrow`);
            // this.setState(
            //   {
            //     daySelected: ,
            //   },
            //   () => {
            //     this.countFetch();
            //     this.getMyAssignedCount();
            //     this.callLog();
            //   }
            // );
            // $("#ridesCountSDateName").text(diffDays + " days after tomorrow");
          }
          // getAllRidesCount();
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));

      if (diffDays == 1) {
        setDayselected("Today");
        // this.setState(
        //   {
        //     daySelected: "Yesterday",
        //   },
        //   () => {
        //     this.countFetch();
        //     this.getMyAssignedCount();
        //     this.callLog();
        //   }
        // );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          setDayselected("Day before yesterday");
          //   this.setState(
          //     {
          //       daySelected: "Day before yesterday",
          //     },
          //     () => {
          //       this.countFetch();
          //       this.getMyAssignedCount();
          //       this.callLog();
          //     }
          //   );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          setDayselected(`${diffDays} days from yesterday`);
          //   this.setState(
          //     {
          //       daySelected: `${diffDays} days from yesterday`,
          //     },
          //     () => {
          //       this.countFetch();
          //       this.getMyAssignedCount();
          //       this.callLog();
          //     }
          //   );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  };
  const ridesCountSPrevDate = () => {
    // console.log(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() - 1);
    nextDate.setHours(0, 0, 0, 0);
    // console.log(nextDate);
    if (nextDate) {
      setDate(nextDate);
      // this.setState({
      //   date: nextDate,
      // });
    }
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    // console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));
    // console.log(diffDays);
    if (nextDate.getTime() >= currentDate.getTime()) {
      if (nextDate.getTime() == currentDate.getTime()) {
        setDayselected("Today");
        //   this.setState(
        //     {
        //       daySelected: "Today",
        //     },
        //     () => {
        //       this.countFetch();
        //       this.getMyAssignedCount();
        //       this.callLog();
        //     }
        //   );
        // getAllRidesCount();
      } else {
        if (diffDays == 1) {
          setDayselected("Tomorrow");
          // this.setState(
          //   {
          //     daySelected: "Tomorrow",
          //   },
          //   () => {
          //     this.countFetch();
          //     this.getMyAssignedCount();
          //     this.callLog();
          //   }
          // );
          // $("#ridesCountSDateName").text("Tomorrow");
          // getAllRidesCount();
        } else {
          diffDays = diffDays - 1;
          // console.log(diffDays);
          if (diffDays == 1) {
            setDayselected("Day after tomorrow");
            //   this.setState(
            //     {
            //       daySelected: "Day after tomorrow",
            //     },
            //     () => {
            //       this.countFetch();
            //       this.getMyAssignedCount();
            //       this.callLog();
            //     }
            //   );
            // $("#ridesCountSDateName").text("Day after tomorrow");
          } else {
            setDayselected(`${diffDays} days after tomorrow`);
            // this.setState(
            //   {
            //     daySelected: `${diffDays} days after tomorrow`,
            //   },
            //   () => {
            //     this.countFetch();
            //     this.getMyAssignedCount();
            //     this.callLog();
            //   }
            // );
            // $("#ridesCountSDateName").text(diffDays + " days after tomorrow");
          }
          // getAllRidesCount();
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));

      if (diffDays == 1) {
        setDayselected("Yesterday");
        // this.setState(
        //   {
        //     daySelected: "Yesterday",
        //   },
        //   () => {
        //     this.countFetch();
        //     this.getMyAssignedCount();
        //     this.callLog();
        //   }
        // );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          setDayselected("Day before yesterday");
          //   this.setState(
          //     {
          //       daySelected: "Day before yesterday",
          //     },
          //     () => {
          //       this.countFetch();
          //       this.getMyAssignedCount();
          //       this.callLog();
          //     }
          //   );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          setDayselected(`${diffDays} days from yesterday`);
          //   this.setState(
          //     {
          //       daySelected: `${diffDays} days from yesterday`,
          //     },
          //     () => {
          //       this.countFetch();
          //       this.getMyAssignedCount();
          //       this.callLog();
          //     }
          //   );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  };
  const getMyAssignedCount = () => {
    let time = date;
    let milliseconds = time.getTime();
    // console.log(window.sessionStorage.getItem("user_id"));
    // console.log(new Date(this.state.date));
    fetch(config.url + "htmlcrm/getMyAssignedCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        milliseconds: milliseconds,
        executiveID: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          setAssignedCount(result.assignedCount);
          // this.setState({ assignedCount: result.assignedCount });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  };
  const callLog = () => {
    let proxyURL = config.proxy;
    let time = date;
    let data = config.logging + "/apflow?millis=" + time.getTime();
    // console.log(data);
    fetch(proxyURL + data, {
      method: "GET",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if (result.message === "successfull") {
            setGraphData(result);
            //   this.setState({
            //     graphData: result,
            //   });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  };
  const countFetch = () => {
    // console.log("Help");
    let time = date;
    // console.log(region);
    let milliseconds = time.getTime();
    // console.log(new Date(this.state.date));
    fetch(config.url + "htmlcrm/getAllRidesCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        milliseconds: milliseconds,
        region_name: region,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          let resArray = {
            totalRidesCount: result.totalRidesCount,
            assignedRides: result.scheduledRidesCount.assignedRides,
            unassignedRides: result.scheduledRidesCount.unassignedRides,
            cancelledRides: result.scheduledRidesCount.cancelledRides,
            completedRides: result.scheduledRidesCount.completedRides,
            ongoing: result.ongoing,
            detachedRides: result.scheduledRidesCount.detachedRides,
            consumerCanceledCount: result.consumerCanceledCount,
            driverCanceledCount: result.driverCanceledCount,
          };
          setHits(resArray);
          //  this.setState({ hits: resArray });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  };

  /**
   * On change in date
   */
  useEffect(() => {
    countFetch();
    getMyAssignedCount();
    callLog();
  }, [date]);

  /**
   * On change of region
   */

  useEffect(() => {
    countFetch();
    getMyAssignedCount();
    callLog();
  }, [region]);

  /**
   * On chnage in arrow day selector
   */

  useEffect(() => {
    countFetch();
    getMyAssignedCount();
    callLog();
  }, [daySelected]);

  /**
   * Effect to run only on page load
   */

  useEffect(() => {
    setRegion(sessionStorage.getItem("operating_region"));
    countFetch();
    getMyAssignedCount();
    callLog();
    const timer = setInterval(() => countFetch(), 30000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  let ridesData = {
    labels: ["From airport (%)", "To airport (%)"],
    datasets: [
      {
        borderColor: ["#5ec0cb", "#6bb756", "#CC5228"],
        backgroundColor: ["#5ec0cb", "#6bb756", "#CC5228"],
        data: [graphData.fromAiportPercent, graphData.toAiportPercent],
      },
    ],
  };

  return (
    <React.Fragment>
      <Row noGutters className="page-header py-4 px-3">
        <Col className="col-12 col-md-8">
          <Row className="w-100">
            <Col className="col-12">
              <Breadcrumb>
                <BreadcrumbItem>
                  <a href="#" onClick={goBackAccounts}>
                    Business accounts
                  </a>
                </BreadcrumbItem>
                {/* <BreadcrumbItem active>
              {this.props.data.location.state !== undefined
                ? formarText(this.props.data.location.state.label)
                : ""}
            </BreadcrumbItem> */}
              </Breadcrumb>
            </Col>

            <Col className="col-12 mt-1">
              <PageTitle
                title="Account overview"
                className="text-sm-left mb-3 pl-0 custom-margin"
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-md-1 d-none d-md-block"></Col>
        {sessionStorage.getItem("roletype") === stringConstants.subLead && (
          <Col className="col-12 col-md-3">
            <FormGroup>
              <label>Filter based on region</label>
              <FormSelect
                value={region}
                onChange={handleSelectChange}
                style={{ fontWeight: 700 }}
              >
                <option value="all">All</option>
                <option value="Bangalore">Bangalore</option>
                <option value="Hyderabad">Hyderabad</option>
              </FormSelect>
            </FormGroup>
          </Col>
        )}
      </Row>
      <Row className="px-3">
        <Col className="col-md-12 mb-4 d-none d-sm-none d-md-block">
          {/* <Row></Row> */}
          {/* <div className="w-50"> */}
          <Row>
            <Col className="col-md-4 d-flex justify-content-center">
              <div
                className="px-2 mx-2 mb-2"
                style={{
                  width: "100%",
                  minWidth: "150px",
                  textAlign: "center",
                  verticalAlign: "center",
                }}
              >
                <i
                  id="ridesCountSPrevDate"
                  className="fas fa-arrow-left float-left date-pointer"
                  onClick={ridesCountSPrevDate}
                />
                <a id="ridesCountSDateName">{daySelected}</a>
                <i
                  id="ridesCountSNextDate"
                  className="fas fa-arrow-right float-right date-pointer"
                  onClick={ridesCountSNextDate}
                />
              </div>

              {/* <DatePicker
                  className="w-100 date-picker-custom"
                  format="dd/MM/yyyy"
                  onChange={this.onChange}
                  value={this.state.date}
                  clearIcon={null}
                  calendarIcon={<i className="far fa-calendar-alt" />}
                /> */}
            </Col>
            <Col className="col-md-4 d-flex justify-content-center"></Col>
            <Col className="col-md-4 d-flex justify-content-center"></Col>
            <Col className="col-md-4">
              <DatePicker
                className="w-100 date-picker-custom"
                format="dd/MM/yyyy"
                onChange={onChange}
                value={date}
                clearIcon={null}
                calendarIcon={<i className="far fa-calendar-alt" />}
              />
            </Col>
          </Row>

          {/* </div> */}
        </Col>
        <Col className="col-12 mx-auto mb-4 d-block d-sm-block d-md-none d-lg-none d-xl-none">
          {/* <div className="w-50 mx-auto"> */}
          <DatePicker
            className="w-100 date-picker-custom"
            format="dd/MM/yyyy"
            onChange={onChange}
            value={date}
            clearIcon={null}
            calendarIcon={<i className="far fa-calendar-alt" />}
          />
          {/* </div> */}
        </Col>

        {graphData.count !== undefined && (
          <Col className="col-12 col-md-12 mb-md-4 mb-4">
            <Chart data={ridesData} datas={graphData} />
          </Col>
        )}

        {props.smallStats.map((stats, idx) => (
          <Col className="col-md-3 mb-4 ml-auto" key={idx} {...stats.attrs}>
            {/* {console.log(this.state.hits)} */}
            <TripDataBox
              id={`smaller-stats-${idx}`}
              variation="1"
              label={stats.label}
              value={hits.totalRidesCount}
              stats={stats.stats}
            />
          </Col>
        ))}
      </Row>
      <div>
        <Row className="px-3">
          <Col className="col-12 col-md-9 col-lg-9 col-xl-9">
            <Button
              block
              theme="light"
              onClick={toggle}
              className="mb-4 w-md-100 font-props"
              style={{ fontWeight: "700" }}
            >
              {showDetails === false ? "SHOW DETAILS" : "COLLAPSE DETAILS"}
              <i
                className={
                  showDetails === false
                    ? "fas fa-chevron-down float-right"
                    : "fas fa-chevron-up float-right"
                }
              />
            </Button>
          </Col>
        </Row>
        <Collapse open={collapse} className="">
          <Row className="px-3">
            {props.RideStats.map((stats, idx) => (
              <Col className="col-md mb-4" key={idx} {...stats.attrs}>
                {/* {console.log(data[stats.apiID])} */}
                <TripDataBox
                  id={`small-stats-${idx}`}
                  variation="1"
                  label={stats.label}
                  value={hits[stats.apiID]}
                  stats={stats.stats}
                />

                <Tooltip
                  trigger="hover"
                  open={openTip}
                  target={"#small-stats-4"}
                  toggle={toggleTip}
                  placement="bottom"
                >
                  <span>Consumer Cancelled : {hits.consumerCanceledCount}</span>
                  <br />
                  <span>Cancelled via panel : {hits.driverCanceledCount}</span>
                </Tooltip>
              </Col>
            ))}
          </Row>
        </Collapse>
      </div>
      <hr className="mb-3 py-2" />
      <Row className="px-3">
        {props.CountStats.map((stats, idx) => (
          <Col className="col-md mb-4 mt-2" key={idx} {...stats.attrs}>
            <TripDataBox
              id={`smaller-stats-${idx}`}
              variation="1"
              label={stats.label}
              value={assignedCount}
              stats={stats.stats}
            />
          </Col>
        ))}
        <Col className="my-auto">
          <div className="float-right mx-3">
            <Button className="" id="goback-button" onClick={goBackAccounts}>
              <i className="fas fa-arrow-left mx-1" />
              <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                BACK
              </span>
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="px-3 py-2">
        <Col className="col-12 col-md-12 col-lg-12">
          <div className="float-right">
            {/* <a target="_blank" href="/nearbydrivers?operating_region=" >
              <u>NEARBY DRIVERS MAP</u>
            </a> */}
            <Link
              target="_blank"
              to={`/nearbydrivers?operating_region=${region}`}
            >
              NEARBY DRIVERS MAP
            </Link>
          </div>
        </Col>
      </Row>
      {/* <Row className="px-md-2 mb-5">
          <TripView propsData={this.props} />
        </Row> */}
    </React.Fragment>
  );
}

Vendors.propTypes = {
  smallStats: PropTypes.array,
  RideStats: PropTypes.array,
  CountStats: PropTypes.array,
  hits: PropTypes.string,
  date: PropTypes.oneOf(["object", "string"]),
  tripDate: PropTypes.oneOf(["object", "string"]),
};

Vendors.defaultProps = {
  smallStats: [
    {
      label: "Total rides",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "totalRidesCount",
    },
  ],
  RideStats: [
    {
      label: "UNASSIGNED",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "unassignedRides",
    },
    {
      label: "ASSIGNED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "assignedRides",
    },
    {
      label: "ON-GOING RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "ongoing",
    },
    {
      label: "COMPLETED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "completedRides",
    },
    {
      label: "CANCELLED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "cancelledRides",
    },
    {
      label: "DETACHED",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "detachedRides",
    },
  ],
  CountStats: [
    {
      label: "Total assigned",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "assignedCount",
    },
  ],
};

export default Vendors;
