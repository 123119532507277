import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import routes from "./routes";
import withTracker from "./withTracker";
import "bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/login-new.css";
import "../node_modules/react-vis/dist/style.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "./assets/styles/main.css";
import "./assets/styles/login.css";
import "./assets/styles/animate.css";
import "./assets/styles/datepickerCustom.css";
import "./assets/styles/addDriver.css";
import "react-datepicker/dist/react-datepicker.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "./assets/businessAccounts.css";
import "./assets/login.css";

function App() {
  // console.log(props);
  return (
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <React.Fragment>
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={withTracker((props) => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                );
              })}
            />
          );
        })}
      </React.Fragment>
    </Router>
  );
}

export default App;
