import React from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  Container,
  CardBody,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormGroup,
  FormRadio,
  FormInput,
  FormTextarea,
} from "shards-react";
import ReactSnackBar from "react-js-snackbar";
import PageTitle from "./../components/common/PageTitle";
import InputDataForm from "./../components/common/InputDataForm";
import config from "../constants/constants.json";

class QClist extends React.Component {
  constructor() {
    super();
    this.state = {
      displayForm: false,
      dunique_id: "",
      phonenumber: "",
      first_name: "",
      last_name: "",
      login_number: "",
      vehicle_number: "",
      vehicle_type_form: "",
      date: "",
      vehicle_name: "",
      vehicle_make: "",
      vehicle_model: "",
      wearing_uniform: undefined,
      well_behaved: undefined,
      clean_shaven: undefined,
      work_attitude: "",
      appearance: "",
      behaviour: "",
      active_rc: undefined,
      active_dl: undefined,
      active_insurance: undefined,
      active_roadtax: undefined,
      active_fc: undefined,
      active_emission: undefined,
      active_badge: undefined,
      seat_cover: "",
      ac_condition: "",
      air_freshner: undefined,
      tissue_paper: undefined,
      removed_childlock: undefined,
      clean_interiors: undefined,
      spray_bottle: undefined,
      tire_condition: "",
      engine_maintainence: "",
      boot_condition: "",
      vehicle_washed: undefined,
      vehicle_scratches: undefined,
      spare_tire: undefined,
      vehicle_dents: undefined,
      cracked_window: undefined,
      broken_handle: undefined,
      wearing_mask: undefined,
      wearing_glove: undefined,
      has_sanitizer: undefined,
      vehicle_partition: undefined,
      covid_spray_bottle: undefined,
      aarogya_app_installed: undefined,
      final_outcome: "",
      final_engine_maintainance: "",
      final_boot_condition: "",
      additional_remarks: "",
      success: false,
      Show: false,
      Showing: false,
      message: "",
    };
    this.goBack = this.goBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchPhonenumber = this.searchPhonenumber.bind(this);
    this.setPhonenumber = this.setPhonenumber.bind(this);
    this.displayDiv = this.displayDiv.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.additionalRemarks = this.additionalRemarks.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }
  resetState() {
    this.setState({
      displayForm: false,
      dunique_id: "",
      phonenumber: "",
      first_name: "",
      last_name: "",
      login_number: "",
      vehicle_number: "",
      vehicle_type_form: "",
      date: "",
      vehicle_name: "",
      vehicle_make: "",
      vehicle_model: "",
      wearing_uniform: undefined,
      well_behaved: undefined,
      clean_shaven: undefined,
      work_attitude: "",
      appearance: "",
      behaviour: "",
      active_rc: undefined,
      active_dl: undefined,
      active_insurance: undefined,
      active_roadtax: undefined,
      active_fc: undefined,
      active_emission: undefined,
      active_badge: undefined,
      seat_cover: "",
      ac_condition: "",
      air_freshner: undefined,
      tissue_paper: undefined,
      removed_childlock: undefined,
      clean_interiors: undefined,
      spray_bottle: undefined,
      tire_condition: "",
      engine_maintainence: "",
      boot_condition: "",
      vehicle_washed: undefined,
      vehicle_scratches: undefined,
      spare_tire: undefined,
      vehicle_dents: undefined,
      cracked_window: undefined,
      broken_handle: undefined,
      wearing_mask: undefined,
      wearing_glove: undefined,
      has_sanitizer: undefined,
      vehicle_partition: undefined,
      covid_spray_bottle: undefined,
      aarogya_app_installed: undefined,
      final_outcome: "",
      final_engine_maintainance: "",
      final_boot_condition: "",
      additional_remarks: "",
      success: false,
      Show: false,
      Showing: false,
      message: "",
    });
  }
  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };
  additionalRemarks(e) {
    // let value;
    this.setState({
      additional_remarks: e.target.value,
    });
  }

  submitForm(e) {
    if (e) {
      e.preventDefault();
    }

    let data = {
      dunique_id: this.state.dunique_id,
      wearing_uniform: this.state.wearing_uniform,
      well_behaved: this.state.well_behaved,
      clean_shaven: this.state.clean_shaven,
      work_attitude: this.state.work_attitude,
      appearance: this.state.appearance,
      behaviour: this.state.behaviour,
      active_rc: this.state.active_rc,
      active_dl: this.state.active_dl,
      active_insurance: this.state.active_insurance,
      active_roadtax: this.state.active_roadtax,
      active_fc: this.state.active_fc,
      active_emission: this.state.active_emission,
      active_badge: this.state.active_badge,
      seat_cover: this.state.seat_cover,
      ac_condition: this.state.ac_condition,
      air_freshner: this.state.air_freshner,
      tissue_paper: this.state.tissue_paper,
      removed_childlock: this.state.removed_childlock,
      clean_interiors: this.state.clean_interiors,
      spray_bottle: this.state.spray_bottle,
      tyre_condition: this.state.tire_condition,
      engine_maintainence: this.state.engine_maintainence,
      boot_condition: this.state.boot_condition,
      vehicle_washed: this.state.vehicle_washed,
      vehicle_scratches: this.state.vehicle_scratches,
      spare_tyre: this.state.spare_tire,
      vehicle_dents: this.state.vehicle_dents,
      cracked_window: this.state.cracked_window,
      broken_handle: this.state.broken_handle,
      wearing_mask: this.state.wearing_mask,
      wearing_glove: this.state.wearing_glove,
      has_sanitizer: this.state.has_sanitizer,
      vehicle_partition: this.state.vehicle_partition,
      covid_spray_bottle: this.state.covid_spray_bottle,
      aarogya_app_installed: this.state.aarogya_app_installed,
      final_outcome: this.state.final_outcome,
      final_engine_maintainance: this.state.final_engine_maintainance,
      final_boot_condition: this.state.final_boot_condition,
      additional_remarks: this.state.additional_remarks,
      agent_id: window.sessionStorage.getItem("user_id"),
    };

    console.log(data);
    fetch(config.url + "htmlcrm/updateDriverQCData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            console.log(result);
            this.setState(
              {
                success: true,
                message: "QC details successfully uploaded",
              },
              () => {
                this.show();
                setTimeout(() => {
                  this.resetState();
                }, 2500);
              }
            );
          } else {
            console.log(result);
            this.setState(
              {
                success: false,
                message: "Failed to update QC details",
              },
              () => {
                this.show();
              }
            );
          }

          // console.log("herererer");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  displayDiv() {
    this.setState({
      displayForm: true,
    });
  }

  setPhonenumber(e) {
    let value;
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      value = e.target.value;
      this.setState({ phonenumber: value });
    }
  }

  searchPhonenumber(e) {
    if (e) {
      e.preventDefault();
    }
    let data = {
      phonenumber: this.state.phonenumber,
    };

    fetch(config.url + "htmlcrm/getDriverProfileInfoQC", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            console.log(result);
            this.setState(
              {
                first_name: result.first_name,
                last_name: result.last_name,
                vehicle_type_form: result.vehicle_type,
                vehicle_number: result.vehiclenumber,
                vehicle_make: result.vehicle_make,
                vehicle_name: result.vehicleName,
                vehicle_model: result.vehicle_model,
                dunique_id: result.dunique_id,
                login_number: this.state.phonenumber,
              },
              () => {
                this.displayDiv();
              }
            );
          } else {
            this.setState(
              {
                success: false,
                message: "This phonenumber is not registered",
              },
              () => {
                this.show();
              }
            );
          }

          // console.log("herererer");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  handleChange(name, value) {
    switch (name) {
      case "wearing_uniform":
        this.setState({
          wearing_uniform: value,
        });
        break;
      case "clean_shaven":
        this.setState({
          clean_shaven: value,
        });
        break;
      case "well_behaved":
        this.setState({
          well_behaved: value,
        });
        break;
      case "active_rc":
        this.setState({
          active_rc: value,
        });
        break;
      case "active_dl":
        this.setState({
          active_dl: value,
        });
        break;
      case "active_insurance":
        this.setState({
          active_insurance: value,
        });
        break;
      case "active_roadtax":
        this.setState({
          active_roadtax: value,
        });
        break;
      case "active_fc":
        this.setState({
          active_fc: value,
        });
        break;
      case "active_emission":
        this.setState({
          active_emission: value,
        });
        break;
      case "active_badge":
        this.setState({
          active_badge: value,
        });
        break;
      case "air_freshner":
        this.setState({
          air_freshner: value,
        });
        break;
      case "tissue_paper":
        this.setState({
          tissue_paper: value,
        });
        break;
      case "removed_childlock":
        this.setState({
          removed_childlock: value,
        });
        break;
      case "clean_interiors":
        this.setState({
          clean_interiors: value,
        });
        break;
      case "spray_bottle":
        this.setState({
          spray_bottle: value,
        });
        break;
      case "vehicle_washed":
        this.setState({
          vehicle_washed: value,
        });
        break;
      case "vehicle_scratches":
        this.setState({
          vehicle_scratches: value,
        });
        break;
      case "spare_tire":
        this.setState({
          spare_tire: value,
        });
        break;
      case "vehicle_dents":
        this.setState({
          vehicle_dents: value,
        });
        break;
      case "cracked_window":
        this.setState({
          cracked_window: value,
        });
        break;
      case "broken_handle":
        this.setState({
          broken_handle: value,
        });
        break;
      case "wearing_mask":
        this.setState({
          wearing_mask: value,
        });
        break;
      case "wearing_glove":
        this.setState({
          wearing_glove: value,
        });
        break;
      case "has_sanitizer":
        this.setState({
          has_sanitizer: value,
        });
        break;
      case "vehicle_partition":
        this.setState({
          vehicle_partition: value,
        });
        break;
      case "covid_spray_bottle":
        this.setState({
          covid_spray_bottle: value,
        });
        break;
      case "aarogya_app_installed":
        this.setState({
          aarogya_app_installed: value,
        });
        break;
      case "work_attitude":
        this.setState({
          work_attitude: value,
        });
        break;
      case "appearance":
        this.setState({
          appearance: value,
        });
        break;
      case "behaviour":
        this.setState({
          behaviour: value,
        });
        break;
      case "seat_cover":
        this.setState({
          seat_cover: value,
        });
        break;
      case "ac_condition":
        this.setState({
          ac_condition: value,
        });
        break;
      case "tire_condition":
        this.setState({
          tire_condition: value,
        });
        break;
      case "engine_maintainence":
        this.setState({
          engine_maintainence: value,
        });
        break;
      case "boot_condition":
        this.setState({
          boot_condition: value,
        });
        break;
      case "final_outcome":
        this.setState({
          final_outcome: value,
        });
        break;
      case "final_engine_maintainance":
        this.setState({
          final_engine_maintainance: value,
        });
        break;
      case "final_boot_condition":
        this.setState({
          final_boot_condition: value,
        });
        break;
      default:
        break;
    }
  }
  render() {
    return (
      <Container fluid className="main-content-container px-md-4">
        <Row noGutters className="page-header py-4 px-3">
          <Col className="col-12 col-md-6 col-lg-6">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Business accounts
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active />
            </Breadcrumb>
          </Col>
          <Col className="col-12 mt-1">
            <PageTitle
              title="QC checklist"
              className="text-sm-left mb-3  p-0 custom-margin"
            />
          </Col>
        </Row>
        <Row className="px-3 mb-3">
          <Col className="col-12 col-md-12 col-lg-12">
            <div className="float-right mt-2">
              <Button
                className=""
                id="goback-button"
                onClick={this.goBack}
                theme="dark"
              >
                <i className="fas fa-arrow-left mx-1" />
                <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                  BACK
                </span>
              </Button>
            </div>
          </Col>
        </Row>
        <Form onSubmit={this.searchPhonenumber}>
          <Row className="px-3 mb-3">
            <Col className="col-12 col-md-4 col-lg-3 py-4">
              <FormInput
                placeholder="Enter phone number to search for driver"
                value={this.state.phonenumber}
                onChange={this.setPhonenumber}
                className="py-3"
                required
                minLength={10}
                maxLength={10}
                readOnly={this.state.displayForm ? true : false}
                type="text"
              ></FormInput>
              {/* <Form className="d-inline-flex">
              <FormInput></FormInput>
              <Button className="">SUBMIT</Button>
            </Form> */}
            </Col>
            <Col className="col-12 col-md-4  mb-5 d-block d-sm-block d-md-none d-flex justify-content-center">
              <Button
                className=""
                theme="dark"
                size="lg"
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "700",
                  minWidth: "200px",
                  borderRadius: "3px",
                }}
              >
                SUBMIT
              </Button>
            </Col>
            <Col className="col-12 col-md-4 py-4 mb-5 d-none d-sm-none d-md-block d-lg-block my-auto">
              <Button
                className=""
                theme="dark"
                size="lg"
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "700",
                  minWidth: "200px",
                  borderRadius: "3px",
                }}
              >
                SUBMIT
              </Button>
            </Col>
          </Row>
        </Form>

        {this.state.displayForm && (
          <Row className="px-3 mb-3">
            <Col className="col-12 col-md-12 col-lg-12">
              <Card className="mb-4">
                {/* <CardHeader>
                <Row>
                  <Col className="col-12 d-flex justify-content-center mt-4">
                    <h6>Vehicle quality check</h6>
                  </Col>
                </Row>
              </CardHeader> */}
                <CardBody>
                  <Form onSubmit={this.submitForm}>
                    <Row className="mb-3">
                      <Col className="col-12 col-md-12 d-flex justify-content-center my-3">
                        <h6 className="heading-qc">
                          <b>Driver details</b>
                        </h6>
                      </Col>
                      <Col
                        className="col-12 col-md-12 p-3"
                        style={{
                          border: "1px solid thin black",
                          borderRadius: "8px",
                        }}
                      >
                        <Row className="px-3">
                          {this.props.inputValues.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-4 px-2 mb-4"
                              key={idx}
                              {...stats.attrs}
                            >
                              {/* {console.log(this.state.hits)} */}
                              <InputDataForm
                                id={`smaller-stats-${idx}`}
                                variation="1"
                                label={stats.label}
                                stats={stats.stats}
                                value={this.state[stats.name]}
                                name={stats.name}
                                readOnly={true}
                              />
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col className="col-12 col-md-12 mb-4 d-flex justify-content-center ">
                        <h6 className="heading-qc">
                          <b>Driver professionalism</b>
                        </h6>
                      </Col>
                      <Col className="col-12 col-md-12">
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-4">
                              <label className="options-qc">
                                Wearing uniform (white shirt, formal pant)
                              </label>
                            </Col>
                            <Col className="col-12 col-md-2"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="wearing_uniform"
                                checked={this.state.wearing_uniform === true}
                                onChange={() =>
                                  this.handleChange("wearing_uniform", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="wearing_uniform"
                                checked={this.state.wearing_uniform === false}
                                onChange={() =>
                                  this.handleChange("wearing_uniform", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Clean shaven and well dressed
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                checked={this.state.clean_shaven === true}
                                name="clean_shaven"
                                onChange={() =>
                                  this.handleChange("clean_shaven", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="clean_shaven"
                                checked={this.state.clean_shaven === false}
                                onChange={() =>
                                  this.handleChange("clean_shaven", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Driver is well behaved
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                checked={this.state.well_behaved === true}
                                name="well_behaved"
                                onChange={() =>
                                  this.handleChange("well_behaved", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                checked={this.state.well_behaved === false}
                                name="well_behaved"
                                onChange={() =>
                                  this.handleChange("well_behaved", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col className="col-12 col-md-12 mb-4 d-flex justify-content-center">
                        <h6 className="heading-qc">
                          <b>Driver etiquette & behaviour</b>
                        </h6>
                      </Col>
                      <Col className="col-12 col-md-12">
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Attitude to work
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="work_attitude"
                                checked={
                                  this.state.work_attitude === config.excellent
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "work_attitude",
                                    config.excellent
                                  )
                                }
                                required={true}
                              >
                                Excellent
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="work_attitude"
                                checked={
                                  this.state.work_attitude === config.average
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "work_attitude",
                                    config.average
                                  )
                                }
                              >
                                Average
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="work_attitude"
                                checked={
                                  this.state.work_attitude ===
                                  config.belowAverage
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "work_attitude",
                                    config.belowAverage
                                  )
                                }
                              >
                                Below average
                              </FormRadio>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Appearance – clean and tidy
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="appearance"
                                checked={
                                  this.state.appearance === config.excellent
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "appearance",
                                    config.excellent
                                  )
                                }
                                required={true}
                              >
                                Excellent
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="appearance"
                                checked={
                                  this.state.appearance === config.average
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "appearance",
                                    config.average
                                  )
                                }
                              >
                                Average
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="appearance"
                                checked={
                                  this.state.appearance === config.belowAverage
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "appearance",
                                    config.belowAverage
                                  )
                                }
                              >
                                Below average
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Courteous and well behaved
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="behaviour"
                                checked={
                                  this.state.behaviour === config.excellent
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "behaviour",
                                    config.excellent
                                  )
                                }
                                required={true}
                              >
                                Excellent
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="behaviour"
                                checked={
                                  this.state.behaviour === config.average
                                }
                                onChange={() =>
                                  this.handleChange("behaviour", config.average)
                                }
                              >
                                Average
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="behaviour"
                                checked={
                                  this.state.behaviour === config.belowAverage
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "behaviour",
                                    config.belowAverage
                                  )
                                }
                              >
                                Below average
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col className="col-12 col-md-12 mb-4 d-flex justify-content-center ">
                        <h6 className="heading-qc">
                          <b>Vehicle documents</b>
                        </h6>
                      </Col>
                      <Col className="col-12 col-md-12">
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-4">
                              <label className="options-qc">Active RC</label>
                            </Col>
                            <Col className="col-12 col-md-2"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="active_rc"
                                checked={this.state.active_rc === true}
                                onChange={() =>
                                  this.handleChange("active_rc", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="active_rc"
                                checked={this.state.active_rc === false}
                                onChange={() =>
                                  this.handleChange("active_rc", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">Active DL</label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="active_dl"
                                checked={this.state.active_dl === true}
                                onChange={() =>
                                  this.handleChange("active_dl", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="active_dl"
                                checked={this.state.active_dl === false}
                                onChange={() =>
                                  this.handleChange("active_dl", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Active insurance
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="active_insurance"
                                checked={this.state.active_insurance === true}
                                onChange={() =>
                                  this.handleChange("active_insurance", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="active_insurance"
                                checked={this.state.active_insurance === false}
                                onChange={() =>
                                  this.handleChange("active_insurance", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Active road tax
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="active_roadtax"
                                checked={this.state.active_roadtax === true}
                                onChange={() =>
                                  this.handleChange("active_roadtax", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="active_roadtax"
                                checked={this.state.active_roadtax === false}
                                onChange={() =>
                                  this.handleChange("active_roadtax", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">Active FC</label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="active_fc"
                                checked={this.state.active_fc === true}
                                onChange={() =>
                                  this.handleChange("active_fc", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="active_fc"
                                checked={this.state.active_fc === false}
                                onChange={() =>
                                  this.handleChange("active_fc", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Active emission test
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="active_emission"
                                checked={this.state.active_emission === true}
                                onChange={() =>
                                  this.handleChange("active_emission", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="active_emission"
                                checked={this.state.active_emission === false}
                                onChange={() =>
                                  this.handleChange("active_emission", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">Active badge</label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                checked={this.state.active_badge === true}
                                name="active_badge"
                                onChange={() =>
                                  this.handleChange("active_badge", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="active_badge"
                                checked={this.state.active_badge === false}
                                onChange={() =>
                                  this.handleChange("active_badge", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col className="col-12 col-md-12 mb-4 d-flex justify-content-center ">
                        <h6 className="heading-qc">
                          <b>Vehicle interior maintenance</b>
                        </h6>
                      </Col>
                      <Col className="col-12 col-md-12">
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Seat cover condition
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="seat_cover"
                                checked={
                                  this.state.seat_cover === config.excellent
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "seat_cover",
                                    config.excellent
                                  )
                                }
                                required={true}
                              >
                                Excellent
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="seat_cover"
                                checked={
                                  this.state.seat_cover === config.average
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "seat_cover",
                                    config.average
                                  )
                                }
                              >
                                Average
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="seat_cover"
                                checked={
                                  this.state.seat_cover === config.belowAverage
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "seat_cover",
                                    config.belowAverage
                                  )
                                }
                              >
                                Below average
                              </FormRadio>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                A/C condition
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="ac_condition"
                                checked={
                                  this.state.ac_condition === config.excellent
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "ac_condition",
                                    config.excellent
                                  )
                                }
                                required={true}
                              >
                                Excellent
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="ac_condition"
                                checked={
                                  this.state.ac_condition === config.average
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "ac_condition",
                                    config.average
                                  )
                                }
                              >
                                Average
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="ac_condition"
                                checked={
                                  this.state.ac_condition ===
                                  config.belowAverage
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "ac_condition",
                                    config.belowAverage
                                  )
                                }
                              >
                                Below average
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">Air freshner</label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                checked={this.state.air_freshner === true}
                                name="air_freshner"
                                onChange={() =>
                                  this.handleChange("air_freshner", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="air_freshner"
                                checked={this.state.air_freshner === false}
                                onChange={() =>
                                  this.handleChange("air_freshner", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">Tissue paper</label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="tissue_paper"
                                checked={this.state.tissue_paper === true}
                                onChange={() =>
                                  this.handleChange("tissue_paper", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="tissue_paper"
                                checked={this.state.tissue_paper === false}
                                onChange={() =>
                                  this.handleChange("tissue_paper", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Removed child lock
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="removed_childlock"
                                checked={this.state.removed_childlock === true}
                                onChange={() =>
                                  this.handleChange("removed_childlock", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="removed_childlock"
                                checked={this.state.removed_childlock === false}
                                onChange={() =>
                                  this.handleChange("removed_childlock", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Clean interiors
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                checked={this.state.clean_interiors === true}
                                name="clean_interiors"
                                onChange={() =>
                                  this.handleChange("clean_interiors", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="clean_interiors"
                                checked={this.state.clean_interiors === false}
                                onChange={() =>
                                  this.handleChange("clean_interiors", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Hydrocloride spray bottle
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="spray_bottle"
                                checked={this.state.spray_bottle === true}
                                onChange={() =>
                                  this.handleChange("spray_bottle", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="spray_bottle"
                                checked={this.state.spray_bottle === false}
                                onChange={() =>
                                  this.handleChange("spray_bottle", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col className="col-12 col-md-12 mb-4 d-flex justify-content-center ">
                        <h6 className="heading-qc">
                          <b>Vehicle exterior maintenance</b>
                        </h6>
                      </Col>
                      <Col className="col-12 col-md-12">
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Tyre condition
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="tire_condition"
                                checked={
                                  this.state.tire_condition === config.excellent
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "tire_condition",
                                    config.excellent
                                  )
                                }
                                required={true}
                              >
                                Excellent
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="tire_condition"
                                checked={
                                  this.state.tire_condition === config.average
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "tire_condition",
                                    config.average
                                  )
                                }
                              >
                                Average
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="tire_condition"
                                checked={
                                  this.state.tire_condition ===
                                  config.belowAverage
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "tire_condition",
                                    config.belowAverage
                                  )
                                }
                              >
                                Below average
                              </FormRadio>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Engine maintainence
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="engine_maintainence"
                                checked={
                                  this.state.engine_maintainence ===
                                  config.excellent
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "engine_maintainence",
                                    config.excellent
                                  )
                                }
                                required={true}
                              >
                                Excellent
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="engine_maintainence"
                                checked={
                                  this.state.engine_maintainence ===
                                  config.average
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "engine_maintainence",
                                    config.average
                                  )
                                }
                              >
                                Average
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="engine_maintainence"
                                checked={
                                  this.state.engine_maintainence ===
                                  config.belowAverage
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "engine_maintainence",
                                    config.belowAverage
                                  )
                                }
                              >
                                Below average
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Vehicle boot & lock condition
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="boot_condition"
                                checked={
                                  this.state.boot_condition === config.excellent
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "boot_condition",
                                    config.excellent
                                  )
                                }
                                required={true}
                              >
                                Excellent
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="boot_condition"
                                checked={
                                  this.state.boot_condition === config.average
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "boot_condition",
                                    config.average
                                  )
                                }
                              >
                                Average
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="boot_condition"
                                checked={
                                  this.state.boot_condition ===
                                  config.belowAverage
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "boot_condition",
                                    config.belowAverage
                                  )
                                }
                              >
                                Below average
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">Vehicle wash</label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="vehicle_washed"
                                checked={this.state.vehicle_washed === true}
                                onChange={() =>
                                  this.handleChange("vehicle_washed", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="vehicle_washed"
                                checked={this.state.vehicle_washed === false}
                                onChange={() =>
                                  this.handleChange("vehicle_washed", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Scratches on the vehicle
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                checked={this.state.vehicle_scratches === true}
                                onChange={() =>
                                  this.handleChange("vehicle_scratches", true)
                                }
                                name="vehicle_scratches"
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="vehicle_scratches"
                                checked={this.state.vehicle_scratches === false}
                                onChange={() =>
                                  this.handleChange("vehicle_scratches", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">Spare tyre</label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="spare_tire"
                                checked={this.state.spare_tire === true}
                                onChange={() =>
                                  this.handleChange("spare_tire", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="spare_tire"
                                checked={this.state.spare_tire === false}
                                onChange={() =>
                                  this.handleChange("spare_tire", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Dents on the vehicle
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="vehicle_dents"
                                checked={this.state.vehicle_dents === true}
                                onChange={() =>
                                  this.handleChange("vehicle_dents", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="vehicle_dents"
                                checked={this.state.vehicle_dents === false}
                                onChange={() =>
                                  this.handleChange("vehicle_dents", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Cracked window
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="cracked_window"
                                checked={this.state.cracked_window === true}
                                onChange={() =>
                                  this.handleChange("cracked_window", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="cracked_window"
                                checked={this.state.cracked_window === false}
                                onChange={() =>
                                  this.handleChange("cracked_window", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Broken handles
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="broken_handle"
                                checked={this.state.broken_handle === true}
                                onChange={() =>
                                  this.handleChange("broken_handle", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="broken_handle"
                                checked={this.state.broken_handle === false}
                                onChange={() =>
                                  this.handleChange("broken_handle", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col className="col-12 col-md-12 mb-4 d-flex justify-content-center ">
                        <h6 className="heading-qc">
                          <b>COVID 19 safety check</b>
                        </h6>
                      </Col>
                      <Col className="col-12 col-md-12">
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-4">
                              <label className="options-qc">Wearing mask</label>
                            </Col>
                            <Col className="col-12 col-md-2"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="wearing_mask"
                                checked={this.state.wearing_mask === true}
                                onChange={() =>
                                  this.handleChange("wearing_mask", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="wearing_mask"
                                checked={this.state.wearing_mask === false}
                                onChange={() =>
                                  this.handleChange("wearing_mask", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Wearing hand gloves
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="wearing_glove"
                                checked={this.state.wearing_glove === true}
                                onChange={() =>
                                  this.handleChange("wearing_glove", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="wearing_glove"
                                checked={this.state.wearing_glove === false}
                                onChange={() =>
                                  this.handleChange("wearing_glove", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">Sanitizer</label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="has_sanitizer"
                                checked={this.state.has_sanitizer === true}
                                onChange={() =>
                                  this.handleChange("has_sanitizer", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="has_sanitizer"
                                checked={this.state.has_sanitizer === false}
                                onChange={() =>
                                  this.handleChange("has_sanitizer", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Vehicle partition
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="vehicle_partition"
                                checked={this.state.vehicle_partition === true}
                                onChange={() =>
                                  this.handleChange("vehicle_partition", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="vehicle_partition"
                                checked={this.state.vehicle_partition === false}
                                onChange={() =>
                                  this.handleChange("vehicle_partition", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Sodium hypocloride spray bottle
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="covid_spray_bottle"
                                checked={this.state.covid_spray_bottle === true}
                                onChange={() =>
                                  this.handleChange("covid_spray_bottle", true)
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="covid_spray_bottle"
                                checked={
                                  this.state.covid_spray_bottle === false
                                }
                                onChange={() =>
                                  this.handleChange("covid_spray_bottle", false)
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Aarogya application
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3"></Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="aarogya_app_installed"
                                checked={
                                  this.state.aarogya_app_installed === true
                                }
                                onChange={(e) =>
                                  this.handleChange(
                                    "aarogya_app_installed",
                                    true
                                  )
                                }
                                required={true}
                              >
                                Yes
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="aarogya_app_installed"
                                checked={
                                  this.state.aarogya_app_installed === false
                                }
                                onChange={(e) =>
                                  this.handleChange(
                                    "aarogya_app_installed",
                                    false
                                  )
                                }
                              >
                                No
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col className="col-12 col-md-12 mb-4 d-flex justify-content-center ">
                        <h6 className="heading-qc">
                          <b>Outcome</b>
                        </h6>
                      </Col>
                      <Col className="col-12 col-md-12">
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Final outcome
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="final_outcome"
                                checked={
                                  this.state.final_outcome ===
                                  config.professional
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "final_outcome",
                                    config.professional
                                  )
                                }
                                required={true}
                              >
                                Professional driver
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="final_outcome"
                                checked={
                                  this.state.final_outcome === config.aboveAvg
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "final_outcome",
                                    config.aboveAvg
                                  )
                                }
                              >
                                Above average
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="final_outcome"
                                checked={
                                  this.state.final_outcome === config.average
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "final_outcome",
                                    config.average
                                  )
                                }
                              >
                                Average
                              </FormRadio>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Engine maintainance
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="final_engine_maintainance"
                                checked={
                                  this.state.final_engine_maintainance ===
                                  config.excellent
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "final_engine_maintainance",
                                    config.excellent
                                  )
                                }
                                required={true}
                              >
                                Excellent
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="final_engine_maintainance"
                                checked={
                                  this.state.final_engine_maintainance ===
                                  config.average
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "final_engine_maintainance",
                                    config.average
                                  )
                                }
                              >
                                Average
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="final_engine_maintainance"
                                checked={
                                  this.state.final_engine_maintainance ===
                                  config.belowAverage
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "final_engine_maintainance",
                                    config.belowAverage
                                  )
                                }
                              >
                                Below average
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <FormGroup>
                          <Row className="px-3 my-2">
                            <Col className="col-12 col-md-3">
                              <label className="options-qc">
                                Vehicle boot & lock condition
                              </label>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="final_boot_condition"
                                checked={
                                  this.state.final_boot_condition ===
                                  config.excellent
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "final_boot_condition",
                                    config.excellent
                                  )
                                }
                                required={true}
                              >
                                Excellent
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="final_boot_condition"
                                checked={
                                  this.state.final_boot_condition ===
                                  config.average
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "final_boot_condition",
                                    config.average
                                  )
                                }
                              >
                                Average
                              </FormRadio>
                            </Col>
                            <Col className="col-12 col-md-3">
                              <FormRadio
                                name="final_boot_condition"
                                checked={
                                  this.state.final_boot_condition ===
                                  config.belowAverage
                                }
                                onChange={() =>
                                  this.handleChange(
                                    "final_boot_condition",
                                    config.belowAverage
                                  )
                                }
                              >
                                Below average
                              </FormRadio>
                            </Col>
                          </Row>{" "}
                        </FormGroup>
                        <Row className="px-3 my-2">
                          <Col className="col-12">
                            <FormGroup>
                              <label className="options-qc">
                                Additional remarks
                              </label>
                              <FormTextarea
                                rows="6"
                                maxlength="800"
                                minlength="5"
                                value={this.state.additional_remarks}
                                type="text"
                                onChange={this.additionalRemarks}
                                required
                              ></FormTextarea>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-3 d-flex justify-content-center">
                      <Col className="col-12 col-md-4 d-flex justify-content-center">
                        <Button
                          className=""
                          theme="dark"
                          size="lg"
                          style={{
                            fontSize: "0.8rem",
                            fontWeight: "700",
                            minWidth: "200px",
                            borderRadius: "3px",
                          }}
                        >
                          SUBMIT
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {this.state.success === false && (
          <ReactSnackBar
            Icon={
              <i
                className="far fa-times-circle fa-lg"
                style={{ color: "#FF0000" }}
              />
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
        {this.state.success === true && (
          <ReactSnackBar
            Icon={
              <span>
                <i
                  className="far fa-check-circle fa-lg"
                  style={{ color: "#26FF58" }}
                />
              </span>
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
      </Container>
    );
  }
}

QClist.propTypes = {
  inputValues: PropTypes.array,
};

QClist.defaultProps = {
  inputValues: [
    {
      label: "First name",
      value: "",
      attrs: {},
      stats: false,
      name: "first_name",
    },
    {
      label: "Last name",
      value: "",
      attrs: {},
      stats: false,
      name: "last_name",
    },
    {
      label: "Date and Time",
      value: "",
      attrs: {},
      stats: false,
      name: "date_time",
    },
    {
      label: "Login number",
      value: "",
      attrs: {},
      stats: false,
      name: "phonenumber",
    },
    {
      label: "Vehicle name",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_name",
    },
    {
      label: "Vehicle number",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_number",
    },
    {
      label: "Vehicle make",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_make",
    },
    {
      label: "Vehicle type",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_type_form",
    },
    {
      label: "Vehicle model",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_model",
    },
  ],
};

export default QClist;
