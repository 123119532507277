import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardImg,
  FormInput,
  FormGroup,
  Button,
} from "shards-react";
import axios from "axios";
import DragAndDropEdit from "./DragAndDropEdit";
import ReactDatePicker from "react-datepicker";
import socketIOClient from "socket.io-client";
import * as config from "../../constants/constants.json";
import moment from "moment";
import CheckV from "../../assets/checktick.svg";
import ErrorV from "../../assets/errorv.svg";
import Loader from "react-loader-spinner";
// const socket = socketIOClient(config.SOCKET_DOC, {
//   transports: ["websocket"],
// });
class DivRender extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      open: true,
      setInput: false,
      vehicle_front_image: undefined,
      vehicle_back_image: undefined,
      vehicle_left_image: undefined,
      vehicle_right_image: undefined,
      vehicle_with_driver: undefined,
      aadhar_card_image_front: undefined,
      aadhar_card_image_back: undefined,
      pan_card_image: undefined,
      driving_license_image: undefined,
      rc_image: undefined,
      vehicle_insurance_image: undefined,
      driver_badgee_image: undefined,
      emission_cert_image: undefined,
      road_tax_image: undefined,
      permit_image: undefined,
      fitness_image: undefined,
      sales_agreement: undefined,
      police_verification: undefined,
      badge_number: undefined,
      permit_number: undefined,
      aadhar_number: undefined,
      pan_number: undefined,
      dl_number: undefined,
      dunique_id: undefined,
      driverformImageFront: undefined,
      driverformImageBack: undefined,
      value: undefined,
      dl_expiry: undefined,
      roadtax_expiry: undefined,
      permit_expiry: undefined,
      fc_expiry: undefined,
      insurance_expiry: undefined,
      emission_expiry: undefined,
      display_expiry: undefined,
      panVerified: undefined,
      dlVerified: undefined,
      rcVerified: undefined,
      disableDl: false,
      disableRC: false,
      disablePAN: false,
      vehicle_num: undefined,
      pan_name: undefined,
      dldob: undefined,
      pandob: undefined,
      phonenumber: undefined,
      dlno: undefined,
      hoverDivi: false,
    };
    console.log(this.state.hoverDivi);
    this.clickhandler = this.clickhandler.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.pushAPI = this.pushAPI.bind(this);
    this.dateChange = this.dateChange.bind(this);
    this.valueChange = this.valueChange.bind(this);
    this.handleDLChange = this.handleDLChange.bind(this);
    this.handleVehiclenumChange = this.handleVehiclenumChange.bind(this);
    this.handlePanName = this.handlePanName.bind(this);
    this.handlePanDateChange = this.handlePanDateChange.bind(this);
    this.hoverDiv = this.hoverDiv.bind(this);
  }

  hoverDiv() {
    console.log("here");
    this.setState({
      hoverDivi: !this.state.hoverDivi,
    });
  }

  handleDLChange(date) {
    // console.log(date);
    this.setState({
      dldob: date,
    });
  }

  handlePanDateChange(date) {
    this.setState({
      pandob: date,
    });
  }
  componentDidMount() {
    if (this.props.dunique_id) {
      this.setState({
        dunique_id: this.props.dunique_id,
        phonenumber: this.props.phonenumber,
        dlno: this.props.dl,
      });
    }
    console.log(this.state.dunique_id);
  }

  // verifyDL = (e) => {
  //   this.setState({
  //     dlVerified: "waiting",
  //     disableDl: true,
  //   });
  //   let dataToSend = {
  //     phonenumber: this.state.phonenumber,
  //     dob: moment(this.state.dldob).format("DD-MM-yyyy"),
  //     dlno: this.state.dlno,
  //     dunique_id: this.state.dunique_id,
  //   };
  //   console.log(dataToSend);
  //   socket.on("verifydl", (data) => {
  //     console.log(data);
  //     if (data.dunique_id === this.state.dunique_id) {
  //       if (data.message === "successfull") {
  //         this.setState(
  //           {
  //             dlVerified: "verified",
  //             dlverifiedData: data.data,
  //           },
  //           () => {
  //             this.callModal();
  //           }
  //         );
  //       } else {
  //         this.setState({
  //           dlVerified: "failed",
  //           disableDl: false,
  //         });
  //       }
  //     }
  //   });

  //   socket.emit("verifydl", dataToSend);
  //   setTimeout(() => {
  //     if (this.state.dlVerified === "waiting") {
  //       this.setState({
  //         dlVerified: undefined,
  //         disableDl: false,
  //       });
  //     }
  //   }, 15000);
  // };

  callModal = () => {};
  // verifyRC = (e) => {
  //   this.setState({
  //     rcVerified: "waiting",
  //     disableRC: true,
  //   });
  //   let dataToSend = {
  //     phonenumber: this.state.phonenumber,
  //     vehicle_number: this.state.vehicle_num,
  //     dunique_id: this.state.dunique_id,
  //   };
  //   // console.log(dataToSend);
  //   socket.on("verifyrc", (data) => {
  //     // console.log(data);
  //     if (data.dunique_id === this.state.dunique_id) {
  //       if (data.message === "successfull") {
  //         this.setState(
  //           {
  //             rcVerified: "verified",
  //             rcverifiedData: data.data,
  //           },
  //           () => {
  //             this.callModal();
  //           }
  //         );
  //       } else {
  //         this.setState({
  //           rcVerified: "failed",
  //           disableRC: false,
  //         });
  //       }
  //     }
  //   });

  //   socket.emit("verifyrc", dataToSend);
  //   setTimeout(() => {
  //     if (this.state.rcVerified === "waiting") {
  //       this.setState({
  //         rcVerified: undefined,
  //         disableRC: false,
  //       });
  //     }
  //   }, 15000);
  // };

  // verifyPAN = (e) => {
  //   this.setState({
  //     panVerified: "waiting",
  //     disablePAN: true,
  //   });
  //   let dataToSend = {
  //     pan_no: this.state.pan_number,
  //     name: this.state.pan_name,
  //     dob: moment(this.state.pandob).format("DD/MM/yyyy"),
  //     phonenumber: this.state.phonenumber,
  //     dunique_id: this.state.dunique_id,
  //   };
  //   console.log(dataToSend);
  //   socket.on("verifypan", (data) => {
  //     console.log(data);
  //     if (data.dunique_id === this.state.dunique_id) {
  //       if (data.message === "successfull") {
  //         this.setState(
  //           {
  //             panVerified: "verified",
  //             panverifiedData: data.data,
  //           },
  //           () => {
  //             this.callModal();
  //           }
  //         );
  //       } else {
  //         this.setState({
  //           panVerified: "failed",
  //           disablePAN: false,
  //         });
  //       }
  //     }
  //   });

  //   socket.emit("verifypan", dataToSend);
  //   setTimeout(() => {
  //     if (this.state.panVerified === "waiting") {
  //       this.setState({
  //         panVerified: undefined,
  //         disablePAN: false,
  //       });
  //     }
  //   }, 15000);
  // };

  verifyRC = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setWaitingRc();
    let dataToSend = {
      phonenumber: this.state.phonenumber,
      vehicle_number: this.state.vehicle_num1 + "-" + this.state.vehicle_num2,
      dunique_id: this.state.dunique_id,
    };
    // this.rcSocket(dataToSend.dunique_id);
    axios
      .post(config.test_url + "verifyRC/", dataToSend)
      .then((response) => {
        if (response.data.message === "successfull") {
          // this.rcSocket(dataToSend.dunique_id);
        } else {
          this.setFailRc();
        }
      })
      .catch((error) => {
        this.setState({
          rcVerified: undefined,
          disableRC: false,
        });
      });
  };
  verifyDL = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setWaitingDl();
    let dataToSend = {
      phonenumber: this.state.phonenumber,
      dob: moment(this.state.dldob).format("DD-MM-yyyy"),
      dlno: this.state.dlno,
      dunique_id: this.state.dunique_id,
    };
    // this.dlSocket(dataToSend.dunique_id);
    axios
      .post("http://192.168.1.228:8001/verifyDL/", dataToSend)
      .then((response) => {
        if (response.data.message === "successfull") {
          // this.dlSocket(dataToSend.dunique_id);
        } else {
          this.setFailDl();
        }
      })
      .catch((error) => {
        this.setState({
          dlVerified: undefined,
          disableDl: false,
        });
      });
  };

  verifyPAN = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setWaitingPan();
    let dataToSend = {
      pan_no: this.state.pan_number,
      name: this.state.pan_name,
      dob: moment(this.state.pandob).format("DD/MM/yyyy"),
      phonenumber: this.state.phonenumber,
      dunique_id: this.state.dunique_id,
    };
    // this.panSocket(dataToSend.dunique_id);
    // console.log(dataToSend);
    // let dataToSend = {
    //   pan_no: "GUZPS9603G",
    //   phonenumber: "8951973199",
    //   name: "SIDHARTH M A",
    //   dob: "31/03/1993",
    //   dunique_id: "5960d4e5607ce547af585e74",
    // };
    axios
      .post(config.test_url + "verifyPAN/", dataToSend)
      .then((response) => {
        if (response.data.message === "successfull") {
          // socket.connect("pan_" + dataToSend.dunique_id, () => {
          //   console.log(socket.connected);
          // });
        } else {
          this.setFailPan();
        }
      })
      .catch((error) => {
        this.setState({
          panVerified: undefined,
          disablePAN: false,
        });
      });
  };

  setFailPan = () => {
    this.setState({
      panVerified: "failed",
      disablePAN: false,
    });
  };

  setWaitingPan = () => {
    this.setState({
      panVerified: "waiting",
      disablePAN: true,
    });
  };

  setFailDl = () => {
    this.setState({
      dlVerified: "failed",
      disableDl: false,
    });
  };

  setWaitingDl = () => {
    this.setState({
      dlVerified: "waiting",
      disableDl: true,
    });
  };

  setFailRc = () => {
    this.setState({
      rcVerified: "failed",
      disableRC: false,
    });
  };

  setWaitingRc = () => {
    this.setState({
      rcVerified: "waiting",
      disableRC: true,
    });
  };
  // panSocket = (dunique_id) => {
  //   console.log(dunique_id);

  //   socket.on("pan_" + dunique_id, (data) => {
  //     console.log(data);
  //     if (data.message === "successfull" && data.data.status === "valid") {
  //       this.setState(
  //         {
  //           panVerified: "verified",
  //           panverifiedData: data.data,
  //         },
  //         () => {
  //           this.callModal();
  //         }
  //       );
  //     } else {
  //       console.log(data);
  //       this.setFailPan();
  //     }
  //   });
  // };

  // dlSocket = (dunique_id) => {
  //   socket.on("dl_" + dunique_id, (data) => {
  //     console.log(data);
  //     if (data.message === "successfull") {
  //       this.setState(
  //         {
  //           dlVerified: "verified",
  //           dlverifiedData: data.data,
  //         },
  //         () => {
  //           this.callModal();
  //         }
  //       );
  //     } else {
  //       // console.log(data);
  //       this.setFailDl();
  //     }
  //   });
  // };

  // rcSocket = (dunique_id) => {
  //   socket.on("rc_" + dunique_id, (data) => {
  //     console.log(data);
  //     if (data.message === "successfull" && data.data.status === "valid") {
  //       this.setState(
  //         {
  //           rcVerified: "verified",
  //           rcverifiedData: data.data,
  //         },
  //         () => {
  //           this.callModal();
  //         }
  //       );
  //     } else {
  //       // console.log(data);
  //       this.setFailRc();
  //     }
  //   });
  // };

  handleVehiclenumChange(event, type) {
    this.setState({
      vehicle_num: event.target.value,
    });
  }

  valueChange(e, name) {
    // console.log(name);
    let value;
    let newState = {};
    value = e.target.value;
    // console.log(value);
    newState[name] = value;
    this.setState({ ...this.state, ...newState });
    this.props.valueChange(e, name);
  }

  dateChange(e, name) {
    // console.log(name);
    let value;
    let newState = {};
    value = e;
    // console.log(value);
    newState[name] = value;
    this.setState({ ...this.state, ...newState });
    this.props.onKeyChange(e, name);
  }
  handleChange(e, name) {
    // console.log(e)
    this.props.onKeyChange(e, name);
  }

  pushAPI() {
    // this.props.onClickPush(this.props.name)
    this.setState(
      {
        open: !this.state.open,
      },
      () => {
        this.props.onChangeHandler(
          this.state[this.props.name],
          this.props.name
        );
      }
    );
  }
  clickhandler() {
    // console.log("here");
    this.setState({
      open: !this.state.open,
    });

    if (
      this.props.name === "driving_license_image" ||
      this.props.name === "vehicle_insurance_image" ||
      this.props.name === "driver_badgee_image" ||
      this.props.name === "emission_cert_image" ||
      this.props.name === "road_tax_image" ||
      this.props.name === "fitness_image"
    ) {
      this.props.hideDiv(this.props.name);
    }
  }
  handleImage(data, name) {
    const newState = {};
    newState[name] = data;
    this.setState({ ...this.state, ...newState });
  }

  // verifyDL = () => {
  //   this.props.verifyDL();
  // };

  // verifyPan = () => {
  //   this.props.verifyPan();
  // };

  // verifyRC = () => {
  //   this.props.verifyRC();
  // };

  handlePanName(event) {
    this.setState({
      pan_name: event.target.value,
    });
  }

  render() {
    const {
      variation,
      label,
      value,
      name,
      id,
      expiryVal,
      expiryName,
      idno,
      idvalue,
    } = this.props;
    // console.log(this.props);
    var base64regex = /^(?:[data]{4}:(text|image|application)\/[a-z]*)/;
    var result = base64regex.test(value);
    // console.log(result);
    let dataImage;
    if (result === true) {
      dataImage = value;
      // console.log(dataImage);
    } else {
      dataImage = value;
      // console.log(dataImage);
    }
    // console.log(id);
    // console.log(dataImage)
    // console.log(this.state.open);
    // if (name === "aadhar_card_image_front" || name === "pan_card_image" || name === "driving_license_image" || name === "permit_image") {
    //     this.setState({
    //         setInput: true
    //     })
    // }
    if (
      this.props.name === "driving_license_image" ||
      this.props.name === "vehicle_insurance_image" ||
      this.props.name === "driver_badgee_image" ||
      this.props.name === "emission_cert_image" ||
      this.props.name === "road_tax_image" ||
      this.props.name === "fitness_image"
    ) {
      return (
        <div className="w-100 my-4" id={id}>
          <Row className="mx-auto">
            <label className="label-style">{label}</label>
            {this.state.open === true && (
              <div className="ml-auto px-3">
                <i
                  className="fas fa-cog pointer"
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={this.clickhandler}
                />
              </div>
            )}
            {this.state.open === false && (
              <div className="ml-auto px-3">
                <i
                  className="far fa-check-circle pointer px-1"
                  style={{ color: "#59E67F", cursor: "pointer" }}
                  onClick={this.pushAPI}
                />
                <i
                  className="fas fa-ban pointer px-1"
                  style={{ color: "#FF0000", cursor: "pointer" }}
                  onClick={this.clickhandler}
                />
              </div>
            )}
          </Row>
          <Row>
            <Col className="col-12 col-md-12">
              <Card>
                <CardBody className="p-0">
                  {this.state.open === true && (
                    <React.Fragment>
                      {/*  backgroundImage: `url(${dataImage}),url('https://master.gloryindia.in/static/images/document.png')`,
                       Image: `url(${dataImage})`,
                       */}

                      {/* url('https://panel.hornok.in/static/images/document.png') */}

                      <div
                        id={`show-images-${id}`}
                        className={this.state.hoverDivi ? "grow" : "nogrow"}
                        style={{
                          width: "100%",
                          height: "180px",
                          backgroundImage: `url(${dataImage})`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                        onClick={this.hoverDiv}
                      />
                    </React.Fragment>
                  )}
                  {this.state.open === false && (
                    <React.Fragment>
                      <div
                        className=""
                        id={`show-images-${id}`}
                        style={{
                          width: "100%",
                          height: "180px",
                        }}
                      >
                        <DragAndDropEdit
                          id={id}
                          variation="1"
                          label={label}
                          name={name}
                          onDropHandler={this.handleImage}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col className="col-12 col-md-12 mt-2">
              <label>Expiry:</label>
              {this.state.open === true && (
                <FormInput
                  readOnly={this.state.open}
                  value={
                    expiryVal === "" || expiryVal === undefined
                      ? "-"
                      : new Date(expiryVal)
                  }
                  placeholder="--"
                  className="mt-2"
                />
              )}

              {this.state.open === false && (
                <ReactDatePicker
                  className="form-control py-3"
                  selected={this.state[expiryName]}
                  minDate={new Date()}
                  value={this.state[expiryName]}
                  onChange={(e) => {
                    this.dateChange(e, expiryName);
                  }}
                  dateFormat="dd/MM/yyyy"
                />
              )}
            </Col>
            {(this.props.name === "driver_badgee_image" ||
              this.props.name === "driving_license_image") && (
              <Col className="col-12 col-md-12 mt-2">
                {/* <label>ID NO:</label> */}
                {this.state.open === true && (
                  <React.Fragment>
                    <FormGroup>
                      <label>
                        {this.props.name === "driver_badgee_image"
                          ? "Badge no:"
                          : "DL no:"}
                      </label>
                      <FormInput
                        readOnly={this.state.open}
                        value={idvalue}
                        placeholder="--"
                        className="mt-2"
                      />
                    </FormGroup>
                  </React.Fragment>
                )}

                {this.state.open === false && (
                  <React.Fragment>
                    <FormGroup>
                      <label>
                        {this.props.name === "driver_badgee_image"
                          ? "Badge no:"
                          : "DL no:"}
                      </label>
                      <FormInput
                        className="mt-2"
                        value={
                          this.state[idno] === undefined ||
                          this.state[idno] === null
                            ? idvalue
                            : this.state[idno]
                        }
                        onChange={(e) => {
                          this.valueChange(e, idno);
                        }}
                      />
                    </FormGroup>
                    {this.props.name === "driving_license_image" && (
                      <React.Fragment>
                        <FormGroup>
                          <label>D.O.B as in DL</label>
                          <ReactDatePicker
                            className="form-control"
                            selected={this.state.dldob}
                            onChange={(date) => this.handleDLChange(date)}
                            placeholderText="Select date of birth"
                            dateFormat="dd-MM-yyyy"
                          />
                        </FormGroup>
                        <FormGroup>
                          {/* <Button onClick={this.verifyDL}>VERIFY</Button> */}
                        </FormGroup>
                        <FormGroup className="mt-5">
                          {this.state.dlVerified === "waiting" && (
                            <React.Fragment>
                              <div className="d-inline-flex">
                                <Loader
                                  type="Oval"
                                  color="#2E8EFD"
                                  height="auto"
                                  width={20}
                                  className="mx-2"
                                />
                                <label className="mx-2 my-auto">
                                  Verifying license ...
                                </label>
                              </div>
                            </React.Fragment>
                          )}
                          {this.state.dlVerified === "verified" && (
                            <React.Fragment>
                              <img
                                src={CheckV}
                                height="auto"
                                width="25"
                                className="mx-2"
                              ></img>
                              <label className="mx-2 my-auto">
                                Verified !!
                              </label>
                            </React.Fragment>
                          )}
                          {this.state.dlVerified === "failed" && (
                            <React.Fragment>
                              <img
                                src={ErrorV}
                                height="auto"
                                width="25"
                                className="mx-2"
                              ></img>
                              <label
                                className="mx-2"
                                style={{ color: "#FF0000" }}
                              >
                                Verification failed! Retry
                              </label>
                            </React.Fragment>
                          )}
                        </FormGroup>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </Col>
            )}
          </Row>
        </div>
      );
    } else {
      return (
        <div className="w-100 my-4" id={id}>
          <Row className="mx-auto">
            <label className="label-style">{label}</label>
            {this.state.open === true && (
              <div className="ml-auto px-3">
                <i
                  className="fas fa-cog pointer"
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={this.clickhandler}
                />
              </div>
            )}
            {this.state.open === false && (
              <div className="ml-auto px-3">
                <i
                  className="far fa-check-circle pointer px-1"
                  style={{ color: "#59E67F", cursor: "pointer" }}
                  onClick={this.pushAPI}
                />
                <i
                  className="fas fa-ban pointer px-1"
                  style={{ color: "#FF0000", cursor: "pointer" }}
                  onClick={this.clickhandler}
                />
              </div>
            )}
          </Row>
          <Row>
            <Col className="col-12 col-md-12">
              <Card>
                <CardBody className="p-0">
                  {this.state.open === true && (
                    <React.Fragment>
                      {/*  backgroundImage: `url(${dataImage}),url('https://master.gloryindia.in/static/images/document.png')`,
                         backgroundImage: `url(${dataImage})`,
                       */}
                      <div
                        id={`show-images-${id}`}
                        className={this.state.hoverDivi ? "grow" : "nogrow"}
                        style={{
                          width: "100%",
                          height: "180px",
                          backgroundImage: `url(${dataImage})`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                        onClick={this.hoverDiv}
                      />
                    </React.Fragment>
                  )}
                  {this.state.open === false && (
                    <React.Fragment>
                      <div
                        className=""
                        id={`show-images-${id}`}
                        style={{
                          width: "100%",
                          height: "180px",
                        }}
                      >
                        <DragAndDropEdit
                          id={id}
                          variation="1"
                          label={label}
                          name={name}
                          onDropHandler={this.handleImage}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>
            </Col>
            {(this.props.name === "pan_card_image" ||
              this.props.name === "aadhar_card_image_front" ||
              name === "rc_image") && (
              <Col className="col-12 col-md-12 mt-2">
                <FormGroup>
                  <label>
                    {name === "rc_image"
                      ? "Enter vehicle number"
                      : name === "pan_card_image"
                      ? "PAN no"
                      : "Aadhar no"}
                  </label>
                  {this.state.open === true && (
                    <FormInput
                      readOnly={this.state.open}
                      value={idvalue}
                      placeholder="--"
                      className="mt-2"
                    />
                  )}

                  {this.state.open === false && (
                    <React.Fragment>
                      {name === "rc_image" ? (
                        <React.Fragment>
                          <FormGroup>
                            <FormInput
                              className="mt-2"
                              minLength={name === "rc_image" ? 10 : 12}
                              maxLength={name === "rc_image" ? 10 : 12}
                              value={this.state.vehicle_num}
                              onChange={(e) => {
                                this.handleVehiclenumChange(e);
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            {/* <Button onClick={this.verifyRC}>VERIFY</Button> */}
                          </FormGroup>
                          <FormGroup className="mt-5">
                            {this.state.rcVerified === "waiting" && (
                              <React.Fragment>
                                <div className="d-inline-flex">
                                  <Loader
                                    type="Oval"
                                    color="#2E8EFD"
                                    height="auto"
                                    width={20}
                                    className="mx-2"
                                  />
                                  <label className="mx-2 my-auto">
                                    Verifying RC ...
                                  </label>
                                </div>
                              </React.Fragment>
                            )}
                            {this.state.rcVerified === "verified" && (
                              <React.Fragment>
                                <img
                                  src={CheckV}
                                  height="auto"
                                  width="25"
                                  className="mx-2"
                                ></img>
                                <label className="mx-2 my-auto">
                                  Verified !!
                                </label>
                              </React.Fragment>
                            )}
                            {this.state.rcVerified === "failed" && (
                              <React.Fragment>
                                <img
                                  src={ErrorV}
                                  height="auto"
                                  width="25"
                                  className="mx-2"
                                ></img>
                                <label
                                  className="mx-2"
                                  style={{ color: "#FF0000" }}
                                >
                                  Verification failed! Retry
                                </label>
                              </React.Fragment>
                            )}
                          </FormGroup>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {name === "pan_card_image" ? (
                            <React.Fragment>
                              <FormGroup>
                                <FormInput
                                  className="mt-2"
                                  minLength={10}
                                  maxLength={10}
                                  value={this.state[idno]}
                                  onChange={(e) => {
                                    this.valueChange(e, idno);
                                  }}
                                />
                              </FormGroup>
                              <FormGroup>
                                <label>Name as in PAN</label>
                                <FormInput
                                  className="mt-2"
                                  value={this.state.pan_name}
                                  onChange={(e) => {
                                    this.handlePanName(e);
                                  }}
                                />
                              </FormGroup>
                              <FormGroup>
                                <label>D.O.B as in PAN</label>
                                <ReactDatePicker
                                  className="form-control"
                                  selected={this.state.pandob}
                                  onChange={(date) =>
                                    this.handlePanDateChange(date)
                                  }
                                  placeholderText="Select date of birth"
                                  dateFormat="dd-MM-yyyy"
                                />
                              </FormGroup>
                              <FormGroup>
                                {/* <Button onClick={this.verifyPAN}>VERIFY</Button> */}
                              </FormGroup>
                              <FormGroup className="mt-5">
                                {this.state.panVerified === "waiting" && (
                                  <React.Fragment>
                                    <div className="d-inline-flex">
                                      <Loader
                                        type="Oval"
                                        color="#2E8EFD"
                                        height="auto"
                                        width={20}
                                        className="mx-2"
                                      />
                                      <label className="mx-2 my-auto">
                                        Verifying PAN ...
                                      </label>
                                    </div>
                                  </React.Fragment>
                                )}
                                {this.state.panVerified === "verified" && (
                                  <React.Fragment>
                                    <img
                                      src={CheckV}
                                      height="auto"
                                      width="25"
                                      className="mx-2"
                                    ></img>
                                    <label className="mx-2 my-auto">
                                      Verified !!
                                    </label>
                                  </React.Fragment>
                                )}
                                {this.state.panVerified === "failed" && (
                                  <React.Fragment>
                                    <img
                                      src={ErrorV}
                                      height="auto"
                                      width="25"
                                      className="mx-2"
                                    ></img>
                                    <label
                                      className="mx-2"
                                      style={{ color: "#FF0000" }}
                                    >
                                      Verification failed! Retry
                                    </label>
                                  </React.Fragment>
                                )}
                              </FormGroup>
                            </React.Fragment>
                          ) : (
                            <FormGroup>
                              <FormInput
                                className="mt-2"
                                minLength={name === "pan_card_image" ? 10 : 12}
                                maxLength={name === "pan_card_image" ? 10 : 12}
                                value={this.state[idno]}
                                onChange={(e) => {
                                  this.valueChange(e, idno);
                                }}
                              />
                            </FormGroup>
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </FormGroup>
              </Col>
            )}
          </Row>
        </div>
      );
    }
  }
}

export default DivRender;
