import React from "react";
import PropTypes from "prop-types";
import { Container, Col, Row } from "shards-react";
import LoginFooter from "../components/layout/LoginFooter";
// import PopUp from "../components/common/Popup"
const LoginLayout = ({ noNavbar, noFooter, children }) => (
  <Container fluid style={{ background: "#F5F6F8" }}>
    {/* <Col className="mt-md-5"></Col> */}
    <Row>
      <main className="main-content col col" style={{ background: "#F5F6F8" }}>
        {children}
        {/* {<PopUp />} */}
        {/* {!noFooter && <LoginFooter />} */}
      </main>
    </Row>
  </Container>
);

LoginLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  username: PropTypes.string,
  /**
   * Whether to display the footer, or not.
   */
  password: PropTypes.string,
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};
LoginLayout.defaultProps = {
  username: "",
  password: "",
  noNavbar: false,
  noFooter: false,
};
export default LoginLayout;
