// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Form,
} from "shards-react";
import PageTitle from "./../components/common/PageTitle";
import InputDataForm from "./../components/common/InputDataForm";
import DragAndDrop from "./../components/common/DragAndDrop";
import * as config from "../constants/constants.json";
import ReactSnackBar from "react-js-snackbar";

class AddBankDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acc_holder_name: "",
      acc_number: "",
      bank_name: "",
      ifsc: "",
      Show: false,
      Showing: false,
      success: false,
      message: "",
    };
    // console.log(props);
    this.handleKeyChange = this.handleKeyChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };
  // Handle change
  handleKeyChange(e, name) {
    const newState = {};
    newState[name] = e.target.value;
    this.setState({ ...this.state, ...newState });
  }
  updateRidePref(dunique_id) {
    let duty_preference;
    if (
      this.props.oldProps.night_shift === true &&
      this.props.oldProps.day_shift === true
    ) {
      // console.log('log1')
      duty_preference = "day / night";
      // console.log(dutyPreference);
    } else if (this.props.oldProps.day_shift === true) {
      // console.log('log2')
      duty_preference = "day";
      // console.log(dutyPreference);
    } else if (this.props.oldProps.night_shift === true) {
      // console.log('log3')
      duty_preference = "night";
      // console.log(dutyPreference);
    }
    fetch(config.url + "onboard/updateDriverRidePreference", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        dunique_id: dunique_id,
        prefer_recurride: this.props.oldProps.recurride,
        prefer_airport: this.props.oldProps.airport,
        prefer_urban: this.props.oldProps.urban,
        prefer_hiring: "",
        prefer_outstation: this.props.oldProps.outstation,
        permit_location: "none",
        permit_type: "",
        duty_preference: duty_preference,
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          // console.log(resultsss);
          // console.log("herererer");
          // console.log(result.drivers);
          // this.setState({
          //     dData: result.drivers
          // })
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  updateImages(dunique_id) {
    fetch(config.url + "onboard/driverimagedocuments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        executive_id: window.sessionStorage.getItem("user_id"),
        dunique_id: dunique_id,
        profileimage: "",
        driverpro: "",
        displaycard: "",
        rcimage: "",
        insuranceimage: "",
        adharone: "",
        adhartwo: "",
        pancard: "",
        policeverification: "",
        permit: "",
        fc: "",
        dlone: "",
        dltwo: "",
        emission: "",
        roadtax: "",
        vhfront: "",
        vhreare: "",
        vhleft: "",
        vhright: "",
        sales_agreement: "",
      }),
    })
      .then((res) => res.json())
      .then(
        (resultss) => {
          // console.log(resultss);
          // console.log(result.drivers);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  updateDocs(dunique_id) {
    fetch(config.url + "onboard/driverdocumentsdate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        dunique_id: dunique_id,
        dl_to: new Date(this.props.dataState.dl_expiry).getTime(),
        insurance_to: new Date(this.props.dataState.insurance_expiry).getTime(),
        emission_to: new Date(this.props.dataState.emission_expiry).getTime(),
        permit_to: new Date(this.props.dataState.permit_expiry).getTime(),
        fc_to: new Date(this.props.dataState.fc_expiry).getTime(),
        display_card_to: "",
        roadtax_to: "",
      }),
    })
      .then((res) => res.json())
      .then(
        (results) => {
          // console.log(results);
          if (results.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "Dates updated successfully",
              },
              () => {
                this.show();
                this.updateRidePref(dunique_id);
                this.updateImages(dunique_id);
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleClick(e) {
    e.preventDefault();
    // Do API call here
    let data = {
      userid: window.sessionStorage.getItem("user_id"),
      first_name: this.props.oldProps.first_name,
      last_name: this.props.oldProps.last_name,
      basic_user: "false",
      phonenumber: this.props.oldProps.phonenumber,
      dateofbirth: new Date(this.props.oldProps.dob).getTime().toString(),
      prefer_location: this.props.oldProps.preferred_location,
      permanent_address: this.props.oldProps.permanent_address,
      driver_type: "owner",
      vehiclenumber: this.props.dataState.vehicle_number,
      starting_point: this.props.oldProps.starting_point,
      badgenumber: this.props.dataState.badge_number,
      vehiclemake: this.props.dataState.vehicle_make,
      vehicletype: this.props.dataState.vehicle_type,
      vehicle_color: this.props.dataState.vehicle_color,
      accholder_name: this.state.acc_holder_name,
      bankacc_number: this.state.acc_number,
      ifsc_code: this.state.ifsc,
      pannumber: this.props.dataState.pan_number,
      vehiclemodel: this.props.dataState.vehicle_model,
      adhar_number: this.props.dataState.aadhar_number,
      email: this.props.oldProps.email,
      alternate_number: this.props.oldProps.alt_phonenumber,

      fmone_name: this.props.oldProps.family_name_1,
      fmone_phonenumber: this.props.oldProps.family_phn_1,
      fmone_relationship: this.props.oldProps.family_rel_1,
      fmone_address: this.props.oldProps.family_address_1,

      fmtwo_name: this.props.oldProps.family_name_2,
      fmtwo_phonenumber: this.props.oldProps.family_phn_2,
      fmtwo_relationship: this.props.oldProps.family_rel_2,
      fmtwo_address: this.props.oldProps.family_address_1,
      childlock: "true",
      gps: "true",
      panicbutton: "true",
      operating_region: this.props.oldProps.operating_region,
      dl_num: this.props.dataState.dl_number,
    };
    // console.log(data);
    fetch(config.url + "onboard/newdriversregistration", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          if (result.message === "successfull") {
            let dunique_id = result.dunique_id;
            this.setState(
              {
                success: true,
                message: "Updated data successfully",
              },
              () => {
                this.show();
                this.updateDocs(dunique_id);
              }
            );
          } else if (result.message === "vehicle_number") {
            this.setState(
              {
                success: false,
                message: "Invalid vehicle number",
              },
              () => {
                this.show();
              }
            );
          } else {
            this.setState(
              {
                success: false,
                message: "Data failed to update",
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  render() {
    // console.log("****************");
    // console.log(data);
    return (
      <Container fluid className="main-content-container px-4">
        {console.log(this.state)}
        {/* <Row noGutters className="page-header py-2 px-3">
                    <PageTitle title="Add a driver" subtitle="Driver Accounts" className="text-sm-left mb-3" />
                </Row> */}
        <Form onSubmit={this.handleClick}>
          <Row className="px-3">
            <Col className="col-12 col-md-12 col-lg-12">
              <Card>
                <CardBody>
                  <Row>
                    <h5>Bank Details</h5>
                  </Row>
                  <Row>
                    <Col className="col-12 col-md-6 col-lg-6">
                      <DragAndDrop
                        id="passbook-image"
                        variation="1"
                        label="Passbook Image"
                        stats={false}
                      />
                    </Col>
                    <Col className="col-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                      {this.props.inputValues.map((stats, idx) => (
                        <Col
                          className="col-12 px-2 mb-4"
                          key={idx}
                          {...stats.attrs}
                        >
                          {/* {console.log(this.state.hits)} */}
                          <InputDataForm
                            id={`smaller-stats-${idx}`}
                            variation="1"
                            label={stats.label}
                            stats={stats.stats}
                            value={this.state[stats.name]}
                            name={stats.name}
                            onKeyChange={this.handleKeyChange}
                          />
                        </Col>
                      ))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4 mb-4">
            <Col className="col-4" />
            <Col className="col-4 col-4 d-flex justify-content-center mb-3">
              <Button className="py-3">SUBMIT DATA</Button>
            </Col>
            <Col className="col-4" />
          </Row>
        </Form>
        {this.state.success === false && (
          <ReactSnackBar
            Icon={
              <i
                className="far fa-times-circle fa-lg"
                style={{ color: "#FF0000" }}
              />
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
        {this.state.success === true && (
          <ReactSnackBar
            Icon={
              <span>
                <i
                  className="far fa-check-circle fa-lg"
                  style={{ color: "#26FF58" }}
                />
              </span>
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
      </Container>
    );
  }
}
AddBankDetails.propTypes = {
  inputValues: PropTypes.array,
};

AddBankDetails.defaultProps = {
  inputValues: [
    {
      label: "Account holder name",
      value: "",
      attrs: {},
      stats: false,
      name: "acc_holder_name",
    },
    {
      label: "Bank name",
      value: "",
      attrs: {},
      stats: false,
      name: "bank_name",
    },
    {
      label: "IFSC",
      value: "",
      attrs: {},
      stats: false,
      name: "ifsc",
    },
    {
      label: "Account number",
      value: "",
      attrs: {},
      stats: false,
      name: "acc_number",
    },
  ],
  addressField: [
    {
      label: "Current Address",
      value: "",
      attrs: {},
      stats: false,
    },
    {
      label: "Permanent Address",
      value: "",
      attrs: {},
      stats: false,
    },
  ],
  familyInputData: [
    {
      label: "Name",
      value: "",
      attrs: {},
      stats: false,
    },
    {
      label: "Phone number",
      value: "",
      attrs: {},
      stats: false,
    },
    {
      label: "Relationship",
      value: "",
      attrs: {},
      stats: false,
    },
  ],
  familyAddressField: [
    {
      label: "Address",
      value: "",
      attrs: {},
      stats: false,
    },
  ],
  driverPreference: [
    {
      label: "Preferred Location",
      value: "",
      attrs: {},
      stats: false,
    },
    {
      label: "Starting point",
      value: "",
      attrs: {},
      stats: false,
    },
  ],
};

export default AddBankDetails;
