import React from "react";
import PropTypes from "prop-types";
// import classNames from "classnames";
// import shortid from "shortid";
import { NavLink } from "shards-react";
// import { Link, Route } from "react-router-dom";
// import Rides from "../../views/Rides";

class EditDrivatab extends React.Component {
  constructor(props) {
    super(props); //since we are extending class Table so we have to use super in order to override Component class constructor
    // this.updateContent = this.updateContent.bind(this);
    this.state = {
      index: "",
    };
    this.handleSelect = this.handleSelect.bind(this);
    // console.log("props");
  }
  clickEvents(data, index) {
    this.props.onClickHandler(data.name, index);
  }
  handleSelect(index) {
    this.setState({
      index,
    });
  }
  render() {
    // console.log(this.props);
    // let selectedIndex = this.getState();
    const { label, attr, id, name } = this.props;
    // console.log("***********************************");
    // console.log(stats);
    return (
      // onClick={() => { this.clickEvents({ label }, this.props.id) }}
      <NavLink
        id={id}
        className={attr}
        onClick={() => {
          this.clickEvents({ name }, this.props.id);
        }}
      >
        {label}
      </NavLink>
    );
  }
}

EditDrivatab.propTypes = {
  /**
   * The Small Stats variation.
   */
  variation: PropTypes.string,
  /**
   * The label.
   */
  label: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  /**
   * The value.
   */
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  /**
   * Flag to differentiate stat box
   */
  stats: PropTypes.bool,
  isActive: PropTypes.bool,
  attr: PropTypes.string,
};

EditDrivatab.defaultProps = {
  value: 0,
  label: "Label",
  stats: "",
  isActive: false,
  attr: "active d-flex justify-content-center",
};

export default EditDrivatab;
