import React, { useEffect, useState, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  FormGroup,
  FormSelect,
  Row,
  Col,
  Button,
  Collapse,
  FormCheckbox,
  FormTextarea,
} from "shards-react";
import PropTypes from "prop-types";
import axios from "axios";
import { TextField, Button as MatButton } from "@material-ui/core";
import { Modal, Form } from "react-bootstrap";
import moment from "moment";
import {
  createTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import RideIssues from "../../../components/common/RideIssues";

import * as config from "../../../constants/constants.json";

import * as stringData from "../../../constants/Strings.json";
/**Common database components */
import DataTableBase from "../../../components_core/common/DataTableCommon";
// import { event } from "react-ga";

/**Import maps*/
import Maps from "../../../utils/Maps";
import PolyLine from "../../../utils/PolyLine";
import RoadMap from "../../../utils/RoadMap";

/**Material UI  */
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  buttonRoot: {
    backgroundColor: "black",
    borderColor: "black",
    color: "white",
    "&:hover": {
      backgroundColor: "#151220",
      borderColor: "#151220",
      color: "white",
      // boxShadow: "0.1em 0.1em 1em 0em #00000029",
    },
  },
  completed: {
    color: "#27FC69",
  },
  step: {
    "&$active": {
      color: "#151220",
    },
    "&$completed": {
      color: "#27FC69",
    },

    "&$disabled": {
      color: "#FF0000",
    },
    "&$error": {
      color: "#FF0000",
    },
  },
  active: {
    color: "#151220",
  },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(TextField);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const valueCheck = (data) => {
  // console.log(data);
  switch (data) {
    case "Consumercancelled":
      return "Consumer cancelled";
    case "Requested":
      return "Unassigned";
    case "Drivercancelled":
      return "Canceled via panel";
    case "Assigned":
      return "Confirmed";
    case "Accepted":
      return "Left for pickup";
    case "Ended":
      return "Completed";
    case "Booknow":
      return "Book now";
    case "Scheduled":
      return "Airport";
    case data === undefined || data === "":
      return "-";
    default:
      return data;
  }
};

const colorCheck = (data) => {
  // console.log(data);
  switch (data) {
    case "Consumercancelled":
      return "#FF0000";
    case "Requested":
      return "black";
    case "Drivercancelled":
      return "#FF0000";
    case "Assigned":
      return "#59E67F";
    case "Started":
      return "#59E67F";
    case "Accepted":
      return "#FF8307";
    case "Ended":
      return "#59E67F";
    default:
      return data;
  }
};

const InputData = (props) => {
  //   console.log(props);
  const { label, value } = props;
  return (
    <CssTextField
      variant="outlined"
      fullWidth
      label={label}
      value={valueCheck(value)}
      InputProps={{
        readOnly: true,
      }}
      InputLabelProps={{ shrink: true }}
    />
  );
};

const InputArea = (props) => {
  const { label, value, placeholder, type } = props;
  return (
    <CssTextField
      variant="outlined"
      fullWidth
      label={label}
      multiline
      rows={4}
      maxRows={6}
      type={type}
      value={value}
      placeholder={placeholder}
      InputLabelProps={{ shrink: true }}
      onChange={props.onChange}
      required
    />
  );
};

const FineInputArea = (props) => {
  const { label, value, placeholder, type } = props;
  return (
    <CssTextField
      variant="outlined"
      fullWidth
      label={label}
      multiline
      rows={4}
      maxRows={6}
      type={type}
      value={value}
      placeholder={placeholder}
      InputLabelProps={{ shrink: true }}
      onChange={props.onChange}
      required
      disabled={props.readOnly}
    />
  );
};

const FineInputExtraAmyData = (props) => {
  //   console.log(props);
  const { label, value, placeholder, type } = props;
  return (
    <CssTextField
      variant="outlined"
      fullWidth
      label={label}
      value={value}
      type={type}
      placeholder={placeholder}
      InputLabelProps={{ shrink: true }}
      onChange={props.onChange}
      required
      disabled={props.readOnly}
    />
  );
};

const InputExtraAmyData = (props) => {
  //   console.log(props);
  const { label, value, placeholder, type } = props;
  return (
    <CssTextField
      variant="outlined"
      fullWidth
      label={label}
      value={value}
      type={type}
      placeholder={placeholder}
      InputLabelProps={{ shrink: true }}
      onChange={props.onChange}
      required
    />
  );
};

const ViewDetailsButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    color: "white",
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#151220",
    borderColor: "#151220",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#151220",
      borderColor: "#151220",
      boxShadow: "0.1em 0.1em 1em 0em #00000029",
    },
  },
})(MatButton);

/**Button component */
const NotificationButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 14,
    color: "white",
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#151220",
    borderColor: "#151220",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#151220",
      borderColor: "#151220",
      boxShadow: "0.1em 0.1em 1em 0em #00000029",
    },
    "&:disabled": {
      backgroundColor: "grey",
      borderColor: "grey",
      color: "white",
    },
  },
})(MatButton);

export default function BookingDetails(props) {
  const classes = useStyles();
  const [tripData, setTripData] = useState([]);
  const [sequence, setSequence] = useState([]);
  const [extraDisabled, setExtraDisabled] = useState(true);
  const [assignModal, setAssignModal] = useState(false);
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [offeredAmount, setOfferedAmount] = useState(undefined);
  const [assinOffered, setAssignOfferedAmt] = useState(undefined);
  const [disableButton, setDisableButton] = useState(false);
  const [sentSuccess, setSentSuccess] = useState(false);
  const [duty_type, setDutyType] = useState("nearby");
  const [assignPending, setAssignPending] = useState(true);
  const [driverData, setDriverData] = useState([]);
  const [modalSelectDriver, setModalSelectDriver] = useState(undefined);
  const [collapse, setCollapse] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showTripDetails, setToggleTripDetail] = useState(false);
  const [detailCollapse, setDetailCollapse] = useState(true);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [cnoshow, setNoShow] = useState(false);
  const [breakdown, setBreakdown] = useState(false);
  const [flightDelay, setFlightDelay] = useState(false);
  const [dnoshow, setDnoShow] = useState(false);
  const [cancel_reason, setCancelReason] = useState("");
  const [message, setMessage] = useState("");
  const [otpGen, setOtpGen] = useState(false);
  const [showCancelTripDetails, setCancelTripDetails] = useState(false);
  const [detailCancelCollapse, setCancelCollapse] = useState(true);
  const [otpField, setOtpfield] = useState("");
  const [tagDriver, setOpenTagDriver] = useState(false);
  const [tagPhonenumber, setTagPhonenumber] = useState("");
  const [tagOfferedAmount, setTagOfferedAmount] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchFound, setSearchFound] = useState(false);
  const [otpVerCode, setOtpVerCode] = useState("");
  const [otpCode, setOtp] = useState("");
  const [reassignOpen, setReassignOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [driverLocationData, setDriverLocationData] = useState(undefined);
  const [open, setOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [issueTitle, setIssueTitle] = useState("");
  const [issueDescription, setIssueDescription] = useState("");
  const [reviewList, setReviewList] = useState("");
  const [openissue, setOpenIssueTab] = useState(false);
  const [passData, setPassData] = useState([]);
  const [beneficiaryStatus, setBenificiaryStatus] = useState(false);
  const [storeTagData, setStoreTagData] = useState([]);
  const [storePayData, setStorePayData] = useState([]);
  const [showBox, setShowBox] = useState(false);
  const [showTagging, setShowTagging] = useState(false);
  const [showPayBox, setShowPayBox] = useState(false);
  const [openPayNow, setOpenPayNow] = useState(false);
  const [paid_status, setPaidStatus] = useState(false);
  const [deactivate, setDeactive] = useState(false);
  const [fineAmount, setFineAmount] = useState(0);
  const [fineEmptyKms, setEmptyKms] = useState(0);
  const [fineExtrarideAmount, setExtraRideAmount] = useState(0);
  const [fineExtraTraveledKms, setExtraTraveleKms] = useState(0);
  const [fineReason, setFineReason] = useState("");
  const [editable, setEditable] = useState(true);
  const [showOfferedAmount, setShowOfferedAmount] = useState(false);

  /**Check beneficiary status */
  const checkForBeneficiary = () => {
    let dataToSend;
    if (tripData.trip_status === "Requested" && showBox === true) {
      dataToSend = {
        phonenumber: storeTagData.driver_phonenumber,
      };
    } else {
      dataToSend = {
        phonenumber: tripData.driver_phone,
      };
    }

    // console.log(dataToSend);
    axios
      .post(config.url + "payout/checkBeneficiary", dataToSend)
      .then((response) => {
        console.log(response);
        if (
          response.data.message === "old beneficiary" ||
          response.data.message === "new beneficiary"
        ) {
          let responseData = response.data;
          let statusCheck =
            responseData.status.toLowerCase() === "verified" &&
            responseData.account_verified_status.toLowerCase() === "verified"
              ? true
              : false;
          setBenificiaryStatus(statusCheck);
          checkForPaidStatus();
          // this.setState(
          //   {
          //     beneficiaryStatus: statusCheck,
          //   },
          //   () => this.checkForPaidStatus()
          // );
        } else {
        }
      })
      .catch();
  };

  /**Check tag status */
  const checkforTagging = () => {
    let dataToSend = {
      trip_id: props.tripData.trip_id,
    };
    axios
      .post(config.url + "payout/getTaggedDriverDetails", dataToSend)
      .then((response) => {
        console.log(response);
        if (response.data.message === "successfull") {
          setShowTagging(false);
          setShowBox(true);
          setStoreTagData([]);
          // this.setState({
          //   showTagging: false,
          //   showBox: true,
          //   storeTagData: response.data.data,
          // });
        } else {
          setShowTagging(true);
          setShowBox(false);
          setStoreTagData([]);
          // this.setState({
          //   showTagging: true,
          //   showBox: false,
          //   storeTagData: [],
          // });
        }
      });
  };

  /**Check for payout */
  const checkforPayout = () => {
    let dataToSend = {
      trip_id: props.tripData.trip_id,
    };
    axios
      .post(config.url + "payout/getmanualpayment", dataToSend)
      .then((response) => {
        // console.log(response);
        // console.log("*************************");
        if (response.data.message === "successfull") {
          if (
            response.data.is_payout_exists === true &&
            response.data.is_manual_payment === true
          ) {
            setShowPayBox(true);
            setStorePayData(response.data.response);
            // this.setState({
            //   showPayBox: true,
            //   storePayData: response.data.response,
            // });
          } else if (
            response.data.is_payout_exists === true &&
            response.data.is_manual_payment === false
          ) {
            setShowPayBox(false);
            setStorePayData([]);
            // this.setState({ storePayData: [], showPayBox: false });
          }
          // this.setState({
          //   showTagging: false,
          //   showBox: true,
          //   storeTagData: response.data.data,
          // });
        } else {
          setShowPayBox(false);
          setStorePayData([]);
          // this.setState({ storePayData: [], showPayBox: false });
          // this.setState({
          //   showTagging: true,
          //   showBox: false,
          //   storeTagData: [],
          // });
        }
      });
  };

  /**Api to check paid status */
  const checkForPaidStatus = () => {
    let dataToSend = {
      trip_id: tripData.trip_id,
    };
    axios
      .post(config.url + "payout/getPayNowPaidStatus", dataToSend)
      .then((response) => {
        if (response.data.message === "successfull") {
          setPaidStatus(true);
        } else {
        }
      })
      .catch();
  };

  /** Api to pay to driver */
  const payToDriver = (e) => {
    if (e) {
      e.preventDefault();
    }
    setDeactive(false);
    let dataToSend = {
      trip_id: tripData.trip_id,
      transfer_initiatedBy: sessionStorage.getItem("user_id"),
    };
    axios
      .post(config.url + "payout/payNowThroughCashfree", dataToSend)
      .then((response) => {
        // console.log("pay to driver");
        // console.log(response.data);
        if (response.data.message === "successfull") {
          // this.checkForPaidStatus();
          if (
            response.data.status === "success" ||
            response.data.status === "completed" ||
            response.data.status === "pending"
          ) {
            setMessage("Payment made to driver");
            setOpen(true);
            checkForPaidStatus();
            closeTogglePayNow();
            // this.setState(
            //   {
            //     success: true,
            //     message: "Payment made to driver",
            //   },
            //   () => {
            //     this.show();
            //     this.checkForPaidStatus();
            //     this.closeTogglePayNow();
            //   }
            // );
          } else {
            setMessage("Payment initiated. Please wait .....");
            setOpen(true);
            checkForPaidStatus();
            closeTogglePayNow();
          }
        } else {
          setMessage("Payment to driver failed");
          setErrorOpen(true);
        }
      })
      .catch();
  };

  /**Use effect to rest notification successfull message */
  useEffect(() => {
    // let timer;
    if (sentSuccess === true) {
      setOpen(true);
      setMessage("Notification sent to driver");
      setDisableButton(false);
    }
    // return () => {
    //   clearTimeout(timer);
    // };
  }, [sentSuccess]);

  /**Function to handle title input */

  const handleReportIssue = (event) => {
    setIssueTitle(event.target.value);
  };

  const handleReportDescription = (event) => {
    setIssueDescription(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorOpen(false);
  };
  /**Function to set tag offered amount */

  const inputTagOfferedAmount = (event) => {
    setTagOfferedAmount(event.target.value);
  };

  /**Function to enter phonenumber */
  const inputTagData = (event) => {
    setTagPhonenumber(event.target.value);
  };

  /**Open tag modal */

  const openTagDriver = () => {
    setOpenTagDriver(true);
  };

  // const closeTagDriver = () =>{
  //   setOpenTagDriver(false)
  // }

  /**Handle checkbox data */
  const handleChange = (e, data) => {
    switch (data) {
      case "cnoshow":
        setNoShow(!cnoshow);
        break;
      case "breakdown":
        setBreakdown(!breakdown);
        break;
      case "flightDelay":
        setFlightDelay(!flightDelay);
        break;
      case "dnoshow":
        setDnoShow(!dnoshow);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      tripData.trip_id === undefined ||
      tripData.trip_status === "Completed" ||
      tripData.trip_status === "Consumercancelled" ||
      tripData.trip_status === "Drivercancelled"
    ) {
      getReports();
      checkforTagging();
      checkForBeneficiary();
      checkforPayout();
    } else {
      getReports();
      fetchDriverLocation();
      checkForBeneficiary();
      checkforTagging();
      checkforPayout();
    }
  }, [tripData]);

  /**API to fetch driver location */

  const fetchDriverLocation = () => {
    let dataToSend = {
      trip_id: tripData.trip_id,
    };
    console.log(dataToSend);
    axios
      .post(config.url + "htmlcrm/getDriverLocation", dataToSend)
      .then((response) => {
        console.log(response.data);
        if (response) {
          setDriverLocationData(response.data);
        } else {
          setDriverLocationData(undefined);
        }
      })
      .catch();
  };

  /**Handle cancellation reason */

  const onReasonChange = (e) => {
    setCancelReason();
  };

  const openCancelModal = (data) => {
    setModalSelectDriver(data);
    setCancelOpen(true);
  };

  /**Toggle cancel modal close*/
  const toggleCancel = () => {
    setCancelOpen(false);
    setOtpfield("");
    setOtpVerCode("");
    setCancelReason("");
    setModalSelectDriver([]);
    setNoShow(false);
    setBreakdown(false);
    setFlightDelay(false);
    setDnoShow(false);
  };

  let dutyColumn = [];
  let issueTable = [];
  /**Function to toggle nearby and onduty drivers */
  const changeDutyStatus = (e, status) => {
    setDutyType(status);
  };

  const handleOfferedAmtChange = (e) => {
    // console.log(e.target.value);
    setOfferedAmount(e.target.value);
  };

  const handleassignOfferedAmt = (e) => {
    setAssignOfferedAmt(e.taret.value);
  };

  /**Function to refresh assign drivers section */
  const handleDutyRefreshClick = () => {
    getDriversToAssign(modalSelectDriver);
  };

  /**Function to get drivers to assign */

  const getDriversToAssign = (data) => {
    if (duty_type === stringData.nrby) {
      getNearbyDrivers(data);
    } else {
      getOnDutyDrivers(data);
    }
  };

  /**Functionality to generate OTP */
  const gencancelOTP = () => {
    let dataToSend = {
      otpType: "cancellation",
      executive_id: "5d07363d8a332a0af0df7b59",
      // executive_id: window.sessionStorage.getItem("user_id"),
      trip_id: tripData.trip_id,
      rideType: tripData.ride_type,
    };
    axios
      .post(config.url + "htmlcrm/generateApprovalOTP", dataToSend)
      .then((response) => {
        if (response.data.message === "successfull") {
          setMessage("OTP sent to registered email");
          setOpen(true);
          setOtpGen(true);
          setOtpVerCode(response.data.otp_verfication_code);
          setOtp(response.data.otp_number);
        } else {
          setMessage("OTP was not delivered");
          setErrorOpen(true);
          setOtpVerCode(response.data.otp_verfication_code);
          setOtp(response.data.otp_number);
        }
      })
      .catch();
  };

  /**Functionality to verify cancel OTP */
  const verifycancelOTP = (e) => {
    if (e) {
      e.preventDefault();
    }
    console.log("herer");

    let dataToSend = {
      // trip_id: this.props.tripId
      // trip_id: '0K918TQC60KQcwth'
      // executive_id: "5d07363d8a332a0af0df7b59",
      executive_id: sessionStorage.getItem("user_id"),
      otp_number: otpField,
      otp_verification_code: otpVerCode,
    };

    axios
      .post(config.url + "htmlcrm/verifyApprovalOTP", dataToSend)
      .then((response) => {
        if (response.data.message === stringData.onsuccess) {
          cancelRide();
          setOtpGen(false);
          getRideDetails();
        } else {
          setErrorOpen(true);
          setMessage("Invalid cancellation OTP");
        }
      })
      .catch();
  };

  /**Api to get nearby drivers */
  const getNearbyDrivers = (data) => {
    let dataToSend = {
      latitude: data.slatlon[1],
      longitude: data.slatlon[0],
      vehicle_type: data.vehicle_type,
      trip_id: data.trip_id,
      triptype: data.ride_type,
      operating_region: data.region,
    };
    console.log(dataToSend);
    axios
      .post(config.url + "api/nearByDriverlocation", dataToSend)
      .then((response) => {
        console.log(response.data);
        setAssignPending(false);
        setDriverData(response.data.result);
      })
      .catch();
  };

  /**Function to toggle ride details in the assign modal */
  const toggleRideDetails = () => {
    setToggleTripDetail(!showTripDetails);
    setDetailCollapse(!detailCollapse);
    //  collapse: !this.state.collapse,
    //     showTripDetails: !this.state.showTripDetails,
  };

  const toggleCancelRideDetails = () => {
    setCancelTripDetails(!showCancelTripDetails);
    setCancelCollapse(!detailCancelCollapse);
  };

  /**Api to get on duty drivers */
  const getOnDutyDrivers = (data) => {
    console.log(data);
    let dataToSend = {
      vehicle_type: data.vehicle_type,
      trip_id: data.trip_id,
      triptype: data.ride_type,
      operating_region: data.region,
    };

    axios
      .post(config.url + "htmlcrm/freeDrivers", dataToSend)
      .then((response) => {
        console.log(response);
        setAssignPending(false);
        setDriverData(response.data.driverlist);
      })
      .catch();
  };

  /**Function to open assign modal */

  const openAssignModal = (data) => {
    console.log(data);
    getDriversToAssign(data);
    setModalSelectDriver(data);
    setAssignModal(true);
  };

  const closeAssignModal = () => {
    setAssignModal(false);
    setModalSelectDriver([]);
  };

  /**Table to display drivers */
  if (duty_type === stringData.nrby) {
    dutyColumn = useMemo(() => [
      {
        name: "Sl no",
        maxWidth: "100px",
        cell: (row, rowIndex) => <span className="mr-2">{rowIndex + 1}</span>,
      },
      {
        name: "Driver name",
        selector: (row) => row.driver_name,
      },
      {
        name: "Phone number",
        selector: (row) => row.phonenumber,
      },
    ]);
  } else {
    dutyColumn = useMemo(() => [
      {
        name: "Sl no",
        maxWidth: "100px",
        cell: (row, rowIndex) => <span className="mr-2">{rowIndex + 1}</span>,
      },
      {
        name: "Driver name",
        selector: (row) => row.dname,
      },
      {
        name: "Phone number",
        selector: (row) => row.phnno,
      },
    ]);
  }

  useEffect(() => {
    // console.log(modalSelectDriver);
    if (modalSelectDriver) {
      console.log("here");
      setAssignPending(true);
      getDriversToAssign(modalSelectDriver);
    }
  }, [duty_type]);

  /**Function to handle driver selection */
  const driverRowSelection = ({ selectedRows }) => {
    console.log(selectedRows);
    let datas = _.find(selectedRows, (o) => {
      return o.driver_type === "Temporary";
    });
    if (datas !== undefined) {
      setShowOfferedAmount(true);
    } else {
      setShowOfferedAmount(false);
    }
    setSelectedDrivers(selectedRows);
    // setSelectedDrivers(selectedRows);
  };

  /**API to get ride details */
  const getRideDetails = () => {
    let dataToSend = {
      trip_id: props.tripData.trip_id,
    };
    let seqData = [];
    axios
      .post(config.url + "booking/getRideDetails", dataToSend)
      .then((response) => {
        console.log(response.data);
        if (response.data.via_points.length > 0) {
          seqData = response.data.via_points;
          // console.log(seqData);
        }
        setTripData(response.data);
        setSequence(seqData);
        // fetchDriverLocation();
      })
      .catch();
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("user_id") === null ||
      sessionStorage.getItem("user_id") === undefined ||
      sessionStorage.getItem("user_id") === ""
    ) {
      window.location.assign("/");
    } else {
      getRideDetails();

      if (
        tripData.trip_status === "Completed" ||
        tripData.trip_status === "Drivercancelled" ||
        tripData.trip_status === "Consumercancelled"
      ) {
      } else {
        let timerID = setInterval(() => getRideDetails(), 30000);
        return () => {
          clearInterval(timerID);
        };
      }
    }
  }, []);

  useEffect(() => {
    console.log(driverLocationData);
    if (driverLocationData !== undefined) {
      if (driverLocationData.trip_status === "accepted") {
        setActiveStep(1);
      } else if (driverLocationData.trip_status === "notified") {
        setActiveStep(2);
      } else if (driverLocationData.trip_status === "started") {
        setActiveStep(3);
      } else if (
        driverLocationData.toll_info &&
        driverLocationData.toll_info.toll_plaza_passed === true
      ) {
        setActiveStep(4);
      } else if (driverLocationData.trip_status === "ended") {
        setActiveStep(6);
      }
    }
  }, [driverLocationData]);

  /**API to end GCM to drivers */
  const callGCM = (e) => {
    // console.log(e);
    if (e) {
      e.preventDefault();
    }
    setDisableButton(true);
    let drivers;
    console.log(selectedDrivers);
    if (selectedDrivers.length > 0) {
      if (selectedDrivers === undefined || selectedDrivers.length === 0) {
        drivers = [];
      } else {
        drivers = _.map(selectedDrivers, "dunique_id");
      }

      let dataToSend = {
        // executive_id: window.sessionStorage.getItem("user_id"),
        executive_id: "5d07363d8a332a0af0df7b59",
        trip_id: modalSelectDriver.trip_id,
        dunique_ids: drivers.toString(),
      };
      console.log(dataToSend);
      axios
        .post(config.url + "htmlcrm/sendScheduledRideGCM", dataToSend)
        .then((response) => {
          if (response.data.message === stringData.onsuccess) {
            // updateOfferedAmount();
            setSentSuccess(true);
          } else {
            setTimeout(() => {
              setDisableButton(false);
            }, 3000);
          }
        })
        .catch();
    }
  };

  /**Function to select nearby and onduty drivers for mobile devices */
  const handledutyStatusSelectChange = (event) => {
    setDutyType(event.target.value);
  };

  const handleOtpValue = (event) => {
    setOtpfield(event.target.value);
  };

  const toggleExitTag = () => {
    setOpenTagDriver(false);
  };

  const updateOfferedAmount = (data) => {
    if (data) {
      let dataToSend = {
        // trip_id: this.props.tripId
        // trip_id: '0K918TQC60KQcwth'
        trip_id: props.tripData.trip_id,
        userid: sessionStorage.getItem("user_id"),
        valueType: "offered",
        value: data,
      };
      axios
        .post(config.url + "htmlcrm/updateTripData", dataToSend)
        .then((response) => {});
    } else {
    }
  };

  /**Function to tag driver */
  const tagDriverToTheRide = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (tagOfferedAmount === undefined || tagOfferedAmount === null) {
      setMessage("Offered amount cannot be empty");
      setOpen(true);
    } else {
      updateOfferedAmount(tagOfferedAmount);
      let dataToSend = {
        dunique_id: searchData.dunique_id,
        trip_id: tripData.trip_id,
        tagged_by: "5d07363d8a332a0af0df7b59",
        // tagged_by: window.sessionStorage.getItem("user_id"),
        status: "tagged",
      };
      axios
        .post(config.url + "payout/tagDriverToTheRide", dataToSend)
        .then((response) => {
          if (response.data.message === "successfull") {
            setMessage("The driver has been tagged");
            setOpen(true);
            setOpenTagDriver(false);
            setTagPhonenumber("");
            // this.setState(
            //   {
            //     success: true,
            //     message: "The trip has been tagged",
            //     tagPhonenumber: "",
            //     tagDriver: !this.state.tagDriver,
            //   },
            //   () => {
            //     this.show();
            //   }
            // );
          } else {
            setMessage("Failed to tag the driver");
            setErrorOpen(true);
            // this.setState(
            //   {
            //     success: false,
            //     message: "Failed to tag the trip",
            //   },
            //   () => {
            //     this.show();
            //   }
            // );
          }
        });
    }
  };

  /**APi to get tag driver info */

  const searchFoundTest = () => {
    let dataToSend = {
      phonenumber: tagPhonenumber,
    };
    axios
      .post(config.url + "payout/getDriverDetails", dataToSend)
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "successfull") {
          setSearchFound(true);
          setSearchData(response.data.data);
          //  this.setState({
          //    searchFound: true,
          //    searchData: response.data.data,
          //  });
        } else {
          setSearchFound(false);
          setSearchData([]);
          //  this.setState(
          //    {
          //      success: false,
          //      message: "Driver not found",
          //      searchFound: false,
          //      searchData: [],
          //    },
          //    this.show()
          //  );
        }
      });
  };

  /**API to cancel the ride */

  const cancelRide = () => {
    let CancelCall;
    // console.log(this.state.tripdata.trip_status);
    if (
      tripData.trip_status === "Requested" &&
      tripData.trip_status === "Emergency"
    ) {
      // console.log("herererer");
      CancelCall = "cancelRequestedRide";
    } else if (
      tripData.trip_status === "Accepted" ||
      tripData.trip_status === "Notified"
    ) {
      CancelCall = "canceltrip";
    } else if (
      tripData.trip_status === "Assigned" ||
      tripData.trip_status === "Requested"
    ) {
      CancelCall = "cancelRides";
    }

    let dataToSend = {
      // trip_id: this.props.tripId
      // trip_id: '0K918TQC60KQcwth'
      trip_id: tripData.trip_id,
      trip_type: tripData.ride_type,
      user_id: "5d07363d8a332a0af0df7b59",
      userid: "5d07363d8a332a0af0df7b59",
      cancel_by: "5d07363d8a332a0af0df7b59",
      // userid: window.sessionStorage.getItem("user_id"),
      // user_id: window.sessionStorage.getItem("user_id"),
      // cancel_by: window.sessionStorage.getItem("user_id"),
      request_status: tripData.trip_status,
      trip_status: "cancelled",
      dunique_id: tripData.dunique_id,
      cancel_reason: cancel_reason,
    };

    axios
      .post(config.url + `htmlcrm/${CancelCall}`, dataToSend)
      .then((response) => {
        if (response.data.message === stringData.onsuccess) {
          setMessage("The trip has been canceled");
          setOpen(true);
          toggleCancel();
        } else {
          setMessage("Failed to cancel the ride");
          setErrorOpen(true);
        }
      })
      .catch();
    // console.log(CancelCall);
  };

  /**Toggle re-assign modal */
  const toggleReassign = () => {
    setReassignOpen(!reassignOpen);
    // this.setState({
    //   reassignOpen: !this.state.reassignOpen,
    // });
  };

  /**Api to re-assign driver */

  const reAssign = () => {
    let ApiCall;
    // console.log(this.state.tripdata)
    if (
      tripData.trip_status === "Assigned" ||
      tripData.trip_status === "Confirmed"
    ) {
      ApiCall = "cancelAssignedRide";
    } else {
      ApiCall = "cancelLeaveForPickUpRide";
    }

    let dataToSend = {
      trip_id: tripData.trip_id,
      trip_type: tripData.ride_type,
      user_id: "5d07363d8a332a0af0df7b59",
      //  user_id: window.sessionStorage.getItem("user_id"),
    };

    axios
      .post(config.url + `htmlcrm/${ApiCall}`, dataToSend)
      .then((response) => {
        if (response.data.message === stringData.onsuccess) {
          setMessage("Driver has been removed from the ride");
          setOpen(true);
          toggleReassign();
          getRideDetails();
          // Do something
        } else {
          setMessage("Failed to remove the driver");
          setErrorOpen(true);
          // Do something
        }
      })
      .catch();
  };

  function capitalizeFirstLetter(string) {
    // console.log(string);
    return string[0].toUpperCase() + string.slice(1);
  }

  const callAutoAssign = () => {
    console.log("herer");
    let dataToSend = {
      trip_id: tripData.trip_id,
    };
    console.log(dataToSend);
    axios
      .post(config.url + "vendor/autoAssignRide", dataToSend)
      .then((response) => {
        console.log(response);
        if (response.data.message === stringData.onsuccess) {
          setOpen(true);
          setMessage("Auto assign has been triggered");
        } else {
          setErrorOpen(true);
          setMessage("Failed to trigger auto asign");
        }
      })
      .catch();
  };

  /**API to report issue */

  const handleReportApi = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (
      issueTitle === undefined ||
      issueTitle === null ||
      issueTitle === "" ||
      issueDescription === undefined ||
      issueDescription === null ||
      issueDescription === ""
    ) {
      setMessage("Title or description cannot be empty");
      setErrorOpen(true);
    } else {
      let dataToSend = {
        trip_id: tripData.trip_id,
        userid: sessionStorage.getItem("user_id"),
        username: sessionStorage.getItem("username"),
        title: issueTitle,
        message: issueDescription,
      };

      axios
        .post(config.url + "htmlcrm/createRideIssue", dataToSend)
        .then((response) => {
          setIssueTitle("");
          setIssueDescription("");
          setMessage("A new issue has been created");
          setOpen(true);
          getReports();
        });
    }
  };

  const getReports = () => {
    let dataToSend = {
      trip_id: tripData.trip_id,
    };
    console.log(tripData);
    axios
      .post(config.url + "htmlcrm/getAllReportedIssues", dataToSend)
      .then((response) => {
        console.log(response.data);
        setReviewList(response.data.results);
      });
  };

  /**Initialze issue table */
  issueTable = useMemo(() => [
    {
      name: "Sl no",
      maxWidth: "100px",
      cell: (row, rowIndex) => <span className="mr-2">{rowIndex + 1}</span>,
    },
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Reported by",
      selector: (row) => row.username,
    },
    {
      name: "Received on",
      cell: (row) => (
        <span className="mr-2">
          {moment(new Date(row.statusrevise)).format(
            "DD-MM-YY [at] hh:mm:ss A"
          )}
        </span>
      ),
    },
    {
      name: "Status",
      cell: (row) => {
        return <div>{capitalizeFirstLetter(row.status)}</div>;
      },
    },
    {
      name: "Actions",
      grow: 2,
      minWidth: "150px",
      cell: (row) => {
        return (
          <div className="d-flex flex-column">
            <Button
              className="px-2 py-2 my-2 button-width"
              theme="dark"
              onClick={() => openIssueTab(row)}
            >
              VIEW
            </Button>
          </div>
        );
      },
    },
  ]);

  /**Open issue tab on click */
  const openIssueTab = (data) => {
    setPassData(data);
    setOpenIssueTab(true);
  };

  /**Close issue tab */
  const closeIssueTab = () => {
    setPassData([]);
    setOpenIssueTab(false);
  };

  /**Close issue tab */
  const enableRideIssue = () => {
    setPassData([]);
    setOpenIssueTab(false);
  };

  /**Trigger pay now modal */
  const openTogglePayNow = () => {
    setOpenPayNow(true);
  };

  const closeTogglePayNow = () => {
    setOpenPayNow(false);
  };

  const updateExtraTraveledLms = (data) => {
    if (data) {
      let dataToSend = {
        trip_id: tripData.trip_id,
        userid: sessionStorage.getItem("user_id"),
        valueType: "extrakm",
        value: data,
      };
      axios
        .post(config.url + "htmlcrm/updateTripData", dataToSend)
        .then((response) => {});
    }
  };

  const updateFineAmount = (data, reason) => {
    if (data) {
      let dataToSend = {
        trip_id: tripData.trip_id,
        userid: sessionStorage.getItem("user_id"),
        valueType: "fine",
        value: data,
        reason: reason !== undefined ? reason : "",
      };
      axios
        .post(config.url + "htmlcrm/updateTripData", dataToSend)
        .then((response) => {});
    }
  };

  const updateEmptyKms = (data) => {
    if (data) {
      let dataToSend = {
        // trip_id: this.props.tripId
        // trip_id: '0K918TQC60KQcwth'
        trip_id: tripData.trip_id,
        userid: sessionStorage.getItem("user_id"),
        valueType: "emptykm",
        value: data,
      };
      axios
        .post(config.url + "htmlcrm/updateTripData", dataToSend)
        .then((response) => {});
    }
  };

  const updateTagFare = (data) => {
    if (data) {
      let dataToSend = {
        // trip_id: this.props.tripId
        // trip_id: '0K918TQC60KQcwth'
        trip_id: tripData.trip_id,
        userid: sessionStorage.getItem("user_id"),
        valueType: "Ride bonus",
        value: data,
        reason: "",
      };
      axios
        .post(config.url + "htmlcrm/updateTripData", dataToSend)
        .then((response) => {});
    }
  };

  const handleFineUpdatesClick = () => {};

  const callApitoUpdateData = () => {
    updateOfferedAmount(offeredAmount);
    updateExtraTraveledLms(fineExtraTraveledKms);
    updateFineAmount(fineAmount, fineReason);
    updateEmptyKms(fineEmptyKms);
    updateTagFare(fineExtrarideAmount);
  };

  const handleExtrarideAmount = (event) => {
    setExtraRideAmount(event.target.value);
  };

  const handleExtraTraveledKms = (event) => {
    setExtraTraveleKms(event.target.value);
  };

  const handleExtrakms = (event) => {
    setEmptyKms(event.target.value);
  };

  const handleFineAmount = (event) => {
    setFineAmount(event.target.value);
  };

  const handleFineReason = (event) => {
    setFineReason(event.target.value);
  };

  const saveEdit = () => {
    callApitoUpdateData();
  };

  return (
    <React.Fragment>
      {/* Modal to assign the ride */}
      <Modal
        show={assignModal}
        onHide={closeAssignModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Assign ride to driver</Modal.Title>
        </Modal.Header>
        <form onSubmit={callGCM}>
          <Modal.Body>
            <Row noGutters>
              <Col className="d-none d-md-block" md="10">
                <div className={classes.root}>
                  <ButtonGroup
                    size="large"
                    color="dark"
                    aria-label="outlined primary button group"
                  >
                    <MatButton
                      className={
                        duty_type === stringData.nrby ? classes.buttonRoot : ""
                      }
                      onClick={(e) => changeDutyStatus(e, stringData.nrby)}
                    >
                      NEARBY DRIVERS
                    </MatButton>
                    <MatButton
                      className={
                        duty_type === stringData.freed ? classes.buttonRoot : ""
                      }
                      onClick={(e) => changeDutyStatus(e, stringData.freed)}
                    >
                      ON-DUTY DRIVERS
                    </MatButton>
                  </ButtonGroup>
                </div>
              </Col>
              <Col md="2" className="d-flex justify-content-end">
                <Button
                  theme="dark"
                  className="my-auto"
                  onClick={handleDutyRefreshClick}
                >
                  <i className="fas fa-sync-alt" />
                </Button>
              </Col>
              <Col className="d-block d-md-none">
                <FormGroup>
                  <label className="d-flex justify-content-center">
                    Select driver options
                  </label>
                  <FormSelect
                    value={duty_type}
                    onChange={handledutyStatusSelectChange}
                    style={{
                      fontWeight: 700,
                      textAlignLast: "center",
                    }}
                    className="selection-form"
                  >
                    <option value="nearby">Nearby drivers</option>
                    <option value="freedrivers">On duty drivers</option>
                  </FormSelect>
                </FormGroup>
              </Col>
              <Col sm="12" md="12" className="">
                <Button
                  block
                  theme="dark"
                  onClick={toggleRideDetails}
                  className="w-100 mt-3  assign-button-style"
                  style={{
                    backgroundColor: "#F0F0F0",
                    borderColor: "#F0F0F0",
                    color: "#151220",
                  }}
                >
                  Ride details
                  <i
                    className={
                      showTripDetails === false
                        ? "fas fa-chevron-down float-right"
                        : "fas fa-chevron-up float-right"
                    }
                  />
                </Button>
                <Collapse
                  open={detailCollapse}
                  className="px-3 py-2"
                  style={{
                    backgroundColor: "#F0F0F0",
                  }}
                >
                  <div className="mx-md-3">
                    <span className="assign-trip_id">
                      Ride ID :{" "}
                      {modalSelectDriver ? modalSelectDriver.trip_id : "-"}
                    </span>
                    <Row
                      className="w-100 mx-auto mt-3 assign-col-data py-2"
                      style={{
                        backgroundColor: "#F5F6F8",
                      }}
                    >
                      <Col className="col-4 ">Source</Col>
                      <Col className="col-4">Destination</Col>
                      <Col className="col-4">Pickup time</Col>
                    </Row>
                    <Row className="mt-3 assign-row-data px-2">
                      <Col className="col-4">
                        {modalSelectDriver ? modalSelectDriver.sname : "-"}
                      </Col>
                      <Col className="col-4">
                        {modalSelectDriver ? modalSelectDriver.dname : "-"}
                      </Col>
                      <Col className="col-4">
                        {modalSelectDriver
                          ? modalSelectDriver.pickup_time
                          : "-"}
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </Col>
              <Col sm="12" md="12" className="pt-md-4">
                <DataTableBase
                  columns={dutyColumn}
                  selectableRows
                  onSelectedRowsChange={driverRowSelection}
                  datas={driverData}
                  types="assigntable"
                  progressPending={assignPending}
                />
              </Col>
              {showOfferedAmount && (
                <Col className="col-12 col-md-4 pt-5">
                  <CssTextField
                    variant="outlined"
                    fullWidth
                    id="offeredAmount"
                    label="Offered amount"
                    placeholder="Enter the offered amount"
                    onChange={handleassignOfferedAmt}
                    value={assinOffered}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
              )}
            </Row>
            {/* {sentSuccess === true && (
              <Row className="pt-4">
                <Col
                  className="col-12 d-flex justify-content-center"
                  style={{
                    fontSize: "1.3rem",
                    color: "#59E67F",
                  }}
                >
                  NOTIFICATION SENT TO DRIVER
                </Col>
              </Row>
            )} */}
          </Modal.Body>
          <Modal.Footer>
            <Row className="w-100 d-flex justify-content-center">
              <Col sm="12" md="4">
                <NotificationButton
                  className="py-3  w-100"
                  color="primary"
                  type="submit"
                >
                  SEND NOTIFICATION
                </NotificationButton>
              </Col>
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
      {/* Modal to cancel the ride */}
      <Modal
        show={cancelOpen}
        onHide={toggleCancel}
        centered
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Ride cancellation</Modal.Title>
        </Modal.Header>
        <form onSubmit={verifycancelOTP}>
          <Modal.Body>
            <Col sm="12" md="12" className="mb-3 px-0">
              <Button
                block
                theme="dark"
                onClick={toggleCancelRideDetails}
                className="w-100 mt-3  assign-button-style"
              >
                Ride details
                <i
                  className={
                    showCancelTripDetails === false
                      ? "fas fa-chevron-down float-right"
                      : "fas fa-chevron-up float-right"
                  }
                />
              </Button>
              <Collapse
                open={detailCancelCollapse}
                className="px-3 py-2"
                style={{
                  backgroundColor: "#F0F0F0",
                }}
              >
                <div className="mx-md-3">
                  <span className="assign-trip_id">
                    Ride ID :{" "}
                    {modalSelectDriver ? modalSelectDriver.trip_id : "-"}
                  </span>
                  <Row
                    className="w-100 mx-auto mt-3 assign-col-data py-2"
                    style={{
                      backgroundColor: "#F5F6F8",
                    }}
                  >
                    <Col className="col-4 ">Source</Col>
                    <Col className="col-4">Destination</Col>
                    <Col className="col-4">Pickup time</Col>
                  </Row>
                  <Row className="mt-3 assign-row-data px-2">
                    <Col className="col-4">
                      {modalSelectDriver ? modalSelectDriver.sname : "-"}
                    </Col>
                    <Col className="col-4">
                      {modalSelectDriver ? modalSelectDriver.dname : "-"}
                    </Col>
                    <Col className="col-4">
                      {modalSelectDriver ? modalSelectDriver.pickup_time : "-"}
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </Col>
            <Col sm="12" md="12" className="px-0 py-2">
              <h5>Please provide a reason for cancellation:</h5>
            </Col>
            <Row className="py-2">
              <Col className="" sm="12" md="5">
                <FormCheckbox
                  checked={cnoshow}
                  onChange={(e) => handleChange(e, "cnoshow")}
                >
                  Customer no show
                </FormCheckbox>
              </Col>
              <Col className="" sm="12" md="5">
                <FormCheckbox
                  checked={breakdown}
                  onChange={(e) => handleChange(e, "breakdown")}
                >
                  Vehicle breakdown
                </FormCheckbox>
              </Col>
              <Col className="" sm="12" md="5">
                <FormCheckbox
                  checked={flightDelay}
                  onChange={(e) => handleChange(e, "flightDelay")}
                >
                  Flight delayed
                </FormCheckbox>
              </Col>
              <Col className="" sm="12" md="5">
                <FormCheckbox
                  checked={dnoshow}
                  onChange={(e) => handleChange(e, "dnoshow")}
                >
                  Driver no show
                </FormCheckbox>
              </Col>
            </Row>

            {/* <Row className="mt-3">
            {this.props.CancelModData.map((stats, idx) => (
              <Col className="col-12 col-md-6 mb-4 " key={idx} {...stats.attrs}>
               
                <InputData
                  id={`small-stats-${idx}`}
                  variation="1"
                  label={stats.label}
                  value={this.state.tripdata[stats.apiID]}
                  stats={stats.stats}
                />
              </Col>
            ))}
          </Row>
          <Row>
            {this.props.SorDesData.map((stats, idx) => (
              <Col className="col-md-6 col-12 mb-4 " key={idx} {...stats.attrs}>
                
                <InputData
                  id={`small-stats-${idx}`}
                  variation="1"
                  label={stats.label}
                  value={this.state.tripdata[stats.apiID]}
                  stats={stats.stats}
                />
              </Col>
            ))}
          </Row>
 */}

            <Row className="w-100 mx-auto mt-2">
              <FormTextarea
                className="w-100 px-2"
                rows="4"
                value={cancel_reason}
                onChange={onReasonChange}
                placeholder="Reason for cancellation"
                required
              />
            </Row>
            <Row className="pt-4">
              {otpGen === true && (
                <Col sm="12" md="5">
                  <label className="font-weight-light w-100 mb-4">
                    *The OTP is valid for the next 15 minutes
                  </label>
                  <CssTextField
                    label="Cancel otp"
                    variant="outlined"
                    value={otpField}
                    fullWidth
                    placeholder="Enter the cancel otp"
                    onChange={handleOtpValue}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="number"
                    required
                  />
                </Col>
              )}
              {/* <div className="ml-md-5 ml-2 mt-4"> */}
              {/* <FormInput
                size="sm"
                id="#otpNumber"
                placeholder="Enter the OTP"
                className="text-center py-3"
                value={otpNumber}
                onChange={handleOtpChange}
                type="text"
                minLength={6}
                maxLength={6}
              /> */}
              {/* </div> */}
              {/* {otpGen === true && (
              <span
                className="ml-2 font-weight-light mt-4"
                style={{ color: "#59E67F" }}
              >
                OTP has been sent
              </span>
            )}
            {otpGen === false && (
              <span
                className="ml-2 font-weight-light mt-4"
                style={{ color: "#FF0000" }}
              >
                Failed to send OTP
              </span>
            )} */}
              {/* <span className="ml-2 my-2 font-weight-light">
              *This OTP is valid for the next 15 minutes
            </span> */}
            </Row>
            {/* <Row>
            <Col className="col-12 d-flex justify-content-center">
              <Button
                theme="dark"
                className="ml-2 mt-4 "
                onClick={verifycancelOTP}
                size="lg"
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "700",
                  minWidth: "200px",
                  borderRadius: "3px",
                }}
              >
                VERIFY & CANCEL RIDE
              </Button>
            </Col>
          </Row> */}
          </Modal.Body>
          <Modal.Footer>
            <Row className="w-100 d-flex justify-content-center">
              {otpGen === false && (
                <Col sm="12" md="4">
                  <NotificationButton
                    className="py-3 w-100"
                    onClick={gencancelOTP}
                    color="primary"
                  >
                    GENERATE OTP
                  </NotificationButton>
                </Col>
              )}
              {otpGen === true && (
                <React.Fragment>
                  <Col sm="12" md="4">
                    <NotificationButton
                      className="py-3 w-100"
                      onClick={gencancelOTP}
                      color="primary"
                    >
                      RE-GENERATE OTP
                    </NotificationButton>
                  </Col>
                  <Col sm="12" md="4">
                    <NotificationButton
                      className="py-3 w-100"
                      color="primary"
                      type="submit"
                    >
                      VERIFY AND CANCEL
                    </NotificationButton>
                  </Col>
                </React.Fragment>
              )}
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
      {/* Modal to tag a driver  */}
      <Modal
        show={tagDriver}
        onHide={toggleExitTag}
        centered
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Tag a driver</Modal.Title>
        </Modal.Header>
        <form onSubmit={tagDriverToTheRide}>
          <Modal.Body>
            {/* <h5 className="question-text-style">Search for the driver</h5> */}
            <Row className="mt-3">
              <Col className="mb-4 my-md-auto" sm="12" md="8">
                <CssTextField
                  value={tagPhonenumber}
                  fullWidth
                  variant="outlined"
                  placeholder="Enter the driver's phonenumber"
                  onChange={inputTagData}
                  type="text"
                  label="Phone number"
                  maxLength="10"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>
              <Col
                className="d-flex justify-content-center my-auto"
                sm="12"
                md="4"
              >
                <NotificationButton
                  onClick={searchFoundTest}
                  theme="dark"
                  size="lg"
                  className="py-3 w-100"
                >
                  SEARCH
                </NotificationButton>
              </Col>
            </Row>
            {searchFound === true && (
              <Row className="mt-4">
                <h5 className="w-100 px-3">Driver details</h5>
                <Col className="col-12 col-md-4 py-3 ">
                  <CssTextField
                    value={searchData.driver_name}
                    variant="outlined"
                    label="Driver name"
                    readOnly
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {/* Name : {searchData.driver_name} */}
                </Col>
                <Col className="col-12 col-md-4 py-3 ">
                  <CssTextField
                    value={searchData.phone_number}
                    variant="outlined"
                    label="Phone number"
                    readOnly
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {/* Phone : {searchData.phone_number} */}
                </Col>
                <Col className="col-12 col-md-4 py-3 ">
                  <CssTextField
                    value={searchData.vehicle_number}
                    variant="outlined"
                    label="Vehicle number"
                    readOnly
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {/* Vehicle number : {searchData.vehicle_number} */}
                </Col>
                <Col className="col-12 col-md-4 py-3 ">
                  <CssTextField
                    value={tripData.trip_id}
                    variant="outlined"
                    label="Ride ID"
                    readOnly
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {/* Trip ID: {tripData.trip_id} */}
                </Col>
                <Col className="col-12 col-md-12 py-3 ">
                  <h5 className="mb-3">Enter the offered amount</h5>
                  <CssTextField
                    value={tagOfferedAmount}
                    variant="outlined"
                    label="Offered amount"
                    placeholder="Enter offered amount"
                    onChange={inputTagOfferedAmount}
                    type="number"
                    required
                  />
                </Col>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Row className="w-100 d-flex justify-content-center">
              <Col sm="12" md="4">
                <NotificationButton
                  onClick={toggleExitTag}
                  className="py-3 w-100 mb-3"
                >
                  CANCEL
                </NotificationButton>
              </Col>
              <Col sm="12" md="4">
                <NotificationButton className="py-3 w-100" type="submit">
                  TAG DRIVER
                </NotificationButton>
              </Col>
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
      {/* Modal to re-assign driver */}
      <Modal
        show={reassignOpen}
        onHide={toggleReassign}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          {" "}
          <Modal.Title>Re-assign driver</Modal.Title>{" "}
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to remove the driver?</h5>
        </Modal.Body>
        <Modal.Footer>
          {/* reAssign */}
          <Row className="w-100 d-flex justify-content-center">
            <Col sm="12" md="4">
              <NotificationButton
                onClick={toggleReassign}
                theme="dark"
                size="lg"
                className="py-3 w-100 mb-3"
              >
                CANCEL
              </NotificationButton>
            </Col>
            <Col sm="12" md="4">
              <NotificationButton
                onClick={reAssign}
                theme="dark"
                size="lg"
                className="py-3 w-100"
              >
                RE-ASSIGN
              </NotificationButton>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      {/* Modal to approve paynow */}
      <Modal
        show={openPayNow}
        onHide={closeTogglePayNow}
        centered
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Pay now confirmation</Modal.Title>
        </Modal.Header>
        <form onSubmit={payToDriver}>
          <Modal.Body>
            {/* <h5 className="question-text-style">Search for the driver</h5> */}
            <Row className="mt-3">
              <Col className="col-12">
                <p className="question-text-style">
                  Are you sure you want to intitate payment ?
                </p>
              </Col>
              <Col className="col-12">
                {props.tripData.trip_status === "Requested" ? (
                  <React.Fragment>
                    {props.DriverPayData.map((stats, idx) => (
                      <Col
                        className="col-md-6 col-12 mb-4 "
                        key={idx}
                        {...stats.attrs}
                      >
                        {/* {console.log(data[stats.apiID])} */}
                        <InputData
                          id={`small-stats-${idx}`}
                          variation="1"
                          label={stats.label}
                          value={storeTagData[stats.apiID]}
                          stats={stats.stats}
                        />
                      </Col>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {props.DriverPayDatas.map((stats, idx) => (
                      <Col
                        className="col-md-6 col-12 mb-4 "
                        key={idx}
                        {...stats.attrs}
                      >
                        {/* {console.log(data[stats.apiID])} */}
                        <InputData
                          id={`small-stats-${idx}`}
                          variation="1"
                          label={stats.label}
                          value={tripData[stats.apiID]}
                          stats={stats.stats}
                        />
                      </Col>
                    ))}
                  </React.Fragment>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row className="w-100 d-flex justify-content-center">
              <Col sm="12" md="4">
                <NotificationButton
                  onClick={closeTogglePayNow}
                  className="py-3 w-100 mb-3"
                >
                  CLOSE
                </NotificationButton>
              </Col>
              <Col sm="12" md="4">
                <NotificationButton className="py-3 w-100" type="submit">
                  CONFIRM
                </NotificationButton>
              </Col>
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
      {/* Main child body */}
      {openissue === false && (
        <Card className="w-100">
          <CardHeader
            className="text-center "
            style={{
              padding: "2em",
            }}
          >
            {" "}
            <h4
              className="pt-2 "
              style={{
                fontWeight: 700,
              }}
            >
              Ride details{" "}
              <button
                type="button"
                className="close float-right ml-auto mr-3"
                aria-label="Close"
                onClick={props.onTitleChange}
              >
                <span aria-hidden="true">
                  <i class="fas fa-times" />
                </span>
              </button>
            </h4>
          </CardHeader>
          <CardBody>
            <Row noGutters>
              {tripData.trip_status === "Requested" && (
                <React.Fragment>
                  <Col
                    sm="12"
                    md="12"
                    className="mb-5 mb-md-5 px-3"
                    style={{
                      fontSize: "1.2em",
                      alignSelf: "center",
                    }}
                  >
                    Auto assign status:
                    {tripData.autoassign_status === "In progress" && (
                      <label
                        className="mx-2"
                        style={{
                          color: "#FFD800",
                          fontWeight: 700,
                        }}
                      >
                        {" " + tripData.autoassign_status}
                      </label>
                    )}
                    {tripData.autoassign_status === "Not started" && (
                      <label
                        className="mx-2"
                        style={{
                          color: "#FF0000",
                          fontWeight: 700,
                        }}
                      >
                        {" " + tripData.autoassign_status}
                      </label>
                    )}
                    {tripData.autoassign_status === "deactivated" && (
                      <label
                        className="mx-2"
                        style={{
                          color: "#FF0000",
                          fontWeight: 700,
                        }}
                      >
                        {" " +
                          capitalizeFirstLetter(tripData.autoassign_status)}
                      </label>
                    )}
                    {tripData.autoassign_status === "Started" && (
                      <label
                        className="mx-2"
                        style={{
                          color: "#59E67F",
                          fontWeight: 700,
                        }}
                      >
                        {" " + tripData.autoassign_status}
                      </label>
                    )}
                  </Col>
                  {/* <Col sm="12" md="8" className="mb-3 mb-md-5">
                  <NotificationButton
                    className="float-right mb-3 py-3 mx-2 px-4"
                    theme="dark"
                    onClick={callAutoAssign}
                  >
                    TRIGGER AUTO ASSIGN
                  </NotificationButton>
                  <a
                    href={`/autoassignlogs/${tripData.trip_id}`}
                    target="_blank"
                  >
                    <NotificationButton
                      className="float-right mb-3 py-3 mx-2 px-4"
                      theme="dark"
                    >
                      AUTO ASSIGN LOGS
                    </NotificationButton>
                  </a>
                </Col> */}
                </React.Fragment>
              )}
              {props.TripData.map((stats, idx) => (
                <Col className="mb-5 px-2" sm="12" md="3" key={idx}>
                  <InputData
                    id={`small-stats-${idx}`}
                    label={stats.label}
                    defaultValue="-"
                    value={tripData[stats.apiID]}
                  />
                </Col>
              ))}
            </Row>
            <Row noGutters>
              <Col sm="12" md="12">
                <h5
                  className="w-100 mb-5 px-2"
                  style={{
                    fontWeight: 700,
                  }}
                >
                  Rider details
                </h5>
              </Col>
              {props.RiderData.map((stats, idx) => (
                <Col className="mb-5 px-2" sm="12" md="3" key={idx}>
                  <InputData
                    id={`small-stats-${idx}`}
                    label={stats.label}
                    defaultValue="-"
                    value={valueCheck(tripData[stats.apiID])}
                  />
                </Col>
              ))}
            </Row>
            {(tripData.trip_status === "Accepted" ||
              tripData.trip_status === "Notified" ||
              tripData.trip_status === "Started") && (
              <Row noGutters>
                <Col sm="12" md="12">
                  <h5
                    className="w-100 mb-5 px-2"
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    CRN details
                  </h5>
                </Col>
                {props.CrnData.map((stats, idx) => (
                  <Col className="mb-5 px-2" sm="12" md="3" key={idx}>
                    {driverLocationData !== undefined && (
                      <InputData
                        id={`small-stats-${idx}`}
                        label={stats.label}
                        defaultValue="-"
                        value={driverLocationData[stats.apiID]}
                      />
                    )}
                  </Col>
                ))}
              </Row>
            )}

            <Row noGutters>
              <Col sm="12" md="12">
                <h5
                  className="w-100 mb-5 px-2"
                  style={{
                    fontWeight: 700,
                  }}
                >
                  Location details
                </h5>
              </Col>
              {props.TripLocationData.map((stats, idx) => (
                <Col className="mb-5 px-2" sm="12" md="6" key={idx}>
                  <InputData
                    id={`small-stats-${idx}`}
                    label={stats.label}
                    defaultValue="-"
                    value={tripData[stats.apiID]}
                  />
                </Col>
              ))}
            </Row>
            <Row noGutters>
              <Col sm="12" md="12">
                <h5
                  className="w-100 mb-5 px-2"
                  style={{
                    fontWeight: 700,
                  }}
                >
                  Fare details
                </h5>
              </Col>
              {props.FareData.map((stats, idx) => (
                <Col className="mb-5 px-2" sm="12" md="3" key={idx}>
                  <InputData
                    id={`small-stats-${idx}`}
                    label={stats.label}
                    defaultValue="-"
                    value={tripData[stats.apiID]}
                  />
                </Col>
              ))}
            </Row>
            {/* <Row noGutters>
            <Col sm="12" md="12">
              <h5 className="w-100 mb-5 px-2">Extra-fare details</h5>
            </Col>
            {props.ExtraAmount.map((stats, idx) => (
              <Col className="mb-5 px-2" sm="12" md="3" key={idx}>
                <InputExtraAmyData
                  id={`small-stats-${idx}`}
                  label={stats.label}
                  defaultValue="-"
                  value={tripData[stats.apiID]}
                  disabled={extraDisabled}
                />
              </Col>
            ))}
          </Row> */}
            {/* Display distance details only if trip has ended or cancelled */}
            {(tripData.trip_status === "Consumercancelled" ||
              tripData.trip_status === "Drivercancelled" ||
              tripData.trip_status === "Completed" ||
              tripData.trip_status === "Ended") && (
              <Row noGutters className="">
                <Col>
                  <h5
                    className="w-100 mb-5 px-2"
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    Traveled details
                  </h5>
                </Col>
                <Col className="col-12 table-responsive px-2 mb-5">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Total distance in kilometers
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Total ride duration (in H:MM)
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Extra kms traveled
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Extra kms fare
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Glory ride fare
                        </th>
                      </tr>
                      <tr>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {tripData.trip_distance === null ||
                          tripData.trip_distance === undefined
                            ? 0
                            : tripData.trip_distance}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {tripData.ride_duration === null ||
                          tripData.ride_duration === undefined
                            ? 0
                            : tripData.ride_duration}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {tripData.additional_ride_km === null ||
                          tripData.additional_ride_km === undefined
                            ? 0
                            : tripData.additional_ride_km}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {tripData.extrakmcharges === null ||
                          tripData.extrakmcharges === undefined
                            ? 0
                            : tripData.extrakmcharges}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {tripData.final_fare === null ||
                          tripData.final_fare === undefined
                            ? 0
                            : tripData.final_fare}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            )}

            {/* <InputData labe="hello" /> */}
            {(tripData.trip_status === "Requested" ||
              tripData.trip_status === "Assigned") && (
              <Col sm="12" md="12" id="googlemapslive" className="mb-3">
                <Card>
                  <CardBody>
                    <Maps
                      zoom={10}
                      lat={12.9716}
                      lng={77.5946}
                      sourceLatLng={tripData.slatlon}
                      destLatLng={tripData.dlatlon}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
            {(tripData.trip_status === "Accepted" ||
              tripData.trip_status === "Notified" ||
              tripData.trip_status === "Started") && (
              <Col sm="12" md="12" id="googlemapslive" className="mb-3">
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="12" md="9">
                        <RoadMap
                          zoom={10}
                          vehicleType={tripData.vehicle_type}
                          lat={12.9716}
                          lng={77.5946}
                          sourceLatLng={tripData.slatlon}
                          destLatLng={tripData.dlatlon}
                          dataProps={tripData}
                          vendor={tripData.booking_by}
                        />
                      </Col>
                      <Col sm="12" md="3">
                        <h5
                          style={{
                            fontWeight: 700,
                          }}
                        >
                          Ride status
                        </h5>
                        <div
                          className={classes.root}
                          style={{
                            paddingTop: ".5rem",
                          }}
                        >
                          <Stepper
                            activeStep={activeStep}
                            orientation="vertical"
                            style={{
                              paddingTop: "0px",
                              paddingBottom: "0px",
                              paddingLeft: "0px",
                            }}
                          >
                            <Step expanded>
                              <StepLabel
                                StepIconProps={{
                                  classes: {
                                    root: classes.step,
                                    active: classes.active,
                                    completed: classes.completed,
                                  },
                                }}
                              >
                                Left for pickup
                              </StepLabel>
                              {console.log(driverLocationData)}
                              <StepContent>
                                {driverLocationData !== undefined && (
                                  <Typography
                                    style={{
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    {driverLocationData.time_stamps !==
                                      undefined &&
                                      moment(
                                        new Date(
                                          driverLocationData.time_stamps.leaveforpickup_time
                                        )
                                      ).format("DD/MM/YY [at] hh:mm:ss A")}
                                  </Typography>
                                )}
                              </StepContent>
                            </Step>
                            <Step expanded>
                              <StepLabel
                                StepIconProps={{
                                  classes: {
                                    root: classes.step,
                                    completed: classes.completed,
                                    active: classes.active,
                                  },
                                }}
                              >
                                Notified
                              </StepLabel>
                              <StepContent>
                                {driverLocationData !== undefined && (
                                  <Typography
                                    style={{
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    {driverLocationData.time_stamps
                                      .notify_time !== "" &&
                                      moment(
                                        new Date(
                                          driverLocationData.time_stamps.notify_time
                                        )
                                      ).format("DD/MM/YY [at] hh:mm:ss A")}
                                  </Typography>
                                )}
                              </StepContent>
                            </Step>
                            <Step expanded>
                              <StepLabel
                                StepIconProps={{
                                  classes: {
                                    root: classes.step,
                                    completed: classes.completed,
                                    active: classes.active,
                                  },
                                }}
                              >
                                Ride started
                              </StepLabel>
                              <StepContent>
                                {driverLocationData !== undefined && (
                                  <Typography
                                    style={{
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    {driverLocationData.time_stamps
                                      .start_time !== "" &&
                                      moment(
                                        new Date(
                                          driverLocationData.time_stamps.start_time
                                        )
                                      ).format("DD/MM/YY [at] hh:mm:ss A")}
                                  </Typography>
                                )}
                              </StepContent>
                            </Step>
                            <Step expanded>
                              {/* <StepLabel
                              StepIconProps={{
                                classes: {
                                  root: classes.step,
                                  completed: classes.completed,
                                },
                              }}
                            >
                              Toll passed
                            </StepLabel> */}

                              {driverLocationData === undefined ? (
                                <StepLabel
                                  StepIconProps={{
                                    classes: {
                                      root: classes.step,
                                      completed: classes.completed,
                                      active: classes.active,
                                    },
                                  }}
                                >
                                  Toll passed
                                </StepLabel>
                              ) : driverLocationData.toll_info
                                  .toll_plaza_passed === true ||
                                driverLocationData.toll_info
                                  .toll_plaza_passed === "" ? (
                                <StepLabel
                                  StepIconProps={{
                                    classes: {
                                      root: classes.step,
                                      completed: classes.completed,
                                      active: classes.active,
                                    },
                                  }}
                                >
                                  Toll passed
                                </StepLabel>
                              ) : (
                                <StepLabel
                                  StepIconProps={{
                                    classes: {
                                      root: classes.step,
                                    },
                                  }}
                                  error={true}
                                >
                                  Toll passed
                                </StepLabel>
                              )}

                              {/* {(driverLocationData.toll_info.toll_plaza_passed ===
                              true ||
                              driverLocationData.toll_info.toll_plaza_passed ===
                                "") && (
                              <StepLabel
                                StepIconProps={{
                                  classes: {
                                    root: classes.step,
                                    completed: classes.completed,
                                    active: classes.active,
                                  },
                                }}
                              >
                                Toll passed
                              </StepLabel>
                            )} */}

                              <StepContent>
                                {/* {driverLocationData.toll_info
                                .toll_plaza_passed === true && (
                                <Typography style={{ fontSize: "0.8rem" }}>
                                  {driverLocationData.toll_info
                                    .toll_timestamp !== "" &&
                                    moment(
                                      new Date(
                                        driverLocationData.toll_info.toll_timestamp
                                      )
                                    ).format("DD/MM/YY [at] hh:mm:ss A")}
                                </Typography>
                              )} */}
                              </StepContent>
                            </Step>
                            <Step expanded>
                              <StepLabel
                                StepIconProps={{
                                  classes: {
                                    root: classes.step,
                                    completed: classes.completed,
                                    active: classes.active,
                                  },
                                }}
                              >
                                Ride completed
                              </StepLabel>
                              <StepContent>
                                {/* {console.log(driverLocationData.end_time)}
                              {driverLocationData.end_time !== "" && (
                                <Typography style={{ fontSize: "0.8rem" }}>
                                  {moment(
                                    new Date(driverLocationData.end_time)
                                  ).format("DD/MM/YY [at] hh:mm:ss A")}
                                </Typography>
                              )} */}
                              </StepContent>
                            </Step>
                          </Stepper>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )}
            {(tripData.trip_status === "Ended" ||
              tripData.trip_status === "Completed") && (
              <Col sm="12" md="12" id="googlemapslive" className="mb-3">
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="12" md="9">
                        <PolyLine
                          dataProps={tripData}
                          vendor={tripData.booking_by}
                          region={tripData.region}
                        />
                      </Col>
                      <Col sm="12" md="3">
                        <h5
                          style={{
                            fontWeight: 700,
                          }}
                        >
                          Ride status
                        </h5>
                        <div
                          className={classes.root}
                          style={{
                            paddingTop: ".5rem",
                          }}
                        >
                          <Stepper
                            activeStep={activeStep}
                            orientation="vertical"
                            style={{
                              paddingTop: "0px",
                              paddingBottom: "0px",
                              paddingLeft: "0px",
                            }}
                          >
                            <Step expanded>
                              <StepLabel
                                StepIconProps={{
                                  classes: {
                                    root: classes.step,
                                    active: classes.active,
                                    completed: classes.completed,
                                  },
                                }}
                              >
                                Left for pickup
                              </StepLabel>
                              <StepContent>
                                {driverLocationData !== undefined && (
                                  <Typography
                                    style={{
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    {driverLocationData.time_stamps !==
                                      undefined &&
                                      moment(
                                        new Date(
                                          driverLocationData.time_stamps.leaveforpickup_time
                                        )
                                      ).format("DD/MM/YY [at] hh:mm:ss A")}
                                  </Typography>
                                )}
                              </StepContent>
                            </Step>
                            <Step expanded>
                              <StepLabel
                                StepIconProps={{
                                  classes: {
                                    root: classes.step,
                                    completed: classes.completed,
                                    active: classes.active,
                                  },
                                }}
                              >
                                Notified
                              </StepLabel>
                              <StepContent>
                                {driverLocationData !== undefined && (
                                  <Typography
                                    style={{
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    {driverLocationData.time_stamps
                                      .notify_time !== "" &&
                                      moment(
                                        new Date(
                                          driverLocationData.time_stamps.notify_time
                                        )
                                      ).format("DD/MM/YY [at] hh:mm:ss A")}
                                  </Typography>
                                )}
                              </StepContent>
                            </Step>
                            <Step expanded>
                              <StepLabel
                                StepIconProps={{
                                  classes: {
                                    root: classes.step,
                                    completed: classes.completed,
                                    active: classes.active,
                                  },
                                }}
                              >
                                Ride started
                              </StepLabel>
                              <StepContent>
                                {driverLocationData !== undefined && (
                                  <Typography
                                    style={{
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    {driverLocationData.time_stamps
                                      .start_time !== "" &&
                                      moment(
                                        new Date(
                                          driverLocationData.time_stamps.start_time
                                        )
                                      ).format("DD/MM/YY [at] hh:mm:ss A")}
                                  </Typography>
                                )}
                              </StepContent>
                            </Step>
                            <Step expanded>
                              {/* <StepLabel
                              StepIconProps={{
                                classes: {
                                  root: classes.step,
                                  completed: classes.completed,
                                },
                              }}
                            >
                              Toll passed
                            </StepLabel> */}
                              {driverLocationData === undefined ? (
                                <StepLabel
                                  StepIconProps={{
                                    classes: {
                                      root: classes.step,
                                      completed: classes.completed,
                                      active: classes.active,
                                    },
                                  }}
                                >
                                  Toll passed
                                </StepLabel>
                              ) : driverLocationData.toll_info
                                  .toll_plaza_passed === true ||
                                driverLocationData.toll_info
                                  .toll_plaza_passed === "" ? (
                                <StepLabel
                                  StepIconProps={{
                                    classes: {
                                      root: classes.step,
                                      completed: classes.completed,
                                      active: classes.active,
                                    },
                                  }}
                                >
                                  Toll passed
                                </StepLabel>
                              ) : (
                                <StepLabel
                                  StepIconProps={{
                                    classes: {},
                                  }}
                                  error={true}
                                >
                                  Toll passed
                                </StepLabel>
                              )}

                              {/* {(driverLocationData.toll_info.toll_plaza_passed ===
                              true ||
                              driverLocationData.toll_info.toll_plaza_passed ===
                                "") && (
                              <StepLabel
                                StepIconProps={{
                                  classes: {
                                    root: classes.step,
                                    completed: classes.completed,
                                    active: classes.active,
                                  },
                                }}
                              >
                                Toll passed
                              </StepLabel>
                            )} */}

                              <StepContent>
                                {/* {driverLocationData.toll_info
                                .toll_plaza_passed === true && (
                                <Typography style={{ fontSize: "0.8rem" }}>
                                  {driverLocationData.toll_info
                                    .toll_timestamp !== "" &&
                                    moment(
                                      new Date(
                                        driverLocationData.toll_info.toll_timestamp
                                      )
                                    ).format("DD/MM/YY [at] hh:mm:ss A")}
                                </Typography>
                              )} */}
                              </StepContent>
                            </Step>
                            <Step expanded>
                              <StepLabel
                                StepIconProps={{
                                  classes: {
                                    root: classes.step,
                                    completed: classes.completed,
                                    active: classes.active,
                                  },
                                }}
                              >
                                Ride completed
                              </StepLabel>
                              <StepContent>
                                {driverLocationData !== undefined &&
                                  driverLocationData.time_stamps.end_time !==
                                    "" && (
                                    <Typography style={{ fontSize: "0.8rem" }}>
                                      {moment(
                                        new Date(
                                          driverLocationData.time_stamps.end_time
                                        )
                                      ).format("DD/MM/YY [at] hh:mm:ss A")}
                                    </Typography>
                                  )}
                              </StepContent>
                            </Step>
                          </Stepper>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )}

            <Row
              className="d-flex justify-content-center mt-md-5 mb-4"
              style={{
                textAlign: "-webkit-center",
              }}
            >
              {tripData.trip_status !== "Requested" && (
                <Col sm="12" md="5" className="my-2">
                  <Card className="w-75">
                    <CardBody>
                      <Row>
                        <Col
                          className="col-12 col-md-3 pr-md-2"
                          style={{
                            alignSelf: "center",
                          }}
                        >
                          <img
                            src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                            alt="Avatar"
                            style={{
                              width: "100%",
                              borderRadius: "50%",
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            className=""
                          ></img>
                        </Col>
                        <Col
                          className="col-12 col-md-9"
                          style={{
                            textAlign: "left",
                          }}
                        >
                          <FormGroup>
                            <div>
                              <h6 className="mb-0 mt-2">Assigned by</h6>
                            </div>
                            <div>
                              <h4
                                className="mb-0 mt-1"
                                style={{
                                  fontWeight: 600,
                                }}
                              >
                                {tripData.timeStamps !== undefined
                                  ? tripData.timeStamps.assigned_by
                                  : "-"}
                              </h4>
                            </div>
                            <div>
                              <p className="pt-2">
                                {tripData.timeStamps !== undefined
                                  ? tripData.timeStamps.assigned_time
                                  : "-"}
                              </p>
                            </div>
                            {/* <label>Assigned by</label>
                          {tripData.timeStamps.assigned_by !== "-" && (
                            <label
                              style={{
                                fontWeight: "800",
                                fontSize: "1rem",
                              }}
                            >
                              {" " + tripData.timeStamps.assigned_by}
                            </label>
                          )}
                          {tripData.timeStamps.assigned_by === "-" && (
                            <label>-</label>
                          )}
                          {tripData.timeStamps.assigned_by !== "-" && (
                            <label
                              style={{
                                fontWeight: "800",
                                fontSize: "1rem",
                              }}
                            >
                              {" " + tripData.timeStamps.assigned_time}
                            </label>
                          )}
                          {tripData.timeStamps.assigned_time === "-" && (
                            <label>-</label>
                          )} */}
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}
              {tripData.trip_status === "Requested" && (
                <Col sm="12" md="4" className="my-2">
                  <Card className="w-100">
                    <CardBody>
                      <Row>
                        <Col
                          className="col-12 col-md-4 pr-md-1"
                          style={{
                            alignSelf: "center",
                          }}
                        >
                          <img
                            src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                            alt="Avatar"
                            style={{
                              width: "75%",
                              borderRadius: "50%",
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            className=""
                          ></img>
                        </Col>
                        <Col
                          className="col-12 col-md-8 d-flex justify-content-center justify-content-md-start "
                          style={{
                            textAlign: "left",
                          }}
                        >
                          <FormGroup className="my-auto">
                            <p className="mb-0">
                              <label
                                className="mb-0"
                                style={{
                                  fontWeight: 600,
                                  fontSize: "1.3em",
                                }}
                              >
                                No driver assigned
                              </label>
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}

              {tripData.trip_status !== "Requested" && (
                <Col sm="12" md="5" className="my-2">
                  <Card className="w-75">
                    <CardBody>
                      <Row>
                        <p
                          style={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            padding: "5px",
                          }}
                        >
                          {" "}
                          Driver account status:
                          {beneficiaryStatus === false ? (
                            <label
                              className="mx-2"
                              style={{
                                color: "#FF0000",
                              }}
                            >
                              {" "}
                              Not verified
                            </label>
                          ) : (
                            <label
                              className="mx-2"
                              style={{ color: "#59E67F" }}
                            >
                              {" "}
                              Verified
                            </label>
                          )}
                        </p>

                        <Col
                          className="col-12 col-md-3 pr-md-2"
                          style={{
                            alignSelf: "center",
                          }}
                        >
                          <img
                            src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                            alt="Avatar"
                            style={{
                              width: "100%",
                              borderRadius: "50%",
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            className=""
                          ></img>
                        </Col>
                        <Col
                          className="col-12 col-md-9"
                          style={{
                            textAlign: "left",
                          }}
                        >
                          <FormGroup>
                            <div>
                              <h6 className="mb-0 mt-2">Driver details</h6>
                            </div>
                            <div>
                              <h4
                                className="mb-0 mt-1"
                                style={{
                                  fontWeight: 600,
                                }}
                              >
                                {tripData.driver_name === "-" ||
                                tripData.driver_name === ""
                                  ? "-"
                                  : tripData.driver_name}
                              </h4>
                            </div>
                            <p className="pt-2">
                              {tripData.vehiclenumber +
                                " - " +
                                tripData.driver_phone}
                              {/* <label className="mr-2 mb-1">
                              {tripData.vehiclenumber === ""
                                ? "-"
                                : tripData.vehiclenumber}
                            </label>
                            <label className="mx-2 mb-1">
                              {tripData.driver_phone === ""
                                ? "-"
                                : tripData.driver_phone}
                            </label> */}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                      {(tripData.trip_status === "Completed" ||
                        tripData.trip_status === "Ended") &&
                        (window.sessionStorage.getItem("roletype") ===
                          "manager" ||
                          window.sessionStorage.getItem("roletype") ===
                            "teamlead" ||
                          window.sessionStorage.getItem("roletype") ===
                            "assistantmanager") &&
                        beneficiaryStatus === true && (
                          <Row className="d-flex justify-content-center">
                            <React.Fragment>
                              <Col className="col-12 col-md-4 d-flex justify-content-center mt-1">
                                {paid_status === true && (
                                  <ViewDetailsButton
                                    variant="contained"
                                    className="py-3 w-100"
                                    color="primary"
                                    disabled
                                  >
                                    PAID
                                  </ViewDetailsButton>
                                )}
                                {paid_status === false && (
                                  <ViewDetailsButton
                                    variant="contained"
                                    className="py-3 w-100"
                                    color="primary"
                                    disabled={deactivate}
                                    onClick={openTogglePayNow}
                                  >
                                    PAY NOW
                                  </ViewDetailsButton>
                                )}
                              </Col>
                            </React.Fragment>
                          </Row>
                        )}
                    </CardBody>
                  </Card>
                </Col>
              )}
              {tripData.trip_status === "Requested" && showBox === true && (
                <Col sm="12" md="4" className="my-2">
                  <Card className="w-100">
                    <CardHeader>
                      {" "}
                      <h5 className="col-12 d-flex justify-content-center">
                        <u>Tag details</u>
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col
                          className="col-12"
                          style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Driver name
                          </label>{" "}
                          - {storeTagData.driver_name}
                        </Col>
                        <Col
                          className="col-12"
                          style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Driver phonenumber
                          </label>{" "}
                          - {storeTagData.driver_phonenumber}
                        </Col>
                        <Col
                          className="col-12"
                          style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                        >
                          <label style={{ fontWeight: "600" }}>Tagged by</label>{" "}
                          - {storeTagData.tagged_by}
                        </Col>
                        <Col
                          className="col-12"
                          style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Bank account status:
                          </label>{" "}
                          -{" "}
                          {beneficiaryStatus === false ? (
                            <label style={{ color: "#FF0000" }}>
                              {" "}
                              Not verified
                            </label>
                          ) : (
                            <label style={{ color: "#59E67F" }}>
                              {" "}
                              Verified
                            </label>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}
              {(tripData.trip_status === "Requested" ||
                tripData.trip_status === "Completed" ||
                tripData.trip_status === "Ended") &&
                showPayBox === true && (
                  <Col sm="12" md="4" className="my-2">
                    <Card className="w-100">
                      <CardHeader>
                        {" "}
                        <h5 className="col-12 d-flex justify-content-center">
                          <u>Payment details</u>
                        </h5>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col
                            className="col-12"
                            style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Beneficiary id :
                            </label>{" "}
                            - {storePayData.beneficiary_id}
                          </Col>
                          <Col
                            className="col-12"
                            style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Agent name
                            </label>{" "}
                            - {storePayData.user_name}
                          </Col>
                          <Col
                            className="col-12"
                            style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Agent ID
                            </label>{" "}
                            - {storePayData.transfer_initiatedBy}
                          </Col>
                          <Col
                            className="col-12"
                            style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                          >
                            <label style={{ fontWeight: "600" }}>Paid on</label>{" "}
                            -{" "}
                            {moment(new Date(storePayData.paid_date)).format(
                              "DD-MM-YY [at] hh:mm:ss A"
                            )}
                          </Col>
                          <Col
                            className="col-12"
                            style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Paid amount
                            </label>{" "}
                            - {storePayData.paid_amount}
                          </Col>
                          <Col
                            className="col-12"
                            style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Payment mode
                            </label>{" "}
                            - {storePayData.payment_mode}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )}
            </Row>
            {tripData.trip_status !== "Requested" && (
              <Row noGutters className="">
                <Col className="col-12">
                  <h5
                    className="w-100 mb-5 px-2"
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    Ride issues
                  </h5>
                </Col>
                {reviewList !== undefined && reviewList.length > 0 && (
                  <Col className="col-12 mb-5">
                    <Row>
                      <Col className="col-12">
                        <DataTableBase
                          columns={issueTable}
                          datas={reviewList}
                          types="reviewtable"
                        />
                      </Col>
                    </Row>
                  </Col>
                )}

                <Col>
                  <form onSubmit={handleReportApi}>
                    <Row className="d-flex justify-content-center mb-5">
                      <Col className="col-12 col-md-4">
                        <InputExtraAmyData
                          id="issue"
                          label="Report an issue"
                          placeholder="Enter the type of issue"
                          onChange={handleReportIssue}
                          value={issueTitle}
                          type="text"
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-center mb-5">
                      <Col className="col-12 col-md-6">
                        <InputArea
                          id="issue"
                          label="Description"
                          placeholder="Please provide a description of the issue"
                          onChange={handleReportIssue}
                          value={issueTitle}
                          type="text"
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-center mb-5">
                      <Col className="col-12 col-md-3 d-flex justify-content-center">
                        <ViewDetailsButton
                          variant="contained"
                          className="py-3 w-75"
                          color="primary"
                        >
                          REPORT ISSUE
                        </ViewDetailsButton>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </Row>
            )}
            <Row noGutters>
              <Col className="col-12">
                <h5
                  className="w-100 mb-5 px-2"
                  style={{
                    fontWeight: 700,
                  }}
                >
                  Fines and penalty
                  {editable === true && (
                    <MatButton
                      startIcon={<EditIcon />}
                      onClick={() => setEditable(false)}
                    />
                  )}
                  {editable === false && (
                    <React.Fragment>
                      <MatButton
                        startIcon={<Close />}
                        onClick={() => setEditable(true)}
                      />
                      <MatButton startIcon={<Save />} oncClick={saveEdit} />
                    </React.Fragment>
                  )}
                </h5>
              </Col>
              <Col>
                <form>
                  <Row className="mb-5">
                    <Col className="col-12 col-md-4 mb-4">
                      <FineInputExtraAmyData
                        id="offered_amt"
                        label="Offered amount"
                        placeholder={tripData.offeredAmount}
                        value={offeredAmount}
                        onChange={handleOfferedAmtChange}
                        type="text"
                        required
                        readOnly={editable}
                      />
                    </Col>
                    <Col className="col-12 col-md-4 mb-4">
                      <FineInputExtraAmyData
                        id="extraride_amt"
                        label="Extra ride amount"
                        placeholder={tripData.ride_bonus}
                        onChange={handleExtrarideAmount}
                        value={fineExtrarideAmount}
                        type="text"
                        required
                        readOnly={editable}
                      />
                    </Col>
                    <Col className="col-12 col-md-4 mb-4">
                      <FineInputExtraAmyData
                        id="extratrav_kms"
                        label="Extra traveled kms"
                        placeholder={tripData.extra_traveled_kms}
                        onChange={handleExtraTraveledKms}
                        value={fineExtraTraveledKms}
                        type="text"
                        required
                        readOnly={editable}
                      />
                    </Col>
                    <Col className="col-12 col-md-4 mb-4">
                      <FineInputExtraAmyData
                        id="empty_kms"
                        label="Empty kms"
                        placeholder={tripData.empty_kms}
                        onChange={handleExtrakms}
                        value={fineEmptyKms}
                        type="text"
                        required
                        readOnly={editable}
                      />
                    </Col>

                    <Col className="col-12 col-md-4 mb-4">
                      <FineInputExtraAmyData
                        id="fine_amt"
                        label="Fine amount"
                        placeholder={tripData.fineAmount}
                        onChange={handleFineAmount}
                        value={fineAmount}
                        type="text"
                        required
                        readOnly={editable}
                      />
                    </Col>
                    <Col className="col-12 col-md-4 mb-4">
                      <FineInputArea
                        id="fine_reason"
                        label="Fine reason"
                        placeholder={tripData.fineReason}
                        onChange={handleFineReason}
                        value={fineReason}
                        type="text"
                        required
                        readOnly={editable}
                      />
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row className="d-flex justify-content-center">
              {(tripData.trip_status === "Assigned" ||
                tripData.trip_status === "Accepted" ||
                tripData.trip_status === "Notified") && (
                <Col
                  sm="12"
                  md="3"
                  className="mb-4 d-flex justify-content-center"
                >
                  <ViewDetailsButton
                    variant="contained"
                    className="py-3 w-75"
                    color="primary"
                    onClick={toggleReassign}
                  >
                    RE-ASSIGN DRIVER
                  </ViewDetailsButton>
                </Col>
              )}
              {tripData.trip_status === "Requested" && (
                <Col
                  sm="12"
                  md="3"
                  className="mb-4 d-flex justify-content-center"
                >
                  <ViewDetailsButton
                    variant="contained"
                    className="py-3 w-75"
                    color="primary"
                    onClick={() => openAssignModal(tripData)}
                  >
                    ASSIGN DRIVER
                  </ViewDetailsButton>
                </Col>
              )}

              {(tripData.trip_status === "Requested" ||
                tripData.trip_status === "Assigned" ||
                tripData.trip_status === "Accepted" ||
                tripData.trip_status === "Notified") && (
                <Col
                  sm="12"
                  md="3"
                  className="mb-4 d-flex justify-content-center"
                >
                  <ViewDetailsButton
                    variant="contained"
                    className="py-3 w-75"
                    color="primary"
                    onClick={() => openCancelModal(tripData)}
                  >
                    CANCEL RIDE
                  </ViewDetailsButton>
                </Col>
              )}

              {tripData.trip_status === "Requested" && showTagging === true && (
                <Col
                  sm="12"
                  md="3"
                  className="mb-4 d-flex justify-content-center"
                >
                  <ViewDetailsButton
                    variant="contained"
                    className="py-3 w-75"
                    color="primary"
                    onClick={openTagDriver}
                  >
                    TAG DRIVER
                  </ViewDetailsButton>
                </Col>
              )}
            </Row>
          </CardFooter>
        </Card>
      )}

      {openissue === true && (
        <RideIssues data={passData} enableRideIssue={enableRideIssue} />
      )}

      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorOpen}
        autoHideDuration={3000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

BookingDetails.propTypes = {
  TripData: PropTypes.array,
};
BookingDetails.defaultProps = {
  MapDefaults: [
    {
      center: {
        lat: 59.95,
        lng: 30.33,
      },
      zoom: 11,
    },
  ],
  TripData: [
    {
      label: "Ride ID",
      value: "",
      attrs: "",
      apiID: "trip_id",
    },

    {
      label: "Region",
      value: "",
      attrs: "",
      apiID: "region",
    },

    {
      label: "Ride status",
      value: "",
      attrs: "",
      apiID: "trip_status",
    },
    {
      label: "Ride type",
      value: "",
      attrs: "",
      apiID: "ride_type",
    },
    {
      label: "Departure time",
      value: "",
      attrs: "",
      apiID: "pickup_time",
    },
  ],
  RiderData: [
    {
      label: "Rider name",
      value: "",
      attrs: "",
      apiID: "rider_name",
    },
    {
      label: "Rider mobile number",
      value: "",
      attrs: "",
      apiID: "rider_phone",
    },
    {
      label: "Rider email",
      value: "",
      attrs: "",
      apiID: "email",
    },
  ],
  CrnData: [
    {
      label: "Start CRN",
      value: "",
      attrs: "",
      apiID: "crn_number",
    },
    {
      label: "End CRN",
      value: "",
      attrs: "",
      apiID: "endcrn_number",
    },
  ],
  TripLocationData: [
    {
      label: "Pick-up location",
      value: "",
      attrs: "",
      apiID: "sname",
    },
    {
      label: "Drop-off location",
      value: "",
      attrs: "",
      apiID: "dname",
    },
  ],
  VehicleData: [
    {
      label: "Vehicle type",
      value: "",
      attrs: "",
      apiID: "vehicle_type",
    },
  ],
  TripStatusData: [
    {
      label: "Ride status",
      value: "",
      attrs: "",
      apiID: "trip_status",
    },
    {
      label: "Ride type",
      value: "",
      attrs: "",
      apiID: "ride_type",
    },
    {
      label: "Departure time",
      value: "",
      attrs: "",
      apiID: "pickup_time",
    },
  ],
  FareData: [
    {
      label: "Vehicle type",
      value: "",
      attrs: "",
      apiID: "vehicle_type",
    },
    {
      label: "Est. distance(kms)",
      value: "",
      attrs: "",
      apiID: "distance",
    },
    {
      label: "Approx fare",
      value: "",
      attrs: "",
      apiID: "approx_fare",
    },
    {
      label: "Amount to collect",
      value: "",
      attrs: "",
      apiID: "balanceAmount",
    },
  ],
  CancelModData: [
    {
      label: "Ride ID",
      value: "",
      attrs: "",
      apiID: "trip_id",
    },
    {
      label: "Vendor ID",
      value: "",
      attrs: "",
      apiID: "vendor_id",
    },
    {
      label: "Pickup time",
      value: "",
      attrs: "",
      apiID: "pickup_time",
    },
  ],
  DetachModData: [
    {
      label: "Phonenumber",
      value: "",
      attrs: "",
      apiID: "tagPhonenumber",
    },
  ],
  SorDesData: [
    {
      label: "From",
      value: "",
      attrs: "",
      apiID: "sname",
    },
    {
      label: "To",
      value: "",
      attrs: "",
      apiID: "dname",
    },
  ],
  DriverData: [
    {
      label: "Driver name",
      value: "",
      attrs: "",
      apiID: "driver_name",
    },
    {
      label: "Driver Phonenumber",
      value: "",
      attrs: "",
      apiID: "driver_phone",
    },
  ],
  DriverLocation: [
    {
      label: "Start CRN",
      value: "",
      attrs: "",
      apiID: "crn_number",
    },
    {
      label: "End CRN",
      value: "",
      attrs: "",
      apiID: "endcrn_number",
    },
  ],
  ExtraAmount: [
    {
      label: "Offered amount",
      value: "",
      attrs: "",
      apiID: "offeredAmount",
    },
    {
      label: "Extra travelled kms",
      value: "",
      attrs: "",
      apiID: "extra_traveled_kms",
    },
    {
      label: "Empty kms",
      value: "",
      attrs: "",
      apiID: "empty_kms",
    },
    {
      label: "Fine amount",
      value: "",
      attrs: "",
      apiID: "fineAmount",
    },
    {
      label: "Fine reason",
      value: "",
      attrs: "",
      apiID: "fineReason",
    },
  ],
  DriverPayDatas: [
    {
      label: "Driver name",
      value: "",
      attrs: "",
      apiID: "driver_name",
    },
    {
      label: "Driver phonenumber",
      value: "",
      attrs: "",
      apiID: "driver_phone",
    },
  ],
  DriverPayData: [
    {
      label: "Driver name",
      value: "",
      attrs: "",
      apiID: "driver_name",
    },
    {
      label: "Driver phonenumber",
      value: "",
      attrs: "",
      apiID: "driver_phonenumber",
    },
  ],
};
