import React, { Component } from "react";
import PropTypes from "prop-types";
import * as config from "../../constants/constants.json";
import moment from "moment";
import _ from "lodash";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  FormCheckbox,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormTextarea,
} from "shards-react";

class TripDump extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataDump: [],
      cancelInfo: [],
      vendorResponse: [],
      tollInfo: [],
      vendorRequest: [],
    };
  }

  componentDidMount() {
    fetch(config.url + "htmlcrm/getTripDebugInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        trip_id: this.props.tripId,
        // trip_id: "10E04WswJpuWZwJq",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          console.log(result);
          let data = _.concat(result.vendor_response, result.vendor_request);
          let sorted_data = _.orderBy(data, ["timestamp"], ["asc"]);
          // console.log(data);
          console.log(result.cancel_data);
          this.setState({
            dataDump: result,
            cancelInfo: result.cancel_data,
            vendorResponse: sorted_data,
            vendorRequest: result.vendor_request,
            tollInfo: result.tollInfo,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  render() {
    // console.log(this.state.cancelInfo.cancel_time);
    return (
      <Row className="px-md-2 mb-5">
        <Col className="col-md-12 col-12 px-0 px-md-3">
          <Card className="my-4 mx-1 px-md-4 ">
            <CardHeader className="my-4 text-center font-weight-bold">
              <h6
                style={{
                  fontWeight: "800",
                  fontSize: "1.2rem",
                  color: "black",
                }}
              >
                Trip debug info
                <button
                  type="button"
                  className="close float-right ml-auto mr-3"
                  aria-label="Close"
                  onClick={this.props.enableTripDump}
                >
                  <span aria-hidden="true">
                    <i class="fas fa-times" />
                  </span>
                </button>
              </h6>
            </CardHeader>
            <CardBody>
              <Row>
                <Col className="col-12 col-md-3 py-1 mb-3">
                  <div className="mb-2" style={{ fontWeight: "400" }}>
                    Trip accept at
                  </div>
                  <div
                    style={{ fontWeight: "900" }}
                    className="trip-debug-title-response"
                  >
                    {this.state.dataDump.accept_time === undefined ||
                    this.state.dataDump.accept_time === ""
                      ? "N/A"
                      : moment(
                          new Date(this.state.dataDump.accept_time)
                        ).format("DD-MM-YY [at] hh:mm:ss A")}
                  </div>
                </Col>
                <Col className="col-12 col-md-3 py-1 mb-4">
                  <div className="mb-2" style={{ fontWeight: "400" }}>
                    Left for pickup
                  </div>
                  <div
                    style={{ fontWeight: "900" }}
                    className="trip-debug-title-response"
                  >
                    {this.state.dataDump.leaveforpickup_time === undefined ||
                    this.state.dataDump.leaveforpickup_time === ""
                      ? "N/A"
                      : moment(
                          new Date(this.state.dataDump.leaveforpickup_time)
                        ).format("DD-MM-YY [at] hh:mm:ss A")}
                  </div>
                </Col>
                <Col className="col-12 col-md-3 py-1  mb-4">
                  <div className="mb-2" style={{ fontWeight: "400" }}>
                    Notified
                  </div>
                  <div
                    style={{ fontWeight: "900" }}
                    className="trip-debug-title-response"
                  >
                    {this.state.dataDump.notify_time === undefined ||
                    this.state.dataDump.notify_time === ""
                      ? "N/A"
                      : moment(
                          new Date(this.state.dataDump.notify_time)
                        ).format("DD-MM-YY [at] hh:mm:ss A")}
                  </div>
                </Col>
                <Col className="col-12 col-md-3 py-1 mb-4">
                  <div className="mb-2" style={{ fontWeight: "400" }}>
                    Ride started at
                  </div>
                  <div
                    style={{ fontWeight: "900" }}
                    className="trip-debug-title-response"
                  >
                    {this.state.dataDump.start_time === undefined ||
                    this.state.dataDump.start_time === ""
                      ? "N/A"
                      : moment(new Date(this.state.dataDump.start_time)).format(
                          "DD-MM-YY [at] hh:mm:ss A"
                        )}
                  </div>
                </Col>
                <Col className="col-12 col-md-3 py-1  mb-4">
                  <div className="mb-2" style={{ fontWeight: "400" }}>
                    Ride completed at
                  </div>
                  <div
                    style={{ fontWeight: "900" }}
                    className="trip-debug-title-response"
                  >
                    {this.state.dataDump.end_time === undefined ||
                    this.state.dataDump.end_time === ""
                      ? "N/A"
                      : moment(new Date(this.state.dataDump.end_time)).format(
                          "DD-MM-YY [at] hh:mm:ss A"
                        )}
                  </div>
                </Col>
                <Col className="col-12 col-md-3 py-1 mb-4">
                  <div className="mb-2" style={{ fontWeight: "400" }}>
                    Passed toll at
                  </div>
                  <div
                    style={{ fontWeight: "900" }}
                    className="trip-debug-title-response"
                  >
                    {this.state.tollInfo === undefined ||
                    this.state.tollInfo.toll_timestamp === undefined ||
                    this.state.tollInfo.toll_timestamp === ""
                      ? "N/A"
                      : moment(new Date(this.state.dataDump.end_time)).format(
                          "DD-MM-YY [at] hh:mm:ss A"
                        )}
                  </div>
                </Col>
                {this.state.cancelInfo !== undefined && (
                  <React.Fragment>
                    <Col className="col-12 col-md-3 py-1 mb-4">
                      <div className="mb-2" style={{ fontWeight: "400" }}>
                        Ride cancelled at
                      </div>
                      <div
                        style={{ fontWeight: "900" }}
                        className="trip-debug-title-response"
                      >
                        {this.state.cancelInfo.cancel_time === undefined ||
                        this.state.cancelInfo.cancel_time === ""
                          ? "N/A"
                          : moment(
                              new Date(this.state.cancelInfo.cancel_time)
                            ).format("DD-MM-YY [at] hh:mm:ss A")}
                      </div>
                    </Col>
                    <Col className="col-12 col-md-3 py-1 mb-4">
                      <div className="mb-2" style={{ fontWeight: "400" }}>
                        Ride cancelled by
                      </div>
                      <div
                        style={{ fontWeight: "900" }}
                        className="trip-debug-title-response"
                      >
                        {this.state.cancelInfo.cancelled_by === undefined ||
                        this.state.cancelInfo.cancelled_by === "" ||
                        this.state.cancelInfo.cancelled_by === null
                          ? "N/A"
                          : this.state.cancelInfo.cancelled_by}
                      </div>
                    </Col>
                  </React.Fragment>
                )}
              </Row>
              <hr></hr>
              <Row className="px-md-2">
                <Col className="col-12 col-md-12">
                  <Row className="mt-3">
                    <Col className="col-12 col-md-12 my-3 d-flex justify-content-center">
                      <h6
                        style={{
                          fontWeight: "800",
                          fontSize: "1.2rem",
                          color: "black",
                        }}
                      >
                        API response
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12 col-md-12 my-3">
                      <Row style={{ maxHeight: "400px", overflowY: "auto" }}>
                        {this.state.vendorResponse.map((data, index) => (
                          <Col key={index} className="col-12 mb-4">
                            <div className="row ">
                              <Col className="col-2 trip-debug-title d-flex justify-content-end">
                                Ride status:{" "}
                              </Col>
                              <Col className="col-10 trip-debug-title-response d-flex justify-content-start">
                                {data.api}
                              </Col>
                            </div>
                            <div className="row ">
                              <Col className="col-2 trip-debug-title d-flex justify-content-end">
                                API response:
                              </Col>
                              <Col className=" col-10 trip-debug-title-response d-flex justify-content-start">
                                {data === undefined
                                  ? "N/A"
                                  : JSON.stringify(data.response)}
                              </Col>
                            </div>
                            <div className="row ">
                              <Col className="col-2 trip-debug-title d-flex justify-content-end">
                                Error:
                              </Col>
                              <Col className="col-10 trip-debug-title-response d-flex justify-content-start">
                                {data.error === undefined ||
                                data.error === "" ||
                                data.error === null
                                  ? "N/A"
                                  : data.error}
                              </Col>
                            </div>
                          </Col>
                        ))}
                        {/* {this.state.vendorRequest.map((data, index) => (
                          <Col key={index} className="col-12 mb-4">
                            <div className="row">
                              <Col className="col-2 trip-debug-title d-flex justify-content-end">
                                Ride status:{" "}
                              </Col>
                              <span className="col-10 trip-debug-title-response d-flex justify-content-start">
                                {data.api}
                              </span>
                            </div>
                            <div className="row">
                              <Col className="col-2 trip-debug-title d-flex justify-content-end">
                                API response:
                              </Col>
                              <Col className="col-10 trip-debug-title-response d-flex justify-content-start">
                                {data === undefined
                                  ? "N/A"
                                  : JSON.stringify(data.response)}
                              </Col>
                            </div>
                            <div className="row">
                              <Col className="col-2 trip-debug-title d-flex justify-content-end">
                                Error:
                              </Col>
                              <Col className="col-10 trip-debug-title-response d-flex justify-content-start">
                                {data.error === undefined ||
                                data.error === "" ||
                                data.error === null
                                  ? "N/A"
                                  : data.error}
                              </Col>
                            </div>
                          </Col>
                        ))} */}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default TripDump;
