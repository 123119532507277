import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Card, CardBody } from "shards-react";

export default function CountCards(props) {
  /**Get display box values */
  const { variation, label, value, stats, display } = props;

  /**Custom classnames for displaybox */
  const cardClasses = classNames(
    "stats-small",
    variation && `stats-small--${variation}`
  );

  const cardBodyClasses = classNames(
    variation === "1" ? "p-0 d-flex" : "px-0 pb-0"
  );

  const innerWrapperClasses = classNames(
    "d-flex",
    variation === "1" ? "flex-column m-auto" : "px-3"
  );

  const dataFieldClasses = classNames(
    "stats-small__data",
    variation === "1" && "text-center"
  );

  const labelClasses = classNames(
    "stats-small__label",
    "text-uppercase",
    variation !== "1" && "mb-1"
  );

  const valueClasses = classNames(
    "displayBox",
    "count",
    variation === "1" ? "mt-3 mb-0" : "m-0"
  );

  const innerDataFieldClasses = classNames(
    "stats-small__data",
    variation !== "1" && "text-right align-items-center"
  );

  //   const canvasHeight = variation === "1" ? 120 : 60;

  return (
    <Card small className={cardClasses} id={props.id}>
      <CardBody className={cardBodyClasses}>
        <div className={innerWrapperClasses}>
          <span className={labelClasses} style={{ fontSize: "0.65rem" }}>
            {label}
          </span>
          <div className={dataFieldClasses}>
            <h6 className={valueClasses}>{value}</h6>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

CountCards.propTypes = {
  /**
   * The Small Stats variation.
   */
  variation: PropTypes.string,
  /**
   * The label.
   */
  label: PropTypes.string,
  /**
   * The value.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Flag to differentiate stat box
   */
  stats: PropTypes.bool,
  /**
   * Toggle label
   */
  display: PropTypes.bool,
};

CountCards.defaultProps = {
  value: 0,
  label: "Label",
  stats: "",
};
