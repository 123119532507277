import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(5),
    marginTop: "2rem",
    background: "none",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  step: {
    "&$active": {
      color: "#07060a",
    },
    "&$completed": {
      color: "#07060a",
    },

    "&$disabled": {
      color: "#07060a",
    },
    "&$error": {
      color: "#07060a",
    },
  },
  completed: {
    color: "#07060a",
  },
  active: {
    color: "#07060a",
  },
}));
