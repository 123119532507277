import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormTextarea,
  FormGroup,
} from "shards-react";
import ReactTable from "react-table-v6";
import ReactSnackBar from "react-js-snackbar";
import _ from "lodash";
import "react-table-v6/react-table.css";
import PageTitle from "../components/common/PageTitle";
import * as config from "../constants/constants.json";

function TagRidesList(props) {
  console.log(props);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [approve, setApproveModal] = useState(false);
  const [reject, setRejectModal] = useState(false);
  const [approveData, setApproveData] = useState([]);
  const [rejectData, setRejectData] = useState([]);
  const [Show, setShow] = useState(false);
  const [Showing, setShowing] = useState(false);
  const [message, setMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [rejectReason, setRejectReason] = useState(undefined);
  //   const subDataToDisplay = [];

  const show = () => {
    if (Showing) return;
    setShow(true);
    setShowing(true);
    // this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      setShow(false);
      setShowing(false);
      //   this.setState({ Show: false, Showing: false });
    }, 2000);
  };

  const approveClick = (data) => {
    setApproveModal(!approve);
    setApproveData(data);
  };

  const rejectClick = (data) => {
    setRejectModal(!reject);
    setRejectData(data);
  };

  const tagRideList = [
    {
      Header: "Sl no",
      id: "serialno",
      style: {},
      Cell: (row) => {
        // console.log(row);
        return <div>{row.viewIndex + 1}</div>;
      },
    },
    {
      Header: "Ride ID",
      accessor: "trip_id",
    },
    {
      Header: "Source",
      accessor: "sname",
    },
    {
      Header: "Destination",
      accessor: "dname",
    },
    {
      Header: "Pick up time",
      accessor: "time",
    },
    {
      id: "driver_name",
      Header: "Driver details",
      accessor: "driver_name",
      Cell: (row) => {
        return (
          <div>
            {row.original.driver_name + " - " + row.original.driver_phonenumber}
          </div>
        );
      },
    },
    {
      Header: "Actions",
      Cell: (row) => {
        return (
          <div className="d-flex flex-column">
            <Button
              className="mx-2 my-2  table-button-style"
              theme="dark"
              onClick={() => {
                approveClick(row.original);
              }}
            >
              APPROVE
            </Button>
            <Button
              className="mx-2 my-2  table-button-style"
              theme="dark"
              onClick={() => {
                rejectClick(row.original);
              }}
            >
              REJECT
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    _preloadAPI();
  }, []);

  const _preloadAPI = () => {
    let dataToDisplay = {
      user_id: sessionStorage.getItem("user_id"),
    };
    axios
      .post(
        config.url + "payout/getDriverTaggedRideListForApproval",
        dataToDisplay
      )
      .then((response) => {
        if (response.data.message === "successfull") {
          setDataToDisplay(response.data.data);
        } else {
          setDataToDisplay([]);
        }
      });
  };

  const callAPI = (e, type) => {
    // if (e) {
    //   e.preventDefault();
    // }
    let dataToSend;
    if (type === "Approved") {
      dataToSend = {
        approved_by: sessionStorage.getItem("user_id"),
        trip_id: approveData.trip_id,
        status: type,
      };
    } else {
      dataToSend = {
        approved_by: sessionStorage.getItem("user_id"),
        trip_id: rejectData.trip_id,
        status: type,
        reason: rejectReason,
      };
    }
    console.log(dataToSend);
    if (
      type === "rejected" &&
      (rejectReason === undefined || rejectReason === null)
    ) {
      setSuccess(false);
      setMessage("Reason cannot be empty");
      show();
    } else {
      axios
        .post(config.url + "payout/approveRequest", dataToSend)
        .then((response) => {
          console.log(response);
          if (response.data.message === "successfull") {
            type === "Approved"
              ? setApproveModal(false)
              : setRejectModal(false);

            setSuccess(true);
            setMessage(
              type === "Approved"
                ? "Request has been approved"
                : "Request has been rejected"
            );
            show();
            _preloadAPI();
          } else {
            setSuccess(false);
            setMessage(
              type === "Approved"
                ? "Failed to approve request"
                : "Failed to reject request"
            );
            show();
          }
        });
    }
  };

  const reason = (e) => {
    setRejectReason(e.target.value);
  };

  const goBack = () => {
    // this.props.history.goBack();
    props.history.goBack();
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4 px-3">
        <PageTitle
          title="Tagged rides list"
          subtitle="Tagged rides"
          className="text-sm-left mb-3"
        />
      </Row>
      <Row noGutters className="page-header py-4 px-3">
        <Col className="col-12 col-md-12 col-lg-12">
          <Button
            className=" float-right"
            id="goback-button"
            theme="dark"
            onClick={goBack}
          >
            <i className="fas fa-arrow-left mx-1" />
            <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
              BACK
            </span>{" "}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ReactTable
                data={dataToDisplay}
                noDataText="No data to display"
                columns={tagRideList}
                className="text-center -striped -highlight"
                minRows={dataToDisplay.length > 0 ? 0 : 4}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Modal open={approve} backdrop size="md" centered>
          <ModalHeader>Approve tagging</ModalHeader>
          <ModalBody>
            <label style={{ fontSize: "calc(0.5rem + 1vmin)" }}>
              Do you want to approve this request ?
            </label>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col className="col-12">
                <Button
                  className="mx-2  table-button-style"
                  theme="danger"
                  onClick={(e) => setApproveModal(!approve)}
                >
                  CANCEL
                </Button>
                <Button
                  className="mx-2 table-button-style"
                  onClick={(e) => {
                    callAPI(e, "Approved");
                  }}
                >
                  CONFIRM
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Modal open={reject} backdrop size="md" centered>
          <ModalHeader>Reject tagging</ModalHeader>
          <ModalBody>
            <label style={{ fontSize: "calc(0.5rem + 1vmin)" }}>
              Do you want to reject this request ?
            </label>
            <FormGroup>
              {/* <label>Reason for cancellation</label> */}
              <FormTextarea
                value={rejectReason}
                placeholder="Reason for cancellation of request"
                onChange={reason}
                minRows="4"
              ></FormTextarea>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col className="col-12">
                <Button
                  className="mx-2  table-button-style"
                  theme="danger"
                  onClick={(e) => setRejectModal(!reject)}
                >
                  CANCEL
                </Button>
                <Button
                  className="mx-2 table-button-style"
                  onClick={(e) => {
                    callAPI(e, "rejected");
                  }}
                >
                  CONFIRM
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </Row>
      {success === false && (
        <ReactSnackBar
          Icon={
            <i
              className="far fa-times-circle fa-lg"
              style={{ color: "#FF0000" }}
            />
          }
          Show={Show}
        >
          {message}
        </ReactSnackBar>
      )}
      {success === true && (
        <ReactSnackBar
          Icon={
            <span>
              <i
                className="far fa-check-circle fa-lg"
                style={{ color: "#26FF58" }}
              />
            </span>
          }
          Show={Show}
        >
          {message}
        </ReactSnackBar>
      )}
    </Container>
  );
}

export default TagRidesList;
