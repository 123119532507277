import React from "react";
import { FormInput, Col, Row } from "shards-react";

export default class SearchTrackerData extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      filteredData: [],
      columnSearchNow: "",
      searchInput: "",
    };
  }

  handleChange = (event) => {
    // const val = event.target.value;
    // console.log(val)
    // this.setState({ searchInput: val }, () => this.globalSearch());
    // this.props.handleSetSearchInput(val);
    const val = event.target.value;
    // console.log(val)
    this.setState({ searchInput: val }, () => this.globalSearch());
    // console.log(val)
    if (this.props.handleSetSearchInput === undefined) {
      // Do nothing
    } else {
      this.props.handleSetSearchInput(val);
    }
  };

  globalSearch = () => {
    const { searchInput, columnSearchNow } = this.state;
    // console.log(searchInput);
    let filteredData = this.props.data.filter((value) => {
      if (columnSearchNow) {
        return value[columnSearchNow]
          .toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      }
      // console.log(value.phonenumber)
      return (
        value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.phonenumber
          .toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        value.location.toLowerCase().includes(searchInput.toLowerCase())
        // value.vehicle_number.toLowerCase().includes(searchInput.toLowerCase())
      );
    });

    this.props.handleSetFilteredData(filteredData);
  };

  setColumnSearch = (e) => {
    this.setState({ columnSearch: e.target.value }, () => this.globalSearch());
  };

  render() {
    // const { columnSearch } = this.props;
    // const { columnSearchNow } = this.state;

    return (
      <Row>
        <Col className="col-12 col-md-4">
          <FormInput
            size="large"
            name="searchInput"
            value={this.state.searchInput || ""}
            onChange={this.handleChange}
            placeholder="Enter name or phone number"
          />
        </Col>
      </Row>
    );
  }
}
