import React, { useState, useEffect } from "react";
import { Card, FormGroup, Row, Col, Button } from "react-bootstrap";
import { FormInput } from "shards-react";
import Loader from "react-loader-spinner";
import axios from "axios";
import querystring from "query-string";
import emailValidator from "validator/lib/isEmail";
import { Toast } from "react-bootstrap";
import { Animated } from "react-animated-css";
/**
 * Import files and images
 */

import logo from "../assets/logo.svg";
import * as animationData from "../assets/spinner.json";
import LoginImg from "../assets/loginArt.svg";
import stringsConstant from "../constants/stringsConstant.json";
import config from "../constants/constants.json";
import urls from "../constants/urlList.json";
import Cancel from "../assets/cancel.svg";
import Check from "../assets/check.svg";

function FirstUser(props) {
  const [verified, setVerified] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [reEnterPass, setRePassword] = useState("");
  const [name, setName] = useState("");
  const [resetTime, setResetTime] = useState(false);
  const [success, setSuccess] = useState("");

  /**
   * Handle success animation
   */
  const handleSuccess = () => {
    setSuccess(true);
    setResetTime(true);
    setTimeout(() => {
      setResetTime(false);
      loadLoginPage();
    }, 2000);
  };

  /**
   * Load home page
   */

  const loadLoginPage = () => {
    setTimeout(() => {
      window.location.assign("/");
    }, 2500);
  };

  /**
   * Handle failure animation
   */

  const handleFailure = () => {
    setSuccess(false);
    setResetTime(true);
    setTimeout(() => {
      setResetTime(false);
      // setClicked(false);
    }, 2000);
  };

  /**
   * Function to load reset screen if query string exists
   */

  useEffect(() => {
    if (querystring.parse(props.location.search).avatar !== undefined) {
      setEmail(querystring.parse(props.location.search).avatar);
      setVerified(true);
    }
  }, []);

  /**
   * Handle user input
   */

  const handlePassInput = (e, type) => {
    switch (type) {
      case stringsConstant.newPass:
        setNewPassword(e.target.value);
        break;
      case stringsConstant.rePass:
        setRePassword(e.target.value);
        break;
      case stringsConstant.email:
        setEmail(e.target.value);
        break;
      default:
        break;
    }
  };

  /**
   * API to verify email
   */

  const verifyUseEmail = () => {
    // console.log("----------------------------------");
    if (!emailValidator(email)) {
      setValidEmail(false);
    } else {
      let dataTosend = {
        email,
      };

      axios
        .post(config.url + urls.emailConfirmation, dataTosend)
        .then((response) => {
          // console.log(response);
          if (
            response.data.message === stringsConstant.onsuccess &&
            response.data.firsttime === true
          ) {
            setVerified(true);
            setName(response.data.name);
          } else if (response.data.message === stringsConstant.onunsuccess) {
            // Handle unsuccessful event
            // setVerified(false);
            setValidEmail(false);
          }
        });
    }
  };

  /**
   * API to handle password reset
   */

  const sendResetPass = () => {
    if (newPassword !== reEnterPass) {
    } else {
      let dataTosend = {
        email,
        password: newPassword,
      };

      axios.post(config.url + urls.resetPass, dataTosend).then((response) => {
        // console.log(response);
        if (response.data.message === stringsConstant.onsuccess) {
          handleSuccess();
          // setTimeout(() => {
          //   window.location.assign("/");
          // }, 2000);
        } else {
          handleFailure();
        }
      });
    }
  };
  // console.log(email);
  return (
    <Row className="login-custom">
      <Col
        className="col-12 col-md-5 ipad-spec-main"
        style={{ backgroundColor: "#F1F5FB" }}
      >
        <Row className="d-flex justify-content-center custom-row-height">
          <Col className="col-10 col-md-6 col-lg-8 my-auto custom-padding">
            <Card className="login-card">
              {verified === false && (
                <Card.Body>
                  <Row className="px-2 my-auto">
                    {/* <Col className="col-12 d-flex justify-content-center ">
                      <label className="login-image">Glory</label>
                    </Col> */}
                    <Col className="col-12 d-flex justify-content-center mb-4 mt-2">
                      {/* Test as img is not availabel */}
                      {/* <label className="login-image">Glory</label> */}
                      <img src={logo} height="auto" width="125"></img>
                    </Col>
                    <Col className="col-12 d-flex justify-content-center  mb-4">
                      <span className="login-text-new">
                        Enter the below details to continue
                      </span>
                    </Col>
                    <Col className="col-12 mb-5">
                      <FormGroup>
                        <label className="login-text-label px-2">
                          Email address
                        </label>
                        <FormInput
                          className="py-3"
                          placeholder="Enter your email"
                          value={email}
                          invalid={!validEmail}
                          onChange={(e) =>
                            handlePassInput(e, stringsConstant.email)
                          }
                        />
                        {!validEmail && (
                          <label className="login-invalid-msg px-2">
                            Entered email ID is invalid
                          </label>
                        )}
                      </FormGroup>
                    </Col>
                    {/* <Col className="col-12 mb-4">
                      <FormGroup>
                        <label className="login-text-label">Password</label>
                        <FormInput className="py-3" />
                      </FormGroup>
                    </Col> */}
                    <Col className="col-12 mb-4 mt-5"></Col>
                    <Col className="col-12 d-flex justify-content-center mb-2 mt-5">
                      <Button
                        className="login-button-new py-3"
                        style={{
                          backgroundColor: "#151220",
                          boxShadow: "0px 6px 10px #00000029",
                          borderColor: "#151220",
                          fontWeight: 900,
                          fontSize: "calc(0.5rem + 1vmin)",
                        }}
                        onClick={verifyUseEmail}
                      >
                        VERIFY
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              )}
              {verified === true && (
                <Card.Body>
                  <Row className="px-2 my-auto">
                    {/* <Col className="col-12 d-flex justify-content-center ">
                      <label className="login-image">Glory</label>
                    </Col> */}
                    <Col className="col-12 d-flex justify-content-center mb-4 mt-2">
                      {/* Test as img is not availabel */}
                      {/* <label className="login-image">Glory</label> */}
                      <img src={logo} height="auto" width="125"></img>
                    </Col>
                    <Col className="col-12 d-flex justify-content-center">
                      <span className="login-welcome-msg">Welcome {name}</span>
                    </Col>
                    <Col className="col-12 d-flex justify-content-center  mb-4">
                      <span className="login-text-new">
                        Setup your account to get started
                      </span>
                    </Col>
                    <Col className="col-12 mb-2">
                      <FormGroup>
                        <label className="login-text-label px-2">
                          Email address
                        </label>
                        <FormInput
                          className="py-3"
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) =>
                            handlePassInput(e, stringsConstant.email)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-12 mb-2">
                      <FormGroup>
                        <label className="login-text-label px-2">
                          Enter Password
                        </label>
                        <FormInput
                          className="py-3"
                          placeholder="Password"
                          type="password"
                          value={newPassword}
                          onChange={(e) =>
                            handlePassInput(e, stringsConstant.newPass)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-12 mb-4">
                      <FormGroup>
                        <label className="login-text-label px-2">
                          Re-enter password
                        </label>
                        <FormInput
                          className="py-3"
                          placeholder="Password"
                          type="password"
                          value={reEnterPass}
                          onChange={(e) =>
                            handlePassInput(e, stringsConstant.rePass)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-12 d-flex justify-content-center mb-2">
                      <Button
                        className="login-button-new py-3"
                        style={{
                          backgroundColor: "#151220",
                          boxShadow: "0px 6px 10px #00000029",
                          borderColor: "#151220",
                          fontWeight: 900,
                          fontSize: "calc(0.5rem + 1vmin)",
                        }}
                        onClick={sendResetPass}
                      >
                        SET PASSWORD
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Col>
        </Row>
      </Col>
      <Col
        className="col-12 col-md-7 d-none d-sm-none d-md-block login-ipad-spec"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <div className="custom-row-height login-logo-position">
          <img src={LoginImg} height="auto" width="70%"></img>
        </div>
        {success === false && (
          <Animated
            className="animwidth mx-auto animPos col-4 offset-4"
            animationIn="fadeInUp"
            animationOut="fadeOutDown"
            animationInDuration={1500}
            animationOutDuration={1500}
            isVisible={resetTime}
            style={{
              float: "right",
            }}
          >
            <Toast className="custom-toast">
              <Toast.Body className="d-flex justify-content-center">
                <img
                  src={Cancel}
                  height="auto"
                  width={15}
                  className="mx-1"
                ></img>
                Failed to reset password
              </Toast.Body>
            </Toast>
          </Animated>
        )}

        {success === true && (
          <Animated
            className="animwidth mx-auto animPos col-5 offset-5"
            animationIn="fadeInUp"
            animationOut="fadeOutDown"
            animationInDuration={1500}
            animationOutDuration={1500}
            isVisible={resetTime}
            style={{
              float: "right",
            }}
          >
            <Toast className="custom-toast">
              <Toast.Body className="d-flex justify-content-center">
                <img
                  src={Check}
                  height="auto"
                  width={15}
                  className="mx-1"
                ></img>
                Password reset successfully
              </Toast.Body>
            </Toast>
          </Animated>
        )}

        <footer className="footerClass px-4 py-4 footer-class-font">
          <Row>
            <Col className="col-1">
              <span>FAQ</span>
            </Col>
            <Col className="col-2">
              <span>Privacy policy</span>
            </Col>
            <Col className="col-3">
              <span>Terms and conditions</span>
            </Col>
            <Col className="col-6 d-flex justify-content-end">
              <span>Copyright © 2020 Glory</span>
            </Col>
          </Row>
        </footer>
      </Col>
    </Row>
  );
}

export default FirstUser;
