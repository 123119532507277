import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper, Polyline } from "google-maps-react";
import PropTypes from "prop-types";
import equal from "fast-deep-equal/react";
export class NewMap extends Component {
  constructor(props) {
    super(props);
    // this.onMarkerClick = this.onMarkerClick.bind(this);
    // console.log(this.props);
    this.state = {
      locationData: [],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      center: {
        lat: 12.972492,
        lng: 77.59381,
      },
      zoom: 10,

      bounds: [],
      directions: [],
    };
    this.handleMapReady = this.handleMapReady.bind(this);
    // console.log(typeof props.dataprops.slatlon[0]);
    // console.log(typeof this.props.dataProps.slatlon);
  }
  handleMapReady(mapProps, map) {
    // console.log(map);
    this.calculateAndDisplayRoute(map);
  }
  calculateAndDisplayRoute(map) {
    const directionsService = new this.props.google.maps.DirectionsService();
    const directionsDisplay = new this.props.google.maps.DirectionsRenderer();
    // directionsDisplay.setMap(map);

    // const waypoints = this.props.data.map((item) => {
    //   return {
    //     location: { lat: item.lat, lng: item.lng },
    //     stopover: true,
    //   };
    // });

    let origin = {
      lat: this.props.dataProps.slatlon[1],
      lng: this.props.dataProps.slatlon[0],
    };
    let destination = {
      lat: this.props.dataProps.dlatlon[1],
      lng: this.props.dataProps.dlatlon[0],
    };
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: "DRIVING",
      },
      (response, status) => {
        if (status === "OK") {
          // console.log(response);
          this.setState({
            directions: response.routes[0].overview_path,
          });
        } else {
          window.alert("Directions request failed due to " + status);
        }
      }
    );
  }

  componentDidMount() {
    // console.log(this.props.dataProps.slatlon);
    if (
      this.props.dataProps.slatlon === undefined ||
      this.props.dataProps.slatlon[0] === 0
    ) {
    } else {
      let points = [
        {
          lat: this.props.dataProps.slatlon[1],
          lng: this.props.dataProps.slatlon[0],
        },
        {
          lat: this.props.dataProps.dlatlon[1],
          lng: this.props.dataProps.dlatlon[0],
        },
      ];

      var bounds = new this.props.google.maps.LatLngBounds();
      for (var i = 0; i < points.length; i++) {
        bounds.extend(points[i]);
      }
      // console.log(bounds);
      this.setState({
        zoom: 10,
        bounds: bounds,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (!equal(this.props.dataProps.slatlon, prevProps.dataProps.slatlon)) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      // this.updateUser();
      let points = [
        {
          lat: this.props.dataProps.slatlon[1],
          lng: this.props.dataProps.slatlon[0],
        },
      ];

      var bounds = new this.props.google.maps.LatLngBounds();
      for (var i = 0; i < points.length; i++) {
        bounds.extend(points[i]);
      }

      // this.handleMapReady();
      //   this.calculateAndDisplayRoute();
      //   console.log(bounds);
      this.setState({
        zoom: 8,
        bounds: bounds,
      });
    }
  }
  onMarkerClick(props, marker, e) {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  }
  // fetchLocationCall() {
  //     fetch("https://dispatcher.gloryindia.inhtmlcrm/getDriverLocation", {
  //         method: 'POST',
  //         headers: {
  //             'Content-Type': 'application/json'
  //             // 'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //         body: JSON.stringify({
  //             trip_id: this.props.dataProps.trip_id
  //             // trip_id: 'zNgXI0v9IeRWgUQf'
  //         })
  //     })
  //         .then(res => res.json())
  //         .then(
  //             (result) => {
  //                 // console.log("herererer");
  //                 console.log(result);
  //                 this.setState({
  //                     locationState: result.driver_location
  //                 })
  //             },
  //             // Note: it's important to handle errors here
  //             // instead of a catch() block so that we don't swallow
  //             // exceptions from actual bugs in components.
  //             (error) => {

  //             }
  //         )
  // }
  // componentDidMount() {
  //     // this.timerID = setInterval(
  //     //     () => this.fetchLocationCall(),
  //     //     50000
  //     // );
  //     this.fetchLocationCall()
  // }
  // componentWillUnmount() {
  //     // Store.removeChangeListener(this.onChange);
  //     clearInterval(this.timerID);
  // }
  render() {
    // console.log(this.state.directions);
    if (!this.props.google) {
      return <div>Loading...</div>;
    }
    // if(this.props.dataProps.slatlon[1] !== 0 && this.props.dataProps.dlatlon[1] !==0){
    //     let destinations = { lat: this.props.dataProps.dlatlon[1] * 1, lng: this.props.dataProps.dlatlon[0] * 1 };
    //     let origins = { lat: this.props.dataProps.slatlon[1], lng:this.props.dataProps.slatlon[0] };
    //     let travelmode = "DRIVING"
    //     this.props.google.maps.DistanceMatrixService()
    // }
    // let points = [
    //   {
    //     lat: typeof this.props.dataProps.slatlon[1] !== undefined,
    //     lng: this.props.dataProps.slatlon[0]
    //   },
    //   {
    //     lat: this.props.dataProps.dlatlon[1],
    //     lng: this.props.dataProps.dlatlon[0]
    //   }
    // ];
    // let bounds = new this.props.google.maps.LatLngBounds();
    // for (var i = 0; i < points.length; i++) {
    //   bounds.extend(points[i]);
    // }

    // console.log(thitripIds.state.locationState);

    return (
      <div style={{ height: "50vh", width: "100%", paddingLeft: "0px" }}>
        <Map
          google={this.props.google}
          zoom={this.state.zoom}
          initialCenter={this.state.center}
          bounds={this.state.bounds}
        >
          {this.props.dataProps.slatlon[0] !== 0 && (
            <Marker
              title={"The marker`s title will appear as a tooltip."}
              name={"Source"}
              position={{
                lat: this.props.dataProps.slatlon[1],
                lng: this.props.dataProps.slatlon[0],
              }}
              icon={{
                url: "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/pickupmarker.svg",

                scaledSize: new this.props.google.maps.Size(25, 25),
              }}
            />
          )}
        </Map>
      </div>
    );
  }
}
NewMap.default = {
  center: {
    lat: 12.7342888,
    lng: 77.3791981,
  },
  zoom: 15,
};
NewMap.defaultProps = {
  center: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  zoom: PropTypes.number,
};
export default GoogleApiWrapper({
  apiKey: "AIzaSyA6Ir9-jvJV-SFNvy-_Hz4bGXk8sNIWVOo",
})(NewMap);
