import React, { useEffect } from "react";
import { Container } from "shards-react";

/**
 * Import files and components
 */
import Vendors from "./Vendors";
import VendorsNew from "./Vendors_new";
import TripView from "../components/dataviews/TripView";

export default function VendorMain(props) {
  useEffect(() => {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    }
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      {/* <Vendors data={props} /> */}
      <VendorsNew data={props} />
      <TripView propsData={props} />
    </Container>
  );
}
