import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "shards-react";
// import { Animated } from "react-animated-css"
import socketIOClient from "socket.io-client";
import MainFooter from "../components/layout/MainFooter";
import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import Notification from "../components/notification/Notification";
import * as config from "../constants/constants.json";
import { Constants, Dispatcher } from "../flux";
// import NotificationSidebar from "../components/layout/NotificationSideBar/MainSidebar"

const socket = socketIOClient(config.SOCKET, {
  transports: ["websocket"],
});

// const DefaultLayout = ({ children, noNavbar, noFooter }) => (
//   <Container fluid>
//     <Row>
//       <MainSidebar />
//       <Col
//         className="main-content p-0"
//         lg={{ size: 10, offset: 2 }}
//         md={{ size: 9, offset: 3 }}
//         sm="12"
//         tag="main"
//       >
//         {!noNavbar && <MainNavbar />}
//         <Button className="py-5 px-4" style={styles} onClick={this.handleClick}><i class="fas fa-chevron-left"></i></Button>
//         {children}
//         {!noFooter && <MainFooter />}
//       </Col>

//     </Row>

//   </Container>
// );

class DefaultLayout extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.datas = [];
    this.rescheduledata = [];
    this.cancelData = [];
    this.requestRideData = [];
    this.onboardData = [];
    this.shiftNote = [];
    this.state = {
      clicked: false,
      setChangeColor: false,
      rescheduleData: [],
      cancelRideData: [],
      requestRideData: [],
      onboardDriverData: [],
      shiftReportData: [],
      unassignedData: [],
    };
    this.handleClickToggle = this.handleClickToggle.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
    // this.viewedData = this.viewedData.bind(this);
    this.addNotification = this.addNotification.bind(this);
  }
  handleClick() {
    this.setState({
      clicked: !this.state.clicked,
    });
  }

  handleClickToggle() {
    this.setState({
      clicked: true,
    });
  }

  addNotification = (data, type, boolval) => {
    // console.log(data);
    // console.log(type);
    // console.log(boolval);
  };
  // viewedData(socType, reqObj) {
  //   socket.emit(socType, reqObj);
  //   if (
  //     reqObj.type === "quickregistration_notification" ||
  //     reqObj.type === "registration_notification"
  //   ) {
  //     let state = {
  //       dunique_id: reqObj.trip_id,
  //       phonenumber: reqObj.requestType,
  //     };
  //     this.props.mainProps.history.replace(
  //       "/driveraccounts/driverdashboard/driverdetails",
  //       state
  //     );
  //   } else if (reqObj.type === "shiftnotes_notification") {
  //     this.props.mainProps.history.replace(
  //       "/reports/dispatchreports/shiftreports"
  //     );
  //   } else {
  //     /**
  //      * To delete the notification after view
  //      */
  //     // let dataTocheck = this.state.unassignedData;

  //     // _.remove(dataTocheck, function(e) {
  //     //   return e.notification_id === reqObj.notification_id;
  //     // });
  //     // console.log(dataTocheck);
  //     // this.setState({
  //     //   unassignedData: dataTocheck,
  //     // });

  //     let tab;
  //     let body;
  //     if (reqObj.type === "ridercancellation_notification") {
  //       tab = 4;
  //       body = "Canceled";
  //     } else if (reqObj.type === "newriderequest_notification") {
  //       tab = 0;
  //       body = "Unassigned rides";
  //     } else if (reqObj.type === "riderunassignstatus_notification") {
  //       tab = 0;
  //       body = "Unassigned rides";
  //     } else {
  //       tab = 0;
  //       body = "Unassigned rides";
  //     }

  //     let state = {
  //       trip_id: reqObj.trip_id,
  //       requestType: reqObj.requestType,
  //       requestStatus: reqObj.requestStatus,
  //       pickup_time: reqObj.pickup_time,
  //       tab,
  //       body,
  //     };
  //     // console.log(reqObj.type);
  //     // let ven = "quickride";
  //     // if (reqObj.vendor === "quickride") {
  //     //   ven = "quickride";
  //     // }
  //     // window.sessionStorage.setItem("vendor", ven);
  //     window.sessionStorage.setItem("clickedTrip", reqObj.trip_id);

  //     this.props.history.replace(`/businessaccounts/bookings`, state);
  //   }
  // }

  handleToggleSidebar() {
    // console.log("---------------------------");
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR,
    });
  }
  componentDidMount() {}

  render() {
    // console.log(this.state);
    const styles = {
      position: "fixed",
      right: "0px",
      zIndex: 1000,
      top: "45%",
    };

    const stylesNot = {
      position: "fixed",
      right: "0px",
      zIndex: 1000,
      top: "45%",
      background: "#FF0000",
      borderColor: "#FF0000",
    };

    const styleOnClick = {
      position: "fixed",
      right: "268px",
      zIndex: 1000,
      top: "45%",
    };

    // console.log(this.props.children);
    return (
      <Container fluid>
        <Row>
          <MainSidebar />
          <Notification />
          <Col
            className="main-content p-0"
            lg={{ size: 10, offset: 2 }}
            md={{ size: 9, offset: 3 }}
            sm="12"
            tag="main"
          >
            {!this.props.noNavbar && <MainNavbar />}
            {this.props.children}

            {!this.props.noFooter && <MainFooter />}
          </Col>
        </Row>
      </Container>
    );
  }
}
DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default DefaultLayout;
