import React from "react";
import PropTypes from "prop-types";
import ReactSnackBar from "react-js-snackbar";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  FormInput,
  Nav,
  NavItem,
  NavLink,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";
import PageTitle from "./../components/common/PageTitle";
// import { Redirect } from "react-router-dom";
// import Charts from "./../components/utilities/Charts";
import DashboardStatusBox from "./../components/common/DashboardStatusBox";
import ReactTable from "react-table-v6";
import * as config from "../constants/constants.json";
// import SearchDashboardList from "./../components/common/SearchDashboardList";
// import DriverDashboardBox from "../components/common/DriverDashboardBox"

class DriverMainDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeBar: true,
      activeIndex: 0,
      phoneVal: "",
      display: "driversList",
      displayTypeList: "Hatchback",
      data: [],
      vehicleTypeCount: "",
      sedanArr: [],
      hatchBackArr: [],
      suvArr: [],
      hatchbackCount: 0,
      sedanCount: 0,
      suvCount: 0,
      filteredData: [],
      searchInput: "",
      activeArr: [],
      inactiveArr: [],
      blockedArr: [],
      suspendedArr: [],
      activeCount: 0,
      inactiveCount: 0,
      blockedCount: 0,
      suspendedCount: 0,
      success: false,
      Show: false,
      Showing: false,
      message: "",
    };

    this.goBack = this.goBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.getDriverList = this.getDriverList.bind(this);
    this.getVehicleTypeCount = this.getVehicleTypeCount.bind(this);
    this.handleClickApi = this.handleClickApi.bind(this);
    this.getStatusTypeCount = this.getStatusTypeCount.bind(this);
    this.handleStatusCall = this.handleStatusCall.bind(this);
  }
  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };
  handleStatusCall() {
    console.log("here");
    fetch(config.url + "onboard/search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        phonenumber: this.state.phoneVal,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if (result.message === "user does not exist") {
            this.setState(
              {
                success: false,
                message: "User does not exist",
              },
              () => {
                this.show();
              }
            );
          } else if (result.message === "consumer") {
            this.setState(
              {
                success: false,
                message: "User registered as consumer!",
              },
              () => {
                this.show();
              }
            );
          } else if (result.message === "successfull") {
            this.props.history.push(
              "/driveraccounts/driverdashboard/driverdetails",
              this.state.phoneVal
            );
          } else {
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleClickApi(data, index) {
    console.log("heeeeeeeeeeeeeeeeeeeeeeee");
    // console.log(index);
    this.setState({
      displayTypeList: data,
      activeIndex: index,
    });
  }
  getDriverList() {}

  handleSetFilteredData = (filteredData) => {
    this.setState({ filteredData });
  };

  handleSetSearchInput = (searchInput) => {
    this.setState({ searchInput });
  };
  getVehicleTypeCount() {
    console.log("hereeeeeeeeee");
    let sedanArray = [];
    let suvArray = [];
    let hatchBackArray = [];
    console.log({
      type: "cabs",
      accountstate: "all",
      region: sessionStorage.getItem("operating_region"),
    });
    fetch(config.url + "complaintslog/allDriversList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        type: "cabs",
        accountstate: "all",
        region: sessionStorage.getItem("operating_region"),
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);

          let driverList = result.data;
          if (driverList.length > 0) {
            for (let i = 0; i <= driverList.length - 1; i++) {
              if (driverList[i].vehiclename === "Sedan") {
                sedanArray.push(driverList[i]);
              } else if (driverList[i].vehiclename === "Hatchback") {
                hatchBackArray.push(driverList[i]);
              } else if (driverList[i].vehiclename === "SUV") {
                suvArray.push(driverList[i]);
              }
            }
          }

          // console.log(sedanArray);
          // console.log(hatchBackArray);
          // console.log(suvArray);
          this.setState(
            {
              data: driverList,
              hatchBackArr: hatchBackArray,
              sedanArr: sedanArray,
              suvArr: suvArray,
              hatchbackCount: hatchBackArray.length,
              sedanCount: sedanArray.length,
              suvCount: suvArray.length,
            },
            () => {
              this.getStatusTypeCount();
            }
          );
          // this.setState({
          //     dayCount: result.count
          // })
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  getStatusTypeCount() {
    // console.log("hereeeeeeeeee");
    let activeArray = [];
    let inactiveArray = [];
    let blockedArray = [];
    let suspendedArray = [];
    let driverList = this.state.data;
    if (driverList.length > 0) {
      for (let i = 0; i <= driverList.length - 1; i++) {
        if (driverList[i].login_status === "Active") {
          activeArray.push(driverList[i]);
        } else if (driverList[i].login_status === "Inactive") {
          inactiveArray.push(driverList[i]);
        } else if (driverList[i].login_status === "blocked") {
          blockedArray.push(driverList[i]);
        } else if (driverList[i].login_status === "suspended") {
          suspendedArray.push(driverList[i]);
        }
      }
    }
    this.setState({
      activeArr: activeArray,
      inactiveArr: inactiveArray,
      blockedArr: blockedArray,
      suspendedArr: suspendedArray,
      activeCount: activeArray.length,
      inactiveCount: inactiveArray.length,
      blockedCount: blockedArray.length,
      suspendedCount: suspendedArray.length,
    });
  }
  handleClick(data) {
    // console.log(data);
    if (data === "driversList") {
      this.setState({
        activeBar: !this.state.activeBar,
        display: "driversList",
        displayTypeList: "Hatchback",
        activeIndex: 0,
      });
    } else {
      this.setState({
        activeBar: !this.state.activeBar,
        display: "accountStatus",
        displayTypeList: "Active",
        activeIndex: 0,
      });
    }
  }
  handleChange(event) {
    const val = event.target.value;
    this.setState({
      phoneVal: val,
    });
  }

  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    } else {
      this.getVehicleTypeCount();
    }
    // this.getDriverList()
  }

  render() {
    const columns = [
      {
        Header: "Sl no",
        id: "serialno",
        style: {},
        Cell: (row) => {
          // console.log(row);
          return <div>{row.viewIndex + 1}</div>;
        },
      },
      {
        Header: "Name",
        accessor: "drivername",
      },
      {
        Header: "Phone number",
        accessor: "phonenumber",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Vehicle type",
        accessor: "vehiclename",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Vehicle number",
        accessor: "vehiclenumber",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Status",
        // accessor: 'login_status',
        Cell: (row) => {
          if (row.original.login_status === "Active") {
            return <span style={{ color: "#59E67F" }}>Active</span>;
          } else if (row.original.login_status === "Inactive") {
            return <span style={{ color: "#FF8307" }}>Inactive</span>;
          } else if (row.original.login_status === "blocked") {
            return <span style={{ color: "#FF0000" }}>Blocked</span>;
          } else if (row.original.login_status === "suspended") {
            return <span style={{ color: "#FF0000" }}>Suspended</span>;
          }
        },
        style: { whiteSpace: "unset" },
      },
    ];
    let dataForTable;
    // console.log(this.state.displayTypeList);
    if (this.state.displayTypeList === "Hatchback") {
      dataForTable = this.state.hatchBackArr;
    } else if (this.state.displayTypeList === "Sedan") {
      dataForTable = this.state.sedanArr;
    } else if (this.state.displayTypeList === "SUV") {
      dataForTable = this.state.suvArr;
    } else if (this.state.displayTypeList === "Active") {
      dataForTable = this.state.activeArr;
    } else if (this.state.displayTypeList === "Inactive") {
      dataForTable = this.state.inactiveArr;
    } else if (this.state.displayTypeList === "blocked") {
      dataForTable = this.state.blockedArr;
    } else if (this.state.displayTypeList === "suspended") {
      dataForTable = this.state.suspendedArr;
    }
    const dataToDisplay = this.state.searchInput.length
      ? this.state.filteredData
      : dataForTable;

    return (
      <Container fluid className="main-content-container px-md-4">
        {/* <Row>
          <Col className="col-12 col-md-12 col-lg-12">
            <div className="float-right mt-2">
              <Button className="" id="goback-button" onClick={this.goBack}>
                <i className="fas fa-arrow-left mx-1" />
                <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                  BACK
                </span>
              </Button>
            </div>
          </Col>
        </Row> */}
        <Row noGutters className="page-header py-4 px-md-3">
          <Col className="col-12">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Driver accounts
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active />
            </Breadcrumb>
          </Col>
          <Col className="col-12">
            <PageTitle
              title="Driver dashboard"
              className="text-sm-left mb-3 p-0 custom-margin"
            />
          </Col>
          {/* <PageTitle
            title="Driver dashboard"
            subtitle="Driver Accounts"
            className="text-sm-left mb-3"
          /> */}
        </Row>
        <Row className="px-md-3">
          <Col className="col-12 col-md-4 col-lg-3 my-4">
            <FormInput
              size="large"
              className="py-3"
              type="text"
              name="searchInput"
              placeholder="Enter phone number here"
              value={this.state.phoneVal}
              onChange={this.handleChange}
              maxLength={10}
            />
            {/* {console.log(this.state.phoneVal)} */}
          </Col>
          <Col className="col-12 col-md-3 col-lg-3 my-auto mb-4 d-none d-sm-none d-md-block">
            <Button
              className=""
              onClick={this.handleStatusCall}
              theme="dark"
              size="lg"
              style={{
                fontSize: "0.8rem",
                fontWeight: "700",
                minWidth: "200px",
                borderRadius: "3px",
              }}
            >
              CHECK STATUS
            </Button>
          </Col>
          <Col className="col-12 col-md-3 col-lg-3 my-md-4 mb-4 d-block d-sm-block d-md-none d-flex justify-content-center">
            <Button
              className=""
              onClick={this.handleStatusCall}
              theme="dark"
              size="lg"
              style={{
                fontSize: "0.8rem",
                fontWeight: "700",
                minWidth: "200px",
                borderRadius: "3px",
              }}
            >
              CHECK STATUS
            </Button>
          </Col>
        </Row>
        <Row className="px-md-3 mb-5 mt-2">
          <Col className="col-12 col-md-12 col-lg-12">
            <div className="float-right mt-2">
              <Button
                className=""
                id="goback-button"
                theme="dark"
                onClick={this.goBack}
              >
                <i className="fas fa-arrow-left mx-1" />
                <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                  BACK
                </span>
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="px-md-3 mb-5">
          <Col className="col-12 col-md-12 col-lg-12">
            <Card>
              <CardBody>
                <Row>
                  <Col className="col-12 col-md-12 col-lg-12">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          active={this.state.activeBar}
                          onClick={() => {
                            this.handleClick("driversList");
                          }}
                          style={{ fontWeight: "700" }}
                        >
                          Drivers list
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          active={!this.state.activeBar}
                          onClick={() => {
                            this.handleClick("accountStatus");
                          }}
                          style={{ fontWeight: "700" }}
                        >
                          Account status
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>
                <Row className="mt-4">
                  {this.state.display === "driversList" &&
                    this.props.DriversList.map((stats, idx) => (
                      <Col
                        className="col-md mb-4"
                        key={idx}
                        {...stats.attrs}
                        style={{ cursor: "pointer" }}
                      >
                        <DashboardStatusBox
                          id={`smaller-vehicle-stats-${idx}`}
                          indx={idx}
                          variation="1"
                          activeIndex={this.state.activeIndex}
                          label={stats.label}
                          value={this.state[stats.apiID]}
                          stats={stats.stats}
                          defaults={stats.defaults}
                          onClickHandle={this.handleClickApi}
                          name={stats.name}
                        />
                      </Col>
                    ))}
                  {this.state.display === "accountStatus" &&
                    this.props.AccountStatus.map((stats, idx) => (
                      <Col
                        className="col-md mb-4"
                        key={idx}
                        {...stats.attrs}
                        style={{ cursor: "pointer" }}
                      >
                        <DashboardStatusBox
                          id={`smaller-account-stats-${idx}`}
                          variation="1"
                          indx={idx}
                          activeIndex={this.state.activeIndex}
                          label={stats.label}
                          value={this.state[stats.apiID]}
                          stats={stats.stats}
                          defaults={stats.defaults}
                          onClickHandle={this.handleClickApi}
                          name={stats.name}
                        />
                      </Col>
                    ))}
                </Row>
                <Row className="mt-4">
                  <Col className="col-12 col-md-12 col-lg-12 px-0 px-md-3">
                    <ReactTable
                      data={dataToDisplay}
                      columns={columns}
                      className="text-center -striped -highlight"
                      minRows={dataToDisplay.length > 0 ? 0 : 4}
                      noDataText="No data to display"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {this.state.success === false && (
          <ReactSnackBar
            Icon={
              <i
                className="far fa-times-circle fa-lg"
                style={{ color: "#FF0000" }}
              />
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
      </Container>
    );
  }
}

DriverMainDashboard.propTypes = {
  DriversList: PropTypes.array,
  AccountStatus: PropTypes.array,
};
DriverMainDashboard.defaultProps = {
  DriversList: [
    {
      label: "HATCHBACK",
      name: "Hatchback",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "hatchbackCount",
      defaults: "active",
    },
    {
      label: "SEDAN",
      name: "Sedan",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "sedanCount",
      defaults: "inactive",
    },
    {
      label: "SUV",
      name: "SUV",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "suvCount",
      defaults: "inactive",
    },
  ],
  AccountStatus: [
    {
      label: "ACTIVE",
      name: "Active",
      value: "0",
      attrs: { md: "2", sm: "6" },
      stats: false,
      apiID: "activeCount",
    },
    {
      label: "INACTIVE",
      name: "Inactive",
      value: "0",
      attrs: { md: "2", sm: "6" },
      stats: false,
      apiID: "inactiveCount",
    },
    {
      label: "BLOCKED",
      name: "blocked",
      value: "0",
      attrs: { md: "2", sm: "6" },
      stats: false,
      apiID: "blockedCount",
    },
    {
      label: "SUSPENDED",
      name: "suspended",
      value: "0",
      attrs: { md: "2", sm: "6" },
      stats: false,
      apiID: "suspendedCount",
    },
  ],
};

export default DriverMainDashboard;
