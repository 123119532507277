import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "shards-react";
import Logo from "./Logo";
import gmailSvg from "../../assets/gmail.svg";
import * as config from "../../constants/constants.json";

class ResetConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goToHome = this.goToHome.bind(this);
  }

  goToHome() {
    window.location.assign("/");
  }
  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    let data = {
      email: this.state.email,
    };
    fetch(config.url + "", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.message === "successfull") {
          } else {
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  };

  handleUserChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  render() {
    return (
      <Container fluid className="main-content-container h-100 px-4">
        <Row className="h-100 no-gutters ">
          <Col className="mx-auto my-auto col-12 col-md-6 col-lg-4 col-xl-3">
            <Logo
              img="https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/lgoosmall.png"
              alternate="Glory Logo"
              className="pb-4 mx-auto my-auto "
            />
            <Card className="mb-5">
              <CardHeader className="border-bottom card-header-login">
                <h6 id="card-heading" className="m-0 form-heading">
                  Check your mail
                </h6>
              </CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col className="col-12 col-md-12 d-flex justify-content-center mb-2 mt-2">
                    <img
                      src={gmailSvg}
                      className="py-3"
                      height="auto"
                      width="25%"
                    ></img>
                  </Col>
                  <Col className="col-12 col-md-12">
                    <span>
                      <label
                        style={{
                          textAlign: "center",
                          fontSize: "0.9rem",
                          fontWeight: 700,
                        }}
                      >
                        We have sent a reset link to your email account. Kindly
                        click on the link to reset your password
                      </label>
                    </span>
                  </Col>
                  <Col className="col-12 col-md-12 mt-5">
                    <Button
                      onClick={this.goToHome}
                      id="button-heading"
                      className="w-100 login-button-property"
                    >
                      CONTINUE
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ResetConfirmation;
