// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Collapse,
  Button,
  Tooltip,
  Card,
  CardBody,
  FormCheckbox,
  FormSelect,
  CardHeader,
  Form,
  Breadcrumb,
  BreadcrumbItem,
  FormInput,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "shards-react";
import config from "../constants/constants";
import ReactSnackBar from "react-js-snackbar";
import ProgressBar from "./../components/common/ProgressStepBar";
import PageTitle from "./../components/common/PageTitle";
import InputDataForm from "./../components/common/InputDataForm";
import TextArea from "./../components/common/TextArea";
import DragAndDrop from "./../components/common/DragAndDrop";
import AddVehicleData from "./../views/AddVehicleLogic";
import Datepicker from "react-date-picker";
import ReactDatepicker from "react-datepicker";
import equal from "fast-deep-equal/react";

class AddDriverLogic extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      notExist: false,
      comoingFromEdit: false,
      sameFam1Address: false,
      sameFam2Address: false,
      value: "",
      sameAddress: false,
      first_name: "",
      last_name: "",
      date_of_birth: "",
      email: "",
      current_address: "",
      permanent_address: "",
      family_name_1: "",
      family_name_2: "",
      family_phn_1: "",
      family_phn_2: "",
      family_rel_1: "",
      family_rel_2: "",
      family_address_1: "",
      family_address_2: "",
      outstation: false,
      rentals: false,
      airport: false,
      urban: false,
      recurride: false,
      day_shift: false,
      night_shift: false,
      operating_region: "Bangalore",
      preferred_location: "",
      starting_point: "",
      add_driver: true,
      add_vehicle_data: false,
      add_bank_data: false,
      phonenumber: "",
      alt_phonenumber: "",
      driver_profile: "",
      searchphonenumber: "",
      newNumber: "",
      referralCode: "",
      Show: false,
      Showing: false,
      success: false,
      open: false,
    };
    // console.log(props)
    // this.handleChange = this.handleChange.bind(this);
    this.handleKeyChange = this.handleKeyChange.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleClickUpload = this.handleClickUpload.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleVehswitch = this.handleVehswitch.bind(this);
    this.handleBank = this.handleVehswitch.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.goBack = this.goBack.bind(this);
    this.getProgress = this.getProgress.bind(this);
    this.changeProgress = this.changeProgress.bind(this);
    this.searchDriver = this.searchDriver.bind(this);
    this.toggle = this.toggle.bind(this);
    this.goTOEdit = this.goTOEdit.bind(this);
    this.toggleExists = this.toggleExists.bind(this);
    this.onboarddrivernow = this.onboarddrivernow.bind(this);
    this.reset = this.reset.bind(this);
    this.handleSelectChangeType = this.handleSelectChangeType.bind(this);
  }

  reset() {
    this.setState({
      add_driver: true,
    });
  }
  onboarddrivernow() {
    this.setState(
      {
        newNumber: true,
      },
      () => {
        this.toggleExists();
      }
    );
  }

  toggleExists() {
    this.setState({
      notExist: !this.state.notExist,
    });
  }
  goTOEdit() {
    this.props.history.push({
      pathname: "/driveraccounts/editdriver",
      search: `?phonnumber=${this.state.searchphonenumber}`,
      state: { phonenumber: this.state.searchphonenumber },
    });
  }
  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }
  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };

  searchDriver(event) {
    if (event) {
      event.preventDefault();
    }

    // serch opertion here
    fetch(config.url + "onboard/search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        phonenumber: this.state.searchphonenumber,
        // phonenumber: "8123173193"
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          if (resultsss.message) {
            if (resultsss.message === "user does not exist") {
              if (this.state.comoingFromEdit === true) {
                this.setState({
                  newNumber: true,
                });
              } else {
                this.setState({
                  notExist: !this.state.notExist,
                });
              }

              // this.setState({
              //   newNumber: true
              // });
            } else if (resultsss.message === "consumer") {
              this.setState(
                {
                  newNumber: false,
                  success: false,
                  message: "The user is registered as a rider",
                },
                () => {
                  this.show();
                }
              );
            } else if (resultsss.message === "successfull") {
              this.setState({
                newNumber: false,
                open: !this.state.open,
              });
            } else {
              this.setState(
                {
                  newNumber: false,
                  success: false,
                  message: "The user is already registered",
                },
                () => {
                  this.show();
                }
              );
            }
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  changeProgress() {
    this.setState({
      add_bank_data: !this.state.add_bank_data,
    });
    this.getProgress();
  }
  goBack() {
    // this.props.history.goBack();
    this.props.dataForPush.history.goBack();
  }
  handleImage(image, name) {
    this.props.handleImage(image, name);
  }
  handleSelectChange(event) {
    this.props.handleSelectChange(event);
    // this.setState({ operating_region: event.target.value });
  }
  // Handle checkbox change
  handleKeyChange(e, name) {
    this.props.handleKeyChange(e, name);
    console.log(this.props);
  }

  handleClickUpload() {
    // Do something here
    this.setState({
      add_vehicle_data: true,
      add_driver: false,
    });
  }

  handleTime(date) {
    this.setState({
      dob: date,
    });
  }

  handleaddressChange(e, checkName) {
    this.props.handleaddressChange(e, checkName);

    // const newState = {};
    // let addressState;
    // newState[checkName] = !this.state[checkName];
    // if (newState[checkName] === true) {
    //   if (checkName === "sameFam1Address") {
    //     addressState = this.state.current_address;
    //     newState["family_address_1"] = addressState;
    //   } else if (checkName === "sameFam2Address") {
    //     addressState = this.state.current_address;
    //     newState["family_address_2"] = addressState;
    //   } else {
    //     addressState = this.state.current_address;
    //     newState["permanent_address"] = addressState;
    //   }
    // } else {
    //   console.log(newState["permanent_address"]);
    //   addressState = "";
    //   if (checkName === "sameFam1Address") {
    //     newState["family_address_1"] = "";
    //   } else if (checkName === "sameFam2Address") {
    //     newState["family_address_2"] = "";
    //   } else {
    //     newState["permanent_address"] = "";
    //   }
    // }
    // this.setState({
    //   ...this.state,
    //   ...newState,
    // });
  }

  handleChange(e, checkName) {
    // const newState = {};
    // newState[checkName] = !this.state[checkName];
    // this.setState({ ...this.state, ...newState });

    this.props.handleChange(e, checkName);
  }
  handleSwitch() {
    this.setState({ add_driver: !this.state.add_driver });
  }
  handleVehswitch() {
    this.setState({ add_vehicle_data: !this.state.add_vehicle_data });
  }
  handleBank() {
    this.setState({
      add_bank_data: !this.state.add_bank_data,
    });
  }
  getProgress() {
    if (this.state.add_driver === true && this.state.add_bank_data !== true) {
      console.log("here");
      return "0";
    } else if (
      this.state.add_vehicle_data === true &&
      this.state.add_bank_data !== true
    ) {
      console.log("here");
      return "50";
    } else {
      console.log("here");
      return "100";
    }
  }
  componentDidMount(props) {
    console.log(this.props);
    this.setState({
      phonenumber: this.props.phonenumber,
    });
  }

  handleSelectChangeType(event) {
    console.log("here");
    this.props.handleSelectChangeType(event);
  }
  // componentDidUpdate(prevProps) {
  //   if (!equal(prevProps, this.props)) {
  //     console.log(this.props);
  //   }
  // }
  render() {
    // console.log("****************");
    console.log(this.props);
    return (
      <React.Fragment>
        <Row className="">
          <Col className="col-12 col-md-12 col-lg-12 px-0 px-md-3">
            <Card className="px-md-3 px-lg-3">
              <CardHeader>
                {/* <h5 className="text-center">Registration Details</h5> */}
              </CardHeader>
              <CardBody>
                <Row className="">
                  <Col className="col-12 col-md-5 col-lg-5">
                    <label className="label-style">Profile photo</label>
                    <DragAndDrop
                      id="driver-profile-image"
                      variation="1"
                      label="Profile photo"
                      name="driver_profile"
                      value={this.props.driver_profile}
                      onDropHandler={this.handleImage}
                    />
                  </Col>

                  <Col className="col-12 col-md-7 col-lg-7 px-0 px-md-3">
                    {this.props.inputValues.map((stats, idx) => (
                      <Col
                        className="col-12 col-md-6 col-lg-6  d-inline-block py-3"
                        key={idx}
                        {...stats.attrs}
                      >
                        {/* {console.log(this.props.first_name)} */}
                        <InputDataForm
                          id={`driver-details-${idx}`}
                          variation="1"
                          label={stats.label}
                          value={this.props[stats.name]}
                          stats={stats.stats}
                          name={stats.name}
                          readOnly={stats.name === "phonenumber" ? true : false}
                          onKeyChange={this.handleKeyChange}
                        />
                      </Col>
                    ))}
                    <Col className="col-12 col-md-6 col-lg-6  d-inline-block py-3">
                      <label className="label-style">
                        Driver type <span style={{ color: "#FF0000" }}>*</span>
                      </label>
                      <FormSelect
                        value={this.props.driver_type}
                        onChange={this.handleSelectChangeType}
                        style={{ height: "calc(3.16rem + 2px)" }}
                        required
                      >
                        <option value="">Choose driver type</option>
                        <option value="driver">Driver</option>
                        <option value="owner">Owner</option>
                      </FormSelect>
                    </Col>
                    <Col className="col-12 col-md-6 d-inline-block py-3">
                      <InputDataForm
                        id={`referral-details-0}`}
                        variation="1"
                        label="Referral code"
                        value={this.props.referralCode}
                        stats={false}
                        name="referralCode"
                        readOnly={false}
                        onKeyChange={this.handleKeyChange}
                      />
                    </Col>
                  </Col>
                </Row>
                <Row>
                  {this.props.addressField.map((stats, idx) => (
                    <Col
                      className="col-12 col-md-4 col-lg-4 mt-4"
                      key={idx}
                      {...stats.attrs}
                    >
                      {/* {console.log(this.state.hits)} */}
                      <TextArea
                        id={`driver-address-${idx}`}
                        variation="1"
                        label={stats.label}
                        name={stats.name}
                        stats={stats.stats}
                        value={this.props[stats.name]}
                        onKeyChange={this.handleKeyChange}
                      />
                    </Col>
                  ))}
                  <Col className="col-12 col-md-4 col-lg-4 mt-4" />
                </Row>
                <Row className="mt-2">
                  <FormCheckbox
                    checked={this.props.sameAddress}
                    onChange={(e) => this.handleaddressChange(e, "sameAddress")}
                    className="mx-auto mt-2"
                  >
                    Same as the current address
                  </FormCheckbox>
                </Row>
                <hr />
                <h5 className="text-center label-style mb-4">Family Details</h5>
                <Row>
                  <Col className="col-12 col-md-12 col-lg-12">
                    <Row className="mt-4">
                      <Col className="col-12 col-md-12 col-lg-12 mb-2">
                        <h6>Family member 1</h6>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      {this.props.familyInputData_1.map((stats, idx) => (
                        <Col
                          className="col-12 col-md-3 col-lg-3 mb-2"
                          key={idx}
                          {...stats.attrs}
                        >
                          {/* {console.log(this.state.hits)} */}
                          <InputDataForm
                            id={`family-details-1-${idx}`}
                            variation="1"
                            label={stats.label}
                            stats={stats.stats}
                            name={stats.name}
                            value={this.props[stats.name]}
                            onKeyChange={this.handleKeyChange}
                          />
                        </Col>
                      ))}
                      <Col className="col-3" />
                    </Row>

                    <Row className="mt-4">
                      {this.props.familyAddressField_1.map((stats, idx) => (
                        <Col
                          className="col-12 col-md-6 col-lg-6"
                          key={idx}
                          {...stats.attrs}
                        >
                          {/* {console.log(this.state.hits)} */}
                          <TextArea
                            id={`smaller-stats-${idx}`}
                            variation="1"
                            label={stats.label}
                            stats={stats.stats}
                            name={stats.name}
                            value={this.props[stats.name]}
                            onKeyChange={this.handleKeyChange}
                          />
                        </Col>
                      ))}
                    </Row>
                    <Row className="mt-4">
                      <Col className="col-12 mx-auto">
                        <FormCheckbox
                          checked={this.props.sameFam1Address}
                          onChange={(e) =>
                            this.handleaddressChange(e, "sameFam1Address")
                          }
                          className="mx-auto mt-2"
                        >
                          Stay in same address
                        </FormCheckbox>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col className="col-12 col-md-12 col-lg-12">
                    <Row>
                      <Col className="col-12">
                        <h6>Family member 2</h6>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      {this.props.familyInputData_2.map((stats, idx) => (
                        <Col
                          className="col-12 col-md-3 col-lg-3 mb-2"
                          key={idx}
                          {...stats.attrs}
                        >
                          {/* {console.log(this.state.hits)} */}
                          <InputDataForm
                            id={`smaller-stats-${idx}`}
                            variation="1"
                            label={stats.label}
                            stats={stats.stats}
                            name={stats.name}
                            value={this.props[stats.name]}
                            onKeyChange={this.handleKeyChange}
                          />
                        </Col>
                      ))}
                      <Col className="col-3" />
                    </Row>

                    <Row className="mt-4">
                      {this.props.familyAddressField_2.map((stats, idx) => (
                        <Col
                          className="col-12 col-md-6 col-lg-6"
                          key={idx}
                          {...stats.attrs}
                        >
                          {/* {console.log(this.state.hits)} */}
                          <TextArea
                            id={`smaller-stats-${idx}`}
                            variation="1"
                            label={stats.label}
                            stats={stats.stats}
                            name={stats.name}
                            value={this.props[stats.name]}
                            onKeyChange={this.handleKeyChange}
                          />
                        </Col>
                      ))}
                    </Row>
                    <Row className="mt-4">
                      <Col className="col-12 mx-auto">
                        <FormCheckbox
                          checked={this.props.sameFam2Address}
                          onChange={(e) =>
                            this.handleaddressChange(e, "sameFam2Address")
                          }
                          className="mx-auto mt-2"
                        >
                          Stay in same address
                        </FormCheckbox>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr />
                <Row className="mt-4">
                  <Col className="col-12 col-md-6 col-lg-6 ">
                    <Row className="px-md-3 px-lg-3">
                      <label className="label-style">Ride Preference</label>
                    </Row>
                    <Row>
                      <Col className="col-12 col-md-4 col-lg-4">
                        <FormCheckbox
                          checked={this.props.outstation}
                          onChange={(e) => this.handleChange(e, "outstation")}
                          className="mx-auto mt-2"
                        >
                          Outstation
                        </FormCheckbox>
                      </Col>
                      <Col className="col-12 col-md-4 col-lg-4">
                        <FormCheckbox
                          checked={this.props.rentals}
                          onChange={(e) => this.handleChange(e, "rentals")}
                          className="mx-auto mt-2"
                        >
                          Rentals
                        </FormCheckbox>
                      </Col>
                      <Col className="col-12 col-md-4 col-lg-4">
                        <FormCheckbox
                          checked={this.props.airport}
                          onChange={(e) => this.handleChange(e, "airport")}
                          className="mx-auto mt-2"
                        >
                          Airport
                        </FormCheckbox>
                      </Col>

                      <Col className="col-12 col-md-4 col-lg-4">
                        <FormCheckbox
                          checked={this.props.urban}
                          onChange={(e) => this.handleChange(e, "urban")}
                          className="mx-auto mt-2"
                        >
                          Urban
                        </FormCheckbox>
                      </Col>
                      <Col className="col-12 col-md-4 col-lg-4">
                        <FormCheckbox
                          checked={this.props.recurride}
                          onChange={(e) => this.handleChange(e, "recurride")}
                          className="mx-auto mt-2"
                        >
                          Recurride
                        </FormCheckbox>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-12 col-md-6 col-lg-6 ">
                    <Row>
                      <label className="label-style">Shift Preference</label>
                    </Row>
                    <Row>
                      <Col className="col-12 col-md-6 col-lg-6">
                        <FormCheckbox
                          checked={this.props.day_shift}
                          onChange={(e) => this.handleChange(e, "day_shift")}
                          className="mx-auto mt-2"
                        >
                          Day shift(8am-8pm)
                        </FormCheckbox>
                      </Col>
                      <Col className="col-12 col-md-6 col-lg-6">
                        <FormCheckbox
                          checked={this.props.night_shift}
                          onChange={(e) => this.handleChange(e, "night_shift")}
                          className="mx-auto mt-2"
                        >
                          Night shift(8am-8pm)
                        </FormCheckbox>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="col-12 col-md-4 col-lg-4 pb-4">
                    <label>
                      Operating region{" "}
                      <span style={{ color: "#FF0000" }}>*</span>
                    </label>
                    <FormSelect
                      value={this.props.operating_region}
                      onChange={this.handleSelectChange}
                      style={{ height: "calc(3.16rem + 2px)" }}
                      required
                    >
                      <option value="">Choose operating region</option>
                      <option value="Bangalore">Bangalore</option>
                      <option value="Hyderabad">Hyderabad</option>
                    </FormSelect>
                  </Col>
                  {this.props.driverPreference.map((stats, idx) => (
                    <Col
                      className="col-12 col-md-4 col-lg-4 pb-4"
                      key={idx}
                      {...stats.attrs}
                    >
                      {/* {console.log(this.state.hits)} */}
                      <InputDataForm
                        id={`driver-preference-${idx}`}
                        variation="1"
                        label={stats.label}
                        stats={stats.stats}
                        name={stats.name}
                        value={this.props[stats.name]}
                        onKeyChange={this.handleKeyChange}
                      />
                    </Col>
                  ))}
                </Row>
                {/* <Row>
                        <Col className="col-12 col-md-4 col-lg-4 col-xl-4">
                          <label>Field ops</label>
                          <FormSelect style={{ height: "calc(3.16rem + 2px)" }}>
                            <option value="bangalore">Bangalore</option>
                                            <option value="hyderabad">hyderabad</option>
                          </FormSelect>
                        </Col>
                      </Row> */}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {this.state.success === false && (
          <ReactSnackBar
            Icon={
              <i
                className="far fa-times-circle fa-lg"
                style={{ color: "#FF0000" }}
              />
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
      </React.Fragment>
    );
  }
}
AddDriverLogic.propTypes = {
  inputValues: PropTypes.array,
};

AddDriverLogic.defaultProps = {
  inputValues: [
    {
      label: "First name",
      value: "",
      attrs: {},
      stats: false,
      name: "first_name",
    },
    {
      label: "Last name",
      value: "",
      attrs: {},
      stats: false,
      name: "last_name",
    },
    {
      label: "Email",
      value: "",
      attrs: {},
      name: "email",
    },
    {
      label: "Phone number",
      value: "",
      attrs: {},
      name: "phonenumber",
    },
    {
      label: "Alternate phone number",
      value: "",
      attrs: {},
      name: "alt_phonenumber",
    },
    {
      label: "Date of birth",
      value: "",
      attrs: {},
      stats: false,
      name: "date_of_birth",
    },
  ],
  addressField: [
    {
      label: "Current address",
      value: "",
      attrs: {},
      stats: false,
      name: "current_address",
    },
    {
      label: "Permanent address",
      value: "",
      attrs: {},
      stats: false,
      name: "permanent_address",
    },
  ],
  familyInputData_1: [
    {
      label: "Name",
      value: "",
      attrs: {},
      stats: false,
      name: "family_name_1",
    },
    {
      label: "Phone number",
      value: "",
      attrs: {},
      stats: false,
      name: "family_phn_1",
    },
    {
      label: "Relationship",
      value: "",
      attrs: {},
      stats: false,
      name: "family_rel_1",
    },
  ],
  familyInputData_2: [
    {
      label: "Name",
      value: "",
      attrs: {},
      stats: false,
      name: "family_name_2",
    },
    {
      label: "Phone number",
      value: "",
      attrs: {},
      stats: false,
      name: "family_phn_2",
    },
    {
      label: "Relationship",
      value: "",
      attrs: {},
      stats: false,
      name: "family_rel_2",
    },
  ],
  familyAddressField_1: [
    {
      label: "Address",
      value: "",
      attrs: {},
      stats: false,
      name: "family_address_1",
    },
  ],
  familyAddressField_2: [
    {
      label: "Address",
      value: "",
      attrs: {},
      stats: false,
      name: "family_address_2",
    },
  ],
  driverPreference: [
    {
      label: "Preferred location",
      value: "",
      attrs: {},
      stats: false,
      name: "preferred_location",
    },
    {
      label: "Starting point",
      value: "",
      attrs: {},
      stats: false,
      name: "starting_point",
    },
  ],
};

export default AddDriverLogic;
