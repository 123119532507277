import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, Breadcrumb, BreadcrumbItem } from "shards-react";

const InnerBreadTitle = ({ title, subtitle, className, ...attrs }) => {
  const classes = classNames(
    className,
    "text-center",
    "text-md-left",
    "mb-sm-0"
  );

  return (
    <Col xs="12" sm="4" className={classes} {...attrs}>
      {/* <span className="text-uppercase page-subtitle">{subtitle}</span> */}
      <Breadcrumb>
        <BreadcrumbItem className="breadcrumb_biz">
          <label
            className="text-uppercase page-subtitle"
            style={{ backgroundColor: "#f5f6f8 !important" }}
          >
            {subtitle}
          </label>
        </BreadcrumbItem>
      </Breadcrumb>
      <h3 className="page-title">{title}</h3>
    </Col>
  );
};

InnerBreadTitle.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page subtitle.
   */
  subtitle: PropTypes.string,

  path: PropTypes.string,
};

export default InnerBreadTitle;
