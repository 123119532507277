import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import {
  Button,
  Col,
  FormGroup,
  FormSelect,
  ModalFooter,
  Row,
} from "shards-react";
import socketIOClient from "socket.io-client";

// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ExpandLess } from "@material-ui/icons";
import * as config from "../../constants/constants.json";
import * as stringData from "../../constants/Strings.json";

const socket = socketIOClient(config.SOCKET, {
  transports: ["websocket", "polling"],
});

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "240px",
    width: "100%",
    maxWidth: "350px",
    zIndex: 10000,
    position: "fixed",
    bottom: 0,
    maxHeight: "100%",
    overflowY: "auto",
    boxShadow: "2px 2px 8px 2px rgba(0, 0, 0, 0.3) !important",
  },
  heading: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightBold,
  },
  details: {
    border: "1px solid rgba(0,0,0,0.1)",
    cursor: "pointer",
  },
  textRight: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: 10,
  },
  textLeft: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  textDesc: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

function capitalizeFirstLetter(string) {
  // console.log(string);
  return string[0].toUpperCase() + string.slice(1);
}
function getDriverNumber(data) {
  if (data.phonenumber) {
    return data.phonenumber;
  } else {
    let phonenumber = data.driverphonenumber
      ? data.driverphonenumber
      : data.phnno;
    return phonenumber;
  }
}

export default function Notification() {
  const classes = useStyles();
  const [notificationData, setNotificationData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [unreadNotifications, setunreadNotifications] = useState(false);
  const [callModal, setCallModal] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [callAgent, setCallAgent] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    getActiveNotification();
    startSocket();
  }, []);

  const startSocket = () => {
    // console.log(notificationData)
    socket.on(stringData.channel_names.call_notification, (data) => {
      if (data) {
        setNotificationData((oldArray) => {
          let newData = [...oldArray, data];
          let sortedData = _.orderBy(
            newData,
            ["read_status", "time_stamp"],
            ["asc", "desc"]
          );
          return sortedData;
        });
      }
    });
    socket.on(stringData.channel_names.call_discard_notification, (data) => {
      if (data) {
        setNotificationData((oldArray) => {
          let newData = _.filter(oldArray, (filterData) => {
            return filterData.redis_id !== data.redis_id;
          });
          let sortedData = _.orderBy(
            newData,
            ["read_status", "time_stamp"],
            ["asc", "desc"]
          );
          return sortedData;
        });
      }
    });
  };

  const getActiveNotification = () => {
    let dataToSend = {
      user_id: sessionStorage.getItem("user_id"),
    };

    axios
      .post(config.url + "cloudtelephony/getActiveNotifications", dataToSend)
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "successfull") {
          let responseData = response.data.data;
          let sortedData =
            response.data.data && response.data.data.length > 0
              ? _.orderBy(
                  responseData,
                  ["read_status", "time_stamp"],
                  ["asc", "desc"]
                )
              : [];
          // console.log(sortedData);
          setNotificationData(sortedData);
        } else {
          setNotificationData([]);
        }
      })
      .catch();
  };

  useEffect(() => {
    if (_.some(notificationData, { read_status: false })) {
      // setExpanded("panel1");
      setunreadNotifications(true);
    } else {
      console.log("false");
      setunreadNotifications(false);
    }
  }, [notificationData]);

  const readNotification = (data) => {
    console.log(data);
    let dataToSend = {
      redis_id: data.redis_id,
      user_id: sessionStorage.getItem("user_id"),
    };
    axios
      .post(config.url + "cloudtelephony/updateNotificationMarked", dataToSend)
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "successfull") {
          getActiveNotification();
        }
      })
      .catch();
  };

  useEffect(() => {
    if (callModal === true) {
      getAgentList();
    }
  }, [callModal]);

  const getAgentList = () => {
    axios
      .get(config.url + "cloudtelephony/getAgentNumbers")
      .then((response) => {
        console.log(response);
        if (response.data.message === "successfull") {
          setAgentList(response.data.data);
        } else {
          setAgentList([]);
        }
      });
  };

  const openCallModal = (data) => {
    console.log(data);
    // this.setState(
    //   {
    //     callModal: true,
    //     selectedAgent: data,
    //   },
    //   () => {
    //     this.getAgentList(data);
    //   }
    // );
    setExpanded(false);
    readNotification(data);
    setCallModal(true);
    setSelectedAgent(data);
  };

  const closeCallModal = () => {
    // this.setState({
    //   callModal: false,
    //   selectedAgent: [],
    // });
    setCallModal(false);
    setSelectedAgent([]);
  };

  const handleAgentSelect = (e) => {
    // this.setState({
    //   callAgent: e.target.value,
    // });
    setCallAgent(e.target.value);
  };

  const placeCall = () => {
    let selectAgent = selectedAgent;
    console.log(selectAgent);
    // destination_number: getDriverNumber(selectedAgent)
    let dataToSend = {
      destination_number: getDriverNumber(selectAgent),
      agent_number: callAgent,
    };
    console.log(dataToSend);
    axios
      .post(config.url + "cloudtelephony/initiateClickToCall", dataToSend)
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "successfull") {
          setCallModal(false);
          setCallAgent("");
        } else {
        }
      })
      .catch();
  };

  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        elevation={5}
      >
        <AccordionSummary
          expandIcon={<ExpandLess />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            {"Notifications (" + notificationData.length + ")"}
            {unreadNotifications === true && (
              <div
                style={{
                  width: 7,
                  height: 7,
                  borderRadius: 18, //half radius will make it cirlce,
                  backgroundColor: "red",
                }}
              ></div>
            )}
          </Typography>
        </AccordionSummary>
        <div style={{ maxHeight: "100%", overflowY: "auto" }}>
          {notificationData.map((data, index) => (
            <AccordionDetails
              key={index}
              className={classes.details}
              style={{
                background:
                  data.read_status === false ? "rgba(255, 0, 0, 0.09)" : "",
              }}
              // onClick={() => readNotification(data)}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box className="d-flex">
                    <Typography className={classes.textLeft}>
                      Driver name:{" "}
                    </Typography>
                    <Typography className={classes.textRight}>
                      {data.user_name ? data.user_name : "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="d-flex w-100">
                    <Typography className={classes.textLeft}>
                      Phonenumber:{" "}
                    </Typography>
                    <Typography
                      className={classes.textRight}
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => openCallModal(data)}
                    >
                      {data.phonenumber ? data.phonenumber : "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="d-flex w-100">
                    <Typography className={classes.textLeft}>
                      Duty status:{" "}
                    </Typography>
                    <Typography
                      className={classes.textRight}
                      style={{
                        color:
                          data.login_status === "Active"
                            ? "#21C113"
                            : "#FF0000",
                      }}
                    >
                      {data.login_status ? data.login_status : "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="d-flex w-100">
                    <Typography className={classes.textLeft}>
                      Driver type:{" "}
                    </Typography>
                    <Typography className={classes.textRight}>
                      {data.driver_type
                        ? capitalizeFirstLetter(data.driver_type)
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="d-flex w-100">
                    <Typography className={classes.textDesc}>
                      {data.text ? data.text : "-"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          ))}
        </div>
      </Accordion>
      <Modal show={callModal} onHide={closeCallModal} size="md" centered>
        <ModalHeader closeButton style={{ borderBottom: "none" }}>
          Call bridge number
        </ModalHeader>

        <ModalBody className="px-1 pb-0">
          {/* {console.log(this.state.dData)} */}
          {/* {console.log(this.state.dutyState)} */}
          <Row className="px-5  d-flex justify-content-center">
            <Col className="col-12 col-md-9">
              <FormGroup>
                <label>Choose the call bridging number</label>
                <FormSelect
                  value={callAgent}
                  onChange={handleAgentSelect}
                  style={{ fontWeight: 700, textAlignLast: "center" }}
                >
                  <option value="">Click here to select the number</option>
                  {agentList.map((data, index) => (
                    <option value={data.eid} key={index}>
                      {data.agent_name + " - " + data.follow_me_number}
                    </option>
                  ))}
                </FormSelect>
              </FormGroup>
              <p className="question-text-style"></p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{ border: "none" }}>
          <Col className="col-12  d-flex justify-content-center pb-2 ">
            <Button
              theme="dark"
              size="lg"
              className="mx-2 login-button-new"
              style={{
                borderRadius: "5px",
                fontSize: "calc(0.4rem + 1vmin)",
              }}
              onClick={() => placeCall()}
            >
              PLACE CALL
            </Button>
          </Col>
        </ModalFooter>
      </Modal>
      {/* <Dialog
        open={callModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ color: "black !important" }}
        >
          {"Use Call bridging service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormGroup>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select number
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={callAgent}
                  onChange={handleAgentSelect}
                  label="Select agent"
                >
                  <MenuItem value="">
                    <em>Choose an agent to call</em>
                  </MenuItem>
                  {agentList.map((data, index) => (
                    <MenuItem value={data.eid} key={index}>
                      {data.agent_name + " - " + data.follow_me_number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCallModal}>Cancel</Button>
          <Button onClick={placeCall} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
}
