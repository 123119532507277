import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Collapse,
  Button,
  Tooltip,
  Card,
  CardBody,
  FormRadio,
  FormCheckbox,
  FormSelect,
  CardHeader,
  FormInput,
  CardFooter,
  FormTextarea,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import PageTitle from "./../components/common/PageTitle";
import ReactSnackBar from "react-js-snackbar";
import { ExcelRenderer } from "react-excel-renderer";
import ReactTable from "react-table-v6";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import * as config from "../constants/constants.json";
import Search from "./../components/common/SearchTrackerData";

class ViewTracker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUpdate: null,
      openModal: false,
      visitDate: "",
      data: [],
      selected: {},
      selectAll: 0,
      dateNow: new Date(),
      display: false,
      filteredData: [],
      searchInput: "",
      success: false,
      Show: false,
      Showing: false,
      phonenumbers: [],
      dataSelected: [],
      open: false,
      textData: "",
      messageType: "generic",
      successMsg: false,
      selectData: [],
    };

    this.goBack = this.goBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.callApiToGetLeads = this.callApiToGetLeads.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.toggle = this.toggle.bind(this);
    this.changeUpdate = this.changeUpdate.bind(this);
    this.handleVisitChange = this.handleVisitChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSelectClick = this.handleSelectClick.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.sendCustomMsg = this.sendCustomMsg.bind(this);
    this.sendGenericMsg = this.sendCustomMsg.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.goToDashboard = this.goToDashboard.bind(this);
    this.handleSetFilteredData = this.handleSetFilteredData.bind(this);
    this.handleSetSearchInput = this.handleSetSearchInput.bind(this);
    this.selectedUpdate = this.selectedUpdate.bind(this);
  }

  selectedUpdate(data) {
    this.setState({
      selectData: data,
    });
  }
  handleSetFilteredData = (filteredData) => {
    this.setState({ filteredData });
  };

  handleSetSearchInput = (searchInput) => {
    this.setState({ searchInput });
  };
  goToDashboard(data) {
    let phonenumber = data.toString();
    this.props.history.push(
      "/driveraccounts/driverdashboard/driverdetails",
      phonenumber
    );
  }
  sendCustomMsg() {
    this.setState(
      {
        messageType: "custom",
      },
      () => {
        this.sendMessage();
      }
    );
  }
  sendGenericMsg() {
    this.setState(
      {
        messageType: "generic",
      },
      () => {
        this.sendMessage();
      }
    );
  }
  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };
  sendMessage() {
    let phonenumbers = [];
    console.log(this.state.textData);
    if (
      this.state.textData === undefined ||
      this.state.textData === null ||
      this.state.textData === ""
    ) {
      this.setState(
        {
          successMsg: false,
        },
        () => {
          this.show();
          this.toggleModal();
        }
      );
    } else {
      let selectedDrivers = this.state.dataSelected;
      if (selectedDrivers.length > 0) {
        for (let a = 0; a <= selectedDrivers.length - 1; a++) {
          let phonenumber = selectedDrivers[a].phonenumber;

          phonenumbers.push(phonenumber);
          // console.log(phonenumbers)
        }

        let data = {
          drivers: JSON.stringify(phonenumbers),
        };
        switch (this.state.messageType) {
          case "generic":
            data.message = "GET_DOCUMENT_SMS";
            break;
          case "custom":
            data.message = this.state.textData;
            break;
          default:
            break;
        }
        console.log(data);
        fetch(config.url + "htmlcrm/sendCustomSMS", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then(
            (result) => {
              console.log(result);
              if (result.message === "successfull") {
                // this.setState({
                //     data: result.drivers_list
                // })
                this.setState(
                  {
                    successMsg: true,
                  },
                  () => {
                    this.show();
                    this.toggleModal();
                  }
                );
              } else {
                this.setState(
                  {
                    successMsg: false,
                  },
                  () => {
                    this.show();
                    this.toggleModal();
                  }
                );
              }

              // console.log("herererer");
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {}
          );
      } else {
        // alert("No phonenumbers are selected");
      }
    }
  }

  handleTextChange(event) {
    let data = event.target.value;
    console.log(data);
    this.setState({
      textData: data,
    });
  }

  handleSelectClick() {
    console.log("here" + this.state.selectedUpdate);
    this.updateStatus();
  }
  handleVisitChange(date) {
    this.setState({
      visitDate: date,
    });
  }
  changeUpdate(data) {
    this.setState({
      selectedUpdate: data,
    });
  }
  toggleModal() {
    this.setState({
      openModal: !this.state.openModal,
    });
  }

  toggle() {
    console.log("here");
    this.setState({
      open: !this.state.open,
    });
  }

  updateStatus() {
    // console.log(this.state.data)
    let tempObj = {};
    console.log(this.state.selectData);
    let data = this.state.selectData;
    let phonenumber = data.phonenumber;
    let name = data.name;
    let location = data.location;
    let vehicle_number = data.vehicle_number;
    // let phonenumber = this.state.phonenumbers
    // console.log(phonenumber[0])
    tempObj.phonenumber = phonenumber;
    tempObj.meeting_obj = {};
    tempObj.name = name;
    tempObj.location = location;
    tempObj.vehicle_number = vehicle_number;
    tempObj.status =
      this.state.selectedUpdate === "Visiting on"
        ? "Will visit"
        : this.state.selectedUpdate;
    tempObj.milliseconds = new Date().getTime();
    tempObj.user_id = window.sessionStorage.getItem("user_id");
    // console.log(this.state.dateNow)
    let tempdate = new Date(this.state.dateNow).setHours(0, 0, 0, 0);

    let dataToSend = {
      user_id: window.sessionStorage.getItem("user_id"),
      day: tempdate.toString(),
      agent_name:
        window.sessionStorage.getItem("first_name") +
        " " +
        window.sessionStorage.getItem("last_name"),
    };
    let willVisitChecked = false,
      willVisitError = false;
    let selectedMilliseconds = 0;
    console.log(this.state.selectedUpdate);
    if (this.state.selectedUpdate === "Visiting on") {
      willVisitChecked = true;
    }

    if (willVisitChecked) {
      var selectedMeetingTime = new Date(this.state.visitDate).getTime();
      if (selectedMeetingTime) {
        console.log("hererere");
        dataToSend.meeting_time = selectedMeetingTime;
        selectedMilliseconds = selectedMeetingTime;
        willVisitError = false;
      } else {
        willVisitError = true;
      }
    }
    dataToSend.data = JSON.stringify(tempObj);
    // dataToSend.data = tempObj
    console.log(dataToSend);
    console.log("killllllllllllllaaaaaaaaaaaaaaaaaaaaaa");
    console.log(dataToSend);
    fetch(config.url + "htmlcrm/updateleadsentry", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            // this.setState({
            //     data: result.drivers_list
            // })
            this.setState(
              {
                open: !this.state.open,
              },
              () => {
                this.callApiToGetLeads();
              }
            );
          } else {
          }

          // console.log("herererer");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  handleChange(date) {
    this.setState(
      {
        dateNow: date,
      },
      () => {
        this.callApiToGetLeads();
      }
    );
  }

  toggleRow(data, dataSet) {
    // console.log(data)
    console.log(dataSet);
    const newSelected = Object.assign({}, this.state.selected);

    newSelected[data] = !this.state.selected[data];
    // this.setState(
    //   {
    //     selected: newSelected,
    //     selectAll: 2,
    //   },
    //   () => {
    //     console.log(this.state.selected);
    //   }
    // );
    console.log(newSelected);
    newSelected["dunique_id"] = data;
    this.setState({});
    if (newSelected[data] === true) {
      console.log("heree");
      let dataTobeChecked = this.state.dataSelected;
      if (dataTobeChecked > 0) {
        console.log("damnnn here as well");
        for (let a = 0; a <= dataTobeChecked.length - 1; a++) {
          if (dataTobeChecked[a].phonenumber === dataSet.phonenumber) {
            console.log("looooool");
            // Do nothing
          } else {
            this.setState((prevState) => ({
              dataSelected: [...prevState.dataSelected, dataSet],
              selected: newSelected,
              selectAll: 2,
            }));
          }
        }
      } else {
        this.setState((prevState) => ({
          dataSelected: [...prevState.dataSelected, dataSet],
          selected: newSelected,
          selectAll: 2,
        }));
      }
    } else {
      console.log("not heree");
      this.setState({
        selected: newSelected,
        selectAll: 2,
      });
    }
  }

  toggleOnSelectAll() {
    // console.log("here");
    let newSelected = {};

    if (this.state.selectAll === 0) {
      this.state.data.forEach((x) => {
        newSelected[x.phonenumber] = true;
      });
    }
    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
    });
  }
  goBack() {
    this.props.history.goBack();
  }
  callApiToGetLeads() {
    let data = {
      user_id: window.sessionStorage.getItem("user_id"),
      milliseconds: new Date(this.state.dateNow).setHours(0, 0, 0, 0),
    };
    console.log(data);
    fetch(config.url + "htmlcrm/getleadsentry", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.setState({
              data: result.drivers_list,
            });
          } else {
            this.setState({
              data: [],
            });
          }

          // console.log("herererer");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    } else {
      this.callApiToGetLeads();
    }
  }

  // Handle activation call

  render() {
    console.log(this.state);
    const dataToDisplay = this.state.searchInput.length
      ? this.state.filteredData
      : this.state.data;
    console.log(this.state.data);
    console.log(this.state.filteredData);
    const columns = [
      {
        id: "checkbox",
        accessor: "",
        Cell: ({ original }) => {
          // console.log(original);
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selected[original.phonenumber] === true}
              onChange={() => this.toggleRow(original.phonenumber, original)}
            />
          );
        },
        Header: (x) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selectAll === 1}
              ref={(input) => {
                if (input) {
                  input.indeterminate = this.state.selectAll === 2;
                }
              }}
              onChange={() => this.toggleOnSelectAll()}
            />
          );
        },
        sortable: false,
        width: 45,
      },
      {
        Header: "View",
        style: { whiteSpace: "unset" },
        Cell: (row) => {
          if (row.original.status === "Driver has been onboarded") {
            return (
              <div>
                <Button
                  className="mx-2"
                  theme="dark"
                  onClick={() => {
                    this.goToDashboard(row.original.phonenumber);
                  }}
                >
                  VIEW
                </Button>
              </div>
            );
          }
        },
      },
      {
        id: "name",
        Header: "Name",
        accessor: "name",
      },
      {
        id: "phonenumber",
        Header: "Phone number",
        accessor: "phonenumber",
        style: { whiteSpace: "unset" },
      },
      {
        id: "location",
        Header: "Base location",
        accessor: (d) => d.location,
        style: { whiteSpace: "unset" },
      },
      {
        id: "vehicle_number",
        Header: "Vehicle number",
        accessor: "vehicle_number",
        style: { whiteSpace: "unset" },
      },
      {
        id: "milliseconds",
        Header: "Last updated",
        // accessor: d => (d.milliseconds === undefined || d.milliseconds === null) ? "-" : new Date(d.milliseconds),
        Cell: (row) => {
          let date;
          if (
            row.original.milliseconds === undefined ||
            row.original.milliseconds === null
          ) {
            date = "-";
          } else {
            date = moment(new Date(row.original.milliseconds)).format(
              "DD-MM-YY [at] hh:mm:ss A"
            );
          }

          return <div>{date}</div>;
        },
        style: { whiteSpace: "unset" },
      },
      {
        id: "status",
        Header: "Status",
        accessor: (d) =>
          d.status === undefined || d.status === null ? "-" : d.status,

        style: { whiteSpace: "unset" },
      },
      {
        id: "visiting_on",
        Header: "Visiting on",
        accessor: (d) =>
          d.meeting_obj.meeting_time === undefined ||
          d.meeting_obj.meeting_time === null
            ? "-"
            : moment(new Date(d.meeting_obj.meeting_time)).format(
                "DD-MM-YY, hh:mm:ss A"
              ),
        style: { whiteSpace: "unset" },
      },
      {
        Header: "View",
        style: { whiteSpace: "unset" },
        Cell: (row) => {
          return (
            <Button
              className="mx-2 px-3 "
              onClick={() => {
                this.toggleRow(row.phonenumber, row);
                this.selectedUpdate(row.original);
                this.toggle();
              }}
              theme="dark"
            >
              UPDATE
            </Button>
          );
        },
      },
    ];
    return (
      <Container fluid className="main-content-container px-md-4">
        {/* <Row>
          <Col className="col-12 col-md-12 col-lg-12">
            <div className="float-right mt-2">
              <Button className="" id="goback-button" onClick={this.goBack}>
                <i className="fas fa-arrow-left mx-1" />
                <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                  BACK
                </span>
              </Button>
            </div>
          </Col>
        </Row> */}
        <Row noGutters className="page-header py-4 px-md-3 mb-md-5">
          <Col>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="/driveraccounts">Driver accounts</a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  On-boarding tracker
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active />
            </Breadcrumb>
          </Col>
          <Col className="col-12 mt-1">
            <PageTitle
              title="Driver tracker"
              className="text-sm-left mb-3 p-0 custom-margin"
            />
          </Col>
          {/* <PageTitle title="Driver Tracker" className="text-sm-left mb-3" /> */}
        </Row>
        <Row className="mt-2 mb-3 px-md-3">
          <Col className="col-12 col-md-12 col-lg-12 mb-4">
            <Button
              className=" float-right"
              theme="dark"
              id="goback-button"
              onClick={this.goBack}
            >
              <i className="fas fa-arrow-left mx-1" />
              <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                BACK
              </span>{" "}
            </Button>
          </Col>
        </Row>
        <Row className="px-md-3 mb-5">
          <Col className="col-12 col-md-12 col-lg-12">
            <Card>
              <CardBody>
                <Modal show={this.state.openModal} onHide={this.toggleModal}>
                  <ModalHeader closeButton>Message composer</ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col className="col-12 col-md-12 col-lg-12">
                        <pre
                          className="custom-messages px-2 mb-2"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {` Hello from Glory,

                                                    Please carry all the vehicle documents, Aadhar card, PAN card,
                                                    2 passport size photos and police verification document while
                                                    visiting our office for registration.`}
                          <div className="mt-4 mb-2">
                            <Button
                              theme="dark"
                              size="lg"
                              style={{
                                fontSize: "0.8rem",
                                fontWeight: "700",
                                minWidth: "200px",
                                borderRadius: "3px",
                              }}
                              disabled={
                                this.state.selectAll === 0 ? true : false
                              }
                              onClick={this.sendGenericMsg}
                            >
                              SEND MESSAGE
                            </Button>
                          </div>
                        </pre>
                        {/* <div>
                                                   
                                                    <Button>Send Message</Button>
                                                </div> */}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12 col-md-12 col-lg-12">
                        <FormTextarea
                          size="lg"
                          rows="5"
                          onChange={this.handleTextChange}
                          placeholder={this.state.textData}
                        />
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      theme="dark"
                      size="lg"
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "700",
                        minWidth: "120px",
                        borderRadius: "3px",
                      }}
                      className="mx-2"
                      onClick={this.toggleModal}
                    >
                      CANCEL
                    </Button>
                    <Button
                      disabled={this.state.selectAll === 0 ? true : false}
                      className="mx-2"
                      theme="dark"
                      size="lg"
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "700",
                        minWidth: "120px",
                        borderRadius: "3px",
                      }}
                      onClick={this.sendCustomMsg}
                    >
                      SEND MESSAGE
                    </Button>
                  </ModalFooter>
                </Modal>
                <Row>
                  <Col className="col-12 col-md-4 col-lg-3">
                    <ReactDatePicker
                      className="form-control text-center"
                      selected={this.state.dateNow}
                      onChange={(date) => this.handleChange(date)}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="col-12 col-md-12 d-none d-sm-none d-md-block">
                    <Button
                      className="float-right py-3"
                      onClick={this.toggleModal}
                      theme="dark"
                      size="lg"
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "700",
                        minWidth: "200px",
                        borderRadius: "3px",
                      }}
                      disabled={this.state.selectAll === 0 ? true : false}
                    >
                      SEND MESSAGES IN BULK
                    </Button>
                  </Col>
                  <Col className="col-12 col-md-12 d-block d-sm-block d-md-none d-flex justify-content-center">
                    <Button
                      className="py-3"
                      onClick={this.toggleModal}
                      disabled={this.state.selectAll === 0 ? true : false}
                    >
                      SEND MESSAGES IN BULK
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col className="col-12 col-md-12 col-lg-12">
                    <Search
                      data={this.state.data}
                      columns={columns}
                      handleSetFilteredData={this.handleSetFilteredData}
                      handleSetSearchInput={this.handleSetSearchInput}
                    />
                  </Col>
                  <Col className="col-12 col-md-12 col-lg-12 mt-4">
                    <ReactTable
                      data={dataToDisplay}
                      columns={columns}
                      className="text-center -striped -highlight"
                      minRows={dataToDisplay.length > 0 ? 0 : 4}
                      noDataText="No data to display"
                    />
                  </Col>
                </Row>
                <Modal show={this.state.open} onHide={this.toggle}>
                  <ModalHeader closeButton>Driver status</ModalHeader>
                  <ModalBody>
                    <FormRadio
                      name="fruit"
                      checked={this.state.selectedUpdate === "Not interested"}
                      onChange={() => {
                        this.changeUpdate("Not interested");
                      }}
                    >
                      <label>Not interested</label>
                    </FormRadio>
                    <FormRadio
                      name="fruit"
                      checked={this.state.selectedUpdate === "Not reachable"}
                      onChange={() => {
                        this.changeUpdate("Not reachable");
                      }}
                    >
                      <label>Not reachable</label>
                    </FormRadio>
                    <FormRadio
                      name="fruit"
                      checked={this.state.selectedUpdate === "Vehicle sold"}
                      onChange={() => {
                        this.changeUpdate("Vehicle sold");
                      }}
                    >
                      <label>Vehicle sold</label>
                    </FormRadio>
                    <FormRadio
                      name="fruit"
                      checked={this.state.selectedUpdate === "Visiting on"}
                      onChange={() => {
                        this.changeUpdate("Visiting on");
                      }}
                    >
                      <label>Visiting on</label>
                    </FormRadio>

                    {this.state.selectedUpdate === "Visiting on" && (
                      <Row className="px-3 my-2 ">
                        <Col className="col-12 col-md-12 col-lg-12 mb-2 d-flex justify-content-center">
                          <span className="mx-2">
                            Please schedule your meeting
                          </span>
                        </Col>
                        <Col className="col-12 col-md-12 col-lg-12 d-flex justify-content-center">
                          <ReactDatePicker
                            className="form-control text-center"
                            selected={this.state.visitDate}
                            onChange={(date) => this.handleVisitChange(date)}
                            showTimeSelect
                            timeFormat="p"
                            timeIntervals={15}
                            dateFormat="dd MMMM yyyy, p"
                            placeholderText="Choose data and time"
                          />
                        </Col>
                      </Row>
                    )}
                    <FormRadio
                      name="fruit"
                      checked={
                        this.state.selectedUpdate ===
                        "Will visit with a new vehicle"
                      }
                      onChange={() => {
                        this.changeUpdate("Will visit with a new vehicle");
                      }}
                    >
                      <label>Will visit with a new vehicle</label>
                    </FormRadio>

                    <FormRadio
                      name="fruit"
                      checked={
                        this.state.selectedUpdate ===
                        "Driver has been onboarded"
                      }
                      onChange={() => {
                        this.changeUpdate("Driver has been onboarded");
                      }}
                    >
                      <label>Driver has been onboarded</label>
                    </FormRadio>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      className="mx-2"
                      theme="dark"
                      size="lg"
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "700",
                        minWidth: "120px",
                        borderRadius: "3px",
                      }}
                      onClick={this.handleSelectClick}
                    >
                      UPDATE
                    </Button>
                    <Button
                      className="mx-2"
                      theme="dark"
                      size="lg"
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "700",
                        minWidth: "120px",
                        borderRadius: "3px",
                      }}
                      onClick={this.toggle}
                    >
                      CLOSE
                    </Button>
                  </ModalFooter>
                </Modal>
              </CardBody>
              {this.state.display === true && (
                <CardFooter>
                  <Button className="float-right" onClick={this.handleClick}>
                    UPLOAD
                  </Button>
                </CardFooter>
              )}
              {this.state.success === false && (
                <ReactSnackBar
                  Icon={
                    <i
                      className="far fa-times-circle fa-lg"
                      style={{ color: "#FF0000" }}
                    />
                  }
                  Show={this.state.Show}
                >
                  Document update failed, Please try again !!!
                </ReactSnackBar>
              )}
              {this.state.success === true && (
                <ReactSnackBar
                  Icon={
                    <span>
                      <i
                        className="far fa-check-circle fa-lg"
                        style={{ color: "#26FF58" }}
                      />
                    </span>
                  }
                  Show={this.state.Show}
                >
                  <h5 style={{ color: "white" }}>Document updated !!!</h5>
                </ReactSnackBar>
              )}
              {this.state.successMsg === false && (
                <ReactSnackBar
                  Icon={
                    <i
                      className="far fa-times-circle fa-lg"
                      style={{ color: "#FF0000" }}
                    />
                  }
                  Show={this.state.Show}
                >
                  Message could not be sent, Please try again !!!
                </ReactSnackBar>
              )}
              {this.state.successMsg === true && (
                <ReactSnackBar
                  Icon={
                    <span>
                      <i
                        className="far fa-check-circle fa-lg"
                        style={{ color: "#26FF58" }}
                      />
                    </span>
                  }
                  Show={this.state.Show}
                >
                  <h5 style={{ color: "white" }}>
                    Message sent successfully !!!
                  </h5>
                </ReactSnackBar>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

ViewTracker.propTypes = {};
ViewTracker.defaultProps = {};

export default ViewTracker;
