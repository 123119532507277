import React from "react";
import _ from "lodash";
import { Marker, InfoWindow } from "@react-google-maps/api";
import equal from "fast-deep-equal/react";
import { data } from "jquery";
/**
 * Map marker URLs based on the vehicel type
 */
function getImage(data) {
  // console.log(data);
  if (data.vehicle_type === "Hatchback") {
    return "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/bolt.svg";
  } else if (data.vehicle_type === "Sedan") {
    return "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/flash.svg";
  } else if (data.vehicle_type === "SUV") {
    return "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/suv.svg";
  } else {
    return "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/bolt.svg";
  }
}

/**
 * Function to calculate bearing values
 */

function getBearing(
  start_latitude,
  start_longitude,
  stop_latitude,
  stop_longitude
) {
  //   console.log(start_latitude + "****" + start_longitude);
  //   console.log(stop_latitude + "****" + stop_longitude);
  var y = Math.sin(stop_longitude - start_longitude) * Math.cos(stop_latitude);
  var x =
    Math.cos(start_latitude) * Math.sin(stop_latitude) -
    Math.sin(start_latitude) *
      Math.cos(stop_latitude) *
      Math.cos(stop_longitude - start_longitude);
  var brng = (Math.atan2(y, x) * 180) / Math.PI;
  return brng;
}

const divStyle = {
  background: `white`,
};

class Markers extends React.Component {
  constructor(props) {
    // console.log(props);
    super(props);
    this.state = {
      location: [],
      image: "",
      data: [],
      close: false,
    };
    this.position = [];
    this.numDeltas = 100;
    this.velocity = 5;
    this.delay = 10 * 0.5;
    this.i = 0;
    this.deltaLat = 0;
    this.deltaLng = 0;
  }

  componentWillMount() {
    let image;
    if (this.props.vehicle_type === "Hatchback") {
      image =
        "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/bolt.svg";
    } else if (this.props.vehicle_type === "Sedan") {
      image =
        "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/flash.svg";
    } else if (this.props.vehicle_type === "SUV") {
      image =
        "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/suv.svg";
    } else {
      image =
        "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/bolt.svg";
    }
    // console.log(...this.state.location);
    this.setState({
      image,
      id: this.props.data.key,
      data: this.props.data,
      location: this.props.location,
    });
  }

  moveMarker = () => {
    this.position[0] += this.deltaLat;
    this.position[1] += this.deltaLng;

    let latlng = new window.google.maps.LatLng(
      this.position[0],
      this.position[1]
    );
    // console.log(latlng.lat() + "***************" + latlng.lng());
    this.setState({
      location: { lat: latlng.lat(), lng: latlng.lng() },
    });
    // console.log("*******************************************");
    if (this.i != this.numDeltas) {
      this.i++;
      setTimeout(this.moveMarker, this.delay);
    }
  };

  moveObject = (old_data, current_data) => {
    this.i = 0;

    let old_lat = old_data.lat;
    let old_lng = old_data.lng;

    this.position = [old_lat, old_lng];
    this.deltaLat = (current_data.lat - old_lat) / this.numDeltas;
    this.deltaLng = (current_data.lng - old_lng) / this.numDeltas;
    this.moveMarker();
  };

  clickClose = () => {
    this.setState({
      close: false,
    });
  };

  openClick = () => {
    this.setState({
      close: true,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!equal(this.props.data, prevProps.data)) {
      let data = this.props.data;
      let old_data = prevProps.data;
      // console.log(old_data);
      // console.log(data);
      let point1 = {
        lat: old_data.latitude,
        lng: old_data.longitude,
      };
      let point2 = {
        lat: data.latitude,
        lng: data.longitude,
      };
      // console.log(point1);
      // console.log(point2);
      const angle = getBearing(point1.lat, point1.lng, point2.lat, point2.lng);
      const actualAngle = angle;
      // console.log(actualAngle);
      // console.log(data.key);
      let markerL = document.querySelector(
        `[src="${this.state.image + "?" + data.key}"]`
      );
      // console.log(markerL.children[0]);
      if (markerL) {
        // when it hasn't loaded, it's null
        markerL.style.transform = `rotate(${actualAngle}deg)`;
        markerL.style.transition = `all 0.7s ease-in-out`;
      }
      this.moveObject(point1, point2);
    }
  }

  // onLoad(marker) {
  //   console.log(marker);
  // }

  render() {
    let { data } = this.state;
    // console.log(this.state.id);
    // console.log(getImage(this.props.vehicle_type));
    return (
      <React.Fragment>
        {_.isEmpty(this.state.location) === false && (
          <Marker
            title={this.state.data.key}
            icon={{
              id: this.state.data.key,
              url: this.state.image + "?" + this.state.data.key,
              scaledSize: new window.google.maps.Size(40, 40),
            }}
            position={{
              lat: this.state.location.lat,
              lng: this.state.location.lng,
            }}
            onClick={this.openClick}
          >
            {this.state.close && (
              <InfoWindow onCloseClick={this.clickClose}>
                <div style={divStyle}>
                  <div className="pt-2">
                    <label style={{ fontWeight: 100 }}>Name: </label>
                    <span style={{ fontWeight: 600 }}>{data.driver_name}</span>
                  </div>
                  <div>
                    <label style={{ fontWeight: 100 }}>Type: </label>
                    <span style={{ fontWeight: 600 }}>{data.vehicle_type}</span>
                  </div>
                  <div>
                    <label style={{ fontWeight: 100 }}>Vehicle Number: </label>
                    <span style={{ fontWeight: 600 }}>
                      {" "}
                      {data.vehiclenumber}
                    </span>
                  </div>
                  <div className="pb-2">
                    <label style={{ fontWeight: 100 }}>Phone number:</label>
                    <span style={{ fontWeight: 600 }}> {data.phonenumber}</span>
                  </div>
                </div>
              </InfoWindow>
            )}
          </Marker>
        )}
      </React.Fragment>
    );
  }
}

export default Markers;
