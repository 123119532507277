import React, { useState, useEffect } from "react";
// import ReactDatePicker from "react-datepicker";
import ReactSnackBar from "react-js-snackbar";
import axios from "axios";
import moment from "moment";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  CardBody,
  FormGroup,
  FormInput,
} from "shards-react";
import { Button as Btn } from "shards-react";
import ReactDatePicker from "react-datepicker";
import Loader from "react-loader-spinner";
import config from "../constants/constants";
import useStyles from "./styles";
import AddDriver from "../views/AddDriverLogic";
import AddVehicle from "../views/AddVehicleLogic";
import AddBank from "../views/AddBankLogic";
import DL from "../assets/dl.svg";
import RC from "../assets/rc.svg";
import PAN from "../assets/pan.svg";
import CheckV from "../assets/checktick.svg";
import ErrorV from "../assets/errorv.svg";
import socketIOClient from "socket.io-client";
// const socket = socketIOClient(config.SOCKET_DOC, {
//   transports: ["websocket"],
// });

const steps = ["Driver details", "Vehicle details", "Bank details"];
const imageTypeArr = [
  "driverprofileimage",
  "vehiclefront",
  "vehicleright",
  "vehicleleft",
  "vehiclerear",
  "driverwithvehicle",
  "driverformImageFront",
  "driverformImageBack",
  "aadhar",
  "pan",
  "drivinglicense",
  "rc",
  "vehicleinsurance",
  "driverbadge",
  "emissioncrt",
  "roadtax",
  "permit",
  "fc",
  "salesagreement",
  "policeverification",
];

function AddDriverMain(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [dunique_id, setDuniqueID] = useState(undefined);
  const [isSubmitting, setIssubmitting] = useState(false);
  const isLastStep = activeStep === steps.length - 1;
  const [driver_profile, setDriverProfile] = useState(undefined);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [date_of_birth, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [current_address, setCurrentAddress] = useState("");
  const [permanent_address, setPermanentAddress] = useState("");
  const [family_name_1, setFamilyName1] = useState("");
  const [family_name_2, setFamilyName2] = useState("");
  const [family_phn_1, setFamilyPhn1] = useState("");
  const [family_phn_2, setFamilyPhn2] = useState("");
  const [family_rel_1, setFamilyRel1] = useState("");
  const [family_rel_2, setFamilyRel2] = useState("");
  const [family_address_1, setFamilyAddr1] = useState("");
  const [family_address_2, setFamilyAddr2] = useState("");
  const [outstation, setOutstation] = useState(false);
  const [rentals, setRentals] = useState(false);
  const [airport, setAirport] = useState(false);
  const [urban, setUrban] = useState(false);
  const [recurride, setRecurride] = useState(false);
  const [day_shift, setDayShift] = useState(false);
  const [night_shift, setNightShift] = useState(false);
  const [operating_region, setOperatinRegion] = useState("");
  const [preferred_location, setPreferredLocation] = useState("");
  const [starting_point, setStartingPoing] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [alt_phonenumber, setAlternatePhonenumber] = useState("");
  const [searchphonenumber, setSearchPhonenumber] = useState("");
  const [newNumber, setNewNumber] = useState("");
  const [sameAddress, setSameAddr] = useState(false);
  const [sameFam1Address, setFam1Addr] = useState(false);
  const [sameFam2Address, setFam2Addr] = useState(false);
  const [driver_type, setDriverType] = useState("");
  const [front_image, setFrontImage] = useState("");
  const [left_image, setLeftImage] = useState("");
  const [rear_image, setRearImage] = useState("");
  const [right_image, setRightImage] = useState("");
  const [driv_image, setDrivImage] = useState("");
  const [vehicle_name, setVehicleName] = useState("");
  const [vehicle_number, setVehicleNumber] = useState("");
  const [vehicle_color, setVehicleColor] = useState("");
  const [vehicle_make, setVehicleMake] = useState("");
  const [vehicle_model, setVehicleModel] = useState("");
  const [badge_number, setBadgeNumber] = useState("");
  const [permit_number, setPermitNumber] = useState("");
  const [aadhar_number, setAadharNumber] = useState("");
  const [pan_number, setPanNumber] = useState("");
  const [dl_number, setDlNumber] = useState("");
  const [dl_expiry, setDlexpiry] = useState("");
  const [badge_expiry, setBadgeexpiry] = useState("");
  const [emission_expiry, setEmissionexpiry] = useState("");
  const [insurance_expiry, setInsuranceexpiry] = useState("");
  const [permit_expiry, setPermitexpiry] = useState("");
  const [fc_expiry, setFcexpiry] = useState("");
  const [vehicle_type, setVehicleType] = useState("");
  const [driving_license, setDl] = useState("");
  const [registration_cert, setRc] = useState("");
  const [sales_agreement, setSalesAgreement] = useState("");
  const [badge, setBadge] = useState("");
  const [emission_cert, setEmission] = useState("");
  const [vehicle_insurance, setVehicleInsurance] = useState("");
  const [road_tax, setRoadTax] = useState("");
  const [permit, setPermit] = useState("");
  const [fitness_cert, setFc] = useState("");
  const [police_verificatiton, setPolic] = useState("");
  const [pan_card, setPan] = useState("");
  const [aadhar_back, setAadharback] = useState("");
  const [aadhar_front, setAadharFront] = useState("");
  const [acc_holder_name, setAccName] = useState("");
  const [acc_number, setAccNumber] = useState("");
  const [bank_name, setBankName] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [passbook, setPassBook] = useState("");
  const [success, setSuccess] = useState("");
  const [message, setMessage] = useState("");
  const [Show, setShow] = useState(false);
  const [Showing, setShowing] = useState(false);
  const [showVerificationBox, setVerificationBox] = useState(false);
  const [panVerified, setPanVerified] = useState(undefined);
  const [dlVerified, setDlVerified] = useState(undefined);
  const [rcVerified, setRcVerified] = useState(undefined);
  const [disableDl, setDisableDl] = useState(false);
  const [disableRC, setDisableRC] = useState(false);
  const [disablePAN, setDisablePAN] = useState(undefined);
  const [dlverifiedData, setDlverifiedData] = useState(undefined);
  const [rcverifiedData, setRcverifiedData] = useState(undefined);
  const [panverifiedData, setPanverifiedData] = useState(undefined);
  const [vehicle_num1, setVehicleNum1] = useState("");
  const [vehicle_num2, setVehicleNum2] = useState("");
  const [pan_name, setPanName] = useState("");
  const [panNumber, setPanNum] = useState("");
  const [dldob, setDldob] = useState("");
  const [pandob, setPandob] = useState("");
  const [dlno, setDlno] = useState("");
  const [push_array, setPushArray] = useState([]);
  const [referralCode, setReferralCode] = useState("");

  // console.log(bank_name);
  // console.log(acc_holder_name);
  // console.log(acc_number);
  // console.log(ifsc);

  const show = () => {
    if (Showing) return;
    setShow(true);
    setShowing(true);
    // this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      setShow(false);
      setShowing(false);
    }, 2000);
  };

  useEffect(() => {
    setPhonenumber(props.phonenumber);
  }, []);

  const handleSelectChangeType = (event) => {
    // console.log("yo----->" + event);
    setDriverType(event.target.value);
  };

  const handleaddressChange = (e, checkName) => {
    switch (checkName) {
      case "sameAddress":
        setPermanentAddress(current_address);
        setSameAddr(!sameAddress);
        break;
      case "sameFam1Address":
        setFamilyAddr1(current_address);
        setFam1Addr(!sameFam1Address);
        break;
      case "sameFam2Address":
        setFamilyAddr2(current_address);
        setFam2Addr(!sameFam2Address);
        break;

      default:
        break;
    }
  };

  const handleImage = (image, name) => {
    if (name === "driver_profile") {
      setDriverProfile(image);
    }
  };

  const handleSelectChange = (event) => {
    setOperatinRegion(event.target.value);
  };

  const handleKeyChange = (e, name) => {
    // console.log(e.target.value + "----->" + name);
    switch (name) {
      case "date_of_birth":
        setDob(e);
        break;
      case "vehicle_model":
        setVehicleModel(e);
        break;
      case "vehicle_color":
        setVehicleColor(e.target.value);
        break;
      case "vehicle_name":
        setVehicleName(e.target.value);
        break;
      case "vehicle_make":
        setVehicleMake(e.target.value);
        break;
      case "badge_number":
        setBadgeNumber(e.target.value);
        break;
      case "permit_number":
        setPermitNumber(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "current_address":
        setCurrentAddress(e.target.value);
        break;
      case "permanent_address":
        setPermanentAddress(e.target.value);
        break;
      case "first_name":
        console.log("here");
        setFirstName(e.target.value);
        break;
      case "last_name":
        setLastName(e.target.value);
        break;
      case "family_name_1":
        setFamilyName1(e.target.value);
        break;
      case "family_name_2":
        setFamilyName2(e.target.value);
        break;
      case "family_rel_1":
        setFamilyRel1(e.target.value);
        break;
      case "family_rel_2":
        setFamilyRel2(e.target.value);
        break;
      case "family_address_1":
        setFamilyAddr1(e.target.value);
        break;
      case "family_address_2":
        setFamilyAddr2(e.target.value);
        break;
      case "vehicle_number":
        setVehicleNumber(e.target.value);
        break;
      case "dl_number":
        setDlNumber(e.target.value);
        break;
      case "acc_holder_name":
        setAccName(e.target.value);
        break;
      case "bank_name":
        setBankName(e.target.value);
        break;
      case "ifsc":
        setIfsc(e.target.value);
        break;
      case "acc_number":
        setAccNumber(e.target.value);
        break;
      case "aadhar_number":
        let rea = /^[0-9\b]+$/;
        if (e.target.value === "" || rea.test(e.target.value)) {
          setAadharNumber(e.target.value);
        }
        break;
      case "pan_number":
        setPanNumber(e.target.value);
        break;
      case "preferred_location":
        if (e.target !== undefined && e.target.value !== undefined) {
          setPreferredLocation(e.target.value);
        } else {
          setPreferredLocation(e);
        }
        break;
      case "starting_point":
        if (e.target !== undefined && e.target.value !== undefined) {
          setStartingPoing(e.target.value);
        } else {
          setStartingPoing(e);
        }
        break;
      case "phonenumber":
        let rep = /^[0-9\b]+$/;
        if (e.target.value === "" || rep.test(e.target.value)) {
          setPhonenumber(e.target.value);
        }
        break;
      case "alt_phonenumber":
        let re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
          setAlternatePhonenumber(e.target.value);
        }
        break;
      case "family_phn_1":
        let ph1 = /^[0-9\b]+$/;
        if (e.target.value === "" || ph1.test(e.target.value)) {
          setFamilyPhn1(e.target.value);
        }
        break;
      case "family_phn_2":
        let ph2 = /^[0-9\b]+$/;
        if (e.target.value === "" || ph2.test(e.target.value)) {
          setFamilyPhn2(e.target.value);
        }
        break;
      case "searchphonenumber":
        let phn = /^[0-9\b]+$/;
        if (e.target.value === "" || phn.test(e.target.value)) {
          setSearchPhonenumber(e.target.value);
        }
        break;
      case "referralCode":
        setReferralCode(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleVehicleImage = (image, name) => {
    switch (name) {
      case "driv_image":
        setDrivImage(image);
        break;
      case "front_image":
        setFrontImage(image);
        break;
      case "left_image":
        setLeftImage(image);
        break;
      case "right_image":
        setRightImage(image);
        break;
      case "rear_image":
        setRearImage(image);
        break;
      case "aadhar_front":
        setAadharFront(image);
        break;
      case "aadhar_back":
        setAadharback(image);
        break;
      case "driving_license":
        setDl(image);
        break;
      case "pan_card":
        setPan(image);
        break;
      case "registration_cert":
        setRc(image);
        break;
      case "sales_agreement":
        setSalesAgreement(image);
        break;
      case "badge":
        setBadge(image);
        break;
      case "emission_cert":
        setEmission(image);
        break;
      case "vehicle_insurance":
        setVehicleInsurance(image);
        break;
      case "road_tax":
        setRoadTax(image);
        break;
      case "permit":
        setPermit(image);
        break;
      case "fitness_cert":
        setFc(image);
        break;

      default:
        break;
    }
  };

  const handleVehicleChange = (event) => {
    setVehicleType(event.target.value);
  };

  const handledlchange = (date) => {
    setDlexpiry(date);
  };

  const handleEmchange = (date) => {
    setEmissionexpiry(date);
  };

  const handledlInsurchange = (date) => {
    setInsuranceexpiry(date);
  };

  const handledlPermitchange = (date) => {
    setPermitexpiry(date);
  };

  const handledlFcchange = (date) => {
    setFcexpiry(date);
  };

  const handleBadgechange = (date) => {
    setBadgeexpiry(date);
  };

  const handlePassbookImage = (image, name) => {
    setPassBook(image);
  };

  const _renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <AddDriver
            handleImage={handleImage}
            handleKeyChange={handleKeyChange}
            handleSelectChange={handleSelectChange}
            handleaddressChange={handleaddressChange}
            handleChange={handleChange}
            handleSelectChangeType={handleSelectChangeType}
            driver_profile={driver_profile}
            first_name={first_name}
            last_name={last_name}
            date_of_birth={date_of_birth}
            email={email}
            current_address={current_address}
            permanent_address={permanent_address}
            family_name_1={family_name_1}
            family_name_2={family_name_2}
            family_phn_1={family_phn_1}
            family_phn_2={family_phn_2}
            family_rel_1={family_rel_1}
            family_rel_2={family_rel_2}
            family_address_1={family_address_1}
            family_address_2={family_address_2}
            outstation={outstation}
            rentals={rentals}
            airport={airport}
            urban={urban}
            recurride={recurride}
            day_shift={day_shift}
            night_shift={night_shift}
            operating_region={operating_region}
            preferred_location={preferred_location}
            starting_point={starting_point}
            phonenumber={phonenumber}
            searchphonenumber={searchphonenumber}
            alt_phonenumber={alt_phonenumber}
            newNumber={newNumber}
            sameAddress={sameAddress}
            sameFam1Address={sameFam1Address}
            sameFam2Address={sameFam2Address}
            driver_type={driver_type}
            referralCode={referralCode}
          />
        );
      case 1:
        return (
          <AddVehicle
            handleVehicleImage={handleVehicleImage}
            handleBadgechange={handleBadgechange}
            handleKeyChange={handleKeyChange}
            handleVehicleChange={handleVehicleChange}
            handledlchange={handledlchange}
            handleEmchange={handleEmchange}
            handledlInsurchange={handledlInsurchange}
            handledlPermitchange={handledlPermitchange}
            handledlFcchange={handledlFcchange}
            vehicle_name={vehicle_name}
            vehicle_number={vehicle_number}
            vehicle_color={vehicle_color}
            vehicle_make={vehicle_make}
            vehicle_model={vehicle_model}
            badge_number={badge_number}
            permit_number={permit_number}
            aadhar_number={aadhar_number}
            pan_number={pan_number}
            dl_number={dl_number}
            dl_expiry={dl_expiry}
            badge_expiry={badge_expiry}
            emission_expiry={emission_expiry}
            insurance_expiry={insurance_expiry}
            permit_expiry={permit_expiry}
            fc_expiry={fc_expiry}
            vehicle_type={vehicle_type}
            front_image={front_image}
            left_image={left_image}
            rear_image={rear_image}
            right_image={right_image}
            driv_image={driv_image}
            aadhar_front={aadhar_front}
            aadhar_back={aadhar_back}
            driving_license={driving_license}
            pan_card={pan_card}
            registration_cert={registration_cert}
            sales_agreement={sales_agreement}
            badge={badge}
            emission_cert={emission_cert}
            vehicle_insurance={vehicle_insurance}
            road_tax={road_tax}
            permit={permit}
            fitness_cert={fitness_cert}
          />
        );
      case 2:
        return (
          <AddBank
            acc_holder_name={acc_holder_name}
            acc_number={acc_number}
            bank_name={bank_name}
            ifsc={ifsc}
            passbook={passbook}
            handleKeyChange={handleKeyChange}
            handlePassBook={handlePassbookImage}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  };

  const handleChange = (e, checkName) => {
    // const newState = {};
    // newState[checkName] = !this.state[checkName];
    // this.setState({ ...this.state, ...newState });

    switch (checkName) {
      case "outstation":
        setOutstation(!outstation);
        break;
      case "rentals":
        setRentals(!rentals);
        break;
      case "urban":
        setUrban(!urban);
        break;
      case "airport":
        setAirport(!airport);
        break;
      case "recurride":
        setRecurride(!recurride);
        break;
      case "day_shift":
        setDayShift(!day_shift);
        break;
      case "night_shift":
        setNightShift(!night_shift);
        break;

      default:
        break;
    }
  };

  // async function _submitForm(values, actions) {
  //   await _sleep(1000);
  //   // alert(JSON.stringify(values, null, 2));
  //   // actions.setSubmitting(false);
  //   // setActiveStep(activeStep + 1);
  // }

  const _submitForm = () => {
    setIssubmitting(true);
    let dataToSend = {
      userid: window.sessionStorage.getItem("user_id"),
      first_name,
      last_name,
      basic_user: "false",
      phonenumber,
      dateofbirth: new Date(date_of_birth).getTime().toString(),
      prefer_location: preferred_location,
      permanent_address,
      current_address,
      driver_type,
      vehiclenumber: vehicle_number,
      starting_point,
      badgenumber: badge_number,
      vehiclemake: vehicle_make,
      vehicletype: vehicle_name,
      vehiclename: vehicle_type,
      vehicle_color: vehicle_color,
      accholder_name: acc_holder_name,
      bankacc_number: acc_number,
      ifsc_code: ifsc,
      pannumber: pan_number,
      vehiclemodel: new Date(vehicle_model).getTime().toString(),
      adhar_number: aadhar_number,
      email: email,
      alternate_number: alt_phonenumber,
      fmone_name: family_name_1,
      fmone_phonenumber: family_phn_1,
      fmone_relationship: family_rel_1,
      fmone_address: family_address_1,
      fmtwo_name: family_name_2,
      fmtwo_phonenumber: family_phn_2,
      fmtwo_relationship: family_rel_2,
      fmtwo_address: family_address_1,
      childlock: "true",
      gps: "true",
      panicbutton: "true",
      operating_region: operating_region,
      dl_num: dl_number,
      referralCode: referralCode,
    };
    // console.log(dataToSend);
    axios
      .post(config.url + "onboard/newdriversregistration", dataToSend)
      .then((response) => {
        // console.log(response);
        if (response.data.message === "successfull") {
          let dunique_id = response.data.dunique_id;
          setDuniqueID(dunique_id);
          updateDocumentsDate(dunique_id);

          // setTimeout(() => {
          //   setVerificationBox(true);
          // }, 2000);
        } else if (response.data.message === "already exists") {
          setSuccess(false);
          setMessage("Driver exists. Check vehicle number");
          show();
          setIssubmitting(false);
        } else {
          setSuccess(false);
          setMessage("Failed to register driver!! Check data");
          show();
          setIssubmitting(false);
        }
      });
  };

  const updateDocumentsDate = (dunique_id) => {
    let dataToSend = {
      dunique_id: dunique_id,
      dl_to: new Date(dl_expiry).getTime(),
      insurance_to: new Date(insurance_expiry).getTime(),
      emission_to: new Date(emission_expiry).getTime(),
      permit_to: new Date(permit_expiry).getTime(),
      fc_to: new Date(fc_expiry).getTime(),
      display_card_to: "",
      roadtax_to: "",
    };
    axios
      .post(config.url + "onboard/driverdocumentsdate", dataToSend)
      .then((response) => {
        if (response.data.message === "successfull") {
          updateDutyPreference(dunique_id);
          // setIssubmitting(false);
        } else {
          updateDutyPreference(dunique_id);
          // setIssubmitting(false);
          // setSuccess(false);
          // setMessage("Driver registered. Failed to update expiry dates");
          // show();
        }
      });
  };

  const getDutyPreference = () => {
    if (night_shift === true && day_shift === true) {
      return "day / night";
    } else if (day_shift === true) {
      return "day";
    } else if (night_shift === true) {
      return "night";
    } else {
      return "night / day";
    }
  };

  const updateDutyPreference = (dunique_id) => {
    let dataToSend = {
      dunique_id: dunique_id,
      prefer_recurride: recurride,
      prefer_airport: airport,
      prefer_urban: urban,
      prefer_hiring: "",
      prefer_outstation: outstation,
      permit_location: "none",
      permit_type: "",
      duty_preference: getDutyPreference(),
    };
    axios
      .post(config.url + "onboard/updateDriverRidePreference", dataToSend)
      .then((response) => {
        updateDriverImages(dunique_id);
      });
  };

  const updateDriverImages = (dunique_id) => {
    // let dataToSend = {
    //   executive_id: window.sessionStorage.getItem("user_id"),
    //   dunique_id: dunique_id,
    //   profileimage: driver_profile,
    //   driverpro: "",
    //   displaycard: badge,
    //   rcimage: registration_cert,
    //   insuranceimage: vehicle_insurance,
    //   adharone: aadhar_front,
    //   adhartwo: aadhar_back,
    //   pancard: pan_card,
    //   policeverification: "",
    //   permit: permit,
    //   fc: fitness_cert,
    //   dlone: driving_license,
    //   dltwo: "",
    //   emission: emission_cert,
    //   roadtax: road_tax,
    //   vhfront: front_image,
    //   vhreare: rear_image,
    //   vhleft: left_image,
    //   vhright: right_image,
    //   sales_agreement: sales_agreement,
    // };

    // let count = 0
    let base64Img = "";
    let imageType = "";
    let count = 0;

    (function worker() {
      if (imageTypeArr[count] === "driverprofileimage") {
        // $("#uploadingDocTitle").text("Uploading Driver profile...");
        base64Img = driver_profile;
        imageType = "driverprofileimage";
      } else if (imageTypeArr[count] === "vehiclefront") {
        // $("#uploadingDocTitle").text("Uploading Vehicle front...");
        base64Img = front_image;
        imageType = imageTypeArr[count];
      } else if (imageTypeArr[count] === "vehicleright") {
        // $("#uploadingDocTitle").text("Uploading Vehicle right...");
        base64Img = right_image;
        imageType = "vehicleright";
      } else if (imageTypeArr[count] === "vehicleleft") {
        // $("#uploadingDocTitle").text("Uploading Vehicle left...");
        base64Img = left_image;
        imageType = "vehicleleft";
      } else if (imageTypeArr[count] === "vehiclerear") {
        // $("#uploadingDocTitle").text("Uploading Vehicle rear...");
        base64Img = rear_image;
        imageType = "vehiclerear";
      } else if (imageTypeArr[count] === "driverwithvehicle") {
        // $("#uploadingDocTitle").text("Uploading Driver with vehicle...");
        base64Img = driv_image;
        imageType = "driverwithvehicle";
      } else if (imageTypeArr[count] === "aadhar") {
        // $("#uploadingDocTitle").text("Uploading Aadhar...");
        base64Img = aadhar_front;
        imageType = "aadhar";
      } else if (imageTypeArr[count] === "pan") {
        // $("#uploadingDocTitle").text("Uploading PAN...");
        base64Img = pan_card;
        imageType = "pan";
      } else if (imageTypeArr[count] === "drivinglicense") {
        // $("#uploadingDocTitle").text("Uploading DL...");
        base64Img = driving_license;
        imageType = "drivinglicense";
      } else if (imageTypeArr[count] === "rc") {
        // $("#uploadingDocTitle").text("Uploading RC...");
        base64Img = registration_cert;
        imageType = "rc";
      } else if (imageTypeArr[count] === "vehicleinsurance") {
        // $("#uploadingDocTitle").text("Uploading Vehicle insurance...");
        base64Img = vehicle_insurance;
        imageType = "vehicleinsurance";
      } else if (imageTypeArr[count] === "driverbadge") {
        // $("#uploadingDocTitle").text("Uploading Driver badge...");
        base64Img = badge;
        imageType = "driverbadge";
      } else if (imageTypeArr[count] === "emissioncrt") {
        // $("#uploadingDocTitle").text("Uploading Emission certificate...");
        base64Img = emission_cert;
        imageType = "emissioncrt";
      } else if (imageTypeArr[count] === "roadtax") {
        // $("#uploadingDocTitle").text("Uploading Road tax...");
        base64Img = road_tax;
        imageType = "roadtax";
      } else if (imageTypeArr[count] === "permit") {
        // $("#uploadingDocTitle").text("Uploading Permit...");
        base64Img = permit;
        imageType = "permit";
      } else if (imageTypeArr[count] === "fc") {
        // $("#uploadingDocTitle").text("Uploading FC...");
        base64Img = fitness_cert;
        imageType = "fc";
      } else if (imageTypeArr[count] === "salesagreement") {
        // $("#uploadingDocTitle").text("Uploading Sales aggrement...");
        base64Img = sales_agreement;
        imageType = "salesagreement";
      } else if (imageTypeArr[count] === "policeverification") {
        // $("#uploadingDocTitle").text("Uploading Police verification...");
        base64Img = police_verificatiton;
        imageType = "policeverification";
      }
      let reqObj = {
        imageData: base64Img,
        imageType: imageType,
        dunique_id: dunique_id,
        executive_id: sessionStorage.getItem("user_id"),
      };
      axios
        .post(config.url + "onboard/updateDriverImages", reqObj)
        .then((response) => {
          if (response) {
            count++;
            console.log(count);
            if (count < 19) {
              setTimeout(worker, 2000);
            } else {
              setSuccess(true);
              setMessage("Driver onboarded successfully!! Redirecting.....");
              show();
              setTimeout(() => {
                window.location.assign("/driveraccounts/addeditdriver");
              }, 5000);
            }
            // console.log(response);
            // count.push(response.data);
            // console.log();
            // setPushArray((oldArray) => [...oldArray, response.data]);
          }
        });
    })();
    // console.log(push_array);
    // setSuccess(true);
    // setMessage("Driver onboarded successfully!! Redirecting.....");
    // show();
    // setTimeout(() => {
    //   window.location.assign("/driveraccounts/addeditdriver");
    // }, 15000);
  };

  function _handleSubmit(event) {
    if (event) event.preventDefault();
    if (isLastStep) {
      _submitForm();
    } else {
      setActiveStep(activeStep + 1);
      //   actions.setTouched({});
      //   actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const verifyRC = (e) => {
    if (e) {
      e.preventDefault();
    }
    setWaitingRc();
    let dataToSend = {
      phonenumber: phonenumber,
      vehicle_number: vehicle_num1 + "-" + vehicle_num2,
      dunique_id: dunique_id,
    };
    // rcSocket(dataToSend.dunique_id);
    axios
      .post(config.test_url + "verifyRC/", dataToSend)
      .then((response) => {
        if (response.data.message === "successfull") {
          // this.rcSocket(dataToSend.dunique_id);
        } else {
          setFailRc();
        }
      })
      .catch((error) => {
        setRcVerified(undefined);
        setDisableRC(false);
        // this.setState({
        //   rcVerified: undefined,
        //   disableRC: false,
        // });
      });
  };
  const verifyDL = (e) => {
    if (e) {
      e.preventDefault();
    }
    setWaitingDl();
    let dataToSend = {
      phonenumber: phonenumber,
      dob: moment(dldob).format("DD-MM-yyyy"),
      dlno: dlno,
      dunique_id: dunique_id,
    };
    // dlSocket(dataToSend.dunique_id);
    axios
      .post(config.test_url + "verifyDL/", dataToSend)
      .then((response) => {
        if (response.data.message === "successfull") {
          // this.dlSocket(dataToSend.dunique_id);
        } else {
          setFailDl();
        }
      })
      .catch((error) => {
        setDlVerified(undefined);
        setDisableDl(false);
        // this.setState({
        //   dlVerified: undefined,
        //   disableDl: false,
        // });
      });
  };

  const verifyPAN = (e) => {
    if (e) {
      e.preventDefault();
    }
    setWaitingPan();
    let dataToSend = {
      pan_no: panNumber,
      name: pan_name,
      dob: moment(pandob).format("DD/MM/yyyy"),
      phonenumber: phonenumber,
      dunique_id: dunique_id,
    };
    // panSocket(dataToSend.dunique_id);
    // console.log(dataToSend);
    // let dataToSend = {
    //   pan_no: "GUZPS9603G",
    //   phonenumber: "8951973199",
    //   name: "SIDHARTH M A",
    //   dob: "31/03/1993",
    //   dunique_id: "5960d4e5607ce547af585e74",
    // };
    axios
      .post(config.test_url + "verifyPAN/", dataToSend)
      .then((response) => {
        if (response.data.message === "successfull") {
          // socket.connect("pan_" + dataToSend.dunique_id, () => {
          //   console.log(socket.connected);
          // });
        } else {
          setFailPan();
        }
      })
      .catch((error) => {
        setPanVerified(undefined);
        setDisablePAN(false);
        // this.setState({
        //   panVerified: undefined,
        //   disablePAN: false,
        // });
      });
  };

  const setFailPan = () => {
    setPanVerified("failed");
    setDisablePAN(false);
    // this.setState({
    //   panVerified: "failed",
    //   disablePAN: false,
    // });
  };

  const setWaitingPan = () => {
    setPanVerified("waiting");
    setDisablePAN(true);
    // this.setState({
    //   panVerified: "waiting",
    //   disablePAN: true,
    // });
  };

  const setFailDl = () => {
    setDlVerified("failed");
    setDisableDl(false);
    // this.setState({
    //   dlVerified: "failed",
    //   disableDl: false,
    // });
  };

  const setWaitingDl = () => {
    setDlVerified("waiting");
    setDisableDl(true);
    // this.setState({
    //   dlVerified: "waiting",
    //   disableDl: true,
    // });
  };

  const setFailRc = () => {
    setRcVerified("failed");
    setDisableRC(true);
    // this.setState({
    //   rcVerified: "failed",
    //   disableRC: false,
    // });
  };

  const setWaitingRc = () => {
    setRcVerified("waiting");
    setDisableRC(true);
    // this.setState({
    //   rcVerified: "waiting",
    //   disableRC: true,
    // });
  };
  // const panSocket = (dunique_id) => {
  //   console.log(dunique_id);

  //   socket.on("pan_" + dunique_id, (data) => {
  //     console.log(data);
  //     if (data.message === "successfull" && data.data.status === "valid") {
  //       // this.setState(
  //       //   {
  //       //     panVerified: "verified",
  //       //     panverifiedData: data.data,
  //       //   },
  //       //   () => {
  //       //     this.callModal();
  //       //   }
  //       // );

  //       setPanVerified("verified");
  //     } else {
  //       console.log(data);
  //       setFailPan();
  //     }
  //   });
  // };

  // const dlSocket = (dunique_id) => {
  //   socket.on("dl_" + dunique_id, (data) => {
  //     console.log(data);
  //     if (data.message === "successfull") {
  //       setDlVerified("verified");
  //       // this.setState(
  //       //   {
  //       //     dlVerified: "verified",
  //       //     dlverifiedData: data.data,
  //       //   },
  //       //   () => {
  //       //     this.callModal();
  //       //   }
  //       // );
  //     } else {
  //       // console.log(data);
  //       setFailDl();
  //     }
  //   });
  // };

  // const rcSocket = (dunique_id) => {
  //   socket.on("rc_" + dunique_id, (data) => {
  //     console.log(data);
  //     if (data.message === "successfull") {
  //       setRcVerified("verified");
  //     } else {
  //       // console.log(data);
  //       setFailRc();
  //     }
  //   });
  // };

  const handlePanName = (e) => {
    setPanName(e.target.value);
  };
  const handlePanNumber = (e) => {
    setPanNum(e.target.value);
  };
  const handlePanDateChange = (date) => {
    setPandob(date);
  };

  const handleVehiclenumChange = (event, type) => {
    if (type === "f1") {
      setVehicleNum1(event.target.value);
      // this.setState({
      //   vehicle_num1: event.target.value,
      // });
    } else {
      setVehicleNum2(event.target.value);
      // this.setState({
      //   vehicle_num2: event.target.value,
      // });
    }
  };

  const handleDLChange = (date) => {
    setDldob(date);
  };

  const handleDldataChange = (e) => {
    setDlno(e.target.value);
  };

  const goToStart = () => {
    window.location.assign("/driveraccounts/addeditdriver");
  };
  // console.log(getDutyPreference());
  return (
    <React.Fragment>
      <Container fluid className="main-content-container">
        <Row>
          <Col className="col-12 ">
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.step,
                        completed: classes.completed,
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col className="col-12 px-3 py-3">
            {showVerificationBox === false && (
              <Form onSubmit={_handleSubmit}>
                {_renderStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <div className={classes.wrapper}>
                      <Btn
                        onClick={_handleBack}
                        className={classes.button}
                        size="lg"
                        theme="dark"
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: "700",
                          minWidth: "200px",
                          borderRadius: "3px",
                        }}
                      >
                        BACK
                      </Btn>
                    </div>
                  )}
                  <div className={classes.wrapper}>
                    <Btn
                      disabled={isSubmitting}
                      size="lg"
                      type="submit"
                      theme="dark"
                      className={classes.button}
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "700",
                        minWidth: "200px",
                        borderRadius: "3px",
                      }}
                    >
                      {isLastStep ? "SUBMIT" : "NEXT"}
                    </Btn>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
            {showVerificationBox === true && (
              <Row className="px-md-3 my-4">
                <Col className="col-12 col-md-12 col-lg-12">
                  <Card>
                    <CardBody className="mb-4">
                      <React.Fragment>
                        <Row>
                          <Col className="col-12 d-flex justify-content-center my-4">
                            <label
                              style={{
                                fontWeight: 800,
                                fontSize: "calc(1rem + 1vmin)",
                              }}
                            >
                              Verify driver documents
                            </label>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-12">
                            <Row>
                              <Col className="col-12 col-md-4">
                                <Form onSubmit={verifyDL}>
                                  <FormGroup>
                                    <label style={{ fontWeight: 800 }}>
                                      Verify driving details
                                    </label>
                                    <div>
                                      <img
                                        src={DL}
                                        height="auto"
                                        width="250"
                                      ></img>
                                    </div>
                                  </FormGroup>
                                  <FormGroup>
                                    <label
                                      style={{
                                        fontSize: "calc(0.4rem + 1vmin)",
                                        fontWeight: 800,
                                      }}
                                    >
                                      Enter D.O.B as DL
                                    </label>
                                    {/* <FormInput
                              placeholder="Select date of birth"
                              className="py-3"
                              required
                            /> */}
                                    <ReactDatePicker
                                      className="form-control py-3"
                                      selected={dldob}
                                      onChange={(date) => handleDLChange(date)}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="Select date of birth"
                                      readOnly={disableDl}
                                    />
                                    {/*  readOnly={disableDl} */}
                                  </FormGroup>
                                  <FormGroup>
                                    <label
                                      style={{
                                        fontSize: "calc(0.4rem + 1vmin)",
                                        fontWeight: 800,
                                      }}
                                    >
                                      Driving license number
                                    </label>
                                    <FormInput
                                      placeholder="Enter DL number"
                                      className="py-3"
                                      type="text"
                                      minLength="15"
                                      maxLength="15"
                                      value={dlno}
                                      onChange={handleDldataChange}
                                      readOnly={disableDl}
                                      required
                                    />
                                    {/*   readOnly={disableDl} */}
                                  </FormGroup>
                                  <FormGroup className="mt-4">
                                    <Btn
                                      className="py-3 px-5"
                                      style={{
                                        fontSize: "calc(0.4rem + 1vmin)",
                                        fontWeight: 800,
                                      }}
                                      disabled={disableDl}
                                    >
                                      {/* disabled={disableDl} */}
                                      VERIFY
                                    </Btn>
                                  </FormGroup>
                                  <FormGroup className="mt-5">
                                    {dlVerified === "waiting" && (
                                      <React.Fragment>
                                        <div className="d-inline-flex">
                                          <Loader
                                            type="Oval"
                                            color="#2E8EFD"
                                            height="auto"
                                            width={20}
                                            className="mx-2"
                                          />
                                          <label className="mx-2 my-auto">
                                            Verifying license ...
                                          </label>
                                        </div>
                                      </React.Fragment>
                                    )}
                                    {dlVerified === "verified" && (
                                      <React.Fragment>
                                        <img
                                          src={CheckV}
                                          height="auto"
                                          width="25"
                                          className="mx-2"
                                        ></img>
                                        <label className="mx-2 my-auto">
                                          Verified !!
                                        </label>
                                      </React.Fragment>
                                    )}
                                    {dlVerified === "failed" && (
                                      <React.Fragment>
                                        <img
                                          src={ErrorV}
                                          height="auto"
                                          width="25"
                                          className="mx-2"
                                        ></img>
                                        <label
                                          className="mx-2"
                                          style={{ color: "#FF0000" }}
                                        >
                                          Verification failed! Retry
                                        </label>
                                      </React.Fragment>
                                    )}
                                  </FormGroup>
                                </Form>
                              </Col>

                              <Col className="col-12 col-md-4">
                                <Form onSubmit={verifyRC}>
                                  <FormGroup>
                                    <label style={{ fontWeight: 800 }}>
                                      Verify RC
                                    </label>
                                    <div>
                                      <img
                                        src={RC}
                                        height="auto"
                                        width="250"
                                      ></img>
                                    </div>
                                  </FormGroup>
                                  <FormGroup>
                                    <label
                                      style={{
                                        fontSize: "calc(0.4rem + 1vmin)",
                                        fontWeight: 800,
                                      }}
                                    >
                                      Enter the vehicle number
                                    </label>
                                    <Row>
                                      <Col className="col-5">
                                        <FormInput
                                          placeholder="KB72L"
                                          value={vehicle_num1}
                                          onChange={(e) =>
                                            handleVehiclenumChange(e, "f1")
                                          }
                                          readOnly={disableRC}
                                          className="py-3"
                                          required
                                        />
                                        {/* readOnly={disableRC} */}
                                      </Col>
                                      <Col className="col-5">
                                        <FormInput
                                          placeholder="9607"
                                          className="py-3"
                                          value={vehicle_num2}
                                          onChange={(e) =>
                                            handleVehiclenumChange(e, "f2")
                                          }
                                          readOnly={disableRC}
                                          required
                                        />
                                        {/*  readOnly={disableRC} */}
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup className="mt-4">
                                    <Btn
                                      className="py-3 px-5"
                                      style={{
                                        fontSize: "calc(0.4rem + 1vmin)",
                                        fontWeight: 800,
                                      }}
                                      disabled={disableRC}
                                    >
                                      {/* disabled={disableRC} */}
                                      VERIFY
                                    </Btn>
                                  </FormGroup>
                                  <FormGroup className="mt-5">
                                    {rcVerified === "waiting" && (
                                      <React.Fragment>
                                        <div className="d-inline-flex">
                                          <Loader
                                            type="Oval"
                                            color="#2E8EFD"
                                            height="auto"
                                            width={20}
                                            className="mx-2"
                                          />
                                          <label className="mx-2 my-auto">
                                            Verifying RC ...
                                          </label>
                                        </div>
                                      </React.Fragment>
                                    )}
                                    {rcVerified === "verified" && (
                                      <React.Fragment>
                                        <img
                                          src={CheckV}
                                          height="auto"
                                          width="25"
                                          className="mx-2"
                                        ></img>
                                        <label className="mx-2 my-auto">
                                          Verified !!
                                        </label>
                                      </React.Fragment>
                                    )}
                                    {rcVerified === "failed" && (
                                      <React.Fragment>
                                        <img
                                          src={ErrorV}
                                          height="auto"
                                          width="25"
                                          className="mx-2"
                                        ></img>
                                        <label
                                          className="mx-2"
                                          style={{ color: "#FF0000" }}
                                        >
                                          Verification failed! Retry
                                        </label>
                                      </React.Fragment>
                                    )}
                                  </FormGroup>
                                </Form>
                              </Col>

                              <Col className="col-12 col-md-4">
                                <Form onSubmit={verifyPAN}>
                                  <FormGroup>
                                    <label style={{ fontWeight: 800 }}>
                                      Verify PAN
                                    </label>
                                    <div>
                                      <img
                                        src={PAN}
                                        height="auto"
                                        width="250"
                                      ></img>
                                    </div>
                                  </FormGroup>
                                  <FormGroup>
                                    <label
                                      style={{
                                        fontSize: "calc(0.4rem + 1vmin)",
                                        fontWeight: 800,
                                      }}
                                    >
                                      Name as in PAN
                                    </label>
                                    <FormInput
                                      placeholder="Enter your name"
                                      className="py-3"
                                      value={pan_name}
                                      onChange={handlePanName}
                                      type="text"
                                      maxLength="24"
                                      required
                                      readOnly={disablePAN}
                                    />
                                    {/*  readOnly={disablePAN} */}
                                  </FormGroup>
                                  <FormGroup>
                                    <label
                                      style={{
                                        fontSize: "calc(0.4rem + 1vmin)",
                                        fontWeight: 800,
                                      }}
                                    >
                                      PAN number
                                    </label>
                                    <FormInput
                                      placeholder="Enter PAN number"
                                      value={panNumber}
                                      onChange={handlePanNumber}
                                      className="py-3"
                                      required
                                      readOnly={disablePAN}
                                    />
                                    {/* readOnly={disablePAN} */}
                                  </FormGroup>
                                  <FormGroup>
                                    <label
                                      style={{
                                        fontSize: "calc(0.4rem + 1vmin)",
                                        fontWeight: 800,
                                      }}
                                    >
                                      D.O.B as in PAN
                                    </label>

                                    <ReactDatePicker
                                      className="form-control py-3"
                                      selected={pandob}
                                      onChange={(date) =>
                                        handlePanDateChange(date)
                                      }
                                      dateFormat="dd/MM/yyyy"
                                      readOnly={disablePAN}
                                      placeholderText="Select date of birth"
                                    />
                                    {/*  readOnly={disablePAN} */}
                                  </FormGroup>
                                  <FormGroup className="mt-4">
                                    <Btn
                                      className="py-3 px-5"
                                      style={{
                                        fontSize: "calc(0.4rem + 1vmin)",
                                        fontWeight: 800,
                                      }}
                                      disabled={disablePAN}
                                    >
                                      {/*  disabled={disablePAN} */}
                                      VERIFY
                                    </Btn>
                                  </FormGroup>
                                  <FormGroup className="mt-5">
                                    {panVerified === "waiting" && (
                                      <React.Fragment>
                                        <div className="d-inline-flex">
                                          <Loader
                                            type="Oval"
                                            color="#2E8EFD"
                                            height="auto"
                                            width={20}
                                            className="mx-2"
                                          />
                                          <label className="mx-2 my-auto">
                                            Verifying PAN ...
                                          </label>
                                        </div>
                                      </React.Fragment>
                                    )}
                                    {panVerified === "verified" && (
                                      <React.Fragment>
                                        <img
                                          src={CheckV}
                                          height="auto"
                                          width="25"
                                          className="mx-2"
                                        ></img>
                                        <label className="mx-2 my-auto">
                                          Verified !!
                                        </label>
                                      </React.Fragment>
                                    )}
                                    {panVerified === "failed" && (
                                      <React.Fragment>
                                        <img
                                          src={ErrorV}
                                          height="auto"
                                          width="25"
                                          className="mx-2"
                                        ></img>
                                        <label
                                          className="mx-2"
                                          style={{ color: "#FF0000" }}
                                        >
                                          Verification failed! Retry
                                        </label>
                                      </React.Fragment>
                                    )}
                                  </FormGroup>
                                </Form>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-12 d-flex justify-content-center">
                            <Btn
                              className="py-3 px-5"
                              style={{
                                fontSize: "calc(0.4rem + 1vmin)",
                                fontWeight: 800,
                              }}
                              onClick={goToStart}
                            >
                              CONTINUE
                            </Btn>
                          </Col>
                          {/* <Col></Col> */}
                        </Row>
                      </React.Fragment>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        {success === false && (
          <ReactSnackBar
            Icon={
              <i
                className="far fa-times-circle fa-lg"
                style={{ color: "#FF0000" }}
              />
            }
            Show={Show}
          >
            {message}
          </ReactSnackBar>
        )}
        {success === true && (
          <ReactSnackBar
            Icon={
              <span>
                <i
                  className="far fa-check-circle fa-lg"
                  style={{ color: "#26FF58" }}
                />
              </span>
            }
            Show={Show}
          >
            {message}
          </ReactSnackBar>
        )}
      </Container>
    </React.Fragment>
  );
}

export default AddDriverMain;
