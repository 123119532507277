// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Collapse,
  Button,
  Tooltip,
  Card,
  CardBody,
  FormCheckbox,
  FormSelect,
} from "shards-react";
import PageTitle from "./../components/common/PageTitle";
import InputDataForm from "./../components/common/InputDataForm";
import TextArea from "./../components/common/TextArea";
import DragAndDrop from "./../components/common/DragAndDrop";
import DatePicker from "react-date-picker";
import AddBankDetails from "./../views/AddBankDetails";
import ReactDatepicker from "react-datepicker";
import { Form } from "react-bootstrap";

class AddVehicleData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicle_name: "",
      vehicle_number: "",
      vehicle_color: "",
      vehicle_make: "",
      vehicle_model: "",
      badge_number: "",
      permit_number: "",
      aadhar_number: "",
      pan_number: "",
      dl_number: "",
      add_vehicle_data: true,
      add_bank_data: false,
      dl_expiry: "",
      badge_expiry: "",
      emission_expiry: "",
      insurance_expiry: "",
      permit_expiry: "",
      fc_expiry: "",
      vehicle_type: "Hatchback",
      driving_license: "",
      registration_cert: "",
      sales_agreement: "",
      badge: "",
      emission_cert: "",
      vehicle_insurance: "",
      road_tax: "",
      permit: "",
      fitness_cert: "",
      pan_card: "",
      aadhar_back: "",
      aadhar_front: "",
      driv_image: "",
    };
    console.log(props);
    this.handleKeyChange = this.handleKeyChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handledlchange = this.handledlchange.bind(this);
    this.handleVehicleChange = this.handleVehicleChange.bind(this);
    this.handleBadgechange = this.handleBadgechange.bind(this);
    this.handleEmchange = this.handleEmchange.bind(this);
    this.handledlInsurchange = this.handledlInsurchange.bind(this);
    this.handledlPermitchange = this.handledlPermitchange.bind(this);
    this.handledlFcchange = this.handledlFcchange.bind(this);
    this.handleImage = this.handleImage.bind(this);
  }
  handleImage(image, name) {
    console.log(image);
    const newState = {};
    newState[name] = image;
    this.setState({ ...this.state, ...newState });
  }
  handleVehicleChange(event) {
    this.setState({
      vehicle_type: event.target.value,
    });
  }
  handledlchange(date) {
    this.setState({
      dl_expiry: date,
    });
  }
  handleBadgechange(date) {
    this.setState({
      badge_expiry: date,
    });
  }
  handleEmchange(date) {
    this.setState({
      emission_expiry: date,
    });
  }
  handledlInsurchange(date) {
    this.setState({
      insurance_expiry: date,
    });
  }
  handledlPermitchange(date) {
    this.setState({
      permit_expiry: date,
    });
  }
  handledlFcchange(date) {
    this.setState({
      fc_expiry: date,
    });
  }
  // Handle checkbox change
  handleKeyChange(e, name) {
    const newState = {};
    let value;
    if (name === "vehicle_model") {
      value = e;
      newState[name] = value;
      this.setState({ ...this.state, ...newState });
    } else {
      newState[name] = e.target.value;
      this.setState({ ...this.state, ...newState });
    }
  }
  handleClick() {
    //Do API call
    this.setState(
      {
        add_vehicle_data: !this.state.add_vehicle_data,
        add_bank_data: !this.state.add_bank_data,
      },
      () => {
        this.props.progressState("data");
      }
    );
  }

  render() {
    // console.log("****************");
    // console.log(data);
    return (
      <div className="px-3">
        {this.state.add_vehicle_data === true && (
          <div>
            <Form onSubmit={this.handleClick}>
              <Row>
                <Col className="col-12 col-md-12">
                  <div className="float-right mt-2">
                    <Button
                      className=""
                      id="goback-button"
                      onClick={this.props.goBack}
                    >
                      <i className="fas fa-arrow-left mx-1" />
                      <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                        BACK
                      </span>
                    </Button>
                  </div>
                </Col>
                <Col className="col-12 col-md-12 col-lg-12">
                  <Card>
                    <CardBody>
                      <Row>
                        <h5>Vehicle Details</h5>
                      </Row>
                      <Row>
                        {this.props.inputValues.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-4 col-lg-4 py-2"
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.hits)} */}
                            <InputDataForm
                              id={`vehicle-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state[stats.name]}
                              stats={stats.stats}
                              name={stats.name}
                              onKeyChange={this.handleKeyChange}
                            />
                          </Col>
                        ))}
                        <Col className="col-12 col-md-4 col-lg-4 col-xl-4 py-2">
                          <label className="label-style">
                            Choose Vehicle type
                          </label>
                          <FormSelect
                            style={{ height: "calc(3.16rem + 2px)" }}
                            value={this.state.vehicle_type}
                            onChange={this.handleVehicleChange}
                          >
                            <option value="Hatchback">Hatchback</option>
                            <option value="Sedan">Sedan</option>
                            <option value="SUV">SUV</option>
                          </FormSelect>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <h5>Vehicle images</h5>
                      </Row>
                      <Row>
                        {this.props.vehicleImages.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-4 col-lg-4 py-2"
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.hits)} */}
                            <label>{stats.label}</label>
                            <DragAndDrop
                              id={`vehicel-images-${idx}`}
                              variation="1"
                              label={stats.label}
                              stats={stats.stats}
                              name={stats.name}
                              onDropHandler={this.handleImage}
                            />
                          </Col>
                        ))}
                      </Row>
                      <hr />
                      <Row>
                        <Col className="col-12 col-md-9 col-lg-9 my-2">
                          <Card>
                            <CardBody>
                              <Row>
                                <h5>Aadhar card</h5>
                              </Row>
                              <Row>
                                {this.props.aadharImages.map((stats, idx) => (
                                  <Col
                                    className="col-12 col-md-6 col-lg-6 mb-4"
                                    key={idx}
                                    {...stats.attrs}
                                  >
                                    {/* {console.log(this.state.hits)} */}
                                    <DragAndDrop
                                      id={`aadhar-images-${idx}`}
                                      variation="1"
                                      label={stats.label}
                                      stats={stats.stats}
                                      name={stats.name}
                                      onDropHandler={this.handleImage}
                                    />
                                  </Col>
                                ))}
                              </Row>
                              <Row>
                                {this.props.aadharData.map((stats, idx) => (
                                  <Col
                                    className="col-12 col-md-6 col-lg-6 mt-2"
                                    key={idx}
                                    {...stats.attrs}
                                  >
                                    {/* {console.log(this.state.hits)} */}
                                    <InputDataForm
                                      id={`aadhar-data-${idx}`}
                                      variation="1"
                                      label={stats.label}
                                      value={this.state[stats.name]}
                                      stats={stats.stats}
                                      name={stats.name}
                                      onKeyChange={this.handleKeyChange}
                                    />
                                  </Col>
                                ))}
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-12 col-md-6 col-lg-6 my-1">
                          <Card>
                            <CardBody>
                              <Row>
                                <h5>Pan Card</h5>
                              </Row>
                              <Row>
                                {this.props.panImages.map((stats, idx) => (
                                  <Col
                                    className="col-12 col-md-12 col-lg-12"
                                    key={idx}
                                    {...stats.attrs}
                                  >
                                    {/* {console.log(this.state.hits)} */}
                                    <DragAndDrop
                                      id={`pan-images-${idx}`}
                                      variation="1"
                                      label={stats.label}
                                      stats={stats.stats}
                                      name={stats.name}
                                      onDropHandler={this.handleImage}
                                    />
                                  </Col>
                                ))}
                              </Row>
                              <Row>
                                {this.props.panCarddata.map((stats, idx) => (
                                  <Col
                                    className="col-12 col-md-6 col-lg-6 mt-2"
                                    key={idx}
                                    {...stats.attrs}
                                  >
                                    {/* {console.log(this.state.hits)} */}
                                    <InputDataForm
                                      id={`pan-stats-${idx}`}
                                      variation="1"
                                      label={stats.label}
                                      value={this.state[stats.name]}
                                      stats={stats.stats}
                                      name={stats.name}
                                      onKeyChange={this.handleKeyChange}
                                    />
                                  </Col>
                                ))}
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col className="col-12 col-md-6 col-lg-6 my-1">
                          <Card>
                            <CardBody>
                              <Row>
                                <h5>Driving License</h5>
                              </Row>
                              <Row>
                                {this.props.drivindLicense.map((stats, idx) => (
                                  <Col
                                    className="col-12 col-md-12 col-lg-12"
                                    key={idx}
                                    {...stats.attrs}
                                  >
                                    {/* {console.log(this.state.hits)} */}
                                    <DragAndDrop
                                      id={`driving-license-${idx}`}
                                      variation="1"
                                      label={stats.label}
                                      stats={stats.stats}
                                      name={stats.name}
                                      onDropHandler={this.handleImage}
                                    />
                                  </Col>
                                ))}
                              </Row>
                              <Row>
                                {this.props.dlnumber.map((stats, idx) => (
                                  <Col
                                    className="col-12 col-md-6 col-lg-6 mt-2"
                                    key={idx}
                                    {...stats.attrs}
                                  >
                                    {/* {console.log(this.state.hits)} */}
                                    <InputDataForm
                                      id={`dl-number-${idx}`}
                                      variation="1"
                                      label={stats.label}
                                      value={this.state[stats.name]}
                                      stats={stats.stats}
                                      name={stats.name}
                                      onKeyChange={this.handleKeyChange}
                                    />
                                  </Col>
                                ))}
                                <Col className="col-12 col-md-6 col-lg-6 mt-2">
                                  <label className="label-style">
                                    Expiry date
                                  </label>
                                  <ReactDatepicker
                                    className="form-control py-3"
                                    format="dd/MM/yyyy"
                                    selected={this.state.dl_expiry}
                                    onChange={this.handledlchange}
                                    placeholderText="Enter expiry date"
                                  />
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col className="col-12 col-md-6 col-lg-6 my-2">
                          <Card>
                            <CardBody>
                              <Row>
                                <h5>Registration Certificate</h5>
                              </Row>
                              <Row>
                                {this.props.registrationCertificate.map(
                                  (stats, idx) => (
                                    <Col
                                      className="col-12 col-md-12 col-lg-12"
                                      key={idx}
                                      {...stats.attrs}
                                    >
                                      {/* {console.log(this.state.hits)} */}
                                      <DragAndDrop
                                        id={`reg-cert-${idx}`}
                                        variation="1"
                                        label={stats.label}
                                        stats={stats.stats}
                                        name={stats.name}
                                        onDropHandler={this.handleImage}
                                      />
                                    </Col>
                                  )
                                )}
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col className="col-12 col-md-6 col-lg-6 my-2">
                          <Card>
                            <CardBody>
                              <Row>
                                <h5>Sales agreement</h5>
                              </Row>
                              <Row>
                                {this.props.salseAgreement.map((stats, idx) => (
                                  <Col
                                    className="col-12 col-md-12 col-lg-12"
                                    key={idx}
                                    {...stats.attrs}
                                  >
                                    {/* {console.log(this.state.hits)} */}
                                    <DragAndDrop
                                      id={`sales-agrmt-${idx}`}
                                      variation="1"
                                      label={stats.label}
                                      stats={stats.stats}
                                      name={stats.name}
                                      onDropHandler={this.handleImage}
                                    />
                                  </Col>
                                ))}
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col className="col-12 col-md-6 col-lg-6 my-2">
                          <Card>
                            <CardBody>
                              <Row>
                                <h5>Badge</h5>
                              </Row>
                              <Row>
                                {this.props.badge.map((stats, idx) => (
                                  <Col
                                    className="col-12 col-md-12 col-lg-12"
                                    key={idx}
                                    {...stats.attrs}
                                  >
                                    {/* {console.log(this.state.hits)} */}
                                    <DragAndDrop
                                      id={`badge-stats-${idx}`}
                                      variation="1"
                                      label={stats.label}
                                      stats={stats.stats}
                                      name={stats.name}
                                      onDropHandler={this.handleImage}
                                    />
                                  </Col>
                                ))}
                              </Row>
                              <Row>
                                {this.props.badgeNumber.map((stats, idx) => (
                                  <Col
                                    className="col-12 col-md-6 col-lg-6 mt-2"
                                    key={idx}
                                    {...stats.attrs}
                                  >
                                    {/* {console.log(this.state.hits)} */}
                                    <InputDataForm
                                      id={`badge-number-${idx}`}
                                      variation="1"
                                      label={stats.label}
                                      value={this.state[stats.name]}
                                      stats={stats.stats}
                                      name={stats.name}
                                      onKeyChange={this.handleKeyChange}
                                    />
                                  </Col>
                                ))}
                                <Col className="col-12 col-md-6 col-lg-6 mt-2">
                                  <label className="label-style" htmlFor="">
                                    Expiry date
                                  </label>
                                  <ReactDatepicker
                                    className="form-control  py-3"
                                    format="dd/MM/yyyy"
                                    selected={this.state.badge_expiry}
                                    onChange={this.handleBadgechange}
                                    placeholderText="Enter expiry date"
                                  />
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col className="col-12 col-md-6 col-lg-6 my-2">
                          <Card>
                            <CardBody>
                              <Row>
                                <h5>Emission Certificate</h5>
                              </Row>
                              <Row>
                                {this.props.emissionCert.map((stats, idx) => (
                                  <Col
                                    className="col-12 col-md-12 col-lg-12"
                                    key={idx}
                                    {...stats.attrs}
                                  >
                                    {/* {console.log(this.state.hits)} */}
                                    <DragAndDrop
                                      id={`emission-cert-${idx}`}
                                      variation="1"
                                      label={stats.label}
                                      stats={stats.stats}
                                      name={stats.name}
                                      onDropHandler={this.handleImage}
                                    />
                                  </Col>
                                ))}
                              </Row>
                              <Row>
                                <Col className="col-12 col-md-6 col-lg-6 mt-2">
                                  <label className="label-style">
                                    Expiry date
                                  </label>
                                  <ReactDatepicker
                                    format="dd/MM/yyyy"
                                    className="form-control  py-3"
                                    selected={this.state.emission_expiry}
                                    onChange={this.handleEmchange}
                                    placeholderText="Enter expiry date"
                                  />
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col className="col-12 col-md-6 col-lg-6 my-2">
                          <Card>
                            <CardBody>
                              <Row>
                                <h5>Vehicle Insurance</h5>
                              </Row>
                              <Row>
                                {this.props.vehicleInsurance.map(
                                  (stats, idx) => (
                                    <Col
                                      className="col-12 col-md-12 col-lg-12"
                                      key={idx}
                                      {...stats.attrs}
                                    >
                                      {/* {console.log(this.state.hits)} */}
                                      <DragAndDrop
                                        id={`vehicle-insurance-${idx}`}
                                        variation="1"
                                        label={stats.label}
                                        stats={stats.stats}
                                        name={stats.name}
                                        onDropHandler={this.handleImage}
                                      />
                                    </Col>
                                  )
                                )}
                                <Col className="col-12 col-md-6 col-lg-6">
                                  <label className="label-style">
                                    Vehicle Insurance
                                  </label>
                                  <ReactDatepicker
                                    format="dd/MM/yyyy"
                                    className="form-control py-3"
                                    selected={this.state.insurance_expiry}
                                    onChange={this.handledlInsurchange}
                                    placeholderText="Enter expiry date"
                                  />
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col className="col-12 col-md-6 col-lg-6 my-2">
                          <Card>
                            <CardBody>
                              <Row>
                                <h5>Road Tax</h5>
                              </Row>
                              <Row>
                                {this.props.roadTax.map((stats, idx) => (
                                  <Col
                                    className="col-12 col-md-12 col-lg-12"
                                    key={idx}
                                    {...stats.attrs}
                                  >
                                    {/* {console.log(this.state.hits)} */}
                                    <DragAndDrop
                                      id={`road-tax-${idx}`}
                                      variation="1"
                                      label={stats.label}
                                      stats={stats.stats}
                                      name={stats.name}
                                      onDropHandler={this.handleImage}
                                    />
                                  </Col>
                                ))}
                              </Row>
                              <Row>
                                <Col className="col-12 col-md-6 col-lg-6 pb-5 pt-3" />
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col className="col-12 col-md-6 col-lg-6 my-2">
                          <Card>
                            <CardBody>
                              <Row>
                                <h5>Permit</h5>
                              </Row>
                              <Row>
                                {this.props.permit.map((stats, idx) => (
                                  <Col
                                    className="col-12 col-md-12 col-lg-12"
                                    key={idx}
                                    {...stats.attrs}
                                  >
                                    {/* {console.log(this.state.hits)} */}
                                    <DragAndDrop
                                      id={`permit-stats-${idx}`}
                                      variation="1"
                                      label={stats.label}
                                      stats={stats.stats}
                                      name={stats.name}
                                      onDropHandler={this.handleImage}
                                    />
                                  </Col>
                                ))}
                              </Row>
                              <Row>
                                <Col className="col-12 col-md-6 col-lg-6 mt-2">
                                  <label className="label-style">
                                    Expiry date
                                  </label>
                                  <ReactDatepicker
                                    format="dd/MM/yyyy"
                                    className="form-control  py-3"
                                    selected={this.state.permit_expiry}
                                    onChange={this.handledlPermitchange}
                                    placeholderText="Enter expiry date"
                                  />
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col className="col-12 col-md-6 col-lg-6 my-2">
                          <Card>
                            <CardBody>
                              <Row>
                                <h5>Fitness Certificate</h5>
                              </Row>
                              <Row>
                                {this.props.fitnessCert.map((stats, idx) => (
                                  <Col
                                    className="col-12 col-md-12 col-lg-12"
                                    key={idx}
                                    {...stats.attrs}
                                  >
                                    {/* {console.log(this.state.hits)} */}
                                    <DragAndDrop
                                      id={`fitness-cert-${idx}`}
                                      variation="1"
                                      label={stats.label}
                                      stats={stats.stats}
                                      name={stats.name}
                                      onDropHandler={this.handleImage}
                                    />
                                  </Col>
                                ))}
                              </Row>
                              <Row>
                                <Col className="col-12 col-md-6 col-lg-6 pb-5 pt-3" />
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mx-auto mt-4 mb-4">
                <Col className="col-4 " />
                <Col className="col-12 col-md-4 d-flex justify-content-center">
                  {/* <Button className="label-style" onClick={this.handleClick}> */}
                  <Button className="py-3">UPDATE BANK DETAILS</Button>
                </Col>
                <Col className="col-4 " />
              </Row>
            </Form>
          </div>
        )}

        {this.state.add_vehicle_data === false &&
          this.state.add_bank_data === true && (
            <AddBankDetails
              dataState={this.state}
              oldProps={this.props.driverState}
            />
          )}
      </div>
    );
  }
}
AddVehicleData.propTypes = {
  inputValues: PropTypes.array,
};

AddVehicleData.defaultProps = {
  drivindLicense: [
    {
      label: "Driving License",
      value: "",
      attrs: {},
      stats: false,
      name: "driving_license",
    },
  ],
  registrationCertificate: [
    {
      label: "Registration certificate",
      value: "",
      attrs: {},
      stats: false,
      name: "registration_cert",
    },
  ],
  salseAgreement: [
    {
      label: "Sales agreement",
      value: "",
      attrs: {},
      stats: false,
      name: "sales_agreement",
    },
  ],
  badge: [
    {
      label: "Badge",
      value: "",
      attrs: {},
      stats: false,
      name: "badge",
    },
  ],
  emissionCert: [
    {
      label: "Emission certificate",
      value: "",
      attrs: {},
      stats: false,
      name: "emission_cert",
    },
  ],
  vehicleInsurance: [
    {
      label: "Vehicle Insurance",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_insurance",
    },
  ],
  roadTax: [
    {
      label: "Road Tax",
      value: "",
      attrs: {},
      stats: false,
      name: "road_tax",
    },
  ],
  permit: [
    {
      label: "Permit Image",
      value: "",
      attrs: {},
      stats: false,
      name: "permit",
    },
  ],
  fitnessCert: [
    {
      label: "Fitness Certificate",
      value: "",
      attrs: {},
      stats: false,
      name: "fitness_cert",
    },
  ],
  panCarddata: [
    {
      label: "Pan Number",
      value: "",
      attrs: {},
      stats: false,
      name: "pan_number",
    },
  ],
  dlnumber: [
    {
      label: "DL Number",
      value: "",
      attrs: {},
      stats: false,
      name: "dl_number",
    },
  ],
  badgeNumber: [
    {
      label: "Badge Number",
      value: "",
      attrs: {},
      stats: false,
      name: "badge_number",
    },
  ],
  permitNumber: [
    {
      label: "Permit Number",
      value: "",
      attrs: {},
      stats: false,
      name: "permit_number",
    },
  ],
  inputValues: [
    {
      label: "Vehicle Name",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_name",
    },
    {
      label: "Vehicle Number",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_number",
    },
    {
      label: "Vehicle Make",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_make",
    },
    {
      label: "Vehicle Model",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_model",
    },
    {
      label: "Vehicle Color",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_color",
    },
  ],
  vehicleImages: [
    {
      label: "Front Images",
      value: "",
      attrs: {},
      stats: false,
      name: "front_image",
    },
    {
      label: "Left Images",
      value: "",
      attrs: {},
      stats: false,
      name: "left_image",
    },
    {
      label: "Rear Images",
      value: "",
      attrs: {},
      stats: false,
      name: "rear_image",
    },
    {
      label: "Right Images",
      value: "",
      attrs: {},
      stats: false,
      name: "right_image",
    },
    {
      label: "Driver with vehicle Images",
      value: "",
      attrs: {},
      stats: false,
      name: "driv_image",
    },
  ],
  aadharImages: [
    {
      label: "Front Image",
      value: "",
      attrs: {},
      stats: false,
      name: "aadhar_front",
    },
    {
      label: "Back Image",
      value: "",
      attrs: {},
      stats: false,
      name: "aadhar_back",
    },
  ],
  aadharData: [
    {
      label: "Aadhar card number",
      value: "",
      attrs: {},
      stats: false,
      name: "aadhar_number",
    },
  ],
  familyAddressField: [
    {
      label: "Address",
      value: "",
      attrs: {},
      stats: false,
    },
  ],
  driverPreference: [
    {
      label: "Preferred Location",
      value: "",
      attrs: {},
      stats: false,
      name: "preferred_location",
    },
    {
      label: "Starting point",
      value: "",
      attrs: {},
      stats: false,
      name: "sarting_point",
    },
  ],
  panImages: [
    {
      label: "Pan Card",
      value: "",
      attrs: {},
      stats: false,
      name: "pan_card",
    },
  ],
};

export default AddVehicleData;
