import React, { useState, useEffect } from "react";
import { Card, FormGroup, Row, Col } from "react-bootstrap";
import { FormInput, Button } from "shards-react";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import axios from "axios";
import socketIOClient from "socket.io-client";
import emailValidator from "validator/lib/isEmail";
import CookieConsent from "react-cookie-consent";
import storageAvailable from "storage-available";
/**
 * External files and assets
 */
import logo from "../assets/logo.svg";
import LoginImg from "../assets/loginArt.svg";
import Check from "../assets/check.svg";
import Warning from "../assets/warning.svg";
import Cancel from "../assets/cancel.svg";
import FirstStep from "../assets/login_1_new.svg";
import SecondStep from "../assets/login_2_new.svg";
import stringsConstant from "../constants/stringsConstant.json";
import config from "../constants/constants.json";
import urls from "../constants/urlList.json";
import loginEmail from "../assets/email.svg";
import FaceDetect from "./FaceRecognition";
import FaceRecog from "./FaceScanner";

/**
 *  Socket connection
 */

const socket = socketIOClient(config.SOCKET, {
  transports: ["websocket"],
});

// const socket

function LoginView(props) {
  const [verified, setVerified] = useState(false);
  const [resetPass, setResetPass] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [operatingRegion, setOperatingRegion] = useState("");
  const [user_id, setUserID] = useState("");
  const [display, setDisplay] = useState("waiting");
  const [qrcode, setQrCode] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [validPassowrd, setValidPassword] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const [name, setName] = useState("");
  const [checkFace, setCheckFace] = useState(false);
  const [togglePass, setTogglePass] = useState(false);

  /**
   * Toggle password or text field
   */

  const toggleInput = () => {
    setTogglePass(!togglePass);
  };

  /**
   * Effect when reloading the login page
   */
  useEffect(() => {
    console.log(window);
    if (storageAvailable("sessionStorage")) {
      window.sessionStorage.clear();
    } else {
    }
  }, []);

  /**
   * Effect to handle socket condition
   */

  useEffect(() => {
    if (verified === true) {
      socket.on(stringsConstant.socketConstants.qrGen, (data) => {
        // console.log(data);
        if (
          data.message === stringsConstant.onsuccess &&
          data.user_id === user_id
        ) {
          // this.callSetState(data.qrCode);
          setQrCode(data.qrCode);
        }
      });
      socket.on(stringsConstant.socketConstants.locValidate, (data) => {
        console.log(checkFace);
        if (data === stringsConstant.validity.capvalid) {
          setDisplay(stringsConstant.validity.yes);
          setTimeout(() => {
            // if (checkFace === true) {
            //   setOpenAuth(true);
            // } else {
            //   loginUser();
            // }
            loginUser();
          }, 2000);

          // loginUser();
        } else if (data === stringsConstant.validity.gone) {
          setDisplay(stringsConstant.validity.gone);
          // this.setState(
          //   {
          //     exptoken: false,
          //     isVisible: true,
          //     display: "expired",
          //   },
          //   () => {
          //     setTimeout(
          //       function() {
          //         this.setState({ isVisible: false });
          //       }.bind(this),
          //       2500
          //     );
          //   }
          // );
        } else {
          setDisplay(stringsConstant.validity.no);
          // this.setState({
          //   display: "invalid",
          // });
        }
      });
    }
  }, [verified === true]);

  /**
   * Functionality to set email and password from input
   */

  const handleInputChange = (e, type) => {
    switch (type) {
      case stringsConstant.email:
        setEmail(e.target.value);
        break;
      case stringsConstant.password:
        setPassword(e.target.value);
        break;
      default:
        break;
    }
  };

  /**
   * Functionality to validate the user
   */

  const validateUser = () => {
    if (storageAvailable("sessionStorage")) {
      setDisabled(true);
      if (!emailValidator(email)) {
        setValidEmail(false);
        setDisabled(false);
      } else {
        let dataTosend = {
          username: email,
          email,
          password,
        };
        axios
          .post(config.url + urls.validateUser, dataTosend)
          .then((response) => {
            console.log(response);
            if (
              response.data.message === stringsConstant.onsuccess &&
              response.data.qrcode === false &&
              response.data.reset_pass === false &&
              response.data.firsttime === false
            ) {
              //&& response.data.reset_pass === false

              setUserID(response.data.user_id);
              setName(response.data.name);
              setOperatingRegion(response.data.region);
              window.sessionStorage.setItem(
                "operating_region",
                response.data.region
              );
              // if (response.data.check_face === true) {
              //   setOpenAuth(true);
              // } else {
              //   loginUser();
              // }

              loginUser();
              // setUserID(response.data.user_id);
              // setVerified(true);
              // setName(response.data.name);
              // getQrCode(response.data.user_id);
              // setDisabled(!disabled);
            } else if (
              response.data.message === stringsConstant.onsuccess &&
              response.data.qrcode === true &&
              response.data.reset_pass === false
            ) {
              // if (response.data.check_face === true) {
              //   setCheckFace(true);
              // } else {
              //   // Do nothing
              // }
              setUserID(response.data.user_id);
              setVerified(true);
              setName(response.data.name);
              getQrCode(response.data.user_id);
              setDisabled(!disabled);
              setOperatingRegion(response.data.region);
              window.sessionStorage.setItem(
                "operating_region",
                response.data.region
              );
            } else if (response.data.message === stringsConstant.email) {
              setValidEmail(false);
              setDisabled(false);
            } else if (response.data.message === stringsConstant.password) {
              setValidPassword(false);
              setDisabled(false);
            } else if (
              response.data.message === stringsConstant.onsuccess &&
              response.data.reset_pass === true
            ) {
              setResetPass(true);
            }
          })
          .catch((error) => {
            setTimeout(() => {
              setDisabled(false);
            }, 1000);
          });
      }
    } else {
      alert(
        "Cookies and session storage has been blocked for this site. Please enable them in the browser and reload the page again."
      );
    }
  };

  /**
   * Send data to server and retrieve qrcode
   */

  const getQrCode = (user_id) => {
    socket.emit(stringsConstant.socketConstants.qrGen, user_id);
  };

  /**
   * Refresh qr code
   */

  const refreshQr = () => {
    socket.emit(stringsConstant.socketConstants.qrGen, user_id);
    setDisplay(stringsConstant.validity.wait);
  };

  /**
   * Function to return to home page
   */

  const goBacktoHome = () => {
    window.location.assign("/");
  };

  /**
   * Functionality to login user to the panel directly without QR
   */

  const loginUser = () => {
    let dataTovalidate = {
      email,
      password,
    };

    axios.post(config.url + urls.loginUser, dataTovalidate).then((response) => {
      // console.log(response);
      if (response.data.message === stringsConstant.onsuccess) {
        let result = response.data;
        sessionStorage.setItem("user_id", result.user_id);
        sessionStorage.setItem("username", result.username);
        sessionStorage.setItem("phonenumber", result.phonenumber);
        sessionStorage.setItem("usertype", result.usertype);
        sessionStorage.setItem("first_name", result.first_name);
        sessionStorage.setItem("last_name", result.last_name);
        sessionStorage.setItem("roletype", result.roletype);
        sessionStorage.setItem("shift", result.shift);
        sessionStorage.setItem("email", result.email);
        sessionStorage.setItem("operating_region", response.data.region);
        sessionStorage.setItem("lt", response.data.login_token);
        openWindow();
      }
    });
  };

  /**
   * Route users based on the role
   */

  const openWindow = () => {
    setTimeout(function () {
      if (
        (window.sessionStorage.getItem("usertype") === "dispatchexecutive" &&
          window.sessionStorage.getItem("roletype") === "traineeexecutive") ||
        (window.sessionStorage.getItem("usertype") === "dispatchexecutive" &&
          window.sessionStorage.getItem("roletype") === "teamlead") ||
        (window.sessionStorage.getItem("usertype") === "dispatchexecutive" &&
          window.sessionStorage.getItem("roletype") === "seniorexecutive") ||
        (window.sessionStorage.getItem("usertype") === "dispatchexecutive" &&
          window.sessionStorage.getItem("roletype") === "juniorexecutive")
      ) {
        window.location.assign("/businessaccounts/bookings");
      } else if (
        (window.sessionStorage.getItem("usertype") === "supplyexecutive" &&
          window.sessionStorage.getItem("roletype") === "supplyexecutive") ||
        (window.sessionStorage.getItem("usertype") === "supplyexecutive" &&
          window.sessionStorage.getItem("roletype") === "traineeexecutive") ||
        (window.sessionStorage.getItem("usertype") === "supplyexecutive" &&
          window.sessionStorage.getItem("roletype") === "seniorexecutive") ||
        (window.sessionStorage.getItem("usertype") === "supplyexecutive" &&
          window.sessionStorage.getItem("roletype") === "seniorexecutive")
      ) {
        window.location.assign("/driveraccounts");
      } else {
        // window.location.assign("/businessaccounts/bookings");
        props.history.push("/businessaccounts/bookings");
      }
    }, 2000);
  };

  return (
    <React.Fragment>
      {openAuth === false && (
        <Row className="login-custom ">
          <Col
            className="col-12  ipad-spec-main"
            style={{ backgroundColor: "#F1F5FB" }}
          >
            <Row className="d-flex justify-content-center custom-row-height">
              <Col className="col-10 col-md-6 col-lg-8 my-auto custom-padding">
                {resetPass === false && (
                  <Card className="login-card">
                    {verified === false && (
                      <Card.Body className="">
                        <Row className="px-2 my-auto">
                          <Col className="col-12 d-flex justify-content-center mb-4 mt-2">
                            <img
                              src="/lgoosmall.png"
                              height="auto"
                              width="125"
                            ></img>
                          </Col>
                          <Col className="col-12 d-flex justify-content-center  mb-4">
                            <span className="login-text-new">
                              Enter the below details to continue
                            </span>
                          </Col>
                          <Col className="col-12 mb-3">
                            <FormGroup>
                              <label className="login-text-label">
                                Email address
                              </label>
                              <FormInput
                                className="py-3"
                                disabled={disabled}
                                placeholder="Enter your email address"
                                value={email}
                                invalid={!validEmail}
                                onChange={(e) =>
                                  handleInputChange(e, stringsConstant.email)
                                }
                              />
                              {!validEmail && (
                                <label className="login-invalid-msg px-2">
                                  Entered email ID is invalid
                                </label>
                              )}
                            </FormGroup>
                          </Col>
                          <Col className="col-12 mb-2">
                            <FormGroup>
                              <label className="login-text-label">
                                Password
                              </label>
                              <FormInput
                                className="py-3"
                                placeholder="Enter password to continue"
                                disabled={disabled}
                                value={password}
                                invalid={!validPassowrd}
                                type={
                                  togglePass === false ? "password" : "text"
                                }
                                onChange={(e) =>
                                  handleInputChange(e, stringsConstant.password)
                                }
                              />
                              {!validPassowrd && (
                                <label className="login-invalid-msg px-2">
                                  Entered password is incorrect
                                </label>
                              )}
                            </FormGroup>
                          </Col>
                          <Col className="col-12 mb-4">
                            <Row>
                              <Col className="col-6 d-flex justify-content-start">
                                {/* <Link
                                  to="/newUserCreation"
                                  className="login-link-text"
                                >
                                  First time user?
                                </Link> */}
                              </Col>
                              <Col className="col-6 d-flex justify-content-end">
                                <Link
                                  to="/resetpass"
                                  className="login-link-text"
                                >
                                  Forgot password?
                                </Link>
                              </Col>
                            </Row>
                          </Col>
                          <Col className="col-12 d-flex justify-content-center mb-2 mt-1">
                            <Button
                              theme="dark"
                              className="login-button-new py-3"
                              onClick={validateUser}
                              style={{
                                fontSize: "calc(0.5rem + 1vmin)",
                                fontWeight: 900,
                              }}
                            >
                              LOGIN
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    )}
                    {verified === true && (
                      <Card.Body>
                        <Row>
                          <Col className="col-12 mb-3 mt-2">
                            <label className="login-custom-heading">
                              To login, scan the QR code using the Glory login
                              application
                            </label>
                          </Col>
                          <Col
                            className="col-12 mb-4 d-flex justify-content-center"
                            style={{ minHeight: "150px" }}
                          >
                            {qrcode && (
                              <img src={qrcode} height="auto" width="150"></img>
                            )}
                          </Col>
                          <Col className="col-12 mb-3 px-2">
                            <div
                              style={{
                                background:
                                  "#F8F8F8 0% 0% no-repeat padding-box",
                                borderRadius: "8px",
                              }}
                            >
                              <div className="h-100 px-4 py-2 d-flex ">
                                {display === "waiting" && (
                                  <React.Fragment>
                                    <Loader
                                      type="Oval"
                                      color="black"
                                      height="auto"
                                      width={10}
                                    />
                                    {/* <Lottie
                                options={defaultOptions}
                                height="auto"
                                width={20}
                                isClickToPauseDisabled={true}
                              /> */}
                                    <label className="px-2 h-100 mb-0 py-2 login-verification-msg">
                                      Waiting for you to scan
                                    </label>
                                  </React.Fragment>
                                )}
                                {display === "valid" && (
                                  <React.Fragment>
                                    <img
                                      src={Check}
                                      height="auto"
                                      width={20}
                                    ></img>
                                    <label className="px-2 h-100 mb-0 py-2 login-verification-msg">
                                      Verification successful
                                    </label>
                                  </React.Fragment>
                                )}
                                {display === "expired" && (
                                  <React.Fragment>
                                    <img
                                      src={Warning}
                                      height="auto"
                                      width={15}
                                    ></img>
                                    <label className="px-2 h-100 mb-0 py-2 login-verification-msg">
                                      Verification failed !! Click{" "}
                                      <a
                                        onClick={() => refreshQr()}
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                      >
                                        here
                                      </a>{" "}
                                      to retry
                                    </label>
                                  </React.Fragment>
                                )}
                                {display === "invalid" && (
                                  <React.Fragment>
                                    <img
                                      src={Cancel}
                                      height="auto"
                                      width={15}
                                    ></img>
                                    <label className="px-2 h-100 mb-0 py-2 login-verification-msg">
                                      Access deined. Location not valid
                                    </label>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col className="col-6">
                            <div className="">
                              <div className="mt-2">
                                <div
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    backgroundColor: "#151220",
                                    borderRadius: "50%",
                                    display: "inline-block",
                                    marginBottom: "-18px",
                                    textAlign: "center",
                                  }}
                                >
                                  <label className="login-stepper-label">
                                    1
                                  </label>
                                </div>
                                <img
                                  style={{
                                    marginLeft: "20px",
                                  }}
                                  src={FirstStep}
                                  height="auto"
                                  width="30"
                                ></img>
                              </div>
                              <label className="login-stepper">
                                Open Glory login app
                              </label>
                            </div>
                          </Col>
                          <Col className="col-6">
                            <div className="">
                              <div className="mt-2 mb-2">
                                <div
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    backgroundColor: "#151220",
                                    borderRadius: "50%",
                                    display: "inline-block",
                                    marginBottom: "-18px",
                                    textAlign: "center",
                                  }}
                                >
                                  <label className="login-stepper-label">
                                    2
                                  </label>
                                </div>
                                <img
                                  style={{
                                    marginLeft: "20px",
                                  }}
                                  src={SecondStep}
                                  height="auto"
                                  width="50"
                                ></img>
                              </div>
                              <label className="login-stepper">
                                Place the camera at the QR code above.
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    )}
                  </Card>
                )}
                {resetPass === true && (
                  <Card className="login-card">
                    <Card.Body>
                      <Row className="px-2 my-auto">
                        <Col className="col-12 d-flex justify-content-center mb-5 mt-2">
                          {/* Test as img is not availabel */}
                          {/* <label className="login-image">Glory</label> */}
                          <img
                            src="/lgoosmall.png"
                            height="auto"
                            width="125"
                          ></img>
                        </Col>
                        <Col className="col-12 d-flex justify-content-center mb-4 mt-4">
                          <img src={loginEmail} height="auto" width="70"></img>
                        </Col>
                        <Col className="col-12 d-flex justify-content-center  mb-4">
                          <label className="login-text-reset">
                            Check your mail inbox
                          </label>
                        </Col>
                        <Col className="col-12 mb-2">
                          <label className="login-reset-desctext">
                            Password reset has been initiated due to expiry
                          </label>
                        </Col>
                        <Col className="col-12 mb-4">
                          <label className="login-reset-desctext">
                            We have sent a reset link to your email account.
                            Kindly click on the link provided in the email to
                            reset your password
                          </label>
                        </Col>
                        <Col className="col-12 d-flex justify-content-center mb-2 mt-3">
                          <Button
                            className="login-button-new py-3"
                            style={{
                              backgroundColor: "#151220",
                              boxShadow: "0px 6px 10px #00000029",
                              borderColor: "#151220",
                              fontWeight: "800",
                            }}
                            onClick={goBacktoHome}
                          >
                            CONTINUE
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                )}
              </Col>
            </Row>
            <CookieConsent
              location="bottom"
              buttonText="I understand"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
            >
              This website uses cookies to enhance the user experience.{" "}
            </CookieConsent>
          </Col>
          {/* <Col
            className="col-12 col-md-7 d-none d-sm-none d-md-block login-ipad-spec"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <div className="custom-row-height login-logo-position">
              <img src={LoginImg} height="auto" width="70%"></img>
            </div>

            <footer className="footerClass px-4 py-4 footer-class-font">
              <Row>
                <Col className="col-1">
                  <span>
                    <a
                      className="login-link-text"
                      href="/faq.html"
                      target="_blank"
                    >
                      FAQ
                    </a>
                  </span>
                </Col>
                <Col className="col-2">
                  <span>
                    <a
                      className="login-link-text"
                      href="https://www.gloryindia.in/privacy"
                      target="_blank"
                    >
                      Privacy policy
                    </a>
                  </span>
                </Col>
                <Col className="col-3">
                  <span>
                    <a
                      className="login-link-text"
                      href="https://www.gloryindia.in/terms"
                      target="_blank"
                    >
                      Terms and conditions
                    </a>
                  </span>
                </Col>
                <Col className="col-6 d-flex justify-content-end">
                  <span style={{ fontWeight: 500, color: "#151220" }}>
                    Copyright © 2021 Glory
                  </span>
                </Col>
              </Row>
            </footer>
          </Col> */}
        </Row>
      )}
      {openAuth === true && (
        <FaceRecog
          user_id={user_id}
          name={name}
          email={email}
          password={password}
        />
      )}
    </React.Fragment>
  );
}

export default LoginView;
