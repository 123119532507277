import React, { Component } from "react";
import ReactTable from "react-table-v6";
import { Row, Col, Button } from "shards-react";
import "react-table-v6/react-table.css";
import PropTypes from "prop-types";
import Search from "./../common/CommonSearch";
import * as config from "../../constants/constants.json";

class Table extends Component {
  constructor(props) {
    super(props); //since we are extending class Table so we have to use super in order to override Component class constructor
    this.state = {
      //state is by default an object
      data: [],
      filteredData: [],
      searchInput: "",
    };
    this.callAPI = this.callAPI.bind(this);
  }
  handleFilteredData = (filteredData) => {
    this.setState({ filteredData });
  };

  handleSearchInput = (searchInput) => {
    this.setState({ searchInput });
  };
  callAPI() {
    fetch(config.url + "htmlcrm/getdutydrivers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          this.setState({
            data: result.driverslist,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  componentDidMount() {
    this.callAPI();
  }

  render() {
    const execDataToDisplay = this.state.searchInput.length
      ? this.state.filteredData
      : this.state.data;
    //Whenever our class runs, render method will be called automatically, it may have already defined in the constructor behind the scene.
    const columns = [
      {
        Header: "Driver name",
        accessor: "first_name",
      },
      {
        Header: "Phone number",
        accessor: "phonenumber",
      },
      {
        Header: "Vehicle number",
        accessor: "vehiclenumber",
      },
      {
        Header: "Vehicle type",
        accessor: "vehiclename",
      },
      {
        Header: "Driver Type",
        accessor: "driver_type",
        id: "driver_type",
        Cell: (row) => {
          // console.log(row);
          if (row.original.driver_type === "Temporary") {
            return <div style={{ color: "#ff0000" }}>Temporary</div>;
          } else {
            return <div style={{ color: "#39ff14" }}>Regular</div>;
          }
        },
      },
      {
        Header: "App version",
        accessor: "version_code",
        id: "version_code",
        Cell: (row) => {
          // console.log(row);

          return (
            <div style={{ color: "#00A7E1" }}>{row.original.version_code}</div>
          );
        },
      },
      {
        Header: "Last on-duty Time",
        accessor: "lastonduty",
      },
      {
        Header: "Duty status",
        accessor: "dutystatus",
        id: "dutystatus",
        Cell: (row) => {
          // console.log(row);
          if (row.original.dutystatus === "off") {
            return <div style={{ color: "#ff0000" }}>OFF</div>;
          } else {
            return <div style={{ color: "#39ff14" }}>ON</div>;
          }
        },
      },
      {
        Header: "Availability",
        accessor: "driverstatus",
        id: "driverstatus",
        Cell: (row) => {
          // console.log(row);
          if (row.original.driverstatus === "free") {
            return <div style={{ color: "#39ff14" }}>FREE</div>;
          } else {
            return <div style={{ color: "#ff0000" }}>-</div>;
          }
        },
      },
    ];
    return (
      <React.Fragment>
        <Row>
          <Col className="col-12 col-md-12 d-flex justify-content-end py-3">
            <Button onClick={this.callAPI} theme="dark">
              <i className="fas fa-sync-alt" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 col-md-3 col-lg-3 mr-auto mb-5">
            <Search
              typeOfSearch="driver_list"
              data={this.state.data}
              columns={columns}
              handleSetFilteredData={this.handleFilteredData}
              handleSetSearchInput={this.handleSearchInput}
            />
          </Col>
        </Row>
        <Row>
          <Col className="col-12 col-md-12 px-0">
            <ReactTable
              data={execDataToDisplay}
              columns={columns}
              className="text-center"
            />
          </Col>
        </Row>
        {/* {console.log(this.state.data)} */}
      </React.Fragment>
    );
  }
}
Table.default = {
  columns: PropTypes.array,
};
Table.defaultProps = {
  columns: [
    {
      Header: "Driver name",
      accessor: "first_name",
    },
    {
      Header: "Phone number",
      accessor: "phonenumber",
    },
    {
      Header: "Vehicle number",
      accessor: "vehiclenumber",
    },
    {
      Header: "Vehicle type",
      accessor: "vehiclename",
    },
    {
      Header: "App version",
      accessor: "version_code",
    },
    {
      Header: "Last on-duty Time",
      accessor: "lastonduty",
    },
    {
      Header: "Duty status",
      accessor: "dutystatus",
    },
    {
      Header: "Availability",
      accessor: "driverstatus",
    },
  ],
};
export default Table;
