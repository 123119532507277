import React from "react";
import _ from "lodash";
import {
  Container,
  Row,
  Col,
  Collapse,
  Button,
  Tooltip,
  Card,
  CardBody,
  FormCheckbox,
  FormSelect,
  CardHeader,
  Form,
  Breadcrumb,
  BreadcrumbItem,
  FormInput,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormRadio,
} from "shards-react";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import ReactSnackBar from "react-js-snackbar";
import PageTitle from "./../components/common/PageTitle";
import Autocomplete from "react-google-autocomplete";
import PolyLineMap from "../components/utilities/NewMap";
import Hmap from "../components/utilities/Hmap";
import OutstationMap from "../components/utilities/OutstationMap";
import RentalMap from "../components/utilities/RentalMap";
import * as config from "../constants/constants.json";
import { CardFooter } from "reactstrap";
import equal from "fast-deep-equal/react";
const google = window.google;
// console.log(window);
// function boundss() {
//   let city_bounds;

//   if (
//     this.state.operating_region === "Bangalore" ||
//     this.state.operating_region === ""
//   ) {
//     var banSW = new google.maps.LatLng(12.7342888, 77.3791981);
//     var banNE = new google.maps.LatLng(13.173706, 77.8826809);
//     city_bounds = new google.maps.LatLngBounds(banSW, banNE);
//     return city_bounds;
//   } else {
//     var hydSW = new google.maps.LatLng(17.2168886, 78.1599217);
//     var hydNE = new google.maps.LatLng(17.6078088, 78.6561694);
//     city_bounds = new google.maps.LatLngBounds(hydSW, hydNE);
//     return city_bounds;
//   }
// }

class BookARide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      daySelect: moment(new Date()).add(15, "m").toDate(),
      minTime: moment(new Date()).add(15, "m").toDate(),
      maxTime: moment(new Date()).endOf("day").toDate(),
      arrivalDate: moment(new Date()).add(1, "days").toDate(),
      pickreadOnly: false,
      minArrivalDate: "",
      maxTimeArrival: "",
      minTimeArrival: "",
      dropreadOnly: true,
      operating_region: "",
      vendor: "",
      ridetype: "",
      traveltype: "oneway",
      pickup_location: "",
      approx_distance: 0,
      dropoff_location: "Kempegowda International Airport[BLR]",
      outDrop_off: "",
      dropofflocation: "Rajiv Gandhi International Airport",
      estimateddistance: "",
      affrox_fare: [],
      rider_name: "",
      rider_email: "",
      rider_phonenumber: "",
      slat: "",
      slon: "",
      hdlat: "17.2402633",
      hdlon: "78.4293851",
      dlat: "13.1986348",
      dlon: "77.7065928",
      displayApprox: false,
      data: [],
      selectedFruit: "Hatchback",
      displayApproxfare: false,
      approx_fare: 0,
      Show: false,
      Showing: false,
      success: false,
      message: "",
      rentalvalue: "choose",
      outstationvalue: "choose",
      google: [],
      rentalList: [],
      rentalfares: [],
      outstationList: [],
      bounds: "",
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleVendorChange = this.handleVendorChange.bind(this);
    this.handlerideChange = this.handlerideChange.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.switchData = this.switchData.bind(this);
    this.getDistance = this.getDistance.bind(this);
    this.initializeApproxfare = this.initializeApproxfare.bind(this);
    this.changeFruit = this.changeFruit.bind(this);
    this.scheduleRide = this.scheduleRide.bind(this);
    this.submitdata = this.submitdata.bind(this);
    // this.getDate = this.getDate.bind(this);
    // this.getMaxDate = this.getMaxDate.bind(this);
    this.checkRide = this.checkRide.bind(this);
    this.setfares = this.setfares.bind(this);
    this.onChangeTravelType = this.onChangeTravelType.bind(this);
    this.onChangeOutstation = this.onChangeOutstation.bind(this);
    this.getApproxFareOS = this.getApproxFareOS.bind(this);
    this.outstation = this.outstation.bind(this);
    this.setMaxMinDate = this.setMaxMinDate.bind(this);
    this.bounds = this.bounds.bind(this);
  }

  bounds() {
    let city_bounds;

    if (
      this.state.operating_region === "Bangalore" ||
      this.state.operating_region === ""
    ) {
      var banSW = new google.maps.LatLng(12.7342888, 77.3791981);
      var banNE = new google.maps.LatLng(13.173706, 77.8826809);
      city_bounds = new google.maps.LatLngBounds(banSW, banNE);
      console.log(city_bounds);
    } else {
      var hydSW = new google.maps.LatLng(17.2168886, 78.1599217);
      var hydNE = new google.maps.LatLng(17.6078088, 78.6561694);
      city_bounds = new google.maps.LatLngBounds(hydSW, hydNE);
    }
    this.setState({
      bounds: city_bounds,
    });
  }

  getApproxFareOS() {
    var approxObj = {
      distance: this.state.approx_distance,
      sname: this.state.pickup_location,
      dname: "",
      slat: this.state.slat,
      slon: this.state.slon,
      dlat: this.state.dlat,
      dlon: this.state.dlon,
      category: "taxi",
      pickup_time: this.state.daySelect,
      region: this.state.operating_region,
    };
    if (this.state.traveltype === "oneway") {
      approxObj.travel_type = "onewaytrip";
      approxObj.depature_time = new Date(this.state.pickup_time).getTime();
      approxObj.dname = this.state.dropoff_location;
    } else {
      approxObj.travel_type = "roundtrip";
      approxObj.arrival_time = new Date(this.state.arrivalDate).getTime();
      approxObj.endtime = new Date(this.state.arrivalDate).getTime();
      approxObj.pickup_time = new Date(this.state.daySelect).getTime();
    }

    fetch(config.url + "booking/checkOutStationFare", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(approxObj),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("-----------------------------");
        console.log(result);
        this.setState({
          data: result,
          approx_fare: result.fare[0].Hatchback,
          displayApproxfare: true,
        });
        //   this.setState({
        //     topDrivers: result.results
        //   });
      });
  }
  onChangeOutstation = (event) => {
    if (event.value === "choose") {
      this.setState({
        outstationvalue: event.target.value,
        dlat: "",
        dlon: "",
        outDrop_off: "",
      });
    } else {
      let data = _.find(this.state.outstationList, {
        dname: event.target.value,
      });
      console.log(data);
      this.setState(
        {
          outstationvalue: event.target.value,
          dlat: data.location_info[0],
          dlon: data.location_info[1],
          outDrop_off: data.dname,
        },
        () => {
          this.getDistance();
        }
      );
    }
  };
  onChangeTravelType(event) {
    this.setState({
      traveltype: event.target.value,
    });
  }
  // getDate() {
  //   let dateNow = new Date();
  //   let selectedDate = this.state.daySelect;
  //   console.log(dateNow);
  //   console.log(selectedDate);
  //   console.log(
  //     dateNow.setHours(0, 0, 0, 0) === selectedDate.setHours(0, 0, 0, 0)
  //   );
  //   if (dateNow.setHours(0, 0, 0, 0) === selectedDate.setHours(0, 0, 0, 0)) {
  //     console.log(selectedDate);
  //     console.log(new Date());
  //     console.log(
  //       moment(new Date())
  //         .add(15, "m")
  //         .toDate()
  //     );
  //     return moment(new Date())
  //       .add(15, "m")
  //       .toDate();
  //   } else {
  //     return new Date();
  //   }
  // }
  getMaxDate() {
    return moment(this.state.daySelect).endOf("day").toDate();
  }

  onChangeRental = (event) => {
    console.log("----------------------------");
    console.log(event.target.value);
    this.setState(
      {
        rentalvalue: event.target.value,
      },
      () => {
        this.setfares();
      }
    );
  };
  setfares() {
    console.log("***************************************");
    if (this.state.rentalvalue !== "choose") {
      console.log(this.state.rentalvalue);
      let value = this.state.rentalvalue;
      let data = _.map(this.state.rentalList, function (o) {
        console.log(o);
        if (o.option === value) return o;
      });
      let final_data = _.without(data, undefined);
      this.setState({
        rentalfares: final_data[0],
        displayApproxfare: true,
      });
      // console.log(final_data);
    }
  }
  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };

  submitdata(event) {
    let data;
    if (event) {
      event.preventDefault();
    }
    if (this.state.ridetype === "airport") {
      console.log("here");
      this.scheduleRide();
    } else if (this.state.ridetype === "rental") {
      this.rentalRide();
    } else {
      this.outstation();
    }
  }

  outstation() {
    if (
      this.state.traveltype === "oneway" &&
      (this.state.outstationvalue === undefined ||
        this.state.outstationvalue === "choose")
    ) {
      this.setState(
        {
          success: false,
          message: "Please choose a valid packagee",
        },
        () => {
          this.show();
        }
      );
    } else if (
      this.state.traveltype === "twoway" &&
      (this.state.arrivalDate === undefined || this.state.arrivalDate === null)
    ) {
      this.setState(
        {
          success: false,
          message: "Please choose a valid arrival time",
        },
        () => {
          this.show();
        }
      );
    } else if (
      this.state.slat === undefined ||
      this.state.slon === undefined ||
      this.state.slat === "" ||
      this.state.slon === ""
    ) {
      this.setState(
        {
          success: false,
          message: "Please provide a valid pickup location",
        },
        () => {
          this.show();
        }
      );
    } else if (
      this.state.outDrop_off === undefined ||
      this.state.pickup_location === undefined
    ) {
      this.setState(
        {
          success: false,
          message: "Please provide a valid location",
        },
        () => {
          this.show();
        }
      );
    } else {
      let rayData = {
        region: this.state.operating_region,
        trip_type: this.state.ridetype.toLowerCase(),
        src_lat: this.state.slat,
        src_lon: this.state.slon,
        dest_lat: this.state.dlat,
        dest_lon: this.state.dlon,
      };

      fetch(config.url + "htmlcrm/raycasting", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(rayData),
      })
        .then((res) => res.json())
        .then((results) => {
          if (results.message === true) {
            console.log(results);
            let data = {
              region: this.state.operating_region,
              ride_type: this.state.ridetype.toLowerCase(),
              travel_type: this.state.traveltype,
              approx_fare: this.state.approx_fare,
              part_payment: "false",
              distance: this.state.approx_distance,
              sname: this.state.pickup_location,
              dname: "",
              source_lat: this.state.slat,
              source_lon: this.state.slon,
              destination_lat: this.state.dlat,
              destination_lon: this.state.dlon,
              vehicletype: this.state.selectedFruit,
              depature_time: new Date(this.state.daySelect).getTime(),
              arrival_time: "",
              package: "false",
              package_type: "",
              requested_through: window.sessionStorage.getItem("vendor"),
              payment_mode: "cash",
              first_name: this.state.rider_name,
              phonenumber: this.state.rider_phonenumber,
              email: this.state.rider_email,
              executive_name: window.sessionStorage.getItem("username"),
              executive_id: window.sessionStorage.getItem("user_id"),
            };

            if (this.state.traveltype === "oneway") {
              data.travel_type = "onewaytrip";
              data.package_type = "onewaytrip";
              data.dname = this.state.dropoff_location;
              data.package_name = this.state.outstationvalue;
            } else if (this.state.traveltype === "twoway") {
              data.travel_type = "roundtrip";
              data.package_type = "roundtrip";
              data.dname = this.state.outDrop_off;
              data.package_name = this.state.outstationvalue;
              data.arrival_time = new Date(this.state.arrivalDate).getTime();
            }
            fetch(config.url + "booking/taxiOutStationBooking", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: JSON.stringify(data),
            })
              .then((res) => res.json())
              .then((result) => {
                console.log(result);
                if (result.message === "successfull") {
                  this.setState(
                    {
                      success: true,
                      message: "Booking has been created successfully",
                    },
                    () => {
                      this.show();
                      setTimeout(() => {
                        window.location.assign("/bookaride");
                      }, 2000);
                    }
                  );
                } else if (
                  result.message ===
                  "Seems like you already have an upcoming ride"
                ) {
                  this.setState(
                    {
                      success: false,
                      message: "Seems like you already have an upcoming ride",
                    },
                    () => {
                      this.show();
                    }
                  );
                } else {
                  this.setState(
                    {
                      success: false,
                      message: "Booking failed to create",
                    },
                    () => {
                      this.show();
                    }
                  );
                }
                //   this.setState({
                //     topDrivers: result.results
                //   });
              });
          } else {
            this.setState(
              {
                success: false,
                message:
                  "Please check your operating region and pickup/drop-off",
              },
              () => {
                this.show();
              }
            );
          }
        });
    }
  }
  scheduleRide() {
    if (
      this.state.slat === undefined ||
      this.state.slon === undefined ||
      this.state.slat === "" ||
      this.state.slon === ""
    ) {
      this.setState(
        {
          success: false,
          message: "Please provide a valid pickup location",
        },
        () => {
          this.show();
        }
      );
    } else if (
      this.state.dlat === undefined ||
      this.state.dlon === undefined ||
      this.state.dlat === "" ||
      this.state.dlon === ""
    ) {
      this.setState(
        {
          success: false,
          message: "Please provide a valid drop-off location",
        },
        () => {
          this.show();
        }
      );
    } else if (
      this.state.dropoff_location === undefined ||
      this.state.pickup_location === undefined
    ) {
      this.setState(
        {
          success: false,
          message: "Please provide a valid location",
        },
        () => {
          this.show();
        }
      );
    } else {
      let rayData = {
        region: this.state.operating_region,
        trip_type: this.state.ridetype.toLowerCase(),
        src_lat: this.state.slat,
        src_lon: this.state.slon,
        dest_lat: this.state.dlat,
        dest_lon: this.state.dlon,
      };
      fetch(config.url + "htmlcrm/raycasting", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(rayData),
      })
        .then((res) => res.json())
        .then((results) => {
          if (results.message === true) {
            console.log(results);
            let data = {
              region: this.state.operating_region,
              ride_type: this.state.ridetype.toLowerCase(),
              approx_fare: this.state.approx_fare,
              part_payment: "false",
              distance: this.state.approx_distance,
              sname: this.state.pickup_location,
              source_lat: this.state.slat,
              source_lon: this.state.slon,
              dname: this.state.dropoff_location,
              destination_lat: this.state.dlat,
              destination_lon: this.state.dlon,
              vehicletype: this.state.selectedFruit,
              promocode: "",
              requested_through: this.state.vendor,
              pickuptime: new Date(this.state.daySelect).getTime(),
              payment_mode: "cash",
              first_name: this.state.rider_name,
              phonenumber: this.state.rider_phonenumber,
              email: this.state.rider_email,
              executive_name: window.sessionStorage.getItem("username"),
              executive_id: window.sessionStorage.getItem("user_id"),
              is_airport_drop: this.state.pickreadOnly === true ? false : true,
              is_airport_pickup:
                this.state.pickreadOnly === true ? true : false,
              amount_to_be_collected: 0,
            };

            fetch(config.url + "htmlcrm/scheduleRide", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: JSON.stringify(data),
            })
              .then((res) => res.json())
              .then((result) => {
                console.log(result);
                if (result.message === "successfull") {
                  this.setState(
                    {
                      success: true,
                      message: "Booking has been created successfully",
                    },
                    () => {
                      this.show();
                      setTimeout(() => {
                        window.location.assign("/bookaride");
                      }, 2000);
                    }
                  );
                } else if (
                  result.message ===
                  "Seems like you already have an upcoming ride"
                ) {
                  this.setState(
                    {
                      success: false,
                      message: "Seems like you already have an upcoming ride",
                    },
                    () => {
                      this.show();
                    }
                  );
                } else {
                  this.setState(
                    {
                      success: false,
                      message: "Booking failed to create",
                    },
                    () => {
                      this.show();
                    }
                  );
                }
                //   this.setState({
                //     topDrivers: result.results
                //   });
              });
          } else {
            this.setState(
              {
                success: false,
                message:
                  "Please check your operating region and pickup/drop-off",
              },
              () => {
                this.show();
              }
            );
          }
        });
    }
  }

  rentalRide() {
    if (this.state.rentalvalue === "choose") {
      this.setState(
        {
          success: false,
          message: "Please select a valid rental package",
        },
        () => {
          this.show();
        }
      );
    } else if (
      this.state.slat === undefined ||
      this.state.slon === undefined ||
      this.state.slat === "" ||
      this.state.slon === ""
    ) {
      this.setState(
        {
          success: false,
          message: "Please provide a valid pickup location",
        },
        () => {
          this.show();
        }
      );
    } else if (
      this.state.dropoff_location === undefined ||
      this.state.pickup_location === undefined
    ) {
      this.setState(
        {
          success: false,
          message: "Please provide a valid location",
        },
        () => {
          this.show();
        }
      );
    } else {
      let rayData = {
        region: this.state.operating_region,
        trip_type: this.state.ridetype.toLowerCase(),
        src_lat: this.state.slat,
        src_lon: this.state.slon,
      };

      fetch(config.url + "htmlcrm/raycasting", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(rayData),
      })
        .then((res) => res.json())
        .then((results) => {
          if (results.message === true) {
            console.log(results);
            let extra_km_charge;
            let base_fare;
            let extra_hour_charge;
            if (this.state.selectedFruit === "hatchback") {
              extra_km_charge =
                this.state.rentalfares.hatchback.extra_km_charge;
              base_fare = this.state.rentalfares.hatchback.total_amount;
              extra_hour_charge =
                this.state.rentalfares.hatchback.extra_hour_charge;
            } else if (this.state.selectedFruit === "sedan") {
              extra_km_charge = this.state.rentalfares.sedan.extra_km_charge;
              base_fare = this.state.rentalfares.sedan.total_amount;
              extra_hour_charge =
                this.state.rentalfares.sedan.extra_hour_charge;
            } else {
              extra_km_charge = this.state.rentalfares.suv.extra_km_charge;
              base_fare = this.state.rentalfares.suv.total_amount;
              extra_hour_charge = this.state.rentalfares.suv.extra_hour_charge;
            }

            let data = {
              region: this.state.operating_region,
              km_package_limit: this.state.rentalfares.distance_limit,
              hour_package_limit: this.state.rentalfares.package_limit,
              package_rate: this.state.approx_fare,
              sname: this.state.pickup_location,
              source_lat: this.state.slat,
              source_lon: this.state.slon,
              part_payment: "false",
              vehicletype: this.state.selectedFruit,
              payment_mode: "cash",
              promocode: "",
              trip_type: "rental",
              requested_through: this.state.vendor,
              pickup_time: new Date(this.state.daySelect).getTime(),
              first_name: this.state.rider_name,
              phonenumber: this.state.rider_phonenumber,
              email: this.state.rider_email,
              executive_name: window.sessionStorage.getItem("username"),
              executive_id: window.sessionStorage.getItem("user_id"),
              package_id: this.state.rentalfares.package_id,
              package_hour: this.state.rentalfares.package_limit,
              package_km: this.state.rentalfares.distance_limit,
              package_extra_km_rate: extra_km_charge,
              package_base_fare: base_fare,
              package_extra_hour_rate: extra_hour_charge,
            };
            console.log(data);
            fetch(config.url + "booking/taxiRentalBooking", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: JSON.stringify(data),
            })
              .then((res) => res.json())
              .then((result) => {
                console.log(result);
                if (result.message === "successfull") {
                  this.setState(
                    {
                      success: true,
                      message: "Booking has been created successfully",
                    },
                    () => {
                      this.show();
                      setTimeout(() => {
                        window.location.assign("/bookaride");
                      }, 2000);
                    }
                  );
                } else if (
                  result.message ===
                  "Seems like you already have an upcoming ride"
                ) {
                  this.setState(
                    {
                      success: false,
                      message: "Seems like you already have an upcoming ride",
                    },
                    () => {
                      this.show();
                    }
                  );
                } else {
                  this.setState(
                    {
                      success: false,
                      message: "Booking failed to create",
                    },
                    () => {
                      this.show();
                    }
                  );
                }
                //   this.setState({
                //     topDrivers: result.results
                //   });
              });
          } else {
            this.setState(
              {
                success: false,
                message:
                  "Please check your operating region and pickup/drop-off",
              },
              () => {
                this.show();
              }
            );
          }
        });
    }
  }
  changeFruit(fruit, data) {
    console.log(data);
    this.setState({
      selectedFruit: fruit,
      approx_fare: data,
    });
  }

  changeDate(date) {
    console.log(date);
    if (
      this.state.ridetype === "outstation" &&
      this.state.traveltype === "twoway"
    ) {
      console.log("00000000000000000000000000");
      this.setState(
        {
          daySelect: date,
          arrivalDate: moment(date).add(1, "days").toDate(),
          minTimeArrival: moment(date).add(1, "days").startOf("day").toDate(),
          maxTimeArrival: moment(date).add(1, "days").endOf("day").toDate(),
          minArrivalDate: moment(date).add(1, "days").toDate(),
        },
        () => {
          this.setMaxMinDate();
          this.getApproxFareOS();
        }
      );
    } else {
      this.setState(
        {
          daySelect: date,
        },
        () => {
          this.setMaxMinDate();
        }
      );
    }
  }
  setMaxMinDate() {
    let dateNow = new Date();
    const selectedDate = this.state.daySelect;
    const selection = this.state.daySelect;
    console.log(selectedDate);
    if (
      moment(dateNow).format("DD/MM/YYYY") ===
      moment(selectedDate).format("DD/MM/YYYY")
    ) {
      this.setState({
        minTime: moment(new Date()).add(15, "m").toDate(),
        maxTime: moment(new Date()).endOf("day").toDate(),
        daySelect: moment(selectedDate).toDate(),
      });
    } else {
      console.log(selection);
      this.setState({
        minTime: moment(selection).startOf("day").toDate(),
        daySelect: moment(selection).toDate(),
        maxTime: moment(selection).endOf("day").toDate(),
      });
    }
  }

  changeDateArrival(date) {
    this.setState(
      {
        arrivalDate: date,
      },
      () => {
        this.getApproxFareOS();
      }
    );
  }
  initializeApproxfare() {
    var approxObj = {
      distance: this.state.approx_distance,
      sname: this.state.pickup_location,
      dname: this.state.dropoff_location,
      slat: this.state.slat,
      slon: this.state.slon,
      dlat: this.state.dlat,
      dlon: this.state.dlon,
      category: "taxi",
      pickup_time: new Date(this.state.daySelect.getTime()),
      region: this.state.operating_region,
    };
    fetch(config.url + "api/checkapproxfare", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(approxObj),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        this.setState({
          data: result,
          approx_fare: result.fare[0].Hatchback,
          displayApproxfare: true,
        });
        // this.setState({
        //   topDrivers: result.results
        // });
      });
  }
  //   checkapproxfare() {
  //     var approxObj = {
  //       distance: $("#estimated_distance").val(),
  //       sname: $("#feSrcAddr").val(),
  //       dname: $("#feDropOffAddr").val(),
  //       slat: srcMarker.getPosition().lat,
  //       slon: srcMarker.getPosition().lng,
  //       dlat: destMarker.getPosition().lat,
  //       dlon: destMarker.getPosition().lng,
  //       category: "taxi"
  //     };
  //   }
  getDistance() {
    if (
      (this.state.slat === undefined || this.state.slat === "") &
        (this.state.slon === undefined || this.state.slon === "") ||
      (this.state.dlat === undefined || this.state.dlat === "") &
        (this.state.dlon === undefined || this.state.dlon === "")
    ) {
    } else {
      let destinations = { lat: this.state.dlat * 1, lng: this.state.dlon * 1 };
      let origins = { lat: this.state.slat, lng: this.state.slon };
      var service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [new google.maps.LatLng(origins)],
          destinations: [new google.maps.LatLng(destinations)],
          travelMode: "DRIVING",
          unitSystem: google.maps.UnitSystem.IMPERIAL,
        },
        (response, status) => {
          console.log(response);
          if (response.rows[0].elements[0].status === "ZERO_RESULTS") {
            this.setState(
              {
                success: false,
                message: "Unable to fetch distance. Out of bounds",
              },
              () => {
                this.show();
              }
            );
          } else {
            this.setState(
              {
                displayApprox: true,
                approx_distance:
                  response.rows[0].elements[0].distance.value / 1000,
              },
              () => {
                if (
                  this.state.ridetype === "airport" ||
                  this.state.ridetype === "rental"
                ) {
                  this.initializeApproxfare();
                } else {
                  this.getApproxFareOS();
                }
              }
            );
          }
          // console.log(response.rows[0].elements[0].distance.value);
        }
      );

      //   function callback(response, status) {

      //     // See Parsing the Results for
      //     // the basics of a callback function.
      //   }
      //   //

      //   console.log(origins);
      //   console.log(destinations);
      //   distance.matrix(origins, destinations, function(err, distances) {
      //     if (!err) console.log(distances);
      //   });
    }
  }
  switchData() {
    let pickup = this.state.pickup_location;
    let dropodd = this.state.dropoff_location;
    let slat = this.state.slat;
    let slon = this.state.slon;
    let dlat = this.state.dlat;
    let dlon = this.state.dlon;
    this.setState({
      pickup_location: dropodd,
      dropoff_location: pickup,
      slat: dlat,
      slon: dlon,
      dlat: slat,
      dlon: slon,
      pickreadOnly: !this.state.pickreadOnly,
      dropreadOnly: !this.state.dropreadOnly,
    });
  }
  setLocation(data) {
    console.log(data);
    if (data.geometry === undefined) {
    } else {
      let slatitude = data.geometry.location.lat();
      let slongitude = data.geometry.location.lng();
      this.setState(
        {
          slat: slatitude,
          slon: slongitude,
        },
        () => {
          this.getDistance();
        }
      );
    }
  }
  setDestLoc(data) {
    let dlatitude = data.geometry.location.lat();
    let dlongitude = data.geometry.location.lng();
    this.setState(
      {
        dlat: dlatitude,
        dlon: dlongitude,
      },
      () => {
        this.getDistance();
      }
    );
  }
  handleChange(e, name) {
    console.log(name);
    // console.log(e)
    let value;
    let newState = {};
    if (e === undefined) {
    } else {
      if (e.target === undefined) {
        value = e;
        newState[name] = value;
        this.setState({ ...this.state, ...newState }, () => {});
      } else {
        if (name === "rider_name") {
          const re = /^[a-zA-Z]+(\s[a-zA-Z]+)?$/;
          if (e.target.value === "" || re.test(e.target.value)) {
            value = e.target.value;
            newState[name] = value;
            this.setState({ ...this.state, ...newState });
          }
        } else if (name === "rider_email") {
          value = e.target.value;
          const newState = {};
          newState[name] = value;
          this.setState({ ...this.state, ...newState });
        } else if (name === "rider_phonenumber") {
          const re = /^[0-9\b]+$/;
          // const re = /^[+]91(9|8|7)\d{9}$/;
          if (e.target.value === "" || re.test(e.target.value)) {
            value = e.target.value;
            newState[name] = value;
            this.setState({ ...this.state, ...newState });
          }
        } else {
          value = e.target.value;
          const newState = {};
          newState[name] = value;
          this.setState({ ...this.state, ...newState });
        }
      }
    }

    // console.log(value);
  }

  handleVendorChange(event) {
    console.log("here");
    this.setState({ vendor: event.target.value });
  }
  handleSelectChange(event) {
    if (event.target.value === "Bangalore") {
      this.setState({
        operating_region: event.target.value,
        dropoff_location: "Kempegowda International Airport[BLR]",
        pickup_location: "",
        slat: "",
        slon: "",
        dlat: "13.1986348",
        dlon: "77.7065928",
      });
    } else if (event.target.value === "Hyderabad") {
      this.setState({
        operating_region: event.target.value,
        dropoff_location: "Rajiv Gandhi International Airport",
        pickup_location: "",
        slat: "",
        slon: "",
        dlat: "17.2402633",
        dlon: "78.4293851",
      });
    } else {
      this.setState({
        operating_region: event.target.value,
        dropoff_location: "Kempegowda International Airport[BLR]",
        pickup_location: "",
        slat: "",
        slon: "",
        dlat: "13.1986348",
        dlon: "77.7065928",
      });
    }
  }

  handlerideChange(event) {
    // if (this.state.operating_region !== "") {
    //   this.setState(
    //     { ridetype: event.target.value, displayApproxfare: false },
    //     () => {
    //       this.checkRide();
    //     }
    //   );
    // } else {
    //   alert("Select operating region");
    // }
    if (this.state.operating_region === "") {
      this.setState(
        {
          success: false,
          message: "Please select an operating region",
        },
        () => {
          this.show();
        }
      );
    } else if (this.state.vendor === "") {
      this.setState(
        {
          success: false,
          message: "Please select a vendor from the list",
        },
        () => {
          this.show();
        }
      );
    } else {
      if (event.target.value === "outstation") {
        this.setState(
          {
            ridetype: event.target.value,
            displayApproxfare: false,
            displayApprox: false,
            approx_distance: 0,
            pickup_location: "",
            slat: "",
            slon: "",
            dlat: "",
            dlon: "",
          },
          () => {
            this.checkRide();
          }
        );
      } else if (event.target.value === "rental") {
        this.setState(
          {
            ridetype: event.target.value,
            displayApproxfare: false,
            displayApprox: false,
            approx_distance: 0,
            pickup_location: "",
            slat: "",
            slon: "",
            dlat: "",
            dlon: "",
          },
          () => {
            this.checkRide();
          }
        );
      } else if (event.target.value === "airport") {
        this.setState(
          {
            ridetype: event.target.value,
            displayApproxfare: false,
            displayApprox: false,
            approx_distance: 0,
            pickup_location: "",
            slat: "",
            slon: "",
          },
          () => {
            this.checkRide();
          }
        );
      }
    }
  }

  checkRide() {
    console.log(this.state.ridetype);
    if (this.state.ridetype === "rental") {
      let data = {
        user_id: window.sessionStorage.getItem("user_id"),
        region: this.state.operating_region,
      };
      fetch(config.url + "booking/checkRentalFare", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          this.setState({
            rentalList: result.farelist,
          });
          //   this.setState({
          //     topDrivers: result.results
          //   });
        });
    } else if (this.state.ridetype === "outstation") {
      let data = {
        user_id: window.sessionStorage.getItem("user_id"),
        region: this.state.operating_region,
      };
      fetch(config.url + "api/outstationRidePackage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          this.setState({
            outstationList: result,
          });
          //   this.setState({
          //     topDrivers: result.results
          //   });
        });
    } else if (
      this.state.ridetype === "airport" &&
      this.state.operating_region === "Bangalore"
    ) {
      this.setState({
        dropoff_location: "Kempegowda International Airport[BLR]",
        pickup_location: "",
        slat: "",
        slon: "",
        dlat: "13.1986348",
        dlon: "77.7065928",
      });
    } else if (
      this.state.ridetype === "airport" &&
      this.state.operating_region === "Hyderabad"
    ) {
      this.setState({
        dropoff_location: "Rajiv Gandhi International Airport",
        pickup_location: "",
        slat: "",
        slon: "",
        dlat: "17.2402633",
        dlon: "78.4293851",
      });
    }
  }
  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (!equal(this.state.operating_region, prevState.operating_region)) {
  //     this.bounds();
  //   }
  // }

  render() {
    console.log(this.state);

    // console.log(
    //   this.state.data.length > 0
    //     ? this.state.data.fare[0]
    //     : this.state.data.fare
    // );
    // console.log(bounds());

    // console.log(dataconfig);
    // let city_bounds;
    // if (
    //   this.state.operating_region === "Bangalore" ||
    //   this.state.operating_region === ""
    // ) {
    //   var banSW = new google.maps.LatLng(12.7342888, 77.3791981);
    //   var banNE = new google.maps.LatLng(13.173706, 77.8826809);
    //   city_bounds = new google.maps.LatLngBounds(banSW, banNE);
    //   // console.log(city_bounds);
    //   // return city_bounds;
    // } else {
    //   var hydSW = new google.maps.LatLng(17.2168886, 78.1599217);
    //   var hydNE = new google.maps.LatLng(17.6078088, 78.6561694);
    //   city_bounds = new google.maps.LatLngBounds(hydSW, hydNE);
    //   // console.log(city_bounds);
    //   // return city_bounds;
    // }
    // console.log(city_bounds);
    console.log(this.state.operating_region);
    let dataconfig = {
      slatlon: [this.state.slon * 1, this.state.slat * 1],
      dlatlon: [this.state.dlon * 1, this.state.dlat * 1],
    };
    return (
      <Container fluid className="main-content-container px-4 mb-5">
        <Row noGutters className="page-header py-4 px-3">
          <PageTitle
            title="Booking"
            subtitle="Rides"
            className="text-sm-left mb-3"
          />
        </Row>
        <Row className="px-3">
          <Col className="col-12 col-md-12 col-lg-12">
            <Form onSubmit={this.submitdata}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col className="col-12 col-md-5 col-lg-5">
                      <label>Choose a region:</label>
                      <FormSelect
                        required
                        value={this.state.operating_region}
                        onChange={this.handleSelectChange}
                        style={{ height: "calc(3.16rem + 2px)" }}
                      >
                        <option value="">Choose a region</option>
                        <option value="Bangalore">Bangalore</option>
                        <option value="Hyderabad">Hyderabad</option>
                      </FormSelect>
                    </Col>
                    <Col className="col-12 col-md-5 col-lg-5">
                      <label>Booking by:</label>
                      <FormSelect
                        value={this.state.vendor}
                        onChange={this.handleVendorChange}
                        style={{ height: "calc(3.16rem + 2px)" }}
                        required
                      >
                        <option value="">Choose a vendor</option>
                        <option value="glory">Glory</option>
                        {/* <option value="cleartrip">Cleartrip</option>
                        <option value="zippys">Zyppys</option>
                        <option value="oyo">OYO</option>
                        <option value="yatra">Yatra</option>
                        <option value=">viacom">Via.com</option>
                        <option value="mmt">MMT</option> */}
                      </FormSelect>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col className="col-12 col-md-5 col-lg-5">
                      <label>Ride type</label>
                      <FormSelect
                        value={this.state.ridetype}
                        style={{ height: "calc(3.16rem + 2px)" }}
                        onChange={this.handlerideChange}
                        required
                      >
                        <option value="">Choose a ride type</option>
                        <option value="airport">Airport</option>
                        <option value="rental">Rental</option>
                        <option value="outstation">Outstation</option>
                      </FormSelect>
                    </Col>
                    {this.state.ridetype === "rental" && (
                      <Col className="col-12 col-md-5 col-lg-5">
                        <label>Rental packages</label>
                        <FormSelect
                          className="py-3"
                          style={{ height: "calc(3.16rem + 2px)" }}
                          value={this.state.rentalvalue}
                          onChange={this.onChangeRental}
                        >
                          <option value="choose">
                            Choose a rental package
                          </option>
                          {this.state.rentalList.map((data, index) => (
                            <option key={index} value={data.option}>
                              {data.option}
                            </option>
                          ))}
                        </FormSelect>
                      </Col>
                    )}
                    {this.state.ridetype === "outstation" && (
                      <Col className="col-12 col-md-5 col-lg-5">
                        <label>Travel type</label>
                        <FormSelect
                          className="py-3"
                          style={{ height: "calc(3.16rem + 2px)" }}
                          value={this.state.traveltype}
                          onChange={this.onChangeTravelType}
                        >
                          <option value="oneway">One way trip</option>
                          <option value="twoway">Round trip</option>
                        </FormSelect>
                      </Col>
                    )}
                  </Row>
                  <Row className="mb-2">
                    <Col className="col-12 col-md-5 col-lg-5">
                      <label>Departure time</label>
                      <ReactDatePicker
                        className="form-control text-center py-3"
                        selected={this.state.daySelect}
                        onChange={(date) => this.changeDate(date)}
                        minDate={new Date()}
                        minTime={this.state.minTime}
                        maxTime={this.state.maxTime}
                        showDisabledMonthNavigation
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                      />
                    </Col>
                    {this.state.ridetype === "outstation" &&
                      this.state.traveltype === "twoway" && (
                        <Col className="col-12 col-md-5 col-lg-5">
                          <label>Arrival time</label>
                          <ReactDatePicker
                            className="form-control text-center py-3"
                            selected={this.state.arrivalDate}
                            minDate={this.state.minArrivalDate}
                            showDisabledMonthNavigation
                            onChange={(date) => this.changeDateArrival(date)}
                            showTimeSelect
                            minTime={this.state.minTimeArrival}
                            maxTime={this.state.maxTimeArrival}
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                          />
                        </Col>
                      )}
                  </Row>
                  <Row className="mb-2">
                    {this.state.ridetype === "rental" &&
                      this.state.rentalvalue !== "choose" && (
                        <Col className="col-12 col-md-5 col-lg-5">
                          <label>Pick up location</label>
                          <Autocomplete
                            value={this.state.pickup_location}
                            bounds={this.state.bounds}
                            readOnly={this.state.pickreadOnly}
                            className="form-control py-3"
                            placeholder="Set starting point"
                            style={{ width: "100%" }}
                            onPlaceSelected={(place) => {
                              this.handleChange(
                                place.formatted_address,
                                "pickup_location"
                              );
                              this.setLocation(place);
                            }}
                            onChange={(e) =>
                              this.handleChange(e, "pickup_location")
                            }
                            types={["(regions)"]}
                            componentRestrictions={{ country: "in" }}
                          />
                        </Col>
                      )}
                    {this.state.ridetype === "airport" && (
                      <Col className="col-12 col-md-5 col-lg-5">
                        <label>Pick up location</label>
                        <Autocomplete
                          value={this.state.pickup_location}
                          bounds={this.state.bounds}
                          readOnly={this.state.pickreadOnly}
                          className="form-control py-3"
                          placeholder="Set starting point"
                          style={{ width: "100%" }}
                          onPlaceSelected={(place) => {
                            this.handleChange(
                              place.formatted_address,
                              "pickup_location"
                            );
                            this.setLocation(place);
                          }}
                          onChange={(e) =>
                            this.handleChange(e, "pickup_location")
                          }
                          types={["geocode"]}
                          componentRestrictions={{ country: "in" }}
                        />
                      </Col>
                    )}
                    {this.state.ridetype === "outstation" && (
                      <Col className="col-12 col-md-5 col-lg-5">
                        <label>Pick up location</label>
                        <Autocomplete
                          value={this.state.pickup_location}
                          bounds={this.state.bounds}
                          readOnly={this.state.pickreadOnly}
                          className="form-control py-3"
                          placeholder="Set starting point"
                          style={{ width: "100%" }}
                          onPlaceSelected={(place) => {
                            this.handleChange(
                              place.formatted_address,
                              "pickup_location"
                            );
                            this.setLocation(place);
                          }}
                          onChange={(e) =>
                            this.handleChange(e, "pickup_location")
                          }
                          types={["(regions)"]}
                          componentRestrictions={{ country: "in" }}
                        />
                      </Col>
                    )}
                    {this.state.ridetype === "outstation" &&
                      this.state.traveltype === "oneway" && (
                        <Col className="col-12 col-md-5 col-lg-5 mb-sm-2">
                          <label>One way packages</label>
                          <FormSelect
                            className="py-3"
                            style={{ height: "calc(3.16rem + 2px)" }}
                            value={this.state.outstationvalue}
                            onChange={this.onChangeOutstation}
                          >
                            <option value="choose">
                              Choose a one way package
                            </option>
                            {this.state.outstationList.map((data, index) => (
                              <option key={index} value={data.dname}>
                                {data.dname}
                              </option>
                            ))}
                          </FormSelect>
                        </Col>
                      )}
                    {this.state.ridetype === "airport" && (
                      <Col className="col-12 col-md-5 col-lg-5 mb-sm-2">
                        <label>Drop-off location</label>
                        <Autocomplete
                          bounds={this.state.bounds}
                          readOnly={this.state.dropreadOnly}
                          value={this.state.dropoff_location}
                          className="form-control py-3"
                          placeholder="Set drop-off point"
                          style={{ width: "100%" }}
                          onPlaceSelected={(place) => {
                            this.handleChange(
                              place.formatted_address,
                              "dropoff_location"
                            );
                          }}
                          onChange={(e) =>
                            this.handleChange(e, "dropoff_location")
                          }
                          types={["(regions)"]}
                          componentRestrictions={{ country: "in" }}
                        />
                      </Col>
                    )}
                    {this.state.ridetype === "outstation" &&
                      this.state.traveltype === "twoway" && (
                        <Col className="col-12 col-md-5 col-lg-5 mb-sm-2">
                          <label>Drop-off location</label>
                          <Autocomplete
                            value={this.state.outDrop_off}
                            className="form-control py-3"
                            placeholder="Set drop-off point"
                            style={{ width: "100%" }}
                            onPlaceSelected={(place) => {
                              this.handleChange(
                                place.formatted_address,
                                "outDrop_off"
                              );
                              this.setDestLoc(place);
                            }}
                            onChange={(e) =>
                              this.handleChange(e, "outDrop_off")
                            }
                            types={["(regions)"]}
                            componentRestrictions={{ country: "in" }}
                          />
                        </Col>
                      )}
                    {this.state.ridetype === "airport" && (
                      <Col className="col-12 col-md-1 col-lg-1">
                        <label className="pr-2">Switch:</label>
                        <Button
                          onClick={this.switchData}
                          className="mt-2"
                          theme="dark"
                        >
                          <i class="fas fa-exchange-alt" />
                        </Button>
                      </Col>
                    )}
                  </Row>
                  <Row className="mb-2">
                    <Col className="col-12 col-md-12 col-lg-12 pl-0 mb-3">
                      {(this.state.ridetype === "airport" ||
                        this.state.ridetype === "") && (
                        <React.Fragment>
                          {(this.state.operating_region === "Bangalore" ||
                            this.state.operating_region === "") && (
                            <PolyLineMap
                              dataProps={dataconfig}
                              operating_region={this.state.operating_region}
                            />
                          )}
                          {this.state.operating_region === "Hyderabad" && (
                            <Hmap
                              dataProps={dataconfig}
                              operating_region={this.state.operating_region}
                            />
                          )}
                        </React.Fragment>
                      )}
                      {this.state.ridetype === "outstation" && (
                        <OutstationMap dataProps={dataconfig} />
                      )}
                      {this.state.ridetype === "rental" && (
                        <RentalMap dataProps={dataconfig} />
                      )}
                    </Col>
                  </Row>
                  {this.state.displayApprox === true &&
                    this.state.ridetype === "airport" && (
                      <Row className="mb-3">
                        <Col className="col-12 col-md-5 col-lg-5">
                          <label>Estimated distnce (kms)</label>
                          <FormInput
                            readOnly
                            placeholder={this.state.approx_distance}
                          />
                        </Col>
                      </Row>
                    )}
                  {this.state.displayApprox === true &&
                    this.state.ridetype === "outstation" && (
                      <Row className="mb-3">
                        <Col className="col-12 col-md-5 col-lg-5">
                          <label>Estimated distance (kms)</label>
                          <FormInput
                            readOnly
                            placeholder={
                              this.state.traveltype === "oneway"
                                ? this.state.approx_distance
                                : this.state.data.finalDist
                            }
                          />
                        </Col>
                      </Row>
                    )}
                  {this.state.displayApproxfare === true &&
                    this.state.ridetype === "airport" && (
                      <Row className="mb-3">
                        <Col className="col-12 col-md-4 col-lg-4 d-flex justify-content-md-center">
                          <FormRadio
                            name="fruit"
                            checked={this.state.selectedFruit === "Hatchback"}
                            onChange={() => {
                              this.changeFruit(
                                "Hatchback",
                                this.state.data.fare[0].Hatchback
                              );
                            }}
                          >
                            Hatchback -{" "}
                            {this.state.data.length > 0 ||
                            this.state.data !== undefined
                              ? "Rs " + this.state.data.fare[0].Hatchback
                              : "Rs " + 0}
                          </FormRadio>
                        </Col>
                        <Col className="col-12 col-md-4 col-lg-4 d-flex justify-content-md-center">
                          <FormRadio
                            name="fruit"
                            checked={this.state.selectedFruit === "Sedan"}
                            onChange={() => {
                              this.changeFruit(
                                "Sedan",
                                this.state.data.fare[1].Sedan
                              );
                            }}
                          >
                            Sedan -{" "}
                            {this.state.data.length > 0 ||
                            this.state.data !== undefined
                              ? "Rs " + this.state.data.fare[1].Sedan
                              : "Rs " + 0}
                          </FormRadio>
                        </Col>
                        <Col className="col-12 col-md-4 col-lg-4 d-flex justify-content-md-center">
                          <FormRadio
                            name="fruit"
                            checked={this.state.selectedFruit === "SUV"}
                            onChange={() => {
                              this.changeFruit(
                                "SUV",
                                this.state.data.fare[2].SUV
                              );
                            }}
                          >
                            SUV -{" "}
                            {this.state.data.length > 0 ||
                            this.state.data !== undefined
                              ? "Rs " + this.state.data.fare[2].SUV
                              : "Rs " + 0}
                          </FormRadio>
                        </Col>
                      </Row>
                    )}
                  {this.state.displayApproxfare === true &&
                    this.state.ridetype === "outstation" && (
                      <Row className="mb-3">
                        <Col className="col-12 col-md-4 col-lg-4 d-flex justify-content-md-center">
                          <FormRadio
                            name="fruit"
                            checked={this.state.selectedFruit === "Hatchback"}
                            onChange={() => {
                              this.changeFruit(
                                "Hatchback",
                                this.state.data.fare[0].Hatchback
                              );
                            }}
                          >
                            Hatchback -{" "}
                            {this.state.data.length > 0 ||
                            this.state.data !== undefined
                              ? "Rs " + this.state.data.fare[0].Hatchback
                              : "Rs " + 0}
                          </FormRadio>
                        </Col>
                        <Col className="col-12 col-md-4 col-lg-4 d-flex justify-content-md-center">
                          <FormRadio
                            name="fruit"
                            checked={this.state.selectedFruit === "Sedan"}
                            onChange={() => {
                              this.changeFruit(
                                "Sedan",
                                this.state.data.fare[1].Sedan
                              );
                            }}
                          >
                            Sedan -{" "}
                            {this.state.data.length > 0 ||
                            this.state.data !== undefined
                              ? "Rs " + this.state.data.fare[1].Sedan
                              : "Rs " + 0}
                          </FormRadio>
                        </Col>
                        <Col className="col-12 col-md-4 col-lg-4 d-flex justify-content-md-center">
                          <FormRadio
                            name="fruit"
                            checked={this.state.selectedFruit === "SUV"}
                            onChange={() => {
                              this.changeFruit(
                                "SUV",
                                this.state.data.fare[2].SUV
                              );
                            }}
                          >
                            SUV -{" "}
                            {this.state.data.length > 0 ||
                            this.state.data !== undefined
                              ? "Rs " + this.state.data.fare[2].SUV
                              : "Rs " + 0}
                          </FormRadio>
                        </Col>
                      </Row>
                    )}
                  {this.state.displayApproxfare === true &&
                    this.state.ridetype === "rental" && (
                      <Row className="mb-3">
                        <Col className="col-12 col-md-4 col-lg-4 d-flex justify-content-md-center">
                          <FormRadio
                            name="fruit"
                            checked={this.state.selectedFruit === "Hatchback"}
                            onChange={() => {
                              this.changeFruit(
                                "Hatchback",
                                this.state.rentalfares.hatchback.finalfare
                              );
                            }}
                          >
                            Hatchback -{" "}
                            {this.state.rentalfares !== undefined
                              ? "Rs " +
                                this.state.rentalfares.hatchback.finalfare
                              : "Rs " + 0}
                          </FormRadio>
                        </Col>
                        <Col className="col-12 col-md-4 col-lg-4 d-flex justify-content-md-center">
                          <FormRadio
                            name="fruit"
                            checked={this.state.selectedFruit === "Sedan"}
                            onChange={() => {
                              this.changeFruit(
                                "Sedan",
                                this.state.rentalfares.sedan.finalfare
                              );
                            }}
                          >
                            Sedan -{" "}
                            {this.state.data.length > 0 ||
                            this.state.data !== undefined
                              ? "Rs " + this.state.rentalfares.sedan.finalfare
                              : "Rs " + 0}
                          </FormRadio>
                        </Col>
                        <Col className="col-12 col-md-4 col-lg-4 d-flex justify-content-md-center">
                          <FormRadio
                            name="fruit"
                            checked={this.state.selectedFruit === "SUV"}
                            onChange={() => {
                              this.changeFruit(
                                "SUV",
                                this.state.rentalfares.suv.finalfare
                              );
                            }}
                          >
                            SUV -{" "}
                            {this.state.data.length > 0 ||
                            this.state.data !== undefined
                              ? "Rs " + this.state.rentalfares.suv.finalfare
                              : "Rs " + 0}
                          </FormRadio>
                        </Col>
                      </Row>
                    )}
                  <Row>
                    <Col className="col-12 col-md-5 col-lg-5">
                      <label>Rider name</label>
                      <FormInput
                        required
                        className="py-3"
                        placeholder="Enter ride name"
                        value={this.state.rider_name}
                        onChange={(e) => this.handleChange(e, "rider_name")}
                      />
                    </Col>
                    <Col className="col-12 col-md-5 col-lg-5">
                      <label>Rider email</label>
                      <FormInput
                        required
                        className="py-3"
                        placeholder="Enter email"
                        value={this.state.rider_email}
                        onChange={(e) => this.handleChange(e, "rider_email")}
                        type="email"
                      />
                    </Col>
                    <Col className="col-12 col-md-5 col-lg-5 mt-2">
                      <label>Rider mobile number</label>
                      <FormInput
                        required
                        className="py-3"
                        placeholder="Enter 10 digit phonenumber"
                        value={this.state.rider_phonenumber}
                        onChange={(e) =>
                          this.handleChange(e, "rider_phonenumber")
                        }
                        type="text"
                        minLength={10}
                        maxLength={10}
                      />
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="d-flex justify-content-center mt-2 mb-3">
                  <Button
                    className="login-button-new py-3"
                    theme="dark"
                    style={{
                      fontSize: "calc(0.5rem + 1vmin)",
                      fontWeight: 900,
                    }}
                  >
                    CONFIRM
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
        {this.state.success === false && (
          <ReactSnackBar
            Icon={
              <i
                className="far fa-times-circle fa-lg"
                style={{ color: "#FF0000" }}
              />
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
        {this.state.success === true && (
          <ReactSnackBar
            Icon={
              <span>
                <i
                  className="far fa-check-circle fa-lg"
                  style={{ color: "#26FF58" }}
                />
              </span>
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
      </Container>
    );
  }
}

export default BookARide;
