import React from "react";
import PropTypes from "prop-types";
import { Container, Col, Row } from "shards-react";

// import PopUp from "../components/common/Popup"
const EmptyLayout = ({ noNavbar, noFooter, children }) => (
  <Container fluid>
    {/* <Col className="mt-md-5"></Col> */}
    <main className="main-content px-3">
      {children}
      {/* {<PopUp />} */}
    </main>
  </Container>
);

export default EmptyLayout;
