import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Col } from "shards-react";
import DataTable from "react-data-table-component";
import Checkbox from "@material-ui/core/Checkbox";
import { Button as MatButton, TextField } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { withStyles } from "@material-ui/core/styles";

import * as stringData from "../../constants/Strings.json";
import DatePickerWithDay from "../../components_core/common/DatePickerWithDay";

const sortIcon = <ArrowDownward />;
const selectProps = {
  indeterminate: (isIndeterminate) => isIndeterminate,
  color: "dark",
};

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

const customStyles = {
  headCells: {
    style: {
      fontSize: "1.25em",
      fontWeight: 600,
    },
  },
  cells: {
    style: {
      paddingTop: "10px",
      paddingBottom: "10px",
      fontWeight: 500,
    },
  },
};

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <CssTextField
      id="search"
      fullWidth
      variant="outlined"
      label="Search"
      type="text"
      placeholder="Search drivers here"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
      InputLabelProps={{ shrink: true }}
    />
  </>
);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(TextField);

const CustomLoader = () => (
  <div style={{ padding: "24px" }}>
    <Spinner />
    <div>Loading data...</div>
  </div>
);

function DataTableBase(props) {
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  let filteredItems;
  console.log(props.datas);
  if (props.types === stringData.triptable) {
    filteredItems = props.datas.filter(
      (item) =>
        (item.consumername &&
          item.consumername.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.dname &&
          item.dname.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.sname &&
          item.sname.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.drivername &&
          item.drivername.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.driverphonenumber &&
          item.driverphonenumber
            .toLowerCase()
            .includes(filterText.toLowerCase())) ||
        (item.trip_id &&
          item.trip_id.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.vendor_id &&
          item.vendor_id.toLowerCase().includes(filterText.toLowerCase()))
    );
  } else if (props.types === stringData.tagtable) {
    console.log(props.datas);
    filteredItems = props.datas.filter(
      (item) =>
        (item.trip_id &&
          item.trip_id.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.driver_name &&
          item.driver_name.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.driver_phonenumber &&
          item.driver_phonenumber
            .toLowerCase()
            .includes(filterText.toLowerCase())) ||
        (item.time &&
          item.time.toLowerCase().includes(filterText.toLowerCase()))
    );
  } else if (props.types === stringData.reviewtable) {
    console.log(props.datas);
    filteredItems = props.datas.filter(
      (item) =>
        (item.title &&
          item.title.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.username &&
          item.username.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.statusrevise &&
          item.statusrevise.toLowerCase().includes(filterText.toLowerCase()))
    );
  } else {
    filteredItems = props.datas.filter(
      (item) =>
        (item.drivername &&
          item.drivername.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.driverphonenumber &&
          item.driverphonenumber
            .toLowerCase()
            .includes(filterText.toLowerCase())) ||
        (item.dname &&
          item.dname.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.driver_name &&
          item.driver_name.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.phonenumber &&
          item.phonenumber.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.phnno &&
          item.phnno.toLowerCase().includes(filterText.toLowerCase()))
    );
  }

  // console.log(filterText);
  console.log(props.datas.length > 0);
  // console.log(filteredItems);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    const changeDateToday = (data, type) => {
      props.changeDate(data, type);
    };
    return (
      <React.Fragment>
        {props.types === stringData.triptable && (
          <Col md="9" sm="12" className="px-0">
            <DatePickerWithDay
              changeDate={changeDateToday}
              type="table"
              date={props.date}
            />
          </Col>
        )}
        {props.types === stringData.assigntable && (
          <Col md="9" sm="12">
            <h5 style={{ fontWeight: 700 }}>Search drivers from the list</h5>
          </Col>
        )}
        {props.types !== stringData.reviewtable && (
          <Col md="3" sm="12" className="px-0 mb-4">
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
            />
          </Col>
        )}
      </React.Fragment>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      pagination
      selectableRowsComponent={Checkbox}
      selectableRowsComponentProps={selectProps}
      data={filteredItems}
      sortIcon={sortIcon}
      highlightOnHover
      responsive
      subHeader
      paginationResetDefaultPage={resetPaginationToggle}
      subHeaderComponent={subHeaderComponentMemo}
      customStyles={customStyles}
      progressComponent={<CustomLoader />}
      {...props}
    />
  );
}

export default DataTableBase;
