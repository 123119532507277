// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Button,
  BreadcrumbItem,
  Breadcrumb,
} from "shards-react";

import CommonBox from "../components/common/commonDisplayBox";
import PageTitle from "./../components/common/PageTitle";
// import TopDrivers from "../components/common/TopDrivers"
// import RideAnalysis from "../components/dataviews/RideAnalysis"
// const ReportsView = ({ smallStats, bottomStats }) => (

// );

class DispatchReports extends React.Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    }
  }

  render() {
    return (
      <Container fluid className="main-content-container px-md-4">
        {/* <Row>
          <Col className="col-12 col-md-12 col-lg-12">
            <div className="float-right mt-2">
              <Button className="" id="goback-button" onClick={this.goBack}>
                <i className="fas fa-arrow-left mx-1" />
                <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                  BACK
                </span>
              </Button>
            </div>
          </Col>
        </Row> */}
        <Row noGutters className="page-header py-3 px-md-3 mb-4">
          <Col className="col-12">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Reports
                </a>
              </BreadcrumbItem>
              {/* <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Dispatch Reports
                </a>
              </BreadcrumbItem> */}
              <BreadcrumbItem active>Dispatch reports</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="col-12 mt-1">
            <PageTitle
              title="Manage reports"
              className="text-sm-left custom-margin p-0"
            />
          </Col>
        </Row>
        <Row className="px-3">
          <Col className="col-12 col-md-12 col-lg-12 mb-5 mb-md-3">
            <div className="float-right">
              <Button
                className=""
                id="goback-button"
                theme="dark"
                onClick={this.goBack}
              >
                <i className="fas fa-arrow-left mx-1" />
                <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                  BACK
                </span>
              </Button>
            </div>
          </Col>
          {this.props.smallStats.map((stats, idx) => (
            <Col className="col-md mb-4" key={idx} {...stats.attrs}>
              {/* {console.log(smallStats)} */}
              {/* {console.log(idx)}
                  {console.log(stats)} */}
              <CommonBox
                id={`small-stats-${idx}`}
                variation="1"
                label={stats.label}
                value={stats.value}
                stats={stats.stats}
                display={stats.display}
                path="/reports/dispatchreports/"
              />
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}
DispatchReports.propTypes = {
  smallStats: PropTypes.array,
};

DispatchReports.defaultProps = {
  smallStats: [
    {
      label: "shiftreports",
      value: "Shift reports",
      id: "shiftreports",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
    },
  ],
};

export default DispatchReports;
