import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "shards-react";
import { motion } from "framer-motion";
import moment from "moment";
// import Accordion from "react-bootstrap/Accordion";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Card from "react-bootstrap/Card";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import DropArrow from "../../assets/toggle.svg";
import { sortedIndexBy } from "lodash";
import * as config from "../../constants/constants.json";
// import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Cabin, sans-serif",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  step: {
    height: "30px",

    "&$completed": {
      color: "#27FC69",
    },
    "&$disabled": {
      color: "#FF0000",
    },
    "&$error": {
      color: "#FF0000",
    },
  },
  error: {
    color: "#FF0000",
  },
  completed: {
    color: "#27FC69",
  },
  active: {
    backgroundColor: "#27FC69",
  },
}));

function getStatus(data) {
  if (
    data.passenger_state === "pickup" &&
    (data.passenger_status === "boarded" || data.passenger_status === "dropped")
  ) {
    return true;
  } else if (
    data.passenger_state === "drop" &&
    data.passenger_status === "dropped"
  ) {
    return true;
  } else {
    return false;
  }
}

function getError(data) {
  if (
    data.passenger_state === "pickup" &&
    data.passenger_status === "passengernoshow"
  ) {
    return true;
  } else if (
    data.passenger_state === "drop" &&
    data.passenger_status === "passengernoshow"
  ) {
    return true;
  } else {
    return false;
  }
}

export default function VerticalLinearStepper(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [phonenumber, setPhonenumber] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [clickedID, setClickedID] = useState(undefined);
  const [prevClicked, setPrevClicked] = useState(undefined);
  // console.log(props);

  // const callToggle = (data) => {
  //   console.log(data);
  //   setPrevClicked(clickedID);
  //   setClicked(!clicked);
  //   setClickedID(data);
  // };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const openCancelMod = (phone) => {
    setOpen(true);
    setPhonenumber(phone);
  };

  const closeCancelMod = () => {
    setOpen(false);
  };

  const cancelRide = (trip_id, mobile) => {
    let data = {
      userid: window.sessionStorage.getItem("user_id"),
      trip_id: props.trip_id,
      passengerStatus: "PASSENGER_NOT_BOARDED",
      passenger_mobile: phonenumber,
      dunique_id: props.dunique_id === undefined ? "" : props.dunique_id,
    };
    // console.log(data);
    fetch(config.url + "htmlcrm/updateQRPassengerStatus ", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if (result.message === "successfull") {
            setOpen(false);
            props.triggerDetails();
          } else {
            // console.log("error");
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  };

  return (
    <div className={classes.root}>
      <Stepper
        nonLinear
        orientation="vertical"
        className="px-0"
        activeStep={-1}
      >
        {props.data.map((data, index) => (
          <Step expanded key={index} completed={getStatus(data)}>
            <StepLabel
              error={getError(data)}
              StepIconProps={{
                classes: {
                  root: classes.step,
                  completed: classes.completed,
                },
              }}
            ></StepLabel>
            <StepContent>
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                className="ml-4"
                style={{
                  marginTop: "-60px",
                  marginBottom: "30px",
                  boxShadow: "0 4px 14px #00000029",
                  borderRadius: "8px",
                }}
                expanded={expanded === index}
                onChange={handleChange(index)}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id={"panel1a-header-" + index}
                  expandIcon={
                    <img src={DropArrow} height="auto" width="30"></img>
                  }
                >
                  <Row className="w-100">
                    <Col className="col-7">
                      <div className="px-2">
                        <label className="stepHead">Rider name</label>
                        <div className="divCustom">{data.passenger_name}</div>
                      </div>
                    </Col>
                    <Col className="col-5 px-0">
                      {data.passenger_state === "pickup" && (
                        <div className="px-2">
                          <label className="stepHead">Pickup</label>
                          <div className="divCustom">
                            {moment(new Date(data.pickup_time)).format(
                              "DD-MM-YY [at] hh:mm:ss A"
                            )}
                          </div>
                        </div>
                      )}
                      {data.passenger_state === "drop" && (
                        <div className="px-2">
                          <label className="stepHead">Drop off</label>
                          <div className="divCustom">
                            {moment(new Date(data.drop_time)).format(
                              "DD-MM-YY [at] hh:mm:ss A"
                            )}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </AccordionSummary>
                <AccordionDetails>
                  <Row className="">
                    <Col className="col-6 mb-4">
                      <div className="px-2">
                        <label className="stepHead">Rider mobile number</label>
                        <div className="divCustom">{data.passenger_mobile}</div>
                      </div>
                    </Col>
                    <Col className="col-6 mb-4">
                      <div className="px-2">
                        <label className="stepHead">Rider email</label>
                        <div className="divCustom">{data.passenger_email}</div>
                      </div>
                    </Col>
                    <Col className="col-12 mb-4">
                      <div className="px-2">
                        <label className="stepHead">Pickup locatiton</label>
                        <div className="divCustom">
                          {data.pickup_location_name}
                        </div>
                      </div>
                    </Col>
                    <Col className="col-12 mb-4">
                      <div className="px-2">
                        <label className="stepHead">Drop-off locatiton</label>
                        <div className="divCustom">
                          {data.drop_location_name}
                        </div>
                      </div>
                    </Col>
                    <Col className="col-12 mb-2">
                      <div className="px-2">
                        <label className="stepHead">Trip start CRN</label>
                        <div className="divCustom">{data.otp}</div>
                      </div>
                    </Col>
                    {data.passenger_state === "pickup" &&
                      data.passenger_status !== "passengernoshow" &&
                      data.passenger_status !== "boarded" &&
                      (props.trip_status === "Assigned" ||
                        props.trip_status === "Accepted" ||
                        props.trip_status === "Started" ||
                        props.trip_status === "Notified") && (
                        <Col className="col-12 mb-2 d-flex justify-content-center">
                          <Button
                            className="py-3"
                            theme="danger"
                            style={{
                              minWidth: "125px",
                            }}
                            onClick={() => openCancelMod(data.passenger_mobile)}
                          >
                            <label
                              className="my-0"
                              style={{
                                fontSize: "0.9rem",
                              }}
                            >
                              RIDER NO-SHOW
                            </label>
                          </Button>
                        </Col>
                      )}
                  </Row>
                </AccordionDetails>
              </Accordion>
            </StepContent>
            {/* {console.log(
              "------------------------------------------------------"
            )}
            {console.log(data)} */}
          </Step>
        ))}
      </Stepper>
      <Modal open={open} centered modalClassName="modal-overflow-control">
        <ModalHeader>
          <h5 className="my-0">Ride cancellation</h5>
        </ModalHeader>
        <ModalBody>
          <label
            style={{
              fontSize: "0.9rem",
            }}
          >
            Do you want t cancel the ride?
          </label>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Button className="mx-2" onClick={() => cancelRide()}>
              CONFIRM
            </Button>
            <Button
              theme="danger"
              className="mx-2"
              onClick={() => {
                setOpen(false);
              }}
            >
              CLOSE
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
}
