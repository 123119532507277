// import React from "react";
// import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, LoginLayout } from "./layouts";

// Route Views
import Rides from "./views/Rides";
import BizAccounts from "./views/BusinessAccounts";
// import LoginViews from "./views/LoginView";
import LoginView from "./viewHooks/LoginView";
// import Vendors from "./views/Vendors";
import ViewData from "./views/ViewData";

import DriverStatus from "./views/DriverStatus";
import DetachView from "./views/RideDetachment";
import DriverAccounts from "./views/DriverAccounts";
// import AddDriver from "./views/AddDriver";
import BookARide from "./views/BookARide";
// import AddVehivleData from "./views/AddVehicleData";
// import AddBankDetails from "./views/AddBankDetails";
import EditDriver from "./views/EditDriver";
import AddEditDriver from "./views/Addeditdriver";
import QuickRegistration from "./views/QuickRegister";
import DriverActivation from "./views/DriverActivations";
import OnboardingTracker from "./views/OnboardingTracker";
import UploadTrackerFiles from "./views/UploadTrackerFiles";
import ViewTracker from "./views/ViewTracker";
import DriverMainDashboard from "./views/DriverMainDashboard";
import DriverDashboard from "./views/DriverDashboard";
import Idproofs from "./views/Idproofs";
import VehicleImage from "./views/VehicleImage";
import DriverRideHistory from "./views/DriverRideHistory";
import EmployeeManagementView from "./views/EmployeeManagementView";
import DispatchAccountViews from "./components/dataviews/DispatchAccountViews";
import SupplyAccountViews from "./components/dataviews/SupplyAccounts";
import AccountViews from "./components/dataviews/AccountAccountsView";
import RejectedRides from "./views/RejectedRidesList";
import ReportsView from "./views/ReportsView";
import TripReports from "./components/dataviews/TripReports";
import DispatchReports from "./views/DispatchReportsView";
import ShiftReports from "./views/ShiftReports";
import PayoutsView from "./views/PayoutView";
// import PayoutReports from "./components/dataviews/PayoutReports";
// import GenerateReport from "./components/dataviews/GenerateReport";
// import ManageBenificiery from "./views/ManageBeneficiary";
// import PrePayout from "./views/PrePayout";
// import PayoutGeneration from "./views/PayoutGeneration";
// import TransactionHistoryViews from "./views/TransactioHistoryView";
// import RecentPayoutHistory from "./components/dataviews/RecentPayoutHistory";
// import TransactionHistory from "./components/dataviews/TransactionHistory";
import NearByDrivers from "./views/NearbyDrivers";
import EmptyLayout from "./layouts/EmptyLayout";
import AutoAssignLogs from "./views/viewAutoAssignLogs";
import TripDebugInfo from "./views/TripDebugInfo";
// import Faq from "./views/FAQ";
// import TestPRogress from "./views/TestProgress";
// import SecurityDeposit from "./views/SecurityDeposit";
// import PayoutConfig from "./views/PayoutConfig";
// import ConfigVendor from "./views/ConfigVen";
// import VendorMain from "./views/VendorMain";
import VendorMain from "./viewHooks/VendorMain";
import QClist from "./views/QClist";
import QtripDetails from "./components/common/QtripDetails";
// import ResetPass from "./components/common/ResetPassword";
import ResetPass from "./viewHooks/ResetPass";
import ResetConfirmation from "./components/common/ResetConfirmation";
// import CreateNewPass from "./components/common/CreateNewPass";
import CreateNewPass from "./viewHooks/ChangePassword";
// import SuccessScreen from "./components/common/SuccessReset";
import NearbyLayout from "./layouts/NearbyLayout";
import FirstUser from "./viewHooks/FirstUser";
import RegisterFace from "./viewHooks/RegisterFace";
import FaceDetection from "./viewHooks/FaceScanner";
import FaceLayout from "./layouts/FaceLayout";
// import Contests from "./viewHooks/Contests";
// import ContestContainer from "./componentHooks/common/ContestContainer";
import AddDriverForm from "./viewHooks/AddDriver";
import TagRidesList from "./viewHooks/TagRidesList";
import FareConfiguration from "./viewHooks/FareConfiguration";
import Configuration from "./views/ConfigurationTabs";
import ConfigAnalyics from "./viewHooks/ConfigAnalytics";
import FareConfig from "./viewHooks/FareConfig";
import FareConfigMix from "./viewHooks/FareConfigMix";
import FareAnalyticsGraph from "./viewHooks/FareAnalyticsGraph";
import FareSettings from "./viewHooks/FareSettings";
import FareConfigMaster from "./viewHooks/FareConfig_master";
import OtpView from "./views/OtpView";

/**Core modules components */

import BookingsView from "./views_core/BusinessAccounts/Bookings/BookingsView";

export default [
  {
    path: "/configuration/faresettings",
    exact: true,
    layout: DefaultLayout,
    component: FareSettings,
  },
  {
    path: "/configuration",
    exact: true,
    layout: DefaultLayout,
    component: Configuration,
  },
  {
    path: "/configuration/fareconfiguration",
    exact: true,
    layout: DefaultLayout,
    component: FareConfigMaster,
  },

  {
    path: "/businessaccounts/tagRideList",
    exact: true,
    layout: DefaultLayout,
    component: TagRidesList,
  },
  {
    path: "/businessaccounts/driverotps",
    exact: true,
    layout: DefaultLayout,
    component: OtpView,
  },
  {
    path: "/faceDetection",
    exact: true,
    layout: FaceLayout,
    component: FaceDetection,
  },
  {
    path: "/",
    exact: true,
    layout: LoginLayout,
    component: LoginView,
  },
  {
    path: "/home",
    exact: true,
    layout: DefaultLayout,
    component: Rides,
  },
  {
    path: "/resetpass",
    exact: true,
    layout: LoginLayout,
    component: ResetPass,
  },
  {
    path: "/registerFace",
    exact: true,
    layout: LoginLayout,
    component: RegisterFace,
  },
  {
    path: "/changePasswordszzzzz",
    search: "?ctid=",
    exact: true,
    layout: LoginLayout,
    component: CreateNewPass,
  },
  {
    path: "/newUserCreation",
    exact: true,
    layout: LoginLayout,
    component: FirstUser,
  },
  {
    path: "/resetpass/confirmation",
    exact: true,
    layout: LoginLayout,
    component: ResetConfirmation,
  },

  {
    path: "/changePassword",
    search: "?avatar=",
    layout: LoginLayout,
    component: CreateNewPass,
  },
  {
    path: "/businessaccounts",
    exact: true,
    layout: DefaultLayout,
    component: BizAccounts,
  },
  {
    path: "/driveraccounts",
    exact: true,
    layout: DefaultLayout,
    component: DriverAccounts,
  },
  {
    path: "/bookaride",
    exact: true,
    layout: DefaultLayout,
    component: BookARide,
  },
  {
    path: "/reports",
    exact: true,
    layout: DefaultLayout,
    component: ReportsView,
  },
  {
    path: "/businessaccounts/rejectedrideslist",
    exact: true,
    layout: DefaultLayout,
    component: RejectedRides,
  },
  {
    path: "/businessaccounts/tripdebuginfo",
    exact: true,
    layout: DefaultLayout,
    component: TripDebugInfo,
  },
  {
    path: "/driveraccounts/qclist",
    exact: true,
    layout: DefaultLayout,
    component: QClist,
  },
  {
    path: "/tripdetails",
    exact: true,
    layout: DefaultLayout,
    component: QtripDetails,
  },
  {
    path: "/businessaccounts/viewdata/:trp_id",
    exact: true,
    layout: DefaultLayout,
    component: ViewData,
  },
  {
    path: "/businessaccounts/bookings",
    exact: true,
    layout: DefaultLayout,
    component: VendorMain,
  },
  {
    path: "/businessaccounts/bookingsnew",
    exact: true,
    layout: DefaultLayout,
    component: BookingsView,
  },
  {
    path: "/businessaccounts/driverstatus",
    exact: true,
    layout: DefaultLayout,
    component: DriverStatus,
  },
  {
    path: "/businessaccounts/ridedetachments",
    exact: true,
    layout: DefaultLayout,
    component: DetachView,
  },
  {
    path: "/driveraccounts/adddriver",
    exact: true,
    layout: DefaultLayout,
    component: AddDriverForm,
  },
  {
    path: "/driveraccounts/editdriver",
    exact: true,
    layout: DefaultLayout,
    component: EditDriver,
  },
  {
    path: "/driveraccounts/addeditdriver",
    exact: true,
    layout: DefaultLayout,
    component: AddEditDriver,
  },
  {
    path: "/driveraccounts/quicregister",
    exact: true,
    layout: DefaultLayout,
    component: QuickRegistration,
  },
  {
    path: "/driveraccounts/driveractivation",
    exact: true,
    layout: DefaultLayout,
    component: DriverActivation,
  },
  {
    path: "/driveraccounts/onboardingtracker",
    exact: true,
    layout: DefaultLayout,
    component: OnboardingTracker,
  },
  {
    path: "/driveraccounts/onboardingtracker/uploadfile",
    exact: true,
    layout: DefaultLayout,
    component: UploadTrackerFiles,
  },
  {
    path: "/driveraccounts/onboardingtracker/viewtracker",
    exact: true,
    layout: DefaultLayout,
    component: ViewTracker,
  },
  {
    path: "/driveraccounts/driverdashboard",
    exact: true,
    layout: DefaultLayout,
    component: DriverMainDashboard,
  },
  {
    path: "/driveraccounts/driverdashboard/driverdetails",
    exact: true,
    layout: DefaultLayout,
    component: DriverDashboard,
  },
  {
    path: "/driveraccounts/driverdashboard/driverdetails/idproofs",
    exact: true,
    layout: DefaultLayout,
    component: Idproofs,
  },
  {
    path: "/driveraccounts/driverdashboard/driverdetails/vehicleimages",
    exact: true,
    layout: DefaultLayout,
    component: VehicleImage,
  },
  {
    path: "/driveraccounts/driverdashboard/driverdetails/ridehistory",
    exact: true,
    layout: DefaultLayout,
    component: DriverRideHistory,
  },
  {
    path: "/employeemanagement",
    exact: true,
    layout: DefaultLayout,
    component: EmployeeManagementView,
  },
  {
    path: "/employeemanagement/dispatch",
    exact: true,
    layout: DefaultLayout,
    component: DispatchAccountViews,
  },
  {
    path: "/employeemanagement/supply",
    exact: true,
    layout: DefaultLayout,
    component: SupplyAccountViews,
  },
  {
    path: "/employeemanagement/accounts",
    exact: true,
    layout: DefaultLayout,
    component: AccountViews,
  },
  {
    path: "/reports/tripreports",
    exact: true,
    layout: DefaultLayout,
    component: TripReports,
  },
  {
    path: "/reports/dispatchreports",
    exact: true,
    layout: DefaultLayout,
    component: DispatchReports,
  },
  {
    path: "/reports/dispatchreports/shiftreports",
    exact: true,
    layout: DefaultLayout,
    component: ShiftReports,
  },
  {
    path: "/nearbydrivers",
    search: "?operating_region=",
    exact: true,
    layout: NearbyLayout,
    component: NearByDrivers,
  },
  {
    path: "/autoassignlogs/:id",
    exact: true,
    layout: EmptyLayout,
    component: AutoAssignLogs,
  },
  {
    path: "/configuration/countanalytics",
    exact: true,
    layout: DefaultLayout,
    component: FareAnalyticsGraph,
  },
  {
    path: "/payouts",
    exact: true,
    layout: DefaultLayout,
    component: PayoutsView,
  },
];

/** Old routes for bookings
 *  {
    path: "/testprogress",
    exact: true,
    layout: EmptyLayout,
    component: TestPRogress,
  }, {
    path: "/newForm",
    exact: true,
    layout: DefaultLayout,
    component: AddDriverForm,
  },
  {
    path: "/contest",
    exact: true,
    layout: DefaultLayout,
    component: Contests,
  },
  {
    path: "/contest/:id",
    exact: true,
    layout: DefaultLayout,
    component: ContestContainer,
  },
  {
    path: "/businessaccounts/hornok",
    exact: true,
    layout: DefaultLayout,
    component: VendorMain,
  },
  {
    path: "/businessaccounts/cleartrip",
    exact: true,
    layout: DefaultLayout,
    component: VendorMain,
  },
  {
    path: "/businessaccounts/zippys",
    exact: true,
    layout: DefaultLayout,
    component: VendorMain,
  },
  {
    path: "/businessaccounts/oyo",
    exact: true,
    layout: DefaultLayout,
    component: VendorMain,
  },
  {
    path: "/businessaccounts/yatra",
    exact: true,
    layout: DefaultLayout,
    component: VendorMain,
  },
  {
    path: "/businessaccounts/viacom",
    exact: true,
    layout: DefaultLayout,
    component: VendorMain,
  },
  {
    path: "/businessaccounts/quickride",
    exact: true,
    layout: DefaultLayout,
    component: VendorMain,
  },
  {
    path: "/payouts",
    exact: true,
    layout: DefaultLayout,
    component: PayoutsView,
  },
  {
    path: "/payouts/payoutreports",
    exact: true,
    layout: DefaultLayout,
    component: PayoutReports,
  },
  {
    path: "/payouts/generatereports",
    exact: true,
    layout: DefaultLayout,
    component: GenerateReport,
  },
  {
    path: "/payouts/managebeneficiary",
    exact: true,
    layout: DefaultLayout,
    component: ManageBenificiery,
  },
  {
    path: "/payouts/prepayout",
    exact: true,
    layout: DefaultLayout,
    component: PrePayout,
  },
  {
    path: "/payouts/payoutgeneration",
    exact: true,
    layout: DefaultLayout,
    component: PayoutGeneration,
  },
  {
    path: "/payouts/transactions",
    exact: true,
    layout: DefaultLayout,
    component: TransactionHistoryViews,
  },
  {
    path: "/payouts/transactions/recent",
    exact: true,
    layout: DefaultLayout,
    component: RecentPayoutHistory,
  },
  {
    path: "/payouts/transactions/history",
    exact: true,
    layout: DefaultLayout,
    component: TransactionHistory,
  },
  {
    path: "/payouts/securitydeposit",
    exact: true,
    layout: DefaultLayout,
    component: SecurityDeposit,
  },
  {
    path: "/payouts/payoutconfig",
    exact: true,
    layout: DefaultLayout,
    component: PayoutConfig,
  },
  {
    path: "/payouts/payoutconfig/mmt",
    exact: true,
    layout: DefaultLayout,
    component: ConfigVendor,
  },
 */
