// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import PayoutsBox from "../components/common/PayoutBox";
import PageTitle from "./../components/common/PageTitle";
import * as config from "../constants/constants.json";
import * as stringsConstant from "../constants/stringsConstant.json";
import { TimerSharp } from "@material-ui/icons";
// import TopDrivers from "../components/common/TopDrivers"
// import RideAnalysis from "../components/dataviews/RideAnalysis"

class PayoutsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bankBalance: 0,
      opexbalance: 0,
    };
  }

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null ||
      !stringsConstant.user_ids.includes(
        window.sessionStorage.getItem("user_id")
      )
    ) {
      window.location.assign("/");
    } else {
      this.getBankDetails();
      this.getOpexDetails();
    }
  }

  getBankDetails() {
    fetch(config.url + "payout/getBankBalance", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        executiveID: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (results) => {
          console.log(results);
          if (results.message === "successfull") {
            this.setState({
              bankBalance: results.availableBalance,
            });
          } else {
            // alert("Error");
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  getOpexDetails() {
    fetch(config.url + "payout/getOpexWalletBalance", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        executiveID: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (results) => {
          console.log(results);
          if (results.message === "successfull") {
            this.setState({
              opexbalance: results.availableBalance,
            });
          } else {
            // alert("Error");
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  render() {
    return (
      <Container fluid className="main-content-container py-4 px-4">
        <Row noGutters className="page-header  px-3 mb-4">
          <PageTitle
            title="Manage payouts"
            subtitle="Payouts"
            className="text-sm-left mb-3"
          />
        </Row>
        <Row className="px-3">
          {this.props.smallStats.map((stats, idx) => (
            <Col className="col-md mb-4" key={idx} {...stats.attrs}>
              {/* {console.log(smallStats)} */}
              {/* {console.log(idx)}
          {console.log(stats)} */}
              <PayoutsBox
                id={`small-statss-${idx}`}
                variation="1"
                label={stats.label}
                value={this.state[stats.value]}
                stats={stats.stats}
                display={stats.display}
                type={stats.type}
              />
            </Col>
          ))}
        </Row>
        <Row className="py-3">
          <Col className="col-12">
            <PageTitle title="Configuration" className="text-sm-left" />
          </Col>
        </Row>
        <Row className="px-3">
          {this.props.payStats.map((stats, idx) => (
            <Col className="col-md mb-4" key={idx} {...stats.attrs}>
              {/* {console.log(smallStats)} */}
              {/* {console.log(idx)}
          {console.log(stats)} */}
              <PayoutsBox
                id={`small-statss-${idx}`}
                variation="1"
                label={stats.label}
                value={this.state[stats.value]}
                stats={stats.stats}
                display={stats.display}
                type={stats.type}
              />
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}
// const PayoutsView = ({ smallStats, bottomStats }) => (
// );

PayoutsView.propTypes = {
  smallStats: PropTypes.array,
};
// {
//   label: "configurepayout",
//   value: "Configure payout",
//   id: "configurepayreports",
//   attrs: { md: "3", sm: "6" },
//   stats: false,
//   display: false,
// }
PayoutsView.defaultProps = {
  smallStats: [
    {
      label: "Available balance in opex wallet",
      value: "opexbalance",
      id: "opexbalance",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: true,
      type: "opex",
    },
    {
      label: "Available balance for payouts",
      value: "bankBalance",
      id: "bankbalance",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: true,
      type: "bank",
    },
  ],
  payStats: [
    {
      label: "Toggle auto-payout",
      value: "bankBalance",
      id: "bankbalance",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: true,
      type: "payout",
    },
    {
      label: "Toggle comission",
      value: "bankBalance",
      id: "bankbalance",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: true,
      type: "comission",
    },
  ],
};

export default PayoutsView;

/**
 * {
      label: "payoutreports",
      value: "Payout reports",
      id: "payoutreports",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "prepayout",
      value: "Pre payout",
      id: "prepayoutreports",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "payoutconfig",
      value: "Payout configuration",
      id: "payoutconfig",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "payoutgeneration",
      value: "Payouts",
      id: "payoutgeneration",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "generatereports",
      value: "Generate reports",
      id: "generatereports",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "transactions",
      value: "Transactions",
      id: "payoutgeneration",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "managebeneficiary",
      value: "Manage beneficiary",
      id: "managebeneficiary",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "securitydeposit",
      value: "Security deposit",
      id: "managebeneficiary",
      attrs: { md: "3", sm: "6" },
      stats: false,
      display: false,
    },
 */
