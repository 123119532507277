// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";

import OnboardingBox from "../components/common/OnboardingBox";
import PageTitle from "./../components/common/PageTitle";
// import TopDrivers from "../components/common/TopDrivers"
// import RideAnalysis from "../components/dataviews/RideAnalysis"

class OnboardingTracker extends React.Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    }
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4 px-md-3">
          <Col>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Driver accounts
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active />
            </Breadcrumb>
          </Col>
          <Col className="col-12 mt-1">
            <PageTitle
              title="On-boarding tracker"
              className="text-sm-left mb-3 p-0 custom-margin"
            />
          </Col>
        </Row>
        <Row className="mt-2 mb-3 px-md-3">
          <Col className="col-12 col-md-12 col-lg-12 mb-4">
            <Button
              className=" float-right"
              theme="dark"
              id="goback-button"
              onClick={this.goBack}
            >
              <i className="fas fa-arrow-left mx-1" />
              <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                BACK
              </span>{" "}
            </Button>
          </Col>
        </Row>
        <Row className="px-3">
          {this.props.onBoardingTop.map((stats, idx) => (
            <Col className="col-md mb-4" key={idx} {...stats.attrs}>
              <OnboardingBox
                id={`small-stats-${idx}`}
                variation="1"
                label={stats.label}
                value={stats.value}
                stats={stats.stats}
                display={stats.display}
              />
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

{
  /* const OnboardingTracker = ({ onBoardingTop }) => (
    
); */
}

OnboardingTracker.propTypes = {
  onBoardingTop: PropTypes.array,
};

OnboardingTracker.defaultProps = {
  onBoardingTop: [
    {
      label: "uploadfile",
      value: "Upload file",
      id: "UPLOADFILE",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "viewtracker",
      value: "View tracker",
      id: "viewtracker",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
    },
  ],
};

export default OnboardingTracker;
