import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Collapse,
  Button,
  Tooltip,
  Card,
  CardImg,
  CardBody,
  Form,
  FormCheckbox,
  FormSelect,
  FormGroup,
  FormInput,
  CardHeader,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  Breadcrumb,
  BreadcrumbItem,
  FormTextarea,
} from "shards-react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import equal from "fast-deep-equal/react";
import ReactDatePicker from "react-datepicker";
import * as config from "../constants/constants.json";
import * as stringConstant from "../constants/stringsConstant.json";
import PageTitle from "./../components/common/PageTitle";
import InputDataForm from "./../components/common/InputDataForm";
import TextArea from "./../components/common/TextArea";
import DragAndDrop from "./../components/common/DragAndDropProfile";
import AddVehicleData from "./../views/AddVehicleData";
import Datepicker from "react-date-picker";
import ReactDatepicker from "react-datepicker";
import NavTab from "./../components/common/NavTab";
import EditDrivatab from "./../components/common/EditDrivatab";
import DivRender from "./../components/common/DivRender";
import ReactSnackBar from "react-js-snackbar";
function convertStatus(data) {
  if (data === "Active") {
    return <div style={{ color: "#59E67F" }}>Active</div>;
  } else if (data === "suspended") {
    return <div style={{ color: "#FF0000" }}>Suspended</div>;
  } else if (data === "Inactive") {
    return <div style={{ color: "#FF8307" }}>Inactive</div>;
  } else {
    return <div style={{ color: "black" }}>Blocked</div>;
  }
}
class EditDriverLogic extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props);
    this.state = {
      totalData: [],
      dlTest: true,
      insuranceTest: true,
      badgeTest: true,
      permitTest: true,
      emissonTest: true,
      roadTest: true,
      fcTest: true,
      showDiv: true,
      Show: false,
      propDta: [],
      Showing: false,
      success: false,
      message: "",
      openSuspendModal: false,
      openBlockModal: false,
      openActiveModal: false,
      searchphonenumber: "",
      index: 0,
      readOnly: true,
      readOnlyVehicle: true,
      readonlyCheckbox: true,
      readOnlyFamilyDetails: true,
      readOnlyBankDetails: true,
      open: true,
      login_status: "-",
      bankDetailsCog: true,
      driverDetails: true,
      displayProfCog: true,
      vehicleDetailsCog: true,
      driver_type: "",
      ridePrefCog: true,
      familyDetailsCog: true,
      visible: false,
      notExist: false,
      consumerWarn: false,
      divName: "id_proofs",
      first_name: "",
      last_name: "",
      dob: "",
      email: "",
      current_address: "",
      permanent_address: "",
      outstation: false,
      rentals: false,
      airport: false,
      urban: false,
      recurride: false,
      day_shift: false,
      night_shift: false,
      operating_region: "",
      preferred_location: "",
      starting_point: "",
      vehicle_name: "",
      vehicle_number: "",
      vehicle_color: "",
      vehicle_make: "",
      vehicle_model: "",
      phonenumber: "",
      alt_phonenumber: "",
      driver_profile:
        "https://master.gloryindia.in/static/images/avatars/man.png",
      family_name_1: "",
      family_name_2: "",
      family_phn_1: "",
      family_phn_2: "",
      family_rel_1: "",
      family_rel_2: "",
      family_address_1: "",
      family_address_2: "",
      acc_holder_name: "",
      acc_number: "",
      bank_name: "",
      bank_verified_status: "",
      ifsc: "",
      acc_phonenumber: "",
      acc_email: "",
      acc_address: "",
      avg_ratings: "",
      vehicle_type: "",
      results: [],
      vehicle_front_image:
        "https://master.gloryindia.in/static/images/document.png",
      vehicle_back_image:
        "https://master.gloryindia.in/static/images/document.png",
      vehicle_left_image:
        "https://master.gloryindia.in/static/images/document.png",
      vehicle_right_image:
        "https://master.gloryindia.in/static/images/document.png",
      vehicle_with_driver:
        "https://master.gloryindia.in/static/images/document.png",
      aadhar_card_image_front:
        "https://master.gloryindia.in/static/images/document.png",
      aadhar_card_image_back:
        "https://master.gloryindia.in/static/images/document.png",
      pan_card_image: "https://master.gloryindia.in/static/images/document.png",
      driving_license_image:
        "https://master.gloryindia.in/static/images/document.png",
      rc_image: "https://master.gloryindia.in/static/images/document.png",
      vehicle_insurance_image:
        "https://master.gloryindia.in/static/images/document.png",
      driver_badgee_image:
        "https://master.gloryindia.in/static/images/document.png",
      emission_cert_image:
        "https://master.gloryindia.in/static/images/document.png",
      road_tax_image: "https://master.gloryindia.in/static/images/document.png",
      permit_image: "https://master.gloryindia.in/static/images/document.png",
      fitness_image: "https://master.gloryindia.in/static/images/document.png",
      sales_agreement:
        "https://master.gloryindia.in/static/images/document.png",
      police_verification:
        "https://master.gloryindia.in/static/images/document.png",
      badge_number: "",
      permit_number: "",
      aadhar_number: "",
      pan_number: "",
      dl_number: "",
      dunique_id: "",
      driverformImageFront:
        "https://master.gloryindia.in/static/images/document.png",
      driverformImageBack:
        "https://master.gloryindia.in/static/images/document.png",
      registeredBy: "",
      registeredDate: "",
      lastEditedBy: "",
      lastEditedDate: "",
      suspendReason: "",
      dl_expiry: undefined,
      roadtax_expiry: undefined,
      permit_expiry: undefined,
      fc_expiry: undefined,
      insurance_expiry: undefined,
      emission_expiry: undefined,
      display_expiry: undefined,
      openLisc: true,
      openRoad: true,
      hideType: "",
      openConfirm: false,
      driverTypeCog: true,
      vehicleTypeCog: true,
      readOnlyDriver: true,
      readOnlyType: true,
    };
    this.updateExpiry = this.updateExpiry.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.handleNavClick = this.handleNavClick.bind(this);
    this.handleKeyChange = this.handleKeyChange.bind(this);
    this.toggleExists = this.toggleExists.bind(this);
    this.toggleWarn = this.toggleWarn.bind(this);
    this.searchDriver = this.searchDriver.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleEditDetails = this.handleEditDetails.bind(this);
    this.handleImagedata = this.handleImagedata.bind(this);
    this.handleProfileDetails = this.handleProfileDetails.bind(this);
    this.handleVehicleDetails = this.handleVehicleDetails.bind(this);
    this.handleEditVehicle = this.handleEditVehicle.bind(this);
    this.handleEditRidepref = this.handleEditRidepref.bind(this);
    this.handleRideprefDetails = this.handleRideprefDetails.bind(this);
    this.handleFamilyDetails = this.handleFamilyDetails.bind(this);
    this.handleEditFamily = this.handleEditFamily.bind(this);
    this.handleBankDetails = this.handleBankDetails.bind(this);
    this.handleEditBank = this.handleEditBank.bind(this);
    this.handelvehicleType = this.handelvehicleType.bind(this);
    this.imagehandler = this.imagehandler.bind(this);
    this.callImagehandler = this.callImagehandler.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.onboarddriver = this.onboarddriver.bind(this);
    this.toggleBlock = this.toggleBlock.bind(this);
    this.toggleSuspend = this.toggleSuspend.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
    this.handleAreaChange = this.handleAreaChange.bind(this);
    this.callSuspendUser = this.callSuspendUser.bind(this);
    this.callActivateUser = this.callActivateUser.bind(this);
    this.callActivateType = this.callActivateType.bind(this);
    this.callBlockuser = this.callBlockuser.bind(this);
    this.setAlternateNumber = this.setAlternateNumber.bind(this);
    this.dateChange = this.dateChange.bind(this);
    this.hideDiv = this.hideDiv.bind(this);
    this.setHide = this.setHide.bind(this);
    this.handlevehicleTypeDetails = this.handlevehicleTypeDetails.bind(this);
    this.toggleActiveType = this.toggleActiveType.bind(this);
    this.handeldriverType = this.handeldriverType.bind(this);
    this.valueChange = this.valueChange.bind(this);
    this.handledriverTypeDetails = this.handledriverTypeDetails.bind(this);
    this.updateIdno = this.updateIdno.bind(this);
  }

  verifyDL = () => {
    // console.log("hello dl");
  };

  verifyRC = () => {
    // console.log("hello rc");
  };

  valueChange(e, name) {
    // console.log("-----------------------999999999999999");
    let value;
    let newState = {};
    value = e.target.value;
    // console.log(value);
    newState[name] = value;
    this.setState({ ...this.state, ...newState });
  }
  updateIdno(name) {
    // console.log(name);
    let data;
    if (name === "driving_license_image") {
      data = {
        dl_num: this.state.dl_number,
        dataType: "dlNum",
        dunique_id: this.state.dunique_id,
        editedBy: sessionStorage.getItem("user_id"),
      };
    } else if (name === "driver_badgee_image") {
      data = {
        badgenumber: this.state.badge_number,
        dataType: "badgeNum",
        dunique_id: this.state.dunique_id,
        editedBy: sessionStorage.getItem("user_id"),
      };
    } else if (name === "pan_card_image") {
      data = {
        pannumber: this.state.pan_number,
        dataType: "panNum",
        dunique_id: this.state.dunique_id,
        editedBy: sessionStorage.getItem("user_id"),
      };
    } else if (name === "aadhar_card_image_front") {
      data = {
        adhar_number: this.state.aadhar_number,
        dataType: "aadharNum",
        dunique_id: this.state.dunique_id,
        editedBy: sessionStorage.getItem("user_id"),
      };
      // console.log(data);
    }

    fetch(config.url + "onboard/updateDriverOtherDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          // console.log(resultsss);
          if (resultsss.message === "successfull") {
            //  this.setState(
            //    {
            //      success: true,
            //      message: "Data updated to server successfuly",
            //    },
            //    () => {
            //      this.show();
            //    }
            //  );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  updateExpiry(name) {
    let data;
    // console.log(this.state.dl_expiry);
    if (name === "driving_license_image") {
      data = {
        dl_to:
          this.state.dl_expiry === undefined ||
          this.state.dl_expiry === null ||
          this.state.dl_expiry === 0 ||
          this.state.dl_expiry === ""
            ? ""
            : this.state.dl_expiry.getTime(),
        dataType: "dlExp",
        dunique_id: this.state.dunique_id,
        editedBy: sessionStorage.getItem("user_id"),
      };
      // console.log(data);
    } else if (name === "vehicle_insurance_image") {
      data = {
        insurance_to:
          this.state.insurance_expiry === undefined ||
          this.state.insurance_expiry === null ||
          this.state.insurance_expiry === 0 ||
          this.state.insurance_expiry === ""
            ? ""
            : this.state.insurance_expiry.getTime(),
        dataType: "insuranceExp",
        dunique_id: this.state.dunique_id,
        editedBy: sessionStorage.getItem("user_id"),
      };
      // console.log(data);
    } else if (name === "driver_badgee_image") {
      data = {
        display_card_to:
          this.state.display_expiry === undefined ||
          this.state.display_expiry === null ||
          this.state.display_expiry === 0
            ? ""
            : this.state.display_expiry.getTime(),
        dataType: "displayExp",
        dunique_id: this.state.dunique_id,
        editedBy: sessionStorage.getItem("user_id"),
      };
      // console.log(data);
    } else if (name === "emission_cert_image") {
      data = {
        emission_to:
          this.state.emission_expiry === undefined ||
          this.state.emission_expiry === null ||
          this.state.emission_expiry === 0
            ? ""
            : this.state.emission_expiry.getTime(),
        dataType: "emissionExp",
        dunique_id: this.state.dunique_id,
        editedBy: sessionStorage.getItem("user_id"),
      };
      // console.log(data);
    } else if (name === "road_tax_image") {
      data = {
        roadtax_to:
          this.state.roadtax_expiry === undefined ||
          this.state.roadtax_expiry === null ||
          this.state.roadtax_expiry === 0
            ? ""
            : this.state.roadtax_expiry.getTime(),
        dataType: "roadTaxExp",
        dunique_id: this.state.dunique_id,
        editedBy: sessionStorage.getItem("user_id"),
      };
      // console.log(data);
    } else if (name === "fitness_image") {
      data = {
        fc_to:
          this.state.fc_expiry === undefined ||
          this.state.fc_expiry === null ||
          this.state.fc_expiry === 0
            ? ""
            : this.state.fc_expiry.getTime(),
        dataType: "fcExp",
        dunique_id: this.state.dunique_id,
        editedBy: sessionStorage.getItem("user_id"),
      };
      // console.log(data);
    } else {
    }
    fetch(config.url + "onboard/updateDriverDocDateEdited", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          // console.log("Expiry dates");
          // console.log(resultsss);
          if (resultsss.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "Data updated to server",
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  hideDiv(type) {
    // this.setState(
    //   {
    //     showDiv: !this.state.showDiv,
    //   },
    //   () => {
    //     this.setHide(type);
    //   }
    // );

    this.setState({
      hideType: type,
    });
  }

  setHide(type) {
    if (this.state.showDiv === true) {
      this.setState({
        hideType: "",
      });
    } else {
      this.setState({
        hideType: type,
      });
    }
  }
  dateChange(e, name) {
    // console.log("herrrrrrrrrrrrrrrrrrreeeeeeeeeeeeeeeeeee");
    // console.log(name);
    let value;
    let newState = {};
    value = e;
    newState[name] = value;
    this.setState({ ...this.state, ...newState });
  }
  setAlternateNumber() {
    fetch(config.url + "onboard/updateDriverPersonalDataEdited", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        dunique_id: this.state.dunique_id,
        dataType: "alternatenumber",
        alternate_number: this.state.alt_phonenumber,
        editedBy: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          // console.log(resultsss);
          if (resultsss.message === "successfull") {
            this.setState({
              bank_verified_status: resultsss.bank_verified_status,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleChangeSelect(event) {
    this.setState({
      blockDuration: event.target.value,
    });
  }
  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };

  callBlockuser() {
    // console.log(this.state.suspendReason);
    if (
      this.state.suspendReason === undefined ||
      this.state.suspendReason === null ||
      this.state.suspendReason === ""
    ) {
      this.setState(
        {
          success: false,
          message: "Please provide a valid reason",
        },
        () => {
          this.show();
        }
      );
    } else if (
      this.state.blockDuration === undefined ||
      this.state.blockDuration === null ||
      this.state.blockDuration === ""
    ) {
      this.setState(
        {
          success: false,
          message: "Please provide a valid reason",
        },
        () => {
          this.show();
        }
      );
    } else {
      let reqObj = {
        crmuserid: window.sessionStorage.getItem("user_id"),
        phonenumber: this.state.phonenumber,
        status: "blocked",
        block_hours: this.state.blockDuration,
        reason: "Account activation!",
      };
      fetch(config.url + "htmlcrm/pushmessagesuspendaccount", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(reqObj),
      })
        .then((res) => res.json())
        .then(
          (results) => {
            // console.log(results);
            if (results.message === "successfull") {
              // alert("successfull");
              this.setState(
                {
                  success: true,
                  openBlockModal: !this.state.openBlockModal,
                  message: "Account has been blocked!",
                },
                () => {
                  this.show();
                  this.searchDriver();
                }
              );
            } else {
              // alert("Unsuccessfull");
              this.setState(
                {
                  success: false,
                  message: "Failed to block account",
                },
                () => {
                  this.show();
                }
              );
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    }
  }
  callActivateUser() {
    let reqObj = {
      crmuserid: window.sessionStorage.getItem("user_id"),
      phonenumber: this.state.phonenumber,
      status: "Inactive",
      reason: "Account activation!",
    };
    // console.log(reqObj);
    fetch(config.url + "htmlcrm/pushmessagesuspendaccount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(reqObj),
    })
      .then((res) => res.json())
      .then(
        (results) => {
          // console.log(results);
          if (results.message === "successfull") {
            // alert("successfull");
            this.setState(
              {
                success: true,
                openActiveModal: !this.state.openActiveModal,
                message: "Account has been activated!",
              },
              () => {
                this.show();
                this.searchDriver();
              }
            );
          } else {
            // alert("Unsuccessfull");
            this.setState(
              {
                success: false,
                message: "Failed to activate account",
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  callActivateType() {
    let data = {
      data: "vehiclename",
      dunique_id: this.state.dunique_id,
      executive_id: window.sessionStorage.getItem("user_id"),
      value: this.state.vehicle_type,
    };
    fetch(config.url + "htmlcrm/updatedriverdataedited", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          // console.log(resultsss);
          if (resultsss.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "Data updated to server",
              },
              () => {
                this.show();
                this.toggleActiveType();
                this.handelvehicleType();
              }
            );
          } else {
            this.setState(
              {
                success: false,
                message: "Failed to update to server",
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  callSuspendUser() {
    // console.log("here suspend");
    if (
      this.state.suspendReason === undefined ||
      this.state.suspendReason === null ||
      this.state.suspendReason === ""
    ) {
      // console.log("here suspend 1");
      this.setState(
        {
          success: false,
          message: "Please provide a valid reason",
        },
        () => {
          this.show();
        }
      );
    } else {
      console.log("here suspend 2");
      let reqObj = {
        crmuserid: window.sessionStorage.getItem("user_id"),
        phonenumber: this.state.phonenumber,
        status: "suspended",
        reason: this.state.suspendReason,
      };
      fetch(config.url + "htmlcrm/pushmessagesuspendaccount", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(reqObj),
      })
        .then((res) => res.json())
        .then(
          (results) => {
            // console.log(results);
            if (results.message === "successfull") {
              // alert("successfull");
              this.setState(
                {
                  success: true,
                  openSuspendModal: !this.state.openSuspendModal,
                  message: "Account has been suspended!",
                },
                () => {
                  this.show();
                  this.searchDriver();
                }
              );
            } else {
              // alert("Unsuccessfull");
              this.setState(
                {
                  success: false,
                  message: "Failed to suspend account",
                },
                () => {
                  this.show();
                }
              );
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    }
  }
  handleAreaChange(e) {
    this.setState({ suspendReason: e.target.value });
  }
  toggleSuspend() {
    this.setState({
      openSuspendModal: !this.state.openSuspendModal,
    });
  }
  toggleActive() {
    this.setState({
      openActiveModal: !this.state.openActiveModal,
    });
  }
  toggleActiveType() {
    this.setState({
      openConfirm: !this.state.openConfirm,
    });
  }
  toggleBlock() {
    this.setState({
      openBlockModal: !this.state.openBlockModal,
    });
  }
  onboarddriver() {
    this.props.history.push({
      pathname: "/driveraccounts/adddriver",
      search: `?phonnumber=${this.state.searchphonenumber}`,
      state: { phonenumber: this.state.searchphonenumber },
    });
  }
  validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }
  handleSelectChange(event) {
    this.setState({
      operating_region: event.target.value,
    });
  }
  goBack() {
    this.props.dataForPush.history.goBack();
  }
  // vehicle_front_image: '',
  //         vehicle_back_image: '',
  //         vehicle_left_image: '',
  //         vehicle_right_image: '',
  //         vehicle_with_driver: '',
  //         aadhar_card_image_front: '',
  //         aadhar_card_image_back: '',
  //         pan_card_image: '',
  //         driving_license_image: '',
  //         rc_image: '',
  //         vehicle_insurance_image: '',
  //         driver_badgee_image: '',
  //         emission_cert_image: '',
  //         road_tax_image: '',
  //         permit_image: '',
  //         fitness_image: '',
  //         sales_agreement: '',
  //         police_verification: '',
  callImagehandler(name, data) {
    // console.log("********************")
    // console.log(name);
    // console.log("here");
    // console.log(data);
    let imageType, imageData;
    switch (name) {
      case "vehicle_front_image":
        imageType = "vehiclefront";
        // imageData = this.state.vehicle_front_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "vehicle_back_image":
        imageType = "vehiclerear";
        // imageData = this.state.vehicle_back_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "vehicle_right_image":
        imageType = "vehicleright";
        // imageData = this.state.vehicle_right_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "vehicle_left_image":
        imageType = "vehicleleft";
        // imageData = this.state.vehicle_left_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "vehicle_with_driver":
        imageType = "driverwithvehicle";
        // imageData = this.state.vehicle_with_driver.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "aadhar_card_image_front":
        imageType = "aadhar";
        // console.log(this.state.aadhar_card_image_front);
        // imageData = this.state.aadhar_card_image_front.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        // console.log(imageData);
        break;
      case "pan_card_image":
        imageType = "pan";
        // imageData = this.state.pan_card_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "driving_license_image":
        imageType = "drivinglicense";
        // imageData = this.state.driving_license_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "rc_image":
        imageType = "rc";
        // imageData = this.state.rc_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "vehicle_insurance_image":
        imageType = "vehicleinsurance";
        // imageData = this.state.vehicle_insurance_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "driver_badgee_image":
        imageType = "driverbadge";
        // imageData = this.state.driver_badgee_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "emission_cert_image":
        imageType = "emissioncrt";
        // imageData = this.state.emission_cert_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "road_tax_image":
        imageType = "roadTaxExp";
        // imageData = this.state.road_tax_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "permit_image":
        imageType = "permit";
        // imageData = this.state.permit_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "fitness_image":
        imageType = "fc";
        // imageData = this.state.fitness_image.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "sales_agreement":
        imageType = "salesagreement";
        // imageData = this.state.sales_agreement.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "police_verification":
        imageType = "policeverification";
        // imageData = this.state.police_verification.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "driverformImageFront":
        imageType = "driverformImageFront";
        // imageData = this.state.driverformImageFront.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      case "driverformImageBack":
        imageType = "driverformImageBack";
        // imageData = this.state.driverformImageBack.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        imageData = data.replace(/^data:image\/[a-z]+;base64,/, "");
        break;
      default:
        console.log("error");
        break;
    }
    // if (this.validURL(imageData)) {
    //   console.log("true");
    //   console.log(imageData);
    // } else {

    // }
    let dataToSend = {
      dunique_id: this.state.dunique_id,
      imageType: imageType,
      imageData: imageData,
      executive_id: window.sessionStorage.getItem("user_id"),
    };
    // console.log(data);
    console.log("here");
    fetch(config.url + "onboard/updateDriverImages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          // console.log(resultsss);
          if (resultsss.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "Data updated to server successfuly",
              },
              () => {
                this.show();
              }
            );
            // let dataImage = resultsss.imageResponse + "?random_number=" + new Date().getTime();
            // this.imagehandler(resultsss.imageResponse, name)
          } else {
            this.setState(
              {
                success: false,
                message: "Update to server failed",
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleRideprefDetails() {
    let duty_preference;
    if (this.state.day_shift === true && this.state.night_shift === true) {
      duty_preference = "day / night";
    } else if (this.state.day === true) {
      duty_preference = "day";
    } else if (this.state.night_shift === true) {
      duty_preference = "night";
    }
    fetch(config.url + "onboard/updateDriverPersonalDataEdited", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        dunique_id: this.state.dunique_id,
        prefer_recurride: this.state.recurride,
        prefer_airport: this.state.airport,
        prefer_urban: this.state.urban,
        prefer_hiring: this.state.rentals,
        prefer_outstation: this.state.outstation,
        prefer_location: this.state.preferred_location,
        operating_region: this.state.operating_region,
        duty_preference: duty_preference,
        dataType: "ridepreferences",
        editedBy: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          // console.log(resultsss);
          if (resultsss.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "Data updated to server successfuly",
                ridePrefCog: !this.state.ridePrefCog,
                readonlyCheckbox: !this.state.readonlyCheckbox,
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleVehicleDetails() {
    //  console.log ({
    //     dunique_id: this.state.dunique_id,
    //     dataType: "vehicleTypeDetails",
    //     vehiclenumber: this.state.vehicle_number,
    //     vehiclemake: this.state.vehicle_make,
    //     vehiclemodel: this.state.vehicle_model,
    //     vehiclecolor: this.state.vehicle_color,
    //     vehiclename: this.state.vehicle_name,
    //     editedBy: window.sessionStorage.getItem("user_id"),
    //   });
    fetch(config.url + "onboard/updateDriverOtherDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        dunique_id: this.state.dunique_id,
        dataType: "vehicleTypeDetails",
        vehiclenumber: this.state.vehicle_number,
        vehiclemake: this.state.vehicle_make,
        vehiclemodel:
          this.state.vehicle_model !== null ||
          this.state.vehicle_model !== undefined
            ? new Date(this.state.vehicle_model).getTime()
            : "",
        vehiclecolor: this.state.vehicle_color,
        vehiclename: this.state.vehicle_name,
        editedBy: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          // console.log(resultsss);
          if (resultsss.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "Data updated to server successfuly",
                vehicleDetailsCog: !this.state.vehicleDetailsCog,
                readOnlyVehicle: !this.state.readOnlyVehicle,
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  handleBankDetails() {
    fetch(config.url + "onboard/updateDriverPersonalDataEdited", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        dunique_id: this.state.dunique_id,
        dataType: "bankdetails",
        accholder_name: this.state.acc_holder_name,
        ifsc_code: this.state.ifsc,
        account_number: this.state.acc_number,
        accphonenumber: this.state.acc_phonenumber,
        address: this.state.acc_address,
        email: this.state.acc_email,
        editedBy: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          // console.log(resultsss);
          if (resultsss.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "Data updated to server successfuly",
                bankDetailsCog: !this.state.bankDetailsCog,
                readOnlyBankDetails: !this.state.readOnlyBankDetails,
              },
              () => {
                this.show();
              }
            );
          } else {
            this.setState(
              {
                success: false,
                message: "Update to server failed",
                bankDetailsCog: !this.state.bankDetailsCog,
                readOnlyBankDetails: !this.state.readOnlyBankDetails,
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleFamilyDetails() {
    let data = {
      dunique_id: this.state.dunique_id,
      dataType: "familydetails",
      fmone_name: this.state.family_name_1,
      fmone_phonenumber: this.state.family_phn_1,
      fmone_relationship: this.state.family_rel_1,
      fmone_address: this.state.family_address_1,
      fmtwo_name: this.state.family_name_2,
      fmtwo_phonenumber: this.state.family_phn_2,
      fmtwo_relationship: this.state.family_rel_2,
      fmtwo_address: this.state.family_address_2,
      editedBy: window.sessionStorage.getItem("user_id"),
    };
    // console.log(data);
    fetch(config.url + "onboard/updateDriverPersonalDataEdited", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        dunique_id: this.state.dunique_id,
        dataType: "familydetails",
        fmone_name: this.state.family_name_1,
        fmone_phonenumber: this.state.family_phn_1,
        fmone_relationship: this.state.family_rel_1,
        fmone_address: this.state.family_address_1,
        fmtwo_name: this.state.family_name_2,
        fmtwo_phonenumber: this.state.family_phn_2,
        fmtwo_relationship: this.state.family_rel_2,
        fmtwo_address: this.state.family_address_2,
        editedBy: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          // console.log(resultsss);
          if (resultsss.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "Data updated to server successfuly",
                familyDetailsCog: !this.state.familyDetailsCog,
                readOnlyFamilyDetails: !this.state.readOnlyFamilyDetails,
              },
              () => {
                this.show();
              }
            );
          } else {
            this.setState(
              {
                success: false,
                message: "Update to server failed",
                familyDetailsCog: !this.state.familyDetailsCog,
                readOnlyFamilyDetails: !this.state.readOnlyFamilyDetails,
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleImagedata() {
    let profileData = this.state.driver_profile.replace(
      /^data:image\/[a-z]+;base64,/,
      ""
    );
    fetch(config.url + "onboard/updateDriverImages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        dunique_id: this.state.dunique_id,
        imageData: profileData,
        imageType: "driverprofileimage",
        executive_id: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          // console.log(resultsss);
          if (resultsss.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "Data updated to server successfuly",
                displayProfCog: !this.state.displayProfCog,
              },
              () => {
                this.show();
              }
            );
          } else {
            this.setState(
              {
                success: false,
                message: "Update to server failed",
                displayProfCog: !this.state.displayProfCog,
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  handleProfileDetails() {
    // console.log(this.state.alt_phonenumber);
    // console.log(this.state.alt_phonenumber.toString().length);
    if (this.state.alt_phonenumber === undefined) {
      this.setState(
        {
          success: false,
          message: "Please enter a valid alternate phonenumber",
        },
        () => {
          this.show();
        }
      );
    } else {
      fetch(config.url + "onboard/updateDriverPersonalDataEdited", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          dunique_id: this.state.dunique_id,
          dataType: "personaldetails",
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          phonenumber: this.state.phonenumber,
          current_address: this.state.current_address,
          permanent_address: this.state.permanent_address,
          email: this.state.email,
          editedBy: window.sessionStorage.getItem("user_id"),
        }),
      })
        .then((res) => res.json())
        .then(
          (resultsss) => {
            // console.log(resultsss);
            if (resultsss.message === "successfull") {
              this.setState(
                {
                  success: true,
                  message: "Data updated to server successfuly",
                  driverDetails: !this.state.driverDetails,
                  readOnly: !this.state.readOnly,
                },
                () => {
                  this.setAlternateNumber();
                  this.show();
                }
              );
            } else {
              this.setState(
                {
                  success: false,
                  message: "Update to server failed",
                  driverDetails: !this.state.driverDetails,
                  readOnly: !this.state.readOnly,
                },
                () => {
                  this.setAlternateNumber();
                  this.show();
                }
              );
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    }
  }
  handleImage(image, name) {
    // console.log(image);
    const newState = {};
    newState[name] = image;
    this.setState({ ...this.state, ...newState });
  }
  handeldriverType() {
    this.setState({
      driverTypeCog: !this.state.driverTypeCog,
      readOnlyDriver: !this.state.readOnlyDriver,
    });
  }

  handledriverTypeDetails() {
    // console.log(this.state);
    let data = {
      data: "driver_type",
      dunique_id: this.state.dunique_id,
      executive_id: window.sessionStorage.getItem("user_id"),
      value: this.state.driver_type,
    };
    fetch(config.url + "htmlcrm/updatedriverdataedited", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          // console.log(resultsss);
          if (resultsss.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "Data updated to server",
              },
              () => {
                this.show();

                this.handeldriverType();
              }
            );
          } else {
            this.setState(
              {
                success: false,
                message: "Failed to update to server",
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleNavClick(name, index) {
    // console.log(name)
    this.setState({
      divName: name,
      index: index,
    });
  }
  searchDriver(event) {
    if (event) {
      event.preventDefault();
    }

    // serch opertion here
    fetch(config.url + "onboard/search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // phonenumber: this.state.searchphonenumber,
        phonenumber: this.props.phonenumber,
        // phonenumber: "8123173193"
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          // console.log(resultsss);
          if (resultsss.message) {
            if (resultsss.message === "user does not exist") {
              this.setState({
                notExist: !this.state.notExist,
              });
            } else if (resultsss.message === "consumer") {
              this.setState({
                consumerWarn: !this.state.consumerWarn,
              });
            } else if (resultsss.message === "successfull") {
              // console.log(resultsss);
              let resData = resultsss.results;
              // let duty_preference;
              let night, day;
              if (
                resData.driverPreference.duty_preference === "" ||
                resData.driverPreference.duty_preference === undefined ||
                resData.driverPreference.duty_preference === null
              ) {
                night = false;
                day = false;
              } else if (
                resData.driverPreference.duty_preference === "day / night"
              ) {
                night = true;
                day = true;
              } else if (resData.driverPreference.duty_preference === "day") {
                night = false;
                day = true;
              } else if (resData.driverPreference.duty_preference === true) {
                night = true;
                day = false;
              }
              // console.log(resultsss.driverPreference)
              // visible: !this.state.visible,
              this.setState({
                totalData: resData,
                propDta: resData,
                visible: true,
                night_shift: night,
                day_shift: day,
                first_name: resData.first_name,
                last_name: resData.last_name,
                email: resData.email,
                current_address: resData.current_address,
                permanent_address: resData.permanent_address,
                phonenumber: resData.phonenumber,
                alt_phonenumber: resData.alternate_number,
                vehicle_name: resData.vehicle_details.vehicleName,
                vehicle_number: resData.vehicle_details.vehiclenumber,
                vehicle_color: resData.vehicle_details.vehicleColor,
                vehicle_make: resData.vehicle_details.vehicle_make,
                vehicle_type: resData.vehicle_details.vehicle_type,
                vehicle_model: resData.vehicle_details.vehicle_model,
                acc_holder_name: resData.accholder_name,
                acc_number: resData.account_number,
                acc_phonenumber: resData.accphonenumber,
                acc_email: resData.accemail,
                acc_address: resData.accaddress,
                bank_name: "",
                bank_verified_status: resData.bank_verified_status,
                ifsc: resData.ifsc_code,
                family_name_1: resData.familyDetails.fmone_name,
                family_name_2: resData.familyDetails.fmtwo_name,
                family_phn_1: resData.familyDetails.fmone_phonenumber,
                family_phn_2: resData.familyDetails.fmtwo_phonenumber,
                family_rel_1: resData.familyDetails.fmone_relationship,
                family_rel_2: resData.familyDetails.fmtwo_relationship,
                family_address_1: resData.familyDetails.fmone_address,
                family_address_2: resData.familyDetails.fmtwo_address,
                outstation: resData.driverPreference.outstation,
                rentals: resData.driverPreference.rental,
                airport: resData.driverPreference.airport,
                urban: resData.driverPreference.urban,
                recurride: resData.driverPreference.recur_ride,
                operating_region: resData.driverPreference.operating_region,
                preferred_location: resData.driverPreference.preferred_location,
                starting_point: resData.driverPreference.starting_point,
                avg_ratings: resData.avg_ratings,
                vehicle_front_image:
                  resData.imagedata.vehicle_pic[0] +
                  "?mod=" +
                  new Date().getTime(),
                vehicle_back_image:
                  resData.imagedata.vehicle_pic[1] +
                  "?mod=" +
                  new Date().getTime(),
                vehicle_left_image:
                  resData.imagedata.vehicle_pic[2] +
                  "?mod=" +
                  new Date().getTime(),
                vehicle_right_image:
                  resData.imagedata.vehicle_pic[3] +
                  "?mod=" +
                  new Date().getTime(),
                vehicle_with_driver:
                  resData.imagedata.driv_vehicle_image[1] +
                  "?mod=" +
                  new Date().getTime(),
                aadhar_card_image_front:
                  resData.imagedata.driver_adharcard[0] +
                  "?mod=" +
                  new Date().getTime(),
                aadhar_card_image_back:
                  resData.imagedata.driver_adharcard[1] +
                  "?mod=" +
                  new Date().getTime(),
                pan_card_image:
                  resData.imagedata.pan[0] + "?mod=" + new Date().getTime(),
                driving_license_image:
                  resData.imagedata.dl_image[0] +
                  "?mod=" +
                  new Date().getTime(),
                rc_image:
                  resData.imagedata.vehicle_rc[0] +
                  "?mod=" +
                  new Date().getTime(),
                vehicle_insurance_image:
                  resData.imagedata.insurance_image[0] +
                  "?mod=" +
                  new Date().getTime(),
                driver_badgee_image:
                  resData.imagedata.display_card[0] +
                  "?mod=" +
                  new Date().getTime(),
                emission_cert_image:
                  resData.imagedata.emission_image[0] +
                  "?mod=" +
                  new Date().getTime(),
                road_tax_image:
                  resData.imagedata.roadtax_image[0] +
                  "?mod=" +
                  new Date().getTime(),
                permit_image:
                  resData.imagedata.permit_image[0] +
                  "?mod=" +
                  new Date().getTime(),
                fitness_image:
                  resData.imagedata.fc_image[0] +
                  "?mod=" +
                  new Date().getTime(),
                sales_agreement:
                  resData.imagedata.sales_agreement[0] +
                  "?mod=" +
                  new Date().getTime(),
                police_verification:
                  resData.imagedata.police_verification[0] +
                  "?mod=" +
                  new Date().getTime(),
                driver_profile:
                  resData.imagedata.driver_image[0] +
                  "?mod=" +
                  new Date().getTime(),
                dl_expiry:
                  resData.expiry_dates.dl === ""
                    ? undefined
                    : new Date(resData.expiry_dates.dl),
                roadtax_expiry:
                  resData.expiry_dates.roadtax === ""
                    ? undefined
                    : new Date(resData.expiry_dates.roadtax),
                permit_expiry:
                  resData.expiry_dates.permit === ""
                    ? undefined
                    : new Date(resData.expiry_dates.permit),
                fc_expiry:
                  resData.expiry_dates.fc === ""
                    ? undefined
                    : new Date(resData.expiry_dates.fc),
                insurance_expiry:
                  resData.expiry_dates.insurance === ""
                    ? undefined
                    : new Date(resData.expiry_dates.insurance),
                emission_expiry:
                  resData.expiry_dates.emission === ""
                    ? undefined
                    : new Date(resData.expiry_dates.emission),
                display_expiry:
                  resData.expiry_dates.displaycard === ""
                    ? undefined
                    : new Date(resData.expiry_dates.displaycard),
                driverformImageFront:
                  resData.driverformImageFront[0] +
                  "?mod=" +
                  new Date().getTime(),
                driverformImageBack:
                  resData.driverformImageBack[0] +
                  "?mod=" +
                  new Date().getTime(),
                dunique_id: resData.dunique_id,
                dl_number: resData.document_details.dl_number,
                badge_number: resData.document_details.badge_number,
                aadhar_number: resData.document_details.aadhar_number,
                pan_number: resData.document_details.pan_number,
                registeredDate: resData.registeredDate,
                registeredBy: resData.registeredBy,
                lastEditedDate: resData.lastEditedDate,
                lastEditedBy: resData.lastEditedBy,
                login_status: resData.login_status,
                driver_type: resData.driver_type,
              });
            } else {
            }
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleEdit() {
    // alert("hello")
    this.setState({
      displayProfCog: !this.state.displayProfCog,
    });
  }

  handelvehicleType() {
    this.setState({
      vehicleTypeCog: !this.state.vehicleTypeCog,
      readOnlyType: !this.state.readOnlyType,
    });
  }
  handlevehicleTypeDetails() {
    this.setState({
      openConfirm: !this.state.openConfirm,
    });
  }
  handleEditDetails() {
    // alert("hello")
    this.setState({
      driverDetails: !this.state.driverDetails,
      readOnly: !this.state.readOnly,
    });
  }
  handleEditVehicle() {
    this.setState({
      vehicleDetailsCog: !this.state.vehicleDetailsCog,
      readOnlyVehicle: !this.state.readOnlyVehicle,
    });
  }
  handleEditRidepref() {
    this.setState({
      ridePrefCog: !this.state.ridePrefCog,
      readonlyCheckbox: !this.state.readonlyCheckbox,
    });
  }
  handleEditFamily() {
    this.setState({
      familyDetailsCog: !this.state.familyDetailsCog,
      readOnlyFamilyDetails: !this.state.readOnlyFamilyDetails,
    });
  }
  handleEditBank() {
    this.setState({
      bankDetailsCog: !this.state.bankDetailsCog,
      readOnlyBankDetails: !this.state.readOnlyBankDetails,
    });
  }
  handleChange(e, checkName) {
    // console.log(checkName);
    const newState = {};
    newState[checkName] = !this.state[checkName];
    this.setState({ ...this.state, ...newState });
  }

  // Handle text change
  handleKeyChange(e, name) {
    // console.log(name);
    let value;
    if (name === "vehicle_model") {
      value = e;
      const newState = {};
      newState[name] = value;
      this.setState({ ...this.state, ...newState });
    } else if (
      name === "phonenumber" ||
      name === "family_phn_1" ||
      name === "family_phn_2" ||
      name === "alt_phonenumber"
    ) {
      //   console.log("here");
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        value = e.target.value;
        const newState = {};
        newState[name] = value;
        this.setState({ ...this.state, ...newState });
      }
    } else if (name === "preferred_location" || name === "starting_point") {
      if (e.target !== undefined && e.target.value !== undefined) {
        value = e.target.value;
        const newState = {};
        newState[name] = value;
        this.setState({ ...this.state, ...newState });
      } else {
        value = e;
        const newState = {};
        newState[name] = value;
        this.setState({ ...this.state, ...newState });
      }
    } else {
      value = e.target.value;
      const newState = {};
      newState[name] = value;
      this.setState({ ...this.state, ...newState });
    }
    // console.log(value);
  }
  toggleExists() {
    this.setState({
      notExist: !this.state.notExist,
    });
  }
  imagehandler(data, name) {
    // console.log(name);
    // console.log("imageeeeeeeeeeeeeee")
    if (
      name === "driving_license_image" ||
      name === "vehicle_insurance_image" ||
      name === "driver_badgee_image" ||
      name === "emission_cert_image" ||
      name === "road_tax_image" ||
      name === "fitness_image"
    ) {
      // console.log(this.state.showDiv);
      // console.log(this.state.openLisc);
      this.setState(
        {
          showDiv: !this.state.showDiv,
        },
        () => {
          this.updateExpiry(name);
          if (
            name === "driving_license_image" ||
            name === "driver_badgee_image"
          ) {
            this.updateIdno(name);
          }
        }
      );
    } else {
      if (name === "pan_card_image" || name === "aadhar_card_image_front") {
        this.updateIdno(name);
      }
    }
    // console.log(data);
    if (data === undefined || data === null || data === "") {
      // Do nothing
    } else {
      const newState = {};
      newState[name] = data;
      this.setState({ ...this.state, ...newState }, () => {
        this.callImagehandler(name, data);
      });
    }
  }

  toggleWarn() {
    this.setState({
      consumerWarn: !this.state.consumerWarn,
    });
  }
  componentDidMount() {
    // this.props.clicker(false);
    if (this.props.phonenumber) {
      //   console.log(this.props.history.location.state.phonenumber);
      this.setState(
        {
          searchphonenumber: this.props.phonenumber,
        },
        () => {
          this.searchDriver();
        }
      );
    } else {
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!equal(this.props.phonenumber, prevState.searchphonenumber)) {
      // console.log("***********************************************");

      this.setState(
        {
          searchphonenumber: this.props.phonenumber,
        },
        () => {
          this.searchDriver();
        }
      );
    }
  }
  render() {
    // console.log(this.state);
    // console.log(this.props.phonenumber);
    return (
      <Container fluid className="main-content-container px-md-4 px-0">
        {this.state.visible === true && (
          <div>
            <Row className="my-5">
              <Col className="col-12 col-md-12 col-lg-12">
                <div className="float-right mt-2">
                  <Button
                    className=""
                    id="goback-button"
                    theme="dark"
                    onClick={this.goBack}
                  >
                    <i className="fas fa-arrow-left mx-1" />
                    <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                      BACK
                    </span>
                  </Button>
                </div>
              </Col>
            </Row>
            <Row className="">
              <Col className="col-12 col-md-4 col-lg-4 mb-4">
                <Card small>
                  <CardHeader className="ml-auto">
                    <Row>
                      <Col className="col-md-12 col-lg-12">
                        {this.state.displayProfCog === true && (
                          <div className="ml-auto">
                            <i
                              className="fas fa-cog pointer"
                              style={{ color: "blue", cursor: "pointer" }}
                              onClick={this.handleEdit}
                            />
                          </div>
                        )}

                        {this.state.displayProfCog === false && (
                          <div>
                            <i
                              className="far fa-check-circle pointer px-1"
                              style={{ color: "#59E67F", cursor: "pointer" }}
                              onClick={this.handleImagedata}
                            />
                            <i
                              className="fas fa-ban pointer px-1"
                              style={{ color: "#FF0000", cursor: "pointer" }}
                              onClick={this.handleEdit}
                            />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      {this.state.displayProfCog === true && (
                        <Col className="mb-3 col-4 col-md-4 col-lg-4 mx-auto d-flex justify-content-center flex-wrap px-0">
                          <img
                            className="rounded-circle mx-auto d-block perfect-round"
                            src={this.state.driver_profile}
                            alt="profile"
                          />
                        </Col>
                      )}
                      {this.state.displayProfCog === false && (
                        <Col className="mb-3 col-12 col-md-12 col-lg-12 d-flex justify-content-center flex-wrap px-0">
                          <DragAndDrop
                            id="driver-profile-image"
                            variation="1"
                            label="Profile photo"
                            name="driver_profile"
                            onDropHandler={this.handleImage}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col className="col-12 col-md-12 col-lg-12">
                        <h5 className="center-text">
                          {this.state.first_name + " " + this.state.last_name}
                        </h5>
                      </Col>
                      <Col className="col-12 col-md-12 col-lg-12">
                        <h6 className="center-text">
                          {this.state.vehicle_type}
                        </h6>
                      </Col>
                      <Col className="col-12 col-md-12 col-lg-12">
                        <span className="text-muted mx-auto d-flex justify-content-center flex-wrap mb-2">
                          <i class="fas fa-star"> {this.state.avg_ratings}</i>
                        </span>
                      </Col>
                      <Col className="col-12 col-md-12 col-lg-12 ">
                        <span className="text-muted mb-2 d-flex justify-content-center">
                          Account status :
                          {convertStatus(this.state.login_status)}
                        </span>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardHeader />
                </Card>
              </Col>
              <Col className="col-lg-8">
                {window.sessionStorage.getItem("roletype") ===
                  stringConstant.managerType && (
                  <Row className="mb-3">
                    <Col className="col-12 col-md-4 col-lg-4 mb-1">
                      <Button
                        disabled={
                          this.state.login_status === "Active" ||
                          this.state.login_status === "Inactive"
                            ? true
                            : false
                        }
                        theme="dark"
                        size="lg"
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: "700",
                          minWidth: "200px",
                          borderRadius: "3px",
                        }}
                        onClick={this.toggleActive}
                        className="w-100 mb-2"
                      >
                        ACTIVATE
                      </Button>
                      <Modal
                        show={this.state.openActiveModal}
                        onHide={this.toggleActive}
                      >
                        <ModalHeader closeButton>Activate driver</ModalHeader>
                        <ModalBody>
                          <Row className="mb-3">
                            <Col className="col-12">
                              Do you want to activate this driver ?
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col className="col-6">{this.state.first_name}</Col>
                            <Col className="col-6">
                              {this.state.phonenumber}
                            </Col>
                          </Row>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            theme="dark"
                            size="lg"
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "700",
                              minWidth: "120px",
                              borderRadius: "3px",
                            }}
                            onClick={this.toggleActive}
                          >
                            CANCEL
                          </Button>
                          <Button
                            onClick={this.callActivateUser}
                            theme="dark"
                            size="lg"
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "700",
                              minWidth: "120px",
                              borderRadius: "3px",
                            }}
                          >
                            ACTIVATE
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </Col>
                    <Col className="col-12 col-md-4 col-lg-4 mb-1">
                      <Button
                        theme="dark"
                        size="lg"
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: "700",
                          minWidth: "200px",
                          borderRadius: "3px",
                        }}
                        className="w-100 mb-2"
                        onClick={this.toggleBlock}
                        disabled={
                          this.state.login_status === "blocked" ||
                          this.state.login_status === "suspended"
                            ? true
                            : false
                        }
                      >
                        BLOCK
                      </Button>
                      <Modal
                        show={this.state.openBlockModal}
                        onHide={this.toggleBlock}
                      >
                        <ModalHeader closeButton>Block driver</ModalHeader>
                        <ModalBody>
                          <Row className="mb-3">
                            <Col className="col-12">
                              Do you want to block this driver ?
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col className="col-6">{this.state.first_name}</Col>
                            <Col className="col-6">
                              {this.state.phonenumber}
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col className="col-6">
                              <label>Block duration (Hrs)</label>
                              <FormSelect onChange={this.handleChangeSelect}>
                                <option value="">--</option>
                                <option value="12">12</option>
                                <option value="24">24</option>
                                <option value="36">36</option>
                                <option value="48">48</option>
                                <option value="60">60</option>
                                <option value="72">72</option>
                              </FormSelect>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-12">
                              <label>Reason for blocking the account</label>
                              <FormTextarea
                                placeholder="Please provide a reason!"
                                onChange={this.handleAreaChange}
                              />
                            </Col>
                          </Row>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            theme="dark"
                            size="lg"
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "700",
                              minWidth: "120px",
                              borderRadius: "3px",
                            }}
                            onClick={this.toggleBlock}
                          >
                            CANCEL
                          </Button>
                          <Button
                            theme="dark"
                            size="lg"
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "700",
                              minWidth: "120px",
                              borderRadius: "3px",
                            }}
                            onClick={this.callBlockuser}
                          >
                            BLOCK
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </Col>
                    <Col className="col-12 col-md-4 col-lg-4 mb-1">
                      <Button
                        theme="dark"
                        size="lg"
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: "700",
                          minWidth: "200px",
                          borderRadius: "3px",
                        }}
                        className="w-100 mb-2"
                        onClick={this.toggleSuspend}
                        disabled={
                          this.state.login_status === "suspended" ||
                          this.state.login_status === "blocked"
                            ? true
                            : false
                        }
                      >
                        SUSPEND
                      </Button>
                      <Modal
                        show={this.state.openSuspendModal}
                        onHide={this.toggleSuspend}
                      >
                        <ModalHeader>Suspend driver</ModalHeader>
                        <ModalBody>
                          <Row className="mb-3">
                            <Col className="col-12">
                              Do you want to suspend this driver ?
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col className="col-6">{this.state.first_name}</Col>
                            <Col className="col-6">
                              {this.state.phonenumber}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-12">
                              <label>
                                <h5>Reason for account suspension</h5>
                              </label>
                              <FormTextarea
                                placeholder="Please provide a reason!"
                                onChange={this.handleAreaChange}
                              />
                            </Col>
                          </Row>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            theme="dark"
                            size="lg"
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "700",
                              minWidth: "120px",
                              borderRadius: "3px",
                            }}
                            onClick={this.toggleSuspend}
                          >
                            CANCEL
                          </Button>
                          <Button
                            theme="dark"
                            size="lg"
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "700",
                              minWidth: "120px",
                              borderRadius: "3px",
                            }}
                            onClick={this.callSuspendUser}
                          >
                            SUSPEND
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </Col>
                  </Row>
                )}

                <Card>
                  <CardHeader>
                    <Row>
                      <label style={{ fontWeight: "700", fontSize: "1.1rem" }}>
                        <u>DRIVER DETAILS</u>
                      </label>
                      {this.state.driverDetails === true && (
                        <div className="ml-auto px-3">
                          <i
                            className="fas fa-cog pointer"
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={this.handleEditDetails}
                          />
                        </div>
                      )}
                      {this.state.driverDetails === false && (
                        <div className="ml-auto px-2">
                          <i
                            className="far fa-check-circle pointer px-1"
                            style={{ color: "#59E67F", cursor: "pointer" }}
                            onClick={this.handleProfileDetails}
                          />
                          <i
                            className="fas fa-ban pointer px-1"
                            style={{ color: "#FF0000", cursor: "pointer" }}
                            onClick={this.handleEditDetails}
                          />
                        </div>
                      )}
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      {this.props.inputValues.map((stats, idx) => (
                        <Col
                          className="col-12 col-md-6 col-lg-6 mb-4"
                          key={idx}
                          {...stats.attrs}
                        >
                          {/* {console.log(this.state.hits)} */}
                          <InputDataForm
                            id={`edit-driver-details-${idx}`}
                            variation="1"
                            label={stats.label}
                            value={this.state[stats.name]}
                            stats={stats.stats}
                            name={stats.name}
                            onKeyChange={this.handleKeyChange}
                            readOnly={this.state.readOnly}
                          />
                        </Col>
                      ))}
                      {this.props.addressField.map((stats, idx) => (
                        <Col
                          className="col-12 col-md-6 col-lg-6 mb-4"
                          key={idx}
                          {...stats.attrs}
                        >
                          {/* {console.log(this.state.hits)} */}
                          <InputDataForm
                            id={`edit-address-details-${idx}`}
                            variation="1"
                            label={stats.label}
                            value={this.state[stats.name]}
                            stats={stats.stats}
                            name={stats.name}
                            onKeyChange={this.handleKeyChange}
                            readOnly={this.state.readOnly}
                          />
                        </Col>
                      ))}
                      {this.props.inputdrivertype.map((stats, idx) => (
                        <Col
                          className="col-12 col-md-6 col-lg-6 mb-4 "
                          key={idx}
                          {...stats.attrs}
                        >
                          {/* {console.log(this.state.hits)} */}
                          <div style={{ display: "flex" }}>
                            <label className="label-style">Driver type</label>
                            {this.state.driverTypeCog === true && (
                              <div className="ml-auto px-3">
                                <i
                                  className="fas fa-cog pointer"
                                  style={{ color: "blue", cursor: "pointer" }}
                                  onClick={this.handeldriverType}
                                />
                              </div>
                            )}
                            {this.state.driverTypeCog === false && (
                              <div className="ml-auto px-2">
                                <i
                                  className="far fa-check-circle pointer px-1"
                                  style={{
                                    color: "#59E67F",
                                    cursor: "pointer",
                                  }}
                                  onClick={this.handledriverTypeDetails}
                                />
                                <i
                                  className="fas fa-ban pointer px-1"
                                  style={{
                                    color: "#FF0000",
                                    cursor: "pointer",
                                  }}
                                  onClick={this.handeldriverType}
                                />
                              </div>
                            )}
                          </div>

                          <InputDataForm
                            id={`edit-driver-type-details-${idx}`}
                            variation="1"
                            label={stats.label}
                            value={this.state[stats.name]}
                            stats={stats.stats}
                            name={stats.name}
                            onKeyChange={this.handleKeyChange}
                            readOnly={this.state.readOnlyDriver}
                          />
                        </Col>
                      ))}
                    </Row>
                  </CardBody>
                  <CardFooter />
                </Card>
              </Col>
            </Row>
            <Row className="">
              <Col className="col-12 col-md-12 col-lg-12">
                <Card className="mt-4">
                  <CardHeader>
                    <Row className="px-3">
                      <label style={{ fontWeight: "700", fontSize: "1.1rem" }}>
                        <u>VEHICLE DETAILS</u>
                      </label>
                      {this.state.vehicleDetailsCog === true && (
                        <div className="ml-auto px-md-4">
                          <i
                            className="fas fa-cog pointer"
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={this.handleEditVehicle}
                          />
                        </div>
                      )}

                      {this.state.vehicleDetailsCog === false && (
                        <div className="ml-auto px-4">
                          <i
                            className="far fa-check-circle pointer px-1"
                            style={{ color: "#59E67F", cursor: "pointer" }}
                            onClick={this.handleVehicleDetails}
                          />
                          <i
                            className="fas fa-ban pointer px-1"
                            style={{ color: "#FF0000", cursor: "pointer" }}
                            onClick={this.handleEditVehicle}
                          />
                        </div>
                      )}
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <Row>
                      {this.props.vehicleinputValues.map((stats, idx) => (
                        <Col
                          className="col-12 col-md-4 col-lg-4 mb-4"
                          key={idx}
                          {...stats.attrs}
                        >
                          {/* {console.log(this.state.hits)} */}

                          <InputDataForm
                            id={`edit-vehicle-details-${idx}`}
                            variation="1"
                            label={stats.label}
                            value={this.state[stats.name]}
                            stats={stats.stats}
                            name={stats.name}
                            onKeyChange={this.handleKeyChange}
                            readOnly={this.state.readOnlyVehicle}
                          />
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      {this.props.vehicletypeValues.map((stats, idx) => (
                        <Col
                          className="col-12 col-md-4 col-lg-4 mb-4"
                          key={idx}
                          {...stats.attrs}
                        >
                          {/* {console.log(this.state.hits)} */}
                          <div style={{ display: "flex" }}>
                            <label className="label-style">Vehicle type</label>
                            {this.state.vehicleTypeCog === true && (
                              <div className="ml-auto px-3">
                                <i
                                  className="fas fa-cog pointer"
                                  style={{ color: "blue", cursor: "pointer" }}
                                  onClick={this.handelvehicleType}
                                />
                              </div>
                            )}
                            {this.state.vehicleTypeCog === false && (
                              <div className="ml-auto px-2">
                                <i
                                  className="far fa-check-circle pointer px-1"
                                  style={{
                                    color: "#59E67F",
                                    cursor: "pointer",
                                  }}
                                  onClick={this.handlevehicleTypeDetails}
                                />
                                <i
                                  className="fas fa-ban pointer px-1"
                                  style={{
                                    color: "#FF0000",
                                    cursor: "pointer",
                                  }}
                                  onClick={this.handelvehicleType}
                                />
                              </div>
                            )}
                          </div>

                          <InputDataForm
                            id={`edit-vehicle-type-details-${idx}`}
                            variation="1"
                            label={stats.label}
                            value={this.state[stats.name]}
                            stats={stats.stats}
                            name={stats.name}
                            onKeyChange={this.handleKeyChange}
                            readOnly={this.state.readOnlyType}
                          />
                        </Col>
                      ))}
                      <Modal show={this.state.openConfirm} centered>
                        <ModalHeader>Confirmation</ModalHeader>
                        <ModalBody>
                          <Row className="mb-3">
                            <Col className="col-12">
                              <label>
                                Please confirm the change of vehicle type to{" "}
                                <b>{this.state.vehicle_type}</b> ?
                              </label>
                            </Col>
                          </Row>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            theme="dark"
                            size="lg"
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "700",
                              minWidth: "120px",
                              borderRadius: "3px",
                            }}
                            onClick={this.toggleActiveType}
                          >
                            CLOSE
                          </Button>
                          <Button
                            onClick={this.callActivateType}
                            theme="dark"
                            size="lg"
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "700",
                              minWidth: "120px",
                              borderRadius: "3px",
                            }}
                          >
                            CONFIRM
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </Row>
                    <Row className="mt-4">
                      {this.props.vehicleimagesProp.map((stats, idx) => (
                        <Col
                          className="col-12 col-md-3 col-lg-3 mb-4"
                          key={idx}
                          {...stats.attrs}
                        >
                          <DivRender
                            id={`vehicle-images-${idx}`}
                            variation="1"
                            label={stats.label}
                            name={stats.name}
                            value={this.state[stats.name]}
                            onChangeHandler={this.imagehandler}
                            onClickPush={this.callImagehandler}
                          />
                        </Col>
                      ))}
                    </Row>
                  </CardBody>
                  <CardHeader />
                </Card>
              </Col>
            </Row>
            <Row className="mt-4 mb-4">
              <Col className="col-12 col-md-12 col-lg-12">
                <Card>
                  <CardHeader>
                    <Nav tabs>
                      {this.props.navItems.map((stats, idx) => (
                        <NavItem className="d-flex flex-wrap" key={idx}>
                          <EditDrivatab
                            id={idx}
                            variation="1"
                            label={stats.label}
                            state={this.state}
                            onClickHandler={this.handleNavClick}
                            name={stats.name}
                            attr={
                              this.state.index === idx
                                ? "active nav-item-style Navlink-style"
                                : "nav-item-style Navlink-style"
                            }
                          />
                        </NavItem>
                      ))}
                    </Nav>
                  </CardHeader>
                  <CardBody>
                    {this.state.divName === "id_proofs" && (
                      <div>
                        <Row>
                          {this.props.idProofs.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-3 col-lg-3"
                              key={idx}
                              {...stats.attrs}
                            >
                              <DivRender
                                id={`idproofs-images-${idx}`}
                                dunique_id={this.state.dunique_id}
                                phonenumber={this.state.phonenumber}
                                variation="1"
                                label={stats.label}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onChangeHandler={this.imagehandler}
                                onClickPush={this.callImagehandler}
                                idno={stats.idno}
                                idvalue={this.state[stats.idno]}
                                valueChange={this.valueChange}
                              />
                            </Col>
                          ))}
                        </Row>
                        <hr />
                        <Row>
                          {this.props.liscenseProps.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-3 col-lg-3 "
                              key={idx}
                              {...stats.attrs}
                            >
                              <DivRender
                                id={`license-images-${idx}`}
                                dunique_id={this.state.dunique_id}
                                phonenumber={this.state.phonenumber}
                                dl={this.state.dl_number}
                                propDta={this.state.propDta}
                                verifyDL={this.verifyDL}
                                verifyRC={this.verifyRC}
                                variation="1"
                                label={stats.label}
                                name={stats.name}
                                value={this.state[stats.name]}
                                idno={stats.idno}
                                idvalue={this.state[stats.idno]}
                                expiryVal={this.state[stats.expiry]}
                                expiryName={stats.expiry}
                                onChangeHandler={this.imagehandler}
                                onClickPush={this.callImagehandler}
                                onKeyChange={this.dateChange}
                                hideDiv={this.hideDiv}
                                valueChange={this.valueChange}
                              />
                              {/* {(stats.name === "driving_license_image" ||
                                stats.name === "vehicle_insurance_image" ||
                                stats.name === "driver_badgee_image") && (
                                <React.Fragment>
                                  <label>Expiry:</label>
                                  {this.state.hideType !== stats.name && (
                                    <FormInput
                                      readOnly={this.state.openLisc}
                                      value={new Date(this.state[stats.expiry])}
                                      placeholder="--"
                                      className="mt-2"
                                    />
                                  )}
                                  {this.state.hideType === stats.name && (
                                    <ReactDatePicker
                                      className="form-control py-3"
                                      selected={this.state[stats.expiry]}
                                      onChange={(e) => {
                                        this.dateChange(e, stats.expiry);
                                      }}
                                      dateFormat="dd/MM/yyyy"
                                    />
                                  )}
                                </React.Fragment>
                              )} */}
                            </Col>
                          ))}
                        </Row>
                        <hr />
                        <Row>
                          {this.props.roadProps.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-3 col-lg-3"
                              key={idx}
                              {...stats.attrs}
                            >
                              <DivRender
                                id={`road-images-${idx}`}
                                propDta={this.state.propDta}
                                variation="1"
                                label={stats.label}
                                name={stats.name}
                                value={this.state[stats.name]}
                                expiryVal={this.state[stats.expiry]}
                                expiryName={stats.expiry}
                                onChangeHandler={this.imagehandler}
                                onClickPush={this.callImagehandler}
                                onKeyChange={this.dateChange}
                                hideDiv={this.hideDiv}
                              />
                              {/* {(stats.name === "emission_cert_image" ||
                                stats.name === "road_tax_image" ||
                                stats.name === "fitness_image") && (
                                <React.Fragment>
                                  <label>Expiry:</label>
                                  {this.state.hideType !== stats.name && (
                                    <FormInput
                                      readOnly={this.state.openLisc}
                                      value={this.state[stats.expiry]}
                                      placeholder="--"
                                      className="mt-2"
                                    />
                                  )}
                                  {this.state.hideType === stats.name && (
                                    <ReactDatePicker
                                      className="form-control py-3"
                                      selected={this.state[stats.expiry]}
                                      dateFormat="dd/MM/yyyy"
                                      onChange={(e) => {
                                        this.dateChange(e, stats.expiry);
                                      }}
                                    />
                                  )}
                                </React.Fragment>
                              )} */}
                            </Col>
                          ))}
                        </Row>
                        <hr />
                        <Row>
                          {this.props.agreePC.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-3 col-lg-3 "
                              key={idx}
                              {...stats.attrs}
                            >
                              <DivRender
                                id={`agreement-images-${idx}`}
                                variation="1"
                                label={stats.label}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onChangeHandler={this.imagehandler}
                                onClickPush={this.callImagehandler}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                    {this.state.divName === "ride_preferences" && (
                      <div>
                        {this.state.ridePrefCog === true && (
                          <div className="ml-auto px-4 float-right">
                            <i
                              className="fas fa-cog pointer"
                              style={{ color: "blue", cursor: "pointer" }}
                              onClick={this.handleEditRidepref}
                            />
                          </div>
                        )}

                        {this.state.ridePrefCog === false && (
                          <div className="ml-auto px-4 float-right">
                            <i
                              className="far fa-check-circle pointer px-1"
                              style={{ color: "#59E67F", cursor: "pointer" }}
                              onClick={this.handleRideprefDetails}
                            />
                            <i
                              className="fas fa-ban pointer px-1"
                              style={{ color: "#FF0000", cursor: "pointer" }}
                              onClick={this.handleEditRidepref}
                            />
                          </div>
                        )}
                        <Row>
                          <Col className="col-12 col-md-6 col-lg-6 ">
                            <h6 className="label-style">
                              Preferred ride types
                            </h6>
                            <Row>
                              <Col className="col-12 col-md-4">
                                <FormCheckbox
                                  disabled={this.state.readonlyCheckbox}
                                  checked={this.state.outstation}
                                  onChange={(e) =>
                                    this.handleChange(e, "outstation")
                                  }
                                  className=" mt-2 "
                                >
                                  Outstation
                                </FormCheckbox>
                              </Col>
                              <Col className="col-12 col-md-4">
                                <FormCheckbox
                                  disabled={this.state.readonlyCheckbox}
                                  checked={this.state.rentals}
                                  onChange={(e) =>
                                    this.handleChange(e, "rentals")
                                  }
                                  className=" mt-2"
                                >
                                  Rentals
                                </FormCheckbox>
                              </Col>
                              <Col className="col-12 col-md-4">
                                <FormCheckbox
                                  disabled={this.state.readonlyCheckbox}
                                  checked={this.state.airport}
                                  onChange={(e) =>
                                    this.handleChange(e, "airport")
                                  }
                                  className=" mt-2"
                                >
                                  Airport
                                </FormCheckbox>
                              </Col>
                              <Col className="col-12 col-md-4">
                                <FormCheckbox
                                  disabled={this.state.readonlyCheckbox}
                                  checked={this.state.urban}
                                  onChange={(e) =>
                                    this.handleChange(e, "urban")
                                  }
                                  className=" mt-2"
                                >
                                  Urban
                                </FormCheckbox>
                              </Col>
                              <Col className="col-12 col-md-4">
                                <FormCheckbox
                                  disabled={this.state.readonlyCheckbox}
                                  checked={this.state.recurride}
                                  onChange={(e) =>
                                    this.handleChange(e, "recurride")
                                  }
                                  className=" mt-2"
                                >
                                  Recurride
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                          <Col className="col-12 col-md-6 col-lg-6 ">
                            <h6 className="label-style">Preferred shift</h6>
                            <Row>
                              <Col className="col-12 col-md-4">
                                <FormCheckbox
                                  disabled={this.state.readonlyCheckbox}
                                  checked={this.state.day_shift}
                                  onChange={(e) =>
                                    this.handleChange(e, "day_shift")
                                  }
                                  className=" mt-2"
                                >
                                  Day shift(8am-8pm)
                                </FormCheckbox>
                              </Col>
                              <Col className="col-12 col-md-4">
                                <FormCheckbox
                                  disabled={this.state.readonlyCheckbox}
                                  checked={this.state.night_shift}
                                  onChange={(e) =>
                                    this.handleChange(e, "night_shift")
                                  }
                                  className=" mt-2"
                                >
                                  Night shift(8am-8pm)
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col className="col-12 col-md-4 col-lg-4 mb-4">
                            <label>Operating region</label>
                            <FormSelect
                              disabled={this.state.readonlyCheckbox}
                              style={{ height: "calc(3.16rem + 2px)" }}
                              value={this.state.operating_region}
                              onChange={this.handleSelectChange}
                            >
                              {this.props.operatingRegionArray.map(
                                (data, index) => (
                                  <option key={index} value={data}>
                                    {data}
                                  </option>
                                )
                              )}
                            </FormSelect>
                          </Col>
                          {this.props.driverPreference.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-4 col-lg-4 mb-4"
                              key={idx}
                              {...stats.attrs}
                            >
                              {/* {console.log(this.state.hits)} */}
                              <InputDataForm
                                id={`driver-preference-${idx}`}
                                variation="1"
                                label={stats.label}
                                stats={stats.stats}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onKeyChange={this.handleKeyChange}
                                readOnly={this.state.readonlyCheckbox}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                    {this.state.divName === "family_details" && (
                      <div>
                        <Row className="px-3">
                          <label
                            style={{
                              fontWeight: "700",
                              fontSize: "1.1rem",
                            }}
                          >
                            <u>FAMILY DETAILS</u>
                          </label>
                          {this.state.familyDetailsCog === true && (
                            <div className="ml-auto px-4 float-right">
                              <i
                                className="fas fa-cog pointer"
                                style={{ color: "blue" }}
                                onClick={this.handleEditFamily}
                              />
                            </div>
                          )}

                          {this.state.familyDetailsCog === false && (
                            <div className="ml-auto px-4 float-right">
                              <i
                                className="far fa-check-circle pointer"
                                style={{ color: "#59E67F" }}
                                onClick={this.handleFamilyDetails}
                              />
                              <i
                                className="fas mx-2 fa-ban pointer"
                                style={{ color: "#FF0000" }}
                                onClick={this.handleEditFamily}
                              />
                            </div>
                          )}
                        </Row>

                        <Row className="mb-4">
                          <Col className="col-12">
                            <h6>Family member - 1</h6>
                          </Col>

                          {this.props.familyInputData_1.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-3 col-lg-3 mb-4"
                              key={idx}
                              {...stats.attrs}
                            >
                              {/* {console.log(this.state.hits)} */}
                              <InputDataForm
                                id={`family-details-1-${idx}`}
                                variation="1"
                                label={stats.label}
                                stats={stats.stats}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onKeyChange={this.handleKeyChange}
                                readOnly={this.state.readOnlyFamilyDetails}
                              />
                            </Col>
                          ))}
                          <Col className="col-3" />
                          {this.props.familyAddressField_1.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-6 col-lg-6 mb-4"
                              key={idx}
                              {...stats.attrs}
                            >
                              {/* {console.log(this.state.hits)} */}
                              <TextArea
                                id={`smaller-stats-${idx}`}
                                variation="1"
                                label={stats.label}
                                stats={stats.stats}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onKeyChange={this.handleKeyChange}
                                readOnly={this.state.readOnlyFamilyDetails}
                              />
                            </Col>
                          ))}
                        </Row>
                        <Row>
                          <Col className="col-12">
                            <h6>Family member - 2</h6>
                          </Col>

                          {this.props.familyInputData_2.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-3 col-lg-3 mb-4"
                              key={idx}
                              {...stats.attrs}
                            >
                              {/* {console.log(this.state.hits)} */}
                              <InputDataForm
                                id={`smaller-stats-${idx}`}
                                variation="1"
                                label={stats.label}
                                stats={stats.stats}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onKeyChange={this.handleKeyChange}
                                readOnly={this.state.readOnlyFamilyDetails}
                              />
                            </Col>
                          ))}
                          <Col className="col-3" />
                          {this.props.familyAddressField_2.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-6 col-lg-6 mb-4"
                              key={idx}
                              {...stats.attrs}
                            >
                              {/* {console.log(this.state.hits)} */}
                              <TextArea
                                id={`smaller-stats-${idx}`}
                                variation="1"
                                label={stats.label}
                                stats={stats.stats}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onKeyChange={this.handleKeyChange}
                                readOnly={this.state.readOnlyFamilyDetails}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                    {this.state.divName === "bank_account_details" && (
                      <div>
                        <Row className="px-3">
                          <label
                            style={{
                              fontWeight: "700",
                              fontSize: "1.1rem",
                            }}
                          >
                            <u>BANK DETAILS</u>
                          </label>
                          {/* <Col className="col-12 col-md-12 col-lg-12 mb-4"> */}
                          {this.state.bankDetailsCog === true && (
                            <div className="ml-auto px-4 float-right">
                              <i
                                className="fas fa-cog pointer"
                                style={{ color: "blue" }}
                                onClick={this.handleEditBank}
                              />
                            </div>
                          )}

                          {this.state.bankDetailsCog === false && (
                            <div className="ml-auto px-4 float-right">
                              <i
                                className="far fa-check-circle pointer px-1"
                                style={{ color: "#59E67F" }}
                                onClick={this.handleBankDetails}
                              />
                              <i
                                className="fas fa-ban pointer px-1"
                                style={{ color: "#FF0000" }}
                                onClick={this.handleEditBank}
                              />
                            </div>
                          )}
                          {/* </Col> */}
                        </Row>
                        <Row>
                          <Col className="col-12 col-md-12 ">
                            {/* <Card>
                              <CardBody> */}

                            <Row>
                              {this.props.AccinputValues.map((stats, idx) => (
                                <Col
                                  className="col-12 col-md-4 mb-4"
                                  key={idx}
                                  {...stats.attrs}
                                >
                                  {/* {console.log(this.state.hits)} */}
                                  <InputDataForm
                                    id={`smaller-stats-${idx}`}
                                    variation="1"
                                    label={stats.label}
                                    stats={stats.stats}
                                    value={this.state[stats.name]}
                                    name={stats.name}
                                    onKeyChange={this.handleKeyChange}
                                    readOnly={this.state.readOnlyBankDetails}
                                  />
                                </Col>
                              ))}
                            </Row>
                            {/* </CardBody>
                            </Card> */}
                          </Col>
                        </Row>
                      </div>
                    )}
                    {this.state.divName === "driver_form" && (
                      <div>
                        <Row>
                          {this.props.driverForm.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-3 col-lg-3 "
                              key={idx}
                              {...stats.attrs}
                            >
                              <DivRender
                                id={`driverForm-images-${idx}`}
                                variation="1"
                                label={stats.label}
                                name={stats.name}
                                value={this.state[stats.name]}
                                onChangeHandler={this.imagehandler}
                                onClickPush={this.callImagehandler}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                  </CardBody>
                  <CardFooter />
                </Card>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="col-12 col-md-8 col-lg-8">
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-12 col-md-6 col-lg-6">
                        <label
                          style={{
                            fontWeight: "700",
                            fontSize: "1.1rem",
                          }}
                        >
                          <u>REGISTERED BY</u>
                        </label>
                        <div>
                          {this.state.registeredBy +
                            ", " +
                            this.state.registeredDate}
                        </div>
                      </Col>
                      <Col className="col-12 col-md-6 col-lg-6">
                        <label
                          style={{
                            fontWeight: "700",
                            fontSize: "1.1rem",
                          }}
                        >
                          <u>LAST UPDATED BY</u>
                        </label>
                        <div>
                          {this.state.lastEditedBy +
                            ", " +
                            this.state.lastEditedDate}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
        {this.state.success === false && (
          <ReactSnackBar
            Icon={
              <i
                className="far fa-times-circle fa-lg"
                style={{ color: "#FF0000" }}
              />
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
        {this.state.success === true && (
          <ReactSnackBar
            Icon={
              <span>
                <i
                  className="far fa-check-circle fa-lg"
                  style={{ color: "#26FF58" }}
                />
              </span>
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
      </Container>
    );
  }
}
EditDriverLogic.propTypes = {
  inputValues: PropTypes.array,
  vehicleimagesProp: PropTypes.array,
};

EditDriverLogic.defaultProps = {
  vehicleinputValues: [
    {
      label: "Vehicle name",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_name",
    },
    {
      label: "Vehicle number",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_number",
    },
    {
      label: "Vehicle make",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_make",
    },
    {
      label: "Vehicle model",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_model",
    },
    {
      label: "Vehicle color",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_color",
    },
  ],
  vehicletypeValues: [
    {
      label: "Vehicle type",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_type",
    },
  ],
  inputValues: [
    {
      label: "First name",
      value: "",
      attrs: {},
      stats: false,
      name: "first_name",
    },
    {
      label: "Last name",
      value: "",
      attrs: {},
      stats: false,
      name: "last_name",
    },
    {
      label: "Email",
      value: "",
      attrs: {},
      name: "email",
    },
    {
      label: "Phone number",
      value: "",
      attrs: {},
      name: "phonenumber",
    },
    {
      label: "Alternate phone number",
      value: "",
      attrs: {},
      name: "alt_phonenumber",
    },
  ],
  inputdrivertype: [
    {
      label: "Driver type",
      value: "",
      attrs: {},
      name: "driver_type",
    },
  ],
  addressField: [
    {
      label: "Current address",
      value: "",
      attrs: {},
      stats: false,
      name: "current_address",
    },
    {
      label: "Permanent address",
      value: "",
      attrs: {},
      stats: false,
      name: "permanent_address",
    },
  ],
  familyInputData_1: [
    {
      label: "Name",
      value: "",
      attrs: {},
      stats: false,
      name: "family_name_1",
    },
    {
      label: "Phone number",
      value: "",
      attrs: {},
      stats: false,
      name: "family_phn_1",
    },
    {
      label: "Relationship",
      value: "",
      attrs: {},
      stats: false,
      name: "family_rel_1",
    },
  ],
  familyInputData_2: [
    {
      label: "Name",
      value: "",
      attrs: {},
      stats: false,
      name: "family_name_2",
    },
    {
      label: "Phone number",
      value: "",
      attrs: {},
      stats: false,
      name: "family_phn_2",
    },
    {
      label: "Relationship",
      value: "",
      attrs: {},
      stats: false,
      name: "family_rel_2",
    },
  ],
  familyAddressField_1: [
    {
      label: "Address",
      value: "",
      attrs: {},
      stats: false,
      name: "family_address_1",
    },
  ],
  familyAddressField_2: [
    {
      label: "Address",
      value: "",
      attrs: {},
      stats: false,
      name: "family_address_2",
    },
  ],
  driverPreference: [
    {
      label: "Preferred location",
      value: "",
      attrs: {},
      stats: false,
      name: "preferred_location",
    },
    {
      label: "Starting point",
      value: "",
      attrs: {},
      stats: false,
      name: "starting_point",
    },
  ],
  navItems: [
    {
      label: "ID proof",
      name: "id_proofs",
    },
    {
      label: "Ride preferences",
      name: "ride_preferences",
    },
    {
      label: "Family details",
      name: "family_details",
    },
    {
      label: "Bank account details",
      name: "bank_account_details",
    },
    {
      label: "Driver form",
      name: "driver_form",
    },
  ],

  AccinputValues: [
    {
      label: "Account holder name",
      value: "",
      attrs: {},
      stats: false,
      name: "acc_holder_name",
    },
    {
      label: "Phone number",
      value: "",
      attrs: {},
      stats: false,
      name: "acc_phonenumber",
    },
    {
      label: "IFSC*",
      value: "",
      attrs: {},
      stats: false,
      name: "ifsc",
    },
    {
      label: "Account number",
      value: "",
      attrs: {},
      stats: false,
      name: "acc_number",
    },
    {
      label: "Email",
      value: "",
      attrs: {},
      stats: false,
      name: "acc_email",
    },
    {
      label: "Address",
      value: "",
      attrs: {},
      stats: false,
      name: "acc_address",
    },
    {
      label: "Bank status",
      value: "",
      attrs: {},
      stats: false,
      name: "bank_verified_status",
    },
  ],
  vehicleimagesProp: [
    {
      label: "Vehicle front",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_front_image",
    },
    {
      label: "Vehicle rear",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_back_image",
    },
    {
      label: "Vehicel left",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_left_image",
    },
    {
      label: "Vehicle right",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_right_image",
    },
    {
      label: "With driver",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_with_driver",
    },
  ],
  idProofs: [
    {
      label: "Aadhar card",
      idno: "aadhar_number",
      value: "",
      attrs: {},
      stats: false,
      name: "aadhar_card_image_front",
    },
    {
      label: "PAN card",
      value: "",
      idno: "pan_number",
      attrs: {},
      stats: false,
      name: "pan_card_image",
    },
  ],
  agreePC: [
    {
      label: "Sales agreement",
      value: "",
      attrs: {},
      stats: false,
      name: "sales_agreement",
    },
    {
      label: "Police verification",
      value: "",
      attrs: {},
      stats: false,
      name: "police_verification",
    },
  ],

  liscenseProps: [
    {
      label: "Driving license",
      value: "",
      attrs: {},
      expiry: "dl_expiry",
      idno: "dl_number",
      stats: false,
      name: "driving_license_image",
      edit: "dlTest",
    },
    {
      label: "Reg. Certificate",
      value: "",
      expiry: "",
      attrs: {},
      stats: false,
      name: "rc_image",
    },
    {
      label: "Vehicle insurance",
      value: "",
      expiry: "insurance_expiry",
      attrs: {},
      stats: false,
      name: "vehicle_insurance_image",
      edit: "insuranceTest",
    },
    {
      label: "Driver badge(Opt)",
      value: "",
      expiry: "display_expiry",
      attrs: {},
      stats: false,
      idno: "badge_number",
      name: "driver_badgee_image",
      edit: "badgeTest",
    },
  ],

  roadProps: [
    {
      label: "Emission certificate",
      value: "",
      attrs: {},
      stats: false,
      expiry: "emission_expiry",
      name: "emission_cert_image",
      edit: "emissonTest",
    },
    {
      label: "Road tax",
      value: "",
      attrs: {},
      stats: false,
      expiry: "roadtax_expiry",
      name: "road_tax_image",
      edit: "roadTest",
    },
    {
      label: "Permit",
      value: "",
      attrs: {},
      stats: false,
      expiry: "permit_expiry",
      name: "permit_image",
      edit: "permitTest",
    },
    {
      label: "Fitness certificate",
      value: "",
      attrs: {},
      stats: false,
      expiry: "fc_expiry",
      name: "fitness_image",
      edit: "fcTest",
    },
  ],
  driverForm: [
    {
      label: "Front page",
      value: "",
      attrs: {},
      stats: false,
      name: "driverformImageFront",
    },
    {
      label: "Back page",
      value: "",
      attrs: {},
      stats: false,
      name: "driverformImageBack",
    },
  ],
};
export default EditDriverLogic;
