import React, { Component } from "react";

import ReactTable from "react-table-v6";
// import classNames from "classnames";
import "react-table-v6/react-table.css";
import ReactSnackBar from "react-js-snackbar";
// import GoogleMapReact from 'google-maps-react'
import SimpleMap from "./../utilities/ModalMap";
import LiveMap from "./../utilities/LiveGmaps";
import PolyLineMap from "./../utilities/PolyLineMap";
import PropTypes from "prop-types";
import * as config from "../../constants/constants.json";
import Stepper from "./StepperRide";
import StepperCancel from "./StepperCancel";
import StepperPoll from "./StepperPoll";
import moment from "moment";
import equal from "fast-deep-equal";
import TripDump from "./TripDump";
import ColumnConvo from "./ColumnConvo";
import StepQuick from "./StepperQickride";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  FormCheckbox,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormTextarea,
} from "shards-react";
// import DatePicker from "react-date-picker";
import InputData from "./../common/InputData";
import RideIssues from "./RideIssues";
// import FineData from "./../common/FineData"

function capitalizeFirstLetter(string) {
  // console.log(string);
  return string[0].toUpperCase() + string.slice(1);
}
class TripDetails extends Component {
  constructor(props) {
    super(props); //since we are extending class Table so we have to use super in order to override Component class constructor
    // this.updateContent = this.updateContent.bind(this);
    this.sequence = {
      message: "successfull",
      arr: [
        {
          passenger_name: "A",
          passenger_mobile: 6360503139,
          passenger_email: "skjsaj@kssjjs.com",
          pickup_sequence_no: 1,
          drop_sequence_no: 1,
          pickup_time: "2020-10-14T10:26:00.000Z",
          drop_time: "2020-10-14T10:53:00.000Z",
          pickup_location_name:
            "Ashok Nagar Street Number 11, P & T Colony, Jawahar Nagar, Himayatnagar, Bengaluru, Telangana, India",
          pickup_latitude: 12.907789,
          pickup_longitude: 78.4902871,
          drop_latitude: 13.048228,
          drop_longitude: 77.628792,
          drop_location_name:
            "Uppal, Venkateswara Colony, Vijayapuri Colony, Uppal, Bengaluru, Telangana, India",
          passenger_status: "notified",
          number: 1,
          passenger_state: "pickup",
        },
        {
          passenger_name: "A",
          passenger_mobile: 6360503139,
          passenger_email: "skjsaj@kssjjs.com",
          pickup_sequence_no: 1,
          drop_sequence_no: 1,
          pickup_time: "2020-10-14T10:26:00.000Z",
          drop_time: "2020-10-14T10:53:00.000Z",
          pickup_location_name:
            "Ashok Nagar Street Number 11, P & T Colony, Jawahar Nagar, Himayatnagar, Bengaluru, Telangana, India",
          pickup_latitude: 12.907789,
          pickup_longitude: 78.4902871,
          drop_latitude: 13.048228,
          drop_longitude: 77.628792,
          drop_location_name:
            "Uppal, Venkateswara Colony, Vijayapuri Colony, Uppal, Bengaluru, Telangana, India",
          passenger_status: "waitingforpickup",
          number: 2,
          passenger_state: "drop",
        },
        {
          passenger_name: "C",
          passenger_mobile: 5252525250,
          passenger_email: "sanam@gmail.com",
          pickup_sequence_no: 2,
          drop_sequence_no: 2,
          pickup_time: "2020-10-14T10:26:00.000Z",
          drop_time: "2020-10-14T10:53:00.000Z",
          pickup_location_name:
            "Ashok Nagar Street Number 11, P & T Colony, Jawahar Nagar, Himayatnagar, Bengaluru, Telangana, India",
          pickup_latitude: 12.907789,
          pickup_longitude: 78.4902871,
          drop_latitude: 13.048228,
          drop_longitude: 77.628792,
          drop_location_name:
            "Uppal, Venkateswara Colony, Vijayapuri Colony, Uppal, Bengaluru, Telangana, India",
          passenger_status: "boarded",
          number: 3,
          passenger_state: "pickup",
        },
        {
          passenger_name: "C",
          passenger_mobile: 5252525250,
          passenger_email: "sanam@gmail.com",
          pickup_sequence_no: 2,
          drop_sequence_no: 2,
          pickup_time: "2020-10-14T10:26:00.000Z",
          drop_time: "2020-10-14T10:53:00.000Z",
          pickup_location_name:
            "Ashok Nagar Street Number 11, P & T Colony, Jawahar Nagar, Himayatnagar, Bengaluru, Telangana, India",
          pickup_latitude: 12.907789,
          pickup_longitude: 78.4902871,
          drop_latitude: 13.048228,
          drop_longitude: 77.628792,
          drop_location_name:
            "Uppal, Venkateswara Colony, Vijayapuri Colony, Uppal, Bengaluru, Telangana, India",
          passenger_status: "dropped",
          number: 4,
          passenger_state: "drop",
        },
        {
          passenger_name: "B",
          passenger_mobile: 6786786786,
          passenger_email: "sarusuparihongal@gmail.com",
          pickup_sequence_no: 3,
          drop_sequence_no: 3,
          pickup_time: "2020-10-14T10:25:00.000Z",
          drop_time: "2020-10-14T10:53:00.000Z",
          pickup_location_name:
            "Ashok Nagar Street, Ashok Nagar, Himayatnagar, Bengaluru, Telangana",
          pickup_latitude: 12.907789,
          pickup_longitude: 78.4902871,
          drop_latitude: 13.048228,
          drop_longitude: 77.628792,
          drop_location_name:
            "Uppal, Venkateswara Colony, Vijayapuri Colony, Uppal, Bengaluru, Telangana, India",
          passenger_status: "passengernoshow",
          number: 5,
          passenger_state: "pickup",
        },
        {
          passenger_name: "B",
          passenger_mobile: 6786786786,
          passenger_email: "sarusuparihongal@gmail.com",
          pickup_sequence_no: 3,
          drop_sequence_no: 3,
          pickup_time: "2020-10-14T10:25:00.000Z",
          drop_time: "2020-10-14T10:53:00.000Z",
          pickup_location_name:
            "Ashok Nagar Street, Ashok Nagar, Himayatnagar, Bengaluru, Telangana",
          pickup_latitude: 12.907789,
          pickup_longitude: 78.4902871,
          drop_latitude: 13.048228,
          drop_longitude: 77.628792,
          drop_location_name:
            "Uppal, Venkateswara Colony, Vijayapuri Colony, Uppal, Bengaluru, Telangana, India",
          passenger_status: "passengernoshow",
          number: 6,
          passenger_state: "drop",
        },
        {
          passenger_name: "A",
          passenger_mobile: 6360503139,
          passenger_email: "skjsaj@kssjjs.com",
          pickup_sequence_no: 1,
          drop_sequence_no: 1,
          pickup_time: "2020-10-14T10:26:00.000Z",
          drop_time: "2020-10-14T10:53:00.000Z",
          pickup_location_name:
            "Ashok Nagar Street Number 11, P & T Colony, Jawahar Nagar, Himayatnagar, Bengaluru, Telangana, India",
          pickup_latitude: 12.907789,
          pickup_longitude: 78.4902871,
          drop_latitude: 13.048228,
          drop_longitude: 77.628792,
          drop_location_name:
            "Uppal, Venkateswara Colony, Vijayapuri Colony, Uppal, Bengaluru, Telangana, India",
          passenger_status: "notified",
          number: 1,
          passenger_state: "pickup",
        },
        {
          passenger_name: "A",
          passenger_mobile: 6360503139,
          passenger_email: "skjsaj@kssjjs.com",
          pickup_sequence_no: 1,
          drop_sequence_no: 1,
          pickup_time: "2020-10-14T10:26:00.000Z",
          drop_time: "2020-10-14T10:53:00.000Z",
          pickup_location_name:
            "Ashok Nagar Street Number 11, P & T Colony, Jawahar Nagar, Himayatnagar, Bengaluru, Telangana, India",
          pickup_latitude: 12.907789,
          pickup_longitude: 78.4902871,
          drop_latitude: 13.048228,
          drop_longitude: 77.628792,
          drop_location_name:
            "Uppal, Venkateswara Colony, Vijayapuri Colony, Uppal, Bengaluru, Telangana, India",
          passenger_status: "waitingforpickup",
          number: 2,
          passenger_state: "drop",
        },
        {
          passenger_name: "C",
          passenger_mobile: 5252525250,
          passenger_email: "sanam@gmail.com",
          pickup_sequence_no: 2,
          drop_sequence_no: 2,
          pickup_time: "2020-10-14T10:26:00.000Z",
          drop_time: "2020-10-14T10:53:00.000Z",
          pickup_location_name:
            "Ashok Nagar Street Number 11, P & T Colony, Jawahar Nagar, Himayatnagar, Bengaluru, Telangana, India",
          pickup_latitude: 12.907789,
          pickup_longitude: 78.4902871,
          drop_latitude: 13.048228,
          drop_longitude: 77.628792,
          drop_location_name:
            "Uppal, Venkateswara Colony, Vijayapuri Colony, Uppal, Bengaluru, Telangana, India",
          passenger_status: "boarded",
          number: 3,
          passenger_state: "pickup",
        },
        {
          passenger_name: "C",
          passenger_mobile: 5252525250,
          passenger_email: "sanam@gmail.com",
          pickup_sequence_no: 2,
          drop_sequence_no: 2,
          pickup_time: "2020-10-14T10:26:00.000Z",
          drop_time: "2020-10-14T10:53:00.000Z",
          pickup_location_name:
            "Ashok Nagar Street Number 11, P & T Colony, Jawahar Nagar, Himayatnagar, Bengaluru, Telangana, India",
          pickup_latitude: 12.907789,
          pickup_longitude: 78.4902871,
          drop_latitude: 13.048228,
          drop_longitude: 77.628792,
          drop_location_name:
            "Uppal, Venkateswara Colony, Vijayapuri Colony, Uppal, Bengaluru, Telangana, India",
          passenger_status: "dropped",
          number: 4,
          passenger_state: "drop",
        },
        {
          passenger_name: "B",
          passenger_mobile: 6786786786,
          passenger_email: "sarusuparihongal@gmail.com",
          pickup_sequence_no: 3,
          drop_sequence_no: 3,
          pickup_time: "2020-10-14T10:25:00.000Z",
          drop_time: "2020-10-14T10:53:00.000Z",
          pickup_location_name:
            "Ashok Nagar Street, Ashok Nagar, Himayatnagar, Bengaluru, Telangana",
          pickup_latitude: 12.907789,
          pickup_longitude: 78.4902871,
          drop_latitude: 13.048228,
          drop_longitude: 77.628792,
          drop_location_name:
            "Uppal, Venkateswara Colony, Vijayapuri Colony, Uppal, Bengaluru, Telangana, India",
          passenger_status: "passengernoshow",
          number: 5,
          passenger_state: "pickup",
        },
        {
          passenger_name: "B",
          passenger_mobile: 6786786786,
          passenger_email: "sarusuparihongal@gmail.com",
          pickup_sequence_no: 3,
          drop_sequence_no: 3,
          pickup_time: "2020-10-14T10:25:00.000Z",
          drop_time: "2020-10-14T10:53:00.000Z",
          pickup_location_name:
            "Ashok Nagar Street, Ashok Nagar, Himayatnagar, Bengaluru, Telangana",
          pickup_latitude: 12.907789,
          pickup_longitude: 78.4902871,
          drop_latitude: 13.048228,
          drop_longitude: 77.628792,
          drop_location_name:
            "Uppal, Venkateswara Colony, Vijayapuri Colony, Uppal, Bengaluru, Telangana, India",
          passenger_status: "passengernoshow",
          number: 6,
          passenger_state: "drop",
        },
      ],
    };
    this.state = {
      //state is by default an object
      tripdata: [],
      detachOpen: false,
      driverLocationData: [],
      reassignOpen: false,
      cancelOpen: false,
      cnoshow: false,
      breakdown: false,
      flightDelay: false,
      dnoshow: false,
      otpNumber: "",
      otp: "",
      otpVerCode: "",
      textareaValue: "",
      setVisible: false,
      editable: true,
      offeredAmount: "",
      extra_traveled_kms: "",
      empty_kms: "",
      fineAmount: "",
      fineReason: "",
      buttonName: "UPDATE RIDE DETAILS",
      success: false,
      Show: false,
      Showing: false,
      message: "",
      cancel_reason: "",
      otpGen: "",
      reviewList: [],
      issueTitle: "",
      issueDescription: "",
      convoDetails: [],
      switchDiv: false,
      replyDescription: "",
      statusLocation: [],
      tripDump: false,
      rideIssue: false,
      passData: [],
      toll_ticket: "",
    };
    // console.log(this.props);
    this.toggleReassign = this.toggleReassign.bind(this);
    this.toggleDetach = this.toggleDetach.bind(this);
    this.toggleCancel = this.toggleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchDriverLocation = this.fetchDriverLocation.bind(this);
    this.gencancelOTP = this.gencancelOTP.bind(this);
    this.verifycancelOTP = this.verifycancelOTP.bind(this);
    this.handleOtpChange = this.handleOtpChange.bind(this);
    this.reAssign = this.reAssign.bind(this);
    this.cancelRide = this.cancelRide.bind(this);
    this.DetachRide = this.DetachRide.bind(this);
    this.handletextareaChange = this.handletextareaChange.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.callApitoUpdateData = this.callApitoUpdateData.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.updateOfferedAmount = this.updateOfferedAmount.bind(this);
    this.updateExtraTraveledLms = this.updateExtraTraveledLms.bind(this);
    this.updateFineAmount = this.updateFineAmount.bind(this);
    this.updateEmptyKms = this.updateEmptyKms.bind(this);
    this.handleAssignClick = this.handleAssignClick.bind(this);
    this.callAutoAssign = this.callAutoAssign.bind(this);
    this.onReasonChange = this.onReasonChange.bind(this);
    this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
    this.getReports = this.getReports.bind(this);
    this.submitIssue = this.submitIssue.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.submitReply = this.submitReply.bind(this);
    this.closeReply = this.closeReply.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.getTripStatusLocations = this.getTripStatusLocations.bind(this);
    this.enableTripDump = this.enableTripDump.bind(this);
    this.enableRideIssue = this.enableRideIssue.bind(this);
    this.enableTripDetails = this.enableTripDetails.bind(this);
    this.getRideDetails = this.getRideDetails.bind(this);
    this.callToggle = this.callToggle.bind(this);
    // this.getPassengerSequence = this.getPassengerSequence.bind(this);
    // console.log(props);
  }

  // getPassengerSequence(){
  //   let data = {
  //     trip_id: this.props.tripId
  //   }

  //   fetch(config.url, "driver/get")
  // }

  callToggle(data) {
    this.setState({
      clicked: !this.state.clicked,
    });
  }
  enableTripDetails() {
    if (this.state.tripDump === undefined) {
      this.setState({
        tripDump: false,
      });
    }
  }
  enableRideIssue() {
    this.setState(
      {
        rideIssue: !this.state.rideIssue,
      },
      () => {
        this.enableTripDetails();
      }
    );
  }
  enableTripDump() {
    this.setState({
      tripDump: !this.state.tripDump,
    });
  }

  getTripStatusLocations() {
    // console.log("I am here");
    // console.log(this.props.tripId);
    fetch(config.url + "booking/getTripStatusLocations", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        trip_id: this.props.tripId,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("I am here too");
          // console.log(result);
          if (result.message === "successfull") {
            let data = {
              drop_latlon: result.drop_latlon,
              end_time: result.end_time,
              leaveforpickup_time: result.leaveforpickup_time,
              notify_latlon: result.notify_latlon,
              notify_time: result.notify_time,
              start_latlon: result.start_latlon,
              start_time: result.start_time,
              toll_info: result.toll_info,
            };
            if (result.toll_info === undefined) {
              this.setState({
                statusLocation: data,
                toll_ticket: "",
              });
            } else {
              this.setState({
                statusLocation: data,
                toll_ticket:
                  result.toll_info.toll_ticket_url === undefined
                    ? ""
                    : result.toll_info.toll_ticket_url,
              });
            }
          } else {
            this.setState({
              statusLocation: [],
            });
          }
          // console.log("herererer");
          // this.setState({
          //   convoDetails: result.data,
          // });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  refreshList() {
    let data = {
      issue_id: this.state.convoDetails.issue_id,
    };
    // console.log(data);
    this.getConversation(data);
  }
  closeReply() {
    // console.log("Herererer");
    this.setState({
      replyDescription: "",
      switchDiv: false,
    });
  }
  handleClick(data) {
    this.setState(
      {
        passData: data,
      },
      () => {
        this.enableRideIssue();
      }
    );
  }
  getConversation(data) {
    fetch(config.url + "htmlcrm/getIssueData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        issue_id: data.issue_id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          // console.log("herererer");
          this.setState({
            convoDetails: result.data,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  submitIssue() {
    if (
      this.state.issueTitle === undefined ||
      this.state.issueTitle === null ||
      this.state.issueTitle === "" ||
      this.state.issueDescription === undefined ||
      this.state.issueDescription === null ||
      this.state.issueDescription === ""
    ) {
      this.setState(
        {
          success: false,
          message: "Title or description cannot be empty",
        },
        () => {
          this.show();
        }
      );
    } else {
      fetch(config.url + "htmlcrm/createRideIssue", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          trip_id: this.props.tripId,
          userid: window.sessionStorage.getItem("user_id"),
          username: window.sessionStorage.getItem("username"),
          title: this.state.issueTitle,
          message: this.state.issueDescription,
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log("herererer");
            this.setState(
              {
                issueTitle: "",
                issueDescription: "",
                success: true,
                message: "New issue has been created",
              },
              () => {
                this.getReports();
                this.show();
              }
            );
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    }
  }
  submitReply() {
    if (
      this.state.replyDescription === undefined ||
      this.state.replyDescription === null ||
      this.state.replyDescription === ""
    ) {
      this.setState(
        {
          success: false,
          message: "Reply cannot be empty",
        },
        () => {
          this.show();
        }
      );
    } else {
      fetch(config.url + "htmlcrm/addCommentToRI", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          issue_id: this.state.convoDetails.issue_id,
          userid: window.sessionStorage.getItem("user_id"),
          username: window.sessionStorage.getItem("username"),
          status: "open",
          message: this.state.replyDescription,
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log("herererer");
            this.setState(
              {
                replyDescription: "",
              },
              () => {
                let data = {
                  issue_id: this.state.convoDetails.issue_id,
                };
                this.getConversation(data);
              }
            );
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    }
  }
  capitalizeFirstLetter(string) {
    // console.log(string);
    return string[0].toUpperCase() + string.slice(1);
  }

  onReasonChange(event) {
    this.setState({
      cancel_reason: event.target.value,
    });
  }
  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };
  callAutoAssign() {
    // console.log(this.props.tripId);
    fetch(config.url + "vendor/autoAssignRide", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // trip_id: this.props.tripId
        // trip_id: '0K918TQC60KQcwth'
        trip_id: this.props.tripId,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          if (result.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "Auto-assign triggered!",
              },
              () => {
                this.show();
              }
            );
          } else {
            this.setState(
              {
                success: false,
                message: "Update failure!",
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleAssignClick() {
    this.props.onClickCallback(this.state.tripdata);
  }
  handleButtonClick() {
    // event.preventDefault()
    // console.log(this.state.editable)
    if (this.state.editable === true) {
      this.setState({
        editable: !this.state.editable,
        buttonName: "SAVE RIDE DETAILS",
      });
    } else {
      this.setState(
        {
          editable: !this.state.editable,
          buttonName: "UPDATE RIDE DETAILS",
        },
        () => this.callApitoUpdateData()
      );
    }
  }
  callApitoUpdateData() {
    // console.log("called");
    // console.log(this.state.offeredAmount);
    // console.log(this.state.extra_traveled_kms);
    // console.log(this.state.fineAmount);
    // console.log(this.state.empty_kms);
    this.updateOfferedAmount(this.state.offeredAmount);
    this.updateExtraTraveledLms(this.state.extra_traveled_kms);
    this.updateFineAmount(this.state.fineAmount, this.state.fineReason);
    this.updateEmptyKms(this.state.empty_kms);
  }
  updateOfferedAmount(data) {
    if (data) {
      // console.log("this is data => " + data);
      fetch(config.url + "htmlcrm/updateTripData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          // trip_id: this.props.tripId
          // trip_id: '0K918TQC60KQcwth'
          trip_id: this.props.tripId,
          userid: window.sessionStorage.getItem("user_id"),
          valueType: "offered",
          value: data,
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log("herererer");
            // console.log(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    } else {
    }
  }
  updateExtraTraveledLms(data) {
    if (data) {
      // console.log("this is data => " + data);
      fetch(config.url + "htmlcrm/updateTripData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          // trip_id: this.props.tripId
          // trip_id: '0K918TQC60KQcwth'
          trip_id: this.props.tripId,
          userid: window.sessionStorage.getItem("user_id"),
          valueType: "extrakm",
          value: data,
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log("herererer");
            // console.log(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    } else {
    }
  }
  updateFineAmount(data, reason) {
    if (data) {
      // console.log("this is data => " + data);
      fetch(config.url + "htmlcrm/updateTripData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          // trip_id: this.props.tripId
          // trip_id: '0K918TQC60KQcwth'
          trip_id: this.props.tripId,
          userid: window.sessionStorage.getItem("user_id"),
          valueType: "fine",
          value: data,
          reason: reason !== undefined ? reason : "",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.message === "successfull") {
              this.setState(
                {
                  success: true,
                  message: "Updated fine data successfully",
                },
                () => {
                  this.show();
                }
              );
            } else {
              this.setState(
                {
                  success: true,
                  message: "Updating fine data failed",
                },
                () => {
                  this.show();
                }
              );
            }
            // console.log("herererer");
            // console.log(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    } else {
    }
  }
  updateEmptyKms(data) {
    // console.log(data)
    if (data) {
      // console.log("this is data => " + data);
      fetch(config.url + "htmlcrm/updateTripData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          // trip_id: this.props.tripId
          // trip_id: '0K918TQC60KQcwth'
          trip_id: this.props.tripId,
          userid: window.sessionStorage.getItem("user_id"),
          valueType: "emptykm",
          value: data,
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log("herererer");
            // console.log(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    } else {
    }
  }
  updateLocationInteravl() {
    this.fetchDriverLocation();
  }
  handletextareaChange(event) {
    // console.log(event);
    this.setState({ textareaValue: event.target.value }, () => {
      console.log(this.state.handletextareaChange);
    });
  }

  DetachRide() {
    if (
      this.state.cancel_reason === undefined ||
      this.state.cancel_reason === ""
    ) {
      this.setState(
        {
          success: false,
          message: "Please provide a reason",
        },
        () => {
          this.show();
        }
      );
    } else {
      fetch(config.url + "htmlcrm/requestDetachPermission", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          // trip_id: this.props.tripId
          // trip_id: '0K918TQC60KQcwth'
          trip_id: this.props.tripId,
          user_id: window.sessionStorage.getItem("user_id"),
          reason: "Customer no show",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState(
              {
                success: true,
                message: "Detach request raised",
              },
              () => {
                this.show();
                this.toggleDetach();
              }
            );
            // console.log("herererer");
            // console.log(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    }
  }

  cancelRide() {
    let CancelCall;
    // console.log(this.state.tripdata.trip_status);
    if (
      this.state.tripdata.trip_status === "Requested" &&
      this.state.tripdata.trip_status === "Emergency"
    ) {
      // console.log("herererer");
      CancelCall = "cancelRequestedRide";
    } else if (
      this.state.tripdata.trip_status === "Accepted" ||
      this.state.tripdata.trip_status === "Notified"
    ) {
      CancelCall = "canceltrip";
    } else if (
      this.state.tripdata.trip_status === "Assigned" ||
      this.state.tripdata.trip_status === "Requested"
    ) {
      CancelCall = "cancelRides";
    }
    // console.log(CancelCall);
    fetch(config.url + `htmlcrm/${CancelCall}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // trip_id: this.props.tripId
        // trip_id: '0K918TQC60KQcwth'
        trip_id: this.props.tripId,
        trip_type: this.props.rideType,
        userid: window.sessionStorage.getItem("user_id"),
        user_id: window.sessionStorage.getItem("user_id"),
        cancel_by: window.sessionStorage.getItem("user_id"),
        request_status: this.state.tripdata.trip_status,
        trip_status: "cancelled",
        dunique_id: this.state.tripdata.dunique_id,
        cancel_reason: this.state.cancel_reason,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "Trip cancelled successfully",
              },
              () => {
                this.toggleCancel();
                this.show();
              }
            );
            setTimeout(() => this.props.onTitleChange(), 2000);
          } else {
            this.setState({
              success: false,
              message: "Trip cancellation failed",
            });
          }
          // console.log("herererer");
          // console.log(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  handleOtpChange(e) {
    // e.persist();
    // console.log(e.target.name);
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      const target = e.target;
      const value = target.value;
      // const name = target.name;
      this.setState({
        otpNumber: value,
      });
    }
    // console.log(e)
  }
  reAssign() {
    let ApiCall;
    // console.log(this.state.tripdata)
    if (
      this.state.tripdata.trip_status === "Assigned" ||
      this.state.tripdata.trip_status === "Confirmed"
    ) {
      ApiCall = "cancelAssignedRide";
    } else {
      ApiCall = "cancelLeaveForPickUpRide";
    }

    fetch(config.url + `htmlcrm/${ApiCall}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // trip_id: this.props.tripId
        // trip_id: '0K918TQC60KQcwth'
        trip_id: this.props.tripId,
        trip_type: this.props.rideType,
        user_id: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          if (result.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "Driver has been removed from the ride",
              },
              () => {
                this.show();
              }
            );
            setTimeout(() => this.props.onTitleChange(), 2000);
          } else {
            this.setState(
              {
                success: false,
                message: "Unable to re-assign the ride",
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  gencancelOTP() {
    // console.log(this.props);
    fetch(config.url + "htmlcrm/generateApprovalOTP", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // trip_id: this.props.tripId
        // trip_id: '0K918TQC60KQcwth'
        otpType: "cancellation",
        executive_id: window.sessionStorage.getItem("user_id"),
        trip_id: this.props.tripId,
        rideType: this.props.rideType,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          if (result.message === "successfull") {
            this.setState(
              {
                success: true,
                otpGen: true,
                message: "OTP has been generated",
                otpVerCode: result.otp_verfication_code,
                otp: result.otp_number,
              },
              () => {
                this.show();
              }
            );
            setTimeout(() => {
              this.setState({ otpGen: "" });
            }, 2000);
          } else {
            this.setState(
              {
                success: false,
                otpGen: false,
                message: "Failed to generate OTP",
                otpVerCode: result.otp_verfication_code,
                otp: result.otp_number,
              },
              () => {
                this.show();
              }
            );
            setTimeout(() => {
              this.setState({ otpGen: "" });
            }, 2000);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  verifycancelOTP() {
    if (
      (this.state.cancel_reason === undefined ||
        this.state.cancel_reason === " ") &&
      this.state.dnoshow === false &&
      this.state.cnoshow === false &&
      this.state.flightDelay === false &&
      this.state.breakdown === false
    ) {
      this.setState(
        {
          success: false,
          message: "Please provide a reason for cancellation",
        },
        () => {
          this.show();
        }
      );
    } else {
      fetch(config.url + "htmlcrm/verifyApprovalOTP", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          // trip_id: this.props.tripId
          // trip_id: '0K918TQC60KQcwth'

          executive_id: window.sessionStorage.getItem("user_id"),
          otp_number: this.state.otpNumber,
          otp_verification_code: this.state.otpVerCode,
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log(result);
            if (result.message === "successfull") {
              this.setState(
                {
                  success: true,
                  message: "Entered OTP is valid",
                },
                () => {
                  this.cancelRide();
                  this.show();
                }
              );
            } else {
              this.setState(
                {
                  success: false,
                  message: "Entered OTP is invalid",
                },
                () => {
                  this.show();
                }
              );
            }
            // this.setState({
            //     driverLocationData: result
            // })
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    }
    // console.log(this.state.otpVerCode);
  }
  handleOnChange(event) {
    // console.log(event)
    // console.log(event.target.value)
    // console.log(event.target.id);
    if (event.target.id === "offeredAmount") {
      // console.log("here")
      this.setState({
        offeredAmount: event.target.value,
      });
    } else if (event.target.id === "extra_traveled_kms") {
      // console.log("here")
      this.setState({
        extra_traveled_kms: event.target.value,
      });
    } else if (event.target.id === "empty_kms") {
      // console.log("here")
      this.setState({
        empty_kms: event.target.value,
      });
    } else if (event.target.id === "fineAmount") {
      // console.log("here")
      this.setState({
        fineAmount: event.target.value,
      });
    } else if (event.target.id === "fineReason") {
      // console.log("here")
      this.setState({
        fineReason: event.target.value,
      });
    } else if (event.target.id === "issueTitle") {
      this.setState({
        issueTitle: event.target.value,
      });
    } else if (event.target.id === "issueDescription") {
      this.setState({
        issueDescription: event.target.value,
      });
    } else if (event.target.id === "replyDescription") {
      this.setState({
        replyDescription: event.target.value,
      });
    }
  }
  handleChange(e, data) {
    const newState = {};
    newState[data] = !this.state[data];
    this.setState({ ...this.state, ...newState });
  }
  toggleReassign() {
    this.setState({
      reassignOpen: !this.state.reassignOpen,
    });
  }
  toggleDetach() {
    this.setState({
      detachOpen: !this.state.detachOpen,
    });
  }
  toggleCancel() {
    this.setState({
      cancelOpen: !this.state.cancelOpen,
    });
  }
  fetchDriverLocation() {
    fetch(config.url + "htmlcrm/getDriverLocation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        trip_id: this.props.tripId,
        // trip_id: 'zNgXI0v9IeRWgUQf'
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          this.setState({
            driverLocationData: result,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  getRideDetails() {
    fetch(config.url + "booking/getRideDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        trip_id: this.props.tripId,
        // trip_id: 'zNgXI0v9IeRWgUQf'
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          this.setState(
            {
              tripdata: result,
            },
            () => {
              this.fetchDriverLocation();
            }
          );
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  getReports() {
    fetch(config.url + "htmlcrm/getAllReportedIssues", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        trip_id: this.props.tripId,
        // trip_id: 'zNgXI0v9IeRWgUQf'
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          this.setState({
            reviewList: result.results,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  componentDidMount(props) {
    // console.log(this.props);
    // this.getRideDetails();
    // this.fetchDriverLocation();
    // this.getReports();
    // if (
    //   this.props.requestStatus === "ended" ||
    //   this.props.requestStatus === "consumercancelled" ||
    //   this.props.requestStatus === "drivercancelled"
    // ) {
    //   console.log("*******************yooooo**********************");
    //   console.log(this.props.requestStatus);
    //   this.getTripStatusLocations();
    // } else {
    //   this.timerID = setInterval(() => this.getRideDetails(), 20000);
    // }
  }

  componentWillUnmount() {
    // console.log("Interval cleared");
    clearInterval(this.timerID);
  }
  componentDidUpdate(prevProps) {
    // if (!equal(this.props.tripId, prevProps.tripId)) {
    //   // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
    //   // this.updateUser();
    //   fetch(config.url + "booking/getRideDetails", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       // 'Content-Type': 'application/x-www-form-urlencoded',
    //     },
    //     body: JSON.stringify({
    //       trip_id: this.props.tripId,
    //       // trip_id: 'zNgXI0v9IeRWgUQf'
    //     }),
    //   })
    //     .then((res) => res.json())
    //     .then(
    //       (result) => {
    //         // console.log("herererer");
    //         // console.log(result);
    //         this.setState(
    //           {
    //             tripdata: result,
    //           },
    //           () => {
    //             this.fetchDriverLocation();
    //           }
    //         );
    //       },
    //       // Note: it's important to handle errors here
    //       // instead of a catch() block so that we don't swallow
    //       // exceptions from actual bugs in components.
    //       (error) => {}
    //     );
    // }
  }
  render() {
    const columns = [
      {
        Header: "Sl no",
        id: "serialno",
        style: {},
        Cell: (row) => {
          // console.log(row);
          return <div>{row.viewIndex + 1}</div>;
        },
      },
      {
        Header: "Issue ID",
        accessor: "issue_id",
      },
      {
        Header: "Title",
        accessor: "title",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Reported by",
        accessor: "username",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Received on",
        id: "statusrevise",
        style: { whiteSpace: "unset" },
        Cell: (row) => {
          // console.log(row);
          let date = moment(new Date(row.original.statusrevise)).format(
            "DD-MM-YY [at] hh:mm:ss A"
          );
          return <div>{date}</div>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        id: "ridetype",
        Cell: (row) => {
          let data = capitalizeFirstLetter(row.original.status);
          return <div>{data}</div>;
        },
      },
      {
        id: "edit",
        Header: "Actions",
        Cell: (row) => {
          //  console.log(this);
          // console.log(row.original.request_status)
          return (
            <div className="d-flex flex-column">
              {row.original.request_status === "Requested" && (
                <Button
                  className="mx-2 my-2  table-button-style"
                  onClick={() => {
                    this.viewClick(row.original);
                  }}
                >
                  CLOSE
                </Button>
              )}
              <Button
                className="mx-2 my-2  table-button-style"
                onClick={() => {
                  this.handleClick(row.original);
                }}
              >
                VIEW
              </Button>
            </div>
          );
        },
      },
    ];
    //Whenever our class runs, render method will be called automatically, it may have already defined in the constructor behind the scene.
    // console.log("**************************************************");
    // console.log(this.state.driverLocationData["crn_number"]);
    return (
      <React.Fragment>
        {this.state.tripDump === false && this.state.rideIssue !== true && (
          <Row className="px-md-2 mb-5">
            <Col className="col-md-12 col-12">
              <Card className="my-4 mx-1 px-md-4 px-lg-4 px-xl-4">
                <CardHeader className="my-4 text-center text-uppercase font-weight-bold">
                  <h5>
                    Ride Details
                    <button
                      type="button"
                      className="close float-right ml-auto mr-3"
                      aria-label="Close"
                      onClick={this.props.onTitleChange}
                    >
                      <span aria-hidden="true">
                        <i class="fas fa-times" />
                      </span>
                    </button>
                  </h5>
                </CardHeader>
                <CardBody className="px-2">
                  <Row>
                    <Col className="col-12 col-md-12 mb-4">
                      <div className="float-right mx-3">
                        <Button className="" onClick={this.enableTripDump}>
                          <span
                            style={{ fontSize: "0.8rem", fontWeight: "700" }}
                          >
                            VIEW TRIP LOGS
                          </span>
                        </Button>
                      </div>
                      Auto assign status:
                      {this.state.tripdata.autoassign_status ===
                        "In progress" && (
                        <label className="mx-2" style={{ color: "#FFD800" }}>
                          {" " + this.state.tripdata.autoassign_status}
                        </label>
                      )}
                      {this.state.tripdata.autoassign_status ===
                        "Not started" && (
                        <label className="mx-2" style={{ color: "#FF0000" }}>
                          {" " + this.state.tripdata.autoassign_status}
                        </label>
                      )}
                      {this.state.tripdata.autoassign_status ===
                        "deactivated" && (
                        <label className="mx-2" style={{ color: "#FF0000" }}>
                          {" " +
                            this.capitalizeFirstLetter(
                              this.state.tripdata.autoassign_status
                            )}
                        </label>
                      )}
                      {this.state.tripdata.autoassign_status === "Started" && (
                        <label className="mx-2" style={{ color: "#59E67F" }}>
                          {" " + this.state.tripdata.autoassign_status}
                        </label>
                      )}
                    </Col>

                    {this.state.tripdata.trip_status === "Requested" && (
                      <Col className="col-12 col-md-12 d-none">
                        <Button
                          onClick={this.callAutoAssign}
                          className="float-right mb-3 py-3"
                          theme="dark"
                          disabled
                        >
                          TRIGGER AUTO ASSIGN
                        </Button>
                      </Col>
                    )}
                    <Col className="col-12 col-md-12 d-none">
                      <a
                        href={`/autoassignlogs/${this.state.tripdata.trip_id}`}
                        target="_blank"
                      >
                        <Button className="float-right py-3" theme="dark">
                          VIEW LOGS
                        </Button>
                      </a>
                    </Col>
                  </Row>
                  <Row>
                    {/* {console.log(this.state.tripdata)} */}
                    {this.props.TripData.map((stats, idx) => (
                      <Col
                        className="col-12 col-sm-6 col-md-4 mb-4 "
                        key={idx}
                        {...stats.attrs}
                      >
                        {/* {console.log(this.state.tripdata.verification_code)} */}
                        <InputData
                          id={`small-stats-${idx}`}
                          variation="1"
                          label={stats.label}
                          value={this.state.tripdata[stats.apiID]}
                          stats={stats.stats}
                        />
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    {this.props.TripStatusData.map((stats, idx) => (
                      <Col
                        className="col-12 col-md-3 mb-4 "
                        key={idx}
                        {...stats.attrs}
                      >
                        {/* {console.log(this.state.tripdata.verification_code)} */}
                        <InputData
                          id={`small-stats-${idx}`}
                          variation="1"
                          label={stats.label}
                          value={this.state.tripdata[stats.apiID]}
                          stats={stats.stats}
                        />
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    {this.props.TripLocationData.map((stats, idx) => (
                      <Col
                        className="col-12 col-md-6 mb-4 "
                        key={idx}
                        {...stats.attrs}
                      >
                        {/* {console.log(this.state.tripdata.verification_code)} */}
                        <InputData
                          id={`small-stats-${idx}`}
                          variation="1"
                          label={stats.label}
                          value={this.state.tripdata[stats.apiID]}
                          stats={stats.stats}
                        />
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    <Col className="col-12 col-md-6 mt-4">
                      <StepQuick
                        data={this.sequence.arr}
                        callToggle={this.callToggle}
                      />
                    </Col>
                  </Row>
                  {this.state.tripdata.trip_status === "Requested" && (
                    <div className="mx-auto">
                      <Row id="googlemaps" className=" mx-auto h-25">
                        <Col className="col-12 col-md-6 col-lg-6 col-xl-6 p-0 m-0">
                          {this.props.rideType !== "Rental" && (
                            <PolyLineMap dataProps={this.state.tripdata} />
                          )}
                          {this.props.rideType === "Rental" && (
                            <SimpleMap dataProps={this.state.tripdata} />
                          )}
                        </Col>
                        <Col className="col-12 col-md-4 col-xl-4 col-lg-4 p-0 p-md-5">
                          {this.props.FareData.map((stats, idx) => (
                            <Col
                              className="mx-md-2 my-4 p-0"
                              key={idx}
                              {...stats.attrs}
                            >
                              {/* {console.log(this.state.tripdata.verification_code)} */}
                              <InputData
                                id={`small-stats-${idx}`}
                                variation="1"
                                label={stats.label}
                                value={this.state.tripdata[stats.apiID]}
                                stats={stats.stats}
                              />
                            </Col>
                          ))}
                        </Col>
                      </Row>
                      <Row>
                        {this.props.VehicleData.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-6 col-lg-6 col-xl-6 my-4"
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.tripdata.verification_code)} */}
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.tripdata[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        {this.props.RiderData.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-4 col-lg-4 col-xl-4 my-4"
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.tripdata.verification_code)} */}
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.tripdata[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )}
                  {(this.state.tripdata.trip_status === "Assigned" ||
                    this.state.tripdata.trip_status === "Accepted" ||
                    this.state.tripdata.trip_status === "Notified" ||
                    this.state.tripdata.trip_status === "Started") && (
                    <div>
                      <Row
                        id="googlemapslive"
                        className="w-100 h-25 mx-auto"
                        style={{
                          position: "relative",
                          width: "100%",
                          height: 100,
                        }}
                      >
                        <Col className="col-12 col-md-8 col-lg-8 col-xl-8 p-0 m-0 mb-4">
                          <LiveMap
                            dataProps={this.state.tripdata}
                            liveProps={this.state.driverLocationData}
                          />
                        </Col>
                        {(this.state.driverLocationData !== undefined ||
                          this.state.driverLocationData !== null ||
                          this.state.driverLocationData.length > 0) &&
                          this.state.tripdata.trip_status !== "Assigned" && (
                            <Col
                              className="col-12 col-md-4 col-lg-4 col-xl-4 p-0 m-0 mb-md-5"
                              style={{ maxWidth: "350px" }}
                            >
                              <Card>
                                <CardHeader
                                  className="d-flex justify-content-center"
                                  style={{
                                    borderBottom: "solid 0.5px #525151",
                                  }}
                                >
                                  <div>Trip validations</div>
                                </CardHeader>
                                <CardBody className="d-flex justify-content-center">
                                  <StepperPoll
                                    dlocProps={this.state.driverLocationData}
                                  />
                                </CardBody>
                              </Card>
                            </Col>
                          )}
                        <Col className="col-12 col-md-12 col-xl-12 col-lg-12 p-0 px-1">
                          <Row>
                            {this.props.FareData.map((stats, idx) => (
                              <Col
                                className="mx-md-2 my-4 p-0"
                                key={idx}
                                {...stats.attrs}
                              >
                                {/* {console.log(this.state.tripdata.verification_code)} */}
                                <InputData
                                  id={`small-stats-${idx}`}
                                  variation="1"
                                  label={stats.label}
                                  value={this.state.tripdata[stats.apiID]}
                                  stats={stats.stats}
                                />
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        {this.props.DriverLocation.map((stats, idx) => (
                          <Col
                            className="col-md-3 mb-4 "
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.tripdata.verification_code)} */}
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.driverLocationData[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        {this.props.RiderData.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-4 col-lg-4 col-xl-4 my-4"
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.tripdata.verification_code)} */}
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.tripdata[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )}
                  {(this.state.tripdata.trip_status === "Ended" ||
                    this.state.tripdata.trip_status === "Completed" ||
                    this.state.tripdata.trip_status === "Consumercancelled" ||
                    this.state.tripdata.trip_status === "Drivercancelled") && (
                    <div className="mx-auto">
                      <Row
                        id="googlemaps"
                        className="h-25"
                        style={{
                          position: "relative",
                          width: "100%",
                          height: 100,
                        }}
                      >
                        <Col className="col-12 col-md-8 col-lg-8 col-xl-8 p-0 pl-3 m-0 mb-md-5">
                          <PolyLineMap dataProps={this.state.tripdata} />
                        </Col>
                        <Col
                          className="col-12 col-md-4 col-lg-4 col-xl-4 p-0 pl-1 m-0 mb-md-5"
                          style={{ maxWidth: "350px" }}
                        >
                          <Card>
                            <CardHeader
                              className="d-flex justify-content-center"
                              style={{ borderBottom: "solid 0.5px #525151" }}
                            >
                              <div>Trip validations</div>
                            </CardHeader>
                            {this.state.tripdata.trip_status ===
                              "Drivercancelled" ||
                            this.state.tripdata.trip_status ===
                              "Consumercancelled" ? (
                              <CardBody className="d-flex justify-content-center">
                                <StepperCancel dlocProps={this.props.tripId} />
                              </CardBody>
                            ) : (
                              <CardBody className="d-flex justify-content-center">
                                <Stepper dlocProps={this.props.tripId} />
                              </CardBody>
                            )}
                          </Card>
                        </Col>
                        <Col className="col-12 col-md-12 col-lg-12 col-xl-12 p-0 m-0">
                          <Row className="px-2">
                            {this.props.FareData.map((stats, idx) => (
                              <Col
                                className="mx-md-2 my-4 p-0"
                                key={idx}
                                {...stats.attrs}
                              >
                                {/* {console.log(this.state.tripdata.verification_code)} */}
                                <InputData
                                  id={`small-stats-${idx}`}
                                  variation="1"
                                  label={stats.label}
                                  value={this.state.tripdata[stats.apiID]}
                                  stats={stats.stats}
                                />
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        {this.props.VehicleData.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-6 col-lg-6 col-xl-6 my-4"
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.tripdata.verification_code)} */}
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.tripdata[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))}
                        {this.props.DriverLocation.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-6 col-lg-6 col-xl-6 my-4 "
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.tripdata.verification_code)} */}
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.driverLocationData[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        {this.props.RiderData.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-4 col-lg-4 col-xl-4 my-4"
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.tripdata.verification_code)} */}
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.tripdata[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )}

                  {this.state.tripdata.trip_status !== "Requested" &&
                    this.state.tripdata.trip_status !== "Consumercancelled" &&
                    this.state.tripdata.trip_status !== "Drivercancelled" && (
                      <div className="row">
                        {(this.state.toll_ticket !== undefined ||
                          this.state.toll_ticket !== "" ||
                          this.state.toll_ticket !== null) && (
                          <div className="col-12 col-md-3 mx-auto">
                            <label>Toll receipt</label>
                            {(this.state.toll_ticket === "" ||
                              this.state.toll_ticket === null ||
                              this.state.toll_ticket === undefined ||
                              this.state.toll_ticket === " ") && (
                              <div
                                className="mt-3"
                                style={{
                                  width: "250px",
                                  height: "400px",
                                  border: "1px solid #C1C1C1",
                                  backgroundImage: `url("https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/placeholder.svg")`,
                                  borderRadius: "8px",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "50% 50%",
                                  backgroundSize: "300px 100px",
                                }}
                              ></div>
                            )}
                            {this.state.toll_ticket &&
                              this.state.toll_ticket.length > 0 && (
                                <div
                                  className="mt-3"
                                  style={{
                                    width: "250px",
                                    height: "400px",
                                    border: "1px solid #C1C1C1",
                                    backgroundImage: `url(${this.state.toll_ticket})`,
                                    backgroundSize: "240px 390px",
                                    borderRadius: "8px",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "50% 50%",
                                  }}
                                ></div>
                              )}
                          </div>
                        )}

                        <div className="col-12 col-md-9 colmt-3 px-md-5 w-100 mt-5">
                          <Row className="mb-3">
                            <Button
                              className="mx-auto mx-md-3 mx-xl-3 my-auto py-3 "
                              onClick={this.handleButtonClick}
                            >
                              {this.state.buttonName}
                            </Button>
                          </Row>

                          <Row>
                            {/* {this.props.ExtraAmount.map((stats, idx) => ( */}
                            <Col className="col-6 col-md-3 mb-4 ">
                              <label>Offered amount</label>
                              <FormInput
                                id="offeredAmount"
                                readOnly={this.state.editable}
                                type="text"
                                placeholder={this.state.tripdata.offeredAmount}
                                onChange={this.handleOnChange}
                                value={this.state.offeredAmount}
                              />
                            </Col>
                            <Col className="col-6 col-md-3 mb-4 ">
                              <label>Extra travelled kms</label>
                              <FormInput
                                id="extra_traveled_kms"
                                readOnly={this.state.editable}
                                type="text"
                                placeholder={
                                  this.state.tripdata.extra_traveled_kms
                                }
                                onChange={this.handleOnChange}
                                value={this.state.extra_traveled_kms}
                              />
                            </Col>
                            <Col className="col-6 col-md-3 mb-4 ">
                              <label>Empty kms</label>
                              <FormInput
                                id="empty_kms"
                                readOnly={this.state.editable}
                                type="text"
                                placeholder={this.state.tripdata.empty_kms}
                                onChange={this.handleOnChange}
                                value={this.state.empty_kms}
                              />
                            </Col>
                            <Col className="col-6 col-md-3 mb-4 ">
                              <label>Fine amount</label>
                              <FormInput
                                id="fineAmount"
                                readOnly={this.state.editable}
                                type="text"
                                placeholder={this.state.tripdata.fineAmount}
                                onChange={this.handleOnChange}
                                value={this.state.fineAmount}
                              />
                            </Col>
                            <Col className="col-6 col-md-3 mb-4 ">
                              <label>Fine reason</label>
                              <FormTextarea
                                id="fineReason"
                                readOnly={this.state.editable}
                                type="text"
                                placeholder={this.state.tripdata.fineReason}
                                onChange={this.handleOnChange}
                                value={this.state.fineReason}
                              />
                            </Col>
                            {/* ))} */}
                          </Row>
                        </div>
                      </div>
                    )}

                  {this.state.tripdata.trip_status !== "Requested" && (
                    <React.Fragment>
                      {this.state.switchDiv === false && (
                        <div className="mt-3">
                          <label>
                            {" "}
                            <b>Ride issues</b>
                          </label>
                          {this.state.reviewList !== undefined &&
                            this.state.reviewList.length > 0 && (
                              <Row className="mb-3">
                                <Card className="w-100">
                                  <CardBody>
                                    <ReactTable
                                      data={this.state.reviewList}
                                      noDataText="No data to display"
                                      columns={columns}
                                      className="text-center -striped -highlight"
                                      minRows={
                                        this.state.reviewList !== undefined
                                          ? 0
                                          : 4
                                      }
                                    />
                                  </CardBody>
                                </Card>
                              </Row>
                            )}

                          <Row className="mb-3 mt-5 d-flex justify-content-center">
                            <Col className="col-md-3"></Col>
                            <Col className="col-12 col-md-6">
                              <label>Report a new issue</label>
                              <FormInput
                                className="w-100 mb-3"
                                id="issueTitle"
                                type="text"
                                placeholder="Title"
                                onChange={this.handleOnChange}
                                value={this.state.issueTitle}
                              />
                            </Col>
                            <Col className="col-md-3"></Col>
                            <Col className="col-12 col-md-6">
                              <FormTextarea
                                id="issueDescription"
                                type="text"
                                rows="4"
                                placeholder="Describe the issue"
                                onChange={this.handleOnChange}
                                value={this.state.issueDescription}
                              />
                            </Col>
                            <Col className="col-12 d-flex justify-content-center mt-3">
                              <Button
                                className="button-width-style"
                                onClick={this.submitIssue}
                              >
                                SUBMIT
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      )}
                      {/* {this.state.switchDiv === true && (
                        <div className="mt-3">
                          <label>
                            {" "}
                            <b>Ride issues</b>
                          </label>
                          <div>
                            <Button
                              onClick={this.closeReply}
                              style={{ float: "right" }}
                            >
                              CLOSE
                            </Button>
                          </div>
                          <div className="mt-3">
                            <Button
                              onClick={this.refreshList}
                              style={{ float: "left" }}
                            >
                              <i className="fas fa-sync-alt" />
                            </Button>
                          </div>
                          <Row className="mb-3 d-flex justify-content-center">
                            <Col className="col-md-8 mb-3">
                              <h6>
                                {" "}
                                <u>
                                  {" "}
                                  {this.state.convoDetails.title +
                                    "(#" +
                                    this.state.convoDetails.issue_id +
                                    ", #" +
                                    this.state.convoDetails.status +
                                    ")"}
                                </u>
                              </h6>
                            </Col>
                            <Col className="col-md-8">
                              {this.state.convoDetails.chatsession !==
                                undefined && (
                                <React.Fragment>
                                  <Row className="mb-3">
                                    {this.state.convoDetails.chatsession.map(
                                      (data, index) => (
                                        <Col className="col-md-12" key={index}>
                                          {data.userid ===
                                           window.sessionStorage.getItem(
                                              "user_id"
                                            ) && (
                                            <Row className="mx-auto">
                                              <Col
                                                className="col-md-6 mb-2 mt-2 d-flex justify-content-start"
                                                style={{
                                                  borderBottom:
                                                    "thin solid #e8eaf6",
                                                }}
                                              >
                                                <strong>{"->  "}</strong>
                                                <label
                                                  style={{
                                                    fontWeight: "800",
                                                    fontSize: "1rem",
                                                  }}
                                                >
                                                  {data.username}
                                                </label>
                                              </Col>
                                              <Col className="col-md-2"></Col>
                                              <Col className="col-md-4 d-flex justify-content-end">
                                                <label
                                                  style={{ fontSize: "0.7rem" }}
                                                >
                                                  {moment(
                                                    new Date(data.milliseconds)
                                                  ).format(
                                                    "DD-MM-YY [at] hh:mm:ss A"
                                                  )}
                                                </label>
                                              </Col>
                                            </Row>
                                          )}
                                          {data.userid !==
                                           window.sessionStorage.getItem(
                                              "user_id"
                                            ) && (
                                            <Row className="mx-auto">
                                              <Col className="col-md-4 d-flex justify-content-start">
                                                <label
                                                  style={{ fontSize: "0.7rem" }}
                                                >
                                                  {moment(
                                                    new Date(data.milliseconds)
                                                  ).format(
                                                    "DD-MM-YY [at] hh:mm:ss A"
                                                  )}
                                                </label>
                                              </Col>

                                              <Col className="col-md-2"></Col>
                                              <Col
                                                className="col-md-6 mb-2 mt-2 d-flex justify-content-end"
                                                style={{
                                                  borderBottom:
                                                    "thin solid #e8eaf6",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    fontWeight: "800",
                                                    color: "#E0CA3C",
                                                    fontSize: "1rem",
                                                  }}
                                                >
                                                  {data.username}
                                                </label>
                                                <strong>{"  <-"}</strong>
                                              </Col>
                                            </Row>
                                          )}
                                          <Row
                                            className="mx-auto mb-2"
                                            style={{
                                              borderBottom:
                                                "thin solid #e8eaf6",
                                            }}
                                          >
                                            {data.userid !==
                                             window.sessionStorage.getItem(
                                                "user_id"
                                              ) && (
                                              <Col className="col-md-12 mb-2 d-flex justify-content-end">
                                                <label>{data.message}</label>
                                              </Col>
                                            )}
                                            {data.userid ===
                                             window.sessionStorage.getItem(
                                                "user_id"
                                              ) && (
                                              <Col className="col-md-12 mb-2 d-flex justify-content-start">
                                                <label>{data.message}</label>
                                              </Col>
                                            )}
                                          </Row>
                                        </Col>
                                      )
                                    )}
                                  </Row>
                                  <Row className="mx-auto">
                                    <Col className="col-12 mb-3">Reply</Col>
                                    <Col className="col-12">
                                      <FormTextarea
                                        id="replyDescription"
                                        type="text"
                                        rows="4"
                                        placeholder=""
                                        onChange={this.handleOnChange}
                                        value={this.state.replyDescription}
                                      />
                                    </Col>
                                    <Col className="col-12 d-flex justify-content-center mt-3">
                                      <Button
                                        className="button-width-style"
                                        onClick={this.submitReply}
                                      >
                                        SUBMIT
                                      </Button>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </Col>
                          </Row>
                        </div>
                      )} */}
                    </React.Fragment>
                  )}
                </CardBody>
                <hr />
                {this.state.tripdata.trip_status !== "Requested" && (
                  <Row className="mb-5 mt-5 d-flex justify-content-center">
                    <Col className="col-12 col-md-6 col-lg-6 col-xl-6 ">
                      <Card className="px-3">
                        <CardBody className="">
                          <Row className="mx-md-auto">
                            <Col className="col-1" />
                            <Col className="col-4 col-md-4 col-lg-4 col-xl-4 py-1">
                              <img
                                className="rounded-circle img-thumbnail"
                                style={{ width: "5rem", height: "5rem" }}
                                alt="driver profile"
                                src={
                                  this.state.tripdata.profile_pic === undefined
                                    ? "https://panel.hornok.in/static/images/avatars/man.png"
                                    : this.state.tripdata.profile_pic
                                }
                              />
                            </Col>
                            <Col className="col-6 col-md-6 col-lg-6 col-xl-6 py-2">
                              <div className="font-weight-bold">
                                {this.state.tripdata.driver_name}
                              </div>
                              <div className="">
                                {this.state.tripdata.vehicle_type +
                                  " - " +
                                  this.state.tripdata.vehiclenumber}
                              </div>
                              <div className="">
                                {this.state.tripdata.driver_phone}
                              </div>
                            </Col>
                          </Row>
                          {(this.state.tripdata.trip_status === "Assigned" ||
                            this.state.tripdata.trip_status === "Accepted" ||
                            this.state.tripdata.trip_status === "Notified") && (
                            <Row className="mt-4 d-flex justify-content-center">
                              <Col className="col-12 col-md-5 col-lg-5 mt-2 d-flex justify-content-center">
                                <Button
                                  className="py-md-3 button-width-style"
                                  onClick={this.toggleReassign}
                                >
                                  RE-ASSIGN RIDE
                                </Button>
                                <Modal
                                  open={this.state.reassignOpen}
                                  toggle={this.toggleReassign}
                                  centered
                                  modalClassName="modal-overflow-control"
                                >
                                  <ModalHeader closeAriaLabel="close">
                                    <div>Reassign confirmation</div>
                                  </ModalHeader>
                                  <ModalBody>
                                    <span className="question-text-style">
                                      Do you want to reassign the ride ?
                                    </span>

                                    <Row className="mt-3">
                                      {this.props.CancelModData.map(
                                        (stats, idx) => (
                                          <Col
                                            className="col-md-6 col-6 mb-4 "
                                            key={idx}
                                            {...stats.attrs}
                                          >
                                            {/* {console.log(data[stats.apiID])} */}
                                            <InputData
                                              id={`small-stats-${idx}`}
                                              variation="1"
                                              label={stats.label}
                                              value={
                                                this.state.tripdata[stats.apiID]
                                              }
                                              stats={stats.stats}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                    <Row>
                                      {this.props.SorDesData.map(
                                        (stats, idx) => (
                                          <Col
                                            className="col-md-6 col-12 mb-4 "
                                            key={idx}
                                            {...stats.attrs}
                                          >
                                            {/* {console.log(data[stats.apiID])} */}
                                            <InputData
                                              id={`small-stats-${idx}`}
                                              variation="1"
                                              label={stats.label}
                                              value={
                                                this.state.tripdata[stats.apiID]
                                              }
                                              stats={stats.stats}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                    <Row>
                                      {this.props.DriverData.map(
                                        (stats, idx) => (
                                          <Col
                                            className="col-md-6 mb-4 "
                                            key={idx}
                                            {...stats.attrs}
                                          >
                                            {/* {console.log(data[stats.apiID])} */}
                                            <InputData
                                              id={`small-stats-${idx}`}
                                              variation="1"
                                              label={stats.label}
                                              value={
                                                this.state.tripdata[stats.apiID]
                                              }
                                              stats={stats.stats}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  </ModalBody>
                                  <ModalFooter>
                                    <Row>
                                      <Button
                                        theme="warning"
                                        className="mx-2 "
                                        onClick={this.reAssign}
                                      >
                                        REASSIGN
                                      </Button>
                                      <Button
                                        theme="danger"
                                        className="mx-2"
                                        onClick={this.toggleReassign}
                                      >
                                        CLOSE
                                      </Button>
                                    </Row>
                                  </ModalFooter>
                                </Modal>
                              </Col>
                              <Col className="d-none d-sm-none d-md-block col-md-2 col-lg-2" />
                              <Col className="col-12 col-md-5 col-lg-5 mt-2 d-flex justify-content-center">
                                <Button
                                  theme="danger"
                                  className="py-md-3 button-width-style"
                                  onClick={this.toggleCancel}
                                  style={{ backgroundColor: "#FC2727" }}
                                >
                                  CANCEL RIDE
                                </Button>
                                <Modal
                                  open={this.state.cancelOpen}
                                  toggle={this.toggleCancel}
                                  centered
                                  modalClassName="modal-overflow-control"
                                >
                                  <ModalHeader closeAriaLabel="close">
                                    <div>Cancel confirmation</div>
                                  </ModalHeader>
                                  <ModalBody>
                                    <span className="question-text-style">
                                      Do you want to cancel the ride ?
                                    </span>
                                    <Row className="mt-3">
                                      {this.props.CancelModData.map(
                                        (stats, idx) => (
                                          <Col
                                            className="col-6 col-md-6 mb-4 "
                                            key={idx}
                                            {...stats.attrs}
                                          >
                                            {/* {console.log(data[stats.apiID])} */}
                                            <InputData
                                              id={`small-stats-${idx}`}
                                              variation="1"
                                              label={stats.label}
                                              value={
                                                this.state.tripdata[stats.apiID]
                                              }
                                              stats={stats.stats}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                    <Row>
                                      {this.props.SorDesData.map(
                                        (stats, idx) => (
                                          <Col
                                            className="col-md-6 mb-4 "
                                            key={idx}
                                            {...stats.attrs}
                                          >
                                            {/* {console.log(data[stats.apiID])} */}
                                            <InputData
                                              id={`small-stats-${idx}`}
                                              variation="1"
                                              label={stats.label}
                                              value={
                                                this.state.tripdata[stats.apiID]
                                              }
                                              stats={stats.stats}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>

                                    <Row>
                                      <Col className="col-6">
                                        <FormCheckbox
                                          checked={this.state.cnoshow}
                                          onChange={(e) =>
                                            this.handleChange(e, "cnoshow")
                                          }
                                        >
                                          Customer no show
                                        </FormCheckbox>
                                      </Col>
                                      <Col className="col-6">
                                        <FormCheckbox
                                          checked={this.state.breakdown}
                                          onChange={(e) =>
                                            this.handleChange(e, "breakdown")
                                          }
                                        >
                                          Vehicle breakdown
                                        </FormCheckbox>
                                      </Col>
                                      <Col className="col-6">
                                        <FormCheckbox
                                          checked={this.state.flightDelay}
                                          onChange={(e) =>
                                            this.handleChange(e, "flightDelay")
                                          }
                                        >
                                          Flight delayed
                                        </FormCheckbox>
                                      </Col>
                                      <Col className="col-6">
                                        <FormCheckbox
                                          checked={this.state.dnoshow}
                                          onChange={(e) =>
                                            this.handleChange(e, "dnoshow")
                                          }
                                        >
                                          Driver no show
                                        </FormCheckbox>
                                      </Col>
                                    </Row>
                                    <Row className="w-100 mx-auto mt-2">
                                      <textarea
                                        className="w-100"
                                        rows="4"
                                        invalid={
                                          this.state.message ===
                                          "Please provide a reason for cancellation"
                                            ? true
                                            : false
                                        }
                                        value={this.state.cancel_reason}
                                        onChange={this.onReasonChange}
                                        placeholder="Please provide a reason for cancellation"
                                      />
                                    </Row>
                                    <Row>
                                      <Button
                                        className="ml-2 mt-4 "
                                        onClick={this.gencancelOTP}
                                      >
                                        GENERATE OTP
                                      </Button>
                                      <div className="ml-md-5 ml-2 mt-4">
                                        <FormInput
                                          size="sm"
                                          id="#otpNumber"
                                          className="text-center"
                                          value={this.state.otpNumber}
                                          onChange={this.handleOtpChange}
                                          type="text"
                                          minLength={6}
                                          maxLength={6}
                                        />
                                      </div>
                                      {this.state.otpGen === true && (
                                        <span
                                          className="ml-2 font-weight-light mt-4"
                                          style={{ color: "#59E67F" }}
                                        >
                                          OTP has been sent
                                        </span>
                                      )}
                                      {this.state.otpGen === false && (
                                        <span
                                          className="ml-2 font-weight-light mt-4"
                                          style={{ color: "#FF0000" }}
                                        >
                                          Failed to send OTP
                                        </span>
                                      )}
                                      <span className="ml-2 my-2 font-weight-light">
                                        *This OTP is valid for the next 15
                                        minutes
                                      </span>
                                    </Row>
                                    <Row>
                                      <Button
                                        theme="danger"
                                        className="ml-2 mt-4 "
                                        onClick={this.verifycancelOTP}
                                      >
                                        Verify OTP & cancel ride
                                      </Button>
                                    </Row>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                {this.state.tripdata.trip_status === "Requested" && (
                  <React.Fragment>
                    <Row className="mb-5 d-flex justify-content-center">
                      <Col className="col-10 col-md-6 col-lg-6 col-xl-6">
                        <Card>
                          <CardBody className="">
                            <Row className="d-flex justify-content-center">
                              <Col className="col-5 col-md-5 col-lg-5 mt-2 d-flex justify-content-center">
                                <Button
                                  className="py-md-3 button-width-style"
                                  onClick={this.handleAssignClick}
                                >
                                  ASSIGN RIDE
                                </Button>
                              </Col>

                              <Col className="col-5 col-md-5 col-lg-5 mt-2 d-flex justify-content-center">
                                <Button
                                  theme="danger"
                                  className="py-md-3 button-width-style"
                                  onClick={this.toggleCancel}
                                  style={{ backgroundColor: "#FC2727" }}
                                >
                                  CANCEL RIDE
                                </Button>
                                <Modal
                                  open={this.state.cancelOpen}
                                  toggle={this.toggleCancel}
                                  centered
                                  modalClassName="modal-overflow-control"
                                >
                                  <ModalHeader closeAriaLabel="close">
                                    <div>Cancel confirmation</div>
                                  </ModalHeader>
                                  <ModalBody>
                                    <span className="question-text-style">
                                      Do you want to cancel the ride ?
                                    </span>
                                    <Row className="mt-3">
                                      {this.props.CancelModData.map(
                                        (stats, idx) => (
                                          <Col
                                            className="col-6 col-md-6 mb-4 "
                                            key={idx}
                                            {...stats.attrs}
                                          >
                                            {/* {console.log(data[stats.apiID])} */}
                                            <InputData
                                              id={`small-stats-${idx}`}
                                              variation="1"
                                              label={stats.label}
                                              value={
                                                this.state.tripdata[stats.apiID]
                                              }
                                              stats={stats.stats}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                    <Row>
                                      {this.props.SorDesData.map(
                                        (stats, idx) => (
                                          <Col
                                            className="col-md-6 mb-4 "
                                            key={idx}
                                            {...stats.attrs}
                                          >
                                            {/* {console.log(data[stats.apiID])} */}
                                            <InputData
                                              id={`small-stats-${idx}`}
                                              variation="1"
                                              label={stats.label}
                                              value={
                                                this.state.tripdata[stats.apiID]
                                              }
                                              stats={stats.stats}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>

                                    <Row>
                                      <Col className="col-6">
                                        <FormCheckbox
                                          checked={this.state.cnoshow}
                                          onChange={(e) =>
                                            this.handleChange(e, "cnoshow")
                                          }
                                        >
                                          Customer no show
                                        </FormCheckbox>
                                      </Col>
                                      <Col className="col-6">
                                        <FormCheckbox
                                          checked={this.state.breakdown}
                                          onChange={(e) =>
                                            this.handleChange(e, "breakdown")
                                          }
                                        >
                                          Vehicle breakdown
                                        </FormCheckbox>
                                      </Col>
                                      <Col className="col-6">
                                        <FormCheckbox
                                          checked={this.state.flightDelay}
                                          onChange={(e) =>
                                            this.handleChange(e, "flightDelay")
                                          }
                                        >
                                          Flight delayed
                                        </FormCheckbox>
                                      </Col>
                                      <Col className="col-6">
                                        <FormCheckbox
                                          checked={this.state.dnoshow}
                                          onChange={(e) =>
                                            this.handleChange(e, "dnoshow")
                                          }
                                        >
                                          Driver no show
                                        </FormCheckbox>
                                      </Col>
                                    </Row>
                                    <Row className="w-100 mx-auto mt-2">
                                      <textarea
                                        className="w-100"
                                        rows="4"
                                        placeholder="Please provide a reason for cancellation"
                                        invalid={
                                          this.state.message ===
                                          "Please provide a reason for cancellation"
                                            ? true
                                            : false
                                        }
                                        value={this.state.cancel_reason}
                                        onChange={this.onReasonChange}
                                      />
                                    </Row>
                                    <Row>
                                      <Button
                                        className="ml-2 mt-4 "
                                        onClick={this.gencancelOTP}
                                      >
                                        GENERATE OTP
                                      </Button>
                                      <div className="ml-md-5 ml-2 mt-4">
                                        <FormInput
                                          size="sm"
                                          id="#otpNumber"
                                          className="text-center"
                                          value={this.state.otpNumber}
                                          onChange={this.handleOtpChange}
                                          type="text"
                                          minLength={6}
                                          maxLength={6}
                                        />
                                      </div>
                                      {this.state.otpGen === true && (
                                        <span
                                          className="ml-2 font-weight-light mt-4"
                                          style={{ color: "#59E67F" }}
                                        >
                                          OTP has been sent
                                        </span>
                                      )}
                                      {this.state.otpGen === false && (
                                        <span
                                          className="ml-2 font-weight-light mt-4"
                                          style={{ color: "#FF0000" }}
                                        >
                                          Failed to send OTP
                                        </span>
                                      )}
                                      <span className="ml-2 font-weight-light">
                                        *This OTP is valid for the next 15
                                        minutes
                                      </span>
                                    </Row>
                                    <Row>
                                      <Button
                                        theme="danger"
                                        className="ml-2 mt-4 "
                                        onClick={this.verifycancelOTP}
                                      >
                                        VERIFY OTP & CANCEL RIDE
                                      </Button>
                                    </Row>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="mx-auto mb-5 w-100 d-flex justify-content-center">
                      <Col className="col-12 col-md-5 col-lg-5 mt-2 d-flex justify-content-center">
                        <Button
                          theme="dark"
                          className="py-md-3 button-width-style"
                          onClick={this.toggleDetach}
                        >
                          DETACH RIDE
                        </Button>
                        <Modal
                          open={this.state.detachOpen}
                          toggle={this.toggleDetach}
                          centered
                          modalClassName="modal-overflow-control"
                        >
                          <ModalHeader closeAriaLabel="close">
                            <div>Detach confirmation</div>
                          </ModalHeader>
                          <ModalBody>
                            <span className="question-text-style">
                              Do you want to detach the ride ?
                            </span>
                            <Row className="mt-3">
                              {this.props.DetachModData.map((stats, idx) => (
                                <Col
                                  className="col-6 col-md-6 mb-4 "
                                  key={idx}
                                  {...stats.attrs}
                                >
                                  {/* {console.log(data[stats.apiID])} */}
                                  <InputData
                                    id={`small-stats-${idx}`}
                                    variation="1"
                                    label={stats.label}
                                    value={this.state.tripdata[stats.apiID]}
                                    stats={stats.stats}
                                  />
                                </Col>
                              ))}
                            </Row>
                            <Row className="w-100 mx-auto mt-2">
                              <textarea
                                className="w-100"
                                rows="4"
                                value={this.cancel_reason}
                                onChange={this.onReasonChange}
                                placeholder="Please provide a reason for detachment"
                              />
                            </Row>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              theme="danger"
                              className="ml-2 mt-4 "
                              onClick={this.toggleDetach}
                            >
                              CANCEL
                            </Button>
                            <Button
                              theme="dark"
                              className="ml-2 mt-4 "
                              onClick={this.DetachRide}
                            >
                              DETACH RIDE
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
                {this.state.tripdata.trip_status !== "Requested" && (
                  <React.Fragment>
                    {this.state.tripdata.timeStamps !== undefined && (
                      <Row>
                        {this.state.tripdata.timeStamps.assigned_by !==
                          undefined && (
                          <Col className="col-12 col-md-3 col-lg-3">
                            <div
                              className="d-flex justify-content-center"
                              style={{
                                fontWeight: "800",
                                fontSize: "1.1rem",
                              }}
                            >
                              <u>Assigned by</u>
                            </div>
                            <div className="d-flex justify-content-center">
                              {this.state.tripdata.timeStamps.assigned_by !==
                                "-" && (
                                <label
                                  style={{
                                    fontWeight: "800",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {" " +
                                    this.state.tripdata.timeStamps.assigned_by}
                                </label>
                              )}
                              {this.state.tripdata.timeStamps.assigned_by ===
                                "-" && <label>-</label>}
                            </div>
                            <div className="d-flex justify-content-center">
                              {this.state.tripdata.timeStamps.assigned_by !==
                                "-" && (
                                <label
                                  style={{
                                    fontWeight: "800",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {" " +
                                    this.state.tripdata.timeStamps
                                      .assigned_time}
                                </label>
                              )}
                              {this.state.tripdata.timeStamps.assigned_time ===
                                "-" && <label>-</label>}
                            </div>
                          </Col>
                        )}
                        {this.state.tripdata.timeStamps.reassigned_by !==
                          "-" && (
                          <Col className="col-12 col-md-3 col-lg-3">
                            <div
                              className="d-flex justify-content-center"
                              style={{
                                fontWeight: "800",
                                fontSize: "1.1rem",
                              }}
                            >
                              <u>Re-assigned by</u>
                            </div>
                            <div className="d-flex justify-content-center">
                              {this.state.tripdata.timeStamps.reassigned_by !==
                                "-" && (
                                <label
                                  style={{
                                    fontWeight: "800",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {" " +
                                    this.state.tripdata.timeStamps
                                      .reassigned_by}
                                </label>
                              )}
                              {this.state.tripdata.timeStamps.reassigned_by ===
                                "-" && <label>-</label>}
                            </div>
                            <div className="d-flex justify-content-center">
                              {this.state.tripdata.timeStamps
                                .reassigned_time !== "-" && (
                                <label
                                  style={{
                                    fontWeight: "800",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {" " +
                                    this.state.tripdata.timeStamps
                                      .reassigned_time}
                                </label>
                              )}
                              {this.state.tripdata.timeStamps
                                .reassigned_time === "-" && <label>-</label>}
                            </div>
                          </Col>
                        )}
                        {this.state.tripdata.timeStamps.cancelled_executive !==
                          "-" && (
                          <Col className="col-12 col-md-3 col-lg-3">
                            <div
                              className="d-flex justify-content-center"
                              style={{
                                fontWeight: "800",
                                fontSize: "1.1rem",
                              }}
                            >
                              <u>Cancelled by</u>
                            </div>
                            <div className="d-flex justify-content-center">
                              {this.state.tripdata.timeStamps
                                .cancelled_executive !== "-" && (
                                <label
                                  style={{
                                    fontWeight: "800",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {" " +
                                    this.state.tripdata.timeStamps
                                      .cancelled_executive}
                                </label>
                              )}
                              {this.state.tripdata.timeStamps
                                .cancelled_executive === "-" && (
                                <label>-</label>
                              )}
                            </div>
                            <div className="d-flex justify-content-center">
                              {this.state.tripdata.timeStamps.cancel_time !==
                                "-" && (
                                <label
                                  style={{
                                    fontWeight: "800",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {" " +
                                    this.state.tripdata.timeStamps.cancel_time}
                                </label>
                              )}
                              {this.state.tripdata.timeStamps.cancel_time ===
                                "-" && <label>-</label>}
                            </div>
                          </Col>
                        )}
                        {this.state.tripdata.timeStamps.rescheduledStatus !==
                          false && (
                          <Col className="col-12 col-md-3 col-lg-3">
                            <div
                              className="d-flex justify-content-center"
                              style={{
                                fontWeight: "800",
                                fontSize: "1.1rem",
                              }}
                            >
                              <u>Previous re-scheduled time</u>
                            </div>
                            <div className="d-flex justify-content-center">
                              {this.state.tripdata.timeStamps
                                .previousScheduledTime !== "-" && (
                                <label
                                  style={{
                                    fontWeight: "800",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {" " +
                                    this.state.tripdata.timeStamps
                                      .previousScheduledTime}
                                </label>
                              )}
                              {this.state.tripdata.timeStamps
                                .previousScheduledTime === "-" && (
                                <label>-</label>
                              )}
                            </div>
                            {/* <div className="d-flex justify-content-center">
                              {this.state.tripdata.timeStamps.cancel_time !==
                                "-" && (
                                <label
                                  style={{
                                    fontWeight: "800",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {" " +
                                    this.state.tripdata.timeStamps.cancel_time}
                                </label>
                              )}
                              {this.state.tripdata.timeStamps.cancel_time ===
                                "-" && <label>-</label>}
                            </div> */}
                          </Col>
                        )}
                      </Row>
                    )}
                  </React.Fragment>
                )}
              </Card>
              {this.state.success === false && (
                <ReactSnackBar
                  Icon={
                    <i
                      className="far fa-times-circle fa-lg"
                      style={{ color: "#FF0000" }}
                    />
                  }
                  Show={this.state.Show}
                >
                  {this.state.message}
                </ReactSnackBar>
              )}
              {this.state.success === true && (
                <ReactSnackBar
                  Icon={
                    <span>
                      <i
                        className="far fa-check-circle fa-lg"
                        style={{ color: "#26FF58" }}
                      />
                    </span>
                  }
                  Show={this.state.Show}
                >
                  {this.state.message}
                </ReactSnackBar>
              )}
            </Col>
          </Row>
        )}
        {this.state.tripDump === true && (
          <TripDump
            tripId={this.props.tripId}
            enableTripDump={this.enableTripDump}
          />
        )}
        {this.state.rideIssue === true && (
          <RideIssues
            data={this.state.passData}
            enableRideIssue={this.enableRideIssue}
          />
        )}
      </React.Fragment>
    );
  }
}

TripDetails.propTypes = {
  TripData: PropTypes.array,
};
TripDetails.defaultProps = {
  MapDefaults: [
    {
      center: {
        lat: 59.95,
        lng: 30.33,
      },
      zoom: 11,
    },
  ],
  TripData: [
    {
      label: "Ride ID",
      value: "",
      attrs: "",
      apiID: "trip_id",
    },
    {
      label: "Vendor ID",
      value: "",
      attrs: "",
      apiID: "vendor_id",
    },
    {
      label: "Region",
      value: "",
      attrs: "",
      apiID: "region",
    },
  ],
  RiderData: [
    {
      label: "Rider name",
      value: "",
      attrs: "",
      apiID: "rider_name",
    },
    {
      label: "Rider mobile number",
      value: "",
      attrs: "",
      apiID: "rider_phone",
    },
    {
      label: "Rider email",
      value: "",
      attrs: "",
      apiID: "email",
    },
  ],
  TripLocationData: [
    {
      label: "Pick-up location",
      value: "",
      attrs: "",
      apiID: "sname",
    },
    {
      label: "Drop-off location",
      value: "",
      attrs: "",
      apiID: "dname",
    },
  ],
  VehicleData: [
    {
      label: "Vehicle type",
      value: "",
      attrs: "",
      apiID: "vehicle_type",
    },
  ],
  TripStatusData: [
    {
      label: "Booking by",
      value: "",
      attrs: "",
      apiID: "booking_by",
    },
    {
      label: "Ride status",
      value: "",
      attrs: "",
      apiID: "trip_status",
    },
    {
      label: "Ride type",
      value: "",
      attrs: "",
      apiID: "ride_type",
    },
    {
      label: "Departure time",
      value: "",
      attrs: "",
      apiID: "pickup_time",
    },
  ],
  FareData: [
    {
      label: "Est. distance(kms)",
      value: "",
      attrs: "",
      apiID: "distance",
    },
    {
      label: "Approx fare",
      value: "",
      attrs: "",
      apiID: "approx_fare",
    },
    {
      label: "Amount to collect",
      value: "",
      attrs: "",
      apiID: "balanceAmount",
    },
  ],
  CancelModData: [
    {
      label: "Trip ID",
      value: "",
      attrs: "",
      apiID: "trip_id",
    },
    {
      label: "Vendor ID",
      value: "",
      attrs: "",
      apiID: "vendor_id",
    },
    {
      label: "Pickup time",
      value: "",
      attrs: "",
      apiID: "pickup_time",
    },
  ],
  DetachModData: [
    {
      label: "Trip ID",
      value: "",
      attrs: "",
      apiID: "trip_id",
    },
  ],
  SorDesData: [
    {
      label: "From",
      value: "",
      attrs: "",
      apiID: "sname",
    },
    {
      label: "To",
      value: "",
      attrs: "",
      apiID: "dname",
    },
  ],
  DriverData: [
    {
      label: "Driver name",
      value: "",
      attrs: "",
      apiID: "driver_name",
    },
    {
      label: "Driver Phonenumber",
      value: "",
      attrs: "",
      apiID: "driver_phone",
    },
  ],
  DriverLocation: [
    {
      label: "Start CRN",
      value: "",
      attrs: "",
      apiID: "crn_number",
    },
    {
      label: "End CRN",
      value: "",
      attrs: "",
      apiID: "endcrn_number",
    },
  ],
  ExtraAmount: [
    {
      label: "Offered amount",
      value: "",
      attrs: "",
      apiID: "offeredAmount",
    },
    {
      label: "Extra travelled kms",
      value: "",
      attrs: "",
      apiID: "extra_traveled_kms",
    },
    {
      label: "Empty kms",
      value: "",
      attrs: "",
      apiID: "empty_kms",
    },
    {
      label: "Fine amount",
      value: "",
      attrs: "",
      apiID: "fineAmount",
    },
  ],
};

export default TripDetails;
