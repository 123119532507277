import React from "react";
import PropTypes from "prop-types";
// import classNames from "classnames";
// import shortid from "shortid";
import { FormInput, FormTextarea, FormSelect } from "shards-react";
import Autocomplete from "react-google-autocomplete";
// import { Link, Route } from "react-router-dom";
// import Rides from "../../views/Rides";
// import DatePicker from "react-date-picker";
import ReactDatePicker from "react-datepicker";

let current_date = new Date();
let year_minus = current_date.getFullYear() - 18;
let cure_date = new Date();
cure_date.setFullYear(year_minus);

function checkplaceholder(name) {
  if (
    name === "phonenumber" ||
    name === "family_phn_1" ||
    name === "family_phn_2" ||
    name === "alt_phonenumber" ||
    name === "searchphonenumber" ||
    name === "login_number"
  ) {
    return "Enter phone number";
  } else if (name === "accaddress") {
    return "Enter address";
  } else if (name === "date_of_birth") {
    return "Set date of birth";
  } else if (name === "vehicle_model") {
    return "Set model year";
  } else if (name === "first_name") {
    return "Enter first name";
  } else if (name === "last_name") {
    return "Enter last name";
  } else if (name === "family_name_1" || name === "family_name_2") {
    return "Enter name";
  } else if (name === "family_rel_1" || name === "family_rel_2") {
    return "Enter relationship";
  } else if (name === "email") {
    return "Enter email ID";
  } else if (name === "preferred_location") {
    return "Set preferred location";
  } else if (name === "starting_point") {
    return "Set starting point";
  } else if (name === "vehicle_name") {
    return "Enter vehicle name";
  } else if (name === "vehicle_number") {
    return "KA-14-P-0030";
  } else if (name === "vehicle_make") {
    return "Enter vehicle make";
  } else if (name === "vehicle_color") {
    return "Enter vehicle color";
  } else if (name === "aadhar_number") {
    return "Enter aadhar number";
  } else if (name === "pan_number") {
    return "Enter PAN number";
  } else if (name === "dl_number") {
    return "Enter DL number";
  } else if (name === "badge_number") {
    return "Enter badge number";
  } else if (name === "acc_holder_name") {
    return "Enter account holder name";
  } else if (name === "bank_name") {
    return "Enter bank name";
  } else if (name === "ifsc") {
    return "Enter IFSC";
  } else if (name === "acc_number") {
    return "Enter account number";
  } else if (name === "vehicle_type") {
    return "Select vehicle type";
  } else if (name === "referralCode") {
    return "Enter referral code";
  }
}

function checkLabel(name, label) {
  console.log(name);
  if (
    name === "phonenumber" ||
    name === "vehicle_name" ||
    name === "vehicle_type" ||
    name === "first_name" ||
    name === "vehicle_model" ||
    name === "vehicle_number" ||
    name === "vehicle_make" ||
    name === "vehicle_color" ||
    name === "aadhar_number" ||
    name === "acc_holder_name" ||
    name === "bank_name" ||
    name === "ifsc" ||
    name === "acc_number" ||
    name === "current_address"
  ) {
    return (
      <div>
        {label} <span style={{ color: "#FF0000" }}>*</span>
      </div>
    );
  } else {
    return <div>{label}</div>;
  }
}

class InputDataForm extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {};

    // this.handleLocationChange = this.handleLocationChange(this);
    this.state = {
      preferred_location: "",
      starting_point: "",
    };
    this.handleChange = this.handleChange.bind(this);
    // console.log("props");
    // console.log(this.props)
  }

  handleLocationChange(e, name) {
    const newState = {};
    newState[name] = e.target.value;
    this.setState({ ...this.state, ...newState });
  }
  handleChange(e, name) {
    // console.log(e)
    this.props.onKeyChange(e, name);
  }
  render() {
    const { label, value, id, name } = this.props;
    // console.log(name);
    if (name === "vehicle_model") {
      return (
        <div>
          <label className="label-style">{checkLabel(name, label)}</label>
          <div>
            <ReactDatePicker
              id={id}
              className="form-control py-3"
              selected={value}
              onChange={(date) => this.handleChange(date, name)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              autoComplete="off"
              maxDate={new Date()}
              readOnly={this.props.readOnly}
              placeholderText={checkplaceholder(name)}
            />
          </div>
        </div>
      );
    } else if (name === "date_time") {
      return (
        <div>
          <label className="label-style">{checkLabel(name, label)}</label>
          <div>
            <ReactDatePicker
              id={id}
              className="form-control py-3"
              selected={new Date()}
              dateFormat="dd/MM/yyyy, h:mm aa"
              showMonthYearPicker
              readOnly={true}
              placeholderText={checkplaceholder(name)}
            />
          </div>
        </div>
      );
    } else if (name === "date_of_birth") {
      return (
        <div>
          <label className="label-style">{checkLabel(name, label)}</label>
          <div>
            <ReactDatePicker
              id={id}
              className="form-control py-3"
              selected={value}
              onChange={(date) => this.handleChange(date, name)}
              dateFormat="dd/MM/yyyy"
              maxDate={cure_date}
              placeholderText={checkplaceholder(name)}
            />
          </div>
        </div>
      );
    } else if (
      name === "family_phn_1" ||
      name === "family_phn_2" ||
      name === "alt_phonenumber"
    ) {
      return (
        <div>
          <label className="label-style">{checkLabel(name, label)}</label>
          <FormInput
            className="py-3"
            readOnly={this.props.readOnly}
            onChange={(e) => this.handleChange(e, name)}
            id={id}
            name={name}
            type="text"
            value={value}
            minLength={10}
            maxLength={10}
            placeholder={checkplaceholder(name)}
          />
        </div>
      );
    } else if (name === "phonenumber" || name === "searchphonenumber") {
      return (
        <div>
          <label className="label-style">{checkLabel(name, label)}</label>
          <FormInput
            className="py-3"
            readOnly={this.props.readOnly}
            onChange={(e) => this.handleChange(e, name)}
            id={id}
            name={name}
            type="text"
            value={value}
            minLength={10}
            maxLength={10}
            required
            placeholder={checkplaceholder(name)}
          />
        </div>
      );
    } else if (name === "aadhar_number") {
      return (
        <div>
          <label className="label-style">{checkLabel(name, label)}</label>
          <FormInput
            className="py-3"
            readOnly={this.props.readOnly}
            onChange={(e) => this.handleChange(e, name)}
            id={id}
            name={name}
            type="text"
            value={value}
            minLength={12}
            maxLength={12}
            required
            placeholder={checkplaceholder(name)}
          />
        </div>
      );
    } else if (name === "pan_number") {
      return (
        <div>
          <label className="label-style">{checkLabel(name, label)}</label>
          <FormInput
            className="py-3"
            readOnly={this.props.readOnly}
            onChange={(e) => this.handleChange(e, name)}
            id={id}
            name={name}
            type="text"
            value={value}
            minLength={10}
            maxLength={10}
            placeholder={checkplaceholder(name)}
          />
        </div>
      );
    } else if (name === "accaddress") {
      return (
        <div>
          <label className="label-style">{checkLabel(name, label)}</label>
          <FormTextarea
            readOnly={this.props.readOnly}
            onChange={(e) => this.handleChange(e, name)}
            id={id}
            name={name}
            type="text"
            placeholder={checkplaceholder(name)}
            value={value}
          />
        </div>
      );
    } else if (name === "first_name") {
      return (
        <div>
          <label className="label-style">{checkLabel(name, label)}</label>
          <FormInput
            className="py-3"
            readOnly={this.props.readOnly}
            onChange={(e) => this.handleChange(e, name)}
            id={id}
            name={name}
            type="text"
            placeholder={checkplaceholder(name)}
            value={value}
            required
          />
        </div>
      );
    } else if (
      name === "vehicle_name" ||
      name === "vehicle_number" ||
      name === "vehicle_make" ||
      name === "vehicle_color"
    ) {
      return (
        <div>
          <label className="label-style">{checkLabel(name, label)}</label>
          <FormInput
            className="py-3"
            readOnly={this.props.readOnly}
            onChange={(e) => this.handleChange(e, name)}
            id={id}
            name={name}
            type="text"
            placeholder={checkplaceholder(name)}
            value={value}
            required
          />
        </div>
      );
    } else if (
      name === "acc_holder_name" ||
      name === "bank_name" ||
      name === "ifsc" ||
      name === "acc_number"
    ) {
      return (
        <div>
          <label className="label-style">{checkLabel(name, label)}</label>
          <FormInput
            className="py-3"
            readOnly={this.props.readOnly}
            onChange={(e) => this.handleChange(e, name)}
            id={id}
            name={name}
            type="text"
            placeholder={checkplaceholder(name)}
            value={value}
            required
          />
        </div>
      );
    } else if (name === "preferred_location" || name === "starting_point") {
      return (
        <div>
          <label className="label-style">{checkLabel(name, label)}</label>
          {/* {console.log(value)} */}
          <Autocomplete
            readOnly={this.props.readOnly}
            className="form-control py-3"
            placeholder={checkplaceholder(name)}
            value={value}
            // style={{ width: "90%" }}
            onPlaceSelected={(place) => {
              this.handleChange(place.formatted_address, name);
            }}
            types={["(regions)"]}
            componentRestrictions={{ country: "in" }}
            onChange={(e) => this.handleChange(e, name)}
          />
        </div>
      );
    } else if (name === "vehicle_type") {
      return (
        <div>
          {/* <label className="label-style">{label}</label> */}
          <FormSelect
            style={{ height: "calc(3.16rem + 2px)" }}
            disabled={this.props.readOnly}
            placeholder={checkplaceholder(name)}
            onChange={(e) => this.handleChange(e, name)}
            value={value}
            id={id}
            name={name}
          >
            <option value="Hatchback">Hatchback</option>
            <option value="Sedan">Sedan</option>
            <option value="SUV">SUV</option>
          </FormSelect>
        </div>
      );
    } else if (name === "vehicle_type_form") {
      return (
        <div>
          <label className="label-style">{checkLabel(name, label)}</label>
          <FormSelect
            style={{ height: "calc(3.16rem + 2px)" }}
            disabled={this.props.readOnly}
            placeholder={checkplaceholder(name)}
            onChange={(e) => this.handleChange(e, name)}
            value={value}
            id={id}
            name={name}
          >
            <option value="Choose">Choose vehicle type</option>
            <option value="Hatchback">Hatchback</option>
            <option value="Sedan">Sedan</option>
            <option value="SUV">SUV</option>
          </FormSelect>
        </div>
      );
    } else if (name === "driver_type") {
      return (
        <div>
          {/* <label className="label-style">{label}</label> */}
          <FormSelect
            style={{ height: "calc(3.16rem + 2px)" }}
            disabled={this.props.readOnly}
            placeholder={checkplaceholder(name)}
            onChange={(e) => this.handleChange(e, name)}
            value={value}
            id={id}
            name={name}
            className="w-100"
          >
            <option value="owner">Owner</option>
            <option value="driver">Driver</option>
            <option value="temporary">Temporary</option>
          </FormSelect>
        </div>
      );
    } else if (name === "bank_verified_status") {
      return (
        <div>
          <label className="label-style">{checkLabel(name, label)}</label>
          <FormInput
            className="py-3"
            readOnly={true}
            id={id}
            name={name}
            type="text"
            placeholder={checkplaceholder(name)}
            value={value}
            style={{ color: value === "verified" ? "green" : "black" }}
          />
        </div>
      );
    } else if (name === "referralCode") {
      return (
        <div>
          <label className="label-style">{checkLabel(name, label)}</label>
          <FormInput
            className="py-3"
            readOnly={this.props.readOnly}
            onChange={(e) => this.handleChange(e, name)}
            id={id}
            name={name}
            type="text"
            placeholder={checkplaceholder(name)}
            value={value}
            maxLength="15"
          />
        </div>
      );
    } else {
      return (
        <div>
          <label className="label-style">{checkLabel(name, label)}</label>
          <FormInput
            className="py-3"
            readOnly={this.props.readOnly}
            onChange={(e) => this.handleChange(e, name)}
            id={id}
            name={name}
            type="text"
            placeholder={checkplaceholder(name)}
            value={value}
          />
        </div>
      );
    }
  }
}

InputDataForm.propTypes = {
  /**
   * The Small Stats variation.
   */
  variation: PropTypes.string,
  /**
   * The label.
   */
  label: PropTypes.string,
  /**
   * The value.
   */
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  /**
   * Flag to differentiate stat box
   */
  stats: PropTypes.bool,
};

InputDataForm.defaultProps = {
  value: "-",
  label: "Label",
};

export default InputDataForm;
