import axios from "axios";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import DatePicker from "react-date-picker";
import ReactDatepicker from "react-datepicker";
import ReactSnackBar from "react-js-snackbar";
import Select from "react-select";
// import ReactDOM from "react-dom";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// import FileCopySharpIcon from "@material-ui/icons/FileCopySharp";
// import equal from "fast-deep-equal";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  FormGroup,
  FormInput,
  FormSelect,
  ModalFooter,
  Nav,
  NavItem,
  Row,
} from "shards-react";
import config from "../../constants/constants";
// import ModalView from "./../common/Modal"
import { Store } from "../../flux";
import InputData from "./../common/InputData";
import ModNavTab from "./../common/ModalNav";
import NavTab from "./../common/NavTab";
import Pagination from "./../common/Pagination";
import Search from "./../common/Search";
import SearchDriver from "./../common/SearchDriver";
import TripDetails from "./../common/TripDetails";
import eyeShow from "../../assets/eye-view.svg";
import eyeHide from "../../assets/eye-hide.svg";
const oneDay = 24 * 60 * 60 * 1000;
// import BasicModalExample from "../common/Modal"
function capitalizeFirstLetter(string) {
  // console.log(string);
  return string[0].toUpperCase() + string.slice(1);
}

function getDriverNumber(data) {
  // console.log(data);
  if (data.phonenumber) {
    return data.phonenumber;
  } else {
    let phonenumber = data.driverphonenumber
      ? data.driverphonenumber
      : data.phnno;
    return phonenumber;
  }
}

function focusNode(data) {
  // console.log(data);
  if (data) {
    // console.log(data);
    let datas = window.document.getElementById(`${data}`);
    // console.log(datas);

    if (datas) {
      datas.scrollIntoView({
        behavior: "instant",
        block: "center",
      });
      // datas.focus();
    }
    // window.sessionStorage.setItem("clickedTrip", undefined);
    // console.log(datas);
    // console.log(datas);
  }
  // console.log(data);
  //  document.getElementsByTagName("TABLE")[0].setAttribute("id", "mytable");

  //  //table > tbody > tr (latest added row) > td (first cell in the row) > input
  //  let cell = document.getElementById("mytable").lastElementChild
  //    .lastElementChild.firstElementChild.children[0];
  // console.log(this.textInput.current);
  // let data = window.document.getElementById("tableRow");
}

class TripView extends Component {
  constructor(props) {
    super(props); //since we are extending class Table so we have to use super in order to override Component class constructor
    // console.log(props);
    this.timer = null;
    this.textInput = React.createRef();
    let region = sessionStorage.getItem("operating_region");
    this.state = {
      //state is by default an object
      data: [],
      dData: [],
      bulkData: [],
      rideType: "",
      request_status: "",
      tripData: [],
      tripDate: new Date(),
      rangeDate: new Date(),
      endRangeDate: new Date(),
      timerDate: new Date(),
      changeDiv: false,
      tripId: "",
      rideItems: Store.getRideItems(),
      request_type: "requested",
      index: 0,
      navIndex: 0,
      collapse: true,
      openMod: false,
      dutyState: "nearby",
      selected: {},
      selectAll: 0,
      showTripDetails: false,
      filteredData: [],
      filterOption: [],
      payFilerOpion: [],
      columnSearch: [],
      searchInput: "",
      filteredDrivData: [],
      payFilteredData: [],
      searchDrivInput: "",
      vendor: window.sessionStorage.getItem("vendor") || "",
      select2: { value: "All", label: "All" },
      daySelected: "Today",
      sentSuccess: false,
      rowToDisplay: undefined,
      rowsToDisplay: [],
      rowsToRed: [],
      operating_region: region,
      openTip: false,
      Show: false,
      Showing: false,
      offeredAmount: undefined,
      payofferedAmount: "",
      toggleSelect: [],
      bulkToggleSelect: [],
      showOffered: false,
      disableButton: false,
      openPayMod: false,
      modeofpayment: "",
      paytime: new Date(),
      paidtodriver: "",
      paidbyagent: "",
      tripDataPay: [],
      amountSent: "",
      instantPayment: false,
      bulkPayment: false,
      instatnPayArr: [],
      paySearchInput: "",
      bulkSelect: {},
      bulkSelectAll: 0,
      start_time: new Date(),
      end_time: new Date(),
      callModal: false,
      agentList: [],
      selectedAgent: "",
      callAgent: "",
      gozoConfirmModal: false,
    };
    // console.log(props)
    this.handleRefreshClick = this.handleRefreshClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleClickChange = this.handleClickChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.fetchCall = this.fetchCall.bind(this);
    this.fetchOngoingCall = this.fetchOngoingCall.bind(this);
    this.fetchCompletedRides = this.fetchCompletedRides.bind(this);
    this.fetchMiscRides = this.fetchMiscRides.bind(this);
    this.viewClick = this.viewClick.bind(this);
    this.fetchDrivers = this.fetchDrivers.bind(this);
    this.toggleRow = this.toggleRow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetMod = this.resetMod.bind(this);
    this.callGCM = this.callGCM.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handlemodalclick = this.handlemodalclick.bind(this);
    this.ridesCountSNextDate = this.ridesCountSNextDate.bind(this);
    this.ridesCountSPrevDate = this.ridesCountSPrevDate.bind(this);
    this.refreshDrivers = this.refreshDrivers.bind(this);
    this.handleDrivFilteredData = this.handleDrivFilteredData.bind(this);
    this.handleDrivSearchInput = this.handleDrivSearchInput.bind(this);
    this.updateList = this.updateList.bind(this);
    this.tick = this.tick.bind(this);
    this.filterVendor = this.filterVendor.bind(this);
    this.searchAndFilter = this.searchAndFilter.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleVendorChange = this.handleVendorChange.bind(this);
    this.handleManualPhone = this.handleManualPhone.bind(this);
    // this.callLog = this.callLog.bind(this);
    // console.log(this.state.rideItems);
    // console.log(this.state);
  }

  updateTripVisibilityToDriver = (data) => {
    // console.log('==========>', data);
    let dataToSend = {
      trip_id: data.trip_id,
      is_visible: data.is_visible_to_driver === true ? false : true,
    };
    //console.log('==========>', dataToSend);
    axios
      .post(config.url + "htmlcrm/updateTripVisibleToDriverStatus", dataToSend)
      .then((result) => {
        //console.log("--------->", result)
        if (result.data.message === "successfull") {
          //console.log("--------->1", )
          this.setState(
            {
              success: true,
              message: "Trip visibility updated successfully",
            },
            () => {
              this.show();
              this.fetchCall();
            }
          );
        } else {
          //console.log("--------->2")
          this.setState(
            {
              success: false,
              message: "Trip visibility updated unsuccessfully",
            },
            () => {
              this.show();
              //this.fetchCall();
            }
          );
        }
      });
  };

  handleBulkPayment = () => {
    // console.log(this.state.bulkToggleSelect);
    // console.log(this.state.bulkSelect);
    let test = _.compact(
      _.map(this.state.bulkSelect, function (value, prop) {
        if (value) {
          return prop;
        }
      })
    );
    let dataToSend = {
      tripIDArray: test,
      user_id: window.sessionStorage.getItem("user_id"),
    };
    // console.log(dataToSend);
    axios
      .post(config.url + "payout/markTripForInstantPaymentInBulk", dataToSend)
      .then((response) => {
        // console.log(response);
        if (response.data.message === "successfull") {
          this.setState(
            {
              success: true,
              message: "Rides marked for instant payout",
            },
            () => {
              this.show();
              this.handleInstantBulkClose();
            }
          );
        } else {
          this.setState({
            success: false,
            message: "Failed to mark rides for instant payout",
          });
        }
      });
  };

  handleInstantPayment = () => {
    let dataToSend = {
      trip_id: this.state.instatnPayArr.trip_id,
      user_id: window.sessionStorage.getItem("user_id"),
    };
    // console.log(dataToSend);
    axios
      .post(config.url + "payout/markTripForInstantPayment", dataToSend)
      .then((response) => {
        // console.log(response);
        if (response.data.message === "successfull") {
          this.setState(
            {
              instatnPayArr: [],
              instantPayment: false,
              success: true,
              message: "Ride marked for instant payout",
            },
            () => {
              this.show();
              this.fetchOngoingCall();
            }
          );
        } else {
          this.setState({
            success: false,
            message: "Failed to mark for instant payout",
          });
        }
      });
  };
  viewInstantClick = (data) => {
    // console.log(data);
    this.setState({
      instantPayment: true,
      instatnPayArr: data,
    });
  };

  datesAreOnSameDay = (firstDay, secondDay) => {
    return (
      firstDay.getFullYear() === secondDay.getFullYear() &&
      firstDay.getMonth() === secondDay.getMonth() &&
      firstDay.getDate() === secondDay.getDate()
    );
  };

  handleInstantClose = () => {
    this.setState({ instantPayment: false });
  };

  confirmBookingToGozo = () => {
    let trip_id = this.state.tripData.trip_id;
    let vendor_id = this.state.tripData.vendor_id;
    let dataToSend = {
      trip_id,
      vendor_id,
    };
    axios
      .post(config.url + "vendor/confirmGozoBooking", dataToSend)
      .then((response) => {
        // console.log(response);
        if (response.data.message === "successfull") {
          this.setState(
            {
              success: true,
              message: "Booking has been confirmed",
            },
            () => {
              this.openConfirmModal();
              this.show();
              this.fetchCall(this.state);
            }
          );
        } else {
          this.setState(
            {
              success: false,
              message: "Failed to confirm booking",
            },
            () => {
              this.show();
            }
          );
        }
      });
  };
  handleInstantBulkClose = () => {
    this.setState({
      bulkSelect: {},
      bulkSelectAll: 0,
      bulkPayment: false,
    });
  };

  viewPayClick = (data) => {
    // console.log(data);
    this.setState({
      tripDataPay: data,
      openPayMod: true,
    });
  };

  handleOnChange = (e) => {
    this.setState({
      offeredAmount: e.target.value,
    });
  };

  handleManualPhone(e, name) {
    let value;
    const newState = {};
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      value = e.target.value;
      newState[name] = value;
      this.setState({ ...this.state, ...newState });
    }
  }

  handlePayOfferedAmount = (e) => {
    this.setState({
      payofferedAmount: e.target.value,
    });
  };

  handlePaidAmount = (e) => {
    this.setState({
      amountSent: e.target.value,
    });
  };

  handlePaidtoAmount = (e) => {
    this.setState({
      paidtodriver: e.target.value,
    });
  };

  callUpdatePayment = (e) => {
    if (e) {
      e.preventDefault();
    }
    // console.log({
    //   trip_id: this.state.tripDataPay.trip_id,
    //   payment_mode: this.state.modeofpayment,
    //   paid_amount: this.state.amountSent,
    //   paid_date: this.state.paytime.getTime(),
    //   dunique_id: null,
    //   beneficiary_id: this.state.paidtodriver,
    //   transfer_initiatedBy: this.state.paidbyagent,
    //   offered_amount: this.state.payofferedAmount,
    // });
    fetch(config.url + "payout/updatemanualpayment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        trip_id: this.state.tripDataPay.trip_id,
        payment_mode: this.state.modeofpayment,
        paid_amount: this.state.amountSent,
        paid_date: this.state.paytime.getTime(),
        dunique_id: null,
        beneficiary_id: this.state.paidtodriver,
        transfer_initiatedBy: this.state.paidbyagent,
        offered_amount: this.state.payofferedAmount,
      }),
    })
      .then((res) => res.json())
      .then(
        (results) => {
          // console.log(results);
          if (results.message === "successfull") {
            setTimeout(() => {
              this.setState(
                {
                  openPayMod: false,
                  success: true,
                  message: "Data updated successfully",
                  payofferedAmount: "",
                  modeofpayment: "",
                  paytime: new Date(),
                  paidtodriver: "",
                  amountSent: "",
                  paidbyagent: "",
                },
                () => {
                  this.show();
                }
              );
            }, 500);
          } else {
            if (results.error && results.error === "ride not assigned") {
              this.setState(
                {
                  success: false,
                  message: "Ride not assigned to ",
                },
                () => this.show()
              );
            } else if (results.error && results.error === "no ride exists") {
              this.setState(
                {
                  success: false,
                  message: "Unable to find the ride",
                },
                () => this.show()
              );
            } else {
              this.setState(
                {
                  success: false,
                  message: "Data failed to update",
                },
                () => this.show()
              );
            }
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  };
  handlePaidBy = (e) => {
    this.setState({
      paidbyagent: e.target.value,
    });
  };

  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 4000);
  };

  handleSelectChange(event) {
    this.setState(
      {
        operating_region: event.target.value,
      },
      () => {
        this.tick(this.state.request_type);
      }
    );
  }

  handleVendorChange(event) {
    this.setState(
      {
        vendor: event.target.value,
      },
      () => {
        this.filterVendor(this.state.data);
      }
    );
  }

  filterVendor(data) {
    let userInput = this.state.vendor;
    const filteredResults = this.searchAndFilter(userInput, data);
    this.setState({
      searchInput: userInput,
      filteredData: filteredResults,
    });
  }

  searchAndFilter(input, array) {
    // console.log()
    const searchTerm = input.toLowerCase();
    if (searchTerm) {
      return array.filter((item) => {
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            return value.toString().toLowerCase().includes(searchTerm);
          }
          return false;
        });
      });
    } else {
      return array;
    }
  }

  handleDrivFilteredData = (filteredDrivData) => {
    this.setState({ filteredDrivData });
  };

  handlePayFilteredData = (filteredPayData) => {
    this.setState({ payFilteredData: filteredPayData });
  };

  handlePaySeachInput = (searchPayInput) => {
    this.setState({
      paySearchInput: searchPayInput,
    });
  };

  handleDrivSearchInput = (searchDrivInput) => {
    this.setState({ searchDrivInput });
  };
  refreshDrivers() {
    if (this.state.dutyState === "onduty") {
      this.setState(
        {
          searchDrivInput: "",
          filteredDrivData: [],
        },
        () => {
          this.fetchDutyDrivers();
        }
      );
    } else {
      this.setState(
        {
          searchDrivInput: "",
          filteredDrivData: [],
        },
        () => {
          this.fetchDrivers();
        }
      );
    }
  }

  ridesCountDate = () => {
    // console.log(date);
    let newDate = new Date(this.state.tripDate);

    newDate.setHours(0, 0, 0, 0);
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    // console.log(newDate);
    // console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - newDate) / oneDay));
    newDate.getTime() > currentDate.getTime()
      ? newDate.setDate(newDate.getDate() + 1)
      : newDate.setDate(newDate.getDate() - 1);
    if (newDate.getTime() >= currentDate.getTime()) {
      if (newDate.getTime() == currentDate.getTime()) {
        this.setState(
          {
            daySelected: "Today",
          },
          () => {
            this.handleRefreshClick();
          }
        );
        // setDayselected("Today");
      } else {
        if (diffDays === 1) {
          this.setState(
            {
              daySelected: "Tomorrow",
            },
            () => {
              this.handleRefreshClick();
            }
          );
          // setDayselected("Tomorrow");
        } else {
          diffDays = diffDays - 1;
          // console.log(diffDays);
          if (diffDays == 1) {
            this.setState(
              {
                daySelected: "Day after tomorrow",
              },
              () => {
                this.handleRefreshClick();
              }
            );
            // setDayselected("Day after tomorrow");
          } else {
            this.setState(
              {
                daySelected: `${diffDays} days after tomorrow`,
              },
              () => {
                this.handleRefreshClick();
              }
            );
            // setDayselected(`${diffDays} days after tomorrow`);
          }
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - newDate) / oneDay));
      // console.log(diffDays);
      if (diffDays == 1) {
        // setDayselected("Today");
        this.setState(
          {
            daySelected: "Yesterday",
          },
          () => {
            this.handleRefreshClick();
          }
        );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          // setDayselected("Day before yesterday");
          this.setState(
            {
              daySelected: "Day before yesterday",
            },
            () => {
              this.handleRefreshClick();
            }
          );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          // setDayselected(`${diffDays} days from yesterday`);
          this.setState(
            {
              daySelected: `${diffDays} days from yesterday`,
            },
            () => {
              this.handleRefreshClick();
            }
          );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  };
  ridesCountSNextDate() {
    let date = this.state.tripDate;
    // console.log(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    nextDate.setHours(0, 0, 0, 0);
    // console.log(nextDate);
    if (nextDate) {
      this.setState({
        tripDate: nextDate,
      });
    }
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    // console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));
    // console.log(diffDays);
    if (nextDate.getTime() >= currentDate.getTime()) {
      if (nextDate.getTime() == currentDate.getTime()) {
        this.setState(
          {
            daySelected: "Today",
          },
          () => {
            this.handleRefreshClick();
          }
        );
        // getAllRidesCount();
      } else {
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Tomorrow",
            },
            () => {
              this.handleRefreshClick();
            }
          );
          // $("#ridesCountSDateName").text("Tomorrow");
          // getAllRidesCount();
        } else {
          diffDays = diffDays - 1;
          // console.log(diffDays);
          if (diffDays == 1) {
            this.setState(
              {
                daySelected: "Day after tomorrow",
              },
              () => {
                this.handleRefreshClick();
              }
            );
            // $("#ridesCountSDateName").text("Day after tomorrow");
          } else {
            this.setState(
              {
                daySelected: `${diffDays} days after tomorrow`,
              },
              () => {
                this.handleRefreshClick();
              }
            );
            // $("#ridesCountSDateName").text(diffDays + " days after tomorrow");
          }
          // getAllRidesCount();
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));

      if (diffDays == 1) {
        this.setState(
          {
            daySelected: "Yesterday",
          },
          () => {
            this.handleRefreshClick();
          }
        );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Day before yesterday",
            },
            () => {
              this.handleRefreshClick();
            }
          );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          this.setState(
            {
              daySelected: `${diffDays} days from yesterday`,
            },
            () => {
              this.handleRefreshClick();
            }
          );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  }

  ridesCountSPrevDate() {
    let date = this.state.tripDate;
    // console.log(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() - 1);
    nextDate.setHours(0, 0, 0, 0);
    // console.log(nextDate);
    if (nextDate) {
      this.setState({
        tripDate: nextDate,
      });
    }
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    // console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));
    // console.log(diffDays);
    if (nextDate.getTime() >= currentDate.getTime()) {
      if (nextDate.getTime() == currentDate.getTime()) {
        this.setState(
          {
            daySelected: "Today",
          },
          () => {
            this.handleRefreshClick();
          }
        );
        // getAllRidesCount();
      } else {
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Tomorrow",
            },
            () => {
              this.handleRefreshClick();
            }
          );
          // $("#ridesCountSDateName").text("Tomorrow");
          // getAllRidesCount();
        } else {
          diffDays = diffDays - 1;
          // console.log(diffDays);
          if (diffDays == 1) {
            this.setState(
              {
                daySelected: "Day after tomorrow",
              },
              () => {
                this.handleRefreshClick();
              }
            );
            // $("#ridesCountSDateName").text("Day after tomorrow");
          } else {
            this.setState(
              {
                daySelected: `${diffDays} days after tomorrow`,
              },
              () => {
                this.handleRefreshClick();
              }
            );
            // $("#ridesCountSDateName").text(diffDays + " days after tomorrow");
          }
          // getAllRidesCount();
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));

      if (diffDays == 1) {
        this.setState(
          {
            daySelected: "Yesterday",
          },
          () => {
            this.handleRefreshClick();
          }
        );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Day before yesterday",
            },
            () => {
              this.handleRefreshClick();
            }
          );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          this.setState(
            {
              daySelected: `${diffDays} days from yesterday`,
            },
            () => {
              this.handleRefreshClick();
            }
          );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  }
  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filteredData;
    // console.log(filtered);
    let insertNewFilter = 1;
    // console.log("here");
    if (filtered.length) {
      filtered.forEach((filter, i) => {
        // console.log(filter);
        // console.log(i);
        if (filter["id"] === accessor) {
          // console.log("here now");
          if (value === "" || !value.length) {
            // console.log("herewwww");
            filtered.splice(i, 1);
          } else if (value === "null") {
            // console.log("here is my val");
          } else {
            // console.log("herewssswww");
            filter["value"] = value;
          }

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }
    // console.log(filtered);
    this.setState({ filterOption: filtered });
  };

  handlemodalclick(data) {
    // console.log(data);
    this.setState(
      {
        openMod: !this.state.openMod,
        changeDiv: false,
        tripData: data,
      },
      () => {
        this.fetchDrivers();
      }
    );
  }

  goBack() {
    this.props.propsData.history.goBack();
  }

  handleSetData = (data) => {
    // console.log(data);
    this.setState({ data });
  };

  handleSetFilteredData = (filteredData) => {
    this.setState({ filteredData });
  };

  handleSetSearchInput = (searchInput) => {
    this.setState({ searchInput });
  };
  callGCM(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      disableButton: true,
    });
    // console.log("hello osodosadosa");
    // console.log(this.state.selected);
    // console.log(this.state.tripData.trip_id);
    // console.log(this.state.selected);
    let test;
    if (this.state.selected !== undefined || this.state.selected !== null) {
      test = _.compact(
        _.map(this.state.selected, function (value, prop) {
          if (value) {
            return prop;
          }
        })
      );
    } else {
      test = [];
    }
    if (this.state.showOffered === true) {
      this.updateOfferedAmount();
    }
    // console.log(test);
    let data = {
      executive_id: window.sessionStorage.getItem("user_id"),
      trip_id: this.state.tripData.trip_id,
      dunique_ids: "[" + test.toString() + "]",
    };
    // console.log(data);
    fetch(config.url + "htmlcrm/sendScheduledRideGCM", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.message === "successfull") {
            this.setState(
              {
                sentSuccess: true,
                // disableButton: false,
              },
              () => {
                setTimeout(() => {
                  this.setState({ sentSuccess: false, disableButton: false });
                }, 4000);
              }
            );
          } else {
            setTimeout(() => {
              this.setState({ disableButton: false });
            }, 3000);
          }

          // this.setState({
          //     dData: result.drivers
          // })
          // console.log(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  resetMod() {
    this.setState({
      openMod: !this.state.openMod,
      selected: {},
      selectAll: 0,
      toggleSelect: [],
      offeredAmount: undefined,
      showOffered: false,
      navIndex: 0,
      dData: [],
      disableButton: false,
      dutyState: "nearby",
    });
  }

  resetPayMod = () => {
    this.setState(
      {
        payofferedAmount: "",
        modeofpayment: "",
        paytime: new Date(),
        paidtodriver: "",
        paidbyagent: "",
        amountSent: "",
      },
      () => {
        this.setState({
          openPayMod: false,
        });
      }
    );
  };

  handleSelectModeChange = (event) => {
    this.setState({
      modeofpayment: event.target.value,
    });
  };

  updateOfferedAmount = () => {
    fetch(config.url + "htmlcrm/updateTripData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // trip_id: this.props.tripId
        // trip_id: '0K918TQC60KQcwth'
        trip_id: this.state.tripData.trip_id,
        userid: window.sessionStorage.getItem("user_id"),
        valueType: "offered",
        value: this.state.offeredAmount,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.message === "successfull") {
          } else {
          }
          // console.log("herererer");
          // console.log(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  };

  toggle() {
    this.setState({
      collapse: !this.state.collapse,
      showTripDetails: !this.state.showTripDetails,
    });
  }

  toggleRowOn = (data) => {
    console.log(data);
    console.log(this.state.selected);
    const newSelected = Object.assign({}, this.state.selected);

    newSelected[data.dunique_id] = !this.state.selected[data.dunique_id];
    console.log(newSelected);
    // newSelected["dunique_id"] = data;
    // console.log(newSelected);
    this.setState(
      {
        toggleSelect: [...this.state.toggleSelect, data],
        selected: newSelected,
        selectAll: 2,
      },
      () => {
        // console.log(this.state.selected);
        // console.log(this.state.toggleSelect);
        let test = _.compact(
          _.map(this.state.selected, function (value, prop) {
            if (value) {
              return prop;
            }
          })
        );
        // console.log(test);
        let arrayVal = [];
        for (let i = 0; i < test.length; i++) {
          let checkValue = _.filter(this.state.toggleSelect, (o) => {
            return o.dunique_id === test[i];
          });
          // console.log(checkValue);
          arrayVal.push(checkValue[0]);
        }
        // console.log(arrayVal);
        let datas = _.find(arrayVal, (o) => {
          return o.driver_type === "Temporary";
        });
        // console.log(datas);
        if (datas !== undefined) {
          this.setState({
            showOffered: true,
            toggleSelect: arrayVal,
          });
        } else {
          this.setState({
            showOffered: false,
            toggleSelect: arrayVal,
          });
        }
      }
    );
  };

  bulkToggleSelectAll = () => {
    // console.log("here");
    let newSelected = {};

    if (this.state.bulkSelectAll === 0) {
      this.state.data.forEach((x) => {
        newSelected[x.trip_id] = true;
      });
    }

    // console.log(this.state.dData);

    this.setState(
      {
        bulkSelect: newSelected,
        bulkSelectAll: this.state.bulkSelectAll === 0 ? 1 : 0,
        bulkToggleSelect: this.state.data,
      },
      () => {
        let test = _.compact(
          _.map(this.state.bulkSelect, function (value, prop) {
            if (value) {
              return prop;
            }
          })
        );
        // console.log(this.state.toggleSelect);
        let arrayVal = [];
        for (let i = 0; i < test.length; i++) {
          let checkValue = _.filter(this.state.bulkToggleSelect, (o) => {
            return o.trip_id === test[i];
          });
          // console.log(checkValue);
          arrayVal.push(checkValue[0]);
        }
        // console.log(arrayVal);
        this.setState({
          bulkToggleSelect: arrayVal,
        });
      }
    );
  };

  toggleRowBulk = (data) => {
    // console.log(data);
    const newSelected = Object.assign({}, this.state.bulkSelect);
    newSelected[data.trip_id] = !this.state.bulkSelect[data.trip_id];
    this.setState(
      {
        bulkToggleSelect: [...this.state.bulkToggleSelect, data],
        bulkSelect: newSelected,
        bulkSelectAll: 2,
      },
      () => {
        let test = _.compact(
          _.map(this.state.bulkSelect, function (value, prop) {
            if (value) {
              return prop;
            }
          })
        );
        // console.log(test);
        let arrayVal = [];
        for (let i = 0; i < test.length; i++) {
          let checkValue = _.filter(this.state.bulkToggleSelect, (o) => {
            return o.trip_id === test[i];
          });
          // console.log(checkValue);
          arrayVal.push(checkValue[0]);
        }
        this.setState({
          bulkToggleSelect: arrayVal,
        });
      }
    );
  };

  toggleRow(data) {
    console.log(data);
    const newSelected = Object.assign({}, this.state.selected);
    // console.log(newSelected);
    // console.log(this.state.selected);
    newSelected[data.dunique_id] = !this.state.selected[data.dunique_id];
    // newSelected["dunique_id"] = data;
    console.log(newSelected);
    this.setState(
      {
        toggleSelect: [...this.state.toggleSelect, data],
        selected: newSelected,
        selectAll: 2,
      },
      () => {
        let test = _.compact(
          _.map(this.state.selected, function (value, prop) {
            if (value) {
              return prop;
            }
          })
        );
        // console.log(test);
        let arrayVal = [];
        for (let i = 0; i < test.length; i++) {
          let checkValue = _.filter(this.state.toggleSelect, (o) => {
            return o.dunique_id === test[i];
          });
          // console.log(checkValue);
          arrayVal.push(checkValue[0]);
        }
        console.log(arrayVal);
        let datas = _.find(arrayVal, (o) => {
          return o.driver_type === "Temporary";
        });
        // console.log(datas);
        if (datas !== undefined) {
          this.setState({
            showOffered: true,
            toggleSelect: arrayVal,
          });
        } else {
          this.setState({
            showOffered: false,
            toggleSelect: arrayVal,
          });
        }
      }
    );

    // console.log(test);
    // console.log("----------------------------------");
    // console.log(this.state.selected);
    // let datas = _.find(this.state.selected, (o) => {
    //   return o.;
    // });
    // console.log(datas);
  }

  updateList(data) {
    // console.log(data);
  }

  toggleSelectAll() {
    // console.log("here");
    let newSelected = {};

    if (this.state.selectAll === 0) {
      this.state.dData.forEach((x) => {
        // console.log(x);
        newSelected[x.dunique_id] = true;
      });
    }
    // console.log(newSelected);
    this.setState(
      {
        selected: newSelected,
        selectAll: this.state.selectAll === 0 ? 1 : 0,
        toggleSelect: this.state.dData,
      },
      () => {
        let test = _.compact(
          _.map(this.state.selected, function (value, prop) {
            if (value) {
              return prop;
            }
          })
        );
        // console.log(test);
        let arrayVal = [];
        for (let i = 0; i < test.length; i++) {
          let checkValue = _.filter(this.state.toggleSelect, (o) => {
            return o.dunique_id === test[i];
          });
          // console.log(checkValue);
          arrayVal.push(checkValue[0]);
        }
        // console.log(arrayVal);
        let datas = _.find(arrayVal, (o) => {
          return o.driver_type === "Temporary";
        });
        // console.log(datas);
        // console.log(this.state.toggleSelect);
        if (datas !== undefined) {
          this.setState({
            showOffered: true,
            toggleSelect: arrayVal,
          });
        } else {
          this.setState({
            showOffered: false,
            toggleSelect: arrayVal,
          });
        }
      }
    );
  }

  toggleOnSelectAll() {
    // console.log("here");
    let newSelected = {};

    if (this.state.selectAll === 0) {
      this.state.dData.forEach((x) => {
        newSelected[x.dunique_id] = true;
      });
    }

    // console.log(this.state.dData);

    this.setState(
      {
        selected: newSelected,
        selectAll: this.state.selectAll === 0 ? 1 : 0,
        toggleSelect: this.state.dData,
      },
      () => {
        let test = _.compact(
          _.map(this.state.selected, function (value, prop) {
            if (value) {
              return prop;
            }
          })
        );
        // console.log(this.state.toggleSelect);
        let arrayVal = [];
        for (let i = 0; i < test.length; i++) {
          let checkValue = _.filter(this.state.toggleSelect, (o) => {
            return o.dunique_id === test[i];
          });
          // console.log(checkValue);
          arrayVal.push(checkValue[0]);
        }
        // console.log(arrayVal);
        let datas = _.find(arrayVal, (o) => {
          return o.driver_type === "Temporary";
        });
        // console.log(datas);
        // console.log(this.state.toggleSelect);
        if (datas !== undefined) {
          this.setState({
            showOffered: true,
            toggleSelect: arrayVal,
          });
        } else {
          this.setState({
            showOffered: false,
            toggleSelect: arrayVal,
          });
        }
      }
    );
  }

  viewClick(data) {
    // console.log("*****************halooooo*****************");
    // console.log(data);
    // return (<BasicModalExample />)
    this.setState(
      {
        tripData: data,
      },
      () => {
        setTimeout(() => {
          this.fetchDrivers();
          this.enableModal();
        }, 500);
      }
    );
  }

  openConfirmGozo(data) {
    this.setState(
      {
        tripData: data,
      },
      () => {
        setTimeout(() => {
          this.openConfirmModal();
        }, 500);
      }
    );
  }

  openConfirmModal = () => {
    this.setState({
      gozoConfirmModal: !this.state.gozoConfirmModal,
    });
  };

  enableModal = () => {
    this.setState({
      openMod: !this.state.openMod,
    });
  };

  fetchDrivers() {
    let dataToSend = {
      trip_id: this.state.tripData.trip_id,
    };
    axios
      .post(config.locUrl + "index/getNearByDriverSuggestion", dataToSend)
      .then((response) => {
        let dataCond = response.data;
        console.log(dataCond);
        let dataStore;
        if (dataCond.message === "successfull") {
          dataStore = dataCond.response.data.driver_arr
            ? dataCond.response.data.driver_arr
            : [];
        } else {
          dataStore = [];
        }
        this.setState({
          dData: dataStore,
        });
      })
      .catch();
  }

  fetchDutyDrivers() {
    // console.log(this.state.tripData);
    let data = {
      vehicle_type: this.state.tripData.vehicle_type,
      triptype:
        this.state.tripData.ridetype === undefined
          ? this.state.tripData.ride_type
          : this.state.tripData.ridetype,
      trip_id: this.state.tripData.trip_id,
      operating_region: this.state.tripData.region,
    };
    console.log(data);
    fetch(config.url + "htmlcrm/freeDrivers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if (result.message === "unsuccessfull") {
            this.setState({
              dData: [],
            });
          } else {
            this.setState({
              dData: result.driverlist,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  // Handle toggle visibility of the rides Tab
  onChange(props) {
    // console.log("**********adasdasa*************");
    // console.log(...this.state)
    this.setState({
      ...this.state,
      rideItems: Store.getRideItems(),
    });
  }
  handlePayTime = (date) => {
    this.setState({
      paytime: date,
    });
  };
  // Reload list when a date is seleced from the date picker
  handleTime(tripDate) {
    // console.log(this);
    // console.log(this.state.request_type);
    this.setState({ tripDate }, () => {
      if (this.state.request_type === "requested") {
        // this.setState({
        //     request_type: "unassigned"
        // })
        this.fetchCall();
        this.ridesCountDate();
      } else if (this.state.request_type === "assigned") {
        // console.log("here");
        // this.setState({
        //     request_type: "assigned"
        // })
        this.fetchCall();
        this.ridesCountDate();
      } else if (this.state.request_type === "ongoing") {
        // this.setState({
        //     request_type: "ongoing"
        // })
        this.fetchOngoingCall();
        this.ridesCountDate();
      } else if (this.state.request_type === "completed") {
        // this.setState({
        //     request_type: "completed"
        // })
        this.fetchCompletedRides();
        this.ridesCountDate();
      } else {
        // this.setState({
        //   request_type: "canceled"
        // });
        this.fetchMiscRides();
        this.ridesCountDate();
      }
    });
    // this.countFetch()
  }
  // Api call to fetch unassigned and assigned rides
  fetchCall(props) {
    let time = this.state.tripDate.setHours(0, 0, 0, 0);
    let scheduledRidee, rentalRide, outstationRide;
    let filter_type =
      this.state.select2 === undefined ? "all" : this.state.select2.value;
    // console.log(time);
    fetch(config.url + "htmlcrm/taxiScheduleRideList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        region: this.state.operating_region,
        vendor: this.state.vendor,
        milliseconds: time,
        request_type: this.state.request_type,
        filter_type,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          console.log(result);
          let unsortedData = result.taxiScheduleRideList;
          if (unsortedData && unsortedData.length > 0) {
            unsortedData = _.orderBy(unsortedData, ["request_type"], ["desc"]);
          }
          scheduledRidee = unsortedData;

          fetch(config.url + "htmlcrm/taxiOutstationRideList", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
              vendor: this.state.vendor,
              region: this.state.operating_region,
              milliseconds: time,
              request_type: this.state.request_type,
            }),
          })
            .then((res) => res.json())
            .then(
              (results) => {
                // console.log("herererer");
                // console.log(results);
                outstationRide = results.taxiScheduleRideList;
                // this.setState({
                //   data:
                //     result.taxiScheduleRideList === undefined
                //       ? []
                //       : result.taxiScheduleRideList,
                // });

                fetch(config.url + "htmlcrm/taxiRentalRideList", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  body: JSON.stringify({
                    vendor: this.state.vendor,
                    region: this.state.operating_region,
                    milliseconds: time,
                    request_type: this.state.request_type,
                  }),
                })
                  .then((res) => res.json())
                  .then(
                    (resultz) => {
                      // console.log("herererer");
                      // console.log(resultz);
                      rentalRide = resultz.taxiScheduleRideList;
                      // console.log(scheduledRidee);
                      // console.log(rentalRide);
                      // console.log(outstationRide);
                      let data = _.concat(
                        scheduledRidee,
                        rentalRide,
                        outstationRide
                      );
                      let final_data = _.without(data, undefined);
                      console.log(final_data);

                      this.setState(
                        {
                          data: final_data,
                        },
                        () => {
                          let arrays = [];
                          let secondArrays = [];
                          let count = 0;
                          _.forEach(final_data, (forResults) => {
                            let checkedDate = moment(
                              forResults.pickuptime,
                              "DD/MM/YYYY [at] hh:mm A"
                            );

                            let now = moment();

                            let duration = moment.duration(
                              checkedDate.diff(now)
                            );
                            let days = duration.asHours();
                            if (days < 3 && days > 1) {
                              arrays.push(forResults.trip_id);
                              // focusNode(forResults.trip_id);
                            } else if (days < 1 && days > 0) {
                              secondArrays.push(forResults.trip_id);
                            }
                            count++;

                            if (count === final_data.length) {
                              this.setState({
                                rowsToDisplay: arrays,
                                rowsToRed: secondArrays,
                              });
                            }
                          });
                        }
                      );
                      // this.setState({
                      //   data:
                      //     result.taxiScheduleRideList === undefined
                      //       ? []
                      //       : result.taxiScheduleRideList,
                      // });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {}
                  );
              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {}
            );
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  fetchTaxiScheduledRides() {
    // console.log(this.state.tripDate);
    let scheduledRidee;
    let time = this.state.tripDate.setHours(0, 0, 0, 0);
    let filter_type =
      this.state.select2 === undefined ? "all" : this.state.select2.value;
    // console.log({
    //   vendor: this.state.vendor,
    //   milliseconds: time,
    //   request_type: this.state.request_type,
    //   filter_type,
    // });
    // console.log(time);
    fetch(config.url + "htmlcrm/taxiScheduleRideList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        vendor: this.state.vendor,
        region: this.state.operating_region,
        milliseconds: time,
        request_type: this.state.request_type,
        filter_type,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          // return result;
          let unsortedData = result.taxiScheduleRideList;
          if (unsortedData && unsortedData.length > 0) {
            unsortedData = _.orderBy(unsortedData, ["request_type"], ["desc"]);
          }
          scheduledRidee = unsortedData;
          this.setState({
            data: scheduledRidee === undefined ? [] : scheduledRidee,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  handleAgentSelect = (e) => {
    this.setState({
      callAgent: e.target.value,
    });
  };

  placeCall = () => {
    let selectAgent = this.state.selectedAgent;
    // destination_number: getDriverNumber(selectedAgent)
    let dataToSend = {
      destination_number: selectAgent,
      agent_number: this.state.callAgent,
    };
    console.log(dataToSend);
    axios
      .post(config.url + "cloudtelephony/initiateClickToCall", dataToSend)
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "successfull") {
          this.setState({
            callModal: false,
            callAgent: "",
          });
        } else {
          this.setState(
            {
              message: "Invalid number, please select again",
              success: false,
            },
            () => {
              this.show();
            }
          );
        }
      })
      .catch();
  };

  fetchOutstationRides() {
    // console.log(this.state.tripDate);
    let time = this.state.tripDate.setHours(0, 0, 0, 0);
    let filter_type =
      this.state.select2 === undefined ? "all" : this.state.select2.value;
    // console.log(time);
    fetch(config.url + "htmlcrm/taxiOutstationRideList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        vendor: this.state.vendor,
        milliseconds: time,
        request_type: this.state.request_type,
        region: this.state.operating_region,
        filter_type,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          this.setState({
            data:
              result.taxiScheduleRideList === undefined
                ? []
                : result.taxiScheduleRideList,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  fetchRentalRides() {
    // console.log(this.state.tripDate);
    let time = this.state.tripDate.setHours(0, 0, 0, 0);
    let filter_type =
      this.state.select2 === undefined ? "all" : this.state.select2.value;
    // console.log(time);
    fetch(config.url + "htmlcrm/taxiRentalRideList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        vendor: this.state.vendor,
        milliseconds: time,
        request_type: this.state.request_type,
        region: this.state.operating_region,
        filter_type,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          this.setState({
            data:
              result.taxiScheduleRideList === undefined
                ? []
                : result.taxiScheduleRideList,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  // Api call to fetch ongoing rides
  fetchOngoingCall(props) {
    let filter_type =
      this.state.select2 === undefined ? "all" : this.state.select2.value;
    fetch(config.url + "htmlcrm/getOngoingRidesList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        vendor: this.state.vendor,
        region: this.state.operating_region,
        filter_type,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          this.setState({
            data: result.rideslist === undefined ? [] : result.rideslist,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  // Api call to fetch completed rides
  fetchCompletedRides(props) {
    let time = this.state.tripDate.setHours(0, 0, 0, 0);
    let filter_type =
      this.state.select2 === undefined ? "all" : this.state.select2.value;
    // console.log(time)
    // let milliseconds = time.getTime()
    // console.log((this.state.tripDate).getTime())
    fetch(config.url + "htmlcrm/getCompletedRidesList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        vendor: this.state.vendor,
        region: this.state.operating_region,
        milliseconds: time,
        filter_type,
        // request_type: this.state.request_type
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          this.setState({
            data: result.rideslist,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  // Api call to fetch canceled rides
  fetchMiscRides(props) {
    // console.log(this.state.vendor);
    let filter_type =
      this.state.select2 === undefined ? "All" : this.state.select2.value;
    console.log({
      // vendor: this.state.vendor,
      vendor: this.state.vendor,
      region: this.state.operating_region,
      milliseconds: this.state.tripDate.getTime(),
      filter_type,
      // request_type: this.state.request_type
    });
    fetch(config.url + "htmlcrm/getMiscRidesList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // vendor: this.state.vendor,
        vendor: this.state.vendor,
        region: this.state.operating_region,
        milliseconds: this.state.tripDate.getTime(),
        filter_type,
        // request_type: this.state.request_type
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          console.log(result);
          this.setState({
            data: result.rideslist,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  handleRefreshClick() {
    // console.log(this.state.request_type);
    if (this.state.request_type === "requested") {
      this.setState(
        {
          searchInput: "",
          filteredData: [],
        },
        () => {
          // this.fetchCall();
          this.tick(this.state.request_type);
        }
      );
    } else if (this.state.request_type === "assigned") {
      // console.log("here");
      this.setState(
        {
          searchInput: "",
          filteredData: [],
        },
        () => {
          // this.fetchCall();
          this.tick(this.state.request_type);
        }
      );
    } else if (this.state.request_type === "ongoing") {
      this.setState(
        {
          searchInput: "",
          filteredData: [],
        },
        () => {
          // this.fetchOngoingCall();
          this.tick(this.state.request_type);
        }
      );
    } else if (this.state.request_type === "completed") {
      this.setState(
        {
          searchInput: "",
          filteredData: [],
        },
        () => {
          // this.fetchCompletedRides();
          this.tick(this.state.request_type);
        }
      );
    } else {
      this.setState(
        {
          searchInput: "",
          filteredData: [],
        },
        () => {
          // this.fetchMiscRides();
          this.tick(this.state.request_type);
        }
      );
    }
  }
  // Change to ride details page on click of view
  handleClick(data) {
    // console.log("************************")
    // console.log(data);
    this.setState({
      changeDiv: true,
      tripId: data.trip_id,
      rideType: data.ridetype,
      request_status: data.request_status,
    });
  }
  // Handle state change on click of the tab
  handleClickChange(data, index) {
    this.handleSelect(index, data.label);
    // console.log(index);
    // console.log(this.state);
    if (data.label === "Unassigned rides") {
      this.setState(
        {
          request_type: "requested",
          filteredData: [],
          searchInput: "",
          vendor: "",
        },
        () => {
          this.fetchCall();
        }
      );
    } else if (data.label === "Assigned rides") {
      // console.log("here");
      this.setState(
        {
          request_type: "assigned",
          filteredData: [],
          searchInput: "",
          vendor: "",
        },
        () => {
          this.fetchCall();
        }
      );
    } else if (data.label === "Ongoing rides") {
      this.setState(
        {
          request_type: "ongoing",
          filteredData: [],
          searchInput: "",
          vendor: "",
        },
        () => {
          this.fetchOngoingCall();
        }
      );
    } else if (data.label === "Completed rides") {
      this.setState(
        {
          request_type: "completed",
          filteredData: [],
          searchInput: "",
          vendor: "",
        },
        () => {
          this.fetchCompletedRides();
        }
      );
    } else if (data.label === "Nearby drivers") {
      this.setState(
        {
          dutyState: "nearby",
          filteredDrivData: [],
          searchDrivInput: "",
          selected: {},
          toggleSelect: [],
          offeredAmount: undefined,
          showOffered: false,
          selectAll: 0,
          dData: [],
        },
        () => {
          this.fetchDrivers();
        }
      );
    } else if (data.label === "On duty drivers") {
      this.setState(
        {
          dutyState: "onduty",
          filteredDrivData: [],
          searchDrivInput: "",
          dData: [],
          offeredAmount: undefined,
          showOffered: false,
          selected: {},
          toggleSelect: [],
          selectAll: 0,
        },
        () => {
          this.fetchDutyDrivers();
        }
      );
    } else {
      this.setState(
        {
          request_type: "canceled",
          filteredData: [],
          searchInput: "",
          vendor: "",
        },
        () => {
          this.fetchMiscRides();
        }
      );
    }
  }
  // Set active tab
  handleSelect(index, data) {
    // console.log("000000000000000000000000000000000000");
    // console.log(index);
    if (data === "Nearby drivers" || data === "On duty drivers") {
      this.setState({
        navIndex: index,
      });
    } else {
      this.setState({
        index: index,
      });
    }
  }
  // Change to ride list on click of the close button in ride details page
  handleChange() {
    this.setState(
      {
        changeDiv: false,
      },
      () => {
        // this.handleRefreshClick();
      }
    );
  }
  // Function called by set interval to load ride list
  tick(data) {
    console.log(data);
    switch (data) {
      case "requested":
        // console.log(data);
        if (this.state.select2.value === "All") {
          this.fetchCall();
        } else if (this.state.select2.value === "airport") {
          this.fetchTaxiScheduledRides();
        } else if (this.state.select2.value === "outstation") {
          this.fetchOutstationRides();
        } else if (this.state.select2.value === "rental") {
          this.fetchRentalRides();
        } else if (this.state.select2.value === "urban") {
          this.fetchTaxiScheduledRides();
        } else {
          this.fetchCall();
        }
        // this.fetchCall();
        break;
      case "assigned":
        if (this.state.select2.value === "All") {
          this.fetchCall();
        } else if (this.state.select2.value === "airport") {
          this.fetchTaxiScheduledRides();
        } else if (this.state.select2.value === "outstation") {
          this.fetchOutstationRides();
        } else if (this.state.select2.value === "rental") {
          this.fetchRentalRides();
        } else if (this.state.select2.value === "urban") {
          this.fetchTaxiScheduledRides();
        } else {
          this.fetchCall();
        }
        // this.fetchCall();
        break;
      case "completed":
        // console.log(data);
        // if (this.state.select2.value === "All") {
        //   this.fetchCompletedRides();
        // } else if (this.state.select2.value === "airport") {
        //   this.fetchCompletedRides();
        // } else if (this.state.select2.value === "outstation") {
        //   this.fetchCompletedRides();
        // } else if (this.state.select2.value === "rental") {
        //   this.fetchCompletedRides();
        // } else if (this.state.select2.value === "urban") {
        //   this.fetchCompletedRides();
        // } else {
        //   this.fetchCall();
        // }
        // this.fetchCall();
        this.fetchCompletedRides();
        break;
      case "ongoing":
        // console.log(data);
        // if (this.state.select2.value === "All") {
        //   this.fetchOngoingCall();
        // } else if (this.state.select2.value === "airport") {
        //   //   this.fetchTaxiScheduledRides();
        //   this.fetchOngoingCall();
        // } else if (this.state.select2.value === "outstation") {
        //   this.fetchOngoingCall();
        // } else if (this.state.select2.value === "rental") {
        //   this.fetchOngoingCall();
        // } else if (this.state.select2.value === "urban") {
        //   this.fetchOngoingCall();
        // } else {
        //   this.fetchCall();
        // }
        // this.fetchCall();
        this.fetchOngoingCall();
        break;
      case "canceled":
        this.fetchMiscRides();
        break;
      default:
        console.log("canceled");
        this.fetchMiscRides();
        break;
    }
    // this.fetchCall()
  }

  // Called before render only once
  componentDidMount(props) {
    // console.log(this.props);

    // if (this.props.propsData.location.state.label === undefined) {
    //   this.setState(
    //     {
    //       vendor: "all",
    //       tripDate:
    //         this.props.propsData.location.state.pickup_time !== undefined
    //           ? new Date(this.props.propsData.location.state.pickup_time)
    //           : new Date(),
    //       rowToDisplay:
    //         window.sessionStorage.getItem("clickedTrip") === undefined
    //           ? this.props.propsData.location.state.trip_id
    //           : window.sessionStorage.getItem("clickedTrip"),
    //     },
    //     () => {
    //       this.fetchCall(this.state);
    //       focusNode(this.props.propsData.location.state.trip_id);
    //       setTimeout(() => {
    //         this.setState({
    //           rowToDisplay: undefined,
    //         });
    //       }, 5000);
    //     }
    //   );
    // } else {
    //   this.setState(
    //     {
    //       vendor: "all",
    //       tripDate:
    //         this.props.propsData.location.state.pickup_time !== undefined
    //           ? new Date(this.props.propsData.location.state.pickup_time)
    //           : new Date(),
    //       rowToDisplay: this.props.propsData.location.state.trip_id,
    //     },
    //     () => {
    //       focusNode(this.props.propsData.location.state.trip_id);
    //       //   sessionStorage.setItem(
    //       //     "vendor",
    //       //     this.props.propsData.location.state.label
    //       //   );
    //       this.fetchCall(this.state);
    //     }
    //   );
    // }
    if (this.props.propsData.history.location.state === undefined) {
      this.fetchCall(this.state);
    } else {
      this.setState(
        {
          vendor: "all",
          tripDate:
            this.props.propsData.location.state.pickup_time !== undefined
              ? new Date(this.props.propsData.location.state.pickup_time)
              : new Date(),
          rowToDisplay:
            window.sessionStorage.getItem("clickedTrip") === undefined
              ? this.props.propsData.location.state.trip_id
              : window.sessionStorage.getItem("clickedTrip"),
        },
        () => {
          this.fetchCall(this.state);
          // this.handleClickChange(
          //   {
          //     label: this.props.propsData.location.state.body,
          //   },
          //   this.props.propsData.location.state.tab
          // );
          // focusNode(this.props.propsData.location.state.trip_id);
          setTimeout(() => {
            this.setState({
              rowToDisplay: undefined,
            });
          }, 5000);
        }
      );
    }
  }

  getRidesForBulk = (type) => {
    if (type === "requested" || type === "assigned") {
      let time = this.state.tripDate.setHours(0, 0, 0, 0);
      let start_time = this.state.start_time.getTime();
      let end_time = this.state.end_time.getTime();
      let scheduledRidee, rentalRide, outstationRide;
      let filter_type = "all";
      // console.log(time);
      fetch(config.url + "htmlcrm/taxiScheduleRideList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          region: this.state.operating_region,
          vendor: this.state.vendor,
          milliseconds: time,
          request_type: this.state.request_type,
          start_time,
          end_time,
          filter_type,
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log("herererer");
            // console.log(result);
            let unsortedData = result.taxiScheduleRideList;
            if (unsortedData && unsortedData.length > 0) {
              unsortedData = _.orderBy(
                unsortedData,
                ["request_type"],
                ["desc"]
              );
            }
            scheduledRidee = unsortedData;

            fetch(config.url + "htmlcrm/taxiOutstationRideList", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: JSON.stringify({
                vendor: this.state.vendor,
                region: this.state.operating_region,
                milliseconds: time,
                start_time,
                end_time,
                request_type: this.state.request_type,
              }),
            })
              .then((res) => res.json())
              .then(
                (results) => {
                  // console.log("herererer");
                  // console.log(results);
                  outstationRide = results.taxiScheduleRideList;
                  // this.setState({
                  //   data:
                  //     result.taxiScheduleRideList === undefined
                  //       ? []
                  //       : result.taxiScheduleRideList,
                  // });

                  fetch(config.url + "htmlcrm/taxiRentalRideList", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: JSON.stringify({
                      vendor: this.state.vendor,
                      region: this.state.operating_region,
                      milliseconds: time,
                      start_time,
                      end_time,
                      request_type: this.state.request_type,
                    }),
                  })
                    .then((res) => res.json())
                    .then(
                      (resultz) => {
                        // console.log("herererer");
                        // console.log(resultz);
                        rentalRide = resultz.taxiScheduleRideList;
                        // console.log(scheduledRidee);
                        // console.log(rentalRide);
                        // console.log(outstationRide);
                        let data = _.concat(
                          scheduledRidee,
                          rentalRide,
                          outstationRide
                        );
                        let final_data = _.without(data, undefined);
                        // console.log(final_data);

                        this.setState({
                          bulkData: final_data,
                        });
                        // this.setState({
                        //   data:
                        //     result.taxiScheduleRideList === undefined
                        //       ? []
                        //       : result.taxiScheduleRideList,
                        // });
                      },
                      // Note: it's important to handle errors here
                      // instead of a catch() block so that we don't swallow
                      // exceptions from actual bugs in components.
                      (error) => {}
                    );
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {}
              );
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    } else if (type === "ongoing") {
      let filter_type = "all";
      let start_time = this.state.start_time.getTime();
      let end_time = this.state.end_time.getTime();
      fetch(config.url + "htmlcrm/getOngoingRidesList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          vendor: this.state.vendor,
          region: this.state.operating_region,
          filter_type,
          start_time,
          end_time,
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log("herererer");
            // console.log(result);
            this.setState({
              bulkData: result.rideslist === undefined ? [] : result.rideslist,
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    } else {
    }
  };

  componentDidUpdate(prevProps, prevState) {
    clearTimeout(this.timer);
    if (this.props.propsData.location.state === undefined) {
    } else {
      if (
        this.props.propsData.location.state.trip_id ===
        window.sessionStorage.getItem("clickedTrip")
      ) {
        this.setState(
          {
            tripId: this.props.propsData.location.state.trip_id,
            rowToDisplay: this.props.propsData.location.state.trip_id,
            tripDate: new Date(this.props.propsData.location.state.pickup_time),
            // changeDiv: true,
          },
          () => {
            // this.fetchCall(this.state);
            // this.handleClickChange({ label: "Canceled" }, 4);
            this.handleClickChange(
              {
                label: this.props.propsData.location.state.body,
              },
              this.props.propsData.location.state.tab
            );
            focusNode(this.props.propsData.location.state.trip_id);
            this.timer = setTimeout(() => {
              this.setState({
                rowToDisplay: undefined,
              });
            }, 5000);
          }
        );
      } else {
        // if (this.state.rowToDisplay !== undefined) {
        //   this.setState({
        //     rowToDisplay: undefined,
        //   });
        // }
      }
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   // console.log(prevProps);
  //   // console.log(
  //   //   !equal(
  //   //     this.props.propsData.location.state.trip_id,
  //   //     prevProps.propsData.location.state.trip_id
  //   //   )
  //   // );
  //   console.log(prevState);
  //   console.log(this.state.rowToDisplay);
  //   if (!equal(this.state.rowToDisplay, prevState.rowToDisplay)) {
  //     console.log("testing******************");
  //     // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
  //     // this.updateUser();
  //     this.setState(
  //       {
  //         tripId: this.props.propsData.location.state.trip_id,
  //         rowToDisplay: this.props.propsData.location.state.trip_id,
  //         // changeDiv: true,
  //       },
  //       () => {
  //         // setTimeout(() => {
  //         //   this.setState({
  //         //     rowToDisplay: undefined,
  //         //   });
  //         // }, 5000);
  //       }
  //     );
  //   } else {
  //   }
  // }
  // callLog() {
  //   let data = config.logging + "/apflow?millis=" + new Date().getTime();
  //   console.log(data);
  //   fetch(data, {
  //     method: "GET",
  //   })
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         console.log(result);
  //         // this.setState({
  //         //     dData: result.drivers
  //         // })
  //         // console.log(result);
  //       },
  //       // Note: it's important to handle errors here
  //       // instead of a catch() block so that we don't swallow
  //       // exceptions from actual bugs in components.
  //       (error) => {}
  //     );
  // }
  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
    clearInterval(this.timerID);
    // this.setState({
    //   rowToDisplay: undefined
    // })
  }

  handleCopyClick = (data) => {
    // console.log(data);
    let dataFormat = `Hi, There is a trip available. Details are as below \n Pick-up time = ${data.pickuptime} \n Pick-up = ${data.sname} \n Drop-off = ${data.dname} \n Vehicle= ${data.vehicle_type} \n Amount =  \n To get the booking please contact - 8884488694`;
    var el = window.document.createElement("textarea");
    el.value = dataFormat;
    el.setAttribute("readonly", "hidden");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState(
      {
        message: "Trip details copied to clipboard",
        success: true,
      },
      () => {
        this.show();
      }
    );
    // dataFormat.select()
  };

  toggleTip = (targetName) => {
    this.setState({
      ...this.state,
      [targetName]: !this.state[targetName],
    });
    // if (!this.state[targetName]) {
    //   this.setState({
    //     ...this.state,
    //     [targetName]: {
    //       tooltipOpen: true,
    //     },
    //   });
    // } else {
    //   this.setState({
    //     ...this.state,
    //     [targetName]: {
    //       tooltipOpen: !this.state[targetName].tooltipOpen,
    //     },
    //   });
    // }
    // setOpenTip(!openTip);
  };

  initiateCallModal = () => {
    this.setState(
      {
        manualCall: true,
      },
      () => {
        this.getAgentList();
      }
    );
  };

  closeInitiateModal = () => {
    this.setState({
      manualCall: false,
      callAgent: "",
    });
  };

  openCallModal = (data) => {
    // console.log(data);
    this.setState(
      {
        callModal: true,
        selectedAgent: data,
      },
      () => {
        this.getAgentList(data);
      }
    );
  };

  closeCallModal = () => {
    this.setState({
      callModal: false,
      selectedAgent: "",
    });
  };

  getAgentList = (data) => {
    axios
      .get(config.url + "cloudtelephony/getAgentNumbers")
      .then((response) => {
        if (response.data.message === "successfull") {
          this.setState({
            agentList: response.data.data,
          });
        } else {
          this.setState({
            agentList: [],
          });
        }
      });
  };

  manualCallAPI = (e) => {
    if (e) {
      e.preventDefault();
    }
    // destination_number: getDriverNumber(selectedAgent)
    let dataToSend = {
      destination_number: this.state.inputNumber,
      agent_number: this.state.callAgent,
    };
    console.log(dataToSend);
    axios
      .post(config.url + "cloudtelephony/initiateClickToCall", dataToSend)
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "successfull") {
          this.setState({
            manualCall: false,
            callAgent: "",
          });
        } else {
          this.setState(
            {
              message: "Invalid number, please select again",
              success: false,
            },
            () => {
              this.show();
            }
          );
        }
      })
      .catch();
  };

  // isToolTipOpen = (targetName) => {
  //   return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
  // };
  render() {
    // console.log(this.state.offeredAmount);
    //Whenever our class runs, render method will be called automatically, it may have already defined in the constructor behind the scene.
    // console.log("*************state***************");
    // console.log(this.state.tripDate);
    // console.log(this.state.selected.dunique_id);
    const assignDrivCol = [
      {
        id: "checkbox",
        accessor: "",
        Cell: ({ original }) => {
          // console.log(original);
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selected[original.dunique_id] === true}
              onChange={() => this.toggleRow(original)}
            />
          );
        },
        Header: (x) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selectAll === 1}
              ref={(input) => {
                if (input) {
                  input.indeterminate = this.state.selectAll === 2;
                }
              }}
              onChange={() => this.toggleSelectAll()}
            />
          );
        },
        sortable: false,
        width: 45,
      },
      {
        id: "driver_name",
        Header: "Driver name",
        accessor: (d) => d.driver_name,
      },
      {
        id: "phonenumber",
        Header: "Phone number",
        Cell: ({ original }) => {
          return (
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => this.openCallModal(original.phonenumber)}
            >
              {original.phonenumber}
            </span>
          );
        },
      },
      {
        id: "driver_type",
        Header: "Driver Type",
        accessor: (d) => d.driver_type,
      },
      {
        id: "eta",
        Header: "ETA",
        accessor: (d) => (d.eta ? d.eta.eta : "-"),
      },
      {
        id: "distance",
        Header: "Distance (kms)",
        accessor: (d) => (d.eta ? d.eta.distance : "-"),
      },
    ];
    const assignDrivOndutyCol = [
      {
        id: "checkbox",
        accessor: "",
        Cell: ({ original }) => {
          // console.log(original);
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selected[original.dunique_id] === true}
              onChange={() => this.toggleRowOn(original)}
            />
          );
        },
        Header: (x) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selectAll === 1}
              ref={(input) => {
                if (input) {
                  input.indeterminate = this.state.selectAll === 2;
                }
              }}
              onChange={() => this.toggleOnSelectAll()}
            />
          );
        },
        sortable: false,
        width: 45,
      },
      {
        id: "dname",
        Header: "Driver name",
        accessor: (d) => d.dname,
      },
      {
        id: "phonenumber",
        Header: "Phone number",

        Cell: ({ original }) => {
          return (
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => this.openCallModal(original.phnno)}
            >
              {original.phnno}
            </span>
          );
        },
      },
      {
        id: "driver_type",
        Header: "Driver Type",
        accessor: (d) => d.driver_type,
      },
    ];
    let columns = [];
    // console.log(this.state.request_type);
    if (this.state.request_type === "requested") {
      // console.log(this.state.request_type);
      columns = [
        {
          Header: "Sl no",
          id: "serialno",
          style: {},
          Cell: (row) => {
            // console.log(row);

            if (row.original.is_visible_to_driver === true) {
              return (
                <React.Fragment>
                  <div style={{ display: "grid" }}>
                    <span className="mr-2">{row.viewIndex + 1}</span>

                    <img
                      alt=""
                      src={eyeShow}
                      height="auto"
                      width="25"
                      className="mx-5"
                      onClick={() =>
                        this.updateTripVisibilityToDriver(row.original)
                      }
                    ></img>
                  </div>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment>
                  <div style={{ display: "grid" }}>
                    <span className="mr-2">{row.viewIndex + 1}</span>
                    <img
                      alt=""
                      src={eyeHide}
                      height="auto"
                      width="25"
                      className="mx-5"
                      onClick={() =>
                        this.updateTripVisibilityToDriver(row.original)
                      }
                    ></img>
                  </div>
                </React.Fragment>
              );
            }
          },
        },
        {
          Header: "Trip ID",
          accessor: "trip_id",
        },
        {
          Header: "Source",
          accessor: "sname",
          style: { whiteSpace: "unset" },
        },
        {
          Header: "Destination",
          accessor: "dname",
          id: "dname",
          Cell: (row) => {
            if (
              row.original.dname === undefined ||
              row.original.dname === "" ||
              row.original.dname === null
            ) {
              return <div>-</div>;
            } else {
              return <div>{row.original.dname}</div>;
            }
          },
          style: { whiteSpace: "unset" },
        },
        {
          Header: "Pickup time",
          id: "pickuptime",
          // accessor: (d) =>
          //   typeof d.pickuptime === "string"
          //     ? new Date(
          //         moment(d.pickuptime, "DD-MM-YY [at] hh:mm:ss A")
          //       ).getTime()
          //     : d.pickuptime,
          sortable: true,
          style: { whiteSpace: "unset" },
          Cell: (row) => {
            let date;
            // console.log(row.original.request_status);
            if (
              row.original.request_status === "ended" ||
              row.original.request_status === "Requested" ||
              row.original.request_status === "Confirmed" ||
              row.original.request_status === "notified" ||
              row.original.request_status === "started" ||
              row.original.request_status === "accepted" ||
              row.original.request_status === "requested"
            ) {
              date = row.original.pickuptime;
              // console.log(date);
            } else {
              date = moment(new Date(row.original.pickuptime)).format(
                "DD-MM-YY [at] hh:mm:ss A"
              );
            }

            return <div>{date}</div>;
          },
        },
        {
          Header: "Ride type",
          accessor: "ridetype",
          id: "ridetype",
          Cell: (row) => {
            let data = capitalizeFirstLetter(row.original.ridetype);
            if (data === "Outstation" || data === "outstation") {
              let tripType;
              if (row.original.tripType && row.original.tripType === "one_way")
                tripType = "One way";
              if (
                row.original.tripType &&
                row.original.tripType === "roundtrip"
              )
                tripType = "Round trip";

              return <div>{data + " - " + tripType}</div>;
            } else {
              return <div>{data}</div>;
            }
          },
        },
        {
          Header: "Vehicle type",
          accessor: "vehicle_type",
          id: "vehicle_type",
          Cell: (row) => {
            let data = capitalizeFirstLetter(row.original.vehicle_type);
            let data_desc =
              row.original && row.original.vehicle_type_desc
                ? row.original.vehicle_type_desc
                : "Normal";
            return <div>{`${data} - ${data_desc}`}</div>;
          },
        },
        {
          Header: "Vendor",
          accessor: "request_type",
          id: "request_type",
          Cell: (row) => {
            let data = capitalizeFirstLetter(row.original.request_type);
            return <div>{data}</div>;
          },
        },
        {
          Header: "Rider",
          id: "Rider",
          style: { whiteSpace: "unset" },
          Cell: (row) => {
            return (
              <React.Fragment>
                <p className="mb-1">{row.original.consumername}</p>
                <p
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  // onClick={() => this.openCallModal(row.original.phonenumber)}
                >
                  {row.original.phonenumber}
                </p>
              </React.Fragment>
            );
          },
        },
        {
          id: "edit",
          Header: "Actions",
          width: 150,
          Cell: (row) => {
            //  console.log(this);
            // console.log(row.original.request_status)
            return (
              <div className="d-flex flex-column">
                {(row.original.request_status === "Requested" ||
                  row.original.request_status === "requested") &&
                  row.original.request_type === "gozo" &&
                  (row.original.confirmStatus === false ||
                    row.original.confirmStatus === undefined) && (
                    <Button
                      className="px-2 py-2 my-2 table-button-style"
                      theme="dark"
                      onClick={() => {
                        this.openConfirmGozo(row.original);
                      }}
                    >
                      CONFIRM
                    </Button>
                  )}
                {(row.original.request_status === "Requested" ||
                  row.original.request_status === "requested") &&
                  row.original.request_type === "gozo" &&
                  row.original.confirmStatus === true && (
                    <Button
                      className="px-2 py-2 my-2 table-button-style"
                      theme="dark"
                      onClick={() => {
                        this.viewClick(row.original);
                      }}
                    >
                      ASSIGN
                    </Button>
                  )}
                {(row.original.request_status === "Requested" ||
                  row.original.request_status === "requested") &&
                  (row.original.request_type === "mmt" ||
                    row.original.request_type === "glory") && (
                    <Button
                      className="px-2 py-2 my-2 table-button-style"
                      theme="dark"
                      onClick={() => {
                        this.viewClick(row.original);
                      }}
                    >
                      ASSIGN
                    </Button>
                  )}

                <Button
                  className="px-2 py-2 my-2 table-button-style"
                  theme="dark"
                  onClick={() => {
                    this.handleClick(row.original);
                  }}
                >
                  VIEW
                </Button>
                <Button
                  className="px-2 py-2 my-2 table-button-style"
                  theme="dark"
                  onClick={() => this.handleCopyClick(row.original)}
                >
                  COPY INFO
                </Button>
                {/* {row.original.isInstantPayoutMarked === false && (
                  <Button
                    className="px-2 py-2 my-2 table-button-style"
                    theme="dark"
                    onClick={() => {
                      this.viewPayClick(row.original);
                    }}
                  >
                    UPDATE PAYOUT
                  </Button>
                )} */}
                {this.state.request_type === "requested" && (
                  <React.Fragment>
                    {row.original.isInstantPayoutMarked === true && (
                      <Button
                        className="px-2 py-2 my-2 table-button-style"
                        theme="dark"
                        disabled
                      >
                        PAYOUT MARKED
                      </Button>
                    )}
                  </React.Fragment>
                )}
              </div>
            );
          },
        },
      ];
    } else if (this.state.request_type === "canceled") {
      columns = [
        {
          Header: "Sl no",
          id: "serialno",
          style: {},
          Cell: (row) => {
            // console.log(row);
            return <div>{row.viewIndex + 1}</div>;
          },
        },
        {
          Header: "Trip ID",
          accessor: "trip_id",
        },
        {
          Header: "Source",
          accessor: "sname",
          style: { whiteSpace: "unset" },
        },
        {
          Header: "Destination",
          accessor: "dname",
          style: { whiteSpace: "unset" },
        },
        {
          Header: "Pickup time",
          id: "pickuptime",
          sortable: true,
          accessor: (d) =>
            typeof d.pickuptime === "string"
              ? new Date(d.pickuptime).getTime()
              : d.pickuptime,
          style: { whiteSpace: "unset" },
          Cell: (row) => {
            let date;
            // console.log(row.original.request_status);
            if (
              row.original.request_status === "ended" ||
              row.original.request_status === "Requested" ||
              row.original.request_status === "Confirmed" ||
              row.original.request_status === "notified" ||
              row.original.request_status === "started" ||
              row.original.request_status === "accepted" ||
              row.original.request_status === "requested"
            ) {
              date = row.original.pickuptime;
            } else {
              date = moment(new Date(row.original.pickuptime)).format(
                "DD/MM/YY [at] hh:mm A"
              );
            }

            return <div>{date}</div>;
          },
        },
        {
          Header: "Ride type",
          accessor: "ridetype",
          id: "ridetype",
          Cell: (row) => {
            let data = capitalizeFirstLetter(row.original.ridetype);
            if (data === "Outstation" || data === "outstation") {
              let tripType;
              if (row.original.tripType && row.original.tripType === "one_way")
                tripType = "One way";
              if (
                row.original.tripType &&
                row.original.tripType === "roundtrip"
              )
                tripType = "Round trip";

              return <div>{data + " - " + tripType}</div>;
            } else {
              return <div>{data}</div>;
            }
          },
        },
        {
          Header: "Vehicle type",
          accessor: "vehicle_type",
          Cell: (row) => {
            let data = capitalizeFirstLetter(row.original.vehicle_type);
            let data_desc =
              row.original && row.original.vehicle_type_desc
                ? row.original.vehicle_type_desc
                : "Normal";
            return <div>{`${data} - ${data_desc}`}</div>;
          },
        },
        {
          Header: "Vendor",
          accessor: "request_type",
          id: "request_type",
          Cell: (row) => {
            let data = capitalizeFirstLetter(row.original.request_type);
            return <div>{data}</div>;
          },
        },
        {
          Header: "Ride status",
          accessor: "request_status",
          Cell: (row) => {
            if (row.original.request_status === "consumercancelled") {
              return <div style={{ color: "#FF0000" }}>Consumer canceled</div>;
            } else if (row.original.request_status === "drivercancelled") {
              return <div style={{ color: "#FF0000" }}>Canceled via panel</div>;
            } else if (
              row.original.request_status === "ended" ||
              row.original.request_status === "completed"
            ) {
              return <div style={{ color: "#59E67F" }}>Completed</div>;
            } else if (
              row.original.request_status === "Requested" ||
              row.original.request_status === "requested"
            ) {
              return <div>Unassigned</div>;
            } else if (row.original.request_status === "Confirmed") {
              return <div style={{ color: "#ffa600" }}>Confirmed</div>;
            } else if (row.original.request_status === "accepted") {
              return <div style={{ color: "#FF8307" }}>Left for pickup</div>;
            } else if (row.original.request_status === "started") {
              return <div style={{ color: "#59E67F" }}>Started</div>;
            } else if (row.original.request_status === "notified") {
              return <div style={{ color: "#FF8307" }}>Notified</div>;
            } else {
              return <div>{row.original.request_status}</div>;
            }
          },
        },

        {
          Header: "Driver",
          id: "Driver",
          style: { whiteSpace: "unset" },
          Cell: (row) => {
            return (
              <React.Fragment>
                <p>{row.original.drivername}</p>
                <p
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() =>
                    this.openCallModal(row.original.driverphonenumber)
                  }
                >
                  {row.original.driverphonenumber}
                </p>
              </React.Fragment>
            );
          },
        },
        {
          Header: "Rider",
          id: "Rider",
          style: { whiteSpace: "unset" },
          Cell: (row) => {
            return (
              <React.Fragment>
                <p>{row.original.consumername}</p>
                <p
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  // onClick={() => this.openCallModal(row.original.phonenumber)}
                >
                  {row.original.phonenumber}
                </p>
              </React.Fragment>
            );
          },
        },
        {
          id: "edit",
          Header: "Actions",
          width: 150,
          Cell: (row) => {
            //  console.log(this);
            // console.log(row.original.request_status)
            return (
              <div className="d-flex flex-column">
                {(row.original.request_status === "Requested" ||
                  row.original.request_status === "requested") && (
                  <Button
                    className="px-2 py-2 my-2 table-button-style"
                    theme="dark"
                    onClick={() => {
                      this.viewClick(row.original);
                    }}
                  >
                    ASSIGN
                  </Button>
                )}
                <Button
                  className="px-2 py-2 my-2 table-button-style"
                  theme="dark"
                  onClick={() => {
                    this.handleClick(row.original);
                  }}
                >
                  VIEW
                </Button>
              </div>
            );
          },
        },
      ];
    } else {
      columns = [
        {
          Header: "Sl no",
          id: "serialno",
          style: {},
          Cell: (row) => {
            // console.log(row);
            return <div>{row.viewIndex + 1}</div>;
          },
        },
        {
          Header: "Trip ID",
          accessor: "trip_id",
        },
        {
          Header: "Source",
          accessor: "sname",
          style: { whiteSpace: "unset" },
        },
        {
          Header: "Destination",
          accessor: "dname",
          style: { whiteSpace: "unset" },
        },
        {
          Header: "Pickup time",
          id: "pickuptime",
          sortable: true,
          accessor: (d) =>
            typeof d.pickuptime === "string"
              ? new Date(
                  moment(d.pickuptime, "DD-MM-YY [at] hh:mm:ss A")
                ).getTime()
              : d.pickuptime,
          style: { whiteSpace: "unset" },
          Cell: (row) => {
            let date;
            // console.log(row.original.request_status);
            if (
              row.original.request_status === "ended" ||
              row.original.request_status === "Requested" ||
              row.original.request_status === "Confirmed" ||
              row.original.request_status === "notified" ||
              row.original.request_status === "started" ||
              row.original.request_status === "accepted" ||
              row.original.request_status === "requested"
            ) {
              date = row.original.pickuptime;
            } else {
              date = moment(new Date(row.original.pickuptime)).format(
                "DD-MM-YY [at] hh:mm:ss A"
              );
            }

            return <div>{date}</div>;
          },
        },
        {
          Header: "Ride type",
          accessor: "ridetype",
          id: "ridetype",
          Cell: (row) => {
            let data = capitalizeFirstLetter(row.original.ridetype);
            if (data === "Outstation" || data === "outstation") {
              let tripType;
              if (row.original.tripType && row.original.tripType === "one_way")
                tripType = "One way";
              if (
                row.original.tripType &&
                row.original.tripType === "roundtrip"
              )
                tripType = "Round trip";

              return <div>{data + " - " + tripType}</div>;
            } else {
              return <div>{data}</div>;
            }
          },
        },
        {
          Header: "Vehicle type",
          accessor: "vehicle_type",
          Cell: (row) => {
            let data = capitalizeFirstLetter(row.original.vehicle_type);
            let data_desc =
              row.original && row.original.vehicle_type_desc
                ? row.original.vehicle_type_desc
                : "Normal";
            return <div>{`${data} - ${data_desc}`}</div>;
          },
        },
        {
          Header: "Vendor",
          accessor: "booking_source",
          id: "booking_source",
          Cell: (row) => {
            let data;
            if (
              this.state.request_type === "assigned" ||
              this.state.request_type === "confirmed"
            ) {
              data = data = capitalizeFirstLetter(
                row.original.request_type ? row.original.request_type : "-"
              );
            } else {
              data = capitalizeFirstLetter(
                row.original.booking_source ? row.original.booking_source : "-"
              );
            }

            return <div>{data}</div>;
          },
        },
        {
          Header: "Ride status",
          accessor: "request_status",
          Cell: (row) => {
            if (row.original.request_status === "consumercancelled") {
              return <div style={{ color: "#FF0000" }}>Consumer canceled</div>;
            } else if (row.original.request_status === "drivercancelled") {
              return <div style={{ color: "#FF0000" }}>Canceled via panel</div>;
            } else if (
              row.original.request_status === "ended" ||
              row.original.request_status === "completed"
            ) {
              return <div style={{ color: "#59E67F" }}>Completed</div>;
            } else if (
              row.original.request_status === "Requested" ||
              row.original.request_status === "requested"
            ) {
              return <div>Unassigned</div>;
            } else if (row.original.request_status === "Confirmed") {
              return <div style={{ color: "#59E67F" }}>Confirmed</div>;
            } else if (row.original.request_status === "accepted") {
              return <div style={{ color: "#FF8307" }}>Left for pickup</div>;
            } else if (row.original.request_status === "started") {
              return <div style={{ color: "#59E67F" }}>Started</div>;
            } else if (row.original.request_status === "notified") {
              return <div style={{ color: "#FF8307" }}>Notified</div>;
            } else {
              return <div>{row.original.request_status}</div>;
            }
          },
        },
        {
          Header: "Driver",
          id: "Driver",
          style: { whiteSpace: "unset" },
          Cell: (row) => {
            return (
              <React.Fragment>
                <p className="mb-1">{row.original.drivername}</p>
                <p
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() =>
                    this.openCallModal(row.original.driverphonenumber)
                  }
                >
                  {row.original.driverphonenumber}
                </p>
              </React.Fragment>
            );
          },
        },
        {
          Header: "Rider",
          id: "Rider",
          style: { whiteSpace: "unset" },
          Cell: (row) => {
            return (
              <React.Fragment>
                <p className="mb-1 w-100">{row.original.consumername}</p>
                <p
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  // onClick={() => this.openCallModal(row.original.phonenumber)}
                >
                  {row.original.phonenumber}
                </p>
              </React.Fragment>
            );
          },
        },
        {
          id: "edit",
          Header: "Actions",
          width: 150,
          Cell: (row) => {
            //  console.log(this);
            // console.log(row.original.request_status)
            return (
              <div className="d-flex flex-column">
                {(row.original.request_status === "Requested" ||
                  row.original.request_status === "requested") && (
                  <Button
                    className="px-2 py-2 my-2 table-button-style"
                    theme="dark"
                    onClick={() => {
                      this.viewClick(row.original);
                    }}
                  >
                    ASSIGN
                  </Button>
                )}
                <Button
                  className="px-2 py-2 my-2 table-button-style"
                  theme="dark"
                  onClick={() => {
                    this.handleClick(row.original);
                  }}
                >
                  VIEW
                </Button>
                {/* {row.original.isInstantPayoutMarked === false && (
                  <Button
                    className="px-2 py-2 my-2 table-button-style"
                    theme="dark"
                    onClick={() => {
                      this.viewPayClick(row.original);
                    }}
                  >
                    UPDATE PAYOUT
                  </Button>
                )} */}

                {this.state.request_type === "ongoing" && (
                  <React.Fragment>
                    {row.original.isInstantPayoutMarked === true ? (
                      <Button
                        className="px-2 py-2 my-2 table-button-style"
                        theme="dark"
                        disabled
                      >
                        PAYMENT MARKED
                      </Button>
                    ) : (
                      <React.Fragment>
                        {(sessionStorage.getItem("roletype") === "manager" ||
                          sessionStorage.getItem("roletype") === "teamlead" ||
                          sessionStorage.getItem("roletype") ===
                            "assistantmanager") && (
                          <Button
                            className="px-2 py-2 my-2 table-button-style"
                            theme="dark"
                            onClick={() => {
                              this.viewInstantClick(row.original);
                            }}
                          >
                            INSTANT PAYMENT
                          </Button>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                {this.state.request_type === "completed" && (
                  <React.Fragment>
                    {row.original.isInstantPayoutMarked === true && (
                      <Button
                        className="px-2 py-2 my-2 table-button-style"
                        theme="dark"
                        disabled
                      >
                        PAYMENT MARKED
                      </Button>
                    )}
                  </React.Fragment>
                )}
              </div>
            );
          },
        },
      ];
    }

    const payoutColumn = [
      {
        Header: (x) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.bulkSelectAll === 1}
              ref={(input) => {
                if (input) {
                  input.indeterminate = this.state.bulkSelectAll === 2;
                }
              }}
              onChange={() => this.bulkToggleSelectAll()}
            />
          );
        },
        id: "serialno",
        style: {},
        Cell: (row) => {
          // console.log(row);
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.bulkSelect[row.original.trip_id] === true}
              onChange={() => this.toggleRowBulk(row.original)}
            />
          );
        },
      },
      {
        Header: "Trip ID",
        accessor: "trip_id",
      },
      {
        Header: "Source",
        accessor: "sname",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Destination",
        accessor: "dname",
        id: "dname",
        Cell: (row) => {
          if (
            row.original.dname === undefined ||
            row.original.dname === "" ||
            row.original.dname === null
          ) {
            return <div>-</div>;
          } else {
            return <div>{row.original.dname}</div>;
          }
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Pickup time",
        id: "pickuptime",
        // accessor: (d) =>
        //   typeof d.pickuptime === "string"
        //     ? new Date(
        //         moment(d.pickuptime, "DD-MM-YY [at] hh:mm:ss A")
        //       ).getTime()
        //     : d.pickuptime,
        sortable: true,
        style: { whiteSpace: "unset" },
        Cell: (row) => {
          let date;
          // console.log(row.original.request_status);
          if (
            row.original.request_status === "ended" ||
            row.original.request_status === "Requested" ||
            row.original.request_status === "Confirmed" ||
            row.original.request_status === "notified" ||
            row.original.request_status === "started" ||
            row.original.request_status === "accepted" ||
            row.original.request_status === "requested"
          ) {
            date = row.original.pickuptime;
            console.log(date);
          } else {
            date = moment(new Date(row.original.pickuptime)).format(
              "DD-MM-YY [at] hh:mm:ss A"
            );
          }

          return <div>{date}</div>;
        },
      },
      {
        Header: "Ride type",
        accessor: "ridetype",
        id: "ridetype",
        Cell: (row) => {
          let data = capitalizeFirstLetter(row.original.ridetype);
          return <div>{data}</div>;
        },
      },
      {
        Header: "Vehicle type",
        accessor: "vehicle_type",
      },
    ];

    const { rideItems: items } = this.state;
    const dataToDisplay = this.state.searchInput.length
      ? this.state.filteredData
      : this.state.data;

    const payDataToDisplay = this.state.paySearchInput.length
      ? this.state.payFilteredData
      : this.state.bulkData;
    const subDataToDisplay = this.state.searchDrivInput.length
      ? this.state.filteredDrivData
      : this.state.dData;

    if (this.state.changeDiv === false || this.state.changeDiv === undefined) {
      return (
        <Col className="col-md-12 px-0 px-md-1">
          {/* {this.state.openMod === true && (
            <Modal onhandleChange={this.viewClick} />
          )} */}
          <Card className="my-4 mx-1 mx-md-2">
            <CardHeader className="my-4">
              <Row>
                <Col className="col-12 col-md-9">
                  <Nav pills className="rounded d-none d-sm-none d-md-block ">
                    <NavItem className="d-flex flex-wrap border solid rounded ">
                      {items.map((stats, idx) => (
                        <NavTab
                          id={idx}
                          variation="1"
                          label={stats.label}
                          onClickChange={this.handleClickChange}
                          state={this.state}
                          attr={
                            this.state.index === idx
                              ? "active  nav-item-style Navlink-style font-props"
                              : "nav-item-style Navlink-style font-props"
                          }
                        />
                      ))}
                    </NavItem>
                  </Nav>
                  <Nav pills className="rounded d-block d-sm-block d-md-none ">
                    <NavItem>
                      <Col className="col-sm d-flex flex-wrap ">
                        {items.map((stats, idx) => (
                          <NavTab
                            id={idx}
                            variation="1"
                            label={stats.label}
                            onClickChange={this.handleClickChange}
                            state={this.state}
                            attr={
                              this.state.index === idx
                                ? "active nav-item-style Navlink-style"
                                : "nav-item-style Navlink-style"
                            }
                          />
                        ))}
                      </Col>
                    </NavItem>
                  </Nav>
                </Col>
                <Col className="col-12 col-md-3 d-flex justify-content-end ">
                  <Button
                    onClick={this.handleRefreshClick}
                    theme="dark"
                    className="my-2"
                  >
                    <i className="fas fa-sync-alt" />
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-0">
              {/* <Row className="mx-md-2 d-flex flex-row-reverse">
                {this.datesAreOnSameDay(this.state.tripDate, new Date()) &&
                  (this.state.request_type === "requested" ||
                    this.state.request_type === "assigned") &&
                  (sessionStorage.getItem("roletype") === "manager" ||
                    sessionStorage.getItem("roletype") === "teamlead" ||
                    sessionStorage.getItem("roletype") ===
                      "assistantmanager") && (
                    <Col className="col-12 col-md-3 d-flex justify-content-center justify-content-md-end py-3 mt-1">
                      <Button
                        theme="dark"
                        className="my-2 py-3 w-100 mx-3"
                        onClick={(e) => {
                          this.setState({ bulkPayment: true }, () => {
                            this.getRidesForBulk(this.state.request_type);
                          });
                        }}
                      >
                        MARK INSTANT PAYOUT
                      </Button>
                    </Col>
                  )}
                <Col className="col-12 col-md-2 d-flex  justify-content-center justify-content-md-end py-3 mt-1">
                  <Button
                    theme="dark"
                    className="my-2 py-3 w-100 mx-3"
                    onClick={() => this.initiateCallModal()}
                  >
                    PLACE A CALL
                  </Button>
                </Col>
              </Row> */}
              <Row className="px-4 ">
                <Col
                  className="col-md-3 col-lg-3 d-none d-sm-none d-md-block d-xl-block pt-md-2"
                  style={{ zIndex: "999" }}
                >
                  {/* <div className="w-75"> */}
                  {this.state.request_type !== "ongoing" && (
                    <React.Fragment>
                      <FormGroup>
                        <div
                          className=" mb-2"
                          style={{
                            width: "100%",
                            minWidth: "150px",
                            textAlign: "center",
                            verticalAlign: "center",
                          }}
                        >
                          <i
                            id="ridesCountSPrevDate"
                            className="fas fa-arrow-left float-left"
                            onClick={this.ridesCountSPrevDate}
                            style={{ marginTop: "4px" }}
                          />
                          <a
                            id="ridesCountSDateName"
                            className="mt-0"
                            style={{
                              fontSize: "calc(0.4rem + 1vmin)",
                            }}
                          >
                            {this.state.daySelected}
                          </a>
                          {this.state.request_type === "completed" ? (
                            this.state.tripDate.getTime() >=
                            new Date().getTime() ? (
                              <div></div>
                            ) : (
                              <i
                                id="ridesCountSNextDate"
                                style={{ marginTop: "4px" }}
                                className="fas fa-arrow-right float-right"
                                onClick={this.ridesCountSNextDate}
                              />
                            )
                          ) : (
                            <i
                              id="ridesCountSNextDate"
                              style={{ marginTop: "4px" }}
                              className="fas fa-arrow-right float-right"
                              onClick={this.ridesCountSNextDate}
                            />
                          )}
                        </div>
                        {this.state.request_type === "completed" && (
                          <DatePicker
                            className="w-100 date-picker-custom"
                            format="dd/MM/yyyy"
                            onChange={this.handleTime}
                            value={this.state.tripDate}
                            clearIcon={null}
                            maxDate={new Date()}
                            calendarIcon={<i className="far fa-calendar-alt" />}
                          />
                        )}
                        {this.state.request_type !== "completed" && (
                          <DatePicker
                            className="w-100 date-picker-custom"
                            format="dd/MM/yyyy"
                            onChange={this.handleTime}
                            value={this.state.tripDate}
                            clearIcon={null}
                            calendarIcon={<i className="far fa-calendar-alt" />}
                          />
                        )}
                      </FormGroup>
                    </React.Fragment>
                  )}
                  {/* </div> */}
                </Col>
                <Col className="col-12 mx-auto mb-4 d-block d-sm-block d-md-none d-xl-none">
                  {/* <div className="w-50 mx-auto"> */}
                  {this.state.request_type !== "ongoing" && (
                    <DatePicker
                      format="dd/MM/yyyy"
                      className="w-100 date-picker-custom"
                      onChange={this.handleTime}
                      value={this.state.tripDate}
                      clearIcon={null}
                      calendarIcon={<i className="far fa-calendar-alt" />}
                    />
                  )}
                  {/* </div> */}
                </Col>
                <Col
                  className="col-12 col-md-3 col-lg-3"
                  style={{ zIndex: 10 }}
                >
                  {/* {console.log(this.state.select2)} */}
                  <FormGroup style={{ marginTop: "4px" }}>
                    <label style={{ fontWeight: 500 }}>Select trip type</label>
                    <Select
                      onChange={(entry) => {
                        let type;
                        if (entry.value === "all") {
                          type = "All";
                        } else if (entry.value === "urban") {
                          type = "booknow";
                        } else if (entry.value === "airport") {
                          type = "scheduled";
                        } else if (entry.value === "outstation") {
                          type = "outstation";
                        } else if (entry.value === "rental") {
                          type = "rental";
                        }
                        this.setState({ select2: entry }, () => {
                          this.tick(this.state.request_type);
                        });

                        //   this.onFilteredChangeCustom(type, "ridetype");
                      }}
                      value={this.state.select2}
                      multi={true}
                      placeholder="All"
                      options={[
                        { value: "All", label: "All" },
                        { value: "airport", label: "Airport" },
                        { value: "outstation", label: "Outstation" },
                        { value: "rental", label: "Rental" },
                        { value: "urban", label: "Local rides" },
                      ]}
                    />
                  </FormGroup>
                </Col>
                {/* <Col className="col-12 col-md-3">
                  <FormGroup style={{ marginTop: "4px" }}>
                    <label style={{ fontWeight: 500 }}>
                      Filter based on region
                    </label>
                    <FormSelect
                      value={this.state.operating_region}
                      onChange={this.handleSelectChange}
                      style={{ fontWeight: 700, textAlignLast: "center" }}
                    >
                      <option value="all">All</option>
                      <option value="Bangalore">Bangalore</option>
                      <option value="Hyderabad">Hyderabad</option>
                    </FormSelect>
                  </FormGroup>
                </Col> */}
                <Col className="col-12 col-md-3">
                  <FormGroup style={{ marginTop: "4px" }}>
                    <label style={{ fontWeight: 500 }}>
                      Filter based on vendor
                    </label>
                    <FormSelect
                      value={this.state.vendor}
                      onChange={this.handleVendorChange}
                      style={{ fontWeight: 700, textAlignLast: "center" }}
                    >
                      <option value="">All</option>
                      {/* <option value="mmt">MMT</option>
                      <option value="gozo">GOZO</option> */}
                      <option value="glory">GOZO</option>
                    </FormSelect>
                  </FormGroup>
                </Col>
                {/* <Col className="col-12 col-md-2" /> */}
                {/* {sessionStorage.getItem("roletype") === "manager" && ( */}
                <Col className="col-12 col-md-3  ">
                  <FormGroup style={{ marginTop: "4px" }}>
                    <label>Search for driver:</label>
                    <Search
                      data={this.state.data}
                      columns={columns}
                      handleSetFilteredData={this.handleSetFilteredData}
                      handleSetSearchInput={this.handleSetSearchInput}
                    />
                  </FormGroup>
                </Col>

                {/* )} */}
                {/* 
                {sessionStorage.getItem("roletype") !== "manager" && (
                  <Col className="col-12 col-md-3 "></Col>
                )} */}
              </Row>

              <hr className="my-3" />
              <ReactTable
                data={dataToDisplay}
                noDataText="No data to display"
                filtered={this.state.filterOption}
                onFilteredChange={(filtered, column, value) => {
                  this.onFilteredChangeCustom(
                    value,
                    column.id || column.accessor
                  );
                }}
                defaultFilterMethod={(filter, row, column) => {
                  // console.log(row);
                  // console.log(column);
                  // console.log(filter);
                  const id = filter.pivotId || filter.id;
                  // console.log(id);
                  if (typeof filter.value === "object") {
                    // console.log(row[id]);
                    return row[id] !== undefined
                      ? filter.value.indexOf(row[id]) > -1
                      : true;
                  } else {
                    // console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
                    // console.log(filter);
                    if (filter.value === "All") {
                      // console.log("nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");
                      // console.log(row[id]);
                      return row[id] !== undefined
                        ? String(row[id]).indexOf(filter.value) === -1
                        : true;
                    } else {
                      // console.log(row[id]);
                      return row[id] !== undefined
                        ? String(row[id]).indexOf(filter.value) > -1
                        : true;
                    }
                  }
                }}
                columns={columns}
                className="text-center mx-0 react-table-style -striped -highlight"
                PaginationComponent={Pagination}
                // defaultSorted={[
                //   {
                //     id: "pickuptime",
                //     desc: false,
                //   },
                // ]}
                minRows={dataToDisplay.length > 0 ? 0 : 4}
                getTrProps={(state, rowInfo) => {
                  if (rowInfo) {
                    let id = rowInfo.original.trip_id;
                    let totalCount = this.state.rowsToDisplay;
                    let redCount = this.state.rowsToRed;
                    if (
                      totalCount.indexOf(rowInfo.original.trip_id) !== -1 &&
                      (rowInfo.original.request_status === "Requested" ||
                        rowInfo.original.request_status === "requested")
                    ) {
                      // console.log(rowInfo);

                      return {
                        // style: {
                        //   border: "3px solid #FFD939",
                        //   backgroundColor: "#FFFDF5",
                        // },
                        className: "blinkYellow",
                        id: id,
                      };
                    }
                    // else {
                    //   // if (
                    //   //   rowInfo.original.request_type !== "mmt" &&
                    //   //   rowInfo.original.ridetype === "booknow" &&
                    //   //   (rowInfo.original.request_status === "Requested" ||
                    //   //     rowInfo.original.request_status === "Confirmed")
                    //   // ) {
                    //   //   return {
                    //   //     style: {
                    //   //       backgroundColor: "#5cf64a",
                    //   //     },
                    //   //     id: id,
                    //   //   };
                    //   // } else {
                    //   //   return {
                    //   //     id: id,
                    //   //   };
                    //   // }
                    // }
                    else if (
                      redCount.indexOf(rowInfo.original.trip_id) !== -1 &&
                      (rowInfo.original.request_status === "Requested" ||
                        rowInfo.original.request_status === "requested")
                    ) {
                      return {
                        // style: {
                        //   borderLeft: "5px solid #FF0000",
                        //   backgroundColor: "#FFF2F2",
                        // },
                        className: "blinkRed",
                        id: id,
                      };
                    } else {
                      return {
                        id: id,
                      };
                    }
                  }

                  return {};
                }}
              />
            </CardBody>
          </Card>
          <Modal
            show={this.state.gozoConfirmModal}
            onHide={this.openConfirmModal}
            size="md"
            centered
            modalClassName="modal-overflow-control"
          >
            <ModalHeader closeButton style={{ borderBottom: "none" }}>
              Confirm Gozo booking
            </ModalHeader>
            {console.log(this.state.tripData)}
            <ModalBody className="px-1 pb-0">
              {/* {console.log(this.state.dData)} */}
              {/* {console.log(this.state.dutyState)} */}
              <Row className="px-5  d-flex justify-content-center">
                <Col className="col-12 col-md-12">
                  <p className="question-text-style">
                    Are you sure you want to confirm the booking with the
                    provided fare - Rs.
                    {this.state.tripData
                      ? this.state.tripData.approx_fare
                      : "-"}
                    ?
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter style={{ border: "none" }}>
              <Col className="col-12  d-flex justify-content-center pb-2 ">
                <Button
                  theme="dark"
                  size="lg"
                  className="mx-2 login-button-new"
                  style={{
                    borderRadius: "5px",
                    fontSize: "calc(0.4rem + 1vmin)",
                  }}
                  onClick={this.confirmBookingToGozo}
                >
                  CONFIRM
                </Button>
              </Col>
            </ModalFooter>
          </Modal>
          <Modal
            show={this.state.instantPayment}
            onHide={this.handleInstantClose}
            size="lg"
            centered
            modalClassName="modal-overflow-control"
          >
            <ModalHeader closeButton style={{ borderBottom: "none" }}>
              MARK FOR INSTANT PAYMENT
            </ModalHeader>

            <ModalBody className="px-1">
              {/* {console.log(this.state.dData)} */}
              {/* {console.log(this.state.dutyState)} */}
              <Row className="px-5 mb-2">
                <Col className="col-12 col-md-12">
                  <p className="question-text-style">
                    Do you want to mark this ride for instant payments?
                  </p>
                </Col>
              </Row>
              <Row className="px-5">
                {this.props.instantPay.map((stats, idx) => (
                  <Col
                    className="col-md-6 col-12 mb-4 "
                    key={idx}
                    {...stats.attrs}
                  >
                    {/* {console.log(data[stats.apiID])} */}
                    <InputData
                      id={`small-stats-${idx}`}
                      variation="1"
                      label={stats.label}
                      value={this.state.instatnPayArr[stats.accessor]}
                      stats={stats.stats}
                    />
                  </Col>
                ))}
              </Row>
            </ModalBody>
            <ModalFooter>
              <Col className="col-12  d-flex justify-content-center my-2">
                <Button
                  theme="dark"
                  size="lg"
                  className="mx-2 login-button-new"
                  style={{
                    borderRadius: "5px",
                    fontSize: "calc(0.35rem + 1vmin)",
                  }}
                  onClick={this.handleInstantPayment}
                >
                  MARK PAYMENT
                </Button>
              </Col>
            </ModalFooter>
          </Modal>

          <Modal
            show={this.state.callModal}
            onHide={this.closeCallModal}
            size="md"
            centered
            modalClassName="modal-overflow-control"
          >
            <ModalHeader closeButton style={{ borderBottom: "none" }}>
              Call bridge number
            </ModalHeader>

            <ModalBody className="px-1 pb-0">
              {/* {console.log(this.state.dData)} */}
              {/* {console.log(this.state.dutyState)} */}
              <Row className="px-5  d-flex justify-content-center">
                <Col className="col-12 col-md-9">
                  <FormGroup>
                    <label>Choose the call bridging number</label>
                    <FormSelect
                      value={this.state.callAgent}
                      onChange={this.handleAgentSelect}
                      style={{ fontWeight: 700, textAlignLast: "center" }}
                    >
                      <option value="">Click here to select the number</option>
                      {this.state.agentList.map((data, index) => (
                        <option value={data.eid}>
                          {data.agent_name + " - " + data.follow_me_number}
                        </option>
                      ))}
                    </FormSelect>
                  </FormGroup>
                  <p className="question-text-style"></p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter style={{ border: "none" }}>
              <Col className="col-12  d-flex justify-content-center pb-2 ">
                <Button
                  theme="dark"
                  size="lg"
                  className="mx-2 login-button-new"
                  style={{
                    borderRadius: "5px",
                    fontSize: "calc(0.4rem + 1vmin)",
                  }}
                  onClick={() => this.placeCall()}
                >
                  PLACE CALL
                </Button>
              </Col>
            </ModalFooter>
          </Modal>

          <Modal
            show={this.state.manualCall}
            onHide={this.closeInitiateModal}
            size="md"
            centered
            modalClassName="modal-overflow-control"
          >
            <ModalHeader closeButton style={{ borderBottom: "none" }}>
              Call bridge number
            </ModalHeader>
            <form onSubmit={this.manualCallAPI}>
              <ModalBody className="px-1 pb-0">
                {/* {console.log(this.state.dData)} */}
                {/* {console.log(this.state.dutyState)} */}
                <Row className="px-5  d-flex justify-content-center">
                  <Col className="col-12 col-md-9 mb-2">
                    <FormGroup>
                      <label>Enter a number to call</label>
                      <FormInput
                        onChange={(e) =>
                          this.handleManualPhone(e, "inputNumber")
                        }
                        value={this.state.inputNumber}
                        minLength={10}
                        maxLength={10}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col className="col-12 col-md-9">
                    <FormGroup>
                      <label>Choose the call bridging number</label>
                      <FormSelect
                        value={this.state.callAgent}
                        onChange={this.handleAgentSelect}
                        style={{ fontWeight: 700, textAlignLast: "center" }}
                        required
                      >
                        <option value="">
                          Click here to select the number
                        </option>
                        {this.state.agentList.map((data, index) => (
                          <option value={data.eid}>
                            {data.agent_name + " - " + data.follow_me_number}
                          </option>
                        ))}
                      </FormSelect>
                    </FormGroup>
                    <p className="question-text-style"></p>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter style={{ border: "none" }}>
                <Col className="col-12  d-flex justify-content-center pb-2 ">
                  <Button
                    theme="dark"
                    size="lg"
                    className="mx-2 login-button-new"
                    style={{
                      borderRadius: "5px",
                      fontSize: "calc(0.4rem + 1vmin)",
                    }}
                  >
                    PLACE CALL
                  </Button>
                </Col>
              </ModalFooter>
            </form>
          </Modal>

          <Modal
            show={this.state.openPayMod}
            onHide={this.resetPayMod}
            size="lg"
            centered
            modalClassName="modal-overflow-control"
          >
            <ModalHeader closeButton style={{ borderBottom: "none" }}>
              UPDATE PAYOUT
            </ModalHeader>

            <ModalBody className="px-1">
              {/* {console.log(this.state.dData)} */}
              {/* {console.log(this.state.dutyState)} */}

              <React.Fragment>
                <form onSubmit={this.callUpdatePayment}>
                  {/* <Row className="mt-4 w-100 px-3">
                    <Col className="col-12 col-md-12 mb-4 ml-3">
                      <FormGroup>
                        <label>
                          <strong>Trip id - </strong>
                          {this.state.tripDataPay.trip_id}
                        </label>
                      </FormGroup>
                    </Col>
                    <Col className="col-12 col-md-4 mb-4 ml-3">
                     
                      <label className="mb-2" style={{ fontSize: "1rem" }}>
                        <strong>Enter the amount offered</strong>
                      </label>
                      <FormInput
                        id="offeredAmount"
                        className="py-3"
                        type="number"
                        placeholder="0"
                        required
                        onChange={this.handlePayOfferedAmount}
                        value={this.state.payofferedAmount}
                      />
                    </Col>
                  </Row>
                  <hr /> */}

                  <Row className="mt-4 mx-2">
                    <Col className="col-12 col-md-4  my-2">
                      <label className="mb-2" style={{ fontSize: "1rem" }}>
                        <strong>Enter the amount sent</strong>
                      </label>
                      <FormInput
                        id="amountsent"
                        className="py-3"
                        type="number"
                        placeholder="0"
                        required
                        onChange={this.handlePaidAmount}
                        value={this.state.amountSent}
                      />
                    </Col>
                    <Col className="col-12 col-md-4  my-2">
                      <FormGroup>
                        <label style={{ fontSize: "1rem" }}>
                          <strong>Select a payment mode</strong>
                        </label>
                        <FormSelect
                          value={this.state.modeofpayment}
                          onChange={this.handleSelectModeChange}
                          style={{
                            fontWeight: 700,
                            textAlignLast: "center",
                            height: "calc(3.16rem + 2px)",
                          }}
                          required
                        >
                          <option value="">Choose payment mode</option>
                          <option value="cash">Cash</option>
                          <option value="google_pay">Google Pay</option>
                          <option value="phone_pe">Phone Pe</option>
                          <option value="paytm">Paytm</option>
                          <option value="cashfree">Cashfree</option>
                        </FormSelect>
                      </FormGroup>
                    </Col>
                    <Col className="col-12 col-md-4  my-2">
                      <label className="mb-2" style={{ fontSize: "1rem" }}>
                        <strong>Paid to (Driver number)</strong>
                      </label>
                      <FormInput
                        id="paidtodriver"
                        className="py-3"
                        type="text"
                        placeholder="Enter the driver's number"
                        required
                        onChange={this.handlePaidtoAmount}
                        value={this.state.paidtodriver}
                      />
                    </Col>
                    <Col className="col-12 col-md-4  my-2">
                      <label className="mb-2" style={{ fontSize: "1rem" }}>
                        <strong>Paid from (Agent name)</strong>
                      </label>
                      <FormInput
                        id="paidbyagent"
                        className="py-3"
                        type="text"
                        placeholder="Enter the agent's name"
                        required
                        onChange={this.handlePaidBy}
                        value={this.state.paidbyagent}
                      />
                    </Col>
                    <Col className="col-12 col-md-4  my-2">
                      <label className="mb-2" style={{ fontSize: "1rem" }}>
                        <strong>Paid on</strong>
                      </label>
                      <ReactDatepicker
                        className="form-control py-3 text-center"
                        format="dd/MM/yyyy"
                        selected={this.state.paytime}
                        onChange={this.handlePayTime}
                        placeholderText="Enter paid date"
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4 mx-2">
                    <Col className="col-12  d-flex justify-content-center my-2">
                      <Button
                        theme="dark"
                        size="lg"
                        className="mx-2 login-button-new"
                        style={{
                          borderRadius: "5px",
                          fontSize: "calc(0.35rem + 1vmin)",
                        }}
                      >
                        UPDATE PAYMENT
                      </Button>
                    </Col>
                  </Row>
                </form>
              </React.Fragment>
            </ModalBody>
          </Modal>
          <Modal
            show={this.state.openMod}
            onHide={this.resetMod}
            size="lg"
            centered
            modalClassName="modal-overflow-control"
          >
            <ModalHeader closeButton style={{ borderBottom: "none" }}>
              Assign driver
            </ModalHeader>

            <Nav tabs className="my-3 ">
              <NavItem className="d-flex ml-3">
                {this.props.assignDriv.map((stats, idx) => (
                  <div key={idx}>
                    <ModNavTab
                      id={idx}
                      variation="1"
                      label={stats.label}
                      state={this.state}
                      onClickChange={this.handleClickChange}
                      attr={this.state.navIndex === idx ? "active " : ""}
                    />
                  </div>
                ))}
              </NavItem>
            </Nav>

            <ModalBody className="px-1">
              <Row className="mb-2">
                <Col className="col-12 col-md-8 col-lg-8 d-flex justify-content-start ">
                  {this.state.dData && this.state.dData.length > 0 && (
                    <SearchDriver
                      data={this.state.dData}
                      columns={
                        this.state.dutyState === "onduty"
                          ? assignDrivOndutyCol
                          : assignDrivCol
                      }
                      handleSetFilteredData={this.handleDrivFilteredData}
                      handleSetSearchInput={this.handleDrivSearchInput}
                      type={
                        this.state.dutyState === "onduty" ? "onduty" : "nearby"
                      }
                    />
                  )}
                </Col>
                <Col className="col-12 col-md-4 col-lg-4 d-flex justify-content-end">
                  <Button
                    className="my-1"
                    theme="dark"
                    onClick={this.refreshDrivers}
                  >
                    <i className="fas fa-sync-alt" />
                  </Button>
                </Col>
              </Row>
              {/* {console.log(this.state.dData)} */}
              {/* {console.log(this.state.dutyState)} */}
              <ReactTable
                PaginationComponent={Pagination}
                data={subDataToDisplay}
                noDataText="No data to display"
                columns={
                  this.state.dutyState === "onduty"
                    ? assignDrivOndutyCol
                    : assignDrivCol
                }
                className="text-center -striped -highlight"
                minRows={
                  subDataToDisplay && subDataToDisplay.length > 0 ? 0 : 4
                }
              />
              <React.Fragment>
                <Button
                  block
                  theme="dark"
                  onClick={this.toggle}
                  className="w-100 mt-5  assign-button-style"
                >
                  Ride details
                  <i
                    className={
                      this.state.showTripDetails === false
                        ? "fas fa-chevron-down float-right"
                        : "fas fa-chevron-up float-right"
                    }
                  />
                </Button>
                <Collapse
                  open={this.state.collapse}
                  className="px-3 py-2"
                  style={{ backgroundColor: "#DDDDDD" }}
                >
                  <div className="mx-md-3">
                    {/* {console.log(this.state)} */}
                    <span className="assign-trip_id">
                      Trip ID : {this.state.tripData.trip_id}
                    </span>
                    <Row
                      className="w-100 mx-auto mt-3 assign-col-data py-2"
                      style={{ backgroundColor: "#F5F6F8" }}
                    >
                      <Col className="col-4 ">Source</Col>
                      <Col className="col-4">Destination</Col>
                      <Col className="col-4">Pickup time</Col>
                    </Row>
                    <Row className="mt-3 assign-row-data px-2">
                      <Col className="col-4">{this.state.tripData.sname}</Col>
                      <Col className="col-4">{this.state.tripData.dname}</Col>
                      <Col className="col-4">
                        {this.state.tripData.pickuptime}
                      </Col>
                    </Row>
                  </div>
                </Collapse>
                <form onSubmit={this.callGCM}>
                  <Row className="mt-4 w-100">
                    {this.state.showOffered === true && (
                      <Col className="col-12 col-md-5 mb-4 ml-3">
                        {/* {console.log(this.state.tripData)} */}
                        <label className="mb-2" style={{ fontSize: "1rem" }}>
                          <strong>Enter the amount offered</strong>
                        </label>
                        <FormInput
                          id="offeredAmount"
                          type="number"
                          placeholder="0"
                          required
                          onChange={this.handleOnChange}
                          value={this.state.offeredAmount}
                          min={0}
                          max={9999}
                        />
                      </Col>
                    )}
                  </Row>
                  <Row className="mt-4 mx-2">
                    <Col className="col-12  d-flex justify-content-center my-2">
                      <Button
                        theme="dark"
                        disabled={this.state.disableButton}
                        size="lg"
                        className="mx-2 login-button-new"
                        style={{
                          borderRadius: "5px",
                          fontSize: "calc(0.35rem + 1vmin)",
                        }}
                      >
                        SEND NOTIFICATION
                      </Button>
                    </Col>
                    {/* <Col className="col-12  d-flex justify-content-center my-2">
                                <Button
                                  theme="dark"
                                  size="lg"
                                  className="mx-2 login-button-new"
                                  onClick={this.resetMod}
                                  style={{
                                    borderRadius: "5px",
                                    fontSize: "calc(0.35rem + 1vmin)",
                                  }}
                                >
                                  CLOSE
                                </Button>
                              </Col> */}
                  </Row>
                </form>
                {this.state.sentSuccess === true && (
                  <Row className="mt-3 mx-3">
                    <Col
                      className="col-12 d-flex justify-content-center"
                      style={{ fontSize: "1.3rem", color: "#59E67F" }}
                    >
                      NOTIFICATION SENT SUCCESSFULLY
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            </ModalBody>
          </Modal>
          <Modal
            show={this.state.bulkPayment}
            onHide={this.handleInstantBulkClose}
            size="lg"
            centered
          >
            <ModalHeader closeButton style={{ borderBottom: "none" }}>
              INSTANT PAYOUT SELECTION
            </ModalHeader>

            <ModalBody className="px-1">
              <Row className="p-3 mb-3">
                <Col className="col-3">
                  <label>Start time - pickup</label>
                  <ReactDatepicker
                    selected={this.state.start_time}
                    onChange={(date) =>
                      this.setState(
                        {
                          start_time: date,
                        },
                        () => {
                          this.getRidesForBulk(this.state.request_type);
                        }
                      )
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                </Col>
                <Col className="col-3">
                  <label>End time - pickup</label>
                  <ReactDatepicker
                    selected={this.state.end_time}
                    onChange={(date) =>
                      this.setState(
                        {
                          end_time: date,
                        },
                        () => {
                          this.getRidesForBulk(this.state.request_type);
                        }
                      )
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    minTime={this.state.start_time}
                    maxTime={new Date().setHours(23, 59, 59)}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  <ReactTable
                    data={payDataToDisplay}
                    noDataText="No data to display"
                    filtered={this.state.filterOption}
                    onFilteredChange={(filtered, column, value) => {
                      this.onFilteredChangeCustom(
                        value,
                        column.id || column.accessor
                      );
                    }}
                    defaultFilterMethod={(filter, row, column) => {
                      // console.log(row);
                      // console.log(column);
                      // console.log(filter);
                      const id = filter.pivotId || filter.id;
                      // console.log(id);
                      if (typeof filter.value === "object") {
                        // console.log(row[id]);
                        return row[id] !== undefined
                          ? filter.value.indexOf(row[id]) > -1
                          : true;
                      } else {
                        // console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
                        // console.log(filter);
                        if (filter.value === "All") {
                          // console.log("nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");
                          // console.log(row[id]);
                          return row[id] !== undefined
                            ? String(row[id]).indexOf(filter.value) === -1
                            : true;
                        } else {
                          // console.log(row[id]);
                          return row[id] !== undefined
                            ? String(row[id]).indexOf(filter.value) > -1
                            : true;
                        }
                      }
                    }}
                    columns={payoutColumn}
                    className="text-center mx-0 react-table-style -striped -highlight"
                    PaginationComponent={Pagination}
                    // defaultSorted={[
                    //   {
                    //     id: "pickuptime",
                    //     desc: false,
                    //   },
                    // ]}
                    minRows={payDataToDisplay.length > 0 ? 0 : 4}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Col className="col-12  d-flex justify-content-center my-2">
                <Button
                  theme="dark"
                  size="lg"
                  className="mx-2 login-button-new"
                  style={{
                    borderRadius: "5px",
                    fontSize: "calc(0.35rem + 1vmin)",
                  }}
                  onClick={this.handleBulkPayment}
                >
                  MARK PAYOUT
                </Button>
              </Col>
            </ModalFooter>
          </Modal>
          {this.state.success === true && (
            <ReactSnackBar
              Icon={
                <span>
                  <i
                    className="far fa-check-circle fa-lg"
                    style={{ color: "#26FF58" }}
                  />
                </span>
              }
              Show={this.state.Show}
            >
              {this.state.message}
            </ReactSnackBar>
          )}
          {this.state.success === false && (
            <ReactSnackBar
              Icon={
                <span>
                  <i
                    className="far fa-times-circle fa-lg"
                    style={{ color: "#FF0000" }}
                  />
                </span>
              }
              Show={this.state.Show}
            >
              {this.state.message}
            </ReactSnackBar>
          )}
        </Col>
      );
    } else {
      return (
        <TripDetails
          onTitleChange={this.handleChange}
          tripId={this.state.tripId}
          rideType={this.state.rideType}
          requestStatus={this.state.request_status}
          onClickCallback={this.handlemodalclick}
          region={this.state.operating_region}
        />
      );
    }
  }
}
TripView.default = {
  columns: PropTypes.array,
  assignDrivCol: PropTypes.array,
  changeDiv: PropTypes.bool,
  tripId: PropTypes.string,
  request_type: PropTypes.string,
  index: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
};
TripView.defaultProps = {
  assignDriv: [
    {
      label: "Nearby drivers",
    },
    {
      label: "On duty drivers",
    },
  ],
  compColumns: [
    {
      Header: "Sl no",
      id: "serialno",
      style: {},
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Trip ID",
      accessor: "trip_id",
    },
    {
      Header: "Source",
      accessor: "sname",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Destination",
      accessor: "dname",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Pickup time ",
      accessor: "pickuptime",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Ride type",
      accessor: "ridetype",
    },
    {
      Header: "Vehicle type",
      accessor: "vehicle_type",
    },
    {
      Header: "Rider",
      style: { whiteSpace: "unset" },
      Cell: (row) => {
        return (
          <div>
            {row.original.consumername + " - " + row.original.phonenumber}
          </div>
        );
      },
    },
    {
      id: "edit",
      Header: "Action",
      Cell: ({ value }) => {
        return <Button className="">View</Button>;
      },
    },
  ],
  ongoingColumns: [
    {
      Header: "Sl no",
      id: "serialno",
      style: {},
      Cell: (row) => {
        return <div>{row.viewndex + 1}</div>;
      },
    },
    {
      Header: "Trip ID",
      accessor: "trip_id",
    },
    {
      Header: "Source",
      accessor: "sname",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Destination",
      accessor: "dname",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Pickup time ",
      accessor: "pickuptime",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Ride type",
      accessor: "ridetype",
    },
    {
      Header: "Vehicle type",
      accessor: "vehicle_type",
    },
    {
      Header: "Rider",
      style: { whiteSpace: "unset" },
      Cell: (row) => {
        return (
          <div>
            {row.original.consumername + " - " + row.original.phonenumber}
          </div>
        );
      },
    },
    {
      id: "edit",
      Header: "Action",
      Cell: ({ value }) => {
        return <Button className="">View</Button>;
      },
    },
  ],

  instantPay: [
    {
      label: "Driver name",
      accessor: "drivername",
    },
    {
      label: "Driver phone number",
      accessor: "driverphonenumber",
    },
    {
      label: "Trip ID",
      accessor: "trip_id",
    },
    {
      label: "Source",
      accessor: "sname",
      style: { whiteSpace: "unset" },
    },
    {
      label: "Destination",
      accessor: "dname",
      style: { whiteSpace: "unset" },
    },
    {
      label: "Pickup time ",
      accessor: "pickuptime",
      style: { whiteSpace: "unset" },
    },

    {
      label: "Vehicle type",
      accessor: "vehicle_type",
    },
  ],
  changeDiv: false,
  tripId: "",
  index: 0,
  tripDate: new Date(),
};
export default TripView;
