import React, { useState } from "react";
import { Card, FormGroup, Row, Col } from "react-bootstrap";
import { Button } from "shards-react";
import logo from "../assets/logo.svg";
import { FormInput } from "shards-react";
import Loader from "react-loader-spinner";
import axios from "axios";

/**
 * Import files and images
 */
import * as animationData from "../assets/spinner.json";
import LoginImg from "../assets/loginArt.svg";
import loginEmail from "../assets/email.svg";
import stringsConstant from "../constants/stringsConstant.json";
import config from "../constants/constants.json";
import urls from "../constants/urlList.json";

function ResetPassword(props) {
  const [verified, setVerified] = useState(false);
  const [email, setEmail] = useState("");

  /**
   * Handle user input for email
   */

  const handleInput = (e) => {
    setEmail(e.target.value);
  };
  // console.log(email);

  /**
   * Functionality to handle email verification
   */

  const handleVerifyEmail = () => {
    let dataTosend = {
      email,
    };
    axios
      .post(config.url + urls.emailConfirmation, dataTosend)
      .then((response) => {
        // console.log(response);
        if (
          response.data.message === stringsConstant.onsuccess &&
          response.data.firsttime === true
        ) {
          // setVerified(true);
          // window.location.assign(response.data.reset_url);
          props.history.replace({
            pathname: "/changePassword",
            search: `?avatar=${email}`,
          });
        } else if (
          response.data.message === stringsConstant.onsuccess &&
          response.data.firsttime === false
        ) {
          setVerified(true);
        }
      });
  };

  const goBacktoHome = () => {
    window.location.assign("/");
  };

  return (
    <Row className="login-custom">
      <Col
        className="col-12 col-md-5 ipad-spec-main"
        style={{ backgroundColor: "#F1F5FB" }}
      >
        <Row className="d-flex justify-content-center custom-row-height">
          <Col className="col-10 col-md-6 col-lg-8 my-auto custom-padding">
            <Card className="login-card">
              {verified === false && (
                <Card.Body>
                  <Row className="px-2 my-auto">
                    <Col className="col-12 d-flex justify-content-center mb-4 mt-2">
                      {/* Test as img is not availabel */}
                      {/* <label className="login-image">Glory</label> */}
                      <img src={logo} height="auto" width="125"></img>
                    </Col>
                    <Col className="col-12 d-flex justify-content-center  mb-4">
                      <label className="login-text-reset">Reset password</label>
                    </Col>
                    <Col className="col-12 mb-4">
                      <label className="login-reset-desctext">
                        Enter the email associated with your account and we’ll
                        send and email with a link to reset your password.
                      </label>
                    </Col>
                    <Col className="col-12 mb-5">
                      <FormGroup>
                        <label className="login-text-label px-2">
                          Email address
                        </label>
                        <FormInput
                          className="py-3"
                          placeholder="Enter your email address"
                          value={email}
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col className="col-12 mb-4">
                      <FormGroup>
                        <label className="login-text-label">Password</label>
                        <FormInput className="py-3" />
                      </FormGroup>
                    </Col> */}
                    {/* <Col className="col-12 mb-4">
                      <Row>
                        <Col className="col-6 d-flex justify-content-start">
                          <label className="login-link-text">
                            First time user?
                          </label>
                        </Col>
                        <Col className="col-6 d-flex justify-content-end">
                          <label className="login-link-text login-link-align">
                            Forgot password?
                          </label>
                        </Col>
                      </Row>
                    </Col> */}
                    <Col className="col-12 d-flex justify-content-center mb-2 mt-3">
                      <Button
                        className="login-button-new py-3"
                        theme="dark"
                        style={{
                          fontSize: "calc(0.5rem + 1vmin)",
                          fontWeight: "800",
                        }}
                        onClick={handleVerifyEmail}
                      >
                        CONTINUE
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              )}
              {verified === true && (
                <Card.Body>
                  <Row className="px-2 my-auto">
                    <Col className="col-12 d-flex justify-content-center mb-5 mt-2">
                      {/* Test as img is not availabel */}
                      {/* <label className="login-image">Glory</label> */}
                      <img src={logo} height="auto" width="125"></img>
                    </Col>
                    <Col className="col-12 d-flex justify-content-center mb-4 mt-4">
                      <img src={loginEmail} height="auto" width="70"></img>
                    </Col>
                    <Col className="col-12 d-flex justify-content-center  mb-4">
                      <label className="login-text-reset">
                        Check your mail inbox
                      </label>
                    </Col>
                    <Col className="col-12 mb-4">
                      <label className="login-reset-desctext">
                        We have sent a reset link to your email account kindly
                        click on the link provided in the email to reset your
                        password
                      </label>
                    </Col>
                    {/* <Col className="col-12 mb-5">
                      <FormGroup>
                        <label className="login-text-label px-2">
                          Email address
                        </label>
                        <FormInput className="py-3" />
                      </FormGroup>
                    </Col> */}
                    {/* <Col className="col-12 mb-4">
                      <FormGroup>
                        <label className="login-text-label">Password</label>
                        <FormInput className="py-3" />
                      </FormGroup>
                    </Col> */}
                    {/* <Col className="col-12 mb-4">
                      <Row>
                        <Col className="col-6 d-flex justify-content-start">
                          <label className="login-link-text">
                            First time user?
                          </label>
                        </Col>
                        <Col className="col-6 d-flex justify-content-end">
                          <label className="login-link-text login-link-align">
                            Forgot password?
                          </label>
                        </Col>
                      </Row>
                    </Col> */}
                    <Col className="col-12 d-flex justify-content-center mb-2 mt-3">
                      <Button
                        className="login-button-new py-3"
                        style={{
                          backgroundColor: "#151220",
                          boxShadow: "0px 6px 10px #00000029",
                          borderColor: "#151220",
                          fontWeight: "800",
                        }}
                        onClick={goBacktoHome}
                      >
                        CONTINUE
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Col>
        </Row>
      </Col>
      <Col
        className="col-12 col-md-7 d-none d-sm-none d-md-block login-ipad-spec"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <div className="custom-row-height login-logo-position">
          <img src={LoginImg} height="auto" width="70%"></img>
        </div>

        <footer className="footerClass px-4 py-4 footer-class-font">
          <Row>
            <Col className="col-1">
              <span>
                <a className="login-link-text" href="/faq.html" target="_blank">
                  FAQ
                </a>
              </span>
            </Col>
            <Col className="col-2">
              <span>
                <a
                  className="login-link-text"
                  href="https://www.gloryindia.in/privacy"
                  target="_blank"
                >
                  Privacy policy
                </a>
              </span>
            </Col>
            <Col className="col-3">
              <span>
                <a
                  className="login-link-text"
                  href="https://www.gloryindia.in/terms"
                  target="_blank"
                >
                  Terms and conditions
                </a>
              </span>
            </Col>
            <Col className="col-6 d-flex justify-content-end">
              <span style={{ fontWeight: 500, color: "#151220" }}>
                Copyright © 2020 Glory
              </span>
            </Col>
          </Row>
        </footer>
      </Col>
    </Row>
  );
}

export default ResetPassword;
