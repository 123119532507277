import React from "react";
import PropTypes from "prop-types";
import { Container, Col, Row } from "shards-react";

// import PopUp from "../components/common/Popup"
const EmptyLayout = ({ noNavbar, noFooter, children }) => (
  <Container fluid className="px-5 ">
    {/* <Col className="mt-md-5"></Col> */}
    <Row>
      <main
        className="main-content col-12 col-md-12 pl-0 pr-0 d-flex justify-content-center"
        style={{ height: "100vh" }}
      >
        {children}
        {/* {<PopUp />} */}
      </main>
    </Row>
  </Container>
);

export default EmptyLayout;
