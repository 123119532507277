import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";
import stringsData from "../../../constants/stringsConstant.json";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItems: Store.getSidebarItems(),
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems(),
    });
  }
  handleCheck(data) {
    if (data.title === "Configuration") {
      return !stringsData.checkID.includes(
        window.sessionStorage.getItem("user_id")
      );
    } else {
      return data.doNotDisplay.some(
        (item) =>
          item.usertype === window.sessionStorage.getItem("usertype") &&
          item.roletype === window.sessionStorage.getItem("roletype")
      );
    }
    // console.log(data);
  }
  render() {
    const { navItems: items } = this.state;
    return (
      <div className="nav-wrapper">
        <Nav className="nav flex-column ">
          {items.map((item, idx) =>
            item.doNotDisplay.length > 0
              ? this.handleCheck(item) === false && (
                  <SidebarNavItem key={idx} item={item} />
                )
              : item.doNotDisplay.usertype !==
                  window.sessionStorage.getItem("usertype") &&
                item.doNotDisplay.roletype !==
                  window.sessionStorage.getItem("roletype") && (
                  <SidebarNavItem key={idx} item={item} />
                )
          )}
          {/* <SidebarNavItem key={idx} item={item} /> */}
        </Nav>
      </div>
    );
  }
}

// itemData.usertype ===
//window.sessionStorage.getItem("usertype") &&
// itemData.roletype ===
//window.sessionStorage.getItem("roletype") && (
// <SidebarNavItem key={idx} item={item} />
// )

export default SidebarNavItems;
