import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
} from "shards-react";

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props);
    this.state = {
      visible: false,
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  callData() {
    // console.log("here*****************");
    sessionStorage.clear();
    window.location.assign("/");
  }
  render() {
    return (
      <NavItem
        tag={Dropdown}
        caret
        toggle={this.toggleUserActions}
        className="my-auto"
      >
        <DropdownToggle caret tag={NavLink} className="text-nowrap mx-5 ">
          <img
            className="user-avatar rounded-circle mr-2"
            src={require("./../../../../images/user-circle-solid.svg")}
            alt="User Avatar"
            height="30"
            width="30"
          />{" "}
          <span className="d-none d-md-inline-block">
            {" "}
            {window.sessionStorage.getItem("first_name") +
              " " +
              window.sessionStorage.getItem("last_name")}
          </span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} to="/home">
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            tag={Link}
            className="text-danger"
            onClick={() => {
              this.callData();
              this.props.history.push("/");
            }}
          >
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}
