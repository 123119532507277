// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";
import DatePicker from "@wojtekmaj/react-daterange-picker";
import ReactTable from "react-table-v6";
import moment from "moment";
import PageTitle from "./../components/common/PageTitle";
import PolylineMap from "./../components/utilities/ModalMap";
import * as config from "../constants/constants.json";
import { CSVLink } from "react-csv";

const headers = [
  { label: "Booking ID", key: "booking_id" },
  { label: "Booking From", key: "booking_from" },
  { label: "Source", key: "driverphnumber" },
  { label: "Vehicle type", key: "vehicle_type" },
  { label: "Source Name", key: "source_name" },
  { label: "Destination Name", key: "destination_name" },
  { label: "Pickup time", key: "pickup_time" },
  { label: "Request Time", key: "request_time" },
  { label: "Status", key: "booking_status" },
  { label: "Reason", key: "reason" },
  { label: "Rider name", key: "passenger_name" },
  { label: "Rider Phone number", key: "passenger_number" },
];
class RejectedRides extends React.Component {
  constructor(props) {
    // console.log(props)
    super(props);
    // this.toggle = this.toggle.bind(this);
    this.state = {
      open: false,
      collapse: false,
      data: [],
      date: [new Date(), new Date()],
      offeredAmount: "",
      openTip: false,
      showDetails: false,
      coOrdinates: [],
      userData: [],
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    // console.log(this.state.hits);
    this.onChange = this.onChange.bind(this);
    // this.toggleTip = this.toggleTip.bind(this);
    this.goBack = this.goBack.bind(this);
    this.countFetch = this.countFetch.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.getCoordinates = this.getCoordinates.bind(this);
    this.toggle = this.toggle.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    // console.log(props)
  }
  downloadCSV() {}
  getCoordinates() {
    let data = {
      region: sessionStorage.getItem("operating_region"),
    };
    fetch(config.url + "htmlcrm/getcoordinates", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // var flightPlanCoordinates = [];
          // let coordinates = result.bangaloreRegion
          // for (let i = 0; i < coordinates.length; i++) {
          //     var point = new google.maps.LatLng(coordinates[i][0], coordinates[i][1]);
          //     flightPlanCoordinates.push(point);
          // }

          this.setState({
            coOrdinates: result.bangaloreRegion,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }
  handleClick(data) {
    console.log(data);
    this.setState({
      open: !this.state.open,
      userData: data,
    });
  }
  goBack() {
    this.props.history.goBack();
  }
  // onChange = date => this.setState({ date })
  handleOnChange(event) {
    // console.log(event)
    // console.log(event.target.value)
    // console.log(event.target.id);
    this.setState({
      offeredAmount: event.target.value,
    });
  }
  toggleTip() {
    this.setState({
      openTip: !this.state.openTip,
    });
  }
  onChange(date) {
    // console.log(this);
    // console.log(date)
    this.setState({ date }, () => {
      this.countFetch();
    });
  }

  countFetch() {
    let start_time = this.state.date[0];
    let start_milliseconds = start_time.getTime();
    let end_time = this.state.date[1];
    let end_milliseconds = end_time.getTime();
    // console.log(new Date(this.state.date));
    fetch(config.url + "vendor/getRejectedRidesLogs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        start_time: start_milliseconds,
        end_time: end_milliseconds,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.setState({
              data: result.data,
            });
          } else {
            this.setState({
              data: [],
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    } else {
      this.countFetch();
      this.getCoordinates();
    }
  }
  NoDataComponent(props) {
    const { children } = props;
    // console.log(children);
    // console.log("no props");
    return children === "" ? null : <div className="rt-noData">{children}</div>;
  }

  render() {
    let data = this.state.data;
    const defaultExpandedRows = data.map((element, index) => {
      return { index: true };
    });
    // console.log(defaultExpandedRows)
    const columns = [
      {
        Header: "Booking ID",
        accessor: "booking_id",
      },
      {
        Header: "Booking from",
        accessor: "booking_from",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Source",
        accessor: "source_name",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Destination",
        accessor: "destination_name",
        style: { whiteSpace: "unset" },
      },
      {
        id: "pickup_time",
        Header: "Pickup time ",
        // accessor: 'pickup_time',
        Cell: (row) => {
          let date = moment(new Date(row.original.pickup_time)).format(
            "DD-MM-YY, hh:mm:ss A"
          );

          return <div>{date}</div>;
        },
        style: { whiteSpace: "unset" },
      },
      {
        id: "request_time",
        Header: "Request time ",
        // accessor: 'pickup_time',]
        Cell: (row) => {
          let date = moment(new Date(row.original.request_time)).format(
            "DD-MM-YY, hh:mm:ss A"
          );

          return <div>{date}</div>;
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Status",
        accessor: "booking_status",
      },
      {
        id: "edit",
        Header: "Actions",
        Cell: (row) => {
          //  console.log(this);
          // console.log(row.original.request_status)
          return (
            <div className="d-flex flex-column">
              <Button
                className="mx-2 my-2"
                theme="dark"
                onClick={() => {
                  this.handleClick(row.original);
                }}
              >
                VIEW
              </Button>
            </div>
          );
        },
      },
    ];

    // console.log("****************");
    // console.log(data);
    return (
      <Container fluid className="main-content-container px-md-4">
        <Row noGutters className="page-header py-4 px-md-3 mb-md-5">
          <Col className="col-12">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Business accounts
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active>Rejected rides</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="col-12 mt-1">
            <PageTitle
              title="Rejected rides list"
              className="text-sm-left mb-3 p-0 custom-margin"
            />
          </Col>
        </Row>

        <Row className="px-3 mb-4">
          <Col className="col-12 col-md-9 mb-5 order-1 order-md-2">
            {/* <div className=""> */}
            <Button
              className="float-right"
              id="goback-button"
              theme="dark"
              onClick={this.goBack}
            >
              <i className="fas fa-arrow-left mx-1" />
              <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                BACK
              </span>
            </Button>
            {/* </div> */}
          </Col>
          <Col className="col-12 col-md-3 mb-2 order-2 order-md-1">
            {/* <div className="w-50"> */}
            <DatePicker
              className="form-control"
              format="dd/MM/yyyy"
              onChange={this.onChange}
              value={this.state.date}
              clearIcon={null}
              calendarIcon={<i className="far fa-calendar-alt" />}
            />
            {/* </div> */}
          </Col>

          {/* <Col className="col-xl-1 .d-none .d-xl-block" /> */}
        </Row>
        {/* <Row className="px-3"> */}
        {/* <Col className="col-12 col-md-12 col-lg-12"> */}
        {/* <Button className="mx-2 " onClick={this.copyToClipboard}>
              COPY
            </Button> */}
        {/* {this.state.data.length === 0 && <Button>DOWNLOAD CSV</Button>}
            {this.state.data.length > 0 && (
              <CSVLink data={this.state.data} headers={headers}>
                <Button>DOWNLOAD CS</Button>
              </CSVLink>
            )} */}
        {/* <Button className="mx-2 " onClick={this.downloadCSV}>
              CSV
            </Button> */}
        {/* <Button className="mx-2 " onClick={this.printData}>
              PRINT
            </Button> */}
        {/* </Col>
        </Row> */}

        {/* <hr /> */}
        <Modal size="lg" open={this.state.open} toggle={this.toggle}>
          <ModalHeader>Location on map</ModalHeader>
          <ModalBody>
            <Row className="mb-3">
              <Col className="col-12 col-md-12 col-lg-12">
                Trip ID: {this.state.userData.booking_id}
              </Col>
            </Row>
            <Row>
              <Col className="col-11 col-md-11 col-lg-11">
                <PolylineMap
                  dataProps={{}}
                  source_lat={this.state.userData.source_latlon}
                  dest_lat={this.state.userData.destination_latlon}
                  path={this.state.coOrdinates}
                />
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        {/* <Row className="px-3 mb-4">
         
        </Row> */}
        <Row className="px-md-3 mb-4">
          <Col className="col-12 col-md-12 col-lg-12">
            <Card>
              <CardBody className="px-1">
                <ReactTable
                  data={this.state.data}
                  columns={columns}
                  className="text-center -striped -highlight"
                  noDataText="No data to display"
                  NoDataComponent={this.NoDataComponent}
                  SubComponent={(row) => {
                    return (
                      <div>
                        <Row>
                          <Col className="col-12 col-md-12 col-lg-12">
                            <span>Reason : {row.original.reason}</span>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col className="col-12 col-md-12 col-lg-12">
                            <span>
                              Rider name : {row.original.passenger_name}
                            </span>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col className="col-12 col-md-12 col-lg-12">
                            <span>
                              Phone number : {row.original.passenger_number}
                            </span>
                          </Col>
                        </Row>
                        <hr />
                      </div>
                    );
                  }}
                  defaultSorted={[
                    {
                      id: "request_time",
                      desc: false,
                    },
                  ]}
                  minRows={this.state.data.length > 0 ? 0 : 4}
                  noDataText="No data to display"
                />
              </CardBody>
            </Card>
          </Col>
          <Col className="col-12 col-md-12 mt-4 d-flex justify-content-center">
            {/* <Button className="mx-2 " onClick={this.copyToClipboard}>
              COPY
            </Button> */}
            {this.state.data.length === 0 && (
              <Button
                theme="dark"
                size="lg"
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "700",
                  minWidth: "200px",
                  borderRadius: "3px",
                }}
              >
                DOWNLOAD CSV
              </Button>
            )}
            {this.state.data.length > 0 && (
              <CSVLink data={this.state.data} headers={headers}>
                <Button
                  theme="dark"
                  size="lg"
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "700",
                    minWidth: "200px",
                    borderRadius: "3px",
                  }}
                >
                  DOWNLOAD CSV
                </Button>
              </CSVLink>
            )}
            {/* <Button className="mx-2 " onClick={this.downloadCSV}>
              CSV
            </Button> */}
            {/* <Button className="mx-2 " onClick={this.printData}>
              PRINT
            </Button> */}
          </Col>
        </Row>
      </Container>
    );
  }
}
RejectedRides.propTypes = {
  smallStats: PropTypes.array,
  RideStats: PropTypes.array,
  CountStats: PropTypes.array,
  hits: PropTypes.string,
  date: PropTypes.oneOf(["object", "string"]),
  tripDate: PropTypes.oneOf(["object", "string"]),
};

RejectedRides.defaultProps = {
  smallStats: [
    {
      label: "Total",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "totalRidesCount",
    },
  ],
  RideStats: [
    {
      label: "UNASSIGNED",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "unassignedRides",
    },
    {
      label: "ASSIGNED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "assignedRides",
    },
    {
      label: "ON-GOING RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "ongoing",
    },
    {
      label: "COMPLETED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "completedRides",
    },
    {
      label: "CANCELLED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "cancelledRides",
    },
    {
      label: "DETACHED",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "detachedRides",
    },
  ],
  CountStats: [
    {
      label: "Total",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
    },
  ],
};

export default RejectedRides;
