import React from "react";
import PropTypes from "prop-types";
import ReactSnackBar from "react-js-snackbar";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import PageTitle from "./../components/common/PageTitle";
import SearchActivation from "./../components/common/SearchActivation";
import * as config from "../constants/constants.json";
import ReactTable from "react-table-v6";

class DriverActivations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selected: {},
      selectAll: 0,
      open: false,
      phonenumbers: [],
      filteredData: [],
      searchInput: "",
      Show: false,
      Showing: false,
      success: false,
    };
    this.fetchDriverList = this.fetchDriverList.bind(this);
    this.toggleRow = this.toggleRow.bind(this);
    this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
    this.handleSelectClick = this.handleSelectClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleRefreshClick = this.handleRefreshClick.bind(this);
    // this.sendActivationData = this.sendActivationData.bind(this);
  }
  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };
  goBack() {
    this.props.history.goBack();
  }

  handleSetFilteredData = (filteredData) => {
    this.setState({ filteredData });
  };

  handleSetSearchInput = (searchInput) => {
    this.setState({ searchInput });
  };
  handleSelectClick() {
    // console.log("************************************");
    // console.log(this.state.phonenumbers);
    let data = {
      executive_id: window.sessionStorage.getItem("user_id"),
      phonenumbers: JSON.stringify(this.state.phonenumbers),
      status: "Inactive",
    };
    // console.log(data);
    fetch(config.url + "htmlcrm/multipleAccountActivation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.setState(
              {
                open: !this.state.open,
                success: true,
                selected: {},
                selectAll: 0,
                phonenumbers: [],
                filteredData: [],
              },
              () => {
                this.fetchDriverList();
                this.show();
              }
            );
          } else {
            this.setState(
              {
                success: false,
              },
              () => {
                this.show();
              }
            );
          }

          // console.log("herererer");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  toggleRow(data, phonenumber) {
    // console.log(data)
    const newSelected = Object.assign({}, this.state.selected);

    newSelected[data] = !this.state.selected[data];
    // newSelected['dunique_id'] = data
    // this.setState({

    // });
    this.setState((prevState) => ({
      phonenumbers: [...prevState.phonenumbers, phonenumber * 1],
      selected: newSelected,
      selectAll: 2,
    }));
  }

  toggleSelectAll() {
    // console.log("here");
    let newSelected = {};

    if (this.state.selectAll === 0) {
      this.state.data.forEach((x) => {
        newSelected[x.key] = true;
      });
    }

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
    });
  }

  toggleOnSelectAll() {
    // console.log("here");
    let newSelected = {};

    if (this.state.selectAll === 0) {
      this.state.data.forEach((x) => {
        newSelected[x.dunique_id] = true;
      });
    }

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
    });
  }
  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }
  handleRefreshClick() {
    this.fetchDriverList();
  }
  fetchDriverList() {
    let data = {
      region: sessionStorage.getItem("operating_region"),
    };
    // console.log(data);
    fetch(config.url + "htmlcrm/getDListForAct", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          this.setState({
            data: result.driverlist,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    } else {
      this.fetchDriverList();
    }
  }

  capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
  }
  render() {
    // console.log(this.state.data)
    const dataToDisplay = this.state.searchInput.length
      ? this.state.filteredData
      : this.state.data;

    const driverActivationCol = [
      {
        id: "checkbox",
        accessor: "",
        Cell: ({ original }) => {
          // console.log(original);
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selected[original.dunique_id] === true}
              onChange={() =>
                this.toggleRow(original.dunique_id, original.driverphonenumber)
              }
            />
          );
        },
        Header: (x) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selectAll === 1}
              ref={(input) => {
                if (input) {
                  input.indeterminate = this.state.selectAll === 2;
                }
              }}
              onChange={() => this.toggleOnSelectAll()}
            />
          );
        },
        sortable: false,
        width: 45,
      },
      {
        id: "drivername",
        Header: "Driver name",
        accessor: (d) => d.drivername,
      },
      {
        id: "driverphonenumber",
        Header: "Phone number",
        accessor: (d) => d.driverphonenumber,
      },
      {
        id: "vehiclenumber",
        Header: "Vehicle number",
        accessor: (d) => d.vehiclenumber,
      },
      {
        id: "vehicletype",
        Header: "Vehicle type",
        accessor: (d) => d.vehicletype,
      },
      {
        id: "registertime",
        Header: "Date",
        accessor: (d) => d.registertime,
      },
      {
        id: "login_status",
        Header: "Account status",
        accessor: (d) => {
          // this.capitalizeFirstLetter(d.login_status)
          if (d.login_status === "blocked") {
            return <div style={{ color: "#FC2727" }}>Blocked</div>;
          }
        },
      },
    ];

    return (
      <Container fluid className="main-content-container px-md-4">
        {/* <Row className="mt-2">
          <Col className="col-12 col-md-12 col-lg-12">
            <Button
              className=" float-right"
              id="goback-button"
              onClick={this.goBack}
            >
              <i className="fas fa-arrow-left mx-1" />
              <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                BACK
              </span>{" "}
            </Button>
          </Col>
        </Row> */}
        <Row noGutters className="page-header py-4 px-md-3 mb-md-5">
          <Col className="col-12">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Driver accounts
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active>Driver activations</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="col-12 mt-1">
            <PageTitle
              title="Manage activations"
              className="text-sm-left mb-3 p-0 custom-margin"
            />
          </Col>
          {/* <PageTitle
            title="Driver activations"
            subtitle=""
            className="text-sm-left mb-3"
          /> */}
        </Row>
        <Row className="mt-2 mb-3 px-md-3">
          <Col className="col-12 col-md-12 col-lg-12 mb-4">
            <Button
              className=" float-right"
              theme="dark"
              id="goback-button"
              onClick={this.goBack}
            >
              <i className="fas fa-arrow-left mx-1" />
              <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                BACK
              </span>{" "}
            </Button>
          </Col>
        </Row>
        <Row className="mt-3 px-md-3">
          <Col className="col-12 col-md-12 col-lg-12">
            <Card className="mb-5">
              <CardHeader className="my-3">
                <Row>
                  <Col className="col-12 col-md-11 order-2 order-md-1">
                    <div className="ml-0">
                      <SearchActivation
                        data={this.state.data}
                        columns={driverActivationCol}
                        handleSetFilteredData={this.handleSetFilteredData}
                        handleSetSearchInput={this.handleSetSearchInput}
                      />
                    </div>
                  </Col>
                  <Col className="col-12 col-md-1 d-flex justify-content-end order-md-12 order 1 mb-4">
                    <Button onClick={this.handleRefreshClick} theme="dark">
                      <i className="fas fa-sync-alt" />
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pt-md-0 pt-0 px-1">
                <ReactTable
                  data={dataToDisplay}
                  columns={driverActivationCol}
                  className="text-center -striped -highlight"
                  noDataText="No data to display"
                  minRows={dataToDisplay.length > 0 ? 0 : 4}
                />
                <Modal show={this.state.open} onHide={this.toggle}>
                  <ModalHeader closeButton>Driver activation</ModalHeader>
                  <ModalBody>
                    Do you want to activate all the selected drivers ?
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      theme="dark"
                      size="lg"
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "700",
                        minWidth: "120px",
                        borderRadius: "3px",
                      }}
                      onClick={this.handleSelectClick}
                    >
                      ACCEPT
                    </Button>
                    <Button
                      theme="dark"
                      size="lg"
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "700",
                        minWidth: "120px",
                        borderRadius: "3px",
                      }}
                      onClick={this.toggle}
                    >
                      CANCEL
                    </Button>
                  </ModalFooter>
                </Modal>
              </CardBody>
              <CardFooter>
                {(this.state.phonenumbers === undefined ||
                  this.state.phonenumbers === null ||
                  this.state.phonenumbers.length === undefined ||
                  this.state.phonenumbers.length === 0) && (
                  <React.Fragment>
                    <Button
                      className="float-right d-none d-sm-none d-md-block"
                      disabled
                      theme="dark"
                      size="lg"
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "700",
                        minWidth: "200px",
                        borderRadius: "3px",
                      }}
                    >
                      ACTIVATE ACCOUNTS
                    </Button>
                    <Button
                      className="d-block d-sm-block d-md-none mb-3"
                      disabled
                      theme="dark"
                      size="lg"
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        fontSize: "0.8rem",
                        fontWeight: "700",
                        minWidth: "200px",
                        borderRadius: "3px",
                      }}
                    >
                      ACTIVATE ACCOUNTS
                    </Button>
                  </React.Fragment>
                )}
                {this.state.phonenumbers.length > 0 && (
                  <React.Fragment>
                    <Button
                      className="float-right py-3 d-none d-sm-none d-md-block"
                      onClick={this.toggle}
                    >
                      ACTIVATE ACCOUNTS
                    </Button>
                    <Button
                      className="float-right py-3 d-block d-sm-block d-md-none"
                      onClick={this.toggle}
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      ACTIVATE ACCOUNTS
                    </Button>
                  </React.Fragment>
                )}
                {this.state.success === false && (
                  <ReactSnackBar
                    Icon={
                      <i
                        className="far fa-times-circle fa-lg"
                        style={{ color: "#FF0000" }}
                      />
                    }
                    Show={this.state.Show}
                  >
                    Activation Failed, Please try again !!!
                  </ReactSnackBar>
                )}
                {this.state.success === true && (
                  <ReactSnackBar
                    Icon={
                      <span>
                        <i
                          className="far fa-check-circle fa-lg"
                          style={{ color: "#26FF58" }}
                        />
                      </span>
                    }
                    Show={this.state.Show}
                  >
                    <h5 style={{ color: "white" }}>
                      Activation successful !!!
                    </h5>
                  </ReactSnackBar>
                )}
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

DriverActivations.propTypes = {
  inputValues: PropTypes.array,
};
DriverActivations.defaultProps = {
  inputValues: [
    {
      label: "First Name",
      value: "",
      attrs: {},
      stats: false,
      name: "first_name",
    },
    {
      label: "Last Name",
      value: "",
      attrs: {},
      stats: false,
      name: "last_name",
    },
    {
      label: "Phone number",
      value: "",
      attrs: {},
      name: "phonenumber",
    },
    {
      label: "Alternate Phone number",
      value: "",
      attrs: {},
      name: "alt_phonenumber",
    },
    {
      label: "Vehicle Name",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_name",
    },
    {
      label: "Vehicle Number",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_number",
    },
    {
      label: "Vehicle Make",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_make",
    },
    {
      label: "Vehicle Model",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_model",
    },
    {
      label: "Vehicle Color",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_color",
    },
  ],
};

export default DriverActivations;
