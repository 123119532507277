// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
} from "shards-react";
import ReactDatePicker from "react-datepicker";
import Loader from "react-loader-spinner";
import PageTitle from "./../components/common/PageTitle";
import InputDataForm from "./../components/common/InputDataForm";
import DragAndDrop from "./../components/common/DragAndDrop";
import * as config from "../constants/constants.json";
import ReactSnackBar from "react-js-snackbar";
import DL from "../assets/dl.svg";
import RC from "../assets/rc.svg";
import PAN from "../assets/pan.svg";
import CheckV from "../assets/checktick.svg";
import ErrorV from "../assets/errorv.svg";
import socketIOClient from "socket.io-client";
import moment from "moment";
const socket = socketIOClient(config.SOCKET, {
  transports: ["websocket"],
});

class AddBankLogic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acc_holder_name: "",
      acc_number: "",
      bank_name: "",
      ifsc: "",
      showVerificationBox: false,
      panVerified: undefined,
      dlVerified: undefined,
      rcVerified: undefined,
      disableDl: false,
      disableRC: false,
      disablePAN: false,
      dlverifiedData: [],
      rcverifiedData: [],
      panverifiedData: [],
      dldob: "",
      pandob: "",
      dlno: "",
      vehicle_num1: "",
      vehicle_num2: "",
      pan_name: "",
      pan_number: "",
      Show: false,
      Showing: false,
      success: false,
      message: "",
    };
    // console.log(props);
    this.handleKeyChange = this.handleKeyChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDriverChange = this.handleDriverChange.bind(this);
    this.handleDLChange = this.handleDLChange.bind(this);
    this.handlePanDateChange = this.handlePanDateChange.bind(this);
    this.handleDldataChange = this.handleDldataChange.bind(this);
    this.handleVehiclenumChange = this.handleVehiclenumChange.bind(this);
    this.handlePanName = this.handlePanName.bind(this);
    this.handlePanNumber = this.handlePanNumber.bind(this);
  }

  handlePanNumber(event) {
    this.setState({
      pan_number: event.target.value,
    });
  }
  handlePanName(event) {
    this.setState({
      pan_name: event.target.value,
    });
  }
  handleVehiclenumChange(event, type) {
    if (type === "f1") {
      this.setState({
        vehicle_num1: event.target.value,
      });
    } else {
      this.setState({
        vehicle_num2: event.target.value,
      });
    }
  }

  handleDldataChange(event) {
    this.setState({
      dlno: event.target.value,
    });
  }

  handlePanDateChange(date) {
    this.setState({
      pandob: date,
    });
  }

  handleDLChange(date) {
    console.log(date);
    this.setState({
      dldob: date,
    });
  }

  handleDriverChange(event) {
    this.setState({
      driver_type: event.target.value,
    });
  }

  handleDriverChange = () => {};
  verifyDL = (e) => {
    this.setState({
      dlVerified: "waiting",
      disableDl: true,
    });
    let dataToSend = {
      phonenumber: this.state.phonenumber,
      dob: moment(this.state.dldob).format("DD-MM-yyyy"),
      dlno: this.state.dlno,
      dunique_id: this.state.dunique_id,
    };
    // console.log(dataToSend);
    socket.on("verifydl", (data) => {
      // console.log(data);
      if (data.dunique_id === this.state.dunique_id) {
        if (data.message === "successfull") {
          this.setState(
            {
              dlVerified: "verified",
              dlverifiedData: data.data,
            },
            () => {
              this.callModal();
            }
          );
        } else {
          this.setState({
            dlVerified: "failed",
            disableDl: false,
          });
        }
      }
    });

    socket.emit("verifydl", dataToSend);
    setTimeout(() => {
      if (this.state.dlVerified === "waiting") {
        this.setState({
          dlVerified: undefined,
          disableDl: false,
        });
      }
    }, 15000);
  };

  verifyRC = (e) => {
    this.setState({
      rcVerified: "waiting",
      disableRC: true,
    });
    let dataToSend = {
      phonenumber: this.state.phonenumber,
      vehicle_number: this.state.vehicle_num1 + "-" + this.state.vehicle_num2,
      dunique_id: this.state.dunique_id,
    };
    console.log(dataToSend);
    socket.on("verifyrc", (data) => {
      console.log(data);
      if (data.dunique_id === this.state.dunique_id) {
        if (data.message === "successfull") {
          this.setState(
            {
              rcVerified: "verified",
              rcverifiedData: data.data,
            },
            () => {
              this.callModal();
            }
          );
        } else {
          this.setState({
            rcVerified: "failed",
            disableRC: false,
          });
        }
      }
    });

    socket.emit("verifyrc", dataToSend);
    setTimeout(() => {
      if (this.state.rcVerified === "waiting") {
        this.setState({
          rcVerified: undefined,
          disableRC: false,
        });
      }
    }, 15000);
  };

  verifyPAN = (e) => {
    this.setState({
      panVerified: "waiting",
      disablePAN: true,
    });
    let dataToSend = {
      pan_no: this.state.pan_number,
      name: this.state.pan_name,
      dob: moment(this.state.pandob).format("DD/MM/yyyy"),
      phonenumber: this.state.phonenumber,
      dunique_id: this.state.dunique_id,
    };
    // console.log(dataToSend);
    socket.on("verifypan", (data) => {
      console.log(data);
      if (data.dunique_id === this.state.dunique_id) {
        if (data.message === "successfull") {
          this.setState(
            {
              panVerified: "verified",
              panverifiedData: data.data,
            },
            () => {
              this.callModal();
            }
          );
        } else {
          this.setState({
            panVerified: "failed",
            disablePAN: false,
          });
        }
      }
    });

    socket.emit("verifypan", dataToSend);
    setTimeout(() => {
      if (this.state.panVerified === "waiting") {
        this.setState({
          panVerified: undefined,
          disablePAN: false,
        });
      }
    }, 15000);
  };

  callModal = () => {
    if (
      this.state.dlVerified === true &&
      this.state.rcVerified === true &&
      this.state.panVerified === true
    ) {
      this.toggle();
    }
  };

  toggle() {
    setTimeout(() => {
      window.location.assign("/driveraccounts/addeditdriver");
    }, 2000);
  }

  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };
  // Handle change
  handleKeyChange(e, name) {
    this.props.handleKeyChange(e, name);
    // const newState = {};
    // newState[name] = e.target.value;
    // this.setState({ ...this.state, ...newState });
  }

  handleClick(e) {
    e.preventDefault();
    // Do API call here
    let data = {
      userid: window.sessionStorage.getItem("user_id"),
      first_name: this.props.oldProps.first_name,
      last_name: this.props.oldProps.last_name,
      basic_user: "false",
      phonenumber: this.props.oldProps.phonenumber,
      dateofbirth: new Date(this.props.oldProps.date_of_birth)
        .getTime()
        .toString(),
      prefer_location: this.props.oldProps.preferred_location,
      permanent_address: this.props.oldProps.permanent_address,
      current_address: this.props.oldProps.current_address,
      driver_type: "owner",
      vehiclenumber: this.props.dataState.vehicle_number,
      starting_point: this.props.oldProps.starting_point,
      badgenumber: this.props.dataState.badge_number,
      vehiclemake: this.props.dataState.vehicle_make,
      vehicletype: this.props.dataState.vehicle_name,
      vehicle_name: this.props.dataState.vehicle_type,
      vehicle_color: this.props.dataState.vehicle_color,
      accholder_name: this.state.acc_holder_name,
      bankacc_number: this.state.acc_number,
      ifsc_code: this.state.ifsc,
      pannumber: this.props.dataState.pan_number,
      vehiclemodel: new Date(this.props.dataState.vehicle_model)
        .getTime()
        .toString(),
      adhar_number: this.props.dataState.aadhar_number,
      email: this.props.oldProps.email,
      alternate_number: this.props.oldProps.alt_phonenumber,

      fmone_name: this.props.oldProps.family_name_1,
      fmone_phonenumber: this.props.oldProps.family_phn_1,
      fmone_relationship: this.props.oldProps.family_rel_1,
      fmone_address: this.props.oldProps.family_address_1,

      fmtwo_name: this.props.oldProps.family_name_2,
      fmtwo_phonenumber: this.props.oldProps.family_phn_2,
      fmtwo_relationship: this.props.oldProps.family_rel_2,
      fmtwo_address: this.props.oldProps.family_address_1,
      childlock: "true",
      gps: "true",
      panicbutton: "true",
      operating_region: this.props.oldProps.operating_region,
      dl_num: this.props.dataState.dl_number,
    };
    // console.log(data);
    fetch(config.url + "onboard/newdriversregistration", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log("New drivers registration");
          // console.log(result);
          if (result.message === "successfull") {
            let dunique_id = result.dunique_id;
            fetch(config.url + "onboard/driverdocumentsdate", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: JSON.stringify({
                dunique_id: dunique_id,
                dl_to: new Date(this.props.dataState.dl_expiry).getTime(),
                insurance_to: new Date(
                  this.props.dataState.insurance_expiry
                ).getTime(),
                emission_to: new Date(
                  this.props.dataState.emission_expiry
                ).getTime(),
                permit_to: new Date(
                  this.props.dataState.permit_expiry
                ).getTime(),
                fc_to: new Date(this.props.dataState.fc_expiry).getTime(),
                display_card_to: "",
                roadtax_to: "",
              }),
            })
              .then((res) => res.json())
              .then(
                (results) => {
                  // console.log("Drivers doc date");
                  // console.log(results);
                  if (results.message === "successfull") {
                    let duty_preference;
                    if (
                      this.props.oldProps.night_shift === true &&
                      this.props.oldProps.day_shift === true
                    ) {
                      // console.log('log1')
                      duty_preference = "day / night";
                      // console.log(dutyPreference);
                    } else if (this.props.oldProps.day_shift === true) {
                      // console.log('log2')
                      duty_preference = "day";
                      // console.log(dutyPreference);
                    } else if (this.props.oldProps.night_shift === true) {
                      // console.log('log3')
                      duty_preference = "night";
                      // console.log(dutyPreference);
                    }
                    fetch(config.url + "onboard/updateDriverRidePreference", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                      },
                      body: JSON.stringify({
                        dunique_id: dunique_id,
                        prefer_recurride: this.props.oldProps.recurride,
                        prefer_airport: this.props.oldProps.airport,
                        prefer_urban: this.props.oldProps.urban,
                        prefer_hiring: "",
                        prefer_outstation: this.props.oldProps.outstation,
                        permit_location: "none",
                        permit_type: "",
                        duty_preference: duty_preference,
                      }),
                    })
                      .then((res) => res.json())
                      .then(
                        (resultsss) => {
                          // console.log("Update preference");
                          // console.log(resultsss);
                          // console.log("herererer");
                          // console.log(result.drivers);
                          // this.setState({
                          //     dData: result.drivers
                          // })
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {}
                      );
                    fetch(config.url + "onboard/driverimagedocuments", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                      },
                      body: JSON.stringify({
                        executive_id: window.sessionStorage.getItem("user_id"),
                        dunique_id: dunique_id,
                        profileimage: "",
                        driverpro: "",
                        displaycard: "",
                        rcimage: "",
                        insuranceimage: "",
                        adharone: "",
                        adhartwo: "",
                        pancard: "",
                        policeverification: "",
                        permit: "",
                        fc: "",
                        dlone: "",
                        dltwo: "",
                        emission: "",
                        roadtax: "",
                        vhfront: "",
                        vhreare: "",
                        vhleft: "",
                        vhright: "",
                        sales_agreement: "",
                      }),
                    })
                      .then((res) => res.json())
                      .then(
                        (resultss) => {
                          // console.log(resultss);
                          // console.log(result.drivers);
                          this.setState(
                            {
                              success: true,
                              message: "Driver onboarded successfully !!",
                            },
                            () => {
                              this.show();
                              setTimeout(() => this.showVeification(), 2000);
                            }
                          );
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {}
                      );
                  }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {}
              );
          } else {
            this.setState(
              {
                success: false,
                message: "Failed to register the driver",
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  showVeification = () => {
    this.setState({
      showVerificationBox: true,
    });

    // window.location.assign("/driveraccounts/addeditdriver");
  };

  handleImage = (image, name) => {
    this.props.handlePassBook(image, name);
  };

  render() {
    // console.log("****************");
    // console.log(data);
    return (
      <React.Fragment>
        {console.log(this.state)}
        {/* <Row noGutters className="page-header py-2 px-3">
                    <PageTitle title="Add a driver" subtitle="Driver Accounts" className="text-sm-left mb-3" />
                </Row> */}
        {this.state.showVerificationBox === false && (
          <Row className="mb-3">
            <Col className="col-12 col-md-12 col-lg-12">
              <Card className="px-md-3">
                <CardBody>
                  <Row>
                    <h5>Bank Details</h5>
                  </Row>
                  <Row>
                    <Col className="col-12 col-md-6 col-lg-6">
                      <DragAndDrop
                        id="passbook-image"
                        variation="1"
                        label="Passbook Image"
                        value={this.props.passbook}
                        onDropHandler={this.handleImage}
                        stats={false}
                      />
                    </Col>
                    <Col className="col-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                      {this.props.inputValues.map((stats, idx) => (
                        <Col
                          className="col-12 px-2 mb-4"
                          key={idx}
                          {...stats.attrs}
                        >
                          {/* {console.log(this.state.hits)} */}
                          <InputDataForm
                            id={`smaller-stats-${idx}`}
                            variation="1"
                            label={stats.label}
                            stats={stats.stats}
                            value={this.props[stats.name]}
                            name={stats.name}
                            onKeyChange={this.handleKeyChange}
                          />
                        </Col>
                      ))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {this.state.showVerificationBox === true && (
          <Row className="px-md-3 my-4">
            <Col className="col-12 col-md-12 col-lg-12">
              <Card>
                <CardBody className="mb-4">
                  <React.Fragment>
                    <Row>
                      <Col className="col-12 d-flex justify-content-center my-4">
                        <label
                          style={{
                            fontWeight: 800,
                            fontSize: "calc(1rem + 1vmin)",
                          }}
                        >
                          Verify driver documents
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12">
                        <Row>
                          <Col className="col-12 col-md-4">
                            <Form onSubmit={this.verifyDL}>
                              <FormGroup>
                                <label style={{ fontWeight: 800 }}>
                                  Verify driving details
                                </label>
                                <div>
                                  <img src={DL} height="auto" width="250"></img>
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <label
                                  style={{
                                    fontSize: "calc(0.4rem + 1vmin)",
                                    fontWeight: 800,
                                  }}
                                >
                                  Enter D.O.B as DL
                                </label>
                                {/* <FormInput
                              placeholder="Select date of birth"
                              className="py-3"
                              required
                            /> */}
                                <ReactDatePicker
                                  className="form-control py-3"
                                  selected={this.state.dldob}
                                  onChange={(date) => this.handleDLChange(date)}
                                  dateFormat="dd-MM-yyyy"
                                  placeholderText="Select date of birth"
                                  readOnly={this.state.disableDl}
                                />
                              </FormGroup>
                              <FormGroup>
                                <label
                                  style={{
                                    fontSize: "calc(0.4rem + 1vmin)",
                                    fontWeight: 800,
                                  }}
                                >
                                  Driving license number
                                </label>
                                <FormInput
                                  placeholder="Enter DL number"
                                  className="py-3"
                                  type="text"
                                  minLength="15"
                                  maxLength="15"
                                  value={this.state.dlno}
                                  onChange={this.handleDldataChange}
                                  required
                                  readOnly={this.state.disableDl}
                                />
                              </FormGroup>
                              <FormGroup className="mt-4">
                                <Button
                                  className="py-3 px-5"
                                  disabled={this.state.disableDl}
                                >
                                  <label
                                    className="my-0"
                                    style={{
                                      fontSize: "calc(0.4rem + 1vmin)",
                                      fontWeight: 800,
                                    }}
                                  >
                                    VERIFY
                                  </label>
                                </Button>
                              </FormGroup>
                              <FormGroup className="mt-5">
                                {this.state.dlVerified === "waiting" && (
                                  <React.Fragment>
                                    <div className="d-inline-flex">
                                      <Loader
                                        type="Oval"
                                        color="#2E8EFD"
                                        height="auto"
                                        width={20}
                                        className="mx-2"
                                      />
                                      <label className="mx-2 my-auto">
                                        Verifying license ...
                                      </label>
                                    </div>
                                  </React.Fragment>
                                )}
                                {this.state.dlVerified === "verified" && (
                                  <React.Fragment>
                                    <img
                                      src={CheckV}
                                      height="auto"
                                      width="25"
                                      className="mx-2"
                                    ></img>
                                    <label className="mx-2 my-auto">
                                      Verified !!
                                    </label>
                                  </React.Fragment>
                                )}
                                {this.state.dlVerified === "failed" && (
                                  <React.Fragment>
                                    <img
                                      src={ErrorV}
                                      height="auto"
                                      width="25"
                                      className="mx-2"
                                    ></img>
                                    <label
                                      className="mx-2"
                                      style={{ color: "#FF0000" }}
                                    >
                                      Verification failed! Retry
                                    </label>
                                  </React.Fragment>
                                )}
                              </FormGroup>
                            </Form>
                          </Col>

                          <Col className="col-12 col-md-4">
                            <Form onSubmit={this.verifyRC}>
                              <FormGroup>
                                <label style={{ fontWeight: 800 }}>
                                  Verify RC
                                </label>
                                <div>
                                  <img src={RC} height="auto" width="250"></img>
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <label
                                  style={{
                                    fontSize: "calc(0.4rem + 1vmin)",
                                    fontWeight: 800,
                                  }}
                                >
                                  Enter your vehicle number
                                </label>
                                <Row>
                                  <Col className="col-5">
                                    <FormInput
                                      placeholder="KB72L"
                                      value={this.state.vehicle_num1}
                                      onChange={(e) =>
                                        this.handleVehiclenumChange(e, "f1")
                                      }
                                      readOnly={this.state.disableRC}
                                      className="py-3"
                                      required
                                    />
                                  </Col>
                                  <Col className="col-5">
                                    <FormInput
                                      placeholder="9607"
                                      className="py-3"
                                      value={this.state.vehicle_num2}
                                      readOnly={this.state.disableRC}
                                      onChange={(e) =>
                                        this.handleVehiclenumChange(e, "f2")
                                      }
                                      required
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <FormGroup className="mt-4">
                                <Button
                                  className="py-3 px-5"
                                  disabled={this.state.disableRC}
                                >
                                  <label
                                    className="my-0"
                                    style={{
                                      fontSize: "calc(0.4rem + 1vmin)",
                                      fontWeight: 800,
                                    }}
                                  >
                                    VERIFY
                                  </label>
                                </Button>
                              </FormGroup>
                              <FormGroup className="mt-5">
                                {this.state.rcVerified === "waiting" && (
                                  <React.Fragment>
                                    <div className="d-inline-flex">
                                      <Loader
                                        type="Oval"
                                        color="#2E8EFD"
                                        height="auto"
                                        width={20}
                                        className="mx-2"
                                      />
                                      <label className="mx-2 my-auto">
                                        Verifying RC ...
                                      </label>
                                    </div>
                                  </React.Fragment>
                                )}
                                {this.state.rcVerified === "verified" && (
                                  <React.Fragment>
                                    <img
                                      src={CheckV}
                                      height="auto"
                                      width="25"
                                      className="mx-2"
                                    ></img>
                                    <label className="mx-2 my-auto">
                                      Verified !!
                                    </label>
                                  </React.Fragment>
                                )}
                                {this.state.rcVerified === "failed" && (
                                  <React.Fragment>
                                    <img
                                      src={ErrorV}
                                      height="auto"
                                      width="25"
                                      className="mx-2"
                                    ></img>
                                    <label
                                      className="mx-2"
                                      style={{ color: "#FF0000" }}
                                    >
                                      Verification failed! Retry
                                    </label>
                                  </React.Fragment>
                                )}
                              </FormGroup>
                            </Form>
                          </Col>

                          <Col className="col-12 col-md-4">
                            <Form onSubmit={this.verifyPAN}>
                              <FormGroup>
                                <label style={{ fontWeight: 800 }}>
                                  Verify PAN
                                </label>
                                <div>
                                  <img
                                    src={PAN}
                                    height="auto"
                                    width="250"
                                  ></img>
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <label
                                  style={{
                                    fontSize: "calc(0.4rem + 1vmin)",
                                    fontWeight: 800,
                                  }}
                                >
                                  Name as in PAN
                                </label>
                                <FormInput
                                  placeholder="Enter your name"
                                  className="py-3"
                                  value={this.state.pan_name}
                                  onChange={this.handlePanName}
                                  readOnly={this.state.disablePAN}
                                  type="text"
                                  maxLength="10"
                                  minLength="10"
                                  required
                                />
                              </FormGroup>
                              <FormGroup>
                                <label
                                  style={{
                                    fontSize: "calc(0.4rem + 1vmin)",
                                    fontWeight: 800,
                                  }}
                                >
                                  PAN number
                                </label>
                                <FormInput
                                  placeholder="Enter PAN number"
                                  value={this.state.pan_number}
                                  onChange={this.handlePanNumber}
                                  className="py-3"
                                  readOnly={this.state.disablePAN}
                                  required
                                />
                              </FormGroup>
                              <FormGroup>
                                <label
                                  style={{
                                    fontSize: "calc(0.4rem + 1vmin)",
                                    fontWeight: 800,
                                  }}
                                >
                                  D.O.B as in PAN
                                </label>
                                {/* <FormInput
                              placeholder="Select date of birth"
                              className="py-3"
                              required
                            /> */}
                                <ReactDatePicker
                                  className="form-control py-3"
                                  selected={this.state.pandob}
                                  onChange={(date) =>
                                    this.handlePanDateChange(date)
                                  }
                                  readOnly={this.state.disablePAN}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="Select date of birth"
                                />
                              </FormGroup>
                              <FormGroup className="mt-4">
                                <Button
                                  className="py-3 px-5"
                                  disabled={this.state.disablePAN}
                                >
                                  <label
                                    className="my-0"
                                    style={{
                                      fontSize: "calc(0.4rem + 1vmin)",
                                      fontWeight: 800,
                                    }}
                                  >
                                    VERIFY
                                  </label>
                                </Button>
                              </FormGroup>
                              <FormGroup className="mt-5">
                                {this.state.panVerified === "waiting" && (
                                  <React.Fragment>
                                    <div className="d-inline-flex">
                                      <Loader
                                        type="Oval"
                                        color="#2E8EFD"
                                        height="auto"
                                        width={20}
                                        className="mx-2"
                                      />
                                      <label className="mx-2 my-auto">
                                        Verifying PAN ...
                                      </label>
                                    </div>
                                  </React.Fragment>
                                )}
                                {this.state.panVerified === "verified" && (
                                  <React.Fragment>
                                    <img
                                      src={CheckV}
                                      height="auto"
                                      width="25"
                                      className="mx-2"
                                    ></img>
                                    <label className="mx-2 my-auto">
                                      Verified !!
                                    </label>
                                  </React.Fragment>
                                )}
                                {this.state.panVerified === "failed" && (
                                  <React.Fragment>
                                    <img
                                      src={ErrorV}
                                      height="auto"
                                      width="25"
                                      className="mx-2"
                                    ></img>
                                    <label
                                      className="mx-2"
                                      style={{ color: "#FF0000" }}
                                    >
                                      Verification failed! Retry
                                    </label>
                                  </React.Fragment>
                                )}
                              </FormGroup>
                            </Form>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {this.state.success === false && (
          <ReactSnackBar
            Icon={
              <i
                className="far fa-times-circle fa-lg"
                style={{ color: "#FF0000" }}
              />
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
        {this.state.success === true && (
          <ReactSnackBar
            Icon={
              <span>
                <i
                  className="far fa-check-circle fa-lg"
                  style={{ color: "#26FF58" }}
                />
              </span>
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
      </React.Fragment>
    );
  }
}
AddBankLogic.propTypes = {
  inputValues: PropTypes.array,
};

AddBankLogic.defaultProps = {
  inputValues: [
    {
      label: "Account holder name",
      value: "",
      attrs: {},
      stats: false,
      name: "acc_holder_name",
    },
    {
      label: "Bank name",
      value: "",
      attrs: {},
      stats: false,
      name: "bank_name",
    },
    {
      label: "IFSC",
      value: "",
      attrs: {},
      stats: false,
      name: "ifsc",
    },
    {
      label: "Account number",
      value: "",
      attrs: {},
      stats: false,
      name: "acc_number",
    },
  ],
  addressField: [
    {
      label: "Current Address",
      value: "",
      attrs: {},
      stats: false,
    },
    {
      label: "Permanent Address",
      value: "",
      attrs: {},
      stats: false,
    },
  ],
  familyInputData: [
    {
      label: "Name",
      value: "",
      attrs: {},
      stats: false,
    },
    {
      label: "Phone number",
      value: "",
      attrs: {},
      stats: false,
    },
    {
      label: "Relationship",
      value: "",
      attrs: {},
      stats: false,
    },
  ],
  familyAddressField: [
    {
      label: "Address",
      value: "",
      attrs: {},
      stats: false,
    },
  ],
  driverPreference: [
    {
      label: "Preferred Location",
      value: "",
      attrs: {},
      stats: false,
    },
    {
      label: "Starting point",
      value: "",
      attrs: {},
      stats: false,
    },
  ],
};

export default AddBankLogic;
