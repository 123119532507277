// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Collapse,
  Button,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";
import DatePicker from "react-date-picker";

import TripDataBox from "../components/common/TripDataBox";
// import CollapseDiv from "../components/common-actions/CollapseDiv"
import PageTitle from "./../components/common/PageTitle";
// import DatePickerCustom from "../components/utilities/DatePicker"
// import CardLayout from "../components/common/CardLayout"
import TripView from "./../components/dataviews/DataView";
import * as config from "../constants/constants.json";
//import TripDetalis from "./../components/common/TripDetails"
const oneDay = 24 * 60 * 60 * 1000;
function formarText(data) {
  if (data === "mmt") {
    return "MMT";
  } else if (data === "glory") {
    return "Glory";
  } else if (data === "cleartrip") {
    return "Cleartrip";
  } else if (data === "zyppys") {
    return "Zyppys";
  } else if (data === "oyo") {
    return "Oyo";
  } else if (data === "yatra") {
    return "Yatra";
  } else if (data === "via") {
    return "Via.com";
  }
}

class Vendors extends React.Component {
  constructor(props) {
    // console.log(props)
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: false,
      hits: {},
      date: new Date(),
      offeredAmount: "",
      openTip: false,
      showDetails: false,
      daySelected: "Today",
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    // console.log(this.state.hits);
    this.onChange = this.onChange.bind(this);
    this.toggleTip = this.toggleTip.bind(this);
    this.goBack = this.goBack.bind(this);
    this.ridesCountSNextDate = this.ridesCountSNextDate.bind(this);
    this.ridesCountSPrevDate = this.ridesCountSPrevDate.bind(this);
    // console.log(props)
  }
  ridesCountSNextDate() {
    let date = this.state.date;
    console.log(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    nextDate.setHours(0, 0, 0, 0);
    console.log(nextDate);
    if (nextDate) {
      this.setState({
        date: nextDate,
      });
    }
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));
    console.log(diffDays);
    if (nextDate.getTime() >= currentDate.getTime()) {
      if (nextDate.getTime() == currentDate.getTime()) {
        this.setState(
          {
            daySelected: "Today",
          },
          () => {
            this.countFetch();
          }
        );
        // getAllRidesCount();
      } else {
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Tomorrow",
            },
            () => {
              this.countFetch();
            }
          );
          // $("#ridesCountSDateName").text("Tomorrow");
          // getAllRidesCount();
        } else {
          diffDays = diffDays - 1;
          console.log(diffDays);
          if (diffDays == 1) {
            this.setState(
              {
                daySelected: "Day after tomorrow",
              },
              () => {
                this.countFetch();
              }
            );
            // $("#ridesCountSDateName").text("Day after tomorrow");
          } else {
            this.setState(
              {
                daySelected: `${diffDays} days after tomorrow`,
              },
              () => {
                this.countFetch();
              }
            );
            // $("#ridesCountSDateName").text(diffDays + " days after tomorrow");
          }
          // getAllRidesCount();
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));

      if (diffDays == 1) {
        this.setState(
          {
            daySelected: "Yesterday",
          },
          () => {
            this.countFetch();
          }
        );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Day before yesterday",
            },
            () => {
              this.countFetch();
            }
          );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          this.setState(
            {
              daySelected: `${diffDays} days from yesterday`,
            },
            () => {
              this.countFetch();
            }
          );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  }

  ridesCountSPrevDate() {
    let date = this.state.date;
    console.log(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() - 1);
    nextDate.setHours(0, 0, 0, 0);
    console.log(nextDate);
    if (nextDate) {
      this.setState({
        date: nextDate,
      });
    }
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));
    console.log(diffDays);
    if (nextDate.getTime() >= currentDate.getTime()) {
      if (nextDate.getTime() == currentDate.getTime()) {
        this.setState(
          {
            daySelected: "Today",
          },
          () => {
            this.countFetch();
          }
        );
        // getAllRidesCount();
      } else {
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Tomorrow",
            },
            () => {
              this.countFetch();
            }
          );
          // $("#ridesCountSDateName").text("Tomorrow");
          // getAllRidesCount();
        } else {
          diffDays = diffDays - 1;
          console.log(diffDays);
          if (diffDays == 1) {
            this.setState(
              {
                daySelected: "Day after tomorrow",
              },
              () => {
                this.countFetch();
              }
            );
            // $("#ridesCountSDateName").text("Day after tomorrow");
          } else {
            this.setState(
              {
                daySelected: `${diffDays} days after tomorrow`,
              },
              () => {
                this.countFetch();
              }
            );
            // $("#ridesCountSDateName").text(diffDays + " days after tomorrow");
          }
          // getAllRidesCount();
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));

      if (diffDays == 1) {
        this.setState(
          {
            daySelected: "Yesterday",
          },
          () => {
            this.countFetch();
          }
        );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Day before yesterday",
            },
            () => {
              this.countFetch();
            }
          );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          this.setState(
            {
              daySelected: `${diffDays} days from yesterday`,
            },
            () => {
              this.countFetch();
            }
          );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  }
  goBack() {
    this.props.history.goBack();
  }
  // onChange = date => this.setState({ date })
  handleOnChange(event) {
    // console.log(event)
    // console.log(event.target.value)
    // console.log(event.target.id);
    this.setState({
      offeredAmount: event.target.value,
    });
  }
  toggleTip() {
    this.setState({
      openTip: !this.state.openTip,
    });
  }
  onChange(date) {
    // console.log(this);
    // console.log(date)
    this.setState({ date }, () => {
      this.countFetch();
    });
  }
  toggle() {
    this.setState({
      collapse: !this.state.collapse,
      showDetails: !this.state.showDetails,
    });
  }

  countFetch() {
    let time = this.state.date;
    let milliseconds = time.getTime();
    // console.log(new Date(this.state.date));
    fetch(config.url + "htmlcrm/getAllRidesCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        milliseconds: milliseconds,
        region_name: window.sessionStorage.getItem("operating_region"),
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result)
          let resArray = {
            totalRidesCount: result.totalRidesCount,
            assignedRides: result.scheduledRidesCount.assignedRides,
            unassignedRides: result.scheduledRidesCount.unassignedRides,
            cancelledRides: result.scheduledRidesCount.cancelledRides,
            completedRides: result.scheduledRidesCount.completedRides,
            ongoing: result.ongoing,
            detachedRides: result.scheduledRidesCount.detachedRides,
            consumerCanceledCount: result.consumerCanceledCount,
            driverCanceledCount: result.driverCanceledCount,
          };
          this.setState({ hits: resArray });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    } else {
      this.countFetch();
    }
  }
  // componentDidMount() {

  // }

  render() {
    let data = this.state.hits;
    // console.log("****************");
    console.log(data);
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4 px-3">
          <Col className="col-12">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Business accounts
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {this.props.location.state !== undefined
                  ? formarText(this.props.location.state.label)
                  : ""}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="col-12">
            <PageTitle
              title="Account overview"
              className="text-sm-left mb-3 pl-0 custom-margin"
            />
          </Col>
        </Row>
        <Row className="px-3">
          <Col className="col-md-4 mb-4 d-none d-sm-none d-md-block d-xl-block">
            <Row>
              <Col className="col-4 col-md-6 ">
                <div
                  className="px-2 mx-2 mb-2"
                  style={{
                    width: "100%",
                    minWidth: "220px",
                    textAlign: "center",
                    verticalAlign: "center",
                  }}
                >
                  <i
                    id="ridesCountSPrevDate"
                    className="fas fa-arrow-left float-left"
                    onClick={this.ridesCountSPrevDate}
                  />
                  <a id="ridesCountSDateName">{this.state.daySelected}</a>
                  <i
                    id="ridesCountSNextDate"
                    className="fas fa-arrow-right float-right"
                    onClick={this.ridesCountSNextDate}
                  />
                </div>
              </Col>
            </Row>
            {/* <div className="w-50"> */}
            <Row>
              <Col className="col-12">
                <DatePicker
                  className="w-50 date-picker-custom"
                  format="dd/MM/yyyy"
                  onChange={this.onChange}
                  value={this.state.date}
                  clearIcon={null}
                  calendarIcon={<i className="far fa-calendar-alt" />}
                />
              </Col>
            </Row>

            {/* </div> */}
          </Col>
          <Col className="col-12 mx-auto mb-4 d-block d-sm-block d-md-none d-xl-none">
            {/* <div className="w-50 mx-auto"> */}
            <DatePicker
              className="w-100 date-picker-custom"
              format="dd/MM/yyyy"
              onChange={this.onChange}
              value={this.state.date}
              clearIcon={null}
              calendarIcon={<i className="far fa-calendar-alt" />}
            />
            {/* </div> */}
          </Col>
          {this.props.smallStats.map((stats, idx) => (
            <Col className="col-md mb-4 ml-auto" key={idx} {...stats.attrs}>
              {/* {console.log(this.state.hits)} */}
              <TripDataBox
                id={`smaller-stats-${idx}`}
                variation="1"
                label={stats.label}
                value={data.totalRidesCount}
                stats={stats.stats}
              />
            </Col>
          ))}
        </Row>
        <div>
          <Row className="px-3">
            <Col className="col-12 col-md-9 col-lg-9 col-xl-9">
              <Button
                block
                theme="light"
                onClick={this.toggle}
                className="mb-4 w-md-100 font-props"
                style={{ fontWeight: "700" }}
              >
                {this.state.showDetails === false
                  ? "SHOW DETAILS"
                  : "COLLAPSE DETAILS"}
                <i
                  className={
                    this.state.showDetails === false
                      ? "fas fa-chevron-down float-right"
                      : "fas fa-chevron-up float-right"
                  }
                />
              </Button>
            </Col>
          </Row>
          <Collapse open={this.state.collapse} className="">
            <Row className="px-3">
              {this.props.RideStats.map((stats, idx) => (
                <Col className="col-md mb-4" key={idx} {...stats.attrs}>
                  {/* {console.log(data[stats.apiID])} */}
                  <TripDataBox
                    id={`small-stats-${idx}`}
                    variation="1"
                    label={stats.label}
                    value={this.state.hits[stats.apiID]}
                    stats={stats.stats}
                  />

                  <Tooltip
                    trigger="hover"
                    open={this.state.openTip}
                    target={"#small-stats-4"}
                    toggle={this.toggleTip}
                    placement="bottom"
                  >
                    <span>
                      Consumer Cancelled :{" "}
                      {this.state.hits.consumerCanceledCount}
                    </span>
                    <br />
                    <span>
                      Cancelled via panel :{" "}
                      {this.state.hits.driverCanceledCount}
                    </span>
                  </Tooltip>
                </Col>
              ))}
            </Row>
          </Collapse>
        </div>
        <hr />
        <Row className="px-3">
          {this.props.CountStats.map((stats, idx) => (
            <Col className="col-md mb-4" key={idx} {...stats.attrs}>
              <TripDataBox
                id={`smaller-stats-${idx}`}
                variation="1"
                label={stats.label}
                value={stats.value}
                stats={stats.stats}
              />
            </Col>
          ))}
          <Col className="my-auto">
            <div className="float-right mx-3">
              <Button className="" id="goback-button" onClick={this.goBack}>
                <i className="fas fa-arrow-left mx-1" />
                <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                  BACK
                </span>{" "}
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="px-3 py-2">
          <Col className="col-12 col-md-12 col-lg-12">
            <div className="float-right">
              <a target="_blank" href="/nearbydrivers">
                <u>NEARBY DRIVERS MAP</u>
              </a>
            </div>
          </Col>
        </Row>
        <Row className="px-md-2 mb-5">
          <TripView propsData={this.props} />
        </Row>
      </Container>
    );
  }
}
Vendors.propTypes = {
  smallStats: PropTypes.array,
  RideStats: PropTypes.array,
  CountStats: PropTypes.array,
  hits: PropTypes.string,
  date: PropTypes.oneOf(["object", "string"]),
  tripDate: PropTypes.oneOf(["object", "string"]),
};

Vendors.defaultProps = {
  smallStats: [
    {
      label: "Total",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "totalRidesCount",
    },
  ],
  RideStats: [
    {
      label: "UNASSIGNED",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "unassignedRides",
    },
    {
      label: "ASSIGNED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "assignedRides",
    },
    {
      label: "ON-GOING RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "ongoing",
    },
    {
      label: "COMPLETED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "completedRides",
    },
    {
      label: "CANCELLED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "cancelledRides",
    },
    {
      label: "DETACHED",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "detachedRides",
    },
  ],
  CountStats: [
    {
      label: "Total",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
    },
  ],
};

export default Vendors;
