import React, { Component } from "react";
import PropTypes from "prop-types";
import * as config from "../../constants/constants.json";
import ReactSnackBar from "react-js-snackbar";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  FormCheckbox,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormTextarea,
} from "shards-react";

class RideIssues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      convoDetails: [],
      success: false,
      Show: false,
      Showing: false,
      message: "",
      replyDescription: "",
    };
    this.getReports = this.getReports.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.resolveIssue = this.resolveIssue.bind(this);
  }

  resolveIssue() {
    fetch(config.url + "htmlcrm/updateIssueStatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        issue_id: this.state.convoDetails.issue_id,
        userid: window.sessionStorage.getItem("user_id"),
        status: "closed",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if (result.message === "successfull") {
            let data = {
              issue_id: this.state.convoDetails.issue_id,
            };
            this.getConversation(data);
          } else {
          }
          // let data = {
          //   issue_id: this.state.convoDetails.issue_id,
          // };
          // this.getConversation(data);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  componentDidMount() {
    // this.getReports();
    this.getConversation(this.props.data);
    this.submitReply = this.submitReply.bind(this);
  }

  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };
  handleOnChange(event) {
    if (event.target.id === "replyDescription") {
      this.setState({
        replyDescription: event.target.value,
      });
    }
  }
  refreshList() {
    let data = {
      issue_id: this.state.convoDetails.issue_id,
    };
    // console.log(data);
    this.getConversation(data);
  }
  submitReply() {
    if (
      this.state.replyDescription === undefined ||
      this.state.replyDescription === null ||
      this.state.replyDescription === ""
    ) {
      this.setState(
        {
          success: false,
          message: "Reply cannot be empty",
        },
        () => {
          this.show();
        }
      );
    } else {
      fetch(config.url + "htmlcrm/addCommentToRI", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          issue_id: this.state.convoDetails.issue_id,
          userid: window.sessionStorage.getItem("user_id"),
          username: window.sessionStorage.getItem("username"),
          status: "open",
          message: this.state.replyDescription,
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log("herererer");
            this.setState(
              {
                replyDescription: "",
              },
              () => {
                let data = {
                  issue_id: this.state.convoDetails.issue_id,
                };
                this.getConversation(data);
              }
            );
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    }
  }
  getConversation(data) {
    fetch(config.url + "htmlcrm/getIssueData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        issue_id: data.issue_id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          // console.log("herererer");
          this.setState({
            convoDetails: result.data,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  getReports() {
    fetch(config.url + "htmlcrm/getAllReportedIssues", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // trip_id: this.props.tripId,
        trip_id: "RPpx5khtOZKsNJtJ",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          this.setState({
            reviewList: result.results,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  render() {
    return (
      <Row className="px-md-3 mb-5">
        <Col className="col-md-12 col-12">
          <Card className="my-4 mx-1 px-md-4 px-lg-4 px-xl-4">
            <CardHeader className="my-4 text-center text-uppercase font-weight-bold">
              <h6 style={{ fontWeight: "800", fontSize: "1.2rem" }}>
                Ride issues
                <button
                  type="button"
                  className="close float-right ml-auto mr-3"
                  aria-label="Close"
                  onClick={this.props.enableRideIssue}
                >
                  <span aria-hidden="true">
                    <i class="fas fa-times" />
                  </span>
                </button>
              </h6>
            </CardHeader>
            <CardBody>
              <Row className="px-md-2">
                <Col className="col-12 col-md-2 mb-4">
                  <label className="trip-debug-title-response">Issue ID</label>
                  <div>{this.props.data.issue_id}</div>
                </Col>
                <Col className="col-12 col-md-2 mb-4">
                  <label className="trip-debug-title-response">Title</label>
                  <div>{this.props.data.title}</div>
                </Col>
                <Col className="col-12 col-md-2 mb-4">
                  <label className="trip-debug-title-response">
                    Reported by
                  </label>
                  <div>{this.props.data.username}</div>
                </Col>
                <Col className="col-12 col-md-2 mb-4">
                  <label className="trip-debug-title-response">
                    Reviewed on
                  </label>
                  <div>
                    {moment(new Date(this.props.data.statusrevise)).format(
                      "DD-MM-YY [at] hh:mm:ss A"
                    )}
                  </div>
                </Col>
                <Col className="col-12 col-md-2 mb-4">
                  <label className="trip-debug-title-response">Status</label>
                  <div>
                    {this.state.convoDetails.status !== undefined
                      ? this.state.convoDetails.status
                      : this.props.data.status}
                  </div>
                </Col>
                <Col className="col-12 col-md-2 mb-4">
                  {(window.sessionStorage.getItem("roletype") === "manager" ||
                    window.sessionStorage.getItem("roletype") ===
                      "assistantmanager") && (
                    <Button
                      className="button-width-style"
                      theme="dark"
                      onClick={this.resolveIssue}
                      disabled={
                        this.state.convoDetails.status === "open" ? false : true
                      }
                    >
                      <label className="my-0 py-1"> CLOSE ISSUE</label>
                    </Button>
                  )}
                </Col>
              </Row>
              <hr />
              <Row className="mb-5">
                <Col className="col-12 col-md-12 px-md-3">
                  <div className="my-4">
                    <Button
                      theme="dark"
                      onClick={this.refreshList}
                      style={{ float: "right" }}
                    >
                      <i className="fas fa-sync-alt" />
                    </Button>
                  </div>
                </Col>
              </Row>
              {this.state.convoDetails.chatsession !== undefined && (
                <Row className="px-md-2 mt-3 mb-5">
                  {this.state.convoDetails.chatsession.map((data, index) => (
                    <React.Fragment>
                      <Col className="d-none d-sm-none d-md-block col-md-1 mx-auto mb-4">
                        <img
                          src="https://panel.hornok.in/static/images/avatars/man.png"
                          height="auto"
                          width="60"
                          style={{ borderRadius: "50%" }}
                        ></img>
                      </Col>
                      <Col className="col-12 col-md-11 mb-4" key={index}>
                        <Row>
                          <Col className="col-6 d-flex justify-content-start mb-2 trip-debug-title-response">
                            {data.username}
                          </Col>
                          <Col className="col-6 d-flex justify-content-end mb-2 trip-debug-title-response">
                            {moment(new Date(data.milliseconds)).format(
                              "DD-MM-YY [at] hh:mm:ss A"
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-12 trip-debug-title">
                            {data.message}
                          </Col>
                        </Row>
                      </Col>
                    </React.Fragment>
                  ))}
                </Row>
              )}
              <Row className="mx-md-4">
                <Col className="mb-4 mx-md-2">
                  <FormTextarea
                    id="replyDescription"
                    type="text"
                    rows="4"
                    placeholder="Reply to the issue"
                    onChange={this.handleOnChange}
                    value={this.state.replyDescription}
                  />
                </Col>
                <Col className="col-12 d-flex justify-content-center mt-3">
                  <Button
                    className="button-width-style py-3 "
                    theme="dark"
                    onClick={this.submitReply}
                    disabled={
                      this.state.convoDetails.status === "open" ? false : true
                    }
                  >
                    <label className="px-5 my-0 py-1"> SUBMIT</label>
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default RideIssues;
