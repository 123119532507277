import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  FormSelect,
  Button,
} from "shards-react";
import axios from "axios";
import moment from "moment";
import Radio from "@material-ui/core/Radio";
import PropTypes from "prop-types";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ReactDatepicker from "react-datepicker";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import PageTitle from "./../components/common/PageTitle";
import TripDataBox from "./../components/common/TripDataBox";
import * as config from "../constants/constants.json";
import ReactSnackBar from "react-js-snackbar";
// import Select from "@material-ui/core/Select";

const RadioButton = withStyles({
  root: {
    color: "black",
    "&$checked": {
      color: "black",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function getMaxDistace(data, value) {
  if (value === "from_airport") {
    switch (data) {
      case "1":
        return 10;
      case "2":
        return 20;
      case "3":
        return 30;
      case "4":
        return 35;
      case "5":
        return 40;
      case "6":
        return 45;
      default:
        return "";
    }
  } else {
    switch (data) {
      case "1":
        return 20;
      case "2":
        return 25;
      case "3":
        return 30;
      case "4":
        return 35;
      case "5":
        return 40;
      case "6":
        return 45;
      case "7":
        return 50;
      default:
        return "";
    }
  }
}

function getMinHour(data) {
  switch (data) {
    case "1":
      return 0;
    case "2":
      return 6;
    case "3":
      return 11;
    case "4":
      return 16;
    case "5":
      return 21;
    default:
      return "";
  }
}

function getMaxHour(data) {
  switch (data) {
    case "1":
      return 6;
    case "2":
      return 11;
    case "3":
      return 16;
    case "4":
      return 21;
    case "5":
      return 0;
    default:
      return "";
  }
}

function getMinDistace(data, value) {
  // console.log(data);
  // console.log(value);
  if (value === "from_airport") {
    switch (data) {
      case "1":
        return 0;
      case "2":
        return 10;
      case "3":
        console.log("here");
        return 20;
      case "4":
        return 30;
      case "5":
        return 35;
      case "6":
        return 40;
      default:
        return "";
    }
  } else {
    switch (data) {
      case "1":
        return 0;
      case "2":
        return 20;
      case "3":
        return 25;
      case "4":
        return 30;
      case "5":
        return 35;
      case "6":
        return 40;
      case "7":
        return 45;
      default:
        return "";
    }
  }
}

export default function ConfigAnalyics(props) {
  // console.log(moment("0", ["HH"]).format("h A"));
  //   const classes = useStyles();
  const [value, setValue] = React.useState("");
  const [valueDate, setValueDate] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [date, setDate] = React.useState([new Date(), new Date()]);
  const [filterTime, setFilterTime] = React.useState("");
  const [filterVehicle, setFilterVehcile] = React.useState("");
  const [filterDistance, setDistanceSlab] = React.useState("");
  const [filterTimeSlab, setFilterTImeSlab] = React.useState("");
  const [hits, setHits] = React.useState({});
  const [showLogs, setShowLogs] = React.useState(false);
  const [Show, setShow] = React.useState(false);
  const [Showing, setShowing] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);

  useEffect(() => {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    } else {
      //   if (
      //     window.sessionStorage.getItem("usertype") === "dispatchexecutive" ||
      //     window.sessionStorage.getItem("usertype") === "dispatchmanager"
      //   ) {
      //     // console.log("here");
      //     this.getuserDetailsFromServer();
      //   }
    }
  }, []);

  const show = () => {
    if (Showing) return;
    setShow(true);
    setShowing(true);
    // this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      // this.setState({ Show: false, Showing: false });
      setShow(false);
      setShowing(false);
    }, 2500);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeTimeSlab = (event) => {
    setFilterTImeSlab(event.target.value);
  };

  const handleChangeDistanceSlab = (event) => {
    console.log(event.target.value);
    if (event.target.value) {
      setDistanceSlab(event.target.value);
    } else {
      setFilterTImeSlab("");
      setDistanceSlab(event.target.value);
    }
  };

  const handleChangeVehicleType = (event) => {
    if (event.target.value) {
      setFilterVehcile(event.target.value);
    } else {
      setDistanceSlab("");
      setFilterTImeSlab("");
      setFilterVehcile(event.target.value);
    }
  };
  const handleChangeTimeType = (event) => {
    if (event.target.value) {
      setFilterTime(event.target.value);
    } else {
      setFilterVehcile("");
      setDistanceSlab("");
      setFilterTImeSlab("");
      setFilterTime(event.target.value);
    }
  };

  const handleChangeDate = (event) => {
    setValueDate(event.target.value);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleDateRange = (date) => {
    setDate(date);
  };

  const goBackAccounts = () => {
    props.history.push("/configuration");
  };

  const generateLogs = () => {
    if (value === "to_airport" || value === "from_airport") {
      let dataToSend = {
        booking_flow: value,
        date: {
          is_available: valueDate ? true : false,
          min_date:
            valueDate === "individual"
              ? selectedDate.setHours(0, 0, 0, 0)
              : date[0].setHours(0, 0, 0, 0),
          max_date:
            valueDate === "individual"
              ? selectedDate.setHours(0, 0, 0, 0)
              : date[1].setHours(0, 0, 0, 0),
          type: valueDate,
        },
        based_on: filterTime ? filterTime : "",
        vehicle: {
          is_available: filterVehicle ? true : false,
          vehicle_type: filterVehicle,
        },
        time: {
          is_available: filterTimeSlab ? true : false,
          minimum_hour: getMinHour(filterTimeSlab),
          maximum_hour: getMaxHour(filterTimeSlab),
        },
        distance: {
          is_available: filterDistance ? true : false,
          minimum_km: getMinDistace(filterDistance, value),
          maximum_km: getMaxDistace(filterDistance, value),
        },
        taxi_distance: {
          is_available: filterDistance ? true : false,
          minimum_km: getMinDistace(filterDistance, value),
          maximum_km: getMaxDistace(filterDistance, value),
        },
      };
      console.log(dataToSend);
      axios
        .post(config.url + "htmlcrm/getTripCountAfterFareUpdate", dataToSend)
        .then((response) => {
          console.log(response.data);
          if (response.data.message === "successfull") {
            setHits({
              tripCount: response.data.result ? response.data.result : "-",
              lastUpdatedOn: response.data.fare_info.last_updated
                ? response.data.fare_info.last_updated
                : "-",
              distanceSlab: response.data.fare_info.minimum_km
                ? response.data.fare_info.minimum_km +
                  " - " +
                  response.data.fare_info.maximum_km +
                  " " +
                  "kms"
                : "-",
              timeSlab: response.data.fare_info.minimum_hour
                ? moment(response.data.fare_info.minimum_hour, ["HH"]).format(
                    "h A"
                  ) +
                  " - " +
                  moment(response.data.fare_info.maximum_hour, ["HH"]).format(
                    "h A"
                  )
                : "-",
            });
            setShowLogs(true);
          } else {
            setSuccess(false);
            setMessage("No logs available");
            show();
            setShowLogs(false);
          }
        })
        .catch();
    } else {
      setSuccess(false);
      setMessage("Please select booking flow");
      show();
      setShowLogs(false);
    }
  };
  // console.log(hits);
  return (
    <Container fluid className="main-content-container">
      <Row className="w-100">
        <Col className="col-12">
          <Breadcrumb>
            <BreadcrumbItem>
              <a href="#" onClick={goBackAccounts}>
                Configuration /
              </a>
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>

        <Col className="col-12 mt-md-1">
          <PageTitle
            title="Ride flow analytics"
            className="text-sm-left mb-3 pl-0 custom-margin"
          />
        </Col>
      </Row>

      <Row className="px-1 px-md-4 py-md-3 mt-4">
        <Col className="col-12 col-md-4 mt-md-auto">
          <FormControl component="fieldset" className="">
            <FormLabel component="legend" style={{ color: "black" }}>
              Booking flow
            </FormLabel>
            <RadioGroup
              row
              aria-label="booking"
              name="booking1"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="from_airport"
                control={<RadioButton />}
                label="Airport to city"
              />
              <FormControlLabel
                value="to_airport"
                control={<RadioButton />}
                label="City to airport"
              />
            </RadioGroup>
          </FormControl>
        </Col>
        {value && (
          <Col className="col-12 col-md-4 mt-4 mt-md-auto">
            <FormControl component="fieldset" className="w-100">
              <FormLabel component="legend" style={{ color: "black" }}>
                Filter by date
              </FormLabel>
              <RadioGroup
                row
                aria-label="booking"
                name="booking1"
                value={valueDate}
                onChange={handleChangeDate}
              >
                <FormControlLabel
                  value="individual"
                  control={<RadioButton />}
                  label="Individual"
                />
                <FormControlLabel
                  value="range"
                  control={<RadioButton />}
                  label="Date range"
                />
              </RadioGroup>
              {valueDate === "individual" && (
                <ReactDatepicker
                  className="form-control py-3 text-left w-100"
                  dateFormat="dd/MM/yyyy"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  placeholderText="Select time"
                  required
                  
                />
              )}
              {valueDate === "range" && (
                <DateRangePicker
                  className="form-control py-3 "
                  format="dd/MM/yyyy"
                  onChange={handleDateRange}
                  value={date}
                  clearIcon={null}
                  calendarClassName="w-100"
                  calendarIcon={<i className="far fa-calendar-alt" />}
                  style={{ borderStyle: "none", fontSize: "0.7rem" }}
                 
                />
              )}
            </FormControl>
          </Col>
        )}
        {valueDate && (
          <Col className="col-12 col-md-4 mt-4 mt-md-auto">
            <FormControl component="fieldset" className="w-100">
              <FormLabel component="legend" style={{ color: "black" }}>
                Filter based on
              </FormLabel>
              <FormSelect
                value={filterTime}
                onChange={handleChangeTimeType}
                style={{ height: "calc(3.16rem + 2px)" }}
                className="w-100"
              >
                <option value="">Select pickup type</option>
                <option value="pickup_time">Pickup time</option>
                <option value="request_time">Request time</option>
              </FormSelect>
            </FormControl>
          </Col>
        )}
        {filterTime && (
          <Col className="col-12 col-md-4 mt-4 mt-md-5">
            <FormControl component="fieldset" className="w-100">
              <FormLabel component="legend" style={{ color: "black" }}>
                Filter by vehicle type
              </FormLabel>
              <FormSelect
                value={filterVehicle}
                onChange={handleChangeVehicleType}
                style={{ height: "calc(3.16rem + 2px)" }}
                className="w-100"
              >
                <option value="">Select vehicle type</option>
                <option value="hatchback">Hatchback</option>
                <option value="sedan">Sedan</option>
                <option value="suv">SUV</option>
              </FormSelect>
            </FormControl>
          </Col>
        )}

        {filterVehicle && (
          <Col className="col-12 col-md-4 mt-4 mt-md-5">
            <FormControl component="fieldset" className="w-100">
              <FormLabel component="legend" style={{ color: "black" }}>
                Filter by distance slab
              </FormLabel>
              <FormSelect
                value={filterDistance}
                onChange={handleChangeDistanceSlab}
                style={{ height: "calc(3.16rem + 2px)" }}
                className="w-100"
              >
                {value === "from_airport" && (
                  <React.Fragment>
                    <option value="">Select distance slab</option>
                    <option value="1">0 to 10 kms</option>
                    <option value="2">10 to 20 kms</option>
                    <option value="3">20 to 30 kms</option>
                    <option value="4">30 to 35 kms</option>
                    <option value="5">35 to 40 kms</option>
                    <option value="6">40 to 45 kms</option>
                  </React.Fragment>
                )}
                {value === "to_airport" && (
                  <React.Fragment>
                    <option value="">Select distance slab</option>
                    <option value="1">0 to 20 kms</option>
                    <option value="2">20 to 25 kms</option>
                    <option value="3">25 to 30 kms</option>
                    <option value="4">30 to 35 kms</option>
                    <option value="5">35 to 40 kms</option>
                    <option value="6">40 to 45 kms</option>
                    <option value="7">45 to 50 kms</option>
                  </React.Fragment>
                )}
              </FormSelect>
            </FormControl>
          </Col>
        )}
        {filterDistance && (
          <Col className="col-12 col-md-4 mt-4 mt-md-5">
            <FormControl component="fieldset" className="w-100">
              <FormLabel component="legend" style={{ color: "black" }}>
                Filter by time slab
              </FormLabel>
              <FormSelect
                value={filterTimeSlab}
                onChange={handleChangeTimeSlab}
                style={{ height: "calc(3.16rem + 2px)" }}
                className="w-100"
              >
                <option value="">Select time slab</option>
                <option value="1">12 am to 06 am</option>
                <option value="2">06 am to 11 am</option>
                <option value="3">11 am to 04 pm</option>
                <option value="4">04 pm to 09 pm</option>
                <option value="5">09 pm to 12 am</option>
              </FormSelect>
            </FormControl>
          </Col>
        )}
      </Row>

      <Row className="d-flex justify-content-center my-5">
        <Button
          theme="dark"
          className="login-button-new py-3"
          style={{
            fontSize: "calc(0.5rem + 1vmin)",
            fontWeight: 700,
          }}
          onClick={generateLogs}
        >
          GENERATE LOG
        </Button>
      </Row>
      {showLogs === true && (
        <Row className="mt-4 mb-5">
          {props.RideStats.map((stats, idx) => (
            <Col className="col-md mb-4" key={idx} {...stats.attrs}>
              {console.log(hits[stats.apiID])}
              <TripDataBox
                id={`small-stats-${idx}`}
                variation="1"
                label={stats.label}
                value={hits[stats.apiID]}
                stats={stats.stats}
              />
            </Col>
          ))}
        </Row>
      )}
      {success === false && (
        <ReactSnackBar
          Icon={
            <i
              className="far fa-times-circle fa-lg"
              style={{ color: "#FF0000" }}
            />
          }
          Show={Show}
        >
          {message}
        </ReactSnackBar>
      )}
      {success === true && (
        <ReactSnackBar
          Icon={
            <span>
              <i
                className="far fa-check-circle fa-lg"
                style={{ color: "#26FF58" }}
              />
            </span>
          }
          Show={Show}
        >
          {message}
        </ReactSnackBar>
      )}
    </Container>
  );
}

ConfigAnalyics.propTypes = {
  RideStats: PropTypes.array,
  CountStats: PropTypes.array,
};

ConfigAnalyics.defaultProps = {
  RideStats: [
    {
      label: "Last updated on",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "lastUpdatedOn",
    },
    {
      label: "Distance slab",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "distanceSlab",
    },
    {
      label: "Time slab",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "timeSlab",
    },
    {
      label: "Number of trips",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "tripCount",
    },
  ],
};
