import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Collapse,
  Button,
  Tooltip,
  Card,
  CardBody,
  FormCheckbox,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CardHeader,
  FormInput,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";
import PageTitle from "./../components/common/PageTitle";
import ReactSnackBar from "react-js-snackbar";
import { ExcelRenderer } from "react-excel-renderer";
import ReactTable from "react-table-v6";
import Search from "./../components/common/SearchTrackerData";
import * as config from "../constants/constants.json";
class UploadTrackerFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      display: false,
      filteredData: [],
      searchInput: "",
      success: false,
      Show: false,
      Showing: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }
  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    }
  }
  handleClick() {
    // console.log(this.state.data)
    let data = this.state.data;
    if (data.length > 0) {
      for (let a = 0; a <= data.length - 1; a++) {
        data[a].milliseconds = new Date().getTime();
        data[a].user_id = sessionStorage.getItem("user_id");
        data[a].status = "-";
      }
      let dataObj = {
        user_id: window.sessionStorage.getItem("user_id"),
        drivers_list: JSON.stringify(data),
        milliseconds: new Date().setHours(0, 0, 0, 0),
      };
      console.log(dataObj);
      fetch(config.url + "htmlcrm/addleadsentry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(dataObj),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            console.log(result);
            if (result.message === "successfull") {
              this.setState(
                {
                  success: true,
                },
                () => {
                  this.show();
                }
              );
            } else {
              this.setState(
                {
                  success: false,
                },
                () => {
                  this.show();
                }
              );
            }

            // console.log("herererer");
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    }
  }

  handleSetFilteredData = (filteredData) => {
    this.setState({ filteredData });
  };

  handleSetSearchInput = (searchInput) => {
    this.setState({ searchInput });
  };
  fileHandler = (event) => {
    let fileObj = event.target.files[0];
    // let count = 0;
    let localUploadedJson = [];
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let xlRowArray = resp.rows;
        console.log(xlRowArray);
        for (let a = 0; a <= xlRowArray.length - 1; a++) {
          let driverObj = xlRowArray[a];
          console.log(driverObj);

          if (driverObj[0] !== "name" && driverObj[0] !== undefined) {
            let tempObj = {
              name: driverObj[0],
              phonenumber: driverObj[1],
              location: driverObj[2],
              vehicle_number: driverObj[3],
            };
            localUploadedJson.push(tempObj);
          }
        }
        console.log(localUploadedJson);
        this.setState({
          data: localUploadedJson,
          display: true,
        });
      }
    });
  };

  // Handle activation call

  render() {
    const dataToDisplay = this.state.searchInput.length
      ? this.state.filteredData
      : this.state.data;
    const columns = [
      {
        Header: "Sl no",
        id: "serialno",
        style: {},
        Cell: (row) => {
          // console.log(row);
          return <div>{row.viewIndex + 1}</div>;
        },
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Phone number",
        accessor: "phonenumber",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Base location",
        accessor: "location",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Vehicle number",
        accessor: "vehicle_number",
        style: { whiteSpace: "unset" },
      },
    ];
    return (
      <Container fluid className="main-content-container px-md-4 mb-5">
        <Row noGutters className="page-header py-4 px-md-3 mb-md-5">
          <Col>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="/driveraccounts">Driver accounts</a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  On-boarding tracker
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active />
            </Breadcrumb>
          </Col>
          <Col className="col-12 mt-1">
            <PageTitle
              title="Upload file"
              className="text-sm-left mb-3 p-0 custom-margin"
            />
          </Col>
        </Row>
        <Row className="mt-2 mb-3 px-md-3">
          <Col className="col-12 col-md-12 col-lg-12 mb-4">
            <Button
              className=" float-right"
              theme="dark"
              id="goback-button"
              onClick={this.goBack}
            >
              <i className="fas fa-arrow-left mx-1" />
              <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                BACK
              </span>{" "}
            </Button>
          </Col>
        </Row>
        <Row className="px-md-3">
          <Col className="col-12 col-md-12 col-lg-12">
            <Card>
              <CardHeader className="py-2">
                <Row className="">
                  <label className="mb-0 custom-font-size pr-1">
                    <u>UPLOAD DRIVERS</u>
                  </label>
                  <a
                    className=" my-auto"
                    style={{ fontSize: "0.7rem" }}
                    href="/TRACKER_SAMPLE.xlsx"
                    download
                  >
                    <u>Download sample</u>
                  </a>
                </Row>
              </CardHeader>

              <hr className="mt-0" />
              <CardBody className="px-2 px-md-4">
                <Row>
                  <Col className="col-12 col-md-4 mx-auto">
                    <input
                      type="file"
                      name="file"
                      onChange={this.fileHandler}
                    />
                  </Col>
                </Row>
                {this.state.display === true && (
                  <Row className="mt-5">
                    <Col className="col-12 col-md-12 col-lg-12">
                      <Search
                        data={this.state.data}
                        columns={columns}
                        handleSetFilteredData={this.handleSetFilteredData}
                        handleSetSearchInput={this.handleSetSearchInput}
                      />
                    </Col>
                    <Col className="col-12 col-md-12 col-lg-12 mt-4">
                      <ReactTable
                        data={dataToDisplay}
                        columns={columns}
                        className="text-center -striped -highlight"
                        minRows={dataToDisplay.length > 0 ? 0 : 4}
                        noDataText="No data to display"
                      />
                    </Col>
                  </Row>
                )}
              </CardBody>
              {this.state.display === true && (
                <React.Fragment>
                  <CardFooter className="d-none d-sm-none d-md-block">
                    <Button
                      className="float-right "
                      theme="dark"
                      size="lg"
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "700",
                        minWidth: "200px",
                        borderRadius: "3px",
                      }}
                      onClick={this.handleClick}
                    >
                      UPLOAD
                    </Button>
                  </CardFooter>
                  <CardFooter className="d-block d-sm-block d-md-none d-flex justify-content-center">
                    <Button
                      onClick={this.handleClick}
                      style={{
                        width: "125px",
                      }}
                    >
                      UPLOAD
                    </Button>
                  </CardFooter>
                </React.Fragment>
              )}
              {this.state.success === false && (
                <ReactSnackBar
                  Icon={
                    <i
                      className="far fa-times-circle fa-lg"
                      style={{ color: "#FF0000" }}
                    />
                  }
                  Show={this.state.Show}
                >
                  Document update failed, Please try again !!!
                </ReactSnackBar>
              )}
              {this.state.success === true && (
                <ReactSnackBar
                  Icon={
                    <span>
                      <i
                        className="far fa-check-circle fa-lg"
                        style={{ color: "#26FF58" }}
                      />
                    </span>
                  }
                  Show={this.state.Show}
                >
                  <label style={{ color: "white" }}>Document updated !!!</label>
                </ReactSnackBar>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

UploadTrackerFiles.propTypes = {};
UploadTrackerFiles.defaultProps = {};

export default UploadTrackerFiles;
