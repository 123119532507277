import React, { Component } from "react";
import { Col } from "shards-react";
import moment from "moment";

class ColumnConvo extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.data.chatsession.map((data, index) => (
          <Col className="col-12" key={index}>
            {data}
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default ColumnConvo;
