import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormRadio,
  FormInput,
  CardFooter,
  BreadcrumbItem,
  Breadcrumb,
} from "shards-react";
import PageTitle from "./../common/PageTitle";
import { Bar, Pie } from "react-chartjs-2";
import ReactTable from "react-table-v6";
import * as config from "../../constants/constants.json";
import Search from "./../common/CommonSearch";
import DatePicker from "@wojtekmaj/react-daterange-picker";
import ReactSnackBar from "react-js-snackbar";
class TripReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayResults: false,
      hideTripInput: true,
      hidePhoneLayout: true,
      hideDateLayout: true,
      monthNames: [],
      date: [new Date(), new Date()],
      monthValues: [],
      tripID: "",
      selectedType: null,
      phonenumber: "",
      data: [],
      Show: false,
      Showing: false,
      success: false,
      message: "",
      filteredData: [],
      searchInput: "",
    };
    // this.callApiToGetAnalytics = this.callApiToGetAnalytics.bind(this)
    // this.chartReference = React.createRef();
    this.goBack = this.goBack.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.callAPI = this.callAPI.bind(this);
    this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
    this.handlePhonenumber = this.handlePhonenumber.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  handleFilteredData = (filteredData) => {
    this.setState({ filteredData });
  };

  handleSearchInput = (searchInput) => {
    this.setState({ searchInput });
  };
  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };
  onChange(date) {
    // console.log(this);
    // console.log(date);
    this.setState({ date });
  }
  handlePhonenumber(event) {
    let data = event.target.value;
    this.setState({
      phonenumber: data,
    });
  }
  capitalizeFirstLetter(string) {
    // console.log(string);
    if (string !== undefined || string !== null) {
      return "-";
    } else {
      return string[0].toUpperCase() + string.slice(1);
    }
  }

  searchWithPhnDate(data) {
    // console.log(data);
    fetch(config.url + "payout/searchWithPhnDate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (results) => {
          console.log(results);
          if (results.result.length > 0) {
            // this.setState({
            //     managerData: result.executives
            // })
            // this.handleEditJob()
            // this.handleEditBiz()
            this.setState({
              displayResults: true,
              data: results.result,
            });
          } else {
            //Do nothing
            this.setState(
              {
                success: false,
                message: "No data was found for this range or phonenumber",
                data: [],
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  searchWithBookingID(data) {
    fetch(config.url + "payout/searchWithBookingID", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (results) => {
          console.log(results);
          if (results !== undefined) {
            // this.setState({
            //     managerData: result.executives
            // })
            // this.handleEditJob()
            // this.handleEditBiz()
            this.setState({
              displayResults: true,
              data: results.result,
            });
          } else {
            //Do nothing
            this.setState(
              {
                success: false,
                message: "No data was found for this booking ID",
                data: [],
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  callAPI() {
    let reqObj = {};
    switch (this.state.selectedType) {
      case "hornoktripid":
        reqObj.trip_id = this.state.tripID;
        reqObj.search_type = "glory";
        break;
      case "mmttripid":
        reqObj.trip_id = this.state.tripID;
        reqObj.search_type = "mmt";
        break;
      case "driver":
        reqObj.driverType = "all";
        reqObj.phoneNumber = this.state.phonenumber;
        reqObj.startMilliseconds = new Date(this.state.date[0]).getTime();
        reqObj.endMilliseconds = new Date(this.state.date[1]).getTime();
        reqObj.search_type = "driverphonenumber";
        break;
      case "daterange":
        reqObj.driverType = "all";
        reqObj.phoneNumber = null;
        reqObj.startMilliseconds = new Date(this.state.date[0]).getTime();
        reqObj.endMilliseconds = new Date(this.state.date[1]).getTime();
        reqObj.search_type = "daterange";
        break;
      default:
        break;
    }
    if (
      this.state.selectedType === "hornoktripid" ||
      this.state.selectedType === "mmttripid"
    ) {
      this.searchWithBookingID(reqObj);
    } else if (
      this.state.selectedType === "driver" ||
      this.state.selectedType === "daterange"
    ) {
      // console.log("search");
      this.searchWithPhnDate(reqObj);
    } else {
      // console.log("hello");
    }
  }
  handleInput(event) {
    let data = event.target.value;
    this.setState({
      tripID: data,
    });
  }
  changeType(data) {
    let setData;
    if (data === "hornoktripid" || data === "mmttripid") {
      setData = {
        selectedType: data,
        hideTripInput: false,
        hidePhoneLayout: true,
        hideDateLayout: true,
      };
    } else if (data === "driver") {
      setData = {
        selectedType: data,
        hideTripInput: true,
        hidePhoneLayout: false,
        hideDateLayout: false,
      };
    } else {
      setData = {
        selectedType: data,
        hideTripInput: true,
        hidePhoneLayout: true,
        hideDateLayout: false,
      };
    }
    this.setState(setData);
  }

  goBack() {
    this.props.history.goBack();
  }
  componentDidMount() {
    // this.callApiToGetAnalytics()
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    }
  }

  render() {
    const execDataToDisplay = this.state.searchInput.length
      ? this.state.filteredData
      : this.state.data;
    const columns = [
      {
        Header: "Trip Id",
        accessor: "trip_id",
      },
      {
        Header: "MMT ID",
        id: "order_reference_number",
        Cell: (row) => {
          return row.original.order_reference_number
            ? row.original.order_reference_number
            : "-";
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Source",
        accessor: "sname",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Destination",
        accessor: "dname",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Trip Status",
        accessor: "trip_status",
        Cell: (row) => {
          if (
            row.original.trip_status === "ended" ||
            row.original.trip_status === "completed"
          ) {
            return <div>Completed</div>;
          } else if (row.original.trip_status === "consumercancelled") {
            return <div>Rider Cancelled</div>;
          } else if (row.original.trip_status === "drivercancelled") {
            return <div>Cancelled via panel</div>;
          } else if (row.original.trip_status === undefined) {
            return <div> - </div>;
          } else {
            return (
              <div>{this.capitalizeFirstLetter(row.original.trip_status)}</div>
            );
          }
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Pickup time",
        accessor: "pickup_time",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Driver name",
        accessor: "driver_name",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Driver phone number",
        accessor: "phonenumber",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Driver Type",
        accessor: "driver_type",
        Cell: (row) => {
          return this.capitalizeFirstLetter(row.original.driver_type);
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Distance",
        accessor: "distance",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Per Km Fare",
        id: "perKmFare",
        Cell: (row) => {
          return (
            <div>
              {row.original.fare_breakdown !== undefined
                ? row.original.fare_breakdown.perKmFare
                : " - "}
            </div>
          );
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Base fare",
        id: "base_fare",
        Cell: (row) => {
          return (
            <div>
              {row.original.fare_breakdown !== undefined
                ? row.original.fare_breakdown.base_fare
                : " - "}
            </div>
          );
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Commission",
        id: "commission",
        Cell: (row) => {
          return (
            <div>
              {row.original.fare_breakdown !== undefined
                ? row.original.fare_breakdown.commission
                : " - "}
            </div>
          );
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Empty Kms",
        id: "empty_kms",
        Cell: (row) => {
          return (
            <div>
              {row.original.fare_breakdown !== undefined &&
              row.original.fare_breakdown.empty_kms
                ? row.original.fare_breakdown.empty_kms
                : "-"}
            </div>
          );
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Empty Kms Fare",
        id: "empty_km_fare",
        Cell: (row) => {
          return (
            <div>
              {row.original.fare_breakdown !== undefined &&
              row.original.fare_breakdown.empty_km_fare
                ? row.original.fare_breakdown.empty_km_fare
                : "-"}
            </div>
          );
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Fine",
        id: "fineAmount",
        Cell: (row) => {
          return (
            <div>
              {row.original.fare_breakdown !== undefined &&
              row.original.fare_breakdown.fineAmount
                ? row.original.fare_breakdown.fineAmount
                : "-"}
            </div>
          );
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Cash collected",
        id: "cashCollected",
        Cell: (row) => {
          return (
            <div>
              {row.original.fare_breakdown !== undefined &&
              row.original.fare_breakdown.cashCollected
                ? row.original.fare_breakdown.cashCollected
                : "-"}
            </div>
          );
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "MMT amount",
        accessor: "approx_fare",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "TDS",
        id: "tds",
        Cell: (row) => {
          return (
            <div>
              {row.original.fare_breakdown !== undefined &&
              row.original.fare_breakdown.tds
                ? row.original.fare_breakdown.tds
                : "-"}
            </div>
          );
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Offered amount",
        accessor: "offeredAmount",
        Cell: (row) => {
          return (
            <div>
              {row.original.offeredAmount ? row.original.offeredAmount : "-"}
            </div>
          );
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Payable amount",
        accessor: "finalPayout_amount",
        style: { whiteSpace: "unset" },
      },
      {
        id: "edit",
        Header: "Actions",
        Cell: (row) => {
          //  console.log(this);
          // console.log(row.original.request_status)
          return (
            <div className="">
              <Button
                className="px-4 my-2"
                theme="dark"
                onClick={() => {
                  this.getUserDetails(row.original.executiveid);
                }}
              >
                UPDATE
              </Button>
            </div>
          );
        },
      },
    ];

    // console.log(this.state);
    return (
      <Container fluid className="main-content-container px-md-4">
        <Row noGutters className="page-header py-4 px-md-3 mb-4">
          <Col className="col-12">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Reports
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active>Trip reports</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="col-12 mt-1">
            <PageTitle
              title="Generate trip reports"
              className="text-sm-left mb-3 mb-md-0 p-0 custom-margin"
            />
          </Col>
          {/* <PageTitle
            title="Trip Reports"
            subtitle=""
            className="text-sm-left mb-3"
          /> */}
        </Row>
        <Row className="mt-md-2 mb-3 px-md-3">
          <Col className="col-12 col-md-12 col-lg-12 mb-3">
            <Button
              className=" float-right"
              theme="dark"
              id="goback-button"
              onClick={this.goBack}
            >
              <i className="fas fa-arrow-left mx-1" />
              <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                BACK
              </span>{" "}
            </Button>
          </Col>
        </Row>
        <Row className="px-md-3 pb-3">
          <Col className="col-12 col-md-12 col-lg-12">
            <Card>
              <CardBody>
                <Row>
                  <Col className="col-12 col-md-12 mb-2">
                    <Row>
                      <Col className="col-6 mb-3">
                        <FormRadio
                          inline
                          name="report"
                          checked={this.state.selectedType === "hornoktripid"}
                          onChange={() => {
                            this.changeType("hornoktripid");
                          }}
                        >
                          Glory trip ID
                        </FormRadio>
                      </Col>
                      <Col className="col-6">
                        <FormRadio
                          inline
                          name="report"
                          checked={this.state.selectedType === "mmttripid"}
                          onChange={() => {
                            this.changeType("mmttripid");
                          }}
                        >
                          MMT trip ID
                        </FormRadio>
                      </Col>
                      <Col className="col-12 col-md-4 mb-3">
                        <FormInput
                          className="py-3"
                          hidden={this.state.hideTripInput}
                          onChange={this.handleInput}
                          value={this.state.tripID}
                          placeholder="Enter trip ID"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-12 col-md-6 ">
                    <Row>
                      <Col className="col-12 mb-3">
                        <FormRadio
                          inline
                          name="report"
                          checked={this.state.selectedType === "driver"}
                          onChange={() => {
                            this.changeType("driver");
                          }}
                        >
                          Driver
                        </FormRadio>
                      </Col>
                      <Col className="col-12 col-md-8 mb-3">
                        <FormInput
                          hidden={this.state.hidePhoneLayout}
                          onChange={this.handlePhonenumber}
                          value={this.state.phonenumber}
                          className="py-3"
                          placeholder="Enter driver phonenumber"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-12 col-md-6 ">
                    <Row>
                      <Col className="col-12 col-md-8 mb-3">
                        <FormRadio
                          inline
                          name="report"
                          checked={this.state.selectedType === "daterange"}
                          onChange={() => {
                            this.changeType("daterange");
                          }}
                        >
                          Date range
                        </FormRadio>
                      </Col>
                      <Col className="col-12 col-md-8">
                        {this.state.hideDateLayout === false && (
                          <DatePicker
                            className="form-control py-3 "
                            format="dd/MM/yyyy"
                            onChange={this.onChange}
                            value={this.state.date}
                            clearIcon={null}
                            calendarClassName="w-100"
                            calendarIcon={<i className="far fa-calendar-alt" />}
                            style={{ borderStyle: "none", fontSize: "0.7rem" }}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="col-12 col-md-12 col-lg-12">
                    <Button
                      className="float-right"
                      theme="dark"
                      size="lg"
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "700",
                        minWidth: "200px",
                        borderRadius: "3px",
                      }}
                      onClick={this.callAPI}
                    >
                      SEARCH
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        {this.state.displayResults === true && (
          <Row className="mb-5 px-md-3 mt-4">
            <Col className="col-12 col-md-12 col-lg-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-12 col-md-5 ml-auto my-3">
                      <Search
                        typeOfSearch="trip_reports"
                        data={this.state.data}
                        columns={columns}
                        handleSetFilteredData={this.handleFilteredData}
                        handleSetSearchInput={this.handleSearchInput}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12 col-md-12 col-lg-12 px-0 px-md-3">
                      <ReactTable
                        className="text-center -striped -highlight"
                        data={execDataToDisplay}
                        columns={columns}
                        minRows={execDataToDisplay.length > 0 ? 0 : 4}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {this.state.success === false && (
          <ReactSnackBar
            Icon={
              <i
                className="far fa-times-circle fa-lg"
                style={{ color: "#FF0000" }}
              />
            }
            Show={this.state.Show}
          >
            {this.state.message}
          </ReactSnackBar>
        )}
        {/* {this.state.success === true && (
          <ReactSnackBar
            Icon={
              <span>
                <i
                  className="far fa-check-circle fa-lg"
                  style={{ color: "#26FF58" }}
                ></i>
              </span>
            }
            Show={this.state.Show}
          >
            <h5 style={{ color: "white" }}>{this.state.message}</h5>
          </ReactSnackBar>
        )} */}
      </Container>
    );
  }
}

TripReports.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
};

TripReports.defaultProps = {
  title: "Ride Analysis",
};

export default TripReports;
