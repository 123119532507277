import React from "react";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from "shards-react";

export default () => (
  <Form className="pl-4 main-navbar__search w-100 d-none d-md-flex d-lg-flex d-xl-flex">
    <InputGroup seamless className="">
      <InputGroupAddon type="prepend">
        <InputGroupText>
          {/* <i className="material-icons">search</i> */}
        </InputGroupText>
      </InputGroupAddon>
      {/* <FormInput
        className="navbar-search"
        placeholder="Search using driver name, phonenumber or vehicle number..."
      /> */}
    </InputGroup>
  </Form>
);
