import React from "react";
import PropTypes from "prop-types";
// import classNames from "classnames";
// import shortid from "shortid";
import { FormTextarea } from "shards-react";
// import { Link, Route } from "react-router-dom";
// import Rides from "../../views/Rides";

class InputData extends React.Component {
  constructor(props) {
    super(props);
    // console.log("props");
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, name) {
    this.props.onKeyChange(e, name);
  }

  render() {
    const { label, value, name, id } = this.props;
    if (name === "current_address") {
      return (
        <div>
          <label className="label-style">
            {label} <span style={{ color: "#FF0000" }}>*</span>
          </label>
          <FormTextarea
            rows="8"
            id={id}
            name={name}
            onChange={(e) => this.handleChange(e, name)}
            type="text"
            placeholder="Enter address"
            value={value}
            readOnly={this.props.readOnly}
            required
          />
        </div>
      );
    } else {
      return (
        <div>
          <label className="label-style">{label}</label>
          <FormTextarea
            rows="8"
            id={id}
            name={name}
            onChange={(e) => this.handleChange(e, name)}
            type="text"
            placeholder="Enter address"
            value={value}
            readOnly={this.props.readOnly}
          />
        </div>
      );
    }
  }
}

InputData.propTypes = {
  /**
   * The Small Stats variation.
   */
  variation: PropTypes.string,
  /**
   * The label.
   */
  label: PropTypes.string,
  /**
   * The value.
   */
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  /**
   * Flag to differentiate stat box
   */
  stats: PropTypes.bool,
};

InputData.defaultProps = {
  value: "-",
  label: "Label",
};

export default InputData;
