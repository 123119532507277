import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Collapse,
  Button,
  Tooltip,
  Card,
  CardImg,
  CardBody,
  FormRadio,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";
import PageTitle from "./../components/common/PageTitle";
import config from "../constants/constants";
import TripDataBox from "./../components/common/TripDataBox";
import ReactDatepicker from "react-datepicker";
import Charts from "./../components/utilities/Charts";
import DatePicker from "@wojtekmaj/react-daterange-picker";
import ReactTable from "react-table-v6";
import Search from "../components/common/Search";

class DriverRideHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lifeTimeCount: "",
      yearCount: "",
      monthCount: "",
      weekCount: "",
      dayCount: "",
      year: new Date(),
      dynamicYearCount: "",
      arrData: [],
      acceptance_count: "",
      completed_count: "",
      cancelled_count: "",
      date: [new Date(), new Date()],
      selectedRideStatus: "all",
      dataList: [],
      searchInput: "",
      filteredData: [],
    };
    this.getCount = this.getCount.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleVisitChange = this.handleVisitChange.bind(this);
    this.getYearlyAnalytics = this.getYearlyAnalytics.bind(this);
    this.onRangeChange = this.onRangeChange.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.getRidesList = this.getRidesList.bind(this);
  }

  handleSetSearchInput = (searchInput) => {
    this.setState({ searchInput });
  };
  handleSetFilteredData = (filteredData) => {
    this.setState({ filteredData });
  };

  changeStatus(status) {
    this.setState({
      selectedRideStatus: status,
    });
  }
  onRangeChange = (date) => this.setState({ date });

  getRidesList() {
    let dataToSend = {
      start_milliseconds: this.state.date[0].getTime(),
      end_milliseconds: this.state.date[1].getTime(),
      value: this.state.selectedRideStatus,
      dunique_id: this.props.history.location.state.dunique_id,
    };
    console.log(dataToSend);
    fetch(config.url + "htmlcrm/driverRidesList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.setState({
              dataList: result.result,
            });
          } else {
            this.setState({
              dataList: [],
            });
          }

          // console.log("herererer");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  getCountsForPie() {
    console.log(this.state.year.getFullYear());
    fetch(config.url + "htmlcrm/driverRidesCountYear", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        dunique_id: this.props.history.location.state.dunique_id,
        year: this.state.year.getFullYear().toString(),
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.setState({
              acceptance_count: result.acceptance_count,
              completed_count: result.completed_count,
              cancelled_count: result.cancelled_count,
            });
          } else {
          }

          // console.log("herererer");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  getYearlyAnalytics() {
    fetch(config.url + "htmlcrm/driverYearlyCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // phonenumber: this.state.phonenumber
        // phonenumber: '8123173193'
        dunique_id: this.props.history.location.state.dunique_id,
        year: this.state.year.getFullYear(),
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.setState({
              arrData: result.results,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleVisitChange(date) {
    this.setState(
      {
        year: date,
      },
      () => {
        this.callApi();
        this.getYearlyAnalytics();
        this.getCountsForPie();
      }
    );
  }

  callApi() {
    fetch(config.url + "htmlcrm/driverYearCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // phonenumber: this.state.phonenumber
        // phonenumber: '8123173193'
        dunique_id: this.props.history.location.state.dunique_id,
        year: this.state.year.getFullYear(),
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.setState({
              dynamicYearCount: result.count,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  goBack() {
    this.props.history.goBack();
  }
  getCount() {
    fetch(config.url + "htmlcrm/driverLifeTimeCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // phonenumber: this.state.phonenumber
        // phonenumber: '8123173193'
        dunique_id: this.props.history.location.state.dunique_id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.setState({
              lifeTimeCount: result.count,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
    fetch(config.url + "htmlcrm/driverYearCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // phonenumber: this.state.phonenumber
        // phonenumber: '8123173193'
        dunique_id: this.props.history.location.state.dunique_id,
        year: new Date().getFullYear(),
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.setState({
              yearCount: result.count,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
    fetch(config.url + "htmlcrm/driverDateCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // phonenumber: this.state.phonenumber
        // phonenumber: '8123173193'
        dunique_id: this.props.history.location.state.dunique_id,
        type: "month",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.setState({
              monthCount: result.count,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
    fetch(config.url + "htmlcrm/driverDateCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // phonenumber: this.state.phonenumber
        // phonenumber: '8123173193'
        dunique_id: this.props.history.location.state.dunique_id,
        type: "week",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.setState({
              weekCount: result.count,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
    fetch(config.url + "htmlcrm/driverDateCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // phonenumber: this.state.phonenumber
        // phonenumber: '8123173193'
        dunique_id: this.props.history.location.state.dunique_id,
        type: "all",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.setState({
              dayCount: result.count,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    } else {
      this.getCount();
      this.callApi();
      this.getYearlyAnalytics();
      this.getCountsForPie();
    }
  }
  render() {
    let dataAnalytics = this.state.arrData;
    var labels = dataAnalytics.map(function (e) {
      return e._id;
    });
    var dataForPie = dataAnalytics.map(function (e) {
      return e.count;
    });

    let rideData = {
      labels: labels,
      datasets: [
        {
          borderColor: ["#5ec0cb", "#6bb756", "#CC5228"],
          backgroundColor: ["#5ec0cb", "#6bb756", "#CC5228"],
          data: dataForPie,
        },
      ],
    };

    let analyticData = {
      labels: ["Acceptance", "Completed", "Cancelled"],
      datasets: [
        {
          borderColor: ["#5ec0cb", "#6bb756", "#CC5228"],
          backgroundColor: ["#5ec0cb", "#6bb756", "#CC5228"],
          data: [
            this.state.acceptance_count,
            this.state.completed_count,
            this.state.cancelled_count,
          ],
        },
      ],
    };

    const driverActivationCol = [
      {
        id: "trip_id",
        Header: "Trip ID",
        accessor: (d) => d.trip_id,
      },
      {
        id: "pickuptime",
        Header: "Pickup time",
        accessor: (d) => d.pickuptime,
      },
      {
        id: "sname",
        Header: "Source",
        accessor: (d) => d.sname,
      },
      {
        id: "dname",
        Header: "Destination",
        accessor: (d) => d.dname,
      },

      {
        id: "consumername",
        Header: "Rider name",
        accessor: (d) => d.consumername,
      },
      {
        id: "phonenumber",
        Header: "Phone number",
        accessor: (d) => d.phonenumber,
      },
    ];

    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4 px-3">
          <Col className="col-12">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="/driveraccounts">Driver accounts</a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href="/driveraccounts/driverdashboard/">Driver dashboard</a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Driver details
                </a>
              </BreadcrumbItem>

              <BreadcrumbItem active />
            </Breadcrumb>
          </Col>
          <Col className="col-12">
            <PageTitle
              title="Driver ride history"
              className="text-sm-left mb-3 p-0 custom-margin"
            />
          </Col>
          {/* <PageTitle
            title="Driver ID proof"
            subtitle="Driver Dashboard"
            className="text-sm-left mb-3"
          /> */}
        </Row>
        <Row className="px-3">
          {this.props.RideStats.map((stats, idx) => (
            <Col className="col-md mb-4" key={idx} {...stats.attrs}>
              <TripDataBox
                id={`smaller-stats-${idx}`}
                variation="1"
                label={stats.label}
                value={this.state[stats.apiID]}
                stats={stats.stats}
              />
            </Col>
          ))}
        </Row>
        {/* {console.log(this.state.year)} */}
        <Row className="mb-5 px-md-3">
          <Col className="col-12 col-md-12 col-lg-12 mb-4 ">
            <Button
              className="float-right"
              id="goback-button"
              theme="dark"
              onClick={this.goBack}
            >
              <i className="fas fa-arrow-left mx-1" />
              <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                BACK
              </span>
            </Button>
          </Col>
          <div className="float-right mt-2" />
          <Col className="col-12 col-md-12 col-lg-12">
            <Card>
              <CardBody>
                <label className="label-style">Select year</label>
                <Row>
                  <Col className="col-12 col-md-4 col-lg-4 mb-4">
                    <ReactDatepicker
                      className="form-control w-75 text-center"
                      selected={this.state.year}
                      dateFormat="yyyy"
                      onChange={(date) => this.handleVisitChange(date)}
                      showYearPicker
                    />
                  </Col>
                </Row>
                <Row className="d-flex justify-content-md-end">
                  {this.props.TotalStat.map((stats, idx) => (
                    <Col className="col-md  mb-4" key={idx} {...stats.attrs}>
                      <TripDataBox
                        id={`smaller-stats-${idx}`}
                        variation="1"
                        label={stats.label}
                        value={this.state[stats.apiID]}
                        stats={stats.stats}
                      />
                    </Col>
                  ))}
                </Row>
                <Row>
                  <Col className="col-12 col-md-6 col-lg-6 mb-4">
                    <Charts data={rideData} />
                  </Col>
                  <Col className="col-12 col-md-6 col-lg-6 mb-4">
                    <Charts data={analyticData} />
                  </Col>
                </Row>
                <hr />
                <Row className="mb-4">
                  <Col className="col-12 col-md-4">
                    <label>Date: </label>
                    <DatePicker
                      className="form-control py-1 px-0"
                      format="dd/MM/yyyy"
                      clearIcon={null}
                      calendarIcon={<i className="far fa-calendar-alt" />}
                      onChange={this.onRangeChange}
                      value={this.state.date}
                    />
                  </Col>
                  <Col className="col-12  col-md-5">
                    <label>Ride status: </label>
                    <div className="">
                      <FormRadio
                        inline
                        name="status"
                        checked={this.state.selectedRideStatus === "all"}
                        onChange={() => {
                          this.changeStatus("all");
                        }}
                      >
                        <span className="mx-1">All</span>
                      </FormRadio>
                      <FormRadio
                        inline
                        name="status"
                        checked={this.state.selectedRideStatus === "completed"}
                        onChange={() => {
                          this.changeStatus("completed");
                        }}
                      >
                        <span className="mx-1">Completed</span>
                      </FormRadio>
                      <FormRadio
                        inline
                        name="status"
                        checked={this.state.selectedRideStatus === "cancelled"}
                        onChange={() => {
                          this.changeStatus("cancelled");
                        }}
                      >
                        <span className="mx-1">Cancelled</span>
                      </FormRadio>
                    </div>
                  </Col>
                  <Col className="col-12 col-md-3">
                    <Button
                      className=" mt-4"
                      theme="dark"
                      size="lg"
                      onClick={this.getRidesList}
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "700",
                        minWidth: "200px",
                        borderRadius: "3px",
                      }}
                    >
                      SEARCH
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-12">
                    <ReactTable
                      data={this.state.dataList}
                      columns={driverActivationCol}
                      className="text-center -striped -highlight"
                      noDataText="No data to display"
                      minRows={this.state.dataList.length > 0 ? 0 : 4}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row />
      </Container>
    );
  }
}

DriverRideHistory.propTypes = {
  RideStats: PropTypes.array,
};
DriverRideHistory.defaultProps = {
  RideStats: [
    {
      label: "LIFE TIME RIDES",
      value: "0",
      attrs: { md: "2", sm: "6" },
      stats: false,
      apiID: "lifeTimeCount",
    },
    {
      label: "YEARLY RIDES",
      value: "0",
      attrs: { md: "2", sm: "6" },
      stats: false,
      apiID: "yearCount",
    },
    {
      label: "MONTHLY RIDES",
      value: "0",
      attrs: { md: "2", sm: "6" },
      stats: false,
      apiID: "monthCount",
    },
    {
      label: "WEEKLY RIDES",
      value: "0",
      attrs: { md: "2", sm: "6" },
      stats: false,
      apiID: "weekCount",
    },
    {
      label: "DAILY RIDES",
      value: "0",
      attrs: { md: "2", sm: "6" },
      stats: false,
      apiID: "dayCount",
    },
  ],
  TotalStat: [
    {
      label: "YEARLY RIDES",
      value: "0",
      attrs: { md: "2", sm: "6" },
      stats: false,
      apiID: "dynamicYearCount",
    },
  ],
};

export default DriverRideHistory;
