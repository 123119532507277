import React from "react";
import PropTypes from "prop-types";
// import classNames from "classnames";
// import shortid from "shortid";
import { FormInput } from "shards-react";
// import { Link, Route } from "react-router-dom";
// import Rides from "../../views/Rides";

class InputData extends React.Component {
  constructor(props) {
    super(props);
    // console.log("props");
  }
  valueCheck(data) {
    // console.log(data);
    switch (data) {
      case "Consumercancelled":
        return "Consumer cancelled";
      case "Requested":
        return "Unassigned";
      case "Drivercancelled":
        return "Canceled via panel";
      case "Assigned":
        return "Confirmed";
      case "Accepted":
        return "Left for pickup";
      case "Ended":
        return "Completed";
      case "Booknow":
        return "Book now";
      case "rental":
        return "Rental";
      default:
        return data;
    }
  }
  colorCheck(data) {
    // console.log(data);
    switch (data) {
      case "Consumercancelled":
        return "#FF0000";
      case "Requested":
        return "black";
      case "Drivercancelled":
        return "#FF0000";
      case "Assigned":
        return "#59E67F";
      case "Started":
        return "#59E67F";
      case "Accepted":
        return "#FF8307";
      case "Ended":
        return "#59E67F";
      default:
        return data;
    }
  }
  render() {
    const { label, value } = this.props;
    let color = this.colorCheck(value);
    // console.log(color);
    return (
      <div>
        <label>{label}</label>
        <FormInput
          className="py-3"
          style={{ backgroundColor: "white", color: color }}
          readOnly={true}
          type="text"
          value={this.valueCheck(value)}
        />
      </div>
    );
  }
}

InputData.propTypes = {
  /**
   * The Small Stats variation.
   */
  variation: PropTypes.string,
  /**
   * The label.
   */
  label: PropTypes.string,
  /**
   * The value.
   */
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  /**
   * Flag to differentiate stat box
   */
  stats: PropTypes.bool,
};

InputData.defaultProps = {
  value: "-",
  label: "Label",
};

export default InputData;
