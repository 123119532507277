import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Collapse,
  Button,
  Tooltip,
  Card,
  CardImg,
  CardBody,
  Form,
  FormCheckbox,
  FormSelect,
  FormGroup,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CardHeader,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";
import PageTitle from "./../components/common/PageTitle";
import DivRenderNoCog from "./../components/common/DivRenderNoCog";
import * as config from "../constants/constants.json";

class Idproofs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phonenumber: "",
      alt_phonenumber: "",
      driver_profile:
        "https://master.gloryindia.in/static/images/avatars/man.png",
      vehicle_type: "",
      results: [],
      vehicle_front_image: "",
      vehicle_back_image: "",
      vehicle_left_image: "",
      vehicle_right_image: "",
      vehicle_with_driver: "",
      aadhar_card_image_front: "",
      aadhar_card_image_back: "",
      pan_card_image: "",
      driving_license_image: "",
      rc_image: "",
      vehicle_insurance_image: "",
      driver_badgee_image: "",
      emission_cert_image: "",
      road_tax_image: "",
      permit_image: "",
      fitness_image: "",
      sales_agreement: "",
      police_verification: "",
      badge_number: "",
      permit_number: "",
      aadhar_number: "",
      pan_number: "",
      dl_number: "",
      dunique_id: "",
    };
    this.goBack = this.goBack.bind(this);
    this.searchDriver = this.searchDriver.bind(this);
  }
  goBack() {
    this.props.history.goBack();
  }
  searchDriver() {
    // serch opertion here
    fetch(config.url + "onboard/search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // phonenumber: this.state.phonenumber
        phonenumber: this.props.history.location.state.phonenumber,
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          if (resultsss.message) {
            if (resultsss.message === "user does not exist") {
              // this.setState({
              //     notExist: !this.state.notExist
              // })
            } else if (resultsss.message === "consumer") {
              // this.setState({
              //     consumerWarn: !this.state.consumerWarn
              // })
            } else if (resultsss.message === "successfull") {
              console.log(resultsss);
              let resData = resultsss.results;
              // let duty_preference;

              // console.log(resultsss.driverPreference)
              // visible: !this.state.visible,
              this.setState({
                aadhar_card_image_front: resData.imagedata.driver_adharcard[0],
                aadhar_card_image_back: resData.imagedata.driver_adharcard[1],
                pan_card_image: resData.imagedata.pan[0],
                driving_license_image: resData.imagedata.dl_image[0],
                rc_image: resData.imagedata.vehicle_rc[0],
                vehicle_insurance_image: resData.imagedata.insurance_image[0],
                driver_badgee_image: resData.imagedata.display_card[0],
                emission_cert_image: resData.imagedata.emission_image[0],
                road_tax_image: resData.imagedata.roadtax_image[0],
                permit_image: resData.imagedata.permit_image[0],
                fitness_image: resData.imagedata.fc_image[0],
                sales_agreement: resData.imagedata.sales_agreement[0],
                police_verification: resData.imagedata.police_verification[0],
                driver_profile: resData.imagedata.driver_image[0],
                dunique_id: resData.dunique_id,
              });
            } else {
            }
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    } else {
      this.searchDriver();
    }
  }
  render() {
    console.log(this.props);
    return (
      <Container fluid className="main-content-container px-md-4">
        <Row noGutters className="page-header py-4 px-md-3 mb-md-2">
          <Col className="col-12">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="/driveraccounts">Driver accounts</a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href="/driveraccounts/driverdashboard">Driver dashboard</a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Driver details
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active />
            </Breadcrumb>
          </Col>
          <Col className="col-12">
            <PageTitle
              title="Driver ID proofs"
              className="text-sm-left mb-3 p-0 custom-margin"
            />
          </Col>
          {/* <PageTitle
            title="Driver ID proof"
            subtitle="Driver Dashboard"
            className="text-sm-left mb-3"
          /> */}
        </Row>
        <Row className="mb-5">
          <Col className="col-12 col-md-12 col-lg-12">
            <div className="float-right mt-2">
              <Button
                className=""
                id="goback-button"
                theme="dark"
                onClick={this.goBack}
              >
                <i className="fas fa-arrow-left mx-1" />
                <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                  BACK
                </span>
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="px-md-3 mb-5">
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row>
                  {this.props.idProofs.map((stats, idx) => (
                    <Col
                      className="col-12 col-md-3 col-lg-3"
                      key={idx}
                      {...stats.attrs}
                    >
                      <DivRenderNoCog
                        id={`idproofs-images-${idx}`}
                        variation="1"
                        label={stats.label}
                        name={stats.name}
                        value={this.state[stats.name]}
                        onChangeHandler={this.imagehandler}
                        onClickPush={this.callImagehandler}
                      />
                    </Col>
                  ))}
                </Row>
                <hr />
                <Row>
                  {this.props.liscenseProps.map((stats, idx) => (
                    <Col
                      className="col-12 col-md-3 col-lg-3 "
                      key={idx}
                      {...stats.attrs}
                    >
                      <DivRenderNoCog
                        id={`license-images-${idx}`}
                        variation="1"
                        label={stats.label}
                        name={stats.name}
                        value={this.state[stats.name]}
                      />
                    </Col>
                  ))}
                </Row>
                <hr />
                <Row>
                  {this.props.roadProps.map((stats, idx) => (
                    <Col
                      className="col-12 col-md-3 col-lg-3"
                      key={idx}
                      {...stats.attrs}
                    >
                      <DivRenderNoCog
                        id={`road-images-${idx}`}
                        variation="1"
                        label={stats.label}
                        name={stats.name}
                        value={this.state[stats.name]}
                      />
                    </Col>
                  ))}
                </Row>
                <hr />
                <Row>
                  {this.props.agreePC.map((stats, idx) => (
                    <Col
                      className="col-12 col-md-3 col-lg-3 "
                      key={idx}
                      {...stats.attrs}
                    >
                      <DivRenderNoCog
                        id={`agreement-images-${idx}`}
                        variation="1"
                        label={stats.label}
                        name={stats.name}
                        value={this.state[stats.name]}
                      />
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

Idproofs.propTypes = {
  inputValues: PropTypes.array,
  vehicleimagesProp: PropTypes.array,
  idProofs: PropTypes.array,
  agreePC: PropTypes.array,
  liscenseProps: PropTypes.array,
  roadProps: PropTypes.array,
};

Idproofs.defaultProps = {
  vehicleimagesProp: [
    {
      label: "Vehicle front",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_front_image",
    },
    {
      label: "Vehicle Back",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_back_image",
    },
    {
      label: "Vehicel Left",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_left_image",
    },
    {
      label: "Vehicle Right",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_right_image",
    },
    {
      label: "With driver",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_with_driver",
    },
  ],
  idProofs: [
    {
      label: "Aadhar card",
      value: "",
      attrs: {},
      stats: false,
      name: "aadhar_card_image_front",
    },
    {
      label: "PAN card",
      value: "",
      attrs: {},
      stats: false,
      name: "pan_card_image",
    },
  ],
  agreePC: [
    {
      label: "Sales agreement",
      value: "",
      attrs: {},
      stats: false,
      name: "sales_agreement",
    },
    {
      label: "Police verification",
      value: "",
      attrs: {},
      stats: false,
      name: "police_verification",
    },
  ],
  liscenseProps: [
    {
      label: "Driving license",
      value: "",
      attrs: {},
      stats: false,
      name: "driving_license_image",
    },
    {
      label: "Reg. Certificate",
      value: "",
      attrs: {},
      stats: false,
      name: "rc_image",
    },
    {
      label: "Vehicle insurance",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_insurance_image",
    },
    {
      label: "Driver badge(Opt)",
      value: "",
      attrs: {},
      stats: false,
      name: "driver_badgee_image",
    },
  ],
  roadProps: [
    {
      label: "Emission certificate",
      value: "",
      attrs: {},
      stats: false,
      name: "emission_cert_image",
    },
    {
      label: "Road tax",
      value: "",
      attrs: {},
      stats: false,
      name: "road_tax_image",
    },
    {
      label: "Permit",
      value: "",
      attrs: {},
      stats: false,
      name: "permit_image",
    },
    {
      label: "Fitness certificate",
      value: "",
      attrs: {},
      stats: false,
      name: "fitness_image",
    },
  ],
};

export default Idproofs;
