import React from "react";
import { NavItem } from "shards-react";
class NavBookride extends React.Component {
  render() {
    const endpoint = `https://nearbydriver.gloryindia.in/bookings/bookaridenew?id=${sessionStorage.getItem(
      "user_id"
    )}&username=${sessionStorage.getItem("username")}`;
    return (
      <NavItem className="d-flex justify-content-center">
        <div className="text-nowrap ml-2 mr-5 py-3 my-auto">
          <span className="d-none d-inline-block">
            <a href={endpoint} style={{ color: "black" }} target="_blank">
              BOOK A RIDE
            </a>{" "}
          </span>
        </div>
      </NavItem>
    );
  }
}

export default NavBookride;
