import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
// import { Link } from "react-router-dom";

const LoginFooter = ({ contained, menuItems, copyright }) => (
  // <footer className="main-footer">
  //   <Container fluid={contained}>
  //     <Row>
  //       <Col className="col-12 col-md-6 col-lg-4 col-xl-4 ">
  //         <span className="copyright">
  //           <small className="login-text d-flex justify-content-end">
  //             {copyright}
  //           </small>
  //         </span>
  //       </Col>
  //     </Row>
  //   </Container>
  // </footer>
  <footer className="main-footer d-flex p-2 px-3 mt-0">
    <Container fluid={contained}>
      <Row>
        <Col className="text-center mx-auto my-auto col-12 col-md-6 col-lg-4 col-xl-4">
          <span className="copyright my-auto mr-auto">
            <small className="login-text">{copyright}</small>
          </span>
        </Col>
      </Row>
    </Container>
  </footer>
);

LoginFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string,
};

LoginFooter.defaultProps = {
  contained: false,
  copyright: "© Copyright 2020 Glory ECommerce PVT LTD",
};

export default LoginFooter;
