import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, CardImg, FormInput } from "shards-react";
import DragAndDropEdit from "./DragAndDropEdit";

class DivRenderNoCog extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    // this.state = {
    //     open: true,
    //     setInput: false
    // }
    // this.clickhandler = this.clickhandler.bind(this)
    // this.handleImage = this.handleImage.bind(this)
    // this.pushAPI = this.pushAPI.bind(this)
  }

  // pushAPI() {
  //     // this.props.onClickPush(this.props.name)
  //     this.setState({
  //         open: !this.state.open,
  //     }, () => { this.props.onClickPush(this.props.name) })
  // }
  // clickhandler() {
  //     // console.log("here")
  //     this.setState({
  //         open: !this.state.open,
  //     })

  // }
  // handleImage(data, name) {
  //     this.props.onChangeHandler(data, name)
  // }

  render() {
    const { variation, label, value, name, id } = this.props;
    var base64regex = /^(?:[data]{4}:(text|image|application)\/[a-z]*)/;
    var result = base64regex.test(value);
    // console.log(result);
    let dataImage;
    console.log(result);
    if (result === true) {
      dataImage = value;
      // console.log(dataImage);
    } else {
      dataImage = value + "?random_number=" + new Date().getTime();
      // console.log(dataImage);
    }

    // console.log(dataImage)
    // console.log(this.state.open)
    // if (name === "aadhar_card_image_front" || name === "pan_card_image" || name === "driving_license_image" || name === "permit_image") {
    //     this.setState({
    //         setInput: true
    //     })
    // }

    return (
      <div className="w-100 my-4">
        <Row>
          <Col className="col-12 col-md-12">
            <label>{label}</label>
            <Card>
              <CardBody className="p-0">
                <div
                  className="placeholder-cog"
                  style={{
                    width: "100%",
                    height: "170px",
                    backgroundImage: `url(${dataImage})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                ></div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

// url('https://panel.hornok.in/static/images/document.png')

export default DivRenderNoCog;
