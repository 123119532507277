import React, { Component } from "react";
import {
  Map,
  Marker,
  GoogleApiWrapper,
  Polyline,
  Polygon,
} from "google-maps-react";
import PropTypes from "prop-types";
import * as stringsData from "../constants/Strings.json";

function getCenter(region) {
  // console.log("----------->" + region);
  switch (region) {
    case "Bangalore":
      return {
        lat: 12.9716,
        lng: 77.5946,
      };
    case "Hyderabad":
      return {
        lat: 17.4086182,
        lng: 78.3689922,
      };
    default:
      break;
  }
}

export class PolyLine extends Component {
  constructor(props) {
    super(props);
    let centerLog = getCenter(this.props.region);
    // this.onMarkerClick = this.onMarkerClick.bind(this);
    this.state = {
      locationData: [],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      center: centerLog,
      zoom: 10,
    };
    // console.log(this.props);
  }
  onMarkerClick(props, marker, e) {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  }

  render() {
    console.log(this.props.dataProps.trip_status);
    if (!this.props.google) {
      return <div>Loading...</div>;
    }
    let points = [
      {
        lat: parseFloat(this.props.dataProps.slatlon[1]),
        lng: parseFloat(this.props.dataProps.slatlon[0]),
      },
      {
        lat: parseFloat(this.props.dataProps.dlatlon[1]),
        lng: parseFloat(this.props.dataProps.dlatlon[0]),
      },
    ];
    let bounds = new this.props.google.maps.LatLngBounds();
    for (var i = 0; i < points.length; i++) {
      bounds.extend(points[i]);
    }
    let latLngs = [];
    let nLatLngs = [];

    if (
      this.props.dataProps.polyline &&
      this.props.dataProps.polyline.length > 0
    ) {
      for (var a = 0; a <= this.props.dataProps.polyline.length - 1; a++) {
        var _data = {
          lat: this.props.dataProps.polyline[a][0] * 1,
          lng: this.props.dataProps.polyline[a][1] * 1,
        };
        // console.log(_data);
        latLngs.push(_data);
      }
    }
    if (
      this.props.dataProps.notify_polyline &&
      this.props.dataProps.notify_polyline.length > 0 &&
      this.props.dataProps.trip_status === "Ended"
    ) {
      for (
        var b = 0;
        b <= this.props.dataProps.notify_polyline.length - 1;
        b++
      ) {
        var _dataA = {
          lat: this.props.dataProps.notify_polyline[b][0] * 1,
          lng: this.props.dataProps.notify_polyline[b][1] * 1,
        };
        // console.log(_dataA);
        nLatLngs.push(_dataA);
      }
    }

    // console.log(nLatLngs);

    return (
      <div
        className="container-fluid px-0"
        style={{ height: "400px", width: "100%", position: "relative" }}
      >
        <Map
          google={this.props.google}
          zoom={this.state.zoom}
          initialCenter={this.state.center}
          bounds={bounds}
          styles={[
            {
              featureType: "all",
              elementType: "all",
              stylers: [
                {
                  hue: "#008eff",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "all",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "all",
              stylers: [
                {
                  saturation: "0",
                },
                {
                  lightness: "0",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "all",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "all",
              stylers: [
                {
                  visibility: "simplified",
                },
                {
                  saturation: "-60",
                },
                {
                  lightness: "-20",
                },
              ],
            },
          ]}
        >
          {this.props.dataProps.trip_status === "Ended" && (
            <Marker
              title={""}
              name={"Pickup"}
              position={{
                // lat: this.props.dataProps.slatlon[1],
                // lng: this.props.dataProps.slatlon[0],
                lat: nLatLngs.length > 0 ? nLatLngs[0].lat : "",
                lng: nLatLngs.length > 0 ? nLatLngs[0].lng : "",
              }}
              icon={{
                url: "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/leavepickup_new.svg",

                scaledSize: new this.props.google.maps.Size(15, 15),
              }}
            />
          )}
          {this.props.dataProps.trip_status === "Ended" && (
            <Polyline
              path={nLatLngs}
              geodesic={true}
              strokeColor="#3680F4"
              strokeOpacity={0.8}
              strokeWeight={3.5}
            />
          )}
          {this.props.vendor !== "Quickride" &&
            (this.props.dataProps.ride_type !== "outstation" ||
              this.props.dataProps.ride_type !== "rental") && (
              <Polygon
                paths={this.props.dataProps.airport_data.arrival_polygon}
                strokeColor="red"
                strokeOpacity={0.8}
                strokeWeight={2}
                fillColor="red"
                fillOpacity={0.35}
              />
            )}
          {this.props.vendor !== "Quickride" &&
            (this.props.dataProps.ride_type !== "outstation" ||
              this.props.dataProps.ride_type !== "rental") && (
              <Polygon
                paths={this.props.dataProps.airport_data.departure_polygon}
                strokeColor="#151220"
                strokeOpacity={0.8}
                strokeWeight={2}
                fillColor="#151220"
                fillOpacity={0.35}
              />
            )}
          {
            <React.Fragment>
              {this.props.vendor !== "Quickride" &&
                this.props.dataProps.ride_type !== "outstation" && (
                  <React.Fragment>
                    {this.props.dataProps.airport_data.tolls &&
                      this.props.dataProps.airport_data.tolls.map(
                        (data, index) => (
                          <Polygon
                            key={index}
                            paths={data.base_polygon}
                            strokeColor="#5ec0cb"
                            strokeOpacity={0.8}
                            strokeWeight={2}
                            fillColor="#5ec0cb"
                            fillOpacity={0.35}
                          />
                        )
                      )}
                    {this.props.dataProps.airport_data.tolls &&
                      this.props.dataProps.airport_data.tolls.map(
                        (data, index) => (
                          <Polygon
                            key={index}
                            paths={data.polygon}
                            strokeColor="#ffee1f"
                            strokeOpacity={0.8}
                            strokeWeight={2}
                            fillColor="#ffee1f"
                            fillOpacity={0.35}
                          />
                        )
                      )}
                  </React.Fragment>
                )}
            </React.Fragment>
          }

          <Marker
            title={""}
            name={"Source"}
            position={{
              lat: parseFloat(this.props.dataProps.slatlon[1]),
              lng: parseFloat(this.props.dataProps.slatlon[0]),
            }}
            icon={{
              url: "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/source_new.svg",

              scaledSize: new this.props.google.maps.Size(15, 15),
            }}
          />
          <Polyline
            path={latLngs}
            geodesic={true}
            strokeColor="#151220"
            strokeOpacity={0.8}
            strokeWeight={3.5}
          />
          <Marker
            name={"Destination"}
            position={{
              lat: parseFloat(this.props.dataProps.dlatlon[1]),
              lng: parseFloat(this.props.dataProps.dlatlon[0]),
            }}
            icon={{
              url: "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/destination_new.svg",

              scaledSize: new this.props.google.maps.Size(15, 15),
            }}
          />
        </Map>
      </div>
    );
  }
}
PolyLine.default = {
  center: {
    lat: 12.9716,
    lng: 77.5946,
  },
  zoom: 9,
};
PolyLine.defaultProps = {
  center: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  zoom: PropTypes.number,
};
export default GoogleApiWrapper({
  apiKey: stringsData.apiKey,
})(PolyLine);
