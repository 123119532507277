import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// import shortid from "shortid";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  NavLink,
} from "shards-react";
import { Link, Route } from "react-router-dom";
import Rides from "../../views/Rides";

import styled from "styled-components";

class ShiftView extends React.Component {
  constructor(props) {
    super(props);
    this.handleFooterClick = this.handleFooterClick.bind(this);
  }
  handleFooterClick(data, name, lastName, count) {
    // console.log(data);
    this.props.onHandleCall(data, name, lastName, count);
  }

  render() {
    // console.log(this.props);
    const { id, firstName, lastName, roletype, count, userid } = this.props;
    let roleTitle;
    if (roletype === "traineeexecutive") {
      roleTitle = "T.D.E";
    } else if (roletype === "juniorexecutive") {
      roleTitle = "J.D.E";
    } else if (roletype === "seniorexecutive") {
      roleTitle = "S.D.E";
    } else if (roletype === "teamlead") {
      roleTitle = "T.L";
    } else if (roletype === "assistantmanager") {
      roleTitle = "A.M";
    } else if (roletype === "manager") {
      roleTitle = "MGR";
    }
    return (
      <Card id={id} small style={{ minHeight: "200px" }}>
        <CardHeader
          className="card-header font-Color-header"
          style={{ fontWeight: "700" }}
        >
          {roleTitle}
        </CardHeader>
        <CardBody className="d-flex justify-content-center ">
          <CardTitle className="font-Color-header px-3">
            {firstName + " " + lastName}
          </CardTitle>
        </CardBody>
        <NavLink className="p-0 m-0">
          <CardFooter
            className="d-flex justify-content-center zoom-wrapper"
            onClick={() => {
              this.handleFooterClick(userid, firstName, lastName, count);
            }}
          >
            <div>
              <label className="underline-customs">Trips handled :</label>
              <label className="font-Color-header"> {count}</label>
            </div>
          </CardFooter>
        </NavLink>
      </Card>
    );
  }
}

ShiftView.propTypes = {
  /**
   * The Small Stats variation.
   */
  variation: PropTypes.string,
  /**
   * The label.
   */
  label: PropTypes.string,
  /**
   * The value.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Flag to differentiate stat box
   */
  stats: PropTypes.bool,
  /**
   * Toggle label
   */
  display: PropTypes.bool,
};

ShiftView.defaultProps = {
  value: 0,
  label: "Label",
  stats: "",
};

export default ShiftView;
