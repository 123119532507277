import React from "react";
import { Bar, Pie } from "react-chartjs-2";

class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.chartReference = React.createRef();
  }

  componentDidMount() {
    console.log(this.props);
    console.log(this.chartReference); // returns a Chart.js instance reference
  }

  render() {
    let footerLine1 = [this.props.datas.fromAiport, this.props.datas.toAirport];
    //   let footerLine2 = [this.props.data.fromAirport];
    var options = {
      responsive: true,
      tooltips: {
        enabled: true,
        mode: "single",
        callbacks: {
          footer: function(tooltipItems, data) {
            console.log(tooltipItems);
            console.log(data);
            return "Count: " + footerLine1[tooltipItems[0].index];
          },
        },
      },
    };

    return (
      <Pie
        ref={this.chartReference}
        responsive
        data={this.props.data}
        width="300px"
        height="50px"
        options={options}
        legend={{ display: true, position: "right" }}
      />
    );
  }
}

export default Chart;
