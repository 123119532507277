export default function () {
  /**
   * {
      title: "Payouts",
      to: "/payouts",
      htmlBefore: '<i class="fas fa-university"></i>',
      htmlAfter: "",
      doNotDisplay: [
        {
          usertype: "dispatchexecutive",
          roletype: "traineeexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "teamlead",
        },
        {
          usertype: "supplyexecutive",
          roletype: "supplyexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "traineeexecutive",
        },
      ],
    },
    {
      title: "Contests",
      to: "/contest",
      htmlBefore: '<i class="fa fa-trophy" aria-hidden="true"></i>',
      htmlAfter: "",
      doNotDisplay: [
        {
          usertype: "dispatchexecutive",
          roletype: "traineeexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "supplyexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "traineeexecutive",
        },
      ],
    },
   */
  return [
    {
      title: "Dashboard",
      to: "/home",
      htmlBefore: '<i class="fas fa-columns"></i>',
      htmlAfter: "",
      doNotDisplay: [
        {
          usertype: "dispatchexecutive",
          roletype: "traineeexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "teamlead",
        },
        {
          usertype: "supplyexecutive",
          roletype: "supplyexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "traineeexecutive",
        },
      ],
    },
    {
      title: "Business accounts",
      to: "/businessaccounts",
      htmlBefore: '<i class="fas fa-briefcase"></i>',
      htmlAfter: "",
      doNotDisplay: [
        {
          usertype: "supplyexecutive",
          roletype: "supplyexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "traineeexecutive",
        },
      ],
    },
    {
      title: "Employee management",
      to: "/employeemanagement",
      htmlBefore: '<i class="fas fa-cogs"></i>',
      htmlAfter: "",
      doNotDisplay: [
        {
          usertype: "dispatchexecutive",
          roletype: "traineeexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "teamlead",
        },
        {
          usertype: "supplyexecutive",
          roletype: "supplyexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "traineeexecutive",
        },
      ],
    },
    {
      title: "Driver accounts",
      to: "/driveraccounts",
      htmlBefore: '<i class="fas fa-taxi"></i>',
      htmlAfter: "",
      doNotDisplay: {},
    },

    {
      title: "Reports",
      to: "/reports",
      htmlBefore: '<i class="material-icons">book</i>',
      htmlAfter: "",
      doNotDisplay: [
        {
          usertype: "dispatchexecutive",
          roletype: "traineeexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "supplyexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "traineeexecutive",
        },
      ],
    },

    {
      title: "Configuration",
      to: "/configuration",
      htmlBefore: '<i class="fas fa-cogs"></i>',
      htmlAfter: "",
      doNotDisplay: [
        {
          usertype: "dispatchexecutive",
          roletype: "traineeexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "teamlead",
        },
        {
          usertype: "supplyexecutive",
          roletype: "supplyexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "traineeexecutive",
        },
        {
          usertype: "dispatchmanager",
          roletype: "assistantmanager",
        },
      ],
    },
    {
      title: "Payouts",
      to: "/payouts",
      htmlBefore: '<i class="fas fa-university"></i>',
      htmlAfter: "",
      doNotDisplay: [
        {
          usertype: "dispatchexecutive",
          roletype: "traineeexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "teamlead",
        },
        {
          usertype: "supplyexecutive",
          roletype: "supplyexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "juniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "seniorexecutive",
        },
        {
          usertype: "supplyexecutive",
          roletype: "traineeexecutive",
        },
        {
          usertype: "dispatchmanager",
          roletype: "assistantmanager",
        },
        {
          usertype: "dispatchexecutive",
          roletype: "assistantmanager",
        },
      ],
    },
  ];
}
