import React from "react";
import classNames from "classnames";
import {
  InputGroup,
  DatePicker,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
} from "shards-react";

import "../../assets/range-date-picker.css";

const oneDay = 24 * 60 * 60 * 1000;
console.log(DatePicker);
class DatePickerWithDay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
      endDate: undefined,
      daySelected: "Today",
    };

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  handleStartDateChange(value) {
    this.setState(
      {
        ...this.state,
        ...{ startDate: new Date(value) },
      },
      () => {
        this.ridesCountDate();
        this.props.changeDate(new Date(value), this.props.type);
      }
    );
  }

  handleEndDateChange(value) {
    this.setState({
      ...this.state,
      ...{ endDate: new Date(value) },
    });
  }

  ridesCountSNextDate = () => {
    // console.log(date);
    let nextDate = new Date(this.state.startDate);
    nextDate.setDate(nextDate.getDate() + 1);
    nextDate.setHours(0, 0, 0, 0);
    // console.log(nextDate);
    if (nextDate) {
      // setDate(nextDate);
      this.setState(
        {
          startDate: nextDate,
        },
        () => this.props.changeDate(nextDate, this.props.type)
      );
    }
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    // console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));
    // console.log(diffDays);
    if (nextDate.getTime() >= currentDate.getTime()) {
      if (nextDate.getTime() === currentDate.getTime()) {
        this.setState({
          daySelected: "Today",
        });
      } else {
        if (diffDays === 1) {
          this.setState({
            daySelected: "Tomorrow",
          });
          // $("#ridesCountSDateName").text("Tomorrow");
          // getAllRidesCount();
        } else {
          diffDays = diffDays - 1;
          // console.log(diffDays);
          if (diffDays === 1) {
            this.setState({
              daySelected: "Day after tomorrow",
            });
            // $("#ridesCountSDateName").text("Day after tomorrow");
          } else {
            this.setState({
              daySelected: `${diffDays} days after tomorrow`,
            });
            // $("#ridesCountSDateName").text(diffDays + " days after tomorrow");
          }
          // getAllRidesCount();
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));

      if (diffDays === 1) {
        this.setState({
          daySelected: "Yesterday",
        });
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays === 1) {
          this.setState({
            daySelected: "Day before yesterday",
          });
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          this.setState({
            daySelected: `${diffDays} days from yesterday`,
          });
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  };
  ridesCountSPrevDate = () => {
    // console.log(date);
    let nextDate = new Date(this.state.startDate);
    nextDate.setDate(nextDate.getDate() - 1);
    nextDate.setHours(0, 0, 0, 0);
    // console.log(nextDate);
    if (nextDate) {
      this.setState(
        {
          startDate: nextDate,
        },
        () => this.props.changeDate(nextDate, this.props.type)
      );
    }
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    // console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));
    // console.log(diffDays);
    if (nextDate.getTime() >= currentDate.getTime()) {
      if (nextDate.getTime() == currentDate.getTime()) {
        this.setState({
          daySelected: "Today",
        });
        // getAllRidesCount();
      } else {
        if (diffDays === 1) {
          this.setState({
            daySelected: "Tomorrow",
          });
          // $("#ridesCountSDateName").text("Tomorrow");
          // getAllRidesCount();
        } else {
          diffDays = diffDays - 1;
          // console.log(diffDays);
          if (diffDays === 1) {
            this.setState({
              daySelected: "Day after tomorrow",
            });
            // $("#ridesCountSDateName").text("Day after tomorrow");
          } else {
            this.setState({
              daySelected: `${diffDays} days after tomorrow`,
            });
            // $("#ridesCountSDateName").text(diffDays + " days after tomorrow");
          }
          // getAllRidesCount();
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));

      if (diffDays === 1) {
        this.setState({
          daySelected: "Yesterday",
        });
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays === 1) {
          this.setState({
            daySelected: "Day before yesterday",
          });
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          this.setState({
            daySelected: `${diffDays} days from yesterday`,
          });
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  };

  ridesCountDate = () => {
    // console.log(date);
    let newDate = new Date(this.state.startDate);

    newDate.setHours(0, 0, 0, 0);
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    console.log(newDate);
    console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - newDate) / oneDay));
    newDate.getTime() > currentDate.getTime()
      ? newDate.setDate(newDate.getDate() + 1)
      : newDate.setDate(newDate.getDate() - 1);
    if (newDate.getTime() >= currentDate.getTime()) {
      if (newDate.getTime() == currentDate.getTime()) {
        this.setState({
          daySelected: "Today",
        });
        // setDayselected("Today");
      } else {
        if (diffDays === 1) {
          this.setState({
            daySelected: "Tomorrow",
          });
          // setDayselected("Tomorrow");
        } else {
          diffDays = diffDays - 1;
          // console.log(diffDays);
          if (diffDays == 1) {
            this.setState({
              daySelected: "Day after tomorrow",
            });
            // setDayselected("Day after tomorrow");
          } else {
            this.setState({
              daySelected: `${diffDays} days after tomorrow`,
            });
            // setDayselected(`${diffDays} days after tomorrow`);
          }
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - newDate) / oneDay));
      console.log(diffDays);
      if (diffDays == 1) {
        // setDayselected("Today");
        this.setState({
          daySelected: "Today",
        });
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          // setDayselected("Day before yesterday");
          this.setState({
            daySelected: "Yesterday",
          });
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          // setDayselected(`${diffDays} days from yesterday`);
          this.setState({
            daySelected: `${diffDays} days from yesterday`,
          });
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  };

  componentDidMount() {
    this.props.changeDate(new Date(), this.props.type);
  }

  render() {
    const { className } = this.props;
    const classes = classNames(className, "d-flex", "my-auto", "date-range");

    return (
      <FormGroup>
        <div
          className="px-0 mx-0 mb-3 daypicks"
          style={{
            maxWidth: "195px",
            textAlign: "center",
            verticalAlign: "center",
          }}
        >
          <i
            id="ridesCountSPrevDate"
            className="fas fa-arrow-left float-left date-pointer mt-1"
            onClick={this.ridesCountSPrevDate}
          />
          <span
            id="ridesCountSDateName"
            style={{
              fontSize: "0.98em",
            }}
          >
            {this.state.daySelected}
          </span>
          <i
            id="ridesCountSNextDate"
            className="fas fa-arrow-right float-right date-pointer mt-1"
            onClick={this.ridesCountSNextDate}
          />
        </div>
        <InputGroup className={classes}>
          <DatePicker
            size="sm"
            selected={this.state.startDate}
            onChange={this.handleStartDateChange}
            placeholderText="Select date"
            dropdownMode="select"
            className="text-center datepickers"
            dateFormat="dd/MM/yyyy"
          />
          <InputGroupAddon type="append">
            <InputGroupText>
              <i className="material-icons">&#xE916;</i>
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
    );
  }
}

export default DatePickerWithDay;
