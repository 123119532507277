import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  FormGroup,
  FormSelect,
  Button,
  Collapse,
  Tooltip,
  DatePicker,
} from "shards-react";
import axios from "axios";
import _ from "lodash";
import { Button as MatButton, TextField } from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Modal, Form } from "react-bootstrap";
import { makeStyles, withStyles } from "@material-ui/core/styles";
// import { Link } from "react-router-dom";
// import PageTitle from "../../../components/common/PageTitle";
import InnerBreadTitle from "../../../components_core/common/InnerBreadTitle";
import SoloDatePicker from "../../../components_core/common/DatePicker";
import DatePickerWithDay from "../../../components_core/common/DatePickerWithDay";
// import DisplayCards from "../../../components/businessaccounts/DisplayCards";
import CountCards from "../../../components_core/businessaccounts/CountCards";
import * as config from "../../../constants/constants.json";
import * as stringData from "../../../constants/Strings.json";

/**Common database components */
import DataTableBase from "../../../components_core/common/DataTableCommon";

/**Details page to switch on the click of the view button */
import BookingDetails from "./BookingDetails";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**Material UI  */
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  buttonRoot: {
    backgroundColor: "black",
    borderColor: "black",
    color: "white",
    "&:hover": {
      backgroundColor: "#151220",
      borderColor: "#151220",
      color: "white",
      // boxShadow: "0.1em 0.1em 1em 0em #00000029",
    },
  },
}));

const getRideStatus = (request_status) => {
  console.log(request_status);
  if (request_status === "consumercancelled") {
    return <div style={{ color: "#FF0000" }}>Consumer cancelled</div>;
  } else if (request_status === "drivercancelled") {
    return <div style={{ color: "#FF0000" }}>Cancelled via panel</div>;
  } else if (request_status === "ended" || request_status === "completed") {
    return <div style={{ color: "#59E67F" }}>Completed</div>;
  } else if (request_status === "Requested") {
    return <div>Unassigned</div>;
  } else if (request_status === "Confirmed") {
    return <div style={{ color: "#59E67F" }}>Confirmed</div>;
  } else if (request_status === "accepted") {
    return <div style={{ color: "#FF8307" }}>Left for pickup</div>;
  } else if (request_status === "started") {
    return <div style={{ color: "#59E67F" }}>Started</div>;
  } else if (request_status === "notified") {
    return <div style={{ color: "#FF8307" }}>Notified</div>;
  } else {
    return <div>{request_status}</div>;
  }
};

function capitalizeFirstLetter(string) {
  if (string === "scheduled") {
    return "Airport";
  } else {
    return string[0].toUpperCase() + string.slice(1);
  }
}

/**Button component */
const NotificationButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 14,
    color: "white",
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#151220",
    borderColor: "#151220",
    fontFamily: ["Public Sans", "sans-serif"].join(","),
    "&:hover": {
      backgroundColor: "#151220",
      borderColor: "#151220",
      boxShadow: "0.1em 0.1em 1em 0em #00000029",
    },
    "&:disabled": {
      backgroundColor: "grey",
      borderColor: "grey",
      color: "white",
    },
  },
})(MatButton);

/**Text field component */

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(TextField);

export default function BookingsView(props) {
  /**State initialization */
  const [region, setRegion] = useState("Bangalore");
  const [vendor, setVendor] = useState("all");
  const [pending, setPending] = useState(true);
  const [collapse, setCollapse] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [date, setDate] = useState(new Date());
  const [hits, setHits] = useState({});
  const [openTip, setOpenTip] = useState(false);
  const [assignedCount, setAssignedCount] = useState(0);
  const [request_type, setRequestType] = useState("requested");
  const [tripDate, setTripDate] = useState(new Date());
  const [tripData, setTripData] = useState([]);
  const [tripDetailsData, setTripDetailsData] = useState([]);
  const [changeDetailsPage, setChangeDetails] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [duty_type, setDutyType] = useState("nearby");
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [assignPending, setAssignPending] = useState(true);
  const [driverData, setDriverData] = useState([]);
  const [modalSelectDriver, setModalSelectDriver] = useState(undefined);
  const [showTripDetails, setToggleTripDetail] = useState(false);
  const [detailCollapse, setDetailCollapse] = useState(false);
  const [offeredAmount, setOfferedAmount] = useState(undefined);
  const [disableButton, setDisableButton] = useState(false);
  const [sentSuccess, setSentSuccess] = useState(false);
  const [countDate, setCountDate] = useState(new Date());
  const [showOfferedAmount, setShowOfferedAmount] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [clearSelected, setClearSelected] = useState(false);
  const [airportFlow, setAirportFlow] = useState({
    city_to_airport: 0,
    airport_to_city: 0,
  });
  const [tripDataPay, setTripDataPay] = useState([]);
  const [openPayMod, setOpenPayMod] = useState(false);
  const [paytime, setPayoutDate] = useState(new Date());
  const [modeofpayment, setPaymentMode] = useState("");
  const [amountSent, setAmountSent] = useState("");
  const [paidtodriver, setPaidtoDriver] = useState("");
  const [paidbyagent, setPaidbyAgent] = useState("");

  const handlePaidAmount = (event) => {
    setAmountSent(event.target.value);
  };
  const handlePaidtoAmount = (event) => {
    setPaidtoDriver(event.target.value);
  };

  const handlePaidBy = (event) => {
    console.log(event.target.value);
    setPaidbyAgent(event.target.value);
  };
  const getMyAssignedCount = () => {
    console.log("----------------------------------------");
    let time = countDate;
    let milliseconds = time.getTime();
    // console.log(window.sessionStorage.getItem("user_id"));
    // console.log(new Date(this.state.date));

    fetch(config.url + "htmlcrm/getMyAssignedCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        milliseconds: milliseconds,
        executiveID: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          setAssignedCount(result.assignedCount);
          // this.setState({ assignedCount: result.assignedCount });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorOpen(false);
  };

  /**Function to open trip details page */

  const openTripDetails = (data) => {
    setTripDetailsData(data);
    setChangeDetails(true);
    // this.setState({
    //   changeDiv: true,
    //   tripId: data.trip_id,
    //   rideType: data.ridetype,
    //   request_status: data.request_status,
    // });
  };

  /**Function to copy ride data from data table */

  const copyDetails = (data) => {
    let dataFormat = `Hi, There is a trip available. Details are as below \n Pick-up time = ${data.pickuptime} \n Pick-up = ${data.sname} \n Drop-off = ${data.dname} \n Vehicle= ${data.vehicle_type} \n Amount =  \n To get the booking please contact - 8884488694`;
    let el = window.document.createElement("textarea");
    el.value = dataFormat;
    el.setAttribute("readonly", "hidden");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setMessage("Trip details copied to clipboard");
    setOpen(true);
  };

  /**Function to close trip details page */

  const closeTripDetails = (data) => {
    // setTripDetailsData(data);
    handleRefreshClick();
    setChangeDetails(false);
    // setTripDate(tripDate);
    // this.setState({
    //   changeDiv: true,
    //   tripId: data.trip_id,
    //   rideType: data.ridetype,
    //   request_status: data.request_status,
    // });
  };

  /**Function to open payout tab */
  const viewPayClick = (data) => {
    setTripDataPay(data);
    setOpenPayMod(true);
  };

  /**Tabe initialization */
  let column = [];
  let dutyColumn = [];
  if (request_type === stringData.reqz) {
    column = useMemo(() => [
      {
        name: "Sl no",
        maxWidth: "100px",
        cell: (row, rowIndex) => <span className="mr-2">{rowIndex + 1}</span>,
      },
      {
        name: "Ride ID",
        grow: 2,
        selector: (row) => row.trip_id,
      },
      {
        name: "Source",
        selector: (row) => row.sname,
        grow: 3,
        sortable: true,
        wrap: true,
      },
      {
        name: "Destination",
        selector: (row) => row.dname,
        sortable: true,
        grow: 4,
        wrap: true,
      },
      {
        name: "Pickup time",
        selector: (row) => row.pickuptime,
        sortable: true,
        // maxWidth: "250px",
        grow: 2,
        wrap: true,
      },
      {
        name: "Ride type",
        selector: (row) => row.ridetype,
        maxWidth: "150px",
      },
      {
        name: "Vehicle type",
        selector: (row) => row.vehicle_type,
        grow: 1,
        wrap: true,
      },
      {
        name: "Rider",
        selector: (row) => row.consumername + " - " + row.phonenumber,
        wrap: true,
        grow: 2,
      },
      {
        name: "Actions",
        grow: 2,
        minWidth: "150px",
        cell: (row) => {
          return (
            <div className="d-flex flex-column">
              {row.request_status === "Requested" && (
                <Button
                  className="px-2 py-2 my-2 button-width"
                  theme="dark"
                  onClick={() => {
                    // this.viewClick(row.original);
                    openAssignModal(row);
                  }}
                >
                  ASSIGN
                </Button>
              )}

              <Button
                className="px-2 py-2 my-2 button-width"
                theme="dark"
                onClick={() => {
                  openTripDetails(row);
                }}
              >
                VIEW
              </Button>
              <Button
                className="px-2 py-2 my-2 button-width"
                theme="dark"
                onClick={() => copyDetails(row)}
              >
                COPY INFO
              </Button>
              <Button
                className="px-2 py-2 my-2 button-width"
                theme="dark"
                onClick={() => {
                  viewPayClick(row);
                }}
              >
                UPDATE PAYOUT
              </Button>
            </div>
          );
        },
      },
    ]);
  } else {
    column = useMemo(() => [
      {
        name: "Sl no",
        maxWidth: "100px",
        cell: (row, rowIndex) => <span className="mr-2">{rowIndex + 1}</span>,
      },
      {
        name: "Ride ID",
        grow: 2,
        selector: (row) => row.trip_id,
      },
      {
        name: "Source",
        selector: (row) => row.sname,
        grow: 3,
        sortable: true,
        wrap: true,
      },
      {
        name: "Destination",
        selector: (row) => row.dname,
        sortable: true,
        grow: 4,
        wrap: true,
      },
      {
        name: "Pickup time",
        selector: (row) => {
          if (
            row.request_status === "ended" ||
            row.request_status === "Requested" ||
            row.request_status === "Confirmed" ||
            row.request_status === "notified" ||
            row.request_status === "started" ||
            row.request_status === "accepted"
          ) {
            return row.pickuptime;
          } else {
            return moment(new Date(row.pickuptime)).format(
              "DD/MM/YY [at] hh:mm A"
            );
          }
        },
        sortable: true,
        // maxWidth: "250px",
        grow: 3,
        wrap: true,
      },
      {
        name: "Ride type",
        selector: (row) => capitalizeFirstLetter(row.ridetype),
        maxWidth: "150px",
      },
      {
        name: "Vehicle type",
        selector: (row) => row.vehicle_type,
        grow: 1,
        wrap: true,
      },
      {
        name: "Ride status",
        selector: (row) => getRideStatus(row.request_status),
        grow: 2,
        wrap: true,
      },

      {
        name: "Driver",
        selector: (row) => row.drivername + " - " + row.driverphonenumber,
        wrap: true,
        grow: 2,
      },
      {
        name: "Rider",
        selector: (row) => row.consumername + " - " + row.phonenumber,
        wrap: true,
        grow: 2,
      },
      {
        name: "Actions",
        minWidth: "150px",
        grow: 2,
        cell: (row) => {
          return (
            <div className="d-flex flex-column">
              <Button
                className="px-2 py-2 my-2 button-width"
                theme="dark"
                onClick={() => {
                  openTripDetails(row);
                }}
              >
                VIEW
              </Button>

              {request_type !== stringData.cancl && (
                <Button
                  className="px-2 py-2 my-2 button-width"
                  theme="dark"
                  onClick={() => {
                    viewPayClick(row);
                  }}
                >
                  UPDATE PAYOUT
                </Button>
              )}
            </div>
          );
        },
      },
    ]);
  }

  /**Table to display drivers */
  if (duty_type === stringData.nrby) {
    dutyColumn = useMemo(() => [
      {
        name: "Sl no",
        maxWidth: "100px",
        cell: (row, rowIndex) => <span className="mr-2">{rowIndex + 1}</span>,
      },
      {
        name: "Driver name",
        selector: (row) => row.driver_name,
      },
      {
        name: "Phone number",
        selector: (row) => row.phonenumber,
      },
      {
        name: "Driver type",
        selector: (row) => row.driver_type,
      },
    ]);
  } else {
    dutyColumn = useMemo(() => [
      {
        name: "Sl no",
        maxWidth: "100px",
        cell: (row, rowIndex) => <span className="mr-2">{rowIndex + 1}</span>,
      },
      {
        name: "Driver name",
        selector: (row) => row.dname,
      },
      {
        name: "Phone number",
        selector: (row) => row.phnno,
      },
      {
        name: "Driver type",
        selector: (row) => row.driver_type,
      },
    ]);
  }

  /**Function to open assign modal */

  const openAssignModal = (data) => {
    // console.log(data);
    getDriversToAssign(data);
    setModalSelectDriver(data);
    setAssignModal(true);
  };

  const closeAssignModal = () => {
    setOfferedAmount(undefined);
    setAssignModal(false);
    setModalSelectDriver([]);
  };

  /**Functions */
  const handleSelectChange = (event) => {
    setRegion(event.target.value);
  };

  const handlePaymentMode = (event) => {
    setPaymentMode(event.target.value);
  };
  // const [region, setRegion] = useState(
  //   sessionStorage.getItem("operating_region")
  // );

  const handletripStatusSelectChange = (event) => {
    setRequestType(event.target.value);
  };

  const toggle = () => {
    setCollapse(!collapse);
    setShowDetails(!showDetails);
  };

  const toggleTip = () => {
    setOpenTip(!openTip);
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("user_id") === null ||
      sessionStorage.getItem("user_id") === undefined ||
      sessionStorage.getItem("user_id") === ""
    ) {
      window.location.assign("/");
    } else {
      countFetch();
      countFlow();
      fetchCall();
      getMyAssignedCount();
      const timer = setInterval(() => {
        countFetch();
        countFlow();
      }, 120000);
      // const times = setInterval(() => handleRefreshClick(), 60000);
      const countTimer = setInterval(() => getMyAssignedCount(), 60000);
      return () => {
        clearInterval(timer);
        // clearInterval(times);
        clearInterval(countTimer);
      };
    }
  }, []);

  useEffect(() => {
    handleRefreshClick();
    countFetch();
    countFlow();
  }, [region]);

  /**Effect to call function on change in request type */
  useEffect(() => {
    switch (request_type) {
      case stringData.reqz:
        fetchCall();
        break;
      case stringData.accp:
        fetchCall();
        break;
      case stringData.ongo:
        fetchOngoingCall();
        break;
      case stringData.comp:
        fetchCompletedRides();
        break;
      case stringData.cancl:
        fetchCanceledRides();
        break;
      default:
        // Do not do anything
        break;
    }
  }, [request_type]);

  /**Effect to get drivers on duty state change */

  useEffect(() => {
    // console.log(modalSelectDriver);
    if (modalSelectDriver) {
      // console.log("here");
      setAssignPending(true);
      getDriversToAssign(modalSelectDriver);
    }
  }, [duty_type]);

  /**Function to fetch unassigned and accepted rides */
  const fetchCall = () => {
    let date = tripDate.setHours(0, 0, 0, 0);
    console.log(date);
    let filter_type = "all";
    let dataToSend = {
      region,
      vendor: vendor,
      milliseconds: date,
      request_type,
      filter_type,
    };
    axios
      .post(config.url + "htmlcrm/taxiScheduleRideList", dataToSend)
      .then((response) => {
        if (response.data.message === "successfull") {
          let scheduledRidee, rentalRide, outstationRide;
          let unsortedData = response.data.taxiScheduleRideList;
          if (unsortedData && unsortedData.length > 0) {
            unsortedData = _.orderBy(unsortedData, ["request_type"], ["desc"]);
          }
          scheduledRidee = unsortedData;
          let data = _.concat(scheduledRidee, rentalRide, outstationRide);
          let final_data = _.without(data, undefined);
          // console.log(final_data);
          setTripData(final_data);
          setPending(false);
        } else {
          setTripData([]);
          setPending(false);
        }
      })
      .catch((error) => console.log("error"));
  };

  /**Function to fetch ongoing rides */
  const fetchOngoingCall = (props) => {
    let filter_type = "all";

    let dataToSend = {
      vendor: vendor,
      region: region,
      filter_type,
    };
    axios
      .post(config.url + "htmlcrm/getOngoingRidesList", dataToSend)
      .then((response) => {
        if (response.data.rideslist === undefined) {
          setTripData([]);
          setPending(false);
        } else {
          setTripData(response.data.rideslist);
          setPending(false);
        }
      })
      .catch();
  };

  /**Function to fetch completed rides */
  const fetchCompletedRides = (props) => {
    let filter_type = "all";
    let date = tripDate.setHours(0, 0, 0, 0);
    let dataToSend = {
      region,
      vendor: vendor,
      milliseconds: date,
      request_type,
      filter_type,
    };
    axios
      .post(config.url + "htmlcrm/getCompletedRidesList", dataToSend)
      .then((response) => {
        if (response.data.rideslist === undefined) {
          setTripData([]);
          setPending(false);
        } else {
          setTripData(response.data.rideslist);
          setPending(false);
        }
      })
      .catch();
  };

  /**Function to fetch canceled rides */
  const fetchCanceledRides = (props) => {
    let filter_type = "all";
    let date = tripDate.setHours(0, 0, 0, 0);
    let dataToSend = {
      region,
      vendor: vendor,
      milliseconds: date,
      request_type,
      filter_type,
    };
    axios
      .post(config.url + "htmlcrm/getMiscRidesList", dataToSend)
      .then((response) => {
        console.log(response.data);
        if (response.data.rideslist === undefined) {
          setTripData([]);
          setPending(false);
        } else {
          setTripData(response.data.rideslist);
          setPending(false);
        }
      })
      .catch();
  };

  const changeStatus = (e, request_type) => {
    // console.log(request_type);
    setTripData([]);
    setPending(true);
    setRequestType(request_type);
  };

  /**Function to get count of rides */
  const countFetch = () => {
    // console.log("Help");
    let time = countDate;
    console.log(time);
    // console.log(region);
    let milliseconds = time.getTime();
    // console.log(new Date(this.state.date));
    fetch(config.url + "htmlcrm/getAllRidesCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        milliseconds: milliseconds,
        region_name: region,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          let resArray = {
            totalRidesCount: result.totalRidesCount,
            assignedRides: result.scheduledRidesCount.assignedRides,
            unassignedRides: result.scheduledRidesCount.unassignedRides,
            cancelledRides: result.scheduledRidesCount.cancelledRides,
            completedRides: result.scheduledRidesCount.completedRides,
            ongoing: result.ongoing,
            detachedRides: result.scheduledRidesCount.detachedRides,
            consumerCanceledCount: result.consumerCanceledCount,
            driverCanceledCount: result.driverCanceledCount,
          };
          setHits(resArray);
          // if (date > new Date()) {
          //   let test = Math.floor(Math.random() * 2) - 50;
          //   setRideCount(rideCount - test);
          // } else {
          //   let test = Math.floor(Math.random() * 2) - 50;
          //   setRideCount(rideCount + test);
          // }

          //  this.setState({ hits: resArray });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  };

  const classes = useStyles();

  // const getRidesOnRefresh = () =>{

  //     switch (data) {
  //       case "requested":
  //         // console.log(data);
  //         if (this.state.select2.value === "All") {
  //           this.fetchCall();
  //         } else if (this.state.select2.value === "airport") {
  //           this.fetchTaxiScheduledRides();
  //         } else if (this.state.select2.value === "outstation") {
  //           this.fetchOutstationRides();
  //         } else if (this.state.select2.value === "rental") {
  //           this.fetchRentalRides();
  //         } else if (this.state.select2.value === "urban") {
  //           this.fetchTaxiScheduledRides();
  //         } else {
  //           this.fetchCall();
  //         }
  //         // this.fetchCall();
  //         break;
  //       case "assigned":
  //         if (this.state.select2.value === "All") {
  //           this.fetchCall();
  //         } else if (this.state.select2.value === "airport") {
  //           this.fetchTaxiScheduledRides();
  //         } else if (this.state.select2.value === "outstation") {
  //           this.fetchOutstationRides();
  //         } else if (this.state.select2.value === "rental") {
  //           this.fetchRentalRides();
  //         } else if (this.state.select2.value === "urban") {
  //           this.fetchTaxiScheduledRides();
  //         } else {
  //           this.fetchCall();
  //         }
  //         // this.fetchCall();
  //         break;
  //       case "completed":
  //         // console.log(data);
  //         // if (this.state.select2.value === "All") {
  //         //   this.fetchCompletedRides();
  //         // } else if (this.state.select2.value === "airport") {
  //         //   this.fetchCompletedRides();
  //         // } else if (this.state.select2.value === "outstation") {
  //         //   this.fetchCompletedRides();
  //         // } else if (this.state.select2.value === "rental") {
  //         //   this.fetchCompletedRides();
  //         // } else if (this.state.select2.value === "urban") {
  //         //   this.fetchCompletedRides();
  //         // } else {
  //         //   this.fetchCall();
  //         // }
  //         // this.fetchCall();
  //         this.fetchCompletedRides();
  //         break;
  //       case "ongoing":

  //         this.fetchOngoingCall();
  //         break;
  //       default:
  //         // console.log(data);
  //         this.fetchMiscRides();
  //         break;

  //     // this.fetchCall()
  //   }

  // }

  const handleRefreshClick = () => {
    console.log("herererererer");
    switch (request_type) {
      case stringData.reqz:
        setPending(true);
        fetchCall();
        break;
      case stringData.accp:
        setPending(true);
        fetchCall();
        break;
      case stringData.ongo:
        setPending(true);
        fetchOngoingCall();
        break;
      case stringData.comp:
        setPending(true);
        fetchCompletedRides();
        break;
      case stringData.cancl:
        setPending(true);
        fetchCanceledRides();
        break;
      default:
        break;
    }
  };

  /**Function to toggle nearby and onduty drivers */
  const changeDutyStatus = (e, status) => {
    // setSelectedDrivers([]);
    // setClearSelected(true);
    setDutyType(status);
  };

  /**Function to select nearby and onduty drivers for mobile devices */
  const handledutyStatusSelectChange = (event) => {
    setDutyType(event.target.value);
  };

  /**Function to refresh assign drivers section */
  const handleDutyRefreshClick = () => {
    console.log("here");
    getDriversToAssign(modalSelectDriver);
  };

  /**Function to handle driver selection */
  const driverRowSelection = ({ selectedRows }) => {
    // console.log(selectedRows);
    let datas = _.find(selectedRows, (o) => {
      return o.driver_type === "Temporary";
    });
    if (datas !== undefined) {
      setShowOfferedAmount(true);
    } else {
      setShowOfferedAmount(false);
    }
    setSelectedDrivers(selectedRows);
  };

  /**Function to get drivers to assign */

  const getDriversToAssign = (data) => {
    if (duty_type === stringData.nrby) {
      getNearbyDrivers(data);
    } else {
      getOnDutyDrivers(data);
    }
  };

  /**Api to get nearby drivers */
  const getNearbyDrivers = (data) => {
    // console.log("called nearby drivers");
    let dataToSend = {
      latitude: data.slatlon[1],
      longitude: data.slatlon[0],
      vehicle_type: data.vehicle_type,
      trip_id: data.trip_id,
      triptype: data.ridetype,
      operating_region: data.region,
    };
    // console.log(dataToSend);
    axios
      .post(config.url + "api/nearByDriverlocation", dataToSend)
      .then((response) => {
        console.log(response.data);
        setAssignPending(false);
        setDriverData(response.data.result);
      })
      .catch();
  };

  /**Api to get on duty drivers */
  const getOnDutyDrivers = (data) => {
    // console.log(data);
    let dataToSend = {
      vehicle_type: data.vehicle_type,
      trip_id: data.trip_id,
      triptype: data.ridetype,
      operating_region: data.region,
    };

    axios
      .post(config.url + "htmlcrm/freeDrivers", dataToSend)
      .then((response) => {
        console.log(response);
        if (response.data.message === stringData.onunsuccess) {
          setAssignPending(false);
          setDriverData([]);
        } else {
          setAssignPending(false);
          setDriverData(response.data.driverlist);
        }
      })
      .catch();
  };

  /**Function to toggle ride details in the assign modal */
  const toggleRideDetails = () => {
    setToggleTripDetail(!showTripDetails);
    setDetailCollapse(!detailCollapse);
    //  collapse: !this.state.collapse,
    //     showTripDetails: !this.state.showTripDetails,
  };

  const handleOfferedAmtChange = (e) => {
    // console.log(e.target.value);
    setOfferedAmount(e.target.value);
  };

  /**API to update offered amount */

  const updateOfferedAmount = () => {
    let dataToSend = {
      trip_id: modalSelectDriver.trip_id,
      userid: window.sessionStorage.getItem("user_id"),
      valueType: "offered",
      value: offeredAmount,
    };

    axios
      .post(config.url + "htmlcrm/updateTripData", dataToSend)
      .then((response) => {})
      .catch();
  };

  /**API to end GCM to drivers */
  const callGCM = (e) => {
    // console.log(e);
    if (e) {
      e.preventDefault();
    }
    if (selectedDrivers.length > 0) {
      console.log("herereee");
      // updateOfferedAmount();
      setDisableButton(true);
      let drivers;
      // console.log(selectedDrivers);
      if (selectedDrivers === undefined || selectedDrivers.length === 0) {
        drivers = [];
      } else {
        drivers = _.map(selectedDrivers, "dunique_id");
      }

      let dataToSend = {
        // executive_id: window.sessionStorage.getItem("user_id"),
        executive_id: "5d07363d8a332a0af0df7b59",
        trip_id: modalSelectDriver.trip_id,
        dunique_ids: drivers.toString(),
      };
      // console.log(dataToSend);
      axios
        .post(config.url + "htmlcrm/sendScheduledRideGCM", dataToSend)
        .then((response) => {
          if (response.data.message === stringData.onsuccess) {
            updateOfferedAmount();
            setSentSuccess(true);
          } else {
            setTimeout(() => {
              setDisableButton(false);
            }, 3000);
          }
        })
        .catch();
    }
  };

  const changeDate = (data, type) => {
    console.log(type);
    console.log("type");
    if (type === "count") {
      setCountDate(data);
    } else if (type === "payout") {
      setPayoutDate(data);
    } else {
      setTripDate(data);
    }
    // console.log(data);
    // console.log(type);
  };

  useEffect(() => {
    handleRefreshClick();
    countFetch();
    // countFlow();
  }, [tripDate]);

  useEffect(() => {
    // handleRefreshClick();
    countFetch();
    countFlow();
    getMyAssignedCount();
  }, [countDate]);

  /**Use effect to rest notification successfull message */
  useEffect(() => {
    // let timer;
    if (sentSuccess === true) {
      setOpen(true);
      setMessage("Notification sent to driver");
      setDisableButton(false);
    }
    // return () => {
    //   clearTimeout(timer);
    // };
  }, [sentSuccess]);

  const goBackToAccounts = () => {
    props.history.push("/businessAccounts");
  };
  // console.log(clearSelected);

  /**Function to get flow count */
  const countFlow = () => {
    let dataToSend = {
      from_date: countDate.setHours(0, 0, 0, 0),
      to_date: countDate.setHours(0, 0, 0, 0),
      region_name: region,
    };
    console.log(dataToSend);
    axios
      .post(config.url + "htmlcrm/getTripFlowCount", dataToSend)
      .then((response) => {
        console.log(response);
        if (response.data.message === "successfull") {
          setAirportFlow({
            city_to_airport: response.data.city_to_airport,
            airport_to_city: response.data.airport_to_city,
          });
        } else {
          setAirportFlow({
            city_to_airport: 0,
            airport_to_city: 0,
          });
        }
      })
      .catch();
  };

  /**Function to change payout date */
  const changePayoutDate = (value) => {
    setPayoutDate(new Date(value));
  };

  /**Function to close payout modal*/
  const resetPayMod = () => {
    setOpenPayMod(false);
  };

  /**Function to call payout API */
  const callUpdatePayment = (e) => {
    if (e) {
      e.preventDefault();
    }
    let dataToSend = {
      trip_id: tripDataPay.trip_id,
      payment_mode: modeofpayment,
      paid_amount: amountSent,
      paid_date: paytime.getTime(),
      dunique_id: null,
      beneficiary_id: paidtodriver,
      transfer_initiatedBy: paidbyagent,
      offered_amount: "",
    };
    axios
      .post(config.url + "payout/updatemanualpayment", dataToSend)
      .then((response) => {
        if (response.message === "successfull") {
          setTimeout(() => {
            setOpenPayMod(false);
            setMessage("Data updated successfully");
            setPaymentMode("");
            setPayoutDate(new Date());
            setPaidtoDriver("");
            setPaidbyAgent("");
            setAmountSent("");
            setOpen(true);
          }, 500);
        } else {
          if (response.error && response.error === "ride not assigned") {
            setMessage("Ride not assigned");
            setErrorOpen(true);
            // this.setState(
            //   {
            //     success: false,
            //     message: "Ride not assigned to ",
            //   },
            //   () => this.show()
            // );
          } else if (response.error && response.error === "no ride exists") {
            setMessage("Unable to find the ride");
            setErrorOpen(true);
            // this.setState(
            //   {
            //     success: false,
            //     message: "Unable to find the ride",
            //   },
            //   () => this.show()
            // );
          } else {
            setMessage("Data failed to update");
            setErrorOpen(true);
            // this.setState(
            //   {
            //     success: false,
            //     message: "Data failed to update",
            //   },
            //   () => this.show()
            // );
          }
        }
      });
  };

  return (
    <Container fluid className="main-content-container px-4">
      {/* Modal to open ride assignment */}
      {/* <Form onSubmit={(e) => callGCM(e)}> */}
      <Modal
        show={assignModal}
        onHide={closeAssignModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Assign ride to driver</Modal.Title>
        </Modal.Header>
        <form onSubmit={callGCM}>
          <Modal.Body>
            <Row noGutters>
              <Col className="d-none d-md-block" md="10">
                <div className={classes.root}>
                  <ButtonGroup
                    size="large"
                    color="dark"
                    aria-label="outlined primary button group"
                  >
                    <MatButton
                      className={
                        duty_type === stringData.nrby ? classes.buttonRoot : ""
                      }
                      onClick={(e) => changeDutyStatus(e, stringData.nrby)}
                    >
                      NEARBY DRIVERS
                    </MatButton>
                    <MatButton
                      className={
                        duty_type === stringData.freed ? classes.buttonRoot : ""
                      }
                      onClick={(e) => changeDutyStatus(e, stringData.freed)}
                    >
                      ON-DUTY DRIVERS
                    </MatButton>
                  </ButtonGroup>
                </div>
              </Col>
              <Col md="2" className="d-flex justify-content-end">
                <Button
                  theme="dark"
                  className="my-auto"
                  onClick={handleDutyRefreshClick}
                >
                  <i className="fas fa-sync-alt" />
                </Button>
              </Col>
              <Col className="d-block d-md-none">
                <FormGroup>
                  <label className="d-flex justify-content-center">
                    Select driver options
                  </label>
                  <FormSelect
                    value={request_type}
                    onChange={handledutyStatusSelectChange}
                    style={{ fontWeight: 700, textAlignLast: "center" }}
                    className="selection-form"
                  >
                    <option value="nearby">Nearby drivers</option>
                    <option value="freedrivers">On duty drivers</option>
                  </FormSelect>
                </FormGroup>
              </Col>

              <Col sm="12" md="12" className="">
                <Button
                  block
                  theme="dark"
                  onClick={toggleRideDetails}
                  className="w-100 mt-3 assign-button-style"
                  style={{
                    backgroundColor: "#F0F0F0",
                    borderColor: "#F0F0F0",
                    color: "#151220",
                  }}
                >
                  Ride details
                  <i
                    className={
                      showTripDetails === false
                        ? "fas fa-chevron-down float-right"
                        : "fas fa-chevron-up float-right"
                    }
                  />
                </Button>
                <Collapse
                  open={detailCollapse}
                  className="px-3 py-2"
                  style={{ backgroundColor: "#DDDDDD" }}
                >
                  <div className="mx-md-3">
                    <span className="assign-trip_id">
                      Ride ID :{" "}
                      {modalSelectDriver ? modalSelectDriver.trip_id : "-"}
                    </span>
                    <Row
                      className="w-100 mx-auto mt-3 assign-col-data py-2"
                      style={{ backgroundColor: "#F5F6F8" }}
                    >
                      <Col className="col-4 ">Source</Col>
                      <Col className="col-4">Destination</Col>
                      <Col className="col-4">Pickup time</Col>
                    </Row>
                    <Row className="mt-3 assign-row-data px-2">
                      <Col className="col-4">
                        {modalSelectDriver ? modalSelectDriver.sname : "-"}
                      </Col>
                      <Col className="col-4">
                        {modalSelectDriver ? modalSelectDriver.dname : "-"}
                      </Col>
                      <Col className="col-4">
                        {modalSelectDriver ? modalSelectDriver.pickuptime : "-"}
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </Col>
              <Col sm="12" md="12" className="pt-md-4">
                <DataTableBase
                  columns={dutyColumn}
                  selectableRows
                  onSelectedRowsChange={driverRowSelection}
                  datas={driverData}
                  types="assigntable"
                  progressPending={assignPending}
                />
              </Col>

              {showOfferedAmount && (
                <Col className="col-12 col-md-4 pt-5">
                  <InputData
                    id="offeredAmount"
                    label="Offered amount"
                    placeholder="Enter the offered amount"
                    onChange={handleOfferedAmtChange}
                    value={offeredAmount}
                    type="number"
                    required
                  />
                </Col>
              )}
            </Row>
            {/* {sentSuccess === true && (
              <Row className="pt-4">
                <Col
                  className="col-12 d-flex justify-content-center"
                  style={{ fontSize: "1.3rem", color: "#59E67F" }}
                >
                  NOTIFICATION SENT TO DRIVER
                </Col>
              </Row>
            )} */}
          </Modal.Body>
          <Modal.Footer>
            <Row className="w-100 d-flex justify-content-center">
              <Col sm="12" md="4">
                <NotificationButton
                  className="py-3  w-100"
                  color="primary"
                  type="submit"
                >
                  SEND NOTIFICATION
                </NotificationButton>
              </Col>
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
      {/* </Form> */}
      {/* End ride assignment modal */}
      {/* Page Header */}

      {/* Modal to update payout amount */}
      <Modal
        show={openPayMod}
        onHide={resetPayMod}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Update payout to driver</Modal.Title>
        </Modal.Header>
        <form onSubmit={callUpdatePayment}>
          <Modal.Body>
            <Row noGutters>
              <Col className="col-12 col-md-4 py-3 px-2">
                <label className="mb-2" style={{ fontSize: "1rem" }}>
                  <strong>Enter the amount sent</strong>
                </label>
                <InputData
                  id="amountsent"
                  placeholder="Enter the amount sent"
                  onChange={handlePaidAmount}
                  value={amountSent}
                  type="number"
                  required
                />
              </Col>
              <Col className="col-12 col-md-4 py-3 px-2">
                <label className="mb-2" style={{ fontSize: "1rem" }}>
                  <strong>Select a payment mode</strong>
                </label>
                <FormSelect
                  value={modeofpayment}
                  onChange={handlePaymentMode}
                  style={{
                    fontWeight: 700,
                    textAlignLast: "center",
                    height: "calc(3.2rem + 2px)",
                  }}
                  className="selection-form"
                >
                  <option value="">Choose payment mode</option>
                  <option value="cash">Cash</option>
                  <option value="google_pay">Google Pay</option>
                  <option value="phone_pe">Phone Pe</option>
                  <option value="paytm">Paytm</option>
                  <option value="cashfree">Cashfree</option>
                </FormSelect>
              </Col>
              <Col className="col-12 col-md-4 py-3 px-2">
                <label className="mb-2" style={{ fontSize: "1rem" }}>
                  <strong>Paid to (Driver number)</strong>
                </label>
                <InputData
                  id="paidtodriver"
                  placeholder="Enter the driver's name"
                  onChange={handlePaidtoAmount}
                  value={paidtodriver}
                  type="text"
                  required
                />
              </Col>
              <Col className="col-12 col-md-4 py-3 px-2">
                <label className="mb-2" style={{ fontSize: "1rem" }}>
                  <strong>Paid from (Agent name)</strong>
                </label>
                <InputData
                  id="paidbyagent"
                  placeholder="Enter the agent's name"
                  onChange={handlePaidBy}
                  value={paidbyagent}
                  type="text"
                  required
                />
              </Col>
              <Col className="col-12 col-md-4 py-3 px-2">
                <label className="mb-2" style={{ fontSize: "1rem" }}>
                  <strong>Paid on</strong>
                </label>
                <DatePicker
                  size="lg"
                  selected={paytime}
                  onChange={changePayoutDate}
                  placeholderText="Select date"
                  dropdownMode="select"
                  className="text-center datepickers"
                  dateFormat="dd/MM/yyyy"
                  style={{ height: "calc(3.2rem + 2.5px) !important" }}
                />
                {/* <SoloDatePicker changeDate={changeDate} type="payout" /> */}
                {/* <InputData
                  id="offeredAmount"
                  placeholder="Enter paid date"
                  onChange={handleOfferedAmtChange}
                  value={offeredAmount}
                  type="number"
                  required
                /> */}
              </Col>
            </Row>
            {/* {sentSuccess === true && (
              <Row className="pt-4">
                <Col
                  className="col-12 d-flex justify-content-center"
                  style={{ fontSize: "1.3rem", color: "#59E67F" }}
                >
                  NOTIFICATION SENT TO DRIVER
                </Col>
              </Row>
            )} */}
          </Modal.Body>
          <Modal.Footer>
            <Row className="w-100 d-flex justify-content-center">
              <Col sm="12" md="4">
                <NotificationButton
                  className="py-3  w-100"
                  color="primary"
                  type="submit"
                >
                  UPDATE PAYMENT
                </NotificationButton>
              </Col>
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
      {/* End of payout modal */}
      <Row noGutters className="page-header py-4">
        <Col sm="12" md="9">
          <InnerBreadTitle
            title="Bookings overview"
            subtitle="Business accounts /"
            className="text-sm-left pl-0 pb-4 pb-md-0"
            pathName="/businessAccounts"
          />
        </Col>
        <Col className="px-md-2" sm="12" md="3">
          <FormGroup>
            <label className="d-flex justify-content-center">
              Filter based on region
            </label>
            <FormSelect
              value={region}
              onChange={handleSelectChange}
              style={{ fontWeight: 700, textAlignLast: "center" }}
              className="selection-form"
            >
              <option value="all">All</option>
              <option value="Bangalore">Bangalore</option>
            </FormSelect>
          </FormGroup>
        </Col>

        {/* <PageTitle title="Account overview" className="text-sm-left " /> */}
      </Row>
      <Row noGutters className="pt-md-4">
        <Col sm="12" md="9">
          <DatePickerWithDay changeDate={changeDate} type="count" />
        </Col>
        {props.smallStats.map((stats, idx) => (
          <Col
            className="col-12 col-md-3 my-4"
            key={idx}
            {...stats.attrs}
            style={{ paddingLeft: "1.7em" }}
          >
            <CountCards
              id={`count-stats-${idx}`}
              variation="1"
              label={stats.label}
              value={hits[stats.apiID]}
              stats={stats.stats}
            />
          </Col>
        ))}
      </Row>

      <Row noGutters className="py-md-0 py-3">
        <Col sm="12" md="8">
          <Button
            block
            theme="dark"
            onClick={toggle}
            className="mb-4 w-md-100 font-props d-none d-md-block"
            style={{
              fontWeight: "700",
              marginTop: "-50px",
              backgroundColor: "#F0F0F0",
              borderColor: "#F0F0F0",
              color: "#151220",
            }}
          >
            {showDetails === false ? "SHOW RIDE COUNT" : "COLLAPSE RIDE COUNT"}
            <i
              className={
                showDetails === false
                  ? "fas fa-chevron-down float-right"
                  : "fas fa-chevron-up float-right"
              }
            />
          </Button>
          <Button
            block
            theme="dark"
            onClick={toggle}
            className="mb-4 w-md-100 font-props d-block d-md-none"
            style={{ fontWeight: "700" }}
          >
            {showDetails === false ? "SHOW DETAILS" : "COLLAPSE DETAILS"}
            <i
              className={
                showDetails === false
                  ? "fas fa-chevron-down float-right"
                  : "fas fa-chevron-up float-right"
              }
            />
          </Button>
        </Col>
        <Col sm="12" md="12">
          <Collapse open={collapse} className="">
            <Row className="">
              {props.RideStats.map((stats, idx) => (
                <Col sm="12" md="3" className="mb-4" key={idx} {...stats.attrs}>
                  {/* {console.log(data[stats.apiID])} */}
                  <CountCards
                    id={`count-stats-details-${idx}`}
                    variation="1"
                    label={stats.label}
                    value={hits[stats.apiID]}
                    stats={stats.stats}
                  />
                  <Tooltip
                    trigger="hover"
                    open={openTip}
                    target={"#count-stats-details-4"}
                    toggle={toggleTip}
                    placement="bottom"
                  >
                    <span>
                      Consumer Cancelled : {hits.consumerCanceledCount}
                    </span>
                    <br />
                    <span>
                      Cancelled via panel : {hits.driverCanceledCount}
                    </span>
                  </Tooltip>
                </Col>
              ))}
            </Row>
          </Collapse>

          {/* <DatePickerWithDay /> */}
          {/* <CountCards /> */}
        </Col>
      </Row>
      <Row noGutters>
        <Col sm="12" md="12">
          <hr />
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        {props.FlowStats.map((stats, idx) => (
          <Col className="col-md mb-4 mt-2" key={idx} {...stats.attrs}>
            <CountCards
              id={`smaller-stats-${idx}`}
              variation="1"
              label={stats.label}
              value={airportFlow[stats.apiID]}
              stats={stats.stats}
            />
          </Col>
        ))}
        {/* <Col className="my-auto pb-3 pb-md-0 d-none d-md-block">
          <div className="float-left">
            <NotificationButton
              className="py-2  w-100"
              theme="dark"
              style={{ minWidth: "150px" }}
              onClick={goBackToAccounts}
            >
              <i className="fas fa-arrow-left mx-1" />
              <span
                style={{ fontWeight: "700", fontSize: "1em" }}
                className="mx-2"
              >
                BACK
              </span>
            </NotificationButton>
          </div>
        </Col> */}
        {/* <Col className="my-auto pb-3 pb-md-0 d-block d-md-none">
          <div className="float-right">
            <NotificationButton
              className="py-3  w-100"
              theme="dark"
              style={{ minWidth: "150px" }}
              onClick={goBackToAccounts}
            >
              <i className="fas fa-arrow-left mx-1" />
              <span
                style={{ fontSize: "1.15em", fontWeight: "700" }}
                className="mx-3"
              >
                BACK
              </span>
            </NotificationButton>
          </div>
        </Col> */}

        {props.CountStats.map((stats, idx) => (
          <Col className="col-md mb-4 mt-2" key={idx} {...stats.attrs}>
            <CountCards
              id={`smaller-stats-${idx}`}
              variation="1"
              label={stats.label}
              value={assignedCount}
              stats={stats.stats}
            />
          </Col>
        ))}
      </Row>
      <Row noGutters className="pb-4 py-md-4 ">
        {changeDetailsPage === false && (
          <Card className="w-100">
            <CardBody>
              <Row>
                {/* Position of the status button group - Start */}
                <Col className="d-none d-md-block" md="10">
                  <div className={classes.root}>
                    <ButtonGroup
                      size="large"
                      color="dark"
                      aria-label="outlined primary button group"
                    >
                      <MatButton
                        className={
                          request_type === stringData.reqz
                            ? classes.buttonRoot
                            : ""
                        }
                        onClick={(e) => changeStatus(e, stringData.reqz)}
                      >
                        Unassigned
                      </MatButton>
                      <MatButton
                        className={
                          request_type === stringData.accp
                            ? classes.buttonRoot
                            : ""
                        }
                        onClick={(e) => changeStatus(e, stringData.accp)}
                      >
                        Accepted
                      </MatButton>
                      <MatButton
                        className={
                          request_type === stringData.ongo
                            ? classes.buttonRoot
                            : ""
                        }
                        onClick={(e) => changeStatus(e, stringData.ongo)}
                      >
                        Ongoing
                      </MatButton>
                      <MatButton
                        className={
                          request_type === stringData.comp
                            ? classes.buttonRoot
                            : ""
                        }
                        onClick={(e) => changeStatus(e, stringData.comp)}
                      >
                        Completed
                      </MatButton>
                      <MatButton
                        className={
                          request_type === stringData.cancl
                            ? classes.buttonRoot
                            : ""
                        }
                        onClick={(e) => changeStatus(e, stringData.cancl)}
                      >
                        Cancelled
                      </MatButton>
                    </ButtonGroup>
                  </div>
                </Col>
                {/* Position of the status button group - End */}
                {/* Position of the refresh button - Start */}
                <Col md="2" className="d-flex justify-content-end">
                  <Button
                    theme="dark"
                    className="my-auto"
                    onClick={handleRefreshClick}
                  >
                    <i className="fas fa-sync-alt" />
                  </Button>
                </Col>
                {/* Position of the refresh button - End */}
                {/* Status select for mobile devices  - Start */}
                <Col className="d-block d-md-none">
                  <FormGroup>
                    <label className="d-flex justify-content-center">
                      Select the trip status
                    </label>
                    <FormSelect
                      value={request_type}
                      onChange={handletripStatusSelectChange}
                      style={{ fontWeight: 700, textAlignLast: "center" }}
                      className="selection-form"
                    >
                      <option value="requested">Unassigned rides</option>
                      <option value="assigned">Assigned rides</option>
                      <option value="ongoing">Ongoing rides</option>
                      <option value="completed">Completed rides</option>
                      <option value="canceled">Canceled rides</option>
                    </FormSelect>
                  </FormGroup>
                </Col>
                {/* Status select for mobile devices  - Start */}
              </Row>
              {/* Filters and table rows */}
              <Row className="pt-4">
                {/* <Col sm="12" md="12">
                  <DatePickerWithDay />
                </Col> */}
                <Col sm="12" md="12" className="pt-md-4">
                  <DataTableBase
                    columns={column}
                    datas={tripData}
                    types="triptable"
                    progressPending={pending}
                    changeDate={changeDate}
                  />
                </Col>
              </Row>
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  {message}
                </Alert>
              </Snackbar>
              <Snackbar
                open={errorOpen}
                autoHideDuration={3000}
                onClose={handleErrorClose}
              >
                <Alert onClose={handleErrorClose} severity="error">
                  {message}
                </Alert>
              </Snackbar>
            </CardBody>
          </Card>
        )}

        {changeDetailsPage === true && (
          <BookingDetails
            onTitleChange={closeTripDetails}
            tripData={tripDetailsData}
          />
        )}
      </Row>
    </Container>
  );
}

const InputData = (props) => {
  //   console.log(props);
  const { label, value, placeholder, type } = props;
  return (
    <CssTextField
      variant="outlined"
      fullWidth
      placeholder={placeholder}
      label={label}
      value={value}
      onChange={props.onChange}
      InputLabelProps={{ shrink: true }}
      type={type}
      required
    />
  );
};

BookingsView.propTypes = {
  smallStats: PropTypes.array,
  RideStats: PropTypes.array,
  CountStats: PropTypes.array,
  hits: PropTypes.string,
  date: PropTypes.oneOf(["object", "string"]),
  tripDate: PropTypes.oneOf(["object", "string"]),
};

BookingsView.defaultProps = {
  smallStats: [
    {
      label: "Total rides",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "totalRidesCount",
    },
  ],
  RideStats: [
    {
      label: "UNASSIGNED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "unassignedRides",
    },
    {
      label: "ASSIGNED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "assignedRides",
    },
    {
      label: "ON-GOING RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "ongoing",
    },
    {
      label: "COMPLETED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "completedRides",
    },
    {
      label: "CANCELLED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "cancelledRides",
    },
    {
      label: "DETACHED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "detachedRides",
    },
  ],
  CountStats: [
    {
      label: "Total assigned",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "assignedCount",
    },
  ],
  FlowStats: [
    {
      label: "City to airport",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "city_to_airport",
    },
    {
      label: "Airport to city",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "airport_to_city",
    },
  ],
};
