import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import PropTypes from "prop-types";
import * as config from "../../constants/constants.json";
import * as styling from "../../constants/googleMapStyles.json";
function getImage(data) {
  // console.log(data);
  if (data.vehicle_type === "Hatchback") {
    return "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/bolt.svg";
  } else if (data.vehicle_type === "Sedan") {
    return "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/flash.svg";
  } else if (data.vehicle_type === "SUV") {
    return "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/suv.svg";
  } else {
    return "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/bolt.svg";
  }
}

export class LiveMap extends Component {
  constructor(props) {
    super(props);
    // this.onMarkerClick = this.onMarkerClick.bind(this);
    this.state = {
      locationState: [],
      routeCoordinates: [],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      bearing: 0,
      center: {
        lat: 12.9716,
        lng: 77.5946,
      },
      zoom: 9,
      progress: [],
    };
    // console.log(this.props);
  }
  // onMarkerClick(props, marker, e) {
  //     this.setState({
  //         selectedPlace: props,
  //         activeMarker: marker,
  //         showingInfoWindow: true
  //     });
  // }
  fetchLocationCall() {
    fetch(config.url + "htmlcrm/getDriverLocation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        trip_id: this.props.dataProps.trip_id,
        // trip_id: 'zNgXI0v9IeRWgUQf'
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          // console.log(this.state.routeCoordinates.concat([result]))
          this.setState({
            locationState: result.driver_location,
            bearing: result.bearing,
          });
          // routeCoordinates: this.state.routeCoordinates.concat([result])
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  // componentWillUpdate() {
  //     // this.fetchLocationCall()
  //     // this.timerID = setInterval(
  //     //     () => this.fetchLocationCall(),
  //     //     10000
  //     // );
  // }
  componentDidMount() {
    this.timerID = setInterval(() => this.fetchLocationCall(), 40000);
    this.fetchLocationCall();
  }
  componentWillUnmount() {
    // Store.removeChangeListener(this.onChange);
    clearInterval(this.timerID);
  }
  render() {
    if (!this.props.google) {
      return <div>Loading...</div>;
    }
    let scrIcon = {
      url: "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/source_new.svg",

      scaledSize: new this.props.google.maps.Size(15, 15),
    };
    let destIcon = {
      url: "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/destination_new.svg",

      scaledSize: new this.props.google.maps.Size(15, 15),
    };
    let drivIcon = {
      url: getImage({
        vehicle_type: "",
      }),
      scaledSize: new this.props.google.maps.Size(25, 25),
    };
    // console.log(thitripIds.state.locationState);
    return (
      <div style={{ height: "50vh", width: "100%" }}>
        {/* {console.log("called")} */}
        <Map
          style={{}}
          google={this.props.google}
          zoom={this.state.zoom}
          initialCenter={this.state.center}
          styles={styling.style}
        >
          <Marker
            title={"The marker`s title will appear as a tooltip."}
            name={"Source"}
            position={{
              lat: this.props.dataProps.slatlon[1],
              lng: this.props.dataProps.slatlon[0],
            }}
            icon={scrIcon}
          />
          <Marker
            name={"Destination"}
            position={{
              lat: this.props.dataProps.dlatlon[1],
              lng: this.props.dataProps.dlatlon[0],
            }}
            icon={destIcon}
          />
          <Marker
            name={"Driver"}
            position={{
              lat:
                this.state.locationState === undefined
                  ? this.props.liveProps.driver_location[1]
                  : this.state.locationState[1],
              lng:
                this.state.locationState === undefined
                  ? this.props.liveProps.driver_location[0]
                  : this.state.locationState[0],
            }}
            icon={drivIcon}
            rotation={
              this.state.locationState === undefined
                ? this.props.liveProps.bearing
                : this.state.bearing
            }
            mapCenter={{
              lat:
                this.state.locationState === undefined
                  ? this.props.liveProps.driver_location[1]
                  : this.state.locationState[1],
              lng:
                this.state.locationState === undefined
                  ? this.props.liveProps.driver_location[0]
                  : this.state.locationState[0],
            }}
          >
            {/* <Polyline coordinates={this.state.routeCoordinates} strokeWidth={5} /> */}
          </Marker>

          {/* <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                    >
                        <div>
                            <h1>{this.state.selectedPlace.name}</h1>
                        </div>
                    </InfoWindow> */}
        </Map>
      </div>
    );
  }
}
LiveMap.default = {
  center: {
    lat: 12.9716,
    lng: 77.5946,
  },
  zoom: 9,
};
LiveMap.defaultProps = {
  center: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  zoom: PropTypes.number,
};
export default GoogleApiWrapper({
  apiKey: "AIzaSyA6Ir9-jvJV-SFNvy-_Hz4bGXk8sNIWVOo",
})(LiveMap);
