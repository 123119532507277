import React, { Component } from "react";
import pico from "./pico";
import camvas from "./camvas";

// let faceRecognised = false;

const width = 500;
const height = 500;

const rgba_to_grayscale = (rgba, nrows, ncols) => {
  const gray = new Uint8Array(nrows * ncols);
  for (let r = 0; r < nrows; ++r)
    for (let c = 0; c < ncols; ++c)
      gray[r * ncols + c] =
        (2 * rgba[r * 4 * ncols + 4 * c + 0] +
          7 * rgba[r * 4 * ncols + 4 * c + 1] +
          1 * rgba[r * 4 * ncols + 4 * c + 2]) /
        10;
  return gray;
};

class ReactPico extends Component {
  state = {
    faceFinder: null,
    faceRecognised: false,
    updateMemory: pico.instantiate_detection_memory(5),
  };
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    this.loadFaceFinder();
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.props);
    // console.log(prevState);
    if (this.props.onTryAgain !== prevProps.onTryAgain) {
      this.setState({
        faceRecognised: false,
      });
    }
  }
  loadFaceFinder() {
    const cascadeurl =
      "https://raw.githubusercontent.com/nenadmarkus/pico/c2e81f9d23cc11d1a612fd21e4f9de0921a5d0d9/rnt/cascades/facefinder";
    fetch(cascadeurl).then((response) => {
      response.arrayBuffer().then((buffer) => {
        var bytes = new Int8Array(buffer);
        this.setState({
          faceFinder: pico.unpack_cascade(bytes),
        });
        new camvas(this.canvasRef.current.getContext("2d"), this.processVideo);
      });
    });
  }

  processVideo = (video, dt) => {
    // console.log(this.props);
    if (this.state.faceFinder && this.props.onFaceFound) {
      const ctx = this.canvasRef.current.getContext("2d");
      // console.log(ctx);
      const updateMemory = this.state.updateMemory;
      ctx.canvas.height = height;
      ctx.canvas.width = width;
      // console.log(ctx.canvas);
      // render the video frame to the canvas element and extract RGBA pixel data
      ctx.drawImage(video, 0, 0);

      var rgba = ctx.getImageData(0, 0, width, height).data;
      // prepare input to `run_cascade`
      let image = {
        pixels: rgba_to_grayscale(rgba, width, height),
        nrows: height,
        ncols: width,
        ldim: width,
      };
      let params = {
        shiftfactor: 0.1, // move the detection window by 10% of its size
        minsize: 100, // minimum size of a face
        maxsize: 1000, // maximum size of a face
        scalefactor: 1.1, // for multiscale processing: resize the detection window by 10% when moving to the higher scale
      };
      // run the cascade over the frame and cluster the obtained detections
      // dets is an array that contains (r, c, s, q) quadruplets
      // (representing row, column, scale and detection score)
      let dets = pico.run_cascade(image, this.state.faceFinder, params);
      dets = updateMemory(dets);
      dets = pico.cluster_detections(dets, 0.2); // set IoU threshold to 0.2
      // draw detections
      for (let i = 0; i < dets.length; ++i) {
        // check the detection score
        // if it's above the threshold, draw it
        // (the constant 50.0 is empirical: other cascades might require a different one)
        if (dets[i][3] > 50.0) {
          // console.log("hererer********************");
          let imageData = document.getElementById("mycamera").toDataURL("jpg");
          // this.props.onFaceFound({
          //   x: 640 - dets[i][1],
          //   y: dets[i][0],
          //   radius: dets[i][2],
          //   xRatio: (640 - dets[i][1]) / 640,
          //   yRatio: dets[i][0] / 480,
          //   totalX: ((640 - dets[i][1]) / 640) * window.innerWidth,
          //   totalY: (dets[i][0] / 480) * window.innerHeight,
          // });
          if (!this.state.faceRecognised) {
            this.setState(
              {
                faceRecognised: true,
              },
              () =>
                this.props.onFaceFound({
                  imageData: imageData,
                })
            );
            console.log("here");
            // faceRecognised = true;
          }
          var r, c, s;
          ctx.beginPath();
          ctx.arc(
            dets[i][1],
            dets[i][0],
            dets[i][2] / 2,
            0,
            2 * Math.PI,
            false
          );
          ctx.lineWidth = 3;
          ctx.strokeStyle = "white";
          ctx.stroke();
        }
        // if (faceRecognised === true) break;
      }
    }
  };
  render() {
    return (
      <canvas
        id="mycamera"
        ref={this.canvasRef}
        height={500}
        width={500}
        style={{
          marginTop: "auto",
          marginBottom: "auto",
        }}
      ></canvas>
    );
  }
}

export default ReactPico;
