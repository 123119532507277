import React, { Component } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import PropTypes from "prop-types";
import Select from "react-select";
import equal from "fast-deep-equal";
import {
  Row,
  Nav,
  NavItem,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Collapse,
  FormInput,
  Modal,
  ModalBody,
  ModalFooter,
} from "shards-react";
import moment from "moment";

import DatePicker from "react-date-picker";
import TripDetails from "./../common/Details";
import NavTab from "./../common/NavTab";
import ModNavTab from "./../common/ModalNav";
import Search from "./../common/Search";
import * as config from "../../constants/constants.json";
import Pagination from "./../common/Pagination";
// import ModalView from "./../common/Modal"

import { Store } from "../../flux";
const oneDay = 24 * 60 * 60 * 1000;
// import BasicModalExample from "../common/Modal"
function capitalizeFirstLetter(string) {
  // console.log(string);
  return string[0].toUpperCase() + string.slice(1);
}
class TripView extends Component {
  constructor(props) {
    super(props); //since we are extending class Table so we have to use super in order to override Component class constructor
    // console.log(props);
    this.state = {
      //state is by default an object
      data: [],
      dData: [],
      rideType: "",
      request_status: "",
      tripData: [],
      tripDate: new Date(),
      timerDate: new Date(),
      changeDiv: false,
      tripId: "",
      rideItems: Store.getRideItems(),
      request_type: "requested",
      index: 0,
      collapse: true,
      openMod: false,
      dutyState: "nearby",
      selected: {},
      selectAll: 0,
      showTripDetails: false,
      filteredData: [],
      filterOption: [],
      columnSearch: [],
      searchInput: "",
      vendor: window.sessionStorage.getItem("vendor") || "",
      select2: undefined,
      daySelected: "Today",
    };
    // console.log(props)
    this.handleRefreshClick = this.handleRefreshClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleClickChange = this.handleClickChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.fetchCall = this.fetchCall.bind(this);
    this.fetchOngoingCall = this.fetchOngoingCall.bind(this);
    this.fetchCompletedRides = this.fetchCompletedRides.bind(this);
    this.fetchMiscRides = this.fetchMiscRides.bind(this);
    this.viewClick = this.viewClick.bind(this);
    this.fetchDrivers = this.fetchDrivers.bind(this);
    this.toggleRow = this.toggleRow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetMod = this.resetMod.bind(this);
    this.callGCM = this.callGCM.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handlemodalclick = this.handlemodalclick.bind(this);
    this.ridesCountSNextDate = this.ridesCountSNextDate.bind(this);
    this.ridesCountSPrevDate = this.ridesCountSPrevDate.bind(this);
    this.handleDiv = this.handleDiv.bind(this);
    // console.log(this.state.rideItems);
    // console.log(this.state);
  }
  ridesCountSNextDate() {
    let date = this.state.tripDate;
    // console.log(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    nextDate.setHours(0, 0, 0, 0);
    // console.log(nextDate);
    if (nextDate) {
      this.setState({
        tripDate: nextDate,
      });
    }
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    // console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));
    // console.log(diffDays);
    if (nextDate.getTime() >= currentDate.getTime()) {
      if (nextDate.getTime() == currentDate.getTime()) {
        this.setState(
          {
            daySelected: "Today",
          },
          () => {
            this.handleRefreshClick();
          }
        );
        // getAllRidesCount();
      } else {
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Tomorrow",
            },
            () => {
              this.handleRefreshClick();
            }
          );
          // $("#ridesCountSDateName").text("Tomorrow");
          // getAllRidesCount();
        } else {
          diffDays = diffDays - 1;
          // console.log(diffDays);
          if (diffDays == 1) {
            this.setState(
              {
                daySelected: "Day after tomorrow",
              },
              () => {
                this.handleRefreshClick();
              }
            );
            // $("#ridesCountSDateName").text("Day after tomorrow");
          } else {
            this.setState(
              {
                daySelected: `${diffDays} days after tomorrow`,
              },
              () => {
                this.handleRefreshClick();
              }
            );
            // $("#ridesCountSDateName").text(diffDays + " days after tomorrow");
          }
          // getAllRidesCount();
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));

      if (diffDays == 1) {
        this.setState(
          {
            daySelected: "Yesterday",
          },
          () => {
            this.handleRefreshClick();
          }
        );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Day before yesterday",
            },
            () => {
              this.handleRefreshClick();
            }
          );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          this.setState(
            {
              daySelected: `${diffDays} days from yesterday`,
            },
            () => {
              this.handleRefreshClick();
            }
          );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  }

  ridesCountSPrevDate() {
    let date = this.state.tripDate;
    // console.log(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() - 1);
    nextDate.setHours(0, 0, 0, 0);
    // console.log(nextDate);
    if (nextDate) {
      this.setState({
        tripDate: nextDate,
      });
    }
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    // console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));
    // console.log(diffDays);
    if (nextDate.getTime() >= currentDate.getTime()) {
      if (nextDate.getTime() == currentDate.getTime()) {
        this.setState(
          {
            daySelected: "Today",
          },
          () => {
            this.handleRefreshClick();
          }
        );
        // getAllRidesCount();
      } else {
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Tomorrow",
            },
            () => {
              this.handleRefreshClick();
            }
          );
          // $("#ridesCountSDateName").text("Tomorrow");
          // getAllRidesCount();
        } else {
          diffDays = diffDays - 1;
          // console.log(diffDays);
          if (diffDays == 1) {
            this.setState(
              {
                daySelected: "Day after tomorrow",
              },
              () => {
                this.handleRefreshClick();
              }
            );
            // $("#ridesCountSDateName").text("Day after tomorrow");
          } else {
            this.setState(
              {
                daySelected: `${diffDays} days after tomorrow`,
              },
              () => {
                this.handleRefreshClick();
              }
            );
            // $("#ridesCountSDateName").text(diffDays + " days after tomorrow");
          }
          // getAllRidesCount();
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));

      if (diffDays == 1) {
        this.setState(
          {
            daySelected: "Yesterday",
          },
          () => {
            this.handleRefreshClick();
          }
        );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Day before yesterday",
            },
            () => {
              this.handleRefreshClick();
            }
          );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          this.setState(
            {
              daySelected: `${diffDays} days from yesterday`,
            },
            () => {
              this.handleRefreshClick();
            }
          );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  }
  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filteredData;
    // console.log(filtered);
    let insertNewFilter = 1;
    // console.log("here");
    if (filtered.length) {
      filtered.forEach((filter, i) => {
        // console.log(filter);
        // console.log(i);
        if (filter["id"] === accessor) {
          // console.log("here now");
          if (value === "" || !value.length) {
            // console.log("herewwww");
            filtered.splice(i, 1);
          } else if (value === "null") {
            // console.log("here is my val");
          } else {
            // console.log("herewssswww");
            filter["value"] = value;
          }

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }
    // console.log(filtered);
    this.setState({ filterOption: filtered });
  };
  getColumns() {
    const columnSearch = [
      {
        Header: "Sl no",
        id: "serialno",
        style: {},
        Cell: (row) => {
          // console.log(row);
          return <div>{row.viewIndex + 1}</div>;
        },
      },
      {
        Header: "Trip ID",
        accessor: "trip_id",
      },
      {
        Header: "Source",
        accessor: "sname",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Destination",
        accessor: "dname",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Pickup time ",
        accessor: "pickuptime",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Ride type",
        accessor: "ridetype",
      },
      {
        Header: "Vehicle type",
        accessor: "vehicle_type",
      },
      {
        Header: "Request status",
        accessor: "request_status",
        id: "request_status",
        Cell: (row) => {
          if (row.original.request_status === "Consumercancelled") {
            return <div>Consumer canceled</div>;
          } else if (row.original.request_status === "Drivercancelled") {
            return <div>Canceled via panel</div>;
          } else if (
            row.original.request_status === "ended" ||
            row.original.request_status === "completed"
          ) {
            return <div>Completed</div>;
          } else {
            return <div>row.original.request_status</div>;
          }
        },
      },
      {
        Header: "Rider",
        style: { whiteSpace: "unset" },
        Cell: (row) => {
          return (
            <div>
              {row.original.consumername + " - " + row.original.phonenumber}
            </div>
          );
        },
      },
      {
        id: "edit",
        Header: "Actions",
        columns: [
          {
            Cell: (row) => {
              //  console.log(this);
              // console.log(row.original.request_status)
              return (
                <div className="d-flex flex-column">
                  {row.original.request_status === "Requested" && (
                    <Button
                      className="mx-2 my-2  table-button-style"
                      onClick={() => {
                        this.viewClick(row.original);
                      }}
                    >
                      ASSIGN
                    </Button>
                  )}
                  <Button
                    className="mx-2 my-2  table-button-style"
                    onClick={() => {
                      this.handleClick(row.original);
                    }}
                  >
                    VIEW
                  </Button>
                </div>
              );
            },
          },
        ],
      },
    ];
    this.setState({
      columnSearch,
    });
  }
  handlemodalclick() {
    this.setState({
      openMod: !this.state.openMod,
      changeDiv: false,
    });
  }

  goBack() {
    this.props.propsData.history.goBack();
  }

  handleSetData = (data) => {
    // console.log(data);
    this.setState({ data });
  };

  handleSetFilteredData = (filteredData) => {
    this.setState({ filteredData });
  };

  handleSetSearchInput = (searchInput) => {
    this.setState({ searchInput });
  };
  callGCM() {
    // console.log("hello osodosadosa");
    // console.log(this.state.selected);
    // console.log(this.state.tripData.trip_id);
    fetch(config.url + "htmlcrm/sendScheduledRideGCM", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        executive_id: window.sessionStorage.getItem("user_id"),
        trip_id: this.state.tripData.trip_id,
        dunique_ids: this.state.selected.dunique_id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // this.setState({
          //     dData: result.drivers
          // })
          // console.log(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  resetMod() {
    this.setState({
      openMod: !this.state.openMod,
    });
  }

  toggle() {
    this.setState({
      collapse: !this.state.collapse,
      showTripDetails: !this.state.showTripDetails,
    });
  }
  toggleRow(data) {
    // console.log(data)
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[data] = !this.state.selected[data];
    newSelected["dunique_id"] = data;
    this.setState({
      selected: newSelected,
      selectAll: 2,
    });
  }

  toggleSelectAll() {
    // console.log("here");
    let newSelected = {};

    if (this.state.selectAll === 0) {
      this.state.dData.forEach((x) => {
        newSelected[x.key] = true;
      });
    }

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
    });
  }

  toggleOnSelectAll() {
    // console.log("here");
    let newSelected = {};

    if (this.state.selectAll === 0) {
      this.state.dData.forEach((x) => {
        newSelected[x.dunique_id] = true;
      });
    }

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
    });
  }

  viewClick(data) {
    // console.log("*****************halooooo*****************");
    // console.log(data);
    // return (<BasicModalExample />)
    this.setState(
      {
        openMod: !this.state.openMod,
        tripData: data,
      },
      () => {
        this.fetchDrivers();
      }
    );
  }

  fetchDrivers() {
    fetch(config.url + "api/nearByDrivers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        lat: this.state.tripData.slatlon[1],
        lng: this.state.tripData.slatlon[0],
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result.drivers);
          this.setState({
            dData: result.drivers,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  fetchDutyDrivers() {
    // console.log(this.state.tripData);
    fetch(config.url + "htmlcrm/freeDrivers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        vehicle_type: this.state.tripData.vehicle_type,
        triptype: this.state.tripData.ridetype,
        trip_id: this.state.trip_id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result.driverlist);
          this.setState({
            dData: result.driverlist,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  // Handle toggle visibility of the rides Tab
  onChange(props) {
    // console.log("**********adasdasa*************");
    // console.log(...this.state)
    this.setState({
      ...this.state,
      rideItems: Store.getRideItems(),
    });
  }
  // Reload list when a date is seleced from the date picker
  handleTime(tripDate) {
    // console.log(this);
    // console.log(this.state.request_type);
    this.setState({ tripDate }, () => {
      if (this.state.request_type === "requested") {
        // this.setState({
        //     request_type: "unassigned"
        // })
        this.fetchCall();
      } else if (this.state.request_type === "assigned") {
        // console.log("here");
        // this.setState({
        //     request_type: "assigned"
        // })
        this.fetchCall();
      } else if (this.state.request_type === "ongoing") {
        // this.setState({
        //     request_type: "ongoing"
        // })
        this.fetchOngoingCall();
      } else if (this.state.request_type === "completed") {
        // this.setState({
        //     request_type: "completed"
        // })
        this.fetchCompletedRides();
      } else {
        // this.setState({
        //   request_type: "canceled"
        // });
        this.fetchMiscRides();
      }
    });
    // this.countFetch()
  }
  // Api call to fetch unassigned and assigned rides
  fetchCall(props) {
    // console.log(this.state.tripDate);
    let time = this.state.tripDate.setHours(0, 0, 0, 0);
    // console.log(time);
    fetch(config.url + "htmlcrm/taxiScheduleRideList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        vendor: this.state.vendor,
        milliseconds: time,
        request_type: this.state.request_type,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          this.setState({
            data:
              result.taxiScheduleRideList === undefined
                ? []
                : result.taxiScheduleRideList,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  // Api call to fetch ongoing rides
  fetchOngoingCall(props) {
    fetch(config.url + "htmlcrm/getOngoingRidesList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        vendor: this.state.vendor,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          this.setState({
            data: result.rideslist === undefined ? [] : result.rideslist,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  // Api call to fetch completed rides
  fetchCompletedRides(props) {
    let time = this.state.tripDate.setHours(0, 0, 0, 0);
    // console.log(time)
    // let milliseconds = time.getTime()
    // console.log((this.state.tripDate).getTime())
    fetch(config.url + "htmlcrm/getCompletedRidesList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        vendor: this.state.vendor,
        milliseconds: time,
        // request_type: this.state.request_type
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          this.setState({
            data: result.rideslist,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  // Api call to fetch canceled rides
  fetchMiscRides(props) {
    // console.log(this.state.vendor);
    // console.log((this.state.tripDate).getTime())
    fetch(config.url + "htmlcrm/getMiscRidesList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        vendor: this.state.vendor,
        milliseconds: this.state.tripDate.getTime(),
        // request_type: this.state.request_type
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          this.setState({
            data: result.rideslist,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  handleRefreshClick() {
    if (this.state.request_type === "requested") {
      // this.setState({
      //     request_type: "unassigned"
      // })
      this.fetchCall();
    } else if (this.state.request_type === "assigned") {
      // console.log("here");
      // this.setState({
      //     request_type: "assigned"
      // })
      this.fetchCall();
    } else if (this.state.request_type === "ongoing") {
      // this.setState({
      //     request_type: "ongoing"
      // })
      this.fetchOngoingCall();
    } else if (this.state.request_type === "completed") {
      // this.setState({
      //     request_type: "completed"
      // })
      this.fetchCompletedRides();
    } else {
      // this.setState({
      //   request_type: "canceled"
      // });
      this.fetchMiscRides();
    }
  }

  handleDiv() {
    this.setState({
      changeDiv: true,
    });
  }
  // Change to ride details page on click of view
  handleClick(data) {
    // console.log("************************")
    // console.log(data);
    this.setState({
      changeDiv: true,
      tripId: data.trip_id,
      rideType: data.ridetype,
      request_status: data.request_status,
    });
  }
  // Handle state change on click of the tab
  handleClickChange(data, index) {
    this.handleSelect(index);
    // console.log(index);
    // console.log(this.state);
    if (data.label === "Unassigned rides") {
      this.setState(
        {
          request_type: "requested",
        },
        () => {
          this.fetchCall();
        }
      );
    } else if (data.label === "Assigned rides") {
      // console.log("here");
      this.setState(
        {
          request_type: "assigned",
        },
        () => {
          this.fetchCall();
        }
      );
    } else if (data.label === "Ongoing rides") {
      this.setState(
        {
          request_type: "ongoing",
        },
        () => {
          this.fetchOngoingCall();
        }
      );
    } else if (data.label === "Completed rides") {
      this.setState(
        {
          request_type: "completed",
        },
        () => {
          this.fetchCompletedRides();
        }
      );
    } else if (data.label === "Nearby drivers") {
      this.setState(
        {
          dutyState: "nearby",
        },
        () => {
          this.fetchDrivers();
        }
      );
    } else if (data.label === "On duty drivers") {
      this.setState(
        {
          dutyState: "onduty",
        },
        () => {
          this.fetchDutyDrivers();
        }
      );
    } else {
      this.setState(
        {
          request_type: "canceled",
        },
        () => {
          this.fetchMiscRides();
        }
      );
    }
  }
  // Set active tab
  handleSelect(index) {
    // console.log("000000000000000000000000000000000000");
    // console.log(index);
    this.setState({
      index: index,
    });
  }
  // Change to ride list on click of the close button in ride details page
  handleChange() {
    this.setState({
      changeDiv: false,
    });
  }
  // Function called by set interval to load ride list
  tick(data) {
    // console.log(data);
    switch (data) {
      case "requested":
        // console.log(data);
        this.fetchCall();
        break;
      case "assigned":
        this.fetchCall();
        break;
      case "completed":
        // console.log(data);
        this.fetchCompletedRides();
        break;
      case "ongoing":
        // console.log(data);
        this.fetchOngoingCall();
        break;
      default:
        // console.log(data);
        this.fetchMiscRides();
        break;
    }
    // this.fetchCall()
  }

  // Called before render only once
  componentDidMount(props) {
    // console.log(this.state.request_type)
    // console.log("herer");
    // if (this.props.request_type !== this.state.request_type) {
    //     this.fetchCall(this.state)
    // }
    // if (this.props.propsData.location.state.label === undefined) {
    //   this.setState(
    //     {
    //       vendor:window.sessionStorage.getItem("vendor"),
    //     },
    //     this.fetchCall(this.state)
    //   );
    // } else {
    //   this.setState(
    //     {
    //       vendor: this.props.propsData.location.state.label,
    //     },
    //     () => {
    //       sessionStorage.setItem(
    //         "vendor",
    //         this.props.propsData.location.state.label
    //       );
    //       this.fetchCall(this.state);
    //     }
    //   );
    // }
    // console.log("************************************************");
    // console.log(this.props);
    if (this.props.propsData.location.state === undefined) {
      //   this.fetchCall(this.state);
    } else {
      // console.log(this.props.propsData.location.state.trip_id);
      // tripId={this.state.tripId}
      // rideType={this.state.rideType}
      // requestStatus={this.state.request_status}
      this.setState(
        {
          tripId: this.props.propsData.location.state.trip_id,
        },
        () => {
          this.handleDiv();
        }
      );
    }

    // this.timerID = setInterval(
    //     () => this.tick(this.state.request_type),
    //     2000000
    // );
  }

  componentDidUpdate(prevProps) {
    // console.log(prevProps);
    
    if (
      !equal(
        this.props.propsData.location.state.trip_id,
        prevProps.propsData.location.state.trip_id
      )
    ) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      // this.updateUser();
      this.setState({
        tripId: this.props.propsData.location.state.trip_id,
        changeDiv: true,
      });
    }
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
    clearInterval(this.timerID);
  }

  render() {
    //Whenever our class runs, render method will be called automatically, it may have already defined in the constructor behind the scene.
    // console.log("*************state***************");
    // console.log(this.props);
    const assignDrivCol = [
      {
        id: "checkbox",
        accessor: "",
        Cell: ({ original }) => {
          // console.log(original);
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selected[original.key] === true}
              onChange={() => this.toggleRow(original.key)}
            />
          );
        },
        Header: (x) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selectAll === 1}
              ref={(input) => {
                if (input) {
                  input.indeterminate = this.state.selectAll === 2;
                }
              }}
              onChange={() => this.toggleSelectAll()}
            />
          );
        },
        sortable: false,
        width: 45,
      },
      {
        id: "driver_name",
        Header: "Driver name",
        accessor: (d) => d.driver_name,
      },
      {
        id: "phonenumber",
        Header: "Phone number",
        accessor: (d) => d.phonenumber,
      },
      {
        id: "driver_type",
        Header: "Driver Type",
        accessor: (d) => d.driver_type,
      },
    ];
    const assignDrivOndutyCol = [
      {
        id: "checkbox",
        accessor: "",
        Cell: ({ original }) => {
          // console.log(original);
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selected[original.dunique_id] === true}
              onChange={() => this.toggleRow(original.dunique_id)}
            />
          );
        },
        Header: (x) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selectAll === 1}
              ref={(input) => {
                if (input) {
                  input.indeterminate = this.state.selectAll === 2;
                }
              }}
              onChange={() => this.toggleOnSelectAll()}
            />
          );
        },
        sortable: false,
        width: 45,
      },
      {
        id: "dname",
        Header: "Driver name",
        accessor: (d) => d.dname,
      },
      {
        id: "phonenumber",
        Header: "Phone number",
        accessor: (d) => d.phnno,
      },
      {
        id: "driver_type",
        Header: "Driver Type",
        accessor: (d) => d.driver_type,
      },
    ];

    const columns = [
      {
        Header: "Sl no",
        id: "serialno",
        style: {},
        Cell: (row) => {
          // console.log(row);
          return <div>{row.viewIndex + 1}</div>;
        },
      },
      {
        Header: "Trip ID",
        accessor: "trip_id",
      },
      {
        Header: "Source",
        accessor: "sname",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Destination",
        accessor: "dname",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Pickup time ",
        id: "pickuptime",
        style: { whiteSpace: "unset" },
        Cell: (row) => {
          let date;
          // console.log(row.original.request_status);
          if (
            row.original.request_status === "ended" ||
            row.original.request_status === "Requested"
          ) {
            date = row.original.pickuptime;
          } else {
            date = moment(new Date(row.original.pickuptime)).format(
              "DD-MM-YY [at] hh:mm:ss A"
            );
          }

          return <div>{date}</div>;
        },
      },
      {
        Header: "Ride type",
        accessor: "ridetype",
        id: "ridetype",
        Cell: (row) => {
          let data = capitalizeFirstLetter(row.original.ridetype);
          return <div>{data}</div>;
        },
      },
      {
        Header: "Vehicle type",
        accessor: "vehicle_type",
      },
      {
        Header: "Request status",
        accessor: "request_status",
        Cell: (row) => {
          if (row.original.request_status === "consumercancelled") {
            return <div style={{ color: "#FF0000" }}>Consumer canceled</div>;
          } else if (row.original.request_status === "drivercancelled") {
            return <div style={{ color: "#FF0000" }}>Canceled via panel</div>;
          } else if (
            row.original.request_status === "ended" ||
            row.original.request_status === "completed"
          ) {
            return <div style={{ color: "#59E67F" }}>Completed</div>;
          } else {
            return <div>{row.original.request_status}</div>;
          }
        },
      },
      {
        Header: "Rider",
        style: { whiteSpace: "unset" },
        Cell: (row) => {
          return (
            <div>
              {row.original.consumername + " - " + row.original.phonenumber}
            </div>
          );
        },
      },
      {
        id: "edit",
        Header: "Actions",
        Cell: (row) => {
          //  console.log(this);
          // console.log(row.original.request_status)
          return (
            <div className="d-flex flex-column">
              {row.original.request_status === "Requested" && (
                <Button
                  className="mx-2 my-2  table-button-style"
                  onClick={() => {
                    this.viewClick(row.original);
                  }}
                >
                  ASSIGN
                </Button>
              )}
              <Button
                className="mx-2 my-2  table-button-style"
                onClick={() => {
                  this.handleClick(row.original);
                }}
              >
                VIEW
              </Button>
            </div>
          );
        },
      },
    ];
    const { rideItems: items } = this.state;
    const dataToDisplay = this.state.searchInput.length
      ? this.state.filteredData
      : this.state.data;
    // console.log(dataToDisplay.length);

    if (this.state.changeDiv === false || this.state.changeDiv === undefined) {
      return <Col className="col-md-12">Loading ....</Col>;
    } else {
      // console.log(this.state.tripId);

      return (
        <TripDetails
          historyProps={this.props.propsData}
          onTitleChange={this.handleChange}
          tripId={this.state.tripId}
          onClickCallback={this.handlemodalclick}
        />
      );
    }
  }
}
TripView.default = {
  columns: PropTypes.array,
  assignDrivCol: PropTypes.array,
  changeDiv: PropTypes.bool,
  tripId: PropTypes.string,
  request_type: PropTypes.string,
  index: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
};
TripView.defaultProps = {
  assignDriv: [
    {
      label: "Nearby drivers",
    },
    {
      label: "On duty drivers",
    },
  ],
  compColumns: [
    {
      Header: "Sl no",
      id: "serialno",
      style: {},
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Trip ID",
      accessor: "trip_id",
    },
    {
      Header: "Source",
      accessor: "sname",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Destination",
      accessor: "dname",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Pickup time ",
      accessor: "pickuptime",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Ride type",
      accessor: "ridetype",
    },
    {
      Header: "Vehicle type",
      accessor: "vehicle_type",
    },
    {
      Header: "Rider",
      style: { whiteSpace: "unset" },
      Cell: (row) => {
        return (
          <div>
            {row.original.consumername + " - " + row.original.phonenumber}
          </div>
        );
      },
    },
    {
      id: "edit",
      Header: "Action",
      Cell: ({ value }) => {
        return <Button className="">View</Button>;
      },
    },
  ],
  ongoingColumns: [
    {
      Header: "Sl no",
      id: "serialno",
      style: {},
      Cell: (row) => {
        return <div>{row.viewndex + 1}</div>;
      },
    },
    {
      Header: "Trip ID",
      accessor: "trip_id",
    },
    {
      Header: "Source",
      accessor: "sname",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Destination",
      accessor: "dname",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Pickup time ",
      accessor: "pickuptime",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Ride type",
      accessor: "ridetype",
    },
    {
      Header: "Vehicle type",
      accessor: "vehicle_type",
    },
    {
      Header: "Rider",
      style: { whiteSpace: "unset" },
      Cell: (row) => {
        return (
          <div>
            {row.original.consumername + " - " + row.original.phonenumber}
          </div>
        );
      },
    },
    {
      id: "edit",
      Header: "Action",
      Cell: ({ value }) => {
        return <Button className="">View</Button>;
      },
    },
  ],
  changeDiv: false,
  tripId: "",
  index: 0,
  tripDate: new Date(),
};
export default TripView;
