import React from "react";
import GoogleMapReact from "google-map-react";
import * as stringsData from "../constants/Strings.json";

const SourceComponent = () => (
  <img
    src="https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/source_new.svg"
    height="auto"
    width="25"
  ></img>
);

const DestinationComponent = () => (
  <img
    src="https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/destination_new.svg"
    height="auto"
    width="25"
  ></img>
);

const api_key = stringsData.apiKey;
export default function Maps(props) {
  console.log(props);
  return (
    <div
      className="container-fluid px-0"
      style={{ height: "400px", width: "100%" }}
    >
      <GoogleMapReact
        // defaultCenter={{ lat: 12.9716, lng: 77.5946 }}
        bootstrapURLKeys={{ key: api_key }}
        zoom={props.zoom}
        center={{ lat: props.lat, lng: props.lng }}
        // center={{
        //   lat: 12.9716,
        //   lng: 77.5946,
        // }}
        options={{
          styles: [
            {
              featureType: "all",
              elementType: "all",
              stylers: [
                {
                  hue: "#008eff",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "all",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "all",
              stylers: [
                {
                  saturation: "0",
                },
                {
                  lightness: "0",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "all",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "all",
              stylers: [
                {
                  visibility: "simplified",
                },
                {
                  saturation: "-60",
                },
                {
                  lightness: "-20",
                },
              ],
            },
          ],
        }}
      >
        <SourceComponent
          lat={props.sourceLatLng[1]}
          lng={props.sourceLatLng[0]}
        />
        <DestinationComponent
          lat={props.destLatLng[1]}
          lng={props.destLatLng[0]}
        />
      </GoogleMapReact>
    </div>
  );
}
