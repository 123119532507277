export default function() {
  return [
    {
      label: "Unassigned rides",
      attrs: { active: "active" },
    },
    {
      label: "Assigned rides",
    },
    {
      label: "Ongoing rides",
    },
    {
      label: "Completed rides",
    },
    {
      label: "Cancelled rides",
    },
  ];
}
