import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardBody } from "shards-react";
import { Bar, Pie } from "react-chartjs-2";
import axios from "axios";
import * as config from "../../constants/constants.json";
var monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
var monthValues = [];
class RideAnalysis extends React.Component {
  constructor(props) {
    super(props);
    let region = this.props.region;
    this.state = {
      monthNames: [],
      monthValues: [],
      region: region,
    };
    this.callApiToGetAnalytics = this.callApiToGetAnalytics.bind(this);
    this.chartReference = React.createRef();
  }

  callApiToGetAnalytics = async () => {
    let data = {
      region: sessionStorage.getItem("operating_region"),
    };
    try {
      const response = await axios({
        method: "POST",
        url: config.url + "htmlcrm/getAggeregateAnalytics",
        data: data,
      });
      console.log(response);
      let dataArr = response.data.results.data;
      if (dataArr.length > 0) {
        var sortedArr = dataArr.sort(function(a, b) {
          return a.month - b.month;
        });
        // console.log(sortedArr);
        if (monthNames.length > sortedArr.length) {
          monthNames.splice(dataArr.length, monthNames.length - 1);
          // console.log(dataArr.length);
          // console.log(monthNames);
        }

        for (var a = 0; a <= sortedArr.length - 1; a++) {
          monthValues.push(sortedArr[a].ridesCount);
        }
        // console.log(monthNames);
        this.setState({
          monthNames: monthNames,
          monthValues: monthValues,
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };
  callApiToGetAnalytics_old() {
    fetch(config.url + "htmlcrm/getAggeregateAnalytics", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: "",
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        let dataArr = result.results.data;
        if (dataArr.length > 0) {
          var sortedArr = dataArr.sort(function(a, b) {
            return a.month - b.month;
          });
          // console.log(sortedArr);
          if (monthNames.length > sortedArr.length) {
            monthNames.splice(dataArr.length, monthNames.length - 1);
            // console.log(dataArr.length);
            // console.log(monthNames);
          }

          for (var a = 0; a <= sortedArr.length - 1; a++) {
            monthValues.push(sortedArr[a].ridesCount);
          }
          // console.log(monthNames);
          this.setState({
            monthNames: monthNames,
            monthValues: monthValues,
          });
        }
      });
  }

  componentDidMount() {
    this.callApiToGetAnalytics();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.region !== this.props.region) {
      console.log("Here");
      this.setState(
        {
          region: this.props.region,
        },
        () => {
          this.callApiToGetAnalytics();
        }
      );
    }
  }

  render() {
    const { title } = this.props;
    let options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };
    let data = {
      labels: this.state.monthNames,
      datasets: [
        {
          label: "# of rides",
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
          data: this.state.monthValues,
        },
      ],
    };
    // console.log(this.state);
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="pt-0">
          {/* <p className="py-5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet massa in metus pretium luctus. Suspendisse potenti. Pellentesque quis pulvinar ante. In fermentum ex accumsan egestas cursus. Vivamus id leo finibus, maximus metus a, semper mauris. Sed odio libero, scelerisque sit amet neque vitae, ornare tincidunt velit. Curabitur id pretium nunc. Suspendisse vel fringilla nunc, a malesuada sapien. Vestibulum dapibus elit ac porta bibendum. Ut ultricies ultricies maximus. Vestibulum consectetur pellentesque metus nec interdum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Fusce et consequat massa. Sed tincidunt laoreet mauris nec commodo. Maecenas et congue sapien.
          </p> */}
          <Bar
            ref={this.chartReference}
            data={data}
            options={options}
            legend={{ display: true, position: "top" }}
          />
        </CardBody>
      </Card>
    );
  }
}

RideAnalysis.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
};

RideAnalysis.defaultProps = {
  title: "Ride analysis",
};

export default RideAnalysis;
