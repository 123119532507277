import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card, Modal } from "react-bootstrap";
import InputLabel from "@material-ui/core/InputLabel";
import { Breadcrumb, BreadcrumbItem, Button as Buttons } from "shards-react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
// import moment from "moment";
import _ from "lodash";
import Radio from "@material-ui/core/Radio";
// import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { FormGroup, FormLabel, TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import PageTitle from "./../components/common/PageTitle";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import Paper from "@material-ui/core/Paper";
// import TextField from "@material-ui/core/TextField";
// import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { green, purple, grey, red } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Remove";
// import AddButton from "@material-ui/icons/Add";
// import DeleteButton from "@material-ui/icons/Delete"
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
// import InputBase from "@material-ui/core/InputBase";
// import Chip from "@material-ui/core/Chip";
// import ChipInput from "material-ui-chip-input";
// import ReactSnackBar from "react-js-snackbar";
// import Autosuggest from "react-autosuggest";
// import match from "autosuggest-highlight/match";
// import parse from "autosuggest-highlight/parse";
// import Paper from "@material-ui/core/Paper";
// import MenuItem from "@material-ui/core/MenuItem";
import ReactSnackBar from "react-js-snackbar";

import * as config from "../constants/constants.json";
import * as stringsData from "../constants/stringsConstant.json";

const useStyles = makeStyles((theme) => ({
  chipInputRoot: {},
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: "100%",
  },
  tabs: {
    "& .Mui-selected": {
      fontSize: "1.2em",
      fontWeight: 700,
    },
  },
  tabsRoot: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(17),
    textAlign: "left",
    "&:focus": {
      opacity: 1,
    },
    expandedPanel: {
      backgroundColor: theme.palette.primary.main,
    },
  },

  tableRoot: {
    marginTop: theme.spacing(3),
    overflowX: "auto",
    "&-webkit-scrollbar": {
      display: "none",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  headingTwo: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "66.66%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {
    overflowX: "auto",
    border: 0,
    // backgroundColor: "#FAFAFA",
  },
  tabHeader: {
    borderBottom: "none",
    fontWeight: 600,
  },
  selectTableCell: {},
  tableCell: {
    minWidth: 135,

    borderBottom: "none",
  },
  slCell: {
    minWidth: 80,

    borderBottom: "none",
  },
  input: {
    maxWidth: 150,
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  formLabel: {
    fontWeight: 600,

    color: "#000",
    "&.Mui-focused": {
      color: "#000",
    },
  },
  checkbox: {
    root: {
      "&$checked": {
        color: "black",
      },
    },
  },
  typo: {
    fontSize: "0.8rem",
  },
  selectRoot: {
    "&:before": {
      borderColor: "black",
    },
    "&:focus": {
      borderColor: "black",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderColor: "black",
    },
  },
}));

const RadioButton = withStyles({
  root: {
    color: "black",
    "&$checked": {
      color: "black",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function FareSettings(props) {
  const classes = useStyles();
  const [valuePath, setValuePath] = useState("ride_now");
  const [rideLater, setRideLaterData] = useState(0);
  const [rideNow, setRideNowData] = useState(0);
  const [intervalData, setIntervalData] = useState([]);
  const [editChange, setEditChange] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [Show, setShow] = React.useState(false);
  const [Showing, setShowing] = React.useState(false);

  const show = () => {
    if (Showing) return;
    setShow(true);
    setShowing(true);
    // this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      // this.setState({ Show: false, Showing: false });
      setShow(false);
      setShowing(false);
    }, 2500);
  };

  const goBackAccounts = () => {
    props.history.push("/configuration");
  };

  const saveData = (event) => {
    if (event) {
      event.preventDefault();
    }
    updateBookingTime();
  };

  const updateBookingTime = () => {
    let dataToSend = {
      booking_type: valuePath,
      acceptance_window: valuePath === "ride_now" ? rideNow : rideLater,
    };
    axios
      .post(config.url + "htmlcrm/updateBookingTimeConfig", dataToSend)
      .then((response) => {
        if (response.data.message === "successfull") {
          setEditChange(false);
          getDataFromServer();
          setMessage("Time interval updated to server");
          setSuccess(true);
          show();
        } else {
          setEditChange(true);
          setMessage("Failed to update time interval to server");
          setSuccess(false);
          show();
        }
      })
      .catch();
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const getDataFromServer = () => {
    axios
      .get(config.url + "htmlcrm/getBookingTimeConfig")
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "successfull") {
          setIntervalData(response.data.booking_config.time_intervals);
          setRideNowData(
            response.data.booking_config.ride_now.acceptance_window
          );
          setRideLaterData(
            response.data.booking_config.ride_later.acceptance_window
          );
        } else {
          setIntervalData([]);
          setRideNowData([]);
          setRideLaterData([]);
        }
      })
      .catch();
  };

  useEffect(() => {
    getDataFromServer();
  }, [valuePath]);

  const callSetFunction = (e, data) => {
    if (data === "ride_now") {
      setRideNowData(e.target.value);
    } else {
      setRideLaterData(e.target.value);
    }
  };

  const toggleSave = () => {
    setEditChange(!editChange);
    // onToggleEditMode();
  };

  const toggleSaveCancel = () => {
    setEditChange(!editChange);
    // callFareRetrieveAPI();
    // onToggleEditMode();
  };
  const createNew = () => {
    console.log("hello");
  };
  // console.log(intervalData);
  return (
    <Container fluid className="main-content-container">
      <Row className="px-1 px-md-4 py-md-3">
        <Col className="col-12 col-md-12">
          <Row className="w-100">
            <Col className="col-12">
              <Breadcrumb>
                <BreadcrumbItem>
                  <a href="#" onClick={goBackAccounts}>
                    Fare settings /
                  </a>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col className="col-12 mt-md-1">
              <PageTitle
                title="Fare configuration"
                className="text-sm-left mb-3 pl-0 custom-margin"
              />
            </Col>
          </Row>
          <Row className="px-1 px-md-4 py-md-3">
            <Col className="col-12 col-md-12">
              <form onSubmit={saveData} className="px-0">
                {/* <Row className="py-3">
                  <Col className="col-12 col-md-8 mt-3">
                    <FormGroup>
                      <label className="px-3">Select the ride type</label>
                      <FormControl component="fieldset" className="mt-md-3">
                        <RadioGroup
                          row
                          aria-label="ride"
                          name="ride"
                          value={valuePath}
                          onChange={(e) => setValuePath(e.target.value)}
                        >
                          <FormControlLabel
                            disabled={editChange === true ? false : true}
                            className="px-4"
                            control={<RadioButton />}
                            label="Ride now"
                            value="ride_now"
                          />
                          <FormControlLabel
                            disabled={editChange === true ? false : true}
                            className="px-4"
                            value="ride_later"
                            control={<RadioButton />}
                            label="Scheduled"
                          />
                        </RadioGroup>
                      </FormControl>
                    </FormGroup>
                  </Col>
                  <Col className="col-12 col-md-12 d-flex justify-content-end my-auto">
                    {editChange === false && (
                      <Button
                        startIcon={<EditIcon />}
                        onClick={toggleSave}
                        style={{ fontSize: "1.1em", fontWeight: "700" }}
                      >
                        EDIT SETTINGS
                      </Button>
                    )}
                    {editChange === true && (
                      <div className="d-flex justify-content-center">
                        <Button
                          className="mx-2 my-2"
                          type="submit"
                          startIcon={<Save />}
                          style={{ fontSize: "1.1em", fontWeight: "700" }}
                        >
                          SAVE
                        </Button>
                        <Button
                          className="mx-2 my-2"
                          startIcon={<Close />}
                          onClick={toggleSaveCancel}
                          style={{ fontSize: "1.1em", fontWeight: "700" }}
                        >
                          CANCEL
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row> */}
                <Row className="mb-5">
                  <Col className="col-12 col-md-6 mt-3">
                    {/* <FormGroup> */}
                    {/* <label className="px-3">
                        Choose default availability (in minutes)
                      </label> */}
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <FormLabel id="demo-simple-select-outlined-label">
                        Choose default availability (in minutes)
                      </FormLabel>
                      <FormGroup className="d-flex flex-row">
                        <Select
                          labelId="vehicle-type-select"
                          disabled={editChange === true ? false : true}
                          id="vehicle-type-select"
                          className={classes.selectRoot}
                          style={{
                            fontWeight: 700,
                            minWidth: 250,
                          }}
                          value={valuePath === "ride_now" ? rideNow : rideLater}
                          onChange={(e) => callSetFunction(e, valuePath)}
                        >
                          {intervalData.map((data, index) => (
                            <MenuItem key={index} value={data}>
                              {data}
                            </MenuItem>
                          ))}
                          {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem> */}
                          {/* <MenuItem value="airport">Airport</MenuItem> */}
                          {/* <MenuItem value="rental">Rental</MenuItem>
                      <MenuItem value="outstation">Outstation</MenuItem> */}
                        </Select>
                        {editChange === false && (
                          <Button
                            startIcon={<EditIcon />}
                            onClick={toggleSave}
                            style={{ fontSize: "1.1em", fontWeight: "700" }}
                            className="ml-md-4"
                          ></Button>
                        )}
                        {editChange === true && (
                          <React.Fragment>
                            <Button
                              className="ml-md-4"
                              type="submit"
                              startIcon={<Save />}
                              style={{ fontSize: "1.1em", fontWeight: "700" }}
                            ></Button>
                            <Button
                              className=""
                              startIcon={<Close />}
                              onClick={toggleSaveCancel}
                              style={{ fontSize: "1.1em", fontWeight: "700" }}
                            ></Button>
                          </React.Fragment>
                        )}
                      </FormGroup>
                    </FormControl>
                    {/* </FormGroup> */}
                  </Col>
                </Row>
              </form>
            </Col>
            <Col className="col-12 col-md-12">
              <form onSubmit={saveData} className="px-0">
                <Row className="mb-5">
                  <Col className="col-12 col-md-3 mt-3">
                    {/* <FormGroup> */}
                    {/* <label className="px-3">
                        Choose default availability (in minutes)
                      </label> */}
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <FormLabel id="demo-simple-select-outlined-label">
                        Choose default availability (in minutes)
                      </FormLabel>
                      <FormGroup className="d-flex flex-row">
                        <Select
                          labelId="vehicle-type-select"
                          disabled={editChange === true ? false : true}
                          id="vehicle-type-select"
                          className={classes.selectRoot}
                          style={{
                            fontWeight: 700,
                            minWidth: 250,
                          }}
                          value={valuePath === "ride_now" ? rideNow : rideLater}
                          onChange={(e) => callSetFunction(e, valuePath)}
                        >
                          {intervalData.map((data, index) => (
                            <MenuItem key={index} value={data}>
                              {data}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormGroup>
                    </FormControl>
                    {/* </FormGroup> */}
                  </Col>
                  <Col className="col-12 col-md-5 mt-3">
                    {/* <FormGroup> */}
                    {/* <label className="px-3">
                        Choose default availability (in minutes)
                      </label> */}
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <FormLabel id="demo-simple-select-outlined-label">
                        Additional fare to be added (in rupees)
                      </FormLabel>
                      <FormGroup className="d-flex flex-row">
                        <TextField
                          className={classes.selectRoot}
                          variant="outlined"
                          style={{
                            fontWeight: 700,
                            minWidth: 250,
                          }}
                          disabled={editChange === true ? false : true}
                        />

                        {editChange === false && (
                          <Button
                            startIcon={<EditIcon />}
                            onClick={toggleSave}
                            style={{ fontSize: "1.1em", fontWeight: "700" }}
                            className="ml-md-4"
                          ></Button>
                        )}
                        {editChange === true && (
                          <React.Fragment>
                            <Button
                              className="ml-md-4"
                              type="submit"
                              startIcon={<Save />}
                              style={{ fontSize: "1.1em", fontWeight: "700" }}
                            ></Button>
                            <Button
                              className=""
                              startIcon={<Close />}
                              onClick={toggleSaveCancel}
                              style={{ fontSize: "1.1em", fontWeight: "700" }}
                            ></Button>
                          </React.Fragment>
                        )}
                      </FormGroup>
                    </FormControl>
                    {/* </FormGroup> */}
                  </Col>
                  <Col className="col-12 col-md-6 mt-3">
                    <p
                      style={{ fontWeight: 700, cursor: "pointer" }}
                      className="px-2"
                      onClick={editChange ? createNew : ""}
                    >
                      + ADD NEW CONFIGURATION
                    </p>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Col>
      </Row>
      {success === false && (
        <ReactSnackBar
          Icon={
            <i
              className="far fa-times-circle fa-lg"
              style={{ color: "#FF0000" }}
            />
          }
          Show={Show}
        >
          {message}
        </ReactSnackBar>
      )}
      {success === true && (
        <ReactSnackBar
          Icon={
            <span>
              <i
                className="far fa-check-circle fa-lg"
                style={{ color: "#26FF58" }}
              />
            </span>
          }
          Show={Show}
        >
          {message}
        </ReactSnackBar>
      )}
    </Container>
  );
}
