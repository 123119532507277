// eslint-disable-next-line
import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, FormSelect, FormGroup } from "shards-react";
import axios from "axios";
// import { TweenMax, Power3 } from "gsap";

import StatBox from "../components/common/StatBox";
import PageTitle from "./../components/common/PageTitle";
import TopDrivers from "../components/common/TopDrivers";
import RideAnalysis from "../components/dataviews/RideAnalysis";
import * as config from "../constants/constants.json";
import * as stringConstants from "../constants/stringsConstant.json";
// import DataViz from "../components/dataviews/DataViz";

// import { Tween, Timeline } from "react-gsap";
// const Rides = ({ smallStats }) => (
//   );

class Rides extends React.Component {
  constructor(props) {
    super(props);
    let region = sessionStorage.getItem("operating_region");
    this.state = {
      outstationCount: 0,
      rentalCount: 0,
      airportCount: 0,
      urbanCount: 0,
      region: region,
    };
    // this.callApiToGetData = this.callApiToGetData.bind(this)
    this.callApiToGetOutstationRentalData = this.callApiToGetOutstationRentalData.bind(
      this
    );
    this.callApiToGetAirportUrbanData = this.callApiToGetAirportUrbanData.bind(
      this
    );

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(event) {
    this.setState(
      {
        region: event.target.value,
      },
      () => {
        this.callApiToGetOutstationRentalData();
        this.callApiToGetAirportUrbanData();
      }
    );
  }
  callApiToGetOutstationRentalData_old() {
    fetch(config.url + "htmlcrm/getRentalOutstationRides", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: "",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        let data = result.results;
        if (data.length > 0) {
          for (let i = 0; i <= data.length - 1; i++) {
            if (data[i].trip_type === "outstation") {
              console.log(data[i].rideCount);
              this.setState({
                outstationCount: data[i].rideCount,
              });
            } else if (data[i].trip_type === "rental") {
              console.log(data[i].rideCount);
              this.setState({
                rentalCount: data[i].rideCount,
              });
            }
          }
        }
      });
  }
  callApiToGetAirportUrbanData_old() {
    fetch(config.url + "htmlcrm/getUrbanAirportRides", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: "",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        let data = result.results;
        if (data.length > 0) {
          for (let i = 0; i <= data.length - 1; i++) {
            if (data[i].trip_type === "airport") {
              console.log(data[i].rideCount);
              this.setState({
                airportCount: data[i].rideCount,
              });
            } else if (data[i].trip_type === "urban") {
              console.log(data[i].rideCount);
              this.setState({
                urbanCount: data[i].rideCount,
              });
            }
          }
        }
      });
  }

  //config.url + "htmlcrm/getUrbanAirportRides",
  callApiToGetAirportUrbanData = async () => {
    let datas = {
      region: this.state.region,
    };
    console.log(datas);
    try {
      const response = await axios({
        method: "POST",
        url: config.url + "htmlcrm/getUrbanAirportRides",
        data: datas,
      });
      console.log(response);
      let data = response.data.results;
      if (data.length > 0) {
        for (let i = 0; i <= data.length - 1; i++) {
          if (data[i].trip_type === "airport") {
            // console.log(data[i].rideCount);
            this.setState({
              airportCount: data[i].rideCount,
            });
          } else if (data[i].trip_type === "urban") {
            // console.log(data[i].rideCount);
            this.setState({
              urbanCount: data[i].rideCount,
            });
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  callApiToGetOutstationRentalData = async () => {
    let datas = {
      region: this.state.region,
    };
    // console.log(datas);
    try {
      const response = await axios({
        method: "POST",
        url: config.url + "htmlcrm/getRentalOutstationRides",
        data: datas,
      });
      console.log(response);
      let data = response.data.results;
      if (data.length > 0) {
        for (let i = 0; i <= data.length - 1; i++) {
          if (data[i].trip_type === "outstation") {
            // console.log(data[i].rideCount);
            this.setState({
              outstationCount: data[i].rideCount,
            });
          } else if (data[i].trip_type === "rental") {
            // console.log(data[i].rideCount);
            this.setState({
              rentalCount: data[i].rideCount,
            });
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  // Render data when page opens
  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === null ||
      window.sessionStorage.getItem("user_id") === undefined
    ) {
      window.location.assign("/");
    } else {
      this.callApiToGetOutstationRentalData();
      this.callApiToGetAirportUrbanData();
    }
  }

  render() {
    // let divItem = useRef("hello");
    // console.log(divItem);
    return (
      <Container fluid className="main-content-container px-4 mb-5">
        <Row noGutters className="page-header py-4 px-3">
          <PageTitle
            title="Dashboard"
            subtitle="Rides"
            className="text-sm-left mb-3"
          />
          <Col className="col-md-4 d-none d-md-block"></Col>
          {sessionStorage.getItem("roletype") === stringConstants.subLead && (
            <Col className="col-12 col-md-4 w-100 d-flex justify-content-end">
              <FormGroup>
                <label>Filter based on region</label>
                <FormSelect
                  value={this.state.region}
                  onChange={this.handleSelectChange}
                  style={{ fontWeight: 700 }}
                >
                  <option value="all">All</option>
                  <option value="Bangalore">Bangalore</option>
                  <option value="Hyderabad">Hyderabad</option>
                </FormSelect>
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row className="px-3">
          {this.props.smallStats.map((stats, idx) => (
            <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
              {/* {console.log(smallStats)} */}
              {/* {console.log(idx)}
      {console.log(stats)} */}
              <StatBox
                id={`small-stats-${idx}`}
                variation="1"
                label={stats.label}
                value={this.state[stats.apiId]}
              />
            </Col>
          ))}
        </Row>
        <Row className="px-3">
          <Col lg="8" md="12" sm="12" className="mb-4">
            <RideAnalysis region={this.state.region} />
          </Col>
          <Col lg="4" md="12" sm="12" className="mb-4">
            <TopDrivers region={this.state.region} />
          </Col>
        </Row>
      </Container>
    );
  }
}

// function Rides(props) {
//   let divItem = useRef(null);
//   let rideAnalysis = useRef(null);
//   let topBoxes = useRef(null);
//   // let ref = React.createRef();
//   console.log(divItem);
//   // this.state = {
//   //       outstationCount: 0,
//   //       rentalCount: 0,
//   //       airportCount: 0,
//   //       urbanCount: 0,
//   //     };
//   const [outstationCount, setOutCount] = useState(0);
//   const [rentalCount, setRentalCount] = useState(0);
//   const [airportCount, setAirportCount] = useState(0);
//   const [urbanCount, setUrbanCount] = useState(0);

//   const getState = (data) => {
//     if (data === "outstationCount") {
//       return outstationCount;
//     } else if (data === "airportCount") {
//       return airportCount;
//     } else if (data === "rentalCount") {
//       return rentalCount;
//     } else if (data === "urbanCount") {
//       return urbanCount;
//     }
//   };
//   // const [smallStats, setSmallStats] = useState([
//   //   {
//   //     label: "Airport rides",
//   //     value: 0,
//   //     apiId: "airportCount",
//   //     attrs: { md: "6", sm: "6" },
//   //   },
//   //   {
//   //     label: "Rental rides",
//   //     value: 0,
//   //     apiId: "rentalCount",
//   //     attrs: { md: "6", sm: "6" },
//   //   },
//   //   {
//   //     label: "Outstation rides",
//   //     value: 0,
//   //     apiId: "outstationCount",
//   //     attrs: { md: "6", sm: "6" },
//   //   },
//   //   {
//   //     label: "Urban rides",
//   //     value: 0,
//   //     attrs: { md: "6", sm: "6" },
//   //     apiId: "urbanCount",
//   //   },
//   // ]);
//   useEffect(() => {
//     console.log(divItem);
//     TweenMax.to([topBoxes, rideAnalysis, divItem], 0.8, {
//       opacity: 1,
//       y: -20,
//       ease: Power3.Out,
//       stagger: {
//         amount: 1,
//       },
//     });
//   }, [topBoxes, divItem, rideAnalysis]);
//   // useEffect(() => {
//   //   console.log(divItem);
//   //   TweenMax.to(divItem, 4, {
//   //     opacity: 1,
//   //     y: -20,
//   //     ease: Power3.easeOut,
//   //   });
//   // }, []);

//   // useEffect(() => {
//   //   TweenMax.to(rideAnalysis, 4, {
//   //     opacity: 1,
//   //     y: -20,
//   //     ease: Power3.easeOut,
//   //   });
//   // }, []);

//   // useEffect(() => {
//   //   fetch(config.url + "htmlcrm/getUrbanAirportRides", {
//   //     method: "POST",
//   //     headers: {
//   //       "Content-Type": "application/json",
//   //       // 'Content-Type': 'application/x-www-form-urlencoded',
//   //     },
//   //     body: "",
//   //   })
//   //     .then((res) => res.json())
//   //     .then((result) => {
//   //       console.log(result);
//   //       let data = result.results;
//   //       if (data.length > 0) {
//   //         for (let i = 0; i <= data.length - 1; i++) {
//   //           if (data[i].trip_type === "airport") {
//   //             console.log(data[i].rideCount);
//   //             // this.setState({
//   //             //   airportCount: data[i].rideCount,
//   //             // });
//   //             setAirportCount(data[i].rideCount);
//   //           } else if (data[i].trip_type === "urban") {
//   //             console.log(data[i].rideCount);
//   //             // this.setState({
//   //             //   urbanCount: data[i].rideCount,
//   //             // });
//   //             setUrbanCount(data[i].rideCount);
//   //           }
//   //         }
//   //       }
//   //     });
//   // });
//   // useEffect(() => {
//   //   fetch(config.url + "htmlcrm/getRentalOutstationRides", {
//   //     method: "POST",
//   //     headers: {
//   //       "Content-Type": "application/json",
//   //       // 'Content-Type': 'application/x-www-form-urlencoded',
//   //     },
//   //     body: "",
//   //   })
//   //     .then((res) => res.json())
//   //     .then((result) => {
//   //       console.log(result);
//   //       let data = result.results;
//   //       if (data.length > 0) {
//   //         for (let i = 0; i <= data.length - 1; i++) {
//   //           if (data[i].trip_type === "outstation") {
//   //             console.log(data[i].rideCount);
//   //             setOutCount(data[i].rideCount);
//   //             // this.setState({
//   //             //   outstationCount: data[i].rideCount,
//   //             // });
//   //           } else if (data[i].trip_type === "rental") {
//   //             console.log(data[i].rideCount);
//   //             setRentalCount(data[i].rideCount);
//   //             // this.setState({
//   //             //   rentalCount: data[i].rideCount,
//   //             // });
//   //           }
//   //         }
//   //       }
//   //     });
//   // });
//   console.log(props);
//   return (
//     <Container fluid className="main-content-container px-4 mb-5">
//       <Row noGutters className="page-header py-4 px-3">
//         <PageTitle
//           title="Dashboard"
//           subtitle="Rides"
//           className="text-sm-left mb-3"
//         />
//       </Row>
//       <div
//         className="px-3 row"
//         ref={(eel) => {
//           topBoxes = eel;
//         }}
//       >
//         {props.smallStats.map((stats, idx) => (
//           <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
//             {/* {console.log(smallStats)} */}
//             {/* {console.log(idx)}

//       {console.log(stats)} */}
//             {console.log(stats)}
//             <StatBox
//               id={`small-stats-${idx}`}
//               variation="1"
//               label={stats.label}
//               value={[getState(stats.apiId)]}
//             />
//           </Col>
//         ))}
//       </div>
//       <Row className="px-3">
//         <Col lg="8" md="12" sm="12" className="mb-4">
//           <div
//             className="mt-4"
//             id="top-drivers"
//             ref={(el) => {
//               rideAnalysis = el;
//             }}
//           >
//             <RideAnalysis />
//           </div>
//         </Col>
//         <Col lg="4" md="12" sm="12" className="mb-4">
//           <div
//             className="mt-4"
//             id="top-drivers"
//             ref={(eel) => {
//               divItem = eel;
//             }}
//           >
//             <TopDrivers />
//           </div>
//         </Col>
//       </Row>
//       <Row>
//         <Col lg="8" md="12" sm="12">
//           {/* <DataViz /> */}
//         </Col>
//       </Row>
//     </Container>
//   );
// }

Rides.propTypes = {
  smallStats: PropTypes.array,
};

Rides.defaultProps = {
  smallStats: [
    {
      label: "Airport rides",
      value: "0",
      apiId: "airportCount",
      attrs: { md: "6", sm: "6" },
    },
    {
      label: "Rental rides",
      value: "0",
      apiId: "rentalCount",
      attrs: { md: "6", sm: "6" },
    },
    {
      label: "Outstation rides",
      value: "0",
      apiId: "outstationCount",
      attrs: { md: "6", sm: "6" },
    },
    {
      label: "Urban rides",
      value: "0",
      attrs: { md: "6", sm: "6" },
      apiId: "urbanCount",
    },
  ],
};

export default Rides;
