import React, { useMemo, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Row, Col } from "shards-react";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  height: "180px",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

// const thumb = {
//   border: "1px solid #eaeaea",
//   width: "100%",
//   height: "auto",
//   padding: 4
// };
const thumb = {
  display: "flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: "100%",
  height: "100%",
  padding: 4,
  boxSizing: "border-box",
};
const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  width: "100%",
  height: "100%",
};
function callbacker(data, props) {
  props.onDropHandler(data, props.name);
}

function DragAndDropEdit(props) {
  const [files, setFiles] = useState([]);
  const [replace, setReplace] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      // setFiles(acceptedFiles.map(file => Object.assign(file, {
      //     preview: URL.createObjectURL(file)
      // })));
      acceptedFiles.map((file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          //store result into your state array.
          if (props.onDropHandler) {
            callbacker(event.target.result, props);
          }

          // console.log(event.target.result);
        };
        reader.readAsDataURL(file);
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        setReplace(true);
      });
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        {/* <img style={{ backgroundImage: `url(${file.preview})` }} /> */}
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const style = useMemo(() => ({
    ...baseStyle,
  }));
  return (
    <section className="container">
      {/* {console.log(files)} */}
      <div {...getRootProps({ className: "dropzone", style })}>
        <input {...getInputProps()} />
        {/* {console.log({ replace })} */}
        {replace === false && (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
        {replace === true && thumbs}
      </div>
    </section>
  );
}

// function DragAndDrop(props) {
//     const {
//         getRootProps,
//         getInputProps,
//         isDragActive,
//         isDragAccept,
//         isDragReject
//     } = useDropzone({ accept: 'image/*' });

//     const style = useMemo(() => ({
//         ...baseStyle,
//         ...(isDragActive ? activeStyle : {}),
//         ...(isDragAccept ? acceptStyle : {}),
//         ...(isDragReject ? rejectStyle : {})
//     }), [
//         isDragActive,
//         isDragReject
//     ]);

//     return (

//         <div>
//             <label>{props.label}</label>
//             <div className="container">
//                 <div {...getRootProps({ style })}>
//                     <input {...getInputProps()} />
//                     <p>Drag 'n' drop some files here, or click to select files</p>
//                 </div>
//             </div>
//         </div>

//     );
// }

export default DragAndDropEdit;
