import React from "react";
import {
  Container,
  Row,
  Col,
  Tooltip,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  FormSelect,
} from "shards-react";
import DatePicker from "react-date-picker";
import querystring from "query-string";
import TestNearby from "./../components/utilities/mapHook";
// import TestNearby from "./../components/utilities/TestNearby";
import * as config from "../constants/constants.json";
const oneDay = 24 * 60 * 60 * 1000;
class NearbyDrivers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coOrdinates: [],
      userData: [],
      nearbyDrivers: [],
      daySelected: "Today",
      tripDate: new Date(),
      region: querystring.parse(this.props.location.search).operating_region,
    };
    this.handleData = this.handleData.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.ridesCountSNextDate = this.ridesCountSNextDate.bind(this);
    this.ridesCountSPrevDate = this.ridesCountSPrevDate.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(event) {
    this.setState({
      region: event.target.value,
    });
  }

  handleTime(data) {
    this.setState({
      tripDate: data,
    });
  }
  handleData(data) {
    console.log(data);
    this.setState({
      nearbyDrivers: data,
    });
  }

  ridesCountSNextDate() {
    let date = this.state.tripDate;
    // console.log(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    nextDate.setHours(0, 0, 0, 0);
    console.log(nextDate);
    if (nextDate) {
      this.setState({
        tripDate: nextDate,
      });
    }
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));
    console.log(diffDays);
    if (nextDate.getTime() >= currentDate.getTime()) {
      if (nextDate.getTime() == currentDate.getTime()) {
        this.setState(
          {
            daySelected: "Today",
          },
          () => {
            //this.handlerefreshclick();
          }
        );
        // getAllRidesCount();
      } else {
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Tomorrow",
            },
            () => {
              //this.handlerefreshclick();
            }
          );
          // $("#ridesCountSDateName").text("Tomorrow");
          // getAllRidesCount();
        } else {
          diffDays = diffDays - 1;
          console.log(diffDays);
          if (diffDays == 1) {
            this.setState(
              {
                daySelected: "Day after tomorrow",
              },
              () => {
                //this.handlerefreshclick();
              }
            );
            // $("#ridesCountSDateName").text("Day after tomorrow");
          } else {
            this.setState(
              {
                daySelected: `${diffDays} days after tomorrow`,
              },
              () => {
                //this.handlerefreshclick();
              }
            );
            // $("#ridesCountSDateName").text(diffDays + " days after tomorrow");
          }
          // getAllRidesCount();
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));

      if (diffDays == 1) {
        this.setState(
          {
            daySelected: "Yesterday",
          },
          () => {
            //this.handlerefreshclick();
          }
        );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Day before yesterday",
            },
            () => {
              //this.handlerefreshclick();
            }
          );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          this.setState(
            {
              daySelected: `${diffDays} days from yesterday`,
            },
            () => {
              //this.handlerefreshclick();
            }
          );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  }

  ridesCountSPrevDate() {
    let date = this.state.tripDate;
    console.log(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() - 1);
    nextDate.setHours(0, 0, 0, 0);
    console.log(nextDate);
    if (nextDate) {
      this.setState({
        tripDate: nextDate,
      });
    }
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));
    console.log(diffDays);
    if (nextDate.getTime() >= currentDate.getTime()) {
      if (nextDate.getTime() == currentDate.getTime()) {
        this.setState(
          {
            daySelected: "Today",
          },
          () => {
            // //this.handlerefreshclick();
          }
        );
        // getAllRidesCount();
      } else {
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Tomorrow",
            },
            () => {
              // //this.handlerefreshclick();
            }
          );
          // $("#ridesCountSDateName").text("Tomorrow");
          // getAllRidesCount();
        } else {
          diffDays = diffDays - 1;
          console.log(diffDays);
          if (diffDays == 1) {
            this.setState(
              {
                daySelected: "Day after tomorrow",
              },
              () => {
                // //this.handlerefreshclick();
              }
            );
            // $("#ridesCountSDateName").text("Day after tomorrow");
          } else {
            this.setState(
              {
                daySelected: `${diffDays} days after tomorrow`,
              },
              () => {
                // //this.handlerefreshclick();
              }
            );
            // $("#ridesCountSDateName").text(diffDays + " days after tomorrow");
          }
          // getAllRidesCount();
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));

      if (diffDays == 1) {
        this.setState(
          {
            daySelected: "Yesterday",
          },
          () => {
            // //this.handlerefreshclick();
          }
        );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Day before yesterday",
            },
            () => {
              // //this.handlerefreshclick();
            }
          );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          this.setState(
            {
              // daySelected: `${diffDays} days from yesterday`,
            },
            () => {
              //this.handlerefreshclick();
            }
          );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  }
  getCoordinates() {
    let data = {
      region: this.state.region,
    };
    fetch(config.url + "htmlcrm/getcoordinates", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // var flightPlanCoordinates = [];
          // let coordinates = result.bangaloreRegion
          // for (let i = 0; i < coordinates.length; i++) {
          //     var point = new google.maps.LatLng(coordinates[i][0], coordinates[i][1]);
          //     flightPlanCoordinates.push(point);
          // }
          console.log(result)
          this.setState({
            coOrdinates: result.bangaloreRegion,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  componentDidMount() {
    this.getCoordinates();
  }

  render() {
    return (
      <Row className="">
        <Col className="col-12 col-md-9 col-lg-9 mb-4">
          {/* <PolylineMap
              dataProps={{}}
              source_lat={this.state.userData.source_latlon}
              dest_lat={this.state.userData.destination_latlon}
              path={this.state.coOrdinates}
              onDatahandlers={this.handleData}
            /> */}
          <Row>
            <Col className="col-12 col-md-3">
              <Card
                className="mt-2"
                style={{ position: "absolute", zIndex: 999 }}
              >
                <CardBody>
                  <Row>
                    <Col className="col-md-12 d-flex justify-content-center">
                      <div
                        className="px-2 mx-2 mb-2"
                        style={{
                          width: "100%",
                          minWidth: "220px",
                          textAlign: "center",
                          verticalAlign: "center",
                        }}
                      >
                        <i
                          id="ridesCountSPrevDate"
                          className="fas fa-arrow-left float-left mt-1"
                          onClick={this.ridesCountSPrevDate}
                        />
                        <a id="ridesCountSDateName">{this.state.daySelected}</a>
                        <i
                          id="ridesCountSNextDate"
                          className="fas fa-arrow-right float-right mt-1"
                          onClick={this.ridesCountSNextDate}
                        />
                      </div>
                    </Col>
                    <Col className="col-md-12 d-flex justify-content-center">
                      <DatePicker
                        className="w-100 date-picker-custom"
                        format="dd/MM/yyyy"
                        onChange={this.handleTime}
                        value={this.state.tripDate}
                        clearIcon={null}
                        calendarIcon={<i className="far fa-calendar-alt" />}
                      />
                    </Col>
                  </Row>
                  {/* {sessionStorage.getItem("roletype") === "manager" && ( */}
                  <Row>
                    <Col className="col-md-12 mt-3">
                      <FormGroup>
                        <label>Filter based on region</label>
                        <FormSelect
                          value={this.state.region}
                          onChange={this.handleSelectChange}
                          style={{ fontWeight: 700 }}
                        >
                          <option value="Bangalore">Bangalore</option>
                          <option value="Hyderabad">Hyderabad</option>
                        </FormSelect>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <TestNearby
            dateProps={this.state.tripDate}
            region={this.state.region}
            source_lat={this.state.userData.source_latlon}
            dest_lat={this.state.userData.destination_latlon}
            path={this.state.coOrdinates}
            onDatahandlers={this.handleData}
          />
        </Col>
        <Col className="col-12 col-md-3 col-lg-3 mb-5 p-0">
          <Card style={{ overflowY: "auto", maxHeight: "700px" }}>
            <CardHeader>
              <Row className=" border-bottom">
                <Col className="col-9 col-md-9"> Active Drivers</Col>
                <Col className="col-md-3">
                  {this.state.nearbyDrivers.length === undefined
                    ? 0
                    : this.state.nearbyDrivers.length}
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="pb-0 pt-0" style={{ overFlowY: "scroll" }}>
              <Row>
                {this.state.nearbyDrivers.map((data, index) => (
                  <Col
                    className="col-12 col-md-12 col-lg-12 border-bottom mx-1"
                    key={index}
                  >
                    <div className="pt-2">
                      <label style={{ fontWeight: 100 }}>Name: </label>
                      <span style={{ fontWeight: 600 }}>
                        {data.driver_name}
                      </span>
                    </div>
                    <div>
                      <label style={{ fontWeight: 100 }}>Type: </label>
                      <span style={{ fontWeight: 600 }}>
                        {data.vehicle_type}
                      </span>
                    </div>
                    <div>
                      <label style={{ fontWeight: 100 }}>
                        Vehicle Number:{" "}
                      </label>
                      <span style={{ fontWeight: 600 }}>
                        {" "}
                        {data.vehiclenumber}
                      </span>
                    </div>
                    <div className="pb-2">
                      <label style={{ fontWeight: 100 }}>Phone number:</label>
                      <span style={{ fontWeight: 600 }}>
                        {" "}
                        {data.phonenumber}
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default NearbyDrivers;
