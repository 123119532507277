import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Collapse,
  Button,
  Tooltip,
  Card,
  CardBody,
  FormCheckbox,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CardHeader,
  FormInput,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";
import PageTitle from "./../components/common/PageTitle";
import Charts from "./../components/utilities/Charts";
import * as config from "../constants/constants.json";
import DriverDashboardBox from "../components/common/DriverDashboardBox";
function formatStatus(data) {
  if (data === "Active") {
    return <span style={{ color: "#59E67F" }}> Active</span>;
  } else if (data === "Verified") {
    return <span style={{ color: "#59E67F" }}> Verified</span>;
  } else if (data === "on") {
    return <span style={{ color: "#59E67F" }}> ON</span>;
  } else if (data === "off") {
    return <span style={{ color: "#ff0000" }}> OFF</span>;
  } else if (data === "Not Verified" || data === "Not verified") {
    return <span style={{ color: "#ff0000" }}> Not Verified</span>;
  } else if (data === "blocked") {
    return <span style={{ color: "#ff0000" }}> Blocked</span>;
  } else {
    return <span> -</span>;
  }
}
class DriverDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      bankDetailsCog: true,
      driverDetails: true,
      displayProfCog: true,
      vehicleDetailsCog: true,
      ridePrefCog: true,
      duty_status: "",
      driver_type: "",
      last_login: "",
      familyDetailsCog: true,
      visible: false,
      notExist: false,
      consumerWarn: false,
      beneficiary_id: "",
      account_number: "",
      ifsc_code: "",
      acc_status: "",
      divName: "id_proofs",
      first_name: "",
      last_name: "",
      dob: "",
      email: "",
      current_address: "",
      permanent_addr8123173193ess: "",
      outstation: false,
      rentals: false,
      airport: false,
      urban: false,
      recurride: false,
      day_shift: false,
      night_shift: false,
      login_status: "",
      operating_region: "",
      preferred_location: "",
      starting_point: "",
      vehicle_name: "",
      vehicle_number: "",
      vehicle_color: "",
      vehicle_make: "",
      vehicle_model: "",
      phonenumber: "",
      alt_phonenumber: "",
      driver_profile:
        "https://master.gloryindia.in/static/images/avatars/man.png",
      family_name_1: "",
      family_name_2: "",
      family_phn_1: "",
      family_phn_2: "",
      family_rel_1: "",
      family_rel_2: "",
      family_address_1: "",
      family_address_2: "",
      acc_holder_name: "",
      acc_number: "",
      bank_name: "",
      ifsc: "",
      avg_ratings: "",
      vehicle_type: "",
      results: [],
      vehicle_front_image: "",
      vehicle_back_image: "",
      vehicle_left_image: "",
      vehicle_right_image: "",
      vehicle_with_driver: "",
      aadhar_card_image_front: "",
      aadhar_card_image_back: "",
      pan_card_image: "",
      driving_license_image: "",
      rc_image: "",
      vehicle_insurance_image: "",
      driver_badgee_image: "",
      emission_cert_image: "",
      road_tax_image: "",
      permit_image: "",
      fitness_image: "",
      sales_agreement: "",
      police_verification: "",
      badge_number: "",
      permit_number: "",
      aadhar_number: "",
      pan_number: "",
      dl_number: "",
      dunique_id: "",
      lastEditedDate: "",
      acceptance_count: "",
      completed_count: "",
      cancelled_count: "",
    };
    this.goBack = this.goBack.bind(this);
    this.searchDriver = this.searchDriver.bind(this);
    this.getDutyStatus = this.getDutyStatus.bind(this);
    this.checkForDriverBenificiary = this.checkForDriverBenificiary.bind(this);
    this.goToEditDriver = this.goToEditDriver.bind(this);
  }

  goToEditDriver() {
    console.log(this.state.phonenumber);
    let state = {
      phonenumber: this.state.phonenumber,
    };
    this.props.history.push("/driveraccounts/addeditdriver", state);
  }

  // Get beneficiery details
  checkForDriverBenificiary() {
    fetch(config.url + "payout/checkBeneficiary", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        phonenumber: this.props.history.location.state.phonenumber
          ? this.props.history.location.state.phonenumber
          : this.props.history.location.state,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "old beneficiary") {
            // beneficiary_id
            // account_number
            // ifsc_code
            this.setState({
              beneficiary_id: result.beneficiary_id,
              account_number: result.account_number,
              ifsc_code: result.ifsc_code,
              acc_status: result.status,
            });
          } else {
          }

          // console.log("herererer");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  // Get driver details
  searchDriver() {
    // serch opertion here
    fetch(config.url + "onboard/search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // phonenumber: this.state.phonenumber
        phonenumber: this.props.history.location.state.phonenumber
          ? this.props.history.location.state.phonenumber
          : this.props.history.location.state,
      }),
    })
      .then((res) => res.json())
      .then(
        (resultsss) => {
          if (resultsss.message) {
            if (resultsss.message === "user does not exist") {
              this.setState({
                notExist: !this.state.notExist,
              });
            } else if (resultsss.message === "consumer") {
              this.setState({
                consumerWarn: !this.state.consumerWarn,
              });
            } else if (resultsss.message === "successfull") {
              console.log(resultsss);
              let resData = resultsss.results;
              console.log(resData);
              // let duty_preference;
              let night, day;
              if (
                resData.driverPreference.duty_preference === "" ||
                resData.driverPreference.duty_preference === undefined ||
                resData.driverPreference.duty_preference === null
              ) {
                night = false;
                day = false;
              } else if (
                resData.driverPreference.duty_preference === "day / night"
              ) {
                night = true;
                day = true;
              } else if (resData.driverPreference.duty_preference === "day") {
                night = false;
                day = true;
              } else if (resData.driverPreference.duty_preference === true) {
                night = true;
                day = false;
              }
              // console.log(resultsss.driverPreference)
              // visible: !this.state.visible,
              this.setState(
                {
                  night_shift: night,
                  day_shift: day,
                  login_status: resData.login_status,
                  lastEditedDate: resData.lastEditedDate,
                  first_name: resData.first_name,
                  last_name: resData.last_name,
                  email: resData.accemail,
                  current_address: resData.current_address,
                  permanent_address: resData.permanent_address,
                  phonenumber: resData.phonenumber,
                  alt_phonenumber: resData.alternate_number,
                  vehicle_name: resData.vehicle_details.vehicleName,
                  vehicle_number: resData.vehicle_details.vehiclenumber,
                  vehicle_color: resData.vehicle_details.vehicleColor,
                  vehicle_make: resData.vehicle_details.vehicle_make,
                  vehicle_type: resData.vehicle_details.vehicle_type,
                  vehicle_model: resData.vehicle_details.vehicle_model,
                  acc_holder_name: resData.accholder_name,
                  acc_number: resData.account_number,
                  bank_name: "",
                  ifsc: resData.ifsc_code,
                  family_name_1: resData.familyDetails.fmone_name,
                  family_name_2: resData.familyDetails.fmtwo_name,
                  family_phn_1: resData.familyDetails.fmone_phonenumber,
                  family_phn_2: resData.familyDetails.fmtwo_phonenumber,
                  family_rel_1: resData.familyDetails.fmone_relationship,
                  family_rel_2: resData.familyDetails.fmtwo_relationship,
                  family_address_1: resData.familyDetails.fmone_address,
                  family_address_2: resData.familyDetails.fmtwo_address,
                  outstation: resData.driverPreference.outstation,
                  rentals: resData.driverPreference.rentals,
                  airport: resData.driverPreference.airport,
                  urban: resData.driverPreference.urban,
                  recurride: resData.driverPreference.recur_ride,
                  operating_region: resData.driverPreference.operating_region,
                  preferred_location:
                    resData.driverPreference.preferred_location,
                  starting_point: resData.driverPreference.starting_point,
                  avg_ratings: resData.avg_ratings,
                  vehicle_front_image: resData.imagedata.vehicle_pic[0],
                  vehicle_back_image: resData.imagedata.vehicle_pic[1],
                  vehicle_left_image: resData.imagedata.vehicle_pic[2],
                  vehicle_right_image: resData.imagedata.vehicle_pic[3],
                  vehicle_with_driver: resData.imagedata.driv_vehicle_image[1],
                  aadhar_card_image_front:
                    resData.imagedata.driver_adharcard[0],
                  aadhar_card_image_back: resData.imagedata.driver_adharcard[1],
                  pan_card_image: resData.imagedata.pan[0],
                  driving_license_image: resData.imagedata.dl_image[0],
                  rc_image: resData.imagedata.vehicle_rc[0],
                  vehicle_insurance_image: resData.imagedata.insurance_image[0],
                  driver_badgee_image: resData.imagedata.display_card[0],
                  emission_cert_image: resData.imagedata.emission_image[0],
                  road_tax_image: resData.imagedata.roadtax_image[0],
                  permit_image: resData.imagedata.permit_image[0],
                  fitness_image: resData.imagedata.fc_image[0],
                  sales_agreement: resData.imagedata.sales_agreement[0],
                  police_verification: resData.imagedata.police_verification[0],
                  driver_profile: resData.imagedata.driver_image[0],
                  dunique_id: resData.dunique_id,
                },
                () => {
                  this.getAnalytics();
                }
              );
            } else {
            }
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  getAnalytics() {
    fetch(config.url + "htmlcrm/driverRidesCountYear", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        dunique_id: this.state.dunique_id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.setState({
              acceptance_count: result.acceptance_count,
              completed_count: result.completed_count,
              cancelled_count: result.cancelled_count,
            });
          } else {
          }

          // console.log("herererer");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  // Get duty details
  getDutyStatus() {
    fetch(config.url + "htmlcrm/getDriverData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        phonenumber: this.props.history.location.state.phonenumber
          ? this.props.history.location.state.phonenumber
          : this.props.history.location.state,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.setState({
              last_login: result.last_login,
              driver_type: result.driver_type,
              duty_status: result.duty_status,
            });
          } else {
          }

          // console.log("herererer");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    } else {
      this.getDutyStatus();
      this.searchDriver();
      this.checkForDriverBenificiary();
    }
  }

  render() {
    // console.log(this.props);
    let data = {
      labels: ["Acceptance rate", "Completed rate", "Cancelled rate"],
      datasets: [
        {
          borderColor: ["#5ec0cb", "#6bb756", "#CC5228"],
          backgroundColor: ["#5ec0cb", "#6bb756", "#CC5228"],
          data: [
            this.state.acceptance_count,
            this.state.completed_count,
            this.state.cancelled_count,
          ],
        },
      ],
    };
    console.log(this.state);
    return (
      <Container fluid className="main-content-container px-md-4">
        <Row noGutters className="page-header py-4 px-3 mb-4">
          <Col className="col-12">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="/driveraccounts">Driver accounts</a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Driver dashboard
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active />
            </Breadcrumb>
          </Col>
          <Col className="col-12">
            <PageTitle
              title="Driver details"
              className="text-sm-left mb-3 p-0 custom-margin"
            />
          </Col>
          {/* <PageTitle
            title="Driver dashboard"
            subtitle="Driver Accounts"
            className="text-sm-left mb-3"
          /> */}
        </Row>
        <Row className="px-3 mb-5">
          <Col className="col-12 col-md-12 col-lg-12">
            <Button
              className=" float-right"
              id="goback-button"
              onClick={this.goBack}
              theme="dark"
            >
              <i className="fas fa-arrow-left mx-1" />
              <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                BACK
              </span>{" "}
            </Button>
          </Col>
        </Row>
        <Row className="mb-5 px-md-3">
          <Col className="col-12 col-md-12 col-lg-12">
            <Card>
              <CardBody>
                <Row>
                  <Col className="col-12 col-md-3 col-lg-3 mb-4">
                    <Card>
                      <CardBody className="text-center">
                        <div
                          className="rounded-circle mb-2 mx-auto"
                          style={{
                            width: "120px",
                            height: "120px",
                            backgroundImage: `url(${this.state.driver_profile}), url('https://master.gloryindia.in/static/images/avatars/man.png')`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "50% 50%",
                          }}
                        />
                        <h5>
                          {this.state.first_name + " " + this.state.last_name}
                        </h5>
                        <h6>{this.state.phonenumber}</h6>
                        <h6>{this.state.vehicle_type}</h6>
                        <h6>{this.state.vehicle_number}</h6>
                        <span>
                          <i className="fas fa-star" />
                          {this.state.avg_ratings}
                        </span>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="col-12 col-md-9 col-lg-9">
                    <Card>
                      <CardBody>
                        <Row className="mb-4">
                          <Col className="col-12 col-md-12 col-lg-12 mb-2">
                            <div className="float-right ">
                              <Button
                                onClick={this.goToEditDriver}
                                theme="dark"
                                size="lg"
                                style={{
                                  fontSize: "0.8rem",
                                  fontWeight: "700",
                                  minWidth: "200px",
                                  borderRadius: "3px",
                                }}
                              >
                                EDIT PROFILE
                              </Button>
                            </div>
                          </Col>
                          <Col className="col-12 col-md-12 col-lg-12">
                            <React.Fragment>
                              <Charts data={data} />
                            </React.Fragment>
                          </Col>
                        </Row>
                        <Row>
                          {this.props.driverAccountsTop.map((stats, idx) => (
                            <Col
                              className="col-md mb-4"
                              key={idx}
                              {...stats.attrs}
                            >
                              <DriverDashboardBox
                                id={`small-stats-${idx}`}
                                variation="1"
                                label={stats.label}
                                value={stats.value}
                                stats={stats.stats}
                                display={stats.display}
                                dunique_id={this.state.dunique_id}
                                phonenumber={this.state.phonenumber}
                              />
                            </Col>
                          ))}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="col-12 col-md-5 col-lg-4 mb-4 mt-3">
                    <Card>
                      <CardBody>
                        <h5 style={{ textDecoration: "underline" }}>
                          Account details
                        </h5>
                        <h6>
                          <label style={{ fontWeight: "800" }}>
                            Login status:{" "}
                          </label>{" "}
                          {formatStatus(this.state.login_status)}
                        </h6>
                        <h6>
                          <label style={{ fontWeight: "800" }}>
                            Duty status:{" "}
                          </label>
                          {formatStatus(this.state.duty_status)}
                        </h6>
                        <h6>
                          <label style={{ fontWeight: "800" }}>
                            Driver type:{" "}
                          </label>
                          {this.state.driver_type}
                        </h6>
                        <h6>
                          <label style={{ fontWeight: "800" }}>
                            Last login:{" "}
                          </label>
                          {" " + this.state.last_login}
                        </h6>
                        <h6>
                          <label style={{ fontWeight: "800" }}>
                            Last updated:{" "}
                          </label>
                          {this.state.lastEditedDate === undefined ||
                          this.state.lastEditedDate === ""
                            ? " - "
                            : " " + this.state.lastEditedDate}
                        </h6>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="col-12 col-md-5 col-lg-4 mt-3">
                    <Card>
                      <CardBody>
                        <h5 style={{ "text-decoration": "underline" }}>
                          Benefiaciary details
                        </h5>
                        <h6>
                          <label style={{ fontWeight: "800" }}>Status: </label>
                          {formatStatus(this.state.acc_status)}
                        </h6>
                        <h6>
                          <label style={{ fontWeight: "800" }}>ID: </label>
                          {this.state.beneficiary_id === undefined ||
                          this.state.beneficiary_id === ""
                            ? " - "
                            : " " + this.state.beneficiary_id}
                        </h6>
                        <h6>
                          <label style={{ fontWeight: "800" }}>Acc no: </label>
                          {this.state.account_number === undefined ||
                          this.state.account_number === ""
                            ? " - "
                            : " " + this.state.account_number}
                        </h6>
                        <h6>
                          <label style={{ fontWeight: "800" }}>IFSC: </label>
                          {this.state.ifsc_code === undefined ||
                          this.state.ifsc_code === ""
                            ? " - "
                            : " " + this.state.ifsc_code}
                        </h6>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
DriverDashboard.propTypes = {
  driverAccountsTop: PropTypes.array,
  idProofs: PropTypes.array,
  agreePC: PropTypes.array,
  liscenseProps: PropTypes.array,
  roadProps: PropTypes.array,
};

DriverDashboard.defaultProps = {
  driverAccountsTop: [
    {
      label: "idproofs",
      value: "Driver's ID",
      id: "IDPROOFS",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "vehicleimages",
      value: "Vehicle images",
      id: "VEHICLEIMAGES",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "ridehistory",
      value: "Ride history",
      id: "ridehistory",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
    },
  ],
  idProofs: [
    {
      label: "Aadhar card",
      value: "",
      attrs: {},
      stats: false,
      name: "aadhar_card_image_front",
    },
    {
      label: "PAN card",
      value: "",
      attrs: {},
      stats: false,
      name: "pan_card_image",
    },
  ],
  agreePC: [
    {
      label: "Sales agreement",
      value: "",
      attrs: {},
      stats: false,
      name: "sales_agreement",
    },
    {
      label: "Police verification",
      value: "",
      attrs: {},
      stats: false,
      name: "police_verification",
    },
  ],
  liscenseProps: [
    {
      label: "Driving license",
      value: "",
      attrs: {},
      stats: false,
      name: "driving_license_image",
    },
    {
      label: "Reg. Certificate",
      value: "",
      attrs: {},
      stats: false,
      name: "rc_image",
    },
    {
      label: "Vehicle insurance",
      value: "",
      attrs: {},
      stats: false,
      name: "vehicle_insurance_image",
    },
    {
      label: "Driver badge(Opt)",
      value: "",
      attrs: {},
      stats: false,
      name: "driver_badgee_image",
    },
  ],
  roadProps: [
    {
      label: "Emission certificate",
      value: "",
      attrs: {},
      stats: false,
      name: "emission_cert_image",
    },
    {
      label: "Road tax",
      value: "",
      attrs: {},
      stats: false,
      name: "road_tax_image",
    },
    {
      label: "Permit",
      value: "",
      attrs: {},
      stats: false,
      name: "permit_image",
    },
    {
      label: "Fitness certificate",
      value: "",
      attrs: {},
      stats: false,
      name: "fitness_image",
    },
  ],
};

export default DriverDashboard;
