import React, { Component } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
} from "shards-react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import InputData from "./../common/InputData";
import * as config from "../../constants/constants.json";
import DatePicker from "react-date-picker";
// import TripDetails from "./../common/TripDetails"
// import NavTab from "./../common/NavTab"
// import ModNavTab from "./../common/ModalNav"
// import ModalView from "./../common/Modal"

import { Store } from "../../flux";
// import BasicModalExample from "../common/Modal"
const oneDay = 24 * 60 * 60 * 1000;
class Tripdetachview extends Component {
  constructor(props) {
    // console.log(props)
    super(props); //since we are extending class Table so we have to use super in order to override Component class constructor

    this.state = {
      //state is by default an object
      detachData: [],
      detachUserData: [],
      dData: [],
      rideType: "",
      tripData: [],
      tripDate: new Date(),
      timerDate: new Date(),
      changeDiv: false,
      tripId: "",
      rideItems: Store.getRideItems(),
      request_type: "requested",
      index: "",
      collapse: false,
      openMod: false,
      dutyState: "nearby",
      selected: {},
      selectAll: 0,
      daySelected: "Today",
    };
    this.toggle = this.toggle.bind(this);
    // this.onChange = this.onChange.bind(this);
    // this.handleClickChange = this.handleClickChange.bind(this);
    // this.handleSelect = this.handleSelect.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.fetchCall = this.fetchCall.bind(this);
    this.viewClick = this.viewClick.bind(this);
    this.getDetachReqUsers = this.getDetachReqUsers.bind(this);
    // this.toggleRow = this.toggleRow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetMod = this.resetMod.bind(this);
    this.approveDetach = this.approveDetach.bind(this);
    this.rejectDetach = this.rejectDetach.bind(this);
    // this.goBack = this.goBack.bind(this);
    // this.callGCM = this.callGCM.bind(this);
    // console.log(this.state.rideItems);
    // console.log(this.state);
    this.ridesCountSNextDate = this.ridesCountSNextDate.bind(this);
    this.ridesCountSPrevDate = this.ridesCountSPrevDate.bind(this);
  }

  ridesCountDate = () => {
    // console.log(date);
    let newDate = new Date(this.state.tripDate);

    newDate.setHours(0, 0, 0, 0);
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    console.log(newDate);
    console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - newDate) / oneDay));
    newDate.getTime() > currentDate.getTime()
      ? newDate.setDate(newDate.getDate() + 1)
      : newDate.setDate(newDate.getDate() - 1);
    if (newDate.getTime() >= currentDate.getTime()) {
      if (newDate.getTime() == currentDate.getTime()) {
        this.setState(
          {
            daySelected: "Today",
          },
          () => {
            this.fetchCall();
          }
        );
        // setDayselected("Today");
      } else {
        if (diffDays === 1) {
          this.setState(
            {
              daySelected: "Tomorrow",
            },
            () => {
              this.fetchCall();
            }
          );
          // setDayselected("Tomorrow");
        } else {
          diffDays = diffDays - 1;
          // console.log(diffDays);
          if (diffDays == 1) {
            this.setState(
              {
                daySelected: "Day after tomorrow",
              },
              () => {
                this.fetchCall();
              }
            );
            // setDayselected("Day after tomorrow");
          } else {
            this.setState(
              {
                daySelected: `${diffDays} days after tomorrow`,
              },
              () => {
                this.fetchCall();
              }
            );
            // setDayselected(`${diffDays} days after tomorrow`);
          }
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - newDate) / oneDay));
      console.log(diffDays);
      if (diffDays == 1) {
        // setDayselected("Today");
        this.setState(
          {
            daySelected: "Yesterday",
          },
          () => {
            this.fetchCall();
          }
        );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          // setDayselected("Day before yesterday");
          this.setState(
            {
              daySelected: "Day before yesterday",
            },
            () => {
              this.fetchCall();
            }
          );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          // setDayselected(`${diffDays} days from yesterday`);
          this.setState(
            {
              daySelected: `${diffDays} days from yesterday`,
            },
            () => {
              this.fetchCall();
            }
          );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  };
  ridesCountSNextDate() {
    let date = this.state.tripDate;
    // console.log(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    nextDate.setHours(0, 0, 0, 0);
    // console.log(nextDate);
    if (nextDate) {
      this.setState({
        tripDate: nextDate,
      });
    }
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    // console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));
    // console.log(diffDays);
    if (nextDate.getTime() >= currentDate.getTime()) {
      if (nextDate.getTime() == currentDate.getTime()) {
        this.setState(
          {
            daySelected: "Today",
          },
          () => {
            this.fetchCall();
          }
        );
        // getAllRidesCount();
      } else {
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Tomorrow",
            },
            () => {
              this.fetchCall();
            }
          );
          // $("#ridesCountSDateName").text("Tomorrow");
          // getAllRidesCount();
        } else {
          diffDays = diffDays - 1;
          // console.log(diffDays);
          if (diffDays == 1) {
            this.setState(
              {
                daySelected: "Day after tomorrow",
              },
              () => {
                this.fetchCall();
              }
            );
            // $("#ridesCountSDateName").text("Day after tomorrow");
          } else {
            this.setState(
              {
                daySelected: `${diffDays} days after tomorrow`,
              },
              () => {
                this.fetchCall();
              }
            );
            // $("#ridesCountSDateName").text(diffDays + " days after tomorrow");
          }
          // getAllRidesCount();
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));

      if (diffDays == 1) {
        this.setState(
          {
            daySelected: "Yesterday",
          },
          () => {
            this.fetchCall();
          }
        );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Day before yesterday",
            },
            () => {
              this.fetchCall();
            }
          );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          this.setState(
            {
              daySelected: `${diffDays} days from yesterday`,
            },
            () => {
              this.fetchCall();
            }
          );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  }

  ridesCountSPrevDate() {
    let date = this.state.tripDate;
    // console.log(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() - 1);
    nextDate.setHours(0, 0, 0, 0);
    // console.log(nextDate);
    if (nextDate) {
      this.setState({
        tripDate: nextDate,
      });
    }
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));
    console.log(diffDays);
    if (nextDate.getTime() >= currentDate.getTime()) {
      if (nextDate.getTime() == currentDate.getTime()) {
        this.setState(
          {
            daySelected: "Today",
          },
          () => {
            this.fetchCall();
          }
        );
        // getAllRidesCount();
      } else {
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Tomorrow",
            },
            () => {
              this.fetchCall();
            }
          );
          // $("#ridesCountSDateName").text("Tomorrow");
          // getAllRidesCount();
        } else {
          diffDays = diffDays - 1;
          console.log(diffDays);
          if (diffDays == 1) {
            this.setState(
              {
                daySelected: "Day after tomorrow",
              },
              () => {
                this.fetchCall();
              }
            );
            // $("#ridesCountSDateName").text("Day after tomorrow");
          } else {
            this.setState(
              {
                daySelected: `${diffDays} days after tomorrow`,
              },
              () => {
                this.fetchCall();
              }
            );
            // $("#ridesCountSDateName").text(diffDays + " days after tomorrow");
          }
          // getAllRidesCount();
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));

      if (diffDays == 1) {
        this.setState(
          {
            daySelected: "Yesterday",
          },
          () => {
            this.fetchCall();
          }
        );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          this.setState(
            {
              daySelected: "Day before yesterday",
            },
            () => {
              this.fetchCall();
            }
          );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          this.setState(
            {
              daySelected: `${diffDays} days from yesterday`,
            },
            () => {
              this.fetchCall();
            }
          );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  }
  // goBack() {
  //     this.props.dataProps.history.goBack();
  // }
  rejectDetach() {
    fetch(config.url + "htmlcrm/detachPermission", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        vendor: "mmt",
        // milliseconds: time,
        // request_type: this.state.request_type
        trip_id: this.state.tripId,
        approval: "rejected",
        user_id: window.sessionStorage.getItem("user_id"),
        reason: "testing",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          this.fetchCall();
          this.resetMod();
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  approveDetach() {
    fetch(config.url + "htmlcrm/detachPermission", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        vendor: "mmt",
        // milliseconds: time,
        // request_type: this.state.request_type
        trip_id: this.state.tripId,
        approval: "approved",
        user_id: window.sessionStorage.getItem("user_id"),
        reason: "testing",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          this.fetchCall();
          this.resetMod();
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  resetMod() {
    this.setState({
      openMod: !this.state.openMod,
    });
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  viewClick(data) {
    // console.log("*****************halooooo*****************");
    // console.log(data);
    // return (<BasicModalExample />)
    this.setState({
      openMod: !this.state.openMod,
    });
  }

  // Reload list when a date is seleced from the date picker
  handleTime(tripDate) {
    // console.log(this);
    // console.log(date)
    // console.log("****************here******************")
    this.setState({ tripDate }, () => {
      this.fetchCall();
      this.ridesCountDate();
    });
    // this.countFetch()
  }

  //API to get detach request details

  getDetachReqUsers() {
    // console.log("sadasaaaaaaaaaaaaaaaaaaaaaaaa");
    // console.log(window.sessionStorage.getItem('user_id'));
    // console.log(this.state.tripId);
    fetch(config.url + "htmlcrm/getDetachRequestUsers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        vendor: "",
        region: sessionStorage.getItem("operating_region"),
        // milliseconds: time,
        // request_type: this.state.request_type

        trip_id: this.state.tripId,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          this.setState({
            detachUserData: result.list,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  // Api call to fetch unassigned and assigned rides
  fetchCall(props) {
    // console.log(this.state.tripDate);
    let time = this.state.tripDate.setHours(0, 0, 0, 0);
    // console.log(time);
    // console.log(time);
    fetch(config.url + "htmlcrm/detachedRidesList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        vendor: "mmt",
        milliseconds: time,
        // request_type: this.state.request_type
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          this.setState({
            detachData: result.rides,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  // Change to ride details page on click of view
  handleClick(data) {
    // console.log("************************")
    // console.log(data);
    this.setState(
      {
        openMod: !this.state.openMod,
        tripId: data.trip_id,
      },
      () => {
        this.getDetachReqUsers();
      }
    );
  }

  // Change to ride list on click of the close button in ride details page
  handleChange() {
    this.setState({
      changeDiv: false,
    });
  }
  // Function called by set interval to load ride list
  tick(data) {
    // console.log(data);
    // this.fetchCall()
  }

  // Called before render only once
  componentDidMount(props) {
    // console.log(this.state.request_type)
    // console.log("herer");
    // if (this.props.request_type !== this.state.request_type) {
    //     this.fetchCall(this.state)
    // }
    this.fetchCall(this.state);
    // this.timerID = setInterval(
    //     () => this.tick(this.state.request_type),
    //     2000000
    // );
  }

  // componentWillMount() {
  //     Store.addChangeListener(this.onChange);

  // }

  componentWillUnmount() {
    // Store.removeChangeListener(this.onChange);
    clearInterval(this.timerID);
  }

  render() {
    //Whenever our class runs, render method will be called automatically, it may have already defined in the constructor behind the scene.
    // console.log("*************state***************");
    // console.log(this.state.index);
    const columns = [
      {
        Header: "Sl no",
        id: "serialno",
        style: {},
        Cell: (row) => {
          // console.log(row);
          return <div>{row.viewIndex + 1}</div>;
        },
      },
      {
        Header: "Trip ID",
        accessor: "trip_id",
      },
      {
        Header: "Vendor",
        accessor: "requested_through",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Source",
        accessor: "sname",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Destination",
        accessor: "dname",
        style: { whiteSpace: "unset" },
      },
      {
        id: "pickup_time",
        Header: "Pickup time ",
        // accessor: 'pickup_time',
        Cell: (row) => {
          let date = moment(new Date(row.original.pickup_time)).format(
            "DD-MM-YY, hh:mm:ss A"
          );

          return <div>{date}</div>;
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Ride type",
        accessor: "trip_type",
      },
      {
        id: "edit",
        Header: "Actions",
        columns: [
          {
            Cell: (row) => {
              //  console.log(this);
              // console.log(row.original.request_status)
              return (
                <div className="d-flex flex-column">
                  <Button
                    className=" my-2"
                    theme="dark"
                    onClick={() => {
                      this.handleClick(row.original);
                    }}
                  >
                    VIEW
                  </Button>
                </div>
              );
            },
          },
        ],
      },
    ];
    // const { rideItems: items } = this.state;
    return (
      <Col className="col-md-12">
        <Card className="mx-0">
          <CardBody className="px-0">
            <Row className="px-4 mb-md-4">
              <Col className="col-md-4 col-lg-3  d-none d-sm-none d-md-block">
                <FormGroup>
                  <div
                    className="mb-2"
                    style={{
                      width: "100%",
                      minWidth: "220px",
                      textAlign: "center",
                      verticalAlign: "center",
                    }}
                  >
                    <i
                      id="ridesCountSPrevDate"
                      style={{ marginTop: "4px" }}
                      className="fas fa-arrow-left float-left"
                      onClick={this.ridesCountSPrevDate}
                    />
                    <a id="ridesCountSDateName">{this.state.daySelected}</a>
                    <i
                      id="ridesCountSNextDate"
                      style={{ marginTop: "4px" }}
                      className="fas fa-arrow-right float-right"
                      onClick={this.ridesCountSNextDate}
                    />
                  </div>
                  <DatePicker
                    className="w-100 date-picker-custom"
                    onChange={this.handleTime}
                    value={this.state.tripDate}
                    clearIcon={null}
                    calendarIcon={<i className="far fa-calendar-alt" />}
                    format="dd/MM/yyyy"
                  />
                </FormGroup>
                {/* <div className="w-75"> */}
                {/* <Row>
                  <Col className="col-md-9 d-flex justify-content-center">
                    <div
                      className="px-2 mx-2 mb-2"
                      style={{
                        width: "100%",
                        minWidth: "220px",
                        textAlign: "center",
                        verticalAlign: "center",
                      }}
                    >
                      <i
                        id="ridesCountSPrevDate"
                        className="fas fa-arrow-left float-left"
                        onClick={this.ridesCountSPrevDate}
                      />
                      <a id="ridesCountSDateName">{this.state.daySelected}</a>
                      <i
                        id="ridesCountSNextDate"
                        className="fas fa-arrow-right float-right"
                        onClick={this.ridesCountSNextDate}
                      />
                    </div>
                  </Col>
                  <Col className="col-md-9">
                    <DatePicker
                      className="w-100 date-picker-custom"
                      onChange={this.handleTime}
                      value={this.state.tripDate}
                      clearIcon={null}
                      calendarIcon={<i className="far fa-calendar-alt" />}
                      format="dd/MM/yyyy"
                    />
                  </Col>
                </Row> */}

                {/* </div> */}
              </Col>
              <Col className="col-12 px-5 mb-4 d-block d-sm-block d-md-none d-xl-none order-2 order-md-1">
                {/* <div className="w-50 mx-auto"> */}
                <DatePicker
                  className="w-100 date-picker-custom"
                  onChange={this.handleTime}
                  value={this.state.tripDate}
                  clearIcon={null}
                  format="dd/MM/yyyy"
                  calendarIcon={<i className="far fa-calendar-alt" />}
                />
                {/* </div> */}
              </Col>
              <Col className="d-none d-sm-none d-md-block d-xl-block" />
              <Col className="col-12 col-md-3 d-flex justify-content-end py-3 order-1 order-md-2">
                <Button onClick={this.fetchCall} theme="dark">
                  <i className="fas fa-sync-alt" />
                </Button>
              </Col>
            </Row>
            {/* <hr className="my-3"></hr> */}
            <div>
              <Row className="">
                <Col className="">
                  <Modal
                    show={this.state.openMod}
                    onHide={this.resetMod}
                    backdrop="false"
                    className="modal-dialog-centered"
                  >
                    <div>
                      <ModalHeader closeButton>
                        <div>Ride Detach Request</div>
                      </ModalHeader>
                      <ModalBody>
                        <span className="">
                          Do you want to detach this ride ?
                        </span>
                        <Row className="mt-3">
                          {this.props.DetachModData.map((stats, idx) => (
                            <Col
                              className="col-md-6 mb-4 "
                              key={idx}
                              {...stats.attrs}
                            >
                              {/* {console.log(data[stats.apiID])} */}
                              <InputData
                                id={`small-stats-${idx}`}
                                variation="1"
                                label={stats.label}
                                value={this.state.tripId}
                                stats={stats.stats}
                              />
                            </Col>
                          ))}
                        </Row>
                        <Row className="w-100 mx-auto mt-2">
                          <Col className="col-12 pl-0">
                            <div className="ml-auto">
                              <label>
                                Raised by :{" "}
                                {this.state.detachUserData.first_name +
                                  " " +
                                  this.state.detachUserData.last_name}
                              </label>
                            </div>
                          </Col>
                          <Col className="col-12 pl-0">
                            <div className="ml-auto">
                              <label>
                                {this.state.detachUserData.timeStamp}
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Row className="w-100 mx-auto mt-2">
                          <label> {this.state.detachUserData.reason}</label>
                        </Row>
                        <Row className="w-100 mx-auto mt-2">
                          <textarea
                            className="w-100"
                            rows="4"
                            placeholder="Please provide a reason"
                            value={this.state.textareaValue}
                            onChange={this.handletextareaChange}
                          />
                        </Row>
                      </ModalBody>

                      <ModalFooter className="px-0">
                        <Row>
                          <Col className="col-12">
                            <Button
                              theme="dark"
                              size="lg"
                              className="mx-4"
                              onClick={this.approveDetach}
                              style={{
                                fontSize: "0.8rem",
                                fontWeight: "700",
                                minWidth: "120px",
                                borderRadius: "3px",
                              }}
                            >
                              APPROVE
                            </Button>
                            <Button
                              theme="dark"
                              size="lg"
                              className="mx-4"
                              onClick={this.rejectDetach}
                              style={{
                                fontSize: "0.8rem",
                                fontWeight: "700",
                                minWidth: "120px",
                                borderRadius: "3px",
                              }}
                            >
                              REJECT
                            </Button>
                          </Col>
                        </Row>
                      </ModalFooter>
                    </div>
                  </Modal>
                </Col>
              </Row>
              {/* {console.log(this.state.data)} */}
              <ReactTable
                data={this.state.detachData}
                columns={columns}
                className="text-center -striped -highlight"
                noDataText="No data to display"
                defaultSorted={[
                  {
                    id: "pickuptime",
                    desc: false,
                  },
                ]}
                minRows={this.state.detachData.length > 0 ? 0 : 4}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
Tripdetachview.default = {
  columns: PropTypes.array,
  assignDrivCol: PropTypes.array,
  changeDiv: PropTypes.bool,
  tripId: PropTypes.string,
  request_type: PropTypes.string,
  index: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
};
Tripdetachview.defaultProps = {
  DetachModData: [
    {
      label: "Trip ID",
      value: "",
      attrs: "",
      apiID: "trip_id",
    },
  ],
  assignDriv: [
    {
      label: "Nearby drivers",
    },
    {
      label: "On duty drivers",
    },
  ],
  compColumns: [
    {
      Header: "Sl no",
      id: "serialno",
      style: {},
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Trip ID",
      accessor: "trip_id",
    },
    {
      Header: "Vendor",
      accessor: "vendor",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Source",
      accessor: "sname",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Destination",
      accessor: "dname",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Pickup time ",
      accessor: "pickuptime",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Ride type",
      accessor: "ridetype",
    },
    {
      id: "edit",
      Header: "Action",
      Cell: ({ value }) => {
        return <Button>View</Button>;
      },
    },
  ],
  ongoingColumns: [
    {
      Header: "Sl no",
      id: "serialno",
      style: {},
      Cell: (row) => {
        return <div>{row.viewndex + 1}</div>;
      },
    },
    {
      Header: "Trip ID",
      accessor: "trip_id",
    },
    {
      Header: "Source",
      accessor: "sname",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Destination",
      accessor: "dname",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Pickup time ",
      accessor: "pickuptime",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Ride type",
      accessor: "ridetype",
    },
    {
      Header: "Vehicle type",
      accessor: "vehicle_type",
    },
    {
      Header: "Rider",
      style: { whiteSpace: "unset" },
      Cell: (row) => {
        return (
          <div>
            {row.original.consumername + " - " + row.original.phonenumber}
          </div>
        );
      },
    },
    {
      id: "edit",
      Header: "Action",
      Cell: ({ value }) => {
        return <Button>View</Button>;
      },
    },
  ],
  changeDiv: false,
  tripId: "",
  index: 0,
  tripDate: new Date(),
};
export default Tripdetachview;
