// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import { TimelineMax, Power1, Back } from "gsap/all";

import CommonBox from "../components/common/commonDisplayBox";
import PageTitle from "./../components/common/PageTitle";
// import TopDrivers from "../components/common/TopDrivers"
// import RideAnalysis from "../components/dataviews/RideAnalysis"
// const ReportsView = ({ smallStats, bottomStats }) => (
//   <Container fluid className="main-content-container py-4 px-4">
//     <Row noGutters className="page-header  px-3 mb-4">
//       <PageTitle
//         title="Manage reports"
//         subtitle="Reports"
//         className="text-sm-left mb-3"
//       />
//     </Row>
//     <Row className="px-3">
//       {smallStats.map((stats, idx) => (
//         <Col className="col-md mb-4" key={idx} {...stats.attrs}>
//           {/* {console.log(smallStats)} */}
//           {/* {console.log(idx)}
//           {console.log(stats)} */}
//           <CommonBox
//             id={`small-stats-${idx}`}
//             variation="1"
//             label={stats.label}
//             value={stats.value}
//             stats={stats.stats}
//             display={stats.display}
//             path="/reports/"
//           />
//         </Col>
//       ))}
//     </Row>
//   </Container>
// );

class ReportsView extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    }
  }

  render() {
    return (
      <Container fluid className="main-content-container py-4 px-4">
        <Row noGutters className="page-header  px-3 mb-4">
          <PageTitle
            title="Manage reports"
            subtitle="Reports"
            className="text-sm-left mb-3"
          />
        </Row>
        <Row className="px-3 mt-5">
          {this.props.smallStats.map((stats, idx) => (
            <Col className="col-md mb-4" key={idx} {...stats.attrs}>
              {/* {console.log(smallStats)} */}
              {/* {console.log(idx)}
            {console.log(stats)} */}
              <CommonBox
                id={`small-stats-${idx}`}
                variation="1"
                label={stats.label}
                value={stats.value}
                stats={stats.stats}
                display={stats.display}
                path="/reports/"
              />
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

ReportsView.propTypes = {
  smallStats: PropTypes.array,
};

ReportsView.defaultProps = {
  smallStats: [
    {
      label: "dispatchreports",
      value: "Dispatch reports",
      id: "dispatchreports",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "tripreports",
      value: "Trip reports",
      id: "tripreports",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
    },
  ],
};

export default ReportsView;
