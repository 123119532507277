import React, { useEffect } from 'react';
import { Row, Col, Container, Card, Modal } from 'react-bootstrap';
import InputLabel from '@material-ui/core/InputLabel';
import { Breadcrumb, BreadcrumbItem, Button as Buttons } from 'shards-react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { FormGroup } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PageTitle from './../components/common/PageTitle';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import HistoryIcon from '@material-ui/icons/History';

import * as config from '../constants/constants.json';
import * as stringsData from '../constants/stringsConstant.json';
import AnalyticsCard from './AnalyticsCard';

const theme = createMuiTheme({
  props: {
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true // No more ripple, on the whole application 💣!
    }
  }
});

const useStyles = makeStyles(theme => ({
  chipInputRoot: {},
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  root: {
    width: '100%'
  },
  tabs: {
    '& .Mui-selected': {
      fontSize: '1.2em',
      fontWeight: 700
    }
  },
  tabsRoot: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(17),
    textAlign: 'left',
    '&:focus': {
      opacity: 1
    },
    expandedPanel: {
      backgroundColor: theme.palette.primary.main
    }
  },

  tableRoot: {
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    '&-webkit-scrollbar': {
      display: 'none'
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  headingTwo: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '66.66%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  table: {
    overflowX: 'auto',
    border: 0
    // backgroundColor: "#FAFAFA",
  },
  tabHeader: {
    borderBottom: 'none',
    fontWeight: 600
  },
  selectTableCell: {},
  tableCell: {
    minWidth: 135,

    borderBottom: 'none'
  },
  slCell: {
    minWidth: 80,

    borderBottom: 'none'
  },
  input: {
    maxWidth: 150,
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  formLabel: {
    fontWeight: 600,

    color: '#000',
    '&.Mui-focused': {
      color: '#000'
    }
  },
  checkbox: {
    root: {
      '&$checked': {
        color: 'black'
      }
    }
  },
  typo: {
    fontSize: '0.8rem'
  },
  selectRoot: {
    '&:before': {
      borderColor: 'black'
    },
    '&:focus': {
      borderColor: 'black'
    },
    '&:hover:not(.Mui-disabled):before': {
      borderColor: 'black'
    }
  }
}));

const RadioButton = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black'
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />);

/**
 * React function
 */

function FareAnalyticsGraph(props) {
  const classes = useStyles();
  const [vehicleType, setVehicleType] = React.useState('sedan');
  const [rideType, setRideType] = React.useState('airport');
  const [valuePath, setValuePath] = React.useState('to_airport');
  const [loadData, setLoadData] = React.useState([]);
  const [fareType, setFareType] = React.useState('slab');

  useEffect(() => {
    setLoadData([]);
  }, [vehicleType, rideType, valuePath, fareType]);

  // useEffect(() => {
  //   setLoadData([]);
  // }, [rideType]);

  // useEffect(() => {
  //   setLoadData([]);
  // }, [valuePath]);

  /**
   *Go back to configuration page
   *
   */
  const goBackAccounts = () => {
    props.history.push('/configuration');
  };

  const createAnalytics = () => {
    let data = [...loadData];
    console.log(data);
    // let start_date
    // let end_date
    if (data.length > 0) {
      let newDate = [];
      let sortedData = _.orderBy(data, ['start_time'], ['desc']);
      let lastElement = _.last(sortedData);
      let endDate = lastElement.start_time;
      let startDate = moment(new Date(lastElement.start_time))
        .subtract(7, 'd')
        .valueOf();

      newDate.push({ start_time: startDate, end_time: endDate });

      setLoadData(newDate);
    } else {
      let endDate = new Date().getTime();
      let startDate = moment()
        .subtract(7, 'd')
        .valueOf();
      data.push({ start_time: startDate, end_time: endDate });
      setLoadData(data);
    }

    // setLoadData(data);
  };

  const changeRangeDate = date => {
    console.log(date);
    // let data = [...loadData];
    let newDate = [];
    let start_time = date[0].getTime();
    let end_time = date[1].getTime();
    newDate.push({ start_time: start_time, end_time: end_time });
    setLoadData(newDate);
  };

  const resetData = () => {
    setLoadData([]);
  };

  //   useEffect(() => {
  //     getRIdeCountData();
  //   }, []);

  //   useEffect(() => {
  //     getRIdeCountData();
  //   }, [vehicleType]);

  //   useEffect(() => {
  //     getRIdeCountData();
  //   }, [rideType]);

  //   useEffect(() => {
  //     getRIdeCountData();
  //   }, [valuePath]);

  return (
    <Container fluid className="main-content-container">
      <Row className="px-1 px-md-4 py-md-3">
        <Col className="col-12 col-md-12">
          <Row className="w-100">
            <Col className="col-12">
              <Breadcrumb>
                <BreadcrumbItem>
                  <a href="#" onClick={goBackAccounts}>
                    Configuration /
                  </a>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col className="col-12 mt-md-1">
              <PageTitle title="Fare Analytics" className="text-sm-left mb-3 pl-0 custom-margin" />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="col-12 col-md-3 mt-3">
              <FormGroup>
                <label className="px-3">Filter by vehilce type</label>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select vehicle type
                  </InputLabel>
                  <Select
                    labelId="vehicle-type-select"
                    id="vehicle-type-select"
                    label="Select vehicle type"
                    className={classes.selectRoot}
                    style={{
                      fontWeight: 700
                    }}
                    value={vehicleType}
                    onChange={e => setVehicleType(e.target.value)}
                  >
                    {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem> */}
                    <MenuItem value="hatchback">Hatchback</MenuItem>
                    <MenuItem value="sedan">Sedan</MenuItem>
                    <MenuItem value="suv">SUV</MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
            </Col>
            <Col className="col-12 col-md-3 mt-3">
              <FormGroup>
                <label className="px-3">Filter by ride type</label>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Ride type</InputLabel>
                  <Select
                    labelId="vehicle-type-select"
                    id="vehicle-type-select"
                    label="Ride type"
                    className={classes.selectRoot}
                    style={{
                      fontWeight: 700
                    }}
                    value={rideType}
                    onChange={e => setRideType(e.target.value)}
                  >
                    {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem> */}
                    <MenuItem value="airport">Airport</MenuItem>
                    {/* <MenuItem value="rental">Rental</MenuItem>
                      <MenuItem value="outstation">Outstation</MenuItem> */}
                  </Select>
                </FormControl>
              </FormGroup>
            </Col>
            <Col className="col-12 col-md-3 mt-3">
              <FormGroup>
                <label className="px-3">Filter by fare type</label>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Ride type</InputLabel>
                  <Select
                    labelId="vehicle-type-select"
                    id="vehicle-type-select"
                    label="Ride type"
                    className={classes.selectRoot}
                    style={{
                      fontWeight: 700
                    }}
                    value={fareType}
                    onChange={e => setFareType(e.target.value)}
                  >
                    {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem> */}
                    <MenuItem value="slab">Slab</MenuItem>
                    <MenuItem value="per_km">Per km</MenuItem>
                    {/* <MenuItem value="rental">Rental</MenuItem>
                      <MenuItem value="outstation">Outstation</MenuItem> */}
                  </Select>
                </FormControl>
              </FormGroup>
            </Col>
            <Col className="col-12 col-md-3 mt-3">
              <FormGroup>
                <label className="px-3">Filter by type of flow</label>
                <FormControl component="fieldset" className="mt-md-3 d-flex">
                  <RadioGroup
                    row
                    aria-label="days"
                    name="days"
                    value={valuePath}
                    onChange={e => setValuePath(e.target.value)}
                  >
                    <FormControlLabel
                      className="px-4"
                      control={<RadioButton />}
                      label="City to airport"
                      value="to_airport"
                    />
                    <FormControlLabel
                      className="px-4"
                      value="from_airport"
                      control={<RadioButton />}
                      label="Airport to city"
                    />
                  </RadioGroup>
                </FormControl>
              </FormGroup>
            </Col>
          </Row>

          <AnalyticsCard
            rideType={rideType}
            vehicleType={vehicleType}
            valuePath={valuePath}
            type="future"
            start_date={new Date().getTime()}
            end_date={new Date().getTime()}
            showRangePicker="false"
            fareType={fareType}
          />

          {loadData.map((data, index) => (
            <React.Fragment key={index}>
              <AnalyticsCard
                rideType={rideType}
                vehicleType={vehicleType}
                valuePath={valuePath}
                type="past"
                start_date={data.start_time}
                end_date={data.end_time}
                showRangePicker="true"
                changeDate={changeRangeDate}
                fareType={fareType}
              />
            </React.Fragment>
          ))}

          <Row>
            <Col className="col-12 d-flex justify-content-center mb-2">
              <Button startIcon={<HistoryIcon />} onClick={createAnalytics}>
                Load data of past 7 days
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default FareAnalyticsGraph;
