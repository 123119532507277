import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Collapse,
  Button,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  FormSelect,
} from "shards-react";
import DatePicker from "react-date-picker";
import axios from "axios";

/**
 * Import files and components
 */
import TripDataBox from "../components/common/TripDataBox";
import Chart from "../components/utilities/RidesPart";
import PageTitle from "./../components/common/PageTitle";
import * as config from "../constants/constants.json";
import * as stringConstants from "../constants/stringsConstant.json";

/**
 * Global variable
 */

const oneDay = 24 * 60 * 60 * 1000;

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function Vendors(props) {
  const [collapse, setCollapse] = useState(false);
  const [hits, setHits] = useState({});
  const [date, setDate] = useState(new Date());
  const [offeredAmount, setOfferedAmount] = useState("");
  const [openTip, setOpenTip] = useState(false);
  const [openUnassignedTip, setOpenUnassignedTip] = useState(false);
  const [openAssignedTip, setOpenAssignedTip] = useState(false);
  const [openCompletedTip, setOpenCompletedTip] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [daySelected, setDayselected] = useState("Today");
  const [assignedCount, setAssignedCount] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [rentalRidesCount, setRentalRidesCount] = useState(0);
  const [region, setRegion] = useState(
    sessionStorage.getItem("operating_region")
  );
  const [rideFlow, setRideFlow] = useState({
    city_to_airport: 0,
    airport_to_city: 0,
    rental_rides: 0,
  });
  // const [airportFlow, setAirportFlow] = useState({
  //   city_to_airport: 0,
  //   airport_to_city: 0,
  // });
  // const [airport_to_city, setAirportToCity] = useState(0);
  const handleSelectChange = (event) => {
    setRegion(event.target.value);
  };

  const toggle = () => {
    setCollapse(!collapse);
    setShowDetails(!showDetails);
  };

  const handleOnChange = (event) => {
    setOfferedAmount(event.target.value);
  };

  const onChange = (date) => {
    // console.log(date);
    setDate(date);
  };

  const toggleTip = () => {
    setOpenTip(!openTip);
  };

  const toggleUnassignedTip = () => {
    setOpenUnassignedTip(!openUnassignedTip);
  };

  const toggleAssignedTip = () => {
    setOpenAssignedTip(!openAssignedTip);
  };
  const toggleCompletedTip = () => {
    setOpenCompletedTip(!openCompletedTip);
  };

  const goBack = () => {
    props.data.history.goBack();
  };

  const goBackAccounts = () => {
    props.data.history.push("/businessaccounts");
  };

  const ridesCountDate = () => {
    // console.log(date);
    let newDate = new Date(date);

    newDate.setHours(0, 0, 0, 0);
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    // console.log(newDate);
    // console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - newDate) / oneDay));
    newDate.getTime() > currentDate.getTime()
      ? newDate.setDate(newDate.getDate() + 1)
      : newDate.setDate(newDate.getDate() - 1);
    if (newDate.getTime() >= currentDate.getTime()) {
      if (newDate.getTime() == currentDate.getTime()) {
        setDayselected("Today");
      } else {
        if (diffDays === 1) {
          setDayselected("Tomorrow");
        } else {
          diffDays = diffDays - 1;
          // console.log(diffDays);
          if (diffDays == 1) {
            setDayselected("Day after tomorrow");
          } else {
            setDayselected(`${diffDays} days after tomorrow`);
          }
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - newDate) / oneDay));
      // console.log(diffDays);
      if (diffDays == 1) {
        setDayselected("Today");
        // this.setState(
        //   {
        //     daySelected: "Yesterday",
        //   },
        //   () => {
        //     this.countFetch();
        //     this.getMyAssignedCount();
        //     this.callLog();
        //   }
        // );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          setDayselected("Day before yesterday");
          //   this.setState(
          //     {
          //       daySelected: "Day before yesterday",
          //     },
          //     () => {
          //       this.countFetch();
          //       this.getMyAssignedCount();
          //       this.callLog();
          //     }
          //   );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          setDayselected(`${diffDays} days from yesterday`);
          //   this.setState(
          //     {
          //       daySelected: `${diffDays} days from yesterday`,
          //     },
          //     () => {
          //       this.countFetch();
          //       this.getMyAssignedCount();
          //       this.callLog();
          //     }
          //   );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  };

  const ridesCountSNextDate = () => {
    // console.log(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    nextDate.setHours(0, 0, 0, 0);
    // console.log(nextDate);
    if (nextDate) {
      setDate(nextDate);
      //  this.setState({
      //    date: nextDate,
      //  });
    }
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    // console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));
    // console.log(diffDays);
    if (nextDate.getTime() >= currentDate.getTime()) {
      if (nextDate.getTime() == currentDate.getTime()) {
        setDayselected("Today");
      } else {
        if (diffDays == 1) {
          setDayselected("Tomorrow");
          //   this.setState(
          //     {
          //       daySelected: "Tomorrow",
          //     },
          //     () => {
          //       this.countFetch();
          //       this.getMyAssignedCount();
          //       this.callLog();
          //     }
          //   );
          // $("#ridesCountSDateName").text("Tomorrow");
          // getAllRidesCount();
        } else {
          diffDays = diffDays - 1;
          // console.log(diffDays);
          if (diffDays == 1) {
            setDayselected("Day after tomorrow");
            // this.setState(
            //   {
            //     daySelected: "Day after tomorrow",
            //   },
            //   () => {
            //     this.countFetch();
            //     this.getMyAssignedCount();
            //     this.callLog();
            //   }
            // );
            // $("#ridesCountSDateName").text("Day after tomorrow");
          } else {
            setDayselected(`${diffDays} days after tomorrow`);
            // this.setState(
            //   {
            //     daySelected: ,
            //   },
            //   () => {
            //     this.countFetch();
            //     this.getMyAssignedCount();
            //     this.callLog();
            //   }
            // );
            // $("#ridesCountSDateName").text(diffDays + " days after tomorrow");
          }
          // getAllRidesCount();
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));

      if (diffDays == 1) {
        setDayselected("Today");
        // this.setState(
        //   {
        //     daySelected: "Yesterday",
        //   },
        //   () => {
        //     this.countFetch();
        //     this.getMyAssignedCount();
        //     this.callLog();
        //   }
        // );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          setDayselected("Day before yesterday");
          //   this.setState(
          //     {
          //       daySelected: "Day before yesterday",
          //     },
          //     () => {
          //       this.countFetch();
          //       this.getMyAssignedCount();
          //       this.callLog();
          //     }
          //   );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          setDayselected(`${diffDays} days from yesterday`);
          //   this.setState(
          //     {
          //       daySelected: `${diffDays} days from yesterday`,
          //     },
          //     () => {
          //       this.countFetch();
          //       this.getMyAssignedCount();
          //       this.callLog();
          //     }
          //   );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  };
  const ridesCountSPrevDate = () => {
    // console.log(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() - 1);
    nextDate.setHours(0, 0, 0, 0);
    // console.log(nextDate);
    if (nextDate) {
      setDate(nextDate);
      // this.setState({
      //   date: nextDate,
      // });
    }
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    // console.log(currentDate);
    let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));
    // console.log(diffDays);
    if (nextDate.getTime() >= currentDate.getTime()) {
      if (nextDate.getTime() == currentDate.getTime()) {
        setDayselected("Today");
        //   this.setState(
        //     {
        //       daySelected: "Today",
        //     },
        //     () => {
        //       this.countFetch();
        //       this.getMyAssignedCount();
        //       this.callLog();
        //     }
        //   );
        // getAllRidesCount();
      } else {
        if (diffDays == 1) {
          setDayselected("Tomorrow");
          // this.setState(
          //   {
          //     daySelected: "Tomorrow",
          //   },
          //   () => {
          //     this.countFetch();
          //     this.getMyAssignedCount();
          //     this.callLog();
          //   }
          // );
          // $("#ridesCountSDateName").text("Tomorrow");
          // getAllRidesCount();
        } else {
          diffDays = diffDays - 1;
          // console.log(diffDays);
          if (diffDays == 1) {
            setDayselected("Day after tomorrow");
            //   this.setState(
            //     {
            //       daySelected: "Day after tomorrow",
            //     },
            //     () => {
            //       this.countFetch();
            //       this.getMyAssignedCount();
            //       this.callLog();
            //     }
            //   );
            // $("#ridesCountSDateName").text("Day after tomorrow");
          } else {
            setDayselected(`${diffDays} days after tomorrow`);
            // this.setState(
            //   {
            //     daySelected: `${diffDays} days after tomorrow`,
            //   },
            //   () => {
            //     this.countFetch();
            //     this.getMyAssignedCount();
            //     this.callLog();
            //   }
            // );
            // $("#ridesCountSDateName").text(diffDays + " days after tomorrow");
          }
          // getAllRidesCount();
        }
      }
    } else {
      // $("#ridesCountSDatePicker").datepicker("update", nextDate);
      let diffDays = Math.round(Math.abs((currentDate - nextDate) / oneDay));

      if (diffDays == 1) {
        setDayselected("Yesterday");
        // this.setState(
        //   {
        //     daySelected: "Yesterday",
        //   },
        //   () => {
        //     this.countFetch();
        //     this.getMyAssignedCount();
        //     this.callLog();
        //   }
        // );
        // $("#ridesCountSDateName").text("Yesterday");
        // getAllRidesCount();
      } else {
        diffDays = diffDays - 1;
        if (diffDays == 1) {
          setDayselected("Day before yesterday");
          //   this.setState(
          //     {
          //       daySelected: "Day before yesterday",
          //     },
          //     () => {
          //       this.countFetch();
          //       this.getMyAssignedCount();
          //       this.callLog();
          //     }
          //   );
          // $("#ridesCountSDateName").text("Day before yesterday");
        } else {
          setDayselected(`${diffDays} days from yesterday`);
          //   this.setState(
          //     {
          //       daySelected: `${diffDays} days from yesterday`,
          //     },
          //     () => {
          //       this.countFetch();
          //       this.getMyAssignedCount();
          //       this.callLog();
          //     }
          //   );
          // $("#ridesCountSDateName").text(diffDays + " days from yesterday");
        }

        // getAllRidesCount();
      }
    }
  };
  const getMyAssignedCount = () => {
    let time = date;
    let milliseconds = time.getTime();
    // console.log(window.sessionStorage.getItem("user_id"));
    // console.log(new Date(this.state.date));
    fetch(config.url + "htmlcrm/getMyAssignedCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        milliseconds: milliseconds,
        executiveID: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          setAssignedCount(result.assignedCount);
          // this.setState({ assignedCount: result.assignedCount });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  };
  // const callLog = () => {
  //   let proxyURL = config.proxy;
  //   let time = date;
  //   let data = config.logging + "/apflow?millis=" + time.getTime();
  //   // console.log(data);
  //   fetch(proxyURL + data, {
  //     method: "GET",
  //     headers: {
  //       "X-Requested-With": "XMLHttpRequest",
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         // console.log(result);
  //         if (result.message === "successfull") {
  //           setGraphData(result);
  //           //   this.setState({
  //           //     graphData: result,
  //           //   });
  //         }
  //       },
  //       // Note: it's important to handle errors here
  //       // instead of a catch() block so that we don't swallow
  //       // exceptions from actual bugs in components.
  //       (error) => {}
  //     );
  // };
  const countFetch = () => {
    // console.log("Help");
    let time = date;
    console.log(time + "called");
    let milliseconds = time.getTime();
    // console.log(new Date(this.state.date));
    fetch(config.url + "htmlcrm/getAllRidesCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        milliseconds: milliseconds,
        region_name: region,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          let resArray = {
            totalRidesCount: result.totalRidesCount
              ? result.totalRidesCount
              : 0,
            assignedRides: result.scheduledRidesCount.assignedRides
              ? result.scheduledRidesCount.assignedRides
              : 0,
            unassignedRides: result.scheduledRidesCount.unassignedRides
              ? result.scheduledRidesCount.unassignedRides
              : 0,
            cancelledRides: result.scheduledRidesCount.cancelledRides
              ? result.scheduledRidesCount.cancelledRides
              : 0,
            completedRides: result.scheduledRidesCount.completedRides
              ? result.scheduledRidesCount.completedRides
              : 0,
            ongoing: result.ongoing ? result.ongoing : 0,
            detachedRides: result.scheduledRidesCount.detachedRides
              ? result.scheduledRidesCount.detachedRides
              : 0,
            consumerCanceledCount: result.consumerCanceledCount
              ? result.consumerCanceledCount
              : 0,
            driverCanceledCount: result.driverCanceledCount
              ? result.driverCanceledCount
              : 0,
            unassignedRentalCount: result.unassigned_rental_count
              ? result.unassigned_rental_count
              : 0,
            unassignedAirportCount: result.unassigned_airport_count
              ? result.unassigned_airport_count
              : 0,
            assignedRentalCount: result.assigned_rental_count
              ? result.assigned_rental_count
              : 0,
            assignedAirportCount: result.assigned_airport_count
              ? result.assigned_airport_count
              : 0,
            completedAirportCount: result.completed_airport_count
              ? result.completed_airport_count
              : 0,
            completedRentalCount: result.completed_rental_count
              ? result.completed_rental_count
              : 0,
          };
          setHits(resArray);
          //  this.setState({ hits: resArray });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  };

  const countFlow = () => {
    let dataToSend = {
      from_date: date.setHours(0, 0, 0, 0),
      to_date: date.setHours(0, 0, 0, 0),
      region_name: region,
    };
    console.log(dataToSend);
    axios
      .post(config.url + "htmlcrm/getTripFlowCount", dataToSend)
      .then((response) => {
        console.log(response);
        if (response.data.message === "successfull") {
          setRideFlow({
            city_to_airport: response.data.city_to_airport,
            airport_to_city: response.data.airport_to_city,
            rental_rides: response.data.rental_rides,
          });
        } else {
          setRideFlow({
            city_to_airport: 0,
            airport_to_city: 0,
            rental_rides: 0,
          });
        }
      })
      .catch();
  };

  /**
   * On change in date
   */
  useEffect(() => {
    countFetch();
    countFlow();
    getMyAssignedCount();
    // callLog();
    ridesCountDate();
  }, [date]);

  /**
   * On change of region
   */

  useEffect(() => {
    countFetch();
    countFlow();
    getMyAssignedCount();
    // callLog();
  }, [region]);

  /**
   * On chnage in arrow day selector
   */

  useEffect(() => {
    countFetch();
    countFlow();
    getMyAssignedCount();
    // callLog();
  }, [daySelected]);

  /**
   * Effect to run only on page load
   */
  useInterval(() => {
    // Your custom logic here
    countFetch();
    countFlow();
  }, 30000);

  useEffect(() => {
    setRegion(sessionStorage.getItem("operating_region"));
    countFetch();
    getMyAssignedCount();
    // callLog();
    countFlow();
    // const timer = setInterval(() => countFetch(), 30000);
    // return () => {
    //   clearInterval(timer);
    // };
  }, []);

  let ridesData = {
    labels: ["From airport (%)", "To airport (%)"],
    datasets: [
      {
        borderColor: ["#5ec0cb", "#6bb756", "#CC5228"],
        backgroundColor: ["#5ec0cb", "#6bb756", "#CC5228"],
        data: [graphData.fromAiportPercent, graphData.toAiportPercent],
      },
    ],
  };

  return (
    <React.Fragment>
      <Row noGutters className="page-header py-4 px-3">
        <Col className="col-12 col-md-8">
          <Row className="w-100">
            <Col className="col-12">
              <Breadcrumb>
                <BreadcrumbItem>
                  <a href="#" onClick={goBackAccounts}>
                    Business accounts /
                  </a>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>

            <Col className="col-12 mt-md-1">
              <PageTitle
                title="Account overview"
                className="text-sm-left mb-3 pl-0 custom-margin"
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-md-1 d-none d-md-block"></Col>
        {/* {sessionStorage.getItem("roletype") === stringConstants.subLead && ( */}
        <Col className="col-12 col-md-4 col-lg-3 px-3">
          <FormGroup>
            <label>Filter based on region</label>
            <FormSelect
              value={region}
              onChange={handleSelectChange}
              style={{ fontWeight: 700, textAlignLast: "center" }}
            >
              <option value="all">All</option>
              <option value="Bangalore">Bangalore</option>
              <option value="Hyderabad">Hyderabad</option>
            </FormSelect>
          </FormGroup>
        </Col>
        {/* )} */}
      </Row>
      {/* <Row className="px-3">
        <Col className="col-12 col-md-3 mb-md-4 mb-4">
          <FormGroup>
            <div
              className=" mb-2"
              style={{
                width: "100%",
                minWidth: "150px",
                textAlign: "center",
                verticalAlign: "center",
              }}
            >
              <i
                id="ridesCountSPrevDate"
                className="fas fa-arrow-left float-left date-pointer"
                style={{ marginTop: "4px" }}
                onClick={ridesCountSPrevDate}
              />
              <a id="ridesCountSDateName">{daySelected}</a>
              <i
                id="ridesCountSNextDate"
                style={{ marginTop: "4px" }}
                className="fas fa-arrow-right float-right date-pointer"
                onClick={ridesCountSNextDate}
              />
            </div>
            <DatePicker
              className="w-100 date-picker-custom"
              format="dd/MM/yyyy"
              onChange={onChange}
              value={date}
              clearIcon={null}
              calendarIcon={<i className="far fa-calendar-alt" />}
              maxDetail="month"
            />
          </FormGroup>
        </Col>
      </Row> */}

      <Row className="px-3">
        <Col className="col-12 col-md-3 mb-md-4 mb-4">
          <FormGroup>
            <div
              className=" mb-2"
              style={{
                width: "100%",
                minWidth: "150px",
                textAlign: "center",
                verticalAlign: "center",
              }}
            >
              <i
                id="ridesCountSPrevDate"
                className="fas fa-arrow-left float-left date-pointer"
                style={{ marginTop: "4px" }}
                onClick={ridesCountSPrevDate}
              />
              <a id="ridesCountSDateName">{daySelected}</a>
              <i
                id="ridesCountSNextDate"
                style={{ marginTop: "4px" }}
                className="fas fa-arrow-right float-right date-pointer"
                onClick={ridesCountSNextDate}
              />
            </div>
            <DatePicker
              className="w-100 date-picker-custom"
              format="dd/MM/yyyy"
              onChange={onChange}
              value={date}
              clearIcon={null}
              calendarIcon={<i className="far fa-calendar-alt" />}
              maxDetail="month"
            />
          </FormGroup>
        </Col>
        <Col className="col-9 d-none d-md-flex justify-content-end">
          {props.FlowStats.map((stats, idx) => (
            <Col className="col-12 mb-4" key={idx} {...stats.attrs}>
              <TripDataBox
                id={`smaller-stats-${idx}`}
                variation="1"
                label={stats.label}
                value={rideFlow[stats.apiID]}
                stats={stats.stats}
              />
            </Col>
          ))}
          {props.smallStats.map((stats, idx) => (
            <Col className="col-md-3 mb-4" key={idx} {...stats.attrs}>
              {/* {console.log(this.state.hits)} */}
              <TripDataBox
                id={`smaller-stats-${idx}`}
                variation="1"
                label={stats.label}
                value={hits.totalRidesCount}
                stats={stats.stats}
              />
            </Col>
          ))}
        </Col>
        {props.FlowStats.map((stats, idx) => (
          <Col className="col-12 mb-4 d-md-none" key={idx} {...stats.attrs}>
            <TripDataBox
              id={`smaller-stats-${idx}`}
              variation="1"
              label={stats.label}
              value={rideFlow[stats.apiID]}
              stats={stats.stats}
            />
          </Col>
        ))}
        {props.smallStats.map((stats, idx) => (
          <Col className="col-md-2 mb-4 d-md-none" key={idx} {...stats.attrs}>
            {/* {console.log(this.state.hits)} */}
            <TripDataBox
              id={`smaller-stats-${idx}`}
              variation="1"
              label={stats.label}
              value={hits.totalRidesCount}
              stats={stats.stats}
            />
          </Col>
        ))}

        {/* {graphData.count !== undefined && (
          <Col className="col-12 col-md-8 col-lg-9 mb-md-4 mb-4">
            <Chart data={ridesData} datas={graphData} />
          </Col>
        )} */}
      </Row>
      <Row className="px-3 mt-3">
        <Col className="col-12 col-md-12 col-lg-12">
          <Button
            block
            theme="dark"
            onClick={toggle}
            className="mb-4 w-md-100 font-props"
            style={{ fontWeight: "700" }}
          >
            {showDetails === false ? "SHOW RIDE COUNT" : "COLLAPSE RIDE COUNT"}
            <i
              className={
                showDetails === false
                  ? "fas fa-chevron-down float-right"
                  : "fas fa-chevron-up float-right"
              }
            />
          </Button>
        </Col>
        <Col className="col-12">
          <Collapse open={collapse} className="">
            <Row className="">
              {props.RideStats.map((stats, idx) => (
                <Col className="col-md mb-4" key={idx} {...stats.attrs}>
                  {/* {console.log(data[stats.apiID])} */}
                  <TripDataBox
                    id={`small-stats-${idx}`}
                    variation="1"
                    label={stats.label}
                    value={hits[stats.apiID]}
                    stats={stats.stats}
                  />
                  <Tooltip
                    trigger="hover"
                    open={openUnassignedTip}
                    target={"#small-stats-0"}
                    toggle={toggleUnassignedTip}
                    placement="bottom"
                  >
                    <span>Airport rides : {hits.unassignedAirportCount}</span>
                    <br />
                    <span>Rental rides : {hits.unassignedRentalCount}</span>
                  </Tooltip>
                  <Tooltip
                    trigger="hover"
                    open={openAssignedTip}
                    target={"#small-stats-1"}
                    toggle={toggleAssignedTip}
                    placement="bottom"
                  >
                    <span>Airport rides : {hits.assignedAirportCount}</span>
                    <br />
                    <span>Rental rides : {hits.assignedRentalCount}</span>
                  </Tooltip>
                  <Tooltip
                    trigger="hover"
                    open={openCompletedTip}
                    target={"#small-stats-3"}
                    toggle={toggleCompletedTip}
                    placement="bottom"
                  >
                    <span>Airport rides : {hits.completedAirportCount}</span>
                    <br />
                    <span>Rental rides : {hits.completedRentalCount}</span>
                  </Tooltip>
                  <Tooltip
                    trigger="hover"
                    open={openTip}
                    target={"#small-stats-4"}
                    toggle={toggleTip}
                    placement="bottom"
                  >
                    <span>
                      Consumer Cancelled : {hits.consumerCanceledCount}
                    </span>
                    <br />
                    <span>
                      Cancelled via panel : {hits.driverCanceledCount}
                    </span>
                  </Tooltip>
                </Col>
              ))}
            </Row>
          </Collapse>
        </Col>
        <Col className="col-12">
          <hr className="mb-3 py-2" />
        </Col>
      </Row>
      <Row className="px-3 mb-3">
        {props.CountStats.map((stats, idx) => (
          <Col className="col-12 mb-4 mt-2" key={idx} {...stats.attrs}>
            <TripDataBox
              id={`smaller-stats-${idx}`}
              variation="1"
              label={stats.label}
              value={assignedCount}
              stats={stats.stats}
            />
          </Col>
        ))}

        <Col className="my-auto">
          <div className="float-right  mx-md-3">
            <Button
              className=""
              id="goback-button"
              theme="dark"
              onClick={goBackAccounts}
            >
              <i className="fas fa-arrow-left mx-1" />
              <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                BACK
              </span>
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="px-3 py-2">
        <Col className="col-12 col-md-12 col-lg-12 d-flex justify-content-center justify-content-md-end">
          <div className="float-right">
            {/* <a target="_blank" href="/nearbydrivers?operating_region=" >
              <u>NEARBY DRIVERS MAP</u>
            </a> */}
            <Link target="_blank" to={{ pathname: config.nearby }}>
              {/* to={`/nearbydrivers?operating_region=${region}`} */}
              DRIVER LOCATION MAP
            </Link>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

Vendors.propTypes = {
  smallStats: PropTypes.array,
  RideStats: PropTypes.array,
  CountStats: PropTypes.array,
  hits: PropTypes.string,
  date: PropTypes.oneOf(["object", "string"]),
  tripDate: PropTypes.oneOf(["object", "string"]),
};

Vendors.defaultProps = {
  smallStats: [
    {
      label: "Total rides",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "totalRidesCount",
    },
  ],
  RideStats: [
    {
      label: "UNASSIGNED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "unassignedRides",
    },
    {
      label: "ASSIGNED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "assignedRides",
    },
    {
      label: "ON-GOING RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "ongoing",
    },
    {
      label: "COMPLETED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "completedRides",
    },
    {
      label: "CANCELLED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "cancelledRides",
    },
    {
      label: "DETACHED RIDES",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "detachedRides",
    },
  ],
  CountStats: [
    {
      label: "Total assigned",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "assignedCount",
    },
  ],
  FlowStats: [
    {
      label: "City to airport",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "city_to_airport",
    },
    {
      label: "Airport to city",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "airport_to_city",
    },
    {
      label: "Rental rides",
      value: "0",
      attrs: { md: "3", sm: "6" },
      stats: false,
      apiID: "rental_rides",
    },
  ],
};

export default Vendors;
