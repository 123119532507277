import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// import shortid from "shortid";
import { Card, CardBody } from "shards-react";


class StatBox extends React.Component {

  render() {

    const { variation, label, value } = this.props;

    const cardClasses = classNames(
      "py-4 ",
      variation && `stats-small--${variation}`
    );

    const cardBodyClasses = classNames(
      variation === "1" ? "p-0 d-flex" : "px-0 pb-0"
    );

    const innerWrapperClasses = classNames(
      "d-flex",
      variation === "1" ? "flex-column m-auto" : "px-3"
    );

    const dataFieldClasses = classNames(
      "stats-small__data",
      variation === "1" && "text-center"
    );

    const labelClasses = classNames(
      "stats-small__label",
      "text-uppercase",
      variation !== "1" && "mb-1"
    );

    const valueClasses = classNames(
      "stats-small__value font-for-all",
      "count",
      variation === "1" ? "my-3" : "m-0"
    );

    const innerDataFieldClasses = classNames(
      "stats-small__data",
      variation !== "1" && "text-right align-items-center"
    );

    return (
      <Card small className={cardClasses} style={{ height: "120px" }}>
        <CardBody className={cardBodyClasses}>
          <div className={innerWrapperClasses}>
            <div className={dataFieldClasses}>
              <span className={labelClasses}>{label}</span>
              <h6 className={valueClasses}>{value}</h6>
            </div>
            <div className={innerDataFieldClasses}>
              {/* <span className={percentageClasses}>{percentage}</span> */}
            </div>
          </div>
          {/* <canvas
            height={canvasHeight}
            ref={this.canvasRef}
            className={`stats-small-${shortid()}`}
          /> */}
        </CardBody>
      </Card>
    );
  }
}

StatBox.propTypes = {
  /**
   * The Small Stats variation.
   */
  variation: PropTypes.string,
  /**
   * The label.
   */
  label: PropTypes.string,
  /**
   * The value.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

StatBox.defaultProps = {
  value: 0,
  label: "Label",
};

export default StatBox;
