import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import Testing from "./components/common/StepperQickride";
// import TestMap from "./components/utilities/TestMapmovement";
// import Tets from "./components/utilities/TestImp";
// import Tests from "./components/utilities/testPoly";
// import Nearby from "./components/utilities/TestNearby";
// import Testings from "./components/utilities/mapHook";
// import TestDetails from "./components/common/QtripDetails";
// import AddDriver from "./components/dataviews/AddDriverReWork";
// import ResetPass from "./viewHooks/ResetPass";
// import FaceDetect from "./viewHooks/FaceRecognition";
import FaceScanner from "./viewHooks/FaceScanner";
import * as serviceWorker from "./serviceWorker";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
