import React, { useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import { Row, Col, Container, Card, Modal } from "react-bootstrap";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import ReactSnackBar from "react-js-snackbar";

import axios from "axios";
// import { FormCheckbox } from "shards-react";
// import { CheckboxField } from "../componentHooks/FormFields";
import SettingsIcon from "@material-ui/icons/Settings";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Breadcrumb, BreadcrumbItem, Button as Buttons } from "shards-react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import PageTitle from "./../components/common/PageTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { green, purple, grey, red } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";

import * as config from "../constants/constants.json";

// import Modal from "@material-ui/core/Modal";

// console.log(moment("15", "hh").format("hh A"));

const theme = createMuiTheme({
  props: {
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tabsRoot: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),

    "&:focus": {
      opacity: 1,
    },
    expandedPanel: {
      backgroundColor: theme.palette.primary.main,
    },
  },

  tableRoot: {
    marginTop: theme.spacing(3),
    overflowX: "auto",
    "&-webkit-scrollbar": {
      display: "none",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {
    overflowX: "auto",
    border: 0,
  },
  tabHeader: {
    borderBottom: "none",
    fontWeight: 600,
  },
  selectTableCell: {},
  tableCell: {
    minWidth: 135,

    borderBottom: "none",
  },
  slCell: {
    minWidth: 80,

    borderBottom: "none",
  },
  input: {
    maxWidth: 150,
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  formLabel: {
    fontWeight: 600,

    color: "#000",
    "&.Mui-focused": {
      color: "#000",
    },
  },
  checkbox: {
    root: {
      "&$checked": {
        color: "black",
      },
    },
  },
  typo: {
    fontSize: "0.8rem",
  },
}));

const getValue = (data) => {
  if (typeof data === "string") {
    // console.log(data);
    let timeData = data;
    let splitTimeData = timeData.split("to");
    let minimim_hour = moment(splitTimeData[0], "hh A").format("HH:mm");
    let maximum_hour = moment(splitTimeData[1], "hh A").format("HH:mm");
    return [minimim_hour, maximum_hour];
  } else {
    return data;
  }

  // return ["10:00", "12:00"];
};

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[50]),
    border: "solid 1px",
    fontSize: 11,
    fontWeight: 900,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    minWidth: 100,
    backgroundColor: grey[50],
    "&:hover": {
      backgroundColor: grey[900],
      color: theme.palette.getContrastText(grey[900]),
    },
  },
}))(Button);

const SaveButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: grey[900],
    "&:hover": {
      backgroundColor: grey[800],
    },
  },
}))(Button);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",

    "&$selected": {
      color: "black",
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const CustomColorCheckbox = withStyles({
  root: {
    color: "black",
    "&$checked": {
      color: "black",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const RadioButton = withStyles({
  root: {
    color: "black",
    "&$checked": {
      color: "black",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function StyledRadio(props) {
  const classes = useStyles();

  return <Radio color="default" {...props} />;
}

const createData = (
  sl_no,
  name,
  range_1,
  range_2,
  range_3,
  range_4,
  range_5,
  range_6,
  range_7,
  per_km
) => ({
  id: name.replace(" ", "_"),
  sl_no,
  name,
  range_1,
  range_2,
  range_3,
  range_4,
  range_5,
  range_6,
  range_7,
  per_km,
  isEditMode: false,
});

/**
 *  <React.Fragment>
          {name !== "name" ? (
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={row[name]}
              name={name}
              onChange={(e) => onChange(e, row)}
              className={classes.input}
            />
          ) : (
            <TimeRangePicker
              className="form-control"
              clearIcon={null}
              style={{ border: "none" }}
              format="h a"
              rangeDivider="to"
            />
          )}
        </React.Fragment>
 * 
 */

// const CustomTableCell = ({ row, name, onChange }) => {
//   const classes = useStyles();
//   const { isEditMode } = row;
//   return (
//     <TableCell align="left" className={classes.tableCell}>
//       {isEditMode && name !== "sl_no" && name !== "name" ? (
//         <TextField
//           id="outlined-basic"
//           variant="outlined"
//           value={row[name]}
//           name={name}
//           onChange={(e) => onChange(e, row)}
//           className={classes.input}
//         />
//       ) : (
//         row[name]
//       )}
//     </TableCell>
//   );
// };

const CustomTableCell = ({ row, name, onChange }) => {
  const classes = useStyles();
  const { isEditMode } = row;
  return (
    <TableCell
      align="left"
      className={name === "sl_no" ? classes.slCell : classes.tableCell}
    >
      {isEditMode && name !== "sl_no" ? (
        <React.Fragment>
          {name !== "name" ? (
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={row[name]}
              name={name}
              onChange={(e) => onChange(e, row, "text", name)}
              className={classes.input}
              required
            />
          ) : (
            <TimeRangePicker
              className="form-control"
              clearIcon={null}
              clockAriaLabel={null}
              style={{
                border: "none",
                minWidth: "250px !important",
              }}
              value={getValue(row[name])}
              name={name}
              format="h a"
              rangeDivider="to"
              onChange={(e) => onChange(e, row, "time", name)}
              required
            />
          )}
        </React.Fragment>
      ) : (
        row[name]
      )}
    </TableCell>
  );
};

const tConvert = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 0) {
    // If time format correct
    // time = time.slice(1); // Remove full string match value
    // console.log(time);
    // time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

const BlackSwitch = withStyles({
  switchBase: {
    color: grey[200],
    "&$checked": {
      color: grey[900],
    },
    "&$checked + $track": {
      backgroundColor: grey[900],
    },
  },
  checked: {},
  track: {},
})(Switch);

function FareConfiguration(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState("weekdays");
  const [valuePath, setValuePath] = React.useState("to_airport");
  const [valueInnerTab, setValueInner] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [monday, setMonday] = React.useState(false);
  const [tuesday, setTuesday] = React.useState(false);
  const [wednesday, setWednesday] = React.useState(false);
  const [thursday, setThursday] = React.useState(false);
  const [friday, setFriday] = React.useState(false);
  const [endFriday, setEndFriday] = React.useState(false);
  const [saturday, setSaturday] = React.useState(false);
  const [sunday, setSunday] = React.useState(false);
  const [weekDays, setWeekDays] = React.useState([]);
  const [weekEnds, setWeekEnds] = React.useState([]);
  const [editChange, setEditChange] = React.useState(false);
  const [date, onDateChange] = React.useState(["10:00", "11:00"]);
  const [fullData, setFullData] = React.useState([]);
  const [Show, setShow] = React.useState(false);
  const [Showing, setShowing] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [weekDayFare, setWeekdayFare] = React.useState([]);
  const [weekEndFare, setWeekEndFare] = React.useState([]);
  const [openSwitch, setOpenSwitch] = React.useState(false);
  const [openDeactivateSwitch, setOpenDeactivateSwitch] = React.useState(false);
  const [checkedA, setState] = React.useState(false);
  const [checkData, setCheckData] = React.useState([]);

  const handleOpenSwitch = () => {
    setOpenSwitch(true);
  };
  const handleCloseSwitch = () => {
    setOpenSwitch(false);
  };

  const handleDeactivateOpenSwitch = () => {
    setOpenDeactivateSwitch(true);
  };
  const handleDeactivateCloseSwitch = () => {
    setOpenDeactivateSwitch(false);
  };

  const handleSwitchChange = (event) => {
    if (event.target.checked === true) {
      handleOpenSwitch();
    } else {
      handleDeactivateOpenSwitch();
    }
    // setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    retrieveFareData();
  }, []);

  const goBackAccounts = () => {
    props.history.push("/configuration");
  };
  const show = () => {
    if (Showing) return;
    setShow(true);
    setShowing(true);
    // this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      // this.setState({ Show: false, Showing: false });
      setShow(false);
      setShowing(false);
    }, 2500);
  };

  // const [rows, setRows] = React.useState([
  //   createData(1, "12 AM to 6 AM", 0, 0, 0, 0),
  //   createData(2, "6 AM to 11 AM", 0, 0, 0, 0),
  //   createData(3, "11 AM to 4 PM", 0, 0, 0, 0),
  //   createData(4, "4 PM to 9 PM", 0, 0, 0, 0),
  //   createData(5, "9 PM to 12 AM", 0, 0, 0, 0),
  // ]);

  const [rows, setRows] = React.useState([
    createData(1, "12 AM to 6 AM", 0, 0, 0, 0, 0, 0, 0, 0),
    createData(2, "6 AM to 11 AM", 0, 0, 0, 0, 0, 0, 0, 0),
    createData(3, "11 AM to 4 PM", 0, 0, 0, 0, 0, 0, 0, 0),
    createData(4, "4 PM to 9 PM", 0, 0, 0, 0, 0, 0, 0, 0),
    createData(5, "9 PM to 12 AM", 0, 0, 0, 0, 0, 0, 0, 0),
  ]);
  const [previous, setPrevious] = React.useState({});

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeRadio = (event) => {
    setValue(event.target.value);
    setEditChange(false);
  };

  useEffect(() => {
    retrieveFareData();
  }, [value]);

  const handleChangeRadioPath = (event) => {
    setValuePath(event.target.value);
    setEditChange(false);
    // retrieveFareData();
  };
  const deactivateFare = (data) => {
    setState(false);
    setTimeout(() => {
      updateStatus(data);
      setOpenDeactivateSwitch(false);
    }, 100);

    // setState({ ...state, ["checkedA"]: false });
  };

  const activateFare = (data) => {
    // console.log("hererer");
    setState(true);
    // console.log(checkedA);
    setTimeout(() => {
      setOpenSwitch(false);
      updateStatus(data);
    }, 100);

    // setState({ ...state, ["checkedA"]: true });
  };
  useEffect(() => {
    retrieveFareData();
  }, [valuePath]);

  const handleChangeInnerTab = (event, newValue) => {
    setValueInner(newValue);
    setEditChange(false);
    // retrieveFareData();
  };

  useEffect(() => {
    retrieveFareData();
  }, [valueInnerTab]);

  const onChange = (e, row, data, names) => {
    if (data === "time") {
      if (!previous[row.id]) {
        setPrevious((state) => ({ ...state, [row.id]: row }));
      }
      const value = e;
      const name = names;
      const { id } = row;
      const newRows = rows.map((row) => {
        if (row.id === id) {
          return { ...row, [name]: value };
        }
        return row;
      });
      setRows(newRows);
    } else {
      // console.log(row);
      // console.log(e.target);
      if (!previous[row.id]) {
        setPrevious((state) => ({ ...state, [row.id]: row }));
      }
      const value = e.target.value;
      const name = e.target.name;
      const { id } = row;
      const newRows = rows.map((row) => {
        if (row.id === id) {
          return { ...row, [name]: value };
        }
        return row;
      });
      setRows(newRows);
    }
  };
  // const onToggleEditMode = (id) => {
  //   setRows((state) => {
  //     return rows.map((row) => {
  //       if (row.id === id) {
  //         return { ...row, isEditMode: !row.isEditMode };
  //       }
  //       return row;
  //     });
  //   });
  // };

  const onToggleEditMode = () => {
    setRows((state) => {
      return rows.map((row) => {
        return { ...row, isEditMode: !row.isEditMode };
      });
    });
  };

  const onRevert = (id) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious((state) => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };

  const handleOpen = () => {
    setOpen(true);
    getWeekEndFare();
    getWeekdayFares();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeDay = (event) => {
    switch (event.target.name) {
      case "monday":
        setMonday(!monday);
        break;
      case "tuesday":
        setTuesday(!tuesday);
        break;
      case "wednesday":
        setWednesday(!wednesday);
        break;
      case "thursday":
        setThursday(!thursday);
        break;
      case "friday":
        if (endFriday === true) {
          setFriday(!friday);
          setEndFriday(!endFriday);
        } else {
          setFriday(!friday);
        }
        break;
      case "saturday":
        setSaturday(!saturday);
        break;
      case "endFriday":
        if (friday === true) {
          setFriday(!friday);
          setEndFriday(!endFriday);
        } else {
          setEndFriday(!endFriday);
        }
        break;
      case "sunday":
        setSunday(!sunday);
        break;
      default:
        break;
    }
  };

  const getWeekdayFares = () => {
    let datas = {
      ride_type: "airport",
      vehicle_type: valueInnerTab === 0 ? "sedan" : "suv",
      vendor_type: "mmt",
      travel_type: valuePath,
      days_type: "weekdays",
    };
    // console.log(datas);
    axios
      .post(config.url + "htmlcrm/getvendorconfiguredfare", datas)
      .then((response) => {
        if (response.data.message === "successfull") {
          let data = response.data.fare_config;
          // console.log(data);
          // setFullData(response.data.fare_config);
          setWeekdayFare(data);
          setConfigureData(data.days, "weekdays");
        } else {
        }
      });
  };

  const setConfigureData = (days, type) => {
    for (let i = 0; i < days.length; i++) {
      let stringObj = days[i];
      // console.log(stringObj);
      if (type === "weekend") {
        if (stringObj === "friday") {
          stringObj = "endFriday";
        }
      } else {
        if (stringObj === "friday") {
          stringObj = "friday";
        }
      }
      handleDaysSwitch(stringObj);
    }
  };

  const handleDaysSwitch = (data) => {
    switch (data) {
      case "monday":
        setMonday(true);
        break;
      case "tuesday":
        setTuesday(true);
        break;
      case "wednesday":
        setWednesday(true);
        break;
      case "thursday":
        setThursday(true);
        break;
      case "friday":
        setFriday(true);
        break;
      case "saturday":
        // console.log("here");
        setSaturday(true);
        break;
      case "satuarday":
        // console.log("here");
        setSaturday(true);
        break;
      case "endFriday":
        setEndFriday(true);
        break;
      case "sunday":
        setSunday(true);
        break;
      default:
        break;
    }
  };

  const getWeekEndFare = () => {
    let datas = {
      ride_type: "airport",
      vehicle_type: valueInnerTab === 0 ? "sedan" : "suv",
      vendor_type: "mmt",
      travel_type: valuePath,
      days_type: "weekend",
    };
    // console.log(datas);
    axios
      .post(config.url + "htmlcrm/getvendorconfiguredfare", datas)
      .then((response) => {
        if (response.data.message === "successfull") {
          let data = response.data.fare_config;
          // console.log(data);
          setWeekEndFare(data);
          setConfigureData(data.days, "weekend");
        } else {
        }
      });
  };

  const retrieveFareData = () => {
    // Post API to retreive fare data from the server
    let datas = {
      ride_type: "airport",
      vehicle_type: valueInnerTab === 0 ? "sedan" : "suv",
      vendor_type: "mmt",
      travel_type: valuePath,
      days_type: value,
    };
    // console.log(datas);
    axios
      .post(config.url + "htmlcrm/getvendorconfiguredfare", datas)
      .then((response) => {
        // console.log(response.data);
        if (response.data.message === "successfull") {
          let data = response.data.fare_config.config;
          // console.log(data);
          setCheckData(response.data.fare_config);
          setFullData(response.data.fare_config);
          if (response.data.fare_status === "Active") {
            setState(true);
          } else {
            setState(false);
          }
          // setState();
          let count = 0;
          let arrayData = [];
          let sl_no = 1;
          console.log(data);
          data.forEach((indData) => {
            if (datas.vehicle_type === "sedan" && valuePath === "to_airport") {
              let range1 =
                indData.kms[0] !== undefined ? indData.kms[0].base_fare : 0;
              let range2 =
                indData.kms[1] !== undefined ? indData.kms[1].base_fare : 0;
              let range3 =
                indData.kms[2] !== undefined ? indData.kms[2].base_fare : 0;
              let range4 =
                indData.kms[3] !== undefined ? indData.kms[3].base_fare : 0;
              let range5 =
                indData.kms[4] !== undefined ? indData.kms[4].base_fare : 0;
              let range6 =
                indData.kms[5] !== undefined ? indData.kms[5].base_fare : 0;
              let range7 =
                indData.kms[6] !== undefined ? indData.kms[6].base_fare : 0;

              let extra_km = indData.extra_km;
              let minimum_time = moment(indData.minimum_hour, "hh").format(
                "hh A"
              );
              let maximum_time = moment(indData.maximum_hour, "hh").format(
                "hh A"
              );
              // console.log(minimum_time);
              // console.log(maximum_time);
              arrayData.push(
                createData(
                  sl_no,
                  minimum_time + " to " + maximum_time,
                  range1,
                  range2,
                  range3,
                  range4,
                  range5,
                  range6,
                  range7,

                  extra_km
                )
              );
            } else if (
              datas.vehicle_type === "sedan" &&
              valuePath === "from_airport"
            ) {
              let range1 =
                indData.kms[0] !== undefined ? indData.kms[0].base_fare : 0;
              let range2 =
                indData.kms[1] !== undefined ? indData.kms[1].base_fare : 0;
              let range3 =
                indData.kms[2] !== undefined ? indData.kms[2].base_fare : 0;
              let range4 =
                indData.kms[3] !== undefined ? indData.kms[3].base_fare : 0;
              let range5 =
                indData.kms[4] !== undefined ? indData.kms[4].base_fare : 0;
              let range6 =
                indData.kms[5] !== undefined ? indData.kms[5].base_fare : 0;
              let range7 =
                indData.kms[6] !== undefined ? indData.kms[6].base_fare : 0;

              let extra_km = indData.extra_km;
              let minimum_time = moment(indData.minimum_hour, "hh").format(
                "hh A"
              );
              let maximum_time = moment(indData.maximum_hour, "hh").format(
                "hh A"
              );
              // console.log(minimum_time);
              // console.log(maximum_time);
              arrayData.push(
                createData(
                  sl_no,
                  minimum_time + " to " + maximum_time,
                  range1,
                  range2,
                  range3,
                  range4,
                  range5,
                  range6,
                  range7,

                  extra_km
                )
              );
            } else {
              let range1 = 0;
              let range2 = 0;
              let range3 = 0;
              let range4 = 0;
              let range5 = 0;
              let range6 = indData.kms[0].base_fare;
              let range7 = 0;
              let extra_km = indData.extra_km;
              let minimum_time = moment(indData.minimum_hour, "hh").format(
                "hh A"
              );
              let maximum_time = moment(indData.maximum_hour, "hh").format(
                "hh A"
              );
              arrayData.push(
                createData(
                  sl_no,
                  minimum_time + " to " + maximum_time,
                  range1,
                  range2,
                  range3,
                  range4,
                  range5,
                  range6,
                  range7,

                  extra_km
                )
              );
            }

            sl_no++;
            count++;

            if (count === data.length) {
              // console.log(arrayData);
              setRows(arrayData);
            }
          });
        } else {
          if (valueInnerTab === 1) {
            setFullData([]);
            setCheckData([]);
            let sl_no = 1;
            setRows([
              createData(sl_no, "12 AM to 6 AM", 0, 0, 0, 0, 0, 0, 0, 0),
            ]);
          } else {
            setFullData([]);
            setCheckData([]);
            let defaultData = [
              createData(1, "12 AM to 6 AM", 0, 0, 0, 0, 0, 0, 0, 0),
              createData(2, "6 AM to 11 AM", 0, 0, 0, 0, 0, 0, 0, 0),
              createData(3, "11 AM to 4 PM", 0, 0, 0, 0, 0, 0, 0, 0),
              createData(4, "4 PM to 9 PM", 0, 0, 0, 0, 0, 0, 0, 0),
              createData(5, "9 PM to 12 AM", 0, 0, 0, 0, 0, 0, 0, 0),
            ];
            setRows(defaultData);
          }
        }
      });
  };

  // const weekData = () => {
  //   // Post API to retrieve days configuration
  // };

  const toggleSave = () => {
    setEditChange(!editChange);
    onToggleEditMode();
  };

  const toggleSaveCancel = () => {
    setEditChange(!editChange);
    retrieveFareData();
    onToggleEditMode();
  };

  const saveData = (e) => {
    if (e) {
      e.preventDefault();
    }
    console.log("Herererererer");
    setEditChange(!editChange);
    // console.log(rows);
    updateDataToServer();
  };
  // console.log(weekDayFare);

  const updateStatus = (data) => {
    let dataSend = {
      ride_type: "airport",
      user_id: sessionStorage.getItem("user_id"),
      vehicle_type: valueInnerTab === 0 ? "sedan" : "suv",
      vendor_type: "mmt",
      travel_type: valuePath,
      fare_status: data === true ? "Active" : "Inactive",
    };
    console.log(config.url);
    // console.log(dataSend);
    axios
      .post(config.url + "htmlcrm/updatevendorconfiguredfarestatus", dataSend)
      .then((response) => {
        // console.log(response);
        if (response.data.message === "successfull") {
          // data.fare_status === "Active" ? setState(true) : setState(false);
          retrieveFareData();
          setMessage(
            checkedA === true ? "Booking flow enabled" : "Booking flow disabled"
          );
          show();
          setSuccess(true);
        } else {
          retrieveFareData();
          setMessage("Failed to update data");
          show();
          setSuccess(false);
        }
      });
  };

  const updateDataToServer = () => {
    console.log(config.url);
    // console.log(checkedA);
    let dataToCheck = rows;
    let configs = [];
    let count = 0;
    let data;
    let minimim_hour;
    let maximum_hour;
    dataToCheck.forEach((dataToFormat) => {
      // console.log(dataToFormat);
      let timeData = dataToFormat.name;
      if (typeof timeData === "string") {
        let splitTimeData = timeData.split("to");
        minimim_hour = moment(splitTimeData[0], "hh A").format("HH");
        maximum_hour = moment(splitTimeData[1], "hh A").format("HH");
      } else {
        minimim_hour = moment(timeData[0], "hh:mm").format("HH");
        maximum_hour = moment(timeData[1], "hh:mm").format("HH");
      }
      // let splitTimeData = timeData.split("to");

      if (valueInnerTab === 0 && valuePath === "to_airport") {
        data = {
          minimum_hour: minimim_hour * 1,
          maximum_hour: maximum_hour * 1,
          extra_km: dataToFormat.per_km * 1,
          kms: [
            {
              min_km: 0,
              max_km: 20,
              base_fare: dataToFormat.range_1 * 1,
            },
            {
              min_km: 20,
              max_km: 25,
              base_fare: dataToFormat.range_2 * 1,
            },
            {
              min_km: 25,
              max_km: 30,
              base_fare: dataToFormat.range_3 * 1,
            },
            {
              min_km: 30,
              max_km: 35,
              base_fare: dataToFormat.range_4 * 1,
            },
            {
              min_km: 35,
              max_km: 40,
              base_fare: dataToFormat.range_5 * 1,
            },
            {
              min_km: 40,
              max_km: 45,
              base_fare: dataToFormat.range_6 * 1,
            },
            {
              min_km: 45,
              max_km: 50,
              base_fare: dataToFormat.range_7 * 1,
            },
          ],
        };
      } else if (valueInnerTab === 0 && valuePath === "from_airport") {
        data = {
          minimum_hour: minimim_hour * 1,
          maximum_hour: maximum_hour * 1,
          extra_km: dataToFormat.per_km * 1,
          kms: [
            {
              min_km: 0,
              max_km: 10,
              base_fare: dataToFormat.range_1 * 1,
            },
            {
              min_km: 10,
              max_km: 20,
              base_fare: dataToFormat.range_2 * 1,
            },
            {
              min_km: 20,
              max_km: 30,
              base_fare: dataToFormat.range_3 * 1,
            },
            {
              min_km: 30,
              max_km: 35,
              base_fare: dataToFormat.range_4 * 1,
            },
            {
              min_km: 35,
              max_km: 40,
              base_fare: dataToFormat.range_5 * 1,
            },
            {
              min_km: 40,
              max_km: 45,
              base_fare: dataToFormat.range_6 * 1,
            },
          ],
        };
      } else {
        data = {
          minimum_hour: 0 * 1,
          maximum_hour: 0 * 1,
          extra_km: dataToFormat.per_km * 1,
          kms: [
            {
              min_km: 0,
              max_km: 45,
              base_fare: dataToFormat.range_6 * 1,
            },
          ],
        };
      }

      configs.push(data);
      count++;
      if (count === rows.length) {
        let max_distance;

        if (valueInnerTab === 0 && valuePath === "to_airport") {
          max_distance = 50;
        } else {
          max_distance =
            fullData.max_distance === undefined ? 45 : fullData.max_distance;
        }

        let days;
        if (fullData.days === undefined || fullData.days.length === 0) {
          days =
            value === "weekdays"
              ? ["monday", "tuesday", "wednesday", "thursday"]
              : ["friday", "saturday", "sunday"];
        } else {
          days = fullData.days;
        }
        // console.log(checkedA);
        let dataSend = {
          ride_type: "airport",
          user_id: sessionStorage.getItem("user_id"),
          vehicle_type: valueInnerTab === 0 ? "sedan" : "suv",
          vendor_type: "mmt",
          travel_type: valuePath,
          days_type: value,
          fare_status: checkedA === true ? "Active" : "Inactive",
          oldTable: checkData,
          config_fare: {
            max_distance: max_distance * 1,
            type: value,
            days: days,
            config: configs,
          },
        };
        // console.log(dataSend);
        axios
          .post(config.url + "htmlcrm/updatevendorconfiguredfare", dataSend)
          .then((response) => {
            // console.log(response);
            if (response.data.message === "successfull") {
              retrieveFareData();
              setMessage("Fare changes updated successfully");
              show();
              setSuccess(true);
            } else {
              retrieveFareData();
              setMessage("Failed to update fares");
              show();
              setSuccess(false);
            }
          });

        // console.log(dataToSend);
      }
      // console.log(splitTimeData);
    });
  };

  // const updateDataToServer = () => {
  //   let dataToCheck = rows;
  //   let config = [];
  //   let count = 0;
  //   let data;
  //   dataToCheck.forEach((dataToFormat) => {
  //     console.log(dataToFormat);
  //     let timeData = dataToFormat.name;
  //     let splitTimeData = timeData.split("to");
  //     let minimim_hour = moment(splitTimeData[0], "hh A").format("HH");
  //     let maximum_hour = moment(splitTimeData[1], "hh A").format("HH");
  //     if (valueInnerTab === 0) {
  //       data = {
  //         minimum_hour: minimim_hour * 1,
  //         maximum_hour: maximum_hour * 1,
  //         extra_km: dataToFormat.per_km * 1,
  //         kms: [
  //           {
  //             min_km: 0,
  //             max_km: 20,
  //             base_fare: dataToFormat.range_1 * 1,
  //           },
  //           {
  //             min_km: 20,
  //             max_km: 35,
  //             base_fare: dataToFormat.range_2 * 1,
  //           },
  //           {
  //             min_km: 35,
  //             max_km: 45,
  //             base_fare: dataToFormat.range_3 * 1,
  //           },
  //         ],
  //       };
  //     } else {
  //       data = {
  //         minimum_hour: minimim_hour * 1,
  //         maximum_hour: maximum_hour * 1,
  //         extra_km: dataToFormat.per_km * 1,
  //         kms: [
  //           {
  //             min_km: 0,
  //             max_km: 45,
  //             base_fare: dataToFormat.range_3 * 1,
  //           },
  //         ],
  //       };
  //     }

  //     config.push(data);
  //     count++;
  //     if (count === rows.length) {
  //       let dataSend = {
  //         ride_type: "airport",
  //         user_id: "5d07363d8a332a0af0df7b59",
  //         vehicle_type: valueInnerTab === 0 ? "sedan" : "suv",
  //         vendor_type: "mmt",
  //         travel_type: valuePath,
  //         days_type: value,
  //         config_fare: {
  //           max_distance: fullData.max_distance * 1,
  //           type: value,
  //           days: fullData.days,
  //           config: config,
  //         },
  //       };
  //       console.log(dataSend);
  //       // axios
  //       //   .post(
  //       //     "https://dispatcher.gloryindia.inhtmlcrm/updatevendorconfiguredfare",
  //       //     dataSend
  //       //   )
  //       //   .then((response) => {
  //       //     if (response.data.message === "successfull") {
  //       //       retrieveFareData();
  //       //       setMessage("Changes have been updated successfully");
  //       //       show();
  //       //       setSuccess(true);
  //       //     } else {
  //       //       retrieveFareData();
  //       //       setMessage("Changes have been updated successfully");
  //       //       show();
  //       //       setSuccess(false);
  //       //     }
  //       //   });

  //       // console.log(dataToSend);
  //     }
  //     // console.log(splitTimeData);
  //   });
  // };

  const saveFareData = () => {
    updateWeekDay();
    updateWeekEnd();
  };

  const updateWeekDay = () => {
    if (weekDayFare !== undefined || weekDayFare.length > 0) {
      let data = weekDayFare;
      let arrayData = [];
      if (monday) arrayData.push("monday");
      if (tuesday) arrayData.push("tuesday");
      if (wednesday) arrayData.push("wednesday");
      if (thursday) arrayData.push("thursday");
      if (friday) arrayData.push("friday");
      // console.log(arrayData);
      data.days = arrayData;
      // let dataToAdd = []

      let dataSend = {
        ride_type: "airport",
        user_id: "5d07363d8a332a0af0df7b59",
        vehicle_type: valueInnerTab === 0 ? "sedan" : "suv",
        vendor_type: "mmt",
        travel_type: valuePath,
        days_type: "weekdays",
        config_fare: data,
      };
      // console.log(dataSend);
      axios
        .post(config.url + "htmlcrm/updatevendorconfiguredfare", dataSend)
        .then((response) => {
          if (response.data.message === "successfull") {
            setOpen(false);
            retrieveFareData();
            setMessage("Fare changes updated successfully");
            show();
            setSuccess(true);
          } else {
            retrieveFareData();
            setMessage("Failed to update fares");
            show();
            setSuccess(false);
          }
        });
    } else {
      setOpen(false);
      retrieveFareData();
      setMessage("Changes have been updated successfully");
      show();
      setSuccess(true);
    }
  };
  const updateWeekEnd = () => {
    if (weekEndFare !== undefined || weekEndFare.length > 0) {
      let data = weekEndFare;
      let arrayData = [];
      if (endFriday) arrayData.push("friday");
      if (saturday) arrayData.push("saturday");
      if (sunday) arrayData.push("sunday");
      data.days = arrayData;
      // console.log(arrayData);
      let dataSend = {
        ride_type: "airport",
        user_id: "5d07363d8a332a0af0df7b59",
        vehicle_type: valueInnerTab === 0 ? "sedan" : "suv",
        vendor_type: "mmt",
        travel_type: valuePath,
        days_type: "weekend",
        config_fare: data,
      };
      // console.log(dataSend);
      axios
        .post(config.url + "htmlcrm/updatevendorconfiguredfare", dataSend)
        .then((response) => {
          if (response.data.message === "successfull") {
            retrieveFareData();
            setOpen(false);
            setMessage("Fare changes updated successfully");
            show();
            setSuccess(true);
          } else {
            retrieveFareData();
            setMessage("Failed to update fares");
            show();
            setSuccess(false);
          }
        });
    } else {
      setOpen(false);
      retrieveFareData();
      setMessage("Changes have been updated successfully");
      show();
      setSuccess(true);
    }
  };
  const goBack = () => {
    props.history.goBack();
  };
  // console.log(rows);

  return (
    <Container fluid className="main-content-container">
      <Row className="px-1 px-md-4 py-md-3">
        <Col className="col-12 col-md-12">
          <Row className="w-100">
            <Col className="col-12">
              <Breadcrumb>
                <BreadcrumbItem>
                  <a href="#" onClick={goBackAccounts}>
                    Configuration /
                  </a>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>

            <Col className="col-12 mt-md-1">
              <PageTitle
                title="Fare configuration"
                className="text-sm-left mb-3 pl-0 custom-margin"
              />
            </Col>
          </Row>
          <Row className="">
            <Col className="col-12 col-md-12 col-lg-12">
              <div className="float-right mt-2">
                <Buttons
                  className=""
                  id="goback-button"
                  onClick={goBack}
                  theme="dark"
                >
                  <i
                    className="fas fa-arrow-left"
                    style={{ marginRight: "4px", marginLeft: "-2px" }}
                  />
                  <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                    BACK
                  </span>
                </Buttons>
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="col-12 py-3 mt-md-2">
          <span
            style={{
              fontSize: "1.1rem",
              fontWeight: "700",
              cursor: "pointer",
              color: "black",
            }}
          >
            MMT
          </span>
          <span style={{ float: "right" }} onClick={handleOpen}>
            <SettingsIcon
              fontSize="medium"
              className="px-1 py-1"
              style={{ color: "black" }}
            />
            <label
              className="my-auto"
              style={{
                fontSize: "0.9rem",
                fontWeight: "400",
                cursor: "pointer",
                color: "black",
              }}
            >
              Configure days
            </label>
          </span>
        </Col>
        <Modal
          show={openDeactivateSwitch}
          centered
          size="md"
          backdrop="static"
          onHide={handleDeactivateCloseSwitch}
        >
          <Modal.Header style={{ borderBottom: "none" }} closeButton>
            Deactivate flow{" "}
          </Modal.Header>
          <Modal.Body>
            <Typography style={{ fontSize: "1rem" }}>
              Are you sure you want to deactivate{" "}
              <label style={{ fontWeight: 600 }}>
                {valuePath === "to_airport"
                  ? "city to airport"
                  : "airport to city"}
              </label>{" "}
              flow ?
            </Typography>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <div>
              <SaveButton
                className="mx-2"
                onClick={handleDeactivateCloseSwitch}
              >
                CANCEL
              </SaveButton>
              <SaveButton
                className="mx-2"
                onClick={(e) => deactivateFare(false)}
              >
                CONFIRM
              </SaveButton>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={openSwitch}
          centered
          size="md"
          backdrop="static"
          onHide={handleCloseSwitch}
        >
          <Modal.Header closeButton style={{ borderBottom: "none" }}>
            Activate flow{" "}
          </Modal.Header>
          <Modal.Body>
            <Typography style={{ fontSize: "1rem" }}>
              Are you sure you want to deactivate{" "}
              <label style={{ fontWeight: 600 }}>
                {valuePath === "to_airport"
                  ? "city to airport"
                  : "airport to city"}
              </label>{" "}
              flow ?
            </Typography>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <div>
              <SaveButton className="mx-2" onClick={handleCloseSwitch}>
                CANCEL
              </SaveButton>
              <SaveButton className="mx-2" onClick={(e) => activateFare(true)}>
                CONFIRM
              </SaveButton>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={open}
          onHide={handleClose}
          centered
          size="lg"
          backdrop="static"
        >
          <Modal.Header style={{ borderBottom: "none" }} className="mt-4">
            <h5 style={{ fontWeight: 700, color: "black" }}>Configure days</h5>
            <span style={{ float: "right" }}>
              <ColorButton variant="outlined" onClick={handleClose}>
                Close
              </ColorButton>
            </span>
          </Modal.Header>
          <Modal.Body className="mt-2 pb-2">
            <Row className="mb-3">
              <Col className="col-12">
                <FormControl component="fieldset" className="mb-4">
                  <FormLabel component="legend" className={classes.formLabel}>
                    Weekdays
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      className="pr-3"
                      control={
                        <CustomColorCheckbox
                          checked={monday}
                          onChange={handleChangeDay}
                          name="monday"
                        />
                      }
                      style={{ fontSize: "0.5rem !important" }}
                      label="Monday"
                    />
                    <FormControlLabel
                      className="pr-3"
                      control={
                        <CustomColorCheckbox
                          checked={tuesday}
                          onChange={handleChangeDay}
                          name="tuesday"
                        />
                      }
                      label="Tuesday"
                    />
                    <FormControlLabel
                      className="pr-3"
                      control={
                        <CustomColorCheckbox
                          checked={wednesday}
                          onChange={handleChangeDay}
                          name="wednesday"
                        />
                      }
                      label="Wednesday"
                    />
                    <FormControlLabel
                      className="pr-3"
                      control={
                        <CustomColorCheckbox
                          checked={thursday}
                          onChange={handleChangeDay}
                          name="thursday"
                        />
                      }
                      label="Thursday"
                    />
                    <FormControlLabel
                      className="pr-3"
                      control={
                        <CustomColorCheckbox
                          checked={friday}
                          onChange={handleChangeDay}
                          name="friday"
                        />
                      }
                      label="Friday"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend" className={classes.formLabel}>
                    Weekends
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      className="pr-3"
                      control={
                        <CustomColorCheckbox
                          checked={endFriday}
                          onChange={handleChangeDay}
                          name="endFriday"
                        />
                      }
                      label="Friday"
                    />
                    <FormControlLabel
                      className="pr-3"
                      control={
                        <CustomColorCheckbox
                          checked={saturday}
                          onChange={handleChangeDay}
                          name="saturday"
                        />
                      }
                      label="Saturday"
                    />
                    <FormControlLabel
                      className="pr-3"
                      control={
                        <CustomColorCheckbox
                          checked={sunday}
                          onChange={handleChangeDay}
                          name="sunday"
                        />
                      }
                      label="Sunday"
                    />
                  </FormGroup>
                </FormControl>
              </Col>
              <Col className="col-12 mt-5 mb-4 d-flex justify-content-center">
                <SaveButton
                  variant="contained"
                  size="large"
                  style={{ minWidth: "240px" }}
                  className="py-3 "
                  onClick={saveFareData}
                >
                  SAVE CHANGES
                </SaveButton>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Col className="col-12 h-100 mb-md-3 px-1 px-md-3">
          <Card style={{ minHeight: "60vh" }}>
            <Card.Body className="px-1 px-md-5">
              <div className={classes.root}>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>Airport</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: "block" }}
                    className="px-md-4"
                  >
                    <Row>
                      <Col className="col-12"></Col>
                    </Row>
                    <Row>
                      <Col className="col-12 col-md-6">
                        <ThemeProvider theme={theme}>
                          <Tabs
                            value={valueInnerTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChangeInnerTab}
                            aria-label="inner tabs"
                            TabIndicatorProps={{
                              style: {
                                display: "none",
                              },
                            }}
                          >
                            <AntTab
                              label="Sedan"
                              className={classes.tabsRoot}
                              style={{ minWidth: "10%" }}
                            />
                            <AntTab
                              label="SUV"
                              className={classes.tabsRoot}
                              style={{ minWidth: "10%" }}
                            />
                          </Tabs>
                        </ThemeProvider>
                      </Col>
                      <Col className="col-12 col-md-6 d-flex justify-content-end">
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            aria-label="days"
                            name="days"
                            value={valuePath}
                            onChange={handleChangeRadioPath}
                          >
                            <FormControlLabel
                              className="px-4"
                              value="to_airport"
                              control={<RadioButton />}
                              label="City to airport"
                            />
                            <FormControlLabel
                              className="px-4"
                              value="from_airport"
                              control={<RadioButton />}
                              label="Airport to city"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Col>
                      <Col className="col-12">
                        <Card>
                          <Card.Body className="px-md-2 py-md-5 p-0">
                            <Row className="py-2 px-3">
                              <Col className="col-12 col-md-12">
                                <Typography
                                  // style={{ fontWeight: 600 }}
                                  className="d-flex"
                                >
                                  Booking flow:
                                  {checkedA === true ? (
                                    <Typography
                                      style={{
                                        fontWeight: 700,
                                      }}
                                      className="mx-2"
                                    >
                                      Enabled
                                    </Typography>
                                  ) : (
                                    <Typography
                                      style={{
                                        fontWeight: 700,
                                      }}
                                      className="mx-2"
                                    >
                                      Disabled
                                    </Typography>
                                  )}
                                </Typography>
                                <FormControlLabel
                                  control={
                                    <BlackSwitch
                                      checked={checkedA}
                                      onChange={handleSwitchChange}
                                      name="checkedA"
                                    />
                                  }
                                />
                              </Col>
                            </Row>
                            <form onSubmit={saveData} className="px-0 row">
                              <Col className="col-12 col-md-6 px-0">
                                <FormControl component="fieldset">
                                  <RadioGroup
                                    row
                                    aria-label="days"
                                    name="days"
                                    value={value}
                                    onChange={handleChangeRadio}
                                  >
                                    <FormControlLabel
                                      className="px-4"
                                      value="weekdays"
                                      control={<RadioButton />}
                                      label="Weekdays"
                                    />
                                    <FormControlLabel
                                      className="px-4"
                                      value="weekend"
                                      control={<RadioButton />}
                                      label="Weekend"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Col>
                              <Col className="col-12 col-md-6 d-flex justify-content-end">
                                {editChange === false && (
                                  <div>
                                    <SaveButton
                                      onClick={toggleSave}
                                      className="mx-2"
                                    >
                                      EDIT
                                    </SaveButton>
                                  </div>
                                )}
                                {editChange === true && (
                                  <div className="d-flex justify-content-center">
                                    <SaveButton
                                      className="mx-2 my-2"
                                      type="submit"
                                    >
                                      SAVE
                                    </SaveButton>
                                    <SaveButton
                                      className="mx-2 my-2"
                                      onClick={toggleSaveCancel}
                                    >
                                      CANCEL
                                    </SaveButton>
                                  </div>
                                )}
                              </Col>
                              <Col className="col-12">
                                <Paper
                                  className={classes.tableRoot}
                                  elevation="0"
                                >
                                  <Table
                                    className={classes.table}
                                    aria-label="caption table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          align="left"
                                          className={classes.tabHeader}
                                        >
                                          Sl no
                                        </TableCell>

                                        {valueInnerTab === 0 &&
                                          valuePath === "to_airport" && (
                                            <React.Fragment>
                                              <TableCell
                                                align="left"
                                                className={classes.tabHeader}
                                              >
                                                Time slot
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className={classes.tabHeader}
                                              >
                                                0 km to 20 km
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className={classes.tabHeader}
                                              >
                                                20 km to 25 km
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className={classes.tabHeader}
                                              >
                                                25 km to 30 km
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className={classes.tabHeader}
                                              >
                                                30 km to 35 km
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className={classes.tabHeader}
                                              >
                                                35 km to 40 km
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className={classes.tabHeader}
                                              >
                                                40 km to 45 km
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className={classes.tabHeader}
                                              >
                                                45 km to 50 km
                                              </TableCell>
                                            </React.Fragment>
                                          )}
                                        {valueInnerTab === 0 &&
                                          valuePath === "from_airport" && (
                                            <React.Fragment>
                                              <TableCell
                                                align="left"
                                                className={classes.tabHeader}
                                              >
                                                Time slot
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className={classes.tabHeader}
                                              >
                                                0 km to 10 km
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className={classes.tabHeader}
                                              >
                                                10 km to 20 km
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className={classes.tabHeader}
                                              >
                                                20 km to 30 km
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className={classes.tabHeader}
                                              >
                                                30 km to 35 km
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className={classes.tabHeader}
                                              >
                                                35 km to 40 km
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className={classes.tabHeader}
                                              >
                                                40 km to 45 km
                                              </TableCell>
                                            </React.Fragment>
                                          )}
                                        {valueInnerTab === 1 && (
                                          <TableCell
                                            align="left"
                                            className={classes.tabHeader}
                                          >
                                            0 km to 45 km
                                          </TableCell>
                                        )}

                                        <TableCell
                                          align="left"
                                          className={classes.tabHeader}
                                        >
                                          Per km fare
                                        </TableCell>
                                        {/* <TableCell
                                          align="left"
                                          className={classes.tabHeader}
                                        >
                                          Action
                                        </TableCell> */}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {rows.map((row) => (
                                        <TableRow key={row.id}>
                                          <CustomTableCell
                                            {...{
                                              row,
                                              name: "sl_no",
                                              onChange,
                                            }}
                                          />

                                          {valueInnerTab === 0 &&
                                            valuePath === "from_airport" && (
                                              <React.Fragment>
                                                <CustomTableCell
                                                  {...{
                                                    row,
                                                    name: "name",
                                                    onChange,
                                                  }}
                                                />
                                                <CustomTableCell
                                                  {...{
                                                    row,
                                                    name: "range_1",
                                                    onChange,
                                                  }}
                                                />
                                                <CustomTableCell
                                                  {...{
                                                    row,
                                                    name: "range_2",
                                                    onChange,
                                                  }}
                                                />
                                                <CustomTableCell
                                                  {...{
                                                    row,
                                                    name: "range_3",
                                                    onChange,
                                                  }}
                                                />
                                                <CustomTableCell
                                                  {...{
                                                    row,
                                                    name: "range_4",
                                                    onChange,
                                                  }}
                                                />
                                                <CustomTableCell
                                                  {...{
                                                    row,
                                                    name: "range_5",
                                                    onChange,
                                                  }}
                                                />
                                                <CustomTableCell
                                                  {...{
                                                    row,
                                                    name: "range_6",
                                                    onChange,
                                                  }}
                                                />
                                              </React.Fragment>
                                            )}

                                          {valueInnerTab === 0 &&
                                            valuePath === "to_airport" && (
                                              <React.Fragment>
                                                <CustomTableCell
                                                  {...{
                                                    row,
                                                    name: "name",
                                                    onChange,
                                                  }}
                                                />
                                                <CustomTableCell
                                                  {...{
                                                    row,
                                                    name: "range_1",
                                                    onChange,
                                                  }}
                                                />
                                                <CustomTableCell
                                                  {...{
                                                    row,
                                                    name: "range_2",
                                                    onChange,
                                                  }}
                                                />
                                                <CustomTableCell
                                                  {...{
                                                    row,
                                                    name: "range_3",
                                                    onChange,
                                                  }}
                                                />
                                                <CustomTableCell
                                                  {...{
                                                    row,
                                                    name: "range_4",
                                                    onChange,
                                                  }}
                                                />
                                                <CustomTableCell
                                                  {...{
                                                    row,
                                                    name: "range_5",
                                                    onChange,
                                                  }}
                                                />

                                                <CustomTableCell
                                                  {...{
                                                    row,
                                                    name: "range_6",
                                                    onChange,
                                                  }}
                                                />
                                                <CustomTableCell
                                                  {...{
                                                    row,
                                                    name: "range_7",
                                                    onChange,
                                                  }}
                                                />
                                              </React.Fragment>
                                            )}

                                          {valueInnerTab === 1 && (
                                            <CustomTableCell
                                              {...{
                                                row,
                                                name: "range_6",
                                                onChange,
                                              }}
                                            />
                                          )}

                                          <CustomTableCell
                                            {...{
                                              row,
                                              name: "per_km",
                                              onChange,
                                            }}
                                          />
                                          {/* <TableCell
                                            className={classes.tableCell}
                                          >
                                            {row.isEditMode ? (
                                              <>
                                                <IconButton
                                                  aria-label="done"
                                                  onClick={() =>
                                                    onToggleEditMode(row.id)
                                                  }
                                                >
                                                  <DoneIcon />
                                                </IconButton>
                                                <IconButton
                                                  aria-label="revert"
                                                  onClick={() =>
                                                    onRevert(row.id)
                                                  }
                                                >
                                                  <RevertIcon />
                                                </IconButton>
                                              </>
                                            ) : (
                                              <IconButton
                                                aria-label="delete"
                                                onClick={() =>
                                                  onToggleEditMode(row.id)
                                                }
                                              >
                                                <EditIcon />
                                              </IconButton>
                                            )}
                                          </TableCell> */}
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </Paper>
                              </Col>
                            </form>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography className={classes.heading}>Rental</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography></Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography className={classes.heading}>
                      Outstation
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography></Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {success === false && (
        <ReactSnackBar
          Icon={
            <i
              className="far fa-times-circle fa-lg"
              style={{ color: "#FF0000" }}
            />
          }
          Show={Show}
        >
          {message}
        </ReactSnackBar>
      )}
      {success === true && (
        <ReactSnackBar
          Icon={
            <span>
              <i
                className="far fa-check-circle fa-lg"
                style={{ color: "#26FF58" }}
              />
            </span>
          }
          Show={Show}
        >
          {message}
        </ReactSnackBar>
      )}
    </Container>
  );
}

export default FareConfiguration;
