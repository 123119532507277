import React, { Component } from "react";

import ReactTable from "react-table-v6";
// import classNames from "classnames";
import "react-table-v6/react-table.css";
import ReactSnackBar from "react-js-snackbar";
// import GoogleMapReact from 'google-maps-react'
import SimpleMap from "./../utilities/ModalMap";
// import LiveMap from "./../utilities/LiveGmaps";
import LiveMap from "./../utilities/LiveMapNew";
import LiveMaps from "../utilities/LiveMaps";
import PolyLineMap from "./../utilities/PolyLineMap";
import PropTypes from "prop-types";
import config from "../../constants/constants";
import Stepper from "./StepperRide";
import axios from "axios";
import StepperCancel from "./StepperCancel";
import StepperPoll from "./StepperPoll";
import moment from "moment";
import equal from "fast-deep-equal";
import TripDump from "./TripDump";
import ColumnConvo from "./ColumnConvo";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  FormCheckbox,
  FormInput,
  FormTextarea,
  CardFooter,
} from "shards-react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
// import DatePicker from "react-date-picker";
import InputData from "./../common/InputData";
import RideIssues from "./RideIssues";
import StepQuick from "./StepperQickride";
// import FineData from "./../common/FineData"

function capitalizeFirstLetter(string) {
  // console.log(string);
  return string[0].toUpperCase() + string.slice(1);
}

function getDataValue(data, type, originalvalue) {
  if (type === "conscent_taken") {
    return data === true ? "Yes" : "No";
  } else if (type === "toll_route") {
    if (originalvalue.conscent_taken === true) {
      return data === true ? "Toll route" : "Non-toll route";
    } else {
      return "-";
    }
  } else {
    if (originalvalue.conscent_taken === true) {
      return moment(new Date(data)).format("DD-MM-YY [at] hh:mm:ss A");
    } else {
    }
  }
}

class TripDetails extends Component {
  constructor(props) {
    super(props); //since we are extending class Table so we have to use super in order to override Component class constructor
    // this.updateContent = this.updateContent.bind(this);
    this.state = {
      //state is by default an object
      tripdata: [],
      detachOpen: false,
      driverLocationData: [],
      reassignOpen: false,
      cancelOpen: false,
      cnoshow: false,
      breakdown: false,
      flightDelay: false,
      dnoshow: false,
      otpNumber: "",
      otp: "",
      otpVerCode: "",
      textareaValue: "",
      setVisible: false,
      editable: true,
      offeredAmount: "",
      tagOfferedAmount: "",
      extra_traveled_kms: "",
      empty_kms: "",
      fineAmount: "",
      fineReason: "",
      buttonName: "UPDATE RIDE DETAILS",
      success: false,
      Show: false,
      Showing: false,
      message: "",
      cancel_reason: "",
      otpGen: "",
      reviewList: [],
      issueTitle: "",
      issueDescription: "",
      convoDetails: [],
      switchDiv: false,
      replyDescription: "",
      statusLocation: [],
      tripDump: false,
      rideIssue: false,
      passData: [],
      toll_ticket: "",
      vendor: "",
      sequence: [],
      tagDriver: false,
      tagPhonenumber: "",
      searchFound: false,
      searchData: [],
      extrarideamount: 0,
      showTagging: false,
      showBox: false,
      storeTagData: [],
      storePayData: [],
      showPayBox: false,
      beneficiaryStatus: false,
      paid_status: false,
      deactivate: false,
      tollConsentDetails: [],
      openPayNow: false,
    };
    // console.log(this.props);
    this.toggleReassign = this.toggleReassign.bind(this);
    this.toggleDetach = this.toggleDetach.bind(this);
    this.toggleCancel = this.toggleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchDriverLocation = this.fetchDriverLocation.bind(this);
    this.gencancelOTP = this.gencancelOTP.bind(this);
    this.verifycancelOTP = this.verifycancelOTP.bind(this);
    this.handleOtpChange = this.handleOtpChange.bind(this);
    this.reAssign = this.reAssign.bind(this);
    this.cancelRide = this.cancelRide.bind(this);
    this.DetachRide = this.DetachRide.bind(this);
    this.handletextareaChange = this.handletextareaChange.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.callApitoUpdateData = this.callApitoUpdateData.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.updateOfferedAmount = this.updateOfferedAmount.bind(this);
    this.updateExtraTraveledLms = this.updateExtraTraveledLms.bind(this);
    this.updateFineAmount = this.updateFineAmount.bind(this);
    this.updateEmptyKms = this.updateEmptyKms.bind(this);
    this.handleAssignClick = this.handleAssignClick.bind(this);
    this.callAutoAssign = this.callAutoAssign.bind(this);
    this.onReasonChange = this.onReasonChange.bind(this);
    this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
    this.getReports = this.getReports.bind(this);
    this.submitIssue = this.submitIssue.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.submitReply = this.submitReply.bind(this);
    this.closeReply = this.closeReply.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.getTripStatusLocations = this.getTripStatusLocations.bind(this);
    this.enableTripDump = this.enableTripDump.bind(this);
    this.enableRideIssue = this.enableRideIssue.bind(this);
    this.enableTripDetails = this.enableTripDetails.bind(this);
    this.getRideDetails = this.getRideDetails.bind(this);
    this.callToggle = this.callToggle.bind(this);
    this.updateTagFare = this.updateTagFare.bind(this);
    this.checkForBeneficiary = this.checkForBeneficiary.bind(this);
    this.checkForPaidStatus = this.checkForPaidStatus.bind(this);
    this.payToDriver = this.payToDriver.bind(this);
    // console.log(props);
  }

  openTogglePayNow = () => {
    this.setState({
      openPayNow: true,
    });
  };

  closeTogglePayNow = () => {
    this.setState({
      openPayNow: false,
    });
  };

  payToDriver = () => {
    this.setState(
      {
        deactivate: false,
      },
      () => {
        let dataToSend = {
          trip_id: this.state.tripdata.trip_id,
          transfer_initiatedBy: window.sessionStorage.getItem("user_id"),
        };

        axios
          .post(config.url + "payout/payNowThroughCashfree", dataToSend)
          .then((response) => {
            console.log("pay to driver");
            console.log(response.data);
            if (response.data.message === "successfull") {
              // this.checkForPaidStatus();
              if (
                response.data.status === "success" ||
                response.data.status === "completed" ||
                response.data.status === "pending"
              ) {
                this.setState(
                  {
                    success: true,
                    message: "Payment made to driver",
                  },
                  () => {
                    this.show();
                    this.checkForPaidStatus();
                    this.closeTogglePayNow();
                  }
                );
              } else {
                this.setState(
                  {
                    success: true,
                    message: "Payment initiated. Please wait .....",
                  },
                  () => {
                    this.show();
                    this.checkForPaidStatus();
                    this.closeTogglePayNow();
                  }
                );
              }
            } else {
              this.setState(
                {
                  deactivate: false,
                  success: false,
                  message: "Payment to driver failed",
                },
                () => this.show()
              );
            }
          })
          .catch();
      }
    );
  };

  checkforTagging = () => {
    let dataToSend = {
      trip_id: this.props.tripId,
    };
    console.log(dataToSend);
    axios
      .post(config.url + "payout/getTaggedDriverDetails", dataToSend)
      .then((response) => {
        console.log(response);
        if (response.data.message === "successfull") {
          this.setState({
            showTagging: false,
            showBox: true,
            storeTagData: response.data.data,
          });
        } else {
          this.setState({
            showTagging: true,
            showBox: false,
            storeTagData: [],
          });
        }
      });
  };

  checkforPayout = () => {
    let dataToSend = {
      trip_id: this.props.tripId,
    };
    axios
      .post(config.url + "payout/getmanualpayment", dataToSend)
      .then((response) => {
        console.log(response);
        console.log("*************************");
        if (response.data.message === "successfull") {
          if (
            response.data.is_payout_exists === true &&
            response.data.is_manual_payment === true
          ) {
            this.setState({
              showPayBox: true,
              storePayData: response.data.response,
            });
          } else if (
            response.data.is_payout_exists === true &&
            response.data.is_manual_payment === false
          ) {
            this.setState({ storePayData: [], showPayBox: false });
          }
          // this.setState({
          //   showTagging: false,
          //   showBox: true,
          //   storeTagData: response.data.data,
          // });
        } else {
          this.setState({ storePayData: [], showPayBox: false });
          // this.setState({
          //   showTagging: true,
          //   showBox: false,
          //   storeTagData: [],
          // });
        }
      });
  };

  searchFoundTest = () => {
    let dataToSend = {
      phonenumber: this.state.tagPhonenumber,
    };
    axios
      .post(config.url + "payout/getDriverDetails", dataToSend)
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "successfull") {
          this.setState({
            searchFound: true,
            searchData: response.data.data,
          });
        } else {
          this.setState(
            {
              success: false,
              message: "Driver not found",
              searchFound: false,
              searchData: [],
            },
            this.show()
          );
        }
      });
  };

  tagDriverToTheRide = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (
      this.state.tagOfferedAmount === undefined ||
      this.state.tagOfferedAmount === null
    ) {
      this.setState(
        {
          success: false,
          message: "Offered amount cannot be empty",
        },
        () => {
          this.show();
        }
      );
    } else {
      this.updateOfferedAmount(this.state.tagOfferedAmount);
      let dataToSend = {
        dunique_id: this.state.searchData.dunique_id,
        trip_id: this.props.tripId,
        tagged_by: window.sessionStorage.getItem("user_id"),
        status: "tagged",
      };
      axios
        .post(config.url + "payout/tagDriverToTheRide", dataToSend)
        .then((response) => {
          if (response.data.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "The trip has been tagged",
                tagPhonenumber: "",
                tagDriver: !this.state.tagDriver,
              },
              () => {
                this.show();
              }
            );
          } else {
            this.setState(
              {
                success: false,
                message: "Failed to tag the trip",
              },
              () => {
                this.show();
              }
            );
          }
        });
    }
  };

  inputTagData = (e) => {
    this.setState({
      tagPhonenumber: e.target.value,
    });
  };

  inputTagOfferedAmount = (e) => {
    this.setState({
      tagOfferedAmount: e.target.value,
    });
  };

  callToggle(data) {
    this.setState({
      clicked: !this.state.clicked,
    });
  }

  enableTripDetails() {
    if (this.state.tripDump === undefined) {
      this.setState({
        tripDump: false,
      });
    }
  }

  enableRideIssue() {
    this.setState(
      {
        rideIssue: !this.state.rideIssue,
      },
      () => {
        this.enableTripDetails();
      }
    );
  }

  enableTripDump() {
    this.setState({
      tripDump: !this.state.tripDump,
    });
  }

  getTripStatusLocations() {
    // console.log("I am here");
    // console.log(this.props.tripId);
    fetch(config.url + "booking/getTripStatusLocations", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        trip_id: this.props.tripId,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("I am here too");
          // console.log(result);
          if (result.message === "successfull") {
            let data = {
              drop_latlon: result.drop_latlon,
              end_time: result.end_time,
              leaveforpickup_time: result.leaveforpickup_time,
              notify_latlon: result.notify_latlon,
              notify_time: result.notify_time,
              start_latlon: result.start_latlon,
              start_time: result.start_time,
              toll_info: result.toll_info,
            };
            if (result.toll_info === undefined) {
              this.setState({
                statusLocation: data,
                toll_ticket: "",
              });
            } else {
              this.setState({
                statusLocation: data,
                toll_ticket:
                  result.toll_info.toll_ticket_url === undefined
                    ? ""
                    : result.toll_info.toll_ticket_url,
              });
            }
          } else {
            this.setState({
              statusLocation: [],
            });
          }
          // console.log("herererer");
          // this.setState({
          //   convoDetails: result.data,
          // });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  refreshList() {
    let data = {
      issue_id: this.state.convoDetails.issue_id,
    };
    // console.log(data);
    this.getConversation(data);
  }

  closeReply() {
    // console.log("Herererer");
    this.setState({
      replyDescription: "",
      switchDiv: false,
    });
  }

  handleClick(data) {
    this.setState(
      {
        passData: data,
      },
      () => {
        this.enableRideIssue();
      }
    );
  }

  getConversation(data) {
    fetch(config.url + "htmlcrm/getIssueData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        issue_id: data.issue_id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          // console.log("herererer");
          this.setState({
            convoDetails: result.data,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  submitIssue() {
    if (
      this.state.issueTitle === undefined ||
      this.state.issueTitle === null ||
      this.state.issueTitle === "" ||
      this.state.issueDescription === undefined ||
      this.state.issueDescription === null ||
      this.state.issueDescription === ""
    ) {
      this.setState(
        {
          success: false,
          message: "Title or description cannot be empty",
        },
        () => {
          this.show();
        }
      );
    } else {
      fetch(config.url + "htmlcrm/createRideIssue", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          trip_id: this.props.tripId,
          userid: window.sessionStorage.getItem("user_id"),
          username: window.sessionStorage.getItem("username"),
          title: this.state.issueTitle,
          message: this.state.issueDescription,
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log("herererer");
            this.setState(
              {
                issueTitle: "",
                issueDescription: "",
                success: true,
                message: "New issue has been created",
              },
              () => {
                this.getReports();
                this.show();
              }
            );
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    }
  }

  submitReply() {
    if (
      this.state.replyDescription === undefined ||
      this.state.replyDescription === null ||
      this.state.replyDescription === ""
    ) {
      this.setState(
        {
          success: false,
          message: "Reply cannot be empty",
        },
        () => {
          this.show();
        }
      );
    } else {
      fetch(config.url + "htmlcrm/addCommentToRI", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          issue_id: this.state.convoDetails.issue_id,
          userid: window.sessionStorage.getItem("user_id"),
          username: window.sessionStorage.getItem("username"),
          status: "open",
          message: this.state.replyDescription,
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log("herererer");
            this.setState(
              {
                replyDescription: "",
              },
              () => {
                let data = {
                  issue_id: this.state.convoDetails.issue_id,
                };
                this.getConversation(data);
              }
            );
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    }
  }

  capitalizeFirstLetter(string) {
    // console.log(string);
    return string[0].toUpperCase() + string.slice(1);
  }

  onReasonChange(event) {
    this.setState({
      cancel_reason: event.target.value,
    });
  }

  show = () => {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
    }, 2000);
  };

  callAutoAssign() {
    // console.log(this.props.tripId);
    fetch(config.url + "vendor/autoAssignRide", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // trip_id: this.props.tripId
        // trip_id: '0K918TQC60KQcwth'
        trip_id: this.props.tripId,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          if (result.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "Auto-assign triggered!",
              },
              () => {
                this.show();
              }
            );
          } else {
            this.setState(
              {
                success: false,
                message: "Update failure!",
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleAssignClick() {
    console.log(this.state.tripdata);
    this.props.onClickCallback(this.state.tripdata);
  }
  handleButtonClick() {
    // event.preventDefault()
    // console.log(this.state.editable)
    if (this.state.editable === true) {
      this.setState({
        editable: !this.state.editable,
        buttonName: "SAVE RIDE DETAILS",
      });
    } else {
      this.setState(
        {
          editable: !this.state.editable,
          buttonName: "UPDATE RIDE DETAILS",
        },
        () => this.callApitoUpdateData()
      );
    }
  }
  callApitoUpdateData() {
    // console.log("called");
    // console.log(this.state.offeredAmount);
    // console.log(this.state.extra_traveled_kms);
    // console.log(this.state.fineAmount);
    // console.log(this.state.empty_kms);
    this.updateOfferedAmount(this.state.offeredAmount);
    this.updateExtraTraveledLms(this.state.extra_traveled_kms);
    this.updateFineAmount(this.state.fineAmount, this.state.fineReason);
    this.updateEmptyKms(this.state.empty_kms);
    this.updateTagFare(this.state.extrarideamount);
  }

  updateTagFare(data) {
    if (data) {
      // console.log("this is data => " + data);
      console.log({
        // trip_id: this.props.tripId
        // trip_id: '0K918TQC60KQcwth'
        trip_id: this.props.tripId,
        userid: window.sessionStorage.getItem("user_id"),
        valueType: "Ride bonus",
        value: data,
        reason: "",
      });
      fetch(config.url + "htmlcrm/updateTripData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          // trip_id: this.props.tripId
          // trip_id: '0K918TQC60KQcwth'
          trip_id: this.props.tripId,
          userid: window.sessionStorage.getItem("user_id"),
          valueType: "Ride bonus",
          value: data,
          reason: "",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log("herererer");
            console.log(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    } else {
    }
  }

  updateOfferedAmount(data) {
    if (data) {
      // console.log("this is data => " + data);
      fetch(config.url + "htmlcrm/updateTripData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          // trip_id: this.props.tripId
          // trip_id: '0K918TQC60KQcwth'
          trip_id: this.props.tripId,
          userid: window.sessionStorage.getItem("user_id"),
          valueType: "offered",
          value: data,
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log("herererer");
            // console.log(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    } else {
    }
  }
  updateExtraTraveledLms(data) {
    if (data) {
      // console.log("this is data => " + data);
      fetch(config.url + "htmlcrm/updateTripData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          // trip_id: this.props.tripId
          // trip_id: '0K918TQC60KQcwth'
          trip_id: this.props.tripId,
          userid: window.sessionStorage.getItem("user_id"),
          valueType: "extrakm",
          value: data,
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log("herererer");
            // console.log(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    } else {
    }
  }
  updateFineAmount(data, reason) {
    if (data) {
      // console.log("this is data => " + data);
      fetch(config.url + "htmlcrm/updateTripData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          // trip_id: this.props.tripId
          // trip_id: '0K918TQC60KQcwth'
          trip_id: this.props.tripId,
          userid: window.sessionStorage.getItem("user_id"),
          valueType: "fine",
          value: data,
          reason: reason !== undefined ? reason : "",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.message === "successfull") {
              this.setState(
                {
                  success: true,
                  message: "Updated fine data successfully",
                },
                () => {
                  this.show();
                }
              );
            } else {
              this.setState(
                {
                  success: true,
                  message: "Updating fine data failed",
                },
                () => {
                  this.show();
                }
              );
            }
            // console.log("herererer");
            // console.log(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    } else {
    }
  }
  updateEmptyKms(data) {
    // console.log(data)
    if (data) {
      // console.log("this is data => " + data);
      fetch(config.url + "htmlcrm/updateTripData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          // trip_id: this.props.tripId
          // trip_id: '0K918TQC60KQcwth'
          trip_id: this.props.tripId,
          userid: window.sessionStorage.getItem("user_id"),
          valueType: "emptykm",
          value: data,
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log("herererer");
            // console.log(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    } else {
    }
  }
  updateLocationInteravl() {
    this.fetchDriverLocation();
  }
  handletextareaChange(event) {
    // console.log(event);
    this.setState({ textareaValue: event.target.value }, () => {
      // console.log(this.state.handletextareaChange);
    });
  }

  DetachRide() {
    if (
      this.state.cancel_reason === undefined ||
      this.state.cancel_reason === ""
    ) {
      this.setState(
        {
          success: false,
          message: "Please provide a reason",
        },
        () => {
          this.show();
        }
      );
    } else {
      fetch(config.url + "htmlcrm/requestDetachPermission", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          // trip_id: this.props.tripId
          // trip_id: '0K918TQC60KQcwth'
          trip_id: this.props.tripId,
          user_id: window.sessionStorage.getItem("user_id"),
          reason: "Customer no show",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState(
              {
                success: true,
                message: "Detach request raised",
              },
              () => {
                this.show();
                this.toggleDetach();
              }
            );
            // console.log("herererer");
            // console.log(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    }
  }

  cancelRide() {
    let CancelCall;
    let dataToSend;
    console.log(this.props.rideType);
    // console.log(this.state.tripdata.trip_status);
    if (this.props.rideType === "Rental" || this.props.rideType === "rental") {
      CancelCall = "booking/cancelBooking";
      dataToSend = {
        // trip_id: this.props.tripId
        // trip_id: '0K918TQC60KQcwth'
        trip_id: this.props.tripId,
        trip_type: this.props.rideType.toLowerCase(),
        // userid: window.sessionStorage.getItem("user_id"),
        user_id: window.sessionStorage.getItem("user_id"),
        cancel_by: window.sessionStorage.getItem("user_id"),
        // request_status: this.state.tripdata.trip_status.toLowerCase(),
        trip_status: this.state.tripdata.trip_status.toLowerCase(),
        dunique_id: this.state.tripdata.dunique_id,
        cancel_reason: this.state.cancel_reason,
      };
    } else {
      if (
        this.state.tripdata.trip_status === "Requested" &&
        this.state.tripdata.trip_status === "Emergency"
      ) {
        // console.log("herererer");
        CancelCall = "htmlcrm/cancelRequestedRide";
      } else if (
        this.state.tripdata.trip_status === "Accepted" ||
        this.state.tripdata.trip_status === "Notified"
      ) {
        CancelCall = "htmlcrm/canceltrip";
      } else if (
        this.state.tripdata.trip_status === "Assigned" ||
        this.state.tripdata.trip_status === "Requested"
      ) {
        CancelCall = "htmlcrm/cancelRides";
      }
      dataToSend = {
        // trip_id: this.props.tripId
        // trip_id: '0K918TQC60KQcwth'
        trip_id: this.props.tripId,
        trip_type: this.props.rideType.toLowerCase(),
        userid: window.sessionStorage.getItem("user_id"),
        user_id: window.sessionStorage.getItem("user_id"),
        cancel_by: window.sessionStorage.getItem("user_id"),
        request_status: this.state.tripdata.trip_status,
        trip_status: "cancelled",
        dunique_id: this.state.tripdata.dunique_id,
        cancel_reason: this.state.cancel_reason,
        vendor: this.state.tripdata.booking_by,
      };
    }

    console.log(CancelCall);
    console.log(dataToSend);
    fetch(config.url + CancelCall, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "Trip cancelled successfully",
              },
              () => {
                this.toggleCancel();
                this.show();
              }
            );
            setTimeout(() => this.props.onTitleChange(), 2000);
          } else {
            this.setState({
              success: false,
              message: "Trip cancellation failed",
            });
          }
          // console.log("herererer");
          // console.log(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  handleOtpChange(e) {
    // e.persist();
    // console.log(e.target.name);
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      const target = e.target;
      const value = target.value;
      // const name = target.name;
      this.setState({
        otpNumber: value,
      });
    }
    // console.log(e)
  }
  reAssign() {
    let ApiCall;
    // console.log(this.state.tripdata)
    if (
      this.state.tripdata.trip_status === "Assigned" ||
      this.state.tripdata.trip_status === "Confirmed"
    ) {
      ApiCall = "cancelAssignedRide";
    } else {
      ApiCall = "cancelLeaveForPickUpRide";
    }

    fetch(config.url + `htmlcrm/${ApiCall}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // trip_id: this.props.tripId
        // trip_id: '0K918TQC60KQcwth'
        trip_id: this.props.tripId,
        trip_type: this.props.rideType.toLowerCase(),
        user_id: window.sessionStorage.getItem("user_id"),
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          if (result.message === "successfull") {
            this.setState(
              {
                success: true,
                message: "Driver has been removed from the ride",
              },
              () => {
                this.show();
              }
            );
            setTimeout(() => this.props.onTitleChange(), 2000);
          } else {
            this.setState(
              {
                success: false,
                message: "Unable to re-assign the ride",
              },
              () => {
                this.show();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  gencancelOTP() {
    // console.log(this.props);
    fetch(config.url + "htmlcrm/generateApprovalOTP", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        // trip_id: this.props.tripId
        // trip_id: '0K918TQC60KQcwth'
        otpType: "cancellation",
        executive_id: window.sessionStorage.getItem("user_id"),
        trip_id: this.props.tripId,
        rideType: this.props.rideType.toLowerCase(),
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          console.log(result);
          if (result.message === "successfull") {
            this.setState(
              {
                success: true,
                otpGen: true,
                message: "OTP has been generated",
                otpVerCode: result.otp_verfication_code,
                otp: result.otp_number,
              },
              () => {
                this.show();
              }
            );
            setTimeout(() => {
              this.setState({ otpGen: "" });
            }, 2000);
          } else {
            this.setState(
              {
                success: false,
                otpGen: false,
                message: "Failed to generate OTP",
                otpVerCode: result.otp_verfication_code,
                otp: result.otp_number,
              },
              () => {
                this.show();
              }
            );
            setTimeout(() => {
              this.setState({ otpGen: "" });
            }, 2000);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  verifycancelOTP() {
    if (
      (this.state.cancel_reason === undefined ||
        this.state.cancel_reason === " ") &&
      this.state.dnoshow === false &&
      this.state.cnoshow === false &&
      this.state.flightDelay === false &&
      this.state.breakdown === false
    ) {
      this.setState(
        {
          success: false,
          message: "Please provide a reason for cancellation",
        },
        () => {
          this.show();
        }
      );
    } else {
      fetch(config.url + "htmlcrm/verifyApprovalOTP", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          // trip_id: this.props.tripId
          // trip_id: '0K918TQC60KQcwth'

          executive_id: window.sessionStorage.getItem("user_id"),
          otp_number: this.state.otpNumber,
          otp_verification_code: this.state.otpVerCode,
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            console.log(result);
            if (result.message === "successfull") {
              this.setState(
                {
                  success: true,
                  message: "Entered OTP is valid",
                },
                () => {
                  this.cancelRide();
                  this.show();
                }
              );
            } else {
              this.setState(
                {
                  success: false,
                  message: "Entered OTP is invalid",
                },
                () => {
                  this.show();
                }
              );
            }
            // this.setState({
            //     driverLocationData: result
            // })
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    }
    // console.log(this.state.otpVerCode);
  }
  handleOnChange(event) {
    // console.log(event)
    // console.log(event.target.value)
    // console.log(event.target.id);
    if (event.target.id === "offeredAmount") {
      // console.log("here")
      this.setState({
        offeredAmount: event.target.value,
      });
    } else if (event.target.id === "extrarideAmount") {
      this.setState({
        extrarideamount: event.target.value,
      });
    } else if (event.target.id === "extra_traveled_kms") {
      // console.log("here")
      this.setState({
        extra_traveled_kms: event.target.value,
      });
    } else if (event.target.id === "empty_kms") {
      // console.log("here")
      this.setState({
        empty_kms: event.target.value,
      });
    } else if (event.target.id === "fineAmount") {
      // console.log("here")
      this.setState({
        fineAmount: event.target.value,
      });
    } else if (event.target.id === "fineReason") {
      // console.log("here")
      this.setState({
        fineReason: event.target.value,
      });
    } else if (event.target.id === "issueTitle") {
      this.setState({
        issueTitle: event.target.value,
      });
    } else if (event.target.id === "issueDescription") {
      this.setState({
        issueDescription: event.target.value,
      });
    } else if (event.target.id === "replyDescription") {
      this.setState({
        replyDescription: event.target.value,
      });
    }
  }
  handleChange(e, data) {
    const newState = {};
    newState[data] = !this.state[data];
    this.setState({ ...this.state, ...newState });
  }
  toggleReassign() {
    this.setState({
      reassignOpen: !this.state.reassignOpen,
    });
  }
  toggleDetach() {
    this.setState({
      detachOpen: !this.state.detachOpen,
    });
  }

  toggleTag = () => {
    this.setState({
      tagDriver: !this.state.tagDriver,
    });
  };

  toggleExitTag = () => {
    this.setState({
      tagDriver: !this.state.tagDriver,
      tagPhonenumber: "",
      searchFound: false,
    });
  };

  toggleCancel() {
    this.setState({
      cancelOpen: !this.state.cancelOpen,
    });
  }
  fetchDriverLocation() {
    fetch(config.url + "htmlcrm/getDriverLocation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        trip_id: this.props.tripId,
        // trip_id: 'zNgXI0v9IeRWgUQf'
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          console.log(result);
          this.setState({
            driverLocationData: result,
            tollConsentDetails: result.customer_consents
              ? result.customer_consents.toll_info
              : [],
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  getRideDetails() {
    let seqData = [];
    fetch(config.url + "booking/getRideDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        trip_id: this.props.tripId,
        // trip_id: 'zNgXI0v9IeRWgUQf'
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          console.log(result);
          // if (this.state.vendor === "quickride") {
          //   seqData = result.via_points;
          //   console.log(seqData);
          // }
          if (result.via_points.length > 0) {
            seqData = result.via_points;
          }
          this.setState(
            {
              tripdata: result,
              sequence: seqData,
              offeredAmount: result.offeredAmount,
              extrarideamount: result.ride_bonus,
              extra_traveled_kms: result.extra_traveled_kms,
              empty_kms: result.empty_kms,
              fineAmount: result.fineAmount,
              fineReason: result.fineReason,
              tollConsentDetails: result.customer_consents.toll_info
                ? result.customer_consents.toll_info
                : [],
            },
            () => {
              this.fetchDriverLocation();
              this.checkForBeneficiary();
            }
          );
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  getReports() {
    fetch(config.url + "htmlcrm/getAllReportedIssues", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        trip_id: this.props.tripId,
        // trip_id: 'zNgXI0v9IeRWgUQf'
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          this.setState({
            reviewList: result.results,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  checkForBeneficiary = () => {
    if (this.props.rideType.toLowerCase() === "rental") {
    } else {
      let dataToSend;
      if (
        this.state.tripdata.trip_status === "Requested" &&
        this.state.showBox === true
      ) {
        dataToSend = {
          phonenumber: this.state.storeTagData.driver_phonenumber,
        };
      } else {
        dataToSend = {
          phonenumber: this.state.tripdata.driver_phone,
        };
      }

      console.log(dataToSend);
      axios
        .post(config.url + "payout/checkBeneficiary", dataToSend)
        .then((response) => {
          console.log(response);
          if (
            response.data.message === "old beneficiary" ||
            response.data.message === "new beneficiary"
          ) {
            let responseData = response.data;
            let statusCheck =
              responseData.status.toLowerCase() === "verified" &&
              responseData.account_verified_status.toLowerCase() === "verified"
                ? true
                : false;

            this.setState(
              {
                beneficiaryStatus: statusCheck,
              },
              () => this.checkForPaidStatus()
            );
          } else {
          }
        })
        .catch();
    }
  };

  checkForPaidStatus = () => {
    let dataToSend = {
      trip_id: this.state.tripdata.trip_id,
    };
    if (this.props.rideType.toLowerCase() === "rental") {
    } else {
      axios
        .post(config.url + "payout/getPayNowPaidStatus", dataToSend)
        .then((response) => {
          if (response.data.message === "successfull") {
            this.setState({
              paid_status: true,
            });
          } else {
          }
        })
        .catch();
    }
  };

  componentDidMount(props) {
    if (this.props.rideType === "Rental" || this.props.rideType === "rental") {
      this.timerID = setInterval(() => this.getRideDetails(), 30000);
    } else {
      if (
        this.props.requestStatus === "ended" ||
        this.props.requestStatus === "consumercancelled" ||
        this.props.requestStatus === "drivercancelled"
      ) {
        // console.log("*******************yooooo**********************");
        // console.log(this.props.requestStatus);
        this.getTripStatusLocations();
        // this.checkforTagging();
        // this.checkforPayout();
        // this.checkForBeneficiary();
      } else {
        // this.checkforTagging();
        // this.checkforPayout();
        // this.checkForBeneficiary();
        // this.timerID = setInterval(() => this.getRideDetails(), 30000);
      }
    }
  }

  componentWillUnmount() {
    // console.log("Interval cleared");
    clearInterval(this.timerID);
  }
  componentWillMount() {
    // this.setState({
    //   vendor: window.sessionStorage.getItem("vendor"),
    // });
    this.getRideDetails();
    this.fetchDriverLocation();
    this.getReports();
    this.checkforTagging();
    this.checkforPayout();
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props.tripId, prevProps.tripId)) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      // this.updateUser();
      let seqData = [];
      this.checkforTagging();
      this.checkforPayout();
      fetch(config.url + "booking/getRideDetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          trip_id: this.props.tripId,
          // trip_id: 'zNgXI0v9IeRWgUQf'
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log("herererer");
            // console.log(result);
            if (result.via_points.length > 0) {
              seqData = result.via_points;
              // console.log(seqData);
            }
            this.setState(
              {
                tripdata: result,
                sequence: seqData,
              },
              () => {
                this.fetchDriverLocation();
              }
            );
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {}
        );
    }
  }
  render() {
    //  console.log(this.props);
    const columns = [
      {
        Header: "Sl no",
        id: "serialno",
        style: {},
        Cell: (row) => {
          // console.log(row);
          return <div>{row.viewIndex + 1}</div>;
        },
      },
      {
        Header: "Issue ID",
        accessor: "issue_id",
      },
      {
        Header: "Title",
        accessor: "title",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Reported by",
        accessor: "username",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Received on",
        id: "statusrevise",
        style: { whiteSpace: "unset" },
        Cell: (row) => {
          // console.log(row);
          let date = moment(new Date(row.original.statusrevise)).format(
            "DD-MM-YY [at] hh:mm:ss A"
          );
          return <div>{date}</div>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        id: "ridetype",
        Cell: (row) => {
          let data = capitalizeFirstLetter(row.original.status);
          return <div>{data}</div>;
        },
      },
      {
        id: "edit",
        Header: "Actions",
        Cell: (row) => {
          //  console.log(this);
          // console.log(row.original.request_status)
          return (
            <div className="d-flex flex-column">
              {row.original.request_status === "Requested" && (
                <Button
                  theme="dark"
                  className="mx-2 my-2 "
                  onClick={() => {
                    this.viewClick(row.original);
                  }}
                >
                  CLOSE
                </Button>
              )}
              <Button
                className="mx-2 my-2"
                theme="dark"
                onClick={() => {
                  this.handleClick(row.original);
                }}
              >
                VIEW
              </Button>
            </div>
          );
        },
      },
    ];
    //Whenever our class runs, render method will be called automatically, it may have already defined in the constructor behind the scene.
    // console.log("**************************************************");
    // console.log(this.state.driverLocationData["crn_number"]);
    return (
      <React.Fragment>
        <Modal
          show={this.state.openPayNow}
          onHide={this.closeTogglePayNow}
          centered
        >
          <ModalHeader closeButton>
            <Row>
              <Col className="col-12">
                <label>Pay now confirmation</label>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody>
            <span className="question-text-style">
              Are you sure you want to intitate payment ?
            </span>
            <Row className="mt-3">
              {this.state.tripdata.trip_status === "Requested" ? (
                <React.Fragment>
                  {this.props.DriverPayData.map((stats, idx) => (
                    <Col
                      className="col-md-6 col-12 mb-4 "
                      key={idx}
                      {...stats.attrs}
                    >
                      {/* {console.log(data[stats.apiID])} */}
                      <InputData
                        id={`small-stats-${idx}`}
                        variation="1"
                        label={stats.label}
                        value={this.state.storeTagData[stats.apiID]}
                        stats={stats.stats}
                      />
                    </Col>
                  ))}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.props.DriverPayDatas.map((stats, idx) => (
                    <Col
                      className="col-md-6 col-12 mb-4 "
                      key={idx}
                      {...stats.attrs}
                    >
                      {/* {console.log(data[stats.apiID])} */}
                      <InputData
                        id={`small-stats-${idx}`}
                        variation="1"
                        label={stats.label}
                        value={this.state.tripdata[stats.apiID]}
                        stats={stats.stats}
                      />
                    </Col>
                  ))}
                </React.Fragment>
              )}
            </Row>
          </ModalBody>
          <ModalFooter className="px-0">
            <Row>
              <Col className="col-12">
                <Button
                  theme="dark"
                  size="lg"
                  className="mx-2"
                  onClick={this.closeTogglePayNow}
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "700",
                    minWidth: "120px",
                    borderRadius: "3px",
                  }}
                >
                  CLOSE
                </Button>
                <Button
                  theme="dark"
                  size="lg"
                  className="mx-2 "
                  onClick={this.payToDriver}
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "700",
                    minWidth: "120px",
                    borderRadius: "3px",
                  }}
                >
                  CONFIRM
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        {this.state.tripDump === false && this.state.rideIssue !== true && (
          <Row className="px-md-2 mb-5">
            <Col className="col-md-12 col-12 px-0 px-md-3">
              <Card className="my-4 mx-1 px-md-4">
                <CardHeader className="my-4 px-0 text-center text-uppercase font-weight-bold">
                  <h5>
                    Ride Details
                    <button
                      type="button"
                      className="close float-right ml-auto mr-3"
                      aria-label="Close"
                      onClick={this.props.onTitleChange}
                    >
                      <span aria-hidden="true">
                        <i class="fas fa-times" />
                      </span>
                    </button>
                  </h5>
                </CardHeader>
                <CardBody className="px-2">
                  {this.state.tripdata.is_office_trip === false && (
                    <React.Fragment>
                      <Row className="d-flex justify-content-between">
                        <Col className="col-12 col-md-4 mb-4">
                          {/* <div className="float-right mx-3">
                        
                      </div> */}
                          Auto assign status:
                          {this.state.tripdata.autoassign_status ===
                            "In progress" && (
                            <label
                              className="mx-2"
                              style={{ color: "#FFD800" }}
                            >
                              {" " + this.state.tripdata.autoassign_status}
                            </label>
                          )}
                          {this.state.tripdata.autoassign_status ===
                            "Not started" && (
                            <label
                              className="mx-2"
                              style={{ color: "#FF0000" }}
                            >
                              {" " + this.state.tripdata.autoassign_status}
                            </label>
                          )}
                          {this.state.tripdata.autoassign_status ===
                            "deactivated" && (
                            <label
                              className="mx-2"
                              style={{ color: "#FF0000" }}
                            >
                              {" " +
                                this.capitalizeFirstLetter(
                                  this.state.tripdata.autoassign_status
                                )}
                            </label>
                          )}
                          {this.state.tripdata.autoassign_status ===
                            "Started" && (
                            <label
                              className="mx-2"
                              style={{ color: "#59E67F" }}
                            >
                              {" " + this.state.tripdata.autoassign_status}
                            </label>
                          )}
                        </Col>
                        {/* <Col className="col-12 col-md-4 mb-4 d-flex justify-content-end">
                      Driver account status:
                      {this.state.beneficiaryStatus === false ? (
                        <label className="mx-2" style={{ color: "#FF0000" }}>
                          {" "}
                          Not verified
                        </label>
                      ) : (
                        <label className="mx-2" style={{ color: "#59E67F" }}>
                          {" "}
                          Verified
                        </label>
                      )}
                    </Col> */}
                      </Row>
                      <Row>
                        <Col className="col-12 mb-4 d-flex justify-content-end">
                          <Button
                            theme="dark"
                            size="lg"
                            onClick={this.enableTripDump}
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "700",
                              minWidth: "200px",
                              borderRadius: "3px",
                            }}
                          >
                            VIEW TRIP LOGS
                          </Button>
                        </Col>

                        {this.state.tripdata.trip_status === "Requested" && (
                          <Col className="col-12 col-md-12 d-none">
                            <Button
                              onClick={this.callAutoAssign}
                              className="float-right mb-3 py-3"
                              theme="dark"
                              disabled
                            >
                              TRIGGER AUTO ASSIGN
                            </Button>
                          </Col>
                        )}
                        <Col className="col-12 col-md-12 d-none">
                          <a
                            href={`/autoassignlogs/${this.state.tripdata.trip_id}`}
                            target="_blank"
                          >
                            <Button className="float-right py-3" theme="dark">
                              VIEW LOGS
                            </Button>
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        {/* {console.log(this.state.tripdata)} */}
                        {this.props.TripData.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-3 col-lg-3 mb-4"
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.tripdata.verification_code)} */}
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.tripdata[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        {this.props.TripStatusData.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-3 mb-4 "
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.tripdata.verification_code)} */}
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.tripdata[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        {this.props.TripLocationData.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-6 col-lg-6 mb-4 "
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.tripdata.verification_code)} */}
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.tripdata[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))}
                      </Row>
                      {(this.props.rideType === "Rental" ||
                        this.props.rideType === "rental") && (
                        <Row>
                          {this.props.RentalData.map((stats, idx) => (
                            <Col
                              className="col-12 col-md-3 col-lg-3 mb-4 "
                              key={idx}
                              {...stats.attrs}
                            >
                              {/* {console.log(this.state.tripdata.verification_code)} */}
                              <InputData
                                id={`small-stats-${idx}`}
                                variation="1"
                                label={stats.label}
                                value={this.state.tripdata[stats.apiID]}
                                stats={stats.stats}
                              />
                            </Col>
                          ))}
                        </Row>
                      )}

                      {this.state.sequence !== undefined &&
                        this.state.sequence.length > 0 && (
                          <Row>
                            <Col className="col-12 col-md-12 col-lg-9 col-xl-6 mt-4">
                              <StepQuick
                                data={this.state.sequence}
                                callToggle={this.callToggle}
                                trip_id={this.state.tripdata.trip_id}
                                trip_status={this.state.tripdata.trip_status}
                                dunique_id={this.state.tripdata.dunique_id}
                                triggerDetails={this.getRideDetails}
                              />
                            </Col>
                          </Row>
                        )}
                      {(this.state.tripdata.trip_status === "Requested" ||
                        this.state.tripdata.trip_status === "Confirmed") && (
                        <React.Fragment>
                          {console.log(this.props.rideType)}
                          <Row id="googlemaps">
                            <Col className="col-12 col-md-12">
                              <PolyLineMap
                                dataProps={this.state.tripdata}
                                vendor={this.state.tripdata.booking_by}
                                region={this.state.tripdata.region}
                              />
                            </Col>
                            <Col className="col-12 col-md-12">
                              {(this.props.rideType === "Rental" ||
                                this.props.rideType === "rental") && (
                                <Row className="">
                                  {this.props.RentalFareData.map(
                                    (stats, idx) => (
                                      <Col
                                        className="my-4 col-12 col-md-3"
                                        key={idx}
                                        {...stats.attrs}
                                      >
                                        {/* {console.log(this.state.tripdata.verification_code)} */}
                                        <InputData
                                          id={`small-stats-${idx}`}
                                          variation="1"
                                          label={stats.label}
                                          value={
                                            this.state.tripdata[stats.apiID]
                                          }
                                          stats={stats.stats}
                                        />
                                      </Col>
                                    )
                                  )}
                                </Row>
                              )}
                              {(this.props.rideType === "Airport" ||
                                this.props.rideType === "Outstation") && (
                                <Row className="">
                                  {this.props.FareData.map((stats, idx) => (
                                    <Col
                                      className="my-4 col-12 col-md-3"
                                      key={idx}
                                      {...stats.attrs}
                                    >
                                      {/* {console.log(this.state.tripdata.verification_code)} */}
                                      <InputData
                                        id={`small-stats-${idx}`}
                                        variation="1"
                                        label={stats.label}
                                        value={this.state.tripdata[stats.apiID]}
                                        stats={stats.stats}
                                      />
                                    </Col>
                                  ))}
                                </Row>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            {this.props.VehicleData.map((stats, idx) => (
                              <Col
                                className="col-12 col-md-3 col-lg-3 my-4"
                                key={idx}
                                {...stats.attrs}
                              >
                                {/* {console.log(this.state.tripdata.verification_code)} */}
                                <InputData
                                  id={`small-stats-${idx}`}
                                  variation="1"
                                  label={stats.label}
                                  value={this.state.tripdata[stats.apiID]}
                                  stats={stats.stats}
                                />
                              </Col>
                            ))}
                          </Row>
                          {this.state.tripdata.booking_by !== "Quickride" && (
                            <Row>
                              {this.props.RiderData.map((stats, idx) => (
                                <Col
                                  className="col-12 col-md-3 col-lg-3 my-4"
                                  key={idx}
                                  {...stats.attrs}
                                >
                                  {/* {console.log(this.state.tripdata.verification_code)} */}
                                  <InputData
                                    id={`small-stats-${idx}`}
                                    variation="1"
                                    label={stats.label}
                                    value={this.state.tripdata[stats.apiID]}
                                    stats={stats.stats}
                                  />
                                </Col>
                              ))}
                            </Row>
                          )}
                        </React.Fragment>
                      )}
                      {(this.state.tripdata.trip_status === "Accepted" ||
                        this.state.tripdata.trip_status === "Notified" ||
                        this.state.tripdata.trip_status === "Started") && (
                        <React.Fragment>
                          <Row id="googlemapslive" className="">
                            {this.state.tripdata.booking_by === "Quickride" ||
                            this.state.tripdata.ride_type === "outstation" ||
                            this.state.tripdata.ride_type === "rental" ? (
                              <Col className="col-12 col-md-12  p-0 m-0">
                                <LiveMap
                                  dataProps={this.state.tripdata}
                                  vendor={this.state.tripdata.booking_by}
                                />
                              </Col>
                            ) : (
                              <Col className="col-12 col-md-9   m-0 ">
                                <LiveMap
                                  dataProps={this.state.tripdata}
                                  vendor={this.state.tripdata.booking_by}
                                />
                              </Col>
                            )}

                            {this.state.tripdata.booking_by !== "Quickride" &&
                              this.state.tripdata.ride_type !== "rental" &&
                              (this.state.driverLocationData !== undefined ||
                                this.state.driverLocationData !== null ||
                                this.state.driverLocationData.length > 0) &&
                              this.state.tripdata.trip_status !==
                                "Assigned" && (
                                <Col className="col-12 col-md-3">
                                  <Card
                                    style={{ maxWidth: "100%", height: "100%" }}
                                  >
                                    <CardHeader
                                      className="d-flex justify-content-center"
                                      style={{
                                        borderBottom: "solid 0.5px #525151",
                                      }}
                                    >
                                      <div>Trip validations</div>
                                    </CardHeader>
                                    <CardBody className="d-flex justify-content-center p-0">
                                      <StepperPoll
                                        dlocProps={
                                          this.state.driverLocationData
                                        }
                                        rideType={this.props.rideType}
                                      />
                                    </CardBody>
                                  </Card>
                                </Col>
                              )}
                            <Col className="col-12 col-md-12 col-xl-12 col-lg-12 px-3">
                              <Row>
                                {this.props.FareData.map((stats, idx) => (
                                  <Col
                                    className="my-4 col-12 col-md-3"
                                    key={idx}
                                    {...stats.attrs}
                                  >
                                    {/* {console.log(this.state.tripdata.verification_code)} */}
                                    <InputData
                                      id={`small-stats-${idx}`}
                                      variation="1"
                                      label={stats.label}
                                      value={this.state.tripdata[stats.apiID]}
                                      stats={stats.stats}
                                    />
                                  </Col>
                                ))}
                              </Row>
                            </Col>
                            <Col className="col-12 col-md-12 col-xl-12 col-lg-12 px-3">
                              <Row>
                                {this.props.CustomerConsent.map(
                                  (stats, idx) => (
                                    <Col
                                      className="mb-4 col-12 col-md-3"
                                      key={idx}
                                      {...stats.attrs}
                                    >
                                      <InputData
                                        id={`small-stats-${idx}`}
                                        variation="1"
                                        label={stats.label}
                                        value={getDataValue(
                                          this.state.tollConsentDetails[
                                            stats.apiID
                                          ],
                                          stats.apiID,
                                          this.state.tollConsentDetails
                                        )}
                                        stats={stats.stats}
                                      />
                                    </Col>
                                  )
                                )}
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            {this.props.DriverLocation.map((stats, idx) => (
                              <Col
                                className="col-md-3 mb-4 "
                                key={idx}
                                {...stats.attrs}
                              >
                                {/* {console.log(this.state.tripdata.verification_code)} */}
                                <InputData
                                  id={`small-stats-${idx}`}
                                  variation="1"
                                  label={stats.label}
                                  value={
                                    this.state.driverLocationData[stats.apiID]
                                  }
                                  stats={stats.stats}
                                />
                              </Col>
                            ))}
                          </Row>
                          {this.state.tripdata.booking_by !== "Quickride" && (
                            <Row>
                              {this.props.RiderData.map((stats, idx) => (
                                <Col
                                  className="col-12 col-md-4 col-lg-4 col-xl-4 my-4"
                                  key={idx}
                                  {...stats.attrs}
                                >
                                  {/* {console.log(this.state.tripdata.verification_code)} */}
                                  <InputData
                                    id={`small-stats-${idx}`}
                                    variation="1"
                                    label={stats.label}
                                    value={this.state.tripdata[stats.apiID]}
                                    stats={stats.stats}
                                  />
                                </Col>
                              ))}
                            </Row>
                          )}
                        </React.Fragment>
                      )}
                      {(this.state.tripdata.trip_status === "Ended" ||
                        this.state.tripdata.trip_status === "Completed" ||
                        this.state.tripdata.trip_status ===
                          "Consumercancelled" ||
                        this.state.tripdata.trip_status ===
                          "Drivercancelled") && (
                        <React.Fragment>
                          <Row id="googlemaps">
                            {this.state.tripdata.booking_by === "Quickride" ||
                            this.state.tripdata.ride_type === "rental" ? (
                              <Col className="col-12 col-md-9 col-md-9">
                                <PolyLineMap
                                  dataProps={this.state.tripdata}
                                  vendor={this.state.tripdata.booking_by}
                                  region={this.state.tripdata.region}
                                />
                              </Col>
                            ) : (
                              <Col className="col-12 col-md-9 col-lg-9">
                                <PolyLineMap
                                  dataProps={this.state.tripdata}
                                  vendor={this.state.tripdata.booking_by}
                                  region={this.state.tripdata.region}
                                />
                              </Col>
                            )}

                            {this.state.tripdata.ride_type !== "outstation" && (
                              <Col className="col-12 col-md-3">
                                <Card
                                  style={{ maxWidth: "100%", height: "100%" }}
                                >
                                  <CardHeader
                                    className="d-flex justify-content-center"
                                    style={{
                                      borderBottom: "solid 0.5px #525151",
                                    }}
                                  >
                                    <div>Trip validations</div>
                                  </CardHeader>
                                  {this.state.tripdata.trip_status ===
                                    "Drivercancelled" ||
                                  this.state.tripdata.trip_status ===
                                    "Consumercancelled" ? (
                                    <CardBody className="d-flex justify-content-center p-0">
                                      <StepperCancel
                                        dlocProps={this.props.tripId}
                                        rideType={this.props.rideType}
                                      />
                                    </CardBody>
                                  ) : (
                                    <CardBody className="d-flex justify-content-center p-0">
                                      <Stepper
                                        dlocProps={this.props.tripId}
                                        rideType={this.props.rideType}
                                      />
                                    </CardBody>
                                  )}
                                </Card>
                              </Col>
                            )}

                            <Col className="col-12 col-md-12 px-3">
                              <Row className="">
                                {this.props.FareData.map((stats, idx) => (
                                  <Col
                                    className="my-4 col-12 col-md-3"
                                    key={idx}
                                    {...stats.attrs}
                                  >
                                    {/* {console.log(this.state.tripdata.verification_code)} */}
                                    <InputData
                                      id={`small-stats-${idx}`}
                                      variation="1"
                                      label={stats.label}
                                      value={this.state.tripdata[stats.apiID]}
                                      stats={stats.stats}
                                    />
                                  </Col>
                                ))}
                              </Row>
                            </Col>
                            <Col className="col-12 col-md-12 px-3">
                              <Row className="">
                                {this.props.CustomerConsent.map(
                                  (stats, idx) => (
                                    <Col
                                      className="my-4 col-12 col-md-3"
                                      key={idx}
                                      {...stats.attrs}
                                    >
                                      <InputData
                                        id={`small-stats-${idx}`}
                                        variation="1"
                                        label={stats.label}
                                        value={getDataValue(
                                          this.state.tollConsentDetails[
                                            stats.apiID
                                          ],
                                          stats.apiID,
                                          this.state.tollConsentDetails
                                        )}
                                        stats={stats.stats}
                                      />
                                    </Col>
                                  )
                                )}
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            {this.props.VehicleData.map((stats, idx) => (
                              <Col
                                className="col-12 col-md-3 col-lg-3 my-4"
                                key={idx}
                                {...stats.attrs}
                              >
                                {/* {console.log(this.state.tripdata.verification_code)} */}
                                <InputData
                                  id={`small-stats-${idx}`}
                                  variation="1"
                                  label={stats.label}
                                  value={this.state.tripdata[stats.apiID]}
                                  stats={stats.stats}
                                />
                              </Col>
                            ))}
                            {/* {this.props.DriverLocation.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-3 col-lg-3  my-4 "
                            key={idx}
                            {...stats.attrs}
                          >
                           
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.driverLocationData[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))} */}
                          </Row>
                          <Row>
                            {this.props.RiderData.map((stats, idx) => (
                              <Col
                                className="col-12 col-md-3 col-lg-3 my-4"
                                key={idx}
                                {...stats.attrs}
                              >
                                {/* {console.log(this.state.tripdata.verification_code)} */}
                                <InputData
                                  id={`small-stats-${idx}`}
                                  variation="1"
                                  label={stats.label}
                                  value={this.state.tripdata[stats.apiID]}
                                  stats={stats.stats}
                                />
                              </Col>
                            ))}
                          </Row>
                        </React.Fragment>
                      )}
                      {this.state.tripdata.trip_status === "Assigned" && (
                        <React.Fragment>
                          <Col className="col-12 col-md-12 p-0 m-0">
                            {this.props.rideType === "Rental" ||
                            this.props.rideType === "rental" ? (
                              <SimpleMap
                                dataProps={this.state.tripdata}
                                vendor={this.state.tripdata.booking_by}
                                region={this.state.tripdata.region}
                              />
                            ) : (
                              <PolyLineMap
                                dataProps={this.state.tripdata}
                                vendor={this.state.tripdata.booking_by}
                                region={this.state.tripdata.region}
                              />
                            )}
                          </Col>
                          <Col className="col-12 col-md-12 px-0">
                            <Row className="">
                              {this.props.FareData.map((stats, idx) => (
                                <Col
                                  className="my-4 col-12 col-md-3"
                                  key={idx}
                                  {...stats.attrs}
                                >
                                  {/* {console.log(this.state.tripdata.verification_code)} */}
                                  <InputData
                                    id={`small-stats-${idx}`}
                                    variation="1"
                                    label={stats.label}
                                    value={this.state.tripdata[stats.apiID]}
                                    stats={stats.stats}
                                  />
                                </Col>
                              ))}
                            </Row>
                          </Col>
                          {this.state.tripdata.booking_by !== "Quickride" && (
                            <Row>
                              {this.props.RiderData.map((stats, idx) => (
                                <Col
                                  className="col-12 col-md-3 col-lg-3 my-4"
                                  key={idx}
                                  {...stats.attrs}
                                >
                                  {/* {console.log(this.state.tripdata.verification_code)} */}
                                  <InputData
                                    id={`small-stats-${idx}`}
                                    variation="1"
                                    label={stats.label}
                                    value={this.state.tripdata[stats.apiID]}
                                    stats={stats.stats}
                                  />
                                </Col>
                              ))}
                            </Row>
                          )}
                        </React.Fragment>
                      )}
                      {(this.state.tripdata.trip_status === "Assigned" ||
                        this.state.tripdata.trip_status === "Requested") && (
                        <React.Fragment>
                          <div className="row">
                            <div className="col-12 col-md-12 mt-3 w-100 mt-5">
                              <Row className="mb-3">
                                <Button
                                  className="mx-auto mx-md-3 mx-xl-3 my-auto py-3 "
                                  theme="dark"
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "700",
                                    minWidth: "200px",
                                    borderRadius: "3px",
                                  }}
                                  onClick={this.handleButtonClick}
                                >
                                  {this.state.buttonName}
                                </Button>
                              </Row>

                              <Row>
                                {/* {this.props.ExtraAmount.map((stats, idx) => ( */}
                                <Col className="col-6 col-md-3 mb-4 ">
                                  <label>Offered amount</label>
                                  <FormInput
                                    id="offeredAmount"
                                    className="py-3"
                                    readOnly={this.state.editable}
                                    type="text"
                                    placeholder={
                                      this.state.tripdata.offeredAmount
                                    }
                                    onChange={this.handleOnChange}
                                    value={this.state.offeredAmount}
                                    maxLength={4}
                                  />
                                </Col>

                                <Col className="col-6 col-md-3 mb-4 ">
                                  <label>Extra ride amount</label>
                                  <FormInput
                                    className="py-3"
                                    id="extrarideAmount"
                                    readOnly={this.state.editable}
                                    type="text"
                                    placeholder={this.state.tripdata.ride_bonus}
                                    onChange={this.handleOnChange}
                                    value={this.state.extrarideamount}
                                  />
                                </Col>
                                <Col className="col-6 col-md-3 mb-4 ">
                                  <label>Extra travelled kms</label>
                                  <FormInput
                                    className="py-3"
                                    id="extra_traveled_kms"
                                    readOnly={this.state.editable}
                                    type="text"
                                    placeholder={
                                      this.state.tripdata.extra_traveled_kms
                                    }
                                    onChange={this.handleOnChange}
                                    value={this.state.extra_traveled_kms}
                                  />
                                </Col>
                                <Col className="col-6 col-md-3 mb-4 ">
                                  <label>Empty kms</label>
                                  <FormInput
                                    className="py-3"
                                    id="empty_kms"
                                    readOnly={this.state.editable}
                                    type="text"
                                    placeholder={this.state.tripdata.empty_kms}
                                    onChange={this.handleOnChange}
                                    value={this.state.empty_kms}
                                  />
                                </Col>
                                <Col className="col-6 col-md-3 mb-4 ">
                                  <label>Fine amount</label>
                                  <FormInput
                                    className="py-3"
                                    id="fineAmount"
                                    readOnly={this.state.editable}
                                    type="text"
                                    placeholder={this.state.tripdata.fineAmount}
                                    onChange={this.handleOnChange}
                                    value={this.state.fineAmount}
                                  />
                                </Col>
                                <Col className="col-6 col-md-3 mb-4 ">
                                  <label>Fine reason</label>
                                  <FormTextarea
                                    id="fineReason"
                                    readOnly={this.state.editable}
                                    type="text"
                                    placeholder={this.state.tripdata.fineReason}
                                    onChange={this.handleOnChange}
                                    value={this.state.fineReason}
                                  />
                                </Col>
                                {/* ))} */}
                              </Row>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                      {this.state.tripdata.trip_status !== "Requested" &&
                        this.state.tripdata.trip_status !== "Assigned" &&
                        this.state.tripdata.trip_status !==
                          "Consumercancelled" &&
                        this.state.tripdata.trip_status !==
                          "Drivercancelled" && (
                          <React.Fragment>
                            <div className="row">
                              {console.log(
                                this.props.rideType.toLowerCase() !== "rental"
                              )}
                              {this.props.rideType.toLowerCase() !==
                                "rental" && (
                                <React.Fragment>
                                  {(this.state.toll_ticket !== undefined ||
                                    this.state.toll_ticket !== "" ||
                                    this.state.toll_ticket !== null) && (
                                    <React.Fragment>
                                      <div className="col-12 mt-3 mb-0 pb-0">
                                        <label className="label-details">
                                          Toll ticket
                                        </label>
                                      </div>
                                      <div
                                        className="col-12 col-md-3 d-flex justify-content-center"
                                        style={{ marginTop: "-15px" }}
                                      >
                                        {(this.state.toll_ticket === "" ||
                                          this.state.toll_ticket === null ||
                                          this.state.toll_ticket ===
                                            undefined ||
                                          this.state.toll_ticket === " ") && (
                                          <div
                                            className="mt-3"
                                            style={{
                                              width: "250px",
                                              height: "400px",
                                              border: "1px solid #C1C1C1",
                                              backgroundImage: `url("https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/placeholder.svg")`,
                                              borderRadius: "8px",
                                              backgroundRepeat: "no-repeat",
                                              backgroundPosition: "50% 50%",
                                              backgroundSize: "300px 100px",
                                            }}
                                          ></div>
                                        )}
                                        {this.state.toll_ticket &&
                                          this.state.toll_ticket.length > 0 && (
                                            <div
                                              className="mt-3"
                                              style={{
                                                width: "250px",
                                                height: "400px",
                                                border: "1px solid #C1C1C1",
                                                backgroundImage: `url(${this.state.toll_ticket})`,
                                                backgroundSize: "240px 390px",
                                                borderRadius: "8px",
                                                backgroundRepeat: "no-repeat",
                                                backgroundPosition: "50% 50%",
                                              }}
                                            ></div>
                                          )}
                                      </div>
                                    </React.Fragment>
                                  )}
                                </React.Fragment>
                              )}

                              <div
                                className={
                                  this.props.rideType.toLowerCase() !== "rental"
                                    ? "col-12 col-md-9 mt-3 px-md-5 w-100 mt-5"
                                    : "col-12 mt-3 w-100 mt-5"
                                }
                              >
                                <Row className="mb-3">
                                  <Button
                                    className="mx-auto mx-md-3 my-auto py-3 "
                                    onClick={this.handleButtonClick}
                                    theme="dark"
                                    style={{
                                      fontSize: "0.8rem",
                                      fontWeight: "700",
                                      minWidth: "200px",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    {this.state.buttonName}
                                  </Button>
                                </Row>

                                <Row>
                                  {/* {this.props.ExtraAmount.map((stats, idx) => ( */}
                                  <Col className="col-6 col-md-3 mb-4 ">
                                    <label>Offered amount</label>
                                    <FormInput
                                      id="offeredAmount"
                                      className="py-3"
                                      readOnly={this.state.editable}
                                      type="text"
                                      placeholder={
                                        this.state.tripdata.offeredAmount
                                      }
                                      onChange={this.handleOnChange}
                                      value={this.state.offeredAmount}
                                      maxLength={4}
                                    />
                                  </Col>
                                  <Col className="col-6 col-md-3 mb-4 ">
                                    <label>Extra ride amount</label>
                                    <FormInput
                                      className="py-3"
                                      id="extrarideAmount"
                                      readOnly={this.state.editable}
                                      type="text"
                                      placeholder={
                                        this.state.tripdata.ride_bonus
                                      }
                                      onChange={this.handleOnChange}
                                      value={this.state.extrarideamount}
                                    />
                                  </Col>
                                  <Col className="col-6 col-md-3 mb-4 ">
                                    <label>Extra travelled kms</label>
                                    <FormInput
                                      className="py-3"
                                      id="extra_traveled_kms"
                                      readOnly={this.state.editable}
                                      type="text"
                                      placeholder={
                                        this.state.tripdata.extra_traveled_kms
                                      }
                                      onChange={this.handleOnChange}
                                      value={this.state.extra_traveled_kms}
                                    />
                                  </Col>
                                  <Col className="col-6 col-md-3 mb-4 ">
                                    <label>Empty kms</label>
                                    <FormInput
                                      id="empty_kms"
                                      className="py-3"
                                      readOnly={this.state.editable}
                                      type="text"
                                      placeholder={
                                        this.state.tripdata.empty_kms
                                      }
                                      onChange={this.handleOnChange}
                                      value={this.state.empty_kms}
                                    />
                                  </Col>
                                  <Col className="col-6 col-md-3 mb-4 ">
                                    <label>Fine amount</label>
                                    <FormInput
                                      id="fineAmount"
                                      className="py-3"
                                      readOnly={this.state.editable}
                                      type="text"
                                      placeholder={
                                        this.state.tripdata.fineAmount
                                      }
                                      onChange={this.handleOnChange}
                                      value={this.state.fineAmount}
                                    />
                                  </Col>
                                  <Col className="col-6 col-md-3 mb-4 ">
                                    <label>Fine reason</label>
                                    <FormTextarea
                                      id="fineReason"
                                      className="py-3"
                                      readOnly={this.state.editable}
                                      type="text"
                                      placeholder={
                                        this.state.tripdata.fineReason
                                      }
                                      onChange={this.handleOnChange}
                                      value={this.state.fineReason}
                                    />
                                  </Col>
                                  {/* ))} */}
                                </Row>
                              </div>
                            </div>
                          </React.Fragment>
                        )}

                      {(this.state.tripdata.trip_status ===
                        "Consumercancelled" ||
                        this.state.tripdata.trip_status === "Drivercancelled" ||
                        this.state.tripdata.trip_status === "Completed" ||
                        this.state.tripdata.trip_status === "Ended") && (
                        <Row className="mt-5 px-3">
                          <Col className="col-12 table-responsive">
                            <table class="table table-bordered">
                              <tbody>
                                <tr>
                                  <th style={{ textAlign: "center" }}>
                                    Total distance in kilometers
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Total ride duration (in H:MM)
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Extra kms traveled
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Extra kms fare
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Glory ride fare
                                  </th>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    {this.state.tripdata.trip_distance ===
                                      null ||
                                    this.state.tripdata.trip_distance ===
                                      undefined
                                      ? 0
                                      : this.state.tripdata.trip_distance}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {this.state.tripdata.ride_duration ===
                                      null ||
                                    this.state.tripdata.ride_duration ===
                                      undefined
                                      ? 0
                                      : this.state.tripdata.ride_duration}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {this.state.tripdata.additional_ride_km ===
                                      null ||
                                    this.state.tripdata.additional_ride_km ===
                                      undefined
                                      ? 0
                                      : this.state.tripdata.additional_ride_km}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {this.state.tripdata.extrakmcharges ===
                                      null ||
                                    this.state.tripdata.extrakmcharges ===
                                      undefined
                                      ? 0
                                      : this.state.tripdata.extrakmcharges}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {this.state.tripdata.final_fare === null ||
                                    this.state.tripdata.final_fare === undefined
                                      ? 0
                                      : this.state.tripdata.final_fare}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                      )}

                      <React.Fragment>
                        {this.state.switchDiv === false && (
                          <div className="mt-5">
                            <label className="label-details">
                              <b>Ride issues</b>
                            </label>
                            {this.state.reviewList !== undefined &&
                              this.state.reviewList.length > 0 && (
                                <Row className="mb-3">
                                  <Card className="w-100">
                                    <CardBody>
                                      <ReactTable
                                        data={this.state.reviewList}
                                        noDataText="No data to display"
                                        columns={columns}
                                        className="text-center -striped -highlight"
                                        minRows={
                                          this.state.reviewList !== undefined
                                            ? 0
                                            : 4
                                        }
                                      />
                                    </CardBody>
                                  </Card>
                                </Row>
                              )}

                            <Row className="mb-3 mt-5 d-flex justify-content-center">
                              <Col className="col-md-3"></Col>
                              <Col className="col-12 col-md-6">
                                <label>Report a new issue</label>
                                <FormInput
                                  className="w-100 mb-3 py-3"
                                  id="issueTitle"
                                  type="text"
                                  placeholder="Title"
                                  onChange={this.handleOnChange}
                                  value={this.state.issueTitle}
                                />
                              </Col>
                              <Col className="col-md-3"></Col>
                              <Col className="col-12 col-md-6">
                                <FormTextarea
                                  id="issueDescription"
                                  type="text"
                                  rows="4"
                                  placeholder="Describe the issue"
                                  onChange={this.handleOnChange}
                                  value={this.state.issueDescription}
                                />
                              </Col>
                              <Col className="col-12 d-flex justify-content-center mt-4">
                                <Button
                                  className="button-width-style py-3"
                                  theme="dark"
                                  size="lg"
                                  onClick={this.submitIssue}
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "700",
                                    minWidth: "200px",
                                    borderRadius: "3px",
                                  }}
                                >
                                  SUBMIT
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </React.Fragment>
                    </React.Fragment>
                  )}
                  {this.state.tripdata.is_office_trip === true && (
                    <React.Fragment>
                      <Row className="d-flex justify-content-between">
                        <Col className="col-12 mb-4 d-flex justify-content-end">
                          <Button
                            theme="dark"
                            size="lg"
                            onClick={this.enableTripDump}
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "700",
                              minWidth: "200px",
                              borderRadius: "3px",
                            }}
                          >
                            VIEW TRIP LOGS
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        {/* {console.log(this.state.tripdata)} */}
                        {this.props.TripData.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-3 col-lg-3 mb-4"
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.tripdata.verification_code)} */}
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.tripdata[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        {this.props.TripStatusData.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-3 mb-4 "
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.tripdata.verification_code)} */}
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.tripdata[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        <Col className="col-12 col-md-6 mb-4">
                          <Row>
                            {this.state.tripdata.office_trip_details.pickup_locations.map(
                              (stats, idx) => (
                                <Col
                                  className="col-12 col-md-12 py-2"
                                  key={idx}
                                  {...stats.attrs}
                                >
                                  <InputData
                                    id={`small-stats-${idx}`}
                                    variation="1"
                                    label={`Pickup location ${idx + 1}`}
                                    value={stats.place_name}
                                    stats={stats.stats}
                                  />
                                </Col>
                              )
                            )}
                          </Row>
                        </Col>
                        <Col className="col-12 col-md-6 mb-4">
                          <Row>
                            {this.state.tripdata.office_trip_details.dropoff_locations.map(
                              (stats, idx) => (
                                <Col
                                  className="col-12 col-md-12 py-2"
                                  key={idx}
                                  {...stats.attrs}
                                >
                                  <InputData
                                    id={`small-stats-${idx}`}
                                    variation="1"
                                    label={`Drop-off location ${idx + 1}`}
                                    value={stats.place_name}
                                    stats={stats.stats}
                                  />
                                </Col>
                              )
                            )}
                          </Row>
                        </Col>
                      </Row>
                      {(this.state.tripdata.trip_status === "Requested" ||
                        this.state.tripdata.trip_status === "Assigned") && (
                        <React.Fragment>
                          <Row id="googlemaps">
                            <Col className="col-12 col-md-12">
                              <PolyLineMap
                                dataProps={this.state.tripdata}
                                vendor={this.state.tripdata.booking_by}
                                region={this.state.tripdata.region}
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                      {(this.state.tripdata.trip_status === "Accepted" ||
                        this.state.tripdata.trip_status === "Notified" ||
                        this.state.tripdata.trip_status === "Started") && (
                        <React.Fragment>
                          <Row id="googlemapslive" className="">
                            <Col className="col-12 col-md-9   m-0 ">
                              <LiveMap
                                dataProps={this.state.tripdata}
                                vendor={this.state.tripdata.booking_by}
                              />
                            </Col>
                            <Col className="col-12 col-md-3">
                              <Card
                                style={{ maxWidth: "100%", height: "100%" }}
                              >
                                <CardHeader
                                  className="d-flex justify-content-center"
                                  style={{
                                    borderBottom: "solid 0.5px #525151",
                                  }}
                                >
                                  <div>Trip validations</div>
                                </CardHeader>
                                <CardBody className="d-flex justify-content-center p-0">
                                  <StepperPoll
                                    dlocProps={this.state.driverLocationData}
                                    rideType={this.props.rideType}
                                  />
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                      {(this.state.tripdata.trip_status === "Ended" ||
                        this.state.tripdata.trip_status === "Completed" ||
                        this.state.tripdata.trip_status ===
                          "Consumercancelled" ||
                        this.state.tripdata.trip_status ===
                          "Drivercancelled") && (
                        <React.Fragment>
                          <Row id="googlemaps">
                            <Col className="col-12 col-md-9">
                              <PolyLineMap
                                dataProps={this.state.tripdata}
                                vendor={this.state.tripdata.booking_by}
                                region={this.state.tripdata.region}
                              />
                            </Col>
                            <Col className="col-12 col-md-3">
                              <Card
                                style={{ maxWidth: "100%", height: "100%" }}
                              >
                                <CardHeader
                                  className="d-flex justify-content-center"
                                  style={{
                                    borderBottom: "solid 0.5px #525151",
                                  }}
                                >
                                  <div>Trip validations</div>
                                </CardHeader>
                                {this.state.tripdata.trip_status ===
                                  "Drivercancelled" ||
                                this.state.tripdata.trip_status ===
                                  "Consumercancelled" ? (
                                  <CardBody className="d-flex justify-content-center p-0">
                                    <StepperCancel
                                      dlocProps={this.props.tripId}
                                      rideType={this.props.rideType}
                                    />
                                  </CardBody>
                                ) : (
                                  <CardBody className="d-flex justify-content-center p-0">
                                    <Stepper
                                      dlocProps={this.props.tripId}
                                      rideType={this.props.rideType}
                                    />
                                  </CardBody>
                                )}
                              </Card>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                      <Row className="">
                        {this.props.FareData.map((stats, idx) => (
                          <Col
                            className="my-4 col-12 col-md-3"
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.tripdata.verification_code)} */}
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.tripdata[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        {this.props.VehicleData.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-3 col-lg-3 my-4"
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.tripdata.verification_code)} */}
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.tripdata[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        {this.props.RiderData.map((stats, idx) => (
                          <Col
                            className="col-12 col-md-3 col-lg-3 my-4"
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.tripdata.verification_code)} */}
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.tripdata[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        {this.props.DriverLocation.map((stats, idx) => (
                          <Col
                            className="col-md-3 my-4 "
                            key={idx}
                            {...stats.attrs}
                          >
                            {/* {console.log(this.state.tripdata.verification_code)} */}
                            <InputData
                              id={`small-stats-${idx}`}
                              variation="1"
                              label={stats.label}
                              value={this.state.driverLocationData[stats.apiID]}
                              stats={stats.stats}
                            />
                          </Col>
                        ))}
                      </Row>
                      <Row className="col-12 col-md-12 mt-3 w-100 mb-3">
                        <Col className="col-12 col-md-4 px-0 mb-2">
                          <Button
                            className="my-auto py-3"
                            theme="dark"
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "700",
                              minWidth: "200px",
                              borderRadius: "3px",
                            }}
                            onClick={this.handleButtonClick}
                          >
                            {this.state.buttonName}
                          </Button>
                        </Col>
                        <Col className="col-12 col-md-12">
                          <Row>
                            <Col className="col-6 col-md-3 mb-4 ">
                              <label>Offered amount</label>
                              <FormInput
                                id="offeredAmount"
                                className="py-3"
                                readOnly={this.state.editable}
                                type="text"
                                placeholder={this.state.tripdata.offeredAmount}
                                onChange={this.handleOnChange}
                                value={this.state.offeredAmount}
                                maxLength={4}
                              />
                            </Col>
                            <Col className="col-6 col-md-3 mb-4 ">
                              <label>Extra ride amount</label>
                              <FormInput
                                className="py-3"
                                id="extrarideAmount"
                                readOnly={this.state.editable}
                                type="text"
                                placeholder={this.state.tripdata.ride_bonus}
                                onChange={this.handleOnChange}
                                value={this.state.extrarideamount}
                              />
                            </Col>
                            <Col className="col-6 col-md-3 mb-4 ">
                              <label>Extra travelled kms</label>
                              <FormInput
                                className="py-3"
                                id="extra_traveled_kms"
                                readOnly={this.state.editable}
                                type="text"
                                placeholder={
                                  this.state.tripdata.extra_traveled_kms
                                }
                                onChange={this.handleOnChange}
                                value={this.state.extra_traveled_kms}
                              />
                            </Col>
                            <Col className="col-6 col-md-3 mb-4 ">
                              <label>Empty kms</label>
                              <FormInput
                                className="py-3"
                                id="empty_kms"
                                readOnly={this.state.editable}
                                type="text"
                                placeholder={this.state.tripdata.empty_kms}
                                onChange={this.handleOnChange}
                                value={this.state.empty_kms}
                              />
                            </Col>
                            <Col className="col-6 col-md-3 mb-4 ">
                              <label>Fine amount</label>
                              <FormInput
                                className="py-3"
                                id="fineAmount"
                                readOnly={this.state.editable}
                                type="text"
                                placeholder={this.state.tripdata.fineAmount}
                                onChange={this.handleOnChange}
                                value={this.state.fineAmount}
                              />
                            </Col>
                            <Col className="col-6 col-md-3 mb-4 ">
                              <label>Fine reason</label>
                              <FormTextarea
                                id="fineReason"
                                readOnly={this.state.editable}
                                type="text"
                                placeholder={this.state.tripdata.fineReason}
                                onChange={this.handleOnChange}
                                value={this.state.fineReason}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col classname="col-12 col-md-12 mb-2">
                          <label className="label-details">
                            <b>Ride issues</b>
                          </label>
                        </Col>
                        <Col classsName="col-12 col-md-12 mb-2">
                          {this.state.reviewList !== undefined &&
                            this.state.reviewList.length > 0 && (
                              <Row className="mb-3">
                                <Card className="w-100">
                                  <CardBody>
                                    <ReactTable
                                      data={this.state.reviewList}
                                      noDataText="No data to display"
                                      columns={columns}
                                      className="text-center -striped -highlight"
                                      minRows={
                                        this.state.reviewList !== undefined
                                          ? 0
                                          : 4
                                      }
                                    />
                                  </CardBody>
                                </Card>
                              </Row>
                            )}
                        </Col>
                        <Col className="col-12">
                          <label>Report a new issue</label>
                        </Col>
                        <Col className="col-12 d-flex justify-content-center">
                          <Row>
                            <Col className="col-12 col-md-12">
                              <FormInput
                                className="w-100 mb-3 py-3"
                                id="issueTitle"
                                type="text"
                                placeholder="Title"
                                onChange={this.handleOnChange}
                                value={this.state.issueTitle}
                              />
                            </Col>
                            <Col className="col-12 col-md-12 mb-2">
                              <FormTextarea
                                id="issueDescription"
                                type="text"
                                rows="4"
                                placeholder="Describe the issue"
                                onChange={this.handleOnChange}
                                value={this.state.issueDescription}
                              />
                            </Col>
                            <Col className="col-12 col-md-12 d-flex justify-content-center">
                              <Button
                                className="button-width-style py-3"
                                theme="dark"
                                size="lg"
                                onClick={this.submitIssue}
                                style={{
                                  fontSize: "0.8rem",
                                  fontWeight: "700",
                                  minWidth: "200px",
                                  borderRadius: "3px",
                                }}
                              >
                                SUBMIT
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </CardBody>
                <hr />
                {this.state.tripdata.trip_status !== "Requested" && (
                  <Row className="mb-5 mt-5 d-flex justify-content-center">
                    <Col className="col-12 col-md-6">
                      <Card className="px-3">
                        <CardBody className="">
                          <Row className="mx-md-auto">
                            <p
                              style={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                                left: 0,
                                padding: "10px",
                              }}
                              className="mb-0 d-flex justify-content-center justify-content-md-end"
                            >
                              Driver account status:
                              {this.state.beneficiaryStatus === false ? (
                                <label
                                  className="mx-2"
                                  style={{ color: "#FF0000" }}
                                >
                                  {" "}
                                  Not verified
                                </label>
                              ) : (
                                <label
                                  className="mx-2"
                                  style={{ color: "#59E67F" }}
                                >
                                  {" "}
                                  Verified
                                </label>
                              )}
                            </p>
                            {/* {console.log(this.state.tripdata.profile_pic)} */}
                            <Col className="col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-2">
                              <img
                                className="rounded-circle img-thumbnail"
                                style={{ width: "150px", height: "150px" }}
                                onerror="https://panel.hornok.in/static/images/avatars/man.png"
                                src={
                                  this.state.tripdata.profile_pic === null ||
                                  this.state.tripdata.profile_pic === ""
                                    ? "https://panel.hornok.in/static/images/avatars/man.png"
                                    : this.state.tripdata.profile_pic
                                }
                              />
                            </Col>
                            <Col className="col-6 col-md-6 col-lg-6 my-auto mx-auto">
                              <div className="font-weight-bold">
                                {this.state.tripdata.driver_name === ""
                                  ? "-"
                                  : this.state.tripdata.driver_name}
                              </div>
                              <div className="mt-1">
                                {this.state.tripdata.vehicle_type +
                                  " - " +
                                  this.state.tripdata.vehiclenumber}
                              </div>
                              <div className="mt-1">
                                {this.state.tripdata.driver_phone}
                              </div>
                            </Col>
                          </Row>
                          {(this.state.tripdata.trip_status === "Completed" ||
                            this.state.tripdata.trip_status === "Ended") && (
                            <Row>
                              {(window.sessionStorage.getItem("roletype") ===
                                "manager" ||
                                window.sessionStorage.getItem("roletype") ===
                                  "teamlead" ||
                                window.sessionStorage.getItem("roletype") ===
                                  "assistantmanager") && (
                                <React.Fragment>
                                  {this.state.beneficiaryStatus === true && (
                                    <Col className="col-12 col-md-12 d-flex justify-content-center mt-3">
                                      {this.state.paid_status === true && (
                                        <Button
                                          className="button-width-style"
                                          disabled
                                          theme="dark"
                                          size="lg"
                                          style={{
                                            fontSize: "0.8rem",
                                            fontWeight: "700",
                                            minWidth: "200px",
                                            borderRadius: "3px",
                                          }}
                                        >
                                          PAID
                                        </Button>
                                      )}
                                      {this.state.paid_status === false && (
                                        <Button
                                          className="button-width-style"
                                          disabled={this.state.deactivate}
                                          theme="dark"
                                          size="lg"
                                          style={{
                                            fontSize: "0.8rem",
                                            fontWeight: "700",
                                            minWidth: "200px",
                                            borderRadius: "3px",
                                          }}
                                          onClick={this.openTogglePayNow}
                                        >
                                          PAY NOW
                                        </Button>
                                      )}
                                    </Col>
                                  )}
                                </React.Fragment>
                              )}
                            </Row>
                          )}

                          {(this.state.tripdata.trip_status === "Assigned" ||
                            this.state.tripdata.trip_status === "Accepted" ||
                            this.state.tripdata.trip_status === "Notified") && (
                            <Row className="mt-4 d-flex justify-content-center">
                              <Col className="col-12 col-md-5 col-lg-5 mt-2 d-flex justify-content-center mb-3 mb-md-0 mx-3">
                                <Button
                                  className="button-width-style"
                                  theme="dark"
                                  size="lg"
                                  onClick={this.toggleReassign}
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "700",
                                    minWidth: "200px",
                                    borderRadius: "3px",
                                  }}
                                >
                                  RE-ASSIGN RIDE
                                </Button>
                                <Modal
                                  show={this.state.reassignOpen}
                                  onHide={this.toggleReassign}
                                  centered
                                  modalClassName="modal-overflow-control"
                                >
                                  <ModalHeader closeButton>
                                    {/* <div>
                                      <label>Reassign confirmation</label>
                                      <button
                                        type="button"
                                        className="close float-right ml-auto mr-md-3"
                                        aria-label="Close"
                                        onClick={this.props.onTitleChange}
                                      >
                                        <span aria-hidden="true">
                                          <i class="fas fa-times" />
                                        </span>
                                      </button>
                                    </div> */}
                                    <Row>
                                      <Col className="col-12">
                                        <label>Re-assign confirmation</label>
                                      </Col>
                                    </Row>
                                  </ModalHeader>
                                  <ModalBody>
                                    <span className="question-text-style">
                                      Do you want to reassign the ride ?
                                    </span>
                                    <Row className="mt-3">
                                      {this.props.CancelModData.map(
                                        (stats, idx) => (
                                          <Col
                                            className="col-md-6 col-12 mb-4 "
                                            key={idx}
                                            {...stats.attrs}
                                          >
                                            {/* {console.log(data[stats.apiID])} */}
                                            <InputData
                                              id={`small-stats-${idx}`}
                                              variation="1"
                                              label={stats.label}
                                              value={
                                                this.state.tripdata[stats.apiID]
                                              }
                                              stats={stats.stats}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                    <Row>
                                      {this.props.SorDesData.map(
                                        (stats, idx) => (
                                          <Col
                                            className="col-md-6 col-12 mb-4 "
                                            key={idx}
                                            {...stats.attrs}
                                          >
                                            {/* {console.log(data[stats.apiID])} */}
                                            <InputData
                                              id={`small-stats-${idx}`}
                                              variation="1"
                                              label={stats.label}
                                              value={
                                                this.state.tripdata[stats.apiID]
                                              }
                                              stats={stats.stats}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                    <Row>
                                      {this.props.DriverData.map(
                                        (stats, idx) => (
                                          <Col
                                            className="col-md-6 col-12 mb-4 "
                                            key={idx}
                                            {...stats.attrs}
                                          >
                                            {/* {console.log(data[stats.apiID])} */}
                                            <InputData
                                              id={`small-stats-${idx}`}
                                              variation="1"
                                              label={stats.label}
                                              value={
                                                this.state.tripdata[stats.apiID]
                                              }
                                              stats={stats.stats}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  </ModalBody>
                                  <ModalFooter className="px-0">
                                    <Row>
                                      <Col className="col-12">
                                        <Button
                                          theme="dark"
                                          size="lg"
                                          className="mx-2 "
                                          onClick={this.reAssign}
                                          style={{
                                            fontSize: "0.8rem",
                                            fontWeight: "700",
                                            minWidth: "120px",
                                            borderRadius: "3px",
                                          }}
                                        >
                                          REASSIGN
                                        </Button>
                                        <Button
                                          theme="dark"
                                          size="lg"
                                          className="mx-2"
                                          onClick={this.toggleReassign}
                                          style={{
                                            fontSize: "0.8rem",
                                            fontWeight: "700",
                                            minWidth: "120px",
                                            borderRadius: "3px",
                                          }}
                                        >
                                          CLOSE
                                        </Button>
                                      </Col>
                                    </Row>
                                  </ModalFooter>
                                </Modal>
                              </Col>

                              <Col className="col-12 col-md-5 col-lg-5 mt-2 d-flex justify-content-center mx-3">
                                <Button
                                  theme="dark"
                                  className="button-width-style"
                                  onClick={this.toggleCancel}
                                  size="lg"
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "700",
                                    minWidth: "200px",
                                    borderRadius: "3px",
                                  }}
                                >
                                  CANCEL RIDE
                                </Button>
                                <Modal
                                  show={this.state.cancelOpen}
                                  onHide={this.toggleCancel}
                                  centered
                                  modalClassName="modal-overflow-control"
                                >
                                  <ModalHeader closeButton>
                                    <div>Cancel confirmation</div>
                                  </ModalHeader>
                                  <ModalBody>
                                    <span className="question-text-style">
                                      Do you want to cancel the ride ?
                                    </span>
                                    <Row className="mt-3">
                                      {this.props.CancelModData.map(
                                        (stats, idx) => (
                                          <Col
                                            className="col-12 col-md-6 mb-4 "
                                            key={idx}
                                            {...stats.attrs}
                                          >
                                            {/* {console.log(data[stats.apiID])} */}
                                            <InputData
                                              id={`small-stats-${idx}`}
                                              variation="1"
                                              label={stats.label}
                                              value={
                                                this.state.tripdata[stats.apiID]
                                              }
                                              stats={stats.stats}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                    <Row>
                                      {this.props.SorDesData.map(
                                        (stats, idx) => (
                                          <Col
                                            className="col-md-6 col-12 mb-4 "
                                            key={idx}
                                            {...stats.attrs}
                                          >
                                            {/* {console.log(data[stats.apiID])} */}
                                            <InputData
                                              id={`small-stats-${idx}`}
                                              variation="1"
                                              label={stats.label}
                                              value={
                                                this.state.tripdata[stats.apiID]
                                              }
                                              stats={stats.stats}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>

                                    <Row>
                                      <Col className="col-6">
                                        <FormCheckbox
                                          checked={this.state.cnoshow}
                                          onChange={(e) =>
                                            this.handleChange(e, "cnoshow")
                                          }
                                        >
                                          Customer no show
                                        </FormCheckbox>
                                      </Col>
                                      <Col className="col-6">
                                        <FormCheckbox
                                          checked={this.state.breakdown}
                                          onChange={(e) =>
                                            this.handleChange(e, "breakdown")
                                          }
                                        >
                                          Vehicle breakdown
                                        </FormCheckbox>
                                      </Col>
                                      <Col className="col-6">
                                        <FormCheckbox
                                          checked={this.state.flightDelay}
                                          onChange={(e) =>
                                            this.handleChange(e, "flightDelay")
                                          }
                                        >
                                          Flight delayed
                                        </FormCheckbox>
                                      </Col>
                                      <Col className="col-6">
                                        <FormCheckbox
                                          checked={this.state.dnoshow}
                                          onChange={(e) =>
                                            this.handleChange(e, "dnoshow")
                                          }
                                        >
                                          Driver no show
                                        </FormCheckbox>
                                      </Col>
                                    </Row>
                                    <Row className="w-100 mx-auto mt-2">
                                      <textarea
                                        className="w-100"
                                        rows="4"
                                        invalid={
                                          this.state.message ===
                                          "Please provide a reason for cancellation"
                                            ? true
                                            : false
                                        }
                                        value={this.state.cancel_reason}
                                        onChange={this.onReasonChange}
                                        placeholder="Please provide a reason for cancellation"
                                      />
                                    </Row>
                                    <Row className="px-2">
                                      <Button
                                        className="ml-2 mt-4 "
                                        onClick={this.gencancelOTP}
                                        theme="dark"
                                        size="lg"
                                        style={{
                                          fontSize: "0.8rem",
                                          fontWeight: "700",
                                          minWidth: "200px",
                                          borderRadius: "3px",
                                        }}
                                      >
                                        GENERATE OTP
                                      </Button>
                                      <div className="ml-md-5 ml-2 mt-4">
                                        <FormInput
                                          size="sm"
                                          id="#otpNumber"
                                          placeholder="Enter the OTP"
                                          className="text-center py-3"
                                          value={this.state.otpNumber}
                                          onChange={this.handleOtpChange}
                                          type="text"
                                          minLength={6}
                                          maxLength={6}
                                        />
                                      </div>
                                      {this.state.otpGen === true && (
                                        <span
                                          className="ml-2 font-weight-light mt-4"
                                          style={{ color: "#59E67F" }}
                                        >
                                          OTP has been sent
                                        </span>
                                      )}
                                      {this.state.otpGen === false && (
                                        <span
                                          className="ml-2 font-weight-light mt-4"
                                          style={{ color: "#FF0000" }}
                                        >
                                          Failed to send OTP
                                        </span>
                                      )}
                                      <span className="ml-2 my-2 font-weight-light">
                                        *This OTP is valid for the next 15
                                        minutes
                                      </span>
                                    </Row>
                                    <Row>
                                      <Col className="col-12 d-flex justify-content-center">
                                        <Button
                                          theme="dark"
                                          className="ml-2 mt-4 "
                                          onClick={this.verifycancelOTP}
                                          size="lg"
                                          style={{
                                            fontSize: "0.8rem",
                                            fontWeight: "700",
                                            minWidth: "200px",
                                            borderRadius: "3px",
                                          }}
                                        >
                                          VERIFY & CANCEL RIDE
                                        </Button>
                                      </Col>
                                    </Row>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                {this.state.tripdata.trip_status === "Requested" && (
                  <React.Fragment>
                    <Row className="mb-5 d-flex justify-content-center mt-5">
                      <Col className="col-10 col-md-7 col-lg-7 ">
                        <Card>
                          <CardBody className="">
                            <Row className="d-flex justify-content-center">
                              {/* <Col className="col-12 col-md-5 col-lg-5 mt-2 d-flex justify-content-center mx-2">
                                <Button
                                  className="py-md-3"
                                  size="lg"
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "700",
                                    minWidth: "200px",
                                    borderRadius: "3px",
                                  }}
                                  theme="dark"
                                  onClick={this.handleAssignClick}
                                >
                                  ASSIGN RIDE
                                </Button>
                              </Col> */}

                              <Col className="col-12 col-md-5 col-lg-5 mt-2 d-flex justify-content-center mx-2">
                                <Button
                                  theme="dark"
                                  className="py-md-3"
                                  size="lg"
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "700",
                                    minWidth: "200px",
                                    borderRadius: "3px",
                                  }}
                                  onClick={this.toggleCancel}
                                >
                                  CANCEL RIDE
                                </Button>
                                <Modal
                                  show={this.state.cancelOpen}
                                  onHide={this.toggleCancel}
                                  centered
                                  modalClassName="modal-overflow-control"
                                >
                                  <ModalHeader closeButton>
                                    <div>Cancel confirmation</div>
                                  </ModalHeader>
                                  <ModalBody>
                                    <span className="question-text-style">
                                      Do you want to cancel the ride ?
                                    </span>
                                    <Row className="mt-3">
                                      {this.props.CancelModData.map(
                                        (stats, idx) => (
                                          <Col
                                            className="col-6 col-md-6 mb-4 "
                                            key={idx}
                                            {...stats.attrs}
                                          >
                                            {/* {console.log(data[stats.apiID])} */}
                                            <InputData
                                              id={`small-stats-${idx}`}
                                              variation="1"
                                              label={stats.label}
                                              value={
                                                this.state.tripdata[stats.apiID]
                                              }
                                              stats={stats.stats}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                    <Row>
                                      {this.props.SorDesData.map(
                                        (stats, idx) => (
                                          <Col
                                            className="col-md-6 mb-4 "
                                            key={idx}
                                            {...stats.attrs}
                                          >
                                            {/* {console.log(data[stats.apiID])} */}
                                            <InputData
                                              id={`small-stats-${idx}`}
                                              variation="1"
                                              label={stats.label}
                                              value={
                                                this.state.tripdata[stats.apiID]
                                              }
                                              stats={stats.stats}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>

                                    <Row>
                                      <Col className="col-6">
                                        <FormCheckbox
                                          checked={this.state.cnoshow}
                                          onChange={(e) =>
                                            this.handleChange(e, "cnoshow")
                                          }
                                        >
                                          Customer no show
                                        </FormCheckbox>
                                      </Col>
                                      <Col className="col-6">
                                        <FormCheckbox
                                          checked={this.state.breakdown}
                                          onChange={(e) =>
                                            this.handleChange(e, "breakdown")
                                          }
                                        >
                                          Vehicle breakdown
                                        </FormCheckbox>
                                      </Col>
                                      <Col className="col-6">
                                        <FormCheckbox
                                          checked={this.state.flightDelay}
                                          onChange={(e) =>
                                            this.handleChange(e, "flightDelay")
                                          }
                                        >
                                          Flight delayed
                                        </FormCheckbox>
                                      </Col>
                                      <Col className="col-6">
                                        <FormCheckbox
                                          checked={this.state.dnoshow}
                                          onChange={(e) =>
                                            this.handleChange(e, "dnoshow")
                                          }
                                        >
                                          Driver no show
                                        </FormCheckbox>
                                      </Col>
                                    </Row>
                                    <Row className="w-100 mx-auto mt-2">
                                      <textarea
                                        className="w-100"
                                        rows="4"
                                        placeholder="Please provide a reason for cancellation"
                                        invalid={
                                          this.state.message ===
                                          "Please provide a reason for cancellation"
                                            ? true
                                            : false
                                        }
                                        value={this.state.cancel_reason}
                                        onChange={this.onReasonChange}
                                      />
                                    </Row>
                                    <Row>
                                      <Button
                                        className="ml-2 mt-4 "
                                        onClick={this.gencancelOTP}
                                        theme="dark"
                                        size="lg"
                                        style={{
                                          fontSize: "0.8rem",
                                          fontWeight: "700",
                                          minWidth: "200px",
                                          borderRadius: "3px",
                                        }}
                                      >
                                        GENERATE OTP
                                      </Button>
                                      <div className="ml-md-5 ml-2 mt-4">
                                        <FormInput
                                          size="sm"
                                          id="#otpNumber"
                                          className="text-center py-3"
                                          value={this.state.otpNumber}
                                          onChange={this.handleOtpChange}
                                          type="text"
                                          minLength={6}
                                          maxLength={6}
                                          placeholder="Enter the OTP"
                                        />
                                      </div>
                                      {this.state.otpGen === true && (
                                        <span
                                          className="ml-2 font-weight-light mt-4"
                                          style={{ color: "#59E67F" }}
                                        >
                                          OTP has been sent
                                        </span>
                                      )}
                                      {this.state.otpGen === false && (
                                        <span
                                          className="ml-2 font-weight-light mt-4"
                                          style={{ color: "#FF0000" }}
                                        >
                                          Failed to send OTP
                                        </span>
                                      )}
                                      <span className="ml-2 font-weight-light">
                                        *This OTP is valid for the next 15
                                        minutes
                                      </span>
                                    </Row>
                                    <Row>
                                      <Col className="col-12 d-flex justify-content-center">
                                        <Button
                                          theme="dark"
                                          size="lg"
                                          style={{
                                            fontSize: "0.8rem",
                                            fontWeight: "700",
                                            minWidth: "200px",
                                            borderRadius: "3px",
                                          }}
                                          className="ml-2 mt-4 "
                                          onClick={this.verifycancelOTP}
                                        >
                                          VERIFY & CANCEL RIDE
                                        </Button>
                                      </Col>
                                    </Row>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    {this.state.showTagging === true && (
                      <Row className="mx-auto mb-5 w-100 d-flex justify-content-center">
                        <Col className="col-12 col-md-5 col-lg-5 mt-2 d-flex justify-content-center">
                          <Button
                            theme="dark"
                            className="py-md-3 button-width-style"
                            onClick={this.toggleTag}
                            size="lg"
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "700",
                              minWidth: "200px",
                              borderRadius: "3px",
                            }}
                          >
                            TAG DRIVER
                          </Button>
                          <Modal
                            show={this.state.tagDriver}
                            onHide={this.toggleExitTag}
                            centered
                            modalClassName="modal-overflow-control"
                          >
                            <form onSubmit={this.tagDriverToTheRide}>
                              <ModalHeader closeButton>
                                <div>Tag a driver to the trip</div>
                              </ModalHeader>
                              <ModalBody>
                                <span className="question-text-style">
                                  Search for the driver
                                </span>
                                <Row className="mt-3">
                                  {/* {this.props.DetachModData.map((stats, idx) => (
                                <Col
                                  className="col-6 col-md-6 mb-4 "
                                  key={idx}
                                  {...stats.attrs}
                                >
                                  
                                  <InputData
                                    id={`small-stats-${idx}`}
                                    variation="1"
                                    label={stats.label}
                                    value={this.state[stats.apiID]}
                                    stats={stats.stats}
                                  />
                                </Col>
                              ))} */}
                                  <Col className="col-12 col-md-6 mb-4 my-auto">
                                    <FormInput
                                      value={this.state.tagPhonenumber}
                                      className="py-3"
                                      placeholder="Enter the phonenumber"
                                      onChange={this.inputTagData}
                                      type="text"
                                      maxLength="10"
                                      required
                                    />
                                  </Col>
                                  <Col className="my-auto">
                                    <Button
                                      onClick={this.searchFoundTest}
                                      theme="dark"
                                      size="lg"
                                      style={{
                                        fontSize: "0.8rem",
                                        fontWeight: "700",
                                        minWidth: "120px",
                                        borderRadius: "3px",
                                      }}
                                    >
                                      SEARCH
                                    </Button>
                                  </Col>
                                </Row>
                                {this.state.searchFound === true && (
                                  <Row className="mt-4">
                                    <Col className="col-12 py-3 ">
                                      Name : {this.state.searchData.driver_name}
                                    </Col>
                                    <Col className="col-12 py-3 ">
                                      Phone :{" "}
                                      {this.state.searchData.phone_number}
                                    </Col>
                                    <Col className="col-12 py-3 ">
                                      Vehicle number :{" "}
                                      {this.state.searchData.vehicle_number}
                                    </Col>
                                    <Col className="col-12 py-3 ">
                                      Trip ID: {this.props.tripId}
                                    </Col>
                                    <Col className="col-6 py-3 ">
                                      <label
                                        style={{
                                          fontWeight: "800",
                                          fontSize: "1rem",
                                        }}
                                      >
                                        Offered amount:{" "}
                                      </label>
                                      <FormInput
                                        value={this.state.tagOfferedAmount}
                                        className="py-3"
                                        placeholder="Enter offered amount"
                                        onChange={this.inputTagOfferedAmount}
                                        type="number"
                                        required
                                        min={0}
                                        max={9999}
                                      />
                                    </Col>
                                  </Row>
                                )}
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  theme="dark"
                                  size="lg"
                                  className="ml-2"
                                  onClick={this.toggleExitTag}
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "700",
                                    minWidth: "120px",
                                    borderRadius: "3px",
                                  }}
                                >
                                  CANCEL
                                </Button>
                                <Button
                                  theme="dark"
                                  className="ml-2"
                                  size="lg"
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "700",
                                    minWidth: "120px",
                                    borderRadius: "3px",
                                  }}
                                >
                                  TAG DRIVER
                                </Button>
                              </ModalFooter>
                            </form>
                          </Modal>
                        </Col>
                      </Row>
                    )}

                    <Row className="d-flex justify-content-center mb-4">
                      {this.state.showBox === true && (
                        <Col className="col-12 col-md-4 mx-3 mb-3">
                          <Card>
                            <CardHeader>
                              <Col className="col-12 d-flex justify-content-center">
                                {" "}
                                <h5>
                                  <u>Tag details</u>
                                </h5>
                              </Col>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Driver name
                                  </label>{" "}
                                  - {this.state.storeTagData.driver_name}
                                </Col>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Driver phonenumber
                                  </label>{" "}
                                  - {this.state.storeTagData.driver_phonenumber}
                                </Col>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Tagged by
                                  </label>{" "}
                                  - {this.state.storeTagData.tagged_by}
                                </Col>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Bank account status:
                                  </label>{" "}
                                  -{" "}
                                  {this.state.beneficiaryStatus === false ? (
                                    <label style={{ color: "#FF0000" }}>
                                      {" "}
                                      Not verified
                                    </label>
                                  ) : (
                                    <label style={{ color: "#59E67F" }}>
                                      {" "}
                                      Verified
                                    </label>
                                  )}
                                </Col>
                              </Row>
                            </CardBody>
                            <CardFooter>
                              {/* window.sessionStorage.getItem("roletype") ===
                                  "teamlead" || */}
                              {(window.sessionStorage.getItem("roletype") ===
                                "manager" ||
                                window.sessionStorage.getItem("roletype") ===
                                  "assistantmanager" ||
                                window.sessionStorage.getItem("roletype") ===
                                  "teamlead") && (
                                <Row>
                                  {this.state.beneficiaryStatus === true && (
                                    <Col className="col-12 col-md-12 d-flex justify-content-center">
                                      {this.state.paid_status === true && (
                                        <Button
                                          className="button-width-style"
                                          disabled
                                          theme="dark"
                                          size="lg"
                                          style={{
                                            fontSize: "0.8rem",
                                            fontWeight: "700",
                                            minWidth: "200px",
                                            borderRadius: "3px",
                                          }}
                                        >
                                          PAID
                                        </Button>
                                      )}
                                      {this.state.paid_status === false && (
                                        <Button
                                          className="button-width-style"
                                          disabled={this.state.deactivate}
                                          theme="dark"
                                          size="lg"
                                          style={{
                                            fontSize: "0.8rem",
                                            fontWeight: "700",
                                            minWidth: "200px",
                                            borderRadius: "3px",
                                          }}
                                          onClick={this.openTogglePayNow}
                                        >
                                          PAY NOW
                                        </Button>
                                      )}
                                    </Col>
                                  )}
                                </Row>
                              )}
                            </CardFooter>
                          </Card>
                        </Col>
                      )}
                      {this.state.showPayBox === true && (
                        <Col className="col-12 col-md-4 mx-3 ">
                          <Card>
                            <CardHeader>
                              <Col className="col-12 d-flex justify-content-center">
                                {" "}
                                <h5>
                                  <u>Payment details</u>
                                </h5>
                              </Col>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                {/* <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Driver
                                  </label>{" "}
                                  - {this.state.storeTagData.driver_name}
                                </Col> */}
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Beneficiary id :
                                  </label>{" "}
                                  - {this.state.storePayData.beneficiary_id}
                                </Col>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Agent name
                                  </label>{" "}
                                  - {this.state.storePayData.user_name}
                                </Col>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Agent ID
                                  </label>{" "}
                                  -{" "}
                                  {this.state.storePayData.transfer_initiatedBy}
                                </Col>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Paid on
                                  </label>{" "}
                                  -{" "}
                                  {moment(
                                    new Date(this.state.storePayData.paid_date)
                                  ).format("DD-MM-YY [at] hh:mm:ss A")}
                                </Col>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Paid amount
                                  </label>{" "}
                                  - {this.state.storePayData.paid_amount}
                                </Col>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Trip amount
                                  </label>{" "}
                                  -{" "}
                                  {this.state.storePayData &&
                                  this.state.storePayData.trip_amount
                                    ? this.state.storePayData.trip_amount
                                    : "-"}
                                </Col>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Deducted amount
                                  </label>{" "}
                                  -{" "}
                                  {this.state.storePayData &&
                                  this.state.storePayData.deductedAmount
                                    ? this.state.storePayData.deductedAmount
                                    : "-"}
                                </Col>

                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Payment mode
                                  </label>{" "}
                                  - {this.state.storePayData.payment_mode}
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      )}
                    </Row>
                  </React.Fragment>
                )}

                {(this.state.tripdata.trip_status === "Completed" ||
                  this.state.tripdata.trip_status === "Ended") && (
                  <React.Fragment>
                    {this.state.showPayBox === true && (
                      <Row className="d-flex justify-content-center mb-4">
                        <Col className="col-12 col-md-5 col-lg-5">
                          <Card>
                            <CardHeader>
                              <Col className="col-12 d-flex justify-content-center">
                                {" "}
                                <h5>
                                  <u>Payment details</u>
                                </h5>
                              </Col>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                {/* <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Driver
                                  </label>{" "}
                                  - {this.state.storeTagData.driver_name}
                                </Col> */}
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Beneficiary id :
                                  </label>{" "}
                                  - {this.state.storePayData.beneficiary_id}
                                </Col>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Agent name
                                  </label>{" "}
                                  - {this.state.storePayData.user_name}
                                </Col>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Agent ID
                                  </label>{" "}
                                  -{" "}
                                  {this.state.storePayData.transfer_initiatedBy}
                                </Col>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Paid on
                                  </label>{" "}
                                  -{" "}
                                  {moment(
                                    new Date(this.state.storePayData.paid_date)
                                  ).format("DD-MM-YY [at] hh:mm:ss A")}
                                </Col>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Paid amount
                                  </label>{" "}
                                  - {this.state.storePayData.paid_amount}
                                </Col>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Trip amount
                                  </label>{" "}
                                  -{" "}
                                  {this.state.storePayData &&
                                  this.state.storePayData.trip_amount
                                    ? this.state.storePayData.trip_amount
                                    : "-"}
                                </Col>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Deducted amount
                                  </label>{" "}
                                  -{" "}
                                  {this.state.storePayData &&
                                  this.state.storePayData.deductedAmount
                                    ? this.state.storePayData.deductedAmount
                                    : "-"}
                                </Col>
                                <Col
                                  className="col-12"
                                  style={{ fontSize: "calc(0.5rem + 1vmin)" }}
                                >
                                  <label style={{ fontWeight: "600" }}>
                                    Payment mode
                                  </label>{" "}
                                  - {this.state.storePayData.payment_mode}
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </React.Fragment>
                )}
                {this.state.tripdata.trip_status !== "Requested" && (
                  <React.Fragment>
                    {/* <Card className="mb-5">
                      <CardBody> */}
                    {console.log(this.state.tripdata.timeStamps)}
                    {console.log("Jagan")}
                    {this.state.tripdata.timeStamps !== undefined && (
                      <Row>
                        {this.state.tripdata.timeStamps.assigned_by !==
                          undefined && (
                          <Col className="col-12 col-md-3 col-lg-3 px-3 px-md-2">
                            <Card className="mb-5">
                              <CardBody>
                                <div
                                  className="d-flex justify-content-center"
                                  style={{
                                    fontWeight: "800",
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  <u>Assigned by</u>
                                </div>
                                <div className="d-flex justify-content-center">
                                  {this.state.tripdata.timeStamps
                                    .assigned_by !== "-" && (
                                    <label
                                      style={{
                                        fontWeight: "800",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      {" " +
                                        this.state.tripdata.timeStamps
                                          .assigned_by}
                                    </label>
                                  )}
                                  {this.state.tripdata.timeStamps
                                    .assigned_by === "-" && <label>-</label>}
                                </div>
                                <div className="d-flex justify-content-center">
                                  {this.state.tripdata.timeStamps
                                    .assigned_by !== "-" && (
                                    <label
                                      style={{
                                        fontWeight: "800",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      {" " +
                                        this.state.tripdata.timeStamps
                                          .assigned_time}
                                    </label>
                                  )}
                                  {this.state.tripdata.timeStamps
                                    .assigned_time === "-" && <label>-</label>}
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        )}
                        {this.state.tripdata.timeStamps.reassigned_by !==
                          "-" && (
                          <Col className="col-12 col-md-3 col-lg-3  px-3 px-md-2">
                            <Card className="mb-5">
                              <CardBody>
                                <div
                                  className="d-flex justify-content-center"
                                  style={{
                                    fontWeight: "800",
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  <u>Re-assigned by</u>
                                </div>
                                <div className="d-flex justify-content-center">
                                  {this.state.tripdata.timeStamps
                                    .reassigned_by !== "-" && (
                                    <label
                                      style={{
                                        fontWeight: "800",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      {" " +
                                        this.state.tripdata.timeStamps
                                          .reassigned_by}
                                    </label>
                                  )}
                                  {this.state.tripdata.timeStamps
                                    .reassigned_by === "-" && <label>-</label>}
                                </div>
                                <div className="d-flex justify-content-center">
                                  {this.state.tripdata.timeStamps
                                    .reassigned_time !== "-" && (
                                    <label
                                      style={{
                                        fontWeight: "800",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      {" " +
                                        this.state.tripdata.timeStamps
                                          .reassigned_time}
                                    </label>
                                  )}
                                  {this.state.tripdata.timeStamps
                                    .reassigned_time === "-" && (
                                    <label>-</label>
                                  )}
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        )}
                        {this.state.tripdata.timeStamps.cancelled_executive !==
                          "-" && (
                          <Col className="col-12 col-md-3 col-lg-3  px-3 px-md-2">
                            <Card className="mb-5">
                              <CardBody>
                                <div
                                  className="d-flex justify-content-center"
                                  style={{
                                    fontWeight: "800",
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  <u>Cancelled by</u>
                                </div>
                                <div className="d-flex justify-content-center">
                                  {this.state.tripdata.timeStamps
                                    .cancelled_executive !== "-" && (
                                    <label
                                      style={{
                                        fontWeight: "800",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      {" " +
                                        this.state.tripdata.timeStamps
                                          .cancelled_executive}
                                    </label>
                                  )}
                                  {this.state.tripdata.timeStamps
                                    .cancelled_executive === "-" && (
                                    <label>-</label>
                                  )}
                                </div>
                                <div className="d-flex justify-content-center">
                                  {this.state.tripdata.timeStamps
                                    .cancel_time !== "-" && (
                                    <label
                                      style={{
                                        fontWeight: "800",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      {" " +
                                        this.state.tripdata.timeStamps
                                          .cancel_time}
                                    </label>
                                  )}
                                  {this.state.tripdata.timeStamps
                                    .cancel_time === "-" && <label>-</label>}
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        )}
                        {this.state.tripdata.timeStamps.rescheduledStatus !==
                          false && (
                          <Col className="col-12 col-md-3 col-lg-3  px-3 px-md-2">
                            <Card className="mb-5">
                              <CardBody>
                                <div
                                  className="d-flex justify-content-center"
                                  style={{
                                    fontWeight: "800",
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  <u>Previous re-scheduled time</u>
                                </div>
                                <div className="d-flex justify-content-center">
                                  {this.state.tripdata.timeStamps
                                    .previousScheduledTime !== "-" && (
                                    <label
                                      style={{
                                        fontWeight: "800",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      {" " +
                                        this.state.tripdata.timeStamps
                                          .previousScheduledTime}
                                    </label>
                                  )}
                                  {this.state.tripdata.timeStamps
                                    .previousScheduledTime === "-" && (
                                    <label>-</label>
                                  )}
                                </div>
                              </CardBody>
                            </Card>

                            {/* <div className="d-flex justify-content-center">
                              {this.state.tripdata.timeStamps.cancel_time !==
                                "-" && (
                                <label
                                  style={{
                                    fontWeight: "800",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {" " +
                                    this.state.tripdata.timeStamps.cancel_time}
                                </label>
                              )}
                              {this.state.tripdata.timeStamps.cancel_time ===
                                "-" && <label>-</label>}
                            </div> */}
                          </Col>
                        )}
                      </Row>
                    )}
                    {/* </CardBody>
                    </Card> */}
                  </React.Fragment>
                )}
              </Card>
              {this.state.success === false && (
                <ReactSnackBar
                  Icon={
                    <i
                      className="far fa-times-circle fa-lg"
                      style={{ color: "#FF0000" }}
                    />
                  }
                  Show={this.state.Show}
                >
                  {this.state.message}
                </ReactSnackBar>
              )}
              {this.state.success === true && (
                <ReactSnackBar
                  Icon={
                    <span>
                      <i
                        className="far fa-check-circle fa-lg"
                        style={{ color: "#26FF58" }}
                      />
                    </span>
                  }
                  Show={this.state.Show}
                >
                  {this.state.message}
                </ReactSnackBar>
              )}
            </Col>
          </Row>
        )}
        {this.state.tripDump === true && (
          <TripDump
            tripId={this.props.tripId}
            enableTripDump={this.enableTripDump}
          />
        )}
        {this.state.rideIssue === true && (
          <RideIssues
            data={this.state.passData}
            enableRideIssue={this.enableRideIssue}
          />
        )}
      </React.Fragment>
    );
  }
}

TripDetails.propTypes = {
  TripData: PropTypes.array,
};
TripDetails.defaultProps = {
  MapDefaults: [
    {
      center: {
        lat: 59.95,
        lng: 30.33,
      },
      zoom: 11,
    },
  ],
  TripData: [
    {
      label: "Ride ID",
      value: "",
      attrs: "",
      apiID: "trip_id",
    },
    {
      label: "Vendor ID",
      value: "",
      attrs: "",
      apiID: "vendor_id",
    },
    {
      label: "Region",
      value: "",
      attrs: "",
      apiID: "region",
    },
  ],
  RiderData: [
    {
      label: "Rider name",
      value: "",
      attrs: "",
      apiID: "rider_name",
    },
    {
      label: "Rider mobile number",
      value: "",
      attrs: "",
      apiID: "rider_phone",
    },
    {
      label: "Rider email",
      value: "",
      attrs: "",
      apiID: "email",
    },
  ],
  TripLocationData: [
    {
      label: "Pick-up location",
      value: "",
      attrs: "",
      apiID: "sname",
    },
    {
      label: "Drop-off location",
      value: "",
      attrs: "",
      apiID: "dname",
    },
  ],
  VehicleData: [
    {
      label: "Vehicle type",
      value: "",
      attrs: "",
      apiID: "vehicle_type",
    },
  ],
  TripStatusData: [
    {
      label: "Ride status",
      value: "",
      attrs: "",
      apiID: "trip_status",
    },
    {
      label: "Ride type",
      value: "",
      attrs: "",
      apiID: "ride_type",
    },
    {
      label: "One way or round trip",
      value: "",
      attrs: "",
      apiID: "travelType",
    },
    {
      label: "Departure time",
      value: "",
      attrs: "",
      apiID: "pickup_time",
    },
  ],
  FareData: [
    {
      label: "Est. distance(kms)",
      value: "",
      attrs: "",
      apiID: "distance",
    },
    {
      label: "Approx fare",
      value: "",
      attrs: "",
      apiID: "approx_fare",
    },
    {
      label: "Amount to collect",
      value: "",
      attrs: "",
      apiID: "balanceAmount",
    },
  ],
  CustomerConsent: [
    {
      label: "Customer consent taken",
      value: "",
      attrs: "",
      apiID: "conscent_taken",
    },
    {
      label: "Travel via [Toll/Non-toll]",
      value: "",
      attrs: "",
      apiID: "toll_route",
    },
    {
      label: "Consent taken time",
      value: "",
      attrs: "",
      apiID: "timestamp",
    },
  ],
  RentalFareData: [
    {
      label: "Est. distance(kms)",
      value: "",
      attrs: "",
      apiID: "km_package_limit",
    },
    {
      label: "Extra kilometer rate",
      value: "",
      attrs: "",
      apiID: "extrakmcharges",
    },
    {
      label: "Extra hour rate",
      value: "",
      attrs: "",
      apiID: "extrahourcharges",
    },
    {
      label: "Approx fare",
      value: "",
      attrs: "",
      apiID: "approx_fare",
    },
    {
      label: "Amount to collect",
      value: "",
      attrs: "",
      apiID: "balanceAmount",
    },
  ],
  CancelModData: [
    {
      label: "Trip ID",
      value: "",
      attrs: "",
      apiID: "trip_id",
    },
    {
      label: "Vendor ID",
      value: "",
      attrs: "",
      apiID: "vendor_id",
    },
    {
      label: "Pickup time",
      value: "",
      attrs: "",
      apiID: "pickup_time",
    },
  ],
  DriverPayData: [
    {
      label: "Driver name",
      value: "",
      attrs: "",
      apiID: "driver_name",
    },
    {
      label: "Driver phonenumber",
      value: "",
      attrs: "",
      apiID: "driver_phonenumber",
    },
  ],
  DriverPayDatas: [
    {
      label: "Driver name",
      value: "",
      attrs: "",
      apiID: "driver_name",
    },
    {
      label: "Driver phonenumber",
      value: "",
      attrs: "",
      apiID: "driver_phone",
    },
  ],
  DetachModData: [
    {
      label: "Phonenumber",
      value: "",
      attrs: "",
      apiID: "tagPhonenumber",
    },
  ],
  SorDesData: [
    {
      label: "From",
      value: "",
      attrs: "",
      apiID: "sname",
    },
    {
      label: "To",
      value: "",
      attrs: "",
      apiID: "dname",
    },
  ],
  DriverData: [
    {
      label: "Driver name",
      value: "",
      attrs: "",
      apiID: "driver_name",
    },
    {
      label: "Driver Phonenumber",
      value: "",
      attrs: "",
      apiID: "driver_phone",
    },
  ],
  DriverLocation: [
    {
      label: "Start CRN",
      value: "",
      attrs: "",
      apiID: "crn_number",
    },
    {
      label: "End CRN",
      value: "",
      attrs: "",
      apiID: "endcrn_number",
    },
  ],
  ExtraAmount: [
    {
      label: "Offered amount",
      value: "",
      attrs: "",
      apiID: "offeredAmount",
    },
    {
      label: "Extra travelled kms",
      value: "",
      attrs: "",
      apiID: "extra_traveled_kms",
    },
    {
      label: "Empty kms",
      value: "",
      attrs: "",
      apiID: "empty_kms",
    },
    {
      label: "Fine amount",
      value: "",
      attrs: "",
      apiID: "fineAmount",
    },
  ],
  RentalData: [
    {
      label: "Package hours",
      value: "",
      attrs: "",
      apiID: "hour_package_limit",
    },
    {
      label: "Package distance",
      value: "",
      attrs: "",
      apiID: "km_package_limit",
    },
    {
      label: "Package fare",
      value: "",
      attrs: "",
      apiID: "package_rate",
    },
  ],
};

export default TripDetails;

//{
//    label: "Booking by",
//  value: "",
//attrs: "",
//apiID: "booking_by",
//},
