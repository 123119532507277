import React from "react";
import GoogleMapReact from "google-map-react";
// import Polyline from "google-map-react";
import * as stringsData from "../constants/Strings.json";

import * as config from "../constants/constants.json";

function getRideType(data) {
  let datas = data.toLowerCase();
  if (datas === "hatchback") {
    return "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/boltcarmarker.svg";
  } else if (datas === "sedan") {
    return "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/flashcarmarker.svg";
  } else {
    return "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/cruisemarker.svg";
  }
}
// import { LiveMap } from "./LiveGmaps";
const MarkerComponent = (data) => {
  console.log(data);
  return (
    <img src={getRideType(data.vehicleType)} height="auto" width="25"></img>
  );
};

const SourceComponent = () => (
  <img
    src="https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/source_new.svg"
    height="auto"
    width="25"
  ></img>
);

const DestinationComponent = () => (
  <img
    src="https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/destination_new.svg"
    height="auto"
    width="25"
  ></img>
);

function retDistance(lat1, lon1, lat2, lon2) {
  var R = 6371; // km (change this constant to get miles)
  var dLat = ((lat2 - lat1) * Math.PI) / 180;
  var dLon = ((lon2 - lon1) * Math.PI) / 180;
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  if (d > 1) return Math.round(d * 1000);
  else if (d <= 1) return Math.round(d * 1000);
  return d;
}

class RoadMap extends React.Component {
  state = {
    progress: [],
    path: [],
  };

  fieldTest = React.createRef();
  position = [];
  numDeltas = 100;
  velocity = 5;
  delay = 10 * 0.5;
  i = 0;
  deltaLat;
  deltaLng;
  initialDate = new Date();

  fetchLocationCall = () => {
    fetch(config.url + "htmlcrm/getDriverLocation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        trip_id: this.props.dataProps.trip_id,
        // trip_id: "LiP1xL6ix3LvRDLR",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("herererer");
          // console.log(result);
          let lat1 = result.driver_location[1];
          let lng1 = result.driver_location[0];
          // const latLong1 = new window.google.maps.LatLng(lat1, lng1);
          let lat2 =
            this.state.path[0] === undefined
              ? result.driver_location[1]
              : this.state.path[0].lat;
          let lng2 =
            this.state.path[0] === undefined
              ? result.driver_location[0]
              : this.state.path[0].lng;
          // console.log(lat2);
          // console.log(lng2);
          // const latLong2 = new window.google.maps.LatLng(lat2, lng2);
          const distance = retDistance(lat1, lng1, lat2, lng2);
          let ldata = {
            lat: result.driver_location[1],
            lng: result.driver_location[0],
            distance: distance,
          };
          this.setState(
            {
              path: [...this.state.path, ldata],
            },
            () => {
              this.moveObject();
            }
          );
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  };
  getDistance = () => {
    // seconds between when the component loaded and now
    const differentInTime = (new Date() - this.initialDate) / 1000; // pass to seconds
    // console.log(differentInTime);
    return differentInTime * this.velocity; // d = v*t -- thanks Newton!
  };

  componentDidMount = () => {
    this.fetchLocationCall();
    this.interval = window.setInterval(this.fetchLocationCall, 40000);
    // this.intervals = window.setInterval(this.moveObject, 5000);
  };

  componentWillUnmount = () => {
    window.clearInterval(this.interval);
    // window.clearInterval(this.intervals);
  };

  moveMarker = () => {
    this.position[0] += this.deltaLat;
    this.position[1] += this.deltaLng;
    if (window.google) {
      let latlng = new window.google.maps.LatLng(
        this.position[0],
        this.position[1]
      );

      this.setState({
        progress: [latlng],
      });

      if (this.i != this.numDeltas) {
        this.i++;
        setTimeout(this.moveMarker, this.delay);
      }
    }
  };

  moveObject = () => {
    this.i = 0;
    let paths = this.state.path;
    let old_lat =
      paths[paths.length - 2] === undefined
        ? paths[paths.length - 1].lat
        : paths[paths.length - 2].lat;
    let old_lng =
      paths[paths.length - 2] === undefined
        ? paths[paths.length - 1].lng
        : paths[paths.length - 2].lng;
    this.position = [old_lat, old_lng];
    // console.log(old_lat);
    // console.log(old_lng);
    this.deltaLat = (paths[paths.length - 1].lat - old_lat) / this.numDeltas;
    this.deltaLng = (paths[paths.length - 1].lng - old_lng) / this.numDeltas;
    // console.log(this.deltaLat);
    // console.log(this.deltaLng);
    this.moveMarker();
  };

  componentDidUpdate = () => {
    let distance = this.getDistance();
    if (!distance) {
      return;
    }

    let pathss = this.state.path;
    let progress = pathss.filter(
      (coordinates) => coordinates.distance < distance
    );

    let nextLine = pathss.find(
      (coordinates) => coordinates.distance > distance
    );
    // console.log(progress);
    // console.log(nextLine);
    let point1, point2;

    if (nextLine) {
      point1 = progress[progress.length - 1];
      point2 = nextLine;
    } else {
      // it's the end, so use the latest 2
      point1 =
        progress[progress.length - 2] === undefined
          ? progress[progress.length - 1]
          : progress[progress.length - 2];
      point2 = progress[progress.length - 1];
    }

    // console.log(point1);
    // console.log(point2);
    // console.log(point1);
    if (point1 === undefined || point1.lat === undefined) {
    } else {
      if (window.google) {
        const point1LatLng = new window.google.maps.LatLng(
          point1.lat,
          point1.lng
        );
        const point2LatLng = new window.google.maps.LatLng(
          point2.lat,
          point2.lng
        );
        const markerUrl =
          "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/bolt.svg";
        const marker = document.querySelector(`[src="${markerUrl}"]`);
        if (window.google.maps.geometry) {
          const angle = window.google.maps.geometry.spherical.computeHeading(
            point1LatLng,
            point2LatLng
          );
          const actualAngle = angle;
          if (marker) {
            // when it hasn't loaded, it's null
            marker.style.transform = `rotate(${actualAngle}deg)`;
            marker.style.transition = `all 0.5s ease-in-out`;
          }
        }
      }
    }
  };

  render = () => {
    // console.log(window);

    return (
      <div
        className="container-fluid px-0 scale-for-mobile"
        style={{ height: "100%", width: "100%" }}
      >
        <GoogleMapReact
          // defaultCenter={{ lat: 12.9716, lng: 77.5946 }}
          bootstrapURLKeys={{
            key: stringsData.apiKey,
            libraries: ["geometry"],
          }}
          zoom={
            this.state.progress[this.state.progress.length - 1] === undefined
              ? 10
              : 16
          }
          center={{
            lat:
              this.state.progress[this.state.progress.length - 1] === undefined
                ? 12.9716
                : this.state.progress[this.state.progress.length - 1].lat(),
            lng:
              this.state.progress[this.state.progress.length - 1] === undefined
                ? 77.5946
                : this.state.progress[this.state.progress.length - 1].lng(),
          }}
          options={{
            styles: [
              {
                featureType: "all",
                elementType: "all",
                stylers: [
                  {
                    hue: "#008eff",
                  },
                ],
              },
              {
                featureType: "poi",
                elementType: "all",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "road",
                elementType: "all",
                stylers: [
                  {
                    saturation: "0",
                  },
                  {
                    lightness: "0",
                  },
                ],
              },
              {
                featureType: "transit",
                elementType: "all",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "water",
                elementType: "all",
                stylers: [
                  {
                    visibility: "simplified",
                  },
                  {
                    saturation: "-60",
                  },
                  {
                    lightness: "-20",
                  },
                ],
              },
            ],
          }}
        >
          {/* <Polyline path={this.state.path}></Polyline> */}
          {this.props.dataProps &&
            (this.props.dataProps.slatlon[1] !== undefined ||
              this.props.dataProps.slatlon[0] !== undefined) && (
              <SourceComponent
                lat={this.props.dataProps.slatlon[1]}
                lng={this.props.dataProps.slatlon[0]}
              />
            )}
          {this.state.progress && this.state.progress.length > 0 && (
            <MarkerComponent
              vehicleType={this.props.vehicleType}
              lat={this.state.progress[this.state.progress.length - 1].lat()}
              lng={this.state.progress[this.state.progress.length - 1].lng()}
            />
          )}
          {this.props.dataProps &&
            (this.props.dataProps.slatlon[1] !== undefined ||
              this.props.dataProps.slatlon[0] !== undefined) && (
              <DestinationComponent
                lat={this.props.dataProps.dlatlon[1]}
                lng={this.props.dataProps.dlatlon[0]}
              />
            )}
        </GoogleMapReact>
      </div>
    );
  };
}

export default RoadMap;
