import React, { useState, useEffect } from "react";
import { Button, FormInput, FormGroup } from "shards-react";
import ReactPico from "../utils/pico/ReactPico";
import FaceIndicator from "./FaceIndicator";
import socketIOClient from "socket.io-client";

import * as config from "../constants/constants.json";

// const socket = socketIOClient(config.SOCKET_FACE, {
//   transports: ["websocket"],
// });

// import { FaceFinder } from "react-picojs";
/**
 * Import files and images
 */

// import "react-picojs/dist/index.css";

function FaceDetect(props) {
  const [face, setFace] = useState(null);
  const [email, setEmail] = useState("");
  // console.log("loaded");

  // useEffect(() => {
  //   // console.log(face);
  //   if (face !== null) {
  //     var d = new Date();
  //     var milliseconds = d.getTime();
  //     let data = {
  //       content: face.imageData,
  //       // name: props.name,
  //       // user_id: props.user_id,
  //       milliseconds: milliseconds,
  //       name: "ravikiran",
  //       user_id: "5d07363d8a332a0af0df7b59",
  //       type: "verify",
  //     };
  //     console.log(data);
  //     socket.emit("message", data);
  //   }
  // }, [face]);

  const registerData = () => {
    var d = new Date();
    var milliseconds = d.getTime();
    let data = {
      content: face.imageData,
      // name: props.name,
      // user_id: props.user_id,
      milliseconds: milliseconds,
      // name: props.name,
      // user_id: props.user_id,
      name: "sidharth",
      user_id: "5d07363d8a332a0af0df7b59",
      type: "register",
    };
    // console.log(data);
    // socket.emit("message", data);
  };

  // useEffect(() => {
  //   socket.on("message", function(response) {
  //     // console.log(response);
  //     if (response.message === "verified") {
  //     } else {
  //     }
  //   });
  // }, []);

  return (
    <React.Fragment>
      <ReactPico
        onFaceFound={(face) => {
          setFace(face);
        }}
      />
      <div className="d-flex justify-content-center ">
        <FormGroup>
          <label>Email</label>
          <FormInput placeholder="enter user email" value={email} />
        </FormGroup>

        <Button
          className="mx-3 mt-4 login-button-new py-3"
          style={{
            backgroundColor: "#151220",
            boxShadow: "0px 6px 10px #00000029",
            borderColor: "#151220",
          }}
          onClick={registerData}
        >
          REGISTER
        </Button>
      </div>

      {/* {face && <FaceIndicator x={face.totalX} y={face.totalY} />} */}
    </React.Fragment>
  );
}

export default FaceDetect;
