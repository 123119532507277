// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Button,
  CardBody,
  Card,
  FormRadio,
  FormInput,
  FormTextarea,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";
import { Modal, ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import ReactTable from "react-table-v6";
import CommonBox from "../components/common/commonDisplayBox";
import PageTitle from "./../components/common/PageTitle";
import ShiftsView from "./../components/dataviews/ShiftsView";
import SearchAssignData from "./../components/common/SearchAssignData";
import ShiftNotesSection from "./../components/common/ShiftNotesSection";
import * as config from "../constants/constants.json";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import { AsyncParser } from "json2csv";
import socketIOClient from "socket.io-client";
// import TopDrivers from "../components/common/TopDrivers"
// import RideAnalysis from "../components/dataviews/RideAnalysis"
// const ReportsView = ({ smallStats, bottomStats }) => (

// );
let todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);
let shiftNoteMillis = todayDate.getTime();
const socket = socketIOClient("http://139.59.56.178", {
  transports: ["websocket"],
});

const NOTE_PUBLISH = "notepublish";
const NOTE_HISTORY = "notehistory";
const NOTE_SUBMIT = "notesubmit";
const headers = [
  { label: "Trip ID", key: "trip_id" },
  { label: "Cancel Reason", key: "cancel_reason" },
  { label: "Source Name", key: "sname" },
  { label: "Destination Name", key: "dname" },
  { label: "Request Status", key: "request_status" },
  { label: "Requested Through", key: "requested_through" },
  { label: "Cunique ID", key: "cunique_id" },
  { label: "Dunique ID", key: "dunique_id" },
  { label: "Pickup time", key: "pickup_time" },
  { label: "Passenger Name", key: "passenger_name" },
  { label: "Passenger Phonenumber", key: "passenger_phone_number" },
  { label: "Pickup time", key: "pickup_time" },
  { label: "Driver First Name", key: "driver.first_name" },
  { label: "Driver Last Name", key: "driver.last_name" },
  { label: "Driver Phonenumber", key: "driver.phonenumber" },
];
// cunique_id: "58fae1c68bff896af0e39488";
// dname: "Kempegowda International Airport Bengaluru (BLR)";
// drivername: "Test One Goods";
// driverphn: "9663984776";
// dunique_id: "5a671f13120b8e7c885a4d5b";
// executive_name: "Dm Lucifer";
// passenger_name: "Naveen";
// passenger_phone_number: "9535179695";
// pickup_time: "2020-08-31T11:26:00.000Z";
// request_status: "consumercancelled";
// requested_through: "glory";
// sname: "J. P. Nagar, Bengaluru, Karnataka, India";
// trip_id:

const headers_too = [
  { label: "Trip ID", key: "trip_id" },
  { label: "Source Name", key: "sname" },
  { label: "Destination Name", key: "dname" },
  { label: "Request Status", key: "request_status" },
  { label: "Pickup time", key: "pickup_time" },
  { label: "Cunique ID", key: "cunique_id" },
  { label: "Passenger Name", key: "passenger_name" },
  { label: "Passenger Phonenumber", key: "passenger_phone_number" },
  { label: "Pickup time", key: "pickup_time" },
  { label: "Driver name", key: "drivername" },
  { label: "Dunique ID", key: "dunique_id" },
  { label: "Driver Phonenumber", key: "driverphn" },
];

// const data = [
//   {
//     cancel_reason: "-",
//     sname: "Kempegowda International Airport, Bengaluru",
//     dname:
//       "Fortune Select Trinity - Hotel in Bengaluru, Road Number 1, EPIP Zone, Whitefield, Bengaluru, Karnataka, India",
//     trip_id: "CZrVQ9yj3pKun6HI",
//     request_status: "ended",
//     requested_through: "mmt",
//     cunique_id: "5db861d50d24cd0bd698ec8e",
//     dunique_id: "5d839526cdfd931952749d01",
//     pickup_time: "2020-03-13T10:00:00.000Z",
//     passenger_name: "Ravali ANDE ",
//     passenger_phone_number: "7382013524",
//     driver: {
//       first_name: "Theertha Kumar",
//       last_name: "Naik",
//       phonenumber: "7026545437"
//     }
//   }
// ];
class ShiftReports extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      displayStatBox: false,
      displayTripBox: true,
      openAssignDiv: false,
      displayAssigned: false,
      filteredExecData: [],
      shiftnotesData: [],
      searchExecInput: "",
      execName: "",
      count: 0,
      data: [],
      daydata: [],
      midData: [],
      nightData: [],
      open: false,
      selectedId: "trip_id",
      datatripidInput: "",
      datadriverInput: "",
      showTipdata: false,
      showDriverdata: false,
      dataToBeDisplayed: [],
      textAreaData: "",
      driverAreaData: "",
      shiftReportArray: [],
    };
    this.changeselecteId = this.changeselecteId.bind(this);
    this.goBack = this.goBack.bind(this);
    this.DisplayAllExces = this.DisplayAllExces.bind(this);
    this.DisplayAllShiftBox = this.DisplayAllShiftBox.bind(this);
    this.handleShiftDisplay = this.handleShiftDisplay.bind(this);
    this.callApiToGetAllUsers = this.callApiToGetAllUsers.bind(this);
    this.callApitoGetAssignedRides = this.callApitoGetAssignedRides.bind(this);
    this.handleAPICall = this.handleAPICall.bind(this);
    this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
    this.downloadAllUserReport = this.downloadAllUserReport.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.inputData = this.inputData.bind(this);
    this.textAreaInputData = this.textAreaInputData.bind(this);
    this.submitToSocket = this.submitToSocket.bind(this);
  }

  // onClickDownload() {

  //     let data = this.state.data;

  // }
  submitToSocket() {
    let commentCheck =
      this.state.selectedId === "trip_id"
        ? this.state.textAreaData
        : this.state.driverAreaData;
    let data = {
      comment: commentCheck,
      user_id: window.sessionStorage.getItem("user_id"),
      identifier_id: this.state.dataToBeDisplayed._id,
      type: this.state.selectedId === "trip_id" ? "tripid" : "dunique_id",
    };
    socket.emit(NOTE_SUBMIT, data);
  }
  textAreaInputData(event) {
    let data = event.target.value;
    this.state.selectedId === "trip_id"
      ? this.setState({ textAreaData: data })
      : this.setState({ driverAreaData: data });
  }

  inputData(event) {
    let data = event.target.value;
    this.state.selectedId === "trip_id"
      ? this.setState({ datatripidInput: data })
      : this.setState({ datadriverInput: data });
  }

  handleSearch() {
    let data;
    this.state.selectedId === "trip_id"
      ? (data = { trip_id: this.state.datatripidInput, type: "tripid" })
      : (data = {
          phonenumber: this.state.datadriverInput,
          type: "phonenumber",
        });
    fetch(config.url + "htmlcrm/snSearchTripIdDriverPhn", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.state.selectedId === "trip_id"
              ? this.setState({
                  showTipdata: true,
                  dataToBeDisplayed: result.data,
                })
              : this.setState({
                  showDriverdata: true,
                  dataToBeDisplayed: result.data,
                });
          } else {
            // Do nothing
            this.state.selectedId === "trip_id"
              ? this.setState({ showTipdata: false, dataToBeDisplayed: [] })
              : this.setState({ showDriverdata: false, dataToBeDisplayed: [] });
          }

          // console.log("herererer");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  changeselecteId(data) {
    data === "trip_id"
      ? this.setState({
          selectedId: data,
          showDriverdata: false,
        })
      : this.setState({
          selectedId: data,
          showTipdata: false,
        });
  }
  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }
  downloadAllUserReport(data) {
    // console.log("here")
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    let dataToBeSent;
    if (data === "firstshift") {
      dataToBeSent = this.state.daydata;
    } else if (data === "secondshift") {
      dataToBeSent = this.state.midData;
    } else if (data === "thirdshift") {
      dataToBeSent = this.state.nightData;
    }
    console.log(dataToBeSent);
    fetch(config.url + "htmlcrm/downloadAllUsersReport", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        users: JSON.stringify(dataToBeSent),
        milliseconds: date.getTime(),
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message === "successfull") {
            this.setState(
              {
                shiftReportArray: result.data,
              },
              () => {
                setTimeout(() => {
                  this.csvLink.link.click();
                }, 2000);
              }
            );
          } else {
            // Do nothing
            this.setState({
              shiftReportArray: [],
            });
          }

          // console.log("herererer");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  handleExecFilteredData = (filteredExecData) => {
    this.setState({ filteredExecData });
  };

  handleExecSearchInput = (searchExecInput) => {
    this.setState({ searchExecInput });
  };
  capitalizeFirstLetter(string) {
    console.log(string);
    return string[0].toUpperCase() + string.slice(1);
  }
  handleAPICall(data, name, lastName, count) {
    console.log(data);
    this.setState(
      {
        displayStatBox: false,
        displayAssigned: true,
        openAssignDiv: true,
        execName: name + " " + lastName,
        count: count,
      },
      () => {
        this.callApitoGetAssignedRides(data);
      }
    );
    // this.callApitoGetAssignedRides(data)
  }
  sortEmployees(data) {
    let finalArr = [];

    if (data.length > 0) {
      let mgrArr = [];
      let asstMgrArr = [];
      let teamLeadArr = [];
      let sdeArr = [];
      let jdeArr = [];
      let tdeArr = [];

      for (let a = 0; a <= data.length - 1; a++) {
        let empObj = data[a];

        if (empObj.roletype === "traineeexecutive") {
          tdeArr.push(empObj);
        } else if (empObj.roletype === "juniorexecutive") {
          jdeArr.push(empObj);
        } else if (empObj.roletype === "seniorexecutive") {
          sdeArr.push(empObj);
        } else if (empObj.roletype === "teamlead") {
          teamLeadArr.push(empObj);
        } else if (empObj.roletype === "assistantmanager") {
          asstMgrArr.push(empObj);
        } else if (empObj.roletype === "manager") {
          mgrArr.push(empObj);
        }
      }

      if (mgrArr.length > 0) {
        for (let a = 0; a <= mgrArr.length - 1; a++) {
          finalArr.push(mgrArr[a]);
        }
      }

      if (asstMgrArr.length > 0) {
        for (let a = 0; a <= asstMgrArr.length - 1; a++) {
          finalArr.push(asstMgrArr[a]);
        }
      }

      if (teamLeadArr.length > 0) {
        for (let a = 0; a <= teamLeadArr.length - 1; a++) {
          finalArr.push(teamLeadArr[a]);
        }
      }

      if (sdeArr.length > 0) {
        for (let a = 0; a <= sdeArr.length - 1; a++) {
          finalArr.push(sdeArr[a]);
        }
      }

      if (jdeArr.length > 0) {
        for (let a = 0; a <= jdeArr.length - 1; a++) {
          finalArr.push(jdeArr[a]);
        }
      }

      if (tdeArr.length > 0) {
        for (let a = 0; a <= tdeArr.length - 1; a++) {
          finalArr.push(tdeArr[a]);
        }
      }

      return finalArr;
    } else {
      return finalArr;
    }
  }
  callApiToGetAllUsers() {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    fetch(config.url + "htmlcrm/getAllShiftUsers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        userid: window.sessionStorage.getItem("user_id"),
        milliseconds: date.getTime(),
        roletype: window.sessionStorage.getItem("roletype"),
        shift: "all",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          let dayEmp = this.sortEmployees(result.day);
          let midEmp = this.sortEmployees(result.mid);
          let nightEmp = this.sortEmployees(result.night);

          // console.log(dayEmp)
          // console.log(midEmp)
          // console.log(nightEmp)
          if (result.message === "successfull") {
            this.setState({
              daydata: dayEmp,
              midData: midEmp,
              nightData: nightEmp,
            });
          } else {
            // Do nothing
          }

          // console.log("herererer");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  callApitoGetAssignedRides(data) {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    fetch(config.url + "htmlcrm/getMyAssignedRides", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        executiveID: data,
        milliseconds: date.getTime(),
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);

          // console.log(dayEmp)
          // console.log(midEmp)
          // console.log(nightEmp)
          if (result.message === "successfull") {
            this.setState({
              data: result.data,
            });
          } else {
            // Do nothing
            this.setState({
              data: [],
            });
          }

          // console.log("herererer");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }
  DisplayAllExces() {
    // console.log(this.state.displayType)
    this.setState({
      displayType: this.state.displayType,
      displayStatBox: true,
      displayTripBox: false,
      openAssignDiv: false,
      displayAssigned: false,
    });
  }
  DisplayAllShiftBox() {
    this.setState({
      displayType: "",
      displayStatBox: false,
      displayTripBox: true,
    });
  }
  handleShiftDisplay(data) {
    console.log(data);
    this.setState({
      displayType: data,
      displayStatBox: true,
      displayTripBox: false,
    });
  }

  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    if (
      window.sessionStorage.getItem("user_id") === undefined ||
      window.sessionStorage.getItem("user_id") === null
    ) {
      window.location.assign("/");
    } else {
      socket.on(NOTE_HISTORY, (data) => {
        console.log(data);
        this.setState({ shiftnotesData: data.data });
      });
      socket.emit(NOTE_HISTORY, { milliseconds: shiftNoteMillis });
      this.callApiToGetAllUsers();

      if (window.sessionStorage.getItem("roletype") === "teamlead") {
        // console.log("---------------------------------------");
        // console.log(window.sessionStorage.getItem("roletype"));
        let data;
        if (window.sessionStorage.getItem("shift") === "day") {
          data = "firstshift";
        } else if (window.sessionStorage.getItem("shift") === "mid") {
          data = "secondshift";
        } else {
          data = "thirdshift";
        }
        this.setState({
          displayType: data,
          displayStatBox: true,
        });
      }
    }
    // console.log(socket);
  }
  render() {
    console.log(this.state);
    const execDataToDisplay = this.state.searchExecInput.length
      ? this.state.filteredExecData
      : this.state.data;

    const columns = [
      {
        Header: "Sl no",
        id: "serialno",
        style: {},
        Cell: (row) => {
          // console.log(row);
          return <div>{row.viewIndex + 1}</div>;
        },
      },
      {
        Header: "Trip ID",
        accessor: "trip_id",
      },
      {
        Header: "Source",
        accessor: "sname",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Destination",
        accessor: "dname",
        style: { whiteSpace: "unset" },
      },
      {
        id: "pickup_time",
        Header: "Date",
        // accessor: 'pickup_time',
        Cell: (row) => {
          let date = moment(new Date(row.original.pickup_time)).format(
            "DD-MM-YY, hh:mm:ss A"
          );

          return <div>{date}</div>;
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Status",
        accessor: "request_status",
        Cell: (row) => {
          if (
            row.original.request_status === "ended" ||
            row.original.request_status === "completed"
          ) {
            return <div>Completed</div>;
          } else if (row.original.request_status === "consumercancelled") {
            return <div>Rider Cancelled</div>;
          } else if (row.original.request_status === "drivercancelled") {
            return <div>Cancelled via panel</div>;
          } else {
            return (
              <div>
                {this.capitalizeFirstLetter(row.original.request_status)}
              </div>
            );
          }
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Reason",
        accessor: "cancel_reason",
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Customer",
        id: "passenger_name",
        Cell: (row) => {
          return (
            <div>
              {row.original.passenger_name +
                " - " +
                row.original.passenger_phone_number}
            </div>
          );
        },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Driver",
        id: "driver",
        Cell: (row) => {
          return (
            <div>
              {row.original.driver.first_name +
                " " +
                row.original.driver.last_name +
                " - " +
                row.original.driver.phonenumber}
            </div>
          );
        },
        style: { whiteSpace: "unset" },
      },
      {
        id: "edit",
        Header: "View",
        Cell: (row) => {
          //  console.log(this);
          // console.log(row.original.request_status)
          return (
            <div className="d-flex flex-column">
              <Button className="" onClick={() => {}} theme="dark">
                Comment
              </Button>
            </div>
          );
        },
      },
    ];
    return (
      <Container fluid className="main-content-container px-md-4">
        <Row noGutters className="page-header py-4 px-md-3 mb-md-4">
          <Col className="col-12">
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="/reports">Reports</a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href="#" onClick={this.goBack}>
                  Dispatch reports
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active />
              Shift reports
            </Breadcrumb>
          </Col>
          <Col className="col-12 mt-1">
            <PageTitle
              title="Manage shift reports"
              className="text-sm-left mb-3 custom-margin p-0"
            />
          </Col>
        </Row>
        {(this.state.displayType === undefined ||
          this.state.displayType === "") && (
          <Row className="px-3">
            <Col className="col-12 col-md-12 col-lg-12">
              <div className="float-right mt-2">
                <Button
                  className=""
                  id="goback-button"
                  onClick={this.goBack}
                  theme="dark"
                >
                  <i className="fas fa-arrow-left mx-1" />
                  <span style={{ fontSize: "0.8rem", fontWeight: "700" }}>
                    BACK
                  </span>
                </Button>
              </div>
            </Col>
          </Row>
        )}
        <Row className="px-3 mb-3">
          <Col className="col-12 col-md-12 col-lg-12">
            <div className="float-right mt-2">
              <Modal show={this.state.open} onHide={this.toggle}>
                <ModalHeader closeButton>Search</ModalHeader>
                <ModalBody>
                  <Row>
                    <Col className="col-12 col-md-6 col-lg-6">
                      <FormRadio
                        inline
                        name="idselect"
                        checked={this.state.selectedId === "trip_id"}
                        onChange={() => {
                          this.changeselecteId("trip_id");
                        }}
                      >
                        Ride ID
                      </FormRadio>
                    </Col>
                    <Col className="col-12 col-md-6 col-lg-6">
                      <FormRadio
                        inline
                        name="idselect"
                        checked={this.state.selectedId === "driver"}
                        onChange={() => {
                          this.changeselecteId("driver");
                        }}
                      >
                        Driver
                      </FormRadio>
                    </Col>
                  </Row>
                  {this.state.selectedId === "trip_id" && (
                    <Row className="mt-3">
                      <Col className="col-12 col-md-6 col-lg-6">
                        <label>Trip ID</label>
                        <FormInput
                          value={this.state.datatripidInput}
                          onChange={this.inputData}
                          className="py-3"
                          placeholder="Enter trip ID here"
                        />
                      </Col>
                    </Row>
                  )}
                  {this.state.selectedId === "driver" && (
                    <Row className="mt-3">
                      <Col className="col-12 col-md-6 col-lg-6">
                        <label>Driver phone number</label>
                        <FormInput
                          value={this.state.datadriverInput}
                          onChange={this.inputData}
                          placeholder="Enter phone number here"
                          className="py-3"
                        />
                      </Col>
                    </Row>
                  )}

                  <Row className="mt-4">
                    {/* <Col className="col-12 col-md-4 col-lg-4" /> */}
                    <Col className="col-12 col-md-12 d-flex justify-content-center">
                      <Button
                        onClick={this.handleSearch}
                        theme="dark"
                        size="lg"
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: "700",
                          minWidth: "200px",
                          borderRadius: "3px",
                        }}
                      >
                        SEARCH
                      </Button>
                    </Col>
                    {/* <Col className="col-12 col-md-4 col-lg-4" /> */}
                  </Row>
                  {this.state.showTipdata === true && (
                    <Row>
                      <Col className="col-12 col-md-12 col-lg-12">
                        <label>Pickup time</label>
                        <p>
                          {moment(
                            new Date(this.state.dataToBeDisplayed.pickup_time)
                          ).format("DD-MM-YY, hh:mm:ss A")}
                        </p>
                      </Col>
                      <Col className="col-12 col-md-6 col-lg-6">
                        <label>From</label>
                        <p>{this.state.dataToBeDisplayed.sname}</p>
                      </Col>
                      <Col className="col-12 col-md-6 col-lg-6">
                        <label>To</label>
                        <p>{this.state.dataToBeDisplayed.dname}</p>
                      </Col>
                      <Col className="col-12 col-md-12 col-lg-12">
                        <FormTextarea
                          value={this.state.textAreaData}
                          placeholder="Please provide a comment"
                          onChange={this.textAreaInputData}
                        />
                      </Col>
                      <Col className="col-12 col-md-12 col-lg-12 mt-4">
                        <Button
                          onClick={this.submitToSocket}
                          className="float-right"
                        >
                          SUBMIT COMMENT
                        </Button>
                      </Col>
                    </Row>
                  )}
                  {this.state.showDriverdata === true && (
                    <Row className="mt-3">
                      <Col className="col-12 col-md-6 col-lg-6">
                        <label>Driver name</label>
                        <p>
                          {this.state.dataToBeDisplayed.first_name +
                            " " +
                            this.state.dataToBeDisplayed.last_name}
                        </p>
                      </Col>
                      <Col className="col-12 col-md-6 col-lg-6">
                        <label>ID</label>
                        <p>{this.state.dataToBeDisplayed._id}</p>
                      </Col>

                      <Col className="col-12 col-md-12 col-lg-12">
                        <FormTextarea
                          value={this.state.driverAreaData}
                          placeholder="Please provide a comment"
                          onChange={this.textAreaInputData}
                        />
                      </Col>
                      <Col className="col-12 col-md-12 col-lg-12 mt-4">
                        <Button
                          className="float-right"
                          onClick={this.submitToSocket}
                        >
                          SUBMIT COMMENT
                        </Button>
                      </Col>
                    </Row>
                  )}
                </ModalBody>
              </Modal>
            </div>
          </Col>
        </Row>
        <Row>
          {this.state.displayAssigned === false && (
            <Col className="col-12 col-md-12 col-lg-12">
              {this.state.displayType === "firstshift" && (
                <div>
                  {/* d-flex flex-row-reverse */}
                  <Row className="mb-4 px-3">
                    {(window.sessionStorage.getItem("roletype") === "manager" ||
                      window.sessionStorage.getItem("roletype") ===
                        "assistantmanager") && (
                      <Col className="col-12 col-md-4 d-flex justify-content-center mb-2 mb-md-0">
                        <Button
                          className=""
                          theme="dark"
                          size="lg"
                          style={{
                            fontSize: "0.8rem",
                            fontWeight: "700",
                            minWidth: "200px",
                            borderRadius: "3px",
                          }}
                          onClick={this.DisplayAllShiftBox}
                        >
                          VIEW ALL SHIFTS
                        </Button>
                      </Col>
                    )}
                    <Col className="col-12 col-md-4 d-flex justify-content-center mb-2 mb-md-0">
                      <Button
                        className=""
                        theme="dark"
                        size="lg"
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: "700",
                          minWidth: "200px",
                          borderRadius: "3px",
                        }}
                        onClick={this.toggle}
                      >
                        SEARCH
                      </Button>
                    </Col>

                    <Col className="col-12 col-md-4 d-flex justify-content-center mb-2 mb-md-0">
                      <Button
                        className=""
                        theme="dark"
                        size="lg"
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: "700",
                          minWidth: "200px",
                          borderRadius: "3px",
                        }}
                        onClick={() => {
                          this.downloadAllUserReport("firstshift");
                        }}
                      >
                        DOWNLOAD REPORT
                      </Button>
                      <CSVLink
                        data={this.state.shiftReportArray}
                        headers={headers_too}
                        filename="data.csv"
                        className="hidden"
                        ref={(r) => (this.csvLink = r)}
                        target="_blank"
                      />
                    </Col>
                  </Row>
                  <Row noGutters className="page-header py-4 px-3 mb-4">
                    <PageTitle
                      title="First shift"
                      subtitle=""
                      className="text-sm-left mb-3"
                    />
                  </Row>
                </div>
              )}
              {this.state.displayType === "secondshift" && (
                <div>
                  <Row className="mb-4 px-3">
                    {(window.sessionStorage.getItem("roletype") === "manager" ||
                      window.sessionStorage.getItem("roletype") ===
                        "assistantmanager") && (
                      <Col className="col-12 col-md-4 d-flex justify-content-center mb-2 mb-md-0">
                        <Button
                          className=""
                          theme="dark"
                          size="lg"
                          style={{
                            fontSize: "0.8rem",
                            fontWeight: "700",
                            minWidth: "200px",
                            borderRadius: "3px",
                          }}
                          onClick={this.DisplayAllShiftBox}
                        >
                          VIEW ALL SHIFTS
                        </Button>
                      </Col>
                    )}
                    <Col className="col-12 col-md-4 d-flex justify-content-center mb-2 mb-md-0">
                      <Button
                        className=""
                        theme="dark"
                        size="lg"
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: "700",
                          minWidth: "200px",
                          borderRadius: "3px",
                        }}
                        onClick={this.toggle}
                      >
                        SEARCH
                      </Button>
                    </Col>

                    <Col className="col-12 col-md-4 d-flex justify-content-center mb-2 mb-md-0">
                      <Button
                        className=""
                        theme="dark"
                        size="lg"
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: "700",
                          minWidth: "200px",
                          borderRadius: "3px",
                        }}
                        onClick={() => {
                          this.downloadAllUserReport("secondshift");
                        }}
                      >
                        DOWNLOAD REPORT
                      </Button>
                      <CSVLink
                        data={this.state.shiftReportArray}
                        headers={headers_too}
                        filename="data.csv"
                        className="hidden"
                        ref={(r) => (this.csvLink = r)}
                        target="_blank"
                      />
                    </Col>
                  </Row>

                  <Row noGutters className="page-header py-4 px-3 mb-4">
                    <PageTitle
                      title="Second shift"
                      subtitle=""
                      className="text-sm-left mb-3"
                    />
                  </Row>
                </div>
              )}
              {this.state.displayType === "thirdshift" && (
                <div>
                  <Row className="mb-4 px-3">
                    {(window.sessionStorage.getItem("roletype") === "manager" ||
                      window.sessionStorage.getItem("roletype") ===
                        "assistantmanager") && (
                      <Col className="col-12 col-md-4 d-flex justify-content-center mb-2 mb-md-0">
                        <Button
                          className=""
                          theme="dark"
                          size="lg"
                          style={{
                            fontSize: "0.8rem",
                            fontWeight: "700",
                            minWidth: "200px",
                            borderRadius: "3px",
                          }}
                          onClick={this.DisplayAllShiftBox}
                        >
                          VIEW ALL SHIFTS
                        </Button>
                      </Col>
                    )}
                    <Col
                      div
                      className="col-12 col-md-4 d-flex justify-content-center mb-2 mb-md-0"
                    >
                      <Button
                        className=""
                        theme="dark"
                        size="lg"
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: "700",
                          minWidth: "200px",
                          borderRadius: "3px",
                        }}
                        onClick={this.toggle}
                      >
                        SEARCH
                      </Button>
                    </Col>

                    <Col className="col-12 col-md-4 d-flex justify-content-center mb-2 mb-md-0">
                      <Button
                        className=""
                        theme="dark"
                        size="lg"
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: "700",
                          minWidth: "200px",
                          borderRadius: "3px",
                        }}
                        onClick={() => {
                          this.downloadAllUserReport("thirdshift");
                        }}
                      >
                        DOWNLOAD REPORT
                      </Button>
                      <CSVLink
                        data={this.state.shiftReportArray}
                        headers={headers_too}
                        filename="data.csv"
                        className="hidden"
                        ref={(r) => (this.csvLink = r)}
                        target="_blank"
                      />
                    </Col>
                  </Row>
                  <Row noGutters className="page-header py-4 px-3 mb-4">
                    <PageTitle
                      title="Third Shift"
                      subtitle=""
                      className="text-sm-left mb-3"
                    />
                  </Row>
                </div>
              )}
            </Col>
          )}
          {this.state.displayAssigned === true && (
            <Col className="col-12 col-md-12 col-lg-12">
              <Row className="d-flex flex-row-reverse mb-4 px-md-3">
                <div className="mx-3">
                  <Button
                    className=""
                    theme="dark"
                    size="lg"
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "700",
                      minWidth: "200px",
                      borderRadius: "3px",
                    }}
                    onClick={this.DisplayAllExces}
                  >
                    VIEW ALL EXECUTIVES
                  </Button>
                </div>
              </Row>

              <Row className="d-flex flex-row-reverse px-3">
                {/* <Col className="col-6 col-md-3 col-lg-3"> */}
                {this.state.data === 0 && (
                  <Button
                    className=""
                    theme="dark"
                    size="lg"
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "700",
                      minWidth: "200px",
                      borderRadius: "3px",
                    }}
                  >
                    DOWNLOAD REPORT
                  </Button>
                )}
                {this.state.data.length > 0 && (
                  <CSVLink
                    data={this.state.data}
                    headers={headers}
                    className="mx-3"
                  >
                    <Button
                      className=""
                      theme="dark"
                      size="lg"
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "700",
                        minWidth: "200px",
                        borderRadius: "3px",
                      }}
                    >
                      DOWNLOAD REPORT
                    </Button>
                  </CSVLink>
                )}
                {/* </Col> */}
              </Row>
              <Row noGutters className="page-header py-4 px-3 mb-4">
                <PageTitle
                  title={`${this.state.execName}(${this.state.count})`}
                  subtitle=""
                  className="text-sm-left mb-3"
                />
              </Row>
            </Col>
          )}
        </Row>
        {this.state.displayTripBox === true && (
          <React.Fragment>
            {(window.sessionStorage.getItem("roletype") === "manager" ||
              window.sessionStorage.getItem("roletype") ===
                "assistantmanager") && (
              <Row className="px-3">
                {this.props.shiftStats.map((stats, idx) => (
                  <Col className="col-md mb-4" key={idx} {...stats.attrs}>
                    <CommonBox
                      id={`small-stats-${idx}`}
                      variation="1"
                      label={stats.label}
                      value={stats.value}
                      stats={stats.stats}
                      display={stats.display}
                      onClickHandle={this.handleShiftDisplay}
                      path="/reports/dispatchreports/shiftreports/"
                    />
                  </Col>
                ))}
              </Row>
            )}
          </React.Fragment>
        )}
        {this.state.displayStatBox === true &&
          this.state.displayType === "firstshift" && (
            <Row>
              {this.state.daydata.map((data, idx) => (
                <Col className="col-12 col-md-4 col-lg-3 mb-4 p-4" key={idx}>
                  <ShiftsView
                    id={`day-stats-${idx}`}
                    variation="1"
                    firstName={data.first_name}
                    lastName={data.last_name}
                    roletype={data.roletype}
                    count={data.count}
                    shift="firstshift"
                    onHandleCall={this.handleAPICall}
                    userid={data.user_id}
                  />
                </Col>
              ))}
            </Row>
          )}
        {this.state.displayStatBox === true &&
          this.state.displayType === "secondshift" && (
            <Row>
              {this.state.midData.map((data, idx) => (
                <Col className="col-12 col-md-4 col-lg-3 mb-4 p-4" key={idx}>
                  <ShiftsView
                    id={`mid-stats-${idx}`}
                    variation="1"
                    firstName={data.first_name}
                    lastName={data.last_name}
                    roletype={data.roletype}
                    count={data.count}
                    shift="secondshift"
                    onHandleCall={this.handleAPICall}
                    userid={data.user_id}
                  />
                </Col>
              ))}
            </Row>
          )}
        {this.state.displayStatBox === true &&
          this.state.displayType === "thirdshift" && (
            <Row>
              {this.state.nightData.map((data, idx) => (
                <Col className="col-12 col-md-4 col-lg-3 mb-4 p-4" key={idx}>
                  <ShiftsView
                    id={`night-stats-${idx}`}
                    variation="1"
                    firstName={data.first_name}
                    lastName={data.last_name}
                    roletype={data.roletype}
                    count={data.count}
                    shift="thirdshift"
                    onHandleCall={this.handleAPICall}
                    userid={data.user_id}
                  />
                </Col>
              ))}
            </Row>
          )}
        {this.state.openAssignDiv === true && (
          <Row className="px-md-3 mb-5">
            <Col className="col-12 col-md-12 col-lg-12">
              <Card>
                <CardBody className="px-2 px-md-3">
                  <Col className="col-12 col-md-3 col-lg-3 ml-auto my-3">
                    <SearchAssignData
                      data={this.state.data}
                      columns={columns}
                      handleSetFilteredData={this.handleExecFilteredData}
                      handleSetSearchInput={this.handleExecSearchInput}
                    />
                  </Col>
                  <ReactTable
                    className="text-center -striped -highlight"
                    data={execDataToDisplay}
                    columns={columns}
                    minRows={execDataToDisplay.length > 0 ? 0 : 4}
                    noDataText="No data to display"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {this.state.displayTripBox === true && (
          <div>
            <Row noGutters className="page-header py-4 px-md-3 mb-1">
              <PageTitle
                title="Shift notes"
                subtitle=""
                className="text-sm-left mb-3"
              />
            </Row>
            <Row className="px-md-3">
              {this.state.shiftnotesData.length === 0 && (
                <Col className="col-12 col-md-12 col-lg-12 d-flex justify-content-center mb-3">
                  <div>No messages</div>
                </Col>
              )}
              {this.state.shiftnotesData.map((stats, idx) => (
                <Col className="col-12 col-md-12 col-lg-12" key={idx}>
                  {/* {console.log(smallStats)} */}
                  {/* {console.log(idx)}
                    {console.log(stats)} */}
                  <ShiftNotesSection
                    id={`shift-reports-${idx}`}
                    variation="1"
                    value={stats}
                  />
                </Col>
              ))}
            </Row>
          </div>
        )}
      </Container>
    );
  }
}
ShiftReports.propTypes = {
  shiftStats: PropTypes.array,
};

ShiftReports.defaultProps = {
  shiftStats: [
    {
      label: "firstshift",
      value: "Day shift",
      id: "firstshift",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "secondshift",
      value: "Mid shift",
      id: "secondshift",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
    },
    {
      label: "thirdshift",
      value: "Night shift",
      id: "thirdshift",
      attrs: { md: "4", sm: "6" },
      stats: false,
      display: false,
    },
  ],
};

export default ShiftReports;
