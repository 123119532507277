import React from "react";
import { FormInput, Col, Row } from "shards-react";

export default class SearchAssignData extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      filteredData: [],
      columnSearchNow: "",
      searchInput: "",
    };
  }

  handleChange = (event) => {
    // console.log(this.props);
    const val = event.target.value;
    // console.log(val)
    this.setState({ searchInput: val }, () => this.globalSearch());
    // console.log(val)
    if (this.props.handleSetSearchInput === undefined) {
      // Do nothing
    } else {
      this.props.handleSetSearchInput(val);
    }
  };

  globalSearch = () => {
    const { searchInput, columnSearchNow } = this.state;
    // console.log(searchInput);
    let filteredData = this.props.data.filter((value) => {
      // console.log(value);
      if (columnSearchNow) {
        return value[columnSearchNow]
          .toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      }
      // console.log(value.phonenumber)
      return (
        value.sname.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.dname.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.trip_id.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.passenger_phone_number
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        value.driver.first_name
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        value.driver.last_name
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        value.driver.phonenumber
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        value.passenger_name
          .toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      );
    });
    if (
      filteredData === null ||
      filteredData === undefined ||
      filteredData === ""
    ) {
      // Do nothing
    } else {
      this.props.handleSetFilteredData(filteredData);
    }
  };

  setColumnSearch = (e) => {
    this.setState({ columnSearch: e.target.value }, () => this.globalSearch());
  };

  render() {
    // const { columnSearch } = this.props;
    // const { columnSearchNow } = this.state;

    return (
      <div>
        <FormInput
          size="large"
          name="searchInput"
          value={this.state.searchInput || ""}
          onChange={this.handleChange}
          placeholder="Search  Executive id, Name, phone number"
        />
      </div>
    );
  }
}
