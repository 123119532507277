import React from "react";
import PropTypes from "prop-types";
import { Container, Col, Row } from "shards-react";

// import PopUp from "../components/common/Popup"
const EmptyLayout = ({ noNavbar, noFooter, children }) => (
  <Container fluid>
    {/* <Col className="mt-md-5"></Col> */}
    <Row>
      <main className="main-content col col pl-0 pr-0">
        {children}
        {/* {<PopUp />} */}
      </main>
    </Row>
  </Container>
);

export default EmptyLayout;
