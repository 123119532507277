import React from "react";
import _ from "lodash";
// import GoogleMapReact from "google-map-react";
import { GoogleMap, LoadScript, HeatmapLayer } from "@react-google-maps/api";
import * as config from "../../constants/constants.json";
import * as mapStyles from "../../constants/styles.json";
import equal from "fast-deep-equal/react";
import Markers from "../common/Markers";

/**
 * Google map cntainer style
 */
const containerStyle = {
  width: "100%",
  height: "100vh",
};

/**
 * Map marker URLs based on the vehicel type
 */
function getImage(data) {
  // console.log(data);
  if (data.vehicle_type === "Hatchback") {
    return "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/bolt.svg";
  } else if (data.vehicle_type === "Sedan") {
    return "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/flash.svg";
  } else if (data.vehicle_type === "SUV") {
    return "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/suv.svg";
  } else {
    return "https://glory-static.blr1.cdn.digitaloceanspaces.com/glory/bolt.svg";
  }
}

function getCenter(region) {
  switch (region) {
    case "Bangalore":
      return {
        lat: 12.9716,
        lng: 77.5946,
      };
    case "Hyderabad":
      return {
        lat: 17.4086182,
        lng: 78.3689922,
      };
    default:
      return {
        lat: 12.9716,
        lng: 77.5946,
      };
    // break;
  }
}

class Map extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    let region = getCenter(props.region);
    this.state = {
      date: new Date().setHours(0, 0, 0, 0),
      region: "",
      mapdata: [],
      center: region,
      markers: [
        {
          name: "Current position",
          position: {
            lat: 12.9716,
            lng: 77.5946,
          },
        },
      ],
      data: [],
      zoom: 11,
    };
    this.nearByDriversApiCall = this.nearByDriversApiCall.bind(this);
    this.getBookingsData = this.getBookingsData.bind(this);
  }

  /**
   * Function to send data back to NearbyDrivers parent
   */

  sendDataBack(data) {
    this.props.onDatahandlers(data);
  }

  // componentWillMount(){
  //   this.setState({

  //   })
  // }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.dateProps !== prevState.date) {
      // console.log(this.props);
      this.setState(
        {
          date: this.props.dateProps,
        },
        () => {
          setTimeout(() => {
            this.getBookingsData();
          }, 2000);
        }
      );
    } else if (prevProps.region !== this.props.region) {
      this.setState(
        {
          region: this.props.region,
        },
        () => {
          let data = getCenter(this.props.region);
          this.setCenter(data);
        }
      );
    }
  }

  setCenter = (data) => {
    this.setState(
      {
        center: data,
      },
      () => {
        this.nearByDriversApiCall();
      }
    );
  };

  /**
   * Life cycle method to trigger when page loads
   */
  componentDidMount() {
    this.nearByDriversApiCall();
    this.interval = window.setInterval(this.nearByDriversApiCall, 900000);
    this.setState({
      date: this.props.dateProps,
      region: this.props.region,
    });
    setTimeout(() => {
      this.getBookingsData();
    }, 2000);
  }

  /**
   * Function to retrieve nearby drivers
   */

  nearByDriversApiCall() {
    let data = {
      lat: this.state.center.lat,
      lng: this.state.center.lng,
      region: this.props.region,
    };
    console.log(data);
    fetch(config.url + "api/nearByDrivers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.message === "successfull") {
            /**
             * Clear duplicate IDs for the response array
             */
            let drivData =
              result.drivers.length > 0 ? _.uniqBy(result.drivers, "key") : [];

            /**
             * Sort data according to names
             */
            drivData = _.orderBy(drivData, ["driver_name"], ["asc"]);

            /**
             * Store the final data and send it to the parent (NearbyDriver)
             */
            this.setState(
              {
                data: drivData,
              },
              () => {
                this.sendDataBack(drivData);
                // this.handleApiLoaded();
              }
            );
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  /**
   * Function to retrieve source concentration
   */

  getBookingsData() {
    let data = {
      milliseconds: this.state.date.getTime(),
      request_type: "requested",
      vendor: "mmt",
      region: this.state.region,
    };
    // console.log(data);
    // console.log("***********here**********");

    fetch(config.url + "htmlcrm/taxiScheduleRideListHeatMap", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          // let driversList = result.drivers;

          // for (let i = 0; i <= result.drivers.length - 1; i++) {}
          if (result.message === "successfull") {
            this.setState({
              mapdata: result.taxiScheduleRideList,
            });
          } else {
            this.setState({
              mapdata: [],
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }

  render() {
    let { data, mapdata } = this.state;
    const datas = mapdata.map(
      (place) =>
        new window.google.maps.LatLng(place.slatlon[1], place.slatlon[0])
    );
    // console.log(datas);
    // console.log();
    return (
      <div height="100%">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={this.state.center}
          zoom={this.state.zoom}
          options={{ styles: mapStyles.mapStyles }}
        >
          <HeatmapLayer
            data={datas}
            options={{
              radius: 30,
              opacity: 1,
              gradient: [
                "rgba(20, 255, 0, 0)",
                "rgba(71, 253, 0, 1)",
                "rgba(115, 252, 0, 1)",
                "rgba(154, 251, 0, 1)",
                "rgba(201, 249, 0, 1)",
                "rgba(255, 247, 0, 1)",
                "rgba(255, 218, 0, 1)",
                "rgba(255, 188, 0, 1)",
                "rgba(255, 149, 0, 1)",
                "rgba(255, 106, 0, 1)",
                "rgba(255, 68, 0, 1)",
                "rgba(255, 43, 0, 1)",
                "rgba(255, 25, 0, 1)",
                "rgba(255, 0, 0, 1)",
              ],
            }}
          ></HeatmapLayer>
          <>
            {data.map((data, index) => (
              <Markers
                key={data.key}
                data={data}
                vehicle_type={data.vehicle_type}
                location={{ lat: data.latitude, lng: data.longitude }}
              ></Markers>
            ))}
          </>
        </GoogleMap>
      </div>
    );
  }
}

export default Map;
