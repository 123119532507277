import React from "react";
import { FormInput, Col, Row } from "shards-react";
import moment from "moment";

export default class Search extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      filteredData: [],
      columnSearchNow: "",
      searchInput: "",
    };
  }

  handleChange = (event) => {
    // const val = event.target.value;
    // this.setState({ searchInput: val }, () => this.globalSearch());
    // this.props.handleSetSearchInput(val);
    //  console.log(this.props);
    const val = event.target.value;
    // console.log(val)
    this.setState({ searchInput: val }, () => this.globalSearch());
    // console.log(val)
    if (this.props.handleSetSearchInput === undefined) {
      // Do nothing
    } else {
      this.props.handleSetSearchInput(val);
    }
  };

  globalSearch = () => {
    const { searchInput, columnSearchNow } = this.state;
    let filteredData = this.props.data.filter((value) => {
      if (columnSearchNow) {
        return value[columnSearchNow]
          .toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      }
      if (typeof value.pickuptime === "string") {
        // console.log(value);
        return (
          value.trip_id.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.sname.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.sname.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.dname.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.consumername
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          value.pickuptime.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.phonenumber.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.vehicle_type
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          value.drivername.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.driverphonenumber
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          (value && value.booking_source
            ? value.booking_source
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : value.request_type
                .toLowerCase()
                .includes(searchInput.toLowerCase()))
        );
      } else {
        let pickuptime = moment(new Date(value.pickuptime)).format(
          "DD-MM-YY [at] hh:mm:ss A"
        );
        return (
          value.trip_id.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.sname.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.sname.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.dname.toLowerCase().includes(searchInput.toLowerCase()) ||
          pickuptime.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.consumername
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          value.phonenumber.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.vehicle_type
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          value.drivername.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.driverphonenumber
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          (value && value.booking_source
            ? value.booking_source
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : value.request_type
                .toLowerCase()
                .includes(searchInput.toLowerCase()))
        );
      }
    });

    this.props.handleSetFilteredData(filteredData);
  };

  setColumnSearch = (e) => {
    this.setState({ columnSearch: e.target.value }, () => this.globalSearch());
  };

  render() {
    const { columnSearch } = this.props;
    const { columnSearchNow } = this.state;

    return (
      <Col className="col-12 col-md-12 col-lg-12 px-0">
        <FormInput
          size="large"
          name="searchInput"
          value={this.state.searchInput || ""}
          onChange={this.handleChange}
          placeholder="Search"
          class
        />
      </Col>
    );
  }
}
