import React, { useEffect } from "react";
import { Row, Col, Container, Card, Modal } from "react-bootstrap";
import InputLabel from "@material-ui/core/InputLabel";
import { Breadcrumb, BreadcrumbItem, Button as Buttons } from "shards-react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import _ from "lodash";
import Radio from "@material-ui/core/Radio";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { FormGroup } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import PageTitle from "./../components/common/PageTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { green, purple, grey, red } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Remove";
import AddButton from "@material-ui/icons/Add";
// import DeleteButton from "@material-ui/icons/Delete"
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Chip from "@material-ui/core/Chip";
import ChipInput from "material-ui-chip-input";
import ReactSnackBar from "react-js-snackbar";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
// import Paper from "@material-ui/core/Paper";
// import MenuItem from "@material-ui/core/MenuItem";

import * as config from "../constants/constants.json";
import * as stringsData from "../constants/stringsConstant.json";
import {
  ReactAutosuggestExample,
  ReactAutosuggestRemoteExample,
} from "../components/common/AutoSuggest";

function capitalizeFirstLetter(string) {
  // console.log(string);
  return string[0].toUpperCase() + string.slice(1);
}

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

// const suggestions = [
//   { name: "Monday" },
//   { name: "Tuesday" },
//   { name: "Wednesday" },
//   { name: "Thursday" },
//   { name: "Friday" },
//   { name: "Saturday" },
//   { name: "Sunday" },
// ];

const weekends = ["Friday", "Saturday", "Sunday"];
const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday"];

const createData = (
  action,
  name,
  range_1,
  range_2,
  range_3,
  range_4,
  range_5,
  range_6,
  range_7,
  per_km,
  isEditMode,
  parent_id
) => ({
  id: name.replace(/ /g, "_"),
  action,
  name,
  range_1,
  range_2,
  range_3,
  range_4,
  range_5,
  range_6,
  range_7,
  per_km,
  isEditMode: isEditMode,
  parent_id: parent_id,
  isClicked: false,
  parent: true,
});

const createDataNew = (
  action,
  name,
  range_1,
  range_2,
  range_3,
  range_4,
  range_5,
  range_6,
  range_7,
  per_km,
  isEditMode,
  parent_id
) => ({
  id: name.replace(/ /g, "_"),
  action,
  name,
  range_1,
  range_2,
  range_3,
  range_4,
  range_5,
  range_6,
  range_7,
  per_km,
  isEditMode: isEditMode,
  parent_id: parent_id,
  isClicked: false,
  parent: false,
});

const SaveButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: grey[900],
    "&:hover": {
      backgroundColor: grey[800],
    },
  },
}))(Button);

const getValue = (data) => {
  if (typeof data === "string") {
    // console.log(data);
    let timeData = data;
    let splitTimeData = timeData.split("to");
    let minimim_hour = moment(splitTimeData[0], "hh A").format("HH:mm");
    let maximum_hour = moment(splitTimeData[1], "hh A").format("HH:mm");
    return [minimim_hour, maximum_hour];
  } else {
    return data;
  }

  // return ["10:00", "12:00"];
};

const CustomTableCell = ({
  row,
  name,
  onChange,
  removeRow,
  clickNext,
  clickDelete,
}) => {
  const classes = useStyles();
  const { isEditMode } = row;
  const { isClicked } = row;
  // console.log(row);
  // console.log(row[name]);
  return (
    <TableCell
      align="left"
      className={name === "sl_no" ? classes.slCell : classes.tableCell}
    >
      {isEditMode && name !== "sl_no" ? (
        <React.Fragment>
          {name !== "name" ? (
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={row[name]}
              name={name}
              onChange={(e) => onChange(e, row, "text", name)}
              className={classes.input}
              required
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
            />
          ) : (
            <TimeRangePicker
              className="form-control py-2"
              clearIcon={null}
              clockIcon={null}
              style={{
                border: "none",
                minWidth: "150px !important",
                height: "calc(3.16rem + 2px)",
                padding: "18.5px 14px !important",
              }}
              value={getValue(row[name])}
              name={name}
              format="h a"
              disabled
              rangeDivider="to"
              // onChange={(e) => onChange(e, row, "time", name)}
              required
            />
          )}
        </React.Fragment>
      ) : name === "sl_no" ? (
        <React.Fragment>
          {isClicked !== true && row.parent_id !== row.id && (
            <div>
              {row.parent ? (
                <IconButton
                  aria-label="add"
                  disabled
                  className={classes.margin}
                  onClick={(e) => clickNext(row)}
                  // disabled={!isEditMode}
                >
                  <AddButton />
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  aria-label="delete"
                  className={classes.margin}
                  onClick={(e) => clickDelete(row)}
                  // disabled={!isEditMode}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          )}
        </React.Fragment>
      ) : (
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={row[name]}
          disabled
          name={name}
          className={classes.input}
          required
          type="text"
          style={{ minWidth: "150px" }}
        />
      )}
    </TableCell>
    /* row[name] */
  );
};

const theme = createMuiTheme({
  props: {
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
});

const BlackSwitch = withStyles({
  switchBase: {
    color: grey[200],
    "&$checked": {
      color: grey[900],
    },
    "&$checked + $track": {
      backgroundColor: grey[900],
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
  chipInputRoot: {},
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: "100%",
  },
  tabs: {
    "& .Mui-selected": {
      fontSize: "1.2em",
      fontWeight: 700,
    },
  },
  tabsRoot: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(17),
    textAlign: "left",
    "&:focus": {
      opacity: 1,
    },
    expandedPanel: {
      backgroundColor: theme.palette.primary.main,
    },
  },

  tableRoot: {
    marginTop: theme.spacing(3),
    overflowX: "auto",
    "&-webkit-scrollbar": {
      display: "none",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  headingTwo: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "66.66%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {
    overflowX: "auto",
    border: 0,
    // backgroundColor: "#FAFAFA",
  },
  tabHeader: {
    borderBottom: "none",
    fontWeight: 600,
  },
  selectTableCell: {},
  tableCell: {
    minWidth: 135,

    borderBottom: "none",
  },
  slCell: {
    minWidth: 80,

    borderBottom: "none",
  },
  input: {
    maxWidth: 150,
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  formLabel: {
    fontWeight: 600,

    color: "#000",
    "&.Mui-focused": {
      color: "#000",
    },
  },
  checkbox: {
    root: {
      "&$checked": {
        color: "black",
      },
    },
  },
  typo: {
    fontSize: "0.8rem",
  },
  selectRoot: {
    "&:before": {
      borderColor: "black",
    },
    "&:focus": {
      borderColor: "black",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderColor: "black",
    },
  },
}));

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",

    "&$selected": {
      color: "black",
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function FareConfig(props) {
  const clickDelete = (data) => {
    // console.log(data);
    let timeData = data.parent_id;
    // console.log(timeData);
    let splitTimeData = timeData.split("to");
    let max_hour = moment(splitTimeData[1], "hh A").format("HH");
    // console.log(max_hour);
    let date;
    let date_pick;
    let id;
    let dataToUpdate = [...rows];
    // console.log(max_hour);
    if (max_hour === "06" || max_hour === "03") {
      date = "12 AM to 06 AM";
      id = timeData;
      date_pick = "00:00,06:00";
    } else if (max_hour === "11" || max_hour === "08") {
      date = "06 AM to 11 AM";
      id = timeData;
      date_pick = "06:00,11:00";
    } else if (max_hour === "16" || max_hour === "13") {
      date = "11 AM to 04 PM";
      id = timeData;
      date_pick = "11:00,16:00";
    } else if (max_hour === "21" || max_hour === "18") {
      date = "04 PM to 09 PM";
      id = timeData;
      date_pick = "16:00,21:00";
    } else if (max_hour === "00" || max_hour === "0" || max_hour === "22") {
      date = "09 PM to 12 AM";
      id = timeData;
      date_pick = "21:00,00:00";
    }
    // console.log(id);
    // console.log(date);
    let indexOfRow = dataToUpdate.findIndex((x) => x.id === id);
    dataToUpdate[indexOfRow] = {
      ...dataToUpdate[indexOfRow],
      name: date,
    };
    // console.log(dataToUpdate);
    // onClickedData(data, dataToUpdate);
    // let timeData = data.name;
    // let splictTimeData = timeData.split("to");
    // let minimim_hour = moment(splitTimeData[0], "hh A").format("HH");
    // let dataToUpdate = [...rows];
    let indexOfRows = dataToUpdate.findIndex((x) => x.id === data.id);
    dataToUpdate.splice(indexOfRows, 1);
    // console.log(dataToUpdate);
    setRows(dataToUpdate);
    onClickedData(dataToUpdate[indexOfRow], dataToUpdate);

    // onChange(date_pick, dataToUpdate[indexOfRow], "time", "name");
  };

  const clickNext = (data) => {
    // console.log(data);
    let timeData = data.name;
    let splitTimeData = timeData.split("to");
    let minimim_hour = moment(splitTimeData[0], "hh A").format("HH") * 1;
    let max_hour = moment(splitTimeData[1], "hh A").format("HH") * 1;
    // console.log(minimim_hour);
    // console.log(max_hour);
    // console.log(minimim_hour + max_hour);
    let new_min_hour = (minimim_hour + max_hour) / 2;
    // console.log(new_min_hour);
    let new_time_range =
      moment(new_min_hour, "HH").format("hh A") + " to " + splitTimeData[1];
    let dataToUpdate = [...rows];
    let indexOfRow = dataToUpdate.findIndex((x) => x.id === data.id);
    let old_time_range =
      splitTimeData[0] + " to " + moment(new_min_hour, "HH").format("hh A");
    // console.log(old_time_range);
    dataToUpdate[indexOfRow] = {
      ...dataToUpdate[indexOfRow],
      name: old_time_range,
      parent_id: old_time_range.replace(/ /g, "_"),
    };
    let parent_id = old_time_range.replace(/ /g, "_");
    dataToUpdate.splice(
      indexOfRow + 1,
      0,
      createDataNew(6, new_time_range, 0, 0, 0, 0, 0, 0, 0, 0, true, parent_id)
    );
    setRows(dataToUpdate);
    onClickedData(data, dataToUpdate);
    // let maximum_hour = moment(splitTimeData[1], "hh A").format("HH");
    // let new_min_hour = (minimim_hour === "00" ? 12 : minimim_hour) / 4;
    // // console.log(new_min_hour);
    // let new_time_range =
    //   moment(new_min_hour, "HH").format("hh A") + " to " + splitTimeData[1];
    // // console.log(new_time_range);
    // let dataToUpdate = [...rows]; // Get current state of table
    // // console.log(dataToUpdate);
    // // // Find the index of the clicked row
    // let indexOfRow = dataToUpdate.findIndex((x) => x.id === data.id);
    // let old_time_range =
    //   splitTimeData[0] + " to " + moment(new_min_hour, "HH").format("hh A");
    // dataToUpdate[indexOfRow] = {
    //   ...dataToUpdate[indexOfRow],
    //   name: old_time_range,
    // };
    // console.log(dataToUpdate);
    // dataToUpdate.splice(
    //   indexOfRow + 1,
    //   0,
    //   createDataNew(6, new_time_range, 0, 0, 0, 0, 0, 0, 0, 0)
    // );
    // setRows(dataToUpdate);
    // console.log(indexOfRow);
  };

  const handleChangeInnerTab = (event, newValue) => {
    setValueDayTab(newValue);
  };

  /**
   *Go back to configuration page
   *
   */
  const goBackAccounts = () => {
    props.history.push("/configuration");
  };

  /**
   * Function to handle vendor change
   *import InputLabel from '@material-ui/core/InputLabel';
   */
  const handleChangeVendorTab = (event) => {
    setVendorTab(event.target.value);
  };

  const RadioButton = withStyles({
    root: {
      color: "black",
      "&$checked": {
        color: "black",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const onChange = (e, row, data, names) => {
    // console.log(e + "---" + row + "-----" + data + "-----" + names);
    // console.log(row);
    if (data === "time") {
      if (!previous[row.id]) {
        setPrevious((state) => ({ ...state, [row.id]: row }));
      }
      const value = e;
      const name = names;
      const { id } = row;
      const newRows = rows.map((row) => {
        if (row.id === id) {
          return { ...row, [name]: value };
        }
        return row;
      });
      setRows(newRows);
    } else {
      // console.log(row);
      // console.log(e.target);
      if (!previous[row.id]) {
        setPrevious((state) => ({ ...state, [row.id]: row }));
      }
      const value = e.target.value;
      const name = e.target.name;
      const { id } = row;
      const newRows = rows.map((row) => {
        if (row.id === id) {
          return { ...row, [name]: value };
        }
        return row;
      });
      setRows(newRows);
    }
  };

  const toggleSave = () => {
    setEditChange(!editChange);
    onToggleEditMode();
  };

  const toggleSaveCancel = () => {
    setEditChange(!editChange);
    callFareRetrieveAPI();
    onToggleEditMode();
  };

  const onToggleEditMode = () => {
    setRows((state) => {
      return rows.map((row) => {
        return { ...row, isEditMode: !row.isEditMode };
      });
    });
  };

  const onClickedData = (data, dataToUpdate) => {
    let datas = dataToUpdate;
    // console.log(data);
    // console.log(datas);
    let indexOfRow = datas.findIndex((x) => x.id === data.id);
    datas[indexOfRow] = {
      ...datas[indexOfRow],
      isClicked: !data.isClicked,
    };
    setRows(datas);
  };

  const getDayOfTheWeek = (day) => {
    if (day === 0) {
      return "monday";
    } else if (day === 1) {
      return "tuesday";
    } else if (day === 2) {
      return "wednesday";
    } else if (day === 3) {
      return "thursday";
    } else if (day === 4) {
      return "friday";
    } else if (day === 5) {
      return "saturday";
    } else if (day === 6) {
      return "sunday";
    }
  };

  const callFareRetrieveAPI = () => {
    let dataToSend = {
      ride_type: rideType,
      vehicle_type: vehicleType,
      vendor_type: "mmt",
      travel_type: valuePath,
      day_of_week: getDayOfTheWeek(valueDayTab),
    };
    // console.log(dataToSend);
    axios
      .post(config.url + "htmlcrm/v2/getvendorconfiguredfare", dataToSend)
      .then((response) => {
        // console.log(response.data);
        if (response.data.message === stringsData.onsuccess) {
          let data = response.data.result.config;
          setCheckData(response.data.result);
          setFullData(response.data.result);
          let count = 0;
          let arrayData = [];
          let sl_no = 1;
          data.forEach((indData) => {
            if (
              (vehicleType === "sedan" || vehicleType === "hatchback") &&
              valuePath === "to_airport"
            ) {
              let range1 =
                indData.kms[0] !== undefined ? indData.kms[0].base_fare : 0;
              let range2 =
                indData.kms[1] !== undefined ? indData.kms[1].base_fare : 0;
              let range3 =
                indData.kms[2] !== undefined ? indData.kms[2].base_fare : 0;
              let range4 =
                indData.kms[3] !== undefined ? indData.kms[3].base_fare : 0;
              let range5 =
                indData.kms[4] !== undefined ? indData.kms[4].base_fare : 0;
              let range6 =
                indData.kms[5] !== undefined ? indData.kms[5].base_fare : 0;
              let range7 =
                indData.kms[6] !== undefined ? indData.kms[6].base_fare : 0;
              let extra_km = indData.extra_km;
              let minimum_time = moment(indData.minimum_hour, "hh").format(
                "hh A"
              );
              let maximum_time = moment(indData.maximum_hour, "hh").format(
                "hh A"
              );
              let parent = indData.parent;
              let parent_id;
              if (parent === true || parent === undefined) {
                parent_id = minimum_time + " to " + maximum_time;
                arrayData.push(
                  createData(
                    sl_no,
                    minimum_time + " to " + maximum_time,
                    range1,
                    range2,
                    range3,
                    range4,
                    range5,
                    range6,
                    range7,
                    extra_km,
                    false,
                    parent_id
                  )
                );
              } else {
                parent_id = indData.parent_id;
                arrayData.push(
                  createDataNew(
                    sl_no,
                    minimum_time + " to " + maximum_time,
                    range1,
                    range2,
                    range3,
                    range4,
                    range5,
                    range6,
                    range7,
                    extra_km,
                    false,
                    parent_id
                  )
                );
              }
              // console.log(minimum_time);
              // console.log(maximum_time);
            } else if (
              (vehicleType === "sedan" || vehicleType === "hatchback") &&
              valuePath === "from_airport"
            ) {
              let range1 =
                indData.kms[0] !== undefined ? indData.kms[0].base_fare : 0;
              let range2 =
                indData.kms[1] !== undefined ? indData.kms[1].base_fare : 0;
              let range3 =
                indData.kms[2] !== undefined ? indData.kms[2].base_fare : 0;
              let range4 =
                indData.kms[3] !== undefined ? indData.kms[3].base_fare : 0;
              let range5 =
                indData.kms[4] !== undefined ? indData.kms[4].base_fare : 0;
              let range6 =
                indData.kms[5] !== undefined ? indData.kms[5].base_fare : 0;
              let range7 =
                indData.kms[6] !== undefined ? indData.kms[6].base_fare : 0;
              let extra_km = indData.extra_km;
              let minimum_time = moment(indData.minimum_hour, "hh").format(
                "hh A"
              );
              let maximum_time = moment(indData.maximum_hour, "hh").format(
                "hh A"
              );
              // console.log(minimum_time);
              // console.log(maximum_time);
              let parent = indData.parent;
              let parent_id;
              if (parent === true || parent === undefined) {
                parent_id = minimum_time + " to " + maximum_time;
                arrayData.push(
                  createData(
                    sl_no,
                    minimum_time + " to " + maximum_time,
                    range1,
                    range2,
                    range3,
                    range4,
                    range5,
                    range6,
                    range7,
                    extra_km,
                    false,
                    parent_id
                  )
                );
              } else {
                let parent_id = indData.parent_id;
                arrayData.push(
                  createDataNew(
                    sl_no,
                    minimum_time + " to " + maximum_time,
                    range1,
                    range2,
                    range3,
                    range4,
                    range5,
                    range6,
                    range7,
                    extra_km,
                    false,
                    parent_id
                  )
                );
              }
            } else {
              let range1 = 0;
              let range2 = 0;
              let range3 = 0;
              let range4 = 0;
              let range5 = 0;
              let range6 = indData.kms[0].base_fare;
              let range7 = 0;
              let extra_km = indData.extra_km;
              let minimum_time = moment(indData.minimum_hour, "hh").format(
                "hh A"
              );
              let maximum_time = moment(indData.maximum_hour, "hh").format(
                "hh A"
              );
              arrayData.push(
                createData(
                  sl_no,
                  minimum_time + " to " + maximum_time,
                  range1,
                  range2,
                  range3,
                  range4,
                  range5,
                  range6,
                  range7,
                  extra_km,
                  false
                )
              );
            }
            sl_no++;
            count++;
            if (count === data.length) {
              // console.log(arrayData);
              setRows(arrayData);
            }
          });
        } else {
          if (vehicleType === "suv") {
            setFullData([]);
            setCheckData([]);
            let sl_no = 1;
            setRows([
              createData(sl_no, "12 AM to 6 AM", 0, 0, 0, 0, 0, 0, 0, 0, false),
            ]);
          } else {
            setFullData([]);
            setCheckData([]);
            let defaultData = [
              createData(1, "12 AM to 6 AM", 0, 0, 0, 0, 0, 0, 0, 0, false),
              createData(2, "6 AM to 11 AM", 0, 0, 0, 0, 0, 0, 0, 0, false),
              createData(3, "11 AM to 4 PM", 0, 0, 0, 0, 0, 0, 0, 0, false),
              createData(4, "4 PM to 9 PM", 0, 0, 0, 0, 0, 0, 0, 0, false),
              createData(5, "9 PM to 12 AM", 0, 0, 0, 0, 0, 0, 0, 0, false),
            ];
            setRows(defaultData);
          }
        }
      })
      .catch();
  };

  const handleSwitchChange = (event) => {
    if (event.target.checked === true) {
      // setState(true);
      setOpenDutyModal(true);
      // updateStatus(true);
    } else {
      setDeactiveDuty(true);
      // handleDeactivateOpenSwitch();
      // setState(false);

      // updateStatus(false);
    }
  };

  const saveData = (e) => {
    if (e) {
      e.preventDefault();
    }
    setOpenSaveModal(true);
  };

  const handleCloseSaveModal = () => {
    setOpenSaveModal(false);
    setYourChips([]);
    setValueUpdate("current_day");
  };

  const handleCloseDutyModal = () => {
    setOpenDutyModal(false);
    setYourChips([]);
    setValueUpdate("current_day");
  };

  const handleCloseDeactiveModal = () => {
    setDeactiveDuty(false);
    setYourChips([]);
    setValueUpdate("current_day");
  };

  const handleAddChip = (data) => {
    setYourChips(data);
    // console.log(yourChips);
    // let dataToInput = capitalizeFirstLetter(data);
    // let checkData = _.includes(days, data);
    // let dataToInput;
    // if (checkData) {
    //   dataToInput = capitalizeFirstLetter(data);
    //   setYourChips((state) => [...state, dataToInput]);
    // } else if (checkData === false && data === "all") {
    //   // dataToInput = capitalizeFirstLetter(data);
    //   setYourChips(days);
    // } else if (checkData === false && data === "weekdays") {
    //   setYourChips(weekdays);
    // } else if (checkData === false && data === "weekends") {
    //   setYourChips(weekends);
    // } else if (checkData === false && data === "delete") {
    //   setYourChips([]);
    // } else {
    //   // checkForDay(capitalizeFirstLetter(data));
    // }
  };

  const checkForDay = (data) => {
    switch (data) {
      case "Mon":
        setYourChips((state) => [...state, "Monday"]);
        break;
      case "Tue":
        setYourChips((state) => [...state, "Tuesday"]);
        break;
      case "Wed":
        setYourChips((state) => [...state, "Wednesday"]);
        break;
      case "Thu":
        setYourChips((state) => [...state, "Thursday"]);
        break;
      case "Fri":
        setYourChips((state) => [...state, "Friday"]);
        break;
      case "Sat":
        setYourChips((state) => [...state, "Saturday"]);
        break;
      case "Sun":
        setYourChips((state) => [...state, "Sunday"]);
        break;

      default:
        break;
    }
  };

  const updateDuty = (data) => {
    if (valueUpdate === "current_day") {
      // console.log("here");
      //  updateIndiviualFare();
      updateIndividualDuty(data);
    } else {
      updateMoreDuties(data);
      // console.log("many");
      //  updateMoreFares();
    }
  };

  const updateFare = (data) => {
    if (valueUpdate === "current_day") {
      // console.log("here");
      updateIndiviualFare(data);
    } else {
      // console.log("many");
      updateMoreFares(data);
    }
  };

  const activateFare = (data) => {
    // console.log(data);
    // console.log("hererer");
    setState(true);
    // console.log(checkedA);
    setTimeout(() => {
      updateDuty(data);
      //  setOpenSwitch(false);
      //  updateStatus(data);
    }, 100);

    // setState({ ...state, ["checkedA"]: true });
  };

  const deactivateFare = (data) => {
    // console.log("not here");
    setState(false);
    setTimeout(() => {
      updateDuty(data);
      //  updateStatus(data);
      //  setOpenDeactivateSwitch(false);
    }, 100);

    // setState({ ...state, ["checkedA"]: false });
  };

  const updateIndividualDuty = (data) => {
    let dataSend = {
      ride_type: "airport",
      user_id: sessionStorage.getItem("user_id"),
      vehicle_type: vehicleType,
      vendor_type: "mmt",
      travel_type: valuePath,
      fare_status: data === true ? "Active" : "Inactive",
      request_type: "individual",
      day_of_week: getDayOfTheWeek(valueDayTab),
    };
    // console.log(dataSend);
    axios
      .post(config.url + "htmlcrm/toggleFareStatus", dataSend)
      .then((response) => {
        if (response.data.message === "successfull") {
          retrieveFareStatus();
          setMessage(
            dataSend.fare_status === "Active"
              ? "Booking flow activated"
              : "Booking flow deactivated"
          );
          setSuccess(true);
          show();
          dataSend.fare_status === "Active"
            ? handleCloseDutyModal()
            : handleCloseDeactiveModal();
        } else {
          setMessage("Duty status failed to update");
          setSuccess(false);
          show();
        }
      })
      .catch();
  };

  const updateMoreDuties = (data) => {
    let daysToCheck = yourChips;
    if (daysToCheck && daysToCheck.length > 0) {
      let dataSend;
      if (daysToCheck.length > 6) {
        dataSend = {
          ride_type: "airport",
          user_id: sessionStorage.getItem("user_id"),
          vehicle_type: vehicleType,
          vendor_type: "mmt",
          travel_type: valuePath,
          fare_status: data === true ? "Active" : "Inactive",
          request_type: "all",
        };
        axios
          .post(config.url + "htmlcrm/toggleFareStatus", dataSend)
          .then((response) => {
            if (response.data.message === "successfull") {
              retrieveFareStatus();
              setMessage(
                dataSend.fare_status === "Active"
                  ? "Booking flow activated for all days"
                  : "Booking flow deactivated for all days"
              );
              setSuccess(true);
              show();
              dataSend.fare_status === "Active"
                ? handleCloseDutyModal()
                : handleCloseDeactiveModal();
            } else {
              setMessage("Duty status failed to update");
              setSuccess(false);
              show();
            }
          })
          .catch();
      } else {
        let success = 0;
        for (let i = 0; i < daysToCheck.length; i++) {
          success++;
          dataSend = {
            ride_type: "airport",
            user_id: sessionStorage.getItem("user_id"),
            vehicle_type: vehicleType,
            vendor_type: "mmt",
            travel_type: valuePath,
            fare_status: data === true ? "Active" : "Inactive",
            request_type: "individual",
            day_of_week: daysToCheck[i].toLowerCase(),
          };
          // console.log(dataSend);
          axios
            .post(config.url + "htmlcrm/toggleFareStatus", dataSend)
            .then((response) => {
              console.log(response.data);
              if (response.data.message === "successfull") {
                // retrieveFareStatus();
                // success = success + 1;
                console.log(success);
              } else {
              }
            })
            .catch();
        }
        console.log(success);
        if (success === daysToCheck.length) {
          retrieveFareStatus();
          setMessage("Booking flow updated");
          setSuccess(true);
          show();
          daysToCheck[0].fare_status === "Active"
            ? handleCloseDutyModal()
            : handleCloseDeactiveModal();
        } else {
          setMessage("Duty status failed to update");
          setSuccess(false);
          show();
        }
      }
    } else {
      setMessage("Days cannot be empty");
      setSuccess(false);
      show();
    }
    // console.log(daysToCheck.length);
  };

  const updateIndiviualFare = () => {
    let dataToCheck = rows;
    let configs = [];
    let count = 0;
    let data;
    let minimim_hour;
    let maximum_hour;
    dataToCheck.forEach((dataToFormat) => {
      let timeData = dataToFormat.name;
      if (typeof timeData === "string") {
        let splitTimeData = timeData.split("to");
        minimim_hour = moment(splitTimeData[0], "hh A").format("HH");
        maximum_hour = moment(splitTimeData[1], "hh A").format("HH");
      } else {
        minimim_hour = moment(timeData[0], "hh:mm").format("HH");
        maximum_hour = moment(timeData[1], "hh:mm").format("HH");
      }

      if (
        (vehicleType === "sedan" || vehicleType === "hatchback") &&
        valuePath === "to_airport"
      ) {
        data = {
          parent: dataToFormat.parent,
          parent_id: dataToFormat.parent_id
            ? dataToFormat.parent_id
            : dataToFormat.id,
          minimum_hour: minimim_hour * 1,
          maximum_hour: maximum_hour * 1,
          extra_km: dataToFormat.per_km * 1,
          kms: [
            {
              min_km: 0,
              max_km: 20,
              base_fare: dataToFormat.range_1 * 1,
            },
            {
              min_km: 20,
              max_km: 25,
              base_fare: dataToFormat.range_2 * 1,
            },
            {
              min_km: 25,
              max_km: 30,
              base_fare: dataToFormat.range_3 * 1,
            },
            {
              min_km: 30,
              max_km: 35,
              base_fare: dataToFormat.range_4 * 1,
            },
            {
              min_km: 35,
              max_km: 40,
              base_fare: dataToFormat.range_5 * 1,
            },
            {
              min_km: 40,
              max_km: 45,
              base_fare: dataToFormat.range_6 * 1,
            },
            {
              min_km: 45,
              max_km: 50,
              base_fare: dataToFormat.range_7 * 1,
            },
          ],
        };
      } else if (
        (vehicleType === "sedan" || vehicleType === "hatchback") &&
        valuePath === "from_airport"
      ) {
        data = {
          parent: dataToFormat.parent,
          parent_id: dataToFormat.parent_id
            ? dataToFormat.parent_id
            : dataToFormat.id,
          minimum_hour: minimim_hour * 1,
          maximum_hour: maximum_hour * 1,
          extra_km: dataToFormat.per_km * 1,
          kms: [
            {
              min_km: 0,
              max_km: 10,
              base_fare: dataToFormat.range_1 * 1,
            },
            {
              min_km: 10,
              max_km: 20,
              base_fare: dataToFormat.range_2 * 1,
            },
            {
              min_km: 20,
              max_km: 30,
              base_fare: dataToFormat.range_3 * 1,
            },
            {
              min_km: 30,
              max_km: 35,
              base_fare: dataToFormat.range_4 * 1,
            },
            {
              min_km: 35,
              max_km: 40,
              base_fare: dataToFormat.range_5 * 1,
            },
            {
              min_km: 40,
              max_km: 45,
              base_fare: dataToFormat.range_6 * 1,
            },
          ],
        };
      } else {
        data = {
          parent: false,
          parent_id: "none",
          minimum_hour: 0 * 1,
          maximum_hour: 0 * 1,
          extra_km: dataToFormat.per_km * 1,
          kms: [
            {
              min_km: 0,
              max_km: 45,
              base_fare: dataToFormat.range_6 * 1,
            },
          ],
        };
      }
      configs.push(data);
      count++;
      if (count === rows.length) {
        let max_distance;

        if (
          (vehicleType === "sedan" || vehicleType === "hatchback") &&
          valuePath === "to_airport"
        ) {
          max_distance = 50;
        } else {
          // max_distance = 45;
          max_distance =
            fullData.max_distance === undefined ? 45 : fullData.max_distance;
        }

        let days;
        // if (fullData.days === undefined || fullData.days.length === 0) {
        //   days =
        //     value === "weekdays"
        //       ? ["monday", "tuesday", "wednesday", "thursday"]
        //       : ["friday", "saturday", "sunday"];
        // } else {
        //   days = fullData.days;
        // }
        // console.log(checkedA);
        let dataSend = {
          ride_type: "airport",
          user_id: sessionStorage.getItem("user_id"),
          vehicle_type: vehicleType,
          vendor_type: "mmt",
          travel_type: valuePath,
          day_of_week: getDayOfTheWeek(valueDayTab),
          // fare_status: checkedA === true ? "Active" : "Inactive",
          config_old: checkData.config,
          max_distance: max_distance * 1,
          config: configs,
          // config_fare: {
          //   max_distance: max_distance * 1,
          //   type: value,
          //   days: days,
          //   config: configs,
          // },
        };
        console.log(dataSend);
        axios
          .post(config.url + "htmlcrm/v2/updatevendorconfiguredfare", dataSend)
          .then((response) => {
            // console.log(response);
            if (response.data.message === "successfull") {
              callFareRetrieveAPI();
              setEditChange(false);
              // setpenSaveModal(false);
              handleCloseSaveModal();
              //  handleDayEdit("", dataSend.day_of_week);
              // retrieveFareData();
              setMessage("Fare changes updated successfully");
              setSuccess(true);
              show();
              // setSuccess(true);
            } else {
              callFareRetrieveAPI();
              // setEditChange(false)
              //  retrieveFareData();
              setSuccess(false);
              setMessage("Failed to update fares");
              show();
              // setSuccess(false);
            }
          });

        // console.log(dataToSend);
      }
    });
  };

  const updateMoreFares = () => {
    let daysToCheck = yourChips;
    if (daysToCheck && daysToCheck.length > 0) {
      let j = 0;
      for (let i = 0; i < daysToCheck.length; i++) {
        let dataToCheck = rows;
        let configs = [];
        let count = 0;
        let data;
        let minimim_hour;
        let maximum_hour;
        dataToCheck.forEach((dataToFormat) => {
          let timeData = dataToFormat.name;
          if (typeof timeData === "string") {
            let splitTimeData = timeData.split("to");
            minimim_hour = moment(splitTimeData[0], "hh A").format("HH");
            maximum_hour = moment(splitTimeData[1], "hh A").format("HH");
          } else {
            minimim_hour = moment(timeData[0], "hh:mm").format("HH");
            maximum_hour = moment(timeData[1], "hh:mm").format("HH");
          }

          if (
            (vehicleType === "sedan" || vehicleType === "hatchback") &&
            valuePath === "to_airport"
          ) {
            data = {
              parent: dataToFormat.parent,
              parent_id: dataToFormat.parent_id
                ? dataToFormat.parent_id
                : dataToFormat.id,
              minimum_hour: minimim_hour * 1,
              maximum_hour: maximum_hour * 1,
              extra_km: dataToFormat.per_km * 1,
              kms: [
                {
                  min_km: 0,
                  max_km: 20,
                  base_fare: dataToFormat.range_1 * 1,
                },
                {
                  min_km: 20,
                  max_km: 25,
                  base_fare: dataToFormat.range_2 * 1,
                },
                {
                  min_km: 25,
                  max_km: 30,
                  base_fare: dataToFormat.range_3 * 1,
                },
                {
                  min_km: 30,
                  max_km: 35,
                  base_fare: dataToFormat.range_4 * 1,
                },
                {
                  min_km: 35,
                  max_km: 40,
                  base_fare: dataToFormat.range_5 * 1,
                },
                {
                  min_km: 40,
                  max_km: 45,
                  base_fare: dataToFormat.range_6 * 1,
                },
                {
                  min_km: 45,
                  max_km: 50,
                  base_fare: dataToFormat.range_7 * 1,
                },
              ],
            };
          } else if (
            (vehicleType === "sedan" || vehicleType === "hatchback") &&
            valuePath === "from_airport"
          ) {
            data = {
              parent: dataToFormat.parent,
              parent_id: dataToFormat.parent_id
                ? dataToFormat.parent_id
                : dataToFormat.id,
              minimum_hour: minimim_hour * 1,
              maximum_hour: maximum_hour * 1,
              extra_km: dataToFormat.per_km * 1,
              kms: [
                {
                  min_km: 0,
                  max_km: 10,
                  base_fare: dataToFormat.range_1 * 1,
                },
                {
                  min_km: 10,
                  max_km: 20,
                  base_fare: dataToFormat.range_2 * 1,
                },
                {
                  min_km: 20,
                  max_km: 30,
                  base_fare: dataToFormat.range_3 * 1,
                },
                {
                  min_km: 30,
                  max_km: 35,
                  base_fare: dataToFormat.range_4 * 1,
                },
                {
                  min_km: 35,
                  max_km: 40,
                  base_fare: dataToFormat.range_5 * 1,
                },
                {
                  min_km: 40,
                  max_km: 45,
                  base_fare: dataToFormat.range_6 * 1,
                },
              ],
            };
          } else {
            data = {
              parent: dataToFormat.parent,
              parent_id: dataToFormat.parent_id
                ? dataToFormat.parent_id
                : dataToFormat.id,
              minimum_hour: 0 * 1,
              maximum_hour: 0 * 1,
              extra_km: dataToFormat.per_km * 1,
              kms: [
                {
                  min_km: 0,
                  max_km: 45,
                  base_fare: dataToFormat.range_6 * 1,
                },
              ],
            };
          }
          configs.push(data);
          count++;
          if (count === rows.length) {
            let max_distance;

            if (
              (vehicleType === "sedan" || vehicleType === "hatchback") &&
              valuePath === "to_airport"
            ) {
              max_distance = 50;
            } else {
              // max_distance = 45;
              max_distance =
                fullData.max_distance === undefined
                  ? 45
                  : fullData.max_distance;
            }

            let days;
            // if (fullData.days === undefined || fullData.days.length === 0) {
            //   days =
            //     value === "weekdays"
            //       ? ["monday", "tuesday", "wednesday", "thursday"]
            //       : ["friday", "saturday", "sunday"];
            // } else {
            //   days = fullData.days;
            // }
            // console.log(checkedA);
            let dataSend = {
              ride_type: "airport",
              user_id: sessionStorage.getItem("user_id"),
              vehicle_type: vehicleType,
              vendor_type: "mmt",
              travel_type: valuePath,
              day_of_week: daysToCheck[i].toLowerCase(),
              // fare_status: checkedA === true ? "Active" : "Inactive",
              config_old: checkData.config,
              max_distance: max_distance * 1,
              config: configs,
              // config_fare: {
              //   max_distance: max_distance * 1,
              //   type: value,
              //   days: days,
              //   config: configs,
              // },
            };
            console.log(dataSend);
            axios
              .post(
                config.url + "htmlcrm/v2/updatevendorconfiguredfare",
                dataSend
              )
              .then((response) => {
                // console.log(response);
                if (response.data.message === "successfull") {
                  //  handleDayEdit("", dataSend.day_of_week);
                  //  retrieveFareData();
                  // setMessage("Fare changes updated successfully");
                  // show();
                  // setSuccess(true);
                } else {
                  //  retrieveFareData();
                  // setMessage("Failed to update fares");
                  // show();
                  // setSuccess(false);
                }
              });

            // console.log(dataToSend);
          }
        });
        j++;
      }
      if (j === daysToCheck.length) {
        callFareRetrieveAPI();
        setEditChange(false);
        handleCloseSaveModal();
        setMessage("Fare changes updated successfully");
        setSuccess(true);
        show();
      }
    } else {
      setMessage("Days cannot be empty");
      setSuccess(false);
      show();
    }
    // console.log(daysToCheck);
  };

  const handleDeleteChip = (deletedChip, index) => {
    if (deletedChip !== "react") {
      let data = yourChips.filter((c) => c !== deletedChip);
      setYourChips(data);
      // this.setState({
      //   chips: this.state.chips.filter((c) => c !== deletedChip),
      // });
    } else {
      // alert("Why would you delete React?");
    }
  };

  const retrieveFareStatus = () => {
    let dataToSend = {
      ride_type: "airport",
      vehicle_type: vehicleType,
      vendor_type: "mmt",
      travel_type: valuePath,
    };
    // console.log(dataToSend);
    axios
      .post(config.url + "htmlcrm/v2/getoverallstatus", dataToSend)
      .then((response) => {
        // console.log(response);
        if (response.data.message === stringsData.onsuccess) {
          let dataToCheck = response.data.days;
          let dataToCompare = {
            day_of_week: getDayOfTheWeek(valueDayTab),
          };

          let filterData = _.filter(dataToCheck, (o) => {
            return o.day_of_week === getDayOfTheWeek(valueDayTab);
          });
          // console.log(finalData);
          if (filterData) {
            // setCheckData(dataToCheck.)
            // let boolData =

            // console.log();
            filterData[0].fare_status === "Active"
              ? setState(true)
              : setState(false);
          }
        } else {
          setState(false);
        }
      })
      .catch();
  };

  const show = () => {
    if (Showing) return;
    setShow(true);
    setShowing(true);
    // this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      // this.setState({ Show: false, Showing: false });
      setShow(false);
      setShowing(false);
    }, 2500);
  };

  const classes = useStyles();
  const [checkData, setCheckData] = React.useState([]);
  const [fullData, setFullData] = React.useState([]);
  const [vendorTab, setVendorTab] = React.useState(0); // Flag to set vendor
  const [editChange, setEditChange] = React.useState(false);
  const [rows, setRows] = React.useState([
    createData(1, "12 AM to 6 AM", 0, 0, 0, 0, 0, 0, 0, 0),
    createData(2, "6 AM to 11 AM", 0, 0, 0, 0, 0, 0, 0, 0),
    createData(3, "11 AM to 4 PM", 0, 0, 0, 0, 0, 0, 0, 0),
    createData(4, "4 PM to 9 PM", 0, 0, 0, 0, 0, 0, 0, 0),
    createData(5, "9 PM to 12 AM", 0, 0, 0, 0, 0, 0, 0, 0),
  ]);
  // const [valueInnerTab, setValueInner] = React.useState(0);
  const [valueDayTab, setValueDayTab] = React.useState(0); //Select day tab
  const [valuePath, setValuePath] = React.useState("to_airport");
  const [previous, setPrevious] = React.useState({});
  const [vehicleType, setVehicleType] = React.useState("sedan");
  const [rideType, setRideType] = React.useState("airport");
  const [checkedA, setState] = React.useState(false);
  const [openSaveModal, setOpenSaveModal] = React.useState(false);
  const [valueUpdate, setValueUpdate] = React.useState("current_day");
  const [yourChips, setYourChips] = React.useState([]);
  const [openDutyModal, setOpenDutyModal] = React.useState(false);
  const [openDeactiveDuty, setDeactiveDuty] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [Show, setShow] = React.useState(false);
  const [Showing, setShowing] = React.useState(false);

  useEffect(() => {
    // console.log("yo");

    setEditChange(false);
    setRows([]);
    retrieveFareStatus();
    callFareRetrieveAPI();
  }, [valueDayTab]);

  useEffect(() => {
    setEditChange(false);
    setRows([]);
    retrieveFareStatus();
    callFareRetrieveAPI();
  }, [rideType]);

  useEffect(() => {
    setEditChange(false);
    setRows([]);
    retrieveFareStatus();
    callFareRetrieveAPI();
  }, [vehicleType]);

  useEffect(() => {
    setEditChange(false);
    setRows([]);
    retrieveFareStatus();
    callFareRetrieveAPI();
  }, [valuePath]);

  useEffect(() => {
    if (
      sessionStorage.getItem("user_id") === undefined ||
      sessionStorage.getItem("user_id") === null
    ) {
      props.history.push("/");
    } else {
      setEditChange(false);
      setRows([]);
      retrieveFareStatus();
      callFareRetrieveAPI();
    }
  }, []);

  return (
    <Container fluid className="main-content-container">
      <Modal
        show={openSaveModal}
        centered
        size="lg"
        backdrop="static"
        onHide={handleCloseSaveModal}
      >
        <Modal.Header
          style={{ borderBottom: "none" }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <Typography>
            <h5 style={{ textAlign: "center", fontWeight: 700 }}>
              Are you sure you want to update the new fare changes ?
            </h5>
          </Typography>
          <div className="d-flex justify-content-center">
            <FormControl component="fieldset" className="mt-md-3">
              <RadioGroup
                row
                aria-label="days"
                name="days"
                value={valueUpdate}
                onChange={(e) => {
                  setValueUpdate(e.target.value);
                  setYourChips([]);
                }}
              >
                <FormControlLabel
                  className="px-4"
                  control={<RadioButton />}
                  label="For the current day"
                  value="current_day"
                />
                <FormControlLabel
                  className="px-4"
                  value="select_days"
                  control={<RadioButton />}
                  label="Select days"
                />
              </RadioGroup>
            </FormControl>
          </div>
          {valueUpdate === "select_days" && (
            <div className="d-flex justify-content-center mt-3">
              {/* <ChipInput
                fullWidth
                Autosuggest
                value={yourChips}
                onAdd={(chip) => handleAddChip(chip)}
                onDelete={(chip, index) => handleDeleteChip(chip, index)}
                placeholder="Select day of the week. Enter 'all' to select all days"
                InputLabelProps={{ shrink: true }}
                alwaysShowPlaceholder
              /> */}

              <ReactAutosuggestExample
                placeholder="Select day of the week. Enter 'all' to select all days"
                fullWidth
                handleAddChip={handleAddChip}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <div>
            {/* <SaveButton className="mx-2" onClick={handleDeactivateCloseSwitch}>
              CANCEL
            </SaveButton> */}
            <SaveButton
              className="py-3"
              size="large"
              style={{ minWidth: "240px" }}
              onClick={updateFare}
            >
              APPLY CHANGES
            </SaveButton>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openDutyModal}
        centered
        size="lg"
        backdrop="static"
        onHide={handleCloseDutyModal}
      >
        <Modal.Header
          style={{ borderBottom: "none" }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <Typography>
            <h5 style={{ textAlign: "center", fontWeight: 700 }}>
              Are you sure you want to activate the booking flow ?
            </h5>
          </Typography>
          <div className="d-flex justify-content-center">
            <FormControl component="fieldset" className="mt-md-3">
              <RadioGroup
                row
                aria-label="days"
                name="days"
                value={valueUpdate}
                onChange={(e) => {
                  setValueUpdate(e.target.value);
                  setYourChips([]);
                }}
              >
                <FormControlLabel
                  className="px-4"
                  control={<RadioButton />}
                  label="For the current day"
                  value="current_day"
                />
                <FormControlLabel
                  className="px-4"
                  value="select_days"
                  control={<RadioButton />}
                  label="Select days"
                />
              </RadioGroup>
            </FormControl>
          </div>
          {valueUpdate === "select_days" && (
            <div className="d-flex justify-content-center mt-3">
              {/* <ChipInput
                fullWidth
                value={yourChips}
                onAdd={(chip) => handleAddChip(chip)}
                onDelete={(chip, index) => handleDeleteChip(chip, index)}
                placeholder="Select day of the week. Enter 'all' to select all days"
                InputLabelProps={{ shrink: true }}
                alwaysShowPlaceholder
              /> */}
              <ReactAutosuggestExample
                placeholder="Select day of the week. Enter 'all' to select all days"
                fullWidth
                handleAddChip={handleAddChip}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <div>
            {/* <SaveButton className="mx-2" onClick={handleDeactivateCloseSwitch}>
              CANCEL
            </SaveButton> */}
            <SaveButton
              className="py-3"
              size="large"
              style={{ minWidth: "240px" }}
              onClick={(e) => activateFare(true)}
            >
              APPLY CHANGES
            </SaveButton>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openDeactiveDuty}
        centered
        size="lg"
        backdrop="static"
        onHide={handleCloseDeactiveModal}
      >
        <Modal.Header
          style={{ borderBottom: "none" }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <Typography>
            <h5 style={{ textAlign: "center", fontWeight: 700 }}>
              Are you sure you want to deactivate the booking flow ?
            </h5>
          </Typography>
          <div className="d-flex justify-content-center">
            <FormControl component="fieldset" className="mt-md-3">
              <RadioGroup
                row
                aria-label="days"
                name="days"
                value={valueUpdate}
                onChange={(e) => {
                  setValueUpdate(e.target.value);
                  setYourChips([]);
                }}
              >
                <FormControlLabel
                  className="px-4"
                  control={<RadioButton />}
                  label="For the current day"
                  value="current_day"
                />
                <FormControlLabel
                  className="px-4"
                  value="select_days"
                  control={<RadioButton />}
                  label="Select days"
                />
              </RadioGroup>
            </FormControl>
          </div>
          {valueUpdate === "select_days" && (
            <div className="d-flex justify-content-center mt-3">
              {/* <ChipInput
                fullWidth
                value={yourChips}
                onAdd={(chip) => handleAddChip(chip)}
                onDelete={(chip, index) => handleDeleteChip(chip, index)}
                placeholder="Select day of the week. Enter 'all' to select all days"
                InputLabelProps={{ shrink: true }}
                alwaysShowPlaceholder
              /> */}

              <ReactAutosuggestExample
                placeholder="Select day of the week. Enter 'all' to select all days"
                fullWidth
                handleAddChip={handleAddChip}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <div>
            {/* <SaveButton className="mx-2" onClick={handleDeactivateCloseSwitch}>
              CANCEL
            </SaveButton> */}
            <SaveButton
              className="py-3"
              size="large"
              style={{ minWidth: "240px" }}
              onClick={(e) => deactivateFare(false)}
            >
              APPLY CHANGES
            </SaveButton>
          </div>
        </Modal.Footer>
      </Modal>

      <Row className="px-1 px-md-4 py-md-3">
        <Col className="col-12 col-md-12">
          <Row className="w-100">
            <Col className="col-12">
              <Breadcrumb>
                <BreadcrumbItem>
                  <a href="#" onClick={goBackAccounts}>
                    Configuration /
                  </a>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col className="col-12 mt-md-1">
              <PageTitle
                title="Fare configuration"
                className="text-sm-left mb-3 pl-0 custom-margin"
              />
            </Col>
          </Row>
          <form onSubmit={saveData} className="px-0">
            <Row className="mt-5">
              <Col className="col-12 col-md-3 mt-3">
                <FormGroup>
                  <label className="px-3">Filter by vehilce type</label>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Select vehicle type
                    </InputLabel>
                    <Select
                      labelId="vehicle-type-select"
                      id="vehicle-type-select"
                      label="Select vehicle type"
                      className={classes.selectRoot}
                      style={{
                        fontWeight: 700,
                      }}
                      value={vehicleType}
                      onChange={(e) => setVehicleType(e.target.value)}
                    >
                      {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem> */}
                      <MenuItem value="hatchback">Hatchback</MenuItem>
                      <MenuItem value="sedan">Sedan</MenuItem>
                      <MenuItem value="suv">SUV</MenuItem>
                    </Select>
                  </FormControl>
                </FormGroup>
              </Col>
              <Col className="col-12 col-md-3 mt-3">
                <FormGroup>
                  <label className="px-3">Filter by ride type</label>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Ride type
                    </InputLabel>
                    <Select
                      labelId="vehicle-type-select"
                      id="vehicle-type-select"
                      label="Ride type"
                      className={classes.selectRoot}
                      style={{
                        fontWeight: 700,
                      }}
                      value={rideType}
                      onChange={(e) => setRideType(e.target.value)}
                    >
                      {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem> */}
                      <MenuItem value="airport">Airport</MenuItem>
                      {/* <MenuItem value="rental">Rental</MenuItem>
                      <MenuItem value="outstation">Outstation</MenuItem> */}
                    </Select>
                  </FormControl>
                </FormGroup>
              </Col>
              {rideType === "airport" && (
                <Col className="col-12 col-md-6 mt-3">
                  <FormGroup>
                    <label className="px-3">Filter by type of flow</label>
                    <FormControl component="fieldset" className="mt-md-3">
                      <RadioGroup
                        row
                        aria-label="days"
                        name="days"
                        value={valuePath}
                        onChange={(e) => setValuePath(e.target.value)}
                      >
                        <FormControlLabel
                          className="px-4"
                          control={<RadioButton />}
                          label="City to airport"
                          value="to_airport"
                        />
                        <FormControlLabel
                          className="px-4"
                          value="from_airport"
                          control={<RadioButton />}
                          label="Airport to city"
                        />
                      </RadioGroup>
                    </FormControl>
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row className="mt-4 mb-4">
              <Col className="col-12">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col className="col-12 col-md-12">
                        <Typography
                          // style={{handleDayEdit fontWeight: 600 }}
                          className="d-flex"
                        >
                          Booking flow:
                          {checkedA === true ? (
                            <Typography
                              style={{
                                fontWeight: 700,
                              }}
                              className="mx-2"
                            >
                              Enabled
                            </Typography>
                          ) : (
                            <Typography
                              style={{
                                fontWeight: 700,
                              }}
                              className="mx-2"
                            >
                              Disabled
                            </Typography>
                          )}
                        </Typography>
                        <FormControlLabel
                          control={
                            <BlackSwitch
                              name="checkedA"
                              checked={checkedA}
                              onChange={handleSwitchChange}
                            />
                          }
                        />
                      </Col>
                      <Col className="col-12 col-md-8 mt-3">
                        <ThemeProvider theme={theme}>
                          <Tabs
                            className={classes.tabs}
                            value={valueDayTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChangeInnerTab}
                            scrollButtons="auto"
                            variant="scrollable"
                            aria-label="inner tabs"
                            TabIndicatorProps={{
                              style: {
                                display: "none",
                              },
                            }}
                          >
                            <AntTab
                              label="Monday"
                              className={classes.tabsRoot}
                              style={{ minWidth: "10%" }}
                            />
                            <AntTab
                              label="Tuesday"
                              className={classes.tabsRoot}
                              style={{ minWidth: "10%" }}
                            />
                            <AntTab
                              label="Wednesday"
                              className={classes.tabsRoot}
                              style={{ minWidth: "10%" }}
                            />
                            <AntTab
                              label="Thursday"
                              className={classes.tabsRoot}
                              style={{ minWidth: "10%" }}
                            />
                            <AntTab
                              label="Friday"
                              className={classes.tabsRoot}
                              style={{ minWidth: "10%" }}
                            />
                            <AntTab
                              label="Saturday"
                              className={classes.tabsRoot}
                              style={{ minWidth: "10%" }}
                            />
                            <AntTab
                              label="Sunday"
                              className={classes.tabsRoot}
                              style={{ minWidth: "10%" }}
                            />
                          </Tabs>
                        </ThemeProvider>
                      </Col>
                      <Col className="col-12 col-md-4 d-flex justify-content-end my-auto">
                        {editChange === false && (
                          <Button
                            startIcon={<EditIcon />}
                            onClick={toggleSave}
                            style={{ fontSize: "1.1em", fontWeight: "700" }}
                          >
                            EDIT FARE
                          </Button>
                        )}
                        {editChange === true && (
                          <div className="d-flex justify-content-center">
                            <Button
                              className="mx-2 my-2"
                              type="submit"
                              startIcon={<Save />}
                              style={{ fontSize: "1.1em", fontWeight: "700" }}
                            >
                              SAVE
                            </Button>
                            <Button
                              className="mx-2 my-2"
                              startIcon={<Close />}
                              onClick={toggleSaveCancel}
                              style={{ fontSize: "1.1em", fontWeight: "700" }}
                            >
                              CANCEL
                            </Button>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12">
                        <Paper className={classes.tableRoot} elevation="0">
                          <Table
                            className={classes.table}
                            aria-label="caption table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="left"
                                  className={classes.tabHeader}
                                ></TableCell>

                                {(vehicleType === "sedan" ||
                                  vehicleType === "hatchback") &&
                                  valuePath === "to_airport" && (
                                    <React.Fragment>
                                      <TableCell
                                        align="left"
                                        className={classes.tabHeader}
                                      >
                                        Time slot
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tabHeader}
                                      >
                                        0 km to 20 km
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tabHeader}
                                      >
                                        20 km to 25 km
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tabHeader}
                                      >
                                        25 km to 30 km
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tabHeader}
                                      >
                                        30 km to 35 km
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tabHeader}
                                      >
                                        35 km to 40 km
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tabHeader}
                                      >
                                        40 km to 45 km
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tabHeader}
                                      >
                                        45 km to 50 km
                                      </TableCell>
                                    </React.Fragment>
                                  )}
                                {(vehicleType === "sedan" ||
                                  vehicleType === "hatchback") &&
                                  valuePath === "from_airport" && (
                                    <React.Fragment>
                                      <TableCell
                                        align="left"
                                        className={classes.tabHeader}
                                      >
                                        Time slot
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tabHeader}
                                      >
                                        0 km to 10 km
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tabHeader}
                                      >
                                        10 km to 20 km
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tabHeader}
                                      >
                                        20 km to 30 km
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tabHeader}
                                      >
                                        30 km to 35 km
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tabHeader}
                                      >
                                        35 km to 40 km
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tabHeader}
                                      >
                                        40 km to 45 km
                                      </TableCell>
                                    </React.Fragment>
                                  )}
                                {vehicleType === "suv" && (
                                  <TableCell
                                    align="left"
                                    className={classes.tabHeader}
                                  >
                                    0 km to 45 km
                                  </TableCell>
                                )}

                                <TableCell
                                  align="left"
                                  className={classes.tabHeader}
                                >
                                  Per km fare
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row) => (
                                <TableRow key={row.id}>
                                  <CustomTableCell
                                    {...{
                                      row,
                                      name: "sl_no",
                                      onChange,
                                      clickNext,
                                      clickDelete,
                                    }}
                                  />

                                  {(vehicleType === "sedan" ||
                                    vehicleType === "hatchback") &&
                                    valuePath === "from_airport" && (
                                      <React.Fragment>
                                        <CustomTableCell
                                          {...{
                                            row,
                                            name: "name",
                                            onChange,
                                          }}
                                        />
                                        <CustomTableCell
                                          {...{
                                            row,
                                            name: "range_1",
                                            onChange,
                                          }}
                                        />
                                        <CustomTableCell
                                          {...{
                                            row,
                                            name: "range_2",
                                            onChange,
                                          }}
                                        />
                                        <CustomTableCell
                                          {...{
                                            row,
                                            name: "range_3",
                                            onChange,
                                          }}
                                        />
                                        <CustomTableCell
                                          {...{
                                            row,
                                            name: "range_4",
                                            onChange,
                                          }}
                                        />
                                        <CustomTableCell
                                          {...{
                                            row,
                                            name: "range_5",
                                            onChange,
                                          }}
                                        />
                                        <CustomTableCell
                                          {...{
                                            row,
                                            name: "range_6",
                                            onChange,
                                          }}
                                        />
                                      </React.Fragment>
                                    )}

                                  {(vehicleType === "sedan" ||
                                    vehicleType === "hatchback") &&
                                    valuePath === "to_airport" && (
                                      <React.Fragment>
                                        <CustomTableCell
                                          {...{
                                            row,
                                            name: "name",
                                            onChange,
                                          }}
                                        />
                                        <CustomTableCell
                                          {...{
                                            row,
                                            name: "range_1",
                                            onChange,
                                          }}
                                        />
                                        <CustomTableCell
                                          {...{
                                            row,
                                            name: "range_2",
                                            onChange,
                                          }}
                                        />
                                        <CustomTableCell
                                          {...{
                                            row,
                                            name: "range_3",
                                            onChange,
                                          }}
                                        />
                                        <CustomTableCell
                                          {...{
                                            row,
                                            name: "range_4",
                                            onChange,
                                          }}
                                        />
                                        <CustomTableCell
                                          {...{
                                            row,
                                            name: "range_5",
                                            onChange,
                                          }}
                                        />

                                        <CustomTableCell
                                          {...{
                                            row,
                                            name: "range_6",
                                            onChange,
                                          }}
                                        />
                                        <CustomTableCell
                                          {...{
                                            row,
                                            name: "range_7",
                                            onChange,
                                          }}
                                        />
                                      </React.Fragment>
                                    )}

                                  {vehicleType === "suv" && (
                                    <CustomTableCell
                                      {...{
                                        row,
                                        name: "range_6",
                                        onChange,
                                      }}
                                    />
                                  )}

                                  <CustomTableCell
                                    {...{
                                      row,
                                      name: "per_km",
                                      onChange,
                                    }}
                                  />
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          {/* {sundayEdit && (
                          <div className="py-3">
                            <Button onClick={addNewRow}>+ Add time slot</Button>
                          </div>
                        )} */}
                        </Paper>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
      {success === false && (
        <ReactSnackBar
          Icon={
            <i
              className="far fa-times-circle fa-lg"
              style={{ color: "#FF0000" }}
            />
          }
          Show={Show}
        >
          {message}
        </ReactSnackBar>
      )}
      {success === true && (
        <ReactSnackBar
          Icon={
            <span>
              <i
                className="far fa-check-circle fa-lg"
                style={{ color: "#26FF58" }}
              />
            </span>
          }
          Show={Show}
        >
          {message}
        </ReactSnackBar>
      )}
    </Container>
  );
}

export default FareConfig;
