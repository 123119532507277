import React from "react";
import { FormInput, Col, Row } from "shards-react";

export default class SearchDriver extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      filteredData: [],
      columnSearchNow: "",
      searchInput: "",
    };
  }

  handleChange = (event) => {
    // const val = event.target.value;
    // this.setState({ searchInput: val }, () => this.globalSearch());
    // this.props.handleSetSearchInput(val);
    const val = event.target.value;
    // console.log(val)
    this.setState({ searchInput: val }, () => this.globalSearch());
    // console.log(val)
    if (this.props.handleSetSearchInput === undefined) {
      // Do nothing
    } else {
      this.props.handleSetSearchInput(val);
    }
  };

  globalSearch = () => {
    const { searchInput, columnSearchNow } = this.state;
    let filteredData = this.props.data.filter((value) => {
      if (columnSearchNow) {
        return value[columnSearchNow]
          .toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      }
      if (this.props.type === "onduty") {
        return (
          value.dname.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.driver_type.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.phnno.toLowerCase().includes(searchInput.toLowerCase())
        );
      } else {
        return (
          value.driver_type.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.driver_name.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.phonenumber.toLowerCase().includes(searchInput.toLowerCase())
        );
      }
    });

    this.props.handleSetFilteredData(filteredData);
  };

  setColumnSearch = (e) => {
    this.setState({ columnSearch: e.target.value }, () => this.globalSearch());
  };

  render() {
    const { columnSearch } = this.props;
    const { columnSearchNow } = this.state;

    return (
      <Col className="col-12 my-auto">
        <FormInput
          size="large"
          name="searchInput"
          value={this.state.searchInput || ""}
          onChange={this.handleChange}
          placeholder="Search"
          class
        />
      </Col>
    );
  }
}
